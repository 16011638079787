import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Paper, Grid, Box, Typography, Stack, Card } from '@mui/material';
import RewardPartnerVoucherCard from '../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard';
import RewardPartnerCard from '../../components/RewardPartnerCard/RewardPartnerCard';
import RewardCreditsCard from '../../components/RewardCreditsCard/RewardCreditsCard';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { dateTime } from '../../constants/app';
import * as rewardsActions from '../../store/rewards';
import credits_gold from '../../assets/header/Credits_Gold.png';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'white',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'white',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CreditsDetailsFilters = (props) => {
  const dispatch = useDispatch();
  let params = useParams();
  const [noofPartner, setNoofPartner] = React.useState(4);
  const [filtertype, setFiltertype] = React.useState('daily');
  const user = useSelector((state) => state?.user?.UserDetails);
  const rewardsPartnerDetails = useSelector(
    (state) => state?.rewards?.rewardsPartnerDetails,
  );

  const rewardsGetCreditsDetails = useSelector(
    (state) => state?.rewards?.rewardsGetCreditsDetails,
  );

  const rewardsGetCreditslist = useSelector(
    (state) => state?.rewards?.rewardsGetCreditslist,
  );
  const handleChange = async (event) => {
    setFiltertype(event.target.value);
    const data = {
      user_id: user?.id,
      filter_type: event.target.value,
      datetime: dateTime(),
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetCreditsDetails(data),
    );
  };
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      filter_type: filtertype,
      datetime: dateTime(),
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetCreditsDetails(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      datetime: dateTime(),
      noOfDays: 365,
      action_code: 'create_post',
    };
    const linkcxorewardsActions = await dispatch(
      rewardsActions.rewardsGetCreditsByActions(data),
    );
  }, []);

  const dateHeader = (date, rewardsGetCredits, index) => {
    if (filtertype !== 'daily' && filtertype !== 'all') {
      let rewardsdateDetails = Object.keys(rewardsGetCreditsDetails);
      if (rewardsdateDetails?.length > index) {
        let new_date = moment(
          moment(moment(rewardsdateDetails[index + 1]).format('LL')).add(
            1,
            'days',
          ),
        ).format('LL');
        return new_date + ' - ' + date;
      } else {
        return date;
      }
    } else {
      return date;
    }
  };

  return (
    <Fragment>
      <Box>
        <Box sx={{ width: '100%' }}>
          <Box py={2}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}></Box>

                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-simple-select-label">
                      Timeline
                    </InputLabel>
                    <CssSelect
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filtertype}
                      onChange={handleChange}>
                      <MenuItem value="all">All Time</MenuItem>
                      <MenuItem value="today">Today</MenuItem>
                      <MenuItem value="oneweek">Last 1 week</MenuItem>
                      <MenuItem value="onemonth">Last 1 Month</MenuItem>
                      <MenuItem value="sixmonth">Last 6 Month</MenuItem>
                      <MenuItem value="oneyear">Last 1 Year</MenuItem>
                    </CssSelect>
                  </FormControl>
                </Box>
              </Stack>
            </Paper>

            <Box pt={2}>
              {Object.keys(rewardsGetCreditsDetails).map((day, index) => {
                return (
                  <Paper
                    key={index}
                    sx={{
                      backgroundColor: '#232121',
                      backgroundImage: 'none',
                    }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={0}>
                      <Box
                        py={2}
                        px={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography variant="h2s" component="h2s">
                          {dateHeader(day, rewardsGetCreditsDetails, index)}
                        </Typography>
                      </Box>

                      <Box
                        py={2}
                        px={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}></Box>
                    </Stack>

                    <Box py={1}>
                      <Grid container>
                        {rewardsGetCreditsDetails[day]?.length > 0 &&
                          rewardsGetCreditsDetails[day]?.map((list, index) => {
                            return (
                              <Grid
                                key={index}
                                item
                                xs={12}
                                md={6}
                                sx={{
                                  paddingRight: index % 2 === 0 ? '4px' : '4px',
                                  marginTop: '8px',
                                }}>
                                <RewardCreditsCard credit={list} />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Box>
                  </Paper>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(CreditsDetailsFilters);

//
// <Grid container>
//   {rewardsGetCreditslist?.length > 0 &&
//     rewardsGetCreditslist?.map((list, index) => {
//         return (
//           <Grid
//             key={index}
//             item
//             xs={12}
//             md={6}
//             sx={{
//               paddingRight: index % 2 === 0 ? "4px" : "4px",
//               marginTop: "8px",
//             }}
//           >
//             <RewardCreditsCard />
//           </Grid>
//         );
//     })}
// </Grid>
