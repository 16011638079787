import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Box, Typography, Stack, Card } from "@mui/material";
import RewardPartnerVoucherCard from "../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard";
import RewardPartnerCard from "../../components/RewardPartnerCard/RewardPartnerCard";
import RewardCreditsCard from "../../components/RewardCreditsCard/RewardCreditsCard";
import { dateTime } from "../../constants/app";
import * as rewardsActions from "../../store/rewards";
import credits_gold from "../../assets/header/Credits_Gold.png";
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: "#fff",
  },
}));

const CreditsDetailsList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [noofPartner, setNoofPartner] = React.useState(4);
  const user = useSelector((state) => state?.user?.UserDetails);

  const rewardsPartnerDetails = useSelector(
    (state) => state?.rewards?.rewardsPartnerDetails
  );

  const rewardsGetCreditslist = useSelector(
    (state) => state?.rewards?.rewardsGetCreditslist
  );

  return (
    <Fragment>
      <Box>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Box px={2} py={2}>
              <Typography variant="h2s" component="h2s">
                Post
              </Typography>
            </Box>
            <Grid container>
              {rewardsGetCreditslist?.length > 0 &&
                rewardsGetCreditslist?.map((list, index) => {
                  if (list?.type === "post") {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? "4px" : "4px",
                          marginTop: "8px",
                        }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#262525",
                            backgroundImage: "none",
                            justifyContent: "space-between",
                            height: "47px",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "47px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h2" component="h2">
                              {list?.details ? list?.details : ""}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: "#F5BD02",
                                  fontSize: "20px",
                                  lineHeight: "27px",
                                  fontWeight: "600",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {list?.credit ? "+" + list?.credit : ""}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "28px",
                                  maxWidth: "min-content",
                                  width: "28px",
                                  marginLeft: "8px",
                                }}
                              >
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Box>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  }
                })}
            </Grid>

            <Box px={2} py={2}>
              <Typography variant="h2s" component="h2s">
                Article
              </Typography>
            </Box>
            <Grid container>
              {rewardsGetCreditslist?.length > 0 &&
                rewardsGetCreditslist?.map((list, index) => {
                  if (list?.type === "article") {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? "4px" : "4px",
                          marginTop: "8px",
                        }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#262525",
                            backgroundImage: "none",
                            justifyContent: "space-between",
                            minHeight: "47px",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "47px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h2" component="h2">
                              {list?.details ? list?.details : ""}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: "#F5BD02",
                                  fontSize: "20px",
                                  lineHeight: "27px",
                                  fontWeight: "600",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {list?.credit ? "+" + list?.credit : ""}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "28px",
                                  maxWidth: "min-content",
                                  width: "28px",
                                  marginLeft: "8px",
                                }}
                              >
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Box>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  }
                })}
            </Grid>

            <Box px={2} py={2}>
              <Typography variant="h2s" component="h2s">
                Poll
              </Typography>
            </Box>
            <Grid container>
              {rewardsGetCreditslist?.length > 0 &&
                rewardsGetCreditslist?.map((list, index) => {
                  if (list?.type === "poll") {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? "4px" : "4px",
                          marginTop: "8px",
                        }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#262525",
                            backgroundImage: "none",
                            justifyContent: "space-between",
                            minHeight: "47px",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "47px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h2" component="h2">
                              {list?.details ? list?.details : ""}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: "#F5BD02",
                                  fontSize: "20px",
                                  lineHeight: "27px",
                                  fontWeight: "600",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {list?.credit ? "+" + list?.credit : ""}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "28px",
                                  maxWidth: "min-content",
                                  width: "28px",
                                  marginLeft: "8px",
                                }}
                              >
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Box>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  }
                })}
            </Grid>

            <Box px={2} py={2}>
              <Typography variant="h2s" component="h2s">
                Events
              </Typography>
            </Box>
            <Grid container>
              {rewardsGetCreditslist?.length > 0 &&
                rewardsGetCreditslist?.map((list, index) => {
                  if (list?.type === "event") {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? "4px" : "4px",
                          marginTop: "8px",
                        }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#262525",
                            backgroundImage: "none",
                            justifyContent: "space-between",
                            height: "47px",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "47px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h2" component="h2">
                              {list?.details ? list?.details : ""}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: "#F5BD02",
                                  fontSize: "20px",
                                  lineHeight: "27px",
                                  fontWeight: "600",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {list?.credit ? "+" + list?.credit : ""}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "28px",
                                  maxWidth: "min-content",
                                  width: "28px",
                                  marginLeft: "8px",
                                }}
                              >
                                <img alt="creditgold" src={credits_gold} />
                              </Box>
                            </Box>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(CreditsDetailsList);
