import React, { useState } from 'react';
import { Box, FormControl, TextField, Button } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Footer from '../../components/HomeHeader/footer';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import ClearIcon from '@mui/icons-material/Clear';

const CreateForm = () => {
  const [formElements, setFormElements] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleAddTextField = () => {
    setFormElements([...formElements, { type: 'text' }]);
  };

  const handleAddSelect = () => {
    setFormElements([...formElements, { type: 'dropdown', choices: [''] }]);
  };

  const handleAddRadioGroup = () => {
    setFormElements([...formElements, { type: 'radio', choices: ['', ''] }]);
  };

  const handleRemoveElement = (index) => {
    const updatedFormElements = [...formElements];
    updatedFormElements.splice(index, 1);
    setFormElements(updatedFormElements);
  };

  const handleQuestionChange = (e, index) => {
    const updatedFormElements = [...formElements];
    updatedFormElements[index].question = e.target.value;
    setFormElements(updatedFormElements);
  };

  const handleChoiceChange = (e, formIndex, choiceIndex) => {
    const updatedFormElements = [...formElements];
    updatedFormElements[formIndex].choices[choiceIndex] = e.target.value;
    setFormElements(updatedFormElements);
  };

  const addChoice = (formIndex) => {
    const updatedFormElements = [...formElements];
    if (updatedFormElements[formIndex].choices.length < 5) {
      updatedFormElements[formIndex].choices.push('');
      setFormElements(updatedFormElements);
    }
  };

  const removeChoice = (formIndex, choiceIndex) => {
    const updatedFormElements = [...formElements];
    if (updatedFormElements[formIndex].choices.length > 1) {
      updatedFormElements[formIndex].choices.splice(choiceIndex, 1);
      setFormElements(updatedFormElements);
    }
  };

  const renderFormElements = () => {
    return formElements.map((element, index) => (
      <Box key={index}>
        {element.type === 'text' && (
          <Box
            mt={1}
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ width: '800px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    color: 'gray',
                    marginRight: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                  }}>
                  {index + 1}.
                </div>
                <CxoInputCss
                  label="Question"
                  variant="outlined"
                  fullWidth
                  value={element.question}
                  onChange={(e) => handleQuestionChange(e, index)}
                />
              </div>
            </FormControl>
          </Box>
        )}
        {element.type === 'dropdown' && (
          <Box
            mt={1}
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ width: '800px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    color: 'gray',
                    marginRight: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                  }}>
                  {index + 1}.
                </div>
                <CxoInputCss
                  label="Question"
                  variant="outlined"
                  fullWidth
                  value={element.question}
                  onChange={(e) => handleQuestionChange(e, index)}
                />
              </div>{' '}
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  marginTop: '20px',
                }}
                ml={3}>
                <Button
                  sx={{
                    borderRadius: '50px',
                    fontFamily: 'Raleway',
                    lineHeight: '18px',
                    fontWeight: '800',
                    fontSize: '16px',
                    textTransform: 'none',
                    padding: '5px 25px',
                    color: '#000',
                    backgroundColor: (theme) =>
                      theme.palette.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        theme.palette.palette.text.secondary,
                    },
                  }}
                  onClick={() => addChoice(index)}
                  disabled={element.choices.length >= 5}>
                  {' '}
                  Add Option
                </Button>
              </Box>
              {element.choices.map((choice, choiceIndex) => (
                <div
                  key={choiceIndex}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '-20px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                      marginLeft: '24px',
                    }}>
                    <FormControl sx={{ width: '800px' }}>
                      {' '}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          sx={{
                            '& label.Mui-focused': {
                              fontSize: '13px',
                              color: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '& .MuiInputLabel-root': {
                              fontSize: '13px',
                              color: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                              '&:hover fieldset': {
                                borderColor: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                            },
                          }}
                          variant="standard"
                          fullWidth
                          value={choice}
                          onChange={(e) =>
                            handleChoiceChange(e, index, choiceIndex)
                          }
                          placeholder={`Option ${choiceIndex + 1}`}
                          label={`Option ${choiceIndex + 1}`}
                        />
                      </div>{' '}
                    </FormControl>
                  </div>
                  {element.choices.length > 1 && (
                    <ClearIcon
                      onClick={() => removeChoice(index, choiceIndex)}
                      style={{
                        cursor: 'pointer',
                        color: '#fff',
                        width: '20px',
                        height: '20px',
                        marginTop: '20px',
                      }}
                    />
                  )}
                </div>
              ))}{' '}
            </FormControl>
          </Box>
        )}
        {element.type === 'radio' && (
          <Box
            mt={1}
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ width: '800px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    color: 'gray',
                    marginRight: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                  }}>
                  {index + 1}.
                </div>
                <CxoInputCss
                  label="Question"
                  variant="outlined"
                  fullWidth
                  value={element.question}
                  onChange={(e) => handleQuestionChange(e, index)}
                />{' '}
              </div>
              {element.choices.map((choice, choiceIndex) => (
                <div
                  key={choiceIndex}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '-20px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}>
                    <div
                      style={{
                        borderRadius: '50%',
                        width: '16px',
                        height: '16px',
                        border: '1px solid #fff',
                        marginRight: '8px',
                        pointerEvents: 'none',
                        marginTop: '10px',
                      }}
                    />
                    <FormControl sx={{ width: '800px' }}>
                      <TextField
                        sx={{
                          '& label.Mui-focused': {
                            fontSize: '13px',
                            color: (theme) =>
                              theme.palette.palette.text.secondary,
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: '13px',
                            color: (theme) =>
                              theme.palette.palette.text.secondary,
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: (theme) =>
                              theme.palette.palette.text.secondary,
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '&:hover fieldset': {
                              borderColor: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                          },
                        }}
                        variant="standard"
                        fullWidth
                        value={choice}
                        onChange={(e) =>
                          handleChoiceChange(e, index, choiceIndex)
                        }
                        placeholder={`Option ${choiceIndex + 1}`}
                        label={`Option ${choiceIndex + 1}`}
                      />
                    </FormControl>
                  </div>
                </div>
              ))}
            </FormControl>
          </Box>
        )}
        <Box mt={5}>
          <Button
            sx={{
              color: (theme) => theme.palette.palette.text.black,
              borderRadius: '50px',
              fontFamily: 'Raleway',
              lineHeight: '18px',
              fontWeight: '800',
              fontSize: '16px',
              textTransform: 'none',
              padding: '5px 25px',
              backgroundColor: (theme) => theme.palette.palette.text.secondary,
              '&:hover': {
                backgroundColor: (theme) =>
                  theme.palette.palette.text.secondary,
              },
            }}
            onClick={() => handleRemoveElement(index)}>
            Remove
          </Button>
        </Box>
      </Box>
    ));
  };
  const handleSubmit = () => {
    const formDataArray = [];
    formElements.forEach((element) => {
      const formData = {
        _id: generateRandomId(), // Function to generate a random ID
        answers: element.choices || [],
        questionType: getQuestionType(element.type),
        question: element.question,
      };
      formDataArray.push(formData);
    });
    const finalFormData = {
      _id: generateRandomId(), // Function to generate a random ID
      name: title,
      questions: formDataArray,
    };
    console.log('form data', finalFormData); //print submitted data
    setTitle('');
    setDescription('');
    setFormElements([]);
  };
  // Function to generate a random ID
  const generateRandomId = () => {
    debugger;
    const idLength = 24; // Specify the desired length of the ID
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let id = '';
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters.charAt(randomIndex);
    }
    return id;
  };

  // Function to get the question type based on the form element type
  const getQuestionType = (elementType) => {
    // debugger;
    if (elementType === 'text') {
      return 'text';
    } else if (elementType === 'dropdown') {
      return 'drop-down';
    } else if (elementType === 'radio') {
      return 'select';
    } else {
      return 'unknown';
    }
  };
  return (
    <>
      <Navbar />
      <Box pt={15} sx={{ textAlign: 'center' }}>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button onClick={handleAddTextField}>
            <TextFieldsIcon color="secondary" />
          </Button>
          <Button onClick={handleAddSelect}>
            <ArrowDropDownCircleIcon color="secondary" />
          </Button>
          <Button onClick={handleAddRadioGroup}>
            <RadioButtonCheckedIcon color="secondary" />
          </Button>
        </Box>
        <Box
          mt={4}
          ml={1}
          sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <FormControl sx={{ width: '800px' }}>
            <TextField
              sx={{
                '& label.Mui-focused': {
                  fontSize: '13px',
                  color: (theme) => theme.palette.palette.text.secondary,
                },
                '& .MuiInputLabel-root': {
                  fontSize: '13px',
                  color: (theme) => theme.palette.palette.text.secondary,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: (theme) =>
                    theme.palette.palette.text.secondary,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: (theme) =>
                      theme.palette.palette.text.secondary,
                  },
                  '&:hover fieldset': {
                    borderColor: (theme) =>
                      theme.palette.palette.text.secondary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: (theme) =>
                      theme.palette.palette.text.secondary,
                  },
                },
              }}
              variant="standard"
              fullWidth
              value={title}
              onChange={handleTitleChange}
              placeholder="Form Title" // Set the placeholder for the title input
            />
          </FormControl>
        </Box>
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          mt={2}
          ml={1}>
          <FormControl sx={{ width: '800px' }}>
            <TextField
              sx={{
                '& label.Mui-focused': {
                  fontSize: '13px',
                  color: (theme) => theme.palette.palette.text.secondary,
                },
                '& .MuiInputLabel-root': {
                  fontSize: '13px',
                  color: (theme) => theme.palette.palette.text.secondary,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: (theme) =>
                    theme.palette.palette.text.secondary,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: (theme) =>
                      theme.palette.palette.text.secondary,
                  },
                  '&:hover fieldset': {
                    borderColor: (theme) =>
                      theme.palette.palette.text.secondary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: (theme) =>
                      theme.palette.palette.text.secondary,
                  },
                },
              }}
              variant="standard"
              fullWidth
              multiline
              rows={2}
              value={description}
              placeholder="Form Description"
              onChange={handleDescriptionChange}
            />
          </FormControl>
        </Box>

        {renderFormElements()}
      </Box>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}>
        <Button
          sx={{
            borderRadius: '50px',
            fontFamily: 'Raleway',
            lineHeight: '18px',
            fontWeight: '800',
            fontSize: '16px',
            textTransform: 'none',
            padding: '5px 25px',
            color: '#000',
            backgroundColor: (theme) => theme.palette.palette.text.secondary,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.palette.text.secondary,
            },
          }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}>
          Submit
        </Button>
      </div>

      <Footer />
    </>
  );
};
export default CreateForm;
