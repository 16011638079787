import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Paper, Grid, Box, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import CxoButton from "../../../components/CxoButton/CxoButton";
import Stack from "@mui/material/Stack";
import CxoOtpTimer from "../../../components/CxoOtpTimer/CxoOtpTimer";
const Otp = (props) => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(true);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(4);

  const handleChange = (otp) => {
     setOtp(otp);
  };
  const handlecheckOtp = (e) => {
    //desired function to be performed on clicking resend button
    e.preventDefault();
    if(otp.length === 6){
    props.onCheckOtp(props.user, otp);
        setOtp("");
      }
  };
  const timerZero = () => {
    setTimer(false);
  };
  const resendOtp = () => {
    setTimer(true);
    props.resendOtp(props.user);
  };
  const handleKeyPress = () => {
       console.log( "You pressed a key." )
   }
  return (
    <Fragment>
      <Paper
        elevation={2}
        sx={{
          width: "375px",
          height: "450px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}

        >
          <Box py={2} sx={{ textAlign: "center" }}>
            <Typography variant="h2s" component="h2s">
              OTP
            </Typography>
          </Box>

          <Box py={3} sx={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              component="h5"
              sx={{ fontFamily: "sans-serif" }}
            >
              OTP sent to {props?.user?.username}
            </Typography>
          </Box>
            <form onSubmit={handlecheckOtp}>
          <Box py={3} sx={{ display: "flex", justifyContent: "center" }}>
            <OtpInput
              value={otp}
              onChange={handleChange}
              onKeyPress={(e) => handleKeyPress(e)}
              numInputs={6}
              inputStyle={{
                height: "40px",
                width: "39.92px",
                borderColor: "#38CD84",
                margin: "4px",
                color: "#262525",
                fontSize: "22px",
                borderRadius: "5px",
              }}
              focusStyle={{ borderColor: "#38CD84" }}
              isInputNum={true}
            />
          </Box>
          <Box py={1} sx={{ textAlign: "center" }}>
            <CxoOtpTimer resendOtp={props.resendOtp} timerzero={timerZero} />
          </Box>

          <Box py={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CxoButton onClick={handlecheckOtp} label="Verify" type="submit" />
          </Box>
         </form>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default React.memo(Otp);
