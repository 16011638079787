import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useStyles from './profilestyle';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import Divider from '@mui/material/Divider';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import InterestIcon from '../../assets/chooseurinterest';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Collapse from '@mui/material/Collapse';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CxoButton from '../../components/CxoButton/CxoButton';
import { alpha, styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import Skeleton from '@mui/material/Skeleton';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import * as jobsActions from '../../store/jobs';
import * as companyActions from '../../store/company';
import CompanyPostList from './CompanyPostList';
import CompanyJobList from './CompanyJobList';
import CompanyEventList from './CompanyEventList';
import CompanyCorporateMembership from './CompanyCorporateMembership';
import { dateTime } from '../../constants/app';
import moment from 'moment';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import * as articleActions from '../../store/article';
import * as clubsActions from '../../store/clubs';
import * as postActions from '../../store/post';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import * as messageActions from '../../store/message';
import PostWhite from '../../assets/Posts_White.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}>
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const CompanyDetails = (props) => {
  const classes = useStyles();
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(2);
  const [val, setVal] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [section, setSection] = React.useState('');
  const [tab, setTab] = React.useState(0);
  const [edit, setEdit] = React.useState(true);
  const [editsection, setEditsection] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sectiondata, setSectiondata] = React.useState({});
  const [showClubPost, setShowClub] = React.useState(false);
  const [trim, setTrim] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const handleMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuOpen = () => {
    // setAnchorEl(!anchorEl);
  };

  const handleaccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const showQrcode = async () => {
    if (user?.isVerified) {
      navigate(
        '/share/company/' +
          companydetails?.name?.split(' ').join('-') +
          '-lx' +
          companydetails?.id,
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const user = useSelector((state) => state?.user?.UserDetails);
  const userimage = useSelector((state) => state?.user?.UserProfileImage);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const userotherdetails = useSelector(
    (state) => state?.user?.UserOtherDetails,
  );

  const candidateProfile = useSelector(
    (state) => state?.jobs?.CandidateProfile,
  );
  const candidateAbout = useSelector(
    (state) => state?.jobs?.CandidateProfileAbout,
  );

  const userabouts = useSelector((state) => state?.user?.UserAbouts);

  const candidateUserDetails = useSelector(
    (state) => state?.jobs?.CandidateUserProfileDetails,
  );

  const candidateLoading = useSelector(
    (state) => state?.jobs?.candidateLoading,
  );

  const companydetails = useSelector((state) => state?.company?.companyDetails);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));
  useEffect(async () => {
    async function fetchCompanyData() {
      const id = params?.id?.split('-');
      const data = {
        company_page_id: id[id?.length - 1].substring(2),
      };
      const candidate = await dispatch(companyActions.companyDetails(data));
    }
    fetchCompanyData();
  }, []);

  useEffect(() => {
    async function getCompanyMembershipAccessList() {
      const id = params?.id?.split('-');
      const data = {
        company_page_id: parseInt(id[id?.length - 1]?.substring(2)),
      };
      const company = await dispatch(
        companyActions.getCompanyMembershipAccessList(data),
      );
    }
    getCompanyMembershipAccessList();
  }, []);

  useEffect(async () => {
    if (location.pathname.includes('posts')) {
      setVal(0);
    } else if (location.pathname.includes('jobs')) {
      setVal(1);
    } else if (location.pathname.includes('membership')) {
      setVal(2);
    } else if (location.pathname.includes('events')) {
      setVal(3);
    } else {
      setVal(0);
    }
  }, []);

  const changeTab = async (vall) => {
    if (user?.isVerified) {
      const id = params?.id;
      if (vall === 0) {
        navigate('/company/manage/posts/details/' + id);
        setVal(vall);
      } else if (vall === 1) {
        navigate('/company/manage/jobs/details/' + id);
        setVal(vall);
      } else if (vall === 2) {
        navigate('/company/manage/membership/details/' + id);
        setVal(vall);
      } else if (vall === 3) {
        navigate('/company/manage/events/details/' + id);
        setVal(vall);
      } else if (vall === 4) {
        navigate('/company/manage/posts/details/' + id);
        setVal(vall);
      }
    } else {
      // await dispatch(messageActions.openUnderVerification());
    }
  };
  const handleChange = (newValue) => {
    debugger
    setType(newValue);
  };
  const editProfile = () => {
    setEdit(!edit);
  };

  const editCompany = () => {};

  const companyEdit = async (e) => {
    e.stopPropagation();
    const data = {
      company_page_id: companydetails?.id,
    };
    const candidate = await dispatch(companyActions.companyDetails(data));
    navigate(
      '/company/edit/' + companydetails?.name + '-lx' + companydetails?.id,
    );
  };

  const openPost = async () => {
    setShowClub(true);
    // if (user?.isVerified) {
    //   const mypost = await dispatch(clubsActions.openPostEdit('club'));
    // } else {
    //   await dispatch(messageActions.openUnderVerification());
    // }
  };
  const seeMore = () => {
    if (!trim) {
      setTrim(!trim);
    } else {
      setTrim(!trim);
    }
  };
  const openPostCreate = async () => {
    setShowClub(false);
    if (user?.isVerified) {
      const mypost = await dispatch(clubsActions.openPostEdit('club'));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const openPoll = async () => {
    setShowClub(false);
    if (user?.isVerified) {
      const mypoll = await dispatch(postActions.openPollEdit());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const openArticle = async () => {
    setShowClub(false);
    if (user?.isVerified) {
      const myarticle = await dispatch(
        articleActions.openArticleCompany('club'),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const openJob = () => {
    navigate('/company/job/create');
  };
  const openEvent = () => {
    navigate('/company/event/create');
  };
  const editpub = (e, section) => {};

  const editSection = async (e, type, data) => {};
  const addNew = async (e, type) => {};
  const handleDelete = async () => {};
  const deleteSection = (e, type, data) => {};
  const handleClose = () => {
    setShowClub(false);
  };
  const saveFile = () => {
    if (candidateProfile?.resume) {
      saveAs(candidateProfile?.resume, 'profile.pdf');
    }
  };

  const manageAccess = () => {
    const id = params?.id;
    navigate('/company/manage/aceess/' + id);
  };
  const saveResume = (resume) => {
    if (resume) {
      saveAs(resume, 'profile.pdf');
    }
  };
  if (candidateLoading) {
    return (
      <Fragment>
        <Box p={1}>
          <Box sx={{ width: '100%' }}>
            <Paper
              sx={{
                backgroundColor: '#232121',
                backgroundImage: 'none',
                overflowX: 'auto',
              }}>
              <Box
                sx={{
                  height:
                    userotherdetails?.designation?.length +
                      userotherdetails?.company_name?.length >
                    30
                      ? '168px'
                      : '148px',
                  backgroundColor: '#2c2c2d',
                }}></Box>
              <Stack spacing={1} direction="column">
                <Box
                  px={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                    marginBottom: '-60px',
                  }}>
                  <Stack direction="row">
                    <Skeleton variant="circular" width={148} height={148} />
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                  }}>
                  <Skeleton variant="text" width={100} />
                </Box>

                <Box
                  pb={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-8px',
                    transform: ' translateY(-20%)',
                  }}>
                  <Stack spacing={0.5} direction="column" alignItems="center">
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                  </Stack>
                </Box>
              </Stack>
              <Box px={{ md: 4, xs: 2 }} py={2}>
                <Stack
                  spacing={2}
                  py={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Stack direction="column" spacing={1}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                  </Stack>
                </Stack>

                <Box py={2}>
                  <Skeleton variant="text" width={'100%'} />
                </Box>
                <Box py={2}>
                  <Skeleton variant="rectangular" width={'100%'} height={118} />
                </Box>
                <Box py={2}>
                  <Stack direction="row" spacing={5}></Stack>
                </Box>
                <Divider />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {showClubPost && (
          <Fragment>
            <Box
              sx={{
                width: '100%',
                maxWidth: '400',
                height: '100vh',
                position: 'fixed',
                zIndex: '3001',
              }}>
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showClubPost}>
                <Box
                  sx={{
                    width: '400px',
                    backgroundColor: '#333',
                    borderRadius: '10px',
                    height: '300px',
                  }}>
                  <Stack spacing={0} sx={{ width: '100%' }}>
                    <Stack spacing={0} direction="row">
                      <Box
                        px={3}
                        py={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            '@media (max-width: 500px)': {
                              fontSize: '14px',
                            },
                          }}>
                          Share Your Experience
                        </Typography>
                      </Box>
                      <Box
                        pl={3}
                        pr={1.5}
                        py={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                            cursor: 'pointer',
                          }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Stack>
                    <Stack direction="column" sx={{ padding: '16px' }}>
                      <Stack direction="row" sx={{ padding: '8px 12px' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openPostCreate}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                              width: '18px',
                            }}>
                            <img src={PostWhite} alt="" />
                          </Box>
                        </Box>

                        <Box py={0.5} px={2} onClick={openPostCreate}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{
                              fontSize: '15px',
                              cursor: 'pointer',
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                              },
                            }}>
                            Create a post
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider />

                      <Stack
                        direction="row"
                        sx={{ padding: '8px 6px', cursor: 'pointer' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openArticle}>
                          <Box
                            mt={0.4}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '15px',
                              maxWidth: 'min-content',
                              minWidth: '15px',
                              cursor: 'pointer',
                            }}>
                            <Menuicon name="article" color="#ffffff" />
                          </Box>
                        </Box>

                        <Box
                          py={0.5}
                          px={1}
                          onClick={openArticle}
                          sx={{ cursor: 'pointer' }}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontSize: '15px' }}>
                            Compose an article
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider />

                      <Stack
                        direction="row"
                        sx={{ padding: '8px 12px', cursor: 'pointer' }}
                        onClick={openPoll}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openPoll}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <Menuicon name="poll" color="#ffffff" />
                          </Box>
                        </Box>

                        <Box py={0.5} px={2} onClick={openPoll}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontSize: '15px' }}>
                            Create a poll
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider />

                      <Stack
                        direction="row"
                        sx={{ padding: '8px 12px', cursor: 'pointer' }}
                        onClick={openJob}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openJob}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <Menuicon name="designation" color="#ffffff" />
                          </Box>
                        </Box>

                        <Box py={0.5} px={2} onClick={openJob}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontSize: '15px' }}>
                            Create a job
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider />

                      <Stack
                        direction="row"
                        sx={{ padding: '8px 12px', cursor: 'pointer' }}
                        onClick={openEvent}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openEvent}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <Menuicon name="events" color="#ffffff" />
                          </Box>
                        </Box>

                        <Box py={0.5} px={2} onClick={openEvent}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontSize: '15px' }}>
                            Create an event
                          </Typography>
                        </Box>
                      </Stack>
                      <Box></Box>
                    </Stack>
                  </Stack>
                </Box>
              </Backdrop>
            </Box>
          </Fragment>
        )}
        <Box p={{ md: 1, xs: 0 }}>
          <Box sx={{ width: '100%' }}>
            <Paper
              sx={{
                backgroundColor: '#232121',
                backgroundImage: 'none',
                overflowX: 'auto',
              }}>
              <Box
                sx={{
                  height:
                    userotherdetails?.designation?.length +
                      userotherdetails?.company_name?.length >
                    30
                      ? '168px'
                      : '148px',
                  backgroundColor: '#2c2c2d',
                  '@media (max-width: 500px)': {
                    height:
                      userotherdetails?.designation?.length +
                        userotherdetails?.company_name?.length >
                      30
                        ? '100px'
                        : '100px',
                  },
                }}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                  <IconButton
                    aria-label="addfriend"
                    onClick={handleMenuOpen}
                    sx={{ margin: '16px' }}>
                    {/* <MoreVertIcon /> */}
                  </IconButton>
                  {anchorEl && (
                    <Box sx={{ marginLeft: '0px!important' }}>
                      <Box
                        anchorEl={anchorEl}
                        id="primary-search-account-menu"
                        keepMounted
                        sx={{
                          marginTop: '12px',
                          scrollbarWidth: 'thin',
                          position: 'absolute',
                          backgroundColor: '#2c2c2d',
                          height: 'auto',
                          width: '200px',
                          marginLeft: '-235px',
                          zIndex: '1000',
                          boxShadow:
                            '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                          borderRadius: '2px',
                        }}>
                        <Box>
                          <List>
                            <MenuItem
                              button
                              sx={{ padding: '0px 12px' }}
                              onClick={(e) => manageAccess(e)}>
                              <ListItemText>Manage Access</ListItemText>
                            </MenuItem>
                            <Divider />
                          </List>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Stack>
              </Box>

              <Stack spacing={1} direction="column">
                <Box
                  px={{ md: 2, xs: 0.5 }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                    marginBottom: '-60px',
                    '@media (max-width: 500px)': { marginBottom: '-30px' },
                  }}>
                  <Stack direction="row">
                    <Box pt={7} mr={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '48px',
                          maxWidth: 'min-content',
                          minWidth: '48px',
                          cursor: 'pointer',
                          backgroundColor: '#38cd84',
                          alignItems: 'center',
                          borderRadius: '50%',
                        }}
                        onClick={showQrcode}>
                        <QrCode2Icon sx={{ color: '#000000' }} />
                      </Box>
                    </Box>
                    <Stack direction="row">
                      <Avatar
                        src={
                          companydetails?.image
                            ? companydetails?.image
                            : PersonIcon
                        }
                        sx={{
                          width: 148,
                          height: 148,
                          border: '4px solid #38cd84',
                          '@media (max-width: 500px)': {
                            width: 110,
                            height: 110,
                          },
                        }}
                      />
                    </Stack>

                    <Box pt={7} ml={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '48px',
                          maxWidth: 'min-content',
                          minWidth: '48px',
                          cursor: 'pointer',
                        }}
                        onClick={companyEdit}>
                        <Menuicon width="43" name="editcircle" />
                      </Box>
                    </Box>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                  }}>
                  <Typography
                    variant="h2s"
                    component="h2s"
                    sx={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      '@media (max-width: 500px)': {
                        fontSize: '18px',
                      },
                    }}></Typography>
                </Box>

                <Box
                  pb={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-8px',
                    transform: ' translateY(-20%)',
                  }}>
                  <Stack
                    spacing={0.5}
                    direction="column"
                    alignItems="center"></Stack>
                </Box>
              </Stack>
              <Stack spacing={1} direction="column">
                {/* <Box
                  px={{ md: 2, xs: 0.5 }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                    marginBottom: '-60px',
                    '@media (max-width: 500px)': { marginBottom: '-30px' },
                  }}>
                  <Stack direction="row">
                    <Avatar
                      src={
                        companydetails?.image
                          ? companydetails?.image
                          : PersonIcon
                      }
                      sx={{
                        width: 148,
                        height: 148,
                        border: '4px solid #38cd84',
                        '@media (max-width: 500px)': {
                          width: 110,
                          height: 110,
                        },
                      }}
                    />
                  </Stack>
                </Box> */}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                  }}>
                  <Typography
                    variant="h2s"
                    component="h2s"
                    sx={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      paddingBottom: '-16px',
                      '@media (max-width: 500px)': {
                        fontSize: '22px',
                      },
                    }}>
                    {companydetails?.name
                      ? companydetails?.name?.charAt(0).toUpperCase() +
                        companydetails?.name?.slice(1)
                      : 'NA'}{' '}
                  </Typography>
                </Box>

                <Box
                  pb={3}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-64px',
                    transform: ' translateY(-20%)',
                  }}>
                  <Stack
                    spacing={{ md: 0.5, xs: 0.2 }}
                    direction="row"
                    alignItems="center"
                    sx={{ marginTop: '4px' }}>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{ marginTop: '0px' }}>
                      {companydetails?.tagline
                        ? companydetails?.tagline?.charAt(0).toUpperCase() +
                          companydetails?.tagline.slice(1)
                        : ''}
                    </Typography>
                  </Stack>
                </Box>

                <Box
                  pb={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-8px',
                    transform: ' translateY(-20%)',
                  }}>
                  <Stack
                    spacing={{ md: 5, xs: 0.2 }}
                    direction="row"
                    alignItems="center">
                    <Typography variant="h2" component="h2">
                      {companydetails?.industry_title
                        ? companydetails?.industry_title
                        : ''}
                    </Typography>
                    <Typography variant="h2" component="h2">
                      {companydetails?.min_employee
                        ? companydetails?.min_employee +
                          ' - ' +
                          companydetails?.max_employee +
                          ' employees'
                        : ''}
                    </Typography>

                    <Typography variant="h5" component="h5">
                      {companydetails?.registration_type
                        ? companydetails?.registration_type
                        : ''}
                    </Typography>
                  </Stack>
                </Box>

                <Box
                  pb={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-8px',
                    transform: ' translateY(-20%)',
                  }}>
                  <Stack
                    spacing={{ md: 0.5, xs: 0.2 }}
                    direction="row"
                    alignItems="center">
                    <Typography variant="h2s" component="h2s">
                      {companydetails?.domain_name
                        ? companydetails?.domain_name
                        : ''}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Box px={4} py={1}>
                {props?.post?.content?.length > 260 && trim
                  ? props?.post?.content?.substring(0, 260)
                  : props?.post?.content}
                <Typography variant="h2" component="h2">
                  {companydetails?.description?.length > 260 && trim
                    ? companydetails?.description?.substring(0, 260)
                    : companydetails?.description}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {companydetails?.description?.length > 260 && trim ? (
                    <Box
                      component="span"
                      sx={{
                        paddingLeft: '12px',
                        cursor: 'pointer',
                        color: '#38cd84',
                        // marginTop: "-20px",
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                          marginTop: '-20px',
                        },
                      }}
                      onClick={seeMore}>
                      {' '}
                      ...See more
                    </Box>
                  ) : (
                    ' '
                  )}
                  {companydetails?.description?.length > 260 && !trim ? (
                    <Box
                      component="span"
                      sx={{
                        paddingLeft: '12px',
                        cursor: 'pointer',
                        color: '#38cd84',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                          marginTop: '-20px',
                        },
                      }}
                      onClick={seeMore}>
                      {' '}
                      ...See less
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Paper>

            <Box>
              <Box px={0} py={1}>
                <Tabs
                  defaultValue={0}
                  value={val}
                  onChange={handleChange}
                  variant="fullWidth"
                  color="white"
                  aria-label="voucher">
                  <Paper
                    sx={{
                      backgroundColor: '#262525',
                      backgroundImage: 'none',
                    }}>
                    <StyledTabsList sx={{ padding: '8px 16px' }}>
                      <StyledTab>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          onClick={() => changeTab(0)}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{
                              color: val === 0 ? '#000000' : '#ffffff',
                            }}>
                            Posts
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabdigit}
                            sx={{
                              color: val === 0 ? '#000000' : '#ffffff',
                            }}></Typography>
                        </Stack>
                      </StyledTab>
                      <StyledTab>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          onClick={() => changeTab(1)}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{
                              color: val === 1 ? '#000000' : '#ffffff',
                            }}>
                            Jobs
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabdigit}
                            sx={{
                              color: val === 1 ? '#000000' : '#ffffff',
                            }}></Typography>
                        </Stack>
                      </StyledTab>
                      {location.pathname.includes('manage') && (
                        <StyledTab>
                          <Stack
                            direction="row"
                            spacing={0.5}
                            onClick={() => changeTab(2)}>
                            <Typography
                              variant="h5"
                              component="h5"
                              className={classes.tabname}
                              sx={{
                                color: val === 2 ? '#000000' : '#ffffff',
                              }}>
                              Membership
                            </Typography>
                            <Typography
                              variant="h5"
                              component="h5"
                              className={classes.tabdigit}
                              sx={{
                                color: val === 2 ? '#000000' : '#ffffff',
                              }}></Typography>
                          </Stack>
                        </StyledTab>
                      )}
                      <StyledTab>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          onClick={() => changeTab(3)}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{
                              color: val === 3 ? '#000000' : '#ffffff',
                            }}>
                            Events
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabdigit}
                            sx={{
                              color: val === 3 ? '#000000' : '#ffffff',
                            }}></Typography>
                        </Stack>
                      </StyledTab>

                      {location.pathname.includes('manage') && (
                        <StyledTab>
                          <Stack
                            direction="row"
                            spacing={0.5}
                            onClick={() => openPost()}>
                            <Typography
                              variant="h5"
                              component="h5"
                              className={classes.tabname}
                              sx={{
                                color: val === 4 ? '#000000' : '#ffffff',
                              }}>
                              Create
                            </Typography>
                            <Typography
                              variant="h5"
                              component="h5"
                              className={classes.tabdigit}
                              sx={{
                                color: val === 4 ? '#000000' : '#ffffff',
                              }}></Typography>
                          </Stack>
                        </StyledTab>
                      )}
                    </StyledTabsList>
                  </Paper>
                </Tabs>
              </Box>
              {val == 0 && <CompanyPostList />}
              {val == 1 && <CompanyJobList />}
              {val == 2 && <CompanyCorporateMembership />}
              {val == 3 && <CompanyEventList />}
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(CompanyDetails);

// <Typography
//   variant={type === "industryreport" ? "h2s" : "h2"}
//   component={type === "industryreport" ? "h2s" : "h2"}
// >
//   Report
// </Typography>

// <Typography variant="h2" component="h2">
//   {industry?.end_date} {industry?.end_month}{" "}
//   {industry?.end_year}
// </Typography>

//
// {userotherdetails?.id != user?.id && (
//   <Box py={2}>
//     <Stack direction="row" spacing={5}>
//       <Box>
//         <Stack direction="row" spacing={2}>
//           <RemoveRedEyeOutlinedIcon />
//           <Typography variant="h2" component="h2">
//             View Resume
//           </Typography>
//         </Stack>
//       </Box>
//       <Box>
//         <Stack direction="row" spacing={2}>
//           <FileDownloadOutlinedIcon />
//           <Typography variant="h2" component="h2">
//             Download Resume
//           </Typography>
//         </Stack>
//       </Box>
//     </Stack>
//   </Box>
// )}

//
// <Box pt={6} mr={6}>
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "48px",
//       maxWidth: "min-content",
//       minWidth: "48px",
//       cursor: "pointer",
//       alignItems: "center",
//       borderRadius: "50%",
//       backgroundColor: "#38CD84",
//     }}
//   >
//     <QrCode2Icon sx={{ color: "#000000" }} />
//   </Box>
// </Box>

//
// <Box pt={6} ml={6}>
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       borderRadius: "50%",
//       height: "48px",
//       maxWidth: "min-content",
//       minWidth: "48px",
//       cursor: "pointer",
//       backgroundColor: "#38CD84",
//     }}
//   >
//     <Menuicon width="43" color="000000" name="addfriend" />
//   </Box>
// </Box>
