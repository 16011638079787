import React, { Component } from 'react';
import LinkCXOLogo from '../../resources/new-img/White-Rectangle.png';
import { Container } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import TopIcon from "../resources/img/top.png";

class HeaderVentures extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  // handleJoin = () => {
  //   this.props.history.push("/Join");
  // };
  handleVentures = () => {
    // this.props.history.replace("/ventures");
  };

  handleContent = () => {
    // this.props.history.replace("/content");
  };

  handlehome = () => {
    // return <Redirect  to="/" /> ;Redirect
    // this.props.history.replace("/");
  };

  handleAbout = () => {
    // this.props.history.replace("/about-us");
  };

  handleJob = () => {
    // this.props.history.replace("/jobs");
  };

  handleEvent = () => {
    // this.props.history.push("/event");
  };

  handleBusiness = () => {
    // this.props.history.push("/business");
  };

  scroll(event) {
    var ele = document.getElementById(event.target.innerText);
    if (ele) {
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }
  }

  render() {
    return (
      <Container fluid id="no-padding">
        <Navbar
          collapseOnSelect
          expand="sm"
          variant="#1F1E1E"
          style={{ background: '#1F1E1E', marginBottom: '-9px' }}
          className="nav-style"
          onClick={this.scroll.bind(this)}>
          <Navbar.Brand
            style={{
              paddingBottom: '10px',
            }}
            className="logo-image-section">
            <Link to="/" className="header-link">
              <img
                src={LinkCXOLogo}
                alt=""
                className="logo-image-section-style"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
            style={{ marginRight: '60px' }}>
            <Nav className="justify-content-end">
              <Nav.Link className="nave-bar-menu-style">
                <Link to="/" className="nave-bar-menu-style-link">
                  About Us
                </Link>
              </Nav.Link>

              <Nav.Link className="nave-bar-menu-style">
                <Link to="/" className="nave-bar-menu-style-link">
                  Jobs
                </Link>
              </Nav.Link>

              <Nav.Link className="nave-bar-menu-style">
                <Link to="/" className="nave-bar-menu-style-link">
                  Events
                </Link>
              </Nav.Link>
              {/* <Nav.Link
                className="nave-bar-menu-style"
                id="4"
                onClick={this.handlehome}
              >
                Business
              </Nav.Link> */}

              {/* <Nav.Link
                eventKey={2}
                onClick={this.handleContent}
                className="nave-bar-menu-style"
              >
                Industry Updates
              </Nav.Link> */}

              {/* <Nav.Link
                className="nave-bar-menu-style"
                onClick={this.handlehome}
              >
                News & Media
              </Nav.Link> */}
              {/* <Nav.Link
                eventKey={2}
                href="#"
                style={{
                  fontSize: "14px",
                  marginRight: "40px",
                  marginBottom: "10px",
                }}
              >
                <button
                  className="button-events-style-section1"
                  onClick={this.handlehome}
                >
                  <Link to="/ventures" className="nave-bar-menu-style-link">
                    Ventures
                  </Link>
                </button>{" "}
              </Nav.Link> */}
              <Nav.Link
                eventKey={2}
                style={{
                  color: 'black',
                  fontSize: '14px',
                  marginRight: '45px',
                }}>
                <button className="become-a-member-btn">
                  <Link to="/login" className="nave-bar-join">
                    Login
                  </Link>
                </button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}

export default HeaderVentures;
