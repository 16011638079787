import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const EditExperience = (props) => {
  console.log('props', props);
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [file, setFile] = React.useState('');

  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(dayjs(new Date()));
  const [enddate, setEnddate] = React.useState(dayjs(new Date()));
  const [industrylst, setIndustrylst] = useState('');
  const [industrylstselect, setIndustrylstselect] = useState({});
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState('');
  const [functionlstselect, setFunctionlstselect] = useState({});
  const [stillworking, setStillworking] = React.useState(1);
  const designationList = useSelector((state) => state.onboard.designationList);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  console.log('industryList', industryList);
  console.log('functionList', functionList);

  const handleTabChange = (event, newValue) => {
    console.log('newValue', newValue)
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };

  const handledateendChange = (newValue) => {
    setEnddate(newValue);
  };
  useEffect(async () => {
    const designationlist = await dispatch(onboardActions.getDesignationList());
  }, []);
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);

  useEffect(() => {
    async function fetchData() {
      setDatevalue(dayjs(props?.section?.start_date)) 
    }
    fetchData();
  }, []);

  

  useEffect(() => {
    async function fetchData() {
      setEnddate(dayjs(props?.section?.end_date)) 
    }
    fetchData();
  }, []);

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  const handleChange = (newValue) => {
    setType(newValue);
  };

  const handleChangeIndustry = (event) => {
    const {
      target: { value },
    } = event;
    const obj = industryList?.data?.find((x) => x.id === event.target.value);
    setIndustrylst(obj.title);
    setIndustrylstselect(obj);
   };

  const handleStillWorking = (event) => {
    setStillworking(event.target.value);
  };

  const designatioChange = (event) => {
    setDesignationlst(event.target.value);
  };
  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    const obj = functionList?.data?.find((x) => x.id === event.target.value);
    setFunctionlst(obj.title);
    setFunctionlstselect(obj);
  };
  useEffect(() => {
    if (props?.section?.start_date) {
      var dateMomentObject =  dayjs(props?.section?.start_date);
      setDatevalue(dateMomentObject);
    }
  }, []);

  useEffect(() => {
    if (props?.section?.designation) {
      setDesignationlst(props?.section?.designation);
    }
  }, []);

  useEffect(() => {
    if (props?.section?.end_date) {
      var dateMomentObject = dayjs(props?.section?.end_date);
      setEnddate(dateMomentObject);
    }
  }, []);

  useEffect(() => {
    if (props?.section?.still_working) {
      setStillworking(props?.section?.still_working);
    }
  }, []);

  const closeEdit = () => {};

  const handleSubmitform = async (data) => {
    let industryid = industrylstselect?.id
      ? industrylstselect?.id
      : props?.section?.industry_id;
    let functionid = functionlstselect?.id
      ? functionlstselect?.id
      : props?.section?.function_id;
    const usereExp = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      company: data?.company,
      designation: designationlst,
      start_date: dayjs(datevalue).format('DD/MM/YYYY'),

      end_date: dayjs(enddate).format('DD/MM/YYYY'),

      still_working: stillworking,
      industry_id: JSON.stringify(industryid),
      function_id: JSON.stringify(functionid),
      location: data?.location,
      details: data?.details,
      platform: 'web',
    };

    const usereducation = await dispatch(
      userActions.manageExperience(usereExp),
    );
    const userdata = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(userdata));
    if (updateSection) {
      const snackbar = await dispatch(userActions.closeUpdateSectionProfile());
    }
    if (addNew) {
      const snackbaradd = await dispatch(userActions.closeUpdateProfile());
    }
  };
  return (
    <Fragment>
      <Box px={3} py={1} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center  ',
              width: '100%',
            }}>
            <form
              onSubmit={handleSubmit(handleSubmitform)}
              style={{
                width: '100%',
              }}>
              <Box pr={1} sx={{ textAlign: 'center', width: '99%' }}>
                <Stack spacing={1} direction="row" sx={{}}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Company"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="company"
                    defaultValue={
                      props?.section?.company ? props?.section?.company : ''
                    }
                  />
                </Stack>
              </Box>

              <Box px={1} py={1} sx={{ width: '100%' }}>
                <Stack spacing={1} direction="row">
                  <Box py={1} pl={1} sx={{ width: '95%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ color: '#fff' }}>
                        Designation
                      </InputLabel>
                      <Select
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={designationlst}
                        label="Industry List"
                        onChange={designatioChange}
                        size="small"
                        renderValue={(selected) => {
                          return selected;
                        }}>
                        {designationList?.data
                          ? designationList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.title}>
                                  <ListItemText
                                    color="secondary"
                                    primary={item.title}
                                  />
                                  <Checkbox
                                    color="secondary"
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    checked={
                                      designationlst.indexOf(item.title) > -1
                                    }
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Box>

              <Box px={1} sx={{ width: '100%' }}>
                <Stack spacing={1} direction="row">
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ color: '#fff' }}>
                        Industry
                      </InputLabel>
                      <Select
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industrylst}
                        label="Industry List"
                        onChange={handleChangeIndustry}
                        size="small"
                        color="secondary"
                        renderValue={(selected) => {
                          return selected;
                        }}>
                        {industryList?.data
                          ? industryList?.data.map((item, key) => {
                              return (
                                <MenuItem
                                  color="secondary"
                                  key={key}
                                  name={item.title}
                                  value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.substring(0, 25) + '...'
                                    }
                                  />
                                  <Checkbox
                                    color="secondary"
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    checked={industrylst === item.id}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Box>

              <Box px={1} sx={{ width: '100%' }}>
                <Stack spacing={1} direction="row">
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ color: '#fff' }}>
                        Function
                      </InputLabel>
                      <Select
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={functionlst}
                        label="Industry List"
                        onChange={functionChange}
                        size="small"
                        renderValue={(selected) => {
                          return selected;
                        }}>
                        {functionList?.data
                          ? functionList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.substring(0, 25) + '...'
                                    }
                                  />
                                  <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="secondary"
                                    checked={functionlst === item.id}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Box>

              <Box py={1} sx={{ width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="location"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="location"
                    defaultValue={
                      props?.section?.location ? props?.section?.location : ''
                    }
                  />
                </Stack>
              </Box>
              <Box py={1} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="Start Date" 
                            value={datevalue}
                            onChange={handledateChange}               
                          />
                        </DemoContainer>
                    </LocalizationProvider>
                </Stack>
              </Box>

              <Box py={1} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>

<LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="End Date" 
                            value={enddate}
                            onChange={handledateendChange}               
                          />
                        </DemoContainer>
                    </LocalizationProvider>
                </Stack>
              </Box>

              <Box py={1} px={2} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <FormControl>
                    <FormLabel
                      color="secondary"
                      id="demo-row-radio-buttons-group-label">
                      Still Working
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      color="secondary"
                      value={stillworking}
                      onChange={handleStillWorking}>
                      <FormControlLabel
                        value="1"
                        color="secondary"
                        control={<Radio color="secondary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        color="secondary"
                        control={<Radio color="secondary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              <Box py={0} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CssTextField
                        variant="standard"
                        fullWidth
                        multiline
                        rows={5}
                        maxRows={6}
                        value={value}
                        onChange={onChange}
                        color="secondary"
                        label="Details"
                      />
                    )}
                    name="details"
                    defaultValue={
                      props?.section?.details ? props?.section?.details : ''
                    }
                  />
                </Stack>
              </Box>

              <Box
                py={4}
                px={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CxoButton label="Add" type="submit" />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EditExperience);
