import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import BookmarksCard from '../../components/BookmarksCard/BookmarksCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as activityActions from '../../store/activity';
import { dateTime } from '../../constants/app';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));

const CssSelect = styled(Select)(({ theme }) => {
  return {
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.palette.text.default,
      color: theme.palette.palette.text.primary,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const Bookmarks = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [togglefilter, setTogglefilter] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState('All');
  const [filter, setFilter] = useState('All');

  const user = useSelector((state) => state?.user?.UserDetails);
  const bookmarkpostList = useSelector(
    (state) => state?.useractivity?.BookmarkpostList,
  );
  console.log('bookmarkpostList', bookmarkpostList);
  const bookmarkarticleList = useSelector(
    (state) => state?.useractivity?.BookmarkarticleList,
  );
  const bookmarknewsList = useSelector(
    (state) => state?.useractivity?.BookmarknewsList,
  );
  console.log('bookmarkarticleList', bookmarkarticleList);

  const bookmarkclubList = useSelector(
    (state) => state?.useractivity?.BookmarkclubList,
  );

  useEffect(async () => {
    const data = {
      user_id: user.id,
      type: 'article',
      datetime: dateTime(),
    };
    const bookmarks = await dispatch(
      activityActions.getBookmarkListArticle(data),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        user_id: user.id,
        type: 'club',
        datetime: dateTime(),
      };
      const bookmarks = await dispatch(
        activityActions.getBookmarkListClub(data),
      );
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      type: 'post',
      datetime: dateTime(),
    };
    const bookmarks = await dispatch(activityActions.getBookmarkListPost(data));
  }, []);

  // useEffect(async () => {
  //   const data = {
  //     user_id: user.id,
  //     type: "club",
  //       datetime: dateTime()
  //   };
  //   const bookmarks = await dispatch(activityActions.getBookmarkList(data));
  // }, []);
  //
  // useEffect(async () => {
  //   const data = {
  //     user_id: user.id,
  //     type: "event",
  //       datetime: dateTime()
  //   };
  //   const bookmarks = await dispatch(activityActions.getBookmarkList(data));
  // }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      type: 'news',
      datetime: dateTime(),
    };
    const bookmarks = await dispatch(activityActions.getBookmarkListNews(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleShare = (event) => {
    setFilter(event.target.value);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    console.log('selectedIndex', selectedIndex);
  };
  const toggleFilter = () => {
    setTogglefilter(!togglefilter);
  };
  return (
    <Fragment>
      <Box p={{ md: 2, xs: 0 }}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}>
              <Box
                py={2}
                px={{ md: 3, xs: 1 }}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  Bookmarks
                </Typography>
              </Box>
              <Box
                py={2}
                px={{ md: 3, xs: 0 }}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                {togglefilter && (
                  <Card
                    sx={{
                      height: '200px',
                      width: '120x',
                      marginLeft: '-80px',
                      marginTop: '185px',
                      borderRadius: '2px',
                      position: 'absolute',
                    }}>
                    <List aria-label="">
                      <ListItemButton
                        size="small"
                        sx={{ height: '36px' }}
                        selected={selectedIndex === 'All'}
                        onClick={(event) => handleListItemClick(event, 'All')}>
                        <ListItemText primary="All" />
                        {selectedIndex === 'All' ? (
                          <ListItemIcon sx={{ fontSize: 20, minWidth: '16px' }}>
                            <FiberManualRecordIcon
                              size="small"
                              color="secondary"
                              fontSize="8px"
                            />
                          </ListItemIcon>
                        ) : (
                          ''
                        )}
                      </ListItemButton>
                      <Divider />
                      <ListItemButton
                        sx={{ height: '36px' }}
                        selected={selectedIndex === 'Posts'}
                        onClick={(event) =>
                          handleListItemClick(event, 'Posts')
                        }>
                        <ListItemText primary="Posts" />
                        {selectedIndex === 'Posts' ? (
                          <ListItemIcon sx={{ fontSize: 20, minWidth: '16px' }}>
                            <FiberManualRecordIcon
                              size="small"
                              color="secondary"
                              fontSize="8px"
                            />
                          </ListItemIcon>
                        ) : (
                          ''
                        )}
                      </ListItemButton>
                      <Divider />
                      <ListItemButton
                        sx={{ height: '36px' }}
                        selected={selectedIndex === 'Articles'}
                        onClick={(event) =>
                          handleListItemClick(event, 'Articles')
                        }>
                        <ListItemText primary="Articles" />
                        {selectedIndex === 'Articles' ? (
                          <ListItemIcon sx={{ fontSize: 20, minWidth: '16px' }}>
                            <FiberManualRecordIcon
                              size="small"
                              color="secondary"
                              fontSize="8px"
                            />
                          </ListItemIcon>
                        ) : (
                          ''
                        )}
                      </ListItemButton>
                      <Divider />
                      <ListItemButton
                        sx={{ height: '36px' }}
                        selected={selectedIndex === 'news'}
                        onClick={(event) => handleListItemClick(event, 'news')}>
                        <ListItemText primary="news" />
                        {selectedIndex === 'news' ? (
                          <ListItemIcon sx={{ fontSize: 20, minWidth: '16px' }}>
                            <FiberManualRecordIcon
                              size="small"
                              color="secondary"
                              fontSize="8px"
                            />
                          </ListItemIcon>
                        ) : (
                          ''
                        )}
                      </ListItemButton>
                      <Divider />
                      <ListItemButton
                        sx={{ height: '36px' }}
                        selected={selectedIndex === 'clubs'}
                        onClick={(event) =>
                          handleListItemClick(event, 'clubs')
                        }>
                        <ListItemText primary="clubs" />
                        {selectedIndex === 'clubs' ? (
                          <ListItemIcon sx={{ fontSize: 20, minWidth: '16px' }}>
                            <FiberManualRecordIcon
                              size="small"
                              color="secondary"
                              fontSize="8px"
                            />
                          </ListItemIcon>
                        ) : (
                          ''
                        )}
                      </ListItemButton>
                    </List>
                  </Card>
                )}
                <Box px={2} onClick={toggleFilter} sx={{ cursor: 'pointer' }}>
                  <FilterAltOutlinedIcon />
                </Box>
              </Box>
            </Stack>
          </Paper>

          <Paper
            sx={{
              marginTop: '8px',
              padding: '16px',
              backgroundColor: '#232121',
              backgroundImage: 'none',
              '@media (max-width: 500px)': {
                padding: '8px',
              },
            }}>
            {selectedIndex === 'Articles' &&
              bookmarkpostList?.map((post, index) => {
                return <BookmarksCard key={index} list={post} />;
              })}

            {selectedIndex === 'Posts' &&
              bookmarkarticleList?.map((article, index) => {
                return <BookmarksCard key={index} list={article} />;
              })}

            {selectedIndex === 'news' &&
              bookmarknewsList?.map((news, index) => {
                return <BookmarksCard key={index} list={news} />;
              })}

            {selectedIndex === 'clubs' &&
              bookmarkclubList?.map((clubs, index) => {
                return <BookmarksCard key={index} list={clubs} type="clubs" />;
              })}

            {selectedIndex === 'All' &&
              bookmarkpostList?.map((post, index) => {
                return <BookmarksCard key={index} list={post} />;
              })}

            {selectedIndex === 'All' &&
              bookmarkarticleList?.map((article, index) => {
                return <BookmarksCard key={index} list={article} />;
              })}

            {selectedIndex === 'All' &&
              bookmarknewsList?.map((news, index) => {
                return <BookmarksCard key={index} list={news} />;
              })}

            {selectedIndex === 'All' &&
              bookmarkclubList?.map((clubs, index) => {
                return <BookmarksCard key={index} list={clubs} type="clubs" />;
              })}
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(Bookmarks);
