import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import CxoButton from '../../../components/CxoButton/CxoButton';
import Stack from '@mui/material/Stack';
import CxoInput from '../../../components/CxoInput/CxoInput';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import * as messageAction from '../../../store/message';
import * as onboardActions from '../../../store/onboard';
const AddBio = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bio, setBio] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const user = useSelector((state) => state?.auth?.user?.user_details);
  const userDetails = useSelector((state) => state?.user?.UserDetails);

  useEffect(() => {
    async function fetchData() {
      const interestlist = await dispatch(onboardActions.getUserInterestList());
    }
    fetchData();
  }, []);
  const onChange = (event) => {
    if (bio.length < 1000) {
      setBio(event.target.value);
    } else if (event.target.value.length < bio.length) {
      setBio(event.target.value);
    }
  };
  const onChangelinkedin = (event) => {
    setLinkedin(event.target.value);
  };

  var redirect_uri = window.location.href.split('redirect=')[1];
  const onSubmitbio = async () => {
    // if(linkedin.length === 0 || linkedin.includes('linkedin.com')){

    //   if (bio.length > 0 ) {
    //     const data = { user_id: user.id, about: bio, linkedin_url:  linkedin};
    //     const addbio = await dispatch(onboardActions.postBio(data));
    //     if (addbio?.status === 1) {
    //       if (redirect_uri != undefined) {
    //         navigate(
    //           "/addinterest?sign_up_through_page=job_apply&redirect=" +
    //             redirect_uri
    //         );
    //       } else {
    //         navigate("/addinterest");
    //       }
    //     }
    //   }
    //   else{
    //     const msg = 'Please add Bio ';
    //     const sendmessage = await dispatch(
    //       messageAction.messagefromComponent(msg, "error")
    //     );

    //   }
    // }
    // else {
    //   if(!linkedin.includes('linkedin.com')){
    //   const msg = 'Enter a valid linkedin url';
    //   const sendmessage = await dispatch(
    //     messageAction.messagefromComponent(msg, "error")
    //   );
    // }
    // }
    if (bio.length > 0) {
      const data = {
        user_id: user.id,
        about: bio,
        linkedin_url: userDetails?.linkedin_url,
      };
      const addbio = await dispatch(onboardActions.postBio(data));
      if (addbio?.status === 1) {
        if (redirect_uri != undefined) {
          navigate(
            '/addinterest?sign_up_through_page=job_apply&redirect=' +
              redirect_uri,
          );
        } else {
          navigate('/addinterest');
        }
      }
    }
  };
  return (
    <Fragment>
      <Paper
        elevation={2}
        sx={{
          width: { xs: '80%', md: '1000px' },
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'start',
            flexDirection: 'column',
          }}>
          <Box
            py={4}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Typography
              variant="h2s"
              component="h2s"
              sx={{ fontWeight: '600' }}>
              Add Bio
            </Typography>
          </Box>
          <Divider variant="middle" sx={{ width: '100%', margin: '0' }} />
          <Box
            py={0.5}
            px={2}
            sx={{ display: 'flex', justifyContent: 'center', width: '95%' }}>
            {/* 
            <TextField
              variant="standard"
              fullWidth
              rows={1}
              value={linkedin}
              placeholder="Enter Linkedin URL"
              onChange={onChangelinkedin}
              InputProps={{
                disableUnderline: true,
              }}
            /> */}
          </Box>

          {/* <Divider variant="middle" sx={{ width: "100%", margin: "0" }} /> */}
          <Box
            py={0}
            px={2}
            sx={{ display: 'flex', justifyContent: 'center', width: '95%' }}>
            <TextField
              variant="standard"
              fullWidth
              multiline
              rows={12}
              maxRows={20}
              value={bio}
              placeholder="Start typing here..."
              onChange={onChange}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
          <Divider variant="middle" sx={{ width: '100%', margin: '0' }} />
          <Grid container alignItems="flex-end" sx={{ alignItems: 'center' }}>
            <Grid
              item
              xs
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '24px',
              }}>
              <Typography gutterBottom variant="h6" component="h6">
                {bio.length} / 1000
              </Typography>
            </Grid>
            <Grid item>
              <Box
                py={3}
                pr={5}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <CxoButton onClick={onSubmitbio} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default React.memo(AddBio);

// linkedin.length === 0 || linkedin.includes('linkedin.com')
