import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';

function CompanyLogoPage3() {
  return (
    <>
      <Container fluid className="company-logo">
        <Row>
          <Col>
            {' '}
            <h2 className="color-green">LinkCxO Users From Top Companies</h2>
          </Col>
        </Row>
        <Row style={{ padding: '0px 100px ' }}>
          <Col>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/volvo.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Jaguar Land Rover India.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Maruti Suzuki.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Mercedes Benz India.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Ola Electric Mobility.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Panasonic India Pvt Ltd.png"
                  />
                </div>
              </Col>

              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Reliance Nippon Life.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Times of India Group.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Uber.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Vodafone idea limited.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Volkswagen.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Zee Media Corp.Ltd_.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Revolt motors.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/HSBC.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Deloitte.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Royal Enfield.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Samsung India Electronics Pvt Ltd.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Schlumberger.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Syska Led Lights Pvt Ltd.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Tata Consultancy Services.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Tata Motors Limited.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/The Coca-Cola Company.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Harley Davidson India.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Jio Platforms Limited.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/NIVEA India.png"
                  />
                </div>
              </Col>

              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Scania CV.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Udaan.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Nissan Motors India Pvt Ltd_.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/WELLS FARGO.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/OLX India Pvt Ltd.png"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CompanyLogoPage3;
