import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardLong from '../../components/CardLong/CardLong';
import CardSurvey from '../../components/CardLong/CardSurvey';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { dateTime } from '../../constants/app';
import { useNavigate } from 'react-router-dom';
import * as postActions from '../../store/post';
import * as authActions from '../../store/auth';
const HomePost = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(30);
  const [surveyPost, setSurveyPost] = useState("")
  const user = useSelector((state) => state?.auth?.user?.user_details);
  const ispostList = useSelector((state) => !!state?.post?.PostList);
  const postList = useSelector((state) => state?.post?.PostList);
  const postStartindex = useSelector((state) => state?.post?.postStartIndex);
  const postEndindex = useSelector((state) => state?.post?.postEndIndex);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: 0,
      start_item: parseInt(startItem),
      item_count: parseInt(itemCount),
      datetime: dateTime(),
    };

    const formData = new FormData();
    formData.append('user_id', user?.id);
    formData.append('start_item', startItem);
    formData.append('item_count', itemCount);
    formData.append('datetime', dateTime());

    const postlist = await dispatch(postActions.getPostList(formData, data));
  }, []);
  const updatePost = async () => {
    const data = {
      user_id: user?.id,
      start_item: parseInt(startItem),
      item_count: parseInt(postEndindex),
      datetime: dateTime(),
    };

    const formData = new FormData();
    formData.append('user_id', user?.id);
    formData.append('start_item', startItem);
    formData.append('item_count', itemCount);
    formData.append('datetime', dateTime());

    const postlist = await dispatch(postActions.getPostList(formData, data));
  };

  const loadMore = async () => {
    const data = {
      user_id: user?.id,
      start_item: parseInt(postStartindex),
      item_count: parseInt(postEndindex) + 10,
      datetime: dateTime(),
    };
    const formData = new FormData();
    formData.append('user_id', user?.id);
    formData.append('start_item', postStartindex);
    formData.append('item_count', postEndindex + 10);
    formData.append('datetime', dateTime());
    const postlist = await dispatch(postActions.getPostList(formData, data));
  };
  return (
    <Fragment>
      <Box sx={{ paddingTop: { xs: '8px', md: '64px' } }}>
        <InfiniteScroll
          dataLength={postList?.length ? postList?.length : 10}
          next={() => loadMore()}
          hasMore={true}
          loader={
            <Box
              sx={{
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={loadMore}>
              <CircularProgress
                color="secondary"
                size={20}
                sx={{ marginTop: '10px' }}
              />
            </Box>
          }
          height={'calc(100vh - 75px)'}
          endMessage={
            <Box
              sx={{
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={loadMore}>
              <CircularProgress
                color="secondary"
                size={20}
                sx={{ marginTop: '10px' }}
              />
            </Box>
          }>
          {postList?.map((post, index) =>{ 
            if(post?.type == 'survey'){
              return(
                <Box  pt={1} px={1}>
                  <CardSurvey post={post} updatePost={updatePost}  />
               </Box>
              )
            }
            else if(post?.type == 'query'){
              return(
                <Box  pt={1} px={1}>
                  <CardSurvey post={post} updatePost={updatePost}  />
               </Box>
              )
            }
            else{
            return(
            <Box key={index} pt={1} px={1}>
              <CardLong post={post} updatePost={updatePost} />
            </Box>
          )
            }
          }
          )}
        </InfiniteScroll>
      </Box>
    </Fragment>
  );
};

export default React.memo(HomePost);

//
// <Box sx={{height:"40px", display:"flex", justifyContent:"center", alignItems:"center", cursor:"pointer"}} onClick={loadMore}>
//   <CachedOutlinedIcon color="secondary" />
// </Box>
