import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import MenuItem from '@mui/material/MenuItem';
import CxoButton from '../../components/CxoButton/CxoButton';
import OtpInput from 'react-otp-input';
import CloseIcon from '@mui/icons-material/Close';
import * as messageAction from '../../store/message';

import Select from '@mui/material/Select';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';
import * as userActions from '../../store/user';
import * as authActions from '../../store/auth';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));

const CssSelect = styled(Select)(({ theme }) => {
  return {
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.palette.text.default,
      color: theme.palette.palette.text.primary,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const Settings = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [country, setCountry] = React.useState('91');
  const [mobile, setMobile] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [showOtp, setShoOtp] = React.useState(false);
  const [type, setType] = React.useState('');
  const countryChange = (event) => {
    setCountry(event.target.value);
  };
  const user = useSelector((state) => state?.user?.UserDetails);

  const countryList = useSelector((state) => state?.onboard?.countryCodeList);

  useEffect(async () => {
    const countryList = await dispatch(onboardActions.getCountryCodeList());
    if (user?.mobile) {
      setMobile(user?.mobile);
    }

    if (user?.email) {
      setEmail(user?.email);
    }
  }, []);

  const handleChange = (event, newValue) => {
    console.log('newValue', event, newValue);
    setOtp(event);
  };
  const handleChangeemail = (e) => {
    console.log('handleChangeemail', handleChangeemail);
    setEmail(e.target.value);
  };
  const handleChangemobile = (e) => {
    if (e.target.value.length <= 10) {
      setMobile(e.target.value);
    }
  };

  const handleKeyPress = () => {
    console.log('You pressed a key.');
  };
  const closeOtp = () => {
    setShoOtp(false);
  };

  const handlecheckOtp = async (e) => {
    e.stopPropagation();
    if (type == 'email') {
      const data = {
        username: email,
        user_id: user.id,
        otp: otp,
      };
      const sendotp = await dispatch(userActions.updateEmail(data));
      const datauser = {
        id: user?.id,
      };
      const usersabout = await dispatch(userActions.userProfileDetails(data));
      setEmail(usersabout?.data?.email);
      setShoOtp(false);
    } else if (type == 'mobile') {
      const data = {
        username: mobile,
        user_id: user.id,
        otp: otp,
      };
      const sendotp = await dispatch(userActions.updateMobile(data));
      const datauser = {
        id: user?.id,
      };
      const usersabout = await dispatch(userActions.userProfileDetails(data));
      setMobile(usersabout?.data?.mobile);
      setShoOtp(false);
    }
  };

  const sendOtp = async () => {
    if (user?.mobile != mobile) {
      const datamobile = {
        mobile: mobile,
        user_id: user.id,
        email: '',
      };
      const userUpdate = await dispatch(
        authActions.checkUserNameNew(datamobile),
      );
      setType('mobile');
      if (userUpdate.status == 1) {
        const sendotpdata = {
          username: mobile,
          country_code: '+91',
        };
        const sentOtp = await dispatch(
          authActions.sendEmailOtpUpdate(sendotpdata),
        );
        if (sentOtp?.status == 1) {
          setShoOtp(true);
        }
      } else {
        const msg = "  Country Code and Mobile No doesn't match";
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(userUpdate?.message, 'error'),
        );
      }
    }
    if (user?.email != email) {
      setType('email');
      const datamobile = {
        mobile: '',
        user_id: user.id,
        email: email,
      };
      const userUpdate = await dispatch(
        authActions.checkUserNameNew(datamobile),
      );
      if (userUpdate.status == 1) {
        const sendotpdata = {
          username: email,
          country_code: '+91',
        };
        const sentOtp = await dispatch(
          authActions.sendEmailOtpUpdate(sendotpdata),
        );
        if (sentOtp?.status == 1) {
          setShoOtp(true);
        }
      } else {
        const msg = "  Country Code and Mobile No doesn't match";
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(userUpdate?.message, 'error'),
        );
      }
    }
  };

  return (
    <Fragment>
      {showOtp && (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            zIndex: '3001',
          }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showOtp}>
            <Box
              sx={{
                width: '80%',
                backgroundColor: '#1c1c1c',
                borderRadius: '10px',
                maxWidth: '700px',
              }}>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Box
                  py={3}
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    marginLeft: '8px',
                  }}>
                  <Typography variant="h2s" component="h2s">
                    OTP
                  </Typography>
                </Box>
                <Box
                  px={3}
                  pt={0}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                  }}>
                  <CloseIcon
                    sx={{
                      color: '#ffffff',
                      cursor: 'pointer',
                      fontSize: '24',
                    }}
                    onClick={closeOtp}
                  />
                </Box>
              </Stack>
              <form onSubmit={handlecheckOtp}>
                <Box py={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    onKeyPress={(e) => handleKeyPress(e)}
                    numInputs={6}
                    inputStyle={{
                      height: '40px',
                      width: '39.92px',
                      borderColor: '#38CD84',
                      margin: '4px',
                      color: '#262525',
                      fontSize: '22px',
                      borderRadius: '5px',
                    }}
                    focusStyle={{ borderColor: '#38CD84' }}
                    isInputNum={true}
                  />
                </Box>

                <Box py={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CxoButton label="Verify" type="submit" />
                </Box>
              </form>
            </Box>
          </Backdrop>
        </Box>
      )}
      <Box p={2}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}>
              <Box
                py={2}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  Settings
                </Typography>
              </Box>
            </Stack>
          </Paper>

          <Paper
            sx={{
              marginTop: '8px',
              padding: '16px',
              backgroundColor: '#232121',
              backgroundImage: 'none',
            }}>
            <Stack
              direction="column"
              sx={{
                margin: 'auto',
                width: '405px',
                '@media (max-width: 500px)': {
                  width: '305px',
                },
              }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}>
                <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <EmailOutlinedIcon sx={{ color: '#38CD84' }} />
                    </Box>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <CxoInput
                      label="Email ID"
                      value={email ? email : ''}
                      onChange={handleChangeemail}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}>
                <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <PhoneAndroidOutlinedIcon sx={{ color: '#38CD84' }} />
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <CssSelect
                      value={country}
                      onChange={countryChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      size="small">
                      {countryList?.data ? (
                        countryList?.data.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.phone_code}>
                              {' '}
                              {item.iso3 + ' ' + '+ ' + item.phone_code}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem key={1} value="+91">
                          +91
                        </MenuItem>
                      )}
                    </CssSelect>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <CxoInput
                      label="Mobile No"
                      value={mobile ? mobile : ''}
                      onChange={handleChangemobile}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Box
                  py={3}
                  px={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}>
                  <CxoButton label="Update" onClick={sendOtp} />
                </Box>
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(Settings);
