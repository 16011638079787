import React, { useEffect, Fragment } from 'react';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import * as companyActions from '../../store/company';
import { dateTime } from '../../constants/app';
import CardClub from '../../components/CardLong/CardClub';
import CxoButton from '../../components/CxoButton/CxoButton';
import JobCard from '../../components/JobCard/JobCard';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles } from '@mui/styles';
import JobCardSkeleton from '../../components/Skeleton/JobCardSkeleton';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const CompanyJobList = (props) => {
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('open');
  const closedJob = useSelector((state) => state?.jobs?.JobListClosed);
  const [noofOpenjob, setNoofOpenjob] = React.useState(4);
  const [noofInactivejob, setNoofInactivejob] = React.useState(4);
  const [noofClosejob, setNoofClosejob] = React.useState(4);
  const [pageClosejob, setpageClosejob] = React.useState(0);
  const [pageOpenjob, setpageOpenjob] = React.useState(0);
  const [pageInactivejob, setpageInactivejob] = React.useState(0);
  const user = useSelector((state) => state?.user?.UserDetails);
  const recommendedLoading = useSelector(
    (state) => state?.jobs?.recommendedLoading,
  );
  const apliedLoading = useSelector((state) => state?.jobs?.apliedLoading);
  const savedJob = useSelector((state) => state?.jobs?.JobListSaved);
  const savedLoading = useSelector((state) => state?.jobs?.savedLoading);

  const companyOpenJobList = useSelector(
    (state) => state?.company?.companyOpenJobList,
  );

  const companyClosedJobList = useSelector(
    (state) => state?.company?.companyClosedJobList,
  );

  const companyInactiveJobList = useSelector(
    (state) => state?.company?.companyInactiveJobList,
  );

  const jobListLoading = useSelector((state) => state?.jobs?.jobListLoading);

  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_page: id[id?.length - 1]?.substring(2),
        status: 'open',
        page_no: 0,
        hired_by: 'user',
      };
      const users = await dispatch(companyActions.getCompanyOpenJobList(data));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_page: id[id?.length - 1]?.substring(2),
        status: 'inactive',
        page_no: 0,
        hired_by: 'user',
      };
      const users = await dispatch(
        companyActions.getCompanyInactiveJobList(data),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_page: id[id?.length - 1]?.substring(2),
        status: 'closed',
        page_no: 0,
        hired_by: 'user',
      };
      const users = await dispatch(
        companyActions.getCompanyClosedJobList(data),
      );
    }
    fetchData();
  }, []);

  const seeMoreInactive = () => {
    if (companyInactiveJobList?.length > noofInactivejob) {
      setNoofInactivejob(companyInactiveJobList?.length);
    } else if (companyInactiveJobList?.length === noofInactivejob) {
      setNoofInactivejob(4);
    }
  };
  const seeMoreClose = () => {
    if (companyClosedJobList?.length > noofClosejob) {
      setNoofClosejob(companyClosedJobList?.length);
    } else if (companyClosedJobList?.length === noofClosejob) {
      setNoofClosejob(4);
    }
  };

  const seeMoreOpenJob = async () => {
    const id = params?.id?.split('-');
    const data = {
      user_id: user?.id,
      user_table_page: id[id?.length - 1]?.substring(2),
      status: 'open',
      page_no: 0,
      hired_by: 'user',
    };
    const users = await dispatch(companyActions.getCompanyOpenJobList(data));
    setpageOpenjob(pageOpenjob + 1);
  };

  const seeMoreInactiveJob = async () => {
    const id = params?.id?.split('-');
    const data = {
      user_id: user?.id,
      user_table_page: id[id?.length - 1]?.substring(2),
      status: 'inactive',
      page_no: 0,
      hired_by: 'user',
    };
    const users = await dispatch(
      companyActions.getCompanyInactiveJobList(data),
    );
    setpageInactivejob(pageInactivejob + 1);
  };

  const seeMoreCloseJob = async () => {
    const id = params?.id?.split('-');
    const data = {
      user_id: user?.id,
      user_table_page: id[id?.length - 1]?.substring(2),
      status: 'closed',
      page_no: 0,
      hired_by: 'user',
    };
    const users = await dispatch(companyActions.getCompanyClosedJobList(data));
    setpageClosejob(pageClosejob + 1);
  };

  const handleChange = (event, newValue) => {
    if (newValue === 'open') {
      // navigate('/jobs/hire');
      setValue(newValue);
    } else if (newValue === 'inactive') {
      // navigate('/jobs/inactive');
      setValue(newValue);
    } else if (newValue === 'closed') {
      // navigate('/jobs/closed');
      setValue(newValue);
    } else {
      // navigate('/jobs/hire');
      setValue(newValue);
    }
  };

  const createJob = () => {
    navigate('/company/job/create');
  };
  return (
    <>
      <Fragment>
        <Box px={0} id="recommended">
          {/* <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant="fullWidth"
            sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tab
              value="open"
              label={
                <Box
                  py={1}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant="h5s"
                    component="h5s"
                    sx={{
                      fontWeight: 400,
                      textTransform: 'none',
                      color: value === 'open' ? '#38CD84' : '#ffffff',
                    }}>
                    Open{' '}
                    {companyOpenJobList?.length > 0
                      ? '(' + companyOpenJobList[0].full_count + ')'
                      : ''}
                  </Typography>
                </Box>
              }
            />
            <Tab
              value="inactive"
              label={
                <Box
                  py={1}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant="h5s"
                    component="h5s"
                    sx={{
                      fontWeight: 400,
                      textTransform: 'none',
                      color: value === 'inactive' ? '#38CD84' : '#ffffff',
                    }}>
                    Inactive{' '}
                    {companyInactiveJobList?.length > 0
                      ? '(' + companyInactiveJobList[0].full_count + ')'
                      : ''}
                  </Typography>
                </Box>
              }
            />
            <Tab
              value="closed"
              label={
                <Box
                  py={1}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant="h5s"
                    component="h5s"
                    sx={{
                      fontWeight: 400,
                      textTransform: 'none',
                      color: value === 'closed' ? '#38CD84' : '#ffffff',
                    }}>
                    Closed{' '}
                    {companyClosedJobList?.length > 0
                      ? '(' + companyClosedJobList[0].full_count + ')'
                      : ''}
                  </Typography>
                </Box>
              }
            />
          </Tabs> */}

          <Box
            sx={{
              marginTop: '8px',
            }}>
            {jobListLoading ? (
              <JobCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={
                      companyOpenJobList?.length
                        ? companyOpenJobList?.length
                        : 0
                    }
                    next={() => seeMoreOpenJob()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreOpenJob}></Box>
                    }
                    height={
                      companyOpenJobList?.length > 2
                        ? 'calc(90vh - 70px)'
                        : 'auto'
                    }
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreOpenJob}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {companyOpenJobList?.length > 0 &&
                        companyOpenJobList?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <JobCard joblist={list} type="search" />
                            </Grid>
                          );
                        })}
                      {companyOpenJobList?.length == 0 && (
                        <Box pl={3} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            Currently there are no open jobs.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Fragment>
    </>
  );
};

export default React.memo(CompanyJobList);
