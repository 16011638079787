import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("md")]: {
      height: "50px",
    },
    [theme.breakpoints.up("md")]: {
      height: "72px",
      paddingLeft: "32px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "72px",
      paddingLeft: "72px",
    },
  },
  outergrid: {
    paddingLeft: "80px",
    "@media (max-width: 500px)": {
      paddingLeft: "0px",
    },
  },
  outergrid: {
    paddingLeft: "48px",
    "@media (max-width: 500px)": {
      paddingLeft: "0px",
    },
  },
  leftgrid: {
    marginTop: "4px",
  },
  rightgrid: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "8px",
    marginTop: "4px",
  },
  accordionmain: {
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0)",
    backgroundColor: "#232121",
    backgroundImage: "none",
    paddingLeft: "24px",
    paddingRight: "24px",
    "@media (max-width: 500px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  chipmain: {
    marginLeft: "8px",
    marginRight: "8px",
    marginTop: "0px",
    marginBottom: "8px",
    color: "#FFFFFF",
    "@media (max-width: 500px)": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  outergridyoe: {
    paddingLeft: "64px",
    paddingBottom: "16px",
    "@media (max-width: 500px)": {
      paddingLeft: "0px",
    },
  },
  editicon: {
    display: "flex",
    justifyContent: "center",
    height: "24px",
    maxWidth: "min-content",
    minWidth: "16px",
    width: "16px",
    alignItems: "center",
  },
  editiconsec: {
    display: "flex",
    justifyContent: "center",
    height: "24px",
    maxWidth: "min-content",
    minWidth: "16px",
    width: "16px",
    alignItems: "center",
    marginTop: "8px",
    cursor: "pointer",
  },
  headingtab: {
    fontWeight: 600,
  },
}));
