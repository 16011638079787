import {
  CLUB_HOSTED_SUCCESS,
 } from './actions';

export const clubsreducer = (
  state = {
    loading: false,
    clubUpcomingList: [],
    clubDiscoverList: [],
    clubInvitedList: [],
    clubRequestedList: [],
    clubSavedUpcomingList: [],
    clubSavedPassedList: [],
    clubHostedList: [],
    myClubList: [],
    savedClubList: [],
    clubDetails: {},
    clubInvitedMembers: [],
    clubMembers: [],
    clubAttendingMembers: [],
    clubRequestMembers: [],
    clubPostList: [],
    clubStatusUser: {},
    ClubShare: false,
    ShareClubDetails: {},
    clubSendInvitees: false,
    PostEdit: false,
    SendInviteesClubDetails: {},
    clubDetailstoOpen: {},
    clubEdit: {},
    clubs: {},
    clubEventList: [],
    clubMembersFriend: [],
    clubDetailsloading: false,
  },
  action,
) => {
  switch (action.type) {
    case 'DISCOVER_CLUB_LIST_REQUEST':
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case 'DISCOVER_CLUB_LIST_SUCCESS':
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          loading: false,
          errors: {},
          clubDiscoverList: action.payload.clubs.data,
        };
      } else {
        return {
          ...state,
          clubDiscoverList: [
            ...state.clubDiscoverList,
            ...action.payload.clubs.data,
          ],
          loading: false,
          errors: {},
        };
      }
    case CLUB_HOSTED_SUCCESS:
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          loading: false,
          errors: {},
          clubHostedList: action.payload.clubs.data,
        };
      } else {
        return {
          ...state,
          clubHostedList: [
            ...state.clubHostedList,
            ...action.payload.clubs.data,
          ],
          loading: false,
          errors: {},
        };
      }
    case 'INVITED_CLUB_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubInvitedList: action.payload.data,
      };
    case 'REQUESTED_CLUB_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubRequestedList: action.payload.data,
      };
    case 'CLUB_STATUS_FOR_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubStatusUser: action.payload.data,
      };
    case 'CLUB_STATUS_FOR_USER_REQUEST':
      return {
        ...state,
        loading: false,
        errors: {},
        clubStatusUser: {},
      };
    case 'SAVED_CLUB_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        savedClubList: action.payload.data,
      };
    case 'SAVED_CLUB_PASSED_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubSavedPassedList: action.payload.data,
      };
    case 'SAVED_CLUB_UPCOMING_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubSavedUpcomingList: action.payload.data,
      };
    case 'CLUB_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubDetailsloading: false,
        clubDetails: action.payload.data,
      };
    case 'CLUB_DETAILS_FAILURE':
      return {
        ...state,
        loading: false,
        errors: {},
        clubDetailsloading: false,
      };

    case 'CLUB_DETAILS_REQUEST':
      return {
        ...state,
        loading: false,
        errors: {},
        clubDetailsloading: true,
      };
    case 'CLUB_MEMBER_FRIEND_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubMembersFriend: action.payload.data,
      };
    case 'CLUB_EVENT_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubEventList: action.payload.data,
      };
    case 'CLUB_INVITED_MEMBERS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubInvitedMembers: action.payload.data,
      };
    case 'CLUB_REQUESTED_MEMBERS_REQUEST':
      return {
        ...state,
        loading: false,
        errors: {},
        clubRequestMembers: [],
      };
    case 'CLUB_REQUESTED_MEMBERS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubRequestMembers: action.payload.data,
      };
    case 'CLUB_ATTENDING_MEMBERS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubAttendingMembers: action.payload.data,
      };
    case 'CLUB_MEMBERS_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        clubMembers: action.payload.data,
      };
    case 'CLOSE_SHARE_CLUB': {
      return {
        ...state,
        ClubShare: false,
        ShareClubDetails: {},
      };
    }
    case 'OPEN_SHARE_CLUB': {
      return {
        ...state,
        ClubShare: true,
        ShareClubDetails: action.payload,
      };
    }
    case 'OPEN_SEND_INVITEES_CLUB': {
      return {
        ...state,
        clubSendInvitees: true,
        SendInviteesClubDetails: action.payload,
      };
    }
    case 'CLOSE_SEND_INVITEES_CLUB': {
      return {
        ...state,
        clubSendInvitees: false,
        SendInviteesClubDetails: {},
      };
    }
    case 'CLUB_POST_LIST_SUCCESS': {
      return {
        ...state,
        loading: false,
        errors: {},
        clubPostList: action.payload.data,
      };
    }
    case 'CLUB_MANAGE_SUCCESS': {
      return {
        ...state,
        loading: false,
        errors: {},
        clubs: action.payload.data,
      };
    }
    case 'EDIT_CLUB_OPEN': {
      return {
        ...state,
        clubEdit: action.payload,
      };
    }
    case 'OPEN_DETAILS_CLUB': {
      return {
        ...state,
        clubDetailstoOpen: action.payload,
      };
    }
    case 'CLOSE_DETAILS_CLUB': {
      return {
        ...state,
        clubDetailstoOpen: {},
      };
    }
    case 'CLUB_MY_LIST_SUCCESS': {
      return {
        ...state,
        loading: false,
        errors: {},
        myClubList: action.payload.data,
      };
    }
    case 'CLOSE_POST_EDIT': {
      return {
        ...state,
        PostEdit: false,
      };
    }
    case 'OPEN_POST_EDIT': {
      return {
        ...state,
        PostEdit: true,
      };
    }
    default:
      return state;
  }
};
