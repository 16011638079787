import React, { Component } from 'react';
import '../../resources/css/app.css';
import { Row, Col, Container } from 'react-bootstrap';
import HeaderVentures from '../../components/HomeHeader/headerVentures';
import Footer from '../../components/HomeHeader/footer';
import { useNavigate } from 'react-router-dom';
import BulbImg from '../../resources/new-img/Bulb2-01.png';
import Startup1 from '../../resources/new-img/startup1.png';
import Startup2 from '../../resources/new-img/startup2.png';
import Startup3 from '../../resources/new-img/startup3.png';
import { Link } from 'react-router-dom';
import Arrow from '../../resources/new-img/Arrow_Active_green.png';
import icon1 from '../../resources/new-img/Icon 1.png';
import icon2 from '../../resources/new-img/Icon 2.png';
import icon3 from '../../resources/new-img/Icon 3.png';
import icon4 from '../../resources/new-img/Icon 4.png';
import icon5 from '../../resources/new-img/Icon 5.png';
import icon6 from '../../resources/new-img/Icon 6.png';
import icon7 from '../../resources/new-img/Icon 7.png';
import icon8 from '../../resources/new-img/Icon 8.png';
import config from '../../services/apiConfig';
class VenturesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    let pathName = window.location.pathname;
    console.log('jobdetails', this.props.location);

    return (
      <Container fluid id="no-padding">
        <Row>
          <Col md={12} xs={12} className="main-header-style-section">
            {' '}
            <HeaderVentures />{' '}
          </Col>
        </Row>
        <Row className="container-ventures1">
          <Col md={12} xs={12}>
            {' '}
            <Row>
              <Col md={12} xs={12} className="venture-main-heading">
                LinkCxO Ventures - Connecting Great Ideas with Great Investors
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} className="venture-subheading">
                Join us as a Startup Founder or Investor
              </Col>
            </Row>
            <Row>
              <Col md={2} xs={12}></Col>
              <Col md={4} xs={12} className="btn-venture-section">
                <button className="venture-btn">
                  <Link to="/ApplyStartup" className="venture-btn-link">
                    Register to raise funds
                  </Link>
                </button>{' '}
              </Col>
              <Col md={4} xs={12}>
                <button className="venture-btn">
                  <Link to="/ApplyInvestor" className="venture-btn-link">
                    Register as an Investor
                  </Link>
                </button>{' '}
              </Col>
              <Col md={2} xs={12}></Col>
            </Row>
          </Col>
        </Row>
        <Row className="container-ventures2">
          {' '}
          <Col>
            <Row sm={12} className="bulb-venture">
              <Col sm={6} className="left-img-venture">
                <img alt="" src={BulbImg} />
              </Col>
              <Col sm={6} className="right-content-venture">
                <h3 className="color-green">Vision</h3>
                <div
                  style={{
                    height: '3px',
                    marginLeft: '100px',
                    background: 'linear-gradient(to right,#828282,#38cd84)',
                  }}></div>
                <p>
                  LinkCxO's vision is to connect startups founded by graduates
                  of IITs and IIMs, some of the most prestigious educational
                  institutions in the world, with investors from their alumni
                  all of the world. Our supposed bias in the favour of these two
                  institutions is due to our founders being from them as well.
                  This community has a track record of creating and leading
                  ventures that have change the world.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="startup-heading">
                {' '}
                <h3 className="color-green">Startup Process</h3>
                <div
                  style={{
                    height: '4px',
                    marginLeft: '440px',
                    marginRight: '440px',
                    background:
                      'radial-gradient(rgba(56,205,132,0.25)0%, #38cd84 49%, rgba(255,255,255,0.25) 100%)',
                  }}></div>
              </Col>
            </Row>
            <Row sm={12} className="startup-joining">
              <Col sm={1}></Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon1} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    Apply & Join LinkCxO Venture for funding with necessary
                    information
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon2} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  {' '}
                  <p>LinkCxO Team will vet your startup team and stage.</p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon3} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    LinkCxO Team will reach out to investors (ex-IIT/IIM) who
                    would be fit for your business.
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon4} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    Connect with Investors 1-to-1 in multiple rounds of
                    discussions and raise the funds.
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={1}></Col>
            </Row>
            <Row sm={12} className="startup-joining1">
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup1} />
                </Row>
                <Row className="startup-icon-row4">
                  {' '}
                  <p>Founder mentorship and guidance by industry experts</p>
                </Row>
              </Col>
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup2} />
                </Row>
                <Row className="startup-icon-row4">
                  <p>
                    Networking and Access to High Level Investors Circle from
                    IIT/IIM alumnos
                  </p>
                </Row>
              </Col>
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup3} />
                </Row>
                <Row className="startup-icon-row4">
                  {' '}
                  <p>Easy and fast fundraising Process</p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="venture-btn1">
                  <Link to="/ApplyStartup" className="venture-btn1-link">
                    Register to raise funds
                  </Link>
                </button>
              </Col>{' '}
            </Row>
            <Row>
              <Col className="startup-heading">
                {' '}
                <h3 className="color-green">Investor Process</h3>
                <div
                  style={{
                    height: '4px',
                    marginLeft: '440px',
                    marginRight: '440px',
                    background:
                      'radial-gradient(rgba(56,205,132,0.25) 0%, #38cd84 49%, rgba(255,255,255,0.25) 100%)',
                  }}></div>
              </Col>
            </Row>
            <Row sm={12} className="startup-joining">
              <Col sm={1}></Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon5} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    Apply & Join LinkCxO Venture for investing in IIM/IIT backed
                    Startups
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon6} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  {' '}
                  <p>
                    {' '}
                    LinkCxO Team will vet your investor profile and add you to
                    investor pool.
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon7} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    LinkCxO Team will reach out to startups who would be fit for
                    your business.
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon8} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    Connect with founders 1-to-1 in multiple rounds of
                    discussions and invest your money.
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={1}></Col>
            </Row>
            <Row sm={12} className="startup-joining1">
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup1} />
                </Row>
                <Row className="startup-icon-row4">
                  {' '}
                  <p>
                    Validated and in some cases with traction startups vetted by
                    our expert team for higher exit rates
                  </p>
                </Row>
              </Col>
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup1} />
                </Row>
                <Row className="startup-icon-row4">
                  <p>
                    Opportunities to Network with other high potential business
                    owners and investors with IIT/IIM background
                  </p>
                </Row>
              </Col>
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup1} />
                </Row>
                <Row className="startup-icon-row4">
                  {' '}
                  <p>
                    {' '}
                    Premium and Valuable Subscription Plans curated for our
                    investors
                  </p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="venture-btn1">
                  <Link to="/ApplyInvestor" className="venture-btn1-link">
                    Register as an Investor
                  </Link>
                </button>
              </Col>{' '}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12} style={{ background: '#151414' }}>
            {' '}
            <Footer />{' '}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default VenturesPage;
