import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Row, Col, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import KeyboardArrowRightIcon from '../../resources/new-img/Right Arrow - White.png';
import KeyboardArrowLeftIcon from '../../resources/new-img/Left Arrow - White.png';
import Calendar from '../../resources/new-img/GCalendar.png';
import Function from '../../resources/new-img/FunctionA.png';
import Industry from '../../resources/new-img/Industry.png';
import Location from '../../resources/new-img/Location.png';
import { useNavigate } from 'react-router-dom';
import * as activityActions from '../../store/activity';
const NewJobs = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state?.auth?.user?.user_details);
  const jobs = useSelector((state) => state?.activity?.jobList);
  const [responsive, setResponsive] = useState({
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
    mobile: { breakpoint: { max: 500, min: 0 }, items: 1 },
  });

  const mynetwork = useSelector(
    (state) => state?.usernetwork?.userRecommendationList?.data,
  );

  useEffect(() => {
    async function fetchData() {
      const data = {
        page_no: 0,
      };
      const userList = await dispatch(activityActions.getJobsListr(data));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        page_no: 1,
      };
      const userList = await dispatch(activityActions.getJobsListr(data));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        page_no: 2,
      };
      const userList = await dispatch(activityActions.getJobsListr(data));
    }
    fetchData();
  }, []);

  const handleDetails = (item) => {
    const jobcode = item?.job_id + '-' + 'lx' + item?.id;
    if (location?.search?.length > 0) {
      navigate('/JobDetails/' + jobcode + location?.search, {
        state: { item: item },
      });
    } else if (location?.hash?.length > 0) {
      let loc = location?.hash?.split('?');

      if (loc.length > 1) {
        navigate('/JobDetails/' + jobcode + '?' + loc[1], {
          state: { item: item },
        });
      } else {
        navigate('/JobDetails/' + jobcode, {
          state: { item: item },
        });
      }
    } else {
      navigate('/JobDetails/' + jobcode, { state: { item: item } });
    }
  };

  // const CustomRight = ({ onClick }) => (
  //   <button onClick={onClick} className="custom-Right-Arrow1">
  //     <img
  //       alt=""
  //       src={KeyboardArrowRightIcon}
  //       style={{ width: '35px', height: '35px' }}
  //     />
  //   </button>
  // );
  // const CustomLeft = ({ onClick }) => (
  //   <button onClick={onClick} className="custom-Left-Arrow1">
  //     <img
  //       alt=""
  //       src={KeyboardArrowLeftIcon}
  //       style={{ width: '35px', height: '35px' }}
  //     />
  //   </button>
  // );
   const CustomRight = ({ onClick }) => (
     <button onClick={onClick} className="custom-Right-Arrow2">
       <img
         alt=""
         src={KeyboardArrowRightIcon}
         style={{ width: '35px', height: '35px' }}
       />
     </button>
   );
   const CustomLeft = ({ onClick }) => (
     <button onClick={onClick} className="custom-Left-Arrow2">
       <img
         alt=""
         src={KeyboardArrowLeftIcon}
         style={{ width: '35px', height: '35px' }}
       />
     </button>
   );
  const jobcode = props?.joblist?.job_id + '-' + 'lx' + props?.joblist?.id;
  return (
    <Fragment>
      <Container fluid>
        <Row className="nl-jobs" id="jobs">
          <Col>
            <Row>
              <Col md={12}>
                <h1 className="jobsheading">JOBS</h1>

                <p className="nl-jobs-style-section-description">
                  Exclusive Senior & Leadership Levels Career Opportunities Or
                  Recruit Senior & Leadership Levels Executives.{' '}
                </p>
              </Col>
            </Row>
            <Row className="job-carousel">
              {/* <Col md={1} xs={12}></Col> */}
              <Col md={12} xs={12} className="job-outer">
                <Carousel
                  // customRightArrow={<CustomRight />}
                  // customLeftArrow={<CustomLeft />}
                  // responsive={responsive}
                  // swipeable={true}
                  // draggable={true}
                  // ssr={false}
                  // infinite={true}
                  // autoPlay={false}
                  // autoPlaySpeed={6000}
                  // keyBoardControl={true}
                  // containerClass="carousel-container"
                  // deviceType={['mobile']}
                  // itemClass="carousel-item-padding-40-px"
                  // className="main-carousel-style-section"
                  responsive={responsive}
                  autoPlay={true}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  infinite={true}
                  partialVisible={false}
                  dotListClass="custom-dot-list-style"
                  customRightArrow={<CustomRight />}
                  customLeftArrow={<CustomLeft />}
                >
                  {jobs ? (
                    jobs?.map((item, key) => {
                      const jobcode = item?.job_id + '-' + 'lx' + item?.id;
                      var login_redirect_job =
                        '/login?sign_up_through_page=job_apply&redirect=/job/details/' +
                        jobcode;
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          {' '}
                          <div className="jobs-style-section">
                            <div className="nl-job-card-top">
                              <p className="chief-financial-offi-style">
                                {item?.title?.length > 45
                                  ? item?.title?.slice(0, 45) + '...'
                                  : item?.title}
                              </p>
                            </div>

                            <div className="nl-job-card-bottom">
                              <p className="job-card-data-loaction-style">
                                <img
                                  alt=""
                                  src={Location}
                                  width="18px"
                                  height="18px"
                                />
                                &nbsp;&nbsp;&nbsp;
                                {item?.location?.length > 25
                                  ? item?.location?.slice(0, 25) + '...'
                                  : item?.location}
                              </p>
                              <p className="job-card-data-loaction-style">
                                <img
                                  alt=""
                                  src={Industry}
                                  width="18px"
                                  height="18px"
                                />
                                &nbsp;&nbsp;&nbsp;
                                <Box
                                  component="span"
                                  pr={0.5}
                                  sx={{ width: '100%' }}>
                                  {item?.industries &&
                                    item?.industries
                                      ?.slice(0, 1)
                                      ?.map((industry, index) => {
                                        if (index > 0) {
                                          return (
                                            <Typography
                                              key={index}
                                              variant="h5"
                                              component="span"
                                              sx={{ fontSize: '13px' }}>
                                              {',  ' + industry?.title}
                                            </Typography>
                                          );
                                        } else {
                                          return (
                                            <Typography
                                              key={index}
                                              variant="h5"
                                              component="span"
                                              sx={{ fontSize: '13px' }}>
                                              {industry?.title &&
                                              industry?.title?.length > 20
                                                ? industry?.title?.slice(
                                                    0,
                                                    20,
                                                  ) + '...'
                                                : industry?.title}
                                            </Typography>
                                          );
                                        }
                                      })}
                                </Box>
                              </p>
                              <p className="job-card-data-loaction-style">
                                <img
                                  alt=""
                                  src={Function}
                                  width="18px"
                                  height="18px"
                                />
                                &nbsp;&nbsp;&nbsp;
                                <Box
                                  component="span"
                                  pr={0.5}
                                  sx={{ width: '100%' }}>
                                  {item?.functions &&
                                    item?.functions
                                      ?.slice(0, 1)
                                      ?.map((functions, index) => {
                                        if (index > 0) {
                                          return (
                                            <Typography
                                              key={index}
                                              variant="h5"
                                              component="span"
                                              sx={{ fontSize: '13px' }}>
                                              {',  ' + functions?.title}
                                            </Typography>
                                          );
                                        } else {
                                          return (
                                            <Typography
                                              key={index}
                                              variant="h5"
                                              component="span"
                                              sx={{ fontSize: '13px' }}>
                                              {functions?.title &&
                                              functions?.title?.length > 20
                                                ? functions?.title?.slice(
                                                    0,
                                                    20,
                                                  ) + '...'
                                                : functions?.title}
                                            </Typography>
                                          );
                                        }
                                      })}
                                </Box>
                              </p>
                              <p className="job-card-data-loaction-style">
                                <img
                                  alt=""
                                  src={Calendar}
                                  width="18px"
                                  height="18px"
                                />
                                &nbsp;&nbsp;&nbsp;Posted on&nbsp;
                              <span style={{fontFamily:'Open Sans'}}></span>
                                {item?.posted_on}
                              </p>
                            </div>
                            <div className="job-btn">
                              <button
                                onClick={() => handleDetails(item)}
                                class="job-detail-btn">
                                Details
                              </button>

                              <a href={login_redirect_job}>
                                {' '}
                                <button class="job-apply-btn">Apply</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Row style={{ width: '100%' }}></Row>
                  )}
                </Carousel>
              </Col>
            </Row>
            <Row className="home-see-more">
              <a
                className="home-see-more-a"
                href={
                  '/login?sign_up_through_page=job_view&redirect=/jobs/search'
                }>
                ...See More
              </a>
            </Row>

            <Row className="nl-about-us-count-sec">
              <Col>
                <p className="color-green">500+</p>
                <h3>Open Jobs</h3>
              </Col>
              <Col>
                <p className="color-green">600+</p>
                <h3>CxO's Placed</h3>
              </Col>
              <Col>
                <p className="color-green">100+</p>
                <h3>Clients</h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default React.memo(NewJobs);
