import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: "20px",
  },
}));
