import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Paper, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rowroot: {
    paddingTop: '16px',
    paddingLeft: '16px',
  },
}));

const PartnerCardSkeleton = (props) => {
  return (
    <Fragment>
      <Stack direction={{ sx: 'column', md: 'row' }} spacing={1.5}>
        <Card
          sx={{
            display: 'flex',
            backgroundColor: '#262525',
            backgroundImage: 'none',
            minHeight: '185px',
            width: '50%',
          }}>
          <Stack direction="row" sx={{ width: '100%' }}>
            <Skeleton variant="rectangular" width="50%" height={185} />
          </Stack>
        </Card>

        <Card
          sx={{
            display: 'flex',
            backgroundColor: '#262525',
            backgroundImage: 'none',
            // minHeight: "250px",
            width: '50%',
          }}>
          <Stack direction="row" sx={{ width: '100%' }}>
            <Skeleton variant="rectangular" width="50%" height={185} />
          </Stack>
        </Card>
      </Stack>
    </Fragment>
  );
};

export default React.memo(PartnerCardSkeleton);
