import React, { Fragment, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as messageActions from '../../store/message';
import * as authActions from '../../store/auth';
import * as rewardsActions from '../../store/rewards';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import { dateTime } from '../../constants/app';

export default function RewardsMessage(props) {
  const dispatch = useDispatch();
  const [msg, setmsg] = useState(true);

  const underVerification = useSelector(
    (state) => state?.message?.underVerification,
  );
  const rewardsMessageOpen = useSelector((state) => state?.rewards?.openMessage);
  const user = useSelector((state) => state?.auth?.user?.user_details);
  useEffect(async () => {
    const myNetwork = await dispatch(messageActions.openUnderVerification());
    const data = {
      user_id: user?.id,
      self_id: user?.id,
      datetime: dateTime(),
    };
    const userdetails = await dispatch(authActions.userProfileDetails(data));

    if (userdetails?.data?.isVerified == 1) {
      setmsg(false);
    }
  }, []);

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(rewardsActions.closeRewardMessage());
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={rewardsMessageOpen}>
        <Box
          sx={{
            width: '620px',
            height: '156px',
            backgroundColor: '#1c1c1c',
            borderRadius: '10px',
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Box
              px={3}
              pt={3}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon
                sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                onClick={handleClose}
              />
            </Box>

            <Box
                  p={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant="h2s" component="h2s">
                    We will get back to you.
                  </Typography>
                </Box>
                <Box
                  px={3}
                  py={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant="h2" component="h2"></Typography>
                </Box>
  
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
}
