import { makeStyles } from "@mui/styles";

export default makeStyles({
  menuIcon: {
    height: "32px",
  },
  logo:{
      height: "54px" ,   
  },

  // height:"64px"
  menuNetwork: {
    height: "32px",
    filler:
      "invert(100%) sepia(3%) saturate(433%) hue-rotate(276deg) brightness(119%) contrast(100%)",
  },
});
