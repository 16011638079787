import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Automotive from './Automotive';
import RealEstate from './RealEstate';
import Entertainment from './Entertainment';
import Electronics from './Electronics';
import Investment from './Investment';
import Accessories from './Accessories';
import Healthpkg from './Healthpkg';

const Brand = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();
  if (params.type == 'Automotive') return <Automotive />;
  else if (params.type == 'Realestate') return <RealEstate />;
  else if (params.type == 'Entertainment&Hospitality') return <Entertainment />;
  else if (params.type == 'Electronics') return <Electronics />;
  else if (params.type == 'Investment') return <Investment />;
  else if (params.type == 'HealthPackages') return <Healthpkg />;
  else if (params.type == 'Apparels&Accessories') return <Accessories />;
};

export default Brand;
