import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CardLong from '../../components/CardLong/CardLong';
import CardClub from '../../components/CardLong/CardClub';
import CxoButton from '../../components/CxoButton/CxoButton';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import { dateTime } from '../../constants/app';
import ClubCard from '../../components/ClubCard/ClubCard';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubEvent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eventd, setEventd] = useState({
    id: 280,
    user_id: 844,
    event_name: 'test management club event',
    event_mode: 'Offline',
    start_date: 'Jul 9, 2022',
    start_time: '02:51:00',
    image_url:
      'https://linkcxo-test-new.s3.ap-south-1.amazonaws.com/2022-06-09T09-21-12.036Zlinkcxo',
    first_name: 'Baseem',
    last_name: 'Beg',
    venue: 'Bangalore',
    event_link: '',
    video_url: null,
    end_date: 'Aug 9, 2022',
    end_time: '02:51:00',
    type: 'Public',
    event_type: '',
    email: 'baseem.beg@linkcxo.com',
    details: 'Tetsuya is event',
    status: 'Active',
    currency: '₹ (INR)',
    fee: 100,
    isVerified: 1,
    categories: [
      {
        id: 7,
        title: 'Hiring CXOs',
      },
    ],
    attending_count: 0,
    avalableSlot: 50,
  });
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const user = useSelector((state) => state?.user?.UserDetails);
  const clubDetailstoOpen = useSelector(
    (state) => state?.clubs?.clubDetailstoOpen,
  );
  const clubPostList = useSelector((state) => state?.clubs?.clubPostList);
  const clubEventList = useSelector((state) => state?.clubs?.clubEventList);

  const eventDetails = (e, eventdetails) => {
    e.stopPropagation();
    const eventdetail =
      eventdetails?.event_name + '-' + 'lx' + eventdetails?.id;
    navigate('/event/details/' + eventdetail);
  };

  const editEvent = (e) => {
    e.stopPropagation();
    console.log('editEvent');
  };

  useEffect(async () => {
    // const data = {
    //   user_id: user?.id,
    //   club_id: clubDetailstoOpen?.id,
    // };
    // const clubdetails = await dispatch(clubsActions.clubEventList(data));
  }, []);

  useEffect(async () => {
    if (clubDetailstoOpen?.club_id) {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.club_id,
      };
      const clubdetails = await dispatch(clubsActions.clubEventList(data));
    } else {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.id,
      };
      const clubdetails = await dispatch(clubsActions.clubEventList(data));
    }
  }, []);

  useEffect(async () => {
    if (clubDetailstoOpen?.club_id) {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.club_id,
        datetime: dateTime(),
      };
      const clubdetails = await dispatch(clubsActions.clubPostList(data));
    } else {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.id,
        datetime: dateTime(),
      };
      const clubdetails = await dispatch(clubsActions.clubPostList(data));
    }
  }, []);

  return (
    <Fragment>
      {clubEventList?.length > 0 && (
        <Fragment>
          <Box pl={3}>
            <Typography
              gutterBottom
              variant="h5"
              component="h5"
              sx={{ fontWeight: '600' }}>
              Club Events
            </Typography>
          </Box>
          <Box px={2}>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              {clubEventList ? (
                clubEventList?.map((eventClub, index) => {
                  return (
                    <Card
                      sx={{
                        maxWidth: { md: '20%', xs: '100%' },
                        cursor: 'pointer',
                        margin: '8px',
                      }}
                      onClick={(e) => eventDetails(e, eventClub)}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={eventClub?.image_url}
                        alt="Club Event"
                        sx={{ borderRadius: '12px' }}
                      />
                      <CardContent
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}>
                        <Typography
                          gutterBottom
                          variant="h5s"
                          component="div"
                          sx={{ fontWeight: '600', minHeight: '40px' }}>
                          {eventClub?.event_name?.length > 20
                            ? eventClub?.event_name?.substring(0, 20) + '...'
                            : eventClub?.event_name}
                        </Typography>
                        <Stack
                          direction="column"
                          spacing={0.5}
                          justifyContent="center"
                          sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            fontFamily: 'Open Sans',
                            justifyContent: 'center',
                          }}>
                          <Typography
                            variant="h5s"
                            color="text.secondary"
                            sx={{ fontFamily: 'Open Sans' }}>
                            {eventClub?.start_date
                              ? moment(eventClub?.start_date).format('D') +
                                ' ' +
                                moment(eventClub?.start_date).format('MMM') +
                                ', ' +
                                moment(eventClub?.start_date).format('YYYY')
                              : ''}
                          </Typography>
                          <Typography
                            variant="h5s"
                            color="text.secondary"
                            sx={{ fontFamily: 'Open Sans' }}>
                            {eventClub?.start_time
                              ? moment(
                                  eventClub?.start_time,
                                  'hh:mm:ss A',
                                ).format('hh:mm A')
                              : ''}
                          </Typography>
                        </Stack>
                      </CardContent>
                      <CardActions></CardActions>
                    </Card>
                  );
                })
              ) : (
                <Box></Box>
              )}
            </Stack>
          </Box>
          <Box py={2}>
            <Divider />
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default React.memo(ClubEvent);
