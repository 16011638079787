import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as rewardsActions from '../../store/rewards';
import CxoButton from '../../components/CxoButton/CxoButton';
import credits_gold from '../../assets/header/Credits_Gold.png';
import { dateTime } from '../../constants/app';
import CreditsDetailsList from './CreditsDetailsList';
import CreditsDetailsFilters from './CreditsDetailsFilters';
import CreditsActionsFilters from './CreditsActionsFilters';
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const Credits = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [howto, setHowto] = React.useState(true);
  const user = useSelector((state) => state?.user?.UserDetails);
  // const searchresult =  dispatch(searchActions.postSearch(data));
  // let { actions } = useParams();
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsGetCreditsDetails = useSelector(
    (state) => state?.rewards?.rewardsGetCreditsDetails,
  );

  const rewardsGetCreditslist = useSelector(
    (state) => state?.rewards?.rewardsGetCreditslist,
  );
  useEffect(async () => {}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue == 0){
      setValue(newValue);
      navigate("/rewards/credits");
    }
    else if(newValue == 1){
      setValue(newValue);
      navigate("/rewards/my/vouchers");
    }
  };

  const howToEarn = () => {
    setHowto(!howto);
  };

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      filter_type: 'daily',
      datetime: dateTime(),
    };
    const rewardscreditsdetails = await dispatch(
      rewardsActions.rewardsGetCreditsDetails(data),
    );
  }, []);

  useEffect(async () => {
    const rewardscreditslist = await dispatch(
      rewardsActions.rewardsGetCreditsList(),
    );
  }, []);

  return (
    <Fragment>
      <Box p={2}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}>
              <Box
                py={2}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Stack
                  direction="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '48px',
                      maxWidth: 'min-content',
                      width: '48px',
                    }}>
                    <img alt="creditgold" src={credits_gold} />
                  </Box>

                  <Stack
                    direction="column"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: '16px',
                    }}>
                    <Box pt={1} pb={0.5} px={1} sx={{ width: '100%' }}>
                      <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                          color: '#F5BD02',
                          fontSize: '20px',
                          lineHeight: '27px',
                          fontWeight: '600',
                          fontFamily: 'Open Sans',
                        }}>
                        {rewardsTotalCredits?.credits
                          ? parseInt(
                              rewardsTotalCredits?.credits,
                            ).toLocaleString()
                          : 0}
                      </Typography>
                    </Box>
                    <Box pt={0.5} pb={1} px={1} sx={{ width: '100%' }}>
                      <Typography variant="h2" component="h2" sx={{}}>
                        Credits
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>

              <Box
                py={2}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CxoButton
                  label={howto ? 'How to earn?' : 'My Credits'}
                  onClick={howToEarn}
                />
              </Box>
            </Stack>
          </Paper>

          <Box px={0} pt={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '4px 16px' }}>
                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                            Details
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          My Voucher
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>

                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

          {howto ? <CreditsActionsFilters /> : <CreditsDetailsList />}
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(Credits);
