import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@mui/styles';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import Invest1 from '../../../resources/partner-image/Investment.jpg';
import Invest2 from '../../../resources/partner-image/investment2.webp';

const CarouselContainer = styled.div`
  .carousel .control-dots {
    display: none; /* Hide the dots */
  }
  .carousel .thumbs-wrapper {
    display: none; /* Hide the numbering */
  }
  .carousel .carousel-status {
    font-size: 0px;
  }
`;
const LeftDiv = styled.div`
  // background-color: lightblue;
  height: 100%;
`;


const useStyles = makeStyles((theme) => ({
  container: {
    color: '#fff',
    height: 'auto',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px', // Adjust padding for screens with max-width: 600px
    },
  },
  heading: {
    fontSize: '22px',
    color: '#38cd84',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px', // Adjust font size for screens with max-width: 600px
    },
  },
  heading1: {
    fontSize: '30px',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  list: {
    fontSize: '16px',
    color: '#fff',
    paddingLeft: '20px',
    listStyleType: 'disc',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', // Adjust font size for screens with max-width: 600px
    },
  },
}));

const Investment = () => {
  const classes = useStyles();
  const images = [Invest1, Invest2];

  return (
    <>
      <Grid container spacing={2} sx={{ px: { xs: 3, sm: 10 } }}>
        <Grid item xs={12} sm={6}>
          <LeftDiv>
            <CarouselContainer>
              <Carousel
                showThumbs={false}
                autoPlay
                infiniteLoop
                interval={3000}
                stopOnHover={true}>
                {images.map((imageUrl, index) => (
                  <div key={index}>
                    <Box
                      component="img"
                      src={imageUrl}
                      alt={`Image ${index + 1}`}
                      sx={{
                        height: { xs: '200px', sm: '350px' },
                        width: 'auto',
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </CarouselContainer>
          </LeftDiv>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          position="relative"
          justifyContent="center"
          alignItems="center"
          display="flex">
          <div className={classes.container}>
            <div
              style={{
                position: 'absolute',
                bottom: '10px',
                right: '20px',
              }}>
              {' '}
              <a
                href="/login"
                style={{ textDecoration: 'none', color: '#38cd84' }}>
                <p
                  style={{
                    color: '#38cd84',
                    fontSize: '20px',
                    fontWeight: 500,
                  }}>
                  See More
                </p>
              </a>
            </div>
            <h2 className={classes.heading1}>Investment</h2>
            <h2 className={classes.heading}>
              LinkCxO partners with investment firms to provide our members:
            </h2>

            <ul className={classes.list}>
              {/* <li>Access curated CxO-tailored investments</li> */}
              <li>
                <span style={{ fontFamily: 'open Sans' }}>10</span>% Off On a
                Curated List Of startups to Start your Angel Investment Journey
              </li>
              <li>Explore investment options in early-stage startups</li>
              <li>
                Embark on your startup investment journey with just INR 2,00,000
              </li>
              <li> Get expert advice for informed decisions</li>
            </ul>
            <span
              style={{
                fontSize: '20px',
                color: '#38cd84',
                fontWeight: 600,
              }}>
              Partner:
            </span>
            <span style={{ fontSize: '18px', color: '#fff' }}>
              &nbsp;&nbsp;Venture Catalysts
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Investment;
