import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import CompanyLogoPage1 from "./CompanyLogo1";
import CompanyLogoPage2 from "./CompanyLogo2";
import CompanyLogoPage3 from "./CompanyLogo3";

// const pages = [CompanyLogoPage1, CompanyLogoPage2, CompanyLogoPage3];
function CompanyLogoMain() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Container fluid className="displayhide">
        <Row md={12}>
          <Col>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              direction="left"
              customTransition="all .5"
              transitionDuration={20}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              sx={{ paddingBottom: "-50px" }}
            >
              <div style={{ width: "100%" }}>
                <CompanyLogoPage1 />
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <CompanyLogoPage2 />
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <CompanyLogoPage3 />
              </div>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CompanyLogoMain;
