import React, { useEffect } from 'react';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import * as companyActions from '../../store/company';
import { dateTime } from '../../constants/app';
import CardCompany from '../../components/CardLong/CardCompany';
import CxoButton from '../../components/CxoButton/CxoButton';
import * as clubsActions from '../../store/clubs';
import * as messageActions from '../../store/message';
import moment from 'moment';

const CompanyPostList = (props) => {
  let params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);

  const companyPostList = useSelector(
    (state) => state?.company?.companyPostList,
  );
  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_pk: id[id?.length - 1]?.substring(2),
        datetime: dateTime(),
        page_no: 0,
      };
      const users = await dispatch(companyActions.getCompanyPostList(data));
    }
    fetchData();
  }, []);

  const updatePost = async () => {
    const id = params?.id?.split('-');
    const data = {
      user_id: user?.id,
      user_table_pk: id[id?.length - 1]?.substring(2),
      datetime: dateTime(),
      page_no: 0,
    };
    const users = await dispatch(companyActions.getCompanyPostList(data));
  };

  const createPost = async () => {
    if (user?.isVerified) {
      const mypost = await dispatch(clubsActions.openPostEdit('club'));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <>
      <Box>
        {companyPostList?.map((post, index) => (
          <Box key={index} pt={1} px={1}>
            <CardCompany post={post} updatePost={updatePost} />
          </Box>
        ))}

        {companyPostList?.length === 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Stack
                direction="column"
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    color: '#ffffff',
                  }}>
                  There are no posts created yet. Start creating.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '8px',
                  }}>
                  <CxoButton label="Create Post" onClick={createPost} />
                </Box>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default React.memo(CompanyPostList);
