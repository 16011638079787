import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import 'react-quill/dist/quill.snow.css';
import './editorStyle.css';
import useStyles from './editorstyle';
import * as messageActions from '../../store/message';
import * as clubsActions from '../../store/clubs';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import ReactQuill from 'react-quill';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import IconButton from '@mui/material/IconButton';
import { dateTime } from '../../constants/app';
const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

const UpdatePostComponent = (props) => {
  const dispatch = useDispatch();
  const [quilltext, setQuilltext] = useState('');
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(10);
  const [showPreview, setPreview] = useState(false);
  const [imgData, setImgData] = useState(null);
  const editPost = useSelector((state) => state?.post?.UpdateEditPost);
  const updatePost = useSelector((state) => state?.post?.updateEditPost);
  const user = useSelector((state) => state?.user?.UserDetails);

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
    setQuilltext(updatePost?.post?.content);
  }, []);

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
    if (updatePost?.post?.image) {
      setPreview(true);
    } else if (updatePost?.post?.document) {
      setPreview(true);
    }
  }, []);
  const userPostDetails = async () => {
    // const data = {
    //   user_id: user?.id,
    //   start_item: startItem,
    //   item_count: itemCount,
    //   datetime: dateTime(),
    // };
    // const postlist = await dispatch(postActions.getPostList(data));

    const data2 = {
      user_id: user?.id,
      start_item: startItem,
      item_count: itemCount,
      datetime: dateTime(),
    };
    const formData1 = new FormData();
    formData1.append('user_id', user?.id);
    formData1.append('start_item', startItem);
    formData1.append('item_count', itemCount);
    formData1.append('datetime', dateTime());

    const postlist2 = await dispatch(postActions.getPostList(formData1, data2));
  };

  const addPost = async () => {
    console.log('file', file);
    var m = new Date();
    var dateString =
      m.getUTCFullYear() +
      '-' +
      (m.getUTCMonth() + 1) +
      '-' +
      m.getUTCDate() +
      ' ' +
      m.getUTCHours() +
      ':' +
      m.getUTCMinutes() +
      ':' +
      m.getUTCSeconds();
    const formData = new FormData();
    formData.append('id', updatePost?.post?.id);
    formData.append('content', quilltext);
    formData.append('file', file);
    formData.append('user_id', user.id);
    formData.append('datetime', dateString);
    formData.append('document_type', type);
    formData.append('user_type', 'user');
    formData.append('user_table_pk', user.id);
    formData.append('document_name', name);
    const headers = { 'Content-Type': 'multipart/form-data' };
    // const data = {
    //   id: 0,
    //   content: quilltext,
    //   image: "",
    //   user_id: user.id,
    //   datetime: dateString,
    //   document_type: "post",
    //   user_type: "user",
    //   user_table_pk: user.id,
    //   document: "",
    // };
    if (quilltext.length > 0) {
      if (user?.isVerified) {
        const addpost = await dispatch(
          postActions.sendAddNewPostData(formData),
        );
        const snackbar = await dispatch(postActions.closeUpdatePost());
        return userPostDetails();
      } else {
        await dispatch(messageActions.openUnderVerification());
      }
    }
  };

  const handleChange = (event) => {
    if (quilltext.length < 2000) {
      setQuilltext(event.target.value);
    } else if (event.target.value.length < quilltext.length) {
      setQuilltext(event.target.value);
    }
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(postActions.closeUpdatePost());
  };
  const getPhoto = async (event) => {
    setPreview(true);
    setFile(event.target.files[0]);
    setType('image');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };
  const getDocument = async (event) => {
    setFile(event.target.files[0]);
    setType('document');
    setName(event.target.files[0].name);
  };

  const deletePost = async () => {
    const data = {
      user_id: user?.id,
      post_id: updatePost?.post?.id,


    };
    const delpost = await dispatch(postActions.deletePost(data));
    const data2 = {
      user_id: user?.id,
      start_item: startItem,
      item_count: itemCount,
      datetime: dateTime(),
    };
    const formData1 = new FormData();
    formData1.append('user_id', user?.id);
    formData1.append('start_item', startItem);
    formData1.append('item_count', itemCount);
    formData1.append('datetime', dateTime());

    const postlist = await dispatch(postActions.getPostList(formData1, data2));
    const snackbar = await dispatch(postActions.closeUpdatePost());
    if (updatePost?.post?.user_type === 'club') {
      const data = {
        user_id: user?.id,
        club_id: updatePost?.post?.user_table_pk,
        datetime: dateTime(),
      };
      const clubdetails = await dispatch(clubsActions.clubPostList(data));
    }
  };

  const removePreview = () => {
    setPreview(false);
    setFile('');
    setType('');
    setName('');
    setImgData(null);
  };

  const nodelete = async () => {
    const snackbar = await dispatch(postActions.closeUpdatePost());
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={editPost}>
        <Box
          sx={{
            minHeight: updatePost?.type != 'delete' ? '400px' : '200px',
            backgroundColor: '#333',
            borderRadius: '10px',
            maxHeight: updatePost?.type != 'delete' ? '80vh' : '200vh',
            maxWidth: '1100px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid #fff',
            },
            minWidth: { xs: '90%', md: '600px' },
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '24px',
                }}>
                <Typography variant="h2s" component="h2s">
                  {updatePost?.type != 'delete' ? 'Update Post' : 'Delete Post'}
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>
            <Divider />
            {updatePost?.type === 'edit' && (
              <>
                <Box p={2} sx={{ zIndex: '1200' }} className="editor">
                  <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    rows={8}
                    maxRows={16}
                    defaultValue={updatePost?.post?.content}
                    value={quilltext}
                    onChange={handleChange}
                    placeholder="Start Typing Here"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                {showPreview && (
                  <>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          position: 'relative',
                        }}>
                        <AddCircleOutlinedIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '12',
                            position: 'absolute',
                            right: '0px',
                          }}
                          onClick={removePreview}
                        />
                        <Box p={1}>
                          {imgData ? (
                            <img src={imgData} alt="" height="100px" />
                          ) : (
                            <img
                              src={updatePost?.post?.image}
                              alt=""
                              height="100px"
                            />
                          )}
                          {!updatePost?.post?.image &&
                            updatePost?.post?.document_name != 'video' && (
                              <Box p={1} sx={{ height: 'auto' }}>
                                <iframe
                                  width="100%"
                                  height="100px"
                                  src={
                                    'https://docs.google.com/viewer?url=' +
                                    updatePost?.post?.document +
                                    '&embedded=true'
                                  }
                                  frameBorder="0"></iframe>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                <Divider />
                <Box
                  pl={3}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: '48px',
                    alignItems: 'center',
                  }}>
                  <Stack spacing={3} direction="row">
                    <Stack spacing={2} direction="row">
                      <Box>
                        <label htmlFor="icon-button-file">
                          <Box
                            p={1}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}>
                            <InsertPhotoOutlinedIcon />
                          </Box>
                        </label>
                        <input
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={getPhoto}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography variant="h2" component="h2">
                          Images
                        </Typography>
                      </Box>
                    </Stack>
                    {updatePost?.post?.user_type != 'company_page' && (
                      <Stack spacing={2} direction="row">
                        <Box>
                          <label htmlFor="icon-button-file">
                            <Box
                              p={1}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}>
                              <VideoCameraBackOutlinedIcon />
                            </Box>
                          </label>
                          <input
                            id="icon-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={getDocument}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Typography variant="h2" component="h2">
                            Videos
                          </Typography>
                        </Box>
                      </Stack>
                    )}

                    <Stack spacing={2} direction="row">
                      <Box>
                        <label htmlFor="icon-button-file">
                          <Box
                            p={1}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}>
                            <AttachFileOutlinedIcon />
                          </Box>
                        </label>
                        <input
                          id="icon-button-file"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={getDocument}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography variant="h2" component="h2">
                          Documents
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Divider />

                <Grid
                  container
                  alignItems="flex-end"
                  sx={{ alignItems: 'center' }}>
                  <Grid
                    item
                    xs
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '16px',
                    }}>
                    <Box px={3}>
                      <Typography gutterBottom variant="h6" component="h6">
                        {quilltext.length} / 2000
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box
                      py={2}
                      px={3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <CxoButton label="Update Post" onClick={addPost} />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
            {updatePost?.type === 'delete' && (
              <>
                <Stack>
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    <Typography gutterBottom variant="h5" component="h5">
                      Are you sure you want to delete this Post?
                    </Typography>
                  </Box>
                  <Stack direction="row" justifyContent="center">
                    <Box
                      py={3}
                      px={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <CxoButton label="No" onClick={nodelete} />
                    </Box>
                    <Box
                      py={3}
                      px={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <CxoButton label="Yes" onClick={deletePost} />
                    </Box>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(UpdatePostComponent);

//
// <ReactQuill
//   value={quilltext}
//   modules={modules}
//   formats={formats}
//   onChange={handleChange}
// />
