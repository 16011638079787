import { getAccessToken, setTokenResponse } from './authData';
import { logoutSuccess } from '../store/auth';

let _dispatch;
export const setDispatch = (dispatch) => (_dispatch = dispatch);

export const handleResponse = (response) => {
  if (response.config.url === '/o/token/') {
    setTokenResponse(response.data);
  }
  return response.data;
};

export const handleError = async (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      _dispatch(logoutSuccess());
    }
    throw error.response.data;
  }
  throw error.message;
};

export const setAuthorizationHeader = async (config) => {
  const headers = config.headers;
  headers['Authorization'] = 'Bearer ' + getAccessToken();
  return { ...config, headers };
};
