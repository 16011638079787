import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import useStyles from './networkstyles';
import { buttonClasses } from '@mui/base/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import NetworkCardSkeleton from '../../components/Skeleton/NetworkCardSkeleton';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as usernetworkActions from '../../store/usernetwork';
import InfiniteScroll from 'react-infinite-scroll-component';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Network = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [noofSent, setNoofSent] = React.useState(4);
  const [noofpending, setNoofPending] = React.useState(4);
  const [noofExisting, setNoofExisting] = React.useState(4);
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.UserDetails);
  const mynetwork = useSelector(
    (state) => state?.usernetwork?.userRecommendationList?.data,
  );
  const existinglist = useSelector(
    (state) => state?.usernetwork?.ExistingNetworkList,
  );
  const pendinglist = useSelector(
    (state) => state?.usernetwork?.PendingNetworkList,
  );
  const sendlist = useSelector((state) => state?.usernetwork?.SentNetworkList);
  const networkLoading = useSelector(
    (state) => state?.usernetwork?.networkLoading,
  );
  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const userList = await dispatch(
      usernetworkActions.getPeopleRecommendationList(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const existinglist = await dispatch(
      usernetworkActions.getExistingNetwork(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const pendinglist = await dispatch(
      usernetworkActions.getPendingNetwork(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const sentlist = await dispatch(usernetworkActions.getSentNetwork(data));
  }, []);
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      navigate('/network/mynetwork');
      setValue(newValue);
    } else if (newValue === 1) {
      navigate('/network/sent');
      setValue(newValue);
    } else if (newValue === 2) {
      navigate('/network/existing');
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (location.pathname === '/network/pending') {
      setValue(0);
    } else if (location.pathname === '/network/sent') {
      setValue(1);
    } else if (location.pathname === '/network/existing') {
      setValue(2);
    } else {
      setValue(0);
    }
  }, []);

  const seeMorePendingNetwork = () => {};
  const seeMoreExistingNetwork = () => {};
  const seeMoreSendNetwork = () => {};

  const seeMore = () => {
    if (value === 1 && sendlist?.length > noofSent) {
      if (sendlist?.length <= noofSent + 4) {
        setNoofSent(sendlist?.length);
      } else {
        setNoofSent(noofSent + 4);
      }
    } else if (value === 0 && pendinglist?.length > noofpending) {
      if (pendinglist?.length <= noofpending + 4) {
        setNoofPending(pendinglist?.length);
      } else {
        setNoofPending(noofpending + 4);
      }
    } else if (value === 2 && existinglist?.length > noofExisting) {
      if (existinglist?.length <= noofExisting + 4) {
        setNoofExisting(existinglist?.length);
      } else {
        setNoofExisting(noofExisting + 4);
      }
    }
  };

  const seeLess = () => {
    if (value === 1 && sendlist?.length === noofSent) {
      setNoofSent(4);
    } else if (value === 0 && pendinglist?.length === noofpending) {
      setNoofPending(4);
    } else if (value === 2 && existinglist?.length === noofExisting) {
      setNoofExisting(4);
    }
  };

  return (
    <Fragment>
      <Box p={1} py={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="fullWidth"
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tab
            value={0}
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 0 ? '#38CD84' : '#ffffff',
                    }}>
                    Pending
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      textTransform: 'none',
                      color: value === 0 ? '#38CD84' : '#ffffff',
                    }}>
                    {pendinglist?.length > 0
                      ? ' (' + pendinglist?.length + ')'
                      : '(0)'}
                  </Typography>
                </Stack>
              </Box>
            }
          />
          <Tab
            value={1}
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 1 ? '#38CD84' : '#ffffff',
                    }}>
                    Sent
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      textTransform: 'none',
                      color: value === 1 ? '#38CD84' : '#ffffff',
                    }}>
                    {sendlist?.length > 0
                      ? '(' + sendlist?.length + ')'
                      : '(0)'}
                  </Typography>
                </Stack>
              </Box>
            }
          />
          <Tab
            value={2}
            label={
              <Stack direction="row" spacing={0.5}>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.tabname}
                  sx={{
                    textTransform: 'none',
                    color: value === 2 ? '#38CD84' : '#ffffff',
                  }}>
                  Existing
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.tabdigit}
                  sx={{
                    textTransform: 'none',
                    color: value === 2 ? '#38CD84' : '#ffffff',
                  }}>
                  {existinglist?.length > 0
                    ? '(' + existinglist?.length + ')'
                    : '(0)'}
                </Typography>
              </Stack>
            }
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {networkLoading ? (
              <Fragment>
                <Box>
                  <NetworkCardSkeleton />
                </Box>
                <Box pt={1}>
                  <NetworkCardSkeleton />
                </Box>
              </Fragment>
            ) : (
              <Box>
                <InfiniteScroll
                  dataLength={pendinglist?.length ? pendinglist?.length : 0}
                  next={() => seeMorePendingNetwork()}
                  hasMore={true}
                  loader={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMorePendingNetwork}></Box>
                  }
                  height={'calc(90vh - 75px)'}
                  endMessage={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMorePendingNetwork}>
                      <Box>End</Box>
                    </Box>
                  }>
                  <Grid container>
                    {pendinglist?.length > 0 &&
                      pendinglist?.map((list, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: index % 2 === 0 ? '8px' : '0px',
                              marginTop: '8px',
                            }}>
                            <NetworkCard userlist={list} type="pending" />
                          </Grid>
                        );
                      })}
                    {!pendinglist && (
                      <Box p={2}>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            '@media (max-width: 500px)': {
                              fontSize: '12px',
                              fontWeight: '400',
                            },
                          }}>
                          Right now there are no pending connection requests.
                        </Typography>
                      </Box>
                    )}
                    {pendinglist?.length === 0 && (
                      <Box p={2}>
                        <Typography variant="h5" component="h5">
                          Right now there are no pending connection requests.
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {networkLoading ? (
              <Fragment>
                <Box>
                  <NetworkCardSkeleton />
                </Box>
                <Box pt={1}>
                  <NetworkCardSkeleton />
                </Box>
              </Fragment>
            ) : (
              <Box>
                <InfiniteScroll
                  dataLength={sendlist?.length ? sendlist?.length : 0}
                  next={() => seeMoreSendNetwork()}
                  hasMore={true}
                  loader={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreSendNetwork}></Box>
                  }
                  height={'calc(90vh - 75px)'}
                  endMessage={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreSendNetwork}>
                      <Box>End</Box>
                    </Box>
                  }>
                  <Grid container>
                    {sendlist?.length > 0 &&
                      sendlist?.map((list, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: index % 2 === 0 ? '8px' : '0px',
                              marginTop: '8px',
                            }}>
                            <NetworkCard userlist={list} type="sent" />
                          </Grid>
                        );
                      })}

                    {sendlist?.length === 0 &&
                      (
                        <Box p={2}>
                          <Typography variant="h5" component="h5">
                            Right now there are no sent connection requests.
                          </Typography>
                        </Box>
                      )}
                  </Grid>
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {networkLoading ? (
              <Fragment>
                <Box>
                  <NetworkCardSkeleton />
                </Box>
                <Box pt={1}>
                  <NetworkCardSkeleton />
                </Box>
              </Fragment>
            ) : (
              <Box>
                <InfiniteScroll
                  dataLength={existinglist?.length ? existinglist?.length : 0}
                  next={() => seeMoreExistingNetwork()}
                  hasMore={true}
                  loader={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreExistingNetwork}></Box>
                  }
                  height={'calc(90vh - 75px)'}
                  endMessage={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreExistingNetwork}>
                      <Box>End</Box>
                    </Box>
                  }>
                  <Grid container>
                    {existinglist?.length > 0 &&
                      existinglist?.map((list, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: index % 2 === 0 ? '8px' : '0px',
                              marginTop: '8px',
                            }}>
                            <NetworkCard userlist={list} type="existing" />
                          </Grid>
                        );
                      })}

                    {existinglist?.length === 0 &&
                      (
                        <Box p={2}>
                          <Typography variant="h5" component="h5">
                            Right now there are no connections.
                          </Typography>
                        </Box>
                      )}

                      {!existinglist &&              (
                        <Box p={2}>
                          <Typography variant="h5" component="h5">
                            Right now there are no connections.
                          </Typography>
                        </Box>
                      )}
                  </Grid>
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        </TabPanel>
        {/* <Tabs
          defaultValue={0}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          color="white"
          aria-label="ant example">
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            <TabsList sx={{ padding: '8px 16px' }}>
              <Tab>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                    Pending
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                    {pendinglist?.length > 0
                      ? ' (' + pendinglist?.length + ')'
                      : '(0)'}
                  </Typography>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                    Sent
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                    {sendlist?.length > 0
                      ? '(' + sendlist?.length + ')'
                      : '(0)'}
                  </Typography>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      color: value === 2 ? '#000000' : '#ffffff',
                    }}>
                    Existing
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                    {existinglist?.length > 0
                      ? '(' + existinglist?.length + ')'
                      : '(0)'}
                  </Typography>
                </Stack>
              </Tab>
            </TabsList>
          </Paper>
        </Tabs> */}
      </Box>
    </Fragment>
  );
};

export default React.memo(Network);

// <TabPanel value={0}>
// <Box
//   sx={{
//     marginTop: '0px',
//   }}>
//   {networkLoading ? (
//     <Fragment>
//       <Box>
//         <NetworkCardSkeleton />
//       </Box>
//       <Box pt={1}>
//         <NetworkCardSkeleton />
//       </Box>
//     </Fragment>
//   ) : (
//     <Box>
//       <InfiniteScroll
//         dataLength={pendinglist?.length ? pendinglist?.length : 0}
//         next={() => seeMorePendingNetwork()}
//         hasMore={true}
//         loader={
//           <Box
//             sx={{
//               height: '40px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               cursor: 'pointer',
//             }}
//             onClick={seeMorePendingNetwork}></Box>
//         }
//         height={'calc(90vh - 75px)'}
//         endMessage={
//           <Box
//             sx={{
//               height: '40px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               cursor: 'pointer',
//             }}
//             onClick={seeMorePendingNetwork}>
//             <Box>End</Box>
//           </Box>
//         }>
//         <Grid container>
//           {pendinglist?.length > 0 &&
//             pendinglist?.map((list, index) => {
//               return (
//                 <Grid
//                   key={index}
//                   item
//                   xs={12}
//                   md={6}
//                   sx={{
//                     paddingRight: index % 2 === 0 ? '8px' : '0px',
//                     marginTop: '8px',
//                   }}>
//                   <NetworkCard userlist={list} type="pending" />
//                 </Grid>
//               );
//             })}
//           {!pendinglist && (
//             <Box p={2}>
//               <Typography
//                 variant="h5"
//                 component="h5"
//                 sx={{
//                   '@media (max-width: 500px)': {
//                     fontSize: '12px',
//                     fontWeight: '400',
//                   },
//                 }}>
//                 Right now there are no pending connection requests.
//               </Typography>
//             </Box>
//           )}
//           {pendinglist?.length === 0 && (
//             <Box p={2}>
//               <Typography variant="h5" component="h5">
//                 Right now there are no pending connection requests.
//               </Typography>
//             </Box>
//           )}
//         </Grid>
//       </InfiniteScroll>
//     </Box>
//   )}
// </Box>

// {/* {networkLoading ? (
//   <NetworkCardSkeleton />
// ) : (
//   <Grid container sx={{ paddingBottom: '8px' }}>
//     {pendinglist?.length > 0 &&
//       pendinglist?.map((list, index) => {
//         return (
//           <Grid
//             key={index}
//             item
//             xs={12}
//             md={6}
//             sx={{
//               paddingRight: index % 2 === 0 ? '8px' : '0px',
//               marginTop: '8px',
//             }}>
//             <NetworkCard userlist={list} type="pending" />
//           </Grid>
//         );
//       })}
//     {pendinglist?.length === 0 && (
//       <Box p={2}>
//         <Typography
//           variant="h5"
//           component="h5"
//           sx={{
//             '@media (max-width: 500px)': {
//               fontSize: '12px',
//               fontWeight: '400',
//             },
//           }}>
//           Right now there are no pending connection requests.
//         </Typography>
//       </Box>
//     )}
//     {!pendinglist && (
//       <Box p={2}>
//         <Typography
//           variant="h5"
//           component="h5"
//           sx={{
//             '@media (max-width: 500px)': {
//               fontSize: '12px',
//               fontWeight: '400',
//             },
//           }}>
//           Right now there are no pending connection requests.
//         </Typography>
//       </Box>
//     )}
//   </Grid>
// )} */}
// </TabPanel>

// <TabPanel value={1}>
// <Box
//   sx={{
//     marginTop: '0px',
//   }}>
//   {networkLoading ? (
//     <Fragment>
//       <Box>
//         <NetworkCardSkeleton />
//       </Box>
//       <Box pt={1}>
//         <NetworkCardSkeleton />
//       </Box>
//     </Fragment>
//   ) : (
//     <Box>
//       <InfiniteScroll
//         dataLength={sendlist?.length ? sendlist?.length : 0}
//         next={() => seeMoreSendNetwork()}
//         hasMore={true}
//         loader={
//           <Box
//             sx={{
//               height: '40px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               cursor: 'pointer',
//             }}
//             onClick={seeMoreSendNetwork}></Box>
//         }
//         height={'calc(90vh - 75px)'}
//         endMessage={
//           <Box
//             sx={{
//               height: '40px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               cursor: 'pointer',
//             }}
//             onClick={seeMoreSendNetwork}>
//             <Box>End</Box>
//           </Box>
//         }>
//         <Grid container>
//           {sendlist?.length > 0 &&
//             sendlist?.map((list, index) => {
//               return (
//                 <Grid
//                   key={index}
//                   item
//                   xs={12}
//                   md={6}
//                   sx={{
//                     paddingRight: index % 2 === 0 ? '8px' : '0px',
//                     marginTop: '8px',
//                   }}>
//                   <NetworkCard userlist={list} type="sent" />
//                 </Grid>
//               );
//             })}

//           {sendlist?.length === 0 &&
//             !(
//               <Box p={2}>
//                 <Typography variant="h5" component="h5">
//                   Right now there are no sent connection requests.
//                 </Typography>
//               </Box>
//             )}
//         </Grid>
//       </InfiniteScroll>
//     </Box>
//   )}
// </Box>
// </TabPanel>
// <TabPanel value={2} sx={{ paddingBottom: '8px' }}>
// <Box
//   sx={{
//     marginTop: '0px',
//   }}>
//   {networkLoading ? (
//     <Fragment>
//       <Box>
//         <NetworkCardSkeleton />
//       </Box>
//       <Box pt={1}>
//         <NetworkCardSkeleton />
//       </Box>
//     </Fragment>
//   ) : (
//     <Box>
//       <InfiniteScroll
//         dataLength={existinglist?.length ? existinglist?.length : 0}
//         next={() => seeMoreExistingNetwork()}
//         hasMore={true}
//         loader={
//           <Box
//             sx={{
//               height: '40px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               cursor: 'pointer',
//             }}
//             onClick={seeMoreExistingNetwork}></Box>
//         }
//         height={'calc(90vh - 75px)'}
//         endMessage={
//           <Box
//             sx={{
//               height: '40px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               cursor: 'pointer',
//             }}
//             onClick={seeMoreExistingNetwork}>
//             <Box>End</Box>
//           </Box>
//         }>
//         <Grid container>
//           {existinglist?.length > 0 &&
//             existinglist?.map((list, index) => {
//               return (
//                 <Grid
//                   key={index}
//                   item
//                   xs={12}
//                   md={6}
//                   sx={{
//                     paddingRight: index % 2 === 0 ? '8px' : '0px',
//                     marginTop: '8px',
//                   }}>
//                   <NetworkCard userlist={list} type="sent" />
//                 </Grid>
//               );
//             })}

//           {existinglist?.length === 0 &&
//             !(
//               <Box p={2}>
//                 <Typography variant="h5" component="h5">
//                   Right now there are no connections.
//                 </Typography>
//               </Box>
//             )}
//         </Grid>
//       </InfiniteScroll>
//     </Box>
//   )}
// </Box>
// </TabPanel>
