import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import { useNavigate } from 'react-router-dom';
import * as messageActions from '../../store/message';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function PeopleuMayKnow(props) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const connect = async () => {
    if (user?.isVerified) {
      props?.connectNetwork(props?.network.id);
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const ignore = async () => {
    if (user?.isVerified) {
      props?.ignoreNetwork(props?.network.id);
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const profileOpen = async (first_name, last_name, id) => {
    if (user?.isVerified) {
      const profile = first_name + '-' + last_name + '-' + 'lx' + id;
      navigate('/user/' + profile);
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  useEffect(async () => {
    const data = {
      user_id: props?.network?.id,
    };
    // console.log('props?.network', props?.network);
    // const mutualfriend = await dispatch(usernetworkActions.discoverUserNetwork(data));
    // if(mutualfriend.Status === 1){
    //   setMutualFriend(mutualfriend?.data)
    // }
    // console.log('mutualfriend', mutualfriend);
  }, []);

  return (
    <Card
      sx={{
        backgroundColor: '#343232',
        borderRadius: '5px',
        backgroundImage: 'none',
        boxShadow: '0',
        marginBottom:'8px'
      }}>
      <CardHeader
        avatar={
          props?.network?.image ? (
            <Avatar
              src={props?.network?.image}
              sx={{
                width: 48,
                height: 48,
                cursor: 'pointer',
                marginTop: '-16px',
              }}
              onClick={() =>
                profileOpen(
                  props?.network?.first_name,
                  props?.network?.last_name,
                  props?.network?.id,
                )
              }
            />
          ) : (
            <Avatar
              sx={{
                width: 48,
                height: 48,
                cursor: 'pointer',
                marginTop: '-16px',
              }}
              onClick={() =>
                profileOpen(
                  props?.network?.first_name,
                  props?.network?.last_name,
                  props?.network?.id,
                )
              }>
              {props?.network?.first_name[0]}
            </Avatar>
          )
        }
        action={
          <Stack
            spacing={1}
            direction="column"
            sx={{ display: 'flex', alignItems: 'center', marginTop: '-4px' }}>
            <Box
              aria-label="close"
              size="small"
              sx={{
                backgroundColor: '#38CD84',
                cursor: 'pointer',
                marginBottom: '8px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '20px',
                height: '20px',
              }}
              onClick={ignore}>
              <CloseIcon
                size="small"
                fontSize="12px"
                sx={{ color: '#000000' }}
              />
            </Box>
          </Stack>
        }
        title={
          <Typography variant="h5s" component="h5s" sx={{ fontSize: '15px' }}>
            {props?.network?.first_name ? props?.network?.first_name : ''}{' '}
            {props?.network?.last_name &&
            props?.network?.last_name?.length +
              props?.network?.first_name?.length >
              20
              ? props?.network?.last_name?.slice(0, 1) + '...'
              : props?.network?.last_name}
          </Typography>
        }
        subheader={
          <Stack direction="column">
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: '12px', color: 'rgba(255, 255,255,0.5)' }}>
              {props?.network?.designation &&
              props?.network?.designation?.length +
                props?.network?.company_name?.length >
                30
                ? props?.network?.designation?.slice(0, 11) + '...'
                : props?.network?.designation}{' '}
              ||{' '}
              {props?.network?.designation &&
              props?.network?.designation?.length +
                props?.network?.company_name?.length >
                30
                ? props?.network?.company_name?.slice(0, 11) + '...'
                : props?.network?.company_name}
            </Typography>

            <Stack direction="row" justifyContent="space-between">
              <Box
                pl={1.3}
                pt={1}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  pt={0.5}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItem: 'center',
                    justifyContent: 'center',
                  }}>
                  {props?.network?.mutualFriends
                    ?.slice(0, 3)
                    .map((friend, index) => {
                      return (
                        <Avatar
                          key={index}
                          src={friend?.image}
                          sx={{
                            height: '20px',
                            border: '1.5px solid  #ffffff',
                            width: '20px',
                            marginLeft: '-10px',
                          }}
                        />
                      );
                    })}
                  {props?.network?.mutualFriends.length != 0 && (
                    <>
                      <Avatar
                        sx={{
                          height: '20px',
                          width: '20px',
                          marginLeft: '-10px',
                        }}
                      />
                      <Avatar
                        sx={{
                          height: '20px',
                          width: '20px',
                          marginLeft: '-10px',
                          border: '1.5px solid  #ffffff',
                          backgroundColor: '#343232',
                          fontSize: '10px',
                          color: '#ffffff',
                          fontWeight: '600',
                        }}>
                        {props?.network?.mutualFriends?.length}
                      </Avatar>
                    </>
                  )}

                  {props?.network?.mutualFriends.length != 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '0px',
                          paddingLeft: '8px',
                        }}>
                        <img alt="mutual" src={mutualfriends} />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box pt={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                <CxoButtonWithIcon
                  label="Connect"
                  width="112px"
                  minWidth="112px"
                  onClick={connect}
                />
              </Box>
            </Stack>
          </Stack>
        }
      />
    </Card>
  );
}
