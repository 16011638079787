import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import * as userActions from '../../store/user';
import * as messageActions from '../../store/message';
import 'react-quill/dist/quill.snow.css';
import CxoButton from '../../components/CxoButton/CxoButton';
import Icon from '../../assets/icon';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import IconButton from '@mui/material/IconButton';
import CxoInput from '../../components/CxoInput/CxoInput';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import Select from '@mui/material/Select';
import { Input } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { dateTime } from '../../constants/app';
import * as messageAction from '../../store/message';
import * as companyActions from '../../store/company';
import OtpInput from 'react-otp-input';
import DoneIcon from '@mui/icons-material/Done';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

function CheckboxCss({ label, icon, checkedIcon, value, onChange }) {
  console.log('CheckboxCss', value);
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          icon={icon}
          color="secondary"
          value={value}
          onChange={onChange}
          checkedIcon={checkedIcon}
        />
      }
    />
  );
}

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

const CompanyAccess = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState('Everyone');
  const [skill, setSkill] = useState('');
  const [interest, setInterest] = useState('');
  const [board, setBoard] = useState('');
  const [degree, setDegree] = useState('');
  const [passingyear, setPassingyear] = useState('');
  const [educationdetails, setEducationdetails] = useState('');
  const [otp, setOtp] = React.useState('');

  const [options, setOptions] = useState(2);
  const [pollend, setPollend] = useState(1);
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(40);
  const [toggleshare, setToggleshare] = useState(false);
  const [share, setShare] = useState('Everyone');
  const [domainCheck, setDomainCheck] = useState(false);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const addNewtype = useSelector((state) => state?.user?.AddNewType);
  const openCompany = useSelector((state) => state?.user?.openCompany);
  const user = useSelector((state) => state?.user?.UserDetails);
  const userabouts = useSelector((state) => state?.user?.UserAbouts);
  const otpSendSuccess = useSelector((state) => state?.company?.otpSendSuccess);
  const companyEmail = useSelector((state) => state?.company?.companyEmail);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const interestList = useSelector((state) => state.onboard.interestList);
  const [industrylst, setIndustrylst] = useState([]);
  const [jobtype, setJobType] = useState([]);
  const openCompanyAccess = useSelector(
    (state) => state?.company?.openCompanyAccess,
  );
  const openCompanydata = useSelector((state) => state?.company?.openCompany);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
  }, []);

  const addPost = async () => {};
  const handleChange = (event) => {
    setOtp(event);
  };
  const handleSkill = (event) => {
    setSkill(event.target.value);
  };

  const handleInterest = (event) => {
    setInterest(event.target.value);
  };

  const handleBoard = (event) => {
    setBoard(event.target.value);
  };
  const addSkills = async () => {
    const data = {
      id: 0,
      user_id: user?.id,
      title: skill,
    };
    const addskills = await dispatch(userActions.manageSkill(data));
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
    const snackbar = await dispatch(userActions.closeUpdateProfile());
  };

  const addInterest = async () => {
    const intert = [];
    intert.push(interest);
    const data = {
      id: 0,
      user_id: user?.id,
      aTitle: interest,
      platform: 'web',
    };
    console.log('interest', data);
    const addsInterest = await dispatch(userActions.manageInterest(data));
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
    const snackbar = await dispatch(userActions.closeUpdateProfile());
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(companyActions.closeCompanyAccess());
  };

  const addOption = () => {
    setOptions(options + 1);
  };
  const removeOptions = () => {
    if (options > 2) {
      setOptions(options - 1);
    }
  };
  const handleSubmitpoll = async (data) => {};

  const handlePollend = (event) => {
    setPollend(event.target.value);
  };
  const handleShare = (event) => {
    setShare(event.target.value);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const toggleShare = () => {
    setToggleshare(!toggleshare);
  };

  const handleKeyPress = () => {
    console.log('You pressed a key.');
  };

  const handleEducation = (event, type) => {
    if (type === 'Board') {
      setBoard(event.target.value);
    } else if (type === 'Degree') {
      setDegree(event.target.value);
    } else if (type === 'yearofpassing') {
      setPassingyear(event.target.value);
    } else if (type === 'Details') {
      setEducationdetails(event.target.value);
    }
  };

  const notAuthorize = () => {};

  const yesAuthorize = () => {
    setDomainCheck(true);
  };
  const handleSubmitform = async (data) => {
    const datafo = {
      email_id: data?.email,
      company_page_id: openCompanydata?.id,
      section_name: JSON.stringify(jobtype),
    };
    const otpsend = await dispatch(companyActions.accessCompanyCreate(datafo));
    const snackbar = await dispatch(companyActions.closeCompanyAccess());
  };

  const jobtypeChange = (event) => {
    setJobType(event.target.value);
  };

  const handlecheckOtp = async (data) => {
    // e.stopPropagation();
    console.log('otp,', data);
    const dataOtp = {
      otp: otp,
      email: companyEmail,
    };
    const verifyotp = await dispatch(companyActions.verifyCompanyOtp(dataOtp));
    navigate('/company/manage/page');
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '1200',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCompanyAccess}>
        <Box
          sx={{
            width: '60%',
            marginTop: '80px',
            maxWidth: '600px',
            backgroundColor: '#262525',
            borderRadius: '10px',
            '@media (max-width: 500px)': { width: '90%' },
          }}>
          <Box sx={{ width: '100%' }}>
            <Stack spacing={0} sx={{ width: '100%' }}>
              <Stack spacing={0} direction="row">
                <Box
                  px={3}
                  pt={2}
                  sx={{
                    paddingLeft: '72px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}></Box>
                <Box
                  px={3}
                  py={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CloseIcon
                    sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                    onClick={handleClose}
                  />
                </Box>
              </Stack>

              <Box
                // mt={2}
                px={2}
                pt={1}
                pb={1}
                sx={{ zIndex: '1200', borderRadius: '5px' }}
                className="editor">
                <Stack
                  direction="column"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '24px 32px 32px 48px',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                    }}>
                    <Typography variant="h2s" component="h2s">
                      Give Access To
                    </Typography>
                  </Box>
                  <Stack direction="column" justifyContent="center" spacing={1}>
                    <Box px={{ sm: 4, xs: 0 }} pt={2}>
                      <form onSubmit={handleSubmit(handleSubmitform)}>
                        <Stack spacing={1} direction="row">
                          <Box
                            pt={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  width: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                  marginLeft: '-5px',
                                  marginTop: '-12px',
                                  '@media (max-width: 500px)': {
                                    minWidth: '18px',
                                    height: '18px',
                                    marginLeft: '-8px',
                                    marginTop: '0px',
                                  },
                                }}>
                                <Menuicon name="email" color="#38CD84" />
                              </Box>
                            </Box>
                          </Box>

                          <Box sx={{ width: '100%' }}>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Stack>
                                  <CxoInputCss
                                    label="Email *"
                                    value={value}
                                    err={errors?.email ? errors?.email : false}
                                    onChange={onChange}
                                  />
                                  <Box
                                    sx={{
                                      fontSize: '9px',
                                      height: '10px',
                                      paddingLeft: '8px',
                                    }}>
                                    {errors?.email?.message}{' '}
                                  </Box>
                                </Stack>
                              )}
                              name="email"
                              required
                              rules={{
                                required: {
                                  value: true,
                                  message: 'Email is required',
                                },
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: 'invalid email address',
                                },
                                maxLength: {
                                  value: 60,
                                  message: 'Max 60 character allow',
                                },
                              }}
                              defaultValue=""
                            />
                          </Box>
                        </Stack>

                        <Stack spacing={1} direction="row">
                          <Box
                            pt={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                  '@media (max-width: 500px)': {
                                    height: '18px',
                                    minWidth: '18px',
                                  },
                                }}>
                                <DoneIcon color="secondary" />
                              </Box>
                            </Box>
                          </Box>
                          <Box py={1} pl={1} sx={{ width: '96%' }}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                style={{
                                  color: '#38CD84',
                                  fontSize: '13px',
                                }}
                                id="demo-simple-select-label">
                                Access To *
                              </InputLabel>
                              <CssSelect
                                MenuProps={{
                                  fontSize: '12px',
                                  disablePadding: true,
                                  PaperProps: {
                                    style: {
                                      maxHeight:
                                        ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                      width: 250,
                                    },
                                  },
                                  variant: 'menu',
                                  getContentAnchorEl: null,
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Function"
                                multiple
                                className={classes.underline}
                                value={jobtype}
                                onChange={jobtypeChange}
                                size="small">
                                <MenuItem key={0} value="posts">
                                  Posts
                                </MenuItem>
                                <MenuItem key={1} value="jobs">
                                  Jobs
                                </MenuItem>
                                <MenuItem key={2} value="events">
                                  Events
                                </MenuItem>
                              </CssSelect>
                            </FormControl>
                          </Box>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                          <CxoButton label="Submit" />
                        </Stack>
                      </form>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(CompanyAccess);
