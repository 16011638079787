import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';

import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';

import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const EditLanguage = (props) => {
  console.log('props', props);
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState('');
  const [reading, setReading] = React.useState('');
  const [language, setLanguage] = React.useState([
    'Arabic',
    'Chinese',
    'English',
    'French',
    'German',
    'Hindi',
    'Italian',
    'Portuguese',
    'Russian',
    'Spanish',
  ]);
  const [writing, setWriting] = React.useState('');
  const [speaking, setSpeaking] = React.useState('');
  const [type, setType] = React.useState('');
  const [tab, setTab] = React.useState(0);
  const [proficiency, setProficiency] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(new Date());
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };
  const handleProficiency = (event) => {
    setProficiency(event.target.value);
  };
  const handleReading = (event) => {
    setReading(event.target.value);
  };
  const handleWriting = (event) => {
    setWriting(event.target.value);
  };
  const handleSpeaking = (event) => {
    setSpeaking(event.target.value);
  };
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);

  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  const handleChange = (newValue) => {
    setType(newValue);
  };

  const onChange = (event) => {
    setValue(event.target.value);
  };
  const closeEdit = () => {};

  const handleSubmitform = async (data) => {
    console.log('data', data);
    const userlanguage = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      title: value,
      proficiency: proficiency,
      reading: reading,
      writing: writing,
      speaking: speaking,
    };
    if (
      value.length > 0 &&
      proficiency.length > 0 &&
      reading.length > 0 &&
      writing.length > 0 &&
      speaking.length > 0
    ) {
      const userlang = await dispatch(
        userActions.manageUserLanguage(userlanguage),
      );
      const userdata = {
        id: user?.id,
      };
      const usersabout = await dispatch(userActions.userProfileAbout(userdata));
      if (updateSection) {
        const snackbar = await dispatch(
          userActions.closeUpdateSectionProfile(),
        );
      }
      if (addNew) {
        const snackbaradd = await dispatch(userActions.closeUpdateProfile());
      }
    }
  };

  useEffect(() => {
    if (props?.section?.proficiency) {
      setProficiency(props?.section?.proficiency);
    }
  }, []);

  useEffect(() => {
    if (props?.section?.reading) {
      setReading(props?.section?.reading);
    }
  }, []);

  useEffect(() => {
    if (props?.section?.writing) {
      setWriting(props?.section?.writing);
    }
  }, []);

  useEffect(() => {
    if (props?.section?.speaking) {
      setSpeaking(props?.section?.speaking);
    }
  }, []);
  useEffect(() => {
    if (props?.section?.title) {
      setValue(props?.section?.title);
    }
  }, []);
  return (
    <Fragment>
      <Box px={3} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center  ',
              width: '100%',
            }}>
            <form
              onSubmit={handleSubmit(handleSubmitform)}
              style={{
                width: '100%',
              }}>
              <Box px={0.5} sx={{ width: '100%' }}>
                <Stack spacing={1} direction="row">
                  <Box py={0.5} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ color: '#38cd84' }}>
                        Language
                      </InputLabel>
                      <Select
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Language"
                        onChange={onChange}
                        size="small"
                        renderValue={(selected) => {
                          return selected;
                        }}>
                        {language
                          ? language.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item}>
                                  <ListItemText
                                    color="secondary"
                                    primary={item}
                                  />
                                  <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="secondary"
                                    checked={functionlst === item}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Box>

              <Box pb={0.5} pt={2} px={2} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <FormControl>
                    <FormLabel
                      color="secondary"
                      id="demo-row-radio-buttons-group-label">
                      Proficiency
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      color="secondary"
                      value={proficiency}
                      onChange={handleProficiency}>
                      <FormControlLabel
                        value="Beginner"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="Beginner"
                      />
                      <FormControlLabel
                        value="Good"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="Good"
                      />
                      <FormControlLabel
                        value="Professional"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="Professional"
                      />
                      <FormControlLabel
                        value="Expert"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="Expert"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              <Box pt={0.5} px={2} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <FormControl>
                    <FormLabel
                      color="secondary"
                      id="demo-row-radio-buttons-group-label">
                      Reading
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      color="secondary"
                      value={reading}
                      onChange={handleReading}>
                      <FormControlLabel
                        value="Yes"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              <Box pt={0.5} px={2} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <FormControl>
                    <FormLabel
                      color="secondary"
                      id="demo-row-radio-buttons-group-label">
                      Writing
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      color="secondary"
                      value={writing}
                      onChange={handleWriting}>
                      <FormControlLabel
                        value="Yes"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              <Box pt={0.5} px={2} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <FormControl>
                    <FormLabel
                      color="secondary"
                      id="demo-row-radio-buttons-group-label">
                      Speaking
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      color="secondary"
                      value={speaking}
                      onChange={handleSpeaking}>
                      <FormControlLabel
                        value="Yes"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        color="secondary"
                        control={<Radio color="secondary" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              <Box
                pt={1}
                pb={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CxoButton label="Add" type="submit" />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EditLanguage);
