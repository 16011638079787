import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logo: {
    cursor:"pointer",
    [theme.breakpoints.down("md")]: {
      height: "70px",
    },
    [theme.breakpoints.up("md")]: {
      height: "70px",      
      paddingLeft: "32px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "70px",
      paddingLeft: "52px",
    },
  },
  outergrid: {
    [theme.breakpoints.down("md")]: {
      minHeight: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "100vh",
    },
  },

  singupmessage:{
    [theme.breakpoints.down("md")]: {
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      height:" 88px",
      fontSize:"16px"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "120px",
      display:"flex",
      justifyContent:"start",
      alignItems:"center",
      height:"calc(100vh - 88px)",
      paddingBottom:"166px"
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "120px",
      display:"flex",
      justifyContent:"start",
      alignItems:"center",
      height:"calc(100vh - 88px)",
      paddingBottom:"166px"
    },
  }
}));
