import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import KeyboardArrowRightIcon from '../../resources/new-img/Right Arrow - White.png';
import KeyboardArrowLeftIcon from '../../resources/new-img/Left Arrow - White.png';
import Arijit from '../../resources/img/meet our speaker/Arijit.jpg';
import Dinesh from '../../resources/img/meet our speaker/Dinesh.jpg';
import Dhruv from '../../resources/img/meet our speaker/Dhruv.jpg';
import Rishi from '../../resources/img/meet our speaker/Rishi.jpeg';
;

class MeetOurSpeakers extends Component {
  constructor(props) {
    super();
    this.state = {
      allcardDataT: [
        {
          image: Arijit,
          description:
            'Arijit Bhattacharyya Entrepreneur. Angel Investor, Startup Mentor, Technologist, TEDx Speaker, Public Speaker. International Business Developer. Government Advisor. Globe Trotter.',
          name: 'Arijit Bhattacharya',
          designation: 'Founder & CEO',
          company: 'VirtualInfoCom',
        },

        {
          image: Dinesh,
          description:
            'Senior Director & Head – IPR80+ patent publications (Inventor)10+ Research Publications Ph.D in Pharmaceutical Sciences - UIPS',
          name: 'Dr. Dinesh Kumar',
          designation: 'Senior Director & Head',
          company: 'IPR	Jubilant Generics Ltd',
        },

        {
          image: Dhruv,
          description:
            'Author | Angel Investor | Director, Lead Angels Network | Professor at MDI Gurgaon | Formerly Senior Vice President with NIIT | Speaker | Mentor to Startups',
          name: 'Dhruv Nath',
          designation: 'Director',
          company: 'Lead angels network',
        },

        {
          image: Rishi,
          description:
            'Bestselling Author. Incubator, investor and mentor to early stage and growth companies. L.I.O.N. Senior executive. Former Multinational C-suite executive. ',
          name: 'Rishi Pipraiya',
          designation: 'CEO & Mentor',
          company: ' Capitalist	Imaginara Legacies',
        },
      ],
    };
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const CustomRight = ({ onClick }) => (
      <button onClick={onClick} className="custom-Right-Arrow2">
        <img
          alt=""
          src={KeyboardArrowRightIcon}
          style={{ width: '35px', height: '35px' }}
        />
      </button>
    );
    const CustomLeft = ({ onClick }) => (
      <button onClick={onClick} className="custom-Left-Arrow2">
        <img
          alt=""
          src={KeyboardArrowLeftIcon}
          style={{ width: '35px', height: '35px' }}
        />
      </button>
    );
    return (
      <Container fluid className="nl-main-speakers">
        <Row>
          <Col md={12} xs={12}>
            <h2
              className="color-green"
              style={{ paddingTop: '50px', paddingBottom: '10px' }}>
              Meet Our Speakers!
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={1} xs={12}></Col>
          <Col md={10} xs={12}>
            <Carousel
              customRightArrow={<CustomRight />}
              customLeftArrow={<CustomLeft />}
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={false}
              // responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={this.props.deviceType !== 'mobile' ? true : false}
              autoPlaySpeed={3000}
              // keyBoardControl={true}
              // customTransition="all .5"
              // transitionDuration={1000}
              containerClass="carousel-container"
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              className="main-meet-speaker-style-section1">
              {this.state.allcardDataT.map((item) => {
                return (
                  <div
                    style={{
                      padding: '0px 80px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    {' '}
                    <div className="meet-speaker-style-section">
                      <div className="card-image1-style-section">
                        <img
                          alt=""
                          src={item.image}
                          className="card-img1-style-section"
                        />
                      </div>

                      <div className="item-description1-style-section">
                        <p
                          className="all-card-name-style"
                          style={{ color: '#38cd84', fontSize: '20px' }}>
                          {item.name}{' '}
                        </p>
                        <p style={{ color: '#96B3DF', fontSize: '14px' }}>
                          {item.designation.length >= 30
                            ? item.designation.substring(0, 30) + '...'
                            : item.designation}
                          ,{' '}
                          {item.company.length >= 35
                            ? item.company.substring(0, 35) + '...'
                            : item.company}
                        </p>
                        <p className="lorem-ipsum-dolor-style-p1">
                          {item.description.length >= 230
                            ? item.description.substring(0, 230) + '...'
                            : item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
            <Col md={1} xs={12}></Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MeetOurSpeakers;
