import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/system';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import CorporatePackageCard from '../../components/CorporatePackageCard/CorporatePackageCard';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import * as rewardsActions from '../../store/rewards';
import * as messageActions from '../../store/message';
import Divider from '@mui/material/Divider';
import CxoButton from '../../components/CxoButton/CxoButton';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const Corporate = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [queryParameters] = useSearchParams();
  const [details, setDetails] = React.useState('');
  const [detailsPremium, setDetailsPremium] = React.useState('');
  const [detailsElite, setDetailsElite] = React.useState('');
  const [benefits, setBenefits] = React.useState([]);
  const [benefitsPremium, setBenefitsPremium] = React.useState([]);
  const [benefitsElite, setBenefitsElite] = React.useState([]);
  const [showpopup, setShowpopup] = React.useState(false);

  const user = useSelector((state) => state?.user?.UserDetails);

  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const membershipList = useSelector((state) => state?.rewards?.membershipList);
  const corporateLoading = useSelector(
    (state) => state?.rewards?.corporate_loading,
  );
  const corporatePackages = useSelector(
    (state) => state?.rewards?.corporatePackages,
  );

  const corporateMemberships = useSelector(
    (state) => state?.rewards?.corporateMemberships,
  );
  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );
  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  useEffect(() => {
    async function fetchData() {
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardsCategoryList(),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (queryParameters.get('visit') == 'new') {
      setShowpopup(true);
    } else {
      setShowpopup(false);
    }
  }, []);

  useEffect(() => {
    async function fetchDetails() {
      const data = {
        membership_id: 2,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
    }
    fetchData();
  }, []);

  useEffect(() => {
    function fetchData() {
      const type = params?.type;
      if (type === 'Elite') {
        setValue(1);
      } else if (type === 'Premium') {
        setValue(0);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCorporateData() {
      const response = await dispatch(rewardsActions.corporatePackages());
    }
    fetchCorporateData();
  }, []);

  useEffect(() => {
    async function fetchMembserhsipData() {
      const response = await dispatch(rewardsActions.corporateMembership());
    }
    fetchMembserhsipData();
  }, []);

  useEffect(() => {
    async function fetchMembserhsipProductData() {
      const response = await dispatch(
        rewardsActions.corporateMembershipProduct(),
      );
    }
    fetchMembserhsipProductData();
  }, []);

  const handleChange = async (event, newValue) => {
    if (user?.isVerified) {
      if (newValue === 2) {
        navigate('/corporate/partners');
        setValue(newValue);
      } else if (newValue === 0) {
        navigate('/corporate/packagetab/Premium');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/corporate/packagetab/Elite');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const buyMembership = () => {
    if (value === 1) {
      navigate('/corporate/packages/group/membership/Elite');
    } else if (value === 0) {
      navigate('/corporate/packages/group/membership/Premium');
    }
  };

  const handleClickOpen = () => {
    setShowpopup(true);
  };

  const handleClose = () => {
    setShowpopup(false);
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 1) {
        navigate('/corporate/packagetab/Premium');
        setValue(val);
      } else if (val === 2) {
        navigate('/corporate/partners');
        setValue(val);
      } else if (val === 0) {
        navigate('/corporate/packagetab/Elite');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = params?.type;
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });
      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );
      // let newArray = responsebenefits?.data.reduce((acc, cur) => {
      //   let { category_id, ...rest } = cur;
      //   let ex = acc.find((x) => x.category_id === category_id);
      //   if (!ex) {
      //     ex = { category_id, value: [] };
      //     acc.push(ex);
      //   }
      //   ex.value.push(rest);
      //   return acc;
      // }, []);

      // let newresult = responsebenefits?.data?.reduce(function (r, a) {
      //   r[a.category_id] = r[a.category_id] || [];
      //   r[a.category_id].push(a);
      //   return r;
      // }, Object.create(null));
      // console.log('result', newresult, newArray);
      // setBenefits(newArray);
      setDetails(responsedetails?.data[0]);
      setBenefits(JSON.parse(responsedetails?.data[0].details));
      console.log('benefits', benefits);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = 'Premium';
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });
      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );
      // let newArray = responsebenefits?.data.reduce((acc, cur) => {
      //   let { category_id, ...rest } = cur;
      //   let ex = acc.find((x) => x.category_id === category_id);
      //   if (!ex) {
      //     ex = { category_id, value: [] };
      //     acc.push(ex);
      //   }
      //   ex.value.push(rest);
      //   return acc;
      // }, []);

      // let newresult = responsebenefits?.data?.reduce(function (r, a) {
      //   r[a.category_id] = r[a.category_id] || [];
      //   r[a.category_id].push(a);
      //   return r;
      // }, Object.create(null));
      // console.log('result', newresult, newArray);
      // setBenefits(newArray);
      setDetailsPremium(responsedetails?.data[0]);
      setBenefitsPremium(JSON.parse(responsedetails?.data[0].details));
      console.log('benefits', benefits);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = 'Elite';
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });
      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );
      // let newArray = responsebenefits?.data.reduce((acc, cur) => {
      //   let { category_id, ...rest } = cur;
      //   let ex = acc.find((x) => x.category_id === category_id);
      //   if (!ex) {
      //     ex = { category_id, value: [] };
      //     acc.push(ex);
      //   }
      //   ex.value.push(rest);
      //   return acc;
      // }, []);

      // let newresult = responsebenefits?.data?.reduce(function (r, a) {
      //   r[a.category_id] = r[a.category_id] || [];
      //   r[a.category_id].push(a);
      //   return r;
      // }, Object.create(null));
      // console.log('result', newresult, newArray);
      // setBenefits(newArray);
      setDetailsElite(responsedetails?.data[0]);
      setBenefitsElite(JSON.parse(responsedetails?.data[0].details));
      console.log('benefits', benefits);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const type = params?.type;
    if (type == 'Elite') {
      setValue(1);
    } else if (type == 'Premium') {
      setValue(0);
    }
  }, []);

  const redirectToSection = (id, name) => {
    if (id > 0) {
      navigate('/rewards/partners/' + name + '-lx' + id);
    }
  };

  const redirectTocategory = (id) => {
    var result = rewardsCategoryList.filter((obj) => {
      return obj.id === id;
    });

    if (result[0]?.name === 'Real Estate') {
      const path =
        '/rewards/realestate/partners/' + result[0]?.name + '-' + 'lx' + id;
      navigate(path, { state: { category: result[0] } });
    } else {
      const path = '/rewards/partners/' + result[0]?.name + '-' + 'lx' + id;
      navigate(path, { state: { category: result[0]?.name } });
    }
  };

  const backgroundColorReturn = (id) => {
    if (id === 1) {
      return 'rgb(56, 205, 132, 0.5)';
    } else if (id === 2) {
      return 'rgb(218,208,216, 0.5)';
    } else if (id === 3) {
      return 'rgb(251, 188, 4, 0.5)';
    }
  };

  const perPrice = () => {
    if (value === 1) {
      console.log('benefitsElite', detailsElite);
      return (
        <Stack direction="row">
          {detailsElite?.price_in_doller !== NaN && (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  textDecoration: 'line-through',
                }}>
                $ {parseInt(detailsElite?.price_in_doller)} (₹{' '}
                {parseInt(detailsElite?.price)})
              </Typography>
            </>
          )}
          {detailsElite?.price_in_doller != NaN && (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  paddingLeft: '8px',
                }}>
                {' / '}${' '}
                {parseInt(
                  parseInt(detailsElite?.price_in_doller) -
                    parseInt(detailsElite?.price_in_doller) *
                      (detailsElite?.offer_per / 100),
                )}{' '}
                {'('} ₹{' '}
                {parseInt(
                  detailsElite?.price -
                    detailsElite?.price * (detailsElite?.offer_per / 100),
                )}
                {')'} {' '} Per Person
              </Typography>
            </>
          )}
        </Stack>
      );
    } else if (value === 0) {
      var dollar = parseInt(detailsPremium?.price_in_doller)
        ? parseInt(detailsPremium?.price_in_doller)
        : 0;
      return (
        <Stack direction="row">
          {detailsPremium?.price_in_doller != NaN && (
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '24px',
                lineHeight: '24px',
                color: '#fff',
                paddingBottom: '16px',
                textDecoration: 'line-through',
              }}>
              $ {dollar} (₹ {parseInt(detailsPremium?.price)}){' '}  
            </Typography>
          )}
          {detailsPremium?.price_in_doller != NaN && (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  paddingLeft: '8px',
                }}>
                {' / '}${' '}
                {parseInt(
                  parseInt(detailsPremium?.price_in_doller) -
                    parseInt(detailsPremium?.price_in_doller) *
                      (detailsPremium?.offer_per / 100),
                )}{' '}
                {'('}₹{' '}
                {parseInt(
                  detailsPremium?.price -
                    detailsPremium?.price * (detailsPremium?.offer_per / 100),
                )}
                {')'} {' '} Per Person
              </Typography>
            </>
          )}
        </Stack>
      );
    }
  };

  if (corporateLoading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Premium
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>

                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Elite
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Premium
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>

                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Elite
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    {/* <StyledTab onClick={() => changeTab(3)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 3 ? '#000000' : '#ffffff' }}>
                          Coupons
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 3 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab> */}
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Paper
                    sx={{
                      backgroundColor: '#262525',
                      backgroundImage: 'none',
                    }}>
                    {value == 1 && (
                      <Grid container>
                        {benefitsElite?.b2b?.map((benefit, index) => {
                          return (
                            <>
                              <Box
                                sx={{
                                  width: '100%',
                                  color: '#fff',
                                  padding: '6px 16px',
                                  cursor: 'pointer',
                                  margin: '2px 8px',
                                  backgroundColor: () =>
                                    backgroundColorReturn(
                                      benefit[Object.keys(benefit)[0]].package,
                                    ),
                                }}
                                onClick={() =>
                                  redirectToSection(
                                    benefit[Object.keys(benefit)[0]]
                                      .category_id,
                                    Object.keys(benefit)[0],
                                  )
                                }>
                                <Stack direction="column" spacing="3">
                                  <Typography
                                    variant="h2s"
                                    component="h2s"
                                    className={classes.tabname}
                                    sx={{
                                      color: '#000000',
                                      fontWeight: '600',
                                      paddingBottom: '4px',
                                    }}>
                                    {Object.keys(benefit)[0]}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    component="h5"
                                    className={classes.tabname}>
                                    {benefit[Object.keys(benefit)[0]].benefits}
                                  </Typography>
                                </Stack>
                              </Box>
                              <Divider />
                            </>
                          );
                        })}
                      </Grid>
                    )}
                    {value == 0 && (
                      <Grid container>
                        {benefitsPremium?.b2b?.map((benefit, index) => {
                          return (
                            <>
                              <Box
                                sx={{
                                  width: '100%',
                                  color: '#fff',
                                  padding: '6px 16px',
                                  cursor: 'pointer',
                                  margin: '2px 8px',
                                  backgroundColor: () =>
                                    backgroundColorReturn(
                                      benefit[Object.keys(benefit)[0]].package,
                                    ),
                                }}
                                onClick={() =>
                                  redirectToSection(
                                    benefit[Object.keys(benefit)[0]]
                                      .category_id,
                                    Object.keys(benefit)[0],
                                  )
                                }>
                                <Stack direction="column" spacing="3">
                                  <Typography
                                    variant="h2s"
                                    component="h2s"
                                    className={classes.tabname}
                                    sx={{
                                      color: '#000000',
                                      fontWeight: '600',
                                      paddingBottom: '4px',
                                    }}>
                                    {Object.keys(benefit)[0]}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    component="h5"
                                    className={classes.tabname}>
                                    {benefit[Object.keys(benefit)[0]].benefits}
                                  </Typography>
                                </Stack>
                              </Box>
                              <Divider />
                            </>
                          );
                        })}
                      </Grid>
                    )}
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        direction: 'row',
                        paddingLeft: '8px',
                        marginTop: '8px',
                        backgroundColor: '#3E3D3D',
                        alignItems: 'center',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}>
                      {' '}
                      <Box
                        px={2}
                        mb={1}
                        mt={2.5}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}>
                        {perPrice()} { ' ' } 
                      </Box>
                      <Box pb={1} pt={0.5} pr={2}>
                        <CxoButton
                          onClick={buyMembership}
                          label="Purchase Now"
                        />
                        {/* {details[0]?.id > 1 ? (
                            <CxoButton onClick={buyMembership} label="BUY" />
                          ) : (
                            <CxoButtonDisabled label="BUY" />
                        )} */}
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Fragment>
            {/* <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      {corporatePackages?.map((packages, index) => {
                        return (
                          <Grid
                            key={0}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: 0 % 2 === 0 ? '8px' : '0px',
                              marginTop: '8px',
                            }}>
                            <CorporatePackageCard
                              name={packages?.title}
                              type={packages?.title}
                              package={packages}
                            />
                          </Grid>
                        );
                      })}
                      {/* <Grid
                        key={0}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: 0 % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <CorporatePackageCard
                          name="Corporate Membership"
                          type="corporate_membership"
                        />
                      </Grid>
                      <Grid
                        key={0}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: 1 % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <CorporatePackageCard
                          icon="jobs"
                          name="Job Slots"
                          type="job_slots"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment> */}
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(Corporate);

// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab>
