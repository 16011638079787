import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Navbar from '../../../components/HomeHeader/navBar/navBar';
import Footer from '../../../components/HomeHeader/footer';
import PartnershipBanner from './PartnerBanner';
import Automobile from './Automobile';
import Apparel from './Apparel';
import Health from './Health';
import RealState from './RealEstate';
import Investment from './Investment';
import Entertainment from './Entertainment';
import Electronics from './Electronics';
import Resume from './Resume';
import Auto from '../../../resources/BenefitsLogos/automobile.png';
import App from '../../../resources/BenefitsLogos/apparel.png';
import Invest from '../../../resources/BenefitsLogos/investment.png';
import Hea from '../../../resources/BenefitsLogos/health.png';
import Elect from '../../../resources/BenefitsLogos/electronics.png';
import Enter from '../../../resources/BenefitsLogos/entertainment.png';
import Real from '../../../resources/BenefitsLogos/real-state.png';
import Res from '../../../resources/BenefitsLogos/resume.png';

const logos = [
  {
    src: Auto,
    alt: 'Automobile',
    text: 'Automobile',
    targetSection: 'automobile',
  },
  { src: App, alt: 'Apparel', text: 'Apparel', targetSection: 'apparel' },
  { src: Hea, alt: 'Health', text: 'Health', targetSection: 'health' },
  {
    src: Real,
    alt: 'Real State',
    text: 'Real State',
    targetSection: 'realState',
  },
  {
    src: Invest,
    alt: 'Investment',
    text: 'Investment',
    targetSection: 'investment',
  },
  {
    src: Enter,
    alt: 'Entertainment',
    text: 'Hospitality & Entertainment',
    targetSection: 'entertainment',
  },
  {
    src: Elect,
    alt: 'Electronics',
    text: 'Electronics',
    targetSection: 'electronics',
  },
  {
    src: Res,
    alt: 'Resume',
    text: 'Professional Profile Enhancement',
    targetSection: 'resume',
  },
];

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    height: '140px',
    margin: '0',
    cursor:'pointer'
  },
  frame: {
    background: 'rgba(56, 205, 132, 0.25);',
    border: '2px solid #38cd84',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  frame1: {
    width: '150px',
    height: '80px',
    display: 'flex',
    marginTop: '5%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  logo: {
    objectFit: 'contain',
    width: '36px',
    height: '36px',
    backgroundSize: 'cover',
  },
  text: {
    fontSize: '14px',
    color: '#38cd8',
    textAlign: 'center',
  },
}));

const RewardPartnership = () => {
  const classes = useStyles();
  const scrollRefs = {};
  const scrollToSection = (section) => {
    if (scrollRefs[section]) {
      const offset = scrollRefs[section].offsetTop - 63 ;
      window.scrollTo({ top: offset, behavior: 'smooth' });
    }
  };
  return (
    <>
      <Navbar />
      <PartnershipBanner />
      <Box
        mt={5}
        px={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {logos.map((logo, index) => (
          <Box
            mx={1.5}
            key={index}
            onClick={() => scrollToSection(logo.targetSection)}>
            <div className={classes.container}>
              <div className={classes.frame}>
                <img src={logo.src} alt={logo.alt} className={classes.logo} />
              </div>
              <div className={classes.frame1}>
                <p className={classes.text}>{logo.text}</p>
              </div>
            </div>
          </Box>
        ))}
      </Box>
      <div ref={(ref) => (scrollRefs.automobile = ref)}>
        <Automobile />
      </div>
      <div ref={(ref) => (scrollRefs.apparel = ref)}>
        <Apparel />
      </div>
      <div ref={(ref) => (scrollRefs.health = ref)}>
        <Health />
      </div>
      <div ref={(ref) => (scrollRefs.realState = ref)}>
        <RealState />
      </div>
      <div ref={(ref) => (scrollRefs.investment = ref)}>
        <Investment />
      </div>
      <div ref={(ref) => (scrollRefs.entertainment = ref)}>
        <Entertainment />
      </div>
      <div ref={(ref) => (scrollRefs.electronics = ref)}>
        <Electronics />
      </div>
      <div ref={(ref) => (scrollRefs.resume = ref)}>
        <Resume />
      </div>
      <Footer />
    </>
  );
};

export default RewardPartnership;
