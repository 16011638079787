import * as React from 'react';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { alpha, styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const CssTextField = styled(TextField)(({ theme }, props) => {
  return {
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-input': {
      fontFamily: 'Open Sans',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Open Sans',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
        fontFamily: 'Open Sans',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

export default function CxoInput(props) {
  return (
    <Box sx={{ '& > :not(style)': { m: 1 }, width: '100%' }}>
      <CssTextField
        id="input-with-sx"
        color="primary"
        error={props?.err ? props?.err : false}
        label={props?.label ? props.label : ''}
        variant="standard"
        size="small"
        value={props?.value}
        onChange={props?.onChange}
        sx={{
          width: '100%',
          fontFamily: 'Open Sans',
          fontVariantMumeric: 'Open Sans',
        }}
      />
    </Box>
  );
}
