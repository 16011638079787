import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import Chat from '../../assets/home/Chat_Active.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
import Menuicon from '../../assets/menuicon';
import EventsIcon from '../../assets/eventsicon';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Share from '../../assets/home/Share_White.png';
import credits_gold from '../../assets/header/Credits_Gold.png';
import Membership_Star_White from '../../assets/header/Membership_Star_White.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as jobsActions from '../../store/jobs';
import * as eventsActions from '../../store/events';
import * as activityActions from '../../store/activity';
import { useNavigate, useLocation } from 'react-router-dom';
import deleticon from '../../assets/home/Delete_White.png';
import invite from '../../assets/home/Invited_by_user_White.png';
import * as rewardsActions from '../../store/rewards';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';
import { FormatColorTextSharp } from '@mui/icons-material';

const RewardMembershipCard = (props) => {
  console.log('RewardMembershipCard', props);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const navigate = useNavigate();

  const membershipDetails = async () => {
    navigate('/rewards/membership/details/' + props?.membership?.type);
  };

  // props?.membership?.price_in_doller
  // props?.membership?.price?.toLocaleString()

  const membershipPrice = () => {
    if (props?.membership?.offer_per === 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <Stack direction="row">
            <Box
              pr={0.5}
              pt={0.5}
              sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  color: '#ffffff',
                  fontSize: '16px',
                  lineHeight: '16px',
                  fontWeight: '400',
                  fontFamily: 'Open Sans',
                  textDecoration: 'line-through',
                }}>
                {'$' +
                  parseInt(props?.membership?.price_in_doller) +
                  (' ( ₹' + props?.membership?.price?.toLocaleString() + ' ) ')}
              </Typography>
            </Box>
          </Stack>
        </Box>
      );
    } else if (
      props?.membership?.offer_per < 100 &&
      props?.membership?.offer_per > 0
    ) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '8px',
          }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              color: '#ffffff',
              fontSize: '16px',
              lineHeight: '27px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
              textDecoration: 'line-through',
            }}>
            {'$' +
              parseInt(props?.membership?.price_in_doller) +
              (' ( ₹' + props?.membership?.price?.toLocaleString() + ' ) ')}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: 'flex' }}>
          <Stack direction="column">
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: '#ffffff',
                fontSize: '22px',
                lineHeight: '27px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
              }}>
              {props?.membership?.id == 2 && '$ 399'}
              {props?.membership?.id == 3 && '$ 599'}
              {/* ₹ {props?.membership?.price?.toLocaleString()} */}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: '#ffffff',
                fontSize: '16px',
                lineHeight: '27px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
              }}>
              {props?.membership?.id == 2 && ' ₹ 32000 '}
              {props?.membership?.id == 3 && '  ₹ 48000 '}
              {/* ₹ {props?.membership?.price?.toLocaleString()} */}
            </Typography>
          </Stack>
        </Box>
      );
    }
  };

  const membershipPrice2 = () => {
    if (props?.membership?.offer_per === 100) {
      return (
        <Box sx={{ display: 'flex' }}>
          <Stack direction="column">
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: '#38CD84',
                fontSize: '22px',
                lineHeight: '27px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
                textDecoration: 'line-through',
              }}>
              {'$' + parseInt(props?.membership?.price_in_doller)}
            </Typography>
          </Stack>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              color: '#38CD84',
              fontSize: '16px',
              lineHeight: '27px',
              fontWeight: '600',
              fontFamily: 'Open Sans',
              marginLeft: '8px',
            }}>
            Free
          </Typography>
        </Box>
      );
    } else if (
      props?.membership?.offer_per < 100 &&
      props?.membership?.offer_per > 0
    ) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              color: '#38CD84',
              fontSize: '18px',
              lineHeight: '27px',
              fontWeight: '600',
              fontFamily: 'Open Sans',
              marginLeft: '0px',
            }}>
            ${' '}
            {parseInt(
              props?.membership?.price_in_doller -
                props?.membership?.price_in_doller *
                  (props?.membership?.offer_per / 100),
            ).toLocaleString()}
            {' ('}₹{' '}
            {(
              props?.membership?.price -
              props?.membership?.price * (props?.membership?.offer_per / 100)
            ).toLocaleString()}
            {')'}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: 'flex' }}>
          <Stack direction="column">
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: '#ffffff',
                fontSize: '22px',
                lineHeight: '27px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
              }}>
              {props?.membership?.id == 2 && '$ 399'}
              {props?.membership?.id == 3 && '$ 599'}
              {/* ₹ {props?.membership?.price?.toLocaleString()} */}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: '#ffffff',
                fontSize: '16px',
                lineHeight: '27px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
              }}>
              {props?.membership?.id == 2 && ' ₹ 32000 '}
              {props?.membership?.id == 3 && '  ₹ 48000 '}
              {/* ₹ {props?.membership?.price?.toLocaleString()} */}
            </Typography>
          </Stack>
        </Box>
      );
    }
  };

  const colorStart = () => {
    if (props?.membership?.id == 1) {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', color: '#38CD84' }}
        />
      );
    } else if (props?.membership?.id == 2) {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', color: '#C0C0C0' }}
        />
      );
    } else if (props?.membership?.id == 3) {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', color: '#FFC107' }}
        />
      );
    } else if (props?.membership?.id == 4) {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', color: '#A0B2C6' }}
        />
      );
    } else {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', color: '#38CD84' }}
        />
      );
    }
  };

  const typeMemberColor = () => {
    if (props?.membership?.id == 1) {
      return (
        <Typography
          variant="h6s"
          component="h6s"
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            letterSpacing: '0.31px',
            fontWeight: 600,
            textTransform: 'capitalize',
            fontFamily: 'Open Sans',
            color: '#38CD84',
          }}>
          {props?.membership?.type ? props?.membership?.type : ''}
        </Typography>
      );
    } else if (props?.membership?.id == 2) {
      return (
        <Typography
          variant="h6s"
          component="h6s"
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            letterSpacing: '0.31px',
            fontWeight: 600,
            textTransform: 'capitalize',
            fontFamily: 'Open Sans',
            color: '#C0C0C0',
          }}>
          {props?.membership?.type ? props?.membership?.type : ''}
        </Typography>
      );
    } else if (props?.membership?.id == 3) {
      return (
        <Typography
          variant="h6s"
          component="h6s"
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            letterSpacing: '0.31px',
            fontWeight: 600,
            textTransform: 'capitalize',
            fontFamily: 'Open Sans',
            color: '#FFC107',
          }}>
          {props?.membership?.type ? props?.membership?.type : ''}
        </Typography>
      );
    } else if (props?.membership?.id == 4) {
      return (
        <Typography
          variant="h6s"
          component="h6s"
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            letterSpacing: '0.31px',
            fontWeight: 600,
            textTransform: 'capitalize',
            fontFamily: 'Open Sans',
            color: '#A0B2C6',
          }}>
          {props?.membership?.type ? props?.membership?.type : ''}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h6s"
          component="h6s"
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            letterSpacing: '0.31px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: '#38CD84',
          }}>
          {props?.membership?.type ? props?.membership?.type : ''}
        </Typography>
      );
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        backgroundColor: '#262525',
        backgroundImage: 'none',
        justifyContent: 'space-between',
        minHeight: '171px',
        width: '100%',
      }}
      onClick={membershipDetails}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'flex-start',
          display: 'flex',
          width: '40%',
          maxWidth: { xs: 350, md: 250 },
          padding: '0px',
          objectFit: 'cover',
          backgroundColor: '#333',
          margin: '0px 0px 0px 0px',
          cursor: 'pointer',
        }}
        alt="">
        <Stack direction="column" sx={{ width: '100%', alignItems: 'center' }}>
          <Box
            sx={{
              width: '80px',
              height: '60px',
              backgroundColor: '#262525',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {colorStart()}
          </Box>
          <Box
            sx={{
              width: '0px',
              height: '0px',
              border: '40px solid',
              borderBottomColor: 'transparent',
              borderTopColor: '#262525',
              borderLeftColor: 'transparent',
              borderRightColor: 'transparent',
            }}></Box>
        </Stack>
      </Box>

      <Stack
        direction="column"
        sx={{
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          paddingLeft: '16px',
          width: '60%',
        }}>
        <Box
          pt={1}
          sx={{
            display: 'flex',
            alignItems: 'space-between',
            justifyContent: 'flex-start',
          }}>
          {typeMemberColor()}
        </Box>
        <Box>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontSize: '14px',
              lineHeight: '21px',
              letterSpacing: '0.22px',
              fontWeight: 400,
            }}>
            {props?.membership?.expiry_msg ? props?.membership?.expiry_msg : ''}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="h6" component="h6">
            {props?.membership?.id == 1 ? 'Complimentary' : ''}
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography variant="h6" component="h6"></Typography>
        </Box>
        <CardActions sx={{ width: '100%' }}>
          <Stack direction="column">
            <Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                }}>
                {membershipPrice2()}
              </Typography>
            </Box>
            <Stack
              direction="row"
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}>
              <Box pb={0}>{membershipPrice()}</Box>
              <Box pb={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CxoButtonWithIcon
                  label="Details"
                  onClick={membershipDetails}
                />
              </Box>
            </Stack>
          </Stack>
        </CardActions>
      </Stack>
    </Card>
  );
};

export default React.memo(RewardMembershipCard);
