import { apiUrl } from '../../services';
import { http } from '../../services/client';
import { showMessage } from '../message';

export const GET_PEOPLE_RECOMMENDATION_LIST_FULFILLED =
  'GET_PEOPLE_RECOMMENDATION_LIST_FULFILLED';
export const GET_PEOPLE_RECOMMENDATION_LIST_PENDING =
  'GET_PEOPLE_RECOMMENDATION_LIST_PENDING';
export const GET_PEOPLE_RECOMMENDATION_LIST_REJECTED =
  'GET_PEOPLE_RECOMMENDATION_LIST_REJECTED';

const getPeopleRecommendationListRequest = () => ({
  type: GET_PEOPLE_RECOMMENDATION_LIST_PENDING,
});

const getPeopleRecommendationListFailure = (error) => ({
  type: GET_PEOPLE_RECOMMENDATION_LIST_REJECTED,
  payload: { error: error },
});

const getPeopleRecommendationListSuccess = (data) => ({
  type: GET_PEOPLE_RECOMMENDATION_LIST_FULFILLED,
  payload: data,
});

export const getPeopleRecommendationList = (payload) => {
  return (dispatch) => {
    dispatch(getPeopleRecommendationListRequest());
    return http
      .post(apiUrl.GET_MY_NETWORK_LIST, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(getPeopleRecommendationListSuccess(response));
          return response;
        } else {
          dispatch(getPeopleRecommendationListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getPeopleRecommendationListFailure(error));
        return error;
      });
  };
};

export const GET_MY_NETWORK_LIST_FULFILLED = 'GET_MY_NETWORK_LIST_FULFILLED';
export const GET_MY_NETWORK_LIST_PENDING = 'GET_MY_NETWORK_LIST_PENDING';
export const GET_MY_NETWORK_LIST_REJECTED = 'GET_MY_NETWORK_LIST_REJECTED';
export const GET_MY_NETWORK_LIST_NEW_FULFILLED =
  'GET_MY_NETWORK_LIST_NEW_FULFILLED';
export const GET_MY_NETWORK_LIST_MORE_FULFILLED =
  'GET_MY_NETWORK_LIST_MORE_FULFILLED';

const getMyNetworkListRequest = (type) => ({
  type: GET_MY_NETWORK_LIST_PENDING,
  payload: { type: type },
});

const getMyNetworkListFailure = (error) => ({
  type: GET_MY_NETWORK_LIST_REJECTED,
  payload: { error: error },
});

const getMyNetworkListSuccess = (data, type) => ({
  type: GET_MY_NETWORK_LIST_FULFILLED,
  payload: { data: data, type: type },
});

export const getMyNetworkList = (payload, type) => {
  return (dispatch) => {
    dispatch(getMyNetworkListRequest(type));
    return http
      .post(apiUrl.GET_MY_NETWORK_LIST, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(getMyNetworkListSuccess(response, type));
          return response;
        } else {
          dispatch(getMyNetworkListFailure(response, type));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getMyNetworkListFailure(error, type));
        return error;
      });
  };
};
// export const getMyNetworkListold = (payload) => {
//   return (dispatch) => {
//     dispatch(getMyNetworkListRequest());
//     return new Promise((resolve, reject) => {
//       const token = getAccessToken();
//       API.http.post(
//         {
//           endPoint: apiUrl.GET_MY_NETWORK_LIST,
//           body: payload,
//           token: token,
//         },
//         (error, response) => {
//           if (error) {
//             console.log(error);
//             dispatch(getMyNetworkListFailure(error));
//             resolve(error);
//           } else {
//             if (response?.status === 1) {
//               dispatch(getMyNetworkListSuccess(response));
//               resolve(response);
//             } else {
//               dispatch(getMyNetworkListFailure(response));
//               resolve(response);
//             }
//           }
//         }
//       );
//     });
//   };
// };

export const SEND_CONNECTION_NETWORK_FULFILLED =
  'SEND_CONNECTION_NETWORK_FULFILLED';
export const SEND_CONNECTION_NETWORK_PENDING =
  'SEND_CONNECTION_NETWORK_PENDING';
export const SEND_CONNECTION_NETWORK_REJECTED =
  'SEND_CONNECTION_NETWORK_REJECTED';

const sendConnectionNetworkRequest = () => ({
  type: SEND_CONNECTION_NETWORK_PENDING,
});

const sendConnectionNetworkFailure = (error) => ({
  type: SEND_CONNECTION_NETWORK_REJECTED,
  payload: { error: error },
});

const sendConnectionNetworkSuccess = (data) => ({
  type: SEND_CONNECTION_NETWORK_FULFILLED,
  payload: data,
});

export const sendConnectionNetwork = (payload) => {
  return (dispatch) => {
    dispatch(sendConnectionNetworkRequest());
    return http
      .post(apiUrl.CONNECTION_NETWORK, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(sendConnectionNetworkSuccess(response));
          showMessage({
            dispatch,
            message: 'Connection Request send successfully',
            variant: 'success',
          });

          return response;
        } else {
          dispatch(sendConnectionNetworkFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(sendConnectionNetworkSuccess(error));
        return error;
      });
  };
};

export const SEND_CONNECTION_DISLIKE_FULFILLED =
  'SEND_CONNECTION_DISLIKE_FULFILLED';
export const SEND_CONNECTION_DISLIKE_PENDING =
  'SEND_CONNECTION_DISLIKE_PENDING';
export const SEND_CONNECTION_DISLIKE_REJECTED =
  'SEND_CONNECTION_DISLIKE_REJECTED';

const sendConnectionDislikeRequest = () => ({
  type: SEND_CONNECTION_DISLIKE_PENDING,
});

const sendConnectionDislikeFailure = (error) => ({
  type: SEND_CONNECTION_DISLIKE_REJECTED,
  payload: { error: error },
});

const sendConnectionDislikeSuccess = (data) => ({
  type: SEND_CONNECTION_DISLIKE_FULFILLED,
  payload: data,
});

export const sendConnectionDislike = (payload) => {
  return (dispatch) => {
    dispatch(sendConnectionDislikeRequest());
    return http
      .post(apiUrl.CONNECTION_DISLIKE, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(sendConnectionDislikeSuccess(response));
          return response;
        } else {
          dispatch(sendConnectionDislikeFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(sendConnectionDislikeFailure(error));
        return error;
      });
  };
};

export const GET_EXISTING_NETWORK_LIST_FULFILLED =
  'GET_EXISTING_NETWORK_LIST_FULFILLED';
export const GET_EXISTING_NETWORK_LIST_PENDING =
  'GET_EXISTING_NETWORK_LIST_PENDING';
export const GET_EXISTING_NETWORK_LIST_REJECTED =
  'GET_EXISTING_NETWORK_LIST_REJECTED';

const getExistingNetworkRequest = () => ({
  type: GET_EXISTING_NETWORK_LIST_PENDING,
});

const getExistingNetworkFailure = (error) => ({
  type: GET_EXISTING_NETWORK_LIST_REJECTED,
  payload: { error: error },
});

const getExistingNetworkSuccess = (data) => ({
  type: GET_EXISTING_NETWORK_LIST_FULFILLED,
  payload: data,
});

export const getExistingNetwork = (payload) => {
  return (dispatch) => {
    dispatch(getExistingNetworkRequest());
    return http
      .post(apiUrl.GET_EXISTING_NETWORK_LIST, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(getExistingNetworkSuccess(response));
          return response;
        } else {
          dispatch(getExistingNetworkSuccess([]));
          return response;
        }
      })
      .catch((error) => {
        if (error?.status === -1 && error?.message === 'No record found') {
          dispatch(getExistingNetworkSuccess(error));
          return error;
        } else {
          dispatch(getExistingNetworkFailure(error));
          return error;
        }
      });
  };
};

export const GET_PENDING_NETWORK_LIST_FULFILLED =
  'GET_PENDING_NETWORK_LIST_FULFILLED';
export const GET_PENDING_NETWORK_LIST_PENDING =
  'GET_PENDING_NETWORK_LIST_PENDING';
export const GET_PENDING_NETWORK_LIST_REJECTED =
  'GET_PENDING_NETWORK_LIST_REJECTED';

const getPendingNetworkRequest = () => ({
  type: GET_PENDING_NETWORK_LIST_PENDING,
});

const getPendingNetworkFailure = (error) => ({
  type: GET_PENDING_NETWORK_LIST_REJECTED,
  payload: { error: error },
});

const getPendingNetworkSuccess = (data) => ({
  type: GET_PENDING_NETWORK_LIST_FULFILLED,
  payload: data,
});

export const getPendingNetwork = (payload) => {
  return (dispatch) => {
    dispatch(getPendingNetworkRequest());
    return http
      .post(apiUrl.GET_PENDING_NETWORK_LIST, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(getPendingNetworkSuccess(response));
          return response;
        } else {
          dispatch(getPendingNetworkSuccess([]));
          return response;
        }
      })
      .catch((error) => {
        if (error?.status === -1 && error?.message === 'No record found') {
          dispatch(getPendingNetworkSuccess([]));
          return error;
        } else {
          dispatch(getPendingNetworkFailure(error));
          return error;
        }
      });
  };
};

export const GET_SENT_NETWORK_LIST_FULFILLED =
  'GET_SENT_NETWORK_LIST_FULFILLED';
export const GET_SENT_NETWORK_LIST_PENDING = 'GET_SENT_NETWORK_LIST_PENDING';
export const GET_SENT_NETWORK_LIST_REJECTED = 'GET_SENT_NETWORK_LIST_REJECTED';

const getSentNetworkRequest = () => ({
  type: GET_SENT_NETWORK_LIST_PENDING,
});

const getSentNetworkFailure = (error) => ({
  type: GET_SENT_NETWORK_LIST_REJECTED,
  payload: { error: error },
});

const getSentNetworkSuccess = (data) => ({
  type: GET_SENT_NETWORK_LIST_FULFILLED,
  payload: data,
});

export const getSentNetwork = (payload) => {
  return (dispatch) => {
    dispatch(getSentNetworkRequest());
    return http
      .post(apiUrl.GET_SENT_NETWORK_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getSentNetworkSuccess(response));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'No record found') {
            dispatch(getSentNetworkSuccess([]));
            return response;
          } else {
            dispatch(getSentNetworkFailure(response));
            return response;
          }
 
        }
      })
      .catch((error) => {
        if (error?.status === -1 && error?.message === 'No record found') {
          dispatch(getSentNetworkSuccess([]));
          return error;
        } else {
          dispatch(getSentNetworkFailure(error));
          return error;
        }
      });
  };
};

export const GET_NETWORK_FILTER_LIST_FULFILLED =
  'GET_NETWORK_FILTER_LIST_FULFILLED';
export const GET_NETWORK_FILTER_LIST_PENDING =
  'GET_NETWORK_FILTER_LIST_PENDING';
export const GET_NETWORK_FILTER_LIST_REJECTED =
  'GET_NETWORK_FILTER_LIST_REJECTED';

const getNetworkFilterRequest = () => ({
  type: GET_NETWORK_FILTER_LIST_PENDING,
});

const getNetworkFilterFailure = (error) => ({
  type: GET_NETWORK_FILTER_LIST_REJECTED,
  payload: { error: error },
});

const getNetworkFilterSuccess = (data) => ({
  type: GET_NETWORK_FILTER_LIST_FULFILLED,
  payload: data,
});

export const getNetworkFilter = (payload) => {
  return (dispatch) => {
    dispatch(getNetworkFilterRequest());
    return http
      .post(apiUrl.NETWORK_FILTER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getNetworkFilterSuccess(response));
          return response;
        } else {
          dispatch(getNetworkFilterFailure(response));
          return response;
        }
      })
      .catch((error) => {
        if (error?.status === -1 && error?.message === 'No record found') {
          dispatch(getNetworkFilterSuccess(error));
          return error;
        } else {
          dispatch(getNetworkFilterFailure(error));
          return error;
        }
      });
  };
};

export const ACCEPT_REQUEST_FULFILLED = 'ACCEPT_REQUEST_FULFILLED';
export const ACCEPT_REQUEST_PENDING = 'ACCEPT_REQUEST_PENDING';
export const ACCEPT_REQUEST_REJECTED = 'ACCEPT_REQUEST_REJECTED';

const acceptRequestRequest = () => ({
  type: ACCEPT_REQUEST_PENDING,
});

const acceptRequestFailure = (error) => ({
  type: ACCEPT_REQUEST_REJECTED,
  payload: { error: error },
});

const acceptRequestSuccess = (data) => ({
  type: ACCEPT_REQUEST_FULFILLED,
  payload: data,
});

export const acceptRequest = (payload) => {
  return (dispatch) => {
    dispatch(acceptRequestRequest());
    return http
      .post(apiUrl.ACCEPT_REQUEST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(acceptRequestSuccess(response));
          return response;
        } else {
          dispatch(acceptRequestSuccess([]));
          return response;
        }
      })
      .catch((error) => {
        dispatch(acceptRequestFailure(error));
        return error;
      });
  };
};

export const REQUEST_REJECT_FULFILLED = 'REQUEST_REJECT_FULFILLED';
export const REQUEST_REJECT_PENDING = 'REQUEST_REJECT_PENDING';
export const REQUEST_REJECT_REJECTED = 'REQUEST_REJECT_REJECTED';

const requestRejectRequest = () => ({
  type: REQUEST_REJECT_PENDING,
});

const requestRejectFailure = (error) => ({
  type: REQUEST_REJECT_REJECTED,
  payload: { error: error },
});

const requestRejectSuccess = (data) => ({
  type: REQUEST_REJECT_FULFILLED,
  payload: data,
});

export const requestReject = (payload) => {
  return (dispatch) => {
    dispatch(requestRejectRequest());
    return http
      .post(apiUrl.REQUEST_REJECT, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(requestRejectSuccess(response));
          return response;
        } else {
          dispatch(requestRejectFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(requestRejectFailure(error));
        return error;
      });
  };
};

export const FRIEND_REQUEST_REMOVE_FULFILLED =
  'FRIEND_REQUEST_REMOVE_FULFILLED';
export const FRIEND_REQUEST_REMOVE_PENDING = 'FRIEND_REQUEST_REMOVE_PENDING';
export const FRIEND_REQUEST_REMOVE_REJECTED = 'FRIEND_REQUEST_REMOVE_REJECTED';

const friendRequestRemoveRequest = () => ({
  type: FRIEND_REQUEST_REMOVE_PENDING,
});

const friendRequestRemoveFailure = (error) => ({
  type: FRIEND_REQUEST_REMOVE_REJECTED,
  payload: { error: error },
});

const friendRequestRemoveSuccess = (data) => ({
  type: FRIEND_REQUEST_REMOVE_FULFILLED,
  payload: data,
});

export const friendRequestRemove = (payload) => {
  return (dispatch) => {
    dispatch(friendRequestRemoveRequest());
    return http
      .post(apiUrl.REMOVE_PENDING_NETWORK, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(friendRequestRemoveSuccess(response));

          showMessage({
            dispatch,
            message: 'Connection Request withdraw successfully',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(friendRequestRemoveFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(friendRequestRemoveFailure(error));
        return error;
      });
  };
};

export const REMOVE_EXISTING_NETWORK_FULFILLED =
  'REMOVE_EXISTING_NETWORK_FULFILLED';
export const REMOVE_EXISTING_NETWORK_PENDING =
  'REMOVE_EXISTING_NETWORK_PENDING';
export const REMOVE_EXISTING_NETWORK_REJECTED =
  'REMOVE_EXISTING_NETWORK_REJECTED';

const removeExistingNetworkRequest = () => ({
  type: REMOVE_EXISTING_NETWORK_PENDING,
});

const removeExistingNetworkFailure = (error) => ({
  type: REMOVE_EXISTING_NETWORK_REJECTED,
  payload: { error: error },
});

const removeExistingNetworkSuccess = (data) => ({
  type: REMOVE_EXISTING_NETWORK_FULFILLED,
  payload: data,
});

export const removeExistingNetwork = (payload) => {
  return (dispatch) => {
    dispatch(removeExistingNetworkRequest());
    return http
      .post(apiUrl.REMOVE_EXISTING_NETWORK, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(removeExistingNetworkSuccess(response));
          return response;
        } else {
          dispatch(removeExistingNetworkFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(removeExistingNetworkFailure(error));
        return error;
      });
  };
};

export const DISCOVER_USER_NETWORK_FULFILLED =
  'DISCOVER_USER_NETWORK_FULFILLED';
export const DISCOVER_USER_NETWORK_PENDING = 'DISCOVER_USER_NETWORK_PENDING';
export const DISCOVER_USER_NETWORK_REJECTED = 'DISCOVER_USER_NETWORK_REJECTED';

const discoverUserNetworkRequest = () => ({
  type: DISCOVER_USER_NETWORK_PENDING,
});

const discoverUserNetworkFailure = (error) => ({
  type: DISCOVER_USER_NETWORK_REJECTED,
  payload: { error: error },
});

const discoverUserNetworkSuccess = (data) => ({
  type: DISCOVER_USER_NETWORK_FULFILLED,
  payload: data,
});

export const discoverUserNetwork = (payload) => {
  return (dispatch) => {
    dispatch(discoverUserNetworkRequest());
    return http
      .post(apiUrl.DISCOVER_USER, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(discoverUserNetworkSuccess(response));
          return response;
        } else {
          dispatch(discoverUserNetworkFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(discoverUserNetworkFailure(error));
        return error;
      });
  };
};

export const MY_CONNECTIONS_FULFILLED = 'MY_CONNECTIONS_FULFILLED';
export const MY_CONNECTIONS_PENDING = 'MY_CONNECTIONS_PENDING';
export const MY_CONNECTIONS_REJECTED = 'MY_CONNECTIONS_REJECTED';

const myConnectionsRequest = () => ({
  type: MY_CONNECTIONS_PENDING,
});

const myConnectionsFailure = (error) => ({
  type: MY_CONNECTIONS_REJECTED,
  payload: { error: error },
});

const myConnectionsSuccess = (data) => ({
  type: MY_CONNECTIONS_FULFILLED,
  payload: data,
});

export const myConnections = (payload) => {
  return (dispatch) => {
    dispatch(myConnectionsRequest());
    return http
      .post(apiUrl.MY_CONNECTIONS, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(myConnectionsSuccess(response));
          return response;
        } else {
          dispatch(myConnectionsFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(myConnectionsFailure(error));
        return error;
      });
  };
};

// //   =====================================Connect Api============================================
//
// export function ConnectionRequest(from_user_id,to_user_id ,datetime,token) {
//     return dispatch => {
//       dispatch({
//         type: 'CONNECTION_REQUEST_FULFILLED',
//         payload: client.post('/network/request',{from_user_id ,to_user_id,datetime}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// // ===================================Existing network Api ================================================
//
// export function GetExistingNetwork(user_id ,token) {
//     return dispatch => {
//       dispatch({
//         type: 'GET_EXISTING_NETWORK_LIST',
//         payload: client.post('/network/existing_friend_list',{user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// // =================================Pending-network-Api=======================================================
//
// export function GetPendingNetwork(user_id ,token) {
//     return dispatch => {
//       dispatch({
//         type: 'GET_PENDING_NETWORK_LIST',
//         payload: client.post('/network/pending_friend_list',{user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
//   // =================================Accept-Request-network-Api=======================================================
//
//   export function AcceptRequest(from_user_id,to_user_id,datetime,token) {
//     return dispatch => {
//       dispatch({
//         type: 'ACCEPT_REQUEST_FULFILLED',
//         payload: client.post('/network/request_accept',{from_user_id,to_user_id,datetime}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// // ===================================Sent network Api ================================================
//
// export function GetSentNetworklist(user_id ,token) {
//     return dispatch => {
//       dispatch({
//         type: 'GET_SENT_NETWORK_LIST',
//         payload: client.post('/network/sent_friend_list',{user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// //===================================Remove user from friend pending list=====================================
//
// export function RemovePendingNetworklist(from_user_id, to_user_id) {
//     return dispatch => {
//       dispatch({
//         type: 'REMOVE_PENDING_NETWORK',
//         payload: client.post('/network/connection_request_remove',{from_user_id, to_user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
// //===================================network dislike=====================================
//
// export function DislikeNetwork(from_user_id, to_user_id,datetime) {
//     return dispatch => {
//       dispatch({
//         type: 'DISLIKE_NETWORK',
//         payload: client.post('/network/dislike',{from_user_id, to_user_id,datetime}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// // ===========================================Remove users from existing friend list==================================
//
// export function RemoveExistingNetworklist(from_user_id, to_user_id) {
//     return dispatch => {
//       dispatch({
//         type: 'REMOVE_EXISTING_NETWORK_FULFILLED',
//         payload: client.post('/network/unfriend',{from_user_id, to_user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// // ==============================================Count friends list by status -user-network-count-api ==========================================
//
// export function UserNetworkCount(user_id) {
//     return dispatch => {
//       dispatch({
//         type: 'USER_NETWORK_COUNT',
//         payload: client.post('/network/user_network_count',{user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + JSON.parse(localStorage.getItem('User_Details')).token },
//         })
//       })
//     }
//   }
//
//
// //============================================Followers APi==================================================================================
//
// export function Followers(user_id,token) {
//     return dispatch => {
//       dispatch({
//         type: 'FOLLOWERS_FULFILLED',
//         payload: client.post('/network/followers',{user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// //================================================Follow User API=============================================================================
//
// export function FollowUser(from_user_id,to_user_id,datetime,token) {
//     return dispatch => {
//       dispatch({
//         type: 'FOLLOW_USER_FULFILLED',
//         payload: client.post('/network/follow_user',{from_user_id,to_user_id,datetime}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
// // =============================================All User List API=========================================================================
//
// export function GetAllUserList(user_id,token) {
//     return dispatch => {
//       dispatch({
//         type: 'GET_ALL_USER_LIST',
//         payload: client.post('/network/all_users_list',{user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
//
//
// //===================================List of all the other user recommendations for logged in user id=======================================
//
// export function  GetPeopleRecommendationList(user_id,token) {
//     return dispatch => {
//       dispatch({
//         type: 'GET_PEOPLE_RECOMMENDATION_LIST',
//         payload: client.post('/network/recommendation',{user_id}, {
//           params: {  },
//           headers: {Authorization:'Bearer ' + token },
//         })
//       })
//     }
//   }
