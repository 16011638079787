import palette from "./palette";
export default {
  useNextVariants: true,
  //htmlFontSize: 10,
  fontFamily: "Raleway",
  h2: {
    color: palette.text.primary,
    fontWeight: "500",
    lineHeight: "12px",
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "1px",
    fontFamily: "Raleway",
  },
  h2s: {
    color: palette.text.secondary,
    // fontWeight: "500",
    // letterSpacing: "1px",
    // fontFamily: "Raleway",
     fontWeight: "500",
    lineHeight: "12px",
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "1px",
    fontFamily: "Raleway",
  },
  h3: {
    color: palette.text.primary,
    fontWeight: "500",
    lineHeight: "11px",
    fontSize: "9px",
    letterSpacing: "0.14px",
    fontFamily: "Raleway",
  },
  h3s: {
    color: palette.text.secondary,
    fontWeight: "500",
    lineHeight: "14px",
    fontSize: "12px",
    letterSpacing: "0.14px",
    fontFamily: "Raleway",
  },
  cardsubtitle: {
    color: "#959498",
    fontWeight: "200",
    lineHeight: "11px",
    fontSize: "8px",
    letterSpacing: "0.13px",
    fontFamily: "Raleway",
  },
  cardsubtitles: {
    color: palette.text.secondary,
    fontWeight: "200",
    lineHeight: "9px",
    fontSize: "8px",
    letterSpacing: "0.13px",
    fontFamily: "Raleway",
  },
  h6: {
    color: palette.text.primary,
    fontFamily: "Raleway",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.32px",
  },
  h6s: {
    color: palette.text.secondary,
    fontFamily: "Raleway",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.32px",
  },
  h5: {
    color: palette.text.primary,
    fontFamily: "Raleway",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "1.05px",
  },
  h5s: {
    color: palette.text.secondary,
    fontFamily: "Raleway",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "1.05px",
  },
};
