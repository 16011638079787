import React, { Component, Fragment, useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

const EventCardSkeleton = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        backgroundColor: "#262525",
        backgroundImage: "none",
        marginBottom: "8px",
        // minHeight: "250px",
        width: "100%",
      }}
    >
      <Box>
        <Stack direction="row">
          <Skeleton variant="square" width={200} height={200} />
          <Stack direction="column">
            <Box px={4} py={2}>
              <Skeleton variant="square" width={200} height={20} />
            </Box>
            <Box px={4} py={1}>
              <Stack direction="row" spacing={10}>
                <Skeleton variant="square" width={100} height={20} />
                <Skeleton variant="square" width={100} height={20} />
              </Stack>
            </Box>
            <Box px={4} py={1}>
              <Stack direction="row" spacing={10}>
                <Skeleton variant="square" width={100} height={20} />
                <Skeleton variant="square" width={100} height={20} />
                <Skeleton variant="square" width={100} height={20} />
              </Stack>
            </Box>
            <Box px={4} py={1}>
              <Skeleton variant="square" width={400} height={40} />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default React.memo(EventCardSkeleton);
