import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import MenuItem from '@mui/material/MenuItem';
import JobCard from '../../components/JobCard/JobCard';
import JobSearch from './JobSearch';
import JobHire from './JobHire';
import JobApply from '../../components/JobApply/JobApply';
import JobProfileCard from '../../components/JobProfileCard/JobProfileCard';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Select from '@mui/material/Select';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';
import * as jobsActions from '../../store/jobs';
import * as messageActions from '../../store/message';
import SearchHeader from '../JobSearchContainer/SearchHeader';

const useStyles = makeStyles((theme) => ({}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);
const Jobs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { tab } = useParams();
  let location = useLocation();

  const user = useSelector((state) => state?.user?.UserDetails);
  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'open',
    };
    const jobs = await dispatch(jobsActions.getJobsList(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
    };
    // const jobs = await dispatch(jobsActions.getRecommendedList(data));
  }, []);

  useEffect(async () => {
    if (location.pathname === '/jobs/search') {
      setValue(0);
    } else if (location.pathname === '/jobs/hire') {
      setValue(1);
    } else {
      setValue(0);
    }
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      job_id: 475,
    };
    // const jobs = await dispatch(jobsActions.getSimilarList(data));
  }, []);

  const handleChange = async (event, newValue) => {
    console.log('newValue', newValue);

    if (user?.isVerified) {
      if (newValue === 0) {
        navigate('/jobs/search');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/jobs/hire');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const createJob = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      navigate('/job/create/');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <Fragment>
      <Box px={1} py={1}>
        <SearchHeader />
      </Box>
    </Fragment>
  );
};

export default React.memo(Jobs);
