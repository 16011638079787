import React from 'react';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import DrawerComponent from './drawer';
import Logo from '../../../resources/new-img/logo_golden_bkg.svg';
import {  useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as activityActions from '../../../store/activity';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  appbarnavstyle: {
    padding: '0 47px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  logo: {
    flexGrow: '1',
    cursor: 'pointer',
  },
  img: {
    marginLeft: "12px",
    width: 'auto',
    height: '70px',
  },
  menu: {
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '16px',
    padding: '0 15px',
    color: '#fff',
    backgroundColor: 'none',
    '&:hover': {
      textDecoration: 'none',
      textTransform: 'none',
      boxShadow: 'none',
      backgroundColor: 'none',
      color: '#fff',
    },
  },
  menu1: {
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '16px',
    padding: '5px 15px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#1C4586',
    '&:hover': {
      textDecoration: 'none',
      textTransform: 'none',
      boxShadow: 'none',
      backgroundColor: '#1C4586',
      color: '#fff',
    },
  },
  '@media (min-width: 900px)': {
    logo1: {
      display: 'none',
    },
    img1: {
      display: 'none',
    },
  },
  '@media (max-width: 900px)': {
    logo1: {
      float: 'right',
      marginLeft: '70%',
      flexGrow: '1',
      cursor: 'pointer',
    },
    logo: {
      display: 'none',
    },
    img1: {
      marginLeft: "60px",
      marginTop: '12px',
      width: '200px',
      height: 'auto',
    },
  },
  '@media (max-width: 680px)': {
    logo1: {
      float: 'right',
      marginLeft: '55%',
      flexGrow: '1',
      cursor: 'pointer',
    },
    logo: {
      display: 'none',
    },
    img1: {
      marginTop: '12px',
      width: '200px',
      height: 'auto',
    },
  },
  '@media (max-width: 468px)': {
    logo1: {
      marginLeft: '45%',
      flexGrow: '1',
      cursor: 'pointer',
    },
    appbarnavstyle: {
      paddingLeft: '0',
    },
    img1: {
      marginTop: '12px',
      width: '180px',
      height: 'auto',
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const redirectUrl = () => {
    if (location?.search?.length > 0) {
      let url = '/login' + location?.search;
      return url;
    } else if (location?.hash?.length > 0) {
      let path = location?.hash?.split('?');
      let final_url_hash = '/login' + '?' + path[1];
      return final_url_hash;
    } else {
      return '/login';
    }
  };

  const appendUrl = (url) => {
    if (location?.search?.length > 0) {
      let final_url = url + location?.search;
      return final_url;
    } else if (location?.hash?.length > 0) {
      let path = location?.hash?.split('?');
      let final_url_hash = url;
      if (path[1]) {
        final_url_hash = url + '?' + path[1];
      }
      return final_url_hash;
    } else {
      return url;
    }
  };

  const tempUserId = useSelector((state) => state?.activity?.tempUserId);
  React.useEffect(() => {
    async function postUserTrack() {
      if (location?.search?.includes('campaign')) {
        debugger;
        if (tempUserId == 0 || tempUserId == undefined) {
          const data = {
            tempUserId: uuidv4(),
          };
           await dispatch(activityActions.assignUserId(data));
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname?.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: data?.tempUserIdo,
          };
           await dispatch(activityActions.userTrack(data2));
        } else {
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: tempUserId,
          };
          await dispatch(activityActions.userTrack(data2));
        }
      }
    }
    postUserTrack();
  }, [location]);

  return (
    <AppBar
      className={classes.appbarnavstyle}
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#1F1E1E',
      }}>
      <CssBaseline />
      <Toolbar>
        <Box className={classes.logo1}>
          {' '}
          <Link to={appendUrl('/#home')}>
            {' '}
            <Box component="img" className={classes.img1} src={Logo} />
          </Link>
        </Box>
        <Box className={classes.logo}>
          {' '}
          <Link to={appendUrl('/#home')}>
            {' '}
            <Box component="img" className={classes.img} src={Logo} />
          </Link>
        </Box>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            {location.pathname.includes('/aboutus') ? (
              <Button>
                <Link
                  to="/aboutus"
                  className={classes.menu1}
                  onClick={scrollToTop}>
                  About Us
                </Link>
              </Button>
            ) : (
              <Button>
                <Link
                  to="/aboutus"
                  className={classes.menu}
                  onClick={scrollToTop}>
                  About Us
                </Link>
              </Button>
            )}
            <Button>
              <Link
                to="/#jobs"
                className={
                  `${location.pathname}${location.hash}` === '/#jobs'
                    ? classes.menu1
                    : classes.menu
                }>
                Jobs
              </Link>
            </Button>
            <Button>
              <Link
                to="/#events"
                className={
                  `${location.pathname}${location.hash}` === '/#events'
                    ? classes.menu1
                    : classes.menu
                }>
                Events
              </Link>
            </Button>
            {location.pathname.includes('/reward-partnership') ? (
              <Button>
                <Link
                  to="/reward-partnership"
                  className={classes.menu1}
                  onClick={scrollToTop}>
                  Rewards and Benefits
                </Link>
              </Button>
            ) : (
              <Button>
                <Link
                  to="/reward-partnership"
                  className={classes.menu}
                  onClick={scrollToTop}>
                  Rewards and Benefits
                </Link>
              </Button>
            )}{' '}
            {location.pathname.includes('/corporate') ? (
              <Button>
                <Link
                  to="/corporate"
                  className={classes.menu1}
                  onClick={scrollToTop}>
                  Corporate
                </Link>
              </Button>
            ) : (
              <Button>
                <Link
                  to="/corporate"
                  className={classes.menu}
                  onClick={scrollToTop}>
                  Corporate
                </Link>
              </Button>
            )}
            <Button
              sx={{
                textTransform: 'none',
                textDecoration: 'none',
                padding: '2px 20px',
                borderRadius: '5px ',
                color: '#262525',
                marginLeft: '10px',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#38CD84',
                '&:hover': {
                  backgroundColor: '#38CD84',
                  color: '#262525 ',
                },
                '@media (max-width: 1100px)': {
                  padding: '3px 15px',
                },
              }}>
              <Link
                to={redirectUrl()}
                className="nave-bar-join"
                id="nave-bar-join-id">
                Login
              </Link>
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
