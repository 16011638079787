import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Menuicon from '../../assets/menuicon';
import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';
import { dateTime } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import * as usernetworkActions from '../../store/usernetwork';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SearchNetworkCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const connectNetwork = async (connection) => {
    const data = {
      from_user_id: user?.id,
      to_user_id: connection,
      datetime: dateTime(),
    };
    const connectNetwork = await dispatch(
      usernetworkActions.sendConnectionNetwork(data),
    );
  };

  const redirectProfile = async (network) => {
    console.log('network', network);
    if (user?.isVerified) {
      const profile =
        network?.first_name +
        '-' +
        network?.last_name +
        '-' +
        'lx' +
        network?.id;
      console.log('profile', profile);
      navigate('/user/' + profile);
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  console.log('props', props.network);
  const friendlistIcon = (network) => {
    if (user?.id != network.id)
      return (
        <IconButton
          aria-label="addfriend"
          onClick={() => {
            connectNetwork(network.id);
          }}>
          <Menuicon name="addfriend" color="#ffffff" />
        </IconButton>
      );
    else {
      return <></>;
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: '#252525',
        backgroundImage: 'none',
        position: 'relative',
      }}>
      {props?.network?.map((network, index) => {
        return (
          <CardHeader
            key={index}
            avatar={
              <Avatar
                onClick={() => {
                  redirectProfile(network);
                }}
                src={network?.image ? network?.image : PersonIcon}
                sx={{ width: 48, height: 48, cursor: 'pointer' }}
              />
            }
            action={
              <Stack
                spacing={1}
                direction="row"
                sx={{ display: 'flex', alignItems: 'center' }}>
                {friendlistIcon(network)}
              </Stack>
            }
            title={
              <Typography variant="h5s" component="h5s">
                {network?.first_name} {network?.last_name}
              </Typography>
            }
            subheader={
              <Box>
                <Typography variant="h6" component="h6">
                  {network?.designation ? network?.designation : ''}
                </Typography>
                <Typography variant="h6" component="h6">
                  {network?.company_name ? network?.company_name : ''}
                </Typography>
              </Box>
            }
          />
        );
      })}
    </Card>
  );
}

// { props?.post?.document &&
//   <Document
// file= {props?.post?.document }
// onLoadSuccess={onDocumentLoadSuccess}
// >
// <Page pageNumber={pageNumber} />
// </Document>
//
// }
