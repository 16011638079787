import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import JobDetailsnew from "./JobDetailsnew";
import { useNavigate, useLocation } from "react-router-dom";

const JobDetailsWrapper = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectSignup = (data) => {};
  const handleOnCLose = () => {
    navigate(-1);
  };
  return (
    <JobDetailsnew item={location?.state?.item} handleOnCLose={handleOnCLose} />
  );
};

export default JobDetailsWrapper;
