import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  CLOSE_UNDER_VERIFICATION,
  OPEN_UNDER_VERIFICATION,
  NOTIFICATION_LIST_FULFILLED,
  NOTIFICATION_LIST_PENDING,
  NOTIFICATION_LIST_REJECTED,
} from './actions';

const initialSnackbarState = {
  notifications: [],
  message: '',
  openSnackbar: false,
  variant: 'success',
  underVerification: false,
  noOfClicks: 0,
  notificationList: [],
};

export const messagereducer = (state = initialSnackbarState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        variant: action.payload.variant,
        message: action.payload.message,
        openSnackbar: true,
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        variant: 'success',
        message: '',
        openSnackbar: false,
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.payload.key,
        ),
        message: '',
        openSnackbar: false,
      };
    case CLOSE_UNDER_VERIFICATION: {
      return {
        ...state,
        underVerification: false,
      };
    }
    case OPEN_UNDER_VERIFICATION: {
      return {
        ...state,
        noOfClicks: state.noOfClicks + 1,
        underVerification: true,
      };
    }
    case NOTIFICATION_LIST_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case NOTIFICATION_LIST_FULFILLED: {
      return {
        ...state,
        notificationList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case NOTIFICATION_LIST_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: {},
      };
    }

    default:
      return state;
  }
};
