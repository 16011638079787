import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
import Backdrop from '@mui/material/Backdrop';
import Tab from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import CxoButtonWithIcon from '../../components/CxoButton/CxoButton';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import * as messageAction from '../../store/message';
import RewardSubHeader from '../../components/RewardSubHeader/RewardSubHeader';
import * as rewardsActions from '../../store/rewards';
import * as userActions from '../../store/user';
import CxoButton from '../../components/CxoButton/CxoButton';
import credits_gold from '../../assets/header/Credits_Gold.png';
import { dateTime } from '../../constants/app';
import * as messageActions from '../../store/message';
import RewardVoucherCard from '../../components/RewardVoucherCard/RewardVoucherCard';

import StarIcon from '@mui/icons-material/Star';
import useRazorpay from 'react-razorpay';
import CloseIcon from '@mui/icons-material/Close';
import config from '../../services/apiConfig';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import moment from 'moment';

import * as activityActions from '../../store/activity';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: 'rgba(111, 106, 106, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(111, 106, 106, 0.25)',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
// }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#fff !important',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#fff !important',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '-2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const RewardVoucherDetails = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Razorpay = useRazorpay();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('');
  const [details, setDetails] = React.useState('');
  const [detailsBasic, setDetailsBasic] = React.useState('');
  const [detailsPremium, setDetailsPremium] = React.useState('');
  const [detailsElite, setDetailsElite] = React.useState('');
  const [benefits, setBenefits] = React.useState([]);
  const [benefitsBasic, setBenefitsBasic] = React.useState([]);
  const [benefitsPremium, setBenefitsPremium] = React.useState([]);
  const [membershipDetails, setMembershipDetails] = React.useState('');
  const [termdata, setTermdata] = React.useState({});
  const [showconfirmation, setShowConfirmation] = React.useState(false);
  const [showdetails, setShowDetails] = React.useState(false);
  const [addCoupon, setAddCoupon] = React.useState(false);
  const [addcouponShow, setAddCouponShow] = React.useState(false);
  const [currentMembership, setCurrentmembership] = React.useState('');
  const [couponCode, setcouponCode] = React.useState('');
  const [tabNumber, setTabNumber] = useState(3);
  const tempUserId = useSelector((state) => state?.activity?.tempUserId);
  const user = useSelector((state) => state?.user?.UserDetails);
  let param = useParams();
  let location = useLocation();
  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );
  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );
  const membershipUpgradePrice = useSelector(
    (state) => state?.rewards?.membershipUpgradePrice,
  );

  const membershipList = useSelector((state) => state?.rewards?.membershipList);

  const handleChange = async (event, newValue) => {
    if (user?.isVerified) {
      if (newValue === 0) {
        navigate('/rewards/membership/details/Basic');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/rewards/membership/details/Premium');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/membership/details/Elite');
        setValue(newValue);
      } else if (newValue === 3) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Stack
                    direction="row"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '48px',
                        maxWidth: 'min-content',
                        width: '48px',
                      }}>
                      <img alt="creditgold" src={credits_gold} />
                    </Box>

                    <Stack
                      direction="row"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '16px',
                      }}>
                      <Box pt={1} pb={0.5} px={1} sx={{ width: '100%' }}>
                        <Typography
                          variant="h2"
                          component="h2"
                          sx={{
                            color: '#F5BD02',
                            fontSize: '20px',
                            lineHeight: '27px',
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                          }}>
                          {rewardsTotalCredits?.credits
                            ? parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()
                            : parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()}
                        </Typography>
                      </Box>
                      <Box pt={0.5} pb={1} px={1}>
                        <Typography variant="h2" component="h2" sx={{}}>
                          Credits
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButton label="Details"   />
                </Box>
              </Stack>
            </Paper>
            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box p={1}>
          <Box sx={{ width: '100%' }}>
            <RewardSubHeader />
            {/* <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Membership
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Vouchers
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box> */}
            {/* <RewardTabs /> */}

            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                            Details
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          My Voucher
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>

                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>
            <Fragment>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default RewardVoucherDetails;

// {userButton()}
// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab> rzp_test_0uFcavHKXDrPCV
