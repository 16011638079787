import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import JobCard from '../../components/JobCard/JobCard';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import { dateTime } from '../../constants/app';
import JobCardSkeleton from '../../components/Skeleton/JobCardSkeleton';
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const JobTab = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  let location = useLocation();
  const navigate = useNavigate();
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );
  const recommendedLoading = useSelector(
    (state) => state?.jobs?.recommendedLoading,
  );
  const apliedLoading = useSelector((state) => state?.jobs?.apliedLoading);
  const savedJob = useSelector((state) => state?.jobs?.JobListSaved);
  const savedLoading = useSelector((state) => state?.jobs?.savedLoading);
  const appliedJob = useSelector((state) => state?.jobs?.JobListApplied);
  const [noofRecomended, setNoofRecomended] = React.useState(8);
  const [noofApplied, setNoofApplied] = React.useState(4);
  const [noofSaved, setNoofSaved] = React.useState(4);
  const [pageno, setPageno] = React.useState(0);
  const [appliedpageno, setAppliedpageno] = React.useState(0);
  const [savedpageno, setSavedpageno] = React.useState(0);
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    if (newValue === 'recomendation') {
      navigate('/jobs/search');
      setValue(newValue);
    } else if (newValue === 'applied') {
      navigate('/jobs/applied');
      setValue(newValue);
    } else if (newValue === 'saved') {
      navigate('/jobs/saved');
      setValue(newValue);
    } else {
      navigate('/jobs/search');
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (location.pathname === '/jobs/search') {
      setValue('recomendation');
    } else if (location.pathname === '/jobs/applied') {
      setValue('applied');
    } else if (location.pathname === '/jobs/saved') {
      setValue('saved');
    } else {
      setValue(0);
    }
  }, []);

  // useEffect(async () => {
  //   const data = {
  //     user_id: user.id,
  //     status: 'open',
  //   };
  //   const jobs = await dispatch(jobsActions.getJobsList(data));
  // }, []);

  useEffect(async () => {
    const data2 = {
      cand_id: user.id,
      page_no: appliedpageno,
    };

    const jobs2 = await dispatch(jobsActions.getJobListByStatusApplied(data2));
    const data = {
      cand_id: user.id,
      page_no: appliedpageno + 1,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
  }, []);

  useEffect(async () => {
    const data = {
      cand_id: user.id,
      status: 'saved',
    };
    const jobs = await dispatch(
      jobsActions.getJobListByStatusSaved(data, data.status),
    );
  }, []);

  useEffect(() => {
    async function fetchDataRec() {
      const data = {
        user_id: user.id,
        page_no: pageno,
      };
      const jobs = await dispatch(jobsActions.getRecommendedList(data));
    }
    fetchDataRec();
  }, []);
  const seeMore = () => {
    if (recommendedJob?.length > noofRecomended) {
      setNoofRecomended(recommendedJob?.length);
    } else if (recommendedJob?.length === noofRecomended) {
      setNoofRecomended(4);
    }
  };

  const seeMoreSaved = () => {
    if (savedJob?.length > noofSaved) {
      setNoofSaved(savedJob?.length);
    } else if (savedJob?.length === noofSaved) {
      setNoofSaved(4);
    }
  };

  const seeMoreRec = async () => {
    // if( recommendedJob?.length - 4 >= noofRecomended){
    //   debugger
    //   setNoofRecomended(noofRecomended + 4);
    // }
    // else{
    setPageno(pageno + 1);
    const data = {
      user_id: user.id,
      page_no: pageno + 1,
    };

    const jobs = await dispatch(jobsActions.getRecommendedList(data, 'new'));
    setNoofRecomended(noofRecomended + 4);
    // }aerch"

    // if (recommendedJob?.length > noofRecomended) {
    //   setNoofRecomended(recommendedJob?.length);
    // } else if (recommendedJob?.length === noofRecomended) {
    //   setNoofRecomended(4);
    // }
  };

  const seeMoreApllied = () => {
    if (appliedJob?.length > noofApplied) {
      setNoofApplied(appliedJob?.length);
    } else if (appliedJob?.length === noofApplied) {
      setNoofApplied(4);
    }
  };

  const seeMoreAppliedJob = async () => {
    setAppliedpageno(appliedpageno + 1);
    const data = {
      cand_id: user.id,
      page_no: appliedpageno,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
  };

  const apply = async () => {
    const data = {
      user_id: user.id,
    };
    // const jobstatus = await dispatch(jobsActions.manageJobStatus(data));
  };

  const saveJob = async (jobid) => {
    const data = {
      user_id: user.id,
      type: 'job',
      content_id: jobid,
      datetime: dateTime(),
    };
    // const bookpost = await dispatch(activityActions.saveBookmark(data));
  };

  return (
    <Fragment>
      <Box px={0} id="recommended">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="fullWidth"
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tab
            value="recomendation"
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    fontWeight: 400,
                    textTransform: 'none',
                    color: value === 'recomendation' ? '#38CD84' : '#ffffff',
                    '@media (max-width: 500px)': {
                      fontSize: '13px',
                      fontWeight: '400',
                    },
                  }}>
                  Recommended Jobs{' '}
                  {recommendedJob?.length > 0
                    ? '(' + recommendedJob[0].full_count + ')'
                    : ''}
                </Typography>
              </Box>
            }
          />
          <Tab
            value="applied"
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 400,
                    textTransform: 'none',
                    color: value === 'applied' ? '#38CD84' : '#ffffff',
                    '@media (max-width: 500px)': { fontSize: '16px' },
                  }}>
                  Applied{' '}
                  {appliedJob?.length
                    ? '(' + appliedJob[0]?.full_count + ')'
                    : ''}
                </Typography>
              </Box>
            }
          />
          <Tab
            value="saved"
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 400,
                    textTransform: 'none',
                    color: value === 'saved' ? '#38CD84' : '#ffffff',
                    '@media (max-width: 500px)': { fontSize: '16px' },
                  }}>
                  Saved {savedJob?.length ? '(' + savedJob?.length + ')' : ''}
                </Typography>
              </Box>
            }
          />
        </Tabs>

        <TabPanel value={value} index={'recomendation'}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {recommendedLoading ? (
              <JobCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={
                      recommendedJob?.length ? recommendedJob?.length : 0
                    }
                    next={() => seeMoreRec()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreRec}></Box>
                    }
                    height={
                      recommendedJob?.length > 2 ? 'calc(90vh - 70px)' : 'auto'
                    }
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreRec}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {recommendedJob?.length > 0 &&
                        recommendedJob?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <JobCard
                                joblist={list}
                                type="search"
                                apply={apply}
                                saveJob={saveJob}
                              />
                            </Grid>
                          );
                        })}
                      {recommendedJob?.length == 0 && (
                        <Box pl={3} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            Currently, there are no recommended jobs, please
                            check later.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={'applied'}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {apliedLoading ? (
              <JobCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={appliedJob?.length ? appliedJob?.length : 0}
                    next={() => seeMoreAppliedJob()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}></Box>
                    }
                    height={
                      appliedJob?.length > 2 ? 'calc(90vh - 100px)' : 'auto'
                    }
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreAppliedJob}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {appliedJob?.length > 0 &&
                        appliedJob?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <JobCard joblist={list} type="search_applied" />
                            </Grid>
                          );
                        })}
                      {appliedJob?.length == 0 && (
                        <Box pl={3} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            You've not applied to any job yet.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={'saved'}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {savedLoading ? (
              <JobCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={savedJob?.length ? savedJob?.length : 0}
                    next={() => seeMoreSaved()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => seeMoreSaved()}></Box>
                    }
                    height={savedJob?.length > 2 ? 'calc(90vh - 70px)' : 'auto'}
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => seeMoreSaved()}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {savedJob?.length > 0 &&
                        savedJob?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <JobCard joblist={list} type="search_saved" />
                            </Grid>
                          );
                        })}
                      {savedJob?.length == 0 && (
                        <Box pl={3} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            Currently, there are no jobs saved by you.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobTab);

{
  /* <Box
py={3}
px={3}
sx={{
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
  cursor: "pointer",
}}
onClick={seeMore}
>
{recommendedJob?.length > 4 ? (
  <Typography
    variant="h5"
    component="h5"
    sx={{
      fontWeight: 600,
      "@media (max-width: 500px)": {
        fontSize: "12px",
        fontWeight: "400",
      },
    }}
  >
    {recommendedJob?.length === noofRecomended
      ? "See Less"
      : "See All"}
  </Typography>
) : (
  <Typography variant="h5" component="h5"></Typography>
)}
</Box> */
}

{
  /* <Grid container>
{recommendedJob?.length > 0 &&
  recommendedJob?.slice(0, noofRecomended)?.map((list, index) => {
    return (
      <Grid
        key={index}
        item
        xs={12}
        md={6}
        sx={{
          paddingRight: index % 2 === 0 ? "8px" : "0px",
          marginTop: "8px",
        }}
      >
        <JobCard
          joblist={list}
          type="search"
          apply={apply}
          saveJob={saveJob}
        />
      </Grid>
    );
  })}
{recommendedJob?.length == 0 && (
  <Box pl={3} pt={3}>
    <Typography
      variant="h5"
      component="h5"
      sx={{
        "@media (max-width: 500px)": {
          fontSize: "12px",
        },
      }}
    >
      Currently, there are no recommended jobs, please check
      later.
    </Typography>
  </Box>
)}
</Grid>
 <Box
 py={2}
 sx={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   cursor: "pointer",
 }}
 onClick={seeMoreRec}
>
 <Typography variant="h5" component="h5">
  See More
 </Typography>
</Box> */
}
