import React, { Component, Fragment, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';
import * as clubsActions from '../../store/clubs';
import 'react-quill/dist/quill.snow.css';
import './editorStyle.css';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import ReactQuill from 'react-quill';
import HeaderEditor from './HeaderEditor';
import Divider from '@mui/material/Divider';
import { dateTime } from '../../constants/app';
const modulesheader = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formatsheader = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];
const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

const ArticleEditor = (props) => {
  const dispatch = useDispatch();
  const [articleBody, setArticlebody] = useState('');
  const [articleHeader, setArticleHeader] = useState('');
  const [hashkeyword, setKeywords] = useState('');
  const editArticle = useSelector((state) => state?.article?.ArticleEdit);
  const article = useSelector((state) => state?.article?.article);
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(10);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const user = useSelector((state) => state?.user?.UserDetails);
  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
    // console.log('myNetwork', myNetwork);
  }, []);

  const handleChange = (event) => {
    setArticlebody(event.target.value);
  };
  const handleHeader = (e) => {
    console.log(e.target.value);
    if (articleHeader.length < 160) {
      setArticleHeader(e.target.value.substring(0, 160));
    } else if (e.target.value.length < articleHeader.length) {
      setArticleHeader(e.target.value);
    }
  };

  const handlekeywordChange = (event) => {
    setKeywords(event.target.value);
  };

  const userPostDetails = async () => {
    if (clubDetails?.club_id) {
      const data = {
        user_id: user?.id,
        club_id: clubDetails?.club_id,
      };
      const clubdetails = await dispatch(clubsActions.clubPostList(data));
    } else {
      const data = {
        user_id: user?.id,
        club_id: clubDetails?.id,
      };
      const clubdetails = await dispatch(clubsActions.clubPostList(data));
    }
  };
  const addArticle = async () => {
    var m = new Date();
    var dateString =
      m.getUTCFullYear() +
      '-' +
      (m.getUTCMonth() + 1) +
      '-' +
      m.getUTCDate() +
      ' ' +
      m.getUTCHours() +
      ':' +
      m.getUTCMinutes() +
      ':' +
      m.getUTCSeconds();
    const formData = new FormData();

    if (article?.type === 'edit') {
      formData.append('id', article?.post?.id);
    } else {
      formData.append('id', 0);
    }

    formData.append('content', articleBody);
    formData.append('image', '');
    formData.append('user_id', user.id);
    formData.append('datetime', dateTime());
    formData.append('heading', articleHeader);
    formData.append('hash_tags', hashkeyword);
    formData.append('user_type', 'club');
    formData.append('user_table_pk', clubDetails.id);
    formData.append('is_published', 1);

    const headers = { 'Content-Type': 'multipart/form-data' };
    // const data = {
    //   id: 0,
    //   content: quilltext,
    //   image: "",
    //   user_id: user.id,
    //   datetime: dateString,
    //   document_type: "post",
    //   user_type: "user",
    //   user_table_pk: user.id,
    //   document: "",
    // };
    if (articleBody.length > 0) {
      if (user?.isVerified) {
        const addarticle = await dispatch(
          articleActions.sendAddNewArticleData(
            formData,
            async (error, response) => {
              if (response) {
                const data = {
                  user_id: user?.id,
                  start_item: startItem,
                  item_count: itemCount,
                  datetime: dateTime(),
                };
                setArticleHeader('');
                setArticlebody('');
                setKeywords('');

                const formData1 = new FormData();
                formData1.append('user_id', user?.id);
                formData1.append('start_item', startItem);
                formData1.append('item_count', itemCount);
                formData1.append('datetime', dateTime());

                const postlist = await dispatch(
                  postActions.getPostList(formData1, data),
                );

                const snackbar = await dispatch(
                  articleActions.closeArticleEdit(),
                );
                return userPostDetails();
              }
            },
          ),
        );
      } else {
        await dispatch(messageActions.openUnderVerification());
      }
    }
  };
  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const snackbar = await dispatch(articleActions.closeArticleEdit());
  };

  const handleSave = () => {};
  useEffect(async () => {
    if (article?.type === 'edit') {
      setArticleHeader(article?.post?.heading);
      setArticlebody(article?.post?.content);
      setKeywords(article?.post?.hash_tags);
    }
  }, []);
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}>
        <Box
          sx={{
            width: '70%',
            // height: '78vh',
            backgroundColor: '#333',
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h2s" component="h2s">
                  Compose an Article
                </Typography>
              </Box>
              <Box
                px={1}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <SaveOutlinedIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleSave}
                />
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>
            <Divider />
            <Box px={2} py={0.5}>
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={2}
                maxRows={3}
                value={articleHeader}
                onChange={handleHeader}
                placeholder="Add your Headline here"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Divider />
            <Box
              px={2}
              py={0.5}
              sx={{ width: '100%', zIndex: '1200' }}
              className="editor">
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={12}
                maxRows={14}
                value={articleBody}
                onChange={handleChange}
                placeholder="Start typing here..."
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Divider />
            {/* <Box
              pl={3}
              sx={{
                display: "flex",
                width: "100%",
                height: "48px",
                alignItems: "center",
              }}
            >
              <Stack spacing={3} direction="row">
                <Box
                  sx={{
                    display: "flex",
                    width: "248px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h2" component="h2">
                    Keywords/Tags: #
                  </Typography>
                </Box>
                <TextField
                  variant="standard"
                  fullWidth
                  multiline
                  rows={1}
                  maxRows={2}
                  value={hashkeyword}
                  onChange={handlekeywordChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Stack>
            </Box>
            <Divider /> */}
            <Box
              p={3}
              px={5}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}>
              <CxoButton label="Post" onClick={addArticle} />
            </Box>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(ArticleEditor);
