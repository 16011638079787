import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';

import JobCard from '../../components/JobCard/JobCard';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));

const SearchResult = (props) => {
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.UserDetails);
  const searchresult = useSelector((state) => state?.search?.SearchList);
  const jobfilterResult = useSelector((state) => state?.jobs?.jobfilteresult);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  useEffect(() => {}, []);

  const handleChange = (newValue) => {
    setType(newValue);
  };
  return (
    <Fragment>
      <Box p={2}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            <Grid container>
              {jobfilterResult?.length > 0 &&
                jobfilterResult?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <JobCard joblist={list} type="result" />
                    </Grid>
                  );
                })}
              {jobfilterResult?.length === 0 && (
                <Box pl={3} pt={1}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
                    Currently there are no filered jobs.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(SearchResult);

// <Typography
//   variant={type === "industryreport" ? "h2s" : "h2"}
//   component={type === "industryreport" ? "h2s" : "h2"}
// >
//   Report
// </Typography>

// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("events");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="events"
//       color={type === "events" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Typography
//       variant={type === "events" ? "h2s" : "h2"}
//       component={type === "events" ? "h2s" : "h2"}
//     >
//       Events
//     </Typography>
//   </Box>
// </Stack>

// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("clubs");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="clubs"
//       color={type === "clubs" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Typography
//       variant={type === "clubs" ? "h2s" : "h2"}
//       component={type === "clubs" ? "h2s" : "h2"}
//     >
//       Clubs
//     </Typography>
//   </Box>
// </Stack>

//
// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("industryreport");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="industryreport"
//       color={type === "industryreport" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Stack direction="column">
//       <Typography
//         variant={type === "industryreport" ? "h2s" : "h2"}
//         component={type === "industryreport" ? "h2s" : "h2"}
//       >
//         Industry
//       </Typography>
//     </Stack>
//   </Box>
// </Stack>

//
// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("newsletters");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="newsletters"
//       color={type === "newsletters" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Typography
//       variant={type === "newsletters" ? "h2s" : "h2"}
//       component={type === "newsletters" ? "h2s" : "h2"}
//     >
//       Newsletters
//     </Typography>
//   </Box>
// </Stack>

//
// {type === "All" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="clubs" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Clubs
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}
//
// {type === "clubs" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="clubs" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Clubs
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}

//
// {type === "All" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="events" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Events
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}
//
// {type === "events" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="events" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Events
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}
