import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import JobCard from '../../components/JobCard/JobCard';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import { dateTime } from '../../constants/app';
import JobCardSkeleton from '../../components/Skeleton/JobCardSkeleton';
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const JobHire = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  let location = useLocation();
  const navigate = useNavigate();
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );
  const recommendedLoading = useSelector(
    (state) => state?.jobs?.recommendedLoading,
  );
  const apliedLoading = useSelector((state) => state?.jobs?.apliedLoading);
  const savedJob = useSelector((state) => state?.jobs?.JobListSaved);
  const savedLoading = useSelector((state) => state?.jobs?.savedLoading);
  const appliedJob = useSelector((state) => state?.jobs?.JobListApplied);
  const [noofRecomended, setNoofRecomended] = React.useState(8);
  const [noofApplied, setNoofApplied] = React.useState(4);
  const [noofSaved, setNoofSaved] = React.useState(4);
  const [pageno, setPageno] = React.useState(0);
  const [appliedpageno, setAppliedpageno] = React.useState(0);
  const [value, setValue] = React.useState('one');

  const openJob = useSelector((state) => state?.jobs?.JobListOpen);
  const inactiveJob = useSelector((state) => state?.jobs?.JobListInactive);
  const jobListLoading = useSelector((state) => state?.jobs?.jobListLoading);

  const closedJob = useSelector((state) => state?.jobs?.JobListClosed);
  const [noofOpenjob, setNoofOpenjob] = React.useState(4);
  const [noofInactivejob, setNoofInactivejob] = React.useState(4);
  const [noofClosejob, setNoofClosejob] = React.useState(4);
  const [pageClosejob, setpageClosejob] = React.useState(0);
  const [pageOpenjob, setpageOpenjob] = React.useState(0);
  const [pageInactivejob, setpageInactivejob] = React.useState(0);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'open',
      page_no: 0,
    };
    const jobs = await dispatch(jobsActions.getJobsList(data));
    setpageOpenjob(0);
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'inactive',
      page_no: 0,
    };
    const jobs = await dispatch(jobsActions.getJobsListInactive(data));
    setpageInactivejob(0);
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'closed',
      page_no: 0,
    };
    const jobs = await dispatch(jobsActions.getJobsListClose(data));
    setpageClosejob(0);
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user.id,
    };
    // const jobs = await dispatch(jobsActions.getRecommendedList(data));
  }, []);

  const seeMoreInactive = () => {
    if (inactiveJob?.length > noofInactivejob) {
      setNoofInactivejob(inactiveJob?.length);
    } else if (inactiveJob?.length === noofInactivejob) {
      setNoofInactivejob(4);
    }
  };
  const seeMoreClose = () => {
    if (closedJob?.length > noofClosejob) {
      setNoofClosejob(closedJob?.length);
    } else if (closedJob?.length === noofClosejob) {
      setNoofClosejob(4);
    }
  };

  const seeMoreOpenJob = async () => {
    const data = {
      user_id: user.id,
      status: 'open',
      page_no: pageOpenjob + 1,
    };
    const jobs = await dispatch(jobsActions.getJobsList(data));
    setpageOpenjob(pageOpenjob + 1);
  };

  const seeMoreInactiveJob = async () => {
    const data = {
      user_id: user.id,
      status: 'inactive',
      page_no: pageInactivejob + 1,
    };
    const jobs = await dispatch(jobsActions.getJobsListInactive(data));
    setpageInactivejob(pageInactivejob + 1);
  };

  const seeMoreCloseJob = async () => {
    const data = {
      user_id: user.id,
      status: 'closed',
      page_no: pageClosejob + 1,
    };
    const jobs = await dispatch(jobsActions.getJobsListClose(data));
    setpageClosejob(pageClosejob + 1);
  };

  const handleChange = (event, newValue) => {
    if (newValue === 'open') {
      navigate('/jobs/hire');
      setValue(newValue);
    } else if (newValue === 'inactive') {
      navigate('/jobs/inactive');
      setValue(newValue);
    } else if (newValue === 'closed') {
      navigate('/jobs/closed');
      setValue(newValue);
    } else {
      navigate('/jobs/hire');
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (location.pathname === '/jobs/hire') {
      setValue('open');
    } else if (location.pathname === '/jobs/inactive') {
      setValue('inactive');
    } else if (location.pathname === '/jobs/closed') {
      setValue('closed');
    } else {
      setValue('open');
    }
  }, []);

  // useEffect(async () => {
  //   const data = {
  //     user_id: user.id,
  //     status: 'open',
  //   };
  //   const jobs = await dispatch(jobsActions.getJobsList(data));
  // }, []);

  useEffect(async () => {
    const data = {
      cand_id: user.id,
      page_no: appliedpageno,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
  }, []);

  useEffect(async () => {
    const data = {
      cand_id: user.id,
      status: 'saved',
    };
    const jobs = await dispatch(
      jobsActions.getJobListByStatusSaved(data, data.status),
    );
  }, []);

  useEffect(() => {
    async function fetchDataRec() {
      const data = {
        user_id: user.id,
        page_no: pageno,
      };
      const jobs = await dispatch(jobsActions.getRecommendedList(data));
    }
    fetchDataRec();
  }, []);

  const seeMore = () => {
    if (recommendedJob?.length > noofRecomended) {
      setNoofRecomended(recommendedJob?.length);
    } else if (recommendedJob?.length === noofRecomended) {
      setNoofRecomended(4);
    }
  };

  const seeMoreSaved = () => {
    if (savedJob?.length > noofSaved) {
      setNoofSaved(savedJob?.length);
    } else if (savedJob?.length === noofSaved) {
      setNoofSaved(4);
    }
  };

  const seeMoreRec = async () => {
    // if( recommendedJob?.length - 4 >= noofRecomended){
    //   debugger
    //   setNoofRecomended(noofRecomended + 4);
    // }
    // else{
    setPageno(pageno + 1);
    const data = {
      user_id: user.id,
      page_no: pageno + 1,
    };

    const jobs = await dispatch(jobsActions.getRecommendedList(data, 'new'));
    setNoofRecomended(noofRecomended + 4);
    // }aerch"

    // if (recommendedJob?.length > noofRecomended) {
    //   setNoofRecomended(recommendedJob?.length);
    // } else if (recommendedJob?.length === noofRecomended) {
    //   setNoofRecomended(4);
    // }
  };

  const seeMoreApllied = () => {
    if (appliedJob?.length > noofApplied) {
      setNoofApplied(appliedJob?.length);
    } else if (appliedJob?.length === noofApplied) {
      setNoofApplied(4);
    }
  };

  const seeMoreAppliedJob = async () => {
    const data = {
      cand_id: user.id,
      page_no: appliedpageno,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
    setAppliedpageno(appliedpageno + 1);
  };

  const apply = async () => {
    const data = {
      user_id: user.id,
    };
    // const jobstatus = await dispatch(jobsActions.manageJobStatus(data));
  };

  const saveJob = async (jobid) => {
    const data = {
      user_id: user.id,
      type: 'job',
      content_id: jobid,
      datetime: dateTime(),
    };
    // const bookpost = await dispatch(activityActions.saveBookmark(data));
  };

  return (
    <Fragment>
      <Box px={0} id="recommended">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="fullWidth"
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tab
            value="open"
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 400,
                    textTransform: 'none',
                    color: value === 'open' ? '#38CD84' : '#ffffff',
                  }}>
                  Open{' '}
                  {openJob?.length > 0 ? '(' + openJob[0].full_count + ')' : ''}
                </Typography>
              </Box>
            }
          />
          <Tab
            value="inactive"
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 400,
                    textTransform: 'none',
                    color: value === 'inactive' ? '#38CD84' : '#ffffff',
                  }}>
                  Inactive{' '}
                  {inactiveJob?.length > 0
                    ? '(' + inactiveJob[0].full_count + ')'
                    : ''}
                </Typography>
              </Box>
            }
          />
          <Tab
            value="closed"
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 400,
                    textTransform: 'none',
                    color: value === 'closed' ? '#38CD84' : '#ffffff',
                  }}>
                  Closed{' '}
                  {closedJob?.length > 0
                    ? '(' + closedJob[0].full_count + ')'
                    : ''}
                </Typography>
              </Box>
            }
          />
        </Tabs>

        <TabPanel value={value} index={'open'}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {jobListLoading ? (
              <JobCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={openJob?.length ? openJob?.length : 0}
                    next={() => seeMoreOpenJob()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreOpenJob}></Box>
                    }
                    height={openJob?.length > 2 ? 'calc(90vh - 70px)' : 'auto'}
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreOpenJob}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {openJob?.length > 0 &&
                        openJob?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <JobCard joblist={list} type="hire" />
                            </Grid>
                          );
                        })}
                      {openJob?.length == 0 && (
                        <Box pl={3} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            Currently there are no open jobs.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={'inactive'}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {apliedLoading ? (
              <JobCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={inactiveJob?.length ? inactiveJob?.length : 0}
                    next={() => seeMoreInactiveJob()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreInactive}></Box>
                    }
                    height={
                      inactiveJob?.length > 2 ? 'calc(90vh - 70px)' : 'auto'
                    }
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreInactive}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {inactiveJob?.length > 0 &&
                        inactiveJob?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <JobCard joblist={list} type="inactive" />
                            </Grid>
                          );
                        })}
                      {inactiveJob?.length == 0 && (
                        <Box pl={3} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            Currently there are no inactive jobs.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={'closed'}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {savedLoading ? (
              <JobCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={closedJob?.length ? closedJob?.length : 0}
                    next={() => seeMoreCloseJob()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreCloseJob}></Box>
                    }
                    height={
                      closedJob?.length > 2 ? 'calc(90vh - 70px)' : 'auto'
                    }
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreCloseJob}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {closedJob?.length > 0 &&
                        closedJob?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <JobCard joblist={list} type="closed" />
                            </Grid>
                          );
                        })}
                      {closedJob?.length == 0 && (
                        <Box pl={3} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            Currently there are no closed jobs.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobHire);

{
  /* <Box
py={3}
px={3}
sx={{
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
  cursor: "pointer",
}}
onClick={seeMore}
>
{recommendedJob?.length > 4 ? (
  <Typography
    variant="h5"
    component="h5"
    sx={{
      fontWeight: 600,
      "@media (max-width: 500px)": {
        fontSize: "12px",
        fontWeight: "400",
      },
    }}
  >
    {recommendedJob?.length === noofRecomended
      ? "See Less"
      : "See All"}
  </Typography>
) : (
  <Typography variant="h5" component="h5"></Typography>
)}
</Box> */
}

{
  /* <Grid container>
{recommendedJob?.length > 0 &&
  recommendedJob?.slice(0, noofRecomended)?.map((list, index) => {
    return (
      <Grid
        key={index}
        item
        xs={12}
        md={6}
        sx={{
          paddingRight: index % 2 === 0 ? "8px" : "0px",
          marginTop: "8px",
        }}
      >
        <JobCard
          joblist={list}
          type="search"
          apply={apply}
          saveJob={saveJob}
        />
      </Grid>
    );
  })}
{recommendedJob?.length == 0 && (
  <Box pl={3} pt={3}>
    <Typography
      variant="h5"
      component="h5"
      sx={{
        "@media (max-width: 500px)": {
          fontSize: "12px",
        },
      }}
    >
      Currently, there are no recommended jobs, please check
      later.
    </Typography>
  </Box>
)}
</Grid>
 <Box
 py={2}
 sx={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   cursor: "pointer",
 }}
 onClick={seeMoreRec}
>
 <Typography variant="h5" component="h5">
  See More
 </Typography>
</Box> */
}
