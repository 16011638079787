import { API, apiUrl } from "../../services";
import { http, http_noauth } from "../../services/client";
import { showMessage } from "../message";
import {
  getAccessToken,
} from "../../services/authData";

export const GET_POST_LIST_FULFILLED = "GET_POST_LIST_FULFILLED";
export const GET_POST_LIST_PENDING = "GET_POST_LIST_PENDING";
export const GET_POST_LIST_REJECTED = "GET_POST_LIST_REJECTED";

const getPostListPENDING = () => ({
  type: GET_POST_LIST_PENDING,
});

const getPostListFailure = (error) => ({
  type: GET_POST_LIST_REJECTED,
  payload: { error },
});

const getPostListSuccess = (data, payload) => ({
  type: GET_POST_LIST_FULFILLED,
  payload: { data: data, payload: payload },
});

export const getPostListJson = (payload) => {
  return (dispatch) => {
    dispatch(getPostListPENDING());
    return http
      .post(apiUrl.GET_POST_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getPostListSuccess(response, payload));
          return response;
        } else {
          dispatch(getPostListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getPostListFailure(error));
        return error;
      });
  };
};

export const getPostList = (payload, data) => {
  return (dispatch) => {
    dispatch(getPostListPENDING());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.GET_POST_LIST,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          console.log("error", error);
    

          // showMessage({
          //   dispatch,
          //   message: "Shared failed",
          //   variant: "error",
          // });
          // callback(error);
          return dispatch(getPostListFailure(error));
        } else {
          console.log("getPostListSuccess", response);

          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          // showMessage({
          //   dispatch,
          //   message: "Shared Successfully",
          //   variant: "success",
          // });
          // callback(null, response);
          return dispatch(getPostListSuccess(response, data));
        }
      }
    );
  };
};

// export const getPostList = (payload) => {
//   return (dispatch) => {
//     dispatch(getPostListPENDING());
//     return http
//       .post(apiUrl.GET_POST_LIST, payload)
//       .then((response) => {
//         if (response?.status === 1) {
//           dispatch(getPostListSuccess(response, payload));
//           return response;
//         } else {
//           dispatch(getPostListFailure(response));
//           return response;
//         }
//       })
//       .catch((error) => {
//         dispatch(getPostListFailure(error));
//         return error;
//       });
//   };
// };
export const getPostList2 = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "GET_POST_LIST",
      payload: http_noauth.post(apiUrl.GET_POST_LIST, payload, {
        params: {},
      }),
    });
  };
};

export const getPostListList = (payload) => {
  return (dispatch) => {
    dispatch(getPostListPENDING());
    return new Promise(async (resolve, reject) => {
      try {
        const response = await http_noauth.post(apiUrl.GET_POST_LIST, payload);
        console.log("response", response);
        if (response.status === -2) {
          dispatch(getPostListFailure(response));
          return response;
        }
      } catch (err) {
        if ("error_description" in err) throw err.error_description;
        throw err;
      }

      // API.http.post(
      //   {
      //     endPoint: apiUrl.GET_POST_LIST,
      //     body: payload,
      //     token: token,
      //   },
      //   (error, response) => {
      //     if (error) {
      //       console.log(error);
      //       dispatch(getPostListFailure(error));
      //       resolve(error);
      //     } else {
      //
      //       if (response?.status === 1) {
      //         dispatch(getPostListSuccess(response));
      //         resolve(response);
      //       } else {
      //         dispatch(getPostListFailure(response));
      //         resolve(response);
      //       }
      //     }
      //   }
      // );
    });
  };
};

export const POST_POST_LIKE_FULFILLED = "POST_POST_LIKE_FULFILLED";
export const POST_POST_LIKE_PENDING = "POST_POST_LIKE_PENDING";
export const POST_POST_LIKE_REJECTED = "POST_POST_LIKE_REJECTED";

const postPostLikePending = () => ({
  type: "POST_POST_LIKE_PENDING",
});

// const postPostLikeFailure = (error) => ({
//   type: "POST_POST_LIKE_REJECTED",
//   payload: { error },
// });

// const postPostLikeSuccess = (data, post) => ({
//   type: POST_POST_LIKE_FULFILLED,
//   payload: { data, post },
// });

export const postPostLike = (payload) => {
  return (dispatch) => {
    dispatch(postPostLikePending());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.POST_LIKE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          console.log("error", error);
          return error;
        } else {
          console.log("response", response);

          return response;
        }
      }
    );

    // return http
    //   .post(apiUrl.POST_LIKE, payload)
    //   .then((response) => {
    //     console.log("payload", response, payload);
    //     if (response?.status === 1) {
    //       dispatch(postPostLikeSuccess(response, payload));
    //       return response;
    //     } else {
    //       dispatch(postPostLikeFailure(response));
    //       return response;
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(postPostLikeFailure(error));
    //     return error;
    //   });
  };
};

const addNewPostPending = () => ({
  type: "SEND_ADD_NEW_POST_DATA_PENDING",
});

// const addNewPostFailure = (error) => ({
//   type: "SEND_ADD_NEW_POST_DATA_REJECTED",
//   payload: { error },
// });

// const addNewPostSuccess = (data) => ({
//   type: "SEND_ADD_NEW_POST_DATA_FULFILLED",
//   payload: data,
// });

// =====================Add-Post-Api==============================================
// require -- id,content,image ,user_id ,datetime, document_type ,user_type , user_table_pk

export const uploadPhoto = (formData, userId) => {
  return new Promise(async (resolve, reject) => {
    await API.post(
      {
        endPoint: apiUrl.UPLOAD_USER_PHOTO,
        body: formData,
        isFormData: true,
        queryParams: {
          userId,
        },
      },
      (error, url) => {
        if (error) {
          console.log("--error", error);
          reject(error);
        } else {
          resolve(url);
        }
      }
    );
  });
};

export const sendAddNewPostData = (payload, callback) => {
  return (dispatch) => {
    dispatch(addNewPostPending());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.ADD_NEW_POST,
        body: payload,
        token: token,
      },
      (error, response) => {
        if (error) {
          callback(error, null);
        } else {
          callback(null, response);
        }
      }
    );
    // .then((response) => {
    //   if (response?.status === 1) {
    //     dispatch(addNewPostSuccess(response));
    //     dispatch(closePostEdit());
    //     return response;
    //   } else {
    //     dispatch(addNewPostFailure(response));
    //     return response;
    //   }
    // })
    // .catch((error) => {
    //   dispatch(addNewPostFailure(error));
    //   return error;
    // });
  };
};

export const POST_POLL_FULFILLED = "POST_POLL_FULFILLED";
export const POST_POLL_PENDING = "POST_POLL_PENDING";
export const POST_POLL_REJECTED = "POST_POLL_REJECTED";

const postPollPending = () => ({
  type: "POST_POLL_PENDING",
});

const postPollFailure = (error) => ({
  type: "POST_POLL_REJECTED",
  payload: { error },
});

const postPollSuccess = (data, post) => ({
  type: POST_POLL_FULFILLED,
  payload: data,
});

export const postPoll = (payload, callback) => {
  return (dispatch) => {
    dispatch(postPollPending());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.ADD_NEW_POLL,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(postPollFailure(error));
          showMessage({
            dispatch,
            message: "Poll not created",
            variant: "error",
          });
          callback(error, null);
          return error;
        } else {
          console.log("response", response);
          dispatch(postPollSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          showMessage({
            dispatch,
            message: "Poll created",
            variant: "success",
          });
          callback(null, response);
          return response;
        }
      }
    );
  };
};
//
// export const postPoll = (payload) => {
//   return (dispatch) => {
//     dispatch(postPollPending());
//     return http
//       .post(apiUrl.ADD_NEW_POLL, payload)
//       .then((response) => {
//         console.log("payload", response, payload);
//         if (response?.status === 1) {
//           dispatch(postPollSuccess(response, payload));
//           showMessage({
//             dispatch,
//             message: "Poll Created",
//             variant: "success",
//           });
//           dispatch(closePollEdit());
//           return response;
//         } else {
//           dispatch(postPollFailure(response));
//           showMessage({
//             dispatch,
//             message: "Poll not created",
//             variant: "error",
//           });
//           return response;
//         }
//       })
//       .catch((error) => {
//         dispatch(postPollFailure(error));
//         showMessage({
//           dispatch,
//           message: "Poll not created",
//           variant: "error",
//         });
//         return error;
//       });
//   };
// };

export const POST_VOTE_POLL_FULFILLED = "POST_VOTE_POLL_FULFILLED";
export const POST_VOTE_POLL_PENDING = "POST_VOTE_POLL_PENDING";
export const POST_VOTE_POLL_REJECTED = "POST_VOTE_POLL_REJECTED";

const postVotePollPending = () => ({
  type: "POST_VOTE_POLL_PENDING",
});

const postVotePollFailure = (error) => ({
  type: "POST_VOTE_POLL_REJECTED",
  payload: { error },
});

const postVotePollSuccess = (data, post) => ({
  type: POST_VOTE_POLL_FULFILLED,
  payload: data,
});

export const postVotePoll = (payload) => {
  return (dispatch) => {
    dispatch(postVotePollPending());
    return http
      .post(apiUrl.MANAGE_VOTE, payload)
      .then((response) => {
        console.log("payload", response, payload);
        if (response?.status === 1) {
          dispatch(postVotePollSuccess(response, payload));
          showMessage({
            dispatch,
            message: "Poll Voted",
            variant: "success",
          });
          dispatch(postVotePollFailure());
          return response;
        } else {
          dispatch(postVotePollFailure(response));
          showMessage({
            dispatch,
            message: "Poll not created",
            variant: "error",
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(postVotePollFailure(error));
        showMessage({
          dispatch,
          message: "Poll not created",
          variant: "error",
        });
        return error;
      });
  };
};

export const GET_COMMENT_POST_LIST_FULFILLED =
  "GET_COMMENT_POST_LIST_FULFILLED";
export const GET_COMMENT_POST_LIST_PENDING = "GET_COMMENT_POST_LIST_PENDING";
export const GET_COMMENT_POST_LIST_REJECTED = "GET_COMMENT_POST_LIST_REJECTED";

const getPostCommentsPENDING = () => ({
  type: GET_COMMENT_POST_LIST_PENDING,
});

const getPostCommentsFailure = (error) => ({
  type: GET_COMMENT_POST_LIST_REJECTED,
  payload: { error },
});

const getPostCommentsSuccess = (data) => ({
  type: GET_COMMENT_POST_LIST_FULFILLED,
  payload: data,
});

export const getPostComments = (payload) => {
  return (dispatch) => {
    dispatch(getPostCommentsPENDING());
    return http
      .post(apiUrl.GET_COMMENT_POST_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getPostCommentsSuccess(response));
          return response;
        } else {
          dispatch(getPostCommentsFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getPostCommentsFailure(error));
        return error;
      });
  };
};

export const MANAGE_COMMENT_FULFILLED = "MANAGE_COMMENT_FULFILLED";
export const MANAGE_COMMENT_PENDING = "MANAGE_COMMENT_PENDING";
export const MANAGE_COMMENT_REJECTED = "MANAGE_COMMENT_REJECTED";

const manageCommentPending = (payload) => ({
  type: MANAGE_COMMENT_PENDING,
  payload: payload,
});

const manageCommentFailure = (error) => ({
  type: MANAGE_COMMENT_REJECTED,
  payload: { error },
});

const manageCommentSuccess = (data) => ({
  type: MANAGE_COMMENT_FULFILLED,
  payload: data,
});

export const manageComment = (payload) => {
  return (dispatch) => {
    dispatch(manageCommentPending(payload));
    return http
      .post(apiUrl.MANAGE_COMMENT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(manageCommentSuccess(response));
          return response;
        } else {
          dispatch(manageCommentFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(manageCommentFailure(error));
        return error;
      });
  };
};

export const DELETE_COMMENT_FULFILLED = "DELETE_COMMENT_FULFILLED";
export const DELETE_COMMENT_PENDING = "DELETE_COMMENT_PENDING";
export const DELETE_COMMENT_REJECTED = "DELETE_COMMENT_REJECTED";

const deleteCommentPending = (payload) => ({
  type: DELETE_COMMENT_PENDING,
  payload: payload,
});

const deleteCommentFailure = (error) => ({
  type: DELETE_COMMENT_REJECTED,
  payload: { error },
});

const deleteCommentSuccess = (data) => ({
  type: DELETE_COMMENT_FULFILLED,
  payload: data,
});

export const deleteComment = (payload) => {
  return (dispatch) => {
    dispatch(deleteCommentPending(payload));
    return http
      .post(apiUrl.DELETE_COMMENT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(deleteCommentSuccess(response));
          return response;
        } else {
          dispatch(deleteCommentFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(deleteCommentFailure(error));
        return error;
      });
  };
};

export const CONTENT_ACTION_FULFILLED = "CONTENT_ACTION_FULFILLED";
export const CONTENT_ACTION_PENDING = "CONTENT_ACTION_PENDING";
export const CONTENT_ACTION_REJECTED = "CONTENT_ACTION_REJECTED";

const contentActionPending = () => ({
  type: CONTENT_ACTION_PENDING,
});

const contentActionFailure = (error) => ({
  type: CONTENT_ACTION_REJECTED,
  payload: { error },
});

const contentActionSuccess = (data) => ({
  type: CONTENT_ACTION_FULFILLED,
  payload: data,
});

export const contentAction = (payload) => {
  return (dispatch) => {
    dispatch(contentActionPending());
    return http
      .post(apiUrl.CONTENT_ACTION, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(contentActionSuccess(response));
          if (payload?.status === "hide") {
            showMessage({
              dispatch,
              message: "Hide Successfully",
              variant: "success",
            });
          } else if (payload?.status === "report") {
            showMessage({
              dispatch,
              message: "Reported Successfully",
              variant: "success",
            });
          }
          return response;
        } else {
          dispatch(contentActionFailure(response));
          showMessage({
            dispatch,
            message: "Server Error",
            variant: "error",
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(contentActionFailure(error));
        showMessage({
          dispatch,
          message: "Failed",
          variant: "error",
        });
        return error;
      });
  };
};

export const SHARE_POST_FULFILLED = "SHARE_POST_FULFILLED";
export const SHARE_POST_PENDING = "SHARE_POST_PENDING";
export const SHARE_POST_REJECTED = "SHARE_POST_REJECTED";

const sharePostPending = () => ({
  type: SHARE_POST_PENDING,
});

const sharePostFailure = (error) => ({
  type: SHARE_POST_REJECTED,
  payload: { error },
});

const sharePostSuccess = (data) => ({
  type: SHARE_POST_FULFILLED,
  payload: data,
});

export const sharePost = (payload) => {
  return (dispatch) => {
    dispatch(sharePostPending());
    return http
      .post(apiUrl.SHARE_POST, payload)
      .then((response) => {
        console.log("response", response);
        if (response?.status === 1) {
          dispatch(sharePostSuccess(response));
          showMessage({
            dispatch,
            message: "Shared Successfully",
            variant: "success",
          });
          return response;
        } else {
          dispatch(sharePostFailure(response));
          showMessage({
            dispatch,
            message: "Sharing Failed",
            variant: "error",
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(sharePostFailure(error));
        showMessage({
          dispatch,
          message: "Sharing Failed",
          variant: "error",
        });
        return error;
      });
  };
};

export const DELETE_POST_FULFILLED = "DELETE_POST_FULFILLED";
export const DELETE_POST_PENDING = "DELETE_POST_PENDING";
export const DELETE_POST_REJECTED = "DELETE_POST_REJECTED";

const deletePostPending = () => ({
  type: DELETE_POST_PENDING,
});

const deletePostFailure = (error) => ({
  type: DELETE_POST_REJECTED,
  payload: { error },
});

const deletePostSuccess = (data) => ({
  type: DELETE_POST_FULFILLED,
  payload: data,
});

export const deletePost = (payload) => {
  return (dispatch) => {
    dispatch(deletePostPending());
    return http
      .post(apiUrl.DELETE_POST, payload)
      .then((response) => {
        console.log("response", response);
        if (response?.status === 1) {
          dispatch(deletePostSuccess(response));
          showMessage({
            dispatch,
            message: "Delete Post Successfully",
            variant: "success",
          });
          return response;
        } else {
          dispatch(deletePostSuccess(response));
          showMessage({
            dispatch,
            message: "Delete Failed",
            variant: "error",
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(deletePostFailure(error));
        showMessage({
          dispatch,
          message: "Sharing Failed",
          variant: "error",
        });
        return error;
      });
  };
};

export const CLOSE_POST_EDIT = "CLOSE_POST_EDIT";
export const OPEN_POST_EDIT = "OPEN_POST_EDIT";

export const closePostEdit = () => ({
  type: "CLOSE_POST_EDIT",
});

export const openPostEdit = () => ({
  type: "OPEN_POST_EDIT",
});

export const CLOSE_POLL_EDIT = "CLOSE_POLL_EDIT";
export const OPEN_POLL_EDIT = "OPEN_POLL_EDIT";

export const closePollEdit = () => ({
  type: "CLOSE_POLL_EDIT",
});

export const openPollEdit = () => ({
  type: "OPEN_POLL_EDIT",
});

export const CLOSE_REPORT_POST = "CLOSE_REPORT_POST";
export const OPEN_REPORT_POST = "OPEN_REPORT_POST";

export const closeReportPost = () => ({
  type: "CLOSE_REPORT_POST",
});

export const openReportPost = (post) => {
  return {
    type: "OPEN_REPORT_POST",
    payload: post,
  };
};

export const CLOSE_SHARE_POST = "CLOSE_SHARE_POST";
export const OPEN_SHARE_POST = "OPEN_SHARE_POST";

export const closeSharePost = () => ({
  type: "CLOSE_SHARE_POST",
});

export const openSharePost = (post) => {
  return {
    type: "OPEN_SHARE_POST",
    payload: post,
  };
};

export const CLOSE_UPDATE_POST = "CLOSE_UPDATE_POST";
export const OPEN_UPDATE_POST = "OPEN_UPDATE_POST";

export const closeUpdatePost = () => ({
  type: "CLOSE_UPDATE_POST",
});

export const openUpdatePost = (post) => {
  return {
    type: "OPEN_UPDATE_POST",
    payload: post,
  };
};

export const CLOSE_HELP_FEEDBACK = "CLOSE_HELP_FEEDBACK";
export const OPEN_HELP_FEEDBACK = "OPEN_HELP_FEEDBACK";

export const closeHelpFeedback = () => ({
  type: "CLOSE_HELP_FEEDBACK",
});

export const openHelpFeedback = () => {
  return {
    type: "OPEN_HELP_FEEDBACK",
  };
};




export const GET_FORM_DETAILS_FULFILLED = "GET_FORM_DETAILS_FULFILLED";
export const GET_FORM_DETAILS_PENDING = "GET_FORM_DETAILS_PENDING";
export const GET_FORM_DETAILS_REJECTED = "GET_FORM_DETAILS_REJECTED";

const getFormDetailsPending = (payload) => ({
  type: GET_FORM_DETAILS_PENDING,
  payload: payload,
});

const getFormDetailsFailure = (error) => ({
  type: GET_FORM_DETAILS_REJECTED,
  payload: { error },
});

const getFormDetailsSuccess = (data) => ({
  type: GET_FORM_DETAILS_FULFILLED,
  payload: data,
});

export const getFormDetails = (payload) => {
  return (dispatch) => {
    dispatch(getFormDetailsPending(payload));
    return http
      .post(apiUrl.GET_FORM_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getFormDetailsSuccess(response));
          return response;
        } else {
          dispatch(getFormDetailsFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getFormDetailsFailure(error));
        return error;
      });
  };
};




export const GET_ADS_FULFILLED = "GET_ADS_FULFILLED";
export const GET_ADS_PENDING = "GET_ADS_PENDING";
export const GET_ADS_REJECTED = "GET_ADS_REJECTED";

const getsAdsPending = (payload) => ({
  type: GET_ADS_PENDING,
  payload: payload,
});

const getsAdsFailure = (error) => ({
  type: GET_ADS_REJECTED,
  payload: { error },
});

const getsAdsSuccess = (data) => ({
  type: GET_ADS_FULFILLED,
  payload: data,
});

export const getsAds = (payload) => {
  return (dispatch) => {
    dispatch(getsAdsPending(payload));
    return http
      .post(apiUrl.GET_ADS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getsAdsSuccess(response));
          return response;
        } else {
          dispatch(getsAdsFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(isSubmitFormAllowedFailure(error));
        return error;
      });
  };
};

export const IS_SUBMIT_FORM_ALLOWED_FULFILLED = "IS_SUBMIT_FORM_ALLOWED_FULFILLED";
export const IS_SUBMIT_FORM_ALLOWED_PENDING = "IS_SUBMIT_FORM_ALLOWED_PENDING";
export const IS_SUBMIT_FORM_ALLOWED_REJECTED = "IS_SUBMIT_FORM_ALLOWED_REJECTED";

const isSubmitFormAllowedPending = (payload) => ({
  type: IS_SUBMIT_FORM_ALLOWED_PENDING,
  payload: payload,
});

const isSubmitFormAllowedFailure = (error) => ({
  type: IS_SUBMIT_FORM_ALLOWED_REJECTED,
  payload: { error },
});

const isSubmitFormAllowedSuccess = (data) => ({
  type: IS_SUBMIT_FORM_ALLOWED_FULFILLED,
  payload: data,
});

export const isSubmitFormAllowed = (payload) => {
  return (dispatch) => {
    dispatch(isSubmitFormAllowedPending(payload));
    return http
      .post(apiUrl.IS_SUBMIT_FORM_ALLOWED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(isSubmitFormAllowedSuccess(response));
          return response;
        } else {
          dispatch(isSubmitFormAllowedFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(isSubmitFormAllowedFailure(error));
        return error;
      });
  };
};





export const DELETE_FORM_QUERY_RESPONSE_FULFILLED = "DELETE_FORM_QUERY_RESPONSE_FULFILLED";
export const DELETE_FORM_QUERY_RESPONSE_PENDING = "DELETE_FORM_QUERY_RESPONSE_PENDING";
export const DELETE_FORM_QUERY_RESPONSE_REJECTED = "DELETE_FORM_QUERY_RESPONSE_REJECTED";

const deleteFormQueryResponsePending = (payload) => ({
  type: DELETE_FORM_QUERY_RESPONSE_PENDING,
  payload: payload,
});

const deleteFormQueryResponseFailure = (error) => ({
  type: DELETE_FORM_QUERY_RESPONSE_REJECTED,
  payload: { error },
});

const deleteFormQueryResponseSuccess = (data) => ({
  type: DELETE_FORM_QUERY_RESPONSE_FULFILLED,
  payload: data,
});

export const deleteFormQueryResponse = (payload) => {
  return (dispatch) => {
    dispatch(deleteFormQueryResponsePending(payload));
    return http
      .post(apiUrl.DELETE_FORM_QUERY_RESPONSE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(deleteFormQueryResponseSuccess(response));
          return response;
        } else {
          dispatch(deleteFormQueryResponseFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(deleteFormQueryResponseFailure(error));
        return error;
      });
  };
};

export const GET_SIGNED_URL_FULFILLED = "GET_SIGNED_URL_FULFILLED";
export const GET_SIGNED_URL_PENDING = "GET_SIGNED_URL_PENDING";
export const GET_SIGNED_URL_REJECTED = "GET_SIGNED_URL_REJECTED";

const getSignedUrlPending = () => ({
  type: GET_SIGNED_URL_PENDING,
});

const getSignedUrlFailure = (error) => ({
  type: GET_SIGNED_URL_REJECTED,
  payload: { error },
});

const getSignedUrlSuccess = (data) => ({
  type: GET_SIGNED_URL_FULFILLED,
  payload: data,
});

export const getSignedUrl = (payload) => {
  return (dispatch) => {
    dispatch(getSignedUrlPending());
    return http
      .post(apiUrl.GET_SIGNED_URL, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getSignedUrlSuccess(response));
          return response;
        } else {
          dispatch(getSignedUrlFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getSignedUrlFailure(error));
        return error;
      });
  };
};
