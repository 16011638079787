import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import EventCardSkeleton from '../../components/Skeleton/EventCardSkeleton';
import ClubCard from '../../components/ClubCard/ClubCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubsDiscover = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofDiscover, setNoofDiscover] = React.useState(2);
  const [discoverPageNo, setDiscoverPageNo] = React.useState(0);

  const user = useSelector((state) => state?.user?.UserDetails);
  const clubDiscoverList = useSelector(
    (state) => state?.clubs?.clubDiscoverList,
  );
  const clubLoading = useSelector((state) => state?.clubs?.loading);
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: discoverPageNo,
    };
    if (data?.page_no >= 0) {
      const eventList = await dispatch(clubsActions.discoverClubList(data));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeMoreDiscover = async () => {
    setDiscoverPageNo(discoverPageNo + 1);
    const data = {
      user_id: user.id,
      page_no: discoverPageNo + 1,
    };

    const clubs = await dispatch(clubsActions.discoverClubList(data));
  };

  const seeMore = () => {
    if (clubDiscoverList?.length > noofDiscover) {
      setNoofDiscover(clubDiscoverList?.length);
    } else if (clubDiscoverList?.length === noofDiscover) {
      setNoofDiscover(4);
    }
  };
  return (
    <Fragment>
      <Box px={0}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            {clubLoading ? (
              <EventCardSkeleton />
            ) : (
              <>
                <Box>
                  <InfiniteScroll
                    dataLength={
                      clubDiscoverList?.length ? clubDiscoverList?.length : 0
                    }
                    next={() => seeMoreDiscover()}
                    hasMore={true}
                    loader={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreDiscover}></Box>
                    }
                    height={
                      clubDiscoverList?.length > 2
                        ? 'calc(90vh - 70px)'
                        : 'auto'
                    }
                    endMessage={
                      <Box
                        sx={{
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={seeMoreDiscover}>
                        <Box>End</Box>
                      </Box>
                    }>
                    <Grid container>
                      {clubDiscoverList?.length > 0 &&
                        clubDiscoverList?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={12}
                              sx={{
                                marginTop: '8px',
                              }}>
                              <ClubCard clubs={list} type="discover" />
                            </Grid>
                          );
                        })}
                      {clubDiscoverList?.length == 0 && (
                        <Box pl={2} pt={3}>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '12px',
                              },
                            }}>
                            Currently there are no Clubs available .
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </InfiniteScroll>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box
          py={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          <Typography variant="h5" component="h5"></Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(ClubsDiscover);
