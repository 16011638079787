import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useStyles from './profilestyle';
import Divider from '@mui/material/Divider';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import InterestIcon from '../../assets/chooseurinterest';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Collapse from '@mui/material/Collapse';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CxoButton from '../../components/CxoButton/CxoButton';
import { alpha, styled } from '@mui/material/styles';
import LinkIcon from '@mui/icons-material/Link';
import QRCode from 'react-qr-code';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import * as messageAction from '../../store/message';

import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as authActions from '../../store/auth';
import * as userActions from '../../store/user';
import * as jobsActions from '../../store/jobs';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const CssAccordion = styled(Accordion)(({ theme }) => {
  return {
    '& .MuiAccordion-root': {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0)',
    },
  };
});

const CssTab = styled(Tab)(({ theme }) => {
  return {
    '& .MuiTabs-indicator': {
      height: '4px',
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const UserProfileShare = (props) => {
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [section, setSection] = React.useState('');
  const [tab, setTab] = React.useState(0);
  const [edit, setEdit] = React.useState(true);
  const [editsection, setEditsection] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sectiondata, setSectiondata] = React.useState({});

  const handleaccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.UserDetails);
  const userimage = useSelector((state) => state?.user?.UserProfileImage);
  const userotherdetails = useSelector(
    (state) => state?.user?.UserOtherDetails,
  );

  const candidateUserDetails = useSelector(
    (state) => state?.jobs?.CandidateUserProfileDetails,
  );
  const userabouts = useSelector((state) => state?.user?.UserAbouts);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  useEffect(() => {
    async function fetchData() {
      const profilefield = await dispatch(
        authActions.profileRequiredField({ user_id: user?.id }),
      );
      console.log('profilefield', profilefield);
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const id = params?.profile?.split('-');
    if (id?.length > 0) {
      const data = {
        user_id: id[id?.length - 1].substring(2),
        self_id: id[id?.length - 1].substring(2),
        datetime: dateTime(),
      };
      const users = await dispatch(
        userActions.otheruserProfileDetails(data, 'other'),
      );
    }
  }, []);

  useEffect(async () => {
    const id = params?.profile?.split('-');
    console.log('keywords', params.name, id[id?.length - 1].substring(2));
    if (id?.length > 0) {
      const data = {
        user_id: id[id?.length - 1].substring(2),
        self_id: id[id?.length - 1].substring(2),
        datetime: dateTime(),
      };
      const users = await dispatch(
        userActions.otheruserProfileDetails(data, 'other'),
      );
    }
  }, []);

  useEffect(async () => {
    async function fetchDataProfile() {
      const id = params?.profile?.split('-');
      const dataCadidate = {
        cand_id: id[id?.length - 1].substring(2),
      };
      const candidate = await dispatch(
        jobsActions.candidateProfileDetails(dataCadidate),
      );
    }
    fetchDataProfile();
  }, []);

  const handleChange = (newValue) => {
    setType(newValue);
  };
  const editProfile = () => {
    setEdit(!edit);
  };
  const editpub = (e, section) => {
    e.stopPropagation();
    setSection(section);
    setEditsection(!editsection);
  };

  const copyContent = async () => {
    try {
      var link =
        'https://www.linkcxo.com/user/' +
        userotherdetails?.first_name +
        '-' +
        userotherdetails?.last_name +
        '-lx' +
        userotherdetails?.id;
      let msg = 'Content copied to clipboard';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'success'),
      );
      await navigator.clipboard.writeText(link);
      // let msg = 'Content copied to clipboard';
      // const sendmessage = await dispatch(
      //   messageAction.messagefromComponent(msg, 'success'),
      // );

      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);

      let msg = 'Failed to copy';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }
  };

  const valueQr = () => {
    return (
      'https://www.linkcxo.com/user/' +
      user?.first_name +
      '-' +
      user?.last_name +
      '-lx' +
      user?.id
    );
  };
  const editSection = async (e, type, data) => {
    e.stopPropagation();
    const openeditsection = await dispatch(
      userActions.openUpdateSectionProfile(type, data),
    );
  };
  const addNew = async (e, type) => {
    e.stopPropagation();
    const openaddnew = await dispatch(userActions.openUpdateProfile(type));
  };
  const handleDelete = async () => {
    const datayy = {
      id: sectiondata?.id,
    };
    if (section === 'education') {
      const deletesection = await dispatch(userActions.deleteEducation(datayy));
    } else if (section === 'experience') {
      const deletesection = await dispatch(
        userActions.deleteExperience(datayy),
      );
    } else if (section === 'interest') {
      const deletesection = await dispatch(userActions.deleteInterest(datayy));
    } else if (section === 'language') {
      const deletesection = await dispatch(
        userActions.deleteUserLanguage(datayy),
      );
    } else if (section === 'experience') {
      const deletesection = await dispatch(
        userActions.deleteExperience(datayy),
      );
    }
  };
  const deleteSection = (e, type, data) => {
    e.stopPropagation();
    setOpen(true);
    setSection(type);
    setSectiondata(data);
  };
  const handleClose = () => {
    setOpen(false);
    setSection('');
    setSectiondata({});
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          sx={{
            fontSize: '24px',
            fontWeight: '600',
            color: '#38cd84',
            paddingTop: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width: 500px)': {
              fontSize: '20px',
            },
          }}
          id="alert-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent
          sx={{
            width: '500px',
            paddingTop: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width: 500px)': {
              width: '300px',
            },
          }}>
          <DialogContentText
            sx={{ paddingTop: '30px' }}
            id="alert-dialog-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CxoButton label="No" onClick={handleClose}>
            Disagree
          </CxoButton>
          <CxoButton label="Yes" onClick={handleDelete} autoFocus>
            Agree
          </CxoButton>
        </DialogActions>
      </Dialog>

      <Box p={1}>
        <Box sx={{ width: '100%' }}>
          <Paper
            sx={{
              backgroundColor: '#232121',
              backgroundImage: 'none',
              overflowX: 'auto',
            }}>
            <Box
              sx={{
                height:
                  userotherdetails?.designation?.length +
                    userotherdetails?.company_name?.length >
                  30
                    ? '168px'
                    : '148px',
                backgroundColor: '#2c2c2d',
                '@media (max-width: 500px)': {
                  height:
                    userotherdetails?.designation?.length +
                      userotherdetails?.company_name?.length >
                    30
                      ? '100px'
                      : '100px',
                },
              }}></Box>
            <Stack spacing={1} direction="column">
              <Box
                px={{ md: 2, xs: 0.5 }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  transform: ' translateY(-50%)',
                  marginBottom: '-60px',
                  '@media (max-width: 500px)': { marginBottom: '-30px' },
                }}>
                <Stack direction="row">
                  <Avatar
                    src={
                      userotherdetails?.image
                        ? userotherdetails?.image
                        : PersonIcon
                    }
                    sx={{
                      width: 148,
                      height: 148,
                      border: '4px solid #38cd84',
                      '@media (max-width: 500px)': {
                        width: 110,
                        height: 110,
                      },
                    }}
                  />
                </Stack>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  transform: ' translateY(-50%)',
                }}>
                <Typography
                  variant="h2s"
                  component="h2s"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    '@media (max-width: 500px)': {
                      fontSize: '18px',
                    },
                  }}>
                  {userotherdetails?.first_name
                    ? userotherdetails?.first_name?.charAt(0).toUpperCase() +
                      userotherdetails?.first_name?.slice(1)
                    : 'NA'}{' '}
                  {userotherdetails?.last_name
                    ? userotherdetails?.last_name?.charAt(0).toUpperCase() +
                      userotherdetails?.last_name?.slice(1)
                    : ''}
                </Typography>
              </Box>

              <Box
                pb={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-8px',
                  transform: ' translateY(-20%)',
                }}>
                <Stack spacing={0.5} direction="column" alignItems="center">
                  <Typography variant="h2" component="h2">
                    {userotherdetails?.designation
                      ? userotherdetails?.designation?.charAt(0).toUpperCase() +
                        userotherdetails?.designation?.slice(1)
                      : ''}
                  </Typography>
                  <Typography variant="h2" component="h2">
                    {userotherdetails?.designation
                      ? userotherdetails?.company_name
                          ?.charAt(0)
                          .toUpperCase() +
                        userotherdetails?.company_name?.slice(1)
                      : ''}
                  </Typography>
                  <Typography variant="h5" component="h5">
                    {userotherdetails?.location
                      ? userotherdetails?.location
                      : ''}
                  </Typography>
                </Stack>
              </Box>
              <Stack direction="row" sx={{ paddingBottom: '32px' }}>
                <Box
                  sx={{
                    width: '20%',
                    margin: '16px',
                    backgroundColor: '#38cd84',
                  }}>
                  <Box
                    sx={{
                      height: 'auto',
                      margin: '0 auto',
                      maxWidth: 256,
                      width: '100%',
                      padding: '16px',
                    }}>
                    <QRCode
                      size={256}
                      bgColor="#38cd84"
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={valueQr()}
                      viewBox={`0 0 256 256`}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '70%',
                    justifyContent: 'center',
                  }}>
                  <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        className={classes.headingtab}>
                        Share Via
                      </Typography>
                    </Box>
                    <Stack
                      direction="row"
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack
                        sx={{
                          display: 'flex',
                          direction: 'column',
                          alignItems: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                            backgroundColor: '#38cd84',
                            cursor: 'pointer',
                          }}
                          onClick={() => copyContent()}>
                          <LinkIcon sx={{ color: '#000' }} />
                        </Box>
                        <Box p={2}>
                          <Typography
                            variant="h2"
                            component="h2"
                            className={classes.headingtab}>
                            Copy Url
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack
                        sx={{
                          display: 'flex',
                          direction: 'column',
                          alignItems: 'center',
                        }}>
                        <a
                          style={{
                            textDecoration: 'none',
                            display: 'contents',
                          }}
                          href={
                            'whatsapp://send?text=' +
                            'https://www.linkcxo.com/user/' +
                            userotherdetails?.first_name +
                            '-' +
                            userotherdetails?.last_name +
                            '-lx' +
                            userotherdetails?.id
                          }>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '50px',
                              width: '50px',
                              borderRadius: '50%',
                              backgroundColor: '#38cd84',
                              cursor: 'pointer',
                            }}>
                            <WhatsAppIcon sx={{ color: '#000' }} />
                          </Box>
                          <Box p={2}>
                            <Typography
                              variant="h2"
                              component="h2"
                              className={classes.headingtab}>
                              Whatsapp
                            </Typography>
                          </Box>
                        </a>
                      </Stack>

                      <Stack
                        sx={{
                          display: 'flex',
                          direction: 'column',
                          alignItems: 'center',
                        }}>
                        <a
                          style={{
                            textDecoration: 'none',
                            display: 'contents',
                          }}
                          href={
                            'mailto:?subject=&body=' +
                            'https://www.linkcxo.com/user/' +
                            userotherdetails?.first_name +
                            '-' +
                            userotherdetails?.last_name +
                            '-lx' +
                            userotherdetails?.id
                          }>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '50px',
                              width: '50px',
                              borderRadius: '50%',
                              backgroundColor: '#38cd84',
                              cursor: 'pointer',
                            }}>
                            <EmailIcon sx={{ color: '#000' }} />
                          </Box>
                          <Box p={2}>
                            <Typography
                              variant="h2"
                              component="h2"
                              className={classes.headingtab}>
                              Email
                            </Typography>
                          </Box>
                        </a>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(UserProfileShare);

// <Typography
//   variant={type === "industryreport" ? "h2s" : "h2"}
//   component={type === "industryreport" ? "h2s" : "h2"}
// >
//   Report
// </Typography>

// <Typography variant="h2" component="h2">
//   {industry?.end_date} {industry?.end_month}{" "}
//   {industry?.end_year}
// </Typography>

//
// {userotherdetails?.id != user?.id && (
//   <Box py={2}>
//     <Stack direction="row" spacing={5}>
//       <Box>
//         <Stack direction="row" spacing={2}>
//           <RemoveRedEyeOutlinedIcon />
//           <Typography variant="h2" component="h2">
//             View Resume
//           </Typography>
//         </Stack>
//       </Box>
//       <Box>
//         <Stack direction="row" spacing={2}>
//           <FileDownloadOutlinedIcon />
//           <Typography variant="h2" component="h2">
//             Download Resume
//           </Typography>
//         </Stack>
//       </Box>
//     </Stack>
//   </Box>
// )}

// <Typography
//   variant="h2s"
//   component="h2s"
//   sx={{
//     fontWeight: "600",
//     fontFamily: "Open Sans",
//     fontSize: "22px",
//   }}
// >
//   {userotherdetails?.total_credit
//     ? userotherdetails?.total_credit?.toLocaleString()
//     : 0}
// </Typography>
// <Typography variant="h2" component="h2">
//   Credits
// </Typography>
