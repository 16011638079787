import React, { useEffect } from 'react';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import * as companyActions from '../../store/company';
import { dateTime } from '../../constants/app';
import CardClub from '../../components/CardLong/CardClub';
import CxoButton from '../../components/CxoButton/CxoButton';
import EventCard from '../../components/EventCard/EventCard';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CompanyEventList = (props) => {
  let params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const user = useSelector((state) => state?.user?.UserDetails);

  const companyEventList = useSelector(
    (state) => state?.company?.companyEventList,
  );

  const companyEventPastList = useSelector(
    (state) => state?.company.companyEventPastList,
  );

  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const formData = new FormData();
      formData.append('user_id', user?.id);
      formData.append(
        'user_table_pk',
        parseInt(id[id?.length - 1]?.substring(2)),
      );
      formData.append('categoryId', 0);
      formData.append('page_no', 0);
      // const data = {
      //   user_id: user?.id,
      //   user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
      //   categoryId: 0,
      //   page_no: 0,
      // };

      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
        user_type: 'company_page',
        page_no: 0,
      };
      const events = await dispatch(
        companyActions.getCompanyPastEventList(data),
      );
    }
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue != 3) {
      if (newValue === 0) {
        setValue(newValue);
      } else if (newValue === 1) {
        setValue(newValue);
      } else if (newValue === 2) {
        setValue(newValue);
      }
    }
  };
  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const formData = new FormData();
      formData.append('user_id', user?.id);
      formData.append(
        'user_table_pk',
        parseInt(id[id?.length - 1]?.substring(2)),
      );
      formData.append('categoryId', 0);
      formData.append('page_no', 0);
      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
        categoryId: 0,
        page_no: 0,
      };

      // const data = {
      //   user_id: user?.id,
      //   user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
      //   user_type: 'company_page',
      //   page_no: 0,
      // };
      const events = await dispatch(companyActions.getCompanyEventList(data));
    }
    fetchData();
  }, []);
  const createEvent = () => {
    navigate('/company/event/create');
  };
  return (
    <>
      <Box px={1} py={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="fullWidth"
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tab
            value={0}
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 0 ? '#38CD84' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Upcoming{' '}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 0 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </Box>
            }
          />
          <Tab
            value={1}
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 1 ? '#38CD84' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Past{' '}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 1 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </Box>
            }
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container sx={{ paddingBottom: '8px' }}>
            <Box sx={{ width: '100%', height: '10vh' }}>
              {companyEventList?.map((post, index) => (
                <Box key={index} pt={1} px={1}>
                  <EventCard events={post} type="own" />
                </Box>
              ))}

              {companyEventList?.length === 0 && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '16px',
                    }}>
                    <Stack
                      direction="column"
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          color: '#ffffff',
                        }}>
                        There are no events created yet. Start creating.
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingTop: '8px',
                        }}>
                        <CxoButton label="Create Event" onClick={createEvent} />
                      </Box>
                    </Stack>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container sx={{ paddingBottom: '8px' }}>
            <Box sx={{ width: '100%', height: '10vh' }}>
              {companyEventPastList?.map((post, index) => (
                <Box key={index} pt={1} px={1}>
                  <EventCard events={post} type="past" />
                </Box>
              ))}
              {companyEventPastList?.length === 0 && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: '16px',
                    }}>
                    <Stack
                      direction="column"
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          color: '#ffffff',
                        }}>
                        There are no past events.
                      </Typography>
                    </Stack>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container>
            <Box sx={{ width: '100%' }}></Box>
            <Box sx={{ width: '100%' }}></Box>
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
};

export default React.memo(CompanyEventList);
