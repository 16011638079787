import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  FormGroup,
  Checkbox,
  Typography,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Footer from '../../components/HomeHeader/footer';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import CxoButton from '../../components/CxoButton/CxoButton';
import { useNavigate } from 'react-router-dom';
import config from '../../services/apiConfig';
import * as postActions from '../../store/post';
import axios from 'axios';
const CenteredContainer = styled(Box)({});
const QaForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  let params = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [formData, setFormData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isSubmitAllowed, setSubmitAllowed] = useState(true);
  const [selectedStatuses, setSelectedStatuses] = useState({});
  const user = useSelector((state) => state?.user?.UserDetails);
  const isformSubmitAllowed = useSelector(
    (state) => state?.post?.isformSubmitAllowed,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // New state
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const id = params?.id;
      try {
        const response = await axios.post(
          config?.serverBaseUrl + '/forms/get_form',
          {
            postId: id,
          },
        );
        const formData4 = response?.data?.data[0];
        setFormData(formData4);
        //     if(response?.data?.status === 1){
        //   try {
        //     const response3 = await axios.post(
        //       config?.serverBaseUrl + '/forms/is_submit_form_allowed',
        //       {
        //         postId : id,
        //         userId: user?.id
        //       },
        //     );

        //     if(response3?.data?.status === 1){
        //       const formData2 = response?.data?.data;

        //       setSubmitAllowed(formData2)
        //     }

        //     // setFormData(formData);
        //   } catch (error) {
        //     console.error('Error API:', error);
        //   }
        // }
      } catch (error) {
        console.error('Error API:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const id = params?.id;
      try {
        const response = await axios.post(
          config?.serverBaseUrl + '/forms/is_submit_form_allowed',
          {
            postId: id,
            userId: user?.id,
          },
        );

        if (response?.data?.status === 1) {
          const formData2 = response?.data?.data;

          setSubmitAllowed(formData2);
        }
        // setFormData(formData);
      } catch (error) {
        console.error('Error API:', error);
      }
    };

    fetchData();
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getErrorMessage = (questionType, question) => {
    if (questionType === 'text') {
      return 'This field is required and must have a minimum length of one.';
    } else if (questionType === 'checkbox') {
      return `Select at least one field for "${question}".`;
    } else if (questionType === 'radio') {
      return `Select a value for "${question}".`;
    }
    return '';
  };

  const handleCheckboxChange = (e, questionId, optionValue) => {
    const isChecked = e.target.checked;
    setSelectedStatuses((prevSelectedStatuses) => {
      const currentSelectedOptions = prevSelectedStatuses[questionId] || [];

      const updatedSelectedOptions = isChecked
        ? [...currentSelectedOptions, optionValue]
        : currentSelectedOptions.filter((value) => value !== optionValue);

      return {
        ...prevSelectedStatuses,
        [questionId]: updatedSelectedOptions,
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const id = params?.id;
      const data1 =    {
        postId: id,
        userId: user?.id,
      }
      const answers = await dispatch(postActions.isSubmitFormAllowed(data1));

  
    };

    fetchData();
  }, []);

  const onSubmit = async (data) => {
    const id = params?.id;
    const formId = formData?.formId;
    const userId = user?.id;
    const formResponses = formData.formQuestions?.map((question) => {
      const questionId = question.questionId;
      const response = data[`question-${questionId}`];
      return {
        questionId,
        question: question.question,
        questionType: question.questionType,
        response:
          question.questionType === 'checkbox'
            ? selectedStatuses[questionId]
            : response,
      };
    });



    const requestData = {
      formId,
      userId,
      formResponses,
    };

    try {
      const response = await axios.post(
        config?.serverBaseUrl + '/forms/submit_form',
        requestData,
      );
      // setShowSuccessMessage(true);
      setSelectedStatuses({});
      reset();
      setFormData((prevFormData) => ({
        ...prevFormData,
        formQuestions: prevFormData.formQuestions.map((question) => {
          if (question.questionType === 'checkbox') {
            return {
              ...question,
              questionOptions: question.questionOptions.map((option) => ({
                ...option,
                selected: false,
              })),
            };
          } else if (question.questionType === 'radio') {
            return {
              ...question,
              questionOptions: question.questionOptions.map((option) => ({
                ...option,
                selected: false,
              })),
            };
          }
          return question;
        }),
      }));
      setSuccess(true);
    } catch (error) {
      console.error('Error:', error.message);
      console.log('Failed to submit form data.');
    }
  };

  const redirectResponse = () => {
    const id = formData?.formId;
    navigate('/response/survey/' + id);
  };
  if ( isformSubmitAllowed) {
    if (success) {
      return (
        <CenteredContainer pt={2}>
          <Box
            sx={{
              borderRadius: '4px',
              padding: '50px 10px',
              backgroundColor: '#333',
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                
              <Typography variant="h1s" sx={{ color: '#ffffff' }} gutterBottom>
                Thank You for Your Response!
              </Typography>
              <Typography variant="h1s" sx={{ color: '#ffffff' }} gutterBottom>
                We appreciate your contribution. Your input is 
              </Typography>
              <Typography variant="h1s" sx={{ color: '#ffffff' }} gutterBottom>
              invaluable to the community.
              </Typography>

             
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              sx={{
                paddingTop: '16px',
                paddingBottom: '16px',
              }}>
              {' '}
              <CxoButton label="Response" onClick={redirectResponse} />
            </Box>
          </Box>
        </CenteredContainer>
      );
    }
    // else if( !isSubmitAllowed  ) {

    //   return (
    //     <>
    //       <CenteredContainer pt={2}>
    //       <Box
    //         sx={{
    //           borderRadius: '4px',
    //           padding: '50px',
    //           backgroundColor: '#333',
    //         }}>
    //         <Box
    //           sx={{
    //             display: 'flex',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //           }}>
    //           <Typography variant="h1s" sx={{ color: '#ffffff' }} gutterBottom>
    //             Form submition is not allowed, to check response Click below
    //           </Typography>
    //         </Box>
    //         <Box
    //           mt={2}
    //           display="flex"
    //           justifyContent="center"
    //           sx={{
    //             paddingTop: '16px',
    //             paddingBottom: '16px',
    //           }}>
    //           {' '}
    //           <CxoButton label="Response" onClick={redirectResponse} />
    //         </Box>
    //       </Box>
    //     </CenteredContainer>
    //     </>
    //   )
    // }
    else {
      return (
        <>
          <CenteredContainer py={2}>
            <Box
              px={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}>
              <Typography
                color="secondary"
                variant="h5"
                gutterBottom
                mt={2}
                sx={{
                  fontFamily: Number.isNaN(Number(formData?.formTitle))
                    ? 'Open Sans'
                    : '',
                  fontSize: '18px',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                }}>
                {formData?.formTitle}
              </Typography>
            </Box>
            <Box
              px={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}>
              <Typography
                color="primary"
                variant="h6"
                gutterBottom
                mt={2}
                sx={{
                  fontFamily: Number.isNaN(Number(formData?.formDescription))
                    ? 'Open Sans'
                    : '',
                  lineHeight: '21px',
                  color: '#fff',
                  fontSize: '15px',
                  letterSpacing: '1px',
                  color: 'rgba(255, 255,255,1)',
                  whiteSpace: 'pre-line',
                }}>
                {formData?.formDescription}
              </Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              {formData.formQuestions?.map((question, index) => (
                <div
                  key={question.questionId}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px rgba(128, 128, 128, 0.5)',
                    padding: '20px',
                    transition: '0.5s',
                    margin: '10px',
                  }}>
                  <div style={{ marginRight: '10px' }}>
                    <Typography
                      color="secondary"
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                      }}>
                      {formData.formQuestions?.length > 1
                        ? `Q${index + 1}.`
                        : ''}
                    </Typography>
                  </div>
                  {question.questionType === 'text' && (
                    <div
                      style={{
                        width: isMobile ? '100%' : '800px',
                        ml: isMobile ? 0 : -1,
                      }}>
                      <Controller
                        name={`question-${question.questionId}`}
                        control={control}
                        defaultValue=""
                        rules={{ required: true, minLength: 1 }}
                        render={({ field }) => (
                          <div>
                            <CxoInputCss
                              sx={{
                                fontSize: '14px',
                                '@media (max-width: 500px)': {
                                  fontSize: '13px',
                                },
                              }}
                              {...field}
                              label={question.question}
                              fullWidth
                            />
                            {errors[`question-${question.questionId}`] && (
                              <span style={{ color: 'red' }}>
                                {getErrorMessage('text', question.question)}
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )}
                  {/* {question.questionType === 'checkbox' && (
                  <div style={{ width: isMobile ? '100%' : '800px' }}>
                    <Typography
                      sx={{
                        color: '#38CD84',
                        fontSize: '14px',
                        fontFamily: Number.isNaN(Number(question.question))
                          ? 'Open Sans'
                          : '',
                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                          marginTop: '10px',
                        },
                      }}>
                      {question.question}
                    </Typography>

                      

                    <FormGroup>
                      {question.questionOptions?.map((option) => (
                        <Controller
                          key={option.formQuestionOptionId}
                          name={`question-${question.questionId}-${option.formQuestionOptionId}`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <FormControlLabel
                                style={{
                                  color: '#fff',
                                  fontSize: '13px',
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                  },
                                }}
                                control={
                                  <Checkbox color="secondary" {...field} />
                                }
                                label={option.value}
                              />
                            </div>
                          )}
                        />
                      ))}
                    </FormGroup>
                    {question.questionOptions?.some(
                      (option) =>
                        errors[
                          `question-${question.questionId}-${option.formQuestionOptionId}`
                        ],
                    ) && (
                      <span style={{ color: 'red' }}>
                        {getErrorMessage('checkbox', question.question)}
                      </span>
                    )}
                  </div>
                )} */}

                  {question?.questionType === 'checkbox' && (
                    <Box
                      mt={0.2}
                      sx={{
                        width: isMobile ? '100%' : '800px',
                      }}>
                      <FormControl component="fieldset">
                        <FormLabel
                          style={{
                            color: '#38CD84',
                            fontSize: '14px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}
                          id={`question-label-${question.questionId}`}>
                          {question?.question}
                        </FormLabel>
                        <FormGroup>
                          {question?.questionOptions?.map((option) => (
                            <FormControlLabel
                              key={option.formQuestionOptionId}
                              style={{
                                color: '#fff',
                                fontSize: '13px',
                                '@media (max-width: 500px)': {
                                  fontSize: '13px',
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={
                                    selectedStatuses[
                                      question.questionId
                                    ]?.includes(option.value) || false
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      question.questionId,
                                      option.value,
                                    )
                                  }
                                  name={`question_${question.questionId}_${option.formQuestionOptionId}`}
                                  color="secondary"
                                />
                              }
                              label={option.value}
                            />
                          ))}
                        </FormGroup>

                        {question.questionOptions?.some(
                          (option) =>
                            errors[
                              `question-${question.questionId}-${option.formQuestionOptionId}`
                            ],
                        ) && (
                          <span style={{ color: 'red' }}>
                            {getErrorMessage('checkbox', question.question)}
                          </span>
                        )}
                      </FormControl>
                    </Box>
                  )}
                  {question.questionType === 'radio' && (
                    <div style={{ width: isMobile ? '100%' : '800px' }}>
                      <Typography
                        sx={{
                          color: '#38CD84',
                          fontSize: '14px',
                          fontFamily: Number.isNaN(Number(question.question))
                            ? 'Open Sans'
                            : '',
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                            marginTop: '10px',
                          },
                        }}>
                        {question?.question}
                      </Typography>
                      <Controller
                        name={`question-${question.questionId}`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            row
                            sx={{
                              flexDirection: 'column',
                              color: '#fff',
                            }}>
                            {question.questionOptions?.map((option) => (
                              <FormControlLabel
                                color="primary"
                                key={option.formQuestionOptionId}
                                value={option.value}
                                control={
                                  <Radio size="small" color="secondary" />
                                }
                                label={option.value}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                      {errors[`question-${question.questionId}`] && (
                        <span style={{ color: 'red' }}>
                          {getErrorMessage('radio', question.question)}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              ))}
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  paddingTop: '16px',
                  paddingBottom: '32px',
                }}>
                {' '}
                {formData?.formQuestions?.length > 0 && isSubmitAllowed && (
                  <CxoButton label="Submit" type="submit" />
                )}
                {/* <CxoButton label="Submit" type="submit" /> */}
              </Box>
              {/* <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                {showSuccessMessage && (
                  <Box
                    sx={{
                      width: '50%',
                      fontSize: '10px',
                      textAlign: 'center',
                      padding: '10px',
                      backgroundColor: '#212121',
                      border: '1px solid #38CD84',
                      color: '#fff',
                      fontWeight: 'bold',
                      borderRadius: '5px',
                    }}>
                    Form submitted successfully!
                    <div style={{ marginTop: '10px' }}>
                      <Button
                        sx={{ padding: '1px 10px', fontSize: '10px' }}
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowSuccessMessage(false)}>
                        Close
                      </Button>
                    </div>
                  </Box>
                )}
              </Box> */}
            </form>
          </CenteredContainer>
        </>
      );
    }
  } else {
    return (
      <CenteredContainer pt={2}>
        <Box
          sx={{
            borderRadius: '4px',
            padding: '50px',
            backgroundColor: '#333',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography variant="h1s" sx={{ color: '#ffffff' }} gutterBottom>
              View Responses
            </Typography>
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            sx={{
              paddingTop: '16px',
              paddingBottom: '16px',
            }}>
            {' '}
            <CxoButton label="Response" onClick={redirectResponse} />
          </Box>
        </Box>
      </CenteredContainer>
    );
  }
};

export default QaForm;
