import {
  LINKCXO_REWARD_LIST_SUCCESS,
  REWARDS_CATEGORY_LIST_SUCCESS,
  REWARDS_PARTNER_LIST_SUCCESS,
  REWARDS_PARTNER_LIST_REQUEST,
  REWARDS_PARTNER_LIST_FAILURE,
  REWARDS_PARTNER_DETAILS_SUCCESS,
  REWARDS_VOUCHER_LIST_SUCCESS,
  REWARDS_VOUCHER_LIST_REQUEST,
  REWARDS_VOUCHER_LIST_FAILURE,
  REWARDS_VOUCHER_DETAILS_SUCCESS,
  REWARDS_VOUCHER_DETAILS_REQUEST,
  REWARDS_VOUCHER_DETAILS_FAILURE,
  REWARDS_REEDEMED_VOUCHER_LIST_SUCCESS,
  REWARDS_REEDEMED_VOUCHER_LIST_REQUEST,
  REWARDS_REEDEMED_VOUCHER_LIST_FAILURE,
  REWARDS_EXPIRED_VOUCHER_LIST_SUCCESS,
  REWARDS_GET_TOTAL_CREDITS_SUCCESS,
  REWARDS_GET_TOTAL_CREDITS_REQUEST,
  REWARDS_GET_APPLAUSES_SUCCESS,
  REWARDS_GET_CREDITS_LIST_SUCCESS,
  REWARDS_GET_CREDITS_DETAILS_SUCCESS,
  REWARDS_REDEEM_VOUCHER_SUCCESS,
  REWARDS_MEMBERSHIP_TYPE_LIST_SUCCESS,
  REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_SUCCESS,
  REWARDS_NO_OF_AVAILABLE_SUCCESS,
  REWARDS_REALESTATE_LIST_SUCCESS,
  REWARDS_REALESTATE_LIST_REQUEST,
  REWARDS_REALESTATE_LIST_FAILURE,
  REWARDS_REALESTATE_FILTER_LIST_SUCCESS,
  REWARDS_REALESTATE_FILTER_LIST_REQUEST,
  REWARDS_REALESTATE_FILTER_LIST_FAILURE,
  REWARDS_REALESTATE_LOCATION_FILTER_LIST_SUCCESS,
  REWARDS_REALESTATE_LOCATION_FILTER_LIST_REQUEST,
  REWARDS_REALESTATE_LOCATION_FILTER_LIST_FAILURE,
  REWARDS_MEMBERSHIP_LIST_SUCCESS,
  REWARDS_MEMBERSHIP_LIST_REQUEST,
  REWARDS_MEMBERSHIP_LIST_FAILURE,
  REWARDS_MEMBERSHIP_DETAILS_SUCCESS,
  REWARDS_MEMBERSHIP_DETAILS_REQUEST,
  REWARDS_MEMBERSHIP_DETAILS_FAILURE,
  REWARDS_USER_MEMBERSHIP_SUCCESS,
  REWARDS_USER_MEMBERSHIP_REQUEST,
  REWARDS_USER_MEMBERSHIP_FAILURE,
  REWARDS_MEMBERSHIP_BENEFITS_SUCCESS,
  REWARDS_MEMBERSHIP_BENEFITS_REQUEST,
  REWARDS_MEMBERSHIP_BENEFITS_FAILURE,
  REWARDS_UPGRADE_MEMBERSHIP_PRICE_SUCCESS,
  REWARDS_UPGRADE_MEMBERSHIP_PRICE_REQUEST,
  REWARDS_UPGRADE_MEMBERSHIP_PRICE_FAILURE,
  CORPORATE_PACKAGES_SUCCESS,
  CORPORATE_PACKAGES_REQUEST,
  CORPORATE_PACKAGES_FAILURE,
  CORPORATE_MEMBERSHIP_SUCCESS,
  CORPORATE_MEMBERSHIP_REQUEST,
  CORPORATE_MEMBERSHIP_FAILURE,
  CORPORATE_MEMBERSHIP_PRODUCT_SUCCESS,
  CORPORATE_MEMBERSHIP_PRODUCT_REQUEST,
  CORPORATE_MEMBERSHIP_PRODUCT_FAILURE,
  CORPORATE_MEMBERSHIP_BENEFITS_SUCCESS,
  CORPORATE_MEMBERSHIP_BENEFITS_REQUEST,
  CORPORATE_MEMBERSHIP_BENEFITS_FAILURE,
  CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_SUCCESS,
  CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_REQUEST,
  CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_FAILURE,
  CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS,
  CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST,
  CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE,
  REWARDS_REQUEST_NEW_VOUCHER_SUCCESS,
  REWARDS_REQUEST_NEW_VOUCHER_REQUEST,
  REWARDS_REQUEST_NEW_VOUCHER_FAILURE,
  CLOSE_REWARDS_MESSAGE,
  OPEN_REWARDS_MESSAGE
} from './actions';

export const rewardsreducer = (
  state = {
    loading: false,
    linkcxoRewardList: [],
    rewardsCategoryList: [],
    rewardsPartnerList: [],
    rewardsPartnerDetails: [],
    rewardsVoucherList: [],
    rewardsRealEstateList: [],
    rewardsRealEstateFilterList: [],
    rewardsRealEstateLocationFilterList: [],
    rewardsVoucherDetails: [],
    rewardVoucherDetail: [],
    rewardsReedemedVoucherList: [],
    rewardsExpiredVoucherList: [],
    rewardsPurchasedVoucherList: [],
    rewardsTotalCredits: 0,
    rewardsGetApplauses: [],
    rewardsCreditsDetails: [],
    rewardsGetCreditslist: [],
    rewardsGetCreditsDetails: [],
    rewardsMembershipTypeList: [],
    rewardsPartnerVoucher: {},
    rewardsGetCreditsByApplause: [],
    rewardsGetCreditsSetActions: '',
    rewardDetailsloading: false,
    rewardVoucherloading: false,
    rewardVouchelistloading: false,
    rewardRedeemVoucherloading: false,
    membershipList: [],
    membershipDetails: [],
    membershipUserDetails: [],
    membershipBenefits: [],
    membershipUpgradePrice: 0,
    corporatePackages: [],
    corporateMemberships: [],
    corporateMembershipsProduct: [],
    corporate_loading: false,
    customCorporatePackage: false,
    costumPackage: {},
    corporateMembershipsBenefits: [],
    corporateMembershipProductDetails: [],
    corporateMembershipPurchasedVoucher: [],
    openPackage: {},
    openMessage: false
  },
  action,
) => {
  switch (action.type) {
    case CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS:
      return {
        ...state,
        rewardRedeemVoucherloading: false,
        errors: {},
        corporateMembershipPurchasedVoucher: action.payload?.data?.data,
      };
    case CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST:
      return {
        ...state,
        rewardRedeemVoucherloading: true,
        errors: {},
        corporateMembershipPurchasedVoucher: [],
      };
    case CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE:
      return {
        ...state,
        rewardRedeemVoucherloading: false,
        errors: {},
        corporateMembershipPurchasedVoucher: [],
      };
    case CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMembershipProductDetails: action.payload?.data[0],
      };
    case CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        corporate_loading: true,
        errors: {},
        corporateMembershipProductDetails: [],
      };
    case CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMembershipProductDetails: [],
      };
    case CORPORATE_MEMBERSHIP_PRODUCT_SUCCESS:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMembershipsProduct: action.payload.data,
      };
    case CORPORATE_MEMBERSHIP_PRODUCT_REQUEST:
      return {
        ...state,
        corporate_loading: true,
        errors: {},
        corporateMembershipsProduct: [],
      };
    case CORPORATE_MEMBERSHIP_PRODUCT_FAILURE:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMembershipsProduct: [],
      };
    case CORPORATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMemberships: action.payload,
      };
    case CORPORATE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        corporate_loading: true,
        errors: {},
        corporateMemberships: [],
      };
    case CORPORATE_MEMBERSHIP_FAILURE:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMemberships: [],
      };

    case CORPORATE_MEMBERSHIP_BENEFITS_SUCCESS:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMembershipsBenefits: action.payload.data,
      };
    case CORPORATE_MEMBERSHIP_BENEFITS_REQUEST:
      return {
        ...state,
        corporate_loading: true,
        errors: {},
        corporateMembershipsBenefits: [],
      };
    case CORPORATE_MEMBERSHIP_BENEFITS_FAILURE:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporateMembershipsBenefits: [],
      };
    case CORPORATE_PACKAGES_SUCCESS:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporatePackages: action.payload.data,
      };
    case CORPORATE_PACKAGES_REQUEST:
      return {
        ...state,
        corporate_loading: true,
        errors: {},
        corporatePackages: [],
      };
    case CORPORATE_PACKAGES_FAILURE:
      return {
        ...state,
        corporate_loading: false,
        errors: {},
        corporatePackages: [],
      };
    case LINKCXO_REWARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        linkcxoRewardList: action.payload.data,
      };
    case REWARDS_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsCategoryList: action.payload.data,
      };
    case REWARDS_PARTNER_LIST_SUCCESS: {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          loading: false,
          errors: {},
          rewardsPartnerloading: false,
          rewardsPartnerList: action.payload.reward.data,
        };
      } else {
        return {
          ...state,
          loading: false,
          errors: {},
          rewardsPartnerList: [
            ...state.rewardsPartnerList,
            ...action.payload.reward.data,
          ],
          rewardsPartnerloading: false,
        };
      }
    }

    case REWARDS_PARTNER_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerloading: true,
      };
    case REWARDS_PARTNER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerloading: false,
      };
    case REWARDS_REALESTATE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerloading: false,
        rewardsRealEstateList: action.payload.data, // rewardsRealEstateFilterList: action.payload.data,
      };
    case REWARDS_REALESTATE_FILTER_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerloading: true,
        rewardsRealEstateList: [],
      };
    case REWARDS_REALESTATE_FILTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerloading: false,
      };
    case REWARDS_REALESTATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardVouchelistloading: false,
        rewardsRealEstateList: action.payload.data,
      };
    case REWARDS_REALESTATE_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerloading: true,
      };
    case REWARDS_REALESTATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerloading: false,
      };

    case REWARDS_REALESTATE_LOCATION_FILTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},

        rewardsRealEstateLocationFilterList: action.payload.data,
      };
    case REWARDS_REALESTATE_LOCATION_FILTER_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case REWARDS_REALESTATE_LOCATION_FILTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case REWARDS_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerDetails: action.payload.data,
      };

    case REWARDS_VOUCHER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardVouchelistloading: false,
        rewardsVoucherList: action.payload.data,
      };

    case REWARDS_VOUCHER_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardVouchelistloading: true,
      };

    case REWARDS_UPGRADE_MEMBERSHIP_PRICE_SUCCESS:
      return {
        ...state,
        rewardsMembershiploading: false,
        membershipUpgradePrice: action.payload.data?.amountToPay,
      };
    case REWARDS_UPGRADE_MEMBERSHIP_PRICE_REQUEST:
      return {
        ...state,
        rewardsMembershiploading: true,
      };
    case REWARDS_UPGRADE_MEMBERSHIP_PRICE_FAILURE:
      return {
        ...state,
        rewardsMembershiploading: false,
      };

    case REWARDS_USER_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        rewardsMembershiploading: false,
        membershipDetails: action.payload.data,
      };
    case REWARDS_USER_MEMBERSHIP_REQUEST:
      return {
        ...state,
        rewardsMembershiploading: true,
      };
    case REWARDS_USER_MEMBERSHIP_FAILURE:
      return {
        ...state,
        rewardsMembershiploading: false,
      };

    case REWARDS_MEMBERSHIP_DETAILS_SUCCESS:
      return {
        ...state,
        rewardsMembershiploading: false,
        membershipUserDetails: action.payload.data,
      };
    case REWARDS_MEMBERSHIP_DETAILS_REQUEST:
      return {
        ...state,
        rewardsMembershiploading: true,
      };
    case REWARDS_MEMBERSHIP_DETAILS_FAILURE:
      return {
        ...state,
        rewardsMembershiploading: false,
      };
    case REWARDS_MEMBERSHIP_LIST_SUCCESS:
      return {
        ...state,
        rewardsMembershiploading: false,
        membershipList: action.payload.data,
      };
    case REWARDS_MEMBERSHIP_LIST_REQUEST:
      return {
        ...state,
        rewardsMembershiploading: true,
      };

    case REWARDS_MEMBERSHIP_BENEFITS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardDetailsloading: false,
        membershipBenefits: action.payload.data,
      };
    case REWARDS_MEMBERSHIP_BENEFITS_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardDetailsloading: false,
      };
    case REWARDS_MEMBERSHIP_BENEFITS_FAILURE:
      return {
        ...state,
        rewardsMembershiploading: false,
      };

    case REWARDS_MEMBERSHIP_LIST_FAILURE:
      return {
        ...state,
        rewardsMembershiploading: false,
      };
    case REWARDS_VOUCHER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardVouchelistloading: false,
      };
    case REWARDS_VOUCHER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardDetailsloading: false,
        rewardVoucherDetail: action.payload.data,
      };
    case REWARDS_NO_OF_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        noOfAvailableCoupons: action.payload?.data[0].number_of_coupons,
      };
    case REWARDS_REEDEMED_VOUCHER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardRedeemVoucherloading: false,
        rewardsReedemedVoucherList: action.payload.data,
      };

    case REWARDS_REEDEMED_VOUCHER_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardRedeemVoucherloading: true,
      };
    case REWARDS_REEDEMED_VOUCHER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardRedeemVoucherloading: false,
      };

    case REWARDS_EXPIRED_VOUCHER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsExpiredVoucherList: action.payload.data,
      };
    case REWARDS_GET_TOTAL_CREDITS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsTotalCredits: action.payload.data,
      };
    case REWARDS_GET_TOTAL_CREDITS_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsTotalCredits: 0,
      };
    case REWARDS_VOUCHER_DETAILS_REQUEST:
      return {
        ...state,
        rewardDetailsloading: true,
      };
    case REWARDS_VOUCHER_DETAILS_FAILURE:
      return {
        ...state,
        rewardDetailsloading: false,
      };
    case 'REWARDS_GET_TOTAL_CREDITS_FAILURE':
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsTotalCredits: 0,
      };
    case REWARDS_GET_CREDITS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsGetCreditslist: action.payload.data,
      };
    case REWARDS_GET_APPLAUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsGetApplauses: action.payload.data,
      };
    case REWARDS_GET_CREDITS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsGetCreditsDetails: action.payload,
      };
    case REWARDS_REDEEM_VOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsVoucherDetails: action.payload.data,
      };
    case REWARDS_MEMBERSHIP_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsMembershipTypeList: action.payload.data,
      };
    case REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsGetCreditsByApplause: action.payload.data,
      };

    case 'REWARDS_VOUCHER_DETAILS':
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsVoucherDetails: action.payload,
      };
    case 'REWARDS_PARTNER_VOUCHER':
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsPartnerVoucher: action.payload,
      };
    case 'REWARDS_GET_CREDITS_SET_ACTION':
      return {
        ...state,
        loading: false,
        errors: {},
        rewardsGetCreditsSetActions: action.payload,
      };
    case 'CUSTOM_CORPORATE_PACKAGE_OPEN': {
      return {
        ...state,
        customCorporatePackage: true,
        customPackage: action.payload?.package,
        from: action.payload?.from,
      };
    }
    case REWARDS_REQUEST_NEW_VOUCHER_SUCCESS : {
      return {
        ...state,
        openMessage: true
      };
    }
    case REWARDS_REQUEST_NEW_VOUCHER_REQUEST : {
      return{
        ...state,
        openMessage :  false
      }
    }
    case REWARDS_REQUEST_NEW_VOUCHER_FAILURE :{
      return{
        ...state,
        openMessage :  false
      }
    }
    case CLOSE_REWARDS_MESSAGE: {
      return{
        ...state,
        openMessage :  false
      }
    }
    case OPEN_REWARDS_MESSAGE: {
      return{
        ...state,
        openMessage :  true
      }
    }
    case 'CUSTOM_CORPORATE_PACKAGE_CLOSE': {
      return {
        ...state,
        customCorporatePackage: false,
        costumPackage: {},
      };
    }
    case 'OPENED_PACKAGE': {
      return {
        ...state,
        openPackage: action.payload,
      };
    }
    default:
      return state;
  }
};
