import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import AvatarGroup from '@mui/material/AvatarGroup';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import { dateTime } from '../../constants/app';

import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import { useNavigate, useLocation } from 'react-router-dom';
import * as usermembersActions from '../../store/usernetwork';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ClubMembers(props) {
  console.log('ClubMembers', props);
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventDetails = useSelector((state) => state?.events?.eventDetails);
  const user = useSelector((state) => state?.user?.UserDetails);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const connect = async () => {
    const data = {
      club_id: clubDetails?.id,
      from_user_id: user?.id,
      to_user_id: props?.members?.user_id,
      member_id: props?.members?.member_id,
      datetime: dateTime(),
    };
    const clubsconnect = await dispatch(clubsActions.clubRequestAccept(data));

    const dataclub = {
      club_id: clubDetails?.id,
      user_id: user?.id,
    };
    const clubmemblist5 = await dispatch(
      clubsActions.clubRequestedMembers(dataclub),
    );
    const clubmemberlist6 = await dispatch(
      clubsActions.clubMembersList(dataclub),
    );
    const clubmembelist7 = await dispatch(
      clubsActions.clubInvitedMembers(dataclub),
    );
  };

  const remove = async () => {
    const data = {
      club_id: clubDetails?.id,
      from_user_id: user?.id,
      to_user_id: props?.members?.user_id,
      member_id: props?.members?.member_id,
      datetime: dateTime(),
    };
    const clubsconnect = await dispatch(clubsActions.clubRequestAccept(data));

    const dataclub = {
      club_id: clubDetails?.id,
      user_id: user?.id,
    };

    const clubmemblist1 = await dispatch(
      clubsActions.clubRequestedMembers(dataclub),
    );
    const clubmemberlist2 = await dispatch(
      clubsActions.clubMembersList(dataclub),
    );
    const clubmembelist3 = await dispatch(
      clubsActions.clubInvitedMembers(dataclub),
    );
  };

  const ignore = async () => {
    const data = {
      club_id: clubDetails?.id,
      from_user_id: user?.id,
      to_user_id: props?.members?.user_id,
      member_id: props?.members?.member_id,
      datetime: dateTime(),
    };
    const clubsconnect = await dispatch(clubsActions.clubRequestReject(data));

    const dataclub = {
      club_id: clubDetails?.id,
      user_id: user?.id,
    };

    const clubmemblist1 = await dispatch(
      clubsActions.clubRequestedMembers(dataclub),
    );

    const clubmemberlist2 = await dispatch(
      clubsActions.clubMembersList(dataclub),
    );
    const clubmembelist3 = await dispatch(
      clubsActions.clubInvitedMembers(dataclub),
    );
  };

  const profileOpen = (first_name, last_name, id) => {
    if (props?.type === 'requested') {
      const profile = first_name + '-' + last_name + '-' + 'lx' + id;
      navigate('/user/' + profile);
    } else {
      const profile =
        first_name + '-' + last_name + '-' + 'lx' + props?.members?.id;
      navigate('/user/' + profile);
    }
  };

  const removeMember = async () => {
    const data = {
      id: props?.members?.id,
      club_id: clubDetails?.id,
    };
    const clubunsend = await dispatch(clubsActions.clubRemoveMember(data));

    const dataclub = {
      club_id: clubDetails?.id,
      user_id: user?.id,
    };
    const clubmembelist = await dispatch(
      clubsActions.clubInvitedMembers(dataclub),
    );
    const clubmemberlist1 = await dispatch(
      clubsActions.clubMembersList(dataclub),
    );

    const clubmemblist2 = await dispatch(
      clubsActions.clubRequestedMembers(dataclub),
    );
  };
  const unSend = async () => {
    // const data = {
    //   club_id: clubDetails?.id,
    //   from_user_id: user?.id,
    //   to_user_id: props?.members?.user_id,
    //   member_id: props?.members?.member_id,
    //   datetime: dateTime(),
    // };
    // const clubsconnect = await dispatch(clubsActions.clubRequestReject(data));
    const data = {
      member_id: props?.members?.id,
    };
    const clubunsend = await dispatch(clubsActions.clubUnsendRequest(data));

    const dataclub = {
      club_id: clubDetails?.id,
      user_id: user?.id,
    };
    const clubmembelist = await dispatch(
      clubsActions.clubInvitedMembers(dataclub),
    );
    const clubmemberlist1 = await dispatch(
      clubsActions.clubMembersList(dataclub),
    );

    const clubmemblist2 = await dispatch(
      clubsActions.clubRequestedMembers(dataclub),
    );
  };

  useEffect(async () => {
    const data = {
      user_id: props?.members?.id,
    };
    // console.log('props?.members', props?.members);
    // const mutualfriend = await dispatch(usermembersActions.discoverUsermembers(data));
    // if(mutualfriend.Status === 1){
    //   setMutualFriend(mutualfriend?.data)
    // }
    // console.log('mutualfriend', mutualfriend);
  }, []);

  return (
    <Card
      sx={{
        backgroundColor: '#262525',
        borderRadius: '5px',
        backgroundImage: 'none',
        boxShadow: '0',
      }}>
      <CardHeader
        avatar={
          props?.members?.image ? (
            <Avatar
              src={props?.members?.image}
              sx={{
                width: 48,
                height: 48,
                cursor: 'pointer',
              }}
              onClick={() =>
                profileOpen(
                  props?.members?.first_name,
                  props?.members?.last_name,
                  props?.members?.id,
                )
              }
            />
          ) : (
            <Avatar
              sx={{
                width: 48,
                height: 48,
                cursor: 'pointer',
              }}
              onClick={() =>
                profileOpen(
                  props?.members?.first_name,
                  props?.members?.last_name,
                  props?.members?.id,
                )
              }>
              {props?.members?.first_name[0] + props?.members?.last_name[0]}
            </Avatar>
          )
        }
        action={
          <Stack
            spacing={1}
            direction="column"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {props?.type === 'Requested' && props?.members?.id !== user?.id && (
              <Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CxoButtonWithIcon
                    label="Accept"
                    width="112px"
                    minWidth="112px"
                    onClick={connect}
                  />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CxoButtonWithIcon
                    label="Ignore"
                    width="112px"
                    minWidth="112px"
                    onClick={ignore}
                  />
                </Box>
              </Fragment>
            )}
            {props?.type === 'Invited' && props?.members?.id !== user?.id && (
              <Fragment>
                <Box
                  pt={2}
                  pr={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButtonWithIcon
                    label="Unsend"
                    width="112px"
                    minWidth="112px"
                    onClick={unSend}
                  />
                </Box>
              </Fragment>
            )}
            {props?.type === 'Attending' &&
              props?.members?.role === 'Admin' && (
                <Fragment>
                  <Box
                    py={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CxoButtonWithIcon
                      label="Owner"
                      width="112px"
                      minWidth="112px"
                    />
                  </Box>
                </Fragment>
              )}

            {props?.type === 'Attending' && props?.members?.id !== user?.id && (
              <Fragment>
                <Box
                  pt={2}
                  pr={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButtonWithIcon
                    label="Remove"
                    width="112px"
                    minWidth="112px"
                    onClick={removeMember}
                  />
                </Box>
              </Fragment>
            )}

            {props?.type === 'members' && props?.members?.role === 'Admin' && (
              <Fragment>
                <Box
                  py={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButtonWithIcon
                    label="Owner"
                    width="112px"
                    minWidth="112px"
                  />
                </Box>
              </Fragment>
            )}
          </Stack>
        }
        title={
          <Typography variant="h5s" component="h5s" sx={{ fontSize: '15px' }}>
            {props?.members?.first_name ? props?.members?.first_name : ''}{' '}
            {props?.members?.last_name &&
            props?.members?.last_name?.length +
              props?.members?.first_name?.length >
              32
              ? props?.members?.last_name?.slice(0, 1) + '...'
              : props?.members?.last_name}
          </Typography>
        }
        subheader={
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: '12px', color: 'rgba(255, 255,255,1)' }}>
                {props?.members?.designation &&
                props?.members?.designation?.length +
                  props?.members?.company_name?.length >
                  50
                  ? props?.members?.designation?.slice(0, 25) + '...'
                  : props?.members?.designation}{' '}
                ||{' '}
                {props?.members?.designation &&
                props?.members?.designation?.length +
                  props?.members?.company_name?.length >
                  50
                  ? props?.members?.company_name?.slice(0, 25) + '...'
                  : props?.members?.company_name}
              </Typography>

              <Stack direction="row" justifyContent="space-between">
                <Box
                  pl={1.3}
                  pt={1}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    pt={0.5}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItem: 'center',
                      justifyContent: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '0px',
                          paddingLeft: '8px',
                        }}></Box>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        }
      />
    </Card>
  );
}
