import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import Helios from '../../../resources/PartnerLogos/Helios-01.png';
import Vero_Moda from '../../../resources/PartnerLogos/Vero_Moda.png';
import Allen from '../../../resources/PartnerLogos/Allen.png';
import Armani from '../../../resources/PartnerLogos/Armani Exchange-01.png';

const Accessories = (props) => {
  const navigate = useNavigate();

  const navigateNextTwoTone = (id, name) => {
    navigate('/rewards/voucher/' + name + 'lx_' + id);
  };
  return (
    <>
      <Stack
        direction={'column'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          backgroundColor: '#262525',
        }}>
        <Box
          sx={{
            paddingTop: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            paddingBottom: '36px',
          }}>
          <h2 className="partner-heading">
          Elevate your lifestyle{' '}
          </h2>
        </Box>
        <Stack direction={'row'} spacing={8}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('58', 'Vero Moda')}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img
                alt=""
                src={Vero_Moda}
                className="logo_brand_img"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('50', 'Allen Solly')}
              sx={{
                backgroundColor: '#262525',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img
                alt=""
                src={Allen}
                className="logo_brand"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('54', 'Armani Exchange')}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img
                alt=""
                src={Armani}
                className="logo_brand_img"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('66', 'Helios')}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Helios} className="logo_brand_img" />
            </Box>
          </Box>
          {/* <Box>
            <Box
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}>
              <img alt="" src={bmw} className="logo_brand_img" />
            </Box>
          </Box> */}
        </Stack>
        <Box pt={8}>
          <h2 className="partner-heading">
            Exclusive Benefits For LinkCxO Members:
          </h2>
        </Box>
        <Box sx={{ width: '100%', padding: '40px 180px' }}>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 10 Lakh on Premium Real Estate
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 4 Lakh on Premium Automobiles
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Exclusive offers on Entertainment and Hospitality
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Offers and Discounts on Premium Apparel and Accessory Brands
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Discount on Purchases of Electronics from India’s Leading Big Box
            Brands
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Health Package Discounts from one of India’s Leading Digital
            Healthcare Platform
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Opportunity to Invest in Hand Picked Startups to start your Angel
            Investing Journey with exclusive Discounts
          </p>
        </Box>
      </Stack>
    </>
  );
};

export default Accessories;
