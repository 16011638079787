import React from "react";

let EventsIcon = (props) => {
  switch (props.name) {
    case "event_fee":
      return (
        <svg
          fill={props.color || "#ffffff"}
          width={props.width || "24"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 102.26 61.27"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M7.42,7.62c0-1.78,0-3.43,0-5.09A2.21,2.21,0,0,1,9.9,0H99.81a2.21,2.21,0,0,1,2.45,2.48V51.15a2.24,2.24,0,0,1-2.53,2.49H94.84v5c0,1.91-.78,2.67-2.71,2.67q-44.73,0-89.45,0c-1.9,0-2.68-.79-2.68-2.7V10.34C0,8.39.78,7.62,2.76,7.62ZM77.53,12H17.32C15.8,19.14,11.51,23.44,4.4,25v19c7.13,1.53,11.42,5.83,12.93,12.93h60.2c1.53-7.15,5.84-11.43,12.9-12.92V25C83.3,23.41,79,19.12,77.53,12Zm14.54-4.4c2,0,2.77.74,2.77,2.79V49.23h3V4.42H11.82v3.2ZM12.8,56.89c-.72-4.19-4.75-8.17-8.37-8.38v8.38ZM4.35,20.44c4.23-.77,8.16-4.75,8.39-8.47l-8.39,0ZM82.09,12c.31,3.75,4.31,7.78,8.35,8.4V12Zm8.36,36.43c-4.18.76-8.22,4.86-8.33,8.41h8.33Z"
              />
              <path
                class="cls-1"
                d="M47.26,14.73A19.72,19.72,0,1,1,27.57,34.47,19.72,19.72,0,0,1,47.26,14.73ZM62.6,34.44A15.34,15.34,0,1,0,47.3,49.8,15.36,15.36,0,0,0,62.6,34.44Z"
              />
            </g>
          </g>
        </svg>
      );

    case "category":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 43.04 45.28"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path class="cls-1" d="M43,0V4.48H0V0Z" />
              <path class="cls-1" d="M0,18.07V13.59H43v4.48Z" />
              <path class="cls-1" d="M43,27.2v4.48H0V27.2Z" />
              <path class="cls-1" d="M43,40.81v4.47H0V40.81Z" />
            </g>
          </g>
        </svg>
      );
    case "event_name":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 124.5 106.43"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M4.41,0C1.2,0,0,1.21,0,4.45v97.31a12.93,12.93,0,0,0,.1,1.88,3.33,3.33,0,0,0,2.7,2.68,11.12,11.12,0,0,0,1.76.1q57.69,0,115.38,0a11.19,11.19,0,0,0,1.76-.1,3.34,3.34,0,0,0,2.69-2.69,9.08,9.08,0,0,0,.1-1.63q0-31.48,0-63V21.54q0-8.73,0-17.46c0-2.73-1.33-4.07-4-4.08Zm113,99.35H7.11V34.21H117.36Zm0-92.23V27H7.13V7.12Z"
              />
              <path
                class="cls-1"
                d="M102.84,63.25H67.32a5.88,5.88,0,0,0-1.33.1,3.52,3.52,0,0,0-2.73,3.83c.23,1.91,1.73,3.1,3.93,3.1q17.64,0,35.28,0a5.3,5.3,0,0,0,1.77-.28,3.41,3.41,0,0,0,2.1-3.94A3.47,3.47,0,0,0,102.84,63.25Z"
              />
            </g>
          </g>
        </svg>
      );
    case "event_attending":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50.83 43.45"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M1.8,0C.49,0,0,.49,0,1.82V41.55a4.78,4.78,0,0,0,0,.76,1.36,1.36,0,0,0,1.1,1.1,5.58,5.58,0,0,0,.72,0H49a4.23,4.23,0,0,0,.72,0,1.39,1.39,0,0,0,1.1-1.1,4.47,4.47,0,0,0,0-.67v-40A1.48,1.48,0,0,0,49.17,0ZM47.92,40.56H2.9V14h45Zm0-37.65V11h-45V2.91Z"
              />
              <path
                class="cls-1"
                d="M32.9,22.11a1,1,0,0,0-1.08-.19,1,1,0,0,0-.66.87,1.14,1.14,0,0,0,.39,1l2,2,.45.46H22.23a1.06,1.06,0,0,0,0,2.11H34l-2.45,2.46a1.1,1.1,0,0,0-.38,1,1,1,0,0,0,.68.85,1,1,0,0,0,.38.07,1.07,1.07,0,0,0,.78-.36l2.49-2.49,1.67-1.66a1.11,1.11,0,0,0,0-1.82Z"
              />
              <path
                class="cls-1"
                d="M41.2,19.44v0a1,1,0,0,0-1-.93,1,1,0,0,0-.76.28,1.27,1.27,0,0,0-.33.91V34.92a1.17,1.17,0,0,0,.35.86,1.05,1.05,0,0,0,.71.26h.12a1.07,1.07,0,0,0,.94-1.2Z"
              />
            </g>
          </g>
        </svg>
      );
    case "events_suggestedby":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 122.51 165.01"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M0,150a14.35,14.35,0,0,1,1-5.25,14.13,14.13,0,0,1,2.94-4.63,14.66,14.66,0,0,1,10.94-4.6,14.68,14.68,0,0,1,7.32,1.75,11.3,11.3,0,0,1,4.61,4.61l-6.1,4.35a5.36,5.36,0,0,0-1.09-1.77,5.47,5.47,0,0,0-1.5-1.1,6.11,6.11,0,0,0-1.7-.58,9.2,9.2,0,0,0-1.7-.16,6.15,6.15,0,0,0-3,.67A6.08,6.08,0,0,0,9.7,145a7.4,7.4,0,0,0-1.19,2.46,9.88,9.88,0,0,0-.39,2.72,9.44,9.44,0,0,0,.45,2.92,7.64,7.64,0,0,0,1.31,2.46,6.6,6.6,0,0,0,2.11,1.7,6.18,6.18,0,0,0,2.81.63,8.14,8.14,0,0,0,1.68-.18,6.28,6.28,0,0,0,1.66-.62,5.46,5.46,0,0,0,1.44-1.1,4.91,4.91,0,0,0,1-1.66l6.52,3.89a8.3,8.3,0,0,1-2,2.87,12.52,12.52,0,0,1-3,2.13,16.34,16.34,0,0,1-3.65,1.32,16.81,16.81,0,0,1-3.79.45,13.39,13.39,0,0,1-5.93-1.3,15,15,0,0,1-4.63-3.42,15.45,15.45,0,0,1-3-4.84A14.65,14.65,0,0,1,0,150Z"
              />
              <path
                class="cls-1"
                d="M55.11,135.65l5.28,9.18,5.29-9.18h8.61l-9.71,14.76L74,164.76H65.36l-5-8.77-5,8.77H46.78l9.39-14.35-9.72-14.76Z"
              />
              <path
                class="cls-1"
                d="M107.67,165a14,14,0,0,1-6-1.28A14.77,14.77,0,0,1,97,160.39a15.07,15.07,0,0,1-3-4.73,14.71,14.71,0,0,1-1.07-5.5,14.26,14.26,0,0,1,1.11-5.53,14.88,14.88,0,0,1,3.09-4.7,15.25,15.25,0,0,1,4.72-3.24,14.63,14.63,0,0,1,6-1.21,14,14,0,0,1,6,1.28,14.56,14.56,0,0,1,4.64,3.36,15.22,15.22,0,0,1,3,4.75,14.79,14.79,0,0,1,1,5.46,14.26,14.26,0,0,1-1.1,5.51,14.93,14.93,0,0,1-7.77,7.94A14.36,14.36,0,0,1,107.67,165Zm-6.6-14.76a10.07,10.07,0,0,0,.41,2.85,7.33,7.33,0,0,0,1.23,2.43,6.22,6.22,0,0,0,2.07,1.73,7.08,7.08,0,0,0,6,0,6.14,6.14,0,0,0,2.07-1.77A7.39,7.39,0,0,0,114,153a10.45,10.45,0,0,0,.39-2.83,9.73,9.73,0,0,0-.41-2.85,7.05,7.05,0,0,0-1.25-2.42,6.77,6.77,0,0,0-8-1.66,6,6,0,0,0-2.07,1.75,7.25,7.25,0,0,0-1.21,2.46A10.2,10.2,0,0,0,101.07,150.25Z"
              />
              <path
                class="cls-1"
                d="M82.33,48.44l.06-.18a3.27,3.27,0,0,1,.51-1.06,15.53,15.53,0,0,0,4.2-9.39,11.72,11.72,0,0,0-.49-4c-.13-.56-.27-1.12-.36-1.67,0-.23-.1-.47-.15-.7A3,3,0,0,1,86,30.34a21.7,21.7,0,0,0,.52-8C85.71,15.8,83.66,11,80,7.17,75.44,2.35,69.5.07,61.34,0h0C52.9,0,46.93,2.34,42.54,7.21c-5.62,6.25-7.65,13.86-6,22.64a3.59,3.59,0,0,1,.07,1.62c-3.1,5.14.08,11.94,2.45,15.14l.2.27a6.52,6.52,0,0,1,1,1.56A45.88,45.88,0,0,0,48,62.11a17.14,17.14,0,0,0,13.33,6.12h0a17.15,17.15,0,0,0,13.33-6.12A45,45,0,0,0,82.33,48.44ZM43.26,35.84l.06-.09A2.56,2.56,0,0,0,45,34.63a3.59,3.59,0,0,0,0-2.84c-.9-3.41-1.66-7.48-.52-11.83,1.89-7.23,6.88-11.47,14-11.93a30.87,30.87,0,0,1,7.93.4C72.84,9.68,77,14.07,78.37,21.11c.8,4-.05,7.76-1,11.29-.17.66-.63,2.4,1.4,3.06.49.15.71.49.59.91a23.73,23.73,0,0,1-1.5,3.93l-.28.05a4.62,4.62,0,0,0-.51.1l-.42.12-.15.42c-.3.82-.56,1.65-.83,2.48a35.36,35.36,0,0,1-2,5.24A59,59,0,0,1,68.51,57a8.72,8.72,0,0,1-6.85,3.39A9.69,9.69,0,0,1,54,57a36.14,36.14,0,0,1-7.37-14.84l-.4-1.65-.57-.09a3.32,3.32,0,0,1-2-1.92A3.3,3.3,0,0,1,43.26,35.84Z"
              />
              <path
                class="cls-1"
                d="M13.28,124.69a3.94,3.94,0,0,0,3.93-3.81l.14-4.27q.36-11.25.74-22.51a5.85,5.85,0,0,1,3-5.07C28.5,84.59,36,80.13,43,76a1.67,1.67,0,0,1,.85-.27,5,5,0,0,0,3.7-1,3.6,3.6,0,0,0,1.14-2.62,5,5,0,0,0-1.36-3.72,4.33,4.33,0,0,0-3.13-1.3,7.51,7.51,0,0,0-3.41,1.09c-8.52,5-16.26,9.63-23.65,14.09a13.44,13.44,0,0,0-6.89,11.51c-.19,4.6-.34,9.19-.48,13.79-.06,2-.12,3.89-.19,5.84l-.18,5.82c0,.47,0,.94-.05,1.4a3.94,3.94,0,0,0,3.94,4.07Z"
              />
              <path
                class="cls-1"
                d="M54.14,124.69a3.49,3.49,0,0,0,3.46-3L61,96.08c0-.11,0-.21,0-.32l.33,2.49q1.32,10,2.65,20c.15,1.12.3,2.26.45,3.4a3.51,3.51,0,0,0,3.47,3h0a3.49,3.49,0,0,0,3.46-4l-.31-2.28L67.51,91.64c-.08-.62-.18-1.25-.28-1.87-.54-3.32-1.06-6.46,1.45-9.2a2.12,2.12,0,0,0,.43-.94,1.05,1.05,0,0,1,0-.19,7.52,7.52,0,0,0-1.25-6.89,6.39,6.39,0,0,0-5.84-2.29,15.37,15.37,0,0,1-1.8,0l-.58,0A7.09,7.09,0,0,0,54,72.73a6.67,6.67,0,0,0-1.18,5.88,13.89,13.89,0,0,0,1.38,3.21c.31.57.6,1.11.84,1.67a3.61,3.61,0,0,1,.4,1.6q-1.45,11.19-3,22.37L51,118.64c-.09.7-.18,1.4-.28,2.1a3.5,3.5,0,0,0,3.47,4Zm9.19-48L61.05,81l-2.27-4.3Z"
              />
              <path
                class="cls-1"
                d="M113,114.49l-.22-6.92c-.14-4.58-.29-9.16-.48-13.74a13.56,13.56,0,0,0-7-11.62c-3.09-1.87-6.2-3.72-9.3-5.57L89.78,72.9c-2.85-1.71-5.7-3.42-8.58-5.09a5,5,0,0,0-4.43-.43,4.65,4.65,0,0,0-2.72,3.07,4.16,4.16,0,0,0,1.77,4.86,4.33,4.33,0,0,0,2.35.53,3.94,3.94,0,0,1,1.84.42c4.29,2.52,8.55,5.07,12.82,7.61L101,88.75a6.44,6.44,0,0,1,3.48,5.72q.41,13.22.87,26.42a3.92,3.92,0,0,0,3.92,3.8h0a3.92,3.92,0,0,0,3.92-4.06Z"
              />
            </g>
          </g>
        </svg>
      );

    case "events_partnered":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 124.5 106.43"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M86.51,90.48c-5.26,0-10.53,0-15.79,0a7.86,7.86,0,0,1-7.55-5.72,7.62,7.62,0,0,1-.31-2.13q0-15.86,0-31.71a7.84,7.84,0,0,1,7.76-7.82c5.28,0,10.57,0,15.85,0a2.63,2.63,0,0,1,.94,5.12,3.89,3.89,0,0,1-1.27.16H71.09a3.52,3.52,0,0,0-1.21.14,2.6,2.6,0,0,0-1.73,2.33c0,.21,0,.41,0,.62V82.12a4.49,4.49,0,0,0,.07,1.1,2.62,2.62,0,0,0,2.4,2h31.33a3.87,3.87,0,0,0,1.22-.12A2.62,2.62,0,0,0,105,82.74c0-.26,0-.53,0-.8V67.19a3.87,3.87,0,0,1,.13-1.22,2.63,2.63,0,0,1,5.13.58c0,.12,0,.24,0,.37,0,5.22,0,10.44,0,15.67a7.88,7.88,0,0,1-6.17,7.7,9.16,9.16,0,0,1-2,.19Z"
              />
              <path
                class="cls-1"
                d="M104.82,52.15c-.15.18-.29.38-.45.55Q96.5,60.58,88.61,68.45a2.69,2.69,0,0,1-2.79.84A2.63,2.63,0,0,1,84.57,65c.17-.2.37-.38.56-.57l15.58-15.56a4.29,4.29,0,0,1,.48-.36l-.09-.17h-.52c-1.17,0-2.34,0-3.51,0a2.63,2.63,0,0,1-.52-5.21,3.6,3.6,0,0,1,.73-.06H107.4a2.64,2.64,0,0,1,2.85,2.84c0,3.44,0,6.87,0,10.31a2.53,2.53,0,0,1-1.43,2.35,2.48,2.48,0,0,1-2.63-.13,2.59,2.59,0,0,1-1.21-2.3c0-1.11,0-2.22,0-3.33v-.58Z"
              />
              <path
                class="cls-1"
                d="M4.41,0C1.2,0,0,1.21,0,4.45v97.31a12.93,12.93,0,0,0,.1,1.88,3.33,3.33,0,0,0,2.7,2.68,11.12,11.12,0,0,0,1.76.1q57.69,0,115.38,0a11.19,11.19,0,0,0,1.76-.1,3.34,3.34,0,0,0,2.69-2.69,9.08,9.08,0,0,0,.1-1.63q0-31.48,0-63V21.54q0-8.73,0-17.46c0-2.73-1.33-4.07-4-4.08Zm113,99.35H7.11V34.21H117.36Zm0-92.23V27H7.13V7.12Z"
              />
            </g>
          </g>
        </svg>
      );

    case "events_online":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 75.26 64.33"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M2.67,0C.72,0,0,.73,0,2.69V61.51a7.2,7.2,0,0,0,.06,1.14,2,2,0,0,0,1.63,1.62,7.37,7.37,0,0,0,1.06.06H72.5a7.21,7.21,0,0,0,1.06-.06,2,2,0,0,0,1.63-1.63,5.91,5.91,0,0,0,.06-1V13c0-3.52,0-7,0-10.55A2.17,2.17,0,0,0,72.8,0ZM70.94,60.05H4.3V20.68H70.94ZM71,4.3V16.35H4.31V4.3Z"
              />
              <path
                class="cls-1"
                d="M49.8,30.8a18.44,18.44,0,0,1,12.07,4.49,1.44,1.44,0,0,1,.37,1.94,1.38,1.38,0,0,1-1.94.4c-.31-.2-.6-.44-.89-.67a15.62,15.62,0,0,0-20,.54,1.41,1.41,0,0,1-2-.08,1.39,1.39,0,0,1,.13-2,18.93,18.93,0,0,1,6.87-3.83A18.37,18.37,0,0,1,49.8,30.8Z"
              />
              <path
                class="cls-1"
                d="M50.31,36.55a12.31,12.31,0,0,1,7.18,2.59l.37.28a1.41,1.41,0,0,1,.24,2,1.39,1.39,0,0,1-2,.21,9.37,9.37,0,0,0-4.82-2.18,9.78,9.78,0,0,0-7.82,2.12,1.41,1.41,0,1,1-1.66-2.24c.57-.36,1.11-.78,1.7-1.12A12.93,12.93,0,0,1,50.31,36.55Z"
              />
              <path
                class="cls-1"
                d="M50.16,42.21a6.71,6.71,0,0,1,3.63,1.23,1.37,1.37,0,0,1,.39,1.91,1.42,1.42,0,0,1-1.94.43,4.24,4.24,0,0,0-4.88,0,1.41,1.41,0,0,1-1.61-2.32A7.26,7.26,0,0,1,50.16,42.21Z"
              />
              <path
                class="cls-1"
                d="M49.79,50.75a1.43,1.43,0,0,1,0-2.86,1.43,1.43,0,1,1,0,2.86Z"
              />
            </g>
          </g>
        </svg>
      );
    case "events_offline":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 77.89 66.58"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M2.76,0C.75,0,0,.76,0,2.78V63.67a6.51,6.51,0,0,0,.07,1.17,2.07,2.07,0,0,0,1.68,1.68,6.58,6.58,0,0,0,1.1.06H75a6.58,6.58,0,0,0,1.1-.06,2.11,2.11,0,0,0,1.69-1.68,6.42,6.42,0,0,0,.06-1V2.55A2.25,2.25,0,0,0,75.35,0ZM73.43,62.15h-69V21.4h69Zm0-57.7V16.92h-69V4.45Z"
              />
              <path
                class="cls-1"
                d="M53.29,29.5A18.71,18.71,0,0,1,65.19,34a1.81,1.81,0,0,1,.74,1,1.44,1.44,0,0,1-2.29,1.49,17.2,17.2,0,0,0-5.54-3.18,15.86,15.86,0,0,0-15.52,2.92l-.24.23a1.44,1.44,0,1,1-1.92-2.14A19.13,19.13,0,0,1,48,30.16,19.79,19.79,0,0,1,53.29,29.5Z"
              />
              <path
                class="cls-1"
                d="M50.92,47c-.79-.78-1.55-1.56-2.33-2.32a1.2,1.2,0,0,1-.41-1.36,1.76,1.76,0,0,1,1.26-1.15,1.16,1.16,0,0,1,1.13.38c.79.8,1.59,1.59,2.4,2.4l.28-.26,2.12-2.12a1.22,1.22,0,0,1,1.87,0l.19.2a1.2,1.2,0,0,1,0,1.84l-2.15,2.15L55,47l2.28,2.27a1.16,1.16,0,0,1,.37,1.19,1.78,1.78,0,0,1-1.16,1.22,1.23,1.23,0,0,1-1.36-.43c-.71-.73-1.44-1.45-2.18-2.19l-.25.23-2,2a1.23,1.23,0,0,1-2,0l-.21-.22a1.19,1.19,0,0,1,0-1.72c.68-.69,1.37-1.37,2-2.05Z"
              />
              <path
                class="cls-1"
                d="M52.83,35.38A13.19,13.19,0,0,1,60.75,38l.49.36a1.44,1.44,0,1,1-1.79,2.25,10.19,10.19,0,0,0-12.77-.16l-.23.2a1.44,1.44,0,0,1-2.05-.2,1.43,1.43,0,0,1,.25-2,13.36,13.36,0,0,1,7.06-2.92C52.13,35.4,52.56,35.4,52.83,35.38Z"
              />
            </g>
          </g>
        </svg>
      );
    case "events_invitation":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 143 142.99"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M71.48,143H4.83A16.51,16.51,0,0,1,3,142.93,3.25,3.25,0,0,1,.09,140a14.06,14.06,0,0,1,0-1.72Q0,99.67,0,61.05a5.59,5.59,0,0,1,2.22-4.79c5.55-4.46,11-9.06,16.49-13.57a2.35,2.35,0,0,0,.94-2.06c0-6.69,0-13.37,0-20.06,0-2.84,1.26-4.1,4.11-4.1,8.46,0,16.91,0,25.37,0a3.75,3.75,0,0,0,2.16-.76C57.13,10.91,62.94,6.1,68.76,1.3c2.09-1.73,3.5-1.74,5.59,0C80.17,6.1,86,10.93,91.81,15.7a3.5,3.5,0,0,0,2,.74c8.46,0,16.91,0,25.37,0,3,0,4.2,1.23,4.2,4.15,0,6.65,0,13.29,0,19.93a2.63,2.63,0,0,0,1.1,2.27c5.58,4.57,11.11,9.2,16.7,13.76A4.55,4.55,0,0,1,143,60.4q0,39.27,0,78.54c0,2.87-1.21,4-4.14,4.05ZM136,66.71c-.63.44-1,.73-1.46,1l-14.81,11L74.3,112.68c-2.27,1.69-3.32,1.69-5.63,0L59,105.46q-19-14.07-38-28.14c-4.37-3.25-8.67-6.58-13-9.86a11.84,11.84,0,0,0-1-.64V136H136ZM71.48,106.15l.67-.44q21.76-16.23,43.53-32.42a2.08,2.08,0,0,0,.86-1.94q0-23.28,0-46.56c0-.46,0-.92-.06-1.34H26.56c0,.29,0,.48,0,.67q0,23.77,0,47.56a2.38,2.38,0,0,0,.87,1.59c4.37,3.32,8.78,6.59,13.19,9.87Zm0-98.28c-3.43,2.85-6.7,5.59-10.15,8.47H81.68Zm-51.95,43L9,59.64l10.54,7.85ZM134,59.6,123.53,51V67.41Z"
              />
              <path
                class="cls-1"
                d="M52.49,37.6c-1.05,0-1.44.4-1.44,1.46V70.81a3.08,3.08,0,0,0,0,.61,1.09,1.09,0,0,0,.87.88,3.69,3.69,0,0,0,.58,0H90.19a3.51,3.51,0,0,0,.57,0,1.08,1.08,0,0,0,.88-.88,2.91,2.91,0,0,0,0-.53q0-10.27,0-20.55V44.63c0-1.9,0-3.79,0-5.69a1.17,1.17,0,0,0-1.32-1.34ZM89.35,70h-36V48.77h36Zm0-30.09v6.5h-36v-6.5Z"
              />
              <path
                class="cls-1"
                d="M81.37,41.11h0a2.07,2.07,0,0,0,0,4.13h0a2.07,2.07,0,0,0,2.07-2,2.1,2.1,0,0,0-.6-1.48A2.05,2.05,0,0,0,81.37,41.11Z"
              />
              <path
                class="cls-1"
                d="M61.38,41.11h0a2.06,2.06,0,0,0-1.48,3.51,2,2,0,0,0,1.46.62h0a2.06,2.06,0,0,0,2.06-2,2.08,2.08,0,0,0-2-2.11Z"
              />
            </g>
          </g>
        </svg>
      );
    case "events_time":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 150.63 168.61"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M63.58.06a5.76,5.76,0,0,0-4,2.22,4.41,4.41,0,0,0-.47,4.58,5,5,0,0,0,4.32,2.82l6.54,0V31a70,70,0,0,0-29.42,9.07C13.66,55.31,0,87.75,8.06,117.21a69.56,69.56,0,0,0,67.17,51.4,70.87,70.87,0,0,0,12-1A69.18,69.18,0,0,0,143.77,88C138.51,57,111.94,33.08,80.58,31c-.6,0-.71-.14-.71-.14L79.81,9.71l7.31,0a4.74,4.74,0,0,0,4.76-4.95A4.77,4.77,0,0,0,87.07,0Zm71.51,99.79c-.12,32.59-26.91,59.06-59.74,59.06h-.1a59.7,59.7,0,0,1-42.52-17.73A58.41,58.41,0,0,1,15.52,99.57c.12-32.56,26.9-59,59.73-59h.11a59.78,59.78,0,0,1,42.57,17.73A58.32,58.32,0,0,1,135.09,99.85Z"
              />
              <path
                class="cls-1"
                d="M6.05,52.09l.47-.25a8.49,8.49,0,0,0,2.09-1.39c4.37-4.24,8.77-8.6,13-12.82l1.47-1.45c2.32-2.31,2.52-5.15.53-7.25a4.67,4.67,0,0,0-3.3-1.52,5.64,5.64,0,0,0-4.08,1.87Q9.13,36.3,2,43.35a8,8,0,0,0-1.21,1.4,4.56,4.56,0,0,0-.16,4.94c1,1.68,2.46,2.47,4.85,2.55h.3Z"
              />
              <path
                class="cls-1"
                d="M149.1,43.91c-5.35-5.34-10.3-10.24-15.14-15a4.7,4.7,0,0,0-6.85-.09,4.88,4.88,0,0,0-1.52,3.43,5.07,5.07,0,0,0,1.57,3.57c5.79,5.79,10.57,10.51,15,14.85a6.77,6.77,0,0,0,2,1.23,3.6,3.6,0,0,1,.39.19l.23.12h.29c2.46,0,4-.88,5-2.78A4.58,4.58,0,0,0,149.1,43.91Z"
              />
              <path
                class="cls-1"
                d="M80.2,73c0-3.07-2-5.25-4.78-5.31a4.94,4.94,0,0,0-3.52,1.35,5,5,0,0,0-1.47,3.6c-.05,9.92-.05,19.64,0,28.92a4.77,4.77,0,0,0,4.95,5H94.75A5.23,5.23,0,0,0,98.59,105a4.86,4.86,0,0,0,1.21-3.65c-.17-2.68-2.24-4.49-5.16-4.5H80.21Z"
              />
            </g>
          </g>
        </svg>
      );
    case "events_add_banner":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 118.04 118.27"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M44.77,118.23c-10.42,0-20.84.06-31.26,0C4.36,118.16.05,113.89,0,104.9Q0,80.82,0,56.74c0-9.08,4-13.52,12.94-13,6.29.4,10.82-1.35,14.27-6.5,1.8-2.66,4.37-3.63,7.68-3.52,6.44.23,12.91.27,19.35,0,3.69-.16,6.57.91,8.47,4,3.2,5.09,7.69,6.29,13.48,6,9.27-.42,13.4,3.93,13.41,13q0,24.08,0,48.16c0,8.63-4.4,13.15-13.1,13.25C66,118.32,55.36,118.23,44.77,118.23Zm0-6c10.25,0,20.5,0,30.75,0,6.46,0,8.23-1.77,8.24-8.22q0-21.09,0-42.18c0-12.39,0-12.41-12.48-12.39-3.16,0-6.69.48-8.55-2.65-4.09-6.86-10.25-7.87-17.38-7.14a66.36,66.36,0,0,1-8.92,0c-1.93-.07-3.68.17-4.77,1.92-4.38,7.07-10.83,8.64-18.7,8C7.61,49.1,6.05,51.2,6,56.76q0,24.06,0,48.13c0,5.54,1.79,7.29,7.49,7.32C24,112.26,34.36,112.23,44.78,112.23Z"
              />
              <path
                class="cls-1"
                d="M103.18,14.94c3.73,0,7,0,10.26,0,2.44,0,4.57.77,4.6,3.37s-2.26,2.84-4.36,3c-3.26.19-6.57-.46-10,.48-1,3.44-.37,6.93-.52,10.35-.1,2.25-.88,4.11-3.3,4.12s-3.19-2-3.2-4.18c0-3.43,0-6.87,0-10.82-4.07,0-7.51.07-11,0-2.21-.06-4-1.05-3.89-3.53.14-2.28,2-2.72,4-2.72,3.44,0,6.87,0,10.83,0,0-3.9,0-7.33,0-10.76C96.72,2,97.25.08,99.86,0s3.23,2,3.29,4.06C103.26,7.49,103.18,10.93,103.18,14.94Z"
              />
              <path
                class="cls-1"
                d="M44.81,59.55c11.47,0,20.2,8.65,20.12,20A20,20,0,0,1,44.76,99.1C33.57,99,24.25,89.74,24.45,79S33.7,59.56,44.81,59.55ZM58.94,79.43c.06-8-6-13.9-14.18-13.89-8,0-14,5.78-14.1,13.49a13.78,13.78,0,0,0,14.2,14C52.8,93,58.88,87.08,58.94,79.43Z"
              />
            </g>
          </g>
        </svg>
      );
    case "club_owner":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48.78 35.77"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M14.77,17.57,21.25,7.39c-1.66-2.53-1.53-4.75.34-6.35a4.29,4.29,0,0,1,5.66.05c1.83,1.63,1.93,3.86.28,6.3L34,17.58l8.34-3.74a9.35,9.35,0,0,1,0-1.5A3.23,3.23,0,0,1,45.3,9.76,3.28,3.28,0,0,1,48.61,12a3.2,3.2,0,0,1-1.3,3.72,1.57,1.57,0,0,0-.63.77q-2.93,6.87-5.86,13.73a2,2,0,0,0-.16.77c0,1.18,0,2.35,0,3.53a1.14,1.14,0,0,1-1.28,1.27H9.43a1.15,1.15,0,0,1-1.3-1.3v0a20.79,20.79,0,0,0-.07-3.87A19.49,19.49,0,0,0,6.6,27Q4.34,21.71,2.05,16.41a1.66,1.66,0,0,0-.58-.68,3.25,3.25,0,1,1,5-2.65c0,.26-.1.59,0,.76s.46.25.71.36l7.11,3.19Zm29.69-1.39A1.29,1.29,0,0,0,42.75,16c-2.79,1.29-5.61,2.54-8.42,3.81-.88.4-1.29.27-1.81-.54L25.92,8.93c-.09-.15-.19-.29-.22-.34H23.07l-.21.33-6.6,10.35c-.52.82-.93.95-1.81.55C11.62,18.55,8.77,17.29,6,16a1.26,1.26,0,0,0-1.62.17,1.24,1.24,0,0,0,.06.18C6.19,20.54,8,24.75,9.8,29c.13.29.31.3.56.3h28a.57.57,0,0,0,.62-.42c1.17-2.77,2.36-5.53,3.55-8.29C43.2,19.09,43.84,17.62,44.46,16.18ZM10.32,33.57H38.44V31.46H10.32ZM26.56,4.34A2.17,2.17,0,1,0,24.37,6.5,2.16,2.16,0,0,0,26.56,4.34ZM3.23,11.93a1.08,1.08,0,1,0,0,2.16A1.08,1.08,0,0,0,4.33,13,1.06,1.06,0,0,0,3.23,11.93ZM46.61,13a1.06,1.06,0,0,0-1.07-1.09A1.07,1.07,0,0,0,44.44,13a1.08,1.08,0,0,0,1.07,1.1A1.07,1.07,0,0,0,46.61,13Z"
              />
              <path
                class="cls-1"
                d="M28.18,22.83A3.8,3.8,0,1,1,24.45,19,3.81,3.81,0,0,1,28.18,22.83Zm-3.77-1.69A1.63,1.63,0,1,0,26,22.8,1.61,1.61,0,0,0,24.41,21.14Z"
              />
            </g>
          </g>
        </svg>
      );

    case "event_type":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 82.69 70.69"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M2.93,0C.8,0,0,.8,0,3V67.59a7.25,7.25,0,0,0,.07,1.24,2.22,2.22,0,0,0,1.79,1.79A7.39,7.39,0,0,0,3,70.68H79.66a7.39,7.39,0,0,0,1.17-.06,2.24,2.24,0,0,0,1.79-1.79,7.13,7.13,0,0,0,.07-1.09v-65A2.39,2.39,0,0,0,80,0ZM78,66H4.72V22.72H78ZM78,4.73V18H4.74V4.73Z"
              />
              <path
                class="cls-1"
                d="M61.72,7.24h0a4.21,4.21,0,0,0,0,8.41h0a4.2,4.2,0,0,0,3-7.15A4.13,4.13,0,0,0,61.72,7.24Z"
              />
              <path
                class="cls-1"
                d="M21,7.24h0a4.2,4.2,0,0,0-3,7.14,4.09,4.09,0,0,0,3,1.26h0A4.2,4.2,0,0,0,21,7.24Z"
              />
              <path class="cls-1" d="M50.52,37V32.48H68.81V37Z" />
              <path class="cls-1" d="M50.52,46.16V41.65H68.8v4.51Z" />
              <path class="cls-1" d="M50.52,55.33V50.82H68.81v4.51Z" />
              <path class="cls-1" d="M45.89,32.5V37H41.36V32.5Z" />
              <path class="cls-1" d="M45.88,41.69v4.47h-4.5V41.69Z" />
              <path class="cls-1" d="M45.86,55.36H41.37V50.83h4.49Z" />
            </g>
          </g>
        </svg>
      );

    case "event_mode":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 71 71"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M17.36,51.24q-7.48,0-15,0A2.22,2.22,0,0,0,0,53.68q0,7.44,0,14.87A2.23,2.23,0,0,0,2.49,71C5,71,7.41,71,9.87,71s5,0,7.48,0a2.21,2.21,0,0,0,2.4-2.34q0-7.53,0-15.05A2.21,2.21,0,0,0,17.36,51.24ZM15.29,66.5H4.51V55.72H15.29Z"
              />
              <path
                class="cls-1"
                d="M2.59,19.76H17.2a2.31,2.31,0,0,0,2.56-2.58q0-7.3,0-14.61A2.29,2.29,0,0,0,17.2,0H2.6A2.29,2.29,0,0,0,0,2.61V17.13A2.3,2.3,0,0,0,2.59,19.76ZM4.5,4.51H15.28V15.26H4.5Z"
              />
              <path
                class="cls-1"
                d="M2.52,45.35H17.2a2.26,2.26,0,0,0,2.56-2.56q0-7.29,0-14.58c0-1.79-.8-2.57-2.61-2.57-2.41,0-4.81,0-7.21,0s-4.92,0-7.38,0A2.25,2.25,0,0,0,0,28.18V42.77C0,44.5.82,45.35,2.52,45.35Zm2-15.22H15.27V40.85H4.51Z"
              />
              <path
                class="cls-1"
                d="M23.83,13.3a2.18,2.18,0,0,0,.31,4.29,8.46,8.46,0,0,0,1,0H67.93a10.29,10.29,0,0,0,1.11,0,2.19,2.19,0,0,0,.32-4.29,5.33,5.33,0,0,0-1.19-.11H25A5.22,5.22,0,0,0,23.83,13.3Z"
              />
              <path
                class="cls-1"
                d="M69.54,64.6a4.8,4.8,0,0,0-1.37-.13h-43c-.34,0-.68,0-1,0A2.19,2.19,0,0,0,24,68.81a5.87,5.87,0,0,0,1.11.09H68.11a5,5,0,0,0,1.28-.12,2.19,2.19,0,0,0,.15-4.17Z"
              />
              <path
                class="cls-1"
                d="M24.64,43.26q11,0,21.9,0H68.07c.31,0,.62,0,.92,0a2.21,2.21,0,0,0,0-4.41c-.31,0-.62,0-.93,0H24.51a2.17,2.17,0,0,0-2.13,1.34A2.22,2.22,0,0,0,24.64,43.26Z"
              />
              <path
                class="cls-1"
                d="M24.64,6.53H49.12c1.05,0,2.1,0,3.14,0a2.19,2.19,0,0,0,.24-4.35,5.86,5.86,0,0,0-1.11-.06h-24c-1,0-1.91,0-2.87,0a2.22,2.22,0,1,0,.13,4.43Z"
              />
              <path
                class="cls-1"
                d="M24.78,57.8H51.47a5.45,5.45,0,0,0,1.28-.12,2.19,2.19,0,0,0-.29-4.28,8.69,8.69,0,0,0-1,0H25.12a6.83,6.83,0,0,0-1.11.05,2.17,2.17,0,0,0-1.7,2.85A2.28,2.28,0,0,0,24.78,57.8Z"
              />
              <path
                class="cls-1"
                d="M24.79,27.74a3.51,3.51,0,0,0-.92.07,2.21,2.21,0,0,0-1.66,2.44,2.25,2.25,0,0,0,2.34,1.92H51.8A2.26,2.26,0,0,0,54.35,30c0-1.35-1-2.25-2.59-2.25Z"
              />
            </g>
          </g>
        </svg>
      );

    case "event_link":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 135.24 136.43"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M0,99.28C0,89,3.2,80.81,9.67,74c5.68-6,11.59-11.75,17.4-17.61,1.09-1.1,2.29-1.81,3.9-1.07a3.5,3.5,0,0,1,2.17,3.46A36.54,36.54,0,0,0,34,65.9a6.74,6.74,0,0,1-2.15,6.95c-4.41,4.07-9,8-12.51,12.94-5.27,7.39-6.12,15.31-1.78,23.26,4.62,8.45,12.1,12.47,21.77,11.71a21.52,21.52,0,0,0,13.44-6.45c6.56-6.44,13.11-12.91,19.51-19.51C82.73,84,80.67,67.93,67.88,59.9c-3.57-2.24-3.73-2.85-2.49-6.94a15.43,15.43,0,0,1,3.86-6c1.57-1.67,2.83-1.84,4.86-.71,11.09,6.16,17.7,15.38,19.34,28.1s-2.67,23.22-11.67,32.07c-6.13,6-12.17,12.14-18.24,18.23a36.74,36.74,0,0,1-20.92,10.74c-2.35.37-4.65,1.38-7.07.94-7.55-1.38-14.94-3.19-21.11-8.16C7.55,122.6,2.69,115.73.86,106.92A30.79,30.79,0,0,1,0,99.28Z"
              />
              <path
                class="cls-1"
                d="M135.24,36.84c-.07,10.76-3.45,19.34-10.37,26.43-5.46,5.6-11.05,11.07-16.57,16.62-1.16,1.16-2.35,1.93-4.11,1.28s-2-2.09-2.09-3.72a65.73,65.73,0,0,0-1-8.16,4.83,4.83,0,0,1,1.56-4.83q5.42-5.35,10.73-10.79c8.87-9.12,9.45-21.61,1-31.07a21.71,21.71,0,0,0-27.09-4.07,22,22,0,0,0-4.73,3.54C76,28.65,69.35,35.16,62.83,41.78c-10.45,10.6-8.08,27.49,4.85,35,2.79,1.62,3.12,2.29,2.49,5.4a14.41,14.41,0,0,1-5,7.92c-1.41,1.24-2.87.82-4.3,0-8-4.39-13.89-10.65-17-19.28-5.38-15-2.42-28.39,8.6-39.87,6.43-6.69,13.07-13.19,19.68-19.71A36.48,36.48,0,0,1,92.55,1.05C95,.66,97.39-.36,99.89.13c7.43,1.46,14.81,3.17,20.86,8.12C130,15.85,135.19,25.53,135.24,36.84Z"
              />
            </g>
          </g>
        </svg>
      );
    case "event_start":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 71.14 60.97"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M68.29,0a2.56,2.56,0,0,1,2.84,2.86q0,6.87,0,13.75,0,20.66,0,41.31a5.65,5.65,0,0,1-.06,1,2.45,2.45,0,0,1-2,2A6.46,6.46,0,0,1,68,61H3.12A6.52,6.52,0,0,1,2,60.91a2.44,2.44,0,0,1-2-2A6.18,6.18,0,0,1,0,57.79V3.06C0,.93.92,0,3.05,0ZM66,20.38H5.14V55.86H66ZM66,5.13H5.14V15.21H66Z"
              />
              <path
                class="cls-1"
                d="M45.32,44.47c2.3-3.23,4.47-6.25,6.63-9.28q2.17-3.06,4.36-6.11a2.54,2.54,0,1,1,4.1,3c-2,2.8-4,5.58-6,8.37l-6.41,9c-1.24,1.73-2.88,1.86-4.39.36Q40,46.15,36.37,42.5A2.54,2.54,0,1,1,40,38.94c1.62,1.64,3.26,3.26,4.88,4.89A4.32,4.32,0,0,1,45.32,44.47Z"
              />
            </g>
          </g>
        </svg>
      );

    case "event_end":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 71.14 60.97"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M68.28,0a2.56,2.56,0,0,1,2.85,2.85q0,6.87,0,13.75,0,20.66,0,41.31a6.05,6.05,0,0,1,0,1,2.45,2.45,0,0,1-2,2.06,7.3,7.3,0,0,1-.94,0h-65c-.25,0-.5,0-.75,0A2.49,2.49,0,0,1,0,58.61a8.62,8.62,0,0,1,0-.88V3.07C0,.91.92,0,3.1,0ZM5.13,20.36V55.84H66V20.36ZM66,5.13H5.14V15.21H66Z"
              />
              <path
                class="cls-1"
                d="M50.71,37.17c2-2,3.94-3.94,5.83-5.85a2.49,2.49,0,0,1,2.73-.68,2.53,2.53,0,0,1,.89,4.25c-1.73,1.75-3.48,3.48-5.22,5.22a6.68,6.68,0,0,1-.62.47c2,2,3.91,3.91,5.82,5.8A2.54,2.54,0,1,1,56.62,50c-1.82-1.78-3.61-3.6-5.42-5.4-.11-.12-.23-.22-.38-.37-.18.17-.36.32-.53.49-1.66,1.66-3.36,3.27-5,5a2.59,2.59,0,1,1-3.65-3.6c1.72-1.6,3.33-3.3,5-5a4.54,4.54,0,0,1,.62-.46l-5.82-5.81A2.54,2.54,0,1,1,45,31.25c1.78,1.74,3.52,3.51,5.27,5.27A3.7,3.7,0,0,1,50.71,37.17Z"
              />
            </g>
          </g>
        </svg>
      );
    case "event_occurence":
      return (
        <svg
          fill={props.color || "#ffffff"}
          fill-rule="evenodd"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 71.47 71.47"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M58.86,37.83V54.56H8l9.8-9.74-2.95-3L0,56.66,14.88,71.47l2.95-3L8,58.75h55V37.83ZM56.59,0l-3,3,9.8,9.75h-55V33.64h4.2V16.91H63.44l-9.8,9.74,3,3L71.47,14.81Z"
              />
            </g>
          </g>
        </svg>
      );
    default:
      return <div>props.name</div>;
  }
};

export default EventsIcon;
