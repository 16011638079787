import React, { Component, Fragment, useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

const JobCardSkeleton = (props) => {
  return (
    <Stack direction={{ sx: "column", md: "row" }} spacing={1.5}>
      <Card
        sx={{
          display: "flex",
          backgroundColor: "#262525",
          backgroundImage: "none",
          minHeight: "185px",
          width: "50%",
        }}
      >
        <Stack direction="row" sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Box px={2} pt={2}>
                <Skeleton variant="rectangular" width={150} height={15} />
              </Box>

              <Box px={2} pt={1}>
                <Skeleton variant="rectangular" width={100} height={10} />
              </Box>

              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <Box px={2} pt={3}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <Box pt={3}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <Box px={2} pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <Box pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <Box px={2} pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <Box pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>

      <Card
        sx={{
          display: "flex",
          backgroundColor: "#262525",
          backgroundImage: "none",
          // minHeight: "250px",
          width: "50%",
        }}
      >
        <Stack direction="row" sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Box px={2} pt={2}>
                <Skeleton variant="rectangular" width={150} height={15} />
              </Box>

              <Box px={2} pt={2}>
                <Skeleton variant="rectangular" width={100} height={10} />
              </Box>

              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <Box px={2} pt={3}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <Box pt={3}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <Box px={2} pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <Box pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "50%" }}>
                  <Box px={2} pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
                <Stack sx={{ width: "50%" }}>
                  <Box pt={1}>
                    <Skeleton variant="rectangular" width={90} height={20} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>
    </Stack>
  );
};

export default React.memo(JobCardSkeleton);
