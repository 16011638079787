import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PlayStore from '../../resources/new-img/GooglePlaystore.png';
import QRCode from '../../resources/img/appqrcod.png';
import FaceBook from '../../resources/new-img/Facebook.png';
import YouTube from '../../resources/new-img/Youtube.png';
import Twitter from '../../resources/new-img/Twitter.png';
import Linkedin from '../../resources/new-img/LinkedIn.png';
import Instagram from '../../resources/new-img/Instagram.png';
import * as messageAction from '../../store/message';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1),
    backgroundColor: '#333',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
  },

  section1: {
    textAlign: 'center',
  },
  doubleRow: {
    gridRow: 'span 2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function Footer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false); // Track subscription
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribeBtn = () => {
    const data = {
      email: email,
      name: 'Subscriber',
      status: 'enabled',
    };
    axios({
      method: 'post',
      url: 'https://mail.dev.linkcxo.com/api/subscribers',
      data: data,
      headers: {
        authorization: 'Basic YWRtaW46YWRtaW5AMTIz',
      },
    })
      .then(async (response) => {
        const msg = 'Subscription successful';
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(msg, 'success'),
        );

        setIsSubscribed(true);
      })
      .catch(async (error) => {
        if (error?.message?.includes('409')) {
          const msg = 'E-mail already exists.';
          const sendmessage = await dispatch(
            messageAction.messagefromComponent(msg, 'error'),
          );
        } else {
          const msg = 'Error subscribing. Please try again later.';
          const sendmessage = await dispatch(
            messageAction.messagefromComponent(msg, 'error'),
          );
        }
      });
  };

  // const handleSubscribeBtn = () => {
  //     const data = {
  //       email: email,
  //       name: 'Subscriber',
  //       status: 'enabled',
  //       attribs: {
  //         industry: 'Healthcare',
  //         designation: 'CTO',
  //       },
  //     };
  //     axios({
  //       method: 'post',
  //       url: 'http://mail.dev.linkcxo.com/api/subscribers',
  //       data: data,
  //       headers: {
  //         'authorization': "Basic YWRtaW46YWRtaW5AMTIz"
  //       }
  //     })
  //       .then((response) => {
  //         console.log('Subscription successful', response.data);
  //         alert('Subscription successful');

  //         setIsSubscribed(true);
  //       })
  //       .catch((error) => {
  //         console.error('Error subscribing:', error);
  //         alert('Error subscribing. Please try again later.');
  //       });
  //   };

  return (
    <>
      {openSnackbar && <CustomizedSnackbars />}
      <Box component="footer" className={classes.footer}>
        <Grid container>
          <Grid item xs={6} sm={3} className={classes.doubleRow}>
            <Box className={classes.section1}>
              <Box>
                <img alt="" src={QRCode} className="play-style-section1" />
              </Box>
              <Box mt={2}>
                {' '}
                <a
                  href="https://www.facebook.com/LinkCXO/"
                  target="blank"
                  style={{ textDecoration: 'none' }}>
                  <img
                    src={FaceBook}
                    alt=""
                    style={{ width: '35px', height: '35px' }}
                  />{' '}
                </a>
                <a
                  href="https://www.instagram.com/link_cxo/"
                  target="blank"
                  style={{ textDecoration: 'none' }}>
                  <img
                    src={Instagram}
                    alt=""
                    style={{ width: '35px', height: '35px' }}
                  />
                </a>
                <a
                  href="https://twitter.com/linkcxo?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  target="blank"
                  style={{ textDecoration: 'none' }}>
                  {' '}
                  <img
                    src={Twitter}
                    alt=""
                    style={{ width: '35px', height: '35px' }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/linkcxo-global-private-ltd/"
                  target="blank"
                  style={{ textDecoration: 'none' }}>
                  {' '}
                  <img
                    src={Linkedin}
                    alt=""
                    style={{ width: '35px', height: '35px' }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/@linkcxoglobal"
                  target="blank"
                  style={{ textDecoration: 'none' }}>
                  {' '}
                  <img
                    src={YouTube}
                    alt=""
                    style={{ width: '35px', height: '35px' }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box className={classes.section}>
              <Box>
                {' '}
                <p className="address-style-sections">
                  <span
                    style={{
                      fontSize: '18px',
                      color: '#3dcd84',
                    }}>
                    NAVI MUMBAI (Head Office)
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: '18px' }}>
                    LinkCxO Global Pvt. Ltd.
                  </span>
                  <br />
                  <span style={{ fontSize: '14px' }}> AWFIS ,</span>{' '}
                  <span style={{ fontSize: '14px' }} className="numbers">
                    2
                  </span>
                  <span style={{ fontSize: '14px' }}>
                    nd Floor, Raghuleela,
                  </span>
                  <br />
                  <span style={{ fontSize: '14px' }}>
                    {' '}
                    Arcade Vishwaroop, Infotech Pvt. Ltd.
                  </span>
                  <br /> <span style={{ fontSize: '14px' }}>Plot No </span>
                  <span style={{ fontSize: '14px' }} className="numbers">
                    34,
                  </span>{' '}
                  <span style={{ fontSize: '14px' }} className="numbers">
                    35,
                  </span>{' '}
                  <span style={{ fontSize: '14px' }} className="numbers">
                    38,
                  </span>{' '}
                  <span style={{ fontSize: '14px' }}>Navi Mumbai,</span>
                  <br />
                  <span style={{ fontSize: '14px' }}> Maharashtra -</span>{' '}
                  <span style={{ fontSize: '14px' }} className="numbers">
                    400703
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: '14px' }}> Telephone:&nbsp;</span>
                  <span style={{ fontSize: '14px' }} className="numbers">
                    +91 8976803609
                  </span>
                  <br />
                  <span style={{ fontSize: '14px' }}>
                    {' '}
                    Email: hr@linkcxo.com
                  </span>
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box className={classes.section}>
              <Box>
                {' '}
                <p className="address-style-sections">
                  <span
                    style={{
                      fontSize: '18px',
                      color: '#3dcd84',
                    }}>
                    BANGALORE
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: '18px' }}>
                    LinkCxO Global Pvt. Ltd.
                  </span>
                  <br />
                  <span style={{ fontSize: '14px' }} className="numbers">
                    #131/1
                  </span>
                  ,{' '}
                  <span style={{ fontSize: '14px' }} className="numbers">
                    4
                  </span>
                  <span style={{ fontSize: '14px' }}>
                    th Floor, Yashkesh Tower,
                  </span>
                  <br />
                  <span style={{ fontSize: '14px' }} className="numbers">
                    10
                  </span>
                  <span style={{ fontSize: '14px' }}>th Main, </span>
                  <span style={{ fontSize: '14px' }} className="numbers">
                    14
                  </span>
                  <span style={{ fontSize: '14px' }}>th Cross, </span>
                  <span style={{ fontSize: '14px' }} className="numbers">
                    6
                  </span>
                  <span style={{ fontSize: '14px' }}>
                    th Sector, HSR layout,{' '}
                  </span>
                  <br />
                  <span style={{ fontSize: '14px' }}>Bengaluru -</span>{' '}
                  <span style={{ fontSize: '14px' }} className="numbers">
                    560102
                  </span>{' '}
                  <br />
                  <br />
                  Telephone:&nbsp;
                  <span style={{ fontSize: '14px' }} className="numbers">
                    +91 8976803609
                  </span>
                  <br />
                  <span style={{ fontSize: '14px' }}>
                    {' '}
                    Email: contact@linkcxo.com
                  </span>
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box
              mt={3}
              sx={{
                textAlign: 'left',
                fontFamily: 'raleway',
              }}>
              <TextField
                size="small"
                sx={{
                  width: '80%',
                  '& label.Mui-focused': {
                    fontSize: '13px',
                    color: (theme) => theme.palette.palette.text.secondary,
                  },
                  '& .MuiInputLabel-root': {
                    marginTop: '2px',
                    fontSize: '13px',
                    color: (theme) => theme.palette.palette.text.secondary,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: (theme) =>
                      theme.palette.palette.text.secondary,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: (theme) =>
                        theme.palette.palette.text.secondary,
                    },
                    '&:hover fieldset': {
                      borderColor: (theme) =>
                        theme.palette.palette.text.secondary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: (theme) =>
                        theme.palette.palette.text.secondary,
                    },
                  },
                }}
                label="Enter your email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
              />
            </Box>
            <Box
              mt={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '80%',
              }}>
              <Button
                onClick={handleSubscribeBtn}
                sx={{
                  textDecoration: 'none',
                  textTransform: 'none',
                  backgroundColor: '#38CD84',
                  color: '#262525',
                  padding: '5px 15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: '600',
                  '&:hover': {
                    backgroundColor: '#38CD84',
                    color: '#262525',
                  },
                }}>
                {isSubscribed ? 'Subscribed' : 'Subscribe for more'}
              </Button>
            </Box>
            <Box
              mt={3}
              sx={{
                textAlign: 'center',
                fontFamily: 'raleway',
                width: '80%',
              }}>
              {' '}
              <p
                style={{
                  color: '#38CD84',
                  fontWeight: '600',
                  fontSize: '15px',
                }}>
                Download our app now
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.linkcxo"
                target="blank">
                <img alt="" src={PlayStore} />
              </a>
            </Box>
          </Grid>
        </Grid>
      </Box>{' '}
      <Box
        sx={{
          width: '100%',
          height: '1px',
          borderRadius: '5px',
          background: 'grey',
        }}></Box>
      <Box>
        <span
          style={{
            fontSize: '13px',
            color: 'white',
            paddingLeft: '50px',
            paddingBottom: '5px',
            paddingTop: '5px',
          }}>
          &copy; 2019 LinkCXO Global Pvt. Ltd. All Rights Reserved.
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <a href="/PrivacyPolicy" style={{ color: 'white' }}>
            Privacy Policy
          </a>
        </span>
      </Box>{' '}
    </>
  );
}

export default Footer;
