import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import JobCard from '../../components/JobCard/JobCard';
import JobProfileCard from '../../components/JobProfileCard/JobProfileCard';
import * as jobsActions from '../../store/jobs';
import { dateTime } from '../../constants/app';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));
const JobCandidate = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );
  const candidateAppliedList = useSelector(
    (state) => state?.jobs?.CandidateAppliedList,
  );

  const candidateShortList = useSelector(
    (state) => state?.jobs?.CandidateShortList,
  );

  const candidateHoldList = useSelector(
    (state) => state?.jobs?.CandidateHoldList,
  );

  const candidateRejectedList = useSelector(
    (state) => state?.jobs?.CandidateRejectedList,
  );
  const openJob = useSelector((state) => state?.jobs?.JobListOpen);
  const inactiveJob = useSelector((state) => state?.jobs?.JobListInactive);
  const closedJob = useSelector((state) => state?.jobs?.JobListClosed);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const [noofRecomended, setNoofRecomended] = React.useState(4);
  const [noofRejected, setNoofRejected] = React.useState(4);
  const [noofApplied, setNoofApplied] = React.useState(4);
  const [noofShortlist, setNoofShortlist] = React.useState(4);
  const [noofHold, setNoofHold] = React.useState(4);
  useEffect(async () => {
    const data = {
      job_id: jobDetails?.id,
      status: 'applied',
      user_id: user?.id,
    };
    // const jobs = await dispatch(
    //   jobsActions.getCandidateListApplied(data, data.type)
    // );
  }, []);

  useEffect(async () => {
    const data = {
      job_id: props?.jobId,
      status: 'hold',
      user_id: user?.id,
    };
    // const jobs = await dispatch(
    //   jobsActions.getCandidateListHold(data, data.type)
    // );
  }, []);
  useEffect(async () => {
    const data = {
      job_id: props?.jobId,
      status: 'shortlisted',
      user_id: user?.id,
    };

    // const jobs = await dispatch(
    //   jobsActions.getCandidateListShortlist(data, data.type)
    // );
  }, []);

  useEffect(async () => {
    const data = {
      job_id: props?.jobId,
      status: 'rejected',
      user_id: user?.id,
    };
    const jobs = await dispatch(
      jobsActions.getCandidateListRejected(data, data.type),
    );
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user.id,
    };
    const jobs = await dispatch(jobsActions.getRecommendedList(data));
  }, []);
  const seeMore = () => {
    if (candidateAppliedList?.length > noofApplied) {
      setNoofApplied(candidateAppliedList?.length);
    } else if (candidateAppliedList?.length === noofApplied) {
      setNoofApplied(4);
    }
  };

  const seeMoreRejected = () => {
    if (candidateRejectedList?.length > noofRejected) {
      setNoofRejected(candidateRejectedList?.length);
    } else if (candidateRejectedList?.length === noofRejected) {
      setNoofRejected(4);
    }
  };

  const seeMoreShortlist = () => {
    console.log('seeMoreShortlist', candidateShortList);
    if (candidateShortList?.length > noofShortlist) {
      setNoofShortlist(candidateShortList?.length);
    } else if (candidateShortList?.length === noofShortlist) {
      setNoofShortlist(4);
    }
  };

  const seeMoreHold = () => {
    if (candidateHoldList?.length > noofHold) {
      setNoofHold(candidateHoldList?.length);
    } else if (candidateHoldList?.length === noofHold) {
      setNoofHold(4);
    }
  };

  const shortList = async () => {
    const data = {
      job_id: 471,
      cand_id: 844,
      datetime: dateTime(),
      user_id: user.id,
    };
    // const jobs = await dispatch(jobsActions.postJobCandidateShortList(data));
  };
  return (
    <Fragment>
      <Box px={1}>
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Applied{' '}
                  {candidateAppliedList?.length > 0 &&
                    '(' + candidateAppliedList?.length + ')'}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMore}>
                <Typography variant="h5" component="h5">
                  {candidateAppliedList?.length === noofApplied
                    ? 'See Less'
                    : 'See All'}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
          }}>
          <Grid container>
            {candidateAppliedList?.length > 0 &&
              candidateAppliedList
                ?.slice(0, noofApplied)
                ?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <JobProfileCard
                        candidatelist={list}
                        type="applied"
                        shortList={shortList}
                        job={jobDetails}
                      />
                    </Grid>
                  );
                })}
            {candidateAppliedList?.length == 0 && (
              <Box pl={3} py={2}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '12px',
                    },
                  }}>
                  Currently, there are no job applicants.
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Shortlist{' '}
                  {candidateShortList?.length > 0 &&
                    '(' + candidateShortList?.length + ')'}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMoreShortlist}>
                <Typography variant="h5" component="h5">
                  {candidateShortList?.length === noofShortlist
                    ? 'See Less'
                    : 'See All'}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
          }}>
          <Grid container>
            {candidateShortList?.length > 0 &&
              candidateShortList
                ?.slice(0, noofShortlist)
                ?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <JobProfileCard candidatelist={list} type="shortlist" />
                    </Grid>
                  );
                })}
            {candidateShortList?.length == 0 && (
              <Box pl={3} py={2}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '12px',
                    },
                  }}>
                  Currently, there are no shortlisted applications.
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>

        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Hold{' '}
                  {candidateHoldList?.length > 0 &&
                    '(' + candidateHoldList?.length + ')'}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMoreHold}>
                <Typography variant="h5" component="h5">
                  {candidateHoldList?.length === noofHold
                    ? 'See Less'
                    : 'See All'}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>
        <Box
          sx={{
            marginTop: '8px',
          }}>
          <Grid container>
            {candidateHoldList?.length > 0 &&
              candidateHoldList?.slice(0, noofHold)?.map((list, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      paddingRight: index % 2 === 0 ? '8px' : '0px',
                      marginTop: '8px',
                    }}>
                    <JobProfileCard candidatelist={list} type="hold" />
                  </Grid>
                );
              })}
            {candidateHoldList?.length == 0 && (
              <Box pl={2} py={2}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '12px',
                    },
                  }}>
                  Currently, there are no Hold applications.
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>

        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Rejected{' '}
                  {candidateRejectedList?.length > 0 &&
                    '(' + candidateRejectedList?.length + ')'}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMoreRejected}>
                <Typography variant="h5" component="h5">
                  {candidateRejectedList?.length === noofRejected
                    ? 'See Less'
                    : 'See All'}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>
        <Box
          sx={{
            marginTop: '8px',
          }}>
          <Grid container>
            {candidateRejectedList?.length > 0 &&
              candidateRejectedList
                ?.slice(0, noofRejected)
                ?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <JobProfileCard candidatelist={list} type="rejected" />
                    </Grid>
                  );
                })}
            {candidateRejectedList?.length == 0 && (
              <Box pl={3} py={2}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '12px',
                    },
                  }}>
                  Currently, there are no rejected applications.
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobCandidate);
