import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import EventCard from '../../components/EventCard/EventCard';
import EventMembers from '../../components/EventMembers/EventMembers';
import ClubMembers from '../../components/ClubMembers/ClubMembers';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubAttending = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofAttending, setNoofAttending] = React.useState(4);

  const user = useSelector((state) => state?.user?.UserDetails);
  const Attendinglist = useSelector(
    (state) => state?.events?.eventAttendingList,
  );
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const eventDetails = useSelector((state) => state?.events?.eventDetails);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const clubMembers = useSelector((state) => state?.clubs?.clubMembers);

  const eventInvitedMembers = useSelector(
    (state) => state?.events?.eventInvitedMembers,
  );

  const eventAttendingMembers = useSelector(
    (state) => state?.events?.eventAttendingMembers,
  );

  const eventRequestMembers = useSelector(
    (state) => state?.events?.eventRequestMembers,
  );
  useEffect(async () => {
    const data = {
      event_id: eventDetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventAttendingMembers(data));
  }, []);

  useEffect(async () => {
    const data = {
      club_id: clubDetails?.id,
    };

    const clubmemberslist = await dispatch(clubsActions.clubMembersList(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeMore = () => {
    if (clubMembers?.length > noofAttending) {
      setNoofAttending(clubMembers?.length);
    } else if (clubMembers?.length === noofAttending) {
      setNoofAttending(4);
    }
  };
  return (
    <Fragment>
      <Box px={0}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
            }}>
            <Grid container>
              {clubMembers?.length > 0 &&
                clubMembers?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        marginTop: '8px',
                        paddingLeft: '8px',
                      }}>
                      <ClubMembers members={list} type="Attending" />
                    </Grid>
                  );
                })}
              {clubMembers?.length == 0 && (
                <Box pl={2}>
                  <Typography variant="h5" component="h5">
                    There is no members in attending in this club. You can
                    invite some users from your connection.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
        <Box
          py={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={seeMore}>
          <Typography variant="h5" component="h5"></Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(ClubAttending);
