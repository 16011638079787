import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useParams, useHistory } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import { Paper, Grid, Box, Typography, Stack, Card } from "@mui/material";
import RewardPartnerVoucherCard from "../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard";
import RewardPartnerCard from "../../components/RewardPartnerCard/RewardPartnerCard";
import RewardCreditsCard from "../../components/RewardCreditsCard/RewardCreditsCard";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { dateTime } from "../../constants/app";
import * as rewardsActions from "../../store/rewards";
import credits_gold from "../../assets/header/Credits_Gold.png";
import moment from "moment";
import VoucherActions from "./VoucherActions";
import Menuicon from "../../assets/menuicon";
import EventsIcon from "../../assets/eventsicon";
import Share from "../../assets/home/Share_White.png";
import Icon from "../../assets/icon";
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: "#fff",
  },
}));

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiSelect-select": {
      color: "#ffffff",
    },
    "& .MuiSelect-outlined": {
      color: "white",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "purple",
    },
    "& MuiInputLabel-root": {
      color: theme.palette.palette.text.secondary,
    },
    "& label.Mui-focused": {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : "Raleway",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : "Raleway",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CreditsActionsFilters = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const navigate = useNavigate();
  const [noofPartner, setNoofPartner] = React.useState(4);
  const [filtertype, setFiltertype] = React.useState("alltime");
  const [showDetails, setShowDetails] = React.useState(false);
  const [activeType, setActiveType] = React.useState("");
  const [creditList, setCreditList] = React.useState([]);

  const user = useSelector((state) => state?.user?.UserDetails);
  const rewardsPartnerDetails = useSelector(
    (state) => state?.rewards?.rewardsPartnerDetails
  );

  const rewardsGetCreditsSetActions = useSelector(
    (state) => state?.rewards?.rewardsGetCreditsSetActions
  );

  const rewardsGetCreditsDetails = useSelector(
    (state) => state?.rewards?.rewardsGetCreditsDetails
  );

  console.log("CreditsActionsFilters", rewardsGetCreditsDetails);

  let result = [] ;
  if(rewardsGetCreditsDetails?.length > 0){
    result = rewardsGetCreditsDetails?.reduce(function (r, a) {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, Object.create(null));
    console.log("CreditsActionsFilters", result);
}


  const rewardsGetCreditslist = useSelector(
    (state) => state?.rewards?.rewardsGetCreditslist
  );

  const handleChange = async (event) => {
    setFiltertype(event.target.value);
    const data = {
      user_id: user?.id,
      filter_type: event.target.value,
      date: moment().format("YYYY-MM-DD"),
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetCreditsDetails(data)
    );
  };
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      filter_type: filtertype,
      date: moment().format("YYYY-MM-DD"),
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetCreditsDetails(data)
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      datetime: dateTime(),
      noOfDays: 365,
      action_code: "post_liked",
    };
    const linkcxorewardsActions = await dispatch(
      rewardsActions.rewardsGetCreditsByActions(data)
    );
  }, []);

  useEffect(async () => {
    if (params?.actions === "details") {
    } else {
      setActiveType("");
      setShowDetails(false);
    }
  }, []);

  const dateHeader = (date, rewardsGetCredits, index) => {
    if (filtertype !== "daily" && filtertype !== "all") {
      let rewardsdateDetails = Object.keys(rewardsGetCreditsDetails);
      if (rewardsdateDetails?.length > index) {
        let new_date = moment(
          moment(moment(rewardsdateDetails[index + 1]).format("LL")).add(
            1,
            "days"
          )
        ).format("LL");
        return new_date + " - " + date;
      } else {
        return date;
      }
    } else {
      return date;
    }
  };

  const allAction = async () => {};
  const message = (action) => {
    if (action === "post_share") {
      return "You have " + action.split("_").join(" ");
    } else {
      return "You have " + action.split("_").join(" ");
    }
  };

  const messageFilter = (filter) => {
    if (filtertype === "alltime") {
      return "All Time";
    } else if (filtertype === "today") {
      return "Today";
    } else if (filtertype === "weekly") {
      return "Last 1 Week";
    } else if (filtertype === "monthly") {
      return "Last 1 Month";
    } else if (filtertype === "yearly") {
      return "Last 1 year";
    }
  };

  const activeTypeComponent = async (type, actiontype) => {
    let noOfDays = 0;

    if (filtertype === "alltime") {
      noOfDays = 365;
    } else if (filtertype === "today") {
      noOfDays = 1;
    } else if (filtertype === "weekly") {
      noOfDays = 7;
    } else if (filtertype === "monthly") {
      noOfDays = 30;
    } else if (filtertype === "yearly") {
      noOfDays = 365;
    }
    for (let i = 0; actiontype.length > i; i++) {
      let data = {
        user_id: user?.id,
        date: moment().format("YYYY-MM-DD"),
        filter_type: filtertype,
        action_code: actiontype[i],
      };
      let linkcxorewardsActions = await dispatch(
        rewardsActions.rewardsGetCreditsByActions(data)
      );

      if (linkcxorewardsActions?.status === 1) {
        setCreditList(linkcxorewardsActions?.data);
        console.log(
          "creditList linkcxorewardsActions",
          i,
          creditList,
          ...linkcxorewardsActions?.data
        );
      }
    }
    setActiveType(type);
    setShowDetails(true);

    navigate("/rewards/credits/details");
    console.log("creditList", creditList);
  };

  if (params?.actions === "details") {
    return (
      <Fragment>
        <Box>
          <Box sx={{ width: "100%" }}>
            <Box py={2}>
              <Paper
                sx={{ backgroundColor: "#232121", backgroundImage: "none" }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={0}
                >
                  <Box
                    py={1}
                    px={3}
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        fontSize: "20px",
                        lineHeight: "16px",
                        letterSpacing: "0.22px",
                        fontWeight: 600,
                        paddingBottom: "8px",
                        textTransform: "capitalize",
                      }}
                    >
                      {activeType ? activeType : ""}
                    </Typography>
                  </Box>
                  <Box
                    py={0}
                    px={3}
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-label">
                        Timeline
                      </InputLabel>
                      <CssSelect
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={filtertype}
                        onChange={handleChange}
                      >
                        <MenuItem value="alltime">All Time</MenuItem>
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="weekly">Last 1 week</MenuItem>
                        <MenuItem value="monthly">Last 1 Month</MenuItem>
                        <MenuItem value="yearly">Last 1 Year</MenuItem>
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>
              </Paper>

              <Box pt={1}>
                <Grid container>
                  {creditList.map((credit, index) => {
                    return (
                      <Fragment>
                        <Grid
                          key={index}
                          item
                          xs={12}
                          md={6}
                          sx={{
                            paddingRight: index % 2 === 0 ? "8px" : "0px",
                            marginTop: "8px",
                          }}
                        >
                          <Card
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "#333",
                              backgroundImage: "none",
                              justifyContent: "space-between",
                              height: "56px",
                              width: "100%",
                            }}
                          >
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "56px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                paddingRight: "8px",
                                paddingLeft: "16px",
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  sx={{
                                    fontSize: "16px",
                                    lineHeight: "27px",

                                    letterSpacing: "0.31px",
                                    fontWeight: 400,
                                    fontFamily: "Open Sans",
                                  }}
                                >
                                  {message(credit?.action_code)}
                                </Typography>
                              </Box>

                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  height: "48px",
                                  paddingTop: "8px",
                                  paddingBottom: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  sx={{
                                    fontSize: "20px",
                                    lineHeight: "27px",
                                    color: "#F5BD02",
                                    letterSpacing: "0.31px",
                                    fontWeight: 400,
                                    fontFamily: "Open Sans",
                                  }}
                                >
                                  {credit?.credit > 0
                                    ? "+" + credit?.credit
                                    : credit?.credit}
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "18px",
                                    maxWidth: "min-content",
                                    width: "24px",
                                  }}
                                >
                                  <img alt="creditgold" src={credits_gold} />
                                </Box>
                              </Stack>
                            </Stack>
                          </Card>
                        </Grid>
                      </Fragment>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box>
          <Box sx={{ width: "100%" }}>
            <Box py={2}>
              <Paper
                sx={{ backgroundColor: "#232121", backgroundImage: "none" }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={0}
                >
                  <Box
                    py={2}
                    px={3}
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        fontSize: "18px",
                        lineHeight: "16px",
                        letterSpacing: "0.22px",
                        fontWeight: 600,
                        paddingBottom: "8px",
                        textTransform: "capitalize",
                      }}
                    >
                      {messageFilter(filtertype)}
                    </Typography>
                  </Box>
                  <Box
                    py={2}
                    px={3}
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-label">
                        Timeline
                      </InputLabel>
                      <CssSelect
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={filtertype}
                        onChange={handleChange}
                      >
                        <MenuItem value="alltime">All Time</MenuItem>
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="weekly">Last 1 Week</MenuItem>
                        <MenuItem value="monthly">Last 1 Month</MenuItem>
                        <MenuItem value="yearly">Last 1 Year</MenuItem>
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>
              </Paper>

              <Box pt={2}>
                <Grid container>
                  {
                    Object.keys(rewardsGetCreditsDetails).map((list, index) => {

                        console.log('list1', list)

                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: index % 2 === 0 ? "4px" : "4px",
                              marginTop: "8px",
                            }}
                          >
                          {rewardsGetCreditsDetails[list].map((details, index) =>{


                            return (
                              <Card
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  backgroundColor: '#333',
                                  backgroundImage: 'none',
                                  justifyContent: 'space-between',
                                  height: '122px',
                                  width: '100%',
                                  marginTop: '8px',
                                  cursor: 'pointer',
                                }}>
                                <Stack direction="column">
                                  <Box
                                    px={2}
                                    pt={2}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                    }}>
                                    <Typography
                                      variant="h2s"
                                      component="h2s"
                                      sx={{
                                        fontSize: '20px',
                                        lineHeight: '16px',
                                        letterSpacing: '0.22px',
                                        fontWeight: 600,
                                        paddingBottom: '8px',
                                        textTransform: 'capitalize',
                                      }}>
                                      {details?.type ? details?.type : ''}
                                    </Typography>
                                  </Box>
                                  <Box
                                    px={2}
                                    py={0.5}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                    }}>
                                    <Stack
                                      direction="row"
                                      spacing={0.5}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingTop: '4px',
                                        paddingBottom: '4px',
                                      }}>
                                      <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                          fontSize: '20px',
                                          lineHeight: '27px',
                                          color: '#F5BD02',
                                          letterSpacing: '0.31px',
                                          fontWeight: 400,
                                          fontFamily: 'Open Sans',
                                        }}>
                                        {details?.credit > 0
                                          ? '+' + details?.credit
                                          : details?.credit}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          height: '18px',
                                          maxWidth: 'min-content',
                                          width: '24px',
                                        }}>
                                        <img
                                          alt="creditgold"
                                          src={credits_gold}
                                        />
                                      </Box>
                                    </Stack>
                                  </Box>

                                  <Box px={2} py={1}>
                                    <Box pl={0} pb={1}>
                                      <Stack direction="row">
                                        {details?.type === 'poll' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '18px',
                                                maxWidth: 'min-content',
                                                minWidth: '18px',
                                              }}>
                                              <Menuicon
                                                name="poll"
                                                color="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        )}
                                        {details?.type === 'voucher' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '18px',
                                                maxWidth: 'min-content',
                                                minWidth: '18px',
                                              }}>
                                              <Menuicon
                                                name="voucher"
                                                color="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        {details?.type === 'comment' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '18px',
                                                maxWidth: 'min-content',
                                                minWidth: '18px',
                                              }}>
                                              <Menuicon
                                                name="chatbot"
                                                color="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        {details?.type === 'applause' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '18px',
                                                maxWidth: 'min-content',
                                                minWidth: '18px',
                                              }}>
                                              <Icon
                                                name="applause"
                                                color="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        {details?.type === 'event' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '18px',
                                                maxWidth: 'min-content',
                                                minWidth: '18px',
                                              }}>
                                              <Menuicon
                                                name="events"
                                                color="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        {details?.type === 'post' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '18px',
                                                maxWidth: 'min-content',
                                                minWidth: '18px',
                                              }}>
                                              <Menuicon
                                                name="blog"
                                                color="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        {details?.type === 'article' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              mt={0.4}
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '15px',
                                                maxWidth: 'min-content',
                                                minWidth: '15px',
                                              }}>
                                              <Menuicon
                                                name="article"
                                                color="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        {details?.type === 'share' && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              height: '20px',
                                              width: 'fit-content',
                                            }}>
                                            <img
                                              alt="Share"
                                              className={{ height: '20px' }}
                                              src={Share}
                                            />
                                          </Box>
                                        )}
                                        <Box px={1}>
                                          <Typography
                                            variant="h6"
                                            component="h6"
                                            sx={{
                                              fontSize: '16px',
                                              lineHeight: '22px',
                                              color: '#fff',
                                              letterSpacing: '0.25px',
                                              fontWeight: 400,
                                              fontFamily: 'Open Sans',
                                            }}>
                                            {details?.noOfItem} activities
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Box>
                                  </Box>
                                </Stack>
                              </Card>
                            );
                          })

                        }



                          </Grid>
                        );
                    })}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(CreditsActionsFilters);

//
// <Grid container>
//   {rewardsGetCreditslist?.length > 0 &&
//     rewardsGetCreditslist?.map((list, index) => {
//         return (
//           <Grid
//             key={index}
//             item
//             xs={12}
//             md={6}
//             sx={{
//               paddingRight: index % 2 === 0 ? "4px" : "4px",
//               marginTop: "8px",
//             }}
//           >
//             <RewardCreditsCard />
//           </Grid>
//         );
//     })}
// </Grid>

// {Object.keys(result).map((type, index) => {
//   return (
//     <Fragment>
//       <Grid
//         key={index}
//         item
//         xs={12}
//         md={6}
//         sx={{
//           paddingRight: index % 2 === 0 ? "8px" : "0px",
//           marginTop: "8px",
//         }}
//       >
//         <RewardCreditsCard
//           type={type}
//           credits={result[type]}
//           activeType={activeType}
//           activeTypeComponent={activeTypeComponent}
//           showDetails={showDetails}
//         />
//       </Grid>
//     </Fragment>
//   );
// })}
