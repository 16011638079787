import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';
import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { YearCalendar } from '@mui/x-date-pickers/YearCalendar';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const EditEducation = (props) => {
  console.log('props', props);
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [file, setFile] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(dayjs(new Date()));
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);

  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  useEffect(() => {
    if (props?.section?.passing_year) {
      setDatevalue(dayjs(props?.section?.passing_year));
    }
  }, []);
  const handleChange = (newValue) => {
    setType(newValue);
  };

  const closeEdit = () => {};

  const handleSubmitform = async (data) => {
    console.log('data', data);
    const useredu = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      board: data?.board,
      degree: data?.degree,
      passing_year: dayjs(datevalue).year(),
      details: data?.details,
    };
    if (data?.board?.length > 0 && data?.degree?.length > 0) {
      const usereducation = await dispatch(
        userActions.manageEducation(useredu),
      );
      const userdata = {
        id: user?.id,
      };

      const usersabout = await dispatch(userActions.userProfileAbout(userdata));
      if (updateSection) {
        const snackbar = await dispatch(
          userActions.closeUpdateSectionProfile(),
        );
      }
      if (addNew) {
        const snackbaradd = await dispatch(userActions.closeUpdateProfile());
      }
    }
  };

  return (
    <Fragment>
      <Box px={3} py={1} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center  ',
              width: '100%',
              overflowY: 'none',
            }}>
            <form
              onSubmit={handleSubmit(handleSubmitform)}
              style={{
                width: '100%',
              }}>
              <Box py={0} sx={{ width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflowY: 'none',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Board"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'This is required',
                      },
                    }}
                    name="board"
                    defaultValue={
                      props?.section?.board ? props?.section?.board : ''
                    }
                  />
                </Stack>
              </Box>

              <Box pr={1} sx={{ textAlign: 'center', width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Degree"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'This is required',
                      },
                    }}
                    name="degree"
                    defaultValue={
                      props?.section?.degree ? props?.section?.degree : ''
                    }
                  />
                </Stack>
              </Box>

              <Box py={0} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row" sx={{justifyContent:"flex-start", paddingLeft:"16px"}}>
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['YearCalendar']}>
                      <DemoItem label="Year Of Passing">
                        <YearCalendar   
                          value={datevalue}
                          onChange={handledateChange}
                          sx={{height:"100px"}}
                          />
                      </DemoItem>      
                    </DemoContainer>
                  </LocalizationProvider>

                  </Box>

                </Stack>
              </Box>

              <Box pt={4} pr={2} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={0}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CssTextField
                        sx={{ wordWrap: 'break-word' }}
                        variant="standard"
                        fullWidth
                        multiline
                        rows={5}
                        maxRows={6}
                        value={value}
                        onChange={onChange}
                        color="secondary"
                        label="Details"
                      />
                    )}
                    name="details"
                    defaultValue={
                      props?.section?.details ? props?.section?.details : ''
                    }
                  />
                </Stack>
              </Box>

              <Box
                py={4}
                px={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CxoButton label="Add" type="submit" />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EditEducation);
