import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';

import { Rectangle_Logo1 } from '../../data/date-data';
import { Rectangle_Logo2 } from '../../data/date-data';
import { Circular_logo1 } from '../../data/date-data';
import { Circular_logo2 } from '../../data/date-data';

function CompanyLogo() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 3,
    },
  };

  return (
    <>
      <Container fluid className="company-logo-mob">
        <Row style={{ textAlign: 'center' }}>
          <Col>
            {' '}
            <h4 className="color-green">LinkCxO Users From Top Companies</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {' '}
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              direction="left"
              customTransition="all .5"
              transitionDuration={20}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              sx={{ paddingBottom: '-50px' }}>
              {Rectangle_Logo1.map((step, index) => (
                <Row key={step.label}>
                  <Col>
                    <div className="imgFrame" key={step.label}>
                      <img alt="" src={step.image} className="logo_img" />
                    </div>
                  </Col>
                </Row>
              ))}
            </Carousel>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              direction="right"
              customTransition="all .5"
              transitionDuration={20}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              sx={{ paddingBottom: '-50px' }}>
              {Circular_logo1.map((step, index) => (
                <Row key={step.label} style={{ marginLeft: '15px' }}>
                  <Col>
                    <div className="imgFrame1" key={step.label}>
                      <img alt="" src={step.image} className="logo_img1" />
                    </div>
                  </Col>
                </Row>
              ))}
            </Carousel>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              direction="left"
              customTransition="all .5"
              transitionDuration={20}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              sx={{ paddingBottom: '-50px' }}>
              {Rectangle_Logo2.map((step, index) => (
                <Row key={step.label}>
                  <Col>
                    <div className="imgFrame" key={step.label}>
                      <img alt="" src={step.image} className="logo_img" />
                    </div>
                  </Col>
                </Row>
              ))}
            </Carousel>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              direction="right"
              customTransition="all .5"
              transitionDuration={20}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              sx={{ paddingBottom: '-50px' }}>
              {Circular_logo2.map((step, index) => (
                <Row key={step.label} style={{ marginLeft: '15px' }}>
                  <Col>
                    <div className="imgFrame1" key={step.label}>
                      <img alt="" src={step.image} className="logo_img1" />
                    </div>
                  </Col>
                </Row>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CompanyLogo;
