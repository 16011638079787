import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import cxoNetworking from '../../resources/Interest - Icons/CXO Networking_White.png';
import InvOppo from '../../resources/Interest - Icons/Investment Opportunities_White.png';
import BussOppo from '../../resources/Interest - Icons/Business Oppurtunities_White.png';
import HiringCxo from '../../resources/Interest - Icons/Hiring CXOs_White.png';
import FundRise from '../../resources/Interest - Icons/Fund Raising_White.png';
import AdvOppo from '../../resources/Interest - Icons/Advisory Opportunities_Wite.png';
import CareerOppo from '../../resources/Interest - Icons/Career Oppurtunities_White.png';
import IndusEve from '../../resources/Interest - Icons/Industry Events_White.png';
import NewsCont from '../../resources/Interest - Icons/News Content_White.png';
function InterestPage() {
  return (
    <>
      <Container fluid>
        <Row className="interest-section">
          <Col>
            <Row>
              <Col>
                <h2 className="interest-title">
                  Interest Specific catering via App
                </h2>
              </Col>
            </Row>
            <Row className="interest-row1">
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame">
                      <img
                        alt=""
                        src={cxoNetworking}
                        className="interest-img"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                      }}>
                      CxO Networking
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame">
                      <img alt="" src={InvOppo} className="interest-img" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                      }}>
                      Investment Opportunities
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame">
                      <img alt="" src={BussOppo} className="interest-img" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                      }}>
                      Business Opportunities
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame">
                      <img alt="" src={HiringCxo} className="interest-img" />
                    </div>{' '}
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                      }}>
                      Hiring CxOs
                    </p>{' '}
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame">
                      <img alt="" src={FundRise} className="interest-img" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                      }}>
                      Fund Raising
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="interest-row2">
              {' '}
              <Col sm={3} md={3} className="float-right people1">
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame">
                      <img alt="" className="interest-img" src={AdvOppo} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p style={{ color: '#fff' }}>Advisory Opportunities</p>
                  </Col>
                </Row>
              </Col>
              <Col sm={3} md={3} className="float-right people1">
                {' '}
                <Row>
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame">
                      {' '}
                      <img alt="" className="interest-img" src={CareerOppo} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p style={{ color: '#fff' }}>Career Opportunities</p>
                  </Col>
                </Row>
              </Col>
              <Col sm={3} md={3}>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame">
                      <img alt="" className="interest-img" src={IndusEve} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    {' '}
                    <p style={{ color: '#fff' }}>Industry Events</p>
                  </Col>
                </Row>
              </Col>
              <Col sm={3} md={3}>
                {' '}
                <Row>
                  <Col className="logo-frame">
                    <div className="interest-frame">
                      {' '}
                      <img alt="" className="interest-img" src={NewsCont} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    {' '}
                    <p style={{ color: '#fff' }}>News Content</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default InterestPage;
