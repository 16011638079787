import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Paper, Grid, Box, Typography } from '@mui/material';
import CxoButton from '../../components/CxoButton/CxoButton';
import Stack from '@mui/material/Stack';
import CxoInput from '../../components/CxoInput/CxoInput';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import { LinkedInApi } from '../../services/apiConfig';
const LinkedInRedirect = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bio, setBio] = useState('');
  const user = useSelector((state) => state?.user?.UserDetails);
  const redirectUrlweb = useSelector((state) => state?.onboard?.redirectUrlweb);
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const redirect = queryParams.get('redirect');

  useEffect(async () => {
    let host = window.location.href.includes('localhost') ? 'localhost' : '';
    let { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    if (code) {
      const userprofile = await dispatch(
        onboardActions.getLinkedInAcessToken(code, host),
      );
      let email = userprofile?.email;

      const login = await dispatch(
        authActions.attemptLogin({ username: email, type: 'linkedin' }),
      );
      // parseLinkedinData()
      if (login.message === 'Success') {
        if (redirectUrlweb?.length > 0) {
          window.opener.location.href =
            'https://www.linkcxo.com' + redirectUrlweb;
        } else {
          window.opener.location.href = 'https://www.linkcxo.com/feed';
        }
        window.close();
      } else if (login.message === 'User not exist') {
        if (redirectUrlweb?.length > 0) {
          if (redirectUrlweb?.includes('event')) {
            window.opener.location.href =
              `/user/details?account_type=linkedin&email=${email}` +
              '&sign_up_through_page=event' +
              '&redirect=' +
              redirectUrlweb;
          } else if (redirectUrlweb?.includes('job')) {
            window.opener.location.href =
              `/user/details?account_type=linkedin&email=${email}` +
              '&sign_up_through_page=job_apply' +
              '&redirect=' +
              redirectUrlweb;
          } else {
            let temp = redirectUrlweb?.replace('?', '');
            window.opener.location.href =
              `/user/details?account_type=linkedin&email=${email}` + '&' + temp;
          }
        } else {
          window.opener.location.href = `/user/details?account_type=linkedin&email=${email}`;
        }
        window.close();
      }
    }
  }, []);

  const parseLinkedinData = async (data, status) => {
    let email = data;
    let mobile = '';
    // let temp_user_id = JSON.parse(window.localStorage.getItem(("temp-data")))
    let temp_user_id = window.localStorage.getItem('temp-data');
    if (status == 1) {
      const validate = await dispatch(
        authActions.checkMobileEmail({ email, mobile }),
      );

      if (validate.status == 1) {
        const login = await dispatch(
          authActions.attemptLogin({ username: email }),
        );

        if (login.status == 1) {
          window.opener.location.href = 'https://www.linkcxo.com/feed';
          window.close();
        } else if (login.status == -1) {
          return (
            (window.opener.location.href = `/user/details?linkcxo=website&A=${login.data.firstName}&B=${login.data.lastName}&D=${login.data.email}&C=${login.data.image}`),
            window.close()
          );
        }
      } else {
        const login = await dispatch(
          authActions.attemptLogin({ username: email }),
        );
        if (login.status === 1) {
          const profilefield = await dispatch(
            authActions.profileRequiredField({
              user_id: login.data.user_details.id,
            }),
          );
          console.log('profilefield', profilefield);
          if (profilefield?.status == 1) {
            window.opener.location.href = 'https://www.linkcxo.com/';
            window.close();
          } else if (profilefield?.data?.profile_about == 0) {
            window.opener.location.href = 'https://www.linkcxo.com/addbio';
            window.close();
          } else if (profilefield?.data?.user_interest == 0) {
            window.opener.location.href = 'https://www.linkcxo.com/addinterest';
            window.close();
          } else if (profilefield?.status == -2) {
            window.opener.location.href = 'https://www.linkcxo.com/';
            window.close();
          }
        }
      }
    }
  };

  return (
    <Fragment>
      <Paper
        elevation={2}
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box
          px={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <CircularProgress color="success" />
        </Box>
      </Paper>
    </Fragment>
  );
};

export default React.memo(LinkedInRedirect);
