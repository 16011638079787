import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import CxoButtonWithIcon from '../../components/CxoButtonWithIcon/CxoButtonWithIcon';
import CxoButton from '../../components/CxoButton/CxoButton';
import MenuItem from '@mui/material/MenuItem';
import JobCard from '../../components/JobCard/JobCard';
import JobSearch from './JobSearch';
import JobProfileCard from '../../components/JobProfileCard/JobProfileCard';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';
import * as jobsActions from '../../store/jobs';
import * as messageActions from '../../store/message';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { dateTime } from '../../constants/app';
import { saveAs } from 'file-saver';
import JobSimilar from './JobSimilar';
import JobCandidate from './JobCandidate';
import Message from '../../components/Message/Message';
import JobDetailsSkeleton from '../../components/Skeleton/JobDetailsSkeleton';
import CloseIcon from '@mui/icons-material/Close';

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}>
      <div className="expandIconWrapper">
        <Stack direction="row" spacing={2} sx={{ paddingLeft: '16px' }}>
          <ExpandMoreIcon />
        </Stack>
      </div>
      <div className="collapsIconWrapper">
        <Stack direction="row" spacing={2} sx={{ paddingRight: '16px' }}>
          <ExpandMoreIcon />
        </Stack>
      </div>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  rowroot: {
    paddingTop: '16px',
    paddingLeft: '16px',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);
const JobDetails = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState('panel1');
  const [applied, setApplied] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [jobId, setJobId] = React.useState(0);
  let params = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userAbouts = useSelector((state) => state?.user?.UserAbouts);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const jobapplied = useSelector((state) => state?.jobs?.JobListApplied);
  const jobApplyCount = useSelector((state) => state?.jobs?.jobApplyCount);
  const [showconfirmation, setShowConfirmation] = React.useState(false);
  const jobdetailsLoading = useSelector(
    (state) => state?.jobs?.jobdetailsLoading,
  );
  const userfrom = useSelector(
    (state) => state?.user?.UserDetails?.sign_up_through_page,
  );
  const appliedJob = useSelector((state) => state?.jobs?.JobListApplied);
  const savedJob = useSelector((state) => state?.jobs?.JobListSaved);
  const jobuserstatus = useSelector((state) => state?.jobs?.jobUserStatus);

  const membership_type = useSelector(
    (state) => state?.user?.userStatus?.membership_type,
  );
  console.log('jobuserstatus', jobuserstatus);
  const [trim, setTrim] = React.useState(true);

  useEffect(async () => {
    const data = {
      cand_id: user.id,
      page_no: 0,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        cand_id: user?.id,
      };
      const rewardscategorylist = await dispatch(
        jobsActions.getJobApplyCount(data),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.job?.split('-');
      const data = {
        cand_id: user?.id,
        job_id: id[id?.length - 1].substring(2),
      };
      const rewardscategorylist = await dispatch(
        jobsActions.getJobUserStatusDetails(data),
      );
    }
    fetchData();
  }, []);
  useEffect(async () => {
    console.log('keywords', params);
    const id = params?.job?.split('-');
    if (id?.length > 0) {
      const data = {
        job_id: id[id?.length - 1].substring(2),
      };
      setJobId(id[id?.length - 1].substring(2));
      const jobdetails = await dispatch(jobsActions.getJobDetails(data));

      const dataapplied = {
        job_id: id[id?.length - 1].substring(2),
        status: 'applied',
        user_id: user?.id,
      };
      const jobsapplied = await dispatch(
        jobsActions.getCandidateListApplied(dataapplied),
      );
      const datahold = {
        job_id: id[id?.length - 1].substring(2),
        status: 'hold',
        user_id: user?.id,
      };
      const jobshold = await dispatch(
        jobsActions.getCandidateListHold(datahold, datahold.type),
      );
      const datashortlisted = {
        job_id: id[id?.length - 1].substring(2),
        status: 'shortlisted',
        user_id: user?.id,
      };

      const jobshortlist = await dispatch(
        jobsActions.getCandidateListShortlist(datashortlisted, data.type),
      );

      const datareject = {
        job_id: id[id?.length - 1].substring(2),
        status: 'rejected',
        user_id: user?.id,
      };
      const jobsreject = await dispatch(
        jobsActions.getCandidateListRejected(datareject, datareject.type),
      );
    }
    checkUserApplied();
    checkUserSaved();
  }, []);

  useEffect(async () => {
    const data = {
      cand_id: user.id,
      page_no: 0,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
  }, []);

  // useEffect(async () => {
  //
  //   const id = params?.job?.split("-");
  //     if (id?.length > 0) {
  //   const dataapplied = {
  //     job_id: id[id?.length - 1].substring(2),
  //     status: "applied",
  //     user_id: user?.id,
  //   };
  //   const jobs = await dispatch(
  //     jobsActions.getCandidateListApplied(dataapplied)
  //   );
  //     }
  //   checkUserApplied();
  //   checkUserSaved();
  // }, []);

  const checkUserApplied = () => {
    return appliedJob?.some(function (el) {
      // console.log("applied", el?.id, jobDetails?.id);
      // if (el?.id === jobDetails?.id) {
      //   setApplied(true);
      //
      // }
      // console.log('applied',applied, 'saved', saved);
      return el?.id === jobDetails?.id;
    });
  };

  const checkUserSaved = () => {
    return savedJob?.some(function (el) {
      // console.log("applied", el?.id, jobDetails?.id);
      // console.log('applied',applied, 'saved', saved);
      // if (el?.id === jobDetails?.id) {
      //   setSaved(true);
      //     console.log('applied',applied, 'saved', saved);
      // }
      return el?.id === jobDetails?.id;
    });
    console.log('applied', applied, 'saved', saved);
  };

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changePanel = (panel) => {
    setExpanded(false);
    // document.getElementById("jobdetails").scrollIntoView();
    return setExpanded('panel1');
  };

  const checkNoJob = () => {
    if (membership_type === 1 && jobApplyCount <= 2) {
      return true;
    } else if (membership_type === 0 && jobApplyCount <= 2) {
      return true;
    } else if (membership_type === 2 && jobApplyCount <= 8) {
      return true;
    } else if (membership_type === 3) {
      return true;
    } else {
      return false;
    }
  };

  const applyJob = async (e) => {
    e.stopPropagation();

    if (
      user?.isVerified ||
      (userfrom?.includes('job_apply') && jobApplyCount == 0)
    ) {
      if (checkNoJob()) {
        const openapplyjob = await dispatch(
          jobsActions.openApplyJob(jobDetails),
        );
      } else {
        setShowConfirmation(true);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const shareJob = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const opensharejob = await dispatch(jobsActions.openShareJob(jobDetails));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const seeMore = () => {
    setTrim(!trim);
  };
  const saveJob = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const data = {
        job_id: jobDetails?.id,
        cand_id: user?.id,
        status: 'saved',
        datetime: dateTime(),
        user_id: jobDetails?.user_table_pk,
      };
      const savejob = await dispatch(jobsActions.postSaveJobNew(data));
      const datasave = {
        cand_id: user?.id,
        status: 'saved',
      };
      const jobs = await dispatch(
        jobsActions.getJobListByStatusSaved(datasave, datasave.status),
      );
      const id = params?.job?.split('-');
      const data3 = {
        cand_id: user?.id,
        job_id: id[id?.length - 1].substring(2),
      };
      const rewardscategorylist = await dispatch(
        jobsActions.getJobUserStatusDetails(data3),
      );
      const data2 = {
        cand_id: user.id,
        page_no: 0,
      };

      const jobs2 = await dispatch(
        jobsActions.getJobListByStatusApplied(data2),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const unsaveJob = async (e) => {
    e.stopPropagation();

    if (user?.isVerified) {
      const data = {
        job_id: jobDetails?.id,
        cand_id: user?.id,
        status: 'unsave',
        datetime: dateTime(),
        user_id: jobDetails?.user_table_pk,
      };
      const savejob = await dispatch(jobsActions.postSaveJobNew(data));
      const datasave = {
        cand_id: user?.id,
        status: 'saved',
      };
      const jobs = await dispatch(
        jobsActions.getJobListByStatusSaved(datasave, datasave.status),
      );
      const id = params?.job?.split('-');
      const data3 = {
        cand_id: user?.id,
        job_id: id[id?.length - 1].substring(2),
      };
      const rewardscategorylist = await dispatch(
        jobsActions.getJobUserStatusDetails(data3),
      );
      const data2 = {
        cand_id: user.id,
        page_no: 0,
      };

      const jobs2 = await dispatch(
        jobsActions.getJobListByStatusApplied(data2),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const withdrawJob = async (e) => {
    if (user?.isVerified) {
      const data = {
        job_id: jobDetails?.id,
        cand_id: user?.id,
        status: 'withdraw',
        datetime: dateTime(),
        user_id: jobDetails?.user_table_pk,
      };
      const savejob = await dispatch(jobsActions.postSaveJobNew(data));
      const id = params?.job?.split('-');
      const data3 = {
        cand_id: user?.id,
        job_id: id[id?.length - 1].substring(2),
      };
      const rewardscategorylist = await dispatch(
        jobsActions.getJobUserStatusDetails(data3),
      );
      setApplied(false);
      const data2 = {
        cand_id: user.id,
        page_no: 0,
      };

      const jobs2 = await dispatch(
        jobsActions.getJobListByStatusApplied(data2),
      );

      const datasave = {
        cand_id: user?.id,
        status: 'unsave',
      };

      const dataapplied = {
        job_id: jobDetails?.id,
        status: 'applied',
        user_id: user?.id,
      };
      const jobs = await dispatch(
        jobsActions.getCandidateListApplied(dataapplied),
      );
      const datasavewith = {
        cand_id: user?.id,
        page_no: 0,
      };
      const withdrawnk = await dispatch(
        jobsActions.getJobListByStatusApplied(datasavewith),
      );
      const datajob = {
        job_id: jobDetails?.id,
      };

      const jobdetails = await dispatch(jobsActions.getJobDetails(datajob));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }

    // const jobs = await dispatch(
    //   jobsActions.getJobListByStatus(datasave )
    // );
  };

  const showConfirmation = () => {
    setShowConfirmation(!showconfirmation);
  };

  const userSalary = () => {
    let minsalary = 0;
    let maxsalary = 0;
    if (jobDetails?.min_salary >= 100) {
      minsalary = 'Min-' + jobDetails?.min_salary / 100 + ' Cr  ';
    } else {
      minsalary = 'Min-' + jobDetails?.min_salary + ' lpa   ';
    }

    if (jobDetails?.max_salary >= 100 && jobDetails?.max_salary < 201) {
      maxsalary =
        '   \0\0\0 Max- ' + jobDetails?.max_salary / 100 + ' Cr per annum';
    } else if (jobDetails?.max_salary < 100) {
      maxsalary = ' \0\0\0  Max- ' + jobDetails?.max_salary + ' lpa';
    } else if (jobDetails?.max_salary === 201) {
      maxsalary = '\0\0\0 Cr per annum';
    } else if (jobDetails?.max_salary === 'open') {
      maxsalary = '\0\0\0 Max- Open';
    } else if (jobDetails?.max_salary === 'not_disclosed') {
      maxsalary = '\0\0\0 Max- Not disclosed ';
    }

    return minsalary + maxsalary;
  };

  const warningText = () => {
    if (membership_type === 1) {
      return 'You have already applied to 3 jobs with your current plan. Please upgrade your plan to apply for more jobs.';
    } else if (membership_type === 2) {
      return 'You have already applied to 9 jobs with your current plan. Please upgrade your plan to apply for more jobs.';
    }
  };

  const viewPlans = () => {
    navigate('/rewards/membership');
  };

  const saveFile = async () => {
    if (user?.isVerified) {
      if (jobDetails?.jd_file) {
        saveAs(jobDetails?.jd_file, 'jd.pdf');
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const maxexperience = () => {
    if (jobDetails?.max_experience === 'open') {
      return 'Open';
    } else if (jobDetails?.max_experience === 'not_disclosed') {
      return 'Not disclosed';
    } else {
      return jobDetails?.max_experience + ' years';
    }
  };

  if (jobdetailsLoading) {
    return <JobDetailsSkeleton />;
  } else {
    return (
      <Fragment>
        {showconfirmation && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
              border: '1px solid red',
            }}>
            <Backdrop
              sx={{
                color: '#fff',
                backgroundColor: 'rgb(0 0 0 / 28%)',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={showconfirmation}>
              <Stack
                spacing={0}
                sx={{
                  width: '100%',
                  maxWidth: '700px',
                  backgroundColor: '#333333',
                  borderRadius: '5px',
                }}>
                <Stack spacing={0} direction="row">
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '24px',
                    }}>
                    <Typography variant="h5s" component="h5s">
                      Job Applied Limit Exceeded
                    </Typography>
                  </Box>
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                        cursor: 'pointer',
                      }}
                      onClick={showConfirmation}
                    />
                  </Box>
                </Stack>

                <Box
                  p={5}
                  sx={{
                    width: '100%',
                    backgroundColor: '#333333',
                    borderRadius: '10px',
                    maxWidth: '700px',
                    whiteSpace: 'pre-line',
                    borderRadius: '5px',
                    justifyContent: 'center',
                    display: 'flex',
                  }}>
                  {warningText()}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '16px',
                  }}>
                  <CxoButton onClick={viewPlans} label="View Plans" />
                </Box>
              </Stack>
            </Backdrop>
          </Box>
        )}
        <Box px={{ md: 1, xs: 0 }} pt={1} pb={1} id="jobdetails">
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChangePanel('panel1')}>
            <Stack direction="row" spacing={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  padding: '8px',
                  backgroundColor: '#333',
                  width: '100%',
                }}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    paddingTop: '4px',
                    paddingLeft: '24px',
                    '@media (max-width: 500px)': {
                      paddingLeft: '8px',
                    },
                  }}>
                  <Typography
                    variant="h5s"
                    component="h5s"
                    sx={{
                      fontWeight: '600',
                      fontSize: '22px',
                      lineHeight: '21px',
                      '@media (max-width: 500px)': {
                        fontSize: '16px',
                      },
                    }}>
                    {jobDetails?.title ? jobDetails?.title : ''}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '14px',
                      },
                    }}>
                    Company :{' '}
                    {jobDetails?.is_company_confidential &&
                    user?.id !== jobDetails?.user_table_pk
                      ? 'Confidential'
                      : jobDetails?.company_name}
                  </Typography>
                </Stack>
                {!jobuserstatus && (
                  <Box
                    sx={{
                      marginLeft: 'auto',
                      marginRight: '20px',
                      marginTop: '11px',
                    }}>
                    <CxoButton onClick={applyJob} label="Apply" />
                  </Box>
                )}
              </AccordionSummary>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: '#333',
                }}
                p={{ md: 3, xs: 0 }}></Box>
            </Stack>
            <AccordionDetails
              sx={{ backgroundColor: '#272727', marginBottom: '16px' }}>
              <Grid container className={classes.rowroot}>
                <Grid item xs={6}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Location
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                        },
                      }}>
                      {jobDetails?.location ? jobDetails?.location : ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Job Type
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                        },
                      }}>
                      {jobDetails?.job_type ? jobDetails?.job_type : ''}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container className={classes.rowroot}>
                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Experience
                    </Typography>

                    <Stack direction="row">
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        Min-
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          fontFamily: 'Open Sans',
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        {jobDetails?.min_experience
                          ? jobDetails?.min_experience
                          : ''}
                        {'  '} years
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ whiteSpace: 'pre-line', paddingLeft: '8px' }}>
                        {' \0\0\0'}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        {} Max-
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          fontFamily: 'Open Sans',
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        {maxexperience()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={6}>
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      '@media (max-width: 500px)': {
                        paddingTop: '15px',
                      },
                    }}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Salary
                    </Typography>
                    {jobDetails?.is_salary_confidential &&
                    user?.id !== jobDetails?.user_table_pk ? (
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        Confidential
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        {userSalary()}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Grid container className={classes.rowroot}>
                <Grid item xs={12}>
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{ paddingTop: '8px' }}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Industry
                    </Typography>

                    <Box pr={0.5} sx={{ width: '100%' }}>
                      {jobDetails?.industries &&
                        jobDetails?.industries?.map((industry, index) => {
                          if (index > 0) {
                            return (
                              <Typography
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                  },
                                }}
                                key={index}
                                variant="h5"
                                component="span">
                                {',  ' + industry?.title}
                              </Typography>
                            );
                          } else {
                            return (
                              <Typography
                                key={index}
                                variant="h5"
                                component="span"
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                  },
                                }}>
                                {industry?.title}
                              </Typography>
                            );
                          }
                        })}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container className={classes.rowroot}>
                <Grid item xs={12}>
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{ paddingTop: '8px' }}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Function
                    </Typography>

                    <Box pr={0.5} sx={{ width: '100%' }}>
                      {jobDetails?.functions &&
                        jobDetails?.functions?.map((functions, index) => {
                          if (index > 0) {
                            return (
                              <Typography
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                  },
                                }}
                                key={index}
                                variant="h5"
                                component="span">
                                {',  ' + functions?.title}
                              </Typography>
                            );
                          } else {
                            return (
                              <Typography
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                  },
                                }}
                                key={index}
                                variant="h5"
                                component="span">
                                {functions?.title}
                              </Typography>
                            );
                          }
                        })}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container className={classes.rowroot}>
                <Grid item xs={6}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Posted On
                    </Typography>

                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                        },
                      }}>
                      {jobDetails?.posted_on
                        ? moment(jobDetails?.posted_on).format('D') +
                          ' ' +
                          moment(jobDetails?.posted_on).format('MMM') +
                          ' ' +
                          moment(jobDetails?.posted_on).format('YYYY')
                        : ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  {user?.id === jobDetails?.userId && (
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="h5s"
                        component="h5s"
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '14px',
                          },
                        }}>
                        {jobDetails?.applied_count +
                          jobDetails?.shortlisted_count +
                          jobDetails?.rejected_count +
                          jobDetails?.hold_count >
                        0
                          ? 'No of Applicants'
                          : 'No of Applicant'}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          fontFamily: 'Open Sans',
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        {jobDetails?.applied_count +
                          jobDetails?.shortlisted_count +
                          jobDetails?.rejected_count +
                          jobDetails?.hold_count}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              </Grid>

              <Grid container className={classes.rowroot}>
                <Grid item xs={12}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Education
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                        },
                      }}>
                      {jobDetails?.education ? jobDetails?.education : ''}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container className={classes.rowroot}>
                <Grid item xs={12}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Skills
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                        },
                      }}>
                      {jobDetails?.skills ? jobDetails?.skills : ''}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                className={classes.rowroot}
                sx={{ paddingBottom: '8px', paddingRight: '8px' }}>
                <Grid item xs={12}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Job Description
                    </Typography>

                    {jobDetails?.job_description?.length > 0 ? (
                      <Typography
                        variant="h2"
                        color="text.secondary"
                        sx={{
                          color: 'rgba(255, 255,255,1)',
                          whiteSpace: 'pre-line',
                          lineHeight: '21px',
                          letterSpacing: '1.2',
                          '@media (max-width: 500px)': {
                            fontSize: '13px',
                          },
                        }}>
                        {jobDetails?.job_description
                          ? jobDetails?.job_description
                          : ''}
                      </Typography>
                    ) : (
                      <Box py={2}>
                        <Stack direction="row" spacing={5}>
                          <Box onClick={saveFile} sx={{ cursor: 'pointer' }}>
                            <Stack direction="row" spacing={2}>
                              <RemoveRedEyeOutlinedIcon />
                              <Typography variant="h2" component="h2">
                                View JD
                              </Typography>
                            </Stack>
                          </Box>
                          <Box onClick={saveFile} sx={{ cursor: 'pointer' }}>
                            <Stack direction="row" spacing={2}>
                              <FileDownloadOutlinedIcon />
                              <Typography variant="h2" component="h2">
                                Download JD
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Grid>
              </Grid>

              {jobDetails?.user_table_pk !== user?.id && (
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent="space-between"
                  alignItems="center">
                  <Box sx={{ padding: '16px' }}>
                    <Card
                      sx={{
                        padding: '12px',
                        borderRadius: '10px',
                        backgroundColor: '#333',
                        border: '1px solid #3DCD84',
                        minWidth: '320px',
                      }}>
                      <Stack direction="row">
                        <Box
                          pl={2}
                          pr={1}
                          sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={
                              jobDetails?.user_image
                                ? jobDetails?.user_image
                                : PersonIcon
                            }
                            sx={{
                              width: 48,
                              height: 48,
                              cursor: 'pointer',
                              border: '2px solid #3DCD84',
                            }}
                          />
                        </Box>
                        <Box
                          px={2}
                          sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Stack spacing={0.2} direction="column">
                            <Box pb={1}>
                              <Typography
                                variant="h5s"
                                component="h5s"
                                sx={{
                                  paddingTop: '12px',
                                  paddingBottom: '12px',
                                }}>
                                Posted By
                              </Typography>
                            </Box>
                            <Typography
                              variant="h6"
                              component="h6"
                              sx={{
                                paddingTop: '1px',
                                paddingBottom: '3px',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}>
                              {jobDetails?.name ? jobDetails?.name : ''}
                            </Typography>
                            <Typography
                              variant="h6"
                              component="h6"
                              sx={{ paddingTop: '0.5px', fontSize: '11px' }}>
                              {jobDetails?.designation
                                ? jobDetails?.designation
                                : ''}
                            </Typography>
                            <Typography
                              variant="h6"
                              component="h6"
                              sx={{ paddingTop: '0.5px', fontSize: '11px' }}>
                              {jobDetails?.user_company
                                ? jobDetails?.user_company
                                : ''}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    </Card>
                  </Box>

                  {/* {checkUserApplied() && (
                    <Stack direction={{ md: "row", xs: "column" }} spacing={2}>
                      <Box pl={4}>
                        <CxoButton onClick={withdrawJob} label="Withdraw" />
                      </Box>
                      <Box pr={12}>
                        <CxoButton onClick={shareJob} label="Share" />
                      </Box>
                    </Stack>
                  )} */}
                  {!jobuserstatus && (
                    <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
                      <Box>
                        <CxoButton onClick={saveJob} label="Save" />
                      </Box>
                      <Box>
                        <CxoButton onClick={applyJob} label="Apply" />
                      </Box>
                      <Box pr={4}>
                        <CxoButton onClick={shareJob} label="Share" />
                      </Box>
                    </Stack>
                  )}

                  {jobuserstatus && jobuserstatus?.status == 'applied' && (
                    <Stack direction="row" spacing={2}>
                      <Box pl={{ md: 4, xs: 0 }}>
                        <CxoButton onClick={withdrawJob} label="Withdraw" />
                      </Box>
                      <Box pr={{ md: 12, xs: 0 }}>
                        <CxoButton onClick={shareJob} label="Share" />
                      </Box>
                    </Stack>
                  )}

                  {jobuserstatus && jobuserstatus?.status == 'hold' && (
                    <Stack direction="row" spacing={2}>
                      <Box pl={{ md: 4, xs: 0 }}>
                        <CxoButton onClick={withdrawJob} label="Withdraw" />
                      </Box>
                      <Box pr={{ md: 12, xs: 0 }}>
                        <CxoButton onClick={shareJob} label="Share" />
                      </Box>
                    </Stack>
                  )}

                  {jobuserstatus && jobuserstatus?.status == 'rejected' && (
                    <Stack direction="row" spacing={2}>
                      <Box pl={{ md: 4, xs: 0 }}>
                        <CxoButton onClick={withdrawJob} label="Withdraw" />
                      </Box>
                      <Box pr={{ md: 12, xs: 0 }}>
                        <CxoButton onClick={shareJob} label="Share" />
                      </Box>
                    </Stack>
                  )}

                  {jobuserstatus && jobuserstatus?.status == 'shortlist' && (
                    <Stack direction="row" spacing={2}>
                      <Box pl={{ md: 4, xs: 0 }}>
                        <CxoButton onClick={withdrawJob} label="Withdraw" />
                      </Box>
                      <Box pr={{ md: 12, xs: 0 }}>
                        <CxoButton onClick={shareJob} label="Share" />
                      </Box>
                    </Stack>
                  )}

                  {jobuserstatus && jobuserstatus?.status == 'saved' && (
                    <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
                      <Box>
                        <CxoButton onClick={unsaveJob} label="Unsave" />
                      </Box>
                      <Box>
                        <CxoButton onClick={applyJob} label="Apply" />
                      </Box>
                      <Box pr={4}>
                        <CxoButton onClick={shareJob} label="Share" />
                      </Box>
                    </Stack>
                  )}
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>

        {checkUserApplied() && <Message jobdetails={jobDetails} />}

        {!checkUserApplied() && user?.id !== jobDetails?.userId && (
          <JobSimilar jobdetails={jobDetails} changePanel={changePanel} />
        )}
        {user?.id === jobDetails?.userId && (
          <JobCandidate jobdetails={jobDetails} jobId={jobId} />
        )}
      </Fragment>
    );
  }
};

export default JobDetails;

//    <Grid item xs={4}>
//   <Stack direction="column" spacing={1}>
//     <Typography variant="h5s" component="h5s">
//       No Of Applicants
//     </Typography>
//     <Typography variant="h5" component="h5"></Typography>
//   </Stack>
// </Grid>

// if (Object.keys(userAbouts?.jobPreference).length === 0) {
//   const openapplyjob = await dispatch(
//     jobsActions.openApplyJob(jobDetails)
//   );
// } else {
//   navigate("/job/preference", { state: { job: jobDetails } });
// }
