import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Box, Typography } from '@mui/material';
import CxoButton from '../../../components/CxoButton/CxoButton';
import Stack from '@mui/material/Stack';
import useStyles from './chooseinterest';
import AdvisoryOpportunities from '../../../assets/onboard/Advisory_Opportunities.png';
import BusinessOpportunities from '../../../assets/onboard/Business_Opportunities.png';
import CareerOpportunities from '../../../assets/onboard/Career_Opportunities.png';
import CXONetworking from '../../../assets/onboard/CXO_Networking.png';
import FundRaise from '../../../assets/onboard/Fund_Raising.png';
import HiringCXOs from '../../../assets/onboard/Hiring_CXOs.png';
import IndustryEvents from '../../../assets/onboard/Industry_Events.png';
import InvestmentOpportunities from '../../../assets/onboard/Investment_Opportunities.png';
import NewsContent from '../../../assets/onboard/News_Content.png';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import Addnew from './Addnew';
import InterestIcon from '../../../assets/chooseurinterest';
import * as onboardActions from '../../../store/onboard';
const icons = {
  CXONetworking: CXONetworking,
  BusinessOpportunities: BusinessOpportunities,
  CareerOpportunities: CareerOpportunities,
  InvestmentOpportunities: InvestmentOpportunities,
  HiringCXOs: HiringCXOs,
  AdvisoryOpportunities: AdvisoryOpportunities,
  NewsContent: NewsContent,
  IndustryEvents: IndustryEvents,
  FundRaise: FundRaise,
};

let Interests = [
  {
    id: 1,
    label: 'CXO \n Networking',
    icon: 'CXONetworking',
    selected: false,
  },
  {
    id: 2,
    label: 'Business Opportunities',
    icon: 'BusinessOpportunities',
    selected: false,
  },
  { id: 3, label: 'Fund Raising', icon: 'FundRaise', selected: false },
  {
    id: 4,
    label: 'Career Opportunities',
    icon: 'CareerOpportunities',
    selected: false,
  },
  { id: 5, label: 'Industry Events', icon: 'IndustryEvents', selected: false },
  {
    id: 6,
    label: 'Investment \n Opportunities',
    icon: 'InvestmentOpportunities',
    selected: false,
  },
  { id: 7, label: 'Hiring CXOs', icon: 'HiringCXOs', selected: false },
  {
    id: 8,
    label: 'Advisory Opportunities',
    icon: 'AdvisoryOpportunities',
    selected: false,
  },
  { id: 9, label: 'News Contents', icon: 'NewsContent', selected: true },
];

const ChooseUrInterest = (props) => {
  var redirect_uri = window.location.href.split('redirect=')[1];
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [interestList, setInterestList] = useState(Interests);
  const [showAddnew, setShowAddnew] = useState(false);
  const isinterestList = useSelector((state) => !!state.onboard.interestList);
  const getinterestList = useSelector((state) => state.onboard.interestList);
  const user = useSelector((state) => state?.auth?.user?.user_details);

  useEffect(async () => {
    const interestlist = await dispatch(onboardActions.getUserInterestList());
  }, []);

  useEffect(async () => {
    if (!isinterestList) {
      const interestlist = await dispatch(onboardActions.getUserInterestList());
      const modify = interestlist?.data?.map((interest, index) => {
        const title = interest?.title?.split(' ').join();

        return {
          id: interest.id,
          title: interest.title,
          image: interest.image,
          selected: false,
        };
      });

      setInterestList(modify);
    } else {
      const modify = getinterestList?.data?.map((interest, index) => {
        const title = interest?.title?.split(' ').join('');
        let image = interest.image;

        if (icons.hasOwnProperty(title)) {
          image = icons[title];
        }
        return {
          id: interest.id,
          title: interest.title,
          image: image,
          selected: false,
        };
      });

      setInterestList(modify);
    }
  }, []);
  const handleToggle = (title) => {
    let mapped = interestList?.map((task) => {
      return task.title == title
        ? { ...task, selected: !task.selected }
        : { ...task };
    });
    setInterestList(mapped);
  };
  const showaddnew = () => {
    setShowAddnew(!showAddnew);
  };
  const handleClose = () => {
    setShowAddnew(false);
  };
  const addNew = () => {
    let copy = [...interestList];
    copy = [
      ...copy,
      {
        id: interestList?.length + 1,
        title: name,
        image: '',
        selected: true,
      },
    ];
    setInterestList(copy);
    setShowAddnew(false);
    setName('');
  };
  const submitUserInterest = async () => {
    const data = { user_id: user.id, aTitle: [], platform: 'web' };
    let count = 0;
    let aTitles = [];
    const aTitle = interestList?.filter((interst, index) => {
      if (interst.selected === true) {
        count = count + 1;
        aTitles.push(interst.title);
        return interst.title;
      }
    });

    if (count >= 3) {
      const data = {
        user_id: user.id,
        aTitle: aTitles.toString(),
        platform: 'web',
      };
      const userinterest = await dispatch(
        onboardActions.postUserInterestList(data),
      );
      if (userinterest?.status === 1) {
        if (redirect_uri != undefined) {
          navigate(redirect_uri);
        } else {
          navigate('/profile');
        }
      }
    }
  };
  const imageSrc = (src, ttle) => {
    const title = ttle.split(' ').join();

    // icons.hasOwnProperty(ttle);
  };

  return (
    <Fragment>
      <Box
        pt={2}
        elevation={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          px={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showAddnew}>
            {showAddnew ? (
              <Addnew
                onclick={addNew}
                name={name}
                onchange={(e) => {
                  setName(e.target.value);
                }}
                handleClose={handleClose}
              />
            ) : (
              ''
            )}
          </Backdrop>

          <Box
            py={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }}>
            <Box py={3} pb={2} sx={{ paddingBottom: '32px' }}>
              <Typography
                variant="h2s"
                component="h2s"
                sx={{ fontWeight: '800', fontSize: '18px' }}>
                Choose your interests
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(2, 1fr)',
                  md: 'repeat(5, 1fr)',
                },
              }}>
              {interestList?.map((Interest, index) => (
                <Box
                  p={2}
                  xs={4}
                  key={index}
                  className={
                    Interest.selected
                      ? classes.interestBoxselect
                      : classes.interestBox
                  }
                  onClick={() => handleToggle(Interest.title)}>
                  <Box p={1}>
                    {() => imageSrc(Interest.image, Interest.title)}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '36px',
                        maxWidth: 'min-content',
                        minWidth: '36px',
                      }}>
                      <InterestIcon
                        name={Interest?.title?.split(' ').join('')}
                        color={Interest.selected ? '#38CD84' : '#ffffff'}
                      />
                    </Box>
                  </Box>
                  <Box p={1}>
                    <Stack sx={{ textAlign: 'center' }}>
                      {Interest?.title?.split(' ').map((title, index) => (
                        <Typography
                          variant={Interest.selected ? 'h2s' : 'h2'}
                          key={index}
                          component={Interest.selected ? 'h2s' : 'h2'}
                          sx={{ fontSize: '14px' }}>
                          {title}
                        </Typography>
                      ))}
                    </Stack>
                  </Box>
                </Box>
              ))}
              <Box
                p={2}
                key="add"
                className={classes.interestBox}
                onClick={showaddnew}>
                <AddIcon sx={{ fontSize: 80 }} />
              </Box>
            </Box>
            <Box
              p={1}
              sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
              <Typography variant="h2" component="h2">
                Select minimum 3 interests*
              </Typography>
            </Box>
            <Box
              py={4}
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CxoButton onClick={submitUserInterest} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(ChooseUrInterest);
