import {
  COMPANY_PAGE_GET_SUCCESS,
  COMPANY_PAGE_GET_REQUEST,
  COMPANY_PAGE_GET_FAILURE,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_REQUEST,
  COMPANY_DETAILS_FAILURE,
  COMPANY_POST_LIST_SUCCESS,
  COMPANY_POST_LIST_REQUEST,
  COMPANY_POST_LIST_FAILURE,
  COMPANY_JOB_LIST_SUCCESS,
  COMPANY_JOB_LIST_REQUEST,
  COMPANY_JOB_LIST_FAILURE,
  COMPANY_CHECK_OTP_SEND_SUCCESS,
  COMPANY_CHECK_OTP_SEND_REQUEST,
  COMPANY_CHECK_OTP_SEND_FAILURE,
  COMPANY_VERIFY_OTP_SUCCESS,
  COMPANY_VERIFY_OTP_REQUEST,
  COMPANY_VERIFY_OTP_FAILURE,
  COMPANY_ACCESS_CREATE_SUCCESS,
  COMPANY_ACCESS_CREATE_REQUEST,
  COMPANY_ACCESS_CREATE_FAILURE,
  OPEN_COMPANY_ACCESS,
  CLOSE_COMPANY_ACCESS,
  COMPANY_EVENT_LIST_SUCCESS,
  COMPANY_EVENT_LIST_REQUEST,
  COMPANY_EVENT_LIST_FAILURE,
  COMPANY_INACTIVE_JOB_LIST_SUCCESS,
  COMPANY_INACTIVE_JOB_LIST_REQUEST,
  COMPANY_INACTIVE_JOB_LIST_FAILURE,
  COMPANY_CLOSED_JOB_LIST_SUCCESS,
  COMPANY_CLOSED_JOB_LIST_REQUEST,
  COMPANY_CLOSED_JOB_LIST_FAILURE,
  COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS,
  COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST,
  COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE,
  COMPANY_EVENT_PAST_LIST_SUCCESS,
  COMPANY_EVENT_PAST_LIST_REQUEST,
  COMPANY_EVENT_PAST_LIST_FAILURE,
  COMPANY_MEMBERSHIP_PURCHASED_DETAILS_SUCCESS,
  COMPANY_MEMBERSHIP_PURCHASED_DETAILS_REQUEST,
  COMPANY_MEMBERSHIP_PURCHASED_DETAILS_FAILURE,
  COMPANY_MEMBERSHIP_ACCESS_LIST_SUCCESS,
  COMPANY_MEMBERSHIP_ACCESS_LIST_REQUEST,
  COMPANY_MEMBERSHIP_ACCESS_LIST_FAILURE,
  COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_SUCCESS,
  COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_REQUEST,
  COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_FAILURE,
  COMPANY_VOUCHER_DETAILS_SUCCESS,
  COMPANY_VOUCHER_DETAILS_REQUEST,
  COMPANY_VOUCHER_DETAILS_FAILURE,
  COMPANY_CHECK_DOMAIN_NAME_SUCCESS,
  COMPANY_CHECK_DOMAIN_NAME_REQUEST,
  COMPANY_CHECK_DOMAIN_NAME_FAILURE,
  RESET_COMPANY_OTP,
} from './actions';

export const companyreducer = (
  state = {
    loading: false,
    companyList: [],
    companyDetails: {},
    companyPostList: [],
    companyEventList: [],
    companyEventPastList: [],
    companyOpenJobList: [],
    companyInactiveJobList: [],
    companyClosedJobList: [],
    companyEmail: '',
    otpSendSuccess: false,
    companyVerified: false,
    openCompanyAccess: false,
    savedLoading: false,
    companyDomain: '',
    openCompany: {},
    companyData: '',
    companyMembershipVouchers: [],
    companyMembershipPurchasedDetails: [],
    companyMembershipTotalPurchased: [],
    companyMembershipTotalExpired: [],
    companyMembershipTotalUsed: [],
    companyMembershipAccessList: [],
    companyMembershipPurchasedVoucher: [],
    companyVoucherDetails: [],
    domainExist: false,
  },
  action,
) => {
  switch (action.type) {
    case COMPANY_CHECK_DOMAIN_NAME_SUCCESS:
      return {
        ...state,
        domainExist: action?.payload,
      };
    case COMPANY_CHECK_DOMAIN_NAME_REQUEST:
      return {
        ...state,
        domainExist: false,
      };
    case COMPANY_CHECK_DOMAIN_NAME_FAILURE:
      return {
        ...state,
        domainExist: false,
      };
    case COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_SUCCESS:
      return {
        ...state,
        companyMembershipPurchasedVoucher: action?.payload,
      };
    case COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_REQUEST:
      return {
        ...state,
        companyMembershipPurchasedVoucher: [],
      };
    case COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_FAILURE:
      return {
        ...state,
        companyMembershipPurchasedVoucher: [],
      };
    case COMPANY_MEMBERSHIP_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        companyMembershipAccessList: action?.payload,
      };
    case COMPANY_MEMBERSHIP_ACCESS_LIST_REQUEST:
      return {
        ...state,
        companyMembershipAccessList: [],
      };
    case COMPANY_MEMBERSHIP_ACCESS_LIST_FAILURE:
      return {
        ...state,
        companyMembershipAccessList: [],
      };

    case COMPANY_VOUCHER_DETAILS_SUCCESS:
      return {
        ...state,
        companyVoucherDetails: action?.payload,
      };
    case COMPANY_VOUCHER_DETAILS_REQUEST:
      return {
        ...state,
        companyVoucherDetails: [],
      };
    case COMPANY_VOUCHER_DETAILS_FAILURE:
      return {
        ...state,
        companyVoucherDetails: [],
      };
    case COMPANY_MEMBERSHIP_PURCHASED_DETAILS_SUCCESS:
      return {
        ...state,
        companyMembershipPurchasedDetails: action?.payload?.data,
        companyMembershipTotalPurchased: action?.payload?.total_purchased,
        companyMembershipTotalExpired: action?.payload?.total_expired,
        companyMembershipTotalUsed: action?.payload?.total_used,
      };
    case COMPANY_MEMBERSHIP_PURCHASED_DETAILS_REQUEST:
      return {
        ...state,
        companyMembershipPurchasedDetails: [],
      };
    case COMPANY_MEMBERSHIP_PURCHASED_DETAILS_FAILURE:
      return {
        ...state,
        companyMembershipPurchasedDetails: [],
      };
    case COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS:
      return {
        ...state,
        companyMembershipVouchers: action?.payload,
      };
    case COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST:
      return {
        ...state,
        companyMembershipVouchers: [],
      };
    case COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE:
      return {
        ...state,
        companyMembershipVouchers: [],
      };

    case COMPANY_EVENT_LIST_SUCCESS:
      return {
        ...state,
        companyEventList: action?.payload,
      };
    case COMPANY_EVENT_LIST_REQUEST:
      return {
        ...state,
        companyEventList: [],
      };
    case COMPANY_EVENT_LIST_FAILURE:
      return {
        ...state,
        companyEventList: [],
      };

    case COMPANY_EVENT_PAST_LIST_SUCCESS:
      return {
        ...state,
        companyEventPastList: action?.payload[0],
      };
    case COMPANY_EVENT_PAST_LIST_REQUEST:
      return {
        ...state,
        companyEventPastList: [],
      };
    case COMPANY_EVENT_PAST_LIST_FAILURE:
      return {
        ...state,
        companyEventPastList: [],
      };

    case OPEN_COMPANY_ACCESS:
      return {
        ...state,
        openCompanyAccess: true,
        openCompany: action?.payload?.data,
      };
    case CLOSE_COMPANY_ACCESS:
      return {
        ...state,
        openCompanyAccess: false,
      };
    case COMPANY_ACCESS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_ACCESS_CREATE_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_ACCESS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        otpSendSuccess: false,
        companyVerified: false,
        companyData: action?.payload?.data,
      };
    case COMPANY_VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
        otpSendSuccess: true,
        companyVerified: false,
      };
    case RESET_COMPANY_OTP:
      return {
        ...state,
        loading: false,
        errors: {},
        otpSendSuccess: false,
        companyVerified: false,
      };
    case COMPANY_CHECK_OTP_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        companyEmail: action?.payload?.payld?.email,
        companyDomain: action?.payload?.payld?.domain_name,
        otpSendSuccess: true,
        companyVerified: false,
      };
    case COMPANY_CHECK_OTP_SEND_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_CHECK_OTP_SEND_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case COMPANY_JOB_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_JOB_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case COMPANY_JOB_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        companyOpenJobList: action?.payload,
      };

    case COMPANY_INACTIVE_JOB_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_INACTIVE_JOB_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case COMPANY_INACTIVE_JOB_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        companyInactiveJobList: action?.payload,
      };

    case COMPANY_CLOSED_JOB_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_CLOSED_JOB_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case COMPANY_CLOSED_JOB_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        companyClosedJobList: action?.payload,
      };
    case COMPANY_PAGE_GET_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_PAGE_GET_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case COMPANY_PAGE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        companyList: action?.payload,
      };
    case COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        companyDetails: action?.payload[0],
      };
    case COMPANY_POST_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_POST_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case COMPANY_POST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        companyPostList: action?.payload,
      };
    default:
      return state;
  }
};
