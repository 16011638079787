import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import * as userActions from '../../store/user';
import * as messageActions from '../../store/message';
import 'react-quill/dist/quill.snow.css';
import useStyles from './addnewstyle';
import CxoButton from '../../components/CxoButton/CxoButton';
import Icon from '../../assets/icon';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import IconButton from '@mui/material/IconButton';
import CxoInput from '../../components/CxoInput/CxoInput';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditEducation from './editEducation';
import EditLanguage from './editLanguage';
import EditAffilation from './editAffilation';
import EditAwards from './editAwards';
import EditPublication from './editPublication';
import EditExperience from './editExperience';
import EditSkills from './editSkills';
import { useForm, Controller } from 'react-hook-form';
import { dateTime } from '../../constants/app';

const UpdateProfileComponent = (props) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState('Everyone');
  const [skill, setSkill] = useState('');
  const [interest, setInterest] = useState('');
  const [board, setBoard] = useState('');
  const [degree, setDegree] = useState('');
  const [passingyear, setPassingyear] = useState('');
  const [educationdetails, setEducationdetails] = useState('');

  const [options, setOptions] = useState(2);
  const [pollend, setPollend] = useState(1);
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(40);
  const [toggleshare, setToggleshare] = useState(false);
  const [share, setShare] = useState('Everyone');

  const addNew = useSelector((state) => state?.user?.UpdateProfile);
  const addNewtype = useSelector((state) => state?.user?.UpdateProfileType);
  const updateSection = useSelector((state) => state?.user?.UpdateSection);
  const user = useSelector((state) => state?.user?.UserDetails);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
  }, []);

  const addPost = async () => {};
  const handleChange = (event) => {};
  const handleSkill = (event) => {
    setSkill(event.target.value);
  };

  const handleInterest = (event) => {
    setInterest(event.target.value);
  };

  const handleBoard = (event) => {
    setBoard(event.target.value);
  };
  const addSkills = async () => {
    const data = {
      id: 0,
      user_id: user?.id,
      title: skill,
    };
    const addskills = await dispatch(userActions.manageSkill(data));
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
    const snackbar = await dispatch(userActions.closeUpdateSectionProfile());
  };

  const addInterest = async () => {
    const intert = [];
    intert.push(interest);
    const data = {
      id: 0,
      user_id: user?.id,
      aTitle: interest,
      platform: 'web',
    };
    const addsInterest = await dispatch(userActions.manageInterest(data));
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
    const snackbar = await dispatch(userActions.closeUpdateSectionProfile());
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const snackbar = await dispatch(userActions.closeUpdateSectionProfile());
  };

  const addOption = () => {
    setOptions(options + 1);
  };
  const removeOptions = () => {
    if (options > 2) {
      setOptions(options - 1);
    }
  };
  const handleSubmitpoll = async (data) => {};

  const handlePollend = (event) => {
    setPollend(event.target.value);
  };
  const handleShare = (event) => {
    setShare(event.target.value);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const toggleShare = () => {
    setToggleshare(!toggleshare);
  };

  const handleEducation = (event, type) => {
    if (type === 'Board') {
      setBoard(event.target.value);
    } else if (type === 'Degree') {
      setDegree(event.target.value);
    } else if (type === 'yearofpassing') {
      setPassingyear(event.target.value);
    } else if (type === 'Details') {
      setEducationdetails(event.target.value);
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '1200',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={addNew}>
        <Box
          sx={{
            width: '60%',
            maxWidth: '600px',
            backgroundColor: '#1c1c1c',
            borderRadius: '10px',
            maxHeight: '80vh',
            minWidth: '60%',
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                pt={2}
                sx={{
                  paddingLeft: '72px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {addNewtype === 'education' && (
                  <Typography variant="h2s" component="h2s">
                    Update Your {addNewtype === 'education' ? 'Education' : ''}
                  </Typography>
                )}
                {addNewtype === 'award' && (
                  <Typography variant="h2s" component="h2s">
                    Add Your{' '}
                    {addNewtype === 'award' ? 'Award & Certification' : ''}
                  </Typography>
                )}
                {addNewtype === 'affiliation' && (
                  <Typography variant="h2s" component="h2s">
                    Add Your {addNewtype === 'affiliation' ? 'Affiliation' : ''}
                  </Typography>
                )}
                {addNewtype === 'publications' && (
                  <Typography variant="h2s" component="h2s">
                    Add Your{' '}
                    {addNewtype === 'publications' ? 'Publication' : ''}
                  </Typography>
                )}
                {addNewtype === 'language' && (
                  <Typography variant="h2s" component="h2s">
                    Add Your {addNewtype === 'language' ? 'Language' : ''}
                  </Typography>
                )}
                {addNewtype === 'skills' && (
                  <Typography variant="h2s" component="h2s">
                    Add Your {addNewtype === 'skills' ? 'Skills' : ''}
                  </Typography>
                )}

                {addNewtype === 'interest' && (
                  <Typography variant="h2s" component="h2s">
                    Add Your {addNewtype === 'interest' ? 'Interest' : ''}
                  </Typography>
                )}

                {addNewtype === 'experience' && (
                  <Typography variant="h2s" component="h2s">
                    Add Your {addNewtype === 'experience' ? 'Experience' : ''}
                  </Typography>
                )}
                {addNewtype != 'skills' &&
                  addNewtype != 'interest' &&
                  addNewtype != 'experience' &&
                  addNewtype != 'education' &&
                  addNewtype != 'language' &&
                  addNewtype != 'publications' &&
                  addNewtype != 'affiliation' &&
                  addNewtype != 'award' && (
                    <Typography variant="h2s" component="h2s">
                      Add Your {addNewtype}
                    </Typography>
                  )}
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>

            <Box
              p={2}
              sx={{ zIndex: '1200', borderRadius: '5px' }}
              className="editor">
              <Divider variant="middle" sx={{ width: '95%' }} />
              {addNewtype === 'skills' && (
                <Fragment>
                  <EditSkills section={updateSection} />
                </Fragment>
              )}

              {addNewtype === 'interest' && (
                <Fragment>
                  <Box
                    py={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <TextField
                      fullWidth
                      value={interest}
                      onChange={handleInterest}
                    />
                  </Box>

                  <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-end">
                    <Grid item>
                      <Box
                        py={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          width: '100%',
                        }}>
                        <CxoButton label="Submit" onClick={addInterest} />
                      </Box>
                    </Grid>
                  </Grid>
                </Fragment>
              )}

              {addNewtype === 'education' && (
                <Fragment>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <EditEducation section={updateSection} />
                  </Box>
                </Fragment>
              )}

              {addNewtype === 'experience' && (
                <Fragment>
                  <Box
                    sx={{ height: '70vh', width: '100%', overflowY: 'scroll' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}>
                      <EditExperience section={updateSection} />
                    </Box>
                  </Box>
                </Fragment>
              )}
              {addNewtype === 'affiliation' && (
                <Fragment>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <EditAffilation section={updateSection} />
                  </Box>
                </Fragment>
              )}

              {addNewtype === 'award' && (
                <Fragment>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <EditAwards section={updateSection} />
                  </Box>
                </Fragment>
              )}

              {addNewtype === 'publications' && (
                <Fragment>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <EditPublication section={updateSection} />
                  </Box>
                </Fragment>
              )}

              {addNewtype === 'language' && (
                <Fragment>
                  <Box
                    py={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <EditLanguage section={updateSection} />
                  </Box>
                </Fragment>
              )}
            </Box>

            <Box></Box>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(UpdateProfileComponent);
