import axios from 'axios';
import config from './apiConfig';

const http_noauth = {
  /**
   * @description - Calling http get method to fetch data from server
   * @param {String} endPoint - Api end point
   */
  get: ({ endPoint, queryParams }, callback) => {
    axios
      .get(`${config.serverBaseUrl}/${endPoint}`, {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*',
        },
        params: queryParams || {},
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http get method to fetch data by id from server
   * @param {String} endPoint - Api end point
   * @param {String} id - Record id
   */
  getById: ({ endPoint, id }, callback) => {
    axios
      .get(`${config.serverBaseUrl}/${endPoint}/${id}`, {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http post method to create record in server
   * @param {String} endPoint - Api end point
   * @param {Object} body - Request payload
   */
  post: ({ endPoint, queryParams, body }, callback) => {
    axios
      .post(`${config.serverBaseUrl}/${endPoint}`, body, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          Accept: 'application/json',
        },
        params: queryParams || {},
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },
  /**
   * @description - Calling http put method to update record in server
   * @param {String} endPoint - Api end point
   * @param {Object} body - Request payload
   * @param {String} id - Record id
   */
  put: ({ endPoint, body, id, isFormData = false }, callback) => {
    axios
      .put(`${config.serverBaseUrl}/${endPoint}/${id}`, body, {
        headers: {
          'Content-Type': isFormData
            ? 'multipart/form-data'
            : 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http delete method to delete record(s) in server
   * @param {String} endPoint - Api end point
   * @param {String} ids - Records id
   */
  delete: ({ endPoint, body = {}, queryParams = {} }, callback) => {
    axios
      .delete(`${config.serverBaseUrl}/${endPoint}`, {
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: queryParams,
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },
};
const http = {
  /**
   * @description - Calling http get method to fetch data from server
   * @param {String} endPoint - Api end point
   */
  get: ({ endPoint, queryParams }, callback) => {
    axios
      .get(`${config.serverBaseUrl}/${endPoint}`, {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*',
        },
        params: queryParams || {},
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http get method to fetch data by id from server
   * @param {String} endPoint - Api end point
   * @param {String} id - Record id
   */
  getById: ({ endPoint, id }, callback) => {
    axios
      .get(`${config.serverBaseUrl}/${endPoint}/${id}`, {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http post method to create record in server
   * @param {String} endPoint - Api end point
   * @param {Object} body - Request payload
   */
  post: ({ endPoint, queryParams, body, token }, callback) => {
    axios
      .post(`${config.serverBaseUrl}/${endPoint}`, body, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
        params: queryParams || {},
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },
  /**
   * @description - Calling http put method to update record in server
   * @param {String} endPoint - Api end point
   * @param {Object} body - Request payload
   * @param {String} id - Record id
   */
  put: ({ endPoint, body, id, isFormData = false }, callback) => {
    axios
      .put(`${config.serverBaseUrl}/${endPoint}/${id}`, body, {
        headers: {
          'Content-Type': isFormData
            ? 'multipart/form-data'
            : 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        console.log('error', error);
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http delete method to delete record(s) in server
   * @param {String} endPoint - Api end point
   * @param {String} ids - Records id
   */
  delete: ({ endPoint, body = {}, queryParams = {} }, callback) => {
    axios
      .delete(`${config.serverBaseUrl}/${endPoint}`, {
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: queryParams,
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },
};
const http_form = {
  /**
   * @description - Calling http get method to fetch data from server
   * @param {String} endPoint - Api end point
   */
  get: ({ endPoint, queryParams }, callback) => {
    axios
      .get(`${config.serverBaseUrl}/${endPoint}`, {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*',
        },
        params: queryParams || {},
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http get method to fetch data by id from server
   * @param {String} endPoint - Api end point
   * @param {String} id - Record id
   */
  getById: ({ endPoint, id }, callback) => {
    axios
      .get(`${config.serverBaseUrl}/${endPoint}/${id}`, {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http post method to create record in server
   * @param {String} endPoint - Api end point
   * @param {Object} body - Request payload
   */
  post: ({ endPoint, queryParams, body, token }, callback) => {
    axios
      .post(`${config.serverBaseUrl}/${endPoint}`, body, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
        params: queryParams || {},
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },
  /**
   * @description - Calling http put method to update record in server
   * @param {String} endPoint - Api end point
   * @param {Object} body - Request payload
   * @param {String} id - Record id
   */
  put: ({ endPoint, body, id, isFormData = false }, callback) => {
    axios
      .put(`${config.serverBaseUrl}/${endPoint}/${id}`, body, {
        headers: {
          'Content-Type': isFormData
            ? 'multipart/form-data'
            : 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        console.log('error', error);
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },

  /**
   * @description - Calling http delete method to delete record(s) in server
   * @param {String} endPoint - Api end point
   * @param {String} ids - Records id
   */
  delete: ({ endPoint, body = {}, queryParams = {} }, callback) => {
    axios
      .delete(`${config.serverBaseUrl}/${endPoint}`, {
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: queryParams,
      })
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        const errorData =
          error.response && error.response.data ? error.response.data : error;
        callback(errorData);
      });
  },
};

export default { http_noauth, http, http_form };
