import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import EventCard from '../../components/EventCard/EventCard';
import EventMembers from '../../components/EventMembers/EventMembers';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const EventAttending = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofAttending, setNoofAttending] = React.useState(4);

  const user = useSelector((state) => state?.user?.UserDetails);
  const Attendinglist = useSelector(
    (state) => state?.events?.eventAttendingList,
  );
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const eventDetails = useSelector((state) => state?.events?.eventDetails);

  const eventInvitedMembers = useSelector(
    (state) => state?.events?.eventInvitedMembers,
  );

  const eventAttendingMembers = useSelector(
    (state) => state?.events?.eventAttendingMembers,
  );

  const eventRequestMembers = useSelector(
    (state) => state?.events?.eventRequestMembers,
  );
  useEffect(async () => {
    const data = {
      event_id: eventDetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventAttendingMembers(data));
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        event_id: eventDetails?.id,
      };
      const eventList = await dispatch(eventsActions.eventInvitedMembers(data));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        event_id: eventDetails?.id,
      };
      const eventList = await dispatch(
        eventsActions.eventRequestedMembers(data),
      );
    }
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeMore = () => {
    if (Attendinglist?.length > noofAttending) {
      setNoofAttending(Attendinglist?.length);
    } else if (Attendinglist?.length === noofAttending) {
      setNoofAttending(4);
    }
  };
  return (
    <Fragment>
      <Box px={1}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
            }}>
            <Grid container>
              {eventAttendingMembers?.length > 0 &&
                eventAttendingMembers?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        marginTop: '8px',
                        paddingLeft: '8px',
                      }}>
                      <EventMembers
                        members={list}
                        type="Attending"
                        eventDetails={eventDetails}
                      />
                    </Grid>
                  );
                })}
              {eventAttendingMembers?.length == 0 && (
                <Box pl={2}>
                  <Typography variant="h5" component="h5">
                    There is no members in attending this event now. You can
                    invite some users from your connection.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
        <Box
          py={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={seeMore}>
          <Typography variant="h5" component="h5"></Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EventAttending);
