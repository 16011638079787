import {  apiUrl } from '../../services';
import { http } from '../../services/client';
import { showMessage } from '../message';
export const POST_SEARCH_FULFILLED = 'POST_SEARCH_FULFILLED';
export const POST_SEARCH_PENDING = 'POST_SEARCH_PENDING';
export const POST_SEARCH_REJECTED = 'POST_SEARCH_REJECTED';

const postSearchPending = () => ({
  type: 'POST_SEARCH_PENDING',
});

const postSearchFailure = (error) => ({
  type: 'POST_SEARCH_REJECTED',
  payload: { error },
});

const postSearchSuccess = (data, post) => ({
  type: POST_SEARCH_FULFILLED,
  payload: data,
});

export const postSearch = (payload) => {
  return (dispatch) => {
    dispatch(postSearchPending());
    return http
      .post(apiUrl.SEARCH_ALL, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(postSearchSuccess(response, payload));
          showMessage({
            dispatch,
            message: 'Search List',
            variant: 'success',
          });

          return response;
        } else {
          dispatch(postSearchFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(postSearchFailure(error));
        showMessage({
          dispatch,
          message: 'error',
          variant: 'error',
        });
        return error;
      });
  };
};
