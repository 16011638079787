import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import BgImage from '../../assets/auth/BG.png';
import logo from '../../assets/header/Link_Logo_White.svg';
import KnowUMore from './KnowUMore/KnowUMore';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down('md')]: {
      height: '50px',
    },
    [theme.breakpoints.up('md')]: {
      height: '72px',
      paddingLeft: '32px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '72px',
      paddingLeft: '72px',
    },
  },
  outergrid: {
    [theme.breakpoints.down('md')]: {
      minHeight: '85vh',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '100vh',
    },
  },
}));
const UserDetailsContainer = (props) => {
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const classes = useStyles();
  return (
    <Fragment>
      <Helmet>
        <title>Details</title>
      </Helmet>
      {openSnackbar && <CustomizedSnackbars />}
      <Paper
        elevation={0}
        sx={{
          backgroundImage: `url(${BgImage})`,
          height: 'inherit',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        <Grid container spacing={0} className={classes.outergrid}>
          <Grid item xs={12} md={6} sm={12}>
            <Box pl={5} pt={2}>
              <img alt="logo" className={classes.logo} src={logo} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <KnowUMore />
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default React.memo(UserDetailsContainer);
