import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import { alpha } from '@mui/material/styles';

import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';

import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));
const EditFunctions = (props) => {
  console.log('EditSkills', props);
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [skill, setSkill] = useState('');
  const [tempskill, setTempSkill] = useState([]);
  const [deleteskill, setDeleteSkill] = useState([]);
  const [oldskill, setOldSkill] = useState([]);
  const [oldFunction, setoldFunction] = useState([]);
  const [file, setFile] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(new Date());
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };
  const handleSkill = (event) => {
    setSkill(event.target.value);
  };

  const addFunctions = async () => {
    console.log('functionlst', functionlst);
    const profiledata = {
      id: 0,
      user_id: user?.id,
      aFunctionId: JSON.stringify(functionlst),
    };
    const usersabout = await dispatch(userActions.manageFunction(profiledata));
    const data = {
      id: user?.id,
    };
    const usersaboutdata = await dispatch(userActions.userProfileAbout(data));
    const data2 = {
      user_id: user?.id,
      self_id: user?.id,
      datetime: dateTime(),
    };
    const users = await dispatch(userActions.userProfileDetails(data2));

    const snackbar = await dispatch(userActions.closeUpdateProfile());
    // const data = {
    //   id: 0,
    //   user_id: user?.id,
    //   title: skill,
    // };
    // const addskills = await dispatch(userActions.manageSkill(data));
    // const profiledata = {
    //   id: user?.id,
    // };
    // const usersabout = await dispatch(
    //   userActions.userProfileAbout(profiledata)
    // );
    // const snackbar = await dispatch(userActions.closeUpdateProfile());
  };

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);
  const userabouts = useSelector((state) => state?.user?.UserAbouts);

  // const searchresult =  dispatch(searchActions.postSearch(data));

  const handleChange = (newValue) => {
    setType(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      console.log('Enter key pressed');
      let temp = tempskill;
      temp.push(skill);
      setTempSkill(temp);
      setSkill('');
    }
  };
  const handleDelete = async (e, skill, type) => {
    if (type === 'new') {
      setTempSkill(tempskill.filter((e) => e !== skill));
    }
    if (type === 'old') {
      setOldSkill(oldskill.filter((e) => e !== skill));
    }
  };

  useEffect(() => {
    if (props?.section) {
      setOldSkill(props?.section);
    }
  }, []);

  useEffect(() => {
    let result = functionList?.data?.filter(
      ({ id: id }) =>
        !userabouts?.data?.some(
          ({ function_id: function_id }) => function_id === id,
        ),
    );

    const arr = userabouts?.aFunctionId?.map((object) => object.function_id);
    setoldFunction(arr);
    const ResultArrayObjOne = functionList?.data?.filter(
      ({ id: id }) =>
        !userabouts?.aFunctionId?.some(
          ({ function_id: function_id }) => function_id === id,
        ),
    );
    console.log('result 3', result, ResultArrayObjOne, functionList?.data, arr);
  }, []);

  const closeEdit = () => {};
  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSubmitform = async (data) => {
    console.log('data', data);

    const useredu = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      award_name: data?.award_name,
      awarded_by: data?.awarded_by,
      details: data?.details,
    };
    const usereducation = await dispatch(userActions.manageRewards(useredu));
    const userdata = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(userdata));
    if (updateSection) {
      const snackbar = await dispatch(userActions.closeUpdateSectionProfile());
    }
    if (addNew) {
      const snackbaradd = await dispatch(userActions.closeUpdateProfile());
    }
  };
  return (
    <Fragment>
      <Fragment>
        <Box
          py={4}
          px={{ md: 5, xs: 0 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            // overflowY: "scroll",
          }}>
          <Box px={3} sx={{ width: '100%' }}>
            <Stack spacing={1} direction="row">
              <Box
                pt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}></Box>
              <Box py={1} sx={{ width: '100%' }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: '#38CD84' }}>
                    Function
                  </InputLabel>
                  <Select
                    MenuProps={{
                      fontSize: '12px',
                      disablePadding: true,
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                      variant: 'menu',
                      getContentAnchorEl: null,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={functionlst}
                    label="Industry List"
                    multiple
                    onChange={functionChange}
                    size="small"
                    renderValue={(selected) => {
                      console.log('selected', selected);
                      return selected.length + ' ' + 'Selected';
                    }}>
                    {functionList?.data
                      ? functionList?.data.map((item, key) => {
                          const arr = userabouts?.aFunctionId?.map((object) =>
                            parseInt(object.function_id),
                          );
                          console.log('arr', arr?.includes(item.id));
                          if (!arr?.includes(item.id)) {
                            return (
                              <MenuItem key={key} value={item.id}>
                                <ListItemText
                                  color="secondary"
                                  primary={item.title.substring(0, 25) + '...'}
                                />
                                <Checkbox
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                  color="secondary"
                                  checked={functionlst.indexOf(item.id) > -1}
                                />
                              </MenuItem>
                            );
                          } else {
                            <Fragment></Fragment>;
                          }
                        })
                      : ''}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box></Box>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <Box
              py={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <CxoButton label="Save" onClick={addFunctions} />
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    </Fragment>
  );
};

export default React.memo(EditFunctions);
