import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import CxoButton from '../../components/CxoButton/CxoButton';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { dateTime } from '../../constants/app';
import { countryListnew } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../services/apiConfig';

import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import * as messageAction from '../../store/message';
import { array } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const CssAutocomplete = styled(Autocomplete)(({ theme }) => {
  return {
    '& .MuiAutocomplete-root': {
      padding: '0px',
    },
    '& .MuiSelect-select': {
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
    },
    '& .MuiList-root': {
      maxHeight: '60vh',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      padding: '1px 4px',
      marginRight: '5px',

      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CustomTextField = styled(TextField)(({ theme }) => {
  return {
    '& .MuiOutlinedInput-root': {
      padding: '8px',

      '& fieldset': {
        borderColor: '#4c4c4c',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: '#4c4c4c',
        borderWidth: '1px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4c4c4c',
        borderWidth: '1px',
      },
    },
  };
});

const JobApply = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [country, setCountry] = useState('91');
  const user = useSelector((state) => state?.user?.UserDetails);
  const userDetails = useSelector((state) => state?.user?.UserDetails);

  const openJobApply = useSelector((state) => state?.jobs?.JobApply);
  const detailsJobApply = useSelector((state) => state?.jobs?.ApplyJobDetails);
  const jobsave = useSelector((state) => state?.jobs?.jobsave);
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [writeBody, setWriteBody] = useState('');
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [imgData, setImgData] = useState(null);

  const [salaryinlacs, setSalaryInlacs] = useState(0);
  const [salaryinCrore, setSalaryInCrore] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState({
    id: 99,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  });

  const countryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleWrite = (e) => {
    setWriteBody(e.target.value);
  };

  const getDocument = async (event) => {
    const selectedFile = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (
      (event.target.files[0].name.split('.')[1] === 'pdf' ||
        event.target.files[0].name.split('.')[1] === 'doc' ||
        event.target.files[0].name.split('.')[1] === 'docx') &&
      selectedFile.size <= maxSize
    ) {
      console.log('set file');
      setFile(event.target.files[0]);
      setType('document');
      let flname = user?.first_name + '_' + user?.last_name;
      console.log(event.target.files[0].name.split('.')[1]);
      setName(flname + '.' + event.target.files[0].name.split('.')[1]);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    } else {
      let msg =
        selectedFile.size <= maxSize
          ? 'Only  pdf, doc, docx format accepted '
          : 'File size exceeds the maximum limit';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }
  };

  const handleSubmitform = async (formdata) => {
    // console.log("handleSubmitform", formdata, detailsJobApply);
    // const data = {
    //   job_id : detailsJobApply?.id,
    //   cand_id : user?.id,
    //   status: 'applied',
    //   email: formdata?.email,
    //   country_code: +91,
    //   mobile:  formdata?.mobile,
    //   message: writeBody,
    //   datetime : dateTime(),
    //   user_id: detailsJobApply?.user_table_pk
    // }

    if (file && writeBody?.length > 0) {
      if (salaryinCrore != 0 || salaryinlacs != 0) {
        let flname = user?.first_name + '_' + user?.last_name;
        let ctc = parseInt(salaryinCrore * 100) + parseInt(salaryinlacs);
        const formDatanew = new FormData();
        formDatanew.append('job_id', detailsJobApply?.id);
        formDatanew.append('cand_id', user?.id);
        formDatanew.append('status', 'applied');
        formDatanew.append('email', formdata?.email);
        formDatanew.append('country_code', '+91');
        formDatanew.append('datetime', dateTime());
        formDatanew.append('mobile', formdata?.mobile);
        formDatanew.append('message', writeBody);
        formDatanew.append('user_id', detailsJobApply?.user_table_pk);
        formDatanew.append('last_ctc', ctc);
        formDatanew.append('resume', file, name);

        const savejob = await dispatch(
          jobsActions.postSaveJob(formDatanew, {
            job_id: detailsJobApply?.id,
            status: 'applied',
            user_id: user?.id,
          }),
        );
        const data = {
          cand_id: user?.id,
        };
        const jobapplycount = await dispatch(
          jobsActions.getJobApplyCount(data),
        );
        // navigate('/jobs/search#applied');

        window.location.href = config.baseURL + 'jobs/applied';
        const snackbar = await dispatch(jobsActions.closeApplyJob());
      } else {
        let msg = 'Last Salary drawn required';
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(msg, 'error'),
        );
      }
    } else {
      let msg = 'Message and Resume is Mandatory';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }
  };

  const saveJob = async () => {
    const data = {
      job_id: detailsJobApply?.id,
    };
    const jobdetails = await dispatch(jobsActions.getJobDetails(data));
    const dataapplied = {
      job_id: detailsJobApply?.id,
      status: 'applied',
      user_id: user?.id,
    };
    const jobs = await dispatch(
      jobsActions.getCandidateListApplied(dataapplied),
    );

    const snackbar = await dispatch(jobsActions.closeApplyJob());
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(jobsActions.closeApplyJob());
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '1001',
          '@media (max-width: 500px)': {
            height: '100vh',
          },
        }}>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: '2001',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          }}
          open={openJobApply}>
          <Box px={0} sx={{ width: '100%' }}>
            <Box pt={{ md: 2, xs: 0 }} sx={{ width: '100%' }}>
              <Paper
                sx={{
                  backgroundColor: '#232121',
                  backgroundImage: 'none',
                  width: '60%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}>
                {/* <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={0}>
                  <Box pt={2} pr={2}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                      }}
                      onClick={handleClose}
                    />
                  </Box>
                </Stack> */}

                <form
                  onSubmit={handleSubmit(handleSubmitform)}
                  className="job-apply scrollbar-hidden">
                  <Box pt={0} sx={{ width: '100%' }}>
                    <Stack
                      direction={{ md: 'row', xs: 'column' }}
                      justifyContent="flex-start"
                      alignItems={{ md: 'flex-end', xs: 'flex-start' }}
                      spacing={0}>
                      <Box
                        px={2}
                        pb={2}
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          src={user?.image ? user?.image : PersonIcon}
                          sx={{
                            width: 48,
                            height: 48,
                            cursor: 'pointer',
                            border: '0.1px solid #3DCD84',
                          }}
                        />
                        <Box pl={2} pr={1}>
                          <Typography variant="h5s" component="h5s">
                            {userDetails?.first_name
                              ? userDetails?.first_name
                              : ''}{' '}
                            {userDetails?.last_name
                              ? userDetails?.last_name
                              : ''}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        pl={0}
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        {' '}
                        <Box
                          pt={{ md: 3, xs: 0 }}
                          sx={{
                            width: '120px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: { md: '0px', xs: '15px' },
                            paddingBottom: { md: '16px', xs: '0px' },
                          }}>
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <>
                                  <CssAutocomplete
                                    id="country-select-demo"
                                    sx={{
                                      width: 124,
                                      paddingRight: '5px',
                                      '@media (max-width: 500px)': {
                                        width: 110,
                                      },
                                    }}
                                    value={selectedCountry}
                                    options={countryListnew}
                                    autoHighlight
                                    disableClearable
                                    variant="standard"
                                    onChange={(_event, country) => {
                                      console.log('country', country);
                                      setSelectedCountry(country);
                                    }}
                                    getOptionLabel={(option) => {
                                      return (
                                        option.iso3 + ' +' + option.phone_code
                                      );
                                    }}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          '& > img': { mr: 2, flexShrink: 0 },
                                          width: '120px',
                                          '@media (max-width: 500px)': {
                                            width: '100px',
                                          },
                                        }}
                                        {...props}>
                                        {option.iso3} ({option.phone_code})
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                  />
                                </>
                              )}
                              name="country_code"
                              defaultValue="91"
                            />
                          </FormControl>
                        </Box>
                        <Box
                          pt={{ md: 0, xs: 1 }}
                          px={3}
                          sx={{
                            width: '170px',
                            paddingLeft: { md: '0px', xs: '15px' },
                            paddingBottom: { md: '0px', xs: '0px' },
                          }}>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Stack
                                sx={{ paddingTop: { md: '16px', xs: '0px' } }}>
                                <CxoInputCss
                                  label="Mobile No *"
                                  isApplyJob={true}
                                  value={value}
                                  err={errors?.mobile ? errors?.mobile : false}
                                  onChange={onChange}
                                />
                                <Box
                                  sx={{
                                    fontSize: '9px',
                                    height: '10px',
                                    paddingLeft: '8px',
                                  }}>
                                  {errors?.mobile?.message}{' '}
                                </Box>
                              </Stack>
                            )}
                            name="mobile"
                            required
                            rules={{
                              required: {
                                value: true,
                                message: 'Mobile is required',
                              },
                            }}
                            defaultValue={
                              userDetails?.mobile ? userDetails?.mobile : ''
                            }
                          />
                        </Box>
                      </Box>

                      {/* <Box
                        sx={{
                          width: "120px",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: { md: "0px", xs: "15px" },
                          paddingBottom: { md: "16px", xs: "0px" },
                          "@media (max-width: 500px)": {
                            width: "100px",
                            display: "flex",
                            alignItems: "center",
                          },
                        }}
                      >
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <>
                                <CssAutocomplete
                                  id="country-select-demo"
                                  sx={{
                                    width: 124,
                                    paddingRight: "5px",
                                    "@media (max-width: 500px)": {
                                      width: 110,
                                    },
                                  }}
                                  value={selectedCountry}
                                  options={countryListnew}
                                  autoHighlight
                                  disableClearable
                                  variant="standard"
                                  onChange={(_event, country) => {
                                    console.log("country", country);
                                    setSelectedCountry(country);
                                  }}
                                  getOptionLabel={(option) => {
                                    return (
                                      option.iso3 + " +" + option.phone_code
                                    );
                                  }}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                        width: "120px",
                                        "@media (max-width: 500px)": {
                                          width: "100px",
                                        },
                                      }}
                                      {...props}
                                    >
                                      {option.iso3} ({option.phone_code})
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country Code"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </>
                            )}
                            name="country_code"
                            defaultValue="91"
                          />
                        </FormControl>
                      </Box>
                      <Box
                        px={2}
                        sx={{
                          width: "150px",
                          paddingLeft: { md: "0px", xs: "15px" },
                          paddingBottom: { md: "0px", xs: "0px" },
                          "@media (max-width: 500px)": {
                            width: "120px",
                          },
                        }}
                      >
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Stack
                              sx={{ paddingTop: { md: "16px", xs: "0px" } }}
                            >
                              <CxoInputCss
                                label="Mobile No *"
                                value={value}
                                err={errors?.mobile ? errors?.mobile : false}
                                onChange={onChange}
                              />
                              <Box
                                sx={{
                                  fontSize: "9px",
                                  height: "10px",
                                  paddingLeft: "8px",
                                }}
                              >
                                {errors?.mobile?.message}{" "}
                              </Box>
                            </Stack>
                          )}
                          name="mobile"
                          required
                          rules={{
                            required: {
                              value: true,
                              message: "Mobile is required",
                            },
                          }}
                          defaultValue={
                            userDetails?.mobile ? userDetails?.mobile : ""
                          }
                        />
                      </Box> */}
                      <Box
                        pl={{ md: 0, xs: 0 }}
                        pb={0}
                        pr={4}
                        sx={{
                          paddingLeft: { md: '0px', xs: '15px' },
                          paddingBottom: { md: '0px', xs: '20px' },
                          width: '300px',
                        }}>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Stack>
                              <CxoInputCss
                                label="E-mail ID *"
                                isApplyJob={true}
                                value={value}
                                err={errors?.email ? errors?.email : false}
                                onChange={onChange}
                              />
                              <Box
                                sx={{
                                  fontSize: '9px',
                                  height: '10px',
                                  paddingLeft: '8px',
                                }}>
                                {errors?.email?.message}{' '}
                              </Box>
                            </Stack>
                          )}
                          name="email"
                          required
                          rules={{
                            required: {
                              value: true,
                              message: 'Email is required',
                            },
                          }}
                          defaultValue={
                            userDetails?.email ? userDetails?.email : ''
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          alignSelf: 'flex-start',
                          marginLeft: 'auto',
                          marginRight: '20px',
                          marginTop: '10px',
                        }}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Stack>

                    {/* <Box pl={4} pt={2} pb={{ md: 2, xs: 1 }}>
                      <Typography variant="h5s" component="h5s">
                        Write to the Hiring Manager
                      </Typography>
                    </Box> */}

                    {/* <Divider /> */}
                    <Box p={2}>
                      <CustomTextField
                        variant="outlined"
                        fullWidth
                        autoFocus
                        multiline
                        rows={8}
                        maxRows={10}
                        value={writeBody}
                        onChange={handleWrite}
                        className="scrollbar-hidden"
                        placeholder="Write to the Hiring Manager"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Box>
                    {/* <Divider /> */}

                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent="flex-start"
                      sx={{
                        '@media (min-width: 500px)': {
                          // paddingBottom: '20px',
                        },
                      }}>
                      <Stack sx={{}}>
                        <Box pl={4} pt={0} pb={{ md: 0, xs: 1 }}>
                          <Typography variant="h6s" component="h6s">
                            Last Salary Drawn
                          </Typography>
                        </Box>

                        <Stack
                          spacing={0}
                          direction="row"
                          sx={{ paddingLeft: '32px', gap: '20px' }}>
                          <Box
                            pt={0}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="salary" color="#38CD84" />
                              </Box>
                            </Box>
                          </Box>

                          <Box py={1} pl={1}>
                            <FormControl variant="standard" fullWidth>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <>
                                      <CssAutocomplete
                                        id="country-select-demo"
                                        sx={{
                                          width: 110,
                                          paddingRight: '5px',
                                          '@media (max-width: 500px)': {
                                            width: 70,
                                          },
                                        }}
                                        value={salaryinCrore}
                                        options={[...Array(10)].map(
                                          (_, i) => i,
                                        )}
                                        autoHighlight
                                        disableClearable
                                        variant="standard"
                                        onChange={(_event, sal) => {
                                          setSalaryInCrore(sal);
                                        }}
                                        renderOption={(props, option) => (
                                          <Box
                                            component="li"
                                            sx={{
                                              '& > img': {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                              width: '120px',
                                              '@media (max-width: 500px)': {
                                                width: '100px',
                                              },
                                            }}
                                            {...props}>
                                            {option}
                                          </Box>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="In Crores "
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                          />
                                        )}
                                      />
                                    </>
                                  )}
                                  name="country_code"
                                  defaultValue=""
                                />
                              </FormControl>
                            </FormControl>
                          </Box>

                          <Box py={1} pl={1}>
                            <FormControl variant="standard" fullWidth>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <>
                                      <CssAutocomplete
                                        id="country-select-demo"
                                        sx={{
                                          width: 110,
                                          paddingRight: '5px',
                                          height: 24,
                                          '@media (max-width: 500px)': {
                                            width: 90,
                                          },
                                        }}
                                        value={salaryinlacs}
                                        options={[...Array(100)].map(
                                          (_, i) => i,
                                        )}
                                        autoHighlight
                                        disableClearable
                                        variant="standard"
                                        onChange={(_event, sal) => {
                                          if (sal < 100) {
                                            setSalaryInlacs(sal);
                                          }
                                        }}
                                        renderOption={(props, option) => (
                                          <Box
                                            component="li"
                                            sx={{
                                              '& > img': {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                              width: '120px',
                                              '@media (max-width: 500px)': {
                                                width: '100px',
                                              },
                                            }}
                                            {...props}>
                                            {option}
                                          </Box>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="In lacs"
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                          />
                                        )}
                                      />
                                    </>
                                  )}
                                  name="salarythousand"
                                />
                              </FormControl>
                            </FormControl>
                          </Box>
                        </Stack>
                      </Stack>

                      <Box sx={{ marginLeft: '60px', marginTop: '10px' }}>
                        <Stack direction="row">
                          <Box>
                            <label htmlFor="icon-button-doc">
                              <Box
                                p={2}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}>
                                <AttachFileOutlinedIcon color="secondary" />
                              </Box>
                            </label>
                            <input
                              id="icon-button-doc"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={getDocument}
                              name="docum"
                              accept=".doc, .docx,.pdf,"
                            />
                          </Box>
                          <Box direction="column">
                            <Box py={1}>
                              <Typography variant="h6s" component="h6s">
                                Attach Resume* (Only pdf, doc, docx - Upto 2 MB
                                )
                              </Typography>
                            </Box>
                            <Box>
                              {name.length > 0 ? (
                                <Typography variant="h6" component="h6">
                                  {name}
                                </Typography>
                              ) : (
                                <Typography variant="h6" component="h6">
                                  No file attached
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: '45%',
                        marginBottom: '4px',
                        marginLeft: '20px',
                      }}>
                      <Typography
                        // variant="h6s"
                        // component="h6s"
                        style={{
                          color: 'gray',
                          fontSize: '8px',
                          lineHeight: '10px',
                        }}>
                        *The salary is disclosed to the hiring manager for the
                        jobs you apply to.
                      </Typography>
                      <Box>
                        <Typography
                          // variant="h6s"
                          // component="h6s"
                          style={{
                            color: 'gray',
                            fontSize: '8px',
                            lineHeight: '10px',
                          }}>
                          Providing salary information is mandatory to recommend
                          better job opportunities.
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      pb={2}
                      px={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}>
                      <CxoButton label="Apply" />
                    </Box>
                  </Box>
                </form>
              </Paper>
            </Box>
          </Box>
        </Backdrop>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobApply);
