import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
// import Carousel from "react-multi-carousel";
import AllenSoly from '../../resources/PartnerLogos/Allen Solly-01.png';
import Amazon from '../../resources/PartnerLogos/Amazon-01.png';
import Armani from '../../resources/PartnerLogos/Armani Exchange-01.png';
import Croma from '../../resources/PartnerLogos/Croma-01.png';
import Helios from '../../resources/PartnerLogos/Helios-01.png';
import Jaguar from '../../resources/PartnerLogos/Jaguar-01.png';
import Pvr from '../../resources/PartnerLogos/PVR-01.png';
import Taj from '../../resources/PartnerLogos/Taj-01.png';
import Vero from '../../resources/PartnerLogos/Vero Moda-01.png';
import Godrej from '../../resources/PartnerLogos/GodrejWhite.png';
import LanT from '../../resources/PartnerLogos/L&T-White.png';
import LandRover from '../../resources/PartnerLogos/LandRover-White.png';
import Lodha from '../../resources/PartnerLogos/Lodha-White.png';
import MediBuddy from '../../resources/PartnerLogos/MediBuddy-White.png';
import Oracle from '../../resources/PartnerLogos/Oracle-White.png';
import Puma from '../../resources/PartnerLogos/Puma-White.png';
import Raheja from '../../resources/PartnerLogos/Raheja-White.png';
import Vogue from '../../resources/PartnerLogos/Vogue.png';
import Wadhwa from '../../resources/PartnerLogos/Wadhwa-White.png';
import { useNavigate } from 'react-router-dom';

function RegiasterAsPartner() {
  const navigate = useNavigate();
  const redirectPartnerPage = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    navigate('/PartnerPage');
  };
  return (
    <>
      <Container fluid className="no-padding">
        <Row className="partner-logo-section-mob">
          {' '}
          <Col>
            <Row>
              <Col>
                {' '}
                <h2 className="interest-title">Partners</h2>
              </Col>
            </Row>{' '}
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={AllenSoly} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Amazon} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Pvr} className="logo_img1" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vero} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Jaguar} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Croma} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Armani} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Oracle} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={MediBuddy} className="logo_img" />
                </div>
              </Col>{' '}
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Godrej} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LanT} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LandRover} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Lodha} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={MediBuddy} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Oracle} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Puma} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vogue} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Raheja} className="logo_img1" />
                </div>
              </Col>{' '}
            </Row>
          </Col>
        </Row>{' '}
        <Row className="partner-logo-section">
          {' '}
          <Col>
            <Row>
              <Col>
                {' '}
                <h2 className="interest-title">Partners</h2>
              </Col>
            </Row>{' '}
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={AllenSoly} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Amazon} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Pvr} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vero} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Jaguar} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LanT} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Lodha} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Oracle} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={MediBuddy} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Croma} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Armani} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Taj} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Helios} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Godrej} className="logo_img1" />
                </div>
              </Col>
            </Row>
            <Row style={{ padding: '0px 50px 0px 100px' }}>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LandRover} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Puma} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Wadhwa} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Raheja} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vogue} className="logo_img" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row className="PartnerBtn-section">
          <Col sm={6}>
            {" "}
            <p className="partner-txt">Register to Parner with LinkCxO</p>
          </Col>
          <Col sm={6}>
            {" "}
            <button className="partner-btn" onClick={redirectPartnerPage}>
              Register
            </button>
          </Col>
        </Row>{" "} */}
      </Container>
    </>
  );
}

export default RegiasterAsPartner;
