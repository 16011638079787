import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';

function CompanyLogoPage2() {
  return (
    <>
      <Container fluid className="company-logo">
        <Row>
          <Col>
            {' '}
            <h2 className="color-green">LinkCxO Users From Top Companies</h2>
          </Col>
        </Row>
        <Row style={{ padding: '0px 100px ' }}>
          <Col>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Tata Teleservices Limited.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Disney Star India.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Dr Reddy's.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Ex Daimler India Commercial Vehicles Private Limited.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Future Group.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/GE%2C Aviation.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Hindustan Platinum.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/National Geographic.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Paytm.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/PwC.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/RB.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Reliance.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Schindler-Logo.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Shell.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Standard Chartered.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Trident Group.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/ICICI Bank.png"
                  />
                </div>
              </Col>

              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/John Deere.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Radio Mirchi (Times of India Group).png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Kotak Mahindra Bank Ltd.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Larsen %26 Toubro.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Mahindra %26 Mahindra.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/tata group.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Google.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/HCL.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Hero MOTOCORP.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Eaton Corporation.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/GMR Group.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Adani.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Tech Mahindra.png"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CompanyLogoPage2;
  