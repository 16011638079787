import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";

function Designation() {
  return (
    <>
      <Container fluid>
        <Row className="interest-section">
          <Col>
            <Row>
              <Col>
                <h2 className="interest-title">
                  Check if you are eligible to be a member of the LinkCxO
                  community
                </h2>
              </Col>
            </Row>
            <Row className="desigRow">
              <div className="green-box">
                <p className="designation">President</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Executive Officer</p>
              </div>

              <div className="green-box">
                <p className="designation">Chief Technology Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Entrepreneurs</p>
              </div>
              <div className="green-box">
                <p className="designation">Vice - President</p>
              </div>
              <div className="green-box">
                <p className="designation">Co - Founder</p>
              </div>
            </Row>
            <Row className="desigRow">
              <div className="green-box">
                <p className="designation">Country Head</p>
              </div>
              <div className="green-box">
                <p className="designation">Vertical Head</p>
              </div>
              <div className="green-box">
                <p className="designation">Investor</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Risk Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">General Managers</p>
              </div>
              <div className="green-box">
                <p className="designation">Directors </p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Investment Officer</p>
              </div>
            </Row>
            <Row className="desigRow">
              <div className="green-box">
                <p className="designation">Founder</p>
              </div>
              <div className="green-box">
                <p className="designation">Business Owners</p>
              </div>
              <div className="green-box">
                <p className="designation">Board Members</p>
              </div>
              <div className="green-box">
                <p className="designation">Advisors</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Engineering</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Diversity Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Financial Officer</p>
              </div>
            </Row>
            <Row className="desigRow">
              <div className="green-box">
                <p className="designation">Chief Sales Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Marketing Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Compliance Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Revenue Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Information Officer</p>
              </div>
            </Row>
            <Row className="desigRow">
              <div className="green-box">
                <p className="designation">Chief Operating Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Data Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Green Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Chief Digital Officer</p>
              </div>
              <div className="green-box">
                <p className="designation">Others</p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row
          className="interest-section-mobile"
          style={{ padding: "0px 10px" }}
        >
          <Col>
            <Row>
              <Col>
                <h2 className="interest-title" style={{ marginTop: "-30px" }}>
                  Check if you are eligible to be a member of the LinkCxO
                  community
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="green-box">
                  <p className="designation">President</p>
                </div>{" "}
                <div className="green-box">
                  <p className="designation">Vice - President</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Technology Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Entrepreneurs</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Executive Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Country Head</p>
                </div>{" "}
                <div className="green-box">
                  <p className="designation">Vertical Head</p>
                </div>
                <div className="green-box">
                  <p className="designation">Co - Founder</p>
                </div>{" "}
                <div className="green-box">
                  <p className="designation">Chief Risk Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Vertical Head</p>
                </div>{" "}
                <div className="green-box">
                  <p className="designation">Directors </p>
                </div>
                <div className="green-box">
                  <p className="designation">Investor</p>
                </div>
                <div className="green-box">
                  <p className="designation">General Managers</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Investment Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Founder</p>
                </div>
                <div className="green-box">
                  <p className="designation">Business Owners</p>
                </div>
                <div className="green-box">
                  <p className="designation">Board Members</p>
                </div>
                <div className="green-box">
                  <p className="designation">Advisors</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Engineering</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Diversity Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Financial Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Sales Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Marketing Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Compliance Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Revenue Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Information Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Operating Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Data Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Green Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Chief Digital Officer</p>
                </div>
                <div className="green-box">
                  <p className="designation">Others</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Designation;
