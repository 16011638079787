let _tokenResponse = null;
let _isLoggedIn = false;
let _userData = null;
let _pushNotification = true;

const loadFromStorage = () => {
  if (_isLoggedIn) return;

  try {
    _tokenResponse = JSON.parse(localStorage.getItem("tokenResponse"));
    _userData = JSON.parse(localStorage.getItem("userData"));
    if (_tokenResponse) {
      _isLoggedIn = true;
    }
  } catch (err) {}
};

export const clearAll = () => {
  localStorage.removeItem("tokenResponse");
  localStorage.removeItem("userData");
  localStorage.removeItem("persist:root");
  _tokenResponse = null;
  _isLoggedIn = false;
  _userData = null;
};

export const setTokenResponse = (tokenResponse) => {
  _tokenResponse = tokenResponse;
  _isLoggedIn = true;
  localStorage.setItem("tokenResponse", tokenResponse);
};

export const setUserData = (userData) => {
  _userData = userData;
  localStorage.setItem("userData", JSON.stringify(userData));
};

export const getUserData = () => {
  loadFromStorage();

  return JSON.stringify(localStorage.getItem("userData"));
};

export const getLoggedIn = () => _isLoggedIn;

export const hasPermission = (permission) => {
  return _userData.groups.find((group) => {
    return group.permissions.includes(permission);
  });
};

export const setPushTokenResponse = (tokenResponse) => {
  localStorage.setItem("pushtokenResponse", false);
};
export const getAccessToken = () => {
  loadFromStorage();

  return localStorage.getItem("tokenResponse");
};
// export const getRefreshToken = () => _tokenResponse.refresh_token;
export const getPushNotification = () => _pushNotification;
