import React, { Fragment, useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));

const CssSelect = styled(Select)(({ theme }) => {
  return {
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.palette.text.default,
      color: theme.palette.palette.text.primary,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const Settings = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [country, setCountry] = React.useState('91');
  const countryChange = (event) => {
    setCountry(event.target.value);
  };
  const user = useSelector((state) => state?.user?.UserDetails);

  const notificationList = useSelector(
    (state) => state?.message?.notificationList,
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log('useEffect 1');
  }, []);

  useEffect(() => {
    console.log('useEffect 2');
  }, []);

  useLayoutEffect(() => {
    console.log('useLayoutEffect 1');
  }, []);
  useLayoutEffect(() => {
    console.log('useLayoutEffect 2');
  }, []);
  console.log('rendering');
  return (
    <Fragment>
      <Box p={{ md: 2, xs: 0, sm: 2 }}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}>
              <Box
                py={2}
                px={{ md: 3, xs: 0, sm: 3 }}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  Notifications
                </Typography>
              </Box>
            </Stack>
          </Paper>

          <Paper
            sx={{
              marginTop: '8px',
              padding: '16px',
              backgroundColor: '#232121',
              backgroundImage: 'none',
              '@media (max-width: 500px)': {
                padding: '0px',
              },
            }}>
            {notificationList?.map((notification, index) => {
              if (
                notification.type === 'friend_accept' ||
                notification.type === 'job_share' ||
                notification.type === 'job_application' ||
                notification.type === 'job_post' ||
                notification.type === 'share_post' ||
                notification.type === 'like_post' ||
                notification.type === 'comment_post' ||
                notification.type === 'friend_request' ||
                notification.type === 'event_post' ||
                notification.type === 'event_invite' ||
                notification.type === 'event_share' ||
                notification.type === 'event_interest' ||
                notification.type === 'event_request_reject' ||
                notification.type === 'event_request_accept' ||
                notification.type === 'profile_view'
              ) {
                return <NotificationCard key={index} list={notification} />;
              }
            })}
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(Settings);
