import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../HomeHeader/navBar/navBar';
import Footer from '../HomeHeader/footer';
import axios from 'axios';
import config from '../../services/apiConfig';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import { makeStyles } from '@mui/styles';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import { styled } from '@mui/system';
import * as rewardsActions from '../../store/rewards';
import * as messageActions from '../../store/message';
import MyQuery from './MyQuery';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const MySurvey = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedForm, setSelectedForm] = useState(null);
  const [formData, setFormData] = useState([]);
  const user = useSelector((state) => state?.user?.UserDetails);
  const [value, setValue] = React.useState(0);
  const [queryParameters] = useSearchParams();
  const dispatch = useDispatch();

  const handleEditClick = (formData) => {
    setSelectedForm(formData);
  };

  const handleClosePopup = () => {
    setSelectedForm(null);
  };

  const navigateSurvey = (id) => {
    navigate('/response/survey/' + id);
  };

  const handleFormUpdate = async () => {
    try {
      const response = await axios.post(
        config?.serverBaseUrl + '/forms/get_form',
      );
      const responseData = response.data;
      const sortedData = responseData.data.sort((a, b) => b.formId - a.formId);
      setFormData(sortedData);
    } catch (error) {
      console.error('Error API:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          config?.serverBaseUrl + '/forms/get_form',
          {
            userId: user?.id,
            formType: 'survey',
          },
        );
        const responseData = response?.data?.data;
        // Sort the formData array by formId in descending order
        setFormData(responseData);
      } catch (error) {
        console.error('Error API:', error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(
  //         config?.serverBaseUrl + '/forms/get_form',
  //         {
  //           userId: user?.id,
  //           formType: 'query',
  //         },
  //       );
  //       const responseData = response.data;
  //       console.log(response.data, 'response.data');

  //       // Sort the formData array by formId in descending order
  //       const sortedData = responseData.data.sort(
  //         (a, b) => b.formId - a.formId,
  //       );

  //       setFormData(sortedData);
  //     } catch (error) {
  //       console.error('Error API:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);


  useEffect(() => {
    const fetchData = async () => {

    };

    fetchData();
  }, []);

  const handleChange = async (event, newValue) => {
    if (user?.isVerified) {
      if (newValue === 1) {
        navigate('/my-query');
        setValue(newValue);
      } else if (newValue === 0) {
        navigate('/my-survey');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 1) {
        navigate('/my-query');
        setValue(val);
      } else if (val === 0) {
        navigate('/my-survey');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <>
      <div style={{ padding: '10px 0px 50px' }}>
        <Box px={0} py={1}>
          <Tabs
            defaultValue={0}
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            color="white"
            aria-label="voucher">
            <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
              <StyledTabsList sx={{ padding: '8px 16px' }}>
                <StyledTab onClick={() => changeTab(0)}>
                  <Stack direction="row" spacing={0.5}>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabname}
                      sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                      Survey
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabdigit}
                      sx={{
                        color: value === 0 ? '#000000' : '#ffffff',
                      }}></Typography>
                  </Stack>
                </StyledTab>
                <StyledTab onClick={() => changeTab(1)}>
                  <Stack direction="row" spacing={0.5}>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabname}
                      sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                      Query
                    </Typography>
                    <Typography 
                      variant="h5"
                      component="h5"
                      className={classes.tabdigit}
                      sx={{
                        color: value === 1 ? '#000000' : '#ffffff',
                      }}></Typography>
                  </Stack>
                </StyledTab>
              </StyledTabsList>
            </Paper>
          </Tabs>
        </Box>

        {value === 1 && <MyQuery />}
        {value === 0 && <>
        {formData?.length === 0 ? (
          <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}>
              <Typography variant="h5" component="h5">
                            There is no survey
              </Typography>
          </Box>
        ) : (
          <>
            <Paper
              sx={{
                backgroundColor: '#232121',
                backgroundImage: 'none',
              }}></Paper>
            {value === 0 && (
              <>
                {formData?.map((formDataItem, index) => (
                  <>
                    <Stack
                      key={formDataItem?.formId}
                      pt={2}
                      spacing={0}
                      direction="column">
                      <Card
                        onClick={() => navigateSurvey(formDataItem?.formId)}
                        sx={{
                          minWidth: 272,
                          width: '100%',
                          backgroundColor: '#272727',
                          '@media (max-width: 500px)': {
                            minWidth: 190,
                          },
                        }}>
                        <Stack direction="row" justifyContent="space-between">
                          <Stack direction="column">
                            <CardHeader
                              sx={{ paddingBottom: '0px' }}
                              title={
                                <Stack direction="row" spacing={1}>
                                  <Typography variant="h5s" component="h5s">
                                    {formDataItem?.formTitle}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    component="h6"
                                    sx={{
                                      fontFamily: 'Open Sans',
                                      paddingTop: '3px',
                                      color: 'rgba(255, 255,255,0.5)',
                                    }}></Typography>
                                </Stack>
                              }
                              subheader={<Box></Box>}
                            />

                            <CardContent
                              sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
                              <Typography
                                variant="h6"
                                component="h6"
                                sx={{ lineHeight: '1.4em' }}>
                                {formDataItem?.formDescription?.length > 80
                                  ? formDataItem?.formDescription?.substring(
                                      0,
                                      80,
                                    )
                                  : formDataItem?.formDescription}
                              </Typography>
                            </CardContent>

                            <CardActions>
                              <Box px={1.4}>
                                <Typography
                                  variant="h6s"
                                  component="h6s"></Typography>
                              </Box>
                            </CardActions>
                          </Stack>
                        </Stack>
                      </Card>
                    </Stack>
                  </>
                ))}
              </>
            )}
            
          </>
        )}
        </>
}
      </div>
    </>
  );
};

const EditSurvey = () => {
  return (
    <>
      <MySurvey />
    </>
  );
};

export default MySurvey;
