import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import CommentCard from '../CommentCard/CommentCard';
import CommentReply from '../CommentCard/CommentReply';
import CommentSkeleton from '../CommentCard/CommentSkeleton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as articleActions from '../../store/article';
import * as activityActions from '../../store/activity';
import * as messageActions from '../../store/message';
import { dateTime } from '../../constants/app';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BorderLinearProgresssecond = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#38cd84' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 400],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#656363' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 50 }}>
        {props.ischecked ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              color: '#38cd84',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        )}
      </Box>
      <Box sx={{ minWidth: 60, width: '100%', mr: 1 }}>
        {props.ischecked ? (
          <BorderLinearProgresssecond
            variant="determinate"
            color="secondary"
            {...props}
          />
        ) : (
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            {...props}
          />
        )}
      </Box>
    </Box>
  );
}

export default function CardCompany(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const [comments, setComment] = React.useState([]);
  const [postcomment, setPostComment] = React.useState('');
  const [pollvalue, setPollValue] = React.useState('');
  const [voted, setVoted] = React.useState(false);
  const [trim, setTrim] = React.useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  // pdf
  const seeMore = () => {
    setTrim(!trim);
  };
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const commentloading = useSelector((state) => state?.post?.commentloading);
  const commentpostid = useSelector((state) => state?.post?.commentpostid);
  const postEndindex = useSelector((state) => state?.post?.postEndIndex);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const clicks = useSelector((state) => state?.message?.noOfClicks);

  useEffect(() => {
    if (props?.post?.type === 'poll') {
      props?.post?.pollOption?.some(function (el) {
        if (el.isChecked) {
          setPollValue(el.id);
          setVoted(true);
        }
      });
    }
  }, []);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handlePoll = async (event) => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        poll_id: props?.post?.post_id,
        option_id: parseInt(event.target.value),
        undo: false,
        datetime: dateTime(),
      };

      if (!voted) {
        setPollValue(event.target.value);
        const votepoll = await dispatch(postActions.postVotePoll(data));
        props.updatePost();
        setVoted(true);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  let subheader = props?.post?.designation + ' || ' + props?.post?.company_name;
  let docment =
    'https://docs.google.com/viewer?url=' +
    props?.post?.document +
    '&embedded=true';
  const likePost = async () => {
    if (user?.isVerified) {
      const postlike = await dispatch(
        postActions.postPostLike({
          content_id: props?.post?.post_id,
          type: props?.post?.type,
          from_user_id: user?.id,
          to_user_id: props?.post?.user_id,
          user_type: 'company_page',
          datetime: dateTime(),
        }),
      );
      props.updatePost();
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const sendComment = (event) => {
    setPostComment(event.target.value);
  };
  const deleteComment = async (id) => {
    const data = {
      user_id: user.id,
      id: id,
    };
    const deletecomment = await dispatch(postActions.getPostComments(data));
  };
  const commentPost = async (type) => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: 'post',
        content_id: props?.post.post_id,
        datetime: dateTime(),
      };
      if (type === 'postcomment') {
        setExpanded(true);
        if (expanded) {
          const commentpost = await dispatch(postActions.getPostComments(data));
          console.log('commentpost', commentpost?.data);
          setComment(commentpost?.data);
        }
      }
      if (type === 'toggle') {
        setExpanded(!expanded);
        if (!expanded) {
          const commentpost = await dispatch(postActions.getPostComments(data));
          setComment(commentpost?.data);
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const deleteCommentPost = async (data) => {
    const deletecmmnt = await dispatch(postActions.deleteComment(data));
    const userdata = {
      user_id: user.id,
      type: 'post',
      content_id: props?.post.id,
      datetime: dateTime(),
    };
    props.updatePost();
    const commentpost = await dispatch(postActions.getPostComments(userdata));
    setComment(commentpost?.data);
  };
  const bookmarkPost = async () => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: props?.post.type,
        content_id: props?.post.id,
        datetime: dateTime(),
      };
      const bookpost = await dispatch(activityActions.saveBookmark(data));
      setAnchorEl(!anchorEl);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const reportPost = async () => {
    if (user?.isVerified) {
      const reportpost = await dispatch(
        postActions.openReportPost({ post: props.post, type: 'report' }),
      );
      setAnchorEl(!anchorEl);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const hidePost = async () => {
    if (user?.isVerified) {
      const data = {
        content_id: props?.post?.id,
        status: 'hide',
        user_id: user?.id,
        message: '',
        datetime: dateTime(),
        aReport_field: '',
      };
      const submitReport = await dispatch(postActions.contentAction(data));
      const data1 = {
        user_id: user?.id,
        start_item: 0,
        item_count: postEndindex,
        datetime: dateTime(),
      };

      const formData1 = new FormData();
      formData1.append('user_id', user?.id);
      formData1.append('start_item', 0);
      formData1.append('item_count', postEndindex);
      formData1.append('datetime', dateTime());

      const postlist = await dispatch(
        postActions.getPostList(formData1, data1),
      );

      setAnchorEl(!anchorEl);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const deletePost = async () => {
    if (user?.isVerified) {
      const hidepost = await dispatch(
        postActions.openUpdatePost({ post: props.post, type: 'delete' }),
      );

      const data = {
        user_id: user?.id,
        start_item: 0,
        item_count: postEndindex,
        datetime: dateTime(),
      };

      const formData1 = new FormData();
      formData1.append('user_id', user?.id);
      formData1.append('start_item', 0);
      formData1.append('item_count', postEndindex);
      formData1.append('datetime', dateTime());

      const postlist = await dispatch(postActions.getPostList(formData1, data));
      setAnchorEl(false);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const editPost = async () => {
    if (user?.isVerified) {
      if (props?.post.type === 'post') {
        const hidepost = await dispatch(
          postActions.openUpdatePost({ post: props.post, type: 'edit' }),
        );
      } else if (props?.post.type === 'article') {
        const hidepost = await dispatch(
          articleActions.openUpdateArticle({ post: props.post, type: 'edit' }),
        );
      }
      setAnchorEl(false);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const sharePost = async () => {
    if (user?.isVerified) {
      const sharepost = await dispatch(
        postActions.openSharePost({ post: props.post }),
      );
      setAnchorEl(false);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const updateComment = async (savecomment, id) => {
    if (user?.isVerified) {
      const data = {
        user_id: user?.id,
        type: 'post',
        content_id: props?.post?.post_id,
        content: savecomment,
        to_user_id: props?.post?.user_id,
        created_datetime: dateTime(),
        id: id,
      };
      const commentpost = await dispatch(postActions.manageComment(data));
      commentPost('postcomment');
      const userdata = {
        user_id: user.id,
        type: 'post',
        content_id: props?.post.post_id,
        datetime: dateTime(),
      };
      props.updatePost();
      const commentposts = await dispatch(
        postActions.getPostComments(userdata),
      );
      setComment(commentposts?.data);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const postComment = async (postcomment) => {
    if (user?.isVerified) {
      const data = {
        user_id: user?.id,
        type: 'post',
        content_id: props?.post?.post_id,
        content: postcomment,
        to_user_id: props?.post?.user_id,
        created_datetime: dateTime(),
        id: 0,
      };
      const commentpost = await dispatch(postActions.manageComment(data));
      commentPost('postcomment');
      props.updatePost();
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const timeago = () => {
    if (props?.post?.created_datetime === 'Invalid date') {
      return '';
    } else {
      return moment(props?.post?.new_created_datetime1).fromNow();
    }
  };
  const validityleftt = () => {
    return props?.post?.pollRemainingTime;
  };
  const vadityleft = () => {
    console.log('vadityleft', props?.post);
    if (!props?.post?.is_poll_expired) {
      let deadline = moment(props?.post?.created_datetime)
        .add(props?.post?.validity, 'd')
        .format('YYYY-MM-DD HH:mm:ss');
      var b = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); //now
      var a = moment(deadline);
      let days = a.diff(b, 'days');
      let hr = a.diff(b, 'hours');
      let minutes = a.diff(b, 'minutes');
      if (days > 0) return days + ' days left';
      else if (days == 0 && hr > 0) {
        return hr + ' hours left';
      } else if (days == 0 && hr == 0) {
        return minutes + ' minutes left';
      }
    } else {
      return '';
    }

    // if (props?.post?.validity === 1 && !props?.post?.is_poll_expired) {
    //   let deadline = moment(props?.post?.created_datetime)
    //     .add(props?.post?.validity, 'd')
    //     .format('YYYY-MM-DD HH:mm:ss');
    //   let ms = moment(deadline, 'DD/MM/YYYY HH:mm:ss').diff(
    //     moment(new Date(), 'DD/MM/YYYY HH:mm:ss'),
    //   );
    // let d = moment.duration(ms).asDays();
    // // let s = d.format("hh:mm:ss");
    // let given = moment("26/03/2022 ", "DD/MM/YYYY HH:mm:ss");
    // let current =moment(new Date() ).format('DD/MM/YYYY HH:mm:ss');
    //
    // var now = moment(new Date()); //todays date
    // var end = moment("2022-03-26"); // another date
    // var duration = moment.duration(end.diff(now));
    // var days = duration.asDays();
    // console.log('days',days)
    // console.log('validity', deadline, ms,   d, current);
    //   console.log('deadline', deadline);
    //   var b = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); //now
    //   var a = moment(deadline);

    //   console.log(a.diff(b, 'minutes')); // 44700
    //   console.log(a.diff(b, 'hours')); // 745
    //   console.log(a.diff(b, 'days')); // 31
    //   console.log(a.diff(b, 'weeks')); // 4

    //   console.log(
    //     'validity left',
    //     props?.post?.id,
    //     moment().diff(props?.post?.created_datetime, 'days'),
    //   );
    //   return props?.post?.validity - +' day left';
    // } else if (props?.post?.validity > 1) {
    //   return props?.post?.validity + ' days left';
    // } else {
    //   return '';
    // }
  };
  const onError = (event) => {
    console.log('event', event);
  };
  const file =
    'https://emailmarketingmohanta.s3.ap-south-1.amazonaws.com/2022-03-31T05-17-40(2).pdf';
  const type = 'pdf';
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const profileOpen = async () => {
    debugger;
    if (user?.isVerified) {
      const profile =
        props?.post?.first_name +
        '-' +
        props?.post?.last_name +
        '-' +
        'lx' +
        props?.post?.user_id;
      console.log('profile', profile);
      navigate('/user/' + profile);
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const dropDown = () => {
    if (
      user?.id == parseInt(props?.post?.user_id) &&
      props?.post?.type != 'poll'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={editPost}>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={deletePost}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (
      user?.id == props?.post?.user_id &&
      props?.post?.type === 'poll'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={deletePost}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (user?.id != props?.post?.user_id) {
      // && props?.post?.type != "poll"
      return (
        <Box>
          <List>
            <MenuItem
              button
              sx={{ padding: '2px 12px' }}
              onClick={bookmarkPost}>
              <ListItemText>Bookmarks</ListItemText>
            </MenuItem>

            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={hidePost}>
              <ListItemText>Hide</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={reportPost}>
              <ListItemText>Report</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    }
  };
  return (
    <Card
      sx={{
        backgroundColor: '#252525',
        backgroundImage: 'none',
        position: 'relative',
      }}>
      <CardHeader
        avatar={
          <Avatar
            onClick={() => profileOpen()}
            src={props?.post?.image ? props?.post?.image : PersonIcon}
            sx={{ width: 48, height: 48, cursor: 'pointer' }}
          />
        }
        action={
          <Stack
            spacing={1}
            direction="row"
            sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label="settings" onClick={handleMobileMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            {anchorEl && (
              <Box sx={{ marginLeft: '0px!important' }}>
                <Box
                  anchorEl={anchorEl}
                  id="primary-search-account-menu"
                  keepMounted
                  sx={{
                    marginTop: '18px',
                    scrollbarWidth: 'thin',
                    position: 'absolute',
                    backgroundColor: '#252525',
                    height: 'auto',
                    width: '129px',
                    marginLeft: '-129px',
                    zIndex: '1000',
                    boxShadow:
                      '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                    borderRadius: '2px',
                  }}
                  open={anchorEl}
                  onClose={handleMenuClose}>
                  {dropDown()}
                </Box>
              </Box>
            )}
          </Stack>
        }
        title={
          <Stack direction="row" spacing={1}>
            <Typography variant="h5s" component="h5s">
              {props?.post?.name}
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontFamily: 'Open Sans',
                paddingTop: '3px',
                color: 'rgba(255, 255,255,0.5)',
              }}>
              {timeago()}
            </Typography>
          </Stack>
        }
        subheader={
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontSize: '13px',
              paddingTop: '3px',
              color: 'rgba(255, 255,255,0.5)',
            }}>
            {props?.post?.industry_title}
          </Typography>
        }
      />

      {props?.post?.type === 'article' && (
        <CardContent sx={{ paddingTop: '8px', paddingBottom: '4px' }}>
          <Box>
            <Typography
              variant="h5s"
              color="secondary"
              sx={{
                paddingBottom: '8px',
                fontSize: '16px',
                fontWeight: '600',
              }}>
              {props?.post?.heading}
            </Typography>
          </Box>
        </CardContent>
      )}
      {props?.post?.content ? (
        <CardContent sx={{ paddingTop: '8px' }}>
          <Typography
            variant="h2"
            color="text.secondary"
            sx={{
              color: 'rgba(255, 255,255,1)',
              whiteSpace: 'pre-line',
              lineHeight: '21px',
              letterSpacing: '1.2',
            }}>
            {props?.post?.content?.length > 410 && trim
              ? props?.post?.content?.substring(0, 410)
              : props?.post?.content}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props?.post?.content?.length > 410 && trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',
                  marginTop: '-20px',
                }}
                onClick={seeMore}>
                {' '}
                ...See more
              </Box>
            ) : (
              ' '
            )}
            {props?.post?.content?.length > 410 && !trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',
                  marginTop: '-20px',
                }}
                onClick={seeMore}>
                {' '}
                ...See less
              </Box>
            ) : (
              ''
            )}
          </Box>
        </CardContent>
      ) : (
        ''
      )}
      {props?.post?.type === 'poll' ? (
        <CardContent sx={{ paddingTop: '8px', paddingBottom: '4px' }}>
          <Box px={2}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: 'rgba(255, 255,255,1)',
                    paddingBottom: '8px',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}>
                  {props?.post?.heading}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
                value={pollvalue}
                onChange={handlePoll}
                color="secondary">
                {props?.post?.pollOption?.map((poll, index) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{ width: '100%' }}>
                      {props?.post?.is_poll_expired ? (
                        <FormControlLabel
                          key={index}
                          value={poll?.id}
                          control={<Box pl={1} />}
                          label={
                            poll?.isChecked ? (
                              <Typography variant="h5s" component="h5s">
                                {poll?.options}
                              </Typography>
                            ) : (
                              <Typography variant="h5" component="h5">
                                {poll?.options}
                              </Typography>
                            )
                          }
                        />
                      ) : (
                        <FormControlLabel
                          key={index}
                          value={poll?.id}
                          control={<Radio size="small" color="secondary" />}
                          label={
                            poll?.isChecked ? (
                              <Typography variant="h5s" component="h5s">
                                {poll?.options}
                              </Typography>
                            ) : (
                              <Typography variant="h5" component="h5">
                                {poll?.options}
                              </Typography>
                            )
                          }
                        />
                      )}

                      {voted && (
                        <Box sx={{ maxWidth: 200, width: '100%' }}>
                          <LinearProgressWithLabel
                            value={poll?.vote}
                            ischecked={poll?.isChecked}
                            color="secondary"
                          />
                        </Box>
                      )}
                    </Stack>
                  );
                })}
              </RadioGroup>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    maxWidth: 200,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      paddingBottom: '8px',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    {vadityleft()}
                  </Typography>
                </Box>
              </Box>
            </FormControl>
          </Box>
        </CardContent>
      ) : (
        ''
      )}

      {props?.post?.document_name && props?.post?.document_name !== 'image' && (
        <Box px={2} py={2}>
          <Typography variant="h6s" component="h6s">
            {props?.post?.document_name ? props?.post?.document_name : ''}
          </Typography>
        </Box>
      )}

      {props?.post?.document && !props?.post?.post_image && (
        <>
          {/*  // <Pdf file={pdf} page={page} /> */}
          <iframe
            width="100%"
            height="500px"
            src={docment}
            frameBorder="0"></iframe>
          {/*
<PDFViewer url={pdf} />

        <Pdf file={pdf}  page={page}>
      {({ pdfDocument, pdfPage, canvas }) => (
        <>
          {!pdfDocument && <span>Loading...</span>}
          {canvas}
          {Boolean(pdfDocument && pdfDocument.numPages) && (
            <nav>
              <ul className="pager">
                <li className="previous">
                  <button
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous
                  </button>
                </li>
                <li className="next">
                  <button
                    disabled={page === pdfDocument.numPages}
                    onClick={() => setPage(page + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </>
      )}
    </Pdf>
*/}

          {/*    <div>
         <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
           <Page pageNumber={pageNumber} />
         </Document>
         <p>
           Page {pageNumber} of {numPages}
         </p>
       </div>
*/}
        </>
      )}

      {props?.post?.post_image ? (
        <CardMedia
          component="img"
          sx={{ maxHeight: '45vh', width: 'auto', margin: 'auto' }}
          image={props?.post?.post_image}
          alt=" "
        />
      ) : (
        ''
      )}

      {/* {props?.post?.aChildPost &&
        Object.keys(props?.post?.aChildPost).length != 0 && (
          <CardLongShare
            post={props.post.aChildPost}
            clubDetails={props?.post?.clubDetails}
          />
        )} */}

      <Divider />
      <CardActions disableSpacing>
        {props?.post?.type != 'poll' ? (
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={likePost}>
                <IconButton
                  aria-label="applause"
                  sx={{
                    width: '40px',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="applause"
                      color={
                        props?.post?.liked_by_me == 0 ? '#ffffff' : '#38cd84'
                      }
                    />
                  </Box>
                </IconButton>
                <Typography
                  variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                  component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                  {props?.post?.like_count ? props?.post?.like_count : 0}{' '}
                  Applauses
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => commentPost('toggle')}>
                <IconButton aria-label="share">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '16px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="comment"
                      color={
                        props?.post?.comment_count > 0 ? '#38cd84' : '#ffffff'
                      }
                    />
                  </Box>
                </IconButton>

                <Stack direction="row" spacing={0.5}>
                  {' '}
                  <Typography
                    variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    {props?.post?.comment_count
                      ? props?.post?.comment_count
                      : 0}{' '}
                  </Typography>{' '}
                  <Typography
                    variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    Comments
                  </Typography>
                </Stack>

                {/* <Typography variant="h6" component="h6">
                  {props?.post?.comment_count ? props?.post?.comment_count : 0}{' '}
                  Comments
                </Typography> */}
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={likePost}>
                <IconButton
                  aria-label="applause"
                  sx={{
                    width: '40px',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="applause"
                      color={
                        props?.post?.liked_by_me == 0 ? '#ffffff' : '#38cd84'
                      }
                    />
                  </Box>
                </IconButton>
                <Typography
                  variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                  component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                  {props?.post?.like_count ? props?.post?.like_count : 0}{' '}
                  Applauses
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => commentPost('toggle')}>
                <IconButton aria-label="share">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '16px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="comment"
                      color={
                        props?.post?.comment_count > 0 ? '#38cd84' : '#ffffff'
                      }
                    />
                  </Box>
                </IconButton>
                <Stack direction="row" spacing={0.5}>
                  {' '}
                  <Typography
                    variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    {props?.post?.comment_count
                      ? props?.post?.comment_count
                      : 0}{' '}
                  </Typography>{' '}
                  <Typography
                    variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    Comments
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box>
          <CommentReply postComment={postComment} />
        </Box>
        <CardContent>
          {commentloading && (
            <Box>
              <CommentSkeleton
                commentpostid={commentpostid}
                postid={props?.post?.id}
              />
            </Box>
          )}
          {comments &&
            comments
              ?.slice(0)
              .reverse()
              .map((comment, index) => {
                return (
                  <CommentCard
                    deleteComment={deleteCommentPost}
                    updateComment={updateComment}
                    key={index}
                    comment={comment}
                  />
                );
              })}
        </CardContent>
      </Collapse>
    </Card>
  );
}
