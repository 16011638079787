import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useStyles from './networkcardstyle';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
// import corporate from '../../assets/home/Memberships.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as messageActions from '../../store/message';
import * as rewardsActions from '../../store/rewards';
import * as companyActions from '../../store/company';
import Icon from '../../assets/icon';
import MenuIcon from '../../assets/menuicon';
import EventsIcon from '../../assets/eventsicon';
import { useNavigate, useLocation } from 'react-router-dom';

export default function CompanyPageCard(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [allCounter, setAllCounter] = React.useState([]);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const navigate = useNavigate();

  useEffect(async () => {
    const data = {
      user_id: props?.userlist?.id,
    };

    // const mutualfriend = await dispatch(usernetworkActions.discoverUserNetwork(data));
    // if(mutualfriend.Status === 1){
    //   setMutualFriend(mutualfriend?.data)
    // }
    // console.log('mutualfriend',user.id,props?.userlist?.id, mutualfriend);
  }, []);

  useEffect(async () => {
    const data = {
      company_page_id: props?.company?.id,
    };

    const noofcounter = await dispatch(
      companyActions.companyAdditionalDetails(data),
    );
    if (noofcounter.status === 1) {
      setAllCounter(noofcounter?.data[0]);
    }
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const companyAcess = async (e) => {
    e.stopPropagation();
    const data = {
      company_page_id: props?.company?.id,
    };

    const accessList = await dispatch(
      companyActions.getCompanyMembershipAccessList(data),
    );

    if (accessList?.data?.length > 0) {
      navigate(
        '/company/manage/aceess/' +
          props?.company?.name +
          '-lx' +
          props?.company?.id,
      );
    } else {
      const openAccess = await dispatch(
        companyActions.openCompanyAccess(props?.company),
      );
    }
  };

  const companyDetails = async (e) => {
    e.stopPropagation();
    navigate(
      '/company/manage/posts/details/' +
        props?.company?.name +
        '-lx' +
        props?.company?.id,
    );
    // navigate(
    //   '/company/details/' + props?.company?.name + '-lx' + props?.company?.id,
    // );
  };

  const companyEdit = async (e) => {
    e.stopPropagation();
    const data = {
      company_page_id: props?.company?.id,
    };
    const candidate = await dispatch(companyActions.companyDetails(data));
    navigate(
      '/company/edit/' + props?.company?.name + '-lx' + props?.company?.id,
    );
  };

  const membershipPackageDetails = async () => {
    if (props?.type == 'customize') {
      const customisePackage = await dispatch(
        rewardsActions.customCorporatePackageOpen(),
      );
    } else {
      navigate(
        '/corporate/packages/group/membership/' + props?.membership?.type,
      );
    }
  };

  const profileOpen = async () => {};
  if (props?.type == 'customize') {
    return (
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '171px',
          width: '100%',
        }}
        onClick={membershipPackageDetails}>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
            width: '30%',
            maxWidth: { xs: 350, md: 250 },
            padding: '0px',
            objectFit: 'cover',
            backgroundColor: '#333',
            margin: '0px 0px 0px 0px',
            cursor: 'pointer',
          }}
          alt="">
          <Stack
            direction="column"
            sx={{ width: '100%', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#333',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 'min-content',
                }}>
                <img
                  alt="Membership"
                  src="https://cv-exotalent.s3.ap-south-1.amazonaws.com/Membership.png"
                  style={{
                    height: '171px',
                    objectFit: 'cover',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Box>

        <Stack
          direction="column"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '16px',
            width: '60%',
          }}>
          <Box
            pt={1}
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              justifyContent: 'flex-start',
            }}>
            {' '}
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '20px',
                lineHeight: '20px',
              }}>
              {' '}
              Customise For Your Team
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 400,
              }}>
              {props?.membership?.quantity
                ? 'Min. Purchase of ' + props?.membership?.quantity + ' Members'
                : ''}
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6">
              Be a part of CxO communities
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6"></Typography>
          </Box>
          <CardActions sx={{ width: '100%', padding: '0 !important' }}>
            <Stack direction="column" sx={{ width: '100%' }}>
              <Box>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '24px',
                    lineHeight: '24px',
                  }}>
                  {' '}
                </Typography>
              </Box>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                <Box pb={0}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '24px',
                      lineHeight: '24px',
                    }}>
                    {' '}
                  </Typography>
                </Box>
                <Box
                  pb={1}
                  pr={1}
                  sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <CxoButtonWithIcon
                    label={
                      props?.type == 'customize' ? 'Get In Touch' : 'Access'
                    }
                    onClick={companyAcess}
                  />
                </Box>
              </Stack>
            </Stack>
          </CardActions>
        </Stack>
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '171px',
          width: '100%',
        }}
        onClick={companyDetails}>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
            width: '30%',
            maxWidth: { xs: 350, md: 250 },
            padding: '0px',
            objectFit: 'cover',
            backgroundColor: '#333',
            margin: '0px 0px 0px 0px',
            cursor: 'pointer',
          }}
          alt="">
          <Stack
            direction="column"
            sx={{ width: '100%', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#333',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 'min-content',
                }}>
                <img
                  alt="Company"
                  src={props?.company?.image}
                  style={{
                    height: '171px',
                    objectFit: 'cover',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Box>

        <Stack
          direction="column"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '16px',
            width: '60%',
          }}>
          <Box
            pt={1}
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              justifyContent: 'flex-start',
            }}>
            {' '}
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '20px',
                lineHeight: '20px',
              }}>
              {' '}
              {props?.company?.name}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 400,
              }}></Typography>
          </Box>
          <Box pb={1}>
            <Stack direction="row" spacing={4}>
              <Stack direction="column" spacing={2}>
                <Stack spacing={1} direction="row" pt={2}>
                  <Box
                    pl={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '20px',
                        maxWidth: 'min-content',
                        minWidth: '20px',
                      }}>
                      <MenuIcon name="article" width="20" color="#ffffff" />
                    </Box>
                  </Box>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontSize: '14px',
                      paddingLeft: '8px',
                      paddingTop: '4px',
                    }}>
                    {allCounter?.total_posts}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: '14px', paddingTop: '4px' }}>
                    {allCounter?.total_posts > 1 ? 'Posts' : 'Post'}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Box
                    pl={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                      }}>
                      <Icon name="jobs" width="24" color="#ffffff" />
                    </Box>
                  </Box>

                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontSize: '14px',
                      paddingLeft: '6px',
                      fontFamily: 'Open Sans',
                      paddingTop: '4px',
                    }}>
                    {allCounter?.total_jobs}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ fontSize: '14px', paddingTop: '4px' }}>
                    {allCounter?.total_jobs > 1 ? 'Jobs' : 'Job'}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Stack direction="column" spacing={2}>
                  <Stack spacing={1} direction="row" pt={2}>
                    <Box
                      pl={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Icon name="rewards_white" width="24" color="#ffffff" />
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                          fontSize: '14px!important',
                          fontFamily: 'Open Sans',
                          paddingLeft: '6px',
                          paddingTop: '4px',
                        }}>
                        {allCounter?.total_memberships}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ fontSize: '14px', paddingTop: '4px' }}>
                      {allCounter?.total_memberships > 1 ? 'Rewards' : 'Reward'}
                    </Typography>
                  </Stack>
                  <Stack spacing={1} direction="row">
                    <Box
                      pl={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <MenuIcon name="events" width="24" color="#ffffff" />
                      </Box>
                    </Box>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontSize: '14px',
                        paddingLeft: '6px',
                        fontFamily: 'Open Sans',
                        paddingTop: '4px',
                      }}>
                      {allCounter?.total_events}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ fontSize: '14px', paddingTop: '4px' }}>
                      {allCounter?.total_events > 1 ? 'Events' : 'Event'}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box pb={1}>
            <Stack direction="row" spacing="3"></Stack>
          </Box>
          <CardActions sx={{ width: '100%', padding: '0 !important' }}>
            <Stack direction="column" sx={{ width: '100%' }}>
              <Box sx={{ marginBottom: '16px' }}>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '24px',
                    lineHeight: '24px',
                  }}>
                  {' '}
                </Typography>
              </Box>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                <Box
                  pb={1}
                  pr={1}
                  sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <CxoButtonWithIcon
                    label={'Edit'}
                    onClick={(e) => companyEdit(e)}
                  />
                </Box>
                {/* <Box
                  pb={1}
                  pr={1}
                  sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <CxoButtonWithIcon
                    label={'Access'}
                    onClick={(e) => companyAcess(e)}
                  />
                </Box> */}
              </Stack>
            </Stack>
          </CardActions>
        </Stack>
      </Card>
    );
  }
}
