import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CxoButton from '../../components/CxoButton/CxoButton';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useParams } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import { dateTime } from '../../constants/app';
import EventsIcon from '../../assets/eventsicon';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import EventRequested from './EventRequested';
import EventInvited from './EventInvited';
import EventAttending from './EventAttending';

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const EventsInvitations = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const [value, setValue] = React.useState(0);
  const Requestedlist = useSelector(
    (state) => state?.events?.eventRequestedList,
  );
  const eventInvitedMembers = useSelector(
    (state) => state?.events?.eventInvitedMembers,
  );

  const eventAttendingMembers = useSelector(
    (state) => state?.events?.eventAttendingMembers,
  );

  const eventRequestMembers = useSelector(
    (state) => state?.events?.eventRequestMembers,
  );
  const eventDetails = useSelector((state) => state?.events?.eventDetails);
  const Invitedlist = useSelector((state) => state?.events?.eventInvitedList);

  const Attendinglist = useSelector(
    (state) => state?.events?.eventAttendingList,
  );
  const upcomingEventList = useSelector(
    (state) => state?.events?.eventUpcomingList,
  );

  useEffect(async () => {
    const data = {
      event_id: eventDetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventRequestedMembers(data));
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Box px={1} py={1}>
        <Tabs
          defaultValue={0}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          color="white"
          aria-label="ant example">
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            <StyledTabsList sx={{ padding: '8px 16px' }}>
              <StyledTab sx={{ width: '200px' }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      color: value === 0 ? '#000000' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '14px',
                      },
                    }}>
                    Requested {'(' + eventRequestMembers?.length + ')'}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 0 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </StyledTab>
              <StyledTab sx={{ width: '200px' }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      color: value === 1 ? '#000000' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '14px',
                      },
                    }}>
                    Invited {'(' + eventInvitedMembers?.length + ')'}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 1 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </StyledTab>
              <StyledTab sx={{ width: '200px' }}>
                <Stack direction="row" spacing={0.5}>
                  <Box>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabname}
                      sx={{
                        color: value === 2 ? '#000000' : '#ffffff',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Attending {'(' + eventAttendingMembers?.length + ')'}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabdigit}
                      sx={{
                        color: value === 2 ? '#000000' : '#ffffff',
                      }}></Typography>
                  </Box>
                </Stack>
              </StyledTab>
            </StyledTabsList>
          </Paper>

          <StyledTabPanel value={0}>
            <Grid container sx={{ paddingBottom: '8px' }}>
              <Box sx={{ width: '100%' }}>
                <EventRequested />
              </Box>
            </Grid>
          </StyledTabPanel>

          <StyledTabPanel value={1}>
            <Grid container sx={{ paddingBottom: '8px' }}>
              <Box sx={{ width: '100%' }}>
                <EventInvited />
              </Box>
            </Grid>
          </StyledTabPanel>
          <StyledTabPanel value={2} sx={{ paddingBottom: '8px' }}>
            <Grid container>
              <Box sx={{ width: '100%' }}>
                <EventAttending />
              </Box>

              <Box sx={{ width: '100%' }}></Box>
            </Grid>
          </StyledTabPanel>
        </Tabs>
      </Box>
    </Fragment>
  );
};

export default React.memo(EventsInvitations);
