import { API, apiUrl } from '../../services';
import { http, http_noauth } from '../../services/client';
import { showMessage } from '../message';
import {
  setUserData,
  setTokenResponse,
  getUserData as getLocalUserData,
  clearAll,
  getAccessToken,
} from '../../services/authData';

export const SEND_EMAIL_OTP_FULFILLED = 'SEND_EMAIL_OTP_FULFILLED';
export const SEND_EMAIL_OTP_PENDING = 'SEND_EMAIL_OTP_PENDING';
export const SEND_EMAIL_OTP_REJECTED = 'SEND_EMAIL_OTP_REJECTED';

export const LOGOUT_FULFILLED = 'LOGOUTFULFILLED';
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';
export const SIGNUP_FULFILLED = 'SIGNUPFULFILLED';
export const SIGNUP_PENDING = 'SIGNUP_PENDING';
export const SIGNUP_REJECTED = 'SIGNUP_REJECTED';

// const loginRequest = () => ({
//   type: LOGIN_PENDING,
// });

const sendEmailOtpRequest = () => ({
  type: SEND_EMAIL_OTP_PENDING,
});

const sendEmailOtpFailure = (error) => ({
  type: SEND_EMAIL_OTP_REJECTED,
  payload: {  loggedIn: true  },
});

const sendEmailOtpSuccess = (userData, email, type) => ({
  type: SEND_EMAIL_OTP_FULFILLED,
  payload: { userData, loggedIn: false, email: email, otptype: type },
});
// const loginFail = (userData) => ({
//   type: LOGIN_FULFILLED,
//   payload: { userData, loggedIn: false },
// });
// const loginCheck = (userData, loggedIn, pushNotification) => ({
//   type: LOGIN_PENDING,
//   payload: { userData, loggedIn, pushNotification, tryAllLogin: true },
// });

export const logoutSuccess = () => ({
  type: LOGOUT_FULFILLED,
  payload: { loggedIn: false },
});
export const logout = () => async (dispatch, getState) => {
  try {
    // await API.revokeToken();

    dispatch(logoutSuccess());
    clearAll();
  } catch (err) {}
};

export const checkAuth = () => async (dispatch, getState) => {
  // dispatch(loginCheck(getLocalUserData(), getLoggedIn(),getPushNotification()));
};

// const fetchUser = (data) => async (dispatch, getState) => {
//   try {
//     // const userData = await API.getUserData();
//     // setUserData(userData);
//     // dispatch(loginSuccess(userData));
//   } catch (err) {}
// };

export const sendEmailOtp = (payload, type) => {
  return (dispatch) => {
    dispatch(sendEmailOtpRequest());
    return http_noauth
      .post(apiUrl.POST_SEND_EMAIL_OTP, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(sendEmailOtpSuccess(response, payload.email, type));
          showMessage({
            dispatch,
            message: 'Otp send successfully',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(sendEmailOtpFailure(response));

          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        showMessage({
          dispatch,
          message: error?.message,
          variant: 'error',
        });
        dispatch(sendEmailOtpFailure(error));
        return error;
      });
  };
};

export const sendEmailOtpUpdate = (payload, type) => {
  return (dispatch) => {
    dispatch(sendEmailOtpRequest());
    return http
      .post(apiUrl.POST_SEND_OTP, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.data?.status === 1) {
          showMessage({
            dispatch,
            message: 'Otp send successfully',
            variant: 'success',
          });
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        showMessage({
          dispatch,
          message: error?.message,
          variant: 'error',
        });

        return error;
      });
  };
};

export const USER_PROFILE_DETAILS_FULFILLED = 'USER_PROFILE_DETAILS_FULFILLED';
export const USER_PROFILE_DETAILS_PENDING = 'USER_PROFILE_DETAILS_PENDING';
export const USER_PROFILE_DETAILS_REJECTED = 'USER_PROFILE_DETAILS_REJECTED';

const userProfileDetailsPending = () => ({
  type: USER_PROFILE_DETAILS_PENDING,
});

const userProfileDetailsFailure = (error) => ({
  type: USER_PROFILE_DETAILS_REJECTED,
  payload: { error },
});

const userProfileDetailsSuccess = (data) => {
  return {
    type: USER_PROFILE_DETAILS_FULFILLED,
    payload: data,
  };
};

export const userProfileDetails = (payload) => {
  return (dispatch) => {
    dispatch(userProfileDetailsPending());
    return http
      .post(apiUrl.USER_PROFILE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userProfileDetailsSuccess(response?.data));

          return response;
        } else {
          dispatch(userProfileDetailsFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userProfileDetailsFailure(error));
        showMessage({
          dispatch,
          message: 'error',
          variant: 'error',
        });
        return error;
      });
  };
};

export const sendEmailOtpold = (payload) => {
  return (dispatch) => {
    dispatch(sendEmailOtpRequest());
    return new Promise((resolve, reject) => {
      API.http_noauth.post(
        {
          endPoint: apiUrl.POST_SEND_EMAIL_OTP,
          body: payload,
        },
        (error, response) => {
          if (error) {
            // console.log(error);
            // const message = `${error.error || error.status || error.name}: ${
            //   error.message
            // }`;
            dispatch(sendEmailOtpFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(sendEmailOtpSuccess(response, payload.email));
              resolve(response);
            } else {
              dispatch(sendEmailOtpFailure(response));
              reject(response);
            }
          }
        },
      );
    });
  };
};

export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

const loginRequest = () => ({
  type: LOGIN_PENDING,
});
const loginFailure = (error) => ({
  type: LOGIN_REJECTED,
});

const loginSuccess = (userData, username, type) => ({
  type: LOGIN_FULFILLED,
  payload: {
    user: userData.data,
    token: userData.token,
    loggedIn: true,
    username: username,
    type: type,
  },
});

export const attemptLogin = (payload) => {
  return (dispatch) => {
    dispatch(loginRequest());
    var m = new Date();
    console.log('m', m);
    var dateString =
      m.getUTCFullYear() +
      '-' +
      (m.getUTCMonth() + 1) +
      '-' +
      m.getUTCDate() +
      ' ' +
      m.getUTCHours() +
      ':' +
      m.getUTCMinutes() +
      ':' +
      m.getUTCSeconds();
    console.log('dateString', dateString);

    const data = {
      username: payload.username,
      temp_user_id: 0,
      id: payload.id,
      login_sec_key:
        'gf32t684r63t46tbv762tb462v27!&@#d$2382y%^#^%^$^&^vevyjwg34676457634xwbgxvch**766bx7buegwbytvbytbcrvvbgvcbgcygwcg34^#$%BYBgVV;sdfg',
      datetime: dateString,
    };
    return http_noauth
      .post(apiUrl.POST_SIGN_IN, data)
      .then((response) => {
        console.log('login response', response);

        setTokenResponse(response.data.token);
        setUserData(response.data.user_details);

        dispatch(loginSuccess(response, payload.username, payload.type));
        return response;
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        dispatch(loginFailure(error));
        return error;
      });
  };
};

export const attemptLoginold = (payload) => {
  return (dispatch) => {
    dispatch(loginRequest());
    return new Promise((resolve, reject) => {
      var m = new Date();
      console.log('m', m);
      var dateString =
        m.getUTCFullYear() +
        '-' +
        (m.getUTCMonth() + 1) +
        '-' +
        m.getUTCDate() +
        ' ' +
        m.getUTCHours() +
        ':' +
        m.getUTCMinutes() +
        ':' +
        m.getUTCSeconds();
      console.log('dateString', dateString);
      const data = {
        username: payload.username,
        temp_user_id: 0,
        id: payload.id,
        login_sec_key:
          'gf32t684r63t46tbv762tb462v27!&@#d$2382y%^#^%^$^&^vevyjwg34676457634xwbgxvch**766bx7buegwbytvbytbcrvvbgvcbgcygwcg34^#$%BYBgVV;sdfg',
        datetime: dateString,
      };
      API.http_noauth.post(
        {
          endPoint: apiUrl.POST_SIGN_IN,
          body: data,
        },
        (error, response) => {
          if (error) {
            // console.log(error);
            // const message = `${error.error || error.status || error.name}: ${
            //   error.message
            // }`;
            dispatch(loginFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              setTokenResponse(response.data.token);
              setUserData(response.data.user_details);
              dispatch(loginSuccess(response, payload.username));
              showMessage({
                dispatch,
                message: response?.message,
                variant: 'success',
              });
              resolve(response);
            } else {
              showMessage({
                dispatch,
                message: response?.message,
                variant: 'error',
              });
              dispatch(loginFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const VERIFY_OTP_FULFILLED = 'VERIFY_OTP_FULFILLED';
export const VERIFY_OTP_PENDING = 'VERIFY_OTP_PENDING';
export const VERIFY_OTP_REJECTED = 'VERIFY_OTP_REJECTED';

const verifyOtpRequest = () => ({
  type: VERIFY_OTP_PENDING,
});

const verifyOtpFailure = (error) => ({
  type: VERIFY_OTP_REJECTED,
});

const verifyOtpSuccess = (userData) => ({
  type: VERIFY_OTP_FULFILLED,
  payload: { userData, loggedIn: true },
});

export const verifyOtp = (payload) => {
  return (dispatch) => {
    dispatch(verifyOtpRequest());
    return http_noauth
      .post(apiUrl.POST_SIGNUP_VERIFY, payload)
      .then((response) => {
        console.log('getArticlesList', response);

        if (response?.status === 1) {
          showMessage({
            dispatch,
            message: 'Otp Verified Successful',
            variant: 'success',
          });
          dispatch(verifyOtpSuccess(response));
          return response;
        } else {
          dispatch(verifyOtpFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);

        showMessage({
          dispatch,
          message: "Otp doesn't match",
          variant: 'error',
        });
        dispatch(verifyOtpFailure(error));
        return error;
      });
  };
};

export const verifyOtpold = (payload) => {
  return (dispatch) => {
    dispatch(verifyOtpRequest());
    return new Promise((resolve, reject) => {
      API.http_noauth.post(
        {
          endPoint: apiUrl.POST_SIGNUP_VERIFY,
          body: payload,
        },
        (error, response) => {
          if (error) {
            // console.log(error);
            // const message = `${error.error || error.status || error.name}: ${
            //   error.message
            // }`;
            dispatch(verifyOtpFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(verifyOtpSuccess(response));
              resolve(response);
            } else {
              dispatch(verifyOtpFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const signUp = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      API.http_noauth.post(
        {
          endPoint: apiUrl.POST_SIGN_UP,
          body: payload,
        },
        (error, response) => {
          if (error) {
            // console.log(error);
            // const message = `${error.error || error.status || error.name}: ${
            //   error.message
            // }`;

            reject(error);
          } else {
            if (response?.status === 1) {
              showMessage({
                dispatch,
                message: 'Sign Up Successful',
                variant: 'success',
              });
              resolve(response);
            } else {
              reject(response);
            }
          }
        },
      );
    });
  };
};

export const PROFILE_REQUIRED_FIELD_FULFILLED =
  'PROFILE_REQUIRED_FIELD_FULFILLED';
export const PROFILE_REQUIRED_FIELD_PENDING = 'PROFILE_REQUIRED_FIELD_PENDING';
export const PROFILE_REQUIRED_FIELD_REJECTED =
  'PROFILE_REQUIRED_FIELD_REJECTED';

const profileRequiredFieldRequest = () => ({
  type: PROFILE_REQUIRED_FIELD_PENDING,
});

const profileRequiredFieldFailure = (error) => ({
  type: PROFILE_REQUIRED_FIELD_REJECTED,
});

const profileRequiredFieldSuccess = (userData) => {
  if (userData?.status === 1) {
    return {
      type: PROFILE_REQUIRED_FIELD_FULFILLED,
      payload: {
        loading: false,
        loggedIn: true,
        addBio: false,
        addInterest: false,
      },
    };
  }
  if (userData?.data?.profile_about === 0) {
    return {
      type: PROFILE_REQUIRED_FIELD_FULFILLED,
      payload: {
        loading: false,
        loggedIn: true,
        addBio: true,
        addInterest: false,
      },
    };
  }
  if (userData?.data?.user_interest === 0) {
    return {
      type: PROFILE_REQUIRED_FIELD_FULFILLED,
      payload: {
        loading: false,
        loggedIn: true,
        addBio: false,
        addInterest: true,
      },
    };
  }
  if (userData?.status === -2) {
    return {
      type: PROFILE_REQUIRED_FIELD_FULFILLED,
      payload: {
        loading: false,
        loggedIn: true,
        addBio: false,
        addInterest: false,
      },
    };
  }
};

export const profileRequiredField = (payload) => {
  //var redirect_uri = "/" + window.location.href.split("redirect=")[1];
  //payload.redirect = redirect_uri;
  return (dispatch) => {
    dispatch(profileRequiredFieldRequest());
    return http
      .post(apiUrl.PROFILE_REQUIRED_FIELD, payload)
      .then((response) => {
        //debugger;
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(profileRequiredFieldSuccess(response));
          return response;
        } else {
          dispatch(profileRequiredFieldFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(profileRequiredFieldFailure(error));
        return error;
      });
  };
};

export const profileRequiredFieldold = (payload) => {
  return (dispatch) => {
    dispatch(verifyOtpRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      console.log('token', token);
      API.http.post(
        {
          endPoint: apiUrl.PROFILE_REQUIRED_FIELD,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            // console.log(error);
            // const message = `${error.error || error.status || error.name}: ${
            //   error.message
            // }`;
            console.log('error', error);
            dispatch(profileRequiredFieldFailure(error));
            resolve(error);
          } else {
            console.log('response', response);
            if (response?.status === 1) {
              dispatch(profileRequiredFieldSuccess(response));
              resolve(response);
            } else {
              dispatch(profileRequiredFieldFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const GET_LINKEDIN_DATA_FULFILLED = 'GET_LINKEDIN_DATA_FULFILLED';
export const GET_LINKEDIN_DATA_PENDING = 'GET_LINKEDIN_DATA_PENDING';
export const GET_LINKEDIN_DATA_REJECTED = 'GET_LINKEDIN_DATA_REJECTED';

const getLinkedInDataRequest = () => ({
  type: GET_LINKEDIN_DATA_PENDING,
});

const getLinkedInDataFailure = (error) => ({
  type: GET_LINKEDIN_DATA_REJECTED,
  payload: { error: error },
});

const getLinkedInDataSuccess = (data) => ({
  type: GET_LINKEDIN_DATA_FULFILLED,
  payload: { linkedIn: data },
});

export const getLinkedInData = (payload) => {
  return (dispatch) => {
    dispatch(getLinkedInDataRequest());
    return http
      .post(apiUrl.GET_LINKEDIN_DATA, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(getLinkedInDataSuccess(response));
          return response;
        } else {
          dispatch(getLinkedInDataFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        dispatch(getLinkedInDataFailure(error));
        return error;
      });
  };
};

export const GET_LINKEDIN_ACCESS_TOKEN_FULFILLED =
  'GET_LINKEDIN_ACCESS_TOKEN_FULFILLED';
export const GET_LINKEDIN_ACCESS_TOKEN_PENDING =
  'GET_LINKEDIN_ACCESS_TOKEN_PENDING';
export const GET_LINKEDIN_ACCESS_TOKEN_REJECTED =
  'GET_LINKEDIN_ACCESS_TOKEN_REJECTED';

const getLinkedInAcesstokenRequest = () => ({
  type: GET_LINKEDIN_ACCESS_TOKEN_PENDING,
});

const getLinkedInAcesstokenFailure = (error) => ({
  type: GET_LINKEDIN_ACCESS_TOKEN_REJECTED,
  payload: { error: error },
});

const getLinkedInAcesstokenSuccess = (data) => ({
  type: GET_LINKEDIN_ACCESS_TOKEN_FULFILLED,
  payload: { linkedIn: data },
});

export const getLinkedInAcesstoken = (payload) => {
  return (dispatch) => {
    dispatch(getLinkedInAcesstokenRequest());
    return http
      .post(apiUrl.GET_LINKEDIN_DATA, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(getLinkedInAcesstokenSuccess(response));
          return response;
        } else {
          dispatch(getLinkedInAcesstokenFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        dispatch(getLinkedInAcesstokenFailure(error));
        return error;
      });
  };
};

export const getLinkedInDataold = (payload) => {
  return (dispatch) => {
    dispatch(getLinkedInDataRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      console.log('interest token', token);
      API.http.post(
        {
          endPoint: apiUrl.GET_LINKEDIN_DATA,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getLinkedInDataFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getLinkedInDataSuccess(response));
              resolve(response);
            } else {
              dispatch(getLinkedInDataFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

// const checkMobileEmailLinkedin = async (user) => {
//   console.log('checkMobileEmailLinkedin', user);
//   const data = {
//     username: 'badal.b.murmu@gmail.com',
//   };
//   return checkMobileEmail(data);
//   // return http
//   //   .post(apiUrl.CHECK_USER, data)
//   //   .then((response) => {
//   //     console.log("checkUserName", response);
//   //     if (response?.status === 1) {
//   //
//   //       return response;
//   //     } else {
//   //
//   //       return response;
//   //     }
//   //   })
//   //   .catch((error) => {
//   //     console.log("getArticlesList error", error);
//   //
//   //     return error;
//   //   });
// };

// {
//  token: {
//    accessToken: 'AQXhZeKTlm7k401setPg4OJiyzWvsPo7mDgR4Ajjt_xjwM9nxTvxCoPy8mBC8JQ_oB_TkmMvJO9_K69OZw9LW-yvUMbwkf_P1R0bUUQdb8zjTC-wBkscsJDNPydSe9hkdVaAa9sh25R79Zj4Io2h3e2v4fP4NA8rJ2B3OkSE_RcapAkCnB8mFecU_3kxGQXre_fZOLWQrgPv_F8zZv5Gbl26M6qNLCsHlNBPQ8svlcZAcKpwtm-WPFOZJPDXvR3LaeO84moJF6csLYIWw4uVPofmhKcbzce488ONIliE1Eg1-wHHZY94e5i1VwVtseEW5127NCn_liku0R-H7zBvf0qI2XPsDA'
//  },
//  email: { emailAddress: 'badal.b.murmu@gmail.com' },
//  userProfile: {
//    firstName: 'Badal',
//    lastName: 'Murmu',
//    profileImageURL: 'https://media-exp1.licdn.com/dms/image/C5603AQH5mdiGuplV8Q/profile-displayphoto-shrink_100_100/0/1616384471958?e=1650499200&v=beta&t=q3ABtAB4m75l8u3a8rbZyKzvg-v0siba-nh2dfLoZ2A'
//  }
// }

export const CHECK_MOBILE_EMAIL_FULFILLED = 'CHECK_MOBILE_EMAIL_FULFILLED';
export const CHECK_MOBILE_EMAIL_PENDING = 'CHECK_MOBILE_EMAIL_PENDING';
export const CHECK_MOBILE_EMAIL_REJECTED = 'CHECK_MOBILE_EMAIL_REJECTED';

const checkMobileEmailRequest = () => ({
  type: CHECK_MOBILE_EMAIL_PENDING,
});

const checkMobileEmailFailure = (error) => ({
  type: CHECK_MOBILE_EMAIL_REJECTED,
  payload: { error: error },
});

const checkMobileEmailSuccess = (data) => ({
  type: CHECK_MOBILE_EMAIL_FULFILLED,
  payload: { linkedIn: data },
});

export const checkMobileEmail = (payload) => {
  return (dispatch) => {
    dispatch(checkMobileEmailRequest());
    return http
      .post(apiUrl.CHECK_MOBILE_EMAIL, payload)
      .then((response) => {
        console.log('getArticlesList', response);
        if (response?.status === 1) {
          dispatch(checkMobileEmailSuccess(response));
          return response;
        } else {
          dispatch(checkMobileEmailFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        dispatch(checkMobileEmailFailure(error));
        return error;
      });
  };
};

export const CHECK_USER_NAME_FULFILLED_NEW = 'CHECK_USER_NAME_FULFILLED_NEW';
export const CHECK_USER_NAME_PENDING_NEW = 'CHECK_USER_NAME_PENDING_NEW';
export const CHECK_USER_NAME_REJECTED_NEW = 'CHECK_USER_NAME_REJECTED_NEW';

const checkUserNameRequestNew = () => ({
  type: CHECK_USER_NAME_PENDING,
});

const checkUserNameFailureNew = (error) => ({
  type: CHECK_USER_NAME_REJECTED,
  payload: { error: error },
});

const checkUserNameSuccessNew = (data) => ({
  type: CHECK_USER_NAME_FULFILLED,
  payload: { linkedIn: data },
});

export const checkUserNameNew = (payload) => {
  return (dispatch) => {
    dispatch(checkUserNameRequestNew());
    return http
      .post(apiUrl.CHECK_USER_NAME, payload)
      .then((response) => {
        console.log('checkUserName', response);
        if (response?.status === 1) {
          dispatch(checkUserNameSuccessNew(response));
          return response;
        } else {
          dispatch(checkUserNameFailureNew(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        dispatch(checkUserNameFailureNew(error));
        return error;
      });
  };
};

export const CHECK_USER_NAME_FULFILLED = 'CHECK_USER_NAME_FULFILLED';
export const CHECK_USER_NAME_PENDING = 'CHECK_USER_NAME_PENDING';
export const CHECK_USER_NAME_REJECTED = 'CHECK_USER_NAME_REJECTED';

const checkUserNameRequest = () => ({
  type: CHECK_USER_NAME_PENDING,
});

const checkUserNameFailure = (error) => ({
  type: CHECK_USER_NAME_REJECTED,
  payload: { error: error },
});

const checkUserNameSuccess = (data) => ({
  type: CHECK_USER_NAME_FULFILLED,
  payload: { linkedIn: data },
});

export const checkUserName = (payload) => {
  return (dispatch) => {
    dispatch(checkUserNameRequest());
    return http
      .post(apiUrl.CHECK_USER, payload)
      .then((response) => {
        console.log('checkUserName', response);
        if (response?.status === 1) {
          dispatch(checkUserNameSuccess(response));
          return response;
        } else {
          dispatch(checkUserNameFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        dispatch(checkUserNameFailure(error));
        return error;
      });
  };
};
const userdatasucces = (data) => ({
  type: 'USER_DATA_FULFILLED',
  payload: { userData: data },
});
export const getUser = (payload) => {
  return (dispatch) => {
    const userData = getLocalUserData();
    console.log('userData', userData);
    dispatch(userdatasucces(userData));
    return true;
  };
};

export const checkMobileEmailold = (payload) => {
  return (dispatch) => {
    dispatch(checkMobileEmailRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      console.log('interest token', token);
      API.http.post(
        {
          endPoint: apiUrl.CHECK_MOBILE_EMAIL,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(checkMobileEmailFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(checkMobileEmailSuccess(response));
              resolve(response);
            } else {
              dispatch(checkMobileEmailFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};
