import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import RewardSubHeader from '../../components/RewardSubHeader/RewardSubHeader';
import * as rewardsActions from '../../store/rewards';
import PartnerRewards from './PartnerRewards';
import * as messageActions from '../../store/message';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

// const StyledTabPanel = styled(TabPanel)((theme) => ({
//   width: '100%',
// }));

// const AntTab = styled((props) => <Tab disableRipple {...props} />)(
//   ({ theme }) => ({
//     color: '#FFFFFF',
//     fontWeight: '800',
//     fontFamily: 'Montserrat',
//     '&:hover': {
//       color: '#38CD84',
//       opacity: 1,
//     },
//     '&.MuiTab-root': {},
//     '&.Mui-selected': {
//       color: '#38CD84',
//     },
//     '&.Mui-focusVisible': {
//       backgroundColor: '#d1eaff',
//     },
//   }),
// );

const   Rewards = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state?.user?.UserDetails);
  // const searchresult =  dispatch(searchActions.postSearch(data));
  // const rewardsTotalCredits = useSelector(
  //   (state) => state?.rewards?.rewardsTotalCredits,
  // );

  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );

  console.log('Rewards', location);
  useEffect(async () => {}, []);

  const handleChange = async (event, newValue) => {
    if (user?.isVerified) {
      if (newValue === 0) {
        navigate('/rewards/membership/details/Basic');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/rewards/membership/details/Premium');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/membership/details/Elite');
        setValue(newValue);
      } else if (newValue === 3) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };



  useEffect(async () => {
    if (location?.state?.tab === 0 || location?.state?.tab === 1) {
      setValue(location?.state?.tab);
    }
  }, []);

  useEffect(async () => {
    if (location.pathname.includes('/rewards/partners')) {
      setValue(3);
    } else if (location.pathname === '/rewards/my/vouchers') {
      setValue(3);
    } else if (location.pathname === 'corporate/partners/category') {
      setValue(3);
    } else {
      setValue(3);
    }
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  const subscriptionType = () => {
    if (user?.membership_type === 0) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 1) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 2) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#C0C0C0' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#C0C0C0',
              fontSize: '12px',
              fontWeight: '400',

              fontFamily: 'Open Sans',
            }}>
            Silver
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 3) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#FFC107' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#FFC107',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Gold
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 4) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#A0B2C6' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#A0B2C6',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Platinum
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    }
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 0) {
        navigate('/rewards/membership/details/Basic');
        setValue(val);
      } else if (val === 1) {
        navigate('/rewards/membership/details/Premium');
        setValue(val);
      } else if (val === 2) {
        navigate('/rewards/membership/details/Elite');
        setValue(val);
      } else if (val === 3) {
        navigate('/rewards/category/partners');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <Fragment>
      <Box p={2}>
        <Box sx={{ width: '100%' }}>
          {location.pathname.includes('/rewards') ? <RewardSubHeader /> : ''}
          {/* <Box px={0} py={1}>
            <Tabs
              defaultValue={1}
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              color="white"
              aria-label="ant example">
              <Paper
                sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                <StyledTabsList sx={{ padding: '8px 16px' }}>
                  <StyledTab>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                        Membership
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 0 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Stack>
                  </StyledTab>
                  <StyledTab>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                        Partners
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 1 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Stack>
                  </StyledTab>
                  <StyledTab>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                        Vouchers
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 2 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Stack>
                  </StyledTab>
                </StyledTabsList>
              </Paper>
              <StyledTabPanel value={1}>
                <PartnerRewards />
                <Grid container sx={{ paddingBottom: '8px' }}></Grid>
              </StyledTabPanel>
              <StyledTabPanel value={2} sx={{ paddingBottom: '8px' }}>
                <VoucherRewards />
                <Grid container></Grid>
              </StyledTabPanel>
            </Tabs>
          </Box> */}

          <Box px={0} py={1}>
            <Tabs
              defaultValue={0}
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              color="white"
              aria-label="voucher">
              <Paper
                sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                <StyledTabsList sx={{ padding: '8px 16px' }}>
                  <StyledTab onClick={() => changeTab(0)}>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                        Basic
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 0 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Stack>
                  </StyledTab>

                  <StyledTab onClick={() => changeTab(1)}>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                        Premium
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 1 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Stack>
                  </StyledTab>

                  <StyledTab onClick={() => changeTab(2)}>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                        Elite
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 2 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Stack>
                  </StyledTab>

                  <StyledTab onClick={() => changeTab(3)}>
                    <Stack direction="row" spacing={0.5} onClick={() => changeTab(3)}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 3 ? '#000000' : '#ffffff' }}>
                        Partners
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 3 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Stack>
                  </StyledTab>
                </StyledTabsList>
              </Paper>
            </Tabs>

            <PartnerRewards />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(Rewards);

//
// <TabPanel value={2}>
//   <LinkcxoRewards />
//   <Grid container sx={{ paddingBottom: "8px" }}></Grid>
// </TabPanel>

// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab>
