import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Box, Typography, Stack } from "@mui/material";
import JobCard from "../../components/JobCard/JobCard";
import JobProfileCard from "../../components/JobProfileCard/JobProfileCard";
import * as jobsActions from "../../store/jobs";
import * as activityActions from "../../store/activity";
import { dateTime } from "../../constants/app";
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: "#fff",
  },
  seismicdata: {
    backgroundColor: "#262626",
    padding: "32px",
  },
  wellheader: {
    backgroundColor: "#000113",
  },
  welldata: {
    maxWidth: "1260px",
  },
}));
const JobSimilar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList
  );
  const candidateAppliedList = useSelector(
    (state) => state?.jobs?.CandidateAppliedList
  );

  const candidateShortList = useSelector(
    (state) => state?.jobs?.CandidateShortList
  );

  const candidateHoldList = useSelector(
    (state) => state?.jobs?.CandidateHoldList
  );

  const openJob = useSelector((state) => state?.jobs?.JobListOpen);
  const inactiveJob = useSelector((state) => state?.jobs?.JobListInactive);
  const closedJob = useSelector((state) => state?.jobs?.JobListClosed);
  const similarJob = useSelector((state) => state?.jobs?.SimilarJobList);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const [noofSimilar, setNoofSimilar] = React.useState(4);
  useEffect(async () => {
    const data = {
      user_id: user.id,
    };
    const jobs = await dispatch(jobsActions.getRecommendedList(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      job_id: jobDetails?.id,
    };
    const jobs = await dispatch(jobsActions.getSimilarList(data));
  }, []);

  const seeMore = () => {
    if (similarJob?.length > noofSimilar) {
      setNoofSimilar(similarJob?.length);
    } else if (similarJob?.length === noofSimilar) {
      setNoofSimilar(4);
    }
  };
  const shortList = async () => {
    // const data = {
    //   job_id: 471,
    //   cand_id: 844,
    //   datetime: dateTime(),
    //   user_id: user.id,
    // };
    // const jobs = await dispatch(jobsActions.postJobCandidateShortList(data));
  };

  const apply = async () => {
    const data = {
      user_id: user.id,
    };
    const jobstatus = await dispatch(jobsActions.manageJobStatus(data));
  };

  const saveJob = async (jobid) => {
    const data = {
      user_id: user.id,
      type: "job",
      content_id: jobid,
      datetime: dateTime(),
    };
    const bookpost = await dispatch(activityActions.saveBookmark(data));
  };
  return (
    <Fragment>
      <Box px={1}>
        <Box pt={2} sx={{ width: "100%" }}>
          <Paper sx={{ backgroundColor: "#232121", backgroundImage: "none" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}
            >
              <Box
                py={3}
                px={3}
                sx={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 600,
                    "@media (max-width: 500px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  Other Jobs
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={seeMore}
              >
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    "@media (max-width: 500px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  {similarJob?.length === noofSimilar ? "See Less" : "See All"}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          sx={{
            marginTop: "8px",
          }}
        >
          <Grid container>
            {similarJob?.length > 0 &&
              similarJob?.slice(0, noofSimilar)?.map((list, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      paddingRight: index % 2 === 0 ? "8px" : "0px",
                      marginTop: "8px",
                    }}
                  >
                    <JobCard
                      joblist={list}
                      type="search"
                      apply={apply}
                      saveJob={saveJob}
                      changePanel={props?.changePanel}
                    />
                  </Grid>
                );
              })}
            {similarJob?.length == 0 && (
              <Box pl={3} py={2}>
                <Typography variant="h5" component="h5">
                  Currently there are no similar jobs
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobSimilar);
