import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import EventCard from '../../components/EventCard/EventCard';
import ClubCard from '../../components/ClubCard/ClubCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubsInvited = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofInvited, setNoofInvited] = React.useState(2);
  const [pageNoInvitation, setPageNoInvitation] = React.useState(0);

  const user = useSelector((state) => state?.user?.UserDetails);
  const Invitedlist = useSelector((state) => state?.events?.eventInvitedList);
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const clubInvitedList = useSelector((state) => state?.clubs?.clubInvitedList);
  const clubRequestedList = useSelector(
    (state) => state?.clubs?.clubRequestedList,
  );
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: pageNoInvitation,
    };
    const eventList = await dispatch(clubsActions.invitedClubList(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const seeMoreInvited = async () => {
    setPageNoInvitation(pageNoInvitation + 1);
    const data = {
      user_id: user?.id,
      page_no: pageNoInvitation + 1,
    };
    const clubHostedList = await dispatch(clubsActions.hostedClubs(data));
  };
  const seeMore = () => {
    if (clubInvitedList?.length > noofInvited) {
      setNoofInvited(clubInvitedList?.length);
    } else if (clubInvitedList?.length === noofInvited) {
      setNoofInvited(2);
    }
  };
  return (
    <Fragment>
      <Box px={0}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <InfiniteScroll
              dataLength={clubInvitedList?.length ? clubInvitedList?.length : 0}
              next={() => seeMoreInvited()}
              hasMore={true}
              loader={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreInvited}></Box>
              }
              height={
                clubInvitedList?.length > 2 ? 'calc(90vh - 70px)' : 'auto'
              }
              endMessage={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreInvited}>
                  <Box>End</Box>
                </Box>
              }>
              <Grid container>
                {clubInvitedList?.length > 0 &&
                  clubInvitedList?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={12}
                        sx={{
                          marginTop: '8px',
                        }}>
                        <ClubCard clubs={list} type="Invited" />
                      </Grid>
                    );
                  })}
                {clubInvitedList?.length == 0 && (
                  <Box pl={3} pt={3}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}>
                      Currently, there are no pending club invitations.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(ClubsInvited);
