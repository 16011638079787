import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Paper, Grid, Box, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import ProfileShort from "../../components/ProfileShort/ProfileShort";
import BottomNavigator from "../../components/BottomNavigator/BottomNavigator";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import Helpandfeedback from "../../components/Helpandfeedback/Helpandfeedback";
import UnderVerification from "../../components/UnderVerification/UnderVerification";
import HomeArticle from "../HomeContainer/HomeArticle";
import ReportPostComponent from "../../components/ReportPost/ReportPost";
import JobApply from "../../components/JobApply/JobApply";
import JobShare from "../../components/JobShare/JobShare";
import ReportJob from "../../components/ReportJob/ReportJob";
import EventsDetails from "./EventsDetails";
import LeftNav from "../../components/LeftNav/LeftNav";

const EventClubDetailsContainer = (props) => {
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const feedbackShow = useSelector((state) => state?.post?.FeedbackShow);
  const openJobApply = useSelector((state) => state?.jobs?.JobApply);
  const openJobShare = useSelector((state) => state?.jobs?.JobShare);
  const reportJob = useSelector((state) => state?.jobs?.ReportJob);
    const reportPost = useSelector((state) => state?.post?.ReportPost);
    const underVerification = useSelector(
      (state) => state?.message?.underVerification
    );
  return (
    <Fragment>
      <Helmet>
        <title>Event Details || LinkCxO - Exclusive CxO Networking</title>
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
        <Header />
      </Box>
      {openSnackbar && <CustomizedSnackbars />}
      {feedbackShow && <Helpandfeedback />}
      {openJobApply && <JobApply />}
      {openJobShare && <JobShare />}
      {reportJob && <ReportJob />}
            {reportPost && <ReportPostComponent />}
              {underVerification && <UnderVerification />}
      <Box
        pt={8}
        sx={{
          display: { md: "flex", xs: "none" },
          justifyContent: "center",
          height: "calc(100vh - 65px )",
          width: "1280px",
          margin: "auto",
        }}
      >
        <div class="wrapper_home">
          <aside>
            <Box py={1} sx={{ width: "100%  " }}>
                <LeftNav />
            </Box>
          </aside>
          <main>
            <div class="wrapper_inner">
              <Box>
                <EventsDetails />
              </Box>
              <Box px={1}></Box>
            </div>
          </main>
        </div>
      </Box>

      <Box
        sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center" }}
      >
        <Grid
          container
          sx={{
            maxWidth: "1228px",
            justifyContent: "center",
            paddingBottom: "64px",
          }}
        >
          <Grid item xs={12} sm={10} md={12}>
            <Box px={1}>
              <EventsDetails />
            </Box>
            <Box px={1}></Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: "0%",
          width: "100%",
          zIndex: 2001,
        }}
      >
        <BottomNavigator />
      </Box>
    </Fragment>
  );
};

export default React.memo(EventClubDetailsContainer);
