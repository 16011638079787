import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import 'react-quill/dist/quill.snow.css';
import useStyles from './sharepoststyle';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import IconButton from '@mui/material/IconButton';
import CxoInput from '../../components/CxoInput/CxoInput';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CardLongShare from '../CardLong/CardLongShare';
import { useForm, Controller } from 'react-hook-form';
import { dateTime } from '../../constants/app';

const SharePost = (props) => {
  const dispatch = useDispatch();
  const [quilltext, setQuilltext] = useState('');

  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(10);
  const sharePost = useSelector((state) => state?.post?.SharePost);
  const post = useSelector((state) => state?.post?.sharePost?.post);
  console.log('post', post);
  const user = useSelector((state) => state?.user?.UserDetails);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
  }, []);
  const addPost = async () => {
    // const data = {
    //   user_id: user.id,
    //   type: props?.post.type,
    //   content_id: props?.post.id,
    //   datetime: dateTime(),
    //   content: props?.post?.content,
    //   user_type: props?.post?.user_type,
    //   user_table_pk: props?.post?.user_table_pk,
    //   child_id: props?.post?.child_id,
    // };
    // const sharepost = await dispatch(postActions.sharePost(data));
    if (quilltext.length >= 0) {
      const sharedata = {
        user_id: user?.id,
        datetime: dateTime(),
        content: quilltext,
        user_type: 'user', // post?.user_type,
        user_table_pk: post?.user_table_pk,
        child_id: post?.id,
      };

      const sharepost = await dispatch(postActions.sharePost(sharedata));

      const data = {
        user_id: user?.id,
        start_item: startItem,
        item_count: itemCount,
        datetime: dateTime(),
      };

      const formData = new FormData();
      formData.append('user_id', user?.id);
      formData.append('start_item', startItem);
      formData.append('item_count', itemCount);
      formData.append('datetime', dateTime());

      const postlist = await dispatch(postActions.getPostList(formData, data));
      const snackbar = await dispatch(postActions.closeSharePost());
    }
  };

  const handleChange = (event) => {
    if (quilltext.length < 2000) {
      setQuilltext(event.target.value);
    } else if (event.target.value.length < quilltext.length) {
      setQuilltext(event.target.value);
    }
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const snackbar = await dispatch(postActions.closeSharePost());
  };
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '400',
        height: '100vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={sharePost}>
        <Box
          sx={{
            width: '80%',
            backgroundColor: '#1c1c1c',
            borderRadius: '10px',
            height: '75vh',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h2s" component="h2s">
                  Share Your Experience
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>
            <Divider />
            <Box p={2} sx={{ zIndex: '1200' }} className="editor">
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={6}
                maxRows={16}
                value={quilltext}
                onChange={handleChange}
                placeholder="Start Typing Here"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box sx={{ maxHeight: '55vh', height: '28vh' }}>
              {post && (
                <CardLongShare
                  post={post}
                  clubDetails={post?.clubDetails}
                  oPost={post}
                  from="share"
                />
              )}
            </Box>
            <Divider />
            <Divider />
            <Grid container alignItems="flex-end" sx={{ alignItems: 'center' }}>
              <Grid
                item
                xs
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '16px',
                }}>
                <Box px={3}>
                  <Typography gutterBottom variant="h6" component="h6">
                    {quilltext.length} / 2000
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <CxoButton label="Post" onClick={addPost} />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(SharePost);

// <FormControl fullWidth>
// <InputLabel id="demo-simple-select-label">Share</InputLabel>
// <Select
// labelId="demo-simple-select-label"
// id="demo-simple-select"
// value={share}
// label="share"
// onChange={handleShare}
// size="small"
// >
// <MenuItem value={'Everyone'}>Everyone</MenuItem>
// <MenuItem value={'Myconnection'}>My Connections</MenuItem>
//
// </Select>
// </FormControl>
