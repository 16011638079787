import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProfileShort from "../ProfileShort/ProfileShort";
import HomeClub from "../../containers/HomeContainer/HomeClub";
import HomeEvent from "../../containers/HomeContainer/HomeEvent";
import AdsCard from "../../components/AdsCard/AdsCard";



const LeftNav = () => {
  const ads = useSelector((state) => state?.post?.ads);
  return (
    <Fragment>
      <ProfileShort />
      {/* <HomeEvent /> */}
      
      <HomeClub /> 
      <AdsCard interval={30000}  position="left"/> 
      
    </Fragment>
  );
};

export default React.memo(LeftNav);
