import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import img1 from '../../../resources/partner-image/Automobile.jpg';
import img2 from '../../../resources/partner-image/Apparel.png';
import img3 from '../../../resources/partner-image/NuraC_39.jpg';
import img4 from '../../../resources/partner-image/Electronics.jpg';
import img5 from '../../../resources/partner-image/Investment.jpg';
import img6 from '../../../resources/partner-image/Hotel.jpg';
import img7 from '../../../resources/partner-image/PVR.png';

const images = [
 img1,img2,img3,img4,img5,img6,img7
];

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    animation: '$zoom-out 8s ease-in-out infinite',
  },
  textOverlay: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    borderRadius: '4px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  firstP: {
    fontSize: '70px',
    fontWeight: 1000,
    color: '#ffffff',
    marginBottom: '-12px',
     textTransform: 'uppercase',
  },
  secondP: {
    fontSize: '55px',
    fontWeight: 800,
    color: '#ffffff',
    marginTop: '-12px',
  },
  backgroundColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6) !important',
  },
  '@keyframes zoom-out': {
    '0%': {
      transform: 'scale(1.0)',
    },

    '100%': {
      transform: 'scale(2)',
    },
  },
}));

const PartnerBanner = () => {
  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const currentImageUrl = images[currentImageIndex];

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={currentImageUrl} alt="Background" className={classes.image} />
        <div className={classes.textOverlay}>
          <p className={classes.firstP}>Unique CxO Rewards</p>
          <p className={classes.secondP}>
            Where CxOs Are Celebrated and Rewarded
          </p>
        </div>
      </div>
    </div>
  );
};

export default PartnerBanner;
