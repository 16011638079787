import { makeStyles } from "@mui/styles";

export default makeStyles({
  interestBox: {
    width: "121px",
    height: "132px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "5px",
    borderColor: "#FFF",
    borderStyle: "solid",
    borderWidth: "1px",
    margin: "1px",
    backgroundColor: "rgba(255,255,255,0.3)",
    cursor: "pointer",
  },
  interestBoxselect: {
    width: "121px",
    height: "132px",
    display: "flex",
    color: "#38CD84",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "5px",
    borderColor: "#38CD84",
    borderStyle: "solid",
    borderWidth: "2px",
    margin: "-1px",
    backgroundColor: "rgba(56,205,132,0.3)",
    cursor: "pointer",
  },
});
