import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { withRouter, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MoreIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/header/Logo.png';
import networks from '../../assets/header/Networks_White.png';
import jobs from '../../assets/header/Jobs_White-01.png';
import news from '../../assets/header/News_White-01.png';
import sponsers from '../../assets/header/Sponsors_White.png';
import chat from '../../assets/header/Chat_White.png';
import useStyles from './headerstyle';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';

export default function BottomNavigator() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ width: '100%' }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          console.log('newValue', newValue);
          if (newValue == 0) {
            navigate('/network');
          } else if (newValue == 1) {
            navigate('/jobs');
          } else if (newValue == 2) {
            navigate('/events/hosting');
          } else if (newValue == 3) {
            navigate('/notifications');
          }
          setValue(newValue);
        }}
        sx={{
          '& .Mui-selected, .Mui-selected > svg': {
            color: '#ffffff',
          },
          backgroundColor: '#313030',
        }}>
        <BottomNavigationAction
          label="Networks"
          icon={
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box className={classes.menuIcon}>
                <Icon
                  name="networks"
                  color={value == 1 ? '#38CD84' : '#ffffff'}
                />
              </Box>
            </Box>
          }
        />
        <BottomNavigationAction
          label="Jobs"
          icon={
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box className={classes.menuIcon}>
                <Icon name="jobs" color={value == 3 ? '#38CD84' : '#ffffff'} />
              </Box>
            </Box>
          }
        />
        <BottomNavigationAction
          label="Events"
          icon={
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box className={classes.menuIcon}>
                <Menuicon
                  name="events"
                  color={value == 4 ? '#38CD84' : '#ffffff'}
                />
              </Box>
            </Box>
          }
        />
        <BottomNavigationAction
          label="Notifications"
          icon={
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box className={classes.menuIcon}>
                <NotificationsIcon
                  sx={{
                    color: value == 2 ? '#38CD84' : '#ffffff',
                  }}
                />
              </Box>
            </Box>
          }
        />
      </BottomNavigation>
    </Box>
  );
}

// <Icon name="news" color={value == 1 ? "#38CD84" : "#ffffff"} />

// <BottomNavigationAction
//   label="Business"
//   icon={
//     <Box sx={{ display: "flex", justifyContent: "center" }}>
//       <Box className={classes.menuIcon}>
//         <Icon
//           name="sponsers"
//           color={value == 2 ? "#38CD84" : "#ffffff"}
//         />
//       </Box>
//     </Box>
//   }
// />
// <BottomNavigationAction
//   label="Jobs"
//   icon={
//     <Box sx={{ display: "flex", justifyContent: "center" }}>
//       <Box className={classes.menuIcon}>
//         <Icon name="jobs" color={value == 3 ? "#38CD84" : "#ffffff"} />
//       </Box>
//     </Box>
//   }
// />
