import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rowroot: {
    paddingTop: '16px',
    paddingLeft: '16px',
  },
}));

const JobDetailsSkeleton = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  return (
    <Fragment>
      <Box px={1} pt={1} pb={1} id="jobdetails">
        <Accordion expanded={expanded === 'panel1'}>
          <Stack direction="row" spacing={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ padding: '8px', backgroundColor: '#333', width: '100%' }}>
              <Stack
                direction="column"
                spacing={1}
                sx={{ paddingTop: '4px', paddingLeft: '24px' }}>
                <Skeleton variant="square" width={200} height={20} />
                <Skeleton variant="square" width={200} height={20} />
              </Stack>
            </AccordionSummary>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#333',
              }}
              p={3}></Box>
          </Stack>
          <AccordionDetails
            sx={{ backgroundColor: '#272727', marginBottom: '16px' }}>
            <Grid container className={classes.rowroot}>
              <Grid item xs={6}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={200} height={20} />
                  <Skeleton variant="square" width={200} height={20} />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={200} height={20} />
                  <Skeleton variant="square" width={200} height={20} />
                </Stack>
              </Grid>
            </Grid>
            <Grid container className={classes.rowroot}>
              <Grid item xs={12} md={6}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={250} height={20} />

                  <Stack direction="row">
                    <Skeleton variant="square" width={200} height={20} />
                    <Skeleton variant="square" width={200} height={20} />
                    <Skeleton variant="square" width={200} height={20} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={classes.rowroot}>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ paddingTop: '8px' }}></Stack>
              </Grid>
            </Grid>

            <Grid container className={classes.rowroot}>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ paddingTop: '8px' }}>
                  <Skeleton variant="square" width={200} height={20} />

                  <Box pr={0.5} sx={{ width: '100%' }}>
                    <Skeleton variant="square" width={200} height={20} />
                    <Skeleton variant="square" width={200} height={20} />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.rowroot}
              sx={{ paddingBottom: '8px', paddingRight: '8px' }}>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={200} height={20} />
                  <Skeleton variant="square" width={500} height={80} />
                </Stack>
              </Grid>
            </Grid>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems="center">
              <Box sx={{ padding: '16px' }}>
                <Card
                  sx={{
                    padding: '12px',
                    borderRadius: '10px',
                    backgroundColor: '#333',
                    border: '1px solid #3DCD84',
                    minWidth: '320px',
                  }}>
                  <Stack direction="row">
                    <Box
                      pl={2}
                      pr={1}
                      sx={{ display: 'flex', alignItems: 'center' }}>
                      <Skeleton variant="circular" width={50} height={50} />
                    </Box>
                    <Box
                      px={2}
                      sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Stack spacing={0.2} direction="column">
                        <Box pb={1}>
                          <Skeleton variant="square" width={200} height={20} />
                        </Box>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            paddingTop: '1px',
                            paddingBottom: '3px',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}></Typography>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            paddingTop: '0.5px',
                            fontSize: '11px',
                          }}></Typography>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            paddingTop: '0.5px',
                            fontSize: '11px',
                          }}></Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Card>
              </Box>

              <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
                <Box>
                  <Skeleton variant="square" width={50} height={20} />
                </Box>
                <Box>
                  <Skeleton variant="square" width={50} height={20} />
                </Box>
                <Box pr={4}>
                  <Skeleton variant="square" width={50} height={20} />
                </Box>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobDetailsSkeleton);
