import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import mobile1 from '../../resources/new-img/Network-mob.png';
import mobile2 from '../../resources/new-img/ChatBot-01.png';
import mobile6 from '../../resources/new-img/Events-mob.png';
import mobile5 from '../../resources/new-img/Clubs-mob.png';
import mobile7 from '../../resources/new-img/Business-mob.png';
import mobile3 from '../../resources/new-img/Jobs-mob.png';
import mobile4 from '../../resources/new-img/Meetup-mob.png';
import mobile8 from '../../resources/new-img/Reward-mob.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import KeyboardArrowRightIcon from '../../resources/new-img/Right Arrow - White.png';
import KeyboardArrowLeftIcon from '../../resources/new-img/Left Arrow - White.png';
import gPlay from '../../resources/new-img/GooglePlaystore.png';
import AvgEngRate from '../../resources/new-img/Average Engagement Rate.png';
import AvgRetRate from '../../resources/new-img/Average Retention Rate.png';
import Seo from '../../resources/new-img/seo-new.png';
import cxoNetworking from '../../resources/Interest - Icons/CXO Networking_White.png';
import InvOppo from '../../resources/Interest - Icons/Investment Opportunities_White.png';
import BussOppo from '../../resources/Interest - Icons/Business Oppurtunities_White.png';
import HiringCxo from '../../resources/Interest - Icons/Hiring CXOs_White.png';
import FundRise from '../../resources/Interest - Icons/Fund Raising_White.png';
import AdvOppo from '../../resources/Interest - Icons/Advisory Opportunities_Wite.png';
import CareerOppo from '../../resources/Interest - Icons/Career Oppurtunities_White.png';
import IndusEve from '../../resources/Interest - Icons/Industry Events_White.png';
import NewsCont from '../../resources/Interest - Icons/News Content_White.png';
import { RocketTwoTone } from '@mui/icons-material';

class FeatureNew extends Component {
  constructor(props) {
    super();
    this.state = {
      allcardDataT: [
        {
          mobile: mobile1,
          heading: 'Network',
          subheading:
            'Exclusive and meaningful connections from a pool of 15000+ Professionals',
        },
        {
          mobile: mobile2,
          heading: 'Club',
          subheading:
            'Create/ join customized clubs for liked-minded individuals',
        },
        {
          mobile: mobile3,
          heading: 'AI-enabled chatbot',
          subheading:
            'Latest Industry Research reports curated just for the Senior Professionals',
        },
        {
          mobile: mobile4,
          heading: 'Jobs',
          subheading:
            'Premium Jobs for C-Level Excutives, right recruitment opportunities',
        },
        {
          mobile: mobile5,
          heading: 'Events',
          subheading: 'Host & attend our Elite events of the C-Suite community',
        },
        // {
        //   mobile: mobile6,
        //   heading: "Business",
        //   subheading: "A service based marketplace for CxOs",
        // },
        // {
        //   mobile: mobile7,
        //   heading: "Meetups",
        //   subheading: "AI-Powered CxO Meetup Recommendations",
        // },
        {
          mobile: mobile8,
          heading: 'Rewards',
          subheading:
            'Chance to Earn credits and  Redeem Premium Exclusive Vouchers',
        },
      ],
    };
  }
  onMouseEnter = (value) => {
    this.setState({ selected: value });
  };
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const CustomRight = ({ onClick }) => (
      <button onClick={onClick} className="feature-Right-Arrow">
        <img
          alt=""
          src={KeyboardArrowRightIcon}
          style={{ width: '23px', height: '23px' }}
        />
      </button>
    );
    const CustomLeft = ({ onClick }) => (
      <button onClick={onClick} className="feature-Left-Arrow">
        <img
          alt=""
          src={KeyboardArrowLeftIcon}
          style={{ width: '25px', height: '25px' }}
        />
      </button>
    );
    return (
      <Container fluid>
        <Row className="nl-main-feature">
          <Col>
            {' '}
            <Row>
              {' '}
              <Col md={4} xs={12}></Col>
              <Col md={4} xs={12}>
                <Carousel
                  customRightArrow={<CustomRight />}
                  customLeftArrow={<CustomLeft />}
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                  autoPlaySpeed={2000}
                  keyBoardControl={true}
                  // customTransition="all .5"
                  // transitionDuration={1000}
                  containerClass="carousel-container"
                  // removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={this.props.deviceType == 'mobile' ? true : false}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  className="main-feature-style-section">
                  {this.state.allcardDataT.map((item) => {
                    return (
                      <div style={{ textAlign: 'center', marginTop: '100px' }}>
                        <div style={{ marginBottom: '50px' }}>
                          <img
                            alt=""
                            src={item.mobile}
                            className="nl-phone-image-style"
                          />
                        </div>

                        <Row
                          className="mobile-feature"
                          style={{
                            height: 'auto',
                            textAlign: 'center',
                            padding: '5px 0px',
                            margin: '30px',
                            backgroundColor: 'rgba(56,205,132, 0.25)',
                            borderRadius: '3px',
                          }}>
                          <Col
                            style={{
                              padding: '0px',
                              textAlign: 'center',
                            }}>
                            {' '}
                            <div
                              style={{
                                color: '#38cd84',
                              }}>
                              <h5>{item.heading} </h5>
                            </div>
                            <div>
                              {' '}
                              <p
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '20px',
                                }}>
                                {item.subheading}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        {/* <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.linkcxo"
              target="blank"
            >
              <img alt="" src={gPlay} className="nl-gplay-image-style" />
            </a>
          </div> */}
                      </div>
                    );
                  })}
                </Carousel>
              </Col>
              <Col md={4} xs={12}></Col>
            </Row>
            <Row className="interest-section-mob">
              <Col>
                <Row>
                  <Col>
                    <h2 className="interest-title">
                      Interest Specific catering via App
                    </h2>
                  </Col>
                </Row>
                <Row className="interest-row3">
                  {' '}
                  <Col>
                    {' '}
                    <Row>
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame">
                          <img
                            alt=""
                            src={cxoNetworking}
                            className="interest-img"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          CxO Networking
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame">
                          <img alt="" src={InvOppo} className="interest-img" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        {' '}
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          Investment Opportunities
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      <Col className="logo-frame">
                        <div className="interest-frame">
                          {' '}
                          <img alt="" className="interest-img" src={BussOppo} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        {' '}
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          Business Opportunities
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="interest-row3">
                  {' '}
                  <Col>
                    {' '}
                    <Row>
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame">
                          <img
                            alt=""
                            src={HiringCxo}
                            className="interest-img"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          Hiring CxO
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame">
                          <img alt="" src={FundRise} className="interest-img" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        {' '}
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          Fund Raise
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      <Col className="logo-frame">
                        <div className="interest-frame">
                          {' '}
                          <img alt="" className="interest-img" src={AdvOppo} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        {' '}
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          Advisory Opportunities
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="interest-row3">
                  {' '}
                  <Col>
                    {' '}
                    <Row>
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame">
                          {' '}
                          <img
                            alt=""
                            className="interest-img"
                            src={CareerOppo}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          Career Opportunities
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame">
                          <img alt="" className="interest-img" src={IndusEve} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        {' '}
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          Industry Events
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      <Col className="logo-frame">
                        <div className="interest-frame">
                          {' '}
                          <img alt="" className="interest-img" src={NewsCont} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        {' '}
                        <p style={{ color: '#fff', fontSize: '12px' }}>
                          News Content
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FeatureNew;
