import React, { Component } from 'react';
import '../../resources/css/app.css';
import { Row, Col, Container } from 'react-bootstrap';
import HeaderRaiseFunds from '../../components/HomeHeader/headerRaiseFunds';
import Footer from '../../components/HomeHeader/footer';
import BulbImg from '../../resources/new-img/Bulb2-01.png';
import Startup1 from '../../resources/new-img/startup1.png';
import Startup2 from '../../resources/new-img/startup2.png';
import Startup3 from '../../resources/new-img/startup3.png';
import { Link } from 'react-router-dom';
import Arrow from '../../resources/new-img/Arrow_Active_green.png';
import icon1 from '../../resources/new-img/Icon 1.png';
import icon2 from '../../resources/new-img/Icon 2.png';
import icon3 from '../../resources/new-img/Icon 3.png';
import icon4 from '../../resources/new-img/Icon 4.png';

class AdvRaiseFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container fluid id="no-padding">
        <Row>
          <Col md={12} xs={12} className="main-header-style-section">
            {' '}
            <HeaderRaiseFunds />{' '}
          </Col>
        </Row>
        <Row className="container-ventures1">
          <Col md={12} xs={12}>
            {' '}
            <Row>
              <Col md={12} xs={12} className="venture-main-heading">
                LinkCxO Ventures - Connecting Great Ideas with Great Investors
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} className="venture-subheading">
                Join us as a Startup Founder or Investor
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} className="btn-venture-section">
                <button className="venture-btn">
                  <Link to="" className="venture-btn-link">
                    Raise Funds
                  </Link>
                </button>{' '}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="container-ventures2">
          {' '}
          <Col>
            <Row sm={12} className="bulb-venture">
              <Col sm={6} className="left-img-venture">
                <img alt="" src={BulbImg} />
              </Col>
              <Col sm={6} className="right-content-venture">
                <h3 className="color-green">Vision</h3>
                <div
                  style={{
                    height: '3px',
                    marginLeft: '100px',
                    background: 'linear-gradient(to right,#828282,#38cd84)',
                  }}></div>
                <p>
                  LinkCxO's vision is to connect startups founded by graduates
                  of IITs and IIMs, some of the most prestigious educational
                  institutions in the world, with investors from their alumni
                  all of the world. Our supposed bias in the favour of these two
                  institutions is due to our founders being from them as well.
                  This community has a track record of creating and leading
                  ventures that have change the world.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <h3 className="color-green">Benefits</h3>
                <div
                  style={{
                    height: '4px',
                    marginLeft: '340px',
                    marginRight: '340px',
                    background:
                      'radial-gradient(rgba(56,205,132,0.25)0%, #38cd84 49%, rgba(255,255,255,0.25) 100%)',
                  }}></div>
              </Col>
            </Row>
            <Row sm={12} className="startup-joining1">
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup1} />
                </Row>
                <Row className="startup-icon-row4">
                  {' '}
                  <p>Founder mentorship and guidance by industry experts</p>
                </Row>
              </Col>
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup2} />
                </Row>
                <Row className="startup-icon-row4">
                  <p>
                    Networking and Access to High Level Investors Circle from
                    IIT/IIM alumnos
                  </p>
                </Row>
              </Col>
              <Col sm={4} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row3">
                  {' '}
                  <img alt="" src={Startup3} />
                </Row>
                <Row className="startup-icon-row4">
                  {' '}
                  <p>Easy and fast fundraising Process</p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <h3 className="color-green">Process</h3>
                <div
                  style={{
                    height: '4px',
                    marginLeft: '340px',
                    marginRight: '340px',
                    background:
                      'radial-gradient(rgba(56,205,132,0.25)0%, #38cd84 49%, rgba(255,255,255,0.25) 100%)',
                  }}></div>
              </Col>
            </Row>
            <Row sm={12} className="startup-joining">
              <Col sm={1}></Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon1} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    Apply & Join LinkCxO Venture for funding with necessary
                    information
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon2} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  {' '}
                  <p>LinkCxO Team will vet your startup team and stage.</p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon3} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    LinkCxO Team will reach out to investors (ex-IIT/IIM) who
                    would be fit for your business.
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={2} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  {' '}
                  <img alt="" src={Arrow} className="venture-arrow" />
                </Row>
                <Row></Row>{' '}
              </Col>
              <Col sm={1} className="startup-joining-box">
                {' '}
                <Row className="startup-icon-row1">
                  <img alt="" src={icon4} className="startup-icon" />
                </Row>
                <Row className="startup-icon-row2">
                  <p>
                    Connect with Investors 1-to-1 in multiple rounds of
                    discussions and raise the funds.
                  </p>
                </Row>{' '}
              </Col>
              <Col sm={1}></Col>
            </Row>

            <Row>
              <Col md={12} xs={12} className="btn-venture-section">
                <button className="venture-btn">
                  <Link to="" className="venture-btn-link">
                    Raise Funds
                  </Link>
                </button>{' '}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12} style={{ background: '#151414' }}>
            {' '}
            <Footer />{' '}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AdvRaiseFunds;
