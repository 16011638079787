import { API, apiUrl } from '../../services';
import { http } from '../../services/client';
import { showMessage } from '../message';
import {
   getAccessToken,
} from '../../services/authData';

export const GET_LINKEDIN_DATA_FULFILLED = 'GET_LINKEDIN_DATA_FULFILLED';
export const GET_LINKEDIN_DATA_PENDING = 'GET_LINKEDIN_DATA_PENDING';
export const GET_LINKEDIN_DATA_REJECTED = 'GET_LINKEDIN_DATA_REJECTED';

const getLinkedInDataRequest = () => ({
  type: GET_LINKEDIN_DATA_PENDING,
});

const getLinkedInDataFailure = (error) => ({
  type: GET_LINKEDIN_DATA_REJECTED,
  payload: { error: error },
});

const getLinkedInDataSuccess = (data) => ({
  type: GET_LINKEDIN_DATA_FULFILLED,
  payload: { linkedIn: data },
});

export const getLinkedInData = (payload) => {
  return (dispatch) => {
    dispatch(getLinkedInDataRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      console.log('interest token', token);
      API.http.post(
        {
          endPoint: apiUrl.GET_LINKEDIN_DATA,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getLinkedInDataFailure(error));
            resolve(error);
          } else {
            console.log(response);
            if (response?.status === 1) {
              dispatch(getLinkedInDataSuccess(response));
              resolve(response);
            } else {
              dispatch(getLinkedInDataFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const HOSTED_CLUB_PASSED_SUCCESS = 'HOSTED_CLUB_PASSED_SUCCESS';
export const HOSTED_CLUB_PASSED_REQUEST = 'HOSTED_CLUB_PASSED_REQUEST';
export const HOSTED_CLUB_PASSED_FAILURE = 'HOSTED_CLUB_PASSED_FAILURE';

const hostedClubPassedRequest = () => ({
  type: HOSTED_CLUB_PASSED_REQUEST,
});

const hostedClubPassedFailure = (error) => ({
  type: HOSTED_CLUB_PASSED_FAILURE,
  payload: { error: error },
});

const hostedClubPassedSuccess = (data) => ({
  type: HOSTED_CLUB_PASSED_SUCCESS,
  payload: data,
});

export const hostedClubPassed = (payload) => {
  return (dispatch) => {
    dispatch(hostedClubPassedRequest());
    return http
      .post(apiUrl.HOSTED_CLUB_PASSED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(hostedClubPassedSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(hostedClubPassedSuccess({ data: [] }));
            return response;
          } else {
            dispatch(hostedClubPassedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(hostedClubPassedFailure());
        return error;
      });
  };
};

export const CLUB_HOSTED_SUCCESS = 'CLUB_HOSTED_SUCCESS';
export const CLUB_HOSTED_REQUEST = 'CLUB_HOSTED_REQUEST';
export const CLUB_HOSTED_FAILURE = 'CLUB_HOSTED_FAILURE';

const hostedClubsRequest = () => ({
  type: CLUB_HOSTED_REQUEST,
});

const hostedClubsFailure = (error) => ({
  type: CLUB_HOSTED_FAILURE,
  payload: { error: error },
});

const hostedClubsSuccess = (data, payld) => ({
  type: CLUB_HOSTED_SUCCESS,
  payload: { clubs: data, payld: payld },
});

export const hostedClubs = (payload) => {
  return (dispatch) => {
    dispatch(hostedClubsRequest());
    return http
      .post(apiUrl.CLUB_HOSTED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(hostedClubsSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(hostedClubsSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(hostedClubsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(hostedClubsFailure());
        return error;
      });
  };
};

export const DISCOVER_CLUB_LIST_SUCCESS = 'DISCOVER_CLUB_LIST_SUCCESS';
export const DISCOVER_CLUB_LIST_REQUEST = 'DISCOVER_CLUB_LIST_REQUEST';
export const DISCOVER_CLUB_LIST_FAILURE = 'DISCOVER_CLUB_LIST_FAILURE';

const discoverClubListRequest = () => ({
  type: DISCOVER_CLUB_LIST_REQUEST,
});

const discoverClubListFailure = (error) => ({
  type: DISCOVER_CLUB_LIST_FAILURE,
  payload: { error: error },
});

const discoverClubListSuccess = (data, payld) => ({
  type: DISCOVER_CLUB_LIST_SUCCESS,
  payload: { clubs: data, payld: payld },
});

export const discoverClubList = (payload) => {
  return (dispatch) => {
    dispatch(discoverClubListRequest());
    return http
      .post(apiUrl.CLUB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(discoverClubListSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(discoverClubListSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(discoverClubListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(discoverClubListFailure());
        return error;
      });
  };
};

export const INVITED_CLUB_LIST_SUCCESS = 'INVITED_CLUB_LIST_SUCCESS';
export const INVITED_CLUB_LIST_REQUEST = 'INVITED_CLUB_LIST_REQUEST';
export const INVITED_CLUB_LIST_FAILURE = 'INVITED_CLUB_LIST_FAILURE';

const invitedClubListRequest = () => ({
  type: INVITED_CLUB_LIST_REQUEST,
});

const invitedClubListFailure = (error) => ({
  type: INVITED_CLUB_LIST_FAILURE,
  payload: { error: error },
});

const invitedClubListSuccess = (data) => ({
  type: INVITED_CLUB_LIST_SUCCESS,
  payload: data,
});

export const invitedClubList = (payload) => {
  return (dispatch) => {
    dispatch(invitedClubListRequest());
    return http
      .post(apiUrl.INVITED_CLUB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(invitedClubListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(invitedClubListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(invitedClubListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(invitedClubListFailure());
        return error;
      });
  };
};

export const REQUESTED_CLUB_LIST_SUCCESS = 'REQUESTED_CLUB_LIST_SUCCESS';
export const REQUESTED_CLUB_LIST_REQUEST = 'REQUESTED_CLUB_LIST_REQUEST';
export const REQUESTED_CLUB_LIST_FAILURE = 'REQUESTED_CLUB_LIST_FAILURE';

const requestedClubListRequest = () => ({
  type: REQUESTED_CLUB_LIST_REQUEST,
});

const requestedClubListFailure = (error) => ({
  type: REQUESTED_CLUB_LIST_FAILURE,
  payload: { error: error },
});

const requestedClubListSuccess = (data) => ({
  type: REQUESTED_CLUB_LIST_SUCCESS,
  payload: data,
});

export const requestedClubList = (payload) => {
  return (dispatch) => {
    dispatch(requestedClubListRequest());
    return http
      .post(apiUrl.REQUESTED_CLUB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(requestedClubListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(requestedClubListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(requestedClubListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(requestedClubListFailure());
        return error;
      });
  };
};

export const CLUB_MY_LIST_SUCCESS = 'CLUB_MY_LIST_SUCCESS';
export const CLUB_MY_LIST_REQUEST = 'CLUB_MY_LIST_REQUEST';
export const CLUB_MY_LIST_FAILURE = 'CLUB_MY_LIST_FAILURE';

const myClubListRequest = () => ({
  type: CLUB_MY_LIST_REQUEST,
});

const myClubListFailure = (error) => ({
  type: CLUB_MY_LIST_FAILURE,
  payload: { error: error },
});

const myClubListSuccess = (data) => ({
  type: CLUB_MY_LIST_SUCCESS,
  payload: data,
});

export const myClubList = (payload) => {
  return (dispatch) => {
    dispatch(myClubListRequest());
    return http
      .post(apiUrl.CLUB_MY_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(myClubListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(myClubListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(myClubListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(myClubListFailure());
        return error;
      });
  };
};

export const SAVED_CLUB_PASSED_SUCCESS = 'SAVED_CLUB_PASSED_SUCCESS';
export const SAVED_CLUB_PASSED_REQUEST = 'SAVED_CLUB_PASSED_REQUEST';
export const SAVED_CLUB_PASSED_FAILURE = 'SAVED_CLUB_PASSED_FAILURE';

const savedClubPassedRequest = () => ({
  type: SAVED_CLUB_PASSED_REQUEST,
});

const savedClubPassedFailure = (error) => ({
  type: SAVED_CLUB_PASSED_FAILURE,
  payload: { error: error },
});

const savedClubPassedSuccess = (data) => ({
  type: SAVED_CLUB_PASSED_SUCCESS,
  payload: data,
});

export const savedClubPassed = (payload) => {
  return (dispatch) => {
    dispatch(savedClubPassedRequest());
    return http
      .post(apiUrl.SAVED_CLUB_PASSED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(savedClubPassedSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(savedClubPassedSuccess({ data: [] }));
            return response;
          } else {
            dispatch(savedClubPassedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(savedClubPassedFailure());
        return error;
      });
  };
};

export const SAVED_CLUB_SUCCESS = 'SAVED_CLUB_SUCCESS';
export const SAVED_CLUB_REQUEST = 'SAVED_CLUB_REQUEST';
export const SAVED_CLUB_FAILURE = 'SAVED_CLUB_FAILURE';

const savedClubRequest = () => ({
  type: SAVED_CLUB_REQUEST,
});

const savedClubFailure = (error) => ({
  type: SAVED_CLUB_FAILURE,
  payload: { error: error },
});

const savedClubSuccess = (data) => ({
  type: SAVED_CLUB_SUCCESS,
  payload: data,
});

export const savedClub = (payload) => {
  return (dispatch) => {
    dispatch(savedClubRequest());
    return http
      .post(apiUrl.SAVED_CLUB, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(savedClubSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(savedClubSuccess({ data: [] }));
            return response;
          } else {
            dispatch(savedClubFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(savedClubFailure());
        return error;
      });
  };
};

export const CLUB_UNSEND_REQUEST_SUCCESS = 'CLUB_UNSEND_REQUEST_SUCCESS';
export const CLUB_UNSEND_REQUEST_REQUEST = 'CLUB_UNSEND_REQUEST_REQUEST';
export const CLUB_UNSEND_REQUEST_FAILURE = 'CLUB_UNSEND_REQUEST_FAILURE';

const clubUnsendRequestRequest = () => ({
  type: CLUB_UNSEND_REQUEST_REQUEST,
});

const clubUnsendRequestFailure = (error) => ({
  type: CLUB_UNSEND_REQUEST_FAILURE,
  payload: { error: error },
});

const clubUnsendRequestSuccess = (data) => ({
  type: CLUB_UNSEND_REQUEST_SUCCESS,
  payload: data,
});

export const clubUnsendRequest = (payload) => {
  return (dispatch) => {
    dispatch(clubUnsendRequestRequest());
    return http
      .post(apiUrl.CLUB_UNSEND_REQUEST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubUnsendRequestSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubUnsendRequestSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubUnsendRequestFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubUnsendRequestFailure());
        return error;
      });
  };
};

export const CLUB_DETAILS_SUCCESS = 'CLUB_DETAILS_SUCCESS';
export const CLUB_DETAILS_REQUEST = 'CLUB_DETAILS_REQUEST';
export const CLUB_DETAILS_FAILURE = 'CLUB_DETAILS_FAILURE';

const clubDetailsRequest = () => ({
  type: CLUB_DETAILS_REQUEST,
});

const clubDetailsFailure = (error) => ({
  type: CLUB_DETAILS_FAILURE,
  payload: { error: error },
});

const clubDetailsSuccess = (data) => ({
  type: CLUB_DETAILS_SUCCESS,
  payload: data,
});

export const clubDetails = (payload) => {
  return (dispatch) => {
    dispatch(clubDetailsRequest());
    return http
      .post(apiUrl.CLUB_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubDetailsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubDetailsFailure());
        return error;
      });
  };
};

export const clubDetailsResponse = (payload) => {
  return (dispatch) => {
    dispatch(clubDetailsRequest());
    return http
      .post(apiUrl.CLUB_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubDetailsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubDetailsFailure());
        return error;
      });
  };
};

export const CLUB_REQUESTED_MEMBERS_SUCCESS = 'CLUB_REQUESTED_MEMBERS_SUCCESS';
export const CLUB_REQUESTED_MEMBERS_REQUEST = 'CLUB_REQUESTED_MEMBERS_REQUEST';
export const CLUB_REQUESTED_MEMBERS_FAILURE = 'CLUB_REQUESTED_MEMBERS_FAILURE';

const clubRequestedMembersRequest = () => ({
  type: CLUB_REQUESTED_MEMBERS_REQUEST,
});

const clubRequestedMembersFailure = (error) => ({
  type: CLUB_REQUESTED_MEMBERS_FAILURE,
  payload: { error: error },
});

const clubRequestedMembersSuccess = (data) => ({
  type: CLUB_REQUESTED_MEMBERS_SUCCESS,
  payload: data,
});

export const clubRequestedMembers = (payload) => {
  return (dispatch) => {
    dispatch(clubRequestedMembersRequest());
    return http
      .post(apiUrl.CLUB_REQUESTED_MEMBERS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubRequestedMembersSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubRequestedMembersSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubRequestedMembersFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubRequestedMembersFailure());
        return error;
      });
  };
};

export const CANCEL_CLUB_SUCCESS = 'CANCEL_CLUB_SUCCESS';
export const CANCEL_CLUB_REQUEST = 'CANCEL_CLUB_REQUEST';
export const CANCEL_CLUB_FAILURE = 'CANCEL_CLUB_FAILURE';

const clubCancelRequest = () => ({
  type: CANCEL_CLUB_REQUEST,
});

const clubCancelFailure = (error) => ({
  type: CANCEL_CLUB_FAILURE,
  payload: { error: error },
});

const clubCancelSuccess = (data) => ({
  type: CANCEL_CLUB_SUCCESS,
  payload: data,
});

export const clubCancel = (payload) => {
  return (dispatch) => {
    dispatch(clubCancelRequest());
    return http
      .post(apiUrl.CANCEL_CLUB, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubCancelSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubCancelSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubCancelFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubCancelFailure());
        return error;
      });
  };
};

export const CLUB_MEMBERS_LIST_SUCCESS = 'CLUB_MEMBERS_LIST_SUCCESS';
export const CLUB_MEMBERS_LIST_REQUEST = 'CLUB_MEMBERS_LIST_REQUEST';
export const CLUB_MEMBERS_LIST_FAILURE = 'CLUB_MEMBERS_LIST_FAILURE';

const clubMembersListRequest = () => ({
  type: CLUB_MEMBERS_LIST_REQUEST,
});

const clubMembersListFailure = (error) => ({
  type: CLUB_MEMBERS_LIST_FAILURE,
  payload: { error: error },
});

const clubMembersListSuccess = (data) => ({
  type: CLUB_MEMBERS_LIST_SUCCESS,
  payload: data,
});

export const clubMembersList = (payload) => {
  return (dispatch) => {
    dispatch(clubMembersListRequest());
    return http
      .post(apiUrl.CLUB_MEMBERS_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubMembersListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubMembersListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubMembersListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubMembersListFailure());
        return error;
      });
  };
};

export const CLUB_ATTENDING_MEMBERS_SUCCESS = 'CLUB_ATTENDING_MEMBERS_SUCCESS';
export const CLUB_ATTENDING_MEMBERS_REQUEST = 'CLUB_ATTENDING_MEMBERS_REQUEST';
export const CLUB_ATTENDING_MEMBERS_FAILURE = 'CLUB_ATTENDING_MEMBERS_FAILURE';

const clubAttendingMembersRequest = () => ({
  type: CLUB_ATTENDING_MEMBERS_REQUEST,
});

const clubAttendingMembersFailure = (error) => ({
  type: CLUB_ATTENDING_MEMBERS_FAILURE,
  payload: { error: error },
});

const clubAttendingMembersSuccess = (data) => ({
  type: CLUB_ATTENDING_MEMBERS_SUCCESS,
  payload: data,
});

export const clubAttendingMembers = (payload) => {
  return (dispatch) => {
    dispatch(clubAttendingMembersRequest());
    return http
      .post(apiUrl.CLUB_ATTENDING_MEMBERS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubAttendingMembersSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubAttendingMembersSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubAttendingMembersFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubAttendingMembersFailure());
        return error;
      });
  };
};

export const CLUB_INVITED_MEMBERS_SUCCESS = 'CLUB_INVITED_MEMBERS_SUCCESS';
export const CLUB_INVITED_MEMBERS_REQUEST = 'CLUB_INVITED_MEMBERS_REQUEST';
export const CLUB_INVITED_MEMBERS_FAILURE = 'CLUB_INVITED_MEMBERS_FAILURE';

const clubInvitedMembersRequest = () => ({
  type: CLUB_INVITED_MEMBERS_REQUEST,
});

const clubInvitedMembersFailure = (error) => ({
  type: CLUB_INVITED_MEMBERS_FAILURE,
  payload: { error: error },
});

const clubInvitedMembersSuccess = (data) => ({
  type: CLUB_INVITED_MEMBERS_SUCCESS,
  payload: data,
});

export const clubInvitedMembers = (payload) => {
  return (dispatch) => {
    dispatch(clubInvitedMembersRequest());
    return http
      .post(apiUrl.CLUB_INVITED_MEMBERS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubInvitedMembersSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubInvitedMembersSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubInvitedMembersFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubInvitedMembersFailure());
        return error;
      });
  };
};

export const CLUB_DELETE_SUCCESS = 'CLUB_DELETE_SUCCESS';
export const CLUB_DELETE_REQUEST = 'CLUB_DELETE_REQUEST';
export const CLUB_DELETE_FAILURE = 'CLUB_DELETE_FAILURE';

const clubDeleteRequest = () => ({
  type: CLUB_DELETE_REQUEST,
});

const clubDeleteFailure = (error) => ({
  type: CLUB_DELETE_FAILURE,
  payload: { error: error },
});

const clubDeleteSuccess = (data) => ({
  type: CLUB_DELETE_SUCCESS,
  payload: data,
});

export const clubDelete = (payload) => {
  return (dispatch) => {
    dispatch(clubDeleteRequest());
    return http
      .post(apiUrl.CLUB_DELETE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubDeleteSuccess(response));
          showMessage({
            dispatch,
            message: ' Club Delete Successfully',
            variant: 'success',
          });
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubDeleteSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubDeleteFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubDeleteFailure());
        return error;
      });
  };
};

export const CLUB_LEAVE_SUCCESS = 'CLUB_LEAVE_SUCCESS';
export const CLUB_LEAVE_REQUEST = 'CLUB_LEAVE_REQUEST';
export const CLUB_LEAVE_FAILURE = 'CLUB_LEAVE_FAILURE';

const clubLeaveRequest = () => ({
  type: CLUB_LEAVE_REQUEST,
});

const clubLeaveFailure = (error) => ({
  type: CLUB_LEAVE_FAILURE,
  payload: { error: error },
});

const clubLeaveSuccess = (data) => ({
  type: CLUB_LEAVE_SUCCESS,
  payload: data,
});

export const clubLeave = (payload) => {
  return (dispatch) => {
    dispatch(clubLeaveRequest());
    return http
      .post(apiUrl.CLUB_LEAVE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubLeaveSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubLeaveSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubLeaveFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubLeaveFailure());
        return error;
      });
  };
};

export const CLUB_REQUEST_ACCEPT_SUCCESS = 'CLUB_REQUEST_ACCEPT_SUCCESS';
export const CLUB_REQUEST_ACCEPT_REQUEST = 'CLUB_REQUEST_ACCEPT_REQUEST';
export const CLUB_REQUEST_ACCEPT_FAILURE = 'CLUB_REQUEST_ACCEPT_FAILURE';

const clubRequestAcceptRequest = () => ({
  type: CLUB_REQUEST_ACCEPT_REQUEST,
});

const clubRequestAcceptFailure = (error) => ({
  type: CLUB_REQUEST_ACCEPT_FAILURE,
  payload: { error: error },
});

const clubRequestAcceptSuccess = (data) => ({
  type: CLUB_REQUEST_ACCEPT_SUCCESS,
  payload: data,
});

export const clubRequestAccept = (payload) => {
  return (dispatch) => {
    dispatch(clubRequestAcceptRequest());
    return http
      .post(apiUrl.CLUB_REQUEST_ACCEPT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubRequestAcceptSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubRequestAcceptSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubRequestAcceptFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubRequestAcceptFailure());
        return error;
      });
  };
};

export const CLUB_REQUEST_REJECT_SUCCESS = 'CLUB_REQUEST_REJECT_SUCCESS';
export const CLUB_REQUEST_REJECT_REQUEST = 'CLUB_REQUEST_REJECT_REQUEST';
export const CLUB_REQUEST_REJECT_FAILURE = 'CLUB_REQUEST_REJECT_FAILURE';

const clubRequestRejectRequest = () => ({
  type: CLUB_REQUEST_REJECT_REQUEST,
});

const clubRequestRejectFailure = (error) => ({
  type: CLUB_REQUEST_REJECT_FAILURE,
  payload: { error: error },
});

const clubRequestRejectSuccess = (data) => ({
  type: CLUB_REQUEST_REJECT_SUCCESS,
  payload: data,
});

export const clubRequestReject = (payload) => {
  return (dispatch) => {
    dispatch(clubRequestRejectRequest());
    return http
      .post(apiUrl.CLUB_REQUEST_REJECT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubRequestRejectSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubRequestRejectSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubRequestRejectFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubRequestRejectFailure());
        return error;
      });
  };
};

export const CLUB_POST_LIST_SUCCESS = 'CLUB_POST_LIST_SUCCESS';
export const CLUB_POST_LIST_REQUEST = 'CLUB_POST_LIST_REQUEST';
export const CLUB_POST_LIST_FAILURE = 'CLUB_POST_LIST_FAILURE';

const clubPostListRequest = () => ({
  type: CLUB_POST_LIST_REQUEST,
});

const clubPostListFailure = (error) => ({
  type: CLUB_POST_LIST_FAILURE,
  payload: { error: error },
});

const clubPostListSuccess = (data) => ({
  type: CLUB_POST_LIST_SUCCESS,
  payload: data,
});

export const clubPostList = (payload) => {
  return (dispatch) => {
    dispatch(clubPostListRequest());
    return http
      .post(apiUrl.CLUB_POST_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubPostListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubPostListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubPostListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubPostListFailure());
        return error;
      });
  };
};

export const CLUB_INVITATION_UNSEND_SUCCESS = 'CLUB_INVITATION_UNSEND_SUCCESS';
export const CLUB_INVITATION_UNSEND_REQUEST = 'CLUB_INVITATION_UNSEND_REQUEST';
export const CLUB_INVITATION_UNSEND_FAILURE = 'CLUB_INVITATION_UNSEND_FAILURE';

const clubInvitationUnsendRequest = () => ({
  type: CLUB_INVITATION_UNSEND_REQUEST,
});

const clubInvitationUnsendFailure = (error) => ({
  type: CLUB_INVITATION_UNSEND_FAILURE,
  payload: { error: error },
});

const clubInvitationUnsendSuccess = (data) => ({
  type: CLUB_INVITATION_UNSEND_SUCCESS,
  payload: data,
});

export const clubInvitationUnsend = (payload) => {
  return (dispatch) => {
    dispatch(clubInvitationUnsendRequest());
    return http
      .post(apiUrl.CLUB_INVITATION_UNSEND, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubInvitationUnsendSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubInvitationUnsendSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubInvitationUnsendFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubInvitationUnsendFailure());
        return error;
      });
  };
};

export const CLUB_REQUEST_SUCCESS = 'CLUB_REQUEST_SUCCESS';
export const CLUB_REQUEST_REQUEST = 'CLUB_REQUEST_REQUEST';
export const CLUB_REQUEST_FAILURE = 'CLUB_REQUEST_FAILURE';

const clubRequestRequest = () => ({
  type: CLUB_REQUEST_REQUEST,
});

const clubRequestFailure = (error) => ({
  type: CLUB_REQUEST_FAILURE,
  payload: { error: error },
});

const clubRequestSuccess = (data) => ({
  type: CLUB_REQUEST_SUCCESS,
  payload: data,
});

export const clubRequest = (payload) => {
  return (dispatch) => {
    dispatch(clubRequestRequest());
    return http
      .post(apiUrl.CLUB_REQUEST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubRequestSuccess(response));
          showMessage({
            dispatch,
            message: ' Club Request Sent Successfully',
            variant: 'success',
          });
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubRequestSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubRequestFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubRequestFailure());
        return error;
      });
  };
};

export const CLUB_DISLIKE_SUCCESS = 'CLUB_DISLIKE_SUCCESS';
export const CLUB_DISLIKE_REQUEST = 'CLUB_DISLIKE_REQUEST';
export const CLUB_DISLIKE_FAILURE = 'CLUB_DISLIKE_FAILURE';

const clubDislikeRequest = () => ({
  type: CLUB_DISLIKE_REQUEST,
});

const clubDislikeFailure = (error) => ({
  type: CLUB_DISLIKE_FAILURE,
  payload: { error: error },
});

const clubDislikeSuccess = (data) => ({
  type: CLUB_DISLIKE_SUCCESS,
  payload: data,
});

export const clubDislike = (payload) => {
  return (dispatch) => {
    dispatch(clubDislikeRequest());
    return http
      .post(apiUrl.CLUB_DISLIKE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubDislikeSuccess(response));
          showMessage({
            dispatch,
            message: ' Club Request Sent Successfully',
            variant: 'success',
          });
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubDislikeSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubDislikeFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubDislikeFailure());
        return error;
      });
  };
};

export const CLUB_REQUEST_WITHDRAW_SUCCESS = 'CLUB_REQUEST_WITHDRAW_SUCCESS';
export const CLUB_REQUEST_WITHDRAW_REQUEST = 'CLUB_REQUEST_WITHDRAW_REQUEST';
export const CLUB_REQUEST_WITHDRAW_FAILURE = 'CLUB_REQUEST_WITHDRAW_FAILURE';

const clubRequestWithdrawRequest = () => ({
  type: CLUB_REQUEST_WITHDRAW_REQUEST,
});

const clubRequestWithdrawFailure = (error) => ({
  type: CLUB_REQUEST_WITHDRAW_FAILURE,
  payload: { error: error },
});

const clubRequestWithdrawSuccess = (data) => ({
  type: CLUB_REQUEST_WITHDRAW_SUCCESS,
  payload: data,
});

export const clubRequestWithdraw = (payload) => {
  return (dispatch) => {
    dispatch(clubRequestWithdrawRequest());
    return http
      .post(apiUrl.CLUB_REQUEST_WITHDRAW, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubRequestWithdrawSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubRequestWithdrawSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubRequestWithdrawFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubRequestWithdrawFailure());
        return error;
      });
  };
};

export const CLUB_REMOVE_MEMBER_SUCCESS = 'CLUB_REMOVE_MEMBER_SUCCESS';
export const CLUB_REMOVE_MEMBER_REQUEST = 'CLUB_REMOVE_MEMBER_REQUEST';
export const CLUB_REMOVE_MEMBER_FAILURE = 'CLUB_REMOVE_MEMBER_FAILURE';

const clubRemoveMemberRequest = () => ({
  type: CLUB_REMOVE_MEMBER_REQUEST,
});

const clubRemoveMemberFailure = (error) => ({
  type: CLUB_REMOVE_MEMBER_FAILURE,
  payload: { error: error },
});

const clubRemoveMemberSuccess = (data) => ({
  type: CLUB_REMOVE_MEMBER_SUCCESS,
  payload: data,
});

export const clubRemoveMember = (payload) => {
  return (dispatch) => {
    dispatch(clubRemoveMemberRequest());
    return http
      .post(apiUrl.CLUB_MEMBER_REMOVE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubRemoveMemberSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubRemoveMemberSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubRemoveMemberFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubRemoveMemberFailure());
        return error;
      });
  };
};

export const CLUB_STATUS_FOR_USER_SUCCESS = 'CLUB_STATUS_FOR_USER_SUCCESS';
export const CLUB_STATUS_FOR_USER_REQUEST = 'CLUB_STATUS_FOR_USER_REQUEST';
export const CLUB_STATUS_FOR_USER_FAILURE = 'CLUB_STATUS_FOR_USER_FAILURE';

const clubStatusForUserRequest = () => ({
  type: CLUB_STATUS_FOR_USER_REQUEST,
});

const clubStatusForUserFailure = (error) => ({
  type: CLUB_STATUS_FOR_USER_FAILURE,
  payload: { error: error },
});

const clubStatusForUserSuccess = (data) => ({
  type: CLUB_STATUS_FOR_USER_SUCCESS,
  payload: data,
});

export const clubStatusForUser = (payload) => {
  return (dispatch) => {
    dispatch(clubStatusForUserRequest());
    return http
      .post(apiUrl.CLUB_STATUS_FOR_USER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubStatusForUserSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubStatusForUserSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubStatusForUserFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubStatusForUserFailure());
        return error;
      });
  };
};

export const CLUB_INVITE_USER_SUCCESS = 'CLUB_INVITE_USER_SUCCESS';
export const CLUB_INVITE_USER_REQUEST = 'CLUB_INVITE_USER_REQUEST';
export const CLUB_INVITE_USER_FAILURE = 'CLUB_INVITE_USER_FAILURE';

const clubInviteUserRequest = () => ({
  type: CLUB_INVITE_USER_REQUEST,
});

const clubInviteUserFailure = (error) => ({
  type: CLUB_INVITE_USER_FAILURE,
  payload: { error: error },
});

const clubInviteUserSuccess = (data) => ({
  type: CLUB_INVITE_USER_SUCCESS,
  payload: data,
});

// export const clubInviteUser = (payload) => {
//   return (dispatch) => {
//     dispatch(clubInviteUserRequest());
//     return http
//       .post(apiUrl.CLUB_INVITE_USER, payload)
//       .then((response) => {
//         if (response?.status === 1) {
//           dispatch(clubInviteUserSuccess(response));
//           return response;
//         } else {
//           if (
//             response?.status === -1 &&
//             response?.message === "No record found"
//           ) {
//             dispatch(clubInviteUserSuccess({ data: [] }));
//             return response;
//           } else {
//             dispatch(clubInviteUserFailure(response));
//             return response;
//           }
//         }
//       })
//       .catch((error) => {
//         dispatch(clubInviteUserFailure());
//         return error;
//       });
//   };
// };

export const CLUB_UPDATE_SUCCESS = 'CLUB_UPDATE_SUCCESS';
export const CLUB_UPDATE_REQUEST = 'CLUB_UPDATE_REQUEST';
export const CLUB_UPDATE_FAILURE = 'CLUB_UPDATE_FAILURE';

const clubUpdateRequest = () => ({
  type: CLUB_UPDATE_REQUEST,
});

const clubUpdateFailure = (error) => ({
  type: CLUB_UPDATE_FAILURE,
  payload: { error: error },
});

const clubUpdateSuccess = (data) => ({
  type: CLUB_UPDATE_SUCCESS,
  payload: data,
});

export const clubUpdate = (payload, statuschange, id, callback) => {
  return (dispatch) => {
    dispatch(clubUpdateRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.CLUB_UPDATE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(clubUpdateFailure(error));
          showMessage({
            dispatch,
            message: 'Job Update failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(clubUpdateSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          if (id === 0) {
            showMessage({
              dispatch,
              message: 'Club successfully Updated',
              variant: 'success',
            });
          } else if (id !== 0 && !statuschange) {
            showMessage({
              dispatch,
              message: 'Club successfully Updated',
              variant: 'success',
            });
          } else if (id !== 0 && statuschange) {
            showMessage({
              dispatch,
              message: 'Club successfully Updated',
              variant: 'success',
            });
          }
          callback(null, response);
          return response;
        }
      },
    );
    // .then((response) =>{
    //   return response
    // })
    // .catch((error) =>{
    //   return error
    // })
  };
};
// export const clubUpdate = (payload) => {
//   return (dispatch) => {
//     dispatch(clubUpdateRequest());
//     return http
//       .post(apiUrl.CLUB_UPDATE, payload)
//       .then((response) => {
//         if (response?.status === 1) {
//           dispatch(clubUpdateSuccess(response));
//           return response;
//         } else {
//           if (
//             response?.status === -1 &&
//             response?.message === "No record found"
//           ) {
//             dispatch(clubUpdateSuccess({ data: [] }));
//             return response;
//           } else {
//             dispatch(clubStatusForUserFailure(response));
//             return response;
//           }
//         }
//       })
//       .catch((error) => {
//         dispatch(clubUpdateFailure());
//         return error;
//       });
//   };
// };

export const clubInviteUser = (payload, callback) => {
  return (dispatch) => {
    dispatch(clubInviteUserRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.CLUB_INVITE_USER,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(clubInviteUserFailure(error));
          showMessage({
            dispatch,
            message: ' Club invitation sent failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(clubInviteUserSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          showMessage({
            dispatch,
            message: 'Club invitation sent successfully',
            variant: 'success',
          });
          callback(null, response);
          return response;
        }
      },
    );
  };
};

export const CLUB_EVENT_LIST_SUCCESS = 'CLUB_EVENT_LIST_SUCCESS';
export const CLUB_EVENT_LIST_REQUEST = 'CLUB_EVENT_LIST_REQUEST';
export const CLUB_EVENT_LIST_FAILURE = 'CLUB_EVENT_LIST_FAILURE';

const clubEventListRequest = () => ({
  type: CLUB_EVENT_LIST_REQUEST,
});

const clubEventListFailure = (error) => ({
  type: CLUB_EVENT_LIST_FAILURE,
  payload: { error: error },
});

const clubEventListSuccess = (data) => ({
  type: CLUB_EVENT_LIST_SUCCESS,
  payload: data,
});

export const clubEventList = (payload) => {
  return (dispatch) => {
    dispatch(clubEventListRequest());
    return http
      .post(apiUrl.CLUB_EVENT_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubEventListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubEventListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubEventListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubEventListFailure());
        return error;
      });
  };
};

export const CLUB_MEMBER_FRIEND_LIST_SUCCESS =
  'CLUB_MEMBER_FRIEND_LIST_SUCCESS';
export const CLUB_MEMBER_FRIEND_LIST_REQUEST =
  'CLUB_MEMBER_FRIEND_LIST_REQUEST';
export const CLUB_MEMBER_FRIEND_LIST_FAILURE =
  'CLUB_MEMBER_FRIEND_LIST_FAILURE';

const clubMemberFriendListRequest = () => ({
  type: CLUB_MEMBER_FRIEND_LIST_REQUEST,
});

const clubMemberFriendListFailure = (error) => ({
  type: CLUB_MEMBER_FRIEND_LIST_FAILURE,
  payload: { error: error },
});

const clubMemberFriendListSuccess = (data) => ({
  type: CLUB_MEMBER_FRIEND_LIST_SUCCESS,
  payload: data,
});

export const clubMemberFriendList = (payload) => {
  return (dispatch) => {
    dispatch(clubMemberFriendListRequest());
    return http
      .post(apiUrl.CLUB_MEMBER_FRIEND_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(clubMemberFriendListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(clubMemberFriendListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(clubMemberFriendListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(clubMemberFriendListFailure());
        return error;
      });
  };
};

export const CLUB_MANAGE_SUCCESS = 'CLUB_MANAGE_SUCCESS';
export const CLUB_MANAGE_REQUEST = 'CLUB_MANAGE_REQUEST';
export const CLUB_MANAGE_FAILURE = 'CLUB_MANAGE_FAILURE';

const clubManageRequest = () => ({
  type: CLUB_MANAGE_REQUEST,
});

const clubManageFailure = (error) => ({
  type: CLUB_MANAGE_FAILURE,
  payload: { error: error },
});

const clubManageSuccess = (data) => ({
  type: CLUB_MANAGE_SUCCESS,
  payload: { club: data },
});

export const clubManage = (payload, statuschange, id, callback) => {
  return (dispatch) => {
    dispatch(clubManageRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.CLUB_MANAGE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(clubManageFailure(error));
          showMessage({
            dispatch,
            message: 'Club create failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(clubManageSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          if (id === 0) {
            showMessage({
              dispatch,
              message: 'Club successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && !statuschange) {
            showMessage({
              dispatch,
              message: 'Club successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && statuschange) {
            showMessage({
              dispatch,
              message: 'Club successfully created',
              variant: 'success',
            });
          }
          callback(null, response);
          return response;
        }
      },
    );
    // .then((response) =>{
    //   return response
    // })
    // .catch((error) =>{
    //   return error
    // })
  };
};

export const CLOSE_SHARE_CLUB = 'CLOSE_SHARE_CLUB';
export const OPEN_SHARE_CLUB = 'OPEN_SHARE_CLUB';

export const closeShareClub = () => ({
  type: 'CLOSE_SHARE_CLUB',
});

export const openShareClub = (clubdetails) => {
  return {
    type: 'OPEN_SHARE_CLUB',
    payload: clubdetails,
  };
};

export const CLOSE_DETAILS_CLUB = 'CLOSE_DETAILS_CLUB';
export const OPEN_DETAILS_CLUB = 'OPEN_DETAILS_CLUB';

export const closeDetailsClub = () => ({
  type: 'CLOSE_DETAILS_CLUB',
});

export const openDetailsClub = (clubdetails) => {
  return {
    type: 'OPEN_DETAILS_CLUB',
    payload: clubdetails,
  };
};

export const CLOSE_SEND_INVITEES_CLUB = 'CLOSE_SEND_INVITEES_CLUB';
export const OPEN_SEND_INVITEES_CLUB = 'OPEN_SEND_INVITEES_CLUB';

export const closeSendInviteesClub = () => ({
  type: 'CLOSE_SEND_INVITEES_CLUB',
});

export const openSendInviteesClub = (clubdetails) => {
  return {
    type: 'OPEN_SEND_INVITEES_CLUB',
    payload: clubdetails,
  };
};

export const reportClubOpen = (clubd) => {
  return {
    type: 'REPORT_CLUB_OPEN',
    payload: clubd,
  };
};

export const reportClubClose = (clubd) => {
  return {
    type: 'REPORT_CLUB_CLOSE',
  };
};

export const editClubOpen = (clubd) => {
  return {
    type: 'EDIT_CLUB_OPEN',
    payload: clubd,
  };
};

export const editClubClose = () => {
  return {
    type: 'EDIT_CLUB_CLOSE',
  };
};

export const CLOSE_POST_EDIT = 'CLOSE_POST_EDIT';
export const OPEN_POST_EDIT = 'OPEN_POST_EDIT';

export const closePostEdit = () => ({
  type: 'CLOSE_POST_EDIT',
});

export const openPostEdit = () => ({
  type: 'OPEN_POST_EDIT',
});
