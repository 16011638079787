import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BgImage from '../../assets/auth/BG.png';
import logo from '../../assets/header/Link_Logo_White.svg';
import useStyles from './authstyles';
import Signin from './Signin/Signin';
import Signup from './Signup/Signup';
import Otp from './Signin/Otp';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import * as authActions from '../../store/auth';
import * as messageAction from '../../store/message';
import * as onboardActions from '../../store/onboard';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import * as userActions from '../../store/user';
import * as usernetworkActions from '../../store/usernetwork';
import * as postActions from '../../store/post';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useParams, useLocation } from 'react-router-dom';
import { dateTime } from '../../constants/app';
import * as activityActions from '../../store/activity';
import { v4 as uuidv4 } from 'uuid';

const AuthenticationContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(true);
  const [swtch, setSwitch] = React.useState(true);
  const [otpchecked, setOtpchecked] = React.useState(false);
  const [policyagree, setPolicyagree] = React.useState(true);
  const [otpUser, setOtpUser] = React.useState('');
  const [redirect, setRedirect] = React.useState('');
  const [type, setType] = React.useState('mobile');
  const [open, setOpen] = React.useState(false);
  const [minutes, setMinutes] = React.useState(1);
  const [seconds, setSeconds] = React.useState(4);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const tempUserId = useSelector((state) => state?.activity?.tempUserId);

  let param = useParams();
  let location = useLocation();
  useEffect(() => {
    if (location?.pathname.includes('/login')) {
      setChecked(true);
    } else if (location?.pathname.includes('/signup')) {
      setChecked(false);
    }
  }, []);

  useEffect(async () => {
    if (location?.search?.includes('job_apply')) {
      var redirecturi = window.location.href.split('redirect=')[1];
      const redrurl = await dispatch(
        onboardActions.setRedirectUrl(redirecturi),
      );
      setRedirect(redirecturi);
    } else if (
      !location?.search?.includes('job_apply') &&
      location?.search?.length > 0
    ) {
      const redrurl = await dispatch(
        onboardActions.setRedirectUrl(location?.search),
      );
    } else {
      const redrurl = await dispatch(onboardActions.setRedirectUrl(''));
    }
  }, []);

  React.useEffect(() => {
    async function postUserTrack() {
      if (location?.search?.includes('campaign')) {
        if (tempUserId == 0 || tempUserId == undefined) {
          const data = {
            tempUserIdo: uuidv4(),
          };
          const assign = await dispatch(activityActions.assignUserId(data));
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: data?.tempUserIdo,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        } else {
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: tempUserId,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        }

        // navigate('/');
      }
    }
    postUserTrack();
  }, [location]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const navigateHome = () => {
    if (location?.search) {
      let path = '/' + location?.search;
      navigate(path);
    } else {
      navigate('/');
    }
  };
  const handleChange = (event) => {
    if (checked) {
      let signuppath = '/signup';
      if (location?.search) {
        signuppath = signuppath + location?.search;
      }
      navigate(signuppath);
      setChecked(!checked);
    } else {
      let loginpath = '/login';
      if (location?.search) {
        loginpath = loginpath + location?.search;
      }
      navigate(loginpath);
      setChecked(!checked);
    }
  };
  const handleswitchChange = (event) => {
    setSwitch(!swtch);
  };

  const handleOtp = async (data, country) => {
    console.log('data', data);
    let sendotpdata = {};
    let checkmobile = {};
    if (swtch) {
      sendotpdata = {
        username: data?.mobile,
        country_code: '+' + data?.country_code,
      };
      checkmobile = {
        mobile: data?.mobile,
        email: '',
      };
      setType('mobile');
    } else {
      sendotpdata = {
        username: data?.email,
        country_code: '+91',
      };
      checkmobile = {
        mobile: '',
        email: data?.email,
      };
      setType('email');
    }

    const userexist = await dispatch(authActions.checkMobileEmail(checkmobile));
    //     const profilefield = await dispatch(
    //   authActions.profileRequiredField({
    //     user_id: login?.data?.user_details?.id,
    //   })
    // );
    // console.log("==================profilefield", profilefield);

    if (userexist?.status === -1 && userexist?.data[0]?.signup_status === 1) {
      const checkauth = await dispatch(
        authActions.sendEmailOtp(sendotpdata, 'signedin'),
      );
      // const checkauth = await dispatch(authActions.sendEmailOtp(data));
      // console.log("checkauth", checkauth);
      if (checkauth?.status === 1) {
        setOtpUser(sendotpdata);
        setChecked(!checked);
        setOtpchecked(!otpchecked);
      }
    } else if (
      userexist?.status === -1 &&
      userexist?.data[0]?.signup_status === 0
    ) {
      let msg = '';
      if (data?.mobile) {
        msg =
          'you have not signed up using this mobile number. Please sign up.';
      } else if (data?.email) {
        msg = 'you have not signed up using this email ID. Please sign up.';
      }
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
      setChecked(false);
    } else {
      let msg = 'hi';
      if (userexist?.status === 1) {
        if (data?.mobile) {
          msg =
            'you have not signed up using this mobile number. Please sign up.';
        } else if (data?.email) {
          msg = 'you have not signed up using this email ID. Please sign up.';
        }
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(msg, 'error'),
        );
        setChecked(false);
      }
    }
  };

  const onclickOtpSignup = async (data) => {
    let sendotpdata = {};
    let checkmobile = {};
    if (swtch) {
      sendotpdata = {
        username: data?.mobile,
        country_code: '+' + data.country_code,
      };
      checkmobile = {
        mobile: data?.mobile,
        email: '',
      };
      setType('mobile');
    } else {
      sendotpdata = {
        username: data?.email,
        country_code: '+91',
      };
      checkmobile = {
        mobile: '',
        email: data?.email,
      };
      setType('email');
    }

    const userexist = await dispatch(authActions.checkMobileEmail(checkmobile));
    if (userexist?.status === 1) {
      const checkauth = await dispatch(
        authActions.sendEmailOtp(sendotpdata, 'signedup'),
      );
      // const checkauth = await dispatch(authActions.sendEmailOtp(data));
      if (checkauth?.status === 1) {
        setOtpUser(sendotpdata);
        setChecked(false);
        setOtpchecked(true);
      }
    } else {
      if (userexist?.status === -1 && userexist?.data[0]?.signup_status === 0) {
        const checkauth = await dispatch(
          authActions.sendEmailOtp(sendotpdata, 'signedup'),
        );
        // const checkauth = await dispatch(authActions.sendEmailOtp(data));
        if (checkauth?.status === 1) {
          setOtpUser(sendotpdata);
          setChecked(false);
          setOtpchecked(true);
        }
      } else {
        let msg = '';
        if (data?.mobile) {
          msg =
            'This mobile is already Signed Up,  Please Login or try with different Mobile No';
        } else if (data?.email) {
          msg =
            'This Email Id is already Signed Up,  Please Login or try with different Email Id';
        }
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(msg, 'error'),
        );
        setChecked(true);
      }
    }
  };

  const handleChangeText = (swt) => {
    if (swt === 'checked') {
      setSwitch(false);
    } else if (swt === 'unchecked') {
      setSwitch(true);
    }
  };

  const handleChangepolicy = () => {
    setPolicyagree(!policyagree);
  };
  const attemptLogin = async (username, user_id) => {
    const login = await dispatch(
      authActions.attemptLogin({
        username: username,
        temp_user_id: 0,
        id: user_id,
        type: type,
      }),
    );

    if (login?.message === 'Success') {
      const data = {
        user_id: login?.data?.user_details?.id,
        self_id: login?.data?.user_details?.id,
        datetime: dateTime(),
      };

      const users = await dispatch(userActions.userProfileDetails(data));

      const data1 = {
        user_id: login?.data?.user_details?.id,
        start_item: 0,
        item_count: 10,
        datetime: dateTime(),
      };

      const formData = new FormData();
      formData.append('user_id', login?.data?.user_details?.id);
      formData.append('start_item', 0);
      formData.append('item_count', 10);
      formData.append('datetime', dateTime());

      const postlist = await dispatch(postActions.getPostList(formData, data1));

      const data3 = {
        user_id: login?.data?.user_details?.id,
      };
      const clubList = await dispatch(clubsActions.discoverClubList(data3));
      const data4 = {
        user_id: login?.data?.user_details?.id,
        categoryId: '',
      };
      const eventList = await dispatch(eventsActions.discoverEventList(data4));
      var redirect_uri = window.location.href.split('redirect=')[1];

      if (users?.data?.signup_status === 1) {
        if (redirect_uri != undefined) {
          //var redirect_jobs = "/" + redirect_uri;
          let redirecturl = '/user/details' + location?.search;
          console.log('redirect_uri', redirect_uri);
          navigate(redirect_uri);
        } else {
          const sendmessage = await dispatch(
            messageAction.messagefromComponent('Login Successful', 'success'),
          );
          navigate('/feed');
        }
      } else if (users?.data?.signup_status === 0) {
        let redirecturl = '/user/details' + location?.search;
        console.log('redirect_uri', redirect_uri);
        navigate(redirecturl);
      }
    }

    // const profilefield = await dispatch(
    //   authActions.profileRequiredField({
    //     user_id: login?.data?.user_details?.id,
    //   })
    // );
    // console.log("==================profilefield", profilefield);

    // console.log('redirect_uri', redirect_uri, window.location);
    // if (profilefield?.status == 1) {
    //   if (redirect_uri != undefined) {
    //     //var redirect_jobs = "/" + redirect_uri;
    //     console.log('redirect_uri', redirect_uri);
    //     navigate(redirect_uri);
    //   } else {
    //     const sendmessage = await dispatch(
    //       messageAction.messagefromComponent("Login Successful", "success")
    //     );
    //     navigate("/feed");
    //   }
    // } else if (profilefield?.status == -1) {
    //   if (redirect_uri != undefined) {
    //     let redirecturl = "/user/details" +  location?.search
    //     navigate(redirecturl);
    //   } else {
    //     navigate("/user/details");
    //   }
    //   // if (redirect_uri != undefined) {
    //   //   //var redirect_jobs = "/" + redirect_uri;
    //   //   console.log('redirect_uri', redirect_uri);
    //   //   navigate(redirect_uri);
    //   // } else {
    //   //   const sendmessage = await dispatch(
    //   //     messageAction.messagefromComponent("Login Successful", "success")
    //   //   );
    //   //   navigate("/feed");
    //   // }

    // } else if (profilefield?.status == -2) {
    //   navigate("/login");
    // }
  };

  // (profilefield?.data?.profile_about == 0) {
  //   navigate("/addbio");
  // } else if (profilefield?.data?.user_interest == 0) {
  //   navigate("/addinterest");

  const checkOtp = async (username, otp) => {
    const data = { username: username.username, otp: otp, temp_user_id: 0 };
    const checkotp = await dispatch(authActions.verifyOtp(data));
    if (checkotp.status === 1) {
      return attemptLogin(username.username, checkotp.data.user_id);
    }
  };
  const resendOtp = async (user) => {
    setMinutes(2);
    setSeconds(59);
    const data = { username: user, country_code: '+91' };
    const resendotp = await dispatch(authActions.sendEmailOtp(otpUser));
    // console.log("resendotp", resendotp);
  };
  const classes = useStyles();
  return (
    <Fragment>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {openSnackbar && <CustomizedSnackbars />}
      <Paper
        elevation={0}
        sx={{
          backgroundImage: `url(${BgImage})`,
          height: 'inherit',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <Grid container spacing={0} className={classes.outergrid}>
          <Grid item xs={12} md={6} sm={12}>
            <Box pl={5} pt={2} onClick={navigateHome}>
              <img alt="logo" className={classes.logo} height={32} src={logo} />
            </Box>

            {window.location.href.includes('job_apply') && (
              <Box className={classes.singupmessage}>
                <Typography
                  variant="h2"
                  component="h2"
                  sx={{ fontSize: { xs: '16px', md: '32px' } }}>
                  Sign up/Login to Apply
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {checked && !otpchecked && (
              <Signin
                onClick={handleChange}
                onclickOtp={handleOtp}
                redirect={redirect}
                handleChange={handleswitchChange}
                handleChangeText={handleChangeText}
                checked={swtch}
              />
            )}

            {!checked && !otpchecked && (
              <Signup
                onClick={handleChange}
                onclickOtpSignup={onclickOtpSignup}
                handleChange={handleswitchChange}
                handleChangeText={handleChangeText}
                policyagree={policyagree}
                redirect={redirect}
                handleChangepolicy={handleChangepolicy}
                checked={swtch}
              />
            )}
            {!checked && otpchecked && (
              <Otp
                user={otpUser}
                minutes={minutes}
                seconds={seconds}
                resendOtp={resendOtp}
                onCheckOtp={checkOtp}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default React.memo(AuthenticationContainer);
