import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Network from '../../resources/new-img/Networks.png';
import AI_chatbot from '../../resources/new-img/Chat.png';
import Career from '../../resources/new-img/Jobs.png';
// import Meetups from "../../resources/new-img/Meetups.png";
import mobile1 from '../../resources/new-img/Network-mob.png';
import mobile2 from '../../resources/new-img/ChatBot-01.png';
import mobile6 from '../../resources/new-img/Events-mob.png';
import mobile5 from '../../resources/new-img/Clubs-mob.png';
import mobile7 from '../../resources/new-img/Business-mob.png';
import mobile3 from '../../resources/new-img/Jobs-mob.png';
import mobile4 from '../../resources/new-img/Meetup-mob.png';
import mobile8 from '../../resources/new-img/Reward-mob.png';
import gPlay from '../../resources/new-img/GooglePlaystore.png';
import Club from '../../resources/new-img/Clubs.png';
import Event from '../../resources/new-img/Events.png';
import Business from '../../resources/new-img/Business.png';
import Rewards from '../../resources/new-img/Credits.png';
// import AvgEngRate from "../../resources/new-img/Average Engagement Rate.png";
// import AvgRetRate from "../../resources/new-img/Average Retention Rate.png";
// import Seo from "../../resources/new-img/seo-new.png";
class Features extends Component {
  constructor(props) {
    super();
    this.state = {
      selected: 'mobile1',
    };
  }

  onMouseEnter = (value) => {
    this.setState({ selected: value });
  };

  render() {
    return (
      <Container fluid className="displayhide">
        <Row>
          <Col>
            <Row
              className="nl-main-container-style"
              style={{ justifyContent: 'center' }}>
              <Col md={4} xs={12} style={{ marginRight: '-60px' }}>
                <div
                  className="features-division1"
                  onMouseEnter={this.onMouseEnter.bind(this, 'mobile1')}
                  style={{
                    backgroundColor:
                      this.state.selected === 'mobile1'
                        ? 'rgba(56,205,132, 0.25)'
                        : '',
                    borderRadius:
                      this.state.selected === 'mobile1' ? '8px' : '',
                  }}>
                  {' '}
                  <div className="text-division1">
                    <p className="nl-bold-content-style1">Community Connect</p>

                    <p className="nl-content-style1">
                      Exclusive and meaningful connections from a pool of 25000+
                      Professionals.
                    </p>
                  </div>
                  <div
                    className="img-division1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100px',
                    }}>
                    <img alt="" src={Network} className="nl-icon-style" />
                  </div>
                </div>

                <div
                  className="features-division1"
                  onMouseEnter={this.onMouseEnter.bind(this, 'mobile2')}
                  style={{
                    background:
                      this.state.selected === 'mobile2'
                        ? 'rgba(56,205,132, 0.25)'
                        : '',
                    borderRadius:
                      this.state.selected === 'mobile2' ? '8px' : '',
                  }}>
                  {' '}
                  <div className="text-division1">
                    <p className="nl-bold-content-style1">AI enabled Chatbot</p>
                    <p className="nl-content-style1">
                      Latest Industry Research reports curated just for the
                      Senior Professionals.
                    </p>
                  </div>
                  <div
                    className="img-division1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100px',
                    }}>
                    {' '}
                    <img alt="" src={AI_chatbot} className="nl-icon-style" />
                  </div>
                </div>

                <div
                  className="features-division1"
                  onMouseEnter={this.onMouseEnter.bind(this, 'mobile3')}
                  style={{
                    background:
                      this.state.selected === 'mobile3'
                        ? 'rgba(56,205,132, 0.25)'
                        : '',
                    borderRadius:
                      this.state.selected === 'mobile3' ? '8px' : '',
                    // paddingBottom: this.state.selected === "mobile3" ? "20px" : "",
                    // paddingRight: this.state.selected === "mobile3" ? "15px" : "",
                  }}>
                  {' '}
                  <div className="text-division1">
                    {' '}
                    <p className="nl-bold-content-style1">Jobs</p>
                    <p className="nl-content-style1">
                       Premium Jobs for C-Level Excutives, right recruitment
                      opportunities.
                    </p>
                  </div>
                  <div
                    className="img-division1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100px',
                    }}>
                    {' '}
                    <img alt="" src={Career} className="nl-icon-style" />
                  </div>
                </div>

                {/* <div
              className="features-division1"
              onMouseEnter={this.onMouseEnter.bind(this, "mobile4")}
              style={{
                background:
                  this.state.selected === "mobile4"
                    ? "rgba(56,205,132, 0.25)"
                    : "",
                borderRadius: this.state.selected === "mobile4" ? "8px" : "",
              }}
            >
              {" "}
              <div className="text-division1">
                <p className="nl-bold-content-style1">Meetups</p>
                <p className="nl-content-style1">
                  AI-Powered CxO Meetup Recommendations
                </p>
              </div>
              <div className="img-division1">
                <img alt="" src={Meetups} className="nl-icon-style" />
              </div>
            </div> */}
              </Col>
              <Col md={3} xs={12}>
                <div
                  style={{
                    width: '100%',
                    padding: '0px 10px',
                  }}>
                  <div>
                    {this.state.selected === 'mobile1' ? (
                      <img
                        alt=""
                        src={mobile1}
                        className="nl-phone-image-style"
                      />
                    ) : (
                      ''
                    )}
                    {this.state.selected === 'mobile2' ? (
                      <img
                        alt=""
                        src={mobile2}
                        className="nl-phone-image-style"
                      />
                    ) : (
                      ''
                    )}
                    {this.state.selected === 'mobile3' ? (
                      <img
                        alt=""
                        src={mobile3}
                        className="nl-phone-image-style"
                      />
                    ) : (
                      ''
                    )}

                    {this.state.selected === 'mobile5' ? (
                      <img
                        alt=""
                        src={mobile5}
                        className="nl-phone-image-style"
                      />
                    ) : (
                      ''
                    )}
                    {this.state.selected === 'mobile6' ? (
                      <img
                        alt=""
                        src={mobile6}
                        className="nl-phone-image-style"
                      />
                    ) : (
                      ''
                    )}

                    {this.state.selected === 'mobile8' ? (
                      <img
                        alt=""
                        src={mobile8}
                        className="nl-phone-image-style"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.linkcxo"
                      target="blank"></a>
                  </div>
                </div>
              </Col>

              <Col md={4} xs={12} style={{ marginLeft: '-80px' }}>
                <div
                  className="features-division"
                  onMouseEnter={this.onMouseEnter.bind(this, 'mobile5')}
                  style={{
                    background:
                      this.state.selected === 'mobile5'
                        ? 'rgba(56,205,132, 0.25)'
                        : '',

                    borderRadius:
                      this.state.selected === 'mobile5' ? '8px' : '',
                  }}>
                  {' '}
                  <div className="text-division">
                    <p className="nl-bold-content-style">Clubs </p>
                    <p className="nl-content-style">
                      Create/ join customized clubs for liked-minded
                      individuals.
                    </p>
                  </div>
                  <div
                    className="img-division"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100px',
                    }}>
                    {' '}
                    <img alt="" src={Club} className="nl-icon-style" />
                  </div>
                </div>

                <div
                  className="features-division"
                  onMouseEnter={this.onMouseEnter.bind(this, 'mobile6')}
                  style={{
                    background:
                      this.state.selected === 'mobile6'
                        ? 'rgba(56,205,132, 0.25)'
                        : '',
                    borderRadius:
                      this.state.selected === 'mobile6' ? '8px' : '',
                  }}>
                  {' '}
                  <div className="text-division">
                    <p className="nl-bold-content-style">Events </p>
                    <p className="nl-content-style">
                      Host & attend our Elite events of the C-Suite community.
                    </p>
                  </div>
                  <div
                    className="img-division"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100px',
                    }}>
                    {' '}
                    <img alt="" src={Event} className="nl-icon-style" />
                  </div>
                </div>

                {/* <div
              className="features-division"
              onMouseEnter={this.onMouseEnter.bind(this, "mobile7")}
              style={{
                background:
                  this.state.selected === "mobile7"
                    ? "rgba(56,205,132, 0.25)"
                    : "",
                borderRadius: this.state.selected === "mobile7" ? "8px" : "",
              }}
            >
              {" "}
              <div className="text-division">
                <p className="nl-bold-content-style">Business</p>
                <p className="nl-content-style">
                  A service based marketplace for CxOs
                </p>
              </div>
              <div className="img-division">
                <img alt="" src={Business} className="nl-icon-style" />
              </div>
            </div> */}

                <div
                  className="features-division"
                  onMouseEnter={this.onMouseEnter.bind(this, 'mobile8')}
                  style={{
                    background:
                      this.state.selected === 'mobile8'
                        ? 'rgba(56,205,132, 0.25)'
                        : '',
                    backgroundPositionY:
                      this.state.selected === 'mobile8' ? '8px' : '',
                    borderRadius:
                      this.state.selected === 'mobile8' ? '8px' : '',
                    backgroundPosition:
                      this.state.selected === 'mobile8' ? 'center' : '',
                  }}>
                  {' '}
                  <div className="text-division">
                    <p className="nl-bold-content-style">Rewards</p>
                    <p className="nl-content-style">
                      Chance to Earn credits and  Redeem Premium Exclusive
                      Vouchers
                    </p>
                  </div>
                  <div
                    className="img-division"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100px',
                    }}>
                    {' '}
                    <img alt="" src={Rewards} className="nl-icon-style" />
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row className="nl-about-us" style={{ marginTop: "80px" }}>
              <Col>
                <Row>
                  <Col>
                    {" "}
                    <h2 className="color-green metrics-engagement">
                      Metrics & Engagement
                    </h2>
                  </Col>
                </Row>
                <Row md={12} className="nl-about-us-count-sec">
                  <Col md={4} className="count-col1">
                    <p className="color-green">4.1</p>
                    <h4>Playstore Rating</h4>
                  </Col>
                  <Col md={4} className="count-col2">
                    <p className="color-green">5000+</p>
                    <h4>Users</h4>
                  </Col>
                  <Col md={4} className="count-col3">
                    <p className="color-green">36 mins</p>
                    <h4>Average Engagement Time</h4>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingBottom: "80px",
                  }}
                >
                  <Col>
                    <Row
                      style={{
                        paddingLeft: "100px",
                        paddingRight: "100px",
                      }}
                    >
                      <Col sm={6}>
                        {" "}
                        <img alt="" src={Seo} className="seo" />
                        <h5 className="color-green">
                          Ranking No."1" for CxO Networking
                        </h5>
                      </Col>
                      <Col sm={6}>
                        {" "}
                        <img alt="" src={AvgEngRate} className="avgeng" />
                        <h5 className="color-green">
                          Exponential Growth in Engagement Rate
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <img alt="" src={AvgRetRate} className="avgret" />
                        <h5 className="color-green">Average Retention Rate</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Features;
