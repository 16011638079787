import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import IconButton from '@mui/material/IconButton';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as jobsActions from '../../store/jobs';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: 'rgba(111, 106, 106, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(111, 106, 106, 0.25)',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#fff !important',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#fff !important',
    [theme.breakpoints.up('md')]: {
      width: '45ch',
    },
  },
}));

const SearchBox = (props) => {
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchTxt, setSearchTxt] = React.useState('');
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.UserDetails);
  const searchresult = useSelector((state) => state?.search?.SearchList);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));
  const handleChange = (newValue) => {
    setType(newValue);
  };
  const updateTxt = (event) => {
    setSearchTxt(event.target.value);
  };
  const handleKeyDown = async (e) => {
    if (e.keyCode === 13 && searchTxt.length > 0) {
      if (location.pathname.includes('/job/searchresult')) {
        const data = {
          search_key: searchTxt,
          page_no: 0,
          user_id: user?.id,
        };
        const searchresult = await dispatch(jobsActions.getJobSearch(data));
        navigate('/job/searchresult?keywords=' + searchTxt + '&pageno=0');
      } else {
        navigate('/job/searchresult?keywords=' + searchTxt + '&pageno=0');
      }
    }
  };
  const searchAll = async () => {
    if (searchTxt.length > 0) {
      if (location.pathname.includes('/job/searchresult')) {
        const data = {
          search_key: searchTxt,
          page_no: 0,
          user_id: user?.id,
        };
        const searchresult = await dispatch(jobsActions.getJobSearch(data));
        navigate('/job/searchresult?keywords=' + searchTxt + '&pageno=0');
      } else {
        navigate('/job/searchresult?keywords=' + searchTxt + '&pageno=0');
      }
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Box sx={{ width: { xs: '70%', md: '600px' } }}>
          <Search>
            <Stack spacing={0} direction="row" justifyContent="space-between">
              <StyledInputBase
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search' }}
                value={searchTxt}
                onKeyDown={handleKeyDown}
                onChange={updateTxt}
                sx={{
                  width: '-moz-available',
                  width: '100%',
                  width: '-webkit-fill-available',
                  color: '#ffffff',
                }}
              />
              <IconButton
                onClick={() => {
                  searchAll();
                }}>
                <SearchIcon sx={{ color: '#959498' }} />
              </IconButton>
            </Stack>
          </Search>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(SearchBox);

// <Typography
//   variant={type === "industryreport" ? "h2s" : "h2"}
//   component={type === "industryreport" ? "h2s" : "h2"}
// >
//   Report
// </Typography>

// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("events");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="events"
//       color={type === "events" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Typography
//       variant={type === "events" ? "h2s" : "h2"}
//       component={type === "events" ? "h2s" : "h2"}
//     >
//       Events
//     </Typography>
//   </Box>
// </Stack>

// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("clubs");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="clubs"
//       color={type === "clubs" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Typography
//       variant={type === "clubs" ? "h2s" : "h2"}
//       component={type === "clubs" ? "h2s" : "h2"}
//     >
//       Clubs
//     </Typography>
//   </Box>
// </Stack>

//
// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("industryreport");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="industryreport"
//       color={type === "industryreport" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Stack direction="column">
//       <Typography
//         variant={type === "industryreport" ? "h2s" : "h2"}
//         component={type === "industryreport" ? "h2s" : "h2"}
//       >
//         Industry
//       </Typography>
//     </Stack>
//   </Box>
// </Stack>

//
// <Stack
//   px={2}
//   py={1}
//   spacing={0}
//   sx={{ cursor: "pointer" }}
//   direction="column"
//   onClick={() => {
//     handleChange("newsletters");
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       height: "24px",
//     }}
//   >
//     <Menuicon
//       name="newsletters"
//       color={type === "newsletters" ? "#38CD84" : "#ffffff"}
//     />
//   </Box>
//   <Box
//     py={0.5}
//     sx={{ display: "flex", justifyContent: "center" }}
//   >
//     <Typography
//       variant={type === "newsletters" ? "h2s" : "h2"}
//       component={type === "newsletters" ? "h2s" : "h2"}
//     >
//       Newsletters
//     </Typography>
//   </Box>
// </Stack>

//
// {type === "All" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="clubs" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Clubs
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}
//
// {type === "clubs" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="clubs" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Clubs
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}

//
// {type === "All" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="events" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Events
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}
//
// {type === "events" && (
//   <Paper
//     sx={{
//       marginTop: "8px",
//       padding: "16px",
//       backgroundColor: "#232121",
//       backgroundImage: "none",
//     }}
//   >
//     <Stack px={2} py={1} spacing={0} direction="row">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           height: "24px",
//           width: "24px",
//         }}
//       >
//         <Menuicon name="events" color="#38CD84" />
//       </Box>
//       <Box
//         py={0.5}
//         px={2}
//         sx={{ display: "flex", justifyContent: "center" }}
//       >
//         <Typography variant="h2s" component="h2s">
//           Events
//         </Typography>
//       </Box>
//     </Stack>
//   </Paper>
// )}
