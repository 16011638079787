import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CompanyPageCard from '../../components/CompanyPageCard/CompanyPageCard';
import * as companyActions from '../../store/company';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const CompanyManagePage = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofAttending, setNoofAttending] = React.useState(4);

  const user = useSelector((state) => state?.user?.UserDetails);
  const Attendinglist = useSelector(
    (state) => state?.events?.eventAttendingList,
  );
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const eventDetails = useSelector((state) => state?.events?.eventDetails);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const clubMembers = useSelector((state) => state?.clubs?.clubMembers);
  const companyList = useSelector((state) => state?.company?.companyList);
  const eventInvitedMembers = useSelector(
    (state) => state?.events?.eventInvitedMembers,
  );

  const eventAttendingMembers = useSelector(
    (state) => state?.events?.eventAttendingMembers,
  );

  const eventRequestMembers = useSelector(
    (state) => state?.events?.eventRequestMembers,
  );
  useEffect(async () => {
    const data = {
      event_id: eventDetails?.id,
    };
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const companylist = await dispatch(companyActions.companyPageGet(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeMore = () => {
    if (clubMembers?.length > noofAttending) {
      setNoofAttending(clubMembers?.length);
    } else if (clubMembers?.length === noofAttending) {
      setNoofAttending(4);
    }
  };
  return (
    <Fragment>
      <Box px={0}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: '#262525',
              backgroundImage: 'none',
              justifyContent: 'center',
              minHeight: '71px',
              width: '100%',
              alignItems: 'center',
              margin: '8px',
            }}>
            <Typography variant="h5s" component="h5s">
              Manage Company Page
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
            }}>
            <Grid container>
              {companyList?.length > 0 &&
                companyList
                  ?.filter((company) => company?.name?.length > 0)
                  .map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          marginTop: '8px',
                          paddingLeft: '8px',
                        }}>
                        <CompanyPageCard company={list} />
                      </Grid>
                    );
                  })}
              {companyList?.length == 0 && (
                <Box pl={2}>
                  <Typography variant="h5" component="h5">
                    No Company page created
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
        <Box
          py={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={seeMore}>
          <Typography variant="h5" component="h5"></Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(CompanyManagePage);
