import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CxoButton from '../../components/CxoButton/CxoButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import EventsIcon from '../../assets/eventsicon';
import InterestIcon from '../../assets/chooseurinterest';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import * as usernetworkActions from '../../store/usernetwork';
import { dateTime } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import * as messageAction from '../../store/message';
import MuiCheckbox from '@mui/material/Checkbox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

function CheckboxCss({ label, icon, checkedIcon, value, onChange }) {
  console.log('CheckboxCss', value);
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          icon={icon}
          color="secondary"
          value={value}
          onChange={onChange}
          checkedIcon={checkedIcon}
        />
      }
    />
  );
}

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

const ClubEdit = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const interestList = useSelector((state) => state.onboard.interestList);
  const existingNetwork = useSelector(
    (state) => state?.usernetwork?.ExistingNetworkList,
  );
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const [industrylst, setIndustrylst] = useState([]);
  const [functionlst, setFunctionlst] = useState([]);
  const [interestlst, setInterestlst] = useState([]);
  const [inviteesId, setInviteesId] = useState([]);
  const [invitees, setInvitees] = useState('all');
  const [hosttype, setHostType] = useState('Indivisual');
  const [eventType, setEventType] = React.useState('Public');
  const [eventMode, setEventMode] = React.useState('Online');
  const [currency, setCurrency] = React.useState(' (INR)');
  const [eventfee, setEventFee] = React.useState(false);
  const [datevalue, setValue] = React.useState(new Date());
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [imgData, setImgData] = useState(null);
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [showPreview, setPreview] = useState(false);
  const [doctitleShow, setDoctitleShow] = useState(false);
  const [networklst, setNetworklst] = useState([]);
  const [alreadyinvite, setAlready] = useState([]);
  const [showManual, setManual] = useState(false);
  const [hostType, setHosttype] = useState('');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);
  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getUserInterestList());
  }, []);

  useEffect(async () => {
    if (user?.email?.indexOf('linkcxo') > -1) {
    }
  }, []);
  useEffect(async () => {
    let result = clubDetails?.functions?.map((functin) => functin?.id);
    setFunctionlst(result);
  }, []);

  useEffect(async () => {
    let result = clubDetails?.industries?.map((industry) => {
      return industry?.id;
    });

    setIndustrylst(result);
  }, []);

  useEffect(async () => {
    let result = clubDetails?.categories?.map((functin) => functin?.id);
    console.log('result', result);
    setInterestlst(result);
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const existinglist = await dispatch(
      usernetworkActions.getExistingNetwork(data),
    );
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrylst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // const sendInvitees = await dispatch(eventsActions.closeSendInviteesEvent());
    setManual(false);
  };
  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  //
  // const interestChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setInterestlst(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const interestChange = (event) => {
    let temp = [];
    temp.push(event.target.value);
    setInterestlst(temp);
    //  (event.target.value);
  };

  const handleNetworkChange = (event) => {
    const {
      target: { value },
    } = event;
    setNetworklst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const hosttypeChange = (event) => {
    setHostType(event.target.value);
  };

  const handleEventType = (event) => {
    setEventType(event.target.value);
  };

  useEffect(async () => {
    setEventType(clubDetails?.type);
  }, []);
  const handleEventMode = (event) => {
    setEventMode(event.target.value);
  };
  const currencyChange = (event) => {
    setCurrency(event.target.value);
  };
  const changeFee = () => {
    setEventFee(!eventfee);
  };
  const handledateChange = (newValue) => {
    setValue(newValue);
  };
  const handleStartdateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEnddateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleStartTimeChange = (newvalue) => {
    setStartTime(newvalue);
  };
  const handleEndTimeChange = (newvalue) => {
    setEndTime(newvalue);
  };
  const handleInvitees = (e) => {
    console.log(e.target.value);
    if (e.target.value === 'manual') {
      setManual(true);
      setInvitees(e.target.value);
    } else {
      setInvitees(e.target.value);
    }
  };

  const getPhoto = async (event) => {
    setDoctitleShow(false);
    setPreview(true);
    setFile(event.target.files[0]);
    setType('image');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmitform = async (data) => {
    console.log(
      'data',
      data,
      eventType,
      eventfee,
      eventMode,
      hosttype,
      moment(startDate).format('YYYY-MM-DD'),
      moment(startTime).format('HH:mm:ss'),
      moment(endDate).format('YYYY-MM-DD'),
      moment(endTime).format('HH:mm:ss'),
    );
    const formData = new FormData();
    formData.append('id', clubDetails?.id);
    formData.append('title', data?.title);
    formData.append('detail', data?.details);
    formData.append('type', eventType);
    formData.append('currency', currency);
    formData.append('email', user?.email);
    formData.append('user_id', user?.id);
    formData.append('datetime', dateTime());
    formData.append('aIndustry', JSON.stringify(industrylst));
    formData.append('aFunction', JSON.stringify(functionlst));
    formData.append('aCategory', JSON.stringify(interestlst));
    formData.append('image', file);

    if (eventfee) {
      formData.append('fee', 0.0);
    } else {
      formData.append('fee', parseFloat(data?.fee));
    }
    // if (invitees === "all") {
    //   let invitees = existingNetwork?.map((network, index) => {
    //     return network?.id;
    //   });
    //   formData.append("invities", JSON.stringify(invitees));
    // } else if (invitees === "manual") {
    //   formData.append("invities", JSON.stringify(networklst));
    // }
    if (
      industrylst?.length > 0 &&
      functionlst?.length > 0 &&
      interestlst?.length > 0
    ) {
      const createClub = await dispatch(
        clubsActions.clubManage(formData, 0, 0, (error, response) => {
          if (response) {
            console.log('createevent', error, response);
            reset();
            setIndustrylst([]);
            setFunctionlst([]);
            setInterestlst([]);
            navigate('/clubs');
          }
        }),
      );
    } else {
      let msg = 'All Fields are Mandatory';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }
  };

  return (
    <Fragment>
      {showManual && (
        <Fragment>
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '1001',
            }}>
            <Backdrop sx={{ color: '#fff', zIndex: '2001' }} open={showManual}>
              <Box px={0}>
                <Box
                  pt={2}
                  sx={{
                    width: '100%',
                    width: 500,
                    '@media (max-width: 500px)': {
                      width: 300,
                    },
                  }}>
                  <Paper
                    sx={{
                      backgroundColor: '#232121',
                      backgroundImage: 'none',
                    }}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={0}>
                      <Box
                        px={3}
                        py={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '16px',
                        }}>
                        <Typography variant="h2s" component="h2s">
                          Send Invite
                        </Typography>
                      </Box>
                      <Box pb={2} pr={2}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack direction="column" justifyContent="center">
                        <Box
                          sx={{
                            width: '400px',
                            '@media (max-width: 500px)': {
                              width: '280px',
                            },
                          }}>
                          <Box
                            py={1}
                            pl={1}
                            sx={{
                              width: '96%',
                            }}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                style={{
                                  color: '#38CD84',
                                  fontSize: '13px',
                                }}
                                id="demo-simple-select-label">
                                Network
                              </InputLabel>
                              <CssSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={networklst}
                                multiple
                                label="Industry List"
                                onChange={handleNetworkChange}
                                size="small"
                                className={classes.underline}
                                color="secondary"
                                sx={{ color: '#38CD84' }}
                                renderValue={(selected) => {
                                  console.log('selected', selected);
                                  return selected.length + ' ' + 'Selected';
                                }}
                                MenuProps={MenuProps}>
                                {existingNetwork
                                  ? existingNetwork?.map((item, key) => {
                                      return (
                                        <MenuItem
                                          color="secondary"
                                          key={key}
                                          value={item.id}>
                                          <ListItemText
                                            color="secondary"
                                            primary={
                                              item.first_name +
                                              ' ' +
                                              item.last_name
                                            }
                                          />
                                          <Checkbox
                                            color="secondary"
                                            icon={<RadioButtonUncheckedIcon />}
                                            checkedIcon={<CheckCircleIcon />}
                                            checked={
                                              networklst.indexOf(item.id) > -1
                                            }
                                          />
                                        </MenuItem>
                                      );
                                    })
                                  : ''}
                              </CssSelect>
                            </FormControl>
                          </Box>
                          <Box p={4}></Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Backdrop>
          </Box>
        </Fragment>
      )}

      <Box px={{ md: 2, xs: 0 }} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0}>
            <Box
              py={3}
              px={3}
              sx={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
              }}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{ fontWeight: 600 }}>
                Edit Club
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>
      <Box px={{ md: 2, xs: 0 }} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Box>
            <Card sx={{ width: '100%', position: 'relative' }}>
              <CardMedia
                component="img"
                src={imgData ? imgData : clubDetails?.image}
                height="230"
                image=""
                sx={{ backgroundColor: '#333' }}
              />
              <Stack
                direction="column"
                sx={{
                  position: 'absolute',
                  top: '50px',
                  left: '40%',
                  margin: 'auto',
                  '@media (max-width: 500px)': {
                    left: '23%',
                  },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <label htmlFor="icon-button-file">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '64px',
                        maxWidth: 'min-content',
                        minWidth: '64px',
                      }}>
                      <EventsIcon name="events_add_banner" color="#38CD84" />
                    </Box>
                  </label>
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={getPhoto}
                  />
                </Box>
                <Box
                  py={2}
                  px={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant="h6s" component="h6s">
                    Add Banner Image/Video*
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>

          <Stack direction="column" justifyContent="center" spacing={1}>
            <Box px={{ md: 16, xs: 1 }} pt={2}>
              <form onSubmit={handleSubmit(handleSubmitform)}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}>
                        <Menuicon name="clubs" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <CxoInputCss
                            label="Title *"
                            value={value}
                            err={errors?.title ? errors?.title : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.title?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="title"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Event Title is required',
                        },
                      }}
                      defaultValue={
                        clubDetails?.title ? clubDetails?.title : ''
                      }
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                            marginLeft: '-8px',
                            marginTop: '-8px',
                          },
                        }}>
                        <InterestIcon name="Others" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Typography variant="h6s" component="h6s">
                      Details *
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}></Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <TextField
                            variant="standard"
                            fullWidth
                            multiline
                            rows={6}
                            maxRows={8}
                            value={value}
                            onChange={onChange}
                            sx={{
                              backgroundColor: '#262525',
                              marginLeft: '8px',
                              padding: '8px',
                              borderRadius: '5px',
                              border: '2px solid #959498',
                            }}
                            placeholder="Start Typing Here"
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.title?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="details"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Event Details is required',
                        },
                      }}
                      defaultValue={
                        clubDetails?.detail ? clubDetails?.detail : ''
                      }
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '16px',
                            height: '16px',
                          },
                        }}>
                        <EventsIcon name="event_type" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pt={0.5} px={2} sx={{ width: '100%' }}>
                    <Stack spacing={2} direction="row">
                      <FormControl>
                        <FormLabel
                          color="secondary"
                          id="demo-row-radio-buttons-group-label"
                          sx={{
                            color: '#38CD84',
                            '@media (max-width: 500px)': { fontSize: '13px' },
                          }}>
                          Type *
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          color="secondary"
                          value={eventType}
                          onChange={handleEventType}>
                          <FormControlLabel
                            value="Public"
                            color="secondary"
                            control={<Radio color="secondary" size="small" />}
                            label="Public"
                          />
                          <FormControlLabel
                            value="Private"
                            color="secondary"
                            control={<Radio color="secondary" size="small" />}
                            label="Private"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}>
                        <EventsIcon name="event_fee" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1.7} sx={{ width: '25%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{ color: '#38CD84' }}
                        id="demo-simple-select-label">
                        Fee
                      </InputLabel>
                      <CssSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Fee*"
                        className={classes.underline}
                        value={currency}
                        onChange={currencyChange}
                        size="small">
                        <MenuItem key={0} value="₹ (INR)">
                          ₹ (INR)
                        </MenuItem>
                      </CssSelect>
                    </FormControl>
                  </Box>

                  <Box sx={{ width: '30%' }}>
                    {!eventfee && (
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack>
                            <CxoInputCss
                              label="Price *"
                              value={value}
                              err={errors?.fee ? errors?.fee : false}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: '9px',
                                height: '10px',
                                paddingLeft: '8px',
                              }}>
                              {errors?.fee?.message}{' '}
                            </Box>
                          </Stack>
                        )}
                        name="fee"
                        required
                        rules={{
                          required: {
                            value: true,
                            message: 'Price is required',
                          },
                        }}
                        defaultValue={clubDetails?.fee ? clubDetails?.fee : ''}
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyItems: 'center',
                      paddingLeft: '16px',
                    }}>
                    <CheckboxCss
                      label="Free"
                      value={eventfee}
                      onChange={changeFee}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}>
                        <Menuicon name="industry" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{ color: '#38CD84' }}
                        id="demo-simple-select-label">
                        Industry *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industrylst}
                        multiple
                        label="Industry List"
                        onChange={handleChange}
                        size="small"
                        className={classes.underline}
                        color="secondary"
                        sx={{ color: '#38CD84' }}
                        renderValue={(selected) => {
                          console.log('selected', selected);
                          return selected.length + ' ' + 'Selected';
                        }}
                        // MenuProps={MenuProps}
                      >
                        {industryList?.data
                          ? industryList?.data.map((item, key) => {
                              return (
                                <MenuItem
                                  color="secondary"
                                  key={key}
                                  value={item.id}>
                                  {' '}
                                  {/* {`${item.industry.substring(0, 20)}`} */}
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.substring(0, 25) + '...'
                                    }
                                  />
                                  <Checkbox
                                    color="secondary"
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    checked={industrylst.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}>
                        <Menuicon name="functions" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{ color: '#38CD84' }}
                        id="demo-simple-select-label">
                        Function *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={functionlst}
                        label="Function"
                        multiple
                        className={classes.underline}
                        onChange={functionChange}
                        size="small"
                        renderValue={(selected) => {
                          console.log('selected', selected);
                          return selected.length + ' ' + 'Selected';
                        }}
                        // MenuProps={MenuProps}
                      >
                        {functionList?.data
                          ? functionList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.substring(0, 25) + '...'
                                    }
                                  />
                                  <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="secondary"
                                    checked={functionlst.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}>
                        <EventsIcon name="category" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{ color: '#38CD84' }}
                        id="demo-simple-select-label">
                        Category *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={interestlst}
                        label="Category"
                        className={classes.underline}
                        onChange={interestChange}
                        size="small">
                        {interestList?.data
                          ? interestList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  {item.title}
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                  <CxoButton label="Update" />
                </Stack>
              </form>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default React.memo(ClubEdit);

//
// <Stack direction="column">
//   <Box
//     sx={{
//       backgroundColor: "#333",
//       height: "150px",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//                         }}
//   >
//
//     <Stack direction="column">
//
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <label htmlFor="icon-button-file">
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "64px",
//               maxWidth: "min-content",
//               minWidth: "64px",
//             }}
//           >
//             <EventsIcon name="events_add_banner" color="#38CD84" />
//           </Box>
//         </label>
//         <input
//           accept="image/*"
//           id="icon-button-file"
//           type="file"
//           style={{ display: "none" }}
//           onChange={getPhoto}
//         />
//       </Box>
//       <Box
//         py={2}
//         px={2}
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Typography variant="h6s" component="h6s">
//           Add Banner Image/Video*
//         </Typography>
//       </Box>
//     </Stack>
//   </Box>
// </Stack>
