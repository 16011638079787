import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import 'firebase/firestore';

// const firebase = require('firebase/app').default
// require('firebase/auth')

// const firebaseConfig = {
//     apiKey: "AIzaSyADhRERWeW47HZyux7rqB1rREVTjVMY2kI",
//     authDomain: "linkcxo-83231.firebaseapp.com",
//     projectId: "linkcxo-83231",
//     storageBucket: "linkcxo-83231.appspot.com",
//     messagingSenderId: "492287509275",
//     appId: "1:492287509275:web:23bd2eb792faedea6a09be",
//     measurementId: "G-G1PV4KWSHF"
//   };
//
//   if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig)
//   }
//
//
// const auth = firebase.auth()
// const firestore = firebase.firestore()
// export {auth, firestore}

const firebaseConfig = {
  apiKey: "AIzaSyAjuWYMK42dTneANpHRx3oxqyfTInCfyco",
  authDomain: "linkcxoweb.firebaseapp.com",
  projectId: "linkcxoweb",
  storageBucket: "linkcxoweb.appspot.com",
  messagingSenderId: "862261882931",
  appId: "1:862261882931:web:bef997c3d75dc39cddb346",
  measurementId: "G-28DPK0P4JB"
};



const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
