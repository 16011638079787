import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import EventCard from '../../components/EventCard/EventCard';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const EventRequested = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofRequested, setNoofRequested] = React.useState(2);
  const [noPage, setNoPage] = React.useState(0);

  const user = useSelector((state) => state?.user?.UserDetails);
  const Requestedlist = useSelector(
    (state) => state?.events?.eventRequestedList,
  );
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      categoryId: [],
      page_no: noPage,
    };
    const eventList = await dispatch(eventsActions.requestedEventList(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeMore = () => {
    if (Requestedlist?.length > noofRequested) {
      setNoofRequested(Requestedlist?.length);
    } else if (Requestedlist?.length === noofRequested) {
      setNoofRequested(2);
    }
  };
  return (
    <Fragment>
      <Box px={0}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  Requested {'(' + Requestedlist?.length + ')'}
                </Typography>
              </Box>

              <Box
                py={2}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMore}>
                {Requestedlist?.length > 2 &&
                Requestedlist?.length !== noofRequested ? (
                  <Fragment>
                    <Box px={2}></Box>
                    <Typography variant="h5" component="h5">
                      See All
                    </Typography>
                  </Fragment>
                ) : (
                  ''
                )}

                {Requestedlist?.length > 2 &&
                Requestedlist?.length === noofRequested ? (
                  <Fragment>
                    <Box px={2}></Box>
                    <Typography variant="h5" component="h5">
                      See less
                    </Typography>
                  </Fragment>
                ) : (
                  ''
                )}
              </Box>
            </Stack>
          </Paper>

          <Box
            sx={{
              marginTop: '8px',
            }}>
            <Grid container>
              {Requestedlist?.length > 0 &&
                Requestedlist?.slice(0, noofRequested)?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={12}
                      sx={{
                        marginTop: '8px',
                      }}>
                      <EventCard events={list} type="Requested" />
                    </Grid>
                  );
                })}
              {Requestedlist?.length == 0 && (
                <Box pl={2} pt={3}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Currently, there are no event requests..
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
        <Box
          py={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={seeMore}>
          <Typography variant="h5" component="h5">
            {Requestedlist?.length > 2 &&
            Requestedlist?.length !== noofRequested
              ? 'See All'
              : ''}
          </Typography>
        </Box>
        <Box
          py={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={seeMore}>
          <Typography variant="h5" component="h5">
            {Requestedlist?.length > 2 &&
            Requestedlist?.length === noofRequested
              ? 'See less'
              : ''}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EventRequested);
