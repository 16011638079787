import React, { Component } from 'react';
import LinkCXOLogo from '../../resources/new-img/White-Rectangle.png';
import { Container } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class HeaderInvest extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <Container fluid id="no-padding">
        <Navbar
          // collapseOnSelect
          expand="sm"
          variant="#1F1E1E"
          style={{
            background: '#1F1E1E',
            marginBottom: '-10px',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          <Navbar.Brand
            style={{
              cursor: 'pointer',
              paddingBottom: '15px',
            }}>
            <Link to="/AdvInvest" className="header-link">
              <img
                src={LinkCXOLogo}
                alt=""
                className="logo-image-section-style"
              />
            </Link>
          </Navbar.Brand>

          <Nav>
            <Nav.Link eventKey={2} className="nave-bar-menu-style-new">
              <Link
                to="/AdvInvest"
                className="nave-bar-menu-style-link"
                style={{ color: '#38cd84' }}>
                Ventures
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar>
      </Container>
    );
  }
}

export default HeaderInvest;
