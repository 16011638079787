import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Paper, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tab, { tabClasses } from '@mui/base/Tab';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}>
      <div className="expandIconWrapper">
        <Stack direction="row" spacing={2} sx={{ paddingLeft: '16px' }}>
          <ExpandMoreIcon />
        </Stack>
      </div>
      <div className="collapsIconWrapper">
        <Stack direction="row" spacing={2} sx={{ paddingRight: '16px' }}>
          <ExpandMoreIcon />
        </Stack>
      </div>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  rowroot: {
    paddingTop: '16px',
    paddingLeft: '16px',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const VoucherDetailsSkeleton = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  return (
    <Fragment>
      <Box px={1} pt={1} pb={1} id="jobdetails">
        <Stack>
          <Skeleton variant="square" width="100%" height={350} />
        </Stack>
        <Accordion expanded={expanded === 'panel1'}>
          <Stack direction="row" spacing={0}></Stack>
          <AccordionDetails
            sx={{ backgroundColor: '#272727', marginBottom: '16px' }}>
            <Grid container className={classes.rowroot}>
              <Grid item xs={6}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={200} height={20} />
                  <Skeleton variant="square" width={200} height={20} />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={200} height={20} />
                  <Skeleton variant="square" width={200} height={20} />
                </Stack>
              </Grid>
            </Grid>
            <Grid container className={classes.rowroot}>
              <Grid item xs={12} md={6}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={200} height={20} />

                  <Stack direction="row">
                    <Skeleton variant="square" width={200} height={20} />
                    <Skeleton variant="square" width={200} height={20} />
                    <Skeleton variant="square" width={200} height={20} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={classes.rowroot}>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ paddingTop: '8px' }}></Stack>
              </Grid>
            </Grid>

            <Grid container className={classes.rowroot}>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ paddingTop: '8px' }}>
                  <Skeleton variant="square" width={200} height={20} />

                  <Box pr={0.5} sx={{ width: '100%' }}>
                    <Skeleton variant="square" width={200} height={20} />
                    <Skeleton variant="square" width={200} height={20} />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.rowroot}
              sx={{ paddingBottom: '8px', paddingRight: '8px' }}>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Skeleton variant="square" width={200} height={20} />
                  <Skeleton variant="square" width={400} height={80} />
                </Stack>
              </Grid>
            </Grid>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems="center">
              <Box sx={{ padding: '16px', width: '100%' }}>
                <Skeleton variant="square" width="100%" height={300} />
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Fragment>
  );
};

export default React.memo(VoucherDetailsSkeleton);
