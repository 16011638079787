import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import MenuItem from '@mui/material/MenuItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NewsCard from './NewsCard';
import { dateTime } from '../../constants/app';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Select from '@mui/material/Select';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';
import * as activityActions from '../../store/activity';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CssSelect = styled(Select)(({ theme }) => {
  return {
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.palette.text.default,
      color: theme.palette.palette.text.primary,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const News = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [country, setCountry] = React.useState('91');
  const [pageNo, setPageNo] = React.useState(0);
  const countryChange = (event) => {
    setCountry(event.target.value);
  };
  const user = useSelector((state) => state?.user?.UserDetails);

  const newsList = useSelector((state) => state?.activity?.NewsList);
  const news_pageno = useSelector((state) => state?.activity?.news_pageno);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function fetchData() {
      for (let i = 0; i < 10; i++) {
        const data = {
          user_id: user.id,
          datetime: dateTime(),
          page_no: i,
        };
        const news = await dispatch(activityActions.getNewsList(data));
      }
    }
    fetchData();
  }, []);

  // const nextNews

  const nextData = async (currentSlide) => {
    if (currentSlide == newsList?.length - 2) {
      const data = {
        user_id: user.id,
        datetime: dateTime(),
        page_no: news_pageno + 1,
      };
      const news = await dispatch(activityActions.getNewsList(data));
    }
  };

  const clickRightBotton = (obj) => {
    console.log('obj', obj);
    return obj;
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    if (currentSlide === newsList?.length - 2) {
      console.log('CustomRightArrow', currentSlide);
      // nextData(currentSlide);
    }

    // onMove means if dragging or swiping in progress.
    return (
      <Box
        sx={{
          width: '48px',
          height: '48px',
          backgroundColor: '#000000',
          zIndex: 2100,
          position: 'absolute',
          outline: 0,
          transition: 'all .5s',
          borderRadius: '35px',
          border: 0,
          background: 'rgba(0,0,0,0.5)',
          minWidth: '24px',
          minHeight: '24px',
          opacity: 1,
          cursor: 'pointer',
          right: 'calc(4% + 1px)',
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ArrowForwardIosIcon
          onClick={onClick}
          aria-label="Bookmark"
          sx={{
            color: '#ffffff',
          }}></ArrowForwardIosIcon>
      </Box>
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    console.log('CustomRightArrow', currentSlide);

    // onMove means if dragging or swiping in progress.
    return (
      <Box
        sx={{
          width: '48px',
          height: '48px',
          backgroundColor: '#000000',
          position: 'absolute',
          outline: 0,
          transition: 'all .5s',
          borderRadius: '35px',
          border: 0,
          background: 'rgba(0,0,0,0.5)',
          minWidth: '24px',
          minHeight: '24px',
          opacity: 1,
          cursor: 'pointer',
          left: 'calc(4% + 2px)',
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ArrowBackIosIcon
          onClick={onClick}
          aria-label="Bookmark"
          sx={{
            color: '#ffffff',
            marginLeft: '8px',
          }}></ArrowBackIosIcon>
      </Box>
    );
  };
  return (
    <Fragment>
      <Box m={2}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}>
              <Box
                py={2}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  News
                </Typography>
              </Box>
            </Stack>
          </Paper>

          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={90000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            removeArrowOnDeviceType={[]}
            deviceType={''}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px">
            {newsList?.length > 0 &&
              [
                ...new Map(newsList.map((item) => [item['id'], item])).values(),
              ]?.map((news, index) => {
                return <NewsCard key={index} news={news} />;
              })}
          </Carousel>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(News);
