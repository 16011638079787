import React, { Fragment, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import * as messageActions from "../../store/message";
import * as postActions from "../../store/post";
import CxoButton from "../CxoButton/CxoButton";
import Icon from "../../assets/icon";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { dateTime } from "../../constants/app";

export default function Helpandfeedback(props) {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("");
  const user = useSelector((state) => state?.user?.UserDetails);
  const reportPost = useSelector((state) => state?.post?.ReportPost);
  const post = useSelector((state) => state?.post?.reportPost);
  const feedbackShow = useSelector((state) => state?.post?.FeedbackShow);

  const handleSubmit = async () => {};

  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const hidepost = await dispatch(postActions.closeHelpFeedback());
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  return (
    <Box
      sx={{ width: "100%", height: "100vh", position: "fixed", zIndex: "3001" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={feedbackShow}
      >
        <Box
          pl={{ md: 3, xs: 0 }}
          sx={{
            width: "700px",
            height: "250px",
            backgroundColor: "#1c1c1c",
            borderRadius: "10px",
            "@media (max-width: 500px)": {
              margin: "0px",
              padding: "0px",
              width: "350px",
              height: "250px",
            },
          }}
        >
          <Stack spacing={0} sx={{ width: "100%" }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CloseIcon
                  sx={{ color: "#ffffff", cursor: "pointer", fontSize: "24" }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>
            <Box p={4}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{ fontSize: "15px" }}
              >
                if you need any assistance or help with understanding features
                of the App, please talk to our Chatbot- Guru for Support &
                Feedback, please write to
                <a
                  href="mailto:support@linkcxo.com"
                  style={{ color: "#ffffff" }}
                >
                  {" "}
                  support@linkcxo.com
                </a>
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
}
