import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import ClubCard from '../../components/ClubCard/ClubCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubsOwned = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofHosting, setNoofHosting] = React.useState(2);
  const [pagenoHosting, setPagenoHosting] = React.useState(0);

  const user = useSelector((state) => state?.user?.UserDetails);
  const clubHostedList = useSelector((state) => state?.clubs?.clubHostedList);
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const upcomingClubList = useSelector(
    (state) => state?.events?.eventUpcomingList,
  );

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: pagenoHosting,
    };
    const clubHostedList = await dispatch(clubsActions.hostedClubs(data));
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const seeMoreHosting = async () => {
    setPagenoHosting(pagenoHosting + 1);
    const data = {
      user_id: user?.id,
      page_no: pagenoHosting + 1,
    };
    const clubHostedList = await dispatch(clubsActions.hostedClubs(data));
  };

  const seeMore = () => {
    if (clubHostedList?.length > noofHosting) {
      setNoofHosting(clubHostedList?.length);
    } else if (clubHostedList?.length === noofHosting) {
      setNoofHosting(4);
    }
  };
  return (
    <Fragment>
      <Box>
        <Box sx={{ width: '100%' }}>
          <Box>
            <InfiniteScroll
              dataLength={clubHostedList?.length ? clubHostedList?.length : 0}
              next={() => seeMoreHosting()}
              hasMore={true}
              loader={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreHosting}></Box>
              }
              height={clubHostedList?.length > 2 ? 'calc(90vh - 70px)' : 'auto'}
              endMessage={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreHosting}>
                  <Box>End</Box>
                </Box>
              }>
              <Grid container>
                {clubHostedList?.length > 0 &&
                  clubHostedList?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={12}
                        sx={{
                          marginTop: '8px',
                        }}>
                        <ClubCard clubs={list} type="own" />
                      </Grid>
                    );
                  })}
                {clubHostedList?.length == 0 && (
                  <Box pl={3} pt={3}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}>
                      There is no Club hosted by you till now.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(ClubsOwned);
