import { API, apiUrl } from '../../services';
import { http } from '../../services/client';
import { showMessage } from '../message';
import {  getAccessToken} from '../../services/authData';

export const COMPANY_PAGE_CREATE_SUCCESS = 'COMPANY_PAGE_CREATE_SUCCESS';
export const COMPANY_PAGE_CREATE_REQUEST = 'COMPANY_PAGE_CREATE_REQUEST';
export const COMPANY_PAGE_CREATE_FAILURE = 'COMPANY_PAGE_CREATE_FAILURE';

const companyPageCreateRequest = () => ({
  type: COMPANY_PAGE_CREATE_REQUEST,
});

const companyPageCreateFailure = (error) => ({
  type: COMPANY_PAGE_CREATE_FAILURE,
  payload: { error: error },
});

const companyPageCreateSuccess = (data) => ({
  type: COMPANY_PAGE_CREATE_SUCCESS,
  payload: data,
});

export const companyPageCreate = (payload, statuschange, id, callback) => {
  return (dispatch) => {
    dispatch(companyPageCreateRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.COMPANY_PAGE_CREATE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(companyPageCreateFailure(error));
          showMessage({
            dispatch,
            message: 'Company create failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(companyPageCreateSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          if (id === 0) {
            showMessage({
              dispatch,
              message: 'Company successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && !statuschange) {
            showMessage({
              dispatch,
              message: 'Company successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && statuschange) {
            showMessage({
              dispatch,
              message: 'Company successfully created',
              variant: 'success',
            });
          }
          callback(null, response);
          return response;
        }
      },
    );
    // .then((response) =>{
    //   return response
    // })
    // .catch((error) =>{
    //   return error
    // })
  };
};

// export const companyPageCreate = (payload) => {
//   return (dispatch) => {
//     dispatch(companyPageCreateRequest());
//     return http_local
//       .post(apiUrl.COMPANY_PAGE_CREATE, payload)
//       .then((response) => {
//         if (response?.status === 1) {
//           dispatch(companyPageCreateSuccess(response?.data));
//           return response;
//         } else {
//           if (
//             response?.status === -1 &&
//             response?.message === 'No record found'
//           ) {
//             dispatch(companyPageCreateSuccess({ data: [] }));
//             return response;
//           } else {
//             dispatch(companyPageCreateFailure(response));
//             return response;
//           }
//         }
//       })
//       .catch((error) => {
//         dispatch(companyPageCreateFailure());
//         return error;
//       });
//   };
// };

export const COMPANY_PAGE_GET_SUCCESS = 'COMPANY_PAGE_GET_SUCCESS';
export const COMPANY_PAGE_GET_REQUEST = 'COMPANY_PAGE_GET_REQUEST';
export const COMPANY_PAGE_GET_FAILURE = 'COMPANY_PAGE_GET_FAILURE';

const companyPageGetRequest = () => ({
  type: COMPANY_PAGE_GET_REQUEST,
});

const companyPageGetFailure = (error) => ({
  type: COMPANY_PAGE_GET_FAILURE,
  payload: { error: error },
});

const companyPageGetSuccess = (data) => ({
  type: COMPANY_PAGE_GET_SUCCESS,
  payload: data,
});

export const companyPageGet = (payload) => {
  return (dispatch) => {
    dispatch(companyPageGetRequest());
    return http
      .post(apiUrl.COMPANY_PAGE_ALL, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(companyPageGetSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(companyPageGetSuccess({ data: [] }));
            return response;
          } else {
            dispatch(companyPageGetFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(companyPageGetFailure());
        return error;
      });
  };
};

export const COMPANY_USER_ACCESS_SUCCESS = 'COMPANY_USER_ACCESS_SUCCESS';
export const COMPANY_USER_ACCESS_REQUEST = 'COMPANY_USER_ACCESS_REQUEST';
export const COMPANY_USER_ACCESS_FAILURE = 'COMPANY_USER_ACCESS_FAILURE';

const companyUserAccessRequest = () => ({
  type: COMPANY_USER_ACCESS_REQUEST,
});

const companyUserAccessFailure = (error) => ({
  type: COMPANY_USER_ACCESS_FAILURE,
  payload: { error: error },
});

const companyUserAccessSuccess = (data) => ({
  type: COMPANY_USER_ACCESS_SUCCESS,
  payload: data,
});

export const companyUserAccess = (payload) => {
  return (dispatch) => {
    dispatch(companyUserAccessRequest());
    return http
      .post(apiUrl.COMPANY_USER_ACCESS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(companyUserAccessSuccess(response?.data));
          return response;
        } else {
          if (
            response?.data?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(companyUserAccessSuccess({ data: [] }));
            return response;
          } else {
            dispatch(companyUserAccessFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(companyUserAccessFailure());
        return error;
      });
  };
};

export const COMPANY_PAGE_SHARED_SUCCESS = 'COMPANY_PAGE_SHARED_SUCCESS';
export const COMPANY_PAGE_SHARED_REQUEST = 'COMPANY_PAGE_SHARED_REQUEST';
export const COMPANY_PAGE_SHARED_FAILURE = 'COMPANY_PAGE_SHARED_FAILURE';

const companyPageSharedRequest = () => ({
  type: COMPANY_DETAILS_REQUEST,
});

const companyPageSharedFailure = (error) => ({
  type: COMPANY_DETAILS_FAILURE,
  payload: { error: error },
});

const companyPageSharedSuccess = (data) => ({
  type: COMPANY_DETAILS_SUCCESS,
  payload: data,
});

export const companyPageShared = (payload) => {
  return (dispatch) => {
    dispatch(companyPageSharedRequest());
    return http
      .post(apiUrl.COMPANY_PAGE_SHARED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(companyPageSharedSuccess(response?.data));
          return response;
        } else {
          if (
            response?.data?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(companyPageSharedSuccess({ data: [] }));
            return response;
          } else {
            dispatch(companyPageSharedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(companyPageSharedFailure());
        return error;
      });
  };
};

export const COMPANY_DETAILS_SUCCESS = 'COMPANY_DETAILS_SUCCESS';
export const COMPANY_DETAILS_REQUEST = 'COMPANY_DETAILS_REQUEST';
export const COMPANY_DETAILS_FAILURE = 'COMPANY_DETAILS_FAILURE';

const companyDetailsRequest = () => ({
  type: COMPANY_DETAILS_REQUEST,
});

const companyDetailsFailure = (error) => ({
  type: COMPANY_DETAILS_FAILURE,
  payload: { error: error },
});

const companyDetailsSuccess = (data) => ({
  type: COMPANY_DETAILS_SUCCESS,
  payload: data,
});

export const companyDetails = (payload) => {
  return (dispatch) => {
    dispatch(companyDetailsRequest());
    return http
      .post(apiUrl.COMPANY_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(companyDetailsSuccess(response?.data));
          return response;
        } else {
          if (
            response?.data?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(companyDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(companyDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(companyDetailsFailure());
        return error;
      });
  };
};

export const COMPANY_ADDITIONAL_DETAILS_SUCCESS =
  'COMPANY_ADDITIONAL_DETAILS_SUCCESS';
export const COMPANY_ADDITIONAL_DETAILS_REQUEST =
  'COMPANY_ADDITIONAL_DETAILS_REQUEST';
export const COMPANY_ADDITIONAL_DETAILS_FAILURE =
  'COMPANY_ADDITIONAL_DETAILS_FAILURE';

const companyAdditionalDetailsRequest = () => ({
  type: COMPANY_ADDITIONAL_DETAILS_REQUEST,
});

const companyAdditionalDetailsFailure = (error) => ({
  type: COMPANY_ADDITIONAL_DETAILS_FAILURE,
  payload: { error: error },
});

const companyAdditionalDetailsSuccess = (data) => ({
  type: COMPANY_ADDITIONAL_DETAILS_SUCCESS,
  payload: data,
});

export const companyAdditionalDetails = (payload) => {
  return (dispatch) => {
    dispatch(companyAdditionalDetailsRequest());
    return http
      .post(apiUrl.COMPANY_ADDITIONAL_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(companyAdditionalDetailsSuccess(response?.data));
          return response;
        } else {
          if (
            response?.data?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(companyAdditionalDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(companyAdditionalDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(companyAdditionalDetailsFailure());
        return error;
      });
  };
};

export const COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS =
  'COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS';
export const COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST =
  'COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST';
export const COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE =
  'COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE';

const getCompanyMembershipPurchasedRequest = () => ({
  type: COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST,
});

const getCompanyMembershipPurchasedFailure = (error) => ({
  type: COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE,
  payload: { error: error },
});

const getCompanyMembershipPurchasedSuccess = (data) => ({
  type: COMPANY_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS,
  payload: data,
});

export const getCompanyMembershipPurchased = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyMembershipPurchasedRequest());
    return http
      .post(apiUrl.COMPANY_MEMBERSHIP_PURCHASED_VOUCHER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyMembershipPurchasedSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyMembershipPurchasedSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyMembershipPurchasedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyMembershipPurchasedFailure());
        return error;
      });
  };
};

export const COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_SUCCESS =
  'COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_SUCCESS';
export const COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_REQUEST =
  'COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_REQUEST';
export const COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_FAILURE =
  'COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_FAILURE';

const getCompanyMembershipRedeemedRequest = () => ({
  type: COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_REQUEST,
});

const getCompanyMembershipRedeemedFailure = (error) => ({
  type: COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_FAILURE,
  payload: { error: error },
});

const getCompanyMembershipRedeemedSuccess = (data) => ({
  type: COMPANY_MEMBERSHIP_REDEEMED_VOUCHER_SUCCESS,
  payload: data,
});

export const getCompanyMembershipRedeemed = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyMembershipRedeemedRequest());
    return http
      .post(apiUrl.COMPANY_MEMBERSHIP_REDEEMED_VOUCHER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyMembershipRedeemedSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyMembershipRedeemedSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyMembershipRedeemedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyMembershipRedeemedFailure());
        return error;
      });
  };
};

export const COMPANY_MEMBERSHIP_ACCESS_LIST_SUCCESS =
  'COMPANY_MEMBERSHIP_ACCESS_LIST_SUCCESS';
export const COMPANY_MEMBERSHIP_ACCESS_LIST_REQUEST =
  'COMPANY_MEMBERSHIP_ACCESS_LIST_REQUEST';
export const COMPANY_MEMBERSHIP_ACCESS_LIST_FAILURE =
  'COMPANY_MEMBERSHIP_ACCESS_LIST_FAILURE';

const getCompanyMembershipAccessListRequest = () => ({
  type: COMPANY_MEMBERSHIP_ACCESS_LIST_REQUEST,
});

const getCompanyMembershipAccessListFailure = (error) => ({
  type: COMPANY_MEMBERSHIP_ACCESS_LIST_FAILURE,
  payload: { error: error },
});

const getCompanyMembershipAccessListSuccess = (data) => ({
  type: COMPANY_MEMBERSHIP_ACCESS_LIST_SUCCESS,
  payload: data,
});

export const getCompanyMembershipAccessList = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyMembershipAccessListRequest());
    return http
      .post(apiUrl.COMPANY_MEMBERSHIP_ACCESS_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyMembershipAccessListSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyMembershipAccessListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyMembershipAccessListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyMembershipAccessListFailure());
        return error;
      });
  };
};

export const COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_SUCCESS =
  'COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_SUCCESS';
export const COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_REQUEST =
  'COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_REQUEST';
export const COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_FAILURE =
  'COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_FAILURE';

const getCompanyMembershipExpiredRequest = () => ({
  type: COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_REQUEST,
});

const getCompanyMembershipExpiredFailure = (error) => ({
  type: COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_FAILURE,
  payload: { error: error },
});

const getCompanyMembershipExpiredSuccess = (data) => ({
  type: COMPANY_MEMBERSHIP_EXPIRED_VOUCHER_SUCCESS,
  payload: data,
});

export const getCompanyMembershipExpired = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyMembershipExpiredRequest());
    return http
      .post(apiUrl.COMPANY_MEMBERSHIP_EXPIRED_VOUCHER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyMembershipExpiredSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyMembershipExpiredSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyMembershipExpiredFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyMembershipExpiredFailure());
        return error;
      });
  };
};

export const COMPANY_POST_LIST_SUCCESS = 'COMPANY_POST_LIST_SUCCESS';
export const COMPANY_POST_LIST_REQUEST = 'COMPANY_POST_LIST_REQUEST';
export const COMPANY_POST_LIST_FAILURE = 'COMPANY_POST_LIST_FAILURE';

const getCompanyPostListRequest = () => ({
  type: COMPANY_POST_LIST_REQUEST,
});

const getCompanyPostListFailure = (error) => ({
  type: COMPANY_POST_LIST_FAILURE,
  payload: { error: error },
});

const getCompanyPostListSuccess = (data) => ({
  type: COMPANY_POST_LIST_SUCCESS,
  payload: data,
});

export const getCompanyPostList = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyPostListRequest());
    return http
      .post(apiUrl.COMPANY_POST_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyPostListSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyPostListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyPostListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyPostListFailure());
        return error;
      });
  };
};

export const COMPANY_JOB_LIST_SUCCESS = 'COMPANY_JOB_LIST_SUCCESS';
export const COMPANY_JOB_LIST_REQUEST = 'COMPANY_JOB_LIST_REQUEST';
export const COMPANY_JOB_LIST_FAILURE = 'COMPANY_JOB_LIST_FAILURE';

const getCompanyJobListRequest = () => ({
  type: COMPANY_JOB_LIST_REQUEST,
});

const getCompanyJobListFailure = (error) => ({
  type: COMPANY_JOB_LIST_FAILURE,
  payload: { error: error },
});

const getCompanyJobListSuccess = (data) => ({
  type: COMPANY_JOB_LIST_SUCCESS,
  payload: data,
});

export const getCompanyOpenJobList = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyJobListRequest());
    return http
      .post(apiUrl.COMPANY_JOB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyJobListSuccess(response?.data));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getCompanyJobListSuccess([]));
            return response;
          } else {
            dispatch(getCompanyJobListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyJobListFailure());
        return error;
      });
  };
};

export const COMPANY_INACTIVE_JOB_LIST_SUCCESS =
  'COMPANY_INACTIVE_JOB_LIST_SUCCESS';
export const COMPANY_INACTIVE_JOB_LIST_REQUEST =
  'COMPANY_INACTIVE_JOB_LIST_REQUEST';
export const COMPANY_INACTIVE_JOB_LIST_FAILURE =
  'COMPANY_INACTIVE_JOB_LIST_FAILURE';

const getCompanyInactiveJobListRequest = () => ({
  type: COMPANY_INACTIVE_JOB_LIST_REQUEST,
});

const getCompanyInactiveJobListFailure = (error) => ({
  type: COMPANY_INACTIVE_JOB_LIST_FAILURE,
  payload: { error: error },
});

const getCompanyInactiveJobListSuccess = (data) => ({
  type: COMPANY_INACTIVE_JOB_LIST_SUCCESS,
  payload: data,
});

export const getCompanyInactiveJobList = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyInactiveJobListRequest());
    return http
      .post(apiUrl.COMPANY_JOB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyInactiveJobListSuccess(response?.data));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getCompanyInactiveJobListSuccess([]));
            return response;
          } else {
            dispatch(getCompanyInactiveJobListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyInactiveJobListFailure());
        return error;
      });
  };
};

export const COMPANY_MEMBERSHIP_PURCHASED_DETAILS_SUCCESS =
  'COMPANY_MEMBERSHIP_PURCHASED_DETAILS_SUCCESS';
export const COMPANY_MEMBERSHIP_PURCHASED_DETAILS_REQUEST =
  'COMPANY_MEMBERSHIP_PURCHASED_DETAILS_REQUEST';
export const COMPANY_MEMBERSHIP_PURCHASED_DETAILS_FAILURE =
  'COMPANY_MEMBERSHIP_PURCHASED_DETAILS_FAILURE';

const getCompanyMembershipPurchasedDetailsRequest = () => ({
  type: COMPANY_MEMBERSHIP_PURCHASED_DETAILS_REQUEST,
});

const getCompanyMembershipPurchasedDetailsFailure = (error) => ({
  type: COMPANY_MEMBERSHIP_PURCHASED_DETAILS_FAILURE,
  payload: { error: error },
});

const getCompanyMembershipPurchasedDetailsSuccess = (data) => ({
  type: COMPANY_MEMBERSHIP_PURCHASED_DETAILS_SUCCESS,
  payload: data,
});

export const getCompanyMembershipPurchasedDetails = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyMembershipPurchasedDetailsRequest());
    return http
      .post(apiUrl.COMPANY_MEMBERSHIP_PURCHASED_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyMembershipPurchasedDetailsSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyMembershipPurchasedDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyMembershipPurchasedDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyMembershipPurchasedDetailsFailure());
        return error;
      });
  };
};

export const COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_SUCCESS =
  'COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_SUCCESS';
export const COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_REQUEST =
  'COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_REQUEST';
export const COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_FAILURE =
  'COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_FAILURE';

const getCompanyMembershipCategoryPurchasedVoucherRequest = () => ({
  type: COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_REQUEST,
});

const getCompanyMembershipCategoryPurchasedVoucherFailure = (error) => ({
  type: COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_FAILURE,
  payload: { error: error },
});

const getCompanyMembershipCategoryPurchasedVoucherSuccess = (data) => ({
  type: COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS_SUCCESS,
  payload: data,
});

export const getCompanyMembershipCategoryPurchasedVoucher = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyMembershipCategoryPurchasedVoucherRequest());
    return http
      .post(apiUrl.COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS, payload)
      .then((response) => {
        debugger;
        if (response?.status === 1) {
          dispatch(
            getCompanyMembershipCategoryPurchasedVoucherSuccess(response?.data),
          );
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(
              getCompanyMembershipCategoryPurchasedVoucherSuccess({ data: [] }),
            );
            return response;
          } else {
            dispatch(
              getCompanyMembershipCategoryPurchasedVoucherFailure(response),
            );
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyMembershipCategoryPurchasedVoucherFailure());
        return error;
      });
  };
};

export const COMPANY_AUTHORIZATION_PERSON_SUCCESS =
  'COMPANY_AUTHORIZATION_PERSON_SUCCESS';
export const COMPANY_AUTHORIZATION_PERSON_REQUEST =
  'COMPANY_AUTHORIZATION_PERSON_REQUEST';
export const COMPANY_AUTHORIZATION_PERSON_FAILURE =
  'COMPANY_AUTHORIZATION_PERSON_FAILURE';

const postCompanyAuthorizationPersonRequest = () => ({
  type: COMPANY_AUTHORIZATION_PERSON_REQUEST,
});

const postCompanyAuthorizationPersonFailure = (error) => ({
  type: COMPANY_AUTHORIZATION_PERSON_FAILURE,
  payload: { error: error },
});

const postCompanyAuthorizationPersonSuccess = (data) => ({
  type: COMPANY_AUTHORIZATION_PERSON_SUCCESS,
  payload: data,
});

export const postCompanyAuthorizationPerson = (payload) => {
  return (dispatch) => {
    dispatch(postCompanyAuthorizationPersonRequest());
    return http
      .post(apiUrl.COMPANY_AUTHORIZATION_PERSON, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postCompanyAuthorizationPersonSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(postCompanyAuthorizationPersonSuccess({ data: [] }));
            return response;
          } else {
            dispatch(postCompanyAuthorizationPersonFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(postCompanyAuthorizationPersonFailure());
        return error;
      });
  };
};

export const COMPANY_CHECK_DOMAIN_NAME_SUCCESS =
  'COMPANY_CHECK_DOMAIN_NAME_SUCCESS';
export const COMPANY_CHECK_DOMAIN_NAME_REQUEST =
  'COMPANY_CHECK_DOMAIN_NAME_REQUEST';
export const COMPANY_CHECK_DOMAIN_NAME_FAILURE =
  'COMPANY_CHECK_DOMAIN_NAME_FAILURE';

const getCompanyCheckDomainNameRequest = () => ({
  type: COMPANY_CHECK_DOMAIN_NAME_REQUEST,
});

const getCompanyCheckDomainNameFailure = (error) => ({
  type: COMPANY_CHECK_DOMAIN_NAME_FAILURE,
  payload: { error: error },
});

const getCompanyCheckDomainNameSuccess = (data) => ({
  type: COMPANY_CHECK_DOMAIN_NAME_SUCCESS,
  payload: data,
});

export const getCompanyCheckDomainName = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyCheckDomainNameRequest());
    return http
      .post(apiUrl.COMPANY_CHECK_DOMAIN_NAME, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyCheckDomainNameSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyCheckDomainNameSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyCheckDomainNameFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyCheckDomainNameFailure());
        return error;
      });
  };
};

export const COMPANY_VOUCHER_DETAILS_SUCCESS =
  'COMPANY_VOUCHER_DETAILS_SUCCESS';
export const COMPANY_VOUCHER_DETAILS_REQUEST =
  'COMPANY_VOUCHER_DETAILS_REQUEST';
export const COMPANY_VOUCHER_DETAILS_FAILURE =
  'COMPANY_VOUCHER_DETAILS_FAILURE';

const getCompanyVoucherDetailsRequest = () => ({
  type: COMPANY_VOUCHER_DETAILS_REQUEST,
});

const getCompanyVoucherDetailsFailure = (error) => ({
  type: COMPANY_VOUCHER_DETAILS_FAILURE,
  payload: { error: error },
});

const getCompanyVoucherDetailsSuccess = (data) => ({
  type: COMPANY_VOUCHER_DETAILS_SUCCESS,
  payload: data,
});

export const getCompanyVoucherDetails = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyVoucherDetailsRequest());
    return http
      .post(apiUrl.COMPANY_VOUCHER_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyVoucherDetailsSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyVoucherDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyVoucherDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyVoucherDetailsFailure());
        return error;
      });
  };
};

export const COMPANY_CLOSED_JOB_LIST_SUCCESS =
  'COMPANY_CLOSED_JOB_LIST_SUCCESS';
export const COMPANY_CLOSED_JOB_LIST_REQUEST =
  'COMPANY_CLOSED_JOB_LIST_REQUEST';
export const COMPANY_CLOSED_JOB_LIST_FAILURE =
  'COMPANY_CLOSED_JOB_LIST_FAILURE';

const getCompanyClosedJobListRequest = () => ({
  type: COMPANY_CLOSED_JOB_LIST_REQUEST,
});

const getCompanyClosedJobListFailure = (error) => ({
  type: COMPANY_CLOSED_JOB_LIST_FAILURE,
  payload: { error: error },
});

const getCompanyClosedJobListSuccess = (data) => ({
  type: COMPANY_CLOSED_JOB_LIST_SUCCESS,
  payload: data,
});

export const getCompanyClosedJobList = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyClosedJobListRequest());
    return http
      .post(apiUrl.COMPANY_JOB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyClosedJobListSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyClosedJobListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyJobListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyClosedJobListFailure());
        return error;
      });
  };
};

export const COMPANY_EVENT_PAST_LIST_SUCCESS =
  'COMPANY_EVENT_PAST_LIST_SUCCESS';
export const COMPANY_EVENT_PAST_LIST_REQUEST =
  'COMPANY_EVENT_PAST_LIST_REQUEST';
export const COMPANY_EVENT_PAST_LIST_FAILURE =
  'COMPANY_EVENT_PAST_LIST_FAILURE';

const getCompanyEventPastListRequest = () => ({
  type: COMPANY_EVENT_PAST_LIST_REQUEST,
});

const getCompanyEventPastListFailure = (error) => ({
  type: COMPANY_EVENT_PAST_LIST_FAILURE,
  payload: { error: error },
});

const getCompanyEventPastListSuccess = (data) => ({
  type: COMPANY_EVENT_PAST_LIST_SUCCESS,
  payload: data,
});

export const getCompanyPastEventList = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyEventPastListRequest());
    return http
      .post(apiUrl.COMPANY_HOSTED_EVENT_PAST_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyEventPastListSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyEventPastListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyEventPastListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyEventPastListFailure());
        return error;
      });
  };
};

export const COMPANY_EVENT_LIST_SUCCESS = 'COMPANY_EVENT_LIST_SUCCESS';
export const COMPANY_EVENT_LIST_REQUEST = 'COMPANY_EVENT_LIST_REQUEST';
export const COMPANY_EVENT_LIST_FAILURE = 'COMPANY_EVENT_LIST_FAILURE';

const getCompanyEventListRequest = () => ({
  type: COMPANY_EVENT_LIST_REQUEST,
});

const getCompanyEventListFailure = (error) => ({
  type: COMPANY_EVENT_LIST_FAILURE,
  payload: { error: error },
});

const getCompanyEventListSuccess = (data) => ({
  type: COMPANY_EVENT_LIST_SUCCESS,
  payload: data,
});

// export const getCompanyEventList = (payload, statuschange, id, callback) => {
//   return (dispatch) => {
//     dispatch(companyPageCreateRequest());
//     const token = getAccessToken();
//     return API.http_form.post(
//       {
//         endPoint: apiUrl.COMPANY_EVENT_LIST,
//         body: payload,
//         token: token,
//       },

//       (error, response) => {
//         if (error) {
//           console.log('error', error);
//           const message = `${error.error || error.status || error.name}: ${
//             error.message
//           }`;
//           dispatch(companyPageCreateFailure(error));
//           showMessage({
//             dispatch,
//             message: 'Company create failed',
//             variant: 'error',
//           });
//           callback(error);
//           return error;
//         } else {
//           console.log('response', response);
//           dispatch(companyPageCreateSuccess(response));
//           // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
//           //     status: "applied"}))
//           // dispatch(getRecommendedList({user_id: type?.user_id}))
//           // dispatch(getJobDetails( {job_id: type?.job_id}))
//           // dispatch(closeApplyJob())
//           if (id === 0) {
//             showMessage({
//               dispatch,
//               message: 'Company successfully created',
//               variant: 'success',
//             });
//           } else if (id !== 0 && !statuschange) {
//             showMessage({
//               dispatch,
//               message: 'Company successfully created',
//               variant: 'success',
//             });
//           } else if (id !== 0 && statuschange) {
//             showMessage({
//               dispatch,
//               message: 'Company successfully created',
//               variant: 'success',
//             });
//           }
//           callback(null, response);
//           return response;
//         }
//       },
//     );
//     // .then((response) =>{
//     //   return response
//     // })
//     // .catch((error) =>{
//     //   return error
//     // })
//   };
// };

export const getCompanyEventList = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyEventListRequest());
    return http
      .post(apiUrl.COMPANY_EVENT_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCompanyEventListSuccess(response?.data));
          return response;
        } else {
          if (
            response?.data?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(getCompanyEventListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getCompanyEventListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCompanyEventListFailure());
        return error;
      });
  };
};

export const COMPANY_PAGE_UPDATE_SUCCESS = 'COMPANY_PAGE_UPDATE_SUCCESS';
export const COMPANY_PAGE_UPDATE_REQUEST = 'COMPANY_PAGE_UPDATE_REQUEST';
export const COMPANY_PAGE_UPDATE_FAILURE = 'COMPANY_PAGE_UPDATE_FAILURE';

const companyPageUpdateRequest = () => ({
  type: COMPANY_PAGE_UPDATE_REQUEST,
});

const companyPageUpdateFailure = (error) => ({
  type: COMPANY_PAGE_UPDATE_FAILURE,
  payload: { error: error },
});

const companyPageUpdateSuccess = (data) => ({
  type: COMPANY_PAGE_UPDATE_SUCCESS,
  payload: data,
});

export const companyPageUpdate = (payload, statuschange, id, callback) => {
  return (dispatch) => {
    dispatch(companyPageUpdateRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.COMPANY_PAGE_UPDATE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(companyPageUpdateFailure(error));
          showMessage({
            dispatch,
            message: 'Company create failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          dispatch(companyPageUpdateSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          if (id === 0) {
            showMessage({
              dispatch,
              message: 'Company successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && !statuschange) {
            showMessage({
              dispatch,
              message: 'Company successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && statuschange) {
            showMessage({
              dispatch,
              message: 'Company successfully created',
              variant: 'success',
            });
          }
          callback(null, response);
          return response;
        }
      },
    );
    // .then((response) =>{
    //   return response
    // })
    // .catch((error) =>{
    //   return error
    // })
  };
};

export const COMPANY_CHECK_OTP_SEND_SUCCESS = 'COMPANY_CHECK_OTP_SEND_SUCCESS';
export const COMPANY_CHECK_OTP_SEND_REQUEST = 'COMPANY_CHECK_OTP_SEND_REQUEST';
export const COMPANY_CHECK_OTP_SEND_FAILURE = 'COMPANY_CHECK_OTP_SEND_FAILURE';

const sendCompanyCheckOtpRequest = () => ({
  type: COMPANY_CHECK_OTP_SEND_REQUEST,
});

const sendCompanyCheckOtpFailure = (error) => ({
  type: COMPANY_CHECK_OTP_SEND_FAILURE,
  payload: { error: error },
});

const sendCompanyCheckOtpSuccess = (data, payld) => ({
  type: COMPANY_CHECK_OTP_SEND_SUCCESS,
  payload: { payld: payld, data: data },
});

export const sendCompanyCheckOtp = (payload) => {
  return (dispatch) => {
    dispatch(sendCompanyCheckOtpRequest());
    return http
      .post(apiUrl.COMPANY_CHECK_OTP_SEND, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(sendCompanyCheckOtpSuccess(response?.data, payload));
          return response;
        } else {
          if (
            response?.data?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(sendCompanyCheckOtpSuccess({ data: [] }));
            return response;
          } else {
            dispatch(sendCompanyCheckOtpFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(sendCompanyCheckOtpFailure());
        return error;
      });
  };
};

export const COMPANY_VERIFY_OTP_SUCCESS = 'COMPANY_VERIFY_OTP_SUCCESS';
export const COMPANY_VERIFY_OTP_REQUEST = 'COMPANY_VERIFY_OTP_REQUEST';
export const COMPANY_VERIFY_OTP_FAILURE = 'COMPANY_VERIFY_OTP_FAILURE';

const verifyCompanyOtpRequest = () => ({
  type: COMPANY_VERIFY_OTP_REQUEST,
});

const verifyCompanyOtpFailure = (error) => ({
  type: COMPANY_VERIFY_OTP_FAILURE,
  payload: { error: error },
});

const verifyCompanyOtpSuccess = (data, payld) => ({
  type: COMPANY_VERIFY_OTP_SUCCESS,
  payload: { payld: payld, data: data },
});

export const verifyCompanyOtp = (payload) => {
  return (dispatch) => {
    dispatch(verifyCompanyOtpRequest());
    return http
      .post(apiUrl.COMPANY_VERIFY_OTP, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(verifyCompanyOtpSuccess(response?.data, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(verifyCompanyOtpSuccess({ data: [] }));
            return response;
          } else {
            dispatch(verifyCompanyOtpFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        debugger;
        dispatch(verifyCompanyOtpFailure());
        showMessage({
          dispatch,
          message: "Otp doesn't Matched",
          variant: 'error',
        });
        return error;
      });
  };
};

export const COMPANY_ACCESS_CREATE_SUCCESS = 'COMPANY_ACCESS_CREATE_SUCCESS';
export const COMPANY_ACCESS_CREATE_REQUEST = 'COMPANY_ACCESS_CREATE_REQUEST';
export const COMPANY_ACCESS_CREATE_FAILURE = 'COMPANY_ACCESS_CREATE_FAILURE';

const accessCompanyCreateRequest = () => ({
  type: COMPANY_ACCESS_CREATE_REQUEST,
});

const accessCompanyCreateFailure = (error) => ({
  type: COMPANY_ACCESS_CREATE_FAILURE,
  payload: { error: error },
});

const accessCompanyCreateSuccess = (data, payld) => ({
  type: COMPANY_ACCESS_CREATE_SUCCESS,
  payload: { payld: payld, data: data },
});

export const accessCompanyCreate = (payload) => {
  return (dispatch) => {
    dispatch(accessCompanyCreateRequest());
    return http
      .post(apiUrl.COMPANY_ACCESS_CREATE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(accessCompanyCreateSuccess(response?.data, payload));
          return response;
        } else {
          if (
            response?.data?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(accessCompanyCreateSuccess({ data: [] }));
            return response;
          } else {
            dispatch(accessCompanyCreateFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(accessCompanyCreateFailure());
        return error;
      });
  };
};

export const OPEN_COMPANY_ACCESS = 'OPEN_COMPANY_ACCESS';
export const CLOSE_COMPANY_ACCESS = 'CLOSE_COMPANY_ACCESS';

export const closeCompanyAccess = () => ({
  type: 'CLOSE_COMPANY_ACCESS',
});

export const openCompanyAccess = (data) => {
  return {
    type: 'OPEN_COMPANY_ACCESS',
    payload: { data: data },
  };
};

export const RESET_COMPANY_OTP = 'RESET_COMPANY_OTP';

export const sendCompanyReset = () => ({
  type: 'RESET_COMPANY_OTP',
});
