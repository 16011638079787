import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import RewardPartnerVoucherCard from '../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard';
import RewardPartnerCard from '../../components/RewardPartnerCard/RewardPartnerCard';
import CorporatePartnerCard from '../../components/CorporatePartnerCard/CorporatePartnerCard';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import { dateTime } from '../../constants/app';
import * as rewardsActions from '../../store/rewards';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const PartnerRewards = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const [noofPartner, setNoofPartner] = React.useState(4);
  const [noofrewardpage, setNoofRewardPage] = React.useState(0);
  const [hideMore, setHidemo] = React.useState(false);
  const user = useSelector((state) => state?.user?.UserDetails);

  const rewardsPartnerDetails = useSelector(
    (state) => state?.rewards?.rewardsPartnerDetails,
  );
  const rewardsPartnerList = useSelector(
    (state) => state?.rewards?.rewardsPartnerList,
  );
  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );
  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );
  const rewardsPartnerloading = useSelector(
    (state) => state?.rewards?.rewardsPartnerloading,
  );
  useEffect(async () => {
    const rewardsCategory = await dispatch(
      rewardsActions.rewardsCategoryList(),
    );
  }, []);

  useEffect(() => {
    async function fetchPartnerData() {
      if (params != 'all') {
        const id = params?.categoryId?.split('-');

        if (id?.length > 0) {
          const data = {
            category_id: id[id?.length - 1].substring(2),
            page_no: noofrewardpage,
          };
          // const eventdetails = await dispatch(eventsActions.eventDetails(data));
          const rewardspartners = await dispatch(
            rewardsActions.rewardsPartnerList(data),
          );

          const datanext = {
            category_id: id[id?.length - 1].substring(2),
            page_no: noofrewardpage + 1,
          };
          // const eventdetails = await dispatch(eventsActions.eventDetails(data));
          const rewardspartnersnext = await dispatch(
            rewardsActions.rewardsPartnerList(datanext),
          );

          if (rewardspartnersnext?.data?.length === 0) {
            setHidemo(true);
          }
        }
      } else {
        const data = {
          category_id: 0,
          page_no: noofrewardpage,
        };
        const rewardspartners = await dispatch(
          rewardsActions.rewardsPartnerList(data),
        );
      }
    }
    fetchPartnerData();
  }, []);

  // useEffect(async () => {
  //   const data = {
  //     category_id: 0,
  //   };
  //   const rewardspartners = await dispatch(
  //     rewardsActions.rewardsPartnerList(data)
  //   );
  // }, []);

  useEffect(async () => {
    const data = {
      partner_id: 25,
      datetime: dateTime(),
    };
    const rewardVouchelist = await dispatch(
      rewardsActions.rewardsVoucherList(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      partner_id: 25,
      datetime: dateTime(),
    };
    const rewardVouchelist = await dispatch(
      rewardsActions.rewardsPartnerDetails(data),
    );
  }, []);

  const seemore = async () => {
    setNoofRewardPage(noofrewardpage + 1);
    if (params != 'all') {
      const id = params?.categoryId?.split('-');

      if (id?.length > 0) {
        const data = {
          category_id: id[id?.length - 1].substring(2),
          page_no: noofrewardpage + 1,
        };
        // const eventdetails = await dispatch(eventsActions.eventDetails(data));
        const rewardspartners = await dispatch(
          rewardsActions.rewardsPartnerList(data),
        );

        const datanext = {
          category_id: id[id?.length - 1].substring(2),
          page_no: noofrewardpage + 2,
        };
        // const eventdetails = await dispatch(eventsActions.eventDetails(data));
        const rewardspartnersnext = await dispatch(
          rewardsActions.rewardsPartnerList(datanext),
        );

        if (rewardspartnersnext?.data?.length === 0) {
          setHidemo(true);
        }
      }
    } else {
      const data = {
        category_id: 0,
        page_no: noofrewardpage + 1,
      };
      const rewardspartners = await dispatch(
        rewardsActions.rewardsPartnerList(data),
      );

      const datanext = {
        category_id: 0,
        page_no: noofrewardpage + 2,
      };
      // const eventdetails = await dispatch(eventsActions.eventDetails(data));
      const rewardspartnersnext = await dispatch(
        rewardsActions.rewardsPartnerList(datanext),
      );

      if (rewardspartnersnext?.data?.length === 0) {
        setHidemo(true);
      }
    }
  };

  if (rewardsPartnerloading) {
    return (
      <Stack direction="column" spacing={1}>
        <PartnerCardSkeleton />
        <PartnerCardSkeleton />
      </Stack>
    );
  } else {
    return (
      <Fragment>
        <Box>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Grid container>
                {rewardsPartnerList?.length > 0 &&
                  rewardsPartnerList?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <CorporatePartnerCard partner={list} type="partner" />
                      </Grid>
                    );
                  })}

                {rewardsPartnerList?.length === 0 && (
                  <Box pl={2}>
                    <Typography variant="h5" component="h5">
                      There is no Partner.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
            {hideMore ? (
              <></>
            ) : (
              <Box
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seemore}>
                <Typography variant="h5" component="h5">
                  See More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(PartnerRewards);

//
// {rewardsVoucherList?.length > 0 &&
//   rewardsVoucherList?.map((list, index) => {
//     return (
//       <Grid
//         key={index}
//         item
//         xs={12}
//         md={6}
//         sx={{
//           paddingRight: index % 2 === 0 ? "8px" : "0px",
//           marginTop: "8px",
//         }}
//       >
//         <RewardPartnerVoucherCard
//           voucher={list}
//           partner={rewardsPartnerDetails}
//           type="voucher"
//         />
//       </Grid>
//     );
//   })}

//
// <Grid
//   key={10}
//   item
//   xs={6}
//   md={6}
//   sx={{
//     marginTop: "8px",
//   }}
// >
//   <RewardPartnerVoucherCard type="own" />
// </Grid>
//
// <Grid
//   key={110}
//   item
//   xs={6}
//   md={6}
//   sx={{
//     marginTop: "8px",
//   }}
// >
//   <RewardPartnerCard type="own" />
// </Grid>
