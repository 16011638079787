import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useStyles from './networkcardstyle';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as messageActions from '../../store/message';
import { useNavigate, useLocation } from 'react-router-dom';

export default function JobProfileCard(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const navigate = useNavigate();

  useEffect(async () => {
    const data = {
      user_id: props?.userlist?.id,
    };

    // const mutualfriend = await dispatch(usernetworkActions.discoverUserNetwork(data));
    // if(mutualfriend.Status === 1){
    //   setMutualFriend(mutualfriend?.data)
    // }
    // console.log('mutualfriend',user.id,props?.userlist?.id, mutualfriend);
  }, []);
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    console.log('handleMobileMenuOpen', anchorEl);
    // setMobileMoreAnchorEl(event.currentTarget);
    setAnchorEl(!anchorEl);
  };
  const connectNetwork = async () => {
    if (user?.isVerified) {
      const data = {
        from_user_id: user?.id,
        to_user_id: props?.userlist?.id,
        datetime: dateTime(),
      };
      const connectNetwork = await dispatch(
        usernetworkActions.sendConnectionNetwork(data),
      );
      const datauser = {
        user_id: user?.id,
      };
      const sentlist = await dispatch(
        usernetworkActions.getSentNetwork(datauser),
      );

      const existinglist = await dispatch(
        usernetworkActions.getExistingNetwork(datauser),
      );
      const pendinglist = await dispatch(
        usernetworkActions.getPendingNetwork(datauser),
      );

      const datadiscover = {
        user_id: user?.id,
        page_no: 0,
      };

      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(datadiscover, 'new'),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const ignoreNetwork = async () => {
    if (user?.isVerified) {
      const data = {
        from_user_id: user?.id,
        to_user_id: props?.userlist?.id,
        datetime: dateTime(),
      };
      const ignoreNetwork = await dispatch(
        usernetworkActions.sendConnectionDislike(data),
      );
      const datauser = {
        user_id: user?.id,
      };
      const sentlist = await dispatch(
        usernetworkActions.getSentNetwork(datauser),
      );
      const existinglist = await dispatch(
        usernetworkActions.getExistingNetwork(datauser),
      );
      const pendinglist = await dispatch(
        usernetworkActions.getPendingNetwork(datauser),
      );
      const datadiscover = {
        user_id: user?.id,
        page_no: 0,
      };

      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(datadiscover, 'new'),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const acceptNetwork = async () => {
    if (user?.isVerified) {
      const data = {
        from_user_id: user?.id,
        to_user_id: props?.userlist?.id,
        datetime: dateTime(),
      };
      const accptNetwork = await dispatch(
        usernetworkActions.acceptRequest(data),
      );
      const datauser = {
        user_id: user?.id,
      };
      const sentlist = await dispatch(
        usernetworkActions.getSentNetwork(datauser),
      );
      const existinglist = await dispatch(
        usernetworkActions.getExistingNetwork(datauser),
      );
      const pendinglist = await dispatch(
        usernetworkActions.getPendingNetwork(datauser),
      );

      const datadiscover = {
        user_id: user?.id,
        page_no: 0,
      };

      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(datadiscover, 'new'),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const rejectNetwork = async () => {
    if (user?.isVerified) {
      const data = {
        from_user_id: user?.id,
        to_user_id: props?.userlist?.id,
      };
      const rjectNetwork = await dispatch(
        usernetworkActions.requestReject(data),
      );
      const datauser = {
        user_id: user?.id,
      };
      const sentlist = await dispatch(
        usernetworkActions.getSentNetwork(datauser),
      );
      const existinglist = await dispatch(
        usernetworkActions.getExistingNetwork(datauser),
      );
      const pendinglist = await dispatch(
        usernetworkActions.getPendingNetwork(datauser),
      );

      const datadiscover = {
        user_id: user?.id,
        page_no: 0,
      };

      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(datadiscover, 'new'),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const friendRequestRemoveNetwork = async () => {
    if (user?.isVerified) {
      const data = {
        from_user_id: user?.id,
        to_user_id: props?.userlist?.id,
      };
      const rjectNetwork = await dispatch(
        usernetworkActions.friendRequestRemove(data),
      );
      const datauser = {
        user_id: user?.id,
      };
      const sentlist = await dispatch(
        usernetworkActions.getSentNetwork(datauser),
      );
      const existinglist = await dispatch(
        usernetworkActions.getExistingNetwork(datauser),
      );
      const pendinglist = await dispatch(
        usernetworkActions.getPendingNetwork(datauser),
      );
      const datadiscover = {
        user_id: user?.id,
        page_no: 0,
      };

      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(datadiscover, 'new'),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const removeFriendNetwork = async () => {
    if (user?.isVerified) {
      const data = {
        from_user_id: user?.id,
        to_user_id: props?.userlist?.id,
      };
      const rjectNetwork = await dispatch(
        usernetworkActions.removeExistingNetwork(data),
      );
      const datauser = {
        user_id: user?.id,
      };
      const sentlist = await dispatch(
        usernetworkActions.getSentNetwork(datauser),
      );
      const existinglist = await dispatch(
        usernetworkActions.getExistingNetwork(datauser),
      );
      const pendinglist = await dispatch(
        usernetworkActions.getPendingNetwork(datauser),
      );
      const datadiscover = {
        user_id: user?.id,
        page_no: 0,
      };

      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(datadiscover, 'new'),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const profileOpen = async () => {
    if (user?.isVerified) {
      const profile =
        props?.userlist?.first_name +
        '-' +
        props?.userlist?.last_name +
        '-' +
        'lx' +
        props?.userlist?.id;
      console.log('profile', profile);
      navigate('/user/' + profile);
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  return (
    <Card
      sx={{
        display: 'flex',
        backgroundColor: '#262525',
        backgroundImage: 'none',
        maxHeight: '160px',
        minHeight: '160px',
        '@media (max-width: 500px)': {
          maxHeight: '100px',
          minWidth: '100px',
        },
      }}>
      {props?.userlist?.image ? (
        <CardMedia
          component="img"
          onClick={() => profileOpen()}
          sx={{
            width: 160,
            minWidth: '160px',
            borderRadius: '5px',
            backgroundColor: '#262525',
            margin: '8px 0px 8px 8px',
            cursor: 'pointer',

            '@media (max-width: 500px)': {
              width: 100,
              minWidth: '120px',
            },
          }}
          image={props?.userlist?.image ? props?.userlist?.image : ''}
        />
      ) : (
        <Box
          onClick={() => profileOpen()}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '216px',
            maxHeight: { xs: 180, md: 167 },
            maxWidth: { xs: 350, md: 250 },
            borderRadius: '16px',
            padding: '8px',
            objectFit: 'cover',
            backgroundColor: '#222121',
            margin: '8px 0px 8px 8px',
            cursor: 'pointer',
          }}
          alt="">
          <Avatar
            sx={{
              width: 92,
              height: 92,
              '@media (max-width: 500px)': {
                width: 80,
                height: 80,
              },
            }}>
            <Typography
              sx={{
                color: '#fff',
                fontSize: '40px',
                fontWeight: '600',
                textTransform: 'capitalize',
              }}>
              {props?.userlist?.first_name[0]}
            </Typography>
          </Avatar>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <CardHeader
          sx={{ padding: '0px 8px' }}
          title={
            <Box pt={1.5}>
              <Typography
                component="h2s"
                variant="h2s"
                sx={{
                  fontSize: '18px',
                  '@media (max-width: 500px)': { fontSize: '14px' },
                }}
                textTransform="capitalize">
                {props?.userlist?.first_name ? props?.userlist?.first_name : ''}{' '}
                {props?.userlist?.last_name ? props?.userlist?.last_name : ''}
              </Typography>
            </Box>
          }
          subheader={
            <Box>
              <Box pt={1.4}>
                <Stack spacing={0.4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      fontSize: '13px',
                      color: '#ffffff',
                      fontWeight: 'revert',
                      lineHeight: '16px',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                        fontWeight: '400',
                      },
                    }}>
                    {props?.userlist?.designation &&
                    props?.userlist?.designation?.length > 30
                      ? props?.userlist?.designation?.slice(0, 30) + '...'
                      : props?.userlist?.designation}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      fontSize: '12px',
                      color: '#ffffff',
                      fontWeight: 'revert',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                        fontWeight: '400',
                      },
                    }}>
                    {props?.userlist?.company_name &&
                    props?.userlist?.company_name?.length > 20
                      ? props?.userlist?.company_name?.slice(0, 20) + '...'
                      : props?.userlist?.company_name}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          }
          action={
            <Box mt={{ md: 1, xs: 0 }}>
              <IconButton aria-label="settings"></IconButton>
            </Box>
          }
        />
        <Stack direction="row" justifyContent="flex-start">
          <Box pl={2} pt={0} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              pt={0.5}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItem: 'center',
                justifyContent: 'center',
              }}>
              {props?.userlist?.mutualFriends
                ?.slice(0, 2)
                .map((friend, index) => {
                  return (
                    <Avatar
                      key={index}
                      src={friend?.image}
                      sx={{
                        height: '20px',
                        border: '1.5px solid  #ffffff',
                        width: '20px',
                        marginLeft: '-10px',
                      }}
                    />
                  );
                })}
              {props?.userlist?.mutualFriends?.length != 0 && (
                <>
                  <Avatar
                    sx={{
                      height: '20px',
                      width: '20px',
                      marginLeft: '-10px',
                      border: '1.5px solid  #ffffff',
                      backgroundColor: '#343232',
                      fontSize: '10px',
                      color: '#ffffff',
                      fontWeight: '600',
                    }}>
                    {props?.userlist?.mutualFriends?.length}
                  </Avatar>
                </>
              )}
            </Box>
          </Box>

          {props?.userlist?.mutualFriends?.length != 0 && (
            <Box
              pl={1.5}
              sx={{
                display: 'flex',
                alignItem: 'center',
                justifyContent: 'center',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '4px',
                }}>
                <img alt="mutual" src={mutualfriends} />
              </Box>
            </Box>
          )}
        </Stack>

        <Box>
          {props.type === 'pending' && (
            <CardActions>
              <CxoButtonWithIcon
                // width="112px"
                label="Ignore"
                onClick={rejectNetwork}
                sx={{
                  width: '112px',
                  '@media (max-width: 500px)': {
                    width: '100px',
                  },
                }}
              />

              <CxoButtonWithIcon
                label="Connect"
                // width="112px"
                onClick={acceptNetwork}
                sx={{
                  width: '112px',
                  '@media (max-width: 500px)': {
                    width: '100px',
                  },
                }}
              />
            </CardActions>
          )}

          {props.type === 'sent' && (
            <CardActions>
              <CxoButtonWithIcon
                label="Cancel"
                onClick={friendRequestRemoveNetwork}
                sx={{
                  width: '112px',
                  '@media (max-width: 500px)': {
                    width: '100px',
                  },
                }}
              />
            </CardActions>
          )}
          {props.type === 'existing' && (
            <CardActions>
              <CxoButtonWithIcon onClick={removeFriendNetwork} label="Remove" />
            </CardActions>
          )}
          {props.type === 'discover' && (
            <CardActions>
              <CxoButtonWithIcon label="Connect " onClick={connectNetwork} />
            </CardActions>
          )}
        </Box>
      </Box>
    </Card>
  );
}
