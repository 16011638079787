import React, { Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Menuicon from '../../assets/menuicon';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import TextField from '@mui/material/TextField';
import CxoButton from '../CxoButton/CxoButton';
import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CommentReply(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [commentText, setCommenttext] = React.useState('');
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  console.log('user', user);
  const handleChange = (event) => {
    setCommenttext(event.target.value);
  };

  const addComment = async () => {
    if (commentText.length > 0) {
      setCommenttext('');
      return props.postComment(commentText);
    }
  };

  const editPost = () => {};
  const deletePost = () => {};

  return (
    <Card
      sx={{
        backgroundColor: '#2c2c2d',
        position: 'relative',
        backgroundImage: 'none',
        marginTop: '2px',
      }}>
      <Fragment>
        <CardHeader
          sx={{ paddingTop: '8px', paddingBottom: '8px' }}
          avatar={
            <Avatar
              src={user?.image ? user?.image : PersonIcon}
              sx={{ width: 24, height: 24 }}
            />
          }
          action={
            <Stack
              spacing={1}
              direction="row"
              sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton aria-label="addfriend">
                <MoreVertIcon />
              </IconButton>
            </Stack>
          }
          title={
            <Stack spacing={1} direction="row">
              <Typography variant="h6s" component="h6s">
                {user?.first_name} {user?.last_name}
              </Typography>
            </Stack>
          }
          subheader={
            <Stack spacing={1} direction="row">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '13px',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>
                {user?.designation ? user?.designation : ''} ||
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '13px',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>
                {user?.company_name ? user?.company_name : ''}
              </Typography>
            </Stack>
          }
        />

        <CardContent sx={{ padding: '8px' }}>
          <Box px={2} sx={{ zIndex: '1200' }} className="editor">
            <TextField
              variant="standard"
              fullWidth
              multiline
              rows={2}
              maxRows={16}
              value={commentText}
              onChange={handleChange}
              placeholder="Start Typing Here"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
        </CardContent>

        <CardActions
          disableSpacing
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}>
          <Box
            py={1}
            px={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}>
            <CxoButton
              label="Post"
              onClick={addComment}
              height="24px"
              width="90px"
              minWidth="90px"
              minHeight="24px"
              disabled="true"
            />
          </Box>
        </CardActions>
      </Fragment>
    </Card>
  );
}

// { props?.post?.document &&
//   <Document
// file= {props?.post?.document }
// onLoadSuccess={onDocumentLoadSuccess}
// >
// <Page pageNumber={pageNumber} />
// </Document>
//
// }
