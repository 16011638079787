import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import useStyles from './networkstyles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchBox from './SearchBox';
import Discover from './Discover';
import NetworkDiscover from './Discover';
import Network from './Network';
import NetworkCardSkeleton from '../../components/Skeleton/NetworkCardSkeleton';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as usernetworkActions from '../../store/usernetwork';
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const NetworkTab = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [noofSent, setNoofSent] = React.useState(4);
  const [noofpending, setNoofPending] = React.useState(4);
  const [noofExisting, setNoofExisting] = React.useState(4);
  const [showSearch, setShowSearch] = React.useState(true);
  const [showfilter, setShowfilter] = React.useState(false);
  const [searchTxt, setSearchTxt] = React.useState('');
  const [pageno, setPageno] = React.useState(0);

  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.UserDetails);
  const mynetwork = useSelector(
    (state) => state?.usernetwork?.userRecommendationList?.data,
  );
  const existinglist = useSelector(
    (state) => state?.usernetwork?.ExistingNetworkList,
  );
  const pendinglist = useSelector(
    (state) => state?.usernetwork?.PendingNetworkList,
  );
  const sendlist = useSelector((state) => state?.usernetwork?.SentNetworkList);
  const networkLoading = useSelector(
    (state) => state?.usernetwork?.networkLoading,
  );
  const searchAll = async () => {
    console.log('searchAll');
    if (searchTxt.length > 0) {
      setPageno(0);
      const data = {
        user_id: user?.id,
        search_string: searchTxt,
        page_no: 0,
      };
      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(data, 'new'),
      );
      if (location.pathname != '/network/discover') {
        navigate('/network/discover');
        setValue(0);
      }
    }
  };
  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const userList = await dispatch(
      usernetworkActions.getPeopleRecommendationList(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const existinglist = await dispatch(
      usernetworkActions.getExistingNetwork(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const pendinglist = await dispatch(
      usernetworkActions.getPendingNetwork(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const sentlist = await dispatch(usernetworkActions.getSentNetwork(data));
  }, []);

  const toogleSearch = () => {
    setShowSearch(!showSearch);
  };
  const updateTxt = (event) => {
    setSearchTxt(event.target.value);
  };

  const showFilter = () => {
    console.log('showFilter');
    setShowfilter(!showfilter);
  };
  const handleKeyDown = async (e) => {
    if (e.keyCode === 13 && searchTxt.length > 0) {
      setPageno(0);
      const data = {
        user_id: user?.id,
        search_string: searchTxt,
        page_no: 0,
      };
      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(data, 'new'),
      );
    }
  };
  const searchNetwork = async (key) => {
    const data = {
      user_id: user?.id,
      search_string: key,
      page_no: 0,
    };
    const userList = await dispatch(
      usernetworkActions.getMyNetworkList(data, 'new'),
    );
  };
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      navigate('/network/discover');
      setValue(newValue);
    } else if (newValue === 1) {
      navigate('/network/mynetwork');
      setValue(newValue);
    } else if (newValue === 2) {
      navigate('/network/existing');
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (location.pathname === '/network/discover') {
      setValue(0);
    } else if (location.pathname === '/network/mynetwork') {
      setValue(1);
    } else if (location.pathname === '/network/sent') {
      setValue(1);
    } else if (location.pathname === '/network/existing') {
      setValue(1);
    } else {
      setValue(0);
    }
  }, []);
  const seeMore = () => {
    if (value === 1 && sendlist?.length > noofSent) {
      if (sendlist?.length <= noofSent + 4) {
        setNoofSent(sendlist?.length);
      } else {
        setNoofSent(noofSent + 4);
      }
    } else if (value === 0 && pendinglist?.length > noofpending) {
      if (pendinglist?.length <= noofpending + 4) {
        setNoofPending(pendinglist?.length);
      } else {
        setNoofPending(noofpending + 4);
      }
    } else if (value === 2 && existinglist?.length > noofExisting) {
      if (existinglist?.length <= noofExisting + 4) {
        setNoofExisting(existinglist?.length);
      } else {
        setNoofExisting(noofExisting + 4);
      }
    }
  };

  const seeLess = () => {
    if (value === 1 && sendlist?.length === noofSent) {
      setNoofSent(4);
    } else if (value === 0 && pendinglist?.length === noofpending) {
      setNoofPending(4);
    } else if (value === 2 && existinglist?.length === noofExisting) {
      setNoofExisting(4);
    }
  };

  return (
    <Fragment>
      <Box px={1} py={1}>
        <Tabs
          defaultValue={0}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          color="white"
          aria-label="ant example">
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            <StyledTabsList sx={{ padding: '2px 16px' }}>
              <StyledTab>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                    Discover
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 0 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </StyledTab>
              <StyledTab>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                    My Network
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 1 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </StyledTab>

              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ width: '25%' }}
                spacing={0}>
                <Stack
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '25%',
                  }}>
                  {!showSearch && (
                    <Box>
                      <SearchBox
                        searchAll={searchAll}
                        handleKeyDown={handleKeyDown}
                        updateTxt={updateTxt}
                        searchTxt={searchTxt}
                      />
                    </Box>
                  )}
                  {showSearch && (
                    <Box onClick={toogleSearch} sx={{ cursor: 'pointer' }}>
                      <SearchIcon />
                    </Box>
                  )}
                  {!showSearch && (
                    <Box onClick={toogleSearch} sx={{ cursor: 'pointer' }}>
                      <CloseOutlinedIcon />
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '60px',
                      paddingRight: '8px',
                      paddingLeft: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={showFilter}>
                    <FilterAltOutlinedIcon />
                  </Box>
                </Stack>
              </Stack>
            </StyledTabsList>
          </Paper>

          <StyledTabPanel value={0}>
            <NetworkDiscover showfilter={showfilter} showFilter={showFilter} searchTxt={searchTxt} />
          </StyledTabPanel>

          <StyledTabPanel value={1}>
            <Network />
          </StyledTabPanel>
          <StyledTabPanel value={2} sx={{ paddingBottom: '8px' }}>
            <Grid container></Grid>
          </StyledTabPanel>
        </Tabs>
      </Box>
    </Fragment>
  );
};

export default React.memo(NetworkTab);
