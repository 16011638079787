import { API, apiUrl } from '../../services';
import { http, http_noauth } from '../../services/client';
import { showMessage } from '../message';
import {

  getAccessToken,
} from '../../services/authData';

export const GET_DESIGNATION_LIST_FULFILLED = 'GET_DESIGNATION_LIST_FULFILLED';
export const GET_DESIGNATION_LIST_PENDING = 'GET_DESIGNATION_LIST_PENDING';
export const GET_DESIGNATION_LIST_REJECTED = 'GET_DESIGNATION_LIST_REJECTED';

const getDesignationListRequest = () => ({
  type: GET_DESIGNATION_LIST_PENDING,
});

const getDesignationListFailure = (error) => ({
  type: GET_DESIGNATION_LIST_REJECTED,
  payload: error,
});

const getDesignationListSuccess = (data) => ({
  type: GET_DESIGNATION_LIST_FULFILLED,
  payload: { designationList: data, loggedIn: true },
});

export const getDesignationList = (payload) => {
  return (dispatch) => {
    dispatch(getDesignationListRequest());
    return http_noauth
      .post(apiUrl.GET_DESIGNATION_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getDesignationListSuccess(response));
          return response;
        } else {
          dispatch(getDesignationListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
        dispatch(getDesignationListFailure(error));
        return error;
      });
  };
};

export const getDesignationListold = (payload) => {
  return (dispatch) => {
    dispatch(getDesignationListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.GET_DESIGNATION_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getDesignationListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getDesignationListSuccess(response));
              resolve(response);
            } else {
              dispatch(getDesignationListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const GET_INDUSTRY_LIST_FULFILLED = 'GET_INDUSTRY_LIST_FULFILLED';
export const GET_INDUSTRY_LIST_PENDING = 'GET_INDUSTRY_LIST_PENDING';
export const GET_INDUSTRY_LIST_REJECTED = 'GET_INDUSTRY_LIST_REJECTED';

const getIndustryListRequest = () => ({
  type: GET_INDUSTRY_LIST_PENDING,
});

const getIndustryListFailure = (error) => ({
  type: GET_INDUSTRY_LIST_REJECTED,
  payload: error,
});

const getIndustryListSuccess = (data) => ({
  type: GET_INDUSTRY_LIST_FULFILLED,
  payload: { industryList: data },
});

export const getIndustryList = (payload) => {
  return (dispatch) => {
    dispatch(getIndustryListRequest());
    return http_noauth
      .post(apiUrl.GET_INDUSTRY_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getIndustryListSuccess(response));
          return response;
        } else {
          dispatch(getIndustryListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getIndustryListFailure(error));
        return error;
      });
  };
};

export const getIndustryListold = (payload) => {
  return (dispatch) => {
    dispatch(getIndustryListRequest());
    const token = getAccessToken();
    return new Promise((resolve, reject) => {
      console.log('getIndustryList token', token);
      API.http.post(
        {
          endPoint: apiUrl.GET_INDUSTRY_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getIndustryListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getIndustryListSuccess(response));
              resolve(response);
            } else {
              dispatch(getIndustryListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const GET_FUNCTION_LIST_FULFILLED = 'GET_FUNCTION_LIST_FULFILLED';
export const GET_FUNCTION_LIST_PENDING = 'GET_FUNCTION_LIST_PENDING';
export const GET_FUNCTION_LIST_REJECTED = 'GET_FUNCTION_LIST_REJECTED';

const getFunctionListRequest = () => ({
  type: GET_FUNCTION_LIST_PENDING,
});

const getFunctionListFailure = (error) => ({
  type: GET_FUNCTION_LIST_REJECTED,
  payload: error,
});

const getFunctionListSuccess = (data) => ({
  type: GET_FUNCTION_LIST_FULFILLED,
  payload: { functionList: data },
});

export const getFunctionList = (payload) => {
  return (dispatch) => {
    dispatch(getFunctionListRequest());
    return http_noauth
      .post(apiUrl.GET_FUNCTION_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getFunctionListSuccess(response));
          return response;
        } else {
          dispatch(getFunctionListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getFunctionListFailure(error));

        return error;
      });
  };
};

export const getFunctionListold = (payload) => {
  return (dispatch) => {
    dispatch(getFunctionListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      console.log('function list token', token);
      API.http.post(
        {
          endPoint: apiUrl.GET_FUNCTION_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getFunctionListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getFunctionListSuccess(response));
              resolve(response);
            } else {
              dispatch(getFunctionListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const GET_LOCATION_LIST_FULFILLED = 'GET_LOCATION_LIST_FULFILLED';
export const GET_LOCATION_LIST_PENDING = 'GET_LOCATION_LIST_PENDING';
export const GET_LOCATION_LIST_REJECTED = 'GET_LOCATION_LIST_REJECTED';

const getLocationListRequest = () => ({
  type: GET_LOCATION_LIST_PENDING,
});

const getLocationListFailure = (error) => ({
  type: GET_LOCATION_LIST_REJECTED,
  payload: error,
});

const getLocationListSuccess = (data) => ({
  type: GET_LOCATION_LIST_FULFILLED,
  payload: { LocationList: data },
});

export const getLocationList = (payload) => {
  return (dispatch) => {
    dispatch(getLocationListRequest());
    return http_noauth
      .post(apiUrl.GET_LOCATION_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getLocationListSuccess(response));
          return response;
        } else {
          dispatch(getLocationListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getLocationListFailure(error));

        return error;
      });
  };
};

export const getLocationListold = (payload) => {
  return (dispatch) => {
    dispatch(getLocationListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.GET_LOCATION_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getLocationListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getFunctionListSuccess(response));
              resolve(response);
            } else {
              dispatch(getLocationListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const GET_SUB_INDUSTRY_LIST_FULFILLED =
  'GET_SUB_INDUSTRY_LIST_FULFILLED';
export const GET_SUB_INDUSTRY_LIST_PENDING = 'GET_SUB_INDUSTRY_LIST_PENDING';
export const GET_SUB_INDUSTRY_LIST_REJECTED = 'GET_SUB_INDUSTRY_LIST_REJECTED';

const getSubIndustryListRequest = () => ({
  type: GET_SUB_INDUSTRY_LIST_PENDING,
});

const getSubIndustryListFailure = (error) => ({
  type: GET_SUB_INDUSTRY_LIST_REJECTED,
  payload: { error: error },
});

const getSubIndustryListSuccess = (data) => ({
  type: GET_SUB_INDUSTRY_LIST_FULFILLED,
  payload: { subindustryList: data },
});

export const getSubIndustryList = (payload) => {
  return (dispatch) => {
    dispatch(getLocationListRequest());
    return http_noauth
      .post(apiUrl.GET_SUB_INDUSTRY_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getSubIndustryListSuccess(response));
          return response;
        } else {
          dispatch(getSubIndustryListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getSubIndustryListFailure(error));

        return error;
      });
  };
};

export const getSubIndustryListold = (payload) => {
  return (dispatch) => {
    dispatch(getSubIndustryListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.GET_SUB_INDUSTRY_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getSubIndustryListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getSubIndustryListSuccess(response));
              resolve(response);
            } else {
              dispatch(getSubIndustryListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const GET_COUNTRY_CODE_LIST_FULFILLED =
  'GET_COUNTRY_CODE_LIST_FULFILLED';
export const GET_COUNTRY_CODE_LIST_PENDING = 'GET_COUNTRY_CODE_LIST_PENDING';
export const GET_COUNTRY_CODE_LIST_REJECTED = 'GET_COUNTRY_CODE_LIST_REJECTED';

const getCountryCodeRequest = () => ({
  type: GET_COUNTRY_CODE_LIST_PENDING,
});

const getCountryCodeFailure = (error) => ({
  type: GET_COUNTRY_CODE_LIST_REJECTED,
  payload: { error: error },
});

const getCountryCodeSuccess = (data) => ({
  type: GET_COUNTRY_CODE_LIST_FULFILLED,
  payload: { countryCodeList: data },
});

export const getCountryCodeListnew = (payload) => {
  return (dispatch) => {
    dispatch(getCountryCodeRequest());
    return http_noauth
      .post(apiUrl.GET_COUNTRY_CODE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCountryCodeSuccess(response));
          return response;
        } else {
          dispatch(getCountryCodeFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getCountryCodeFailure(error));
        showMessage({
          dispatch,
          message: 'Server Error',
          variant: 'error',
        });
        return error;
      });
  };
};

export const getCountryCodeList = (payload) => {
  return (dispatch) => {
    dispatch(getCountryCodeRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.GET_COUNTRY_CODE_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getCountryCodeFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getCountryCodeSuccess(response));
              resolve(response);
            } else {
              dispatch(getCountryCodeFailure(response));
              showMessage({
                dispatch,
                message: 'Server Error',
                variant: 'error',
              });
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const POST_BIO_FULFILLED = 'POST_BIO_FULFILLED';
export const POST_BIO_PENDING = 'POST_BIO_PENDING';
export const POST_BIO_REJECTED = 'POST_BIO_REJECTED';

const postBioRequest = () => ({
  type: POST_BIO_PENDING,
});

const postBioFailure = (error) => ({
  type: POST_BIO_REJECTED,
  payload: { error: error },
});

const postBioSuccess = (data) => ({
  type: POST_BIO_FULFILLED,
  payload: { bio: data },
});

export const postBio = (payload) => {
  return (dispatch) => {
    dispatch(postBioRequest());
    return http
      .post(apiUrl.POST_BIO, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postBioSuccess(response));
          return response;
        } else {
          dispatch(postBioFailure(response));
          showMessage({
            dispatch,
            message: 'Server Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(postBioFailure(error));
        showMessage({
          dispatch,
          message: 'Server Error',
          variant: 'error',
        });
        return error;
      });
  };
};

export const USER_REGISTER_FIRST_ADDITIONAL_FULFILLED =
  'USER_REGISTER_FIRST_ADDITIONAL_FULFILLED';
export const USER_REGISTER_FIRST_ADDITIONAL_PENDING =
  'USER_REGISTER_FIRST_ADDITIONAL_PENDING';
export const USER_REGISTER_FIRST_ADDITIONAL_REJECTED =
  'USER_REGISTER_FIRST_ADDITIONAL_REJECTED';

const userRegisterFirstAdditionalRequest = () => ({
  type: USER_REGISTER_FIRST_ADDITIONAL_PENDING,
});

const userRegisterFirstAdditionalFailure = (error) => ({
  type: USER_REGISTER_FIRST_ADDITIONAL_REJECTED,
  payload: { error: error },
});

const userRegisterFirstAdditionalSuccess = (data) => ({
  type: USER_REGISTER_FIRST_ADDITIONAL_FULFILLED,
  payload: { data: data },
});

export const userRegisterFirstAdditional = (payload) => {
  return (dispatch) => {
    dispatch(userRegisterFirstAdditionalRequest());
    return http
      .post(apiUrl.USER_REGISTER_FIRST_ADDITIONAL, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(userRegisterFirstAdditionalSuccess(response));
          return response;
        } else {
          dispatch(userRegisterFirstAdditionalFailure(response));
          showMessage({
            dispatch,
            message: 'Server Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userRegisterFirstAdditionalFailure(error));
        showMessage({
          dispatch,
          message: 'Server Error',
          variant: 'error',
        });
        return error;
      });
  };
};

export const postBioold = (payload) => {
  return (dispatch) => {
    dispatch(postBioRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.POST_BIO,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(postBioFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(postBioSuccess(response));
              resolve(response);
            } else {
              dispatch(postBioFailure(response));
              showMessage({
                dispatch,
                message: 'Server Error',
                variant: 'error',
              });
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const GET_INTEREST_LIST_FULFILLED = 'GET_INTEREST_LIST_FULFILLED';
export const GET_INTEREST_LIST_PENDING = 'GET_INTEREST_LIST_PENDING';
export const GET_INTEREST_LIST_REJECTED = 'GET_INTEREST_LIST_REJECTED';

const getUserInterestListRequest = () => ({
  type: GET_INTEREST_LIST_PENDING,
});

const getUserInterestListFailure = (error) => ({
  type: GET_INTEREST_LIST_REJECTED,
  payload: { error: error },
});

const getUserInterestListSuccess = (data) => ({
  type: GET_INTEREST_LIST_FULFILLED,
  payload: { interestList: data },
});

export const getUserInterestList = (payload) => {
  return (dispatch) => {
    dispatch(getUserInterestListRequest());
    return http
      .post(apiUrl.GET_INTEREST_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getUserInterestListSuccess(response));

          return response;
        } else {
          dispatch(getUserInterestListFailure(response));

          return response;
        }
      })
      .catch((error) => {
        dispatch(getUserInterestListFailure(error));
        return error;
      });
  };
};

export const getUserInterestListold = (payload) => {
  return (dispatch) => {
    dispatch(getUserInterestListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.GET_INTEREST_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getUserInterestListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getUserInterestListSuccess(response));
              resolve(response);
            } else {
              dispatch(getUserInterestListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const POST_INTEREST_LIST_FULFILLED = 'POST_INTEREST_LIST_FULFILLED';
export const POST_INTEREST_LIST_PENDING = 'POST_INTEREST_LIST_PENDING';
export const POST_INTEREST_LIST_REJECTED = 'POST_INTEREST_LIST_REJECTED';

const postUserInterestListRequest = () => ({
  type: POST_INTEREST_LIST_PENDING,
});

const postUserInterestListFailure = (error) => ({
  type: POST_INTEREST_LIST_REJECTED,
  payload: { error: error },
});

const postUserInterestListSuccess = (data) => ({
  type: POST_INTEREST_LIST_FULFILLED,
  payload: { interestList: data },
});

export const postUserInterestList = (payload) => {
  return (dispatch) => {
    dispatch(postUserInterestListRequest());
    return http
      .post(apiUrl.POST_INTEREST_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postUserInterestListSuccess(response));

          return response;
        } else {
          dispatch(postUserInterestListFailure(response));
          showMessage({
            dispatch,
            message: 'Server Error',
            variant: 'error',
          });

          return response;
        }
      })
      .catch((error) => {
        dispatch(postUserInterestListFailure(error));
        showMessage({
          dispatch,
          message: 'Server Error',
          variant: 'error',
        });
        return error;
      });
  };
};

export const postUserInterestListold = (payload) => {
  return (dispatch) => {
    dispatch(postUserInterestListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      console.log('interest token', token);
      API.http.post(
        {
          endPoint: apiUrl.POST_INTEREST_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(postUserInterestListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(postUserInterestListSuccess(response));
              resolve(response);
            } else {
              dispatch(postUserInterestListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const USER_REGISTER_FIRST_FULFILLED = 'USER_REGISTER_FIRST_FULFILLED';
export const USER_REGISTER_FIRST_PENDING = 'USER_REGISTER_FIRST_PENDING';
export const USER_REGISTER_FIRST_REJECTED = 'USER_REGISTER_FIRST_REJECTED';

const userRegisterFirstRequest = () => ({
  type: USER_REGISTER_FIRST_PENDING,
});

const userRegisterFirstFailure = (error) => ({
  type: USER_REGISTER_FIRST_REJECTED,
  payload: error,
});

const userRegisterFirstSuccess = (data) => ({
  type: USER_REGISTER_FIRST_FULFILLED,
  payload: data,
});

export const userRegisterFirst = (payload) => {
  return (dispatch) => {
    dispatch(userRegisterFirstRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.USER_REGISTER_FIRST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(userRegisterFirstFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(userRegisterFirstSuccess(response));
              resolve(response);
            } else {
              dispatch(userRegisterFirstFailure(response));
              showMessage({
                dispatch,
                message: 'Server Error',
                variant: 'error',
              });
              resolve(response);
            }
          }
        },
      );
    });
  };
};

// export const userRegisterFirst = (payload) => {
//   return (dispatch) => {
//     dispatch(userRegisterFirstRequest());
//     return http
//       .post(apiUrl.USER_REGISTER_FIRST, payload)
//       .then((response) => {
//         console.log("getArticlesList", response);
//         if (response?.status === 1) {
//           dispatch(userRegisterFirstSuccess(response));
//           return response;
//         } else {
//           dispatch(userRegisterFirstFailure(response));
//           showMessage({
//             dispatch,
//             message: "Server Error",
//             variant: "error",
//           });
//           return response;
//         }
//       })
//       .catch((error) => {
//         dispatch(userRegisterFirstFailure(error));
//         showMessage({
//           dispatch,
//           message: "Server Error",
//           variant: "error",
//         });
//         return error;
//       });
//   };
// };

const getLinkedInAcessTokenSuccess = (data) => ({
  type: 'GET_LINKEDIN_DATA_FULFILLED',
  payload: { linkedIn: data },
});

export const getLinkedInAcessToken = (code, host) => {
  return (dispatch) => {
    console.log('payload', code);
    const data = {
      code: code,
      host: host,
    };
    return http
      .post(`https://app.linkcxo.com/get_access_token?code=${code}`, data)
      .then((response) => {
        console.log('getArticlesList', response?.data);
        const user = response?.data;
        console.log('payload', user);
        dispatch(getLinkedInAcessTokenSuccess(user));
        return user;
      })
      .catch((error) => {
        console.log('getArticlesList error', error);

        return error;
      });
    // return new Promise((resolve, reject) => {
    //   axios
    //     .get(`http://localhost:3001/getUserCredentials?code=${code}`)
    //     .then((res) => {
    //       const user = res.data;
    //       console.log("payload", user);
    //       dispatch(checkMobileEmailLinkedin(user));
    //
    //       return user;
    //       // Do something with user
    //     })
    //     .catch((error) => {
    //       console.log('error', error);
    //       reject(error);
    //       return error;
    //     })
    // });
  };
};

// export const getLinkedInAcessToken = (code) => {
//   return (dispatch) => {
//     console.log('payload', code);
//     return http
//       .get(`https://cxosignup-lx.link/getUserCredentials?code=${code}`)
//       .then((response) => {
//         console.log('getArticlesList', response);
//         const user = response;
//         console.log('payload', user);
//         dispatch(getLinkedInAcessTokenSuccess(user));
//         return user;
//       })
//       .catch((error) => {
//         console.log('getArticlesList error', error);

//         return error;
//       });
//     // return new Promise((resolve, reject) => {
//     //   axios
//     //     .get(`http://localhost:3001/getUserCredentials?code=${code}`)
//     //     .then((res) => {
//     //       const user = res.data;
//     //       console.log("payload", user);
//     //       dispatch(checkMobileEmailLinkedin(user));
//     //
//     //       return user;
//     //       // Do something with user
//     //     })
//     //     .catch((error) => {
//     //       console.log('error', error);
//     //       reject(error);
//     //       return error;
//     //     })
//     // });
//   };
// };

export const USER_SUBSCRIPTION_FULFILLED = 'USER_SUBSCRIPTION_FULFILLED';
export const USER_SUBSCRIPTION_PENDING = 'USER_SUBSCRIPTION_PENDING';
export const USER_SUBSCRIPTION_REJECTED = 'USER_SUBSCRIPTION_REJECTED';

const userSubscriptionRequest = () => ({
  type: USER_SUBSCRIPTION_PENDING,
});

const userSubscriptionFailure = (error) => ({
  type: USER_SUBSCRIPTION_REJECTED,
  payload: { error: error },
});

const userSubscriptionSuccess = (data) => ({
  type: USER_SUBSCRIPTION_FULFILLED,
  payload: { bio: data },
});

export const userSubscription = (payload) => {
  return (dispatch) => {
    dispatch(userSubscriptionRequest());
    return http
      .post(apiUrl.USER_SUBSCRIPTIONS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(userSubscriptionSuccess(response));
          return response;
        } else {
          dispatch(userSubscriptionFailure(response));
          showMessage({
            dispatch,
            message: 'Server Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userSubscriptionFailure(error));
        showMessage({
          dispatch,
          message: 'Server Error',
          variant: 'error',
        });
        return error;
      });
  };
};

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const setRedirectUrl = (url) => {
  return {
    type: 'SET_REDIRECT_URL',
    payload: url,
  };
};

// export const USER_REGISTER_FIRST_ADDITIONAL_FULFILLED =
//   'USER_REGISTER_FIRST_ADDITIONAL_FULFILLED';
// export const USER_REGISTER_FIRST_ADDITIONAL_PENDING =
//   'USER_REGISTER_FIRST_ADDITIONAL_PENDING';
// export const USER_REGISTER_FIRST_ADDITIONAL_REJECTED =
//   'USER_REGISTER_FIRST_ADDITIONAL_REJECTED';

// const userRegisterFirstAdditionalRequest = () => ({
//   type: USER_REGISTER_FIRST_ADDITIONAL_PENDING,
// });

// const userRegisterFirstAdditionalFailure = (error) => ({
//   type: USER_REGISTER_FIRST_ADDITIONAL_REJECTED,
//   payload: { error: error },
// });

// const userRegisterFirstAdditionalSuccess = (data) => ({
//   type: USER_REGISTER_FIRST_ADDITIONAL_FULFILLED,
//   payload: { data: data },
// });

// export const userRegisterFirstAdditional = (payload) => {
//   return (dispatch) => {
//     dispatch(userRegisterFirstAdditionalRequest());
//     return http
//       .post(apiUrl.USER_REGISTER_FIRST_ADDITIONAL, payload)
//       .then((response) => {
//         debugger;
//         if (response?.status === 1) {
//           dispatch(userRegisterFirstAdditionalSuccess(response));
//           return response;
//         } else {
//           dispatch(userRegisterFirstAdditionalFailure(response));
//           showMessage({
//             dispatch,
//             message: 'Server Error',
//             variant: 'error',
//           });
//           return response;
//         }
//       })
//       .catch((error) => {
//         dispatch(userRegisterFirstAdditionalFailure(error));
//         showMessage({
//           dispatch,
//           message: 'Server Error',
//           variant: 'error',
//         });
//         return error;
//       });
//   };
// };

export const USER_REGISTER_ADD_INDUSTRY_FULFILLED =
  'USER_REGISTER_ADD_INDUSTRY_FULFILLED';
export const USER_REGISTER_ADD_INDUSTRY_PENDING =
  'USER_REGISTER_ADD_INDUSTRY_PENDING';
export const USER_REGISTER_ADD_INDUSTRY_REJECTED =
  'USER_REGISTER_ADD_INDUSTRY_REJECTED';

const userRegisterAddIndustryRequest = () => ({
  type: USER_REGISTER_FIRST_ADDITIONAL_PENDING,
});

const userRegisterAddIndustryFailure = (error) => ({
  type: USER_REGISTER_FIRST_ADDITIONAL_REJECTED,
  payload: { error: error },
});

const userRegisterAddIndustrySuccess = (data) => ({
  type: USER_REGISTER_FIRST_ADDITIONAL_FULFILLED,
  payload: { data: data },
});

export const userRegisterAddIndustry = (payload) => {
  return (dispatch) => {
    dispatch(userRegisterAddIndustryRequest());
    return http
      .post(apiUrl.ADD_INDUSTRY, payload)
      .then((response) => {
        debugger;
        if (response?.status === 1) {
          dispatch(userRegisterAddFunctionSuccess(response));
          return response;
        } else {
          dispatch(userRegisterAddIndustrySuccess(response));
          showMessage({
            dispatch,
            message: 'Server Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userRegisterAddIndustryFailure(error));
        showMessage({
          dispatch,
          message: 'Server Error',
          variant: 'error',
        });
        return error;
      });
  };
};

export const USER_REGISTER_ADD_FUNCTION_FULFILLED =
  'USER_REGISTER_ADD_FUNCTION_FULFILLED';
export const USER_REGISTER_ADD_FUNCTION_PENDING =
  'USER_REGISTER_ADD_FUNCTION_PENDING';
export const USER_REGISTER_ADD_FUNCTION_REJECTED =
  'USER_REGISTER_ADD_FUNCTION_REJECTED';

const userRegisterAddFunctionRequest = () => ({
  type: USER_REGISTER_ADD_FUNCTION_PENDING,
});

const userRegisterAddFunctionFailure = (error) => ({
  type: USER_REGISTER_ADD_FUNCTION_REJECTED,
  payload: { error: error },
});

const userRegisterAddFunctionSuccess = (data) => ({
  type: USER_REGISTER_ADD_FUNCTION_FULFILLED,
  payload: { data: data },
});

export const userRegisterAddFunction = (payload) => {
  return (dispatch) => {
    dispatch(userRegisterAddFunctionRequest());
    return http
      .post(apiUrl.ADD_FUNCTON, payload)
      .then((response) => {
        debugger;
        if (response?.status === 1) {
          dispatch(userRegisterAddFunctionSuccess(response));
          return response;
        } else {
          dispatch(userRegisterAddFunctionFailure(response));
          showMessage({
            dispatch,
            message: 'Server Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userRegisterAddFunctionFailure(error));
        showMessage({
          dispatch,
          message: 'Server Error',
          variant: 'error',
        });
        return error;
      });
  };
};
