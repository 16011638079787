import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import EventCard from '../../components/EventCard/EventCard';
import EventCardSkeleton from '../../components/Skeleton/EventCardSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const EventHosting = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofHosting, setNoofHosting] = React.useState(2);
  const [noofHostingPage, setNoofHostingPage] = React.useState(0);

  const user = useSelector((state) => state?.user?.UserDetails);
  const Hostinglist = useSelector((state) => state?.events?.eventHostingList);
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const hostedEventLoading = useSelector(
    (state) => state?.events?.hostedEventLoading,
  );
  const upcomingEventList = useSelector(
    (state) => state?.events?.eventUpcomingList,
  );

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: noofHostingPage,
    };
    const hostedEvent = await dispatch(
      eventsActions.hostedEventUpcomming(data),
    );
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeMoreHostedEvent = async () => {
    const data = {
      user_id: user?.id,
      page_no: noofHostingPage + 1,
    };
    const hostedEvent = await dispatch(
      eventsActions.hostedEventUpcomming(data),
    );
    setNoofHostingPage(noofHostingPage + 1);
  };

  const seeMore = async () => {
    const data = {
      user_id: user?.id,
      page_no: noofHostingPage + 1,
    };
    if (data?.page_no >= 0) {
      const hostedEvent = await dispatch(
        eventsActions.hostedEventUpcomming(data),
      );
    }

    setNoofHosting(upcomingEventList?.length);
    setNoofHostingPage(noofHostingPage + 1);

    // setNoofDiscover(2);
    // if (upcomingEventList?.length > noofHosting) {
    //   setNoofHosting(noofHosting + 2);
    // } else if (upcomingEventList?.length === noofHosting) {
    //   // setNoofHosting(2);
    // }
  };

  const seeLess = () => {
    setNoofHosting(2);
  };

  if (hostedEventLoading) {
    return <EventCardSkeleton />;
  } else {
    return (
      <Fragment>
        <>
          <Box>
            <InfiniteScroll
              dataLength={
                upcomingEventList?.length ? upcomingEventList?.length : 0
              }
              next={() => seeMoreHostedEvent()}
              hasMore={true}
              loader={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}></Box>
              }
              height={
                upcomingEventList?.length > 1 ? 'calc(90vh - 100px)' : 'auto'
              }
              endMessage={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreHostedEvent}>
                  <Box>End</Box>
                </Box>
              }>
              <Grid container>
                {upcomingEventList?.length > 0 &&
                  upcomingEventList?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={12}
                        sx={{
                          marginTop: '8px',
                        }}>
                        <EventCard events={list} type="own" />
                      </Grid>
                    );
                  })}
                {upcomingEventList?.length == 0 && (
                  <Box pl={3} pt={3}>
                    <Typography variant="h5" component="h5">
                      There is no Event hosted by you till now .
                    </Typography>
                  </Box>
                )}
              </Grid>
            </InfiniteScroll>
          </Box>
        </>
        <Box>
          {/* <Box sx={{ width: '100%' }}>
            <Box>
              <Grid container>
                {upcomingEventList?.length > 0 &&
                  upcomingEventList
                    ?.slice(0)
                    .reverse()
                    ?.map((list, index) => {
                      return (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          md={12}
                          sx={{
                            marginTop: '8px',
                          }}>
                          <EventCard events={list} type="own" />
                        </Grid>
                      );
                    })}
                {upcomingEventList?.length == 0 && (
                  <Box pl={2} pt={3}>
                    <Typography variant="h5" component="h5">
                      There is no Event hosted by you till now .
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box> */}
          {/* <Box
            py={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={seeMore}>
            <Typography variant="h5" component="h5">
              {upcomingEventList?.length > 2 &&
              upcomingEventList?.length !== noofHosting
                ? 'See More'
                : ''}
            </Typography>
          </Box> */}

          {/* <Box
            pt={0}
            pb={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={seeMore}>
            <Typography variant="h5" component="h5">
              {upcomingEventList?.length > 2 &&
              upcomingEventList?.length === noofHosting
                ? 'See More'
                : ''}
            </Typography>
          </Box> */}
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(EventHosting);
