import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import EventCard from '../../components/EventCard/EventCard';
import EventCardSkeleton from '../../components/Skeleton/EventCardSkeleton';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const EventDiscover = (props) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofDiscover, setNoofDiscover] = React.useState(2);
  const [discoverPageNo, setDiscoverPageNo] = React.useState(0);

  const user = useSelector((state) => state?.user?.UserDetails);
  const discoverlist = useSelector((state) => state?.events?.eventDiscoverList);
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);

  const discoverEventLoading = useSelector(
    (state) => state?.events?.discoverEventLoading,
  );

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      categoryId: '',
      page_no: discoverPageNo,
    };
    const eventList = await dispatch(eventsActions.discoverEventList(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeLess = async () => {
    setNoofDiscover(2);
  };
  const seeMore = async () => {
    console.log('seemore');
    setNoofDiscover(discoverlist?.length);
    setDiscoverPageNo(discoverPageNo + 1);

    const data = {
      user_id: user?.id,
      categoryId: '',
      page_no: discoverPageNo + 1,
    };
    const eventList = await dispatch(eventsActions.discoverEventList(data));
    // setNoofDiscover(2);

    // if (discoverlist?.length > noofDiscover) {
    //   setNoofDiscover(discoverlist?.length);
    // } else if (discoverlist?.length === noofDiscover) {
    //   setDiscoverPageNo(discoverPageNo + 1);

    //   const data = {
    //     user_id: user?.id,
    //     categoryId: '',
    //     page_no: discoverPageNo + 1,
    //   };
    //   const eventList = await dispatch(eventsActions.discoverEventList(data));
    //   // setNoofDiscover(2);
    // }
  };

  if (discoverEventLoading) {
    return (
      <Fragment>
        <Box px={0}>
          <Box sx={{ width: '100%' }}>
            <Box py={2}>
              <EventCardSkeleton />
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box px={0}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                marginTop: '8px',
              }}>
              <Box>
                <InfiniteScroll
                  dataLength={discoverlist?.length ? discoverlist?.length : 0}
                  next={() => seeMore()}
                  hasMore={true}
                  loader={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMore}></Box>
                  }
                  height={
                    discoverlist?.length > 2 ? 'calc(90vh - 70px)' : 'auto'
                  }
                  endMessage={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMore}>
                      <Box>End</Box>
                    </Box>
                  }>
                  <Grid container>
                    {discoverlist?.length > 0 &&
                      discoverlist?.map((list, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            md={12}
                            sx={{
                              marginTop: '8px',
                            }}>
                            <EventCard events={list} type="discover" />
                          </Grid>
                        );
                      })}
                    {discoverlist?.length == 0 && (
                      <Box pl={3} pt={3}>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            '@media (max-width: 500px)': {
                              fontSize: '12px',
                            },
                          }}>
                          Currently there are no events available .
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </InfiniteScroll>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(EventDiscover);

// {discoverlist?.length > 2 && discoverlist?.length !== noofDiscover
//   ? 'See All'
//   : 'See less'}
