import {
  GET_POST_ACTIVITY_FULFILLED,
  GET_POST_ACTIVITY_PENDING,
  GET_POST_ACTIVITY_REJECTED,
  GET_BOOKMARK_POST_LIST_FULFILLED,
  GET_BOOKMARK_ARTICLE_FULFILLED,
  GET_BOOKMARK_LIST_PENDING,
  GET_BOOKMARK_LIST_REJECTED,
  GET_JOBS_ACTIVITY_FULFILLED,
  GET_JOBS_ACTIVITY_PENDING,
  GET_JOBS_ACTIVITY_REJECTED,
  GET_CLUBS_ACTIVITY_FULFILLED,
  GET_CLUBS_ACTIVITY_PENDING,
  GET_CLUBS_ACTIVITY_REJECTED,
  GET_EVENTS_ACTIVITY_FULFILLED,
  GET_EVENTS_ACTIVITY_PENDING,
  GET_EVENTS_ACTIVITY_REJECTED,
  GET_CONNECTION_REQUEST_ACTIVITY_FULFILLED,
  GET_CONNECTION_REQUEST_ACTIVITY_PENDING,
  GET_CONNECTION_REQUEST_ACTIVITY_REJECTED,
  GET_CLUB_BOOKMARK_LIST_FULFILLED,
  GET_CLUB_BOOKMARK_LIST_PENDING,
  GET_CLUB_BOOKMARK_LIST_REJECTED,
  ASSIGN_USER_ID,
} from './actions';
const defaultState = {
  SearchList: [],
  BookmarkpostList: [],
  BookmarkarticleList: [],
  BookmarknewsList: [],
  BookmarkclubList: [],
  PostList: [],
  ArticleList: [],
  SearchListCopy: [],
  loading: true,
  errors: {},
  isComment: '',
  PostEdit: false,
  PollEdit: false,
  NewsList: [],
  news_pageno: 0,
  NewsListCopy: [],
  ViewIndividsualNewsList: [],
  ViewIndividsualNewsListCopy: [],
  JobList: [],
  JobListCopy: [],
  eventList: [],
  upcomingeventList: [],
  pasteventList: [],
  ViewJobDetails: [],
  tempUserId: 0,
};

export const activityreducer = (state = defaultState, action) => {
  switch (action.type) {
    //===================================job-list======================
    case 'GET_JOB_LIST_PENDING': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          jobList: [],
          loading: true,
          errors: {},
        };
      } else {
        return {
          ...state,
          loading: true,
          errors: {},
        };
      }
    }

    case 'GET_JOB_LIST_FULFILLED': {
      console.log('GET_JOB_LIST_FULFILLED', action?.payload?.payld?.page_no)
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          jobList: action?.payload?.job,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          jobList: [
            ...state?.jobList,
            ...action?.payload?.job,
          ],
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_UPCOMING_EVENT_LIST_REJECTED': {
      return {
        ...state,
        upcomingeventList: [],
        loading: false,
        errors: {},
      };
    }
    case 'GET_UPCOMING_EVENT_LIST_FULFILLED': {
      return {
        ...state,
        upcomingeventList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case 'GET_PAST_EVENT_LIST_FULFILLED': {
      return {
        ...state,
        pasteventList: action.payload.data.data,
        loading: false,
        errors: {},
      };
    }

    case 'GET_JOB_LIST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    case 'GET_NEWS_LIST_PENDING': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          loading: true,
          NewsList: [],
          errors: {},
        };
      } else {
        return {
          ...state,
          loading: true,
          errors: {},
        };
      }
    }

    case 'GET_NEWS_LIST_FULFILLED': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          NewsList: action.payload.news.data,
          jobsearchLoading: false,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          NewsList: [...state.NewsList, ...action.payload.news.data],
          jobsearchLoading: false,
          news_pageno: action?.payload?.payld?.page_no,
          loading: false,
          errors: {},
        };
      }
    }
     case 'GET_NEWS_LIST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    //===================================post-list======================
    case GET_POST_ACTIVITY_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case GET_POST_ACTIVITY_FULFILLED: {
      return {
        ...state,
        PostList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case GET_POST_ACTIVITY_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    case GET_CLUB_BOOKMARK_LIST_PENDING: {
      return {
        ...state,
        BookmarkclubList: [],
        loading: true,
        errors: {},
      };
    }

    case GET_CLUB_BOOKMARK_LIST_FULFILLED: {
      return {
        ...state,
        BookmarkclubList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case GET_CLUB_BOOKMARK_LIST_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    //===================================bookmark-list======================
    case GET_BOOKMARK_LIST_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case GET_BOOKMARK_POST_LIST_FULFILLED: {
      return {
        ...state,
        BookmarkpostList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case GET_BOOKMARK_ARTICLE_FULFILLED: {
      return {
        ...state,
        BookmarkarticleList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case 'GET_BOOKMARK_NEWS_FULFILLED': {
      return {
        ...state,
        BookmarknewsList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case GET_BOOKMARK_LIST_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    //===================================bookmark-list======================
    case GET_JOBS_ACTIVITY_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case GET_JOBS_ACTIVITY_FULFILLED: {
      return {
        ...state,
        JobsActivitykList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case GET_JOBS_ACTIVITY_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    //===================================bookmark-list======================
    case GET_CLUBS_ACTIVITY_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case GET_CLUBS_ACTIVITY_FULFILLED: {
      return {
        ...state,
        ClubsActivityList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case GET_CLUBS_ACTIVITY_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    //===================================bookmark-list======================
    case GET_EVENTS_ACTIVITY_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case GET_EVENTS_ACTIVITY_FULFILLED: {
      return {
        ...state,
        EventsActivityList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case GET_EVENTS_ACTIVITY_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    //===================================bookmark-list======================
    case GET_CONNECTION_REQUEST_ACTIVITY_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case GET_CONNECTION_REQUEST_ACTIVITY_FULFILLED: {
      return {
        ...state,
        ConnectionRequestList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case GET_CONNECTION_REQUEST_ACTIVITY_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    case ASSIGN_USER_ID: {
      debugger;
      return {
        ...state,
        tempUserId: action.data.tempUserId,
      };
    }
    default:
      return state;
  }
};
