import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';
import * as activityActions from '../../store/activity';
import * as messageActions from '../../store/message';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const NewsCard = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const timeago = () => {
    if (props?.news?.new_created_datetime1 === 'Invalid date') {
      return '';
    } else {
      return moment(props?.news?.new_created_datetime1).fromNow();
    }
  };

  const redirectToSource = () => {
    if (props?.news?.source_url) {
      window.open(props?.news?.source_url, '_blank');
    }
  };

  const bookmarkNews = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: 'news',
        content_id: props?.news?.id,
        datetime: dateTime(),
      };
      const bookpost = await dispatch(activityActions.saveBookmark(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  return (
    <Fragment>
      <Box mb={3} key={props?.key} sx={{ cursor: 'pointer' }}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Fragment>
              <Box>
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <Card sx={{ width: '100%', position: 'relative' }}>
                    <CardMedia
                      component="img"
                      alt="News "
                      height="400"
                      sx={{ objectFit: 'contain' }}
                      image={props?.news?.image ? props?.news?.image : ''}
                    />

                    <CardHeader
                      sx={{ padding: '0px 0px' }}
                      title={
                        <Box pt={2} px={2}>
                          <Typography
                            component="h2s"
                            variant="h2s"
                            sx={{ fontSize: '18px' }}>
                            {props?.news?.heading ? props?.news?.heading : ''}
                          </Typography>
                        </Box>
                      }
                      subheader={
                        <Box>
                          <Box pt={1.4}></Box>
                        </Box>
                      }
                      action={
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}></Stack>
                      }
                    />
                    <CardContent>
                      <Box>
                        <Typography
                          variant="h2"
                          color="text.secondary"
                          sx={{
                            color: 'rgba(255, 255,255,1)',
                            whiteSpace: 'pre-line',
                            lineHeight: '21px',
                            letterSpacing: '1.2',
                          }}>
                          {props?.news?.content ? props?.news?.content : ''}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingTop: '16px',
                        }}>
                        <Stack direction="column">
                          <Box>
                            <Typography
                              component="h5s"
                              variant="h5s"
                              sx={{ fontSize: '18px' }}></Typography>
                          </Box>
                          <Box py={1}>
                            <Typography
                              component="h5"
                              variant="h5"
                              sx={{ fontSize: '14px' }}>
                              {timeago()}
                            </Typography>
                          </Box>
                          <Box onClick={redirectToSource}>
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{ fontSize: '18px' }}>
                              {props?.news?.source_name
                                ? props?.news?.source_name
                                : ''}
                            </Typography>
                          </Box>
                        </Stack>

                        <Box>
                          <IconButton
                            aria-label="Bookmark"
                            onClick={bookmarkNews}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '28px',
                                  maxWidth: 'min-content',
                                  minWidth: '18px',
                                }}>
                                <BookmarkBorderOutlinedIcon />
                              </Box>
                            </Box>
                          </IconButton>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Paper>
              </Box>
            </Fragment>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(NewsCard);
