import React, { Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Menuicon from '../../assets/menuicon';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import TextField from '@mui/material/TextField';
import CxoButton from '../CxoButton/CxoButton';
import Skeleton from '@mui/material/Skeleton';
import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CommentSkeleton(props) {
  if (props?.postid === props?.commentpostid) {
    return (
      <Card
        sx={{
          backgroundColor: '#2c2c2d',
          position: 'relative',
          backgroundImage: 'none',
          marginTop: '2px',
        }}>
        <Fragment>
          <CardHeader
            sx={{ paddingTop: '8px', paddingBottom: '8px' }}
            avatar={
              <Skeleton variant="circular" width={32} height={32}>
                <Avatar />
              </Skeleton>
            }
            action={
              <Stack
                spacing={1}
                direction="row"
                sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton aria-label="addfriend">
                  <MoreVertIcon />
                </IconButton>
              </Stack>
            }
            title={
              <Stack spacing={1} direction="row">
                <Skeleton variant="text" width={'30%'} />
              </Stack>
            }
            subheader={<Stack spacing={1} direction="row"></Stack>}
          />

          <CardContent sx={{ padding: '8px' }}>
            <Box px={2} sx={{ zIndex: '1200' }} className="editor">
              <Skeleton variant="rectangular" width={'100%'} height={48} />
            </Box>
          </CardContent>
        </Fragment>
      </Card>
    );
  } else {
    return '';
  }
}

// { props?.post?.document &&
//   <Document
// file= {props?.post?.document }
// onLoadSuccess={onDocumentLoadSuccess}
// >
// <Page pageNumber={pageNumber} />
// </Document>
//
// }
