import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import Chat from '../../assets/home/Chat_Active.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
import Menuicon from '../../assets/menuicon';
import EventsIcon from '../../assets/eventsicon';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Share from '../../assets/home/Share_White.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as jobsActions from '../../store/jobs';
import * as eventsActions from '../../store/events';
import * as activityActions from '../../store/activity';
import { useNavigate, useLocation } from 'react-router-dom';
import deleticon from '../../assets/home/Delete_White.png';
import invite from '../../assets/home/Invited_by_user_White.png';
import * as messageActions from '../../store/message';
import * as clubsActions from '../../store/clubs';
import * as companyActions from '../../store/company';
import moment from 'moment';

export default function EventCard(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [clubname, setClubName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const navigate = useNavigate();

  useEffect(async () => {
    const data = {
      user_id: props?.userlist?.id,
    };

    // const mutualfriend = await dispatch(usernetworkActions.discoverUserNetwork(data));
    // if(mutualfriend.Status === 1){
    //   setMutualFriend(mutualfriend?.data)
    // }
    // console.log('mutualfriend',user.id,props?.userlist?.id, mutualfriend);
  }, []);
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  useEffect(() => {
    async function fetchData() {
      if (props?.events?.user_type === 'club') {
        const data = {
          user_id: user?.id,
          club_id: props?.events?.user_table_pk,
        };

        const clubdetails = await dispatch(
          clubsActions.clubDetailsResponse(data),
        );
        setClubName(clubdetails?.data);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (props?.events?.user_type === 'company_page') {
        const data = {
          company_page_id: props?.events?.user_table_pk,
        };
        const candidate = await dispatch(companyActions.companyDetails(data));
        if (candidate?.status == 1) {
          setCompanyName(candidate?.data[0]);
        }
      }
    }
    fetchData();
  }, []);

  const handleMobileMenuOpen = () => {
    // setMobileMoreAnchorEl(event.currentTarget);
    setAnchorEl(!anchorEl);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const eventDetails = () => {
    if (props?.events?.event_name) {
      const eventdetails =
        props?.events?.event_name + '-' + 'lx' + props?.events?.id;
      console.log('eventDetails', props?.event?.type);
      if (props?.type !== 'SavedPassed') {
        navigate('/event/details/' + eventdetails);
      }
    } else {
      const eventdetails =
        props?.events?.title + '-' + 'lx' + props?.events?.id;
      console.log('eventDetails', props?.event?.type);
      if (props?.type !== 'SavedPassed') {
        navigate('/event/details/' + eventdetails);
      }
    }
  };

  const withdraw = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: props?.events?.id,
        user_id: user?.id,
        status: 'accept',
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestWithdraw(data),
      );
      const datainvited = {
        user_id: user?.id,
      };
      const eventListupcoming = await dispatch(
        eventsActions.savedEventUpcoming(datainvited),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const withdrawRequest = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: props?.events?.id,
        user_id: user?.id,
        status: 'request',
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestWithdraw(data),
      );

      const datainvited = {
        user_id: user?.id,
      };
      const eventListupcoming = await dispatch(
        eventsActions.savedEventUpcoming(datainvited),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const attendRequest = async () => {
    console.log('attendRequest', props?.events);
    if (user?.isVerified) {
      console.log('attendRequest', props?.events);
      if (props?.events?.event_type === 'External') {
        window.open(props?.events?.external_event_link, '_blank');
      } else {
        if (props?.events?.user_type != 'club') {
          const data = {
            event_id: props?.events?.id,
            from_user_id: user?.id,
            to_user_id: props?.events?.user_id,
            datetime: dateTime(),
          };
          const attendrequest = await dispatch(
            eventsActions.eventRequest(data),
          );
          const datalist = {
            user_id: user?.id,
            categoryId: '',
          };
          const eventListevent = await dispatch(
            eventsActions.discoverEventList(datalist),
          );

          const eventList = await dispatch(
            eventsActions.requestedEventList(datalist),
          );
        } else {
          const dataclub = {
            user_id: user?.id,
            club_id: props?.events?.user_table_pk,
          };

          const clubdet = await dispatch(clubsActions.clubDetails(dataclub));

          if (clubdet?.status === 1) {
            if (clubdet?.data?.is_member === 1) {
              const data = {
                event_id: props?.events?.id,
                from_user_id: user?.id,
                to_user_id: props?.events?.user_id,
                datetime: dateTime(),
              };
              const attendrequest = await dispatch(
                eventsActions.eventRequest(data),
              );
              const datalist = {
                user_id: user?.id,
                club_id: props?.events?.user_table_pk,
              };

              const clubdet = await dispatch(
                clubsActions.clubDetails(dataclub),
              );

              if (clubdet?.status === 1) {
                if (clubdet?.data?.is_member === 1) {
                  const data = {
                    event_id: props?.events?.id,
                    from_user_id: user?.id,
                    to_user_id: props?.events?.user_id,
                    datetime: dateTime(),
                  };
                  const attendrequest = await dispatch(
                    eventsActions.eventRequest(data),
                  );
                  const datalist = {
                    user_id: user?.id,
                    club_id: props?.events?.user_table_pk,
                  };

                  const clubdet = await dispatch(
                    clubsActions.clubDetails(dataclub),
                  );

                  if (clubdet?.is_member === 1) {
                    const data = {
                      event_id: props?.events?.id,
                      from_user_id: user?.id,
                      to_user_id: props?.events?.user_id,
                      datetime: dateTime(),
                    };
                    const attendrequest = await dispatch(
                      eventsActions.eventRequest(data),
                    );
                    const datalist = {
                      user_id: user?.id,
                      categoryId: '',
                    };
                    const eventListevent = await dispatch(
                      eventsActions.discoverEventList(datalist),
                    );

                    const eventList = await dispatch(
                      eventsActions.requestedEventList(datalist),
                    );
                  } else {
                    const msg = 'Its a club event you need to join club first';

                    const sendmessage = await dispatch(
                      messageActions.messagefromComponent(msg, 'error'),
                    );
                  }
                } else {
                  const msg =
                    'This is a Club centric Event. Please join the club to attend this event.';
                  const sendmessage = await dispatch(
                    messageActions.messagefromComponent(msg, 'error'),
                  );
                }
              }
            } else {
              const msg =
                'This is a Club centric Event. Please join the club to attend this event.';
              const sendmessage = await dispatch(
                messageActions.messagefromComponent(msg, 'error'),
              );
            }
          }
        }
      }

      // const data = {
      //   event_id: props?.events?.id,
      //   from_user_id: user?.id,
      //   to_user_id: props?.events?.user_id,
      //   datetime: dateTime(),
      // };
      // const attendrequest = await dispatch(eventsActions.eventRequest(data));
      // const datalist = {
      //   user_id: user?.id,
      //   categoryId: [],
      // };
      // const eventListevent = await dispatch(
      //   eventsActions.discoverEventList(datalist)
      // );
      //
      // const eventList = await dispatch(
      //   eventsActions.requestedEventList(datalist)
      // );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const rejectInvitation = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: props?.events?.id,
        from_user_id: props?.events?.user_id,
        to_user_id: user?.id,
        datetime: dateTime(),
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestReject(data),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
      const datainvited = {
        user_id: user?.id,
      };
      const eventListhi = await dispatch(
        eventsActions.invitedEventList(datainvited),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const acceptInvitation = async () => {
    if (user?.isVerified) {
      if (props?.events?.event_type === 'External') {
        window.open(props?.events?.external_event_link, '_blank');
      } else {
        const data = {
          event_id: props?.events?.id,
          from_user_id: props?.events?.user_id,
          to_user_id: user?.id,
          datetime: dateTime(),
        };

        // const data = {
        //   event_id : props?.events?.id,
        //   from_user_id: user?.id,
        //   to_user_id: props?.events?.user_id,
        //   datetime: dateTime()
        // }
        // const attendrequest = await dispatch(eventsActions.eventRequest(data));

        const acceptinvitation = await dispatch(
          eventsActions.eventRequestAccept(data),
        );
        const datalist = {
          user_id: user?.id,
          categoryId: [],
        };
        const eventListevent = await dispatch(
          eventsActions.discoverEventList(datalist),
        );
        const eventList = await dispatch(
          eventsActions.requestedEventList(datalist),
        );
        const datainvited = {
          user_id: user?.id,
        };
        const eventListhi = await dispatch(
          eventsActions.invitedEventList(datainvited),
        );

        const eventListddd = await dispatch(
          eventsActions.savedEventUpcoming(datalist),
        );
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const inviteUser = async () => {
    if (user?.isVerified) {
      const sendInvitees = await dispatch(
        eventsActions.openSendInviteesEvent(props?.events),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const editEvent = async () => {
    if (user?.isVerified) {
      const data = {
        id: props?.events?.id,
      };
      const eventdetails = await dispatch(eventsActions.eventDetails(data));
      const eventEdit = await dispatch(
        eventsActions.editEventOpen(eventdetails?.data),
      );

      if (props?.events?.user_type === 'club') {
        navigate('/club/event/edit');
      } else {
        navigate('/event/edit');
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const bookmarkEvent = async () => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: 'event',
        content_id: props?.events?.id,
        datetime: dateTime(),
      };
      const bookpost = await dispatch(activityActions.saveBookmark(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const shareEvent = async (e) => {
    e.stopPropagation();

    if (user?.isVerified) {
      const opensharejob = await dispatch(
        eventsActions.openShareEvent(props?.events),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const convertLinks = (input) => {
    let text = input;
    const linksFound = text.match(/(?:www|https?)[^\s]+/g);
    const aLink = [];

    if (linksFound != null) {
      for (let i = 0; i < linksFound.length; i++) {
        let replace = linksFound[i];
        if (!linksFound[i].match(/(http(s?)):\/\//)) {
          replace = 'http://' + linksFound[i];
        }
        let linkText = replace.split('/')[2];
        if (linkText.substring(0, 3) == 'www') {
          linkText = linkText.replace('www.', '');
        }
        if (linkText.match(/youtu/)) {
          let youtubeID = replace.split('/').slice(-1)[0];
          aLink.push(
            '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' +
              youtubeID +
              '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
          );
        } else if (linkText.match(/vimeo/)) {
          let vimeoID = replace.split('/').slice(-1)[0];
          aLink.push(
            '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
              vimeoID +
              '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
          );
        } else {
          aLink.push(
            '<a href="' + replace + '" target="_blank">' + linkText + '</a>',
          );
        }
        text = text
          .split(linksFound[i])
          .map((item) => {
            return aLink[i].includes('iframe') ? item.trim() : item;
          })
          .join(aLink[i]);
      }
      return text;
    } else {
      return input;
    }
  };

  const getEventName = () => {
    if (props?.events?.event_name) {
      if (props?.events?.event_name?.length > 50) {
        return props?.events?.event_name?.substring(0, 50) + '...';
      } else {
        return props?.events?.event_name;
      }
    } else if (props?.events?.title) {
      if (props?.events?.title?.length > 50) {
        return props?.events?.title?.substring(0, 50) + '...';
      } else {
        return props?.events?.title;
      }
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        backgroundColor: '#262525',
        backgroundImage: 'none',
        // minHeight: "250px",
        width: '100%',
      }}>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', height: 'auto' }}>
          <Stack direction="row">
            {props?.events?.image_url ? (
              <CardMedia
                component="img"
                // sx={{
                //   width: 260,
                //   minWidth: '250px',
                //   maxHeight: '250px',
                //   borderRadius: '5px',
                //   backgroundColor: '#262525',
                //   // margin: '8px 0px 8px 8px',
                //   cursor: 'pointer',
                //   objectFit: 'contain',
                //   '@media (max-width: 500px)': {
                //     width: 170,
                //     minWidth: '170px',
                //     maxHeight: 'auto',
                //     margin: '0px',
                //   },
                // }}
                sx={{
                  width: 360,
                  minWidth: '350px',
                  maxHeight: '200px',
                  borderRadius: '5px',
                  backgroundColor: '#262525',
                  // margin: '8px 0px 8px 8px',
                  cursor: 'pointer',
                  objectFit: 'contain',
                  '@media (max-width: 500px)': {
                    width: 180,
                    minWidth: '180px',
                    maxHeight: 'auto',
                    margin: '0px',
                  },
                }}
                image={props?.events?.image_url ? props?.events?.image_url : ''}
                onClick={eventDetails}
              />
            ) : (
              <Box
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  width: '216px',
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  borderRadius: '16px',
                  padding: '8px',
                  objectFit: 'contain',
                  backgroundColor: '#222121',
                  // margin: '8px 0px 8px 8px',
                  cursor: 'pointer',
                  '@media (max-width: 500px)': { padding: '0px' },
                }}
                onClick={eventDetails}
                alt="">
                <Avatar
                  sx={{
                    width: 72,
                    height: 72,
                  }}>
                  {props?.candidatelist?.first_name[0] +
                    props?.candidatelist?.last_name[0]}
                </Avatar>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '8px',
              }}>
              <CardHeader
                sx={{ padding: '0px 8px' }}
                title={
                  <Box pt={1}>
                    <Typography
                      component="h2s"
                      variant="h2s"
                      sx={{
                        fontSize: '20px',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                          lineHeight: '17px',
                        },
                      }}>
                      {getEventName()}
                    </Typography>
                  </Box>
                }
                subheader={
                  <Box>
                    <Box pt={0.5}>
                      <Stack spacing={0.4}></Stack>
                    </Box>
                  </Box>
                }
                action={<Box mt={1} pr={1}></Box>}
              />

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="flex-start"
                sx={{ paddingTop: '16px' }}>
                <Box
                  pl={1}
                  pt={0}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: { xs: '100%', md: '40%' },
                  }}>
                  <Box
                    pt={0}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItem: 'center',
                      justifyContent: 'flex-start',
                    }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                            '@media (max-width: 500px)': {
                              minWidth: '14px',
                              height: '14px',
                            },
                          }}>
                          <Menuicon name="location" color="#ffffff" />
                        </Box>
                      </Box>

                      <Box py={0.5} px={1}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontSize: '14px',
                            '@media (max-width: 500px)': {
                              fontSize: '12px',
                              marginTop: '-5px',
                            },
                          }}>
                          {props?.events?.venue &&
                          props?.events?.mode !== 'Online'
                            ? props?.events?.venue?.substring(0, 20) + '...'
                            : 'Online'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>

                <Box
                  pl={{ md: 0, xs: 1 }}
                  pt={{ md: 0.5, xs: 1 }}
                  sx={{
                    display: 'flex',
                    width: { xs: '100%', md: '40%' },
                    justifyContent: 'flex-start',
                  }}>
                  <Stack direction="row">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '16px',
                          maxWidth: 'min-content',
                          minWidth: '16px',
                          '@media (max-width: 500px)': {
                            minWidth: '12px',
                            height: '12px',
                            marginTop: '-3px',
                          },
                        }}>
                        <EventsIcon name="event_name" color="#ffffff" />
                      </Box>
                    </Box>
                    <Box py={0.5} px={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                          fontSize: '14px',
                          '@media (max-width: 500px)': {
                            fontSize: '12px',
                            marginTop: '-8px',
                          },
                        }}>
                        {props?.events?.type ? props?.events?.type : ''}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Box
                  pl={0}
                  pt={0.5}
                  sx={{
                    display: 'flex',
                    width: '20%',
                    justifyContent: 'flex-start',
                  }}>
                  <Stack direction="row">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '16px',
                          maxWidth: 'min-content',
                          minWidth: '16px',
                        }}></Box>
                    </Box>
                    <Box py={0.5} px={1}></Box>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="flex-start"
                sx={{ paddingTop: '0px' }}>
                <Box
                  pl={1}
                  pt={0}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: { xs: '100%', md: '46%' },
                  }}>
                  <Box
                    pt={0.5}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItem: 'center',
                      justifyContent: 'flex-start',
                    }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                            '@media (max-width: 500px)': {
                              minWidth: '14px',
                              height: '14px',
                              marginTop: '-20px',
                            },
                          }}>
                          <Menuicon name="calendar" color="#ffffff" />
                        </Box>
                      </Box>

                      <Box py={0.5} px={1}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontSize: '14px',
                            '@media (max-width: 500px)': {
                              marginTop: '-25px',
                            },
                          }}>
                          {props?.events?.start_date
                            ? moment(props?.events?.start_date).format('ll')
                            : ''}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>

                <Box
                  pl={{ md: 0, xs: 0.5 }}
                  pt={0.5}
                  sx={{
                    display: 'flex',
                    width: { xs: '100%', md: '40%' },
                    justifyContent: 'flex-start',
                  }}>
                  <Stack direction="row">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '18px',
                          maxWidth: 'min-content',
                          minWidth: '18px',
                          '@media (max-width: 500px)': {
                            minWidth: '10px',
                            height: '10px',
                            marginTop: '-8px',
                          },
                        }}>
                        <EventsIcon name="event_fee" color="#ffffff" />
                      </Box>
                    </Box>
                    <Box py={{ md: 0.5, xs: 0 }} px={{ md: 1, xs: 0.5 }}>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                          fontSize: '14px',
                          '@media (max-width: 500px)': {
                            marginTop: '-10px',
                          },
                        }}>
                        {props?.events?.fee === 0 ? 'Free' : 'Paid'}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Box
                  pl={0}
                  pt={{ md: 0.5, xs: 0 }}
                  sx={{
                    display: 'flex',
                    width: { xs: '100%', md: '30%' },
                    justifyContent: 'flex-start',
                  }}>
                  <Stack direction="row">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '18px',
                          maxWidth: 'min-content',
                          minWidth: '18px',
                        }}></Box>
                    </Box>

                    <Box
                      pt={{ md: 0.5, xs: 0 }}
                      px={1}
                      sx={{
                        display: { xs: 'none', md: 'block' },
                      }}></Box>
                  </Stack>
                </Box>
              </Stack>

              {props?.events?.event_type === 'External' &&
              props?.type !== 'own' ? (
                <Stack direction="row" sx={{ paddingTop: '4px' }}>
                  <Box
                    pl={1}
                    pt={{ md: 1, xs: 0 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '18px',
                        maxWidth: 'min-content',
                        minWidth: '18px',
                        '@media (max-width: 500px)': {
                          minWidth: '12px',
                          height: '12px',
                          marginTop: '-6px',
                        },
                      }}>
                      {props?.type !== 'past' && (
                        <EventsIcon name="events_partnered" color="#ffffff" />
                      )}
                    </Box>
                  </Box>

                  <Box
                    py={{ md: 0.5, xs: 0 }}
                    pt={{ md: 1.8, xs: 0 }}
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ fontSize: '14px' }}>
                      Partnered Event
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                ''
              )}

              {props?.events?.event_type === 'Indivisual' &&
              props?.type !== 'own' ? (
                <Stack direction="row" sx={{ paddingTop: '4px' }}>
                  <Box
                    pl={1}
                    pt={{ md: 1, xs: 0 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '18px',
                        maxWidth: 'min-content',
                        minWidth: '18px',
                        '@media (max-width: 500px)': {
                          minWidth: '16px',
                          height: '16px',
                          marginTop: '-50px',
                        },
                      }}>
                      {props?.type !== 'past' && (
                        <EventsIcon name="events_suggestedby" color="#ffffff" />
                      )}
                    </Box>
                  </Box>

                  <Box
                    py={{ md: 0.5, xs: 0 }}
                    pt={{ md: 1.8, xs: 0 }}
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ fontSize: '14px' }}>
                      {props?.events?.first_name} {props?.events?.last_name}
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                ''
              )}

              {props?.events?.user_type === 'company_page' && (
                <Stack
                  direction="row"
                  sx={{
                    paddingTop: '8px',
                    '@media (max-width: 500px)': { paddingTop: '0px' },
                  }}>
                  <Box
                    pl={1}
                    pt={{ md: 2, xs: 0 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '18px',
                        maxWidth: 'min-content',
                        minWidth: '18px',
                        '@media (max-width: 500px)': {
                          minWidth: '16px',
                          height: '16px',
                        },
                      }}>
                      <Menuicon name="clubs" color="#38CD84" />
                    </Box>
                  </Box>

                  <Box
                    py={0.5}
                    pt={{ md: 2.2, xs: 0 }}
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      variant="h6s"
                      component="h6s"
                      sx={{
                        fontSize: '16px',
                        '@media (max-width: 500px)': {
                          minWidth: '16px',
                          height: '16px',
                          fontSize: '14px',
                        },
                      }}>
                      {companyName?.page_name?.substring(0, 30)}
                    </Typography>
                  </Box>
                </Stack>
              )}

              {props?.events?.user_type === 'club' && (
                <Stack
                  direction="row"
                  sx={{
                    paddingTop: '8px',
                    '@media (max-width: 500px)': { paddingTop: '0px' },
                  }}>
                  <Box
                    pl={1}
                    pt={{ md: 2, xs: 0 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '18px',
                        maxWidth: 'min-content',
                        minWidth: '18px',
                        '@media (max-width: 500px)': {
                          minWidth: '16px',
                          height: '16px',
                        },
                      }}>
                      <Menuicon name="clubs" color="#38CD84" />
                    </Box>
                  </Box>

                  <Box
                    py={0.5}
                    pt={{ md: 2.2, xs: 0 }}
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      variant="h6s"
                      component="h6s"
                      sx={{
                        fontSize: '16px',
                        '@media (max-width: 500px)': {
                          minWidth: '16px',
                          height: '16px',
                          fontSize: '14px',
                        },
                      }}>
                      {clubname?.title?.substring(0, 30)}
                    </Typography>
                  </Box>
                </Stack>
              )}

              {/*      // <Stack direction="row" sx={{ paddingTop: "8px" }}>
              //   <Box
              //     pl={1}
              //     pt={2}
              //     sx={{
              //       display: "flex",
              //       justifyContent: "center",
              //       alignItem: "center",
              //     }}
              //   >
              //     <Box
              //       sx={{
              //         display: "flex",
              //         justifyContent: "center",
              //         height: "18px",
              //         maxWidth: "min-content",
              //         minWidth: "18px",
              //       }}
              //     >
              //       <EventsIcon name="category" color="#38CD84" />
              //     </Box>
              //   </Box>
              //
              //   <Box
              //     py={0.5}
              //     pt={2.2}
              //     px={1}
              //     sx={{
              //       display: "flex",
              //       alignItems: "center",
              //       justifyContent: "center",
              //     }}
              //   >
              //     <Typography variant="h6s" component="h6s">
              //       {props?.events?.categories[0]?.title}
              //       {props?.events?.categories?.length > 1
              //         ? " + " + (props?.events?.categories?.length - 1)
              //         : ""}
              //     </Typography>
              //   </Box>
              // </Stack>
              */}

              <Stack
                direction="row"
                sx={{
                  paddingTop: '8px',
                  display: { xs: 'none', md: 'block' },
                }}>
                <Box py={1.5} px={1}>
                  <Typography
                    variant="h2"
                    color="text.secondary"
                    sx={{
                       fontSize: '16px',
                      color: 'rgba(255, 255,255,1)',
                      whiteSpace: 'pre-line',
                      lineHeight: '21px',
                      letterSpacing: '1.2',
                    }}>
                    {props?.events?.details?.length > 165
                      ? props?.events?.details?.substring(0, 165) + ' ...'
                      : props?.events?.details}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <Box p={1}>
          {props?.type === 'own' && (
            <Grid container>
              {props?.events?.status === 'Active' && (
                <Fragment>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={editEvent}>
                      <IconButton aria-label="share">
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <Menuicon
                              width="43"
                              color="#ffffff"
                              name="editapp"
                            />
                          </Box>
                        </Box>
                      </IconButton>
                      <Typography variant="h6" component="h6">
                        Edit
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={inviteUser}>
                      <IconButton
                        aria-label="applause"
                        sx={{
                          width: '40px',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '20px',
                            width: 'fit-content',
                          }}>
                          <img
                            alt="Comment"
                            className={{ height: '20px' }}
                            src={invite}
                          />
                        </Box>
                      </IconButton>
                      <Typography variant="h6" component="h6">
                        Invite
                      </Typography>
                    </Stack>
                  </Grid>
                </Fragment>
              )}

              {props?.events?.status === 'Cancelled' && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box py={1}>
                    <Typography
                      component="h2s"
                      variant="h2s"
                      sx={{ fontSize: '18px', fontWeight: '600' }}>
                      This event is cancelled
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}

          {props?.type === 'discover' && (
            <Grid container>
              {props?.events?.status === 'Active' && (
                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={attendRequest}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <EventsIcon name="event_attending" color="#ffffff" />
                      </Box>
                    </IconButton>
                    <Typography variant="h6" component="h6">
                      Attend
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid
                item
                xs={props?.events?.status === 'Active' ? 4 : 6}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={bookmarkEvent}>
                  <IconButton aria-label="Bookmark">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '18px',
                          maxWidth: 'min-content',
                          minWidth: '18px',
                        }}>
                        <BookmarkBorderOutlinedIcon />
                      </Box>
                    </Box>
                  </IconButton>
                  <Typography variant="h6" component="h6">
                    Bookmark
                  </Typography>
                </Stack>
              </Grid>

              <Grid
                item
                xs={props?.events?.status === 'Active' ? 4 : 6}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={shareEvent}>
                  <IconButton
                    aria-label="applause"
                    sx={{
                      width: '40px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '20px',
                        width: 'fit-content',
                      }}>
                      <img
                        alt="Share"
                        className={{ height: '20px' }}
                        src={Share}
                      />
                    </Box>
                  </IconButton>
                  <Typography variant="h6" component="h6">
                    Share
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          )}

          {props?.type === 'Invited' &&
            props?.events?.event_type === 'External' && (
              <Grid container>
                {props?.events?.status === 'Active' && (
                  <Fragment>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={acceptInvitation}>
                        <IconButton
                          aria-label="applause"
                          sx={{
                            width: '40px',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '20px',
                              width: 'fit-content',
                            }}>
                            <DoneOutlinedIcon />
                          </Box>
                        </IconButton>
                        <Typography variant="h6" component="h6">
                          Attend
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={rejectInvitation}>
                        <IconButton aria-label="share">
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '18px',
                                maxWidth: 'min-content',
                                minWidth: '18px',
                              }}>
                              <CloseOutlinedIcon />
                            </Box>
                          </Box>
                        </IconButton>
                        <Typography variant="h6" component="h6">
                          Ignore
                        </Typography>
                      </Stack>
                    </Grid>
                  </Fragment>
                )}
                <Grid
                  item
                  xs={props?.events?.status !== 'Active' ? 12 : 4}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={shareEvent}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <img
                          alt="Share"
                          className={{ height: '20px' }}
                          src={Share}
                        />
                      </Box>
                    </IconButton>
                    <Typography variant="h6" component="h6">
                      Share
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}

          {props?.type === 'Invited' &&
            props?.events?.event_type != 'External' && (
              <Grid container>
                {props?.events?.status === 'Active' && (
                  <Fragment>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={acceptInvitation}>
                        <IconButton
                          aria-label="applause"
                          sx={{
                            width: '40px',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '20px',
                              width: 'fit-content',
                            }}>
                            <DoneOutlinedIcon />
                          </Box>
                        </IconButton>
                        <Typography variant="h6" component="h6">
                          Accept
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={rejectInvitation}>
                        <IconButton aria-label="share">
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '18px',
                                maxWidth: 'min-content',
                                minWidth: '18px',
                              }}>
                              <CloseOutlinedIcon />
                            </Box>
                          </Box>
                        </IconButton>
                        <Typography variant="h6" component="h6">
                          Ignore
                        </Typography>
                      </Stack>
                    </Grid>
                  </Fragment>
                )}
                <Grid
                  item
                  xs={props?.events?.status !== 'Active' ? 12 : 4}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={shareEvent}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <img
                          alt="Share"
                          className={{ height: '20px' }}
                          src={Share}
                        />
                      </Box>
                    </IconButton>
                    <Typography variant="h6" component="h6">
                      Share
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}

          {props?.type === 'Requested' && (
            <Grid container>
              {props?.events?.status === 'Active' && (
                <Grid
                  item
                  xs={6}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={withdrawRequest}>
                    <IconButton aria-label="share">
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                          }}>
                          <CloseOutlinedIcon />
                        </Box>
                      </Box>
                    </IconButton>
                    <Typography variant="h6" component="h6">
                      Cancel Request
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid
                item
                xs={props?.events?.status !== 'Active' ? 12 : 6}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={shareEvent}>
                  <IconButton
                    aria-label="applause"
                    sx={{
                      width: '40px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '20px',
                        width: 'fit-content',
                      }}>
                      <img
                        alt="Share"
                        className={{ height: '20px' }}
                        src={Share}
                      />
                    </Box>
                  </IconButton>
                  <Typography variant="h6" component="h6">
                    Share
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          )}

          {props?.type === 'SavedUpcoming' && (
            <Grid container>
              {props?.events?.status === 'Active' && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={withdraw}>
                    <IconButton aria-label="share">
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                          }}>
                          <CloseOutlinedIcon />
                        </Box>
                      </Box>
                    </IconButton>
                    <Typography variant="h6" component="h6">
                      Withdraw
                    </Typography>
                  </Stack>
                </Grid>
              )}
              {props?.events?.status === 'Cancelled' && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box py={1}>
                    <Typography
                      component="h2s"
                      variant="h2s"
                      sx={{ fontSize: '18px', fontWeight: '600' }}>
                      This event is cancelled
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Stack>
    </Card>
  );
}
