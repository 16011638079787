import React, { useState, useRef } from 'react';
import {
  Box,
  FormControl,
  TextField,
  Button,
  Select,
  Typography,
  InputLabel,
  Stack,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Navbar from '../HomeHeader/navBar/navBar';
import Footer from '../HomeHeader/footer';
import CxoInputCss from '../CxoInputCss/CxoInputCss';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import * as postActions from '../../store/post';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import * as surveyActions from '../../store/survey';
import config from '../../services/apiConfig';
import { dateTime } from '../../constants/app';
import * as messageActions from '../../store/message';


const selected = ['Text Input', 'Multiple Choice', 'Select'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateQueryForm = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [surveyend, setSurveyend] = useState(1);
  const [sharewith, setShareWith] = React.useState('Everyone');
  const scrollToContainer = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };
  const redirectEdit = () => {
    navigate('/survey/edit');
  };
  const [formElements, setFormElements] = useState([
    { type: 'text', choices: [''], inputType: 'Text Input' },
  ]);

  const [title, setTitle] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const [categories, setCategories] = useState([
    {
      formCategoryId: 1,
      formCategoryName: 'Tech',
    },
    {
      formCategoryId: 2,
      formCategoryName: 'Operations',
    },
  ]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const openSurvey = useSelector((state) => state?.survey?.SurveyEdit);
  const openQuery = useSelector((state) => state?.survey?.openQuery);
  const user = useSelector((state) => state?.user?.UserDetails);
  const handleCategory = (event) => {
    setSelectedCategories(event.target.value);
  };

  React.useEffect(() => {
    axios
      .post(config?.serverBaseUrl + '/forms/get_form_categories')
      .then((response) => {
        const data = response.data;
        if (data.status === 1) {
          setCategories(data.data);
        } else {
          console.error('Error fetching categories:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const [formName, setFormName] = useState('');
  const [descriptions, setDescriptions] = useState({});
  const handleInputChange = (event, index) => {
    const updatedFormElements = [...formElements];
    updatedFormElements[index].inputType = event.target.value;
    setFormElements(updatedFormElements);
  };
  const handleformName = (e) => {
    setFormName(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setFormDescription(e.target.value);
  };
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // New state
  const handleAddTextField = () => {
    if (formElements?.length <= 1) {
      setFormElements([
        { type: 'text', choices: [''], inputType: 'Text Input' },
      ]);

      scrollToContainer();
    }
  };
  const handleAddSelect = () => {
    if (formElements?.length <= 1) {
      setFormElements([
        { type: 'checkbox', choices: [''], inputType: 'Multiple Choice' },
      ]);
      scrollToContainer();
    }
  };
  const handleAddRadioGroup = () => {
    if (formElements?.length <= 1) {
      setFormElements([
        { type: 'radio', choices: ['', ''], inputType: 'Select' },
      ]);
      scrollToContainer();
    }
  };
  const handleRemoveElement = (index) => {
    const updatedFormElements = [...formElements];
    updatedFormElements.splice(index, 1);
    setFormElements(updatedFormElements);
  };
  const handleQuestionChange = (e, index) => {
    const updatedFormElements = [...formElements];
    updatedFormElements[index].question = e.target.value;
    setFormElements(updatedFormElements);
  };
  const handleChoiceChange = (e, formIndex, choiceIndex) => {
    const updatedFormElements = [...formElements];
    updatedFormElements[formIndex].choices[choiceIndex] = e.target.value;
    setFormElements(updatedFormElements);
  };
  const addChoice = (formIndex) => {
    const updatedFormElements = [...formElements];
    if (updatedFormElements[formIndex]?.choices?.length < 5) {
      updatedFormElements[formIndex].choices.push('');
      setFormElements(updatedFormElements);
    }
  };
  const removeChoice = (formIndex, choiceIndex) => {
    const updatedFormElements = [...formElements];
    if (updatedFormElements[formIndex]?.choices?.length > 1) {
      updatedFormElements[formIndex].choices.splice(choiceIndex, 1);
      setFormElements(updatedFormElements);
    }
  };

  const handleSurveyend = (event) => {
    setSurveyend(event.target.value);
  };

  const handleShareWith = (event) => {
    setShareWith(event.target.value);
  };

  const renderFormElements = () => {
    return formElements?.map((element, index) => (
      <Box
        key={index}
        sx={{
          backgroundColor: '#333333',
          width: '100%',
          maxWidth: '800px',
          margin: '0px auto',
          paddingBottom: '24px',
          paddingTop: '8px',
          // padding: '20px',
          // border: '1px solid gray',
          borderRadius: '5px',
          boxSizing: 'border-box',
          '@media (max-width: 600px)': {
            maxWidth: '100%',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
          }}>
          <Box></Box>
          <Box></Box>
        </Box>

        {element?.inputType === 'Text Input' && (
          <Box
            mt={1}
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ width: '800px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    color: '#38CD84',
                    marginRight: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                  }}></div>
                <TextField
                  sx={{
                    '& label.Mui-focused': {
                      fontSize: '12px',
                      color: (theme) => theme.palette.palette.text.secondary,
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '12px',
                      color: (theme) => theme.palette.palette.text.secondary,
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: (theme) =>
                        theme.palette.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: (theme) =>
                          theme.palette.palette.text.secondary,
                      },
                      '&:hover fieldset': {
                        borderColor: (theme) =>
                          theme.palette.palette.text.secondary,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: (theme) =>
                          theme.palette.palette.text.secondary,
                      },
                    },
                  }}
                  label="Question *"
                  variant="outlined"
                  fullWidth
                  multiline
                  maxRows={5}
                  value={element?.question}
                  onChange={(e) => handleQuestionChange(e, index)}
                />
              </div>
            </FormControl>
          </Box>
        )}
        {element?.inputType === 'Multiple Choice' && (
          <Box
            mt={1}
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ width: '800px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    color: '#38CD84',
                    marginRight: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                  }}>
                  {index + 1}.
                </div>
                <CxoInputCss
                  sx={{
                    '& label.Mui-focused': {
                      fontSize: '12px',
                      color: (theme) => theme.palette.palette.text.secondary,
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '12px',
                      color: (theme) => theme.palette.palette.text.secondary,
                    },
                  }}
                  label="Question"
                  variant="outlined"
                  fullWidth
                  value={element?.question}
                  onChange={(e) => handleQuestionChange(e, index)}
                />
              </div>{' '}
              {element?.choices?.map((choice, choiceIndex) => (
                <div
                  key={choiceIndex}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '-20px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        width: '16px',
                        height: '16px',
                        border: '1px solid #38CD84',
                        marginRight: '8px',
                        pointerEvents: 'none',
                        marginTop: '40px',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                        marginLeft: '24px',
                      }}>
                      <FormControl sx={{ width: '700px' }}>
                        {' '}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            sx={{
                              '& label.Mui-focused': {
                                fontSize: '12px',
                                color: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                              '& .MuiInputLabel-root': {
                                fontSize: '12px',
                                color: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: (theme) =>
                                    theme.palette.palette.text.secondary,
                                },
                                '&:hover fieldset': {
                                  borderColor: (theme) =>
                                    theme.palette.palette.text.secondary,
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: (theme) =>
                                    theme.palette.palette.text.secondary,
                                },
                              },
                            }}
                            variant="standard"
                            fullWidth
                            value={choice}
                            onChange={(e) =>
                              handleChoiceChange(e, index, choiceIndex)
                            }
                            label={`Option ${choiceIndex + 1}`}
                          />
                        </div>{' '}
                      </FormControl>
                    </div>
                    {element?.choices?.length > 1 && (
                      <ClearIcon
                        onClick={() => removeChoice(index, choiceIndex)}
                        style={{
                          cursor: 'pointer',
                          color: '#38CD84',
                          width: '20px',
                          height: '20px',
                          marginTop: '20px',
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  marginTop: '8px',
                }}
                ml={6}>
                <Button
                  sx={{
                    borderRadius: '50px',
                    fontFamily: 'Raleway',
                    lineHeight: '18px',
                    fontWeight: 800,
                    fontSize: '12px',
                    textTransform: 'none',
                    padding: '4px 10px',
                    color: '#000',
                    backgroundColor: (theme) =>
                      theme.palette.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        theme.palette.palette.text.secondary,
                    },
                  }}
                  onClick={() => addChoice(index)}
                  disabled={element?.choices?.length >= 5}>
                  {' '}
                  Add Option
                </Button>
              </Box>
            </FormControl>
          </Box>
        )}
        {element?.inputType === 'Select' && (
          <Box
            mt={1}
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ width: '800px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    color: '#38CD84',
                    marginRight: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                  }}>
                  {index + 1}.
                </div>
                <CxoInputCss
                  label="Question"
                  variant="outlined"
                  fullWidth
                  value={element?.question}
                  onChange={(e) => handleQuestionChange(e, index)}
                />{' '}
              </div>

              {element?.choices?.map((choice, choiceIndex) => (
                <div
                  key={choiceIndex}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '-20px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        borderRadius: '50%',
                        width: '16px',
                        height: '16px',
                        border: '1px solid #38CD84',
                        marginRight: '8px',
                        pointerEvents: 'none',
                        marginTop: '40px',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                        marginLeft: '24px',
                      }}>
                      <FormControl sx={{ width: '700px' }}>
                        <TextField
                          sx={{
                            '& label.Mui-focused': {
                              fontSize: '12px',
                              color: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '& .MuiInputLabel-root': {
                              fontSize: '12px',
                              color: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: (theme) =>
                                theme.palette.palette.text.secondary,
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                              '&:hover fieldset': {
                                borderColor: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                  theme.palette.palette.text.secondary,
                              },
                            },
                          }}
                          variant="standard"
                          fullWidth
                          value={choice}
                          onChange={(e) =>
                            handleChoiceChange(e, index, choiceIndex)
                          }
                          label={`Option ${choiceIndex + 1}`}
                        />
                      </FormControl>
                    </div>
                    {element?.choices?.length > 1 && (
                      <ClearIcon
                        onClick={() => removeChoice(index, choiceIndex)}
                        style={{
                          cursor: 'pointer',
                          color: '#38CD84',
                          width: '20px',
                          height: '20px',
                          marginTop: '45px',
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  marginTop: '20px',
                }}
                ml={3}>
                <Button
                  sx={{
                    borderRadius: '50px',
                    fontFamily: 'Raleway',
                    lineHeight: '18px',
                    fontWeight: '800',
                    fontSize: '12px',
                    textTransform: 'none',
                    padding: '4px 10px',
                    color: '#000',
                    backgroundColor: (theme) =>
                      theme.palette.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        theme.palette.palette.text.secondary,
                    },
                  }}
                  onClick={() => addChoice(index)}
                  disabled={element?.choices?.length >= 5}>
                  {' '}
                  Add Option
                </Button>
              </Box>
            </FormControl>
          </Box>
        )}
      </Box>
    ));
  };

  const closeSurvey = async () => {
    const snackbar = await dispatch(surveyActions.closeQuery());
    return postList();
  };

  const postList = async () => {
    const formData = new FormData();
    formData.append('user_id', user?.id);
    formData.append('start_item', 0);
    formData.append('item_count', 30);
    formData.append('datetime', dateTime());
    const data = {
      user_id: user?.id,
      start_item: 0,
      item_count: 30,
      datetime: dateTime(),
    };
    const postlist = await dispatch(postActions.getPostList(formData, data));
  };

  const handleSubmit = async (e) => {
    e.stopPropagation();
    const formQuestions = formElements?.map((element, index) => {
      if (getQuestionType(element?.inputType) === 'text') {
        if (element?.question?.length > 0) {
          const questionData = {
            question: element?.question,
            questionType: getQuestionType(element?.inputType),
            description: descriptions[index] || '',
            questionOptions: element?.choices || [],
          };
          return questionData;
        }
      } else if (getQuestionType(element?.inputType) === 'checkbox') {
        if (element?.question?.length > 0 && !element?.choices?.includes('')) {
          const questionData = {
            question: element?.question,
            questionType: getQuestionType(element?.inputType),
            description: descriptions[index] || '',
            questionOptions: element?.choices || [],
          };
          return questionData;
        }
      } else if (getQuestionType(element?.inputType) === 'radio') {
        if (element?.question?.length > 0 && !element?.choices?.includes('')) {
          const questionData = {
            question: element?.question,
            questionType: getQuestionType(element?.inputType),
            description: descriptions[index] || '',
            questionOptions: element?.choices || [],
          };
          return questionData;
        }
      }
    });

    if (formQuestions?.length > 0 && selectedCategories?.length > 0) {
      const formData = {
        formName: formName,
        formTitle: title,
        formDescription: formDescription,
        formCategory: selectedCategories,
        formQuestions: formQuestions,
        userId: user?.id,
        shareWith: 'Everyone',
        userTablePk: user?.id,
        userType: 'user',
        formType: 'query',
      };

      const apiUrl = config?.serverBaseUrl + '/forms/create_form';
      axios
        .post(apiUrl, formData)
        .then((response) => {
          setShowSuccessMessage(true);
          setFormName('');
          setTitle('');
          setFormDescription('');
          setFormElements([]);
          setDescriptions({});
          setSelectedCategories([]);
          postList();
          return closeSurvey();
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }else {
      const msg = 'Category and Question are mandatory';
      const sendmessage = await dispatch(
        messageActions.messagefromComponent(msg, 'error'),
      );
    }
  };

  const getQuestionType = (elementType) => {
    if (elementType === 'Text Input') {
      return 'text';
    } else if (elementType === 'Multiple Choice') {
      return 'checkbox';
    } else if (elementType === 'Select') {
      return 'radio';
    } else {
      return 'unknown';
    }
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(surveyActions.closeQuery());
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '1001',
      }}>
      <Backdrop sx={{ color: '#fff', zIndex: '2001' }} open={openQuery}>
        <Box
          sx={{
            minHeight: '350px',
            backgroundColor: '#333',
            borderRadius: '10px',
            maxHeight: '70vh',
            height: 'auto',
            maxWidth: '1100px',
            marginTop: '90px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid #fff',
            },
            minWidth: { xs: '90%', md: '1080px' },
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}>
          <Stack
            spacing={0}
            direction="column"
            justifyContent="space-between"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '18px',
                  fontWeight: '600',
                }}>
                <Typography
                  variant="h2s"
                  component="h2s"
                  sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                  }}>
                  Create a Query
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{
                    color: '#ffffff',
                    cursor: 'pointer',
                    fontSize: '24',
                    right: '10px',
                    top: '10px',
                    zIndix: '999999999',
                  }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>

            <Divider />

            <Box pt={2} sx={{ textAlign: 'center', position: 'relative' }}>
              {/* <Box
                mt={2}
                ml={0}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <FormControl
                  sx={{
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto',
                    '@media (max-width: 600px)': {
                      maxWidth: '100%',
                      padding: '5px 15px',
                    },
                  }}>
                  <TextField
                    sx={{
                      '& label.Mui-focused': {
                        fontSize: '13px',
                        color: (theme) => theme.palette.palette.text.secondary,
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '13px',
                        color: (theme) => theme.palette.palette.text.secondary,
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: (theme) =>
                          theme.palette.palette.text.secondary,
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: (theme) =>
                            theme.palette.palette.text.secondary,
                        },
                        '&:hover fieldset': {
                          borderColor: (theme) =>
                            theme.palette.palette.text.secondary,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: (theme) =>
                            theme.palette.palette.text.secondary,
                        },
                      },
                    }}
                    variant="standard"
                    fullWidth
                    value={title}
                    onChange={handleTitleChange}
                    label="Query Title"
                  />
                </FormControl>
              </Box> */}
              {/* <Box
                mt={2}
                ml={0}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <FormControl
                  sx={{
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto',
                    '@media (max-width: 600px)': {
                      maxWidth: '100%',
                      padding: '5px 15px',
                    },
                  }}>
                  <TextField
                    sx={{
                      '& label.Mui-focused': {
                        fontSize: '13px',
                        color: (theme) => theme.palette.palette.text.secondary,
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '13px',
                        color: (theme) => theme.palette.palette.text.secondary,
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: (theme) =>
                          theme.palette.palette.text.secondary,
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: (theme) =>
                            theme.palette.palette.text.secondary,
                        },
                        '&:hover fieldset': {
                          borderColor: (theme) =>
                            theme.palette.palette.text.secondary,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: (theme) =>
                            theme.palette.palette.text.secondary,
                        },
                      },
                    }}
                    variant="standard"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={formDescription}
                    onChange={handleDescriptionChange}
                    label="Query Description"
                  />
                </FormControl>
              </Box> */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                mt={2}
                ml={0}>
                <FormControl
                  sx={{
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto',
                    zIndex: '2001',
                    '@media (max-width: 600px)': {
                      maxWidth: '100%',
                      padding: '5px 15px',
                    },
                  }}>
                  <InputLabel
                    sx={{
                      color: '#38CD84',
                      fontSize: '13px',
                      marginLeft: '-14px',
                      marginTop: '5px',
                      '&.Mui-focused': {
                        color: '#38CD84',
                      },
                    }}>
                    Choose Categories *
                  </InputLabel>
                  <Select
                    variant="standard"
                    multiple
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                    }}
                    value={selectedCategories}
                    onChange={handleCategory}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          zIndex: '2001',
                        }}>
                        {selected.length === 0 ? (
                          <Typography variant="body2" color="secondary">
                            Select Category
                          </Typography>
                        ) : (
                          selected?.map((item) => (
                            <Chip
                              key={item.formCategoryId}
                              label={item.formCategoryName}
                              variant="outlined"
                              sx={{
                                backgroundColor: '#38CD84',
                                color: '#000',
                                margin: '4px',
                                zIndex: '2001',
                              }}
                            />
                          ))
                        )}
                      </Box>
                    )}>
                    {categories?.map((category) => (
                      <MenuItem key={category.formCategoryId} value={category}>
                        <Checkbox
                          color="secondary"
                          checked={selectedCategories.some(
                            (selectedCategory) =>
                              selectedCategory.formCategoryId ===
                              category.formCategoryId,
                          )}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                        {category.formCategoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                ref={containerRef}
                sx={{
                  zIndex: '2001',
                  '@media (max-width: 600px)': {
                    padding: '5px 15px',
                  },
                }}>
                {renderFormElements()}
              </Box>
              {/* <Box
                sx={{
                  position: 'absolute',
                  bottom: '20px',
                  right: '40px',
                  // padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  backgroundColor: '#212121',
                  border: '1px solid gray',
                  borderRadius: '5px',
                  '@media (max-width: 600px)': {
                    position: 'relative',
                    bottom: 'auto',
                    right: 'auto',
                    width: '100%',
                    padding: '20px 0',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    overflowX: 'auto', // Add this to allow horizontal scrolling if needed
                  },
                }}>
                <Button onClick={handleAddTextField}>
                  <Tooltip title="Text-Input" style={{ color: '#38CD84' }}>
                    <IconButton>
                      <TextFieldsIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Button>
                <Button onClick={handleAddSelect}>
                  <Tooltip title="Multiple-choice">
                    <IconButton>
                      <CheckBoxIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Button>
                <Button onClick={handleAddRadioGroup}>
                  <Tooltip title="Select">
                    <IconButton>
                      <RadioButtonCheckedIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Button>
              </Box> */}
            </Box>

            <Divider />

            <Grid container alignItems="flex-end" sx={{ alignItems: 'center' }}>
              <Grid
                item
                xs
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '16px',
                }}>
                <Box px={3}></Box>
              </Grid>
              <Grid item>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <Button
                    sx={{
                      borderRadius: '50px',
                      fontFamily: 'Raleway',
                      lineHeight: '18px',
                      fontWeight: '800',
                      fontSize: '16px',
                      textTransform: 'none',
                      padding: '5px 25px',
                      color: '#000',
                      marginLeft: '10px',
                      backgroundColor: (theme) =>
                        theme.palette.palette.text.secondary,
                      '&:hover': {
                        backgroundColor: (theme) =>
                          theme.palette.palette.text.secondary,
                      },
                    }}
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleSubmit(e)}>
                    Create
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};
export default CreateQueryForm;

// formElements?.map((element, index) => (
//   <Box
//     key={index}
//     sx={{
//       backgroundColor: '#212121',
//       width: '100%',
//       maxWidth: '800px',
//       margin: '20px auto',
//       padding: '20px',
//       border: '1px solid gray',
//       borderRadius: '5px',
//       boxSizing: 'border-box',
//       '@media (max-width: 600px)': {
//         maxWidth: '100%',
//       },
//     }}>
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         position: 'relative',
//       }}>
//       <Box>
//         <FormControl>
//           <select
//             value={element?.inputType}
//             onChange={(e) => handleInputChange(e, index)}
//             style={{
//               height: 25,
//               width: 150,
//               fontSize: '12px',
//               fontWeight: 600,
//               backgroundColor: '#38CD84',
//               color: '#000',
//               border: '1px solid #38CD84',
//               borderRadius: '4px',
//               padding: '2px',
//               zIndex: '2001',
//             }}>
//             {selected?.map((item) => (
//               <option key={item} value={item}>
//                 {item}
//               </option>
//             ))}
//           </select>
//         </FormControl>
//       </Box>
//       <Box>
//         <DeleteForeverIcon
//           color="secondary"
//           onClick={() => handleRemoveElement(index)}
//         />
//       </Box>
//     </Box>

//     {element?.inputType === 'Text Input' && (
//       <Box
//         mt={1}
//         sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//         <FormControl sx={{ width: '800px' }}>
//           <div style={{ display: 'flex', alignItems: 'center' }}>
//             <div
//               style={{
//                 color: '#38CD84',
//                 marginRight: '8px',
//                 fontSize: '16px',
//                 fontWeight: 'bold',
//                 marginTop: '5px',
//               }}>

//             </div>
//             <CxoInputCss
//               label="Question"
//               variant="outlined"
//               fullWidth
//               value={element?.question}
//               onChange={(e) => handleQuestionChange(e, index)}
//             />
//           </div>
//         </FormControl>
//       </Box>
//     )}
//     {element?.inputType === 'Multiple Choice' && (
//       <Box
//         mt={1}
//         sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//         <FormControl sx={{ width: '800px' }}>
//           <div style={{ display: 'flex', alignItems: 'center' }}>
//             <div
//               style={{
//                 color: '#38CD84',
//                 marginRight: '8px',
//                 fontSize: '16px',
//                 fontWeight: 'bold',
//                 marginTop: '5px',
//               }}>
//               {index + 1}.
//             </div>
//             <CxoInputCss
//               sx={{
//                 '& label.Mui-focused': {
//                   fontSize: '12px',
//                   color: (theme) => theme.palette.palette.text.secondary,
//                 },
//                 '& .MuiInputLabel-root': {
//                   fontSize: '12px',
//                   color: (theme) => theme.palette.palette.text.secondary,
//                 },
//               }}
//               label="Question"
//               variant="outlined"
//               fullWidth
//               value={element?.question}
//               onChange={(e) => handleQuestionChange(e, index)}
//             />
//           </div>{' '}

//           {element?.choices?.map((choice, choiceIndex) => (
//             <div
//               key={choiceIndex}
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'space-between',
//                 marginTop: '-20px',
//               }}>
//               <div
//                 style={{
//                   display: 'flex',
//                   alignItems: 'center',
//                 }}>
//                 <div
//                   style={{
//                     width: '16px',
//                     height: '16px',
//                     border: '1px solid #38CD84',
//                     marginRight: '8px',
//                     pointerEvents: 'none',
//                     marginTop: '40px',
//                   }}
//                 />
//                 <div
//                   style={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     marginTop: '20px',
//                     marginLeft: '24px',
//                   }}>
//                   <FormControl sx={{ width: '700px' }}>
//                     {' '}
//                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                       <TextField
//                         sx={{
//                           '& label.Mui-focused': {
//                             fontSize: '12px',
//                             color: (theme) =>
//                               theme.palette.palette.text.secondary,
//                           },
//                           '& .MuiInputLabel-root': {
//                             fontSize: '12px',
//                             color: (theme) =>
//                               theme.palette.palette.text.secondary,
//                           },
//                           '& .MuiInput-underline:after': {
//                             borderBottomColor: (theme) =>
//                               theme.palette.palette.text.secondary,
//                           },
//                           '& .MuiOutlinedInput-root': {
//                             '& fieldset': {
//                               borderColor: (theme) =>
//                                 theme.palette.palette.text.secondary,
//                             },
//                             '&:hover fieldset': {
//                               borderColor: (theme) =>
//                                 theme.palette.palette.text.secondary,
//                             },
//                             '&.Mui-focused fieldset': {
//                               borderColor: (theme) =>
//                                 theme.palette.palette.text.secondary,
//                             },
//                           },
//                         }}
//                         variant="standard"
//                         fullWidth
//                         value={choice}
//                         onChange={(e) =>
//                           handleChoiceChange(e, index, choiceIndex)
//                         }
//                         label={`Option ${choiceIndex + 1}`}
//                       />
//                     </div>{' '}
//                   </FormControl>
//                 </div>
//                 {element?.choices?.length > 1 && (
//                   <ClearIcon
//                     onClick={() => removeChoice(index, choiceIndex)}
//                     style={{
//                       cursor: 'pointer',
//                       color: '#38CD84',
//                       width: '20px',
//                       height: '20px',
//                       marginTop: '20px',
//                     }}
//                   />
//                 )}
//               </div>
//             </div>
//           ))}
//             <Box
//             display="flex"
//             justifyContent="space-between"
//             sx={{
//               paddingTop: '16px',
//               paddingBottom: '16px',
//               marginTop: '8px',
//             }}
//             ml={6}>
//             <Button
//               sx={{
//                 borderRadius: '50px',
//                 fontFamily: 'Raleway',
//                 lineHeight: '18px',
//                 fontWeight: 800,
//                 fontSize: '12px',
//                 textTransform: 'none',
//                 padding: '4px 10px',
//                 color: '#000',
//                 backgroundColor: (theme) =>
//                   theme.palette.palette.text.secondary,
//                 '&:hover': {
//                   backgroundColor: (theme) =>
//                     theme.palette.palette.text.secondary,
//                 },
//               }}
//               onClick={() => addChoice(index)}
//               disabled={element?.choices?.length >= 5}>
//               {' '}
//               Add Option
//             </Button>
//           </Box>
//         </FormControl>
//       </Box>
//     )}
//     {element?.inputType === 'Select' && (
//       <Box
//         mt={1}
//         sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//         <FormControl sx={{ width: '800px' }}>
//           <div style={{ display: 'flex', alignItems: 'center' }}>
//             <div
//               style={{
//                 color: '#38CD84',
//                 marginRight: '8px',
//                 fontSize: '16px',
//                 fontWeight: 'bold',
//                 marginTop: '5px',
//               }}>
//               {index + 1}.
//             </div>
//             <CxoInputCss
//               label="Question"
//               variant="outlined"
//               fullWidth
//               value={element?.question}
//               onChange={(e) => handleQuestionChange(e, index)}
//             />{' '}
//           </div>

//           {element?.choices?.map((choice, choiceIndex) => (
//             <div
//               key={choiceIndex}
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'space-between',
//                 marginTop: '-20px',
//               }}>
//               <div
//                 style={{
//                   display: 'flex',
//                   alignItems: 'center',
//                 }}>
//                 <div
//                   style={{
//                     borderRadius: '50%',
//                     width: '16px',
//                     height: '16px',
//                     border: '1px solid #38CD84',
//                     marginRight: '8px',
//                     pointerEvents: 'none',
//                     marginTop: '40px',
//                   }}
//                 />
//                 <div
//                   style={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     marginTop: '20px',
//                     marginLeft: '24px',
//                   }}>
//                   <FormControl sx={{ width: '700px' }}>
//                     <TextField
//                       sx={{
//                         '& label.Mui-focused': {
//                           fontSize: '12px',
//                           color: (theme) =>
//                             theme.palette.palette.text.secondary,
//                         },
//                         '& .MuiInputLabel-root': {
//                           fontSize: '12px',
//                           color: (theme) =>
//                             theme.palette.palette.text.secondary,
//                         },
//                         '& .MuiInput-underline:after': {
//                           borderBottomColor: (theme) =>
//                             theme.palette.palette.text.secondary,
//                         },
//                         '& .MuiOutlinedInput-root': {
//                           '& fieldset': {
//                             borderColor: (theme) =>
//                               theme.palette.palette.text.secondary,
//                           },
//                           '&:hover fieldset': {
//                             borderColor: (theme) =>
//                               theme.palette.palette.text.secondary,
//                           },
//                           '&.Mui-focused fieldset': {
//                             borderColor: (theme) =>
//                               theme.palette.palette.text.secondary,
//                           },
//                         },
//                       }}
//                       variant="standard"
//                       fullWidth
//                       value={choice}
//                       onChange={(e) =>
//                         handleChoiceChange(e, index, choiceIndex)
//                       }
//                       label={`Option ${choiceIndex + 1}`}
//                     />
//                   </FormControl>
//                 </div>
//                 {element?.choices?.length > 1 && (
//                   <ClearIcon
//                     onClick={() => removeChoice(index, choiceIndex)}
//                     style={{
//                       cursor: 'pointer',
//                       color: '#38CD84',
//                       width: '20px',
//                       height: '20px',
//                       marginTop: '45px',
//                     }}
//                   />
//                 )}
//               </div>
//             </div>
//           ))}
//                        <Box
//             display="flex"
//             justifyContent="space-between"
//             sx={{
//               paddingTop: '16px',
//               paddingBottom: '16px',
//               marginTop: '20px',
//             }}
//             ml={3}>
//             <Button
//               sx={{
//                 borderRadius: '50px',
//                 fontFamily: 'Raleway',
//                 lineHeight: '18px',
//                 fontWeight: '800',
//                 fontSize: '12px',
//                 textTransform: 'none',
//                 padding: '4px 10px',
//                 color: '#000',
//                 backgroundColor: (theme) =>
//                   theme.palette.palette.text.secondary,
//                 '&:hover': {
//                   backgroundColor: (theme) =>
//                     theme.palette.palette.text.secondary,
//                 },
//               }}
//               onClick={() => addChoice(index)}
//               disabled={element?.choices?.length >= 5}>
//               {' '}
//               Add Option
//             </Button>
//           </Box>
//         </FormControl>
//       </Box>
//     )}
//   </Box>
// ))
