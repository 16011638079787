import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Paper, Grid, Box, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import ProfileShort from "../../components/ProfileShort/ProfileShort";
import BottomNavigator from "../../components/BottomNavigator/BottomNavigator";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import HomeArticle from "../../containers/HomeContainer/HomeArticle";
import Search from "./Search";
import SearchHeader from "./SearchHeader";
import LeftNav from "../../components/LeftNav/LeftNav";
import JobApply from "../../components/JobApply/JobApply";
import JobShare from "../../components/JobShare/JobShare";
import ReportJob from "../../components/ReportJob/ReportJob";
import UnderVerification from "../../components/UnderVerification/UnderVerification";
import Helpandfeedback from "../../components/Helpandfeedback/Helpandfeedback";

const JobSearchContainer = (props) => {
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const feedbackShow = useSelector((state) => state?.post?.FeedbackShow);
  const openJobApply = useSelector((state) => state?.jobs?.JobApply);
  const openJobShare = useSelector((state) => state?.jobs?.JobShare);
  const reportJob = useSelector((state) => state?.jobs?.ReportJob );
  const underVerification = useSelector(
    (state) => state?.message?.underVerification
  );
  return (
    <Fragment>
      <Helmet>
        <title>Search || LinkCXO - Exclusive CXO Networking</title>
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
        <Header />
      </Box>
 
      {openSnackbar && <CustomizedSnackbars />}
      {feedbackShow && <Helpandfeedback />}
      {openJobApply && <JobApply />}
      {openJobShare && <JobShare />}
      {reportJob && <ReportJob />}
      {underVerification && <UnderVerification />}
      <Box pt={8} sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ maxWidth: "1280px", justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={3}
            sm={8}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box py={1}>
                <LeftNav />
            </Box>
          </Grid>
          <Grid item xs={12} sm={10} md={9}>
            <SearchHeader />
              <Search />
          </Grid>
        </Grid>
      </Box>

   
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: "0%",
          width: "100%",
          zIndex: 2001,
        }}
      >
        <BottomNavigator />
      </Box>
    </Fragment>
  );
};

export default React.memo(JobSearchContainer);
