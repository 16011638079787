import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CxoButton from '../../components/CxoButton/CxoButton';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import * as messageActions from '../../store/message';
import { dateTime } from '../../constants/app';
import EventCard from '../../components/EventCard/EventCard';
import EventInvited from './EventInvited';
import EventRequested from './EventRequested';
import EventSavedPassed from './EventSavedPassed';
import EventSavedUpcoming from './EventSavedUpcoming';
import EventHosting from './EventHosting';
import EventDiscover from './EventDiscover';
import Events from './Events';

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',
  // aparajita
  '@media (max-width: 500px)': {
    width: '100px',
    padding: '4px 8px',
    borderRadius: '10px',
  },

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
  '@media (max-width: 500px)': {
    width: '100%',
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const EventsTab = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state?.user?.UserDetails);
  const [value, setValue] = React.useState(0);

  const upcomingEventList = useSelector(
    (state) => state?.events?.eventUpcomingList,
  );
  const hostinglist = useSelector((state) => state?.events?.eventHostingList);
  const savedUpcominglist = useSelector(
    (state) => state?.events?.eventSavedUpcomingList,
  );
  const savedPassedlist = useSelector(
    (state) => state?.events?.eventSavedPassedList,
  );

  const eventPassedHosted = useSelector(
    (state) => state?.events?.eventHostedPassedList,
  );
  const pendinglist = useSelector((state) => state?.events?.eventPendingList);
  const invitedlist = useSelector((state) => state?.events?.eventInvitedList);

  const requestedlist = useSelector(
    (state) => state?.events?.eventRequestedList,
  );

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: 0,
    };
    const hostedEvent = await dispatch(
      eventsActions.hostedEventUpcomming(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: 0,
    };
    const eventList = await dispatch(eventsActions.savedEventUpcoming(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: 0,
    };
    const eventList = await dispatch(eventsActions.invitedEventList(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      categoryId: [],
      page_no: 0,
    };
    const eventList = await dispatch(eventsActions.requestedEventList(data));
  }, []);

  useEffect(async () => {
    if (location.pathname === '/events/discover') {
      setValue(0);
    } else if (location.pathname === '/events/hosting') {
      setValue(1);
    } else if (location.pathname === '/events/pending') {
      setValue(1);
    } else if (location.pathname === '/events/participating') {
      setValue(1);
    } else {
      setValue(0);
    }
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue != 2) {
      if (newValue === 0) {
        navigate('/events/discover');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/events/hosting');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/events/participating');
        setValue(newValue);
      }
    }
  };

  const createEvent = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      navigate('/event/create/');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <Fragment>
      <Box px={1} py={1}>
        <Tabs
          defaultValue={0}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          color="white"
          aria-label="ant example">
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            <StyledTabsList
              sx={{
                padding: '8px 16px',
                '@media (max-width: 500px)': {
                  padding: '4px 8px',
                },
              }}>
              <StyledTab>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      color: value === 0 ? '#000000' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Discover
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 0 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </StyledTab>
              <StyledTab>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      color: value === 1 ? '#000000' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    My Events{' '}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 1 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </StyledTab>
              {/* <Tab sx={{ width: '180px' }}>
                <Stack direction="row" spacing={0.5}>
                  <Box>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabname}
                      sx={{
                        color: value === 2 ? '#000000' : '#ffffff',
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}>
                      Participating{' '}
                      {savedUpcominglist?.length > 0 ||
                      eventPassedHosted?.length > 0
                        ? '(' + eventPassedHosted?.length + ')'
                        : '(' + 0 + ')'}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabdigit}
                      sx={{
                        color: value === 2 ? '#000000' : '#ffffff',
                      }}></Typography>
                  </Box>
                </Stack>
              </Tab> */}
              <StyledTab >
                <Stack direction="row" spacing={0.5} onClick={createEvent}>
                  <Box>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabname}
                      sx={{
                        color: value === 3 ? '#000000' : '#ffffff',
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}>
                      Create
                    </Typography>
                  </Box>
                </Stack>
              </StyledTab>
            </StyledTabsList>
          </Paper>

          <StyledTabPanel value={0}>
            <Grid container sx={{ paddingBottom: '8px' }}>
              <Box sx={{ width: '100%' }}>
                <EventDiscover />
              </Box>
            </Grid>
          </StyledTabPanel>

          <StyledTabPanel value={1}>
            <Events />
          </StyledTabPanel>
          <StyledTabPanel
            value={2}
            sx={{ paddingBottom: '8px' }}></StyledTabPanel>
        </Tabs>
      </Box>
    </Fragment>
  );
};

export default React.memo(EventsTab);

// {designationList?.data
//   ? designationList?.data.map((item, key) => {
//       return (
//         <MenuItem key={key} value={item.title}>
//           {item.title}
//         </MenuItem>
//       );
//     })
//   : ""}

{
  /* <Box sx={{ width: '100%' }}>
<EventHosting />
</Box> */
}
