import {
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGOUT_FULFILLED,
  SEND_EMAIL_OTP_PENDING,
  SEND_EMAIL_OTP_REJECTED,
  VERIFY_OTP_PENDING,
  VERIFY_OTP_REJECTED,
  PROFILE_REQUIRED_FIELD_PENDING,
  PROFILE_REQUIRED_FIELD_REJECTED,
} from "./actions";
const initialSnackbarState = {
  loggedIn: false,
  tryAllLogin: false,
  submitsucc: false,
  addBio: false,
  addInterest: false,
  email: "",
  otptype: "",
  userData: [],
  profileCompleted: false,
};
export const authreducer = (state = initialSnackbarState, action) => {
  switch (action.type) {
    case "LOGIN_FULFILLED": {
      return { ...state, ...action.payload };
    }
    case LOGOUT_FULFILLED: {
      return { ...state, ...action.payload };
    }
    case LOGIN_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_REJECTED:
      return { ...state, ...action.payload };
    case SEND_EMAIL_OTP_PENDING:
      return { ...state, ...action.payload };
    case "SEND_EMAIL_OTP_FULFILLED": {
      return { ...state, ...action.payload };
    }
    case "USER_PROFILE_DETAILS_FULFILLED": {
      return {
        ...state,
      };
    }
    case SEND_EMAIL_OTP_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_OTP_PENDING:
      return { ...state, ...action.payload };
    case "VERIFY_OTP_FULFILLED": {
      return { ...state, ...action.payload };
    }
    case VERIFY_OTP_REJECTED:
      return { ...state, ...action.payload };
    case PROFILE_REQUIRED_FIELD_PENDING:
      return { ...state, ...action.payload };
    case "PROFILE_REQUIRED_FIELD_FULFILLED": {
      return { ...state,
         ...action.payload,
         profileCompleted: true
         };
    }
    case PROFILE_REQUIRED_FIELD_REJECTED:
      return { ...state, 
        ...action.payload,
        profileCompleted: false

       };
    case "USER_DATA_FULFILLED": {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};
