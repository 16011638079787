import React, { Component } from 'react';
import '../../resources/css/app.css';
import { Row, Col, Container } from 'react-bootstrap';
import Footer from '../../components/HomeHeader/footer';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import NewLandingPageSection from './all-section-inside-new-landing-page';
import cookie from 'react-cookies';

class HomeContainer extends Component {
  componentWillMount() {
    Object.keys(cookie.loadAll()).map((item) =>
      cookie.remove(item.toString(), { path: '/' }),
    ); //  for clear all cookies by react-cookies
  }
  componentDidMount() {}

  render() {
    let pathName = window.location.pathname;
    return (
      <Container fluid id="no-padding">
        <Row className="padding-right-style">
          <Col md={12} xs={12} className="main-header-style-section">
            {' '}
            {/* <Header /> */}
            <Navbar />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            {' '}
            <NewLandingPageSection />{' '}
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} style={{ background: '#151414' }}>
            {' '}
            <Footer />
          </Col>{' '}
        </Row>
      </Container>
    );
  }
}
export default HomeContainer;
