import React, { useEffect } from 'react';
import '../../resources/css/app.css';
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import { Box, Stack, Card } from '@mui/material';
import EventsIcon from '../../assets/eventsicon';
import WestIcon from '@mui/icons-material/West';
import Menuicon from '../../assets/menuicon';
import * as eventsActions from '../../store/events';
import * as activityActions from '../../store/activity';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const EventDetails = (props) => {
  const dispatch = useDispatch();
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const eventDetails = useSelector((state) => state?.events?.eventDetailsHome);
  const tempUserId = useSelector((state) => state?.activity?.tempUserId);
  console.log('eventDetails', eventDetails);

  useEffect(async () => {
    const id = params?.event?.split('-');
    if (id?.length > 0) {
      const data = {
        id: id[id?.length - 1].substring(2),
      };

      const eventdetails = await dispatch(eventsActions.eventDetailsHome(data));
    }
  }, []);

  const joinEvent = () => {
    let eventcode = eventDetails?.title + '-' + 'lx' + eventDetails?.id;
    navigate(
      '/login?sign_up_through_page=event&redirect=/event/details/' + eventcode,
    );
  };

  React.useEffect(() => {
    async function postUserTrack() {
      if (location?.search?.includes('campaign')) {
        if (tempUserId == 0 || tempUserId == undefined) {
          const data = {
            tempUserId: uuidv4(),
          };
          await dispatch(activityActions.assignUserId(data));
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname?.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: data?.tempUserIdo,
          };
          await dispatch(activityActions.userTrack(data2));
        } else {
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: tempUserId,
          };
          await dispatch(activityActions.userTrack(data2));
        }
      }
    }
    postUserTrack();
  }, [location]);

  const convertLinks = (input) => {
    console.log('convertLinks', input);
    if (input) {
      let text = input;
      const linksFound = text.match(/(?:www|https?)[^\s]+/g);
      const aLink = [];

      if (linksFound != null) {
        for (let i = 0; i < linksFound.length; i++) {
          let replace = linksFound[i];
          if (!linksFound[i].match(/(http(s?)):\/\//)) {
            replace = 'http://' + linksFound[i];
          }
          let linkText = replace.split('/')[2];
          let originalText = linksFound[i];
          if (linkText.substring(0, 3) == 'www') {
            linkText = linkText.replace('www.', '');
          }
          if (linkText.match(/youtu/)) {
            let youtubeID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' +
                youtubeID +
                '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            );
          } else if (linkText.match(/vimeo/)) {
            let vimeoID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
                vimeoID +
                '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
            );
          } else {
            aLink.push(
              '<a href="' +
                replace +
                '" target="_blank">' +
                originalText +
                '</a>',
            );
          }
          text = text
            .split(linksFound[i])
            .map((item) => {
              return aLink[i].includes('iframe') ? item.trim() : item;
            })
            .join(aLink[i]);
        }
        return text;
      } else {
        return input;
      }
    }
  };
  const redirectEvent = () => {
    navigate(-1);
  };
  return (
    <>
      <Navbar />
      <Box py={3} px={2} onClick={redirectEvent} sx={{ position: 'relative' }}>
        <WestIcon
          sx={{
            position: 'absolute',
            color: '#fff',
            fontSize: '32px',
            top: '100px',
            left: '20px',
          }}
        />
      </Box>
      <Stack
        direction="row"
        sx={{
          paddingTop: '50px',
          backgroundColor: '#262525',
          height: '100vh',
          overflowY: 'scroll',
        }}>
        <Box
          sx={{
            width: '10%',
            '@media (max-width: 500px)': {
              width: '0%',
            },
          }}>
          <Box py={0} px={2}></Box>
        </Box>
        <Stack
          direction="column"
          sx={{
            width: '100%',
            marginRight: '60px',
            '@media (max-width: 500px)': {
              marginBottom: '30px',
            },
          }}>
          <Stack direction="row">
            <Box sx={{ width: '30%' }}>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    width: '90%',
                    '@media (max-width: 500px)': {
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                      padding: '0px 20px',
                    },
                  }}>
                  <Card
                    sx={{
                      width: '100%',
                    }}>
                    <CardMedia
                      component="img"
                      height={{ md: '300', xs: '200' }}
                      image={eventDetails?.image_url}
                      alt="event image"
                    />
                  </Card>
                </Box>
                <Stack
                  direction="column"
                  sx={{
                    width: '100%',
                    '@media (max-width: 500px)': {
                      width: '100%',
                      padding: '0px 20px',
                    },
                  }}>
                  <Box pb={4} pr={1} sx={{ color: '#38CD84' }}>
                    <h2 className="event-detail-title">
                      <p style={{ fontSize: '28px', lineHeight: '32px',fontWeight:600 }}>
                        {' '}
                        {eventDetails?.title}
                      </p>
                    </h2>
                  </Box>
                  <Box>
                    <Stack direction="row" spacing="3">
                      <Stack
                        direction="row"
                        spacing="3"
                        >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '25px',
                              maxWidth: 'min-content',
                              minWidth: '25px',
                            }}>
                            <Menuicon name="calendar" color="#fff" />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            paddingLeft: '13px',

                          }}>
                          <p
                            style={{
                              color: '#fff',
                              fontSize: '18px',
                              fontFamily: 'Open Sans',
                                '@media (max-width: 500px)': {
                              fontSize: '15px',
                            },
                            }}>
                            {eventDetails?.start_date
                              ? moment(eventDetails?.start_date).format('LL')
                              : ''}
                          </p>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing="3"
                        sx={{ paddingLeft: '32px' }}>
                        <Box
                          mt={0.5}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '20px',
                              maxWidth: 'min-content',
                              minWidth: '20px',
                            }}>
                            <Menuicon name="time" color="#fff" />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            paddingLeft: '16px',
                            color: '#fff',
                            fontSize: '21px',
                            fontFamily: 'Open Sans',
                            '@media (max-width: 500px)': {
                              fontSize: '15px',
                            },
                          }}>
                          <p   style={{
                              color: '#fff',
                              fontSize: '18px',
                              fontFamily: 'Open Sans',
                                '@media (max-width: 500px)': {
                              fontSize: '15px',
                            },
                            }}>
                            {' '}
                            {eventDetails?.start_time
                              ? moment(
                                  eventDetails?.start_time,
                                  'hh:mm:ss A',
                                ).format('hh:mm A')
                              : ''}
                          </p>
                        </Box>
                      </Stack>
                    </Stack>

                    <Stack direction="row" spacing="3">
                       <Stack
                        direction="row"
                        spacing="3"
                        sx={{ paddingLeft: '2px' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}>


                            {eventDetails?.mode === 'Offline' ? (
                              <Box mt={0.4}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                                <Menuicon name="location" color="#fff" /></Box>
                              ) : (    <Box mt={0.6}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '15px',
                              maxWidth: 'min-content',
                              minWidth: '15px',
                            }}>
                                <EventsIcon
                                  name="events_online"
                                  color="#fff"
                                /></Box>
                              )}

                        </Box>
                        <Box
                          sx={{
                            paddingLeft: '16px',
                            color: '#fff',
                            fontSize: '21px',
                            fontFamily: 'Open Sans',
                            textTransform: 'capitalize',
                            '@media (max-width: 500px)': {
                              fontSize: '16px',
                            },
                          }}>
                          <p   style={{
                              color: '#fff',
                              fontSize: '18px',
                              fontFamily: 'Open Sans',
                                '@media (max-width: 500px)': {
                              fontSize: '15px',
                            },
                            }}>
                            {eventDetails?.mode === 'Online'
                              ? eventDetails?.mode + ' Event '
                              : ''}
                            {eventDetails?.mode === 'Offline'
                              ? eventDetails?.venue
                              : ''}
                          </p>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box py={4} paddingLeft={15}>
                    <div className="event-btn">
                      <button
                        className="btn-event-details"
                        style={{
                          cursor: 'pointer',
                          width: '124px',
                          '@media (max-width: 500px)': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                            width: '100%',
                          },
                        }}
                        onClick={joinEvent}>
                        Attend
                      </button>
                    </div>
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Box sx={{ width: '70%' }}>
              <Box sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}>
                <Card
                  style={{
                    maxWidth: '100%',
                    margin: 'auto',
                    height: '60vh',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <CardMedia
                    component="img"
                    image={eventDetails?.image_url}
                    alt="event Image"
                    sx={{ width: 'auto' }}
                  />
                </Card>
              </Box>
            </Box>
          </Stack>
          <Box
            sx={{
              width: '90%',
              '@media (max-width: 500px)': {
                width: '100%',
                padding: '0px 20px',
              },
            }}>
            <Stack direction="column">
              <Box
                py={{ md: 1, xs: 0.5 }}
                sx={{
                  color: '#38CD84',
                }}>
                <h4 className="event-detail">Event Details</h4>
              </Box>
              <Box pb={2} sx={{ color: '#fff' }}>
                <Box pt={{ md: 2, xs: 1 }}>
                  <Box
                    sx={{
                      fontWeight: '500',
                      fontSize: '18px',
                      color: 'rgba(255, 255,255,1)',
                      whiteSpace: 'pre-line',
                      lineHeight: '24px',
                      letterSpacing: '2px',
                      '@media (max-width: 500px)': {
                        fontSize: '15px',
                        lineHeight: '19px',
                        letterSpacing: '1px',
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: convertLinks(eventDetails?.details),
                    }}></Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default EventDetails;
