import React, { useState, Fragment } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
  MuiDrawer: {
    backgroundColor: '#1F1E1E',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawerRoot: {
    position: 'relative !important',
    marginTop: '100px',
    '& .MuiBackdrop-root': {
      marginTop: '100px',
      position: 'relative !important',
      height: '100vh',
    },
  },
  drawerPaper: {
    position: 'absolute !important',
  },
  root: {
    justifyContent: 'flex-end',
    fontFamily: 'Raleway',
  },
  link: {
    fontWeight: '600',
    textDecoration: 'none',
    color: '#000',
    fontSize: '20px',
  },
}));

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        PaperProps={{
          textAlign: 'center',
          sx: {
            backgroundColor: 'black',
            marginTop: '71px',
            width: '100%',
            textAlign: 'center',
          },
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/aboutus">
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#fff',
                fontWeight: '600',
              }}>
              About Us
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/#jobs">
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#fff',
                fontWeight: '600',
              }}>
              Jobs
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/#events">
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#fff',
                fontWeight: '600',
              }}>
              Events
            </ListItemText>
          </ListItem>
          <Divider />

          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/login">
            <ListItemText style={{ textAlign: 'center', padding: '20px 0px' }}>
              <Button
                sx={{
                  textTransform: 'none',
                  textDecoration: 'none',
                  padding: '4px 20px',
                  borderRadius: '3px ',
                  color: '#262525',
                  marginLeft: '20px',
                  fontWeight: '600',
                  fontSize: '18px',
                  backgroundColor: '#38CD84',
                  '&:hover': {
                    backgroundColor: '#38CD84',
                    color: '#262525',
                  },

                  '@media (max-width: 1100px)': {
                    padding: '3px 15px',
                  },
                }}>
                Login
              </Button>
            </ListItemText>
          </ListItem>

          <Divider />
        </List>
      </Drawer>
      <IconButton
        edge="start"
        sx={{ border: '1px solid #fff', borderRadius: '2px' }}
        onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon sx={{}} />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
