import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate,  useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Grid, Box, Typography, Stack  } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/base/Tab';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import RewardMembershipCard from '../../components/RewardMembershipCard/RewardMembershipCard';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import * as rewardsActions from '../../store/rewards';
import StarIcon from '@mui/icons-material/Star';
import * as messageActions from '../../store/message';
import moment from 'moment';
import Slide from '@mui/material/Slide';

// const useStyles = makeStyles((theme) => ({
//   homeroot: {
//     backgroundColor: '#fff',
//   },
// }));
// const StyledTab = styled(Tab)((theme) => ({
//   color: 'white',
//   cursor: 'pointer',
//   width: '140px',
//   padding: '8px 12px',
//   margin: '6px 6px',
//   border: 'none',
//   borderRadius: '18px',
//   display: 'flex',
//   justifyContent: 'center',
//   backgroundColor: '#262525',
//   fontFamily: 'Raleway',
//   fontWeight: '600',
//   letterSpacing: '0.68px',

//   '& .MuiTabs-indicator': {
//     color: '#38CD84',
//     backgroundColor: '#000014',
//   },
//   '&.MuiTab-root': {
//     borderBottom: 0,
//   },
//   '& .Mui-disabled': {
//     color: 'rgb(245,245,245, 0.6)!important',
//   },
//   '&:hover': {
//     backgroundColor: '#38CD84',
//   },
//   '&.Mui-selected': {
//     backgroundColor: '#38CD84',
//     color: '#1F1E1E',
//   },
// }));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const StyledTabsList = styled(TabsList)((theme) => ({
//   minWidth: '320px',
//   backgroundColor: '#262525',
//   borderRadius: '8px',
//   marginBottom: '16px',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-around',
//   alignContent: 'space-between',
//   padding: '16px 16px',
// }));

// const StyledTabPanel = styled(TabPanel)((theme) => ({
//   width: '100%',
// }));

// const AntTab = styled((props) => <Tab disableRipple {...props} />)(
//   ({ theme }) => ({
//     color: '#FFFFFF',
//     fontWeight: '800',
//     fontFamily: 'Montserrat',
//     '&:hover': {
//       color: '#38CD84',
//       opacity: 1,
//     },
//     '&.MuiTab-root': {},
//     '&.Mui-selected': {
//       color: '#38CD84',
//     },
//     '&.Mui-focusVisible': {
//       backgroundColor: '#d1eaff',
//     },
//   }),
// );

const Membership = (props) => {
  let params = useParams();
  console.log('params', window.location.href);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [queryParameters] = useSearchParams();
  const [showpopup, setShowpopup] = React.useState(false);

  const user = useSelector((state) => state?.user?.UserDetails);

  // const searchresult =  dispatch(searchActions.postSearch(data));
  // const rewardsTotalCredits = useSelector(
  //   (state) => state?.rewards?.rewardsTotalCredits,
  // );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const membershipList = useSelector((state) => state?.rewards?.membershipList);

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );
  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  // const rewardsCategoryList = useSelector(
  //   (state) => state?.rewards?.rewardsCategoryList,
  // );

  console.log(
    'rewardsPartnerVoucher',
    rewardsPartnerVoucher,
    rewardsVoucherList,
  );

  useEffect(() => {
    async function fetchData() {
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardsCategoryList(),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log('useEffect', queryParameters.get('visit'));
    if (queryParameters.get('visit') == 'new') {
      setShowpopup(true);
    } else {
      setShowpopup(false);
    }
  }, []);

  useEffect(() => {
    async function fetchDetails() {
      const data = {
        membership_id: 2,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
    }
    fetchData();
  }, []);

  const handleChange = async (event, newValue) => {
    console.log('handleChange', event, newValue);

    if (user?.isVerified) {
      if (newValue === 1) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/my/vouchers');
        setValue(newValue);
      } else if (newValue === 0) {
        navigate('/rewards/membership');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const handleClickOpen = () => {
    setShowpopup(true);
  };

  const handleClose = () => {
    setShowpopup(false);
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 1) {
        navigate('/rewards/category/partners');
        setValue(val);
      } else if (val === 2) {
        navigate('/rewards/my/vouchers');
        setValue(val);
      } else if (val === 0) {
        navigate('/rewards/membership');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const subscriptionType = () => {
    if (user?.membership_type === 0) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 1) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 2) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#C0C0C0' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#C0C0C0',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Silver
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 3) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#FFC107' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#FFC107',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Gold
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 4) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      console.log('membershipDetails[0]', membershipDetails[0]);
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#A0B2C6' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#A0B2C6',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Platinum
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    }
  };

  const creditsDetails = () => {
    navigate('/rewards/credits');
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box pt={2}>
          <Box sx={{ width: '100%' }}>
            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      {membershipList?.length > 0 &&
                        membershipList?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <RewardMembershipCard membership={list} />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(Membership);

// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab>
