import React, { Fragment, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ReactPlayer from "react-player";
import VisibilitySensor from "react-visibility-sensor";
import { useSearchParams } from "react-router-dom";

const VideoApi = (props) => {
  const [play, setPlay] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [url, setUrl] = React.useState("");
  const playIn = () => setPlay(true);
  const pause = () => setPlay(false);

  const onChangeVisible = (isVisible) => {
    if (!isVisible) {
      setPlay(false);
    }
  };
  useEffect(async () => {
    if (searchParams.get("url")) {
      setUrl(searchParams.get("url"));
      console.log("url", searchParams.get("url"));
    }
  }, []);

  return (
    <Fragment>
      <VisibilitySensor onChange={onChangeVisible}>
        <ReactPlayer
          url={url}
          onReady={() => console.log("onReady")}
          onStart={() => {
            setPlay(true);
          }}
          onPlay={() => {
            setPlay(true);
          }}
          playing={play}
          lite={true}
          controls={true}
          muted={true}
          width="100%"
          height="100vh"
        />
      </VisibilitySensor>
    </Fragment>
  );
};

export default React.memo(VideoApi);
