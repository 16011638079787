import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';

import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';

import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));

const EditAwards = (props) => {
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [file, setFile] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(new Date());
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);

  // const searchresult =  dispatch(searchActions.postSearch(data));

  const handleChange = (newValue) => {
    setType(newValue);
  };

  const closeEdit = () => {};

  const handleSubmitform = async (data) => {
    console.log('data', data);

    const useredu = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      award_name: data?.award_name,
      awarded_by: data?.awarded_by,
      details: data?.details,
    };
    if (
      data?.award_name.length > 0 &&
      data?.awarded_by.length > 0 &&
      data?.details.length > 0
    ) {
      const usereducation = await dispatch(userActions.manageRewards(useredu));
      const userdata = {
        id: user?.id,
      };
      const usersabout = await dispatch(userActions.userProfileAbout(userdata));
      if (updateSection) {
        const snackbar = await dispatch(
          userActions.closeUpdateSectionProfile(),
        );
      }
      if (addNew) {
        const snackbaradd = await dispatch(userActions.closeUpdateProfile());
      }
    }
  };
  return (
    <Fragment>
      <Box px={3} py={1} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center  ',
              width: '100%',
            }}>
            <form
              onSubmit={handleSubmit(handleSubmitform)}
              style={{
                width: '100%',
              }}>
              <Box sx={{ textAlign: 'center', width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Name"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="award_name"
                    defaultValue={
                      props?.section?.award_name
                        ? props?.section?.award_name
                        : ''
                    }
                  />
                </Stack>
              </Box>

              <Box sx={{ width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput label="By" value={value} onChange={onChange} />
                    )}
                    name="awarded_by"
                    defaultValue={
                      props?.section?.awarded_by
                        ? props?.section?.awarded_by
                        : ''
                    }
                  />
                </Stack>
              </Box>

              <Box sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CssTextField
                        variant="standard"
                        fullWidth
                        multiline
                        rows={6}
                        maxRows={6}
                        value={value}
                        onChange={onChange}
                        color="secondary"
                        label="Details"
                        sx={{
                          scrollbarWidth: 'none',
                        }}
                      />
                    )}
                    name="details"
                    defaultValue={
                      props?.section?.details ? props?.section?.details : ''
                    }
                  />
                </Stack>
              </Box>

              <Box
                py={4}
                px={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CxoButton label="Add" type="submit" />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EditAwards);
