import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CxoButton from '../../components/CxoButton/CxoButton';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
// import Tab, { tabClasses } from '@mui/base/Tab';
import TabsList from '@mui/base/TabsList';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import * as messageActions from '../../store/message';
import * as clubsActions from '../../store/clubs';
import { dateTime } from '../../constants/app';
import ClubCard from '../../components/ClubCard/ClubCard';
import ClubsInvited from './ClubsInvited';
import ClubsRequested from './ClubsRequested';
import MyClubPassed from './MyClubPassed';
import EventSavedUpcoming from './EventSavedUpcoming';
import ClubsOwned from './ClubsOwned';
import ClubsDiscover from './ClubsDiscover';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const Clubs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state?.user?.UserDetails);
  const [value, setValue] = React.useState(0);
  const clubHostedList = useSelector((state) => state?.clubs?.clubHostedList);
  const myClubList = useSelector((state) => state?.clubs?.myClubList);

  const savedClubList = useSelector((state) => state?.clubs?.savedClubList);

  const clubInvitedList = useSelector((state) => state?.clubs?.clubInvitedList);
  const clubRequestedList = useSelector(
    (state) => state?.clubs?.clubRequestedList,
  );
  const hostinglist = useSelector((state) => state?.events?.eventHostingList);

  const savedUpcominglist = useSelector(
    (state) => state?.events?.eventSavedUpcomingList,
  );

  const pendinglist = useSelector((state) => state?.events?.eventPendingList);
  const invitedlist = useSelector((state) => state?.events?.eventInvitedList);

  const requestedlist = useSelector(
    (state) => state?.events?.eventRequestedList,
  );

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const hostedEvent = await dispatch(
      eventsActions.hostedEventUpcomming(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const eventList = await dispatch(eventsActions.savedEventUpcoming(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const clubList = await dispatch(clubsActions.savedClub(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const eventList = await dispatch(clubsActions.invitedClubList(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const eventList = await dispatch(clubsActions.requestedClubList(data));
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue != 4) {
      if (newValue === 0) {
        navigate('/clubs/owner');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/clubs/pending');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/clubs/requested');
        setValue(newValue);
      } else if (newValue === 3) {
        navigate('/clubs/member');
        setValue(newValue);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === '/clubs/owner') {
      setValue(0);
    } else if (location.pathname === '/clubs/pending') {
      setValue(1);
    } else if (location.pathname === '/clubs/requested') {
      setValue(2);
    } else if (location.pathname === '/clubs/member') {
      setValue(3);
    } else {
      setValue(0);
    }
  }, []);

  const createEvent = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      navigate('/club/new/create');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <Fragment>
      <Box px={1} py={0} sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="fullWidth"
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tab
            value={0}
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 0 ? '#38CD84' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Owner{' '}
                    {clubHostedList?.length > 0
                      ? '(' + clubHostedList?.length + ')'
                      : '(' + 0 + ')'}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 0 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </Box>
            }
          />
          <Tab
            value={1}
            label={
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 1 ? '#38CD84' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Pending{' '}
                    {clubInvitedList?.length > 0
                      ? '(' + clubInvitedList?.length + ')'
                      : '(' + 0 + ')'}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 1 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Stack>
              </Box>
            }
          />
          <Tab
            value={2}
            label={
              <Stack direction="row" spacing={0.5}>
                <Box>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 2 ? '#38CD84' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Requested{' '}
                    {clubRequestedList?.length > 0
                      ? '(' + clubRequestedList?.length + ')'
                      : '(' + 0 + ')'}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 2 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Box>
              </Stack>
            }
          />
          <Tab
            value={3}
            label={
              <Stack direction="row" spacing={0.5}>
                <Box>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}
                    sx={{
                      textTransform: 'none',
                      color: value === 3 ? '#38CD84' : '#ffffff',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Member{' '}
                    {savedClubList?.length > 0
                      ? '(' + savedClubList?.length + ')'
                      : '(' + 0 + ')'}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabdigit}
                    sx={{
                      color: value === 3 ? '#000000' : '#ffffff',
                    }}></Typography>
                </Box>
              </Stack>
            }
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid container sx={{ paddingBottom: '8px' }}>
            <Box sx={{ width: '100%' }}>
              <ClubsOwned />
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container sx={{ paddingBottom: '8px' }}>
            <Box sx={{ width: '100%' }}>
              <ClubsInvited />
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container>
            <Box sx={{ width: '100%' }}>
              <ClubsRequested />
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container>
            <Box sx={{ width: '100%' }}>
              <MyClubPassed />
            </Box>
          </Grid>
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default React.memo(Clubs);

// {designationList?.data
//   ? designationList?.data.map((item, key) => {
//       return (
//         <MenuItem key={key} value={item.title}>
//           {item.title}
//         </MenuItem>
//       );
//     })
//   : ""}
