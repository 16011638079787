import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CardLong from '../../components/CardLong/CardLong';
import CardClub from '../../components/CardLong/CardClub';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import { dateTime } from '../../constants/app';
import { useParams } from 'react-router-dom';
import ClubCard from '../../components/ClubCard/ClubCard';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubPosts = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const user = useSelector((state) => state?.user?.UserDetails);
  const clubDetailstoOpen = useSelector(
    (state) => state?.clubs?.clubDetailstoOpen,
  );
  const clubPostList = useSelector((state) => state?.clubs?.clubPostList);

  useEffect(async () => {
    // const data = {
    //   user_id: user?.id,
    //   club_id: clubDetailstoOpen?.id,
    // };
    // const clubdetails = await dispatch(clubsActions.clubDetails(data));
  }, []);

  useEffect(async () => {
    // if (clubDetailstoOpen?.club_id) {
    //   const id = params?.clubID?.split('_');
    //   const data = {
    //     user_id: user?.id,
    //     club_id: id[id?.length - 1].substring(3),
    //     datetime: dateTime(),
    //   };
    //   const clubdetails = await dispatch(clubsActions.clubPostList(data));
    // } else {
    //   const data = {
    //     user_id: user?.id,
    //     club_id: clubDetailstoOpen?.id,
    //     datetime: dateTime(),
    //   };
    //   const clubdetails = await dispatch(clubsActions.clubPostList(data));
    // }
  }, []);

  return (
    <Fragment>
      <Box>
        {clubPostList?.map((post, index) => (
          <Box key={index} pt={1} px={1}>
            <CardClub post={post} updatePost={props.fetchData} />
          </Box>
        ))}
      </Box>
    </Fragment>
  );
};

export default React.memo(ClubPosts);
