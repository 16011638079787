import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Header from '../../components/Header/Header';
import ProfileShort from '../../components/ProfileShort/ProfileShort';
import BottomNavigator from '../../components/BottomNavigator/BottomNavigator';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import HomeArticle from '../../containers/HomeContainer/HomeArticle';
import UnderVerification from '../../components/UnderVerification/UnderVerification';
import Profile from './Profile';
import EditProfile from './EditProfile';
import AddNew from './AddNew';
import CompanyCheck from './companyCheck';
import UpdateProfile from './UpdateProfile';
import LeftNav from '../../components/LeftNav/LeftNav';

const ProfileContainer = (props) => {
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const openAddnew = useSelector((state) => state?.user?.AddNew);
  const openUpdateProfile = useSelector((state) => state?.user?.UpdateProfile);
  const openCompany = useSelector((state) => state?.user?.openCompany);
  const underVerification = useSelector(
    (state) => state?.message?.underVerification,
  );
  return (
    <Fragment>
      <Helmet>
        <title>Profile || LinkCXO - Exclusive CXO Networking</title>
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
        <Header />
      </Box>
      {openSnackbar && <CustomizedSnackbars />}
      {openAddnew && <AddNew />}
      {openUpdateProfile && <UpdateProfile />}
      {underVerification && <UnderVerification />}
      {openCompany && <CompanyCheck />}
      <Box
        pt={8}
        sx={{
          display: { md: 'flex', xs: 'none' },
          justifyContent: 'center',
          height: 'calc(100vh - 65px )',
          width: '1280px',
          margin: 'auto',
        }}>
        <div class="wrapper_home">
          <aside>
            <Box
              py={1}
              sx={{ display: { md: 'block', xs: 'none' }, width: '100%  ' }}>
              <LeftNav />
            </Box>
          </aside>
          <main>
            <div class="wrapper_inner">
              <Box>
                <Profile />
              </Box>
            </div>
          </main>
        </div>
      </Box>

      <Box
        sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
        <Grid container sx={{ maxWidth: '1228px', justifyContent: 'center' }}>
          <Grid item xs={12} sm={10} md={12}>
            <Box px={1}>
              <Profile />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'fixed',
          bottom: '0%',
          width: '100%',
          zIndex: 2001,
        }}>
        <BottomNavigator />
      </Box>
    </Fragment>
  );
};

export default React.memo(ProfileContainer);
