import {
  POST_SEARCH_FULFILLED,
  POST_SEARCH_PENDING,
  POST_SEARCH_REJECTED,
} from './actions';
const defaultState = {
  SearchList: [],
  loading: true,
  errors: {},
  isComment: '',
  PostEdit: false,
  PollEdit: false,
};

export const searchreducer = (state = defaultState, action) => {
  switch (action.type) {
    //===================================post-list======================
    case POST_SEARCH_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case POST_SEARCH_FULFILLED: {
      return {
        ...state,
        SearchList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case POST_SEARCH_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    default:
      return state;
  }
};
