import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@mui/styles';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';
import Appr1 from '../../../resources/partner-image/resume.png';
// import Appr2 from '../../../resources/partner-image/Hospitality2.png';
const CarouselContainer = styled.div`
  .carousel .control-dots {
    display: none; /* Hide the dots */
  }
`;

const LeftDiv = styled.div`
  // background-color: lightblue;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#fff',
    height: 'auto',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  heading: {
    fontSize: '22px',
    color: '#38cd84',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  heading1: {
    fontSize: '30px',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  list: {
    fontSize: '16px',
    color: '#fff',
    paddingLeft: '20px',
    listStyleType: 'disc',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

const RealState = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const images = [Appr1];

  return (
    <>
      <Grid container spacing={2} pb={3} sx={{ px: { xs: 3, sm: 10 } }}>
        {isMobile ? (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <LeftDiv>
                <CarouselContainer>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    stopOnHover={true}>
                    {images.map((imageUrl, index) => (
                      <div key={index}>
                        <Box
                          component="img"
                          src={imageUrl}
                          alt={`Image ${index + 1}`}
                          sx={{
                            height: { xs: '200px', sm: '350px' },
                            width: 'auto',
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </CarouselContainer>
              </LeftDiv>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              position="relative"
              justifyContent="center"
              alignItems="center"
              display="flex">
              <div className={classes.container}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '20px',
                  }}>
                  {' '}
                  <a
                    href="/login"
                    style={{ textDecoration: 'none', color: '#38cd84' }}>
                    <p
                      style={{
                        color: '#38cd84',
                        fontSize: '20px',
                        fontWeight: 500,
                      }}>
                      See More
                    </p>
                  </a>
                </div>
                <h2 className={classes.heading1}>
                  Professional Profile Enhancement
                </h2>
                <h2 className={classes.heading}>
                  LinkCxO collaborates with professional profile enhancement
                  firms to provide our members:
                </h2>
                <ul className={classes.list}>
                  <li>Access expert resume building for a standout</li>
                  <li>CV Optimize your LinkedIn profile for career goals</li>
                  <li>Develop a unique personal brand</li>
                </ul>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              position="relative"
              justifyContent="center"
              alignItems="center"
              display="flex">
              <div className={classes.container}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '20px',
                  }}>
                  {' '}
                  <a
                    href="/login"
                    style={{ textDecoration: 'none', color: '#38cd84' }}>
                    <p
                      style={{
                        color: '#38cd84',
                        fontSize: '20px',
                        fontWeight: 500,
                      }}>
                      See More
                    </p>
                  </a>
                </div>
                <h2 className={classes.heading1}>
                  Professional Profile Enhancement
                </h2>
                <h2 className={classes.heading}>
                  LinkCxO collaborates with professional profile enhancement
                  firms to provide our members:
                </h2>
                <ul className={classes.list}>
                  <li>Access expert resume building for a standout CV</li>
                  <li>Optimize your LinkedIn profile for career goals</li>
                  <li>Develop a unique personal brand</li>
                </ul>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <LeftDiv>
                <CarouselContainer>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    stopOnHover={true}>
                    {images.map((imageUrl, index) => (
                      <div key={index}>
                        <Box
                          component="img"
                          src={imageUrl}
                          alt={`Image ${index + 1}`}
                          sx={{
                            width: '100%',
                            height: { xs: '200px', sm: '350px' },
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </CarouselContainer>
              </LeftDiv>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default RealState;
