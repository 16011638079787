import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import GetInTouch from '../containers/new-landing-page-section/PartnerPage';
import { HomePageContainer } from '../containers/HomeContainer';
import { AuthenticationContainer } from '../containers/AuthenticationContainer';
import { OnboardContainer } from '../containers/OnboardContainer';
import { RegisterAllContainer } from '../containers/RegisterAllContainer';
import { UserDetailsContainer } from '../containers/UserDetailsContainer';
import { AddInterestedContainer } from '../containers/InterestedContainer';
import { LinkedInRedirect } from '../containers/LinkedInRedirect';
import { NetworkContainer } from '../containers/NetworkContainer';
import { SettingsContainer } from '../containers/SettingsContainer';
import { MyactivityContainer } from '../containers/MyactivityContainer';
import { BookmarksContainer } from '../containers/BookmarksContainer';
import { SearchContainer } from '../containers/SearchContainer';
import { ProfileContainer } from '../containers/ProfileContainer';
import { EditProfileContainer } from '../containers/EditProfileContainer';
import { UserProfileContainer } from '../containers/UserProfileContainer';
import { UserProfileShareContainer } from '../containers/UserProfileShareContainer';
import { CandidateProfileContainer } from '../containers/CandidateProfileContainer';
import { NotificationsContainer } from '../containers/NotificationsContainer';
import { PrivacyPolicyContainer } from '../containers/PrivacyPolicyContainer';
import { JobsContainer } from '../containers/JobsContainer';
import { JobsContainerHire } from '../containers/JobsContainerHire';
import { JobsEditContainer } from '../containers/JobsEditContainer';
import { JobsCreateContainer } from '../containers/JobsCreateContainer';
import { EventCreateContainer } from '../containers/EventCreateContainer';
import { JobDetailsContainer } from '../containers/JobDetailsContainer';
import { EventDetailsContainer } from '../containers/EventDetailsContainer';
import { EventClubDetailsContainer } from '../containers/EventClubDetailsContainer';
import { EventEditContainer } from '../containers/EventEditContainer';
import { ClubEventEditContainer } from '../containers/ClubEventEditContainer';
import { EventsContainer } from '../containers/EventsContainer';
import { ClubDetailsContainer } from '../containers/ClubDetailsContainer';
import { ClubEditContainer } from '../containers/ClubEditContainer';
import { ClubCreateContainer } from '../containers/ClubCreateContainer';
import { JobPreferenceContainer } from '../containers/JobPreferenceContainer';
import { JobPreferenceEditContainer } from '../containers/JobPreferenceEditContainer';
import { EventsManageInvitationContainer } from '../containers/EventsManageInvitationContainer';
import { ClubManageMembersContainer } from '../containers/ClubManageMembersContainer';
import { HomeContainer } from '../containers/new-landing-page-section';
import { RewardsContainer } from '../containers/RewardsContainer';
import { RewardVoucherContainer } from '../containers/RewardVoucherContainer';
import { RewardsContainerVoucher } from '../containers/RewardsContainerVoucher';
import { RewardCategoryRealestateContainer } from '../containers/RewardCategoryRealestateContainer';
import { VoucherDetailsContainer } from '../containers/VoucherDetailsContainer';
import { RewardCreditsContainer } from '../containers/RewardCreditsContainer';
import { RewardMembershipContainer } from '../containers/RewardMembershipContainer';
import { RewardMembershipDetailsContainer } from '../containers/RewardMembershipDetailsContainer';
import { JobFilterContainer } from '../containers/JobFilterContainer';
import { JobSearchContainer } from '../containers/JobSearchContainer';
import { SurveyDetailsContainer } from '../containers/SurveyDetailsContainer';
import { QueryDetailsContainer } from '../containers/QueryDetailsContainer';
import { SurveyResponseDetailsContainer } from '../containers/SurveyResponseDetailsContainer';
import { ClubEventCreateContainer } from '../containers/ClubEventCreateContainer';
import { RewardCategoryContainer } from '../containers/RewardCategoryContainer';
import { CorporatePackagesContainer } from '../containers/CorporatePackagesContainer';
import { CorporateMembershipPackagesContainer } from '../containers/CorporateMembershipPackagesContainer';
import { CorporateMembershipContainer } from '../containers/CorporateMembershipContainer';
import { CorporateJobSlotContainer } from '../containers/CorporateJobSlotContainer';
import { CorporateMembershipGroupContainer } from '../containers/CorporateMembershipGroupContainer';
import { CorporatePackageDetailsContainer } from '../containers/CorporatePackageDetailsContainer';
import { CorporateMembershipDetailsContainer } from '../containers/CorporateMembershipDetailsContainer';
import { CorporatePartnersContainer } from '../containers/CorporatePartnersContainer';
import { CorporateVouchersContainer } from '../containers/CorporateVouchersContainer';
import { CorporateRewardsContainer } from '../containers/CorporateRewardsContainer';
import { CorporateCategoryRealestateContainer } from '../containers/CorporateCategoryRealestateContainer';
import { CorporateRewardVoucherContainer } from '../containers/CorporateRewardVoucherContainer';
import { CompanyPageCreateContainer } from '../containers/CompanyPageCreateContainer';
import { CompanyCorporatePackageDetailsContainer } from '../containers/CompanyCorporatePackageDetailsContainer';
import { CompanyManagePageContainer } from '../containers/CompanyManagePageContainer';
import { CompanyEditPageContainer } from '../containers/CompanyEditPageContainer';
import { CompanyManageAcessContainer } from '../containers/CompanyManageAcessContainer';
import { CompanyPageShareContainer } from '../containers/CompanyPageShareContainer';
import { CompanyCorporateMembershipGroupContainer } from '../containers/CompanyCorporateMembershipGroupContainer';
import { VideoApi } from '../containers/VideoApi';
import { ClubsContainer } from '../containers/ClubsContainer';
import { NewsContainer } from '../containers/NewsContainer';
import { CompanyDetailsPageContainer } from '../containers/CompanyDetailsPageContainer';
import { CompanyDetailsPageOtherContainer } from '../containers/CompanyDetailsPageOtherContainer';
import { SurveyOwnDetailsContainer } from '../containers/SurveyOwnDetailsContainer';
import { QueryOwnDetailsContainer } from '../containers/QueryOwnDetailsContainer';
import { CorporatePackageContainer } from '../containers/CorporatePackageContainer';
import { RewardVoucherDetailsContainer } from '../containers/RewardVoucherDetailsContainer';
import RewardPartnership from '../containers/new-landing-page-section/partnership/RewardPartnership';
import JobDetailsWrapper from '../containers/new-landing-page-section/JobDetailsWrapper';
import VenturesPage from '../containers/new-landing-page-section/VenturesPage';
import EventDetails from '../containers/new-landing-page-section/EventDetail';
import ApplyStartupWrapper from '../containers/new-landing-page-section/ApplyStartupWrapper';
import ApplyInvestorWrapper from '../containers/new-landing-page-section/ApplyInvestorWrapper';
import SuccessStartup from '../containers/new-landing-page-section/SuccessStartup';
import SuccessInvestor from '../containers/new-landing-page-section/SuccessInvestor';
import AdvInvest from '../containers/new-landing-page-section/AdvInvest';
import AdvRaiseFunds from '../containers/new-landing-page-section/AdvRaiseFunds';
import PrivacyPolicy1 from '../containers/new-landing-page-section/PrivacyPolicy';
import { VentureSignupContainer } from '../containers/VentureSignupContainer';
import AboutUs from '../containers/new-landing-page-section/About-us';
import PartnerBtoB from '../containers/new-landing-page-section/partnersB2B';
import PartnerEvent from '../containers/new-landing-page-section/partnerEvent';
import PartnerContent from '../containers/new-landing-page-section/partnerContent';
import PartnerBrand from '../containers/new-landing-page-section/partnerBrand';
import { useState } from 'react';
import QaForm from '../containers/Q&AForm/Q&AFrorm';
import { ReverificationDetailsContainer } from '../containers/ReverificationDetailsContainer';
import CreateForm from '../containers/Q&AForm/CreateForm';
import NotFound from '../containers/NotFound/NotFound';

const BasicRoutes = (props) => {
  const userdata = useSelector((state) => state.auth);
  const [rdirecturl, setRdirecturl] = useState('');
  console.log('BasicRoutes', window.location.href);
  var redirect_uri = window.location.href.split('linkcxo.com')[1];
  // let rduri = window.location.href.split('linkcxo.com')[1];
  useEffect(() => {
    setRdirecturl(window.location.href.split('linkcxo.com')[1]);
  }, []);

  if (redirect_uri?.length > 0) {
    // rduri = '?&redirect=' + redirect_uri;
    console.log('rdirecturl', rdirecturl);
  }

  // const redirectUrl = () => {
  //   var redirect_uri = window.location.href.split('linkcxo.com')[1];
  //   console.log('redirect_uri', redirect_uri);

  //   if (userdata?.loggedIn) {
  //     return <RewardMembershipDetailsContainer />;
  //   } else {
  //     return <Navigate to={'/login?&redirect=' + redirect_uri} />;
  //   }
  // };
  return (
    <Router>
      <Routes>
        <Route
          path="/feed"
          element={
            userdata.loggedIn ? <HomePageContainer /> : <Navigate to="/login" />
          }
        />
        <Route exact path="/home" element={<HomeContainer />} />
        <Route exact path="/login" element={<AuthenticationContainer />} />
        <Route exact path="/signup" element={<AuthenticationContainer />} />
        <Route path="/venture/signup" element={<VentureSignupContainer />} />
        <Route exact path="/addbio" element={<OnboardContainer />} />
        <Route exact path="/registerall" element={<RegisterAllContainer />} />
        <Route exact path="/addinterest" element={<AddInterestedContainer />} />
        <Route exact path="/user/details" element={<UserDetailsContainer />} />
        <Route exact path="/redirect-web" element={<LinkedInRedirect />} />
        <Route exact path="/questionnaire" element={<QaForm />} />

        <Route exact path="/create-questionnaire" element={<CreateForm />} />
        <Route
          exact
          path="/Privacy-Policy"
          element={<PrivacyPolicyContainer />}
        />
        <Route
          exact
          path="/terms-conditions"
          element={<PrivacyPolicyContainer />}
        />
        <Route
          path="/network"
          element={
            userdata.loggedIn ? (
              <NetworkContainer />
            ) : (
              <Navigate to="/login?&redirect=/network" />
            )
          }
        />

        <Route
          path="/network/pending"
          element={
            userdata.loggedIn ? (
              <NetworkContainer />
            ) : (
              <Navigate to="/login?&redirect=/network/pending" />
            )
          }
        />

        <Route
          path="/network/discover"
          element={
            userdata.loggedIn ? (
              <NetworkContainer />
            ) : (
              <Navigate to="/login?&redirect=/network/discover" />
            )
          }
        />

        <Route
          path="/network/mynetwork"
          element={
            userdata.loggedIn ? (
              <NetworkContainer />
            ) : (
              <Navigate to="/login?&redirect=/network/mynetwork" />
            )
          }
        />
        <Route
          path="/network/sent"
          element={
            userdata.loggedIn ? (
              <NetworkContainer />
            ) : (
              <Navigate to="/login?&redirect=/network/sent" />
            )
          }
        />

        <Route
          path="/network/existing"
          element={
            userdata.loggedIn ? (
              <NetworkContainer />
            ) : (
              <Navigate to="/login?&redirect=/network/existing" />
            )
          }
        />
        <Route
          path="/myactivity"
          element={
            userdata.loggedIn ? (
              <MyactivityContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/bookmarks"
          element={
            userdata.loggedIn ? (
              <BookmarksContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/profile"
          element={
            userdata.loggedIn ? (
              <ProfileContainer />
            ) : (
              <Navigate to="/login?&redirect=/profile" />
            )
          }
        />
        <Route
          path="/share/profile/:profile"
          element={
            userdata.loggedIn ? (
              <UserProfileShareContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/reverification/userdetails"
          element={
            userdata.loggedIn ? (
              <ReverificationDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/editprofile"
          element={
            userdata.loggedIn ? (
              <EditProfileContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/settings"
          element={
            userdata.loggedIn ? <SettingsContainer /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/job/edit"
          element={
            userdata.loggedIn ? <JobsEditContainer /> : <Navigate to="/login" />
          }
        />

        <Route
          path="/company/job/edit"
          element={
            userdata.loggedIn ? <JobsEditContainer /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/job/create"
          element={
            userdata.loggedIn ? (
              <JobsCreateContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/company/job/create"
          element={
            userdata.loggedIn ? (
              <JobsCreateContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/jobs/search"
          element={
            userdata.loggedIn ? (
              <JobsContainer />
            ) : (
              <Navigate to="/login?&redirect=/jobs/search" />
            )
          }
        />
        <Route
          path="/jobs/applied"
          element={
            userdata.loggedIn ? (
              <JobsContainer />
            ) : (
              <Navigate to="/login?&redirect=/jobs/applied" />
            )
          }
        />
        <Route
          path="/jobs/saved"
          element={
            userdata.loggedIn ? (
              <JobsContainer />
            ) : (
              <Navigate to="/login?&redirect=/jobs/saved" />
            )
          }
        />
        <Route
          path="/jobs/hire"
          element={
            userdata.loggedIn ? (
              <JobsContainerHire />
            ) : (
              <Navigate to="/login?&redirect=/jobs/hire" />
            )
          }
        />

        <Route
          path="/jobs/inactive"
          element={
            userdata.loggedIn ? (
              <JobsContainerHire />
            ) : (
              <Navigate to="/login?&redirect=/jobs/inactive" />
            )
          }
        />

        <Route
          path="/jobs/closed"
          element={
            userdata.loggedIn ? (
              <JobsContainerHire />
            ) : (
              <Navigate to="/login?&redirect=/jobs/closed" />
            )
          }
        />

        <Route
          path="/notifications"
          element={
            userdata.loggedIn ? (
              <NotificationsContainer />
            ) : (
              <Navigate to="/login?&redirect=/notifications" />
            )
          }
        />
        <Route
          path="/search/:keywords"
          element={
            userdata.loggedIn ? <SearchContainer /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/venture/signup"
          element={
            userdata.loggedIn ? <SearchContainer /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/user/:name"
          element={
            userdata.loggedIn ? (
              <UserProfileContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/cadidate/profile/:code"
          element={
            userdata.loggedIn ? (
              <CandidateProfileContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/"
          element={
            userdata.loggedIn ? <Navigate to="/feed" /> : <HomeContainer />
          }
        />
        <Route
          exact
          path="/aboutus"
          name="Aboutus Page"
          element={<AboutUs />}
        />
        <Route
          exact
          path="/ventures"
          name="Venture Page"
          element={<VenturesPage />}
        />
        <Route
          exact
          path="/ApplyStartup"
          name="Apply Startup"
          element={<ApplyStartupWrapper />}
        />
        <Route
          exact
          path="/ApplyInvestor"
          name="Apply Investor"
          element={<ApplyInvestorWrapper />}
        />
        <Route
          path="/job/details/:job"
          element={
            userdata.loggedIn ? (
              <JobDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/event/details/:eventID"
          element={
            userdata.loggedIn ? (
              <EventDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/club/event/details/:eventID"
          element={
            userdata.loggedIn ? (
              <EventClubDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/club/details/:clubID"
          element={
            userdata.loggedIn ? (
              <ClubDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/event/manage/invitation"
          element={
            userdata.loggedIn ? (
              <EventsManageInvitationContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/club/members"
          element={
            userdata.loggedIn ? (
              <ClubManageMembersContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/event/create"
          element={
            userdata.loggedIn ? (
              <EventCreateContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/event/create"
          element={
            userdata.loggedIn ? (
              <EventCreateContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/club/event/create"
          element={
            userdata.loggedIn ? (
              <ClubEventCreateContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/club/new/create"
          element={
            userdata.loggedIn ? (
              <ClubCreateContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/rewards/partners/:categoryId"
          element={
            userdata.loggedIn ? (
              <RewardsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/corporate/partners/category/:categoryId"
          element={
            userdata.loggedIn ? (
              <CorporateRewardsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/rewards/membership/details/:type"
          element={
            userdata.loggedIn ? (
              <RewardMembershipDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/rewards/membership"
          element={
            userdata.loggedIn ? (
              <RewardMembershipContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/rewards/voucher/details"
          element={
            userdata.loggedIn ? (
              <RewardVoucherDetailsContainer />
            ) : (
              // <RewardMembershipContainer />
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/packages"
          element={
            userdata.loggedIn ? (
              <CorporatePackagesContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/packagetab/:type"
          element={
            userdata.loggedIn ? (
              <CorporatePackageContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/partners"
          element={
            userdata.loggedIn ? (
              <CorporatePartnersContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/vouchers"
          element={
            userdata.loggedIn ? (
              <CorporateVouchersContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/packages/membership"
          element={
            userdata.loggedIn ? (
              <CorporateMembershipContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/company/corporate/packages/membership"
          element={
            userdata.loggedIn ? (
              <CorporateMembershipContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/company/corporate/packages/details/membership/:type"
          element={
            userdata.loggedIn ? (
              <CompanyCorporateMembershipGroupContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/company/corporate/packages/group/membership/:type"
          element={
            userdata.loggedIn ? (
              <CorporateMembershipGroupContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/packages/group/membership/:type"
          element={
            userdata.loggedIn ? (
              <CorporateMembershipGroupContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/package/details"
          element={
            userdata.loggedIn ? (
              <CorporatePackageDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/group/membership/details/:id"
          element={
            userdata.loggedIn ? (
              <CorporatePackageDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/company/corporate/group/membership/details/:id"
          element={
            userdata.loggedIn ? (
              <CompanyCorporatePackageDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/corporate/membership/details/:type"
          element={
            userdata.loggedIn ? (
              <CorporateMembershipDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/company/corporate/membership/details/:type"
          element={
            userdata.loggedIn ? (
              <CorporateMembershipDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/packages/jobslot"
          element={
            userdata.loggedIn ? (
              <CorporateJobSlotContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/corporate/Membership/packages"
          element={
            userdata.loggedIn ? (
              <CorporateMembershipPackagesContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/rewards/category/partners"
          element={
            userdata.loggedIn ? (
              <RewardCategoryContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/rewards/realestate/partners/:categoryId"
          element={
            userdata.loggedIn ? (
              <RewardCategoryRealestateContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/corporate/realestate/partners/:categoryId"
          element={
            userdata.loggedIn ? (
              <CorporateCategoryRealestateContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/job/searchresult"
          element={
            userdata.loggedIn ? (
              <JobSearchContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/job/filterresult"
          element={
            userdata.loggedIn ? (
              <JobFilterContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/rewards/my/vouchers"
          element={
            userdata.loggedIn ? (
              <RewardsContainerVoucher />
            ) : (
              <Navigate to="/login?&redirect=/rewards/my/vouchers" />
            )
          }
        />
        <Route
          path="/rewards/credits/:actions"
          element={
            userdata.loggedIn ? (
              <RewardCreditsContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/rewards/credits"
          element={
            userdata.loggedIn ? (
              <RewardCreditsContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/rewards/voucher/:company"
          element={
            userdata.loggedIn ? (
              <RewardVoucherContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/corporate/rwards/voucher/:company"
          element={
            userdata?.loggedIn ? (
              <CorporateRewardVoucherContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/voucher/rewards/details"
          element={
            userdata.loggedIn ? (
              <VoucherDetailsContainer />
            ) : (
              <Navigate to="/login?&redirect=/voucher/details" />
            )
          }
        />
        <Route
          path="/event/edit"
          element={
            userdata.loggedIn ? (
              <EventEditContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/club/event/edit"
          element={
            userdata.loggedIn ? (
              <ClubEventEditContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/events/discover"
          element={
            userdata.loggedIn ? (
              <EventsContainer />
            ) : (
              <Navigate to="/login?&redirect=/events/discover" />
            )
          }
        />
        <Route
          path="/events/hosting"
          element={
            userdata.loggedIn ? (
              <EventsContainer />
            ) : (
              <Navigate to="/login?&redirect=/events/hosting" />
            )
          }
        />

        <Route
          path="/events/pending"
          element={
            userdata.loggedIn ? (
              <EventsContainer />
            ) : (
              <Navigate to="/login?&redirect=/events/pending" />
            )
          }
        />
        <Route
          path="/events/participating"
          element={
            userdata.loggedIn ? (
              <EventsContainer />
            ) : (
              <Navigate to="/login?&redirect=/events/participating" />
            )
          }
        />
        <Route
          path="/company/manage/aceess/:id"
          element={
            userdata.loggedIn ? (
              <CompanyManageAcessContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/edit/job/preferences"
          element={
            userdata.loggedIn ? (
              <JobPreferenceEditContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/job/preference"
          element={
            userdata.loggedIn ? (
              <JobPreferenceContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/JobDetails/:job"
          name="Job details"
          element={<JobDetailsWrapper />}
        />
        <Route
          path="/eventDetails/:event"
          name="Event details"
          element={<EventDetails />}
        />
        <Route
          path="/news"
          element={
            userdata.loggedIn ? (
              <NewsContainer />
            ) : (
              <Navigate to="/login?&redirect=/news" />
            )
          }
        />
        <Route
          path="/clubs"
          element={
            userdata.loggedIn ? (
              <ClubsContainer />
            ) : (
              <Navigate to="/login?&redirect=/clubs" />
            )
          }
        />

        <Route
          path="/clubs/owner"
          element={
            userdata.loggedIn ? (
              <ClubsContainer />
            ) : (
              <Navigate to="/login?&redirect=/clubs/owner" />
            )
          }
        />

        <Route
          path="/clubs/discover"
          element={
            userdata.loggedIn ? (
              <ClubsContainer />
            ) : (
              <Navigate to="/login?&redirect=/clubs/discover" />
            )
          }
        />

        <Route
          path="/clubs/requested"
          element={
            userdata.loggedIn ? (
              <ClubsContainer />
            ) : (
              <Navigate to="/login?&redirect=/clubs/requested" />
            )
          }
        />

        <Route
          path="/clubs/pending"
          element={
            userdata.loggedIn ? (
              <ClubsContainer />
            ) : (
              <Navigate to="/login?&redirect=/clubs/pending" />
            )
          }
        />

        <Route
          path="/clubs/member"
          element={
            userdata.loggedIn ? <ClubsContainer /> : <Navigate to="/login" />
          }
        />

        <Route
          path="/club/edit"
          element={
            userdata.loggedIn ? <ClubEditContainer /> : <Navigate to="/login" />
          }
        />

        <Route
          path="/company/create"
          element={
            userdata.loggedIn ? (
              <CompanyPageCreateContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/company/manage/page"
          element={
            userdata?.loggedIn ? (
              <CompanyManagePageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/survey/:id"
          element={
            userdata?.loggedIn ? (
              <SurveyDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/query/:id"
          element={
            userdata?.loggedIn ? (
              <QueryDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/response/survey/:id"
          element={
            userdata?.loggedIn ? (
              <SurveyResponseDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/response/query/:id"
          element={
            userdata?.loggedIn ? (
              <SurveyResponseDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/my-survey"
          element={
            userdata?.loggedIn ? (
              <SurveyOwnDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/my-query"
          element={
            userdata?.loggedIn ? (
              <QueryOwnDetailsContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />

        <Route
          path="/company/manage/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/manage/posts/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/manage/jobs/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/manage/membership/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/manage/events/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/job/manage/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/events/manage/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/rewards/manage/details/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/edit/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyEditPageContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/share/company/:id"
          element={
            userdata.loggedIn ? (
              <CompanyPageShareContainer />
            ) : (
              <Navigate to={'/login?&redirect=/' + props?.rout} />
            )
          }
        />
        <Route
          path="/company/page/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageOtherContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/page/posts/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageOtherContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/page/events/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageOtherContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/company/page/jobs/:id"
          element={
            userdata?.loggedIn ? (
              <CompanyDetailsPageOtherContainer />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route path="/mobile_video" element={<VideoApi />} />
        <Route
          exact
          path="/SuccessStartup"
          name="Success Startup"
          element={<SuccessStartup />}
        />
        <Route
          exact
          path="/SuccessInvestor"
          name="Success Investor"
          element={<SuccessInvestor />}
        />
        <Route
          exact
          path="/PrivacyPolicy"
          name="privacy polivi1"
          element={<PrivacyPolicy1 />}
        />
        <Route
          exact
          path="/AdvRaiseFunds"
          name="Raise Funds"
          element={<AdvRaiseFunds />}
        />
        <Route
          exact
          path="/PartnerPage"
          name="partnerPage"
          element={<GetInTouch />}
        />
        <Route
          exact
          path="/PartnerB2B"
          name="partnerB2B"
          element={<PartnerBtoB />}
        />
        {/* <Route exact path="/benefits" name="benefits" element={<Benefits />} /> */}
        <Route
          exact
          path="/PartnerContent"
          name="partnerContent"
          element={<PartnerContent />}
        />
        <Route
          exact
          path="/PartnerBrand"
          name="partnerBrand"
          element={<PartnerBrand />}
        />
        <Route
          exact
          path="/PartnerRewards/:type"
          name="partnerBrand"
          element={<PartnerBrand />}
        />
        <Route
          exact
          path="/PartnerEvent"
          name="partnerEvent"
          element={<PartnerEvent />}
        />

        <Route
          exact
          path="/reward-partnership"
          element={<RewardPartnership />}
        />

        <Route
          exact
          path="/corporate"
          name="corporateB2B"
          element={<PartnerBtoB />}
        />

        <Route exact path="/AdvInvest" name="Invest" element={<AdvInvest />} />
        <Route exact path="/jobs" name="job" element={<JobsContainer />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default BasicRoutes;
