import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ReactPlayer from 'react-player';
import VisibilitySensor from 'react-visibility-sensor';
export default function VideoLong(props) {
  const [play, setPlay] = React.useState(false);
  const playIn = () => setPlay(true);
  const pause = () => setPlay(false);
  const onChangeVisible = (isVisible) => {
    if (!isVisible) {
      setPlay(false);
    }
  };
  return (
    <Fragment>
      <VisibilitySensor onChange={onChangeVisible}>
        <ReactPlayer
          height="auto"
          width=""
          className="react-player"
          url={props?.post?.image}
          onReady={() => console.log('onReady')}
          onStart={() => {
            setPlay(true);
          }}
          onPlay={() => {
            setPlay(true);
          }}
          playing={play}
          lite={true}
          controls={true}
          muted={true}
        />
      </VisibilitySensor>
    </Fragment>
  );
}
