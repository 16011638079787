import React, { useState, useEffect } from 'react';
import BasicRoutes from './routes';
import theme from './theme';
import './App.css';
import { Provider } from 'react-redux';
import configureStore from './store';
import { ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import config from './services/apiConfig';
const { persistor, store } = configureStore();

function App() {
  let { tab } = useParams();
  const [rdirecturl, setRdirecturl] = useState('');

  var redirect_uri = window.location.href.split('localhost:3000')[1];

  let rduri = window.location.href.split('localhost:3000')[1];
  useEffect(() => {
    setRdirecturl(window.location.href.split('linkcxo.com')[1]);
    console.log('setRdirecturl', rdirecturl);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BasicRoutes rout={window.location.href.split(config?.baseURL)[1]} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
