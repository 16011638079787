import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import RewardLinkcxoCard from '../../components/RewardLinkcxoCard/RewardLinkcxoCard';
import RewardLinkcxoCategory from '../../components/RewardLinkcxoCategory/RewardLinkcxoCategory';
import * as rewardsActions from '../../store/rewards';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const LinkcxoRewards = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const linkcxoRewardList = useSelector(
    (state) => state?.rewards?.linkcxoRewardList,
  );

  const rewardsMembershipTypeList = useSelector(
    (state) => state?.rewards?.rewardsMembershipTypeList,
  );
  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  useEffect(async () => {
    const data = {
      category_id: 0,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.linkcxoRewardList(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      category_id: 0,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsMembershipTypeList(data),
    );
  }, []);
  useEffect(async () => {
    const rewardsCategory = await dispatch(
      rewardsActions.rewardsCategoryList(),
    );
  }, []);

  return (
    <Fragment>
      <Box>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Grid container>
              {linkcxoRewardList?.length > 0 &&
                linkcxoRewardList?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <RewardLinkcxoCard reward={list} type="own" />
                    </Grid>
                  );
                })}

              {rewardsMembershipTypeList?.length > 0 &&
                rewardsMembershipTypeList?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <RewardLinkcxoCard reward={list} type="own" />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(LinkcxoRewards);
