import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import Select from '@mui/material/Select';
import { useForm, Controller } from 'react-hook-form';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Footer from '../../components/HomeHeader/footer';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import CxoButton from '../../components/CxoButton/CxoButton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const QaForm = () => {
  const [formData, setFormData] = useState([]);
  // Store the selected status for each dropdown

  const [selectedStatuses, setSelectedStatuses] = useState({});

  // Handle status change for each dropdown
  const handleStatusChange = (e, questionId) => {
    setSelectedStatuses((prevSelectedStatuses) => ({
      ...prevSelectedStatuses,
      [questionId]: e.target.value,
    }));
  };
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    const data = [
      {
        _id: '616f7a9fec05193a80423763',
        name: 'Call-Disposition',
        questions: [
          {
            answers: ['Confirmed', 'Delayed', 'Rescheduled', 'Canceled', 'RNR'],
            _id: '616f7a9fec05194e57423764',
            questionType: 'drop-down',
            question: 'Status',
          },
        ],
      },
      {
        _id: '616f7be2571ec33957ce2065',
        name: 'Pre-Service Checklist',
        questions: [
          {
            answers: [
              'Less than 5 months',
              'More than 5 months',
              'Does not remember',
            ],
            _id: '616f7be2571ec36518ce2066',
            questionType: 'drop-down',
            question: 'Last Service Month',
          },
          {
            answers: ['Running', 'Idle'],
            _id: '616f7be2571ec30cb9ce2067',
            questionType: 'drop-down',
            question: 'Vehicle Status',
          },
          {
            answers: [],
            _id: '616f7be2571ec33819ce2068',
            questionType: 'text',
            question: 'Any specific requirements?',
          },
          {
            answers: ['Yes', 'No'],
            _id: '616f7be2571ec33819ce2070',
            questionType: 'select',
            question: 'Satisfaction',
          },
          // {
          //   _id: '616f7be2571ec33819ce2072',
          //   questionType: 'attachment',
          //   question: 'Upload Document',
          // },
        ],
      },
    ];
    const allQuestions = data.flatMap((obj) => obj.questions);
    setFormData(allQuestions);
  }, []);

  const handleSubmitForm = (data) => {
    console.log('Form Data:', data);
  };

  return (
    <>
      <Navbar />
      <Box
        pt={15}
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          {formData.map((question) => (
            <div key={question._id}>
              {question.questionType === 'drop-down' && (
                <Box mt={2} sx={{ width: '800px' }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel
                      style={{
                        color: '#38CD84',
                        fontSize: '13px',
                      }}
                      id={`question-label-${question._id}`}>
                      {question.question}
                    </InputLabel>
                    <Controller
                      control={control}
                      name={question._id}
                      render={({ field }) => (
                        <Select
                          {...field}
                          MenuProps={MenuProps}
                          label={question.question}
                          labelId={`question-label-${question._id}`}
                          value={selectedStatuses[question._id] || ''}
                          onChange={(e) => handleStatusChange(e, question._id)}
                          size="small">
                          {question.answers.map((answer) => (
                            <MenuItem key={answer} value={answer}>
                              {answer}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>
              )}
              {question.questionType === 'text' && (
                <Box mt={1.1} sx={{ width: '800px' }} ml={-1}>
                  <Controller
                    control={control}
                    name={question._id}
                    render={({ field }) => (
                      <CxoInputCss
                        label={question.question}
                        {...field}
                        value={field.value || ''}
                      />
                    )}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Box>
              )}
              {question.questionType === 'select' && (
                <Box mt={2} sx={{ width: '800px' }}>
                  <FormControl>
                    <FormLabel
                      color="secondary"
                      id={`question-label-${question._id}`}
                      sx={{
                        color: '#38CD84',
                        fontSize: '13px',
                        '@media (max-width: 500px)': {
                          fontSize: '13px',
                          marginTop: '10px',
                        },
                      }}>
                      {question.question}:
                    </FormLabel>
                    <Controller
                      control={control}
                      name={question._id}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          aria-labelledby={`question-label-${question._id}`}
                          color="secondary"
                          {...field}>
                          {question.answers.map((answer) => (
                            <FormControlLabel
                              key={answer}
                              value={answer}
                              color="secondary"
                              control={<Radio size="small" color="secondary" />}
                              label={answer}
                            />
                          ))}
                        </RadioGroup>
                      )}
                      rules={{ required: true }}
                      defaultValue=""
                    />
                  </FormControl>
                </Box>
              )}
              {/* {question.questionType === 'attachment' && (
                <Box mt={2} sx={{ width: '800px' }}>
                  <Typography variant="h6" component="h6" color="secondary">
                    {question.question}
                  </Typography>
                  <Stack direction="row">
                    <Box ml={-0.5}>
                      <Controller
                        control={control}
                        name={`attachment-${question._id}`}
                        render={({ field }) => (
                          <label htmlFor={`file-input-${question._id}`}>
                            <Box
                              py={2}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <AttachFileOutlinedIcon color="secondary" />
                            </Box>
                            <input
                              id={`file-input-${question._id}`}
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => field.onChange(e.target.files[0])}
                            />
                          </label>
                        )}
                      />
                    </Box>
                    <Box mt={2.5}>
                      <Typography variant="h6" component="h6">
                        No file attached
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              )} */}
            </div>
          ))}

          <Box
            display="flex"
            justifyContent="center"
            sx={{
              paddingTop: '16px',
              paddingBottom: '16px',
            }}>
            {' '}
            <CxoButton label="Submit" type="submit" />
          </Box>
        </form>
      </Box>
      <Footer />
    </>
  );
};

export default QaForm;
