import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Taj from '../../../resources/PartnerLogos/Taj-01.png';
import Pvr from '../../../resources/PartnerLogos/PVR-01.png';
// import Prime from '../../../resources/PartnerLogos/amazon-prime-logo-0.png';
import Itc from '../../../resources/PartnerLogos/ITCs-Hotel-Group.png';

const Entertainment = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateNextTwoTone = (id, name) => {
    navigate('/rewards/voucher/' + name + 'lx_' + id);
  };

  return (
    <>
      <Stack
        direction={'column'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          backgroundColor: '#262525',
        }}>
        <Box
          sx={{
            paddingTop: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            paddingBottom: '36px',
          }}>
          <h2 className="partner-heading">
          Unwind in style{' '}
          </h2>
        </Box>
        <Stack direction={'row'} spacing={8}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('53', 'Pvr')}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Pvr} height="80px" width="auto" />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('57', 'Taj')}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Taj} height="120px" width="auto" />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Itc} height="120px" width="auto" />
            </Box>
          </Box>

          {/* <Box>
            <Box
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Prime} height="120px" width="auto" />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Itc} height="120px" width="auto" />
            </Box>
          </Box> */}
        </Stack>
        <Box pt={8}>
          <h2 className="partner-heading">
            Exclusive Benefits For LinkCxO Members:
          </h2>
        </Box>
        <Box sx={{ width: '100%', padding: '40px 180px' }}>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 10 Lakh on Premium Real Estate
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 4 Lakh on Premium Automobiles
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Exclusive offers on Entertainment and Hospitality
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Offers and Discounts on Premium Apparel and Accessory Brands
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Discount on Purchases of Electronics from India’s Leading Big Box
            Brands
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Health Package Discounts from one of India’s Leading Digital
            Healthcare Platform
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Opportunity to Invest in Hand Picked Startups to start your Angel
            Investing Journey with exclusive Discounts
          </p>
        </Box>
      </Stack>
    </>
  );
};

export default Entertainment;
