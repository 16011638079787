import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';

import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const EditAffilation = (props) => {
  console.log('props', props);
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [file, setFile] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(dayjs(new Date()));
  const [enddatevalue, setendDatevalue] = React.useState(dayjs(new Date()));
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };
  const handleenddateChange = (newValue) => {
    setendDatevalue(newValue);
  };
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);
  console.log('userdetails', userdetails?.dob);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));
  useEffect(() => {
    if (props?.section?.start_date) {
      var dateMomentObject = dayjs(props?.section?.start_date);
      
      setDatevalue(dateMomentObject);
    }
  }, []);

  useEffect(() => {
    if (props?.section?.end_date) {
      var dateMomentObject = dayjs(props?.section?.end_date);
      setendDatevalue(dateMomentObject);
    }
  }, []);

  const handleChange = (newValue) => {
    setType(newValue);
  };

  const closeEdit = () => {};

  const handleSubmitform = async (data) => {
    console.log('data', data);

    const usereaffi = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      entity_name: data?.entity_name,
      role: data?.role,
      start_date: dayjs(datevalue).format('YYYY-MM-DD'),
      end_date: dayjs(enddatevalue).format('YYYY-MM-DD'),
      still_active: 0,
      details: data?.details,
    };
    const usereducation = await dispatch(
      userActions.manageAffilation(usereaffi),
    );
    console.log('usereducation', usereducation);
    const userdata = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(userdata));
    if (updateSection) {
      const snackbar = await dispatch(userActions.closeUpdateSectionProfile());
    }
    if (addNew) {
      const snackbaradd = await dispatch(userActions.closeUpdateProfile());
    }
  };
  return (
    <Fragment>
      <Box px={3} py={0} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center  ',
              width: '100%',
            }}>
            <form
              onSubmit={handleSubmit(handleSubmitform)}
              style={{
                width: '100%',
              }}>
              <Box py={1} pr={1} sx={{ textAlign: 'center', width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Name"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="entity_name"
                    rules={{
                      required: {
                        value: true,
                        message: 'This is required',
                      },
                    }}
                    defaultValue={
                      props?.section?.entity_name
                        ? props?.section?.entity_name
                        : ''
                    }
                  />
                </Stack>
              </Box>

              <Box sx={{ width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Role"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="role"
                    rules={{
                      required: {
                        value: true,
                        message: 'This is required',
                      },
                    }}
                    defaultValue={
                      props?.section?.role ? props?.section?.role : ''
                    }
                  />
                </Stack>
              </Box>

              <Box py={1} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="Start Date" 
                            value={datevalue}
                            onChange={handledateChange}               
                          />
                        </DemoContainer>
                    </LocalizationProvider>
                </Stack>
              </Box>

              <Box py={1} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="End Date" 
                            value={enddatevalue}
                            onChange={handleenddateChange}               
                          />
                        </DemoContainer>
                    </LocalizationProvider>
                </Stack>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CssTextField
                        variant="standard"
                        fullWidth
                        multiline
                        rows={3}
                        maxRows={4}
                        value={value}
                        onChange={onChange}
                        color="secondary"
                        label="Details"
                        sx={{
                          scrollbarWidth: 'none',
                        }}
                      />
                    )}
                    name="details"
                    defaultValue={
                      props?.section?.details ? props?.section?.details : ''
                    }
                  />
                </Stack>
              </Box>

              <Box
                py={4}
                px={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CxoButton label="Add" type="submit" />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EditAffilation);
