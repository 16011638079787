import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import * as messageActions from "../../store/message";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
  const dispatch = useDispatch();

  const open = useSelector((state) => state?.message?.openSnackbar);
  const variant = useSelector((state) => state?.message?.variant);
  const message = useSelector((state) => state?.message?.message);
  console.log('message', message);

  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const snackbar = await dispatch(messageActions.closeSnackbar());
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar anchorOrigin={{ vertical:'bottom' ,horizontal :'center' }} open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={variant || "success"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
