import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate,  useSearchParams } from 'react-router-dom';
import { Paper,  Box, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import * as rewardsActions from '../../store/rewards';
import StarIcon from '@mui/icons-material/Star';
import * as messageActions from '../../store/message';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Membership from './Membership';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));



const CorporateMembership = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [queryParameters] = useSearchParams();
  const [showpopup, setShowpopup] = React.useState(false);

  const user = useSelector((state) => state?.user?.UserDetails);

 useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

 useSelector((state) => state?.rewards?.membershipList);

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );
  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

 useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  console.log(
    'rewardsPartnerVoucher',
    rewardsPartnerVoucher,
    rewardsVoucherList,
  );

  useEffect(() => {
    async function fetchData() {
    await dispatch(
        rewardsActions.rewardsCategoryList(),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log('useEffect', queryParameters.get('visit'));
    if (queryParameters.get('visit') == 'new') {
      setShowpopup(true);
    } else {
      setShowpopup(false);
    }
  }, []);

  useEffect(() => {
    async function fetchDetails() {
      const data = {
        membership_id: 2,
      };
     await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
       await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
    }
    fetchData();
  }, []);

  const handleChange = async (event, newValue) => {
    console.log('handleChange', event, newValue);

    if (user?.isVerified) {
      if (newValue === 1) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/my/vouchers');
        setValue(newValue);
      } else if (newValue === 0) {
        navigate('/rewards/membership');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };



  const handleClose = () => {
    setShowpopup(false);
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 1) {
        navigate('/rewards/category/partners');
        setValue(val);
      } else if (val === 2) {
        navigate('/rewards/my/vouchers');
        setValue(val);
      } else if (val === 0) {
        navigate('/rewards/membership');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const subscriptionType = () => {
    if (user?.membership_type === 0) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 1) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 2) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#C0C0C0' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#C0C0C0',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Silver
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 3) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#FFC107' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#FFC107',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Gold
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 4) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#A0B2C6' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#A0B2C6',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Platinum
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    }
  };

  const creditsDetails = () => {
    navigate('/rewards/credits');
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Membership
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Vouchers
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {showpopup && (
          <Dialog
            open={showpopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Stack direction="column" spacing={2}>
                  <Typography variant="h5s" component="h5s">
                    Congratulations on joining the exclusive committee of CxOs!!
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.tabname}>
                    As an early bird, you now have access to complimentary deals
                    and best offers designed especially for you.
                  </Typography>
                  <Typography
                    variant="h5s"
                    component="h5s"
                    className={classes.tabname}>
                    Happy Recce.
                  </Typography>
                </Stack>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Explore more.</Button>
            </DialogActions>
          </Dialog>
        )}
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Packages
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Vouchers
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}>
                <Box
                  py={3}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant="h5s" component="h5s">
                    Memberships Packages
                  </Typography>
                </Box>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '13px',
                      },
                    }}></Typography>
                </Box>
              </Stack>
            </Paper>
            <Fragment>
              <Box>
                <Membership />
                {/* <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      <Grid
                        key={0}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: 0 % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <CorporatePackageCard name="Corporate Membership" />
                      </Grid>
                      <Grid
                        key={0}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: 1 % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <CorporatePackageCard icon="jobs" name="Job Slots" />
                      </Grid>
                      <Grid
                        key={0}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: 2 % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <CorporatePackageCard
                          icon="Advertise"
                          name="Advertisements"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box> */}
              </Box>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(CorporateMembership);

// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab>
