import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NetworkCardSkeleton from '../../components/Skeleton/NetworkCardSkeleton';
import * as usernetworkActions from '../../store/usernetwork';
import SearchIcon from '@mui/icons-material/Search';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchBox from './SearchBox';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import CxoButton from '../../components/CxoButton/CxoButton';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Backdrop from '@mui/material/Backdrop';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Menuicon from '../../assets/menuicon';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import * as onboardActions from '../../store/onboard';
import CxoInput from '../../components/CxoInput/CxoInput';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const NetworkDiscover = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofDiscover, setNoofDiscover] = React.useState(4);
  const [showSearch, setShowSearch] = React.useState(true);
  const [searchTxt, setSearchTxt] = React.useState('');
  const [pageno, setPageno] = React.useState(0);
  const [searchbox, setSearchbox] = React.useState(false);
  const [showfilter, setShowfilter] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [industrylst, setIndustrylst] = useState();
  const [functionlst, setFunctionlst] = useState();
  const [designationlst, setDesignationlst] = useState();
  const designationList = useSelector((state) => state.onboard.designationList);
  const user = useSelector((state) => state?.user?.UserDetails);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const interestlist = useSelector((state) => state.onboard.industryList);
  const discoverlist = useSelector(
    (state) => state?.usernetwork?.userdiscoverList,
  );

  const discoverLoading = useSelector(
    (state) => state?.usernetwork?.discoverLoading,
  );
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(async () => {
    const designationlist = await dispatch(onboardActions.getDesignationList());
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        user_id: user?.id,
      };
      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(data, 'new'),
      );
    }
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const seeMore = () => {
    if (discoverlist?.length > noofDiscover) {
      setNoofDiscover(noofDiscover + 4);
    }
  };

  useEffect(async () => {
    const interestlist = await dispatch(onboardActions.getUserInterestList());
  }, []);

  useEffect(async () => {
    const designationlist = await dispatch(onboardActions.getDesignationList());
  }, []);
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);

  const searchNetwork = async (key) => {
    const data = {
      user_id: user?.id,
      search_string: key,
      page_no: 0,
    };
    const userList = await dispatch(
      usernetworkActions.getMyNetworkList(data, 'new'),
    );
  };

  const seeAllnetwork = async () => {
    setSearchTxt('');
    setPageno(0);
    const data = {
      user_id: user?.id,
      page_no: 0,
    };
    const userList = await dispatch(
      usernetworkActions.getMyNetworkList(data, 'new'),
    );
  };

  const designatioChange = (event) => {
    setDesignationlst(event.target.value);
  };
  const seeLess = () => {
    if (discoverlist?.length === noofDiscover) {
      setNoofDiscover(4);
    }
  };
  const seeAll = () => {
    if (discoverlist?.length != noofDiscover) {
      setNoofDiscover(discoverlist?.length);
    }
  };

  const handleChangeIdustry = (event) => {
    console.log('event', event);
    const {
      target: { value },
    } = event;
    setIndustrylst(value);
  };

  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(value);
  };

  const resetFields = (e) => {
    e.stopPropagation();
    setIndustrylst('');
    setFunctionlst('');
    reset();
  };

  const seeMoreNetwork = async () => {
    if (props?.searchTxt.length > 0) {
      setPageno(pageno + 1);
      const data = {
        user_id: user?.id,
        search_string: props?.searchTxt,
        page_no: pageno + 1,
      };
      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(data, 'more'),
      );
    } else {
      setPageno(pageno + 1);
      const data = {
        user_id: user?.id,
        search_string: searchTxt,
        page_no: pageno + 1,
      };
      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(data, 'more'),
      );
    }
  };
  const toogleSearch = () => {
    setShowSearch(!showSearch);
  };
  const updateTxt = (event) => {
    setSearchTxt(event.target.value);
  };

  const showFilter = () => {
    setShowfilter(!showfilter);
  };

  const handleKeyDown = async (e) => {
    if (e.keyCode === 13 && searchTxt.length > 0) {
      setPageno(0);
      const data = {
        user_id: user?.id,
        search_string: searchTxt,
        page_no: 0,
      };
      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(data, 'new'),
      );
    }
  };
  const searchAll = async () => {
    if (searchTxt.length > 0) {
      setPageno(0);
      const data = {
        user_id: user?.id,
        search_string: searchTxt,
        page_no: 0,
      };
      const userList = await dispatch(
        usernetworkActions.getMyNetworkList(data, 'new'),
      );
    }
  };

  const handleSubmitform = async (data, e) => {
    let dsgn = [];
    let indst = [];
    let funct = [];
    let loctn = [];
    let compny = [];
    if (designationlst?.length > 0) {
      dsgn.push(designationlst);
    }
    if (industrylst?.length > 0) {
      indst.push(industrylst);
    }

    if (functionlst?.length > 0) {
      funct.push(functionlst);
    }

    if (data?.location?.length > 0) {
      loctn.push(data?.location);
    }
    if (data?.company_name?.length > 0) {
      compny.push(data?.company_name);
    }
    const dataSearch = {
      company: data?.company_name,
      aDesignation: designationlst,
      aLocation: data?.location,
      aIndustry: industrylst ? industrylst : '',
      aFunction: functionlst ? functionlst : '',
      aInterest: '',
      user_id: user?.id,
    };

    const filter = await dispatch(
      usernetworkActions.getNetworkFilter(dataSearch),
    );
    return props?.showFilter();
    // setShowfilter(!showfilter);
  };

  const applySearch = async () => {};

  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          position: 'fixed',
          zIndex: '1001',
        }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: '2001' }}
          open={props?.showfilter}>
          <Box px={0}>
            <Box pt={2} sx={{ width: '100%' }}>
              <Paper
                sx={{
                  backgroundColor: '#232121',
                  backgroundImage: 'none',
                  overflowY: 'auto',
                }}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={0}>
                  <Box pt={2} pr={2}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                      }}
                      onClick={props?.showFilter}
                    />
                  </Box>
                </Stack>

                <form onSubmit={(e) => handleSubmit(handleSubmitform, e)}>
                  <Box px={3} sx={{ width: '95%' }}>
                    <Stack spacing={2} direction="row">
                      <Box
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="designation" color="#38CD84" />
                          </Box>
                        </Box>
                      </Box>
                      <Box py={1} pl={1} sx={{ width: '96%' }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Designation
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={designationlst}
                            label="Industry List"
                            onChange={designatioChange}
                            size="small"
                            MenuProps={MenuProps}>
                            {designationList?.data
                              ? designationList?.data.map((item, key) => {
                                  return (
                                    <MenuItem key={key} value={item.title}>
                                      {item.title}
                                    </MenuItem>
                                  );
                                })
                              : ''}
                          </Select>
                        </FormControl>
                      </Box>
                    </Stack>
                  </Box>
                  <Box px={3} sx={{ width: '95%' }}>
                    <Stack spacing={2} direction="row">
                      <Box
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '24px',
                            maxWidth: 'min-content',
                            minWidth: '24px',
                          }}>
                          <Menuicon name="entity" color="#38CD84" />
                        </Box>
                      </Box>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack direction="column" sx={{ width: '100%' }}>
                            <CxoInput
                              label="Company"
                              value={value}
                              err={
                                errors?.company_name
                                  ? errors?.company_name
                                  : false
                              }
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: '9px',
                                height: '10px',
                                paddingLeft: '8px',
                              }}>
                              {errors?.company_name?.message}{' '}
                            </Box>
                          </Stack>
                        )}
                        name="company_name"
                        rules={{
                          required: {
                            value: false,
                            message: 'Company name is required',
                          },
                        }}
                        defaultValue=""
                      />
                    </Stack>
                  </Box>
                  <Box px={4} pt={0} sx={{ width: '100%' }}>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={0}>
                      <Stack spacing={1} direction="row" sx={{ width: '98%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="location" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={{ width: '100%' }}>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Stack
                                sx={{
                                  '@media (max-width: 500px)': {
                                    paddingRight: '10px',
                                  },
                                }}>
                                <CxoInputCss
                                  label="Location"
                                  value={value}
                                  err={
                                    errors?.location ? errors?.location : false
                                  }
                                  onChange={onChange}
                                />
                                <Box
                                  sx={{
                                    fontSize: '9px',
                                    height: '10px',
                                    paddingLeft: '8px',
                                  }}>
                                  {errors?.location?.message}{' '}
                                </Box>
                              </Stack>
                            )}
                            name="location"
                            defaultValue=""
                          />
                        </Box>
                      </Stack>

                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="industry" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>
                        <Box py={1} pl={1} sx={{ width: '96%' }}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel
                              style={{ color: '#38CD84' }}
                              id="demo-simple-select-label">
                              Industry
                            </InputLabel>
                            <CssSelect
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={industrylst}
                              label="Industry List"
                              onChange={handleChangeIdustry}
                              size="small"
                              className={classes.underline}
                              color="secondary"
                              sx={{ color: '#38CD84' }}
                              MenuProps={MenuProps}>
                              {industryList?.data
                                ? industryList?.data.map((item, key) => {
                                    return (
                                      <MenuItem
                                        color="secondary"
                                        key={key}
                                        value={item.id}>
                                        <ListItemText
                                          color="secondary"
                                          primary={
                                            item.title.substring(0, 25) + '...'
                                          }
                                        />
                                      </MenuItem>
                                    );
                                  })
                                : ''}
                            </CssSelect>
                          </FormControl>
                        </Box>
                      </Stack>

                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="functions" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>
                        <Box py={1} pl={1} sx={{ width: '96%' }}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel
                              style={{ color: '#38CD84' }}
                              id="demo-simple-select-label">
                              Function
                            </InputLabel>
                            <CssSelect
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={functionlst}
                              label="Function"
                              className={classes.underline}
                              onChange={functionChange}
                              size="small"
                              sx={{ color: '#38CD84' }}
                              MenuProps={MenuProps}>
                              {functionList?.data
                                ? functionList?.data.map((item, key) => {
                                    return (
                                      <MenuItem key={key} value={item.id}>
                                        <ListItemText
                                          color="secondary"
                                          primary={
                                            item.title.substring(0, 25) + '...'
                                          }
                                        />
                                      </MenuItem>
                                    );
                                  })
                                : ''}
                            </CssSelect>
                          </FormControl>
                        </Box>
                      </Stack>

                      <Stack direction="row" spacing="2">
                        <Box py={2} px={2}>
                          <CxoButton
                            label="Reset"
                            onClick={(e) => resetFields(e)}
                          />
                        </Box>
                        <Box py={2} px={2}>
                          <CxoButton onClick={applySearch} label="Apply" />
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </form>
              </Paper>
            </Box>
          </Box>
        </Backdrop>
      </Box>
      <Box >
        <Box sx={{ width: '100%' }}>
          {/* <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  Discover
                </Typography>
              </Box>

              <Stack
                direction="row"
                sx={{ display: 'flex', alignItems: 'center' }}>
                {!showSearch && (
                  <Box>
                    <SearchBox
                      searchAll={searchAll}
                      handleKeyDown={handleKeyDown}
                      updateTxt={updateTxt}
                      searchTxt={searchTxt}
                    />
                  </Box>
                )}
                {showSearch && (
                  <Box onClick={toogleSearch} sx={{ cursor: 'pointer' }}>
                    <SearchIcon />
                  </Box>
                )}
                {!showSearch && (
                  <Box onClick={toogleSearch} sx={{ cursor: 'pointer' }}>
                    <CloseOutlinedIcon />
                  </Box>
                )}

                <Box
                  py={3}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeAllnetwork}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '13px',
                      },
                    }}>
                    See All
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60px',
                    paddingRight: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={showFilter}>
                  <FilterAltOutlinedIcon />
                </Box>
              </Stack>
            </Stack>
          </Paper> */}

          <Box
            sx={{
              marginTop: '0px',
            }}>
            {discoverLoading ? (
              <Fragment>
                <Box>
                  <NetworkCardSkeleton />
                </Box>
                <Box pt={1}>
                  <NetworkCardSkeleton />
                </Box>
              </Fragment>
            ) : (
              <Box>
                <InfiniteScroll
                  dataLength={discoverlist?.length ? discoverlist?.length : 0}
                  next={() => seeMoreNetwork()}
                  hasMore={true}
                  loader={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreNetwork}></Box>
                  }
                  height={'calc(90vh - 75px)'}
                  endMessage={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreNetwork}>
                      <Box>End</Box>
                    </Box>
                  }>
                  <Grid container>
                    {discoverlist?.length > 0 &&
                      discoverlist?.map((list, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: index % 2 === 0 ? '8px' : '0px',
                              marginTop: '8px',
                            }}>
                            <NetworkCard userlist={list} type="discover" />
                          </Grid>
                        );
                      })}

                    {discoverlist?.length === 0 &&
                      !(
                        <Box p={2}>
                          <Typography variant="h5" component="h5">
                            Right now there are no connections to discover.
                          </Typography>
                        </Box>
                      )}
                  </Grid>
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        </Box>
        {/* {discoverlist?.length > 4 &&
          <Box
            py={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={seeMoreNetwork}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{
                "@media (max-width: 500px)": {
                  fontSize: "13px",
                },
              }}
            >
              See More
            </Typography>
          </Box>
          }  */}

        {/* {discoverlist?.length > 4 && discoverlist?.length === noofDiscover && (
          <Box
            py={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={seeLess}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{
                "@media (max-width: 500px)": {
                  fontSize: "13px",
                },
              }}
            >
              See Less
            </Typography>
          </Box>
        )} */}
      </Box>
    </Fragment>
  );
};

export default React.memo(NetworkDiscover);

// {discoverlist?.length > 4 &&
//   discoverlist?.length === noofDiscover && (
//     <Box
//       py={3}
//       px={3}
//       sx={{
//         display: "flex",
//         justifyItems: "center",
//         alignItems: "center",
//         cursor: "pointer",
//       }}
//       onClick={seeLess}
//     >
//       <Box px={2}></Box>
//       <Typography
//         variant="h5"
//         component="h5"
//         sx={{
//           "@media (max-width: 500px)": {
//             fontSize: "13px",
//           },
//         }}
//       >
//         See less
//       </Typography>
//     </Box>
//   )}

{
  /* <CircularProgress
color="secondary"
size={20}
sx={{ marginTop: "10px" }}
/> */
}

// onClick={showFilter}  onClick={showFilter}
