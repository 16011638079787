import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useStyles from './profilestyle';
import Divider from '@mui/material/Divider';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import InterestIcon from '../../assets/chooseurinterest';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Collapse from '@mui/material/Collapse';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CxoButton from '../../components/CxoButton/CxoButton';
import { alpha, styled } from '@mui/material/styles';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as authActions from '../../store/auth';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const CssAccordion = styled(Accordion)(({ theme }) => {
  return {
    '& .MuiAccordion-root': {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0)',
    },
  };
});

const CssTab = styled(Tab)(({ theme }) => {
  return {
    '& .MuiTabs-indicator': {
      height: '4px',
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const UserProfile = (props) => {
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [section, setSection] = React.useState('');
  const [tab, setTab] = React.useState(0);
  const [edit, setEdit] = React.useState(true);
  const [editsection, setEditsection] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sectiondata, setSectiondata] = React.useState({});

  const handleaccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.UserDetails);
  const userimage = useSelector((state) => state?.user?.UserProfileImage);

  const userotherdetails = useSelector(
    (state) => state?.user?.UserOtherDetails,
  );
  const userabouts = useSelector((state) => state?.user?.UserAbouts);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  useEffect(() => {
    async function fetchData() {
      const profilefield = await dispatch(
        authActions.profileRequiredField({ user_id: user?.id }),
      );
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const id = params?.name?.split('-');
    console.log('keywords', params.name, id[id?.length - 1].substring(2));
    if (id?.length > 0) {
      const data = {
        id: id[id?.length - 1].substring(2),
      };
      const usersabout = await dispatch(userActions.userProfileAbout(data));
    }
  }, []);

  useEffect(async () => {
    const id = params?.name?.split('-');
    console.log('keywords', params.name, id[id?.length - 1].substring(2));
    if (id?.length > 0) {
      const data = {
        user_id: id[id?.length - 1].substring(2),
        self_id: id[id?.length - 1].substring(2),
        datetime: dateTime(),
      };
      const users = await dispatch(
        userActions.otheruserProfileDetails(data, 'other'),
      );
    }
  }, []);
  const handleChange = (newValue) => {
    setType(newValue);
  };
  const editProfile = () => {
    setEdit(!edit);
  };
  const editpub = (e, section) => {
    e.stopPropagation();
    setSection(section);
    setEditsection(!editsection);
  };

  const editSection = async (e, type, data) => {
    e.stopPropagation();
    const openeditsection = await dispatch(
      userActions.openUpdateSectionProfile(type, data),
    );
  };
  const addNew = async (e, type) => {
    e.stopPropagation();
    const openaddnew = await dispatch(userActions.openUpdateProfile(type));
  };
  const handleDelete = async () => {
    const datayy = {
      id: sectiondata?.id,
    };
    if (section === 'education') {
      const deletesection = await dispatch(userActions.deleteEducation(datayy));
    } else if (section === 'experience') {
      const deletesection = await dispatch(
        userActions.deleteExperience(datayy),
      );
    } else if (section === 'interest') {
      const deletesection = await dispatch(userActions.deleteInterest(datayy));
    } else if (section === 'language') {
      const deletesection = await dispatch(
        userActions.deleteUserLanguage(datayy),
      );
    } else if (section === 'experience') {
      const deletesection = await dispatch(
        userActions.deleteExperience(datayy),
      );
    }
  };
  const deleteSection = (e, type, data) => {
    e.stopPropagation();
    setOpen(true);
    setSection(type);
    setSectiondata(data);
  };
  const handleClose = () => {
    setOpen(false);
    setSection('');
    setSectiondata({});
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          sx={{
            fontSize: '24px',
            fontWeight: '600',
            color: '#38cd84',
            paddingTop: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width: 500px)': {
              fontSize: '20px',
            },
          }}
          id="alert-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent
          sx={{
            width: '300px',
            paddingTop: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width: 500px)': {
              width: '300px',
            },
          }}>
          <DialogContentText
            sx={{ paddingTop: '15px' }}
            id="alert-dialog-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CxoButton label="No" onClick={handleClose}>
            Disagree
          </CxoButton>
          <CxoButton label="Yes" onClick={handleDelete} autoFocus>
            Agree
          </CxoButton>
        </DialogActions>
      </Dialog>

      <Box p={1}>
        <Box sx={{ width: '100%' }}>
          <Paper
            sx={{
              backgroundColor: '#232121',
              backgroundImage: 'none',
              overflowX: 'auto',
            }}>
            <Box
              sx={{
                height:
                  userotherdetails?.designation?.length +
                    userotherdetails?.company_name?.length >
                  30
                    ? '168px'
                    : '148px',
                backgroundColor: '#2c2c2d',
                '@media (max-width: 500px)': {
                  height:
                    userotherdetails?.designation?.length +
                      userotherdetails?.company_name?.length >
                    30
                      ? '100px'
                      : '100px',
                },
              }}></Box>
            <Stack spacing={1} direction="column">
              <Box
                px={{ md: 2, xs: 0.5 }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  transform: ' translateY(-50%)',
                  marginBottom: '-60px',
                  '@media (max-width: 500px)': { marginBottom: '-30px' },
                }}>
                <Stack direction="row">
                  <Avatar
                    src={
                      userotherdetails?.image
                        ? userotherdetails?.image
                        : PersonIcon
                    }
                    sx={{
                      width: 148,
                      height: 148,
                      border: '4px solid #38cd84',
                      '@media (max-width: 500px)': {
                        width: 110,
                        height: 110,
                      },
                    }}
                  />
                </Stack>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  transform: ' translateY(-50%)',
                }}>
                <Typography
                  variant="h2s"
                  component="h2s"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    '@media (max-width: 500px)': {
                      fontSize: '18px',
                    },
                  }}>
                  {userotherdetails?.first_name
                    ? userotherdetails?.first_name?.charAt(0).toUpperCase() +
                      userotherdetails?.first_name?.slice(1)
                    : 'NA'}{' '}
                  {userotherdetails?.last_name
                    ? userotherdetails?.last_name?.charAt(0).toUpperCase() +
                      userotherdetails?.last_name?.slice(1)
                    : ''}
                </Typography>
              </Box>

              <Box
                pb={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-8px',
                  transform: ' translateY(-20%)',
                }}>
                <Stack spacing={0.5} direction="column" alignItems="center">
                  <Typography variant="h2" component="h2">
                    {userotherdetails?.designation
                      ? userotherdetails?.designation?.charAt(0).toUpperCase() +
                        userotherdetails?.designation?.slice(1)
                      : ''}
                  </Typography>
                  <Typography variant="h2" component="h2">
                    {userotherdetails?.designation
                      ? userotherdetails?.company_name
                          ?.charAt(0)
                          .toUpperCase() +
                        userotherdetails?.company_name?.slice(1)
                      : ''}
                  </Typography>
                  <Typography variant="h5" component="h5">
                    {userotherdetails?.location
                      ? userotherdetails?.location
                      : ''}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <Box px={{ xs: 2, md: 4 }} py={2}>
              <Stack
                spacing={{ md: 2, xs: 2 }}
                py={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="h2" component="h2">
                  Profile Completed:{' '}
                  {userotherdetails?.profile_percent
                    ? userotherdetails?.profile_percent
                    : 0}{' '}
                  %
                </Typography>
                {/* <Typography variant="h2" component="h2">
                  Last Updated:{' '}
                  {userotherdetails?.edited_datetime
                    ? userotherdetails?.edited_datetime
                    : 'few mins ago'}
                </Typography> */}
              </Stack>
              <Stack
                spacing={{ md: 2, xs: 0.5 }}
                py={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Stack direction="column" spacing={1}>
                  <Typography
                    variant="h2s"
                    component="h2s"
                    sx={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                      fontSize: '22px',
                      '@media (max-width: 500px)': {
                        fontSize: '16px',
                      },
                    }}>
                    {userotherdetails?.connection_count
                      ? userotherdetails?.connection_count
                      : 0}
                  </Typography>
                  <Typography variant="h2" component="h2">
                    Connections
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}></Stack>
              </Stack>

              <Box py={{ xs: 1, md: 2 }}>
                <Typography variant="h2s" component="h2s">
                  {userotherdetails?.profile_heading
                    ? userotherdetails?.profile_heading
                    : ''}
                </Typography>
              </Box>
              <Box py={{ xs: 1, md: 2 }}>
                <Typography variant="h2" component="h2">
                  {userotherdetails?.about ? userotherdetails?.about : ''}
                </Typography>
              </Box>

              <Stack
                py={{ md: 3, xs: 1 }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '42px',
                    maxWidth: 'min-content',
                    minWidth: '42px',
                    cursor: 'pointer',
                  }}>
                  <Tooltip
                    title={
                      userotherdetails?.mobile
                        ? userotherdetails?.mobile
                        : 'N.A'
                    }>
                    <Menuicon
                      name="phone"
                      width="36"
                      color={userotherdetails?.mobile ? '#38CD84' : '#ffffff'}
                    />
                  </Tooltip>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '36px',
                    maxWidth: 'min-content',
                    minWidth: '36px',
                    cursor: 'pointer',
                  }}>
                  <Menuicon
                    name="email"
                    color={userotherdetails?.email ? '#38CD84' : '#ffffff'}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '36px',
                    maxWidth: 'min-content',
                    minWidth: '36px',
                    cursor: 'pointer',
                    '@media (max-width: 500px)': {
                      minWidth: '18px',
                      height: '18px',
                    },
                  }}>
                  <Menuicon name="website" color="#38CD84" />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '24px',
                    maxWidth: 'min-content',
                    minWidth: '24px',
                    cursor: 'pointer',
                    '@media (max-width: 500px)': {
                      minWidth: '22px',
                      height: '22px',
                    },
                  }}>
                  <Menuicon
                    name="blog"
                    color={userotherdetails?.blog ? '#38CD84' : '#ffffff'}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '36px',
                    maxWidth: 'min-content',
                    minWidth: '36px',
                    cursor: 'pointer',
                    '@media (max-width: 500px)': {
                      marginTop: '-5px',
                    },
                  }}>
                  <Menuicon
                    name="dob"
                    color={userotherdetails?.dob ? '#38CD84' : '#ffffff'}
                  />
                </Box>
              </Stack>
              <Divider />
            </Box>

            <Box py={{ md: 1, xs: 0 }}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
                aria-label="disabled tabs example"
                sx={{
                  paddingLeft: '48px',
                  paddingRight: '48px',
                  paddingTop: '16px',
                  '@media (max-width: 500px)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingTop: '0px',
                  },
                }}>
                <CssTab
                  label={
                    <Stack
                      direction="row"
                      spacing={{ md: 2, xs: 0.8 }}
                      alignItems="center">
                      <InterestIcon width={20} name="other" color="#38CD84" />
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '14px',
                          },
                        }}>
                        About
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    textTransform: 'none',
                    fontSize: '20px',
                    fontWeight: '600',
                    '@media (max-width: 500px)': {
                      fontSize: '14px',
                    },
                  }}
                />

                <Tab
                  label={
                    <Stack
                      direction="row"
                      spacing={{ md: 2, xs: 0.8 }}
                      alignItems="center">
                      {' '}
                      <Icon width={20} name="sponsers" color="#808080" />
                      <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                          color: '#808080',
                          '@media (max-width: 500px)': {
                            fontSize: '14px',
                          },
                        }}>
                        Business
                      </Typography>
                    </Stack>
                  }
                  disabled
                  sx={{
                    textTransform: 'none',
                    fontSize: '20px',
                    fontWeight: '600',
                    '@media (max-width: 500px)': {
                      fontSize: '14px',
                    },
                  }}
                />
                <Tab
                  label={
                    <Stack
                      direction="row"
                      spacing={{ md: 2, xs: 0.8 }}
                      alignItems="center">
                      {' '}
                      <Menuicon width={20} name="meetups" color="#808080" />
                      <Typography
                        variant="h2"
                        component="h2"
                        sx={{ color: '#808080' }}>
                        Meetups
                      </Typography>
                    </Stack>
                  }
                  disabled
                  sx={{
                    textTransform: 'none',
                    fontSize: '20px',
                    fontWeight: '600',
                    '@media (max-width: 500px)': {
                      fontSize: '14px',
                    },
                  }}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Box pl={{ xs: 0, md: 2 }} pr={{ xs: 0, md: 2 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'skills'}
                    onChange={handleaccordionChange('skills')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="skills" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 2.5, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Skills
                            </Typography>
                          </Box>
                        </Stack>
                        {/* <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                        ></Stack> */}
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={{ md: 7, xs: 1 }} pb={2}>
                        {userabouts?.aSkill?.map((skill, index) => {
                          if (editsection && section === 'skills') {
                            return (
                              <CssChip
                                key={index}
                                className={classes.chipmain}
                                label={skill.title}
                                variant="outlined"
                              />
                            );
                          } else {
                            return (
                              <CssChip
                                key={index}
                                className={classes.chipmain}
                                label={skill.title}
                                variant="outlined"
                              />
                            );
                          }
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'interest'}
                    onChange={handleaccordionChange('interest')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '36px',
                              maxWidth: 'min-content',
                              minWidth: '36px',
                              width: '36px',
                            }}>
                            <Menuicon name="interests" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 1, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Interests
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          onClick={(e) => addNew(e, 'interest')}></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={{ md: 7, xs: 1 }} pb={2}>
                        {userabouts?.aInterest?.map((interest, index) => {
                          return interest?.title
                            ?.split(',')
                            .map((interst, index) => {
                              if (editsection && section === 'interest') {
                                return (
                                  <CssChip
                                    key={index}
                                    className={classes.chipmain}
                                    label={interst}
                                    onDelete={handleDelete}
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <CssChip
                                    key={index}
                                    className={classes.chipmain}
                                    label={interst}
                                    variant="outlined"
                                  />
                                );
                              }
                            });
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'industry'}
                    onChange={handleaccordionChange('industry')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="industry" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 2.5, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Industry
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={{ md: 7, xs: 1 }} pb={2}>
                        {userabouts?.aIndustryId?.map((industry, index) => {
                          if (editsection && section === 'industry') {
                            return (
                              <CssChip
                                key={index}
                                sx={{ marginLeft: '4px', marginTop: '4px' }}
                                label={industry?.industry}
                                onDelete={handleDelete}
                                variant="outlined"
                              />
                            );
                          } else {
                            return (
                              <CssChip
                                key={index}
                                sx={{
                                  marginLeft: '4px',
                                  marginRight: '4px',
                                  marginTop: '4px',
                                }}
                                label={industry?.industry}
                                variant="outlined"
                              />
                            );
                          }
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'function'}
                    onChange={handleaccordionChange('function')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="functions" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Function
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          onClick={(e) => addNew(e, 'function')}></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={{ md: 7, xs: 1 }} pb={2}>
                        {userabouts?.aFunctionId?.map((industry, index) => {
                          if (editsection && section === 'function') {
                            return (
                              <CssChip
                                key={index}
                                sx={{ marginLeft: '4px', marginTop: '4px' }}
                                label={industry?.functions}
                                onDelete={handleDelete}
                                variant="outlined"
                              />
                            );
                          } else {
                            return (
                              <CssChip
                                key={index}
                                sx={{ marginLeft: '4px', marginTop: '4px' }}
                                label={industry?.functions}
                                variant="outlined"
                              />
                            );
                          }
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'experience'}
                    onChange={handleaccordionChange('experience')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="experience" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Experience
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          onClick={(e) => addNew(e, 'experience')}></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pb={2}>
                        <Grid container className={classes.outergridyoe}>
                          <Grid item xs={12} className={classes.leftgrid}>
                            <Typography variant="h2s" component="h2s">
                              {userotherdetails?.total_experience}{' '}
                              {userotherdetails?.total_experience
                                ? 'Years of Experience'
                                : ''}
                            </Typography>
                          </Grid>
                        </Grid>

                        {userabouts?.aExperience?.map((industry, index) => {
                          return (
                            <Fragment key={index}>
                              <Stack
                                direction="row"
                                sx={{ paddingBottom: '16px' }}>
                                <Box pl={{ md: 2, xs: 0 }}></Box>
                                <Grid
                                  key={index}
                                  container
                                  className={classes.outergrid}>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Company:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {industry?.company}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Industry:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {industry?.industry}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Function:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {industry?.functions}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Location:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {industry?.location}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Designation:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {industry?.designation}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Start Date:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {moment(industry?.start_date).format(
                                        'LL',
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      End Date:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    {industry?.still_working === 1 ? (
                                      <Typography variant="h2" component="h2">
                                        Currently Working
                                      </Typography>
                                    ) : (
                                      <Typography variant="h2" component="h2">
                                        {moment(industry?.end_date).format(
                                          'LL',
                                        )}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Description:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {industry?.details}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Fragment>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'education'}
                    onChange={handleaccordionChange('education')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="educationtwo" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Education
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="row">
                        <Box>
                          <Box pb={2}>
                            {userabouts?.aEducation?.map((education, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={2}></Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        md={5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          University Name:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={7}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {education?.board}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Degree:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={7}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {education?.degree}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Passing year:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={7}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {education?.passing_year}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Details:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={7}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {education?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            })}
                          </Box>
                        </Box>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'affiliation'}
                    onChange={handleaccordionChange('affiliation')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="affiliation" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Affiliations
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pb={2}>
                        {userabouts?.aAffilation?.map((affilation, index) => {
                          return (
                            <Fragment key={index}>
                              <Stack
                                direction="row"
                                sx={{ paddingBottom: '16px' }}>
                                <Box pl={2}></Box>
                                <Grid
                                  key={index}
                                  container
                                  className={classes.outergrid}>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Name:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {affilation?.entity_name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Role:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {affilation?.role}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Start Date:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {moment(affilation?.start_date).format(
                                        'LL',
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      End Date:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {moment(affilation?.end_date).format(
                                        'LL',
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Details:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {affilation?.details}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Fragment>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'award'}
                    onChange={handleaccordionChange('award')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="award" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Awards & Certifications
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pb={2}>
                        {userabouts?.aAward?.map((award, index) => {
                          return (
                            <Fragment key={index}>
                              <Stack
                                direction="row"
                                sx={{ paddingBottom: '16px' }}>
                                <Box pl={2}></Box>
                                <Grid
                                  key={index}
                                  container
                                  className={classes.outergrid}>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Name:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {award?.award_name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      By:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {award?.awarded_by}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Details:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {award?.details}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Fragment>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'publications'}
                    onChange={handleaccordionChange('publications')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="publications" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Publications
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pb={2}>
                        {userabouts?.aPublication?.map((publication, index) => {
                          return (
                            <Fragment key={index}>
                              <Stack
                                direction="row"
                                sx={{ paddingBottom: '16px' }}>
                                <Box pl={2}></Box>
                                <Grid
                                  key={index}
                                  container
                                  className={classes.outergrid}>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Name:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {publication?.publication_title}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Date:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {moment(publication?.date).format('LL')}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Link:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {publication?.link}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Description:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {publication?.details}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Fragment>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'languages'}
                    onChange={handleaccordionChange('languages')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <Menuicon name="languages" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Languages
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pb={2}>
                        {userabouts?.aLanguage?.map((language, index) => {
                          return (
                            <Fragment key={index}>
                              <Stack
                                direction="row"
                                sx={{ paddingBottom: '16px' }}>
                                <Box pl={2}></Box>
                                <Grid
                                  key={index}
                                  container
                                  className={classes.outergrid}>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Language:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {language?.title}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}>
                                    <Typography variant="h2s" component="h2s">
                                      Proficiency:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {language?.proficiency}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    md={2}
                                    xs={6}
                                    className={classes.leftgrid}></Grid>
                                  <Grid
                                    item
                                    md={10}
                                    xs={6}
                                    className={classes.rightgrid}>
                                    <Typography variant="h2" component="h2">
                                      {language?.reading === 'Yes'
                                        ? 'Read,'
                                        : ''}{' '}
                                      {language?.writing === 'Yes'
                                        ? ' Write,'
                                        : ''}{' '}
                                      {language?.speaking === 'Yes'
                                        ? 'Speak'
                                        : ''}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Fragment>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>

                <Box px={{ md: 2, xs: 0 }}>
                  <Accordion
                    className={classes.accordionmain}
                    expanded={expanded === 'aditional'}
                    onChange={handleaccordionChange('aditional')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', paddingRight: '10px' }}
                        alignItems="center">
                        <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              width: '24px',
                              maxWidth: 'min-content',
                              minWidth: '24px',
                            }}>
                            <InterestIcon name="others" color="#38CD84" />
                          </Box>
                          <Box
                            pl={{ md: 3, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.headingtab}>
                              Additional Information
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"></Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={8}></Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2}>
                Item Three
              </TabPanel>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(UserProfile);
