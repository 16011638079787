import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as messageActions from '../../store/message';
import * as rewardsActions from '../../store/rewards';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

export default function CustomiseGroupPackages(props) {
  console.log('CustomiseGroupPackages', props);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);

  useEffect(async () => {
    const data = {
      user_id: props?.userlist?.id,
    };

    // const mutualfriend = await dispatch(usernetworkActions.discoverUserNetwork(data));
    // if(mutualfriend.Status === 1){
    //   setMutualFriend(mutualfriend?.data)
    // }
    // console.log('mutualfriend',user.id,props?.userlist?.id, mutualfriend);
  }, []);
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const membershipDetails = async () => {
    if (props?.type == 'customize') {
      const customisePackage = await dispatch(
        rewardsActions.customCorporatePackageOpen(
          props?.groupPackage,
          props?.from,
        ),
      );
    } else {
      if (location.pathname.includes('company')) {
        navigate(
          '/company/corporate/group/membership/details/' +
            props?.membership?.id,
        );
      } else {
        navigate(
          '/corporate/group/membership/details/' + props?.membership?.id,
        );
      }
    }
  };

  const membershipPrice3 = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#38CD84',
            fontSize: '18px',
            lineHeight: '27px',
            fontWeight: '400',
            fontFamily: 'Open Sans',
            marginLeft: '0px',
          }}>
          {' '}
          ₹{' '}
          {(
            props?.membership?.price -
            props?.membership?.price *
              (props?.membership?.offer_percentage / 100)
          ).toLocaleString()}
          {''}
        </Typography>
      </Box>
    );
  };
  const membershipPrice2 = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <Stack direction="column">
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontSize: '18px',
              lineHeight: '27px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
              textDecoration: 'line-through',
            }}>
            {'₹' + parseInt(props?.membership?.price).toLocaleString()}
          </Typography>
        </Stack>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#38CD84',
            fontSize: '14px',
            lineHeight: '27px',
            fontWeight: '400',
            fontFamily: 'Open Sans',
            marginLeft: '8px',
          }}>
          {props?.membership?.offer_percentage} %Off
        </Typography>
      </Box>
    );
  };
  const profileOpen = async () => {};
  if (props?.type == 'customize') {
    return (
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '171px',
          width: '100%',
        }}
        onClick={membershipDetails}>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
            width: '30%',
            maxWidth: { xs: 350, md: 250 },
            padding: '0px',
            objectFit: 'cover',
            backgroundColor: '#333',
            margin: '0px 0px 0px 0px',
            cursor: 'pointer',
          }}
          alt="">
          <Stack
            direction="column"
            sx={{ width: '100%', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#333',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 'min-content',
                }}>
                <img
                  alt="Membership"
                  src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/image%20(13).png"
                  style={{
                    height: '171px',
                    objectFit: 'cover',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Box>

        <Stack
          direction="column"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '16px',
            width: '60%',
          }}>
          <Box
            pt={1}
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              justifyContent: 'flex-start',
            }}>
            {' '}
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '20px',
                lineHeight: '20px',
              }}>
              {' '}
              Customise For Your Team
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 400,
              }}>
              {props?.membership?.expiry_msg
                ? props?.membership?.expiry_msg
                : ''}
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6">
              Contact us for more details
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6"></Typography>
          </Box>
          <CardActions sx={{ width: '100%', padding: '0 !important' }}>
            <Stack direction="column" sx={{ width: '100%' }}>
              <Box>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '24px',
                    lineHeight: '24px',
                  }}>
                  {' '}
                </Typography>
              </Box>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                <Box pb={0}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '24px',
                      lineHeight: '24px',
                    }}>
                    {' '}
                  </Typography>
                </Box>
                <Box
                  pb={1}
                  pr={1}
                  sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <CxoButtonWithIcon
                    label={
                      props?.type == 'customize'
                        ? 'Get In Touch'
                        : 'Purchase Now'
                    }
                    minWidth="120"
                    onClick={membershipDetails}
                  />
                </Box>
              </Stack>
            </Stack>
          </CardActions>
        </Stack>
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '171px',
          width: '100%',
        }}
        onClick={membershipDetails}>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
            width: '30%',
            maxWidth: { xs: 350, md: 250 },
            padding: '0px',
            objectFit: 'cover',
            backgroundColor: '#333',
            margin: '0px 0px 0px 0px',
            cursor: 'pointer',
          }}
          alt="">
          <Stack
            direction="column"
            sx={{ width: '100%', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#333',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 'min-content',
                }}>
                <img
                  alt="Membership"
                  src={props?.membership?.image}
                  style={{
                    height: '171px',
                    objectFit: 'cover',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Box>

        <Stack
          direction="column"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '16px',
            width: '60%',
          }}>
          <Box
            pt={1}
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              justifyContent: 'flex-start',
            }}>
            {' '}
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '20px',
                lineHeight: '20px',
              }}>
              {' '}
              {props?.membership?.title}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 400,
              }}>
              
            </Typography>
          </Box>
          <Box pb={1} pt={1} pr={2} sx={{ width: '100%' }}>
            <Stack
              direction="column"
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  lineHeight: '14px',
                }}>
                
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  lineHeight: '14px',
                }}></Typography>
            </Stack>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6"></Typography>
          </Box>
          <CardActions sx={{ width: '100%', padding: '0 !important' }}>
            <Stack direction="column" sx={{ width: '100%' }}>
              <Box sx={{ marginBottom: '16px' }}>
                <Stack direction="row" spacing="3"> 
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '24px',
                    lineHeight: '24px',
                  }}>
                  {' '}
                  {membershipPrice3()}
                </Typography>
                {'  '}
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                     paddingLeft: '8px',
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}>
                  {' '}
                    Per Person
                </Typography> 
                </Stack>
              </Box>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                <Box pb={0}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '24px',
                      lineHeight: '24px',
                    }}>
                    {' '}
                    {membershipPrice2()}
                  </Typography>
                </Box>
                <Box
                  pb={1}
                  pr={1}
                  sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <CxoButtonWithIcon
                    label={
                      props?.type == 'customize' ? 'Get In Touch' : 'Buy Now'
                    }
                    onClick={(e) => membershipDetails(e)}
                  />
                </Box>
              </Stack>
            </Stack>
          </CardActions>
        </Stack>
      </Card>
    );
  }
}
