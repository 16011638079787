import { API, apiUrl } from '../../services';
import { http } from '../../services/client';
import { showMessage } from '../message';
import { getAccessToken } from '../../services/authData';

export const ACCOUNT_DETAILS_FULFILLED = 'ACCOUNT_DETAILS_FULFILLED';
export const ACCOUNT_DETAILS_PENDING = 'ACCOUNT_DETAILS_PENDING';
export const ACCOUNT_DETAILS_REJECTED = 'ACCOUNT_DETAILS_REJECTED';

const accountDetailsPending = () => ({
  type: 'ACCOUNT_DETAILS_PENDING',
});

const accountDetailsFailure = (error) => ({
  type: 'ACCOUNT_DETAILS_REJECTED',
  payload: { error },
});

const accountDetailsSuccess = (data, post) => ({
  type: ACCOUNT_DETAILS_FULFILLED,
  payload: data,
});

export const accountDetails = (payload) => {
  return (dispatch) => {
    dispatch(accountDetailsPending());
    return http
      .post(apiUrl.ACCOUNT_DETAILS, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(accountDetailsSuccess(response, payload));

          return response;
        } else {
          dispatch(accountDetailsFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(accountDetailsFailure(error));
 
        return error;
      });
  };
};

export const USER_PROFILE_DETAILS_FULFILLED = 'USER_PROFILE_DETAILS_FULFILLED';
export const USER_PROFILE_DETAILS_PENDING = 'USER_PROFILE_DETAILS_PENDING';
export const USER_PROFILE_DETAILS_REJECTED = 'USER_PROFILE_DETAILS_REJECTED';
export const USER_PROFILE_OTHER_DETAILS_FULFILLED =
  'USER_PROFILE_OTHER_DETAILS_FULFILLED';

const userProfileDetailsPending = () => ({
  type: USER_PROFILE_DETAILS_PENDING,
});

const userProfileDetailsFailure = (error) => ({
  type: USER_PROFILE_DETAILS_REJECTED,
  payload: { error },
});

const userProfileDetailsSuccess = (data) => {
  return {
    type: USER_PROFILE_DETAILS_FULFILLED,
    payload: data,
  };
};

export const userProfileDetails = (payload) => {
  return (dispatch) => {
    dispatch(userProfileDetailsPending());
    return http
      .post(apiUrl.USER_PROFILE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userProfileDetailsSuccess(response?.data));

          return response;
        } else {
          dispatch(userProfileDetailsFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userProfileDetailsFailure(error));
 
        return error;
      });
  };
};

export const OTHER_USER_PROFILE_DETAILS_FULFILLED =
  'OTHER_USER_PROFILE_DETAILS_FULFILLED';
export const OTHER_USER_PROFILE_DETAILS_PENDING =
  'OTHER_USER_PROFILE_DETAILS_PENDING';
export const OTHER_USER_PROFILE_DETAILS_REJECTED =
  'OTHER_USER_PROFILE_DETAILS_REJECTED';
export const OTHER_USER_PROFILE_OTHER_DETAILS_FULFILLED =
  'OTHER_USER_PROFILE_OTHER_DETAILS_FULFILLED';

const otheruserProfileDetailsPending = () => ({
  type: OTHER_USER_PROFILE_DETAILS_PENDING,
});

const otheruserProfileDetailsFailure = (error) => ({
  type: OTHER_USER_PROFILE_DETAILS_REJECTED,
  payload: { error },
});

const otheruserProfileDetailsSuccess = (data, type) => {
  return {
    type: OTHER_USER_PROFILE_DETAILS_FULFILLED,
    payload: data,
  };
};

export const otheruserProfileDetails = (payload) => {
  return (dispatch) => {
    dispatch(otheruserProfileDetailsPending());
    return http
      .post(apiUrl.USER_PROFILE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(otheruserProfileDetailsSuccess(response?.data));

          return response;
        } else {
          dispatch(otheruserProfileDetailsFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(otheruserProfileDetailsFailure(error));
 
        return error;
      });
  };
};

const imageUpdateSuccess = (payload) => {
  return {
    type: 'IMAGE_UPDATE_FULFILLED',
    payload: payload,
  };
};

export const userProfilePic = (payload) => {
  return (dispatch) => {
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.UPDATE_IMAGE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          console.log('error', error);
   
        } else {
          console.log('response', response);
          dispatch(imageUpdateSuccess(response?.data));
          showMessage({
            dispatch,
            message: ' Image Updated',
            variant: 'success',
          });
        }
      },
    );
  };
};

export const userProfileUpdate = (payload) => {
  return (dispatch) => {
    const token = getAccessToken();
    dispatch(userProfileDetailsPending());
    return API.http_form.post(
      {
        endPoint: apiUrl.UPDATE_USER,
        body: payload,
        token: token,
      },
      (error, response) => {
        if (error) {
          console.log('error', error);
    
        } else {
          if (response?.status === 1) {
            dispatch(userProfileDetailsSuccess(response?.data, payload));
            showMessage({
              dispatch,
              message: ' Profile Updated',
              variant: 'success',
            });
            return response;
          } else {
            dispatch(userProfileDetailsFailure(response));
            showMessage({
              dispatch,
              message: ' Error',
              variant: 'error',
            });
            return response;
          }
        }
      },
    );
  };
};

// export const userProfileUpdate = (payload) => {
//   return (dispatch) => {
//     dispatch(userProfileDetailsPending());
//     return http
//       .post(apiUrl.UPDATE_USER, payload)
//       .then((response) => {
//         console.log("payload", response, payload);
//         if (response?.status === 1) {
//           dispatch(userProfileDetailsSuccess(response?.data, payload));
//           showMessage({
//             dispatch,
//             message: " Profile Updated",
//             variant: "success",
//           });
//           return response;
//         } else {
//           dispatch(userProfileDetailsFailure(response));
//           showMessage({
//             dispatch,
//             message: " Error",
//             variant: "error",
//           });
//           return response;
//         }
//       })
//       .catch((error) => {
//         dispatch(userProfileDetailsFailure(error));
//         showMessage({
//           dispatch,
//           message: "error",
//           variant: "error",
//         });
//         return error;
//       });
//   };
// };

export const CHECK_MOBILE_EMAIL_FULFILLED = 'CHECK_MOBILE_EMAIL_FULFILLED';
export const CHECK_MOBILE_EMAIL_PENDING = 'CHECK_MOBILE_EMAIL_PENDING';
export const CHECK_MOBILE_EMAIL_REJECTED = 'CHECK_MOBILE_EMAIL_REJECTED';

const checkMobileEmailPending = () => ({
  type: CHECK_MOBILE_EMAIL_PENDING,
});

const checkMobileEmailFailure = (error) => ({
  type: CHECK_MOBILE_EMAIL_REJECTED,
  payload: { error },
});

const checkMobileEmailSuccess = (data) => ({
  type: CHECK_MOBILE_EMAIL_REJECTED,
  payload: data,
});

export const updateMobile = (payload) => {
  return (dispatch) => {
    dispatch(checkMobileEmailPending());
    return http
      .post(apiUrl.UPDATE_MOBILE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(checkMobileEmailSuccess(response?.data, payload));
          showMessage({
            dispatch,
            message: ' Profile Updated',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(checkMobileEmailFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(checkMobileEmailFailure(error));
  
        return error;
      });
  };
};

export const CHECK_EMAIL_FULFILLED = 'CHECK_EMAIL_FULFILLED';
export const CHECK_EMAIL_PENDING = 'CHECK_EMAIL_PENDING';
export const CHECK_EMAIL_REJECTED = 'CHECK_EMAIL_REJECTED';

export const updateEmail = (payload) => {
  return (dispatch) => {
    dispatch(checkMobileEmailPending());
    return http
      .post(apiUrl.UPDATE_EMAIL, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(checkMobileEmailSuccess(response?.data, payload));
          showMessage({
            dispatch,
            message: ' Profile Updated',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(checkMobileEmailFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(checkMobileEmailFailure(error));
 
        return error;
      });
  };
};

export const USER_PROFILE_ABOUT_FULFILLED = 'USER_PROFILE_ABOUT_FULFILLED';
export const USER_PROFILE_ABOUT_PENDING = 'USER_PROFILE_ABOUT_PENDING';
export const USER_PROFILE_ABOUT_REJECTED = 'USER_PROFILE_ABOUT_REJECTED';

const userProfileAboutPending = () => ({
  type: USER_PROFILE_ABOUT_PENDING,
});

const userProfileAboutFailure = (error) => ({
  type: USER_PROFILE_ABOUT_REJECTED,
  payload: { error },
});

const userProfileAboutSuccess = (data, post) => ({
  type: USER_PROFILE_ABOUT_FULFILLED,
  payload: data,
});

export const userProfileAbout = (payload) => {
  return (dispatch) => {
    dispatch(userProfileAboutPending());
    return http
      .post(apiUrl.USER_PROFILE_ABOUT, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userProfileAboutSuccess(response?.data, payload));
          return response;
        } else {
          dispatch(userProfileAboutFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userProfileDetailsFailure(error));
 
        return error;
      });
  };
};

export const CLOSE_ADD_NEW_ABOUT = 'CLOSE_ADD_NEW_ABOUT';
export const OPEN_ADD_NEW_ABOUT = 'OPEN_ADD_NEW_ABOUT';

export const closeUpdateProfile = () => ({
  type: 'CLOSE_ADD_NEW_ABOUT',
});

export const openUpdateProfile = (type) => {
  return {
    type: 'OPEN_ADD_NEW_ABOUT',
    payload: type,
  };
};

export const CLOSE_UPDATE_ABOUT = 'CLOSE_UPDATE_ABOUT';
export const OPEN_UPDATE_ABOUT = 'OPEN_UPDATE_ABOUT';

export const closeUpdateSectionProfile = () => ({
  type: 'CLOSE_UPDATE_ABOUT',
});

export const openUpdateSectionProfile = (type, data) => {
  return {
    type: 'OPEN_UPDATE_ABOUT',
    payload: { type: type, data: data },
  };
};

export const USER_MANAGE_SKILL_FULFILLED = 'USER_MANAGE_SKILL_FULFILLED';
export const USER_MANAGE_SKILL_PENDING = 'USER_MANAGE_SKILL_PENDING';
export const USER_MANAGE_SKILL_REJECTED = 'USER_MANAGE_SKILL_REJECTED';

const userManageSkillPending = () => ({
  type: USER_MANAGE_SKILL_PENDING,
});

const userManageSkillFailure = (error) => ({
  type: USER_MANAGE_SKILL_REJECTED,
  payload: { error },
});

const userManageSkillSuccess = (data, post) => ({
  type: USER_MANAGE_SKILL_FULFILLED,
  payload: data,
});

export const manageSkill = (payload) => {
  return (dispatch) => {
    dispatch(userManageSkillPending());
    return http
      .post(apiUrl.USER_MANAGE_SKILL, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userManageSkillSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userManageSkillFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userManageSkillFailure(error));
 
        return error;
      });
  };
};

export const USER_DELETE_SKILL_FULFILLED = 'USER_DELETE_SKILL_FULFILLED';
export const USER_DELETE_SKILL_PENDING = 'USER_DELETE_SKILL_PENDING';
export const USER_DELETE_SKILL_REJECTED = 'USER_DELETE_SKILL_REJECTED';

const userDeleteSkillPending = () => ({
  type: USER_DELETE_SKILL_PENDING,
});

const userDeleteSkillFailure = (error) => ({
  type: USER_DELETE_SKILL_REJECTED,
  payload: { error },
});

const userDeleteSkillSuccess = (data, post) => ({
  type: USER_DELETE_SKILL_FULFILLED,
  payload: data,
});

export const deleteSkill = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteSkillPending());
    return http
      .post(apiUrl.USER_DELETE_SKILL, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteSkillSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeleteSkillFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteSkillFailure(error));
   
        return error;
      });
  };
};

export const USER_DELETE_AFFILIATION_FULFILLED =
  'USER_DELETE_AFFILIATION_FULFILLED';
export const USER_DELETE_AFFILIATION_PENDING =
  'USER_DELETE_AFFILIATION_PENDING';
export const USER_DELETE_AFFILIATION_REJECTED =
  'USER_DELETE_AFFILIATION_REJECTED';

const userDeleteAffiliationPending = () => ({
  type: USER_DELETE_AFFILIATION_PENDING,
});

const userDeleteAffiliationFailure = (error) => ({
  type: USER_DELETE_AFFILIATION_REJECTED,
  payload: { error },
});

const userDeleteAffiliationSuccess = (data, post) => ({
  type: USER_DELETE_AFFILIATION_FULFILLED,
  payload: data,
});

export const deleteAffiliation = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteAffiliationPending());
    return http
      .post(apiUrl.USER_DELETE_AFFILIATION, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteAffiliationSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeleteAffiliationFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteAffiliationFailure(error));
 
        return error;
      });
  };
};

export const USER_DELETE_PUBLICATION_FULFILLED =
  'USER_DELETE_PUBLICATION_FULFILLED';
export const USER_DELETE_PUBLICATION_PENDING =
  'USER_DELETE_PUBLICATION_PENDING';
export const USER_DELETE_PUBLICATION_REJECTED =
  'USER_DELETE_PUBLICATION_REJECTED';

const userDeletePublicationPending = () => ({
  type: USER_DELETE_PUBLICATION_PENDING,
});

const userDeletePublicationFailure = (error) => ({
  type: USER_DELETE_PUBLICATION_REJECTED,
  payload: { error },
});

const userDeletePublicationSuccess = (data, post) => ({
  type: USER_DELETE_PUBLICATION_FULFILLED,
  payload: data,
});

export const deletePublication = (payload) => {
  return (dispatch) => {
    dispatch(userDeletePublicationPending());
    return http
      .post(apiUrl.USER_DELETE_PUBLICATION, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeletePublicationSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeletePublicationFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeletePublicationFailure(error));
 
        return error;
      });
  };
};

export const USER_DELETE_AWARDS_FULFILLED = 'USER_DELETE_AWARDS_FULFILLED';
export const USER_DELETE_AWARDS_PENDING = 'USER_DELETE_AWARDS_PENDING';
export const USER_DELETE_AWARDS_REJECTED = 'USER_DELETE_AWARDS_REJECTED';

const userDeleteAwardsPending = () => ({
  type: USER_DELETE_AWARDS_PENDING,
});

const userDeleteAwardsFailure = (error) => ({
  type: USER_DELETE_AWARDS_REJECTED,
  payload: { error },
});

const userDeleteAwardsSuccess = (data, post) => ({
  type: USER_DELETE_AWARDS_FULFILLED,
  payload: data,
});

export const deleteAwards = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteAwardsPending());
    return http
      .post(apiUrl.USER_DELETE_AWARDS, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteAwardsSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeleteAwardsFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteAwardsFailure(error));
 
        return error;
      });
  };
};

export const USER_DELETE_INDUSTRY_FULFILLED = 'USER_DELETE_INDUSTRY_FULFILLED';
export const USER_DELETE_INDUSTRY_PENDING = 'USER_DELETE_INDUSTRY_PENDING';
export const USER_DELETE_INDUSTRY_REJECTED = 'USER_DELETE_INDUSTRY_REJECTED';

const userDeleteIndustryPending = () => ({
  type: USER_DELETE_INDUSTRY_PENDING,
});

const userDeleteIndustryFailure = (error) => ({
  type: USER_DELETE_INDUSTRY_REJECTED,
  payload: { error },
});

const userDeleteIndustrySuccess = (data, post) => ({
  type: USER_DELETE_INDUSTRY_FULFILLED,
  payload: data,
});

export const deleteIndustry = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteIndustryPending());
    return http
      .post(apiUrl.USER_DELETE_INDUSTRY, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteIndustrySuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeleteIndustryFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteIndustryFailure(error));
 
        return error;
      });
  };
};

export const USER_DELETE_FUNCTION_FULFILLED = 'USER_DELETE_FUNCTION_FULFILLED';
export const USER_DELETE_FUNCTION_PENDING = 'USER_DELETE_FUNCTION_PENDING';
export const USER_DELETE_FUNCTION_REJECTED = 'USER_DELETE_FUNCTION_REJECTED';

const userDeleteFunctionPending = () => ({
  type: USER_DELETE_FUNCTION_PENDING,
});

const userDeleteFunctionFailure = (error) => ({
  type: USER_DELETE_FUNCTION_REJECTED,
  payload: { error },
});

const userDeleteFunctionSuccess = (data, post) => ({
  type: USER_DELETE_FUNCTION_FULFILLED,
  payload: data,
});

export const deleteFunction = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteFunctionPending());
    return http
      .post(apiUrl.USER_DELETE_FUNCTION, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteFunctionSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeleteFunctionFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteFunctionFailure(error));
 
        return error;
      });
  };
};

export const USER_MANAGE_AFFILATION_FULFILLED =
  'USER_MANAGE_AFFILATION_FULFILLED';
export const USER_MANAGE_AFFILATION_PENDING = 'USER_MANAGE_AFFILATION_PENDING';
export const USER_MANAGE_AFFILATION_REJECTED =
  'USER_MANAGE_AFFILATION_REJECTED';

const userManageAffilationPending = () => ({
  type: USER_MANAGE_AFFILATION_PENDING,
});

const userManageAffilationFailure = (error) => ({
  type: USER_MANAGE_AFFILATION_REJECTED,
  payload: { error },
});

const userManageAffilationSuccess = (data, post) => ({
  type: USER_MANAGE_AFFILATION_FULFILLED,
  payload: data,
});

export const manageAffilation = (payload) => {
  return (dispatch) => {
    dispatch(userManageAffilationPending());
    return http
      .post(apiUrl.USER_MANAGE_AFFILATION, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userManageAffilationSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userManageAffilationFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userManageAffilationFailure(error));
 
        return error;
      });
  };
};




export const USER_CHALLENGE_PROFILE_VERIFICATION_FULFILLED = 'USER_CHALLENGE_PROFILE_VERIFICATION_FULFILLED';
export const USER_CHALLENGE_PROFILE_VERIFICATION_PENDING = 'USER_CHALLENGE_PROFILE_VERIFICATION_PENDING';
export const USER_CHALLENGE_PROFILE_VERIFICATION_REJECTED = 'USER_CHALLENGE_PROFILE_VERIFICATION_REJECTED';

const userChallengeProfilePending = () => ({
  type: USER_CHALLENGE_PROFILE_VERIFICATION_PENDING,
});

const userChallengeProfileFailure = (error) => ({
  type: USER_CHALLENGE_PROFILE_VERIFICATION_REJECTED,
  payload: { error },
});

const userChallengeProfileSuccess = (data, post) => ({
  type: USER_CHALLENGE_PROFILE_VERIFICATION_FULFILLED,
  payload: data,
});

export const userChallengeProfile = (payload) => {
  return (dispatch) => {
    dispatch(userChallengeProfilePending());
    return http
      .post(apiUrl.USER_CHALLENGE_PROFILE_VERIFICATION, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(userChallengeProfileSuccess(response?.data, payload));
          showMessage({
            dispatch,
            message: 'Challenge Submitted!',
            variant: 'successs',
          });
          return response;
        } else {
          dispatch(userChallengeProfileFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userChallengeProfileFailure(error));
 
        return error;
      });
  };
};


export const USER_MANAGE_FUNCTION_FULFILLED = 'USER_MANAGE_FUNCTION_FULFILLED';
export const USER_MANAGE_FUNCTION_PENDING = 'USER_MANAGE_FUNCTION_PENDING';
export const USER_MANAGE_FUNCTION_REJECTED = 'USER_MANAGE_FUNCTION_REJECTED';

const usermanageFunctionPending = () => ({
  type: USER_MANAGE_FUNCTION_PENDING,
});

const usermanageFunctionFailure = (error) => ({
  type: USER_MANAGE_FUNCTION_REJECTED,
  payload: { error },
});

const usermanageFunctionSuccess = (data, post) => ({
  type: USER_MANAGE_FUNCTION_FULFILLED,
  payload: data,
});

export const manageFunction = (payload) => {
  return (dispatch) => {
    dispatch(usermanageFunctionPending());
    return http
      .post(apiUrl.USER_MANAGE_FUNCTION, payload)
      .then((response) => {
        debugger;
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(usermanageFunctionSuccess(response?.data, payload));
          return response;
        } else {
          dispatch(usermanageFunctionFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(usermanageFunctionFailure(error));
 
        return error;
      });
  };
};

export const USER_MANAGE_INDUSTRY_FULFILLED = 'USER_MANAGE_INDUSTRY_FULFILLED';
export const USER_MANAGE_INDUSTRY_PENDING = 'USER_MANAGE_INDUSTRY_PENDING';
export const USER_MANAGE_INDUSTRY_REJECTED = 'USER_MANAGE_INDUSTRY_REJECTED';

const usermanageIndustryPending = () => ({
  type: USER_MANAGE_INDUSTRY_PENDING,
});

const usermanageIndustryFailure = (error) => ({
  type: USER_MANAGE_INDUSTRY_REJECTED,
  payload: { error },
});

const usermanageIndustrySuccess = (data, post) => ({
  type: USER_MANAGE_INDUSTRY_FULFILLED,
  payload: data,
});

export const manageIndustry = (payload) => {
  return (dispatch) => {
    dispatch(usermanageIndustryPending());
    return http
      .post(apiUrl.USER_MANAGE_INDUSTRY, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(usermanageIndustrySuccess(response?.data, payload));

          return response;
        } else {
          dispatch(usermanageIndustryFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(usermanageIndustryFailure(error));
   
        return error;
      });
  };
};

export const USER_MANAGE_EXPERIENCE_FULFILLED =
  'USER_MANAGE_EXPERIENCE_FULFILLED';
export const USER_MANAGE_EXPERIENCE_PENDING = 'USER_MANAGE_EXPERIENCE_PENDING';
export const USER_MANAGE_EXPERIENCE_REJECTED =
  'USER_MANAGE_EXPERIENCE_REJECTED';

const userManageExperiencePending = () => ({
  type: USER_MANAGE_EXPERIENCE_PENDING,
});

const userManageExperienceFailure = (error) => ({
  type: USER_MANAGE_EXPERIENCE_REJECTED,
  payload: { error },
});

const userManageExperienceSuccess = (data, post) => ({
  type: USER_MANAGE_EXPERIENCE_FULFILLED,
  payload: data,
});

export const manageExperience = (payload) => {
  return (dispatch) => {
    dispatch(userManageExperiencePending());
    return http
      .post(apiUrl.USER_MANAGE_EXPERIENCE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userManageExperienceSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userManageExperienceFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userManageExperienceFailure(error));
 
        return error;
      });
  };
};

export const USER_DELETE_EXPERIENCE_FULFILLED =
  'USER_DELETE_EXPERIENCE_FULFILLED';
export const USER_DELETE_EXPERIENCE_PENDING = 'USER_DELETE_EXPERIENCE_PENDING';
export const USER_DELETE_EXPERIENCE_REJECTED =
  'USER_DELETE_EXPERIENCE_REJECTED';

const userDeleteExperiencePending = () => ({
  type: USER_DELETE_EXPERIENCE_PENDING,
});

const userDeleteExperienceFailure = (error) => ({
  type: USER_DELETE_EXPERIENCE_REJECTED,
  payload: { error },
});

const userDeleteExperienceSuccess = (data, post) => ({
  type: USER_DELETE_EXPERIENCE_FULFILLED,
  payload: data,
});

export const deleteExperience = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteExperiencePending());
    return http
      .post(apiUrl.USER_DELETE_EXPERIENCE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteExperienceSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeleteExperienceFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteExperienceFailure(error));
 
        return error;
      });
  };
};

export const USER_MANAGE_EDUCATION_FULFILLED =
  'USER_MANAGE_EDUCATION_FULFILLED';
export const USER_MANAGE_EDUCATION_PENDING = 'USER_MANAGE_EDUCATION_PENDING';
export const USER_MANAGE_EDUCATION_REJECTED = 'USER_MANAGE_EDUCATION_REJECTED';

const userManageEducationPending = () => ({
  type: USER_MANAGE_EDUCATION_PENDING,
});

const userManageEducationFailure = (error) => ({
  type: USER_MANAGE_EDUCATION_REJECTED,
  payload: { error },
});

const userManageEducationSuccess = (data, post) => ({
  type: USER_MANAGE_EDUCATION_FULFILLED,
  payload: data,
});

export const manageEducation = (payload) => {
  return (dispatch) => {
    dispatch(userManageEducationPending());
    return http
      .post(apiUrl.USER_MANAGE_EDUCATION, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userManageEducationSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userManageEducationFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userManageEducationFailure(error));
  
        return error;
      });
  };
};

export const USER_DELETE_EDUCATION_FULFILLED =
  'USER_DELETE_EDUCATION_FULFILLED';
export const USER_DELETE_EDUCATION_PENDING = 'USER_DELETE_EDUCATION_PENDING';
export const USER_DELETE_EDUCATION_REJECTED = 'USER_DELETE_EDUCATION_REJECTED';

const userDeleteEducationPending = () => ({
  type: USER_DELETE_EDUCATION_PENDING,
});

const userDeleteEducationFailure = (error) => ({
  type: USER_DELETE_EDUCATION_REJECTED,
  payload: { error },
});

const userDeleteEducationSuccess = (data, post) => ({
  type: USER_DELETE_EDUCATION_FULFILLED,
  payload: data,
});

export const deleteEducation = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteEducationPending());
    return http
      .post(apiUrl.USER_DELETE_EDUCATION, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteEducationSuccess(response?.data, payload));
          return response;
        } else {
          dispatch(userDeleteEducationFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteEducationFailure(error));
     
        return error;
      });
  };
};

export const USER_MANAGE_REWARDS_FULFILLED = 'USER_MANAGE_REWARDS_FULFILLED';
export const USER_MANAGE_REWARDS_PENDING = 'USER_MANAGE_REWARDS_PENDING';
export const USER_MANAGE_REWARDS_REJECTED = 'USER_MANAGE_REWARDS_REJECTED';

const userManageRewardsPending = () => ({
  type: USER_MANAGE_REWARDS_PENDING,
});

const userManageRewardsFailure = (error) => ({
  type: USER_MANAGE_REWARDS_REJECTED,
  payload: { error },
});

const userManageRewardsSuccess = (data, post) => ({
  type: USER_MANAGE_REWARDS_FULFILLED,
  payload: data,
});

export const manageRewards = (payload) => {
  return (dispatch) => {
    dispatch(userManageRewardsPending());
    return http
      .post(apiUrl.USER_MANAGE_REWARDS, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userManageRewardsSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userManageRewardsFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userManageRewardsFailure(error));
  
        return error;
      });
  };
};

export const USER_MANAGE_PUBLICATION_FULFILLED =
  'USER_MANAGE_PUBLICATION_FULFILLED';
export const USER_MANAGE_PUBLICATION_PENDING =
  'USER_MANAGE_PUBLICATION_PENDING';
export const USER_MANAGE_PUBLICATION_REJECTED =
  'USER_MANAGE_PUBLICATION_REJECTED';

const userManagePublicationPending = () => ({
  type: USER_MANAGE_PUBLICATION_PENDING,
});

const userManagePublicationFailure = (error) => ({
  type: USER_MANAGE_PUBLICATION_REJECTED,
  payload: { error },
});

const userManagePublicationSuccess = (data, post) => ({
  type: USER_MANAGE_PUBLICATION_FULFILLED,
  payload: data,
});

export const managePublication = (payload) => {
  return (dispatch) => {
    dispatch(userManagePublicationPending());
    return http
      .post(apiUrl.USER_MANAGE_PUBLICATION, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userManagePublicationSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userManagePublicationFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userManagePublicationFailure(error));
 
        return error;
      });
  };
};

export const USER_MANAGE_INTEREST_FULFILLED = 'USER_MANAGE_INTEREST_FULFILLED';
export const USER_MANAGE_INTEREST_PENDING = 'USER_MANAGE_INTEREST_PENDING';
export const USER_MANAGE_INTEREST_REJECTED = 'USER_MANAGE_INTEREST_REJECTED';

const userManageInterestPending = () => ({
  type: USER_MANAGE_INTEREST_PENDING,
});

const userManageInterestFailure = (error) => ({
  type: USER_MANAGE_INTEREST_REJECTED,
  payload: { error },
});

const userManageInterestSuccess = (data) => ({
  type: USER_MANAGE_INTEREST_FULFILLED,
  payload: data,
});

export const manageInterest = (payload) => {
  return (dispatch) => {
    dispatch(userManageInterestPending());
    return http
      .post(apiUrl.USER_MANAGE_INTEREST, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userManageInterestSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userManageInterestFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userManageInterestFailure(error));
  
        return error;
      });
  };
};

export const USER_DELETE_INTEREST_FULFILLED = 'USER_DELETE_INTEREST_FULFILLED';
export const USER_DELETE_INTEREST_PENDING = 'USER_DELETE_INTEREST_PENDING';
export const USER_DELETE_INTEREST_REJECTED = 'USER_DELETE_INTEREST_REJECTED';

const userDeleteInterestPending = () => ({
  type: USER_DELETE_INTEREST_PENDING,
});

const userDeleteInterestFailure = (error) => ({
  type: USER_DELETE_INTEREST_REJECTED,
  payload: { error },
});

const userDeleteInterestSuccess = (data) => ({
  type: USER_DELETE_INTEREST_FULFILLED,
  payload: data,
});

export const deleteInterest = (payload) => {
  return (dispatch) => {
    dispatch(userDeleteInterestPending());
    return http
      .post(apiUrl.USER_DELETE_INTEREST, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(userDeleteInterestSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(userDeleteInterestFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(userDeleteInterestFailure(error));
   
        return error;
      });
  };
};

export const USER_MANAGE_LANGUAGE_FULFILLED = 'USER_MANAGE_LANGUAGE_FULFILLED';
export const USER_MANAGE_LANGUAGE_PENDING = 'USER_MANAGE_LANGUAGE_PENDING';
export const USER_MANAGE_LANGUAGE_REJECTED = 'USER_MANAGE_LANGUAGE_REJECTED';

const manageUserLanguagePending = () => ({
  type: USER_MANAGE_LANGUAGE_PENDING,
});

const manageUserLanguageFailure = (error) => ({
  type: USER_MANAGE_LANGUAGE_REJECTED,
  payload: { error },
});

const manageUserLanguageSuccess = (data) => ({
  type: USER_MANAGE_LANGUAGE_FULFILLED,
  payload: data,
});

export const manageUserLanguage = (payload) => {
  return (dispatch) => {
    dispatch(manageUserLanguagePending());
    return http
      .post(apiUrl.USER_MANAGE_LANGUAGE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(manageUserLanguageSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(manageUserLanguageFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(manageUserLanguageFailure(error));
   
        return error;
      });
  };
};

export const USER_DELETE_LANGUAGE_FULFILLED = 'USER_DELETE_LANGUAGE_FULFILLED';
export const USER_DELETE_LANGUAGE_PENDING = 'USER_DELETE_LANGUAGE_PENDING';
export const USER_DELETE_LANGUAGE_REJECTED = 'USER_DELETE_LANGUAGE_REJECTED';

const deleteUserLanguagePending = () => ({
  type: USER_DELETE_LANGUAGE_PENDING,
});

const deleteUserLanguageFailure = (error) => ({
  type: USER_DELETE_LANGUAGE_REJECTED,
  payload: { error },
});

const deleteUserLanguageSuccess = (data) => ({
  type: USER_DELETE_LANGUAGE_FULFILLED,
  payload: data,
});

export const deleteUserLanguage = (payload) => {
  return (dispatch) => {
    dispatch(deleteUserLanguagePending());
    return http
      .post(apiUrl.USER_DELETE_LANGUAGE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(deleteUserLanguageSuccess(response?.data, payload));

          return response;
        } else {
          dispatch(deleteUserLanguageFailure(response));
          showMessage({
            dispatch,
            message: ' Error',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(deleteUserLanguageFailure(error));
   
        return error;
      });
  };
};


export const USER_IS_VERIFIED_AND_MEMBERSHIP_FULFILLED =
  'USER_IS_VERIFIED_AND_MEMBERSHIP_FULFILLED';
export const USER_IS_VERIFIED_AND_MEMBERSHIP_PENDING = 'USER_IS_VERIFIED_AND_MEMBERSHIP_PENDING';
export const USER_IS_VERIFIED_AND_MEMBERSHIP_REJECTED =
  'USER_IS_VERIFIED_AND_MEMBERSHIP_REJECTED';

const userIsVerifiedNMembershipPending = () => ({
  type: USER_IS_VERIFIED_AND_MEMBERSHIP_PENDING,
});

const userIsVerifiedNMembershipFailure = (error) => ({
  type: USER_IS_VERIFIED_AND_MEMBERSHIP_REJECTED,
  payload: { error },
});

const userIsVerifiedNMembershipSuccess = (data, post) => ({
  type: USER_IS_VERIFIED_AND_MEMBERSHIP_FULFILLED,
  payload: data,
});

export const userIsVerifiedNMembership = (payload) => {
  return (dispatch) => {
    dispatch(userIsVerifiedNMembershipPending());
    return http
      .post(apiUrl.USER_IS_VERIFIED_AND_MEMBERSHIP, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(userIsVerifiedNMembershipSuccess(response?.data, payload));
          return response;
        } else {
          dispatch(userIsVerifiedNMembershipFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(userIsVerifiedNMembershipFailure(error));
        return error;
      });
  };
};

export const CLOSE_COMPANY_CHECK = 'CLOSE_COMPANY_CHECK';
export const OPEN_COMPANY_CHECK = 'OPEN_COMPANY_CHECK';

export const closeCompanyCheck = () => ({
  type: 'CLOSE_COMPANY_CHECK',
});

export const openCompanyCheck = (type, data) => {
  return {
    type: 'OPEN_COMPANY_CHECK',
    payload: { type: type, data: data },
  };
};
