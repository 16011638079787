import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Paper, Grid, Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import CloseIcon from "@mui/icons-material/Close";
import ManIcon from "@mui/icons-material/Man";
import CxoButtonWithIcon from "../CxoButtonWithIcon/CxoButtonWithIcon";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LandscapeIcon from "@mui/icons-material/Landscape";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PostWhite from "../../assets/Posts_White.png";
import Article_Gray from "../../assets/Article_Gray.png";
import moment from "moment";
import { dateTime } from "../../constants/app";

import { useSelector, useDispatch } from "react-redux";
import * as activityActions from "../../store/activity";

export default function BookmarksCard(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const timeago = () => {
    if (props?.list?.created_datetime === "Invalid date") {
      return "";
    } else {
      return moment(props?.list?.created_datetime).fromNow();
    }
  };

  const deleteBookmark = async (type) => {
    const data = {
      user_id: user?.id,
      type: type,
      content_id: props?.list?.id,
    };
    const bookmark = await dispatch(activityActions.deleteBookmark(data));
  };
  if (props?.list?.type === "post") {
    return (
      <Stack pt={2} spacing={0} direction="row">
        <Box
          sx={{
            minWidth: { xs: 120, md: 176 },
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 150, md: 250 },
            backgroundColor: "#272727",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Box
            pt={2}
            sx={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                height: "100px",
                borderRadius: "5px",
                backgroundColor: "#262525",
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
                alignItem: "center",
                cursor: "pointer",
                "@media (max-width: 500px)": {
                  height: "80px",
                },
              }}
              image={props?.list?.image ? props?.list?.image : PostWhite}
            />
          </Box>
        </Box>
        <Card
          sx={{
            minWidth: 272,
            width: "100%",
            backgroundColor: "#272727",
            "@media (max-width: 500px)": {
              minWidth: 190,
            },
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <CardHeader
                sx={{ paddingBottom: "0px" }}
                title={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h5s" component="h5s">
                      {props?.list?.first_name} {props?.list?.last_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontFamily: "Open Sans",
                        paddingTop: "3px",
                        color: "rgba(255, 255,255,0.5)",
                      }}
                    >
                      {timeago()}
                    </Typography>
                  </Stack>
                }
                subheader={<Box></Box>}
              />

              <CardContent sx={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ lineHeight: "1.4em" }}
                >
                  {props?.list?.content?.length > 200
                    ? props?.list?.content?.substring(0, 200) + "..."
                    : props?.list?.content}
                </Typography>
              </CardContent>

              <CardActions>
                <Box px={1.4}>
                  <Typography variant="h6s" component="h6s">
                    Saved From {props?.list?.first_name}' post
                  </Typography>
                </Box>
              </CardActions>
            </Stack>
            <Box
              py={5}
              px={2}
              sx={{
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
                alignItem: "center",
              }}
              onClick={() => deleteBookmark("post")}
            >
              <DeleteForeverOutlinedIcon />
            </Box>
          </Stack>
        </Card>
      </Stack>
    );
  } else if (props?.list?.type === "article") {
    return (
      <Stack pt={2} spacing={0} direction="row">
        <Box
          sx={{
            minWidth: { xs: 120, md: 176 },
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 150, md: 250 },
            // minWidth: { xs: 160, md: 176 },
            // maxHeight: { xs: 233, md: 167 },
            // maxWidth: { xs: 300, md: 250 },
            backgroundColor: "#272727",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Box
            pt={1.8}
            sx={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                height: "100px",
                borderRadius: "5px",
                backgroundColor: "#262525",
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
                alignItem: "center",
                cursor: "pointer",
              }}
              image={props?.list?.image ? props?.list?.image : Article_Gray}
            />
          </Box>
        </Box>
        <Card sx={{ minWidth: 272, width: "100%", backgroundColor: "#272727" }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <CardHeader
                sx={{ paddingBottom: "0px" }}
                title={
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        "@media (max-width: 500px)": {
                          fontSize: "14px",
                          lineHeight: "17px",
                        },
                      }}
                    >
                      {props?.list?.heading}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontFamily: "Open Sans",
                        paddingTop: "3px",
                        color: "rgba(255, 255,255,0.5)",
                        "@media (max-width: 500px)": {
                          fontSize: "10px",
                        },
                      }}
                    >
                      {timeago()}
                    </Typography>
                  </Stack>
                }
                subheader={<Box></Box>}
              />

              <CardContent sx={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    lineHeight: "1.4em",
                    "@media (max-width: 500px)": {
                      fontSize: "11px",
                      lineHeight: "1.2em",
                    },
                  }}
                >
                  {props?.list?.content?.length > 200
                    ? props?.list?.content?.substring(0, 200) + "..."
                    : props?.list?.content}
                </Typography>
              </CardContent>

              <CardActions>
                <Box px={1.4}>
                  <Typography variant="h6s" component="h6s">
                    Saved From {props?.list?.first_name}' post
                  </Typography>
                </Box>
              </CardActions>
            </Stack>

            <Box
              py={5}
              px={2}
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => deleteBookmark("article")}
            >
              <DeleteForeverOutlinedIcon />
            </Box>
          </Stack>
        </Card>
      </Stack>
    );
  } else if (props?.list?.type === "news") {
    return (
      <Stack pt={2} spacing={0} direction="row">
        <Box
          sx={{
            // minWidth: { xs: 160, md: 176 },
            // maxHeight: { xs: 233, md: 167 },
            // maxWidth: { xs: 300, md: 250 },
            minWidth: { xs: 120, md: 176 },
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 150, md: 250 },
            backgroundColor: "#272727",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Box
            pt={1.8}
            sx={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                height: "100px",
                borderRadius: "5px",
                backgroundColor: "#262525",
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
                alignItem: "center",
                cursor: "pointer",
              }}
              image={props?.list?.image ? props?.list?.image : Article_Gray}
            />
          </Box>
        </Box>
        <Card sx={{ minWidth: 272, width: "100%", backgroundColor: "#272727" }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <CardHeader
                sx={{ paddingBottom: "0px" }}
                title={
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="h5s"
                      component="h5s"
                      sx={{
                        "@media (max-width: 500px)": {
                          fontSize: "14px",
                          lineHeight: "17px",
                        },
                      }}
                    >
                      {props?.list?.heading}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontFamily: "Open Sans",
                        paddingTop: "3px",
                        color: "rgba(255, 255,255,0.5)",
                        "@media (max-width: 500px)": {
                          fontSize: "10px",
                        },
                      }}
                    >
                      {timeago()}
                    </Typography>
                  </Stack>
                }
                subheader={<Box></Box>}
              />

              <CardContent sx={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    lineHeight: "1.4em",
                    "@media (max-width: 500px)": {
                      fontSize: "11px",
                      lineHeight: "1.2em",
                    },
                  }}
                >
                  {props?.list?.content?.length > 200
                    ? props?.list?.content?.substring(0, 200) + "..."
                    : props?.list?.content}
                </Typography>
              </CardContent>

              <CardActions>
                <Box px={1.4}>
                  <Typography variant="h6s" component="h6s">
                    Saved From {props?.list?.first_name}' post
                  </Typography>
                </Box>
              </CardActions>
            </Stack>

            <Box
              py={5}
              px={2}
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => deleteBookmark("article")}
            >
              <DeleteForeverOutlinedIcon />
            </Box>
          </Stack>
        </Card>
      </Stack>
    );
  }
  else if(props?.type === "clubs"){
    return(   <Stack pt={2} spacing={0} direction="row">
    <Box
      sx={{
        // minWidth: { xs: 160, md: 176 },
        // maxHeight: { xs: 233, md: 167 },
        // maxWidth: { xs: 300, md: 250 },
        minWidth: { xs: 120, md: 176 },
        maxHeight: { xs: 233, md: 167 },
        maxWidth: { xs: 150, md: 250 },
        backgroundColor: "#272727",
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        alignItem: "center",
      }}
    >
      <Box
        pt={1.8}
        sx={{
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
          alignItem: "center",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            height: "100px",
            borderRadius: "5px",
            backgroundColor: "#262525",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
            alignItem: "center",
            cursor: "pointer",
          }}
          image={props?.list?.image ? props?.list?.image : Article_Gray}
        />
      </Box>
    </Box>
    <Card sx={{ minWidth: 272, width: "100%", backgroundColor: "#272727" }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column">
          <CardHeader
            sx={{ paddingBottom: "0px" }}
            title={
              <Stack direction="row" spacing={1}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    "@media (max-width: 500px)": {
                      fontSize: "14px",
                      lineHeight: "17px",
                    },
                  }}
                >
                  {props?.list?.title}
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontFamily: "Open Sans",
                    paddingTop: "3px",
                    color: "rgba(255, 255,255,0.5)",
                    "@media (max-width: 500px)": {
                      fontSize: "10px",
                    },
                  }}
                >
                  {timeago()}
                </Typography>
              </Stack>
            }
            subheader={<Box></Box>}
          />

          <CardContent sx={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                lineHeight: "1.4em",
                "@media (max-width: 500px)": {
                  fontSize: "11px",
                  lineHeight: "1.2em",
                },
              }}
            >
              {props?.list?.detail?.length > 200
                ? props?.list?.detail?.substring(0, 200) + "..."
                : props?.list?.detail}
            </Typography>
          </CardContent>

          <CardActions>
            <Box px={1.4}>
              <Typography variant="h6s" component="h6s">
                Saved From {props?.list?.first_name} club
              </Typography>
            </Box>
          </CardActions>
        </Stack>

        <Box
          py={5}
          px={2}
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => deleteBookmark("article")}
        >
          <DeleteForeverOutlinedIcon />
        </Box>
      </Stack>
    </Card>
  </Stack>)
  }else{
    return(
      <Box></Box>
    )
  }
}
