import React, { Fragment, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Box, Typography, Stack, TextField } from "@mui/material";
import CxoInputCss from "../../components/CxoInputCss/CxoInputCss";
import Menuicon from "../../assets/menuicon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ListItemText from "@mui/material/ListItemText";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import AntSwitch from "../../components/AntSwitch/AntSwitch";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Checkbox from "@mui/material/Checkbox";
import CxoButton from "../../components/CxoButton/CxoButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import EventsIcon from "../../assets/eventsicon";
import InterestIcon from "../../assets/chooseurinterest";
import { alpha, styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import * as jobsActions from "../../store/jobs";
import * as activityActions from "../../store/activity";
import * as onboardActions from "../../store/onboard";
import * as authActions from "../../store/auth";
import * as eventsActions from "../../store/events";
import * as usernetworkActions from "../../store/usernetwork";
import { dateTime } from "../../constants/app";
import { useNavigate, useLocation } from "react-router-dom";
import * as messageAction from "../../store/message";
import MuiCheckbox from "@mui/material/Checkbox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';


import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: "#38CD84",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function CheckboxCss({ label, icon, checkedIcon, value, onChange }) {
  console.log("CheckboxCss", value);
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          icon={icon}
          color="secondary"
          checked={value}
          value={value}
          onChange={onChange}
          checkedIcon={checkedIcon}
        />
      }
    />
  );
}

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    "& .MuiOutlinedInput-input": {
      color: "green",
    },
    "& .MuiSelect-select": {
      color: "#ffffff",
    },
    "& .MuiSelect-outlined": {
      color: "green",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      color: "green",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "purple",
    },
    "& MuiInputLabel-root": {
      color: theme.palette.palette.text.secondary,
    },
    "& label.Mui-focused": {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : "Raleway",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : "Raleway",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: "1px solid #FFFFFF",
  lineHeight: "21px",
  letterSpacing: "0.19px",
  fontSize: "16px",
  padding: "8px",
  marginTop: "16px",
  "& .MuiSlider-thumb": {
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    "&.Mui-active": {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  "& .MuiChip-deleteIcon": {
    color: "#38CD84",
  },
}));

const EventEdit = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const industryList = useSelector((state) => state?.onboard?.industryList);
  const functionList = useSelector((state) => state?.onboard?.functionList);
  const interestList = useSelector((state) => state?.onboard?.interestList);
  const existingNetwork = useSelector(
    (state) => state?.usernetwork?.ExistingNetworkList
  );

  const eventEdit = useSelector((state) => state?.events?.eventEdit);
  const [industrylst, setIndustrylst] = useState([]);
  const [functionlst, setFunctionlst] = useState([]);
  const [interestlst, setInterestlst] = useState([]);
  const [inviteesId, setInviteesId] = useState([]);
  const [invitees, setInvitees] = useState("all");
  const [hosttype, setHostType] = useState("Individual");
  const [eventType, setEventType] = React.useState("Public");
  const [eventMode, setEventMode] = React.useState("Online");
  const [currency, setCurrency] = React.useState("₹ (INR)");
  const [eventfee, setEventFee] = React.useState(true);
  const [datevalue, setValue] = React.useState(dayjs(new Date()));
  const [startDate, setStartDate] = React.useState(dayjs(new Date()));
  const [endDate, setEndDate] = React.useState(dayjs(new Date()));
  const [startTime, setStartTime] = React.useState(dayjs(new Date()));
  const [endTime, setEndTime] = React.useState(dayjs(new Date()));
  const [imgData, setImgData] = useState(null);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [showPreview, setPreview] = useState(false);
  const [doctitleShow, setDoctitleShow] = useState(false);
  const [networklst, setNetworklst] = useState([]);
  const [alreadyinvite, setAlready] = useState([]);
  const [showManual, setManual] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const eventAttendingMembers = useSelector(
    (state) => state?.events?.eventAttendingMembers
  );

  const eventRequestMembers = useSelector(
    (state) => state?.events?.eventRequestMembers
  );
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);
  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getUserInterestList());
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const existinglist = await dispatch(
      usernetworkActions.getExistingNetwork(data)
    );
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrylst(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const interestChange = (event) => {
    const {
      target: { value },
    } = event;
    setInterestlst(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleNetworkChange = (event) => {
    const {
      target: { value },
    } = event;
    setNetworklst(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(async () => {
    const data = {
      event_id: eventEdit?.id,
    };
    const eventList = await dispatch(eventsActions.eventInvitedMembers(data));
    console.log("eventList", eventList);
    if (eventList?.status !== -1) {
      let invitees = eventList?.data?.map((invited, index) => {
        return invited?.id;
      });
      console.log("invitees", invitees);
      setAlready([...alreadyinvite, ...invitees]);
    }
  }, []);

  useEffect(async () => {
    let result = eventEdit?.categories?.map((category) => category?.id);

    setInterestlst(result);
  }, []);

  useEffect(async () => {
    let result = eventEdit?.industries?.map((industry) => {
      return industry?.id;
    });

    setIndustrylst(result);
  }, []);

  useEffect(async () => {
    let result = eventEdit?.functions?.map((functin) => functin?.id);
    setFunctionlst(result);
  }, []);

  useEffect(async () => {
    setEventMode(eventEdit?.mode);
  }, []);

  useEffect(async () => {
    setHostType(eventEdit?.event_type);
  }, []);

  useEffect(async () => {
    setEventType("Public");
  }, []);

  useEffect(async () => {
    // setStartDate(dayjs(eventEdit?.start_date));
  }, []);

  useEffect(async () => {
    // let time = "May 4, 2022 " + eventEdit?.start_time;
    // console.log("start_time", time, moment(time).format("YYYY-MM-DD HH:mm:ss"));
    // setStartTime(dayjs(time));
  }, []);

  useEffect(async () => {
    // let time = "May 4, 2022 " + eventEdit?.end_time;
    // console.log("start_time", time, moment(time).format("YYYY-MM-DD HH:mm:ss"));
    // setEndTime(dayjs(time));
  }, []);
  useEffect(async () => {
    // setEndDate(dayjs(eventEdit?.end_date));
  }, []);

  useEffect(async () => {
    // setStartTime(moment(eventEdit?.start_time  ).format("HH:mm:ss"))
  }, []);

  useEffect(async () => {
    // setEndTime(moment(eventEdit?.end_time).format("HH:mm:ss"))
  }, []);

  // useEffect(async () => {
  //   setFile(eventEdit?.image_url)
  //   }, []);

  useEffect(async () => {
    console.log("eventfee", eventfee);
    if (eventEdit?.fee == 0) {
      setEventFee(true);
      console.log("eventfee1", eventfee);
    } else {
      setEventFee(false);
      console.log("eventfee2", eventfee);
    }
  }, []);

  const hosttypeChange = (event) => {
    setHostType(event.target.value);
  };

  const handleEventType = (event) => {
    setEventType("public");
  };
  const handleEventMode = (event) => {
    setEventMode(event.target.value);
  };
  const currencyChange = (event) => {
    setCurrency(event.target.value);
  };
  const changeFee = () => {
    setEventFee(!eventfee);
  };
  const handledateChange = (newValue) => {
    setValue(newValue);
  };
  const handleStartdateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEnddateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleStartTimeChange = (newvalue) => {
    setStartTime(newvalue);
  };
  const handleEndTimeChange = (newvalue) => {
    setEndTime(newvalue);
  };
  // const handleInvitees = (e) => {
  //   setInvitees(e.target.value);
  // };

  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // const sendInvitees = await dispatch(eventsActions.closeSendInviteesEvent());
    setManual(false);
  };

  useEffect(async () => {
    const data = {
      event_id: eventEdit?.id,
    };
    const eventList = await dispatch(eventsActions.eventAttendingMembers(data));
    if (eventList?.status !== -1) {
      let attending = eventAttendingMembers?.map((invited, index) => {
        return invited?.id;
      });
      setAlready([...alreadyinvite, ...attending]);
    }
  }, []);

  useEffect(async () => {
    const data = {
      event_id: eventEdit?.id,
    };
    const eventList = await dispatch(eventsActions.eventRequestedMembers(data));
    if (eventList?.status !== -1) {
      let request = eventRequestMembers?.map((invited, index) => {
        return invited?.id;
      });
      setAlready([...alreadyinvite, ...request]);
    }
  }, []);

  const handleInvitees = (e) => {
    console.log(e.target.value);
    if (e.target.value === "manual") {
      setManual(true);
      setInvitees(e.target.value);
    } else {
      setInvitees(e.target.value);
    }
  };
  const getPhoto = async (event) => {
    setDoctitleShow(false);
    setPreview(true);
    setFile(event.target.files[0]);
    setType("image");
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmitform = async (data) => {
    console.log(
      "data",
      data,
      eventType,
      eventfee,
      eventMode,
      hosttype,
      moment(startDate).format("YYYY-MM-DD"),
      moment(startTime).format("HH:mm:ss"),
      moment(endDate).format("YYYY-MM-DD"),
      moment(endTime).format("HH:mm:ss")
    );
    let invitees = existingNetwork?.map((network, index) => {
      return network?.id;
    });
    const formData = new FormData();
    formData.append("id", eventEdit?.id);
    formData.append("title", data?.title);
    formData.append("details", data?.details);
    formData.append("type", eventType);
    formData.append("mode", eventMode);
    formData.append("currency", currency);
    formData.append("start_date", moment(startDate).format("YYYY-MM-DD"));
    formData.append("end_date", moment(endDate).format("YYYY-MM-DD"));
    formData.append("start_time", moment(startTime).format("HH:mm:ss"));
    formData.append("end_time", moment(endTime).format("HH:mm:ss"));
    formData.append("email", data?.email);
    formData.append("user_id", user?.id);
    formData.append("created_at", dateTime());
    formData.append("updated_at", dateTime());
    formData.append("user_type", eventEdit?.user_type);
    formData.append("user_table_pk", eventEdit?.user_table_pk);
    formData.append("aIndustry", JSON.stringify(industrylst));
    formData.append("aFunction", JSON.stringify(functionlst));
    formData.append("aCategory", JSON.stringify(interestlst));
    formData.append("club_id", eventEdit?.user_table_pk);
    formData.append("invities", JSON.stringify([]));
    formData.append("event_type", eventEdit?.event_type);
    if (file) {
      formData.append("image", file);
    } else {
      formData.append("image", eventEdit?.image_url);
    }
    if (eventMode === "Online") {
      formData.append("venue", "");
      formData.append("event_link", data?.link);
    } else if (eventMode === "Offline") {
      formData.append("venue", data?.venue);
      formData.append("event_link", "");
    }
    if (eventfee) {
      formData.append("fee", 0);
    } else {
      formData.append("fee", parseInt(data?.fee));
    }
    formData.append("external_event_link", "");

    if (
      industrylst?.length > 0 &&
      functionlst?.length > 0 &&
      interestlst?.length > 0
    ) {
      const createevent = await dispatch(
        eventsActions.eventUpdate(formData, 0, 0, (error, response) => {
          if (response) {
            console.log("createevent", error, response);
            reset();
            setIndustrylst([]);
            setFunctionlst([]);
            const eventdetails = eventEdit?.title + "-" + "lx" + eventEdit?.id;
            navigate("/event/details/" + eventdetails);
          }
        })
      );
    } else {
      let msg = "All Fields are Mandatory";
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, "error")
      );
    }
  };

  return (
    <Fragment>
      {showManual && (
        <Fragment>
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              zIndex: "1001",
            }}
          >
            <Backdrop sx={{ color: "#fff", zIndex: "2001" }} open={showManual}>
              <Box px={0}>
                <Box
                  pt={2}
                  sx={{
                    width: "100%",
                    width: 500,
                    "@media (max-width: 500px)": {
                      width: 300,
                    },
                  }}
                >
                  <Paper
                    sx={{ backgroundColor: "#232121", backgroundImage: "none" }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={0}
                    >
                      <Box
                        px={3}
                        py={2}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <Typography variant="h2s" component="h2s">
                          Send Invite
                        </Typography>
                      </Box>
                      <Box pb={2} pr={2}>
                        <CloseIcon
                          sx={{
                            color: "#ffffff",
                            cursor: "pointer",
                            fontSize: "24",
                          }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Stack direction="column" justifyContent="center">
                        <Box
                          sx={{
                            width: "400px",
                            "@media (max-width: 500px)": {
                              width: "280px",
                            },
                          }}
                        >
                          <Box
                            py={1}
                            pl={1}
                            sx={{
                              width: "96%",
                            }}
                          >
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                style={{
                                  color: "#38CD84",
                                  fontSize: "13px",
                                }}
                                id="demo-simple-select-label"
                              >
                                Network
                              </InputLabel>
                              <CssSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={networklst}
                                multiple
                                label="Industry List"
                                onChange={handleNetworkChange}
                                size="small"
                                className={classes.underline}
                                color="secondary"
                                sx={{ color: "#38CD84" }}
                                renderValue={(selected) => {
                                  console.log("selected", selected);
                                  return selected.length + " " + "Selected";
                                }}
                              >
                                {existingNetwork
                                  ? existingNetwork?.map((item, key) => {
                                      if (
                                        alreadyinvite?.indexOf(item?.id) === -1
                                      ) {
                                        return (
                                          <MenuItem
                                            color="secondary"
                                            key={key}
                                            value={item?.id}
                                          >
                                            <ListItemText
                                              color="secondary"
                                              primary={
                                                item?.first_name +
                                                " " +
                                                item?.last_name
                                              }
                                            />
                                            <Checkbox
                                              color="secondary"
                                              icon={
                                                <RadioButtonUncheckedIcon />
                                              }
                                              checkedIcon={<CheckCircleIcon />}
                                              checked={
                                                networklst?.indexOf(item.id) >
                                                -1
                                              }
                                            />
                                          </MenuItem>
                                        );
                                      }
                                    })
                                  : ""}
                              </CssSelect>
                            </FormControl>
                          </Box>
                          <Box p={4}></Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Backdrop>
          </Box>
        </Fragment>
      )}

      <Box px={2} pt={2} sx={{ width: "100%" }}>
        <Paper sx={{ backgroundColor: "#232121", backgroundImage: "none" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0}
          >
            <Box
              py={3}
              px={3}
              sx={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5s"
                component="h5s"
                sx={{ fontWeight: 600 }}
              >
                Edit Event
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>
      <Box px={2} pt={2} sx={{ width: "100%" }}>
        <Paper sx={{ backgroundColor: "#232121", backgroundImage: "none" }}>
          <Box>
            <Card sx={{ width: "100%", position: "relative" }}>
              <CardMedia
                component="img"
                src={imgData ? imgData : eventEdit?.image_url}
                height="230"
                image=""
                sx={{ backgroundColor: "#333" }}
              />
              <Stack
                direction="column"
                sx={{
                  position: "absolute",
                  top: "50px",
                  left: "40%",
                  margin: "auto",
                  "@media (max-width: 500px)": {
                    left: "23%",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label htmlFor="icon-button-file">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "64px",
                        maxWidth: "min-content",
                        minWidth: "64px",
                        // "@media (max-width: 500px)": {
                        //   height: "30px",
                        //   minWidth: "30px",
                        // },
                      }}
                    >
                      <EventsIcon name="events_add_banner" color="#38CD84" />
                    </Box>
                  </label>
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={getPhoto}
                  />
                </Box>
                <Box
                  py={2}
                  px={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6s" component="h6s">
                    Add Banner Image/Video*
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>

          <Stack direction="column" justifyContent="center" spacing={1}>
            <Box px={{ md: 16, xs: 0 }} pt={2}>
              <form onSubmit={handleSubmit(handleSubmitform)}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <EventsIcon name="event_name" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <CxoInputCss
                            label="Title *"
                            value={value}
                            err={errors?.title ? errors?.title : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: "9px",
                              height: "10px",
                              paddingLeft: "8px",
                            }}
                          >
                            {errors?.title?.message}{" "}
                          </Box>
                        </Stack>
                      )}
                      name="title"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: "Event Title is required",
                        },
                      }}
                      defaultValue={eventEdit?.title ? eventEdit?.title : ""}
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            minWidth: "18px",
                            height: "18px",
                            marginLeft: "-8px",
                            marginTop: "-8px",
                          },
                        }}
                      >
                        <InterestIcon name="Others" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Typography variant="h6s" component="h6s">
                      Details *
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      ></Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <TextField
                            variant="standard"
                            fullWidth
                            multiline
                            rows={6}
                            maxRows={8}
                            value={value}
                            onChange={onChange}
                            sx={{
                              backgroundColor: "#262525",
                              marginLeft: "8px",
                              padding: "8px",
                              borderRadius: "5px",
                              border: "2px solid #959498",
                            }}
                            placeholder="Start Typing Here"
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                          <Box
                            sx={{
                              fontSize: "9px",
                              height: "10px",
                              paddingLeft: "8px",
                            }}
                          >
                            {errors?.title?.message}{" "}
                          </Box>
                        </Stack>
                      )}
                      name="details"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: "Event Details is required",
                        },
                      }}
                      defaultValue={
                        eventEdit?.details ? eventEdit?.details : ""
                      }
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <Menuicon name="industry" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: "96%" }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: "#38CD84",
                          fontSize: "13px",
                        }}
                        id="demo-simple-select-label"
                      >
                        Industry
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: "12px",
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: "menu",
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industrylst}
                        multiple
                        label="Industry List"
                        onChange={handleChange}
                        size="small"
                        className={classes.underline}
                        color="secondary"
                        sx={{ color: "#38CD84" }}
                        renderValue={(selected) => {
                          console.log("selected", selected);
                          return selected.length + " " + "Selected";
                        }}
                      >
                        {industryList?.data
                          ? industryList?.data.map((item, key) => {
                              return (
                                <MenuItem
                                  color="secondary"
                                  key={key}
                                  value={item.id}
                                >
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.substring(0, 25) + "..."
                                    }
                                  />
                                  <Checkbox
                                    color="secondary"
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    checked={industrylst?.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ""}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "20px",
                            minWidth: "20px",
                          },
                        }}
                      >
                        <Menuicon name="functions" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: "96%" }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: "#38CD84",
                          fontSize: "13px",
                        }}
                        id="demo-simple-select-label"
                      >
                        Function
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: "12px",
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: "menu",
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={functionlst}
                        label="Function"
                        multiple
                        className={classes.underline}
                        onChange={functionChange}
                        size="small"
                        renderValue={(selected) => {
                          console.log("selected", selected);
                          return selected.length + " " + "Selected";
                        }}
                      >
                        {functionList?.data
                          ? functionList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item?.title.substring(0, 25) + "..."
                                    }
                                  />
                                  <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="secondary"
                                    checked={functionlst?.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ""}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <EventsIcon name="category" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: "96%" }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: "#38CD84",
                          fontSize: "13px",
                        }}
                        id="demo-simple-select-label"
                      >
                        Category
                      </InputLabel>
                      <CssSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={interestlst}
                        label="Category"
                        multiple
                        className={classes.underline}
                        onChange={interestChange}
                        size="small"
                        renderValue={(selected) => {
                          console.log("selected", selected);
                          return selected.length + " " + "Selected";
                        }}
                      >
                        {interestList?.data
                          ? interestList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={item.title}
                                  />
                                  <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="secondary"
                                    checked={interestlst?.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ""}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Fragment>
                    <Box
                      pt={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "24px",
                            maxWidth: "min-content",
                            minWidth: "24px",
                            "@media (max-width: 500px)": {
                              height: "18px",
                              minWidth: "18px",
                            },
                          }}
                        >
                          <EventsIcon name="event_mode" color="#38CD84" />
                        </Box>
                      </Box>
                    </Box>
                    <Box pt={0.5} px={2} sx={{ width: "100%" }}>
                      <Stack spacing={2} direction="row">
                        <FormControl>
                          <FormLabel
                            color="secondary"
                            id="demo-row-radio-buttons-group-label"
                            sx={{
                              color: "#38CD84",
                              "@media (max-width: 500px)": {
                                fontSize: "13px",
                              },
                            }}
                          >
                            Mode *
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            color="secondary"
                            value={eventMode}
                            onChange={handleEventMode}
                          >
                            <FormControlLabel
                              value="Online"
                              color="secondary"
                              control={<Radio color="secondary" size="small" />}
                              label="Online"
                            />
                            <FormControlLabel
                              value="Offline"
                              color="secondary"
                              control={<Radio color="secondary" size="small" />}
                              label="Offline"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    </Box>
                  </Fragment>
                </Stack>

                {eventMode === "Online" && (
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "24px",
                            maxWidth: "min-content",
                            minWidth: "24px",
                            "@media (max-width: 500px)": {
                              height: "18px",
                              minWidth: "18px",
                            },
                          }}
                        >
                          <EventsIcon name="event_link" color="#38CD84" />
                        </Box>
                      </Box>
                    </Box>

                    <Box pl={0.5} sx={{ width: "95%" }}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack>
                            <CxoInputCss
                              label="Link*"
                              value={value}
                              err={errors?.link ? errors?.link : false}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: "9px",
                                height: "10px",
                                paddingLeft: "8px",
                              }}
                            >
                              {errors?.link?.message}{" "}
                            </Box>
                          </Stack>
                        )}
                        name="link"
                        required
                        rules={{
                          required: {
                            value: true,
                            message: "Event Link is required",
                          },
                        }}
                        defaultValue={
                          eventEdit?.event_link ? eventEdit?.event_link : ""
                        }
                      />
                    </Box>
                  </Stack>
                )}
                {eventMode === "Offline" && (
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "24px",
                            maxWidth: "min-content",
                            minWidth: "24px",
                            "@media (max-width: 500px)": {
                              height: "20px",
                              minWidth: "20px",
                            },
                          }}
                        >
                          <Menuicon name="location" color="#38CD84" />
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack>
                            <CxoInputCss
                              label="Venue *"
                              value={value}
                              err={errors?.venue ? errors?.venue : false}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: "9px",
                                height: "10px",
                                paddingLeft: "8px",
                              }}
                            >
                              {errors?.venue?.message}{" "}
                            </Box>
                          </Stack>
                        )}
                        name="venue"
                        required
                        rules={{
                          required: {
                            value: true,
                            message: "Venue is required",
                          },
                        }}
                        defaultValue={eventEdit?.venue ? eventEdit?.venue : ""}
                      />
                    </Box>
                  </Stack>
                )}

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                        }}
                      >
                        <EventsIcon name="event_fee" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1.8} sx={{ width: "25%" }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: "#38CD84",
                          fontSize: "13px",
                        }}
                        id="demo-simple-select-label"
                      >
                        Fee
                      </InputLabel>
                      <CssSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Fee*"
                        className={classes.underline}
                        value={currency}
                        onChange={currencyChange}
                        size="small"
                      >
                        <MenuItem key={0} value="₹ (INR)">
                          ₹ (INR)
                        </MenuItem>
                      </CssSelect>
                    </FormControl>
                  </Box>

                  <Box sx={{ width: "30%" }}>
                    {!eventfee && (
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack>
                            <CxoInputCss
                              label="Price *"
                              value={value}
                              err={errors?.fee ? errors?.fee : false}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: "9px",
                                height: "10px",
                                paddingLeft: "8px",
                              }}
                            >
                              {errors?.fee?.message}{" "}
                            </Box>
                          </Stack>
                        )}
                        name="fee"
                        required
                        rules={{
                          required: {
                            value: true,
                            message: "Price is required",
                          },
                        }}
                        defaultValue={eventEdit?.fee ? eventEdit?.fee : ""}
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      paddingLeft: "16px",
                    }}
                  >
                    <CheckboxCss
                      label="Free"
                      value={eventfee}
                      onChange={changeFee}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <EventsIcon name="event_start" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pt={0.5} px={2} sx={{ width: "100%" }}>
                    <Stack spacing={2} direction="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="Start Date"
                            value={startDate}
                            onChange={handleStartdateChange}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Stack>
                  </Box>
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <Menuicon name="calendar" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box pt={0.5} px={2} sx={{ width: "100%" }}>
                    <Stack spacing={2} direction="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['MobileTimePicker']}>

                            <TimePicker label="Start Time"
                                value={startTime}
                                onChange={handleStartTimeChange}
                            />

                        </DemoContainer>
                      </LocalizationProvider>
                    </Stack>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row" sx={{ paddingTop: "8px" }}>
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <EventsIcon name="event_end" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pt={0.5} px={2} sx={{ width: "100%" }}>
                    <Stack spacing={2} direction="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEnddateChange}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Stack>
                  </Box>
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <Menuicon name="calendar" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box pt={0.5} px={2} sx={{ width: "100%" }}>
                    <Stack spacing={2} direction="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['MobileTimePicker']}>
                        <TimePicker label="End Time"
                            value={endTime}
                            onChange={handleEndTimeChange}
                        />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Stack>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row" sx={{ paddingTop: "8px" }}>
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "18px",
                          maxWidth: "min-content",
                          minWidth: "18px",
                          width: "28px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                            width: "22px",
                          },
                        }}
                      >
                        <Menuicon name="email" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pl={0.7} sx={{ width: "96%" }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack sx={{ paddingRight: "16px" }}>
                          <CxoInputCss
                            label="Email Id"
                            value={value}
                            err={errors?.email ? errors?.email : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: "9px",
                              height: "10px",
                              paddingLeft: "8px",
                            }}
                          >
                            {errors?.email?.message}{" "}
                          </Box>
                        </Stack>
                      )}
                      name="email"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: "Email is required",
                        },
                      }}
                      defaultValue={user?.email ? user?.email : ""}
                    />
                  </Box>
                </Stack>

                {/* <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "24px",
                          maxWidth: "min-content",
                          minWidth: "24px",
                          "@media (max-width: 500px)": {
                            height: "18px",
                            minWidth: "18px",
                          },
                        }}
                      >
                        <EventsIcon name="event_occurence" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                   <Box sx={{ width: "100%", paddingLeft: "18px" }}>
                    <Stack spacing={2} direction="row">
                      <FormControl>
                        <FormLabel
                          color="secondary"
                          id="demo-row-radio-buttons-group-label"
                          sx={{
                            color: "#38CD84",
                            "@media (max-width: 500px)": {
                              fontSize: "13px",
                            },
                          }}
                        >
                          Invitees *
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          color="secondary"
                          value={invitees}
                          onClick={handleInvitees}
                        >
                          <FormControlLabel
                            value="manual"
                            color="secondary"
                            control={<Radio color="secondary" size="small" />}
                            label="Select Manually"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Box>
                </Stack> */}

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ paddingTop: "16px", paddingBottom: "16px" }}
                >
                  <CxoButton label="Update" />
                </Stack>
              </form>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default React.memo(EventEdit);

// <Stack direction="column">
//   <Box
//     sx={{
//       backgroundColor: "#333",
//       height: "150px",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//                         }}
//   >
//
//     <Stack direction="column">
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <label htmlFor="icon-button-file">
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "64px",
//               maxWidth: "min-content",
//               minWidth: "64px",
//             }}
//           >
//             <EventsIcon name="events_add_banner" color="#38CD84" />
//           </Box>
//         </label>
//         <input
//           accept="image/*"
//           id="icon-button-file"
//           type="file"
//           style={{ display: "none" }}
//           onChange={getPhoto}
//         />
//       </Box>
//       <Box
//         py={2}
//         px={2}
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Typography variant="h6s" component="h6s">
//           Add Banner Image/Video*
//         </Typography>
//       </Box>
//     </Stack>
//   </Box>
// </Stack>
