import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useStyles from './profilestyle';
import Divider from '@mui/material/Divider';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import InterestIcon from '../../assets/chooseurinterest';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import UnderVerification from '../../components/UnderVerification/UnderVerification';
import EditProfile from './EditProfile';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CxoButton from '../../components/CxoButton/CxoButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { alpha, styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as onboardActions from '../../store/onboard';
import * as userActions from '../../store/user';
import * as jobsActions from '../../store/jobs';
import * as messageActions from '../../store/message';
import * as rewardsActions from '../../store/rewards';
import { dateTime } from '../../constants/app';
import moment from 'moment';
import dayjs from 'dayjs';

const CssAccordion = styled(Accordion)(({ theme }) => {
  return {
    '& .MuiAccordion-root': {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0)',
    },
  };
});

const CssTab = styled(Tab)(({ theme }) => {
  return {
    '& .MuiTabs-indicator': {
      height: '4px',
    },
  };
});

// const CssChip = styled(Chip)(({ theme }) => {
//   return {
//     "& .MuiChip-root":{
//       color:"#38cd84"
//     }
//
//   };
// });

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Profile = (props) => {
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [section, setSection] = React.useState('');
  const [tab, setTab] = React.useState(0);
  const [edit, setEdit] = React.useState(true);
  const [editsection, setEditsection] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sectiondata, setSectiondata] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const handleaccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const userabouts = useSelector((state) => state?.user?.UserAbouts);

  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);
  useEffect(async () => {
    const data = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      self_id: user?.id,
      datetime: dateTime(),
    };
    const users = await dispatch(userActions.userProfileDetails(data));
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = {
        cand_id: user?.id,
      };
      const rewardscategorylist = await dispatch(
        jobsActions.getJobApplyCount(),
      );
    }
    fetchData();
  }, []);

  const handleChange = (newValue) => {
    setType(newValue);
  };
  const editProfile = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      navigate('/editprofile');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const editpub = (e, section) => {
    e.stopPropagation();
    setSection(section);
    setEditsection(!editsection);
  };

  const editSection = async (e, type, data) => {
    e.stopPropagation();
    const openeditsection = await dispatch(
      userActions.openUpdateSectionProfile(type, data),
    );
  };
  const addNew = async (e, type) => {
    e.stopPropagation();
    const openaddnew = await dispatch(userActions.openUpdateProfile(type));
  };

  const openComapny = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const openCompany = await dispatch(userActions.openCompanyCheck());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const handleDeleteInterest = async (id) => {
    const datayy = {
      id: id,
    };
    const deletesection = await dispatch(userActions.deleteInterest(datayy));
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
  };
  const handleDelete = async () => {
    const datayy = {
      id: sectiondata?.id,
    };
    if (section === 'education') {
      const deletesection = await dispatch(userActions.deleteEducation(datayy));
    } else if (section === 'experience') {
      const deletesection = await dispatch(
        userActions.deleteExperience(datayy),
      );
    } else if (section === 'interest') {
      const deletesection = await dispatch(userActions.deleteInterest(datayy));
    } else if (section === 'language') {
      const deletesection = await dispatch(
        userActions.deleteUserLanguage(datayy),
      );
    } else if (section === 'experience') {
      const deletesection = await dispatch(
        userActions.deleteExperience(datayy),
      );
    } else if (section === 'publication') {
      // delete publication

      const deletesection = await dispatch(
        userActions.deletePublication(datayy),
      );
    } else if (section === 'award') {
      // delete publication
      const deletesection = await dispatch(userActions.deleteAwards(datayy));
    } else if (section === 'affiliation') {
      // delete publication
      const deletesection = await dispatch(
        userActions.deleteAffiliation(datayy),
      );
    }
    const data = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(data));
    handleClose();
  };
  const deleteSection = (e, type, data) => {
    e.stopPropagation();
    setOpen(true);
    setSection(type);
    setSectiondata(data);
  };
  const handleClose = () => {
    setOpen(false);
    setSection('');
    setSectiondata({});
  };

  const showQrcode = async () => {
    if (user?.isVerified) {
      navigate(
        '/share/profile/' +
          user?.first_name +
          '-' +
          user?.last_name +
          '-lx' +
          user?.id,
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const handleDeleteIndustry = async (e, id) => {
    const deletesection = await dispatch(
      userActions.deleteIndustry({ id: id }),
    );
    const data = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(data));
  };

  const handleDeleteFunction = async (e, id) => {
    const deletesection = await dispatch(
      userActions.deleteFunction({ id: id }),
    );
    const data = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(data));
  };

  const handleMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };

  const manageCompanyPage = async () => {
    if (user?.isVerified) {
      navigate('/company/manage/page');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const createCompanyPage = async () => {
    if (user?.isVerified) {
      navigate('/company/create');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const saveResume = (resume) => {
    if (resume) {
      saveAs(resume, 'profile.pdf');
    }
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          sx={{
            fontSize: '24px',
            fontWeight: '600',
            color: '#38cd84',
            paddingTop: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          id="alert-dialog-title">
          Confirm
        </DialogTitle>
        <DialogContent
          sx={{
            width: '500px',
            paddingTop: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <DialogContentText
            sx={{ paddingTop: '15px' }}
            id="alert-dialog-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <Box sx={{ justifyContent: 'center', width: '100%' }} pb={2}>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }} >
            <CxoButton label="No" onClick={handleClose} mr={2}>
              Disagree
            </CxoButton>
            <CxoButton label="Yes" onClick={handleDelete} autoFocus ml={2}>
              Agree
            </CxoButton>
          </DialogActions>
        </Box>
      </Dialog>
      {edit ? (
        <Fragment>
          <Box p={1}>
            <Box sx={{ width: '100%' }}>
              <Paper
                sx={{
                  backgroundColor: '#232121',
                  backgroundImage: 'none',
                  overflowX: 'auto',
                }}>
                <Box
                  sx={{
                    height:
                      userdetails?.designation?.length +
                        userdetails?.company_name?.length >
                      30
                        ? '168px'
                        : '148px',
                    backgroundColor: '#2c2c2d',
                    '@media (max-width: 500px)': {
                      height:
                        userdetails?.designation?.length +
                          userdetails?.company_name?.length >
                        30
                          ? '100px'
                          : '100px',
                    },
                  }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <IconButton
                      aria-label="addfriend"
                      onClick={handleMenuOpen}
                      sx={{ margin: '16px' }}>
                      <MoreVertIcon />
                    </IconButton>
                    {anchorEl && (
                      <Box sx={{ marginLeft: '0px!important' }}>
                        <Box
                          anchorEl={anchorEl}
                          id="primary-search-account-menu"
                          keepMounted
                          sx={{
                            marginTop: '12px',
                            scrollbarWidth: 'thin',
                            position: 'absolute',
                            backgroundColor: '#2c2c2d',
                            height: 'auto',
                            width: '200px',
                            marginLeft: '-235px',
                            zIndex: '1000',
                            boxShadow:
                              '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                            borderRadius: '2px',
                          }}>
                          <Box>
                            <List>
                              <MenuItem
                                button
                                sx={{ padding: '0px 12px' }}
                                onClick={(e) => openComapny(e)}>
                                <ListItemText>Create Company Page</ListItemText>
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                button
                                sx={{ padding: '0px 12px' }}
                                onClick={(e) => manageCompanyPage(e)}>
                                <ListItemText>Manage Company Page</ListItemText>
                              </MenuItem>
                            </List>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Stack>
                </Box>
                <Stack spacing={1} direction="column">
                  <Box
                    px={{ md: 2, xs: 0.5 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      transform: ' translateY(-50%)',
                      marginBottom: '-60px',
                      '@media (max-width: 500px)': { marginBottom: '-30px' },
                    }}>
                    <Stack direction="row">
                      <Box pt={7} mr={6}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '48px',
                            maxWidth: 'min-content',
                            minWidth: '48px',
                            cursor: 'pointer',
                            backgroundColor: '#38cd84',
                            alignItems: 'center',
                            borderRadius: '50%',
                          }}
                          onClick={showQrcode}>
                          <QrCode2Icon sx={{ color: '#000000' }} />
                        </Box>
                      </Box>
                      <Avatar
                        src={
                          userdetails?.image ? userdetails?.image : PersonIcon
                        }
                        sx={{
                          width: 148,
                          height: 148,
                          border: '4px solid #38cd84',
                          '@media (max-width: 500px)': {
                            width: 110,
                            height: 110,
                          },
                        }}
                      />

                      <Box pt={7} ml={6}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '48px',
                            maxWidth: 'min-content',
                            minWidth: '48px',
                            cursor: 'pointer',
                          }}
                          onClick={editProfile}>
                          <Menuicon width="43" name="editcircle" />
                        </Box>
                      </Box>
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      transform: ' translateY(-50%)',
                    }}>
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        '@media (max-width: 500px)': {
                          fontSize: '18px',
                        },
                      }}>
                      {userdetails?.first_name
                        ? userdetails?.first_name?.charAt(0).toUpperCase() +
                          userdetails?.first_name?.slice(1)
                        : 'NA'}{' '}
                      {userdetails?.last_name
                        ? userdetails?.last_name?.charAt(0).toUpperCase() +
                          userdetails?.last_name?.slice(1)
                        : ''}
                    </Typography>
                  </Box>

                  <Box
                    pb={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-8px',
                      transform: ' translateY(-20%)',
                    }}>
                    <Stack spacing={0.5} direction="column" alignItems="center">
                      <Typography variant="h2" component="h2">
                        {userdetails?.designation
                          ? userdetails?.designation?.charAt(0).toUpperCase() +
                            userdetails?.designation?.slice(1)
                          : ''}
                      </Typography>
                      <Typography variant="h2" component="h2">
                        {userdetails?.designation
                          ? userdetails?.company_name?.charAt(0).toUpperCase() +
                            userdetails?.company_name?.slice(1)
                          : ''}
                      </Typography>
                      <Typography variant="h5" component="h5">
                        {userdetails?.location ? userdetails?.location : ''}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
                <Box px={{ xs: 2, md: 4 }} py={2}>
                  <Stack
                    spacing={{ md: 2, xs: 0.5 }}
                    py={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography variant="h2" component="h2">
                      Profile Completed:{' '}
                      {userdetails?.profile_percent
                        ? userdetails?.profile_percent
                        : 0}{' '}
                      %
                    </Typography>
                    <Typography
                      variant="h2"
                      component="h2"
                      // sx={{
                      //   "@media (max-width: 500px)": {
                      //     fontSize: "13px",
                      //   },
                      // }}
                    >
                      Last Updated:{' '}
                      {userdetails?.edited_datetime
                        ? userdetails?.edited_datetime
                        : 'few mins ago'}
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={{ md: 2, xs: 0.5 }}
                    py={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          fontWeight: '600',
                          fontFamily: 'Open Sans',
                          fontSize: '22px',
                          '@media (max-width: 500px)': {
                            fontSize: '16px',
                          },
                        }}>
                        {userdetails?.connection_count
                          ? userdetails?.connection_count
                          : 0}
                      </Typography>
                      <Typography variant="h2" component="h2">
                        Connections
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          fontWeight: '600',
                          fontFamily: 'Open Sans',
                          fontSize: '22px',
                          '@media (max-width: 500px)': {
                            fontSize: '16px',
                          },
                        }}>
                        {rewardsTotalCredits?.credits
                          ? parseInt(
                              rewardsTotalCredits?.credits,
                            ).toLocaleString()
                          : 0}
                      </Typography>
                      <Typography variant="h2" component="h2">
                        Credits
                      </Typography>
                    </Stack>
                  </Stack>

                  <Box py={{ xs: 1, md: 2 }}>
                    <Typography variant="h2s" component="h2s">
                      {userdetails?.profile_heading
                        ? userdetails?.profile_heading
                        : ''}
                    </Typography>
                  </Box>
                  <Box py={{ xs: 1, md: 2 }}>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      {userdetails?.about ? userdetails?.about : ''}
                    </Typography>
                  </Box>

                  {userdetails?.id != user?.id && (
                    <Box py={2}>
                      <Stack direction="row" spacing={5}>
                        <Box>
                          <Stack direction="row" spacing={2}>
                            <RemoveRedEyeOutlinedIcon />
                            <Typography variant="h2" component="h2">
                              View Resume
                            </Typography>
                          </Stack>
                        </Box>
                        <Box>
                          <Stack direction="row" spacing={2}>
                            <FileDownloadOutlinedIcon />
                            <Typography variant="h2" component="h2">
                              Download Resume
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    </Box>
                  )}
                  <Stack
                    py={{ md: 3, xs: 1 }}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center">
                    <Tooltip
                      title={userdetails?.mobile ? userdetails?.mobile : 'N.A'}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '42px',
                          maxWidth: 'min-content',
                          minWidth: '42px',
                          cursor: 'pointer',
                        }}>
                        <Menuicon
                          name="phone"
                          width="36"
                          color={userdetails?.mobile ? '#38CD84' : '#ffffff'}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={userdetails?.email ? userdetails?.email : 'N.A'}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '36px',
                          maxWidth: 'min-content',
                          minWidth: '36px',
                          cursor: 'pointer',
                        }}>
                        <Menuicon
                          name="email"
                          color={userdetails?.email ? '#38CD84' : '#ffffff'}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={
                        userdetails?.website ? userdetails?.website : 'N.A'
                      }>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '36px',
                          maxWidth: 'min-content',
                          minWidth: '36px',
                          cursor: 'pointer',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}>
                        <Menuicon name="website" color="#38CD84" />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={userdetails?.blog ? userdetails?.blog : 'N.A'}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          cursor: 'pointer',
                          '@media (max-width: 500px)': {
                            minWidth: '22px',
                            height: '22px',
                          },
                        }}>
                        <Menuicon
                          name="blog"
                          color={userdetails?.blog ? '#38CD84' : '#ffffff'}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={userdetails?.dob ? userdetails?.dob : 'N.A'}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '36px',
                          maxWidth: 'min-content',
                          minWidth: '36px',
                          cursor: 'pointer',
                          '@media (max-width: 500px)': {
                            marginTop: '-5px',
                          },
                        }}>
                        <Menuicon
                          name="dob"
                          color={userdetails?.dob ? '#38CD84' : '#ffffff'}
                        />
                      </Box>
                    </Tooltip>
                  </Stack>
                  <Divider />
                </Box>

                <Box py={{ md: 1, xs: 0 }}>
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="fullWidth"
                    aria-label="disabled tabs example"
                    sx={{
                      paddingLeft: '48px',
                      paddingRight: '48px',
                      paddingTop: '16px',
                      '@media (max-width: 500px)': {
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                      },
                    }}>
                    <CssTab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <InterestIcon
                            width={20}
                            name="other"
                            color="#38CD84"
                          />
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            About
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    />

                    <Tab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          {' '}
                          <Icon width={20} name="sponsers" color="#808080" />
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              color: '#808080',
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            Business
                          </Typography>
                        </Stack>
                      }
                      disabled
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    />
                    <Tab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          {' '}
                          <Menuicon width={20} name="meetups" color="#808080" />
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              color: '#808080',
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            Meetups
                          </Typography>
                        </Stack>
                      }
                      disabled
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <Box pl={{ xs: 0, md: 2 }} pr={{ xs: 0, md: 2 }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'skills'}
                        onChange={handleaccordionChange('skills')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={{ md: 2, xs: 1 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="skills" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 2.5, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Skills
                                </Typography>
                              </Box>
                              <Box
                                className={classes.editicon}
                                onClick={(e) =>
                                  editSection(e, 'skills', userabouts?.aSkill)
                                }>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'skills')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pl={{ md: 7, xs: 1 }} pb={2}>
                            {userabouts?.aSkill?.map((skill, index) => {
                              if (editsection && section === 'skills') {
                                return (
                                  <CssChip
                                    key={index}
                                    className={classes.chipmain}
                                    label={skill.title}
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <CssChip
                                    key={index}
                                    className={classes.chipmain}
                                    label={skill.title}
                                    variant="outlined"
                                  />
                                );
                              }
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'interest'}
                        onChange={handleaccordionChange('interest')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '36px',
                                  maxWidth: 'min-content',
                                  minWidth: '36px',
                                  width: '36px',
                                }}>
                                <Menuicon name="interests" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 1, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Interests
                                </Typography>
                              </Box>
                              <Box
                                className={classes.editicon}
                                sx={{ paddingTop: '6px' }}
                                onClick={(e) => editpub(e, 'interest')}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'interest')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pl={{ md: 7, xs: 1 }} pb={2}>
                            {userabouts?.aInterest?.map((interest, index) => {
                              return interest?.title
                                ?.split(',')
                                .map((interst, index) => {
                                  if (editsection && section === 'interest') {
                                    return (
                                      <CssChip
                                        key={index}
                                        className={classes.chipmain}
                                        label={interst}
                                        onDelete={() =>
                                          handleDeleteInterest(interest.id)
                                        }
                                        variant="outlined"
                                      />
                                    );
                                  } else {
                                    return (
                                      <CssChip
                                        key={index}
                                        className={classes.chipmain}
                                        label={interst}
                                        variant="outlined"
                                      />
                                    );
                                  }
                                });
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'industry'}
                        onChange={handleaccordionChange('industry')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="industry" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 2.5, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Industry
                                </Typography>
                              </Box>
                              <Box
                                className={classes.editicon}
                                onClick={(e) => editpub(e, 'industry')}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'industry')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pl={{ md: 7, xs: 1 }} pb={2}>
                            {userabouts?.aIndustryId?.map((industry, index) => {
                              if (editsection && section === 'industry') {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{ marginLeft: '4px', marginTop: '4px' }}
                                    label={industry.industry}
                                    onDelete={(e) =>
                                      handleDeleteIndustry(
                                        e,
                                        industry?.saved_id,
                                      )
                                    }
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{
                                      marginLeft: '4px',
                                      marginRight: '4px',
                                      marginTop: '4px',
                                    }}
                                    label={industry.industry}
                                    variant="outlined"
                                  />
                                );
                              }
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'function'}
                        onChange={handleaccordionChange('function')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="functions" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Function
                                </Typography>
                              </Box>
                              <Box
                                className={classes.editicon}
                                onClick={(e) => editpub(e, 'function')}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'function')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pl={{ md: 7, xs: 1 }} pb={2}>
                            {userabouts?.aFunctionId?.map(
                              (functions, index) => {
                                if (editsection && section === 'function') {
                                  return (
                                    <CssChip
                                      key={index}
                                      sx={{
                                        marginLeft: '4px',
                                        marginTop: '4px',
                                      }}
                                      label={functions.functions}
                                      onDelete={(e) =>
                                        handleDeleteFunction(
                                          e,
                                          functions?.saved_id,
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  );
                                } else {
                                  return (
                                    <CssChip
                                      key={index}
                                      sx={{
                                        marginLeft: '4px',
                                        marginRight: '4px',
                                        marginTop: '4px',
                                      }}
                                      label={functions.functions}
                                      variant="outlined"
                                    />
                                  );
                                }
                              },
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'experience'}
                        onChange={handleaccordionChange('experience')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="experience" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Experience
                                </Typography>
                              </Box>
                              <Box className={classes.editicon}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'experience')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pb={2}>
                            <Grid container className={classes.outergridyoe}>
                              <Grid item xs={12} className={classes.leftgrid}>
                                <Typography variant="h2s" component="h2s">
                                  {userdetails?.total_experience}{' '}
                                  {userdetails?.total_experience
                                    ? ' Years of Experience'
                                    : ''}
                                </Typography>
                              </Grid>
                            </Grid>

                            {userabouts?.aExperience?.map((industry, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={{ md: 2, xs: 0 }}>
                                      <Box
                                        className={classes.editiconsec}
                                        onClick={(e) =>
                                          editSection(e, 'experience', industry)
                                        }>
                                        <Menuicon
                                          width="43"
                                          color="#38CD84"
                                          name="editapp"
                                        />
                                      </Box>
                                      <Box
                                        className={classes.editiconsec}
                                        onClick={(e) =>
                                          deleteSection(
                                            e,
                                            'experience',
                                            industry,
                                          )
                                        }>
                                        <DeleteOutlinedIcon color="secondary" />
                                      </Box>
                                    </Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Company:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            marginTop: '-3px',
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.company}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Industry:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-5px',
                                            },
                                          }}>
                                          {industry?.industry}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Function:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.functions}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Location:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.location}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Designation:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.designation}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Start Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-5px',
                                            },
                                          }}>
                                          {industry?.start_date}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          End Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        {industry?.still_working === 1 ? (
                                          <Typography
                                            variant="h2"
                                            component="h2"
                                            sx={{
                                              '@media (max-width: 500px)': {
                                                marginTop: '-4px',
                                              },
                                            }}>
                                            Currently Working
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="h2"
                                            component="h2"
                                            sx={{
                                              '@media (max-width: 500px)': {
                                                marginTop: '-4px',
                                              },
                                            }}>
                                            {industry?.end_date}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        md={3.5}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Description:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8.5}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-4px',
                                            },
                                          }}>
                                          {industry?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'education'}
                        onChange={handleaccordionChange('education')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="educationtwo" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Education
                                </Typography>
                              </Box>
                              <Box className={classes.editicon}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'education')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack direction="row">
                            <Box>
                              <Box pb={2}>
                                {userabouts?.aEducation?.map(
                                  (education, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <Stack
                                          direction="row"
                                          sx={{ paddingBottom: '16px' }}>
                                          <Box pl={2}>
                                            <Box
                                              className={classes.editiconsec}
                                              onClick={(e) =>
                                                editSection(
                                                  e,
                                                  'education',
                                                  education,
                                                )
                                              }>
                                              <Menuicon
                                                width="43"
                                                color="#38CD84"
                                                name="editapp"
                                              />
                                            </Box>
                                            <Box
                                              className={classes.editiconsec}
                                              onClick={(e) =>
                                                deleteSection(
                                                  e,
                                                  'education',
                                                  education,
                                                )
                                              }>
                                              <DeleteOutlinedIcon color="secondary" />
                                            </Box>
                                          </Box>
                                          <Grid
                                            key={index}
                                            container
                                            className={classes.outergrid}>
                                            <Grid
                                              item
                                              md={4}
                                              xs={6}
                                              className={classes.leftgrid}>
                                              <Typography
                                                variant="h2s"
                                                component="h2s">
                                                University Name:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              md={8}
                                              xs={6}
                                              className={classes.rightgrid}>
                                              <Typography
                                                variant="h2"
                                                component="h2">
                                                {education?.board}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              md={4}
                                              xs={6}
                                              className={classes.leftgrid}>
                                              <Typography
                                                variant="h2s"
                                                component="h2s">
                                                Degree:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              md={8}
                                              xs={6}
                                              className={classes.rightgrid}>
                                              <Typography
                                                variant="h2"
                                                component="h2">
                                                {education?.degree}
                                              </Typography>
                                            </Grid>

                                            <Grid
                                              item
                                              md={4}
                                              xs={6}
                                              className={classes.leftgrid}>
                                              <Typography
                                                variant="h2s"
                                                component="h2s">
                                                Passing year:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              md={8}
                                              xs={6}
                                              className={classes.rightgrid}>
                                              <Typography
                                                variant="h2"
                                                component="h2">
                                                {education?.passing_year}
                                              </Typography>
                                            </Grid>

                                            <Grid
                                              item
                                              md={4}
                                              xs={6}
                                              className={classes.leftgrid}>
                                              <Typography
                                                variant="h2s"
                                                component="h2s">
                                                Details:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              md={8}
                                              xs={6}
                                              className={classes.rightgrid}>
                                              <Typography
                                                variant="h2"
                                                component="h2">
                                                {education?.details}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Stack>
                                      </Fragment>
                                    );
                                  },
                                )}
                              </Box>
                            </Box>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'affiliation'}
                        onChange={handleaccordionChange('affiliation')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="affiliation" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Affiliations
                                </Typography>
                              </Box>
                              <Box className={classes.editicon}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'affiliation')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pb={2}>
                            {userabouts?.aAffilation?.map(
                              (affilation, index) => {
                                return (
                                  <Fragment key={index}>
                                    <Stack
                                      direction="row"
                                      sx={{ paddingBottom: '16px' }}>
                                      <Box pl={2}>
                                        <Box
                                          className={classes.editiconsec}
                                          onClick={(e) =>
                                            editSection(
                                              e,
                                              'affiliation',
                                              affilation,
                                            )
                                          }>
                                          <Menuicon
                                            width="43"
                                            color="#38CD84"
                                            name="editapp"
                                          />
                                        </Box>
                                        <Box
                                          className={classes.editiconsec}
                                          onClick={(e) =>
                                            deleteSection(
                                              e,
                                              'affiliation',
                                              affilation,
                                            )
                                          }>
                                          <DeleteOutlinedIcon color="secondary" />
                                        </Box>
                                      </Box>
                                      <Grid
                                        key={index}
                                        container
                                        className={classes.outergrid}>
                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Name:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {affilation?.entity_name}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Role:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {affilation?.role}
                                          </Typography>
                                        </Grid>

                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Start Date:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {moment(
                                              affilation?.start_date,
                                            ).format('LL')}
                                          </Typography>
                                        </Grid>

                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            End Date:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {moment(
                                              affilation?.end_date,
                                            ).format('LL')}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Details:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {affilation?.details}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  </Fragment>
                                );
                              },
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'award'}
                        onChange={handleaccordionChange('award')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="award" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Awards & Certifications
                                </Typography>
                              </Box>
                              <Box className={classes.editicon}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'award')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pb={2}>
                            {userabouts?.aAward?.map((award, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={2}>
                                      <Box
                                        className={classes.editiconsec}
                                        onClick={(e) =>
                                          editSection(e, 'award', award)
                                        }>
                                        <Menuicon
                                          width="43"
                                          color="#38CD84"
                                          name="editapp"
                                        />
                                      </Box>
                                      <Box
                                        className={classes.editiconsec}
                                        onClick={(e) =>
                                          deleteSection(e, 'award', award)
                                        }>
                                        <DeleteOutlinedIcon color="secondary" />
                                      </Box>
                                    </Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        md={4}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Name:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {award?.award_name}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={4}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          By:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {award?.awarded_by}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={4}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Details:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {award?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'publications'}
                        onChange={handleaccordionChange('publications')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="publications" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Publications
                                </Typography>
                              </Box>
                              <Box
                                className={classes.editicon}
                                onClick={(e) => editpub(e, 'publications')}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'publications')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pb={2}>
                            {userabouts?.aPublication?.map(
                              (publication, index) => {
                                return (
                                  <Fragment key={index}>
                                    <Stack
                                      direction="row"
                                      sx={{ paddingBottom: '16px' }}>
                                      <Box pl={2}>
                                        <Box
                                          className={classes.editiconsec}
                                          onClick={(e) =>
                                            editSection(
                                              e,
                                              'publications',
                                              publication,
                                            )
                                          }>
                                          <Menuicon
                                            width="43"
                                            color="#38CD84"
                                            name="editapp"
                                          />
                                        </Box>
                                        <Box
                                          className={classes.editiconsec}
                                          onClick={(e) =>
                                            deleteSection(
                                              e,
                                              'publication',
                                              publication,
                                            )
                                          }>
                                          <DeleteOutlinedIcon color="secondary" />
                                        </Box>
                                      </Box>
                                      <Grid
                                        key={index}
                                        container
                                        className={classes.outergrid}>
                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Name:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {publication?.publication_title}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Date:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {moment(publication?.date).format(
                                              'LL',
                                            )}
                                          </Typography>
                                        </Grid>

                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Link:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {publication?.link}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={4}
                                          xs={6}
                                          className={classes.leftgrid}>
                                          <Typography
                                            variant="h2s"
                                            component="h2s">
                                            Description:
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          md={8}
                                          xs={6}
                                          className={classes.rightgrid}>
                                          <Typography
                                            variant="h2"
                                            component="h2">
                                            {publication?.details}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  </Fragment>
                                );
                              },
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'languages'}
                        onChange={handleaccordionChange('languages')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <Menuicon name="languages" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Languages
                                </Typography>
                              </Box>
                              <Box className={classes.editicon}>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onClick={(e) => addNew(e, 'language')}>
                              <AddCircleIcon color="secondary" fontSize="12" />
                              <Typography variant="h2s" component="h2s">
                                Add new
                              </Typography>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box pb={2}>
                            {userabouts?.aLanguage?.map((language, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={2}>
                                      <Box
                                        className={classes.editiconsec}
                                        onClick={(e) =>
                                          editSection(e, 'language', language)
                                        }>
                                        <Menuicon
                                          width="43"
                                          color="#38CD84"
                                          name="editapp"
                                        />
                                      </Box>
                                      <Box
                                        className={classes.editiconsec}
                                        onClick={(e) =>
                                          deleteSection(e, 'language', language)
                                        }>
                                        <DeleteOutlinedIcon color="secondary" />
                                      </Box>
                                    </Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        md={4}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Language:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {language?.title}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={4}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Proficiency:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {language?.proficiency}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={4}
                                        xs={6}
                                        className={classes.leftgrid}></Grid>
                                      <Grid
                                        item
                                        md={8}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {language?.reading === 'Yes'
                                            ? 'Read,'
                                            : ''}{' '}
                                          {language?.writing === 'Yes'
                                            ? ' Write,'
                                            : ''}{' '}
                                          {language?.speaking === 'Yes'
                                            ? 'Speak'
                                            : ''}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Divider />
                    </Box>

                    <Box
                      sx={{
                        paddingLeft: { xs: '0px', md: '16px' },
                        paddingRight: { xs: '0px', md: '16px' },
                      }}>
                      <Accordion
                        className={classes.accordionmain}
                        expanded={expanded === 'aditional'}
                        onChange={handleaccordionChange('aditional')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: '100%', paddingRight: '10px' }}
                            alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  width: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                }}>
                                <InterestIcon name="others" color="#38CD84" />
                              </Box>
                              <Box
                                pl={{ md: 3, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.headingtab}>
                                  Additional Information
                                </Typography>
                              </Box>
                              <Box
                                className={classes.editicon}
                                onClick={(e) =>
                                  navigate('/edit/job/preferences/')
                                }>
                                <Menuicon
                                  width="43"
                                  color="#38CD84"
                                  name="editapp"
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"></Stack>
                          </Stack>
                        </AccordionSummary>

                        {Object.keys(userabouts?.jobPreference).length > 0 && (
                          <AccordionDetails>
                            <Box pb={2} pl={2}>
                              <Fragment key={1}>
                                <Stack
                                  direction="row"
                                  sx={{ paddingBottom: '16px' }}>
                                  <Box pl={2}></Box>
                                  <Grid
                                    key={1}
                                    container
                                    className={classes.outergrid}>
                                    <Grid
                                      item
                                      xs={12}
                                      className={classes.leftgrid}>
                                      <Box pt={1} pb={2}>
                                        <Typography variant="h2" component="h2">
                                          This section would be visible only to
                                          the Hiring Managers of the jobs you
                                          have applied.
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Email:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      <Typography
                                        variant="h2"
                                        component="h2"
                                        mt={-0.8}>
                                        {userabouts?.jobPreference?.email}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Mobile:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      {/* <Stack direction="row" spacing={1}> */}
                                      <Typography
                                        variant="h2"
                                        component="h2"
                                        mt={-1}>
                                        +{' '}
                                        {
                                          userabouts?.jobPreference
                                            ?.country_code
                                        }{' '}
                                        {userabouts?.jobPreference?.mobile}
                                      </Typography>
                                      {/* <Typography variant="h2" component="h2">
                                          {userabouts?.jobPreference?.mobile}
                                        </Typography> */}
                                      {/* </Stack> */}
                                    </Grid>
                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Experience:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      <Typography
                                        variant="h2"
                                        component="h2"
                                        mt={-0.5}>
                                        {userdetails?.total_experience} Years
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Last Salary Drawn:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      <Typography
                                        variant="h2"
                                        component="h2"
                                        mt={-0.5}>
                                        {
                                          userabouts?.jobPreference
                                            ?.salary_drawn
                                        }
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Preffered Industry:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      pt={-0.5}
                                      className={classes.rightgrid}>
                                      {Array.isArray(
                                        userabouts?.jobPreference
                                          ?.preferred_industry,
                                      ) &&
                                        userabouts?.jobPreference?.preferred_industry?.map(
                                          (industry, index) => {
                                            if (index == 0) {
                                              return (
                                                <Typography
                                                  mt={-0.8}
                                                  // variant="span"
                                                  // component="span"
                                                  key={index}>
                                                  {' '}
                                                  {industry}
                                                </Typography>
                                              );
                                            } else {
                                              return (
                                                <Typography
                                                  mt={-0.8}
                                                  // variant="span"
                                                  // component="span"
                                                  key={index}>
                                                  {','} {industry}
                                                </Typography>
                                              );
                                            }
                                          },
                                        )}
                                      {/* <Typography
                                        variant="h2"
                                        component="h2"
                                        mt={-0.7}></Typography> */}
                                    </Grid>

                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Preffered Functions:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      {Array.isArray(
                                        userabouts?.jobPreference
                                          ?.preferred_function,
                                      ) &&
                                        userabouts?.jobPreference?.preferred_function?.map(
                                          (functn, index) => {
                                            if (index === 0) {
                                              return (
                                                <Typography
                                                  mt={-0.8}
                                                  // variant="span"
                                                  // component="span"
                                                  key={index}>
                                                  {' '}
                                                  {functn}
                                                </Typography>
                                              );
                                            } else {
                                              return (
                                                <Typography
                                                  mt={-0.8}
                                                  // variant="span"
                                                  // component="span"
                                                  key={index}>
                                                  {','} {functn}
                                                </Typography>
                                              );
                                            }
                                          },
                                        )}

                                      <Typography
                                        variant="h2"
                                        component="h2"></Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Preferred Location:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      <Typography
                                        variant="h2"
                                        component="h2"
                                        mt={-0.5}>
                                        {
                                          userabouts?.jobPreference
                                            ?.preferred_location
                                        }
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Open To Relocate:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      <Typography
                                        variant="h2"
                                        component="h2"
                                        mt={-0.6}>
                                        {userabouts?.jobPreference
                                          ?.open_to_relocate
                                          ? 'Yes'
                                          : 'No'}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      md={3.5}
                                      xs={6}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Resume:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8.5}
                                      xs={6}
                                      className={classes.rightgrid}>
                                      <Stack direction="row" spacing={5}>
                                        <Box
                                          mt={-1}
                                          onClick={() =>
                                            saveResume(
                                              userabouts?.jobPreference?.resume,
                                            )
                                          }
                                          sx={{ cursor: 'pointer' }}>
                                          <Stack direction="row" spacing={2}>
                                            <FileDownloadOutlinedIcon style={{marginTop:'-5px'}} />
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              Download Resume
                                            </Typography>
                                          </Stack>
                                        </Box>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Fragment>
                            </Box>
                          </AccordionDetails>
                        )}
                      </Accordion>
                      <Divider />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    Item Two
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    Item Three
                  </TabPanel>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Fragment>
      ) : (
        <EditProfile editProfile={editProfile} />
      )}
    </Fragment>
  );
};

export default React.memo(Profile);

// <Typography
//   variant={type === "industryreport" ? "h2s" : "h2"}
//   component={type === "industryreport" ? "h2s" : "h2"}
// >
//   Report
// </Typography>

// <Typography variant="h2" component="h2">
//   {industry?.end_date} {industry?.end_month}{" "}
//   {industry?.end_year}
// </Typography>

// <Typography
//   variant="h2s"
//   component="h2s"
//   sx={{
//     fontWeight: "600",
//     fontFamily: "Open Sans",
//     fontSize: "22px",
//   }}
// >
//   {userdetails?.total_credit
//     ? userdetails?.total_credit?.toLocaleString()
//     : 0}
// </Typography>
// <Typography variant="h2" component="h2">
//   Credits
// </Typography>

//
// {[
//   ...new Map(
//     userabouts?.aExperience?.map((item) => [
//       item["industry_id"],
//       item,
//     ])
//   ).values(),
// ].map((industry, index) => {
//   if (editsection && section === "industry") {
//     return (
//       <CssChip
//         key={index}
//         sx={{ marginLeft: "4px", marginTop: "4px" }}
//         label={industry?.industry + index}
//         onDelete={(e) =>
//           handleDeleteIndustry(e, industry?.id)
//         }
//         variant="outlined"
//       />
//     );
//   } else {
//     return (
//       <CssChip
//         key={index}
//         sx={{
//           marginLeft: "4px",
//           marginRight: "4px",
//           marginTop: "4px",
//         }}
//         label={industry?.industry + index}
//         variant="outlined"
//       />
//     );
//   }
// })}

//
// {[
//   ...new Map(
//     userabouts?.aExperience?.map((item) => [
//       item["function_id"],
//       item,
//     ])
//   ).values(),
// ].map((industry, index) => {
//   if (editsection && section === "function") {
//     return (
//       <CssChip
//         key={index}
//         sx={{ marginLeft: "4px", marginTop: "4px" }}
//         label={industry?.functions}
//         onDelete={(e) =>
//           handleDeleteFunction(e, industry?.id)
//         }
//         variant="outlined"
//       />
//     );
//   } else {
//     return (
//       <CssChip
//         key={index}
//         sx={{ marginLeft: "4px", marginTop: "4px" }}
//         label={industry?.functions}
//         variant="outlined"
//       />
//     );
//   }
// })}
