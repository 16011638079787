import { API, apiUrl } from '../../services';
import { http} from '../../services/client';
import {
  getAccessToken,
} from '../../services/authData';

export const GET_ARTICLES_LIST_SUCCESS = 'GET_ARTICLES_LIST_SUCCESS';
export const GET_ARTICLES_LIST_REQUEST = 'GET_ARTICLES_LIST_REQUEST';
export const GET_ARTICLES_LIST_FAILURE = 'GET_ARTICLES_LIST_FAILURE';

const getArticlesListRequest = () => ({
  type: GET_ARTICLES_LIST_REQUEST,
});

const getArticlesListFailure = (error) => ({
  type: GET_ARTICLES_LIST_FAILURE,
  payload: { error: error },
});

const getArticlesListSuccess = (data) => ({
  type: GET_ARTICLES_LIST_SUCCESS,
  payload: { articles: data },
});

export const getArticlesList = (payload) => {
  return function (dispatch) {
    dispatch({
      type: 'GET_ARTICLES_LIST_REQUEST',
    });
    return http
      .get(apiUrl.GET_POST_LIST, payload)
      .then(({ data }) => {
        console.log('getArticlesList', data);
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
      });
  };
};

export const getArticlesListList = (payload) => {
  return (dispatch) => {
    dispatch(getArticlesListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.GET_ARTICLES_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getArticlesListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getArticlesListSuccess(response));
              resolve(response);
            } else {
              dispatch(getArticlesListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

const addNewArticlePending = () => ({
  type: 'SEND_ADD_NEW_ARTICLE_DATA_PENDING',
});

const addNewArticleFailure = (error) => ({
  type: 'SEND_ADD_NEW_ARTICLE_DATA_REJECTED',
  payload: { error },
});

const addNewArticleSuccess = (data) => ({
  type: 'SEND_ADD_NEW_ARTICLE_DATA_FULFILLED',
  payload: data,
});

// =====================Add-Post-Api==============================================
// require -- id,content,image ,user_id ,datetime, document_type ,user_type , user_table_pk
export const sendAddNewArticleDataold = (payload) => {
  return (dispatch) => {
    dispatch(addNewArticlePending());
    return http
      .post(apiUrl.ADD_NEW_ARTICLE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(addNewArticleSuccess(response));
          dispatch(closeArticleEdit());
          return response;
        } else {
          dispatch(addNewArticleFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(addNewArticleFailure(error));
        return error;
      });
  };
};

export const sendAddNewArticleData = (payload, callback) => {
  return (dispatch) => {
    dispatch(addNewArticlePending());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.ADD_NEW_ARTICLE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          callback(error, null);
        } else {
          console.log('response', response);
          callback(null, response);
        }
      },
    );
    // .then((response) => {
    //   if (response?.status === 1) {
    //     dispatch(addNewPostSuccess(response));
    //     dispatch(closePostEdit());
    //     return response;
    //   } else {
    //     dispatch(addNewPostFailure(response));
    //     return response;
    //   }
    // })
    // .catch((error) => {
    //   dispatch(addNewPostFailure(error));
    //   return error;
    // });
  };
};
export const CLOSE_ARTICLE_EDIT = 'CLOSE_ARTICLE_EDIT';
export const OPEN_ARTICLE_EDIT = 'OPEN_ARTICLE_EDIT';
export const OPEN_ARTICLE_COMPANY_EDIT = 'OPEN_ARTICLE_COMPANY_EDIT';

export const closeArticleEdit = () => ({
  type: CLOSE_ARTICLE_EDIT,
});

export const openArticleEdit = () => ({
  type: OPEN_ARTICLE_EDIT,
});

export const openArticleCompany = () => ({
  type: OPEN_ARTICLE_COMPANY_EDIT,
});

export const CLOSE_UPDATE_ARTICLE = 'CLOSE_UPDATE_ARTICLE';
export const OPEN_UPDATE_ARTICLE = 'OPEN_UPDATE_ARTICLE';

export const closeUpdateArticle = () => ({
  type: 'CLOSE_UPDATE_ARTICLE',
});

export const openUpdateArticle = (article) => {
  return {
    type: 'OPEN_UPDATE_ARTICLE',
    payload: article,
  };
};
