import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import HeaderVentures from '../../components/HomeHeader/headerVentures';
import Footer from '../../components/HomeHeader/footer';
import { countryCodeList } from '../../data/date-data';
import { passing_year_list_iit } from '../../data/date-data';
import Multiselect from '@khanacademy/react-multi-select';
import './styles.css';
import axios from 'axios';

class ApplyInvestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      code: '+91',
      email: '',
      mobile: '',
      linkedin: '',
      university: 'select',
      iit_campus: 'select',
      iit_course: '',
      iit_passingyear: 'select',
      iim_campus: 'select',
      iim_course: '',
      iim_passingyear: 'select',
      campus: '',
      course: '',
      passingyear: 'select',
      industry: 'select',
      // stage: "select",
      // minimuminvestment: "select",
      // howmanyinvest: "",
      successfull_exits_count: '',
      proudinvest: '',
      interested: 'select',
      formErrors: {},
      selected: [],
      selected1: [],
      selected2: [],
      newid: {},
    };
    this.handleSelectedChanged = this.handleSelectedChanged.bind(this);
    this.handleSelected1Changed = this.handleSelected1Changed.bind(this);
    this.handleSelected2Changed = this.handleSelected2Changed.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initialState = this.state;
  }
  onFocusMobile = () => {
    console.log('onfocus===================investor');
    const data = {
      id: '0',
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      email: this.state.email,
      country_code: '91',
      mobile: this.state.mobile,
    };
    if (this.handleFormValidation()) {
      axios
        .post(
          //"http://13.233.22.12:4000/manage_investor",
          'https://lxweb-api.tk/manage_investor',
          data,
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 1) {
            this.setState({ newid: res.data });
          }
        });
    }
  };
  handleFormValidation() {
    let formErrors = {};
    let formIsValid = true;
    const { firstname, lastname, email, mobile } = this.state;

    // name
    if (!firstname) {
      formIsValid = false;
      formErrors['firstnameErr'] = 'First Name is .';
    }
    if (!lastname) {
      formIsValid = false;
      formErrors['lastnameErr'] = 'Last Name is .';
    }
    //Email
    if (!email) {
      console.log(email);
      formIsValid = false;
      formErrors['emailErr'] = 'Email id is .';
    } else {
      var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (emailPattern.test(email)) {
        console.log(email);
        return (formIsValid = true);
      } else {
        formIsValid = false;
        formErrors['emailErr'] = 'Invalid email id.';
      }
    }

    //Mobile

    if (!mobile) {
      console.log('uiu', mobile);
      formIsValid = false;
      formErrors['mobile'] = 'Mobile is .';
    } else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
      // var mobPattern = /^[0-9]{10}$/;
      console.log('mo', mobile);

      if (mobPattern.test(mobile)) {
        console.log('hhh', mobile);
        return (formIsValid = true);
      } else {
        formIsValid = false;
        formErrors['mobileErr'] = 'Invalid mobile number.';
      }
      console.log('ghh', mobile);
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  startup_stage = [
    { label: 'Idea', value: 'Idea' },
    { label: 'Pre-Revenue', value: 'Pre-Revenue' },
    { label: 'Seed', value: 'Seed' },
    { label: 'Round A', value: 'Round A' },
    { label: 'Round B', value: 'Round B' },
  ];
  min_invest = [
    { label: '5 Lakhs', value: '5 Lakhs' },
    { label: '10 Lakhs', value: '10 Lakhs' },
    { label: '15 Lakhs', value: '15 Lakhs' },
    { label: '> 50 Lakhs', value: '> 50 Lakhs' },
    { label: '> 1 Crore', value: '> 1 Crore' },
  ];

  industryList = [
    { value: 'Startup / VCs/ PEs', label: 'Startup / VCs/ PEs' },
    {
      value: 'Real Estate / Construction',
      label: 'Real Estate / Construction',
    },
    { value: 'Professional Services', label: 'Professional Services' },
    { value: 'Not for profit / NGO', label: 'Not for profit / NGO' },
    {
      value: 'Media / Entertainment / Communication',
      label: 'Media / Entertainment / Communication',
    },
    {
      value: 'Logistics / Transportation',
      label: 'Logistics / Transportation',
    },
    { value: 'IT & ITES', label: 'IT & ITES' },
    {
      value: 'Industrial / Manufacturing',
      label: 'Industrial / Manufacturing',
    },
    { value: 'Healthcare / Pharma', label: 'Healthcare / Pharma' },
    { value: 'FMCG / Retail', label: 'FMCG / Retail' },
    { value: 'Energy / Oil & Gas', label: 'Energy / Oil & Gas' },

    { value: 'Education / Training', label: 'Education / Training' },
    { value: 'E commerce', label: 'E commerce' },
    { value: 'Confidential', label: 'Confidential' },
    { value: 'BFSI', label: 'BFSI' },
    {
      value: 'Agriculture and allied services',
      label: 'Agriculture and allied services',
    },
    { value: 'Tourism & Hospitality', label: 'Tourism & Hospitality' },
  ];
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSelectedChanged = (selected) => {
    this.setState({ selected });
  };
  handleSelected1Changed = (selected1) => {
    this.setState({ selected1 });
  };
  handleSelected2Changed = (selected2) => {
    this.setState({ selected2 });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    console.log('event', e);
    var m = new Date();
    var dateString =
      m.getFullYear() +
      '-' +
      (m.getMonth() + 1) +
      '-' +
      m.getDate() +
      ' ' +
      m.getHours() +
      ':' +
      m.getMinutes() +
      ':' +
      m.getSeconds();
    if (this.state.university === 'Other Premium University') {
      const data = {
        id: 0,
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: this.state.linkedin,
        unversity: this.state.university,
        iit_campus: '',
        iit_course: '',
        iit_graduation_year: '',
        iim_campus: '',
        iim_course: '',
        iim_graduation_year: '',
        campus: this.state.campus,
        course: this.state.course,
        gradution_year: this.state.passingyear,
        prefered_industry: this.state.selected2,
        prefered_startup_renge: this.state.selected,
        minimum_investment: this.state.selected1,
        invested_startup_count: this.state.howmanyinvest,
        successfull_exits_count: this.state.successfull_exits_count,
        investment_story: this.state.proudinvest,
        intrested_in_mentor_or_advisor: this.state.interested,
        created_at: dateString,
      };
      console.log(data);
      if (this.handleFormValidation()) {
        axios
          .post(
            //"http://13.233.22.12:4000/manage_investor",
            'https://lxweb-api.tk/manage_investor',
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              return this.props.redirectSuccessPageInvestor(data);
            }
          });

        this.setState(this.initialState);
      }
    } else if (this.state.university === 'IIT') {
      const data = {
        id: 0,
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: this.state.linkedin,
        unversity: this.state.university,
        iit_campus: this.state.iit_campus,
        iit_course: this.state.iit_course,
        iit_graduation_year: this.state.iit_passingyear,
        iim_campus: '',
        iim_course: '',
        iim_graduation_year: '',
        campus: '',
        course: '',
        gradution_year: '',
        prefered_industry: this.state.selected2,
        prefered_startup_renge: this.state.selected,
        minimum_investment: this.state.selected1,
        invested_startup_count: this.state.howmanyinvest,
        successfull_exits_count: this.state.successfull_exits_count,
        investment_story: this.state.proudinvest,
        intrested_in_mentor_or_advisor: this.state.interested,
        created_at: dateString,
      };
      console.log(data);
      if (this.handleFormValidation()) {
        axios
          .post(
            //"http://13.233.22.12:4000/manage_investor",
            'https://lxweb-api.tk/manage_investor',
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              return this.props.redirectSuccessPageInvestor(data);
            }
          });

        this.setState(this.initialState);
      }
    } else if (this.state.university === 'IIM') {
      const data = {
        id: 0,
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: this.state.linkedin,
        unversity: this.state.university,
        iit_campus: '',
        iit_course: '',
        iit_graduation_year: '',
        iim_campus: this.state.iim_campus,
        iim_course: this.state.iim_course,
        iim_graduation_year: this.state.iim_passingyear,
        campus: '',
        course: '',
        gradution_year: '',
        prefered_industry: this.state.selected2,
        prefered_startup_renge: this.state.selected,
        minimum_investment: this.state.selected1,
        invested_startup_count: this.state.howmanyinvest,
        successfull_exits_count: this.state.successfull_exits_count,
        investment_story: this.state.proudinvest,
        intrested_in_mentor_or_advisor: this.state.interested,
        created_at: dateString,
      };
      console.log(data);
      if (this.handleFormValidation()) {
        axios
          .post(
            //"http://13.233.22.12:4000/manage_investor",
            'https://lxweb-api.tk/manage_investor',
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              return this.props.redirectSuccessPageInvestor(data);
            }
          });

        this.setState(this.initialState);
      }
    } else {
      const data = {
        id: 0,
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: this.state.linkedin,
        unversity: this.state.university,
        iit_campus: this.state.iit_campus,
        iit_course: this.state.iit_course,
        iit_graduation_year: this.state.iit_passingyear,
        iim_campus: this.state.iim_campus,
        iim_course: this.state.iim_course,
        iim_graduation_year: this.state.iim_passingyear,
        campus: '',
        course: '',
        gradution_year: '',
        prefered_industry: this.state.selected2,
        prefered_startup_renge: this.state.selected,
        minimum_investment: this.state.selected1,
        invested_startup_count: this.state.howmanyinvest,
        successfull_exits_count: this.state.successfull_exits_count,
        investment_story: this.state.proudinvest,
        intrested_in_mentor_or_advisor: this.state.interested,
        created_at: dateString,
      };
      console.log(data);
      if (this.handleFormValidation()) {
        axios
          .post(
            //"http://13.233.22.12:4000/manage_investor",
            'https://lxweb-api.tk/manage_investor',
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              return this.props.redirectSuccessPageInvestor(data);
            }
          });

        this.setState(this.initialState);
      }
    }
  };

  render() {
    console.log('PROPS============', this.props);
    const { selected1 } = this.state;
    const { selected2 } = this.state;
    const { selected } = this.state;
    const {
      firstnameErr,
      lastnameErr,
      emailErr,
      mobileErr,
      linkedinErr,
      campusErr,
      courseErr,
      passingyearErr,
      industryErr,
      stageErr,
      minimuminvestmentErr,
      howmanyinvestErr,
      proudinvestErr,
      interestedErr,
    } = this.state.formErrors;
    return (
      <Container fluid id="no-padding">
        <Row>
          {' '}
          <Col md={12} xs={12} className="main-header-style-section">
            {' '}
            <HeaderVentures />{' '}
          </Col>
        </Row>
        <Row className="container-ventures3">
          <Col className="form-venture">
            <h2 className="heading-startup">Register as an Investor</h2>
            <form
              autoComplete="off"
              className="form-group"
              onSubmit={this.handleSubmit}>
              <Row>
                <Col className="first_name_row">
                  {' '}
                  <label
                    htmlFor="firstname"
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    First Name*
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    value={this.state.firstname}
                    onChange={this.handleChange}
                    className="form-control"
                    required
                  />
                  {firstnameErr && (
                    <div style={{ color: 'red', paddingBottom: 10 }}>
                      {firstnameErr}
                    </div>
                  )}
                </Col>
                <Col
                  sm={6}
                  className="last_name_row"
                  // style={{ marginLeft: "-8px" }}
                >
                  {' '}
                  <label
                    htmlFor="lastname"
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Last Name*
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={this.state.lastname}
                    onChange={this.handleChange}
                    className="form-control"
                    required
                  />
                  {lastnameErr && (
                    <div style={{ color: 'red', paddingBottom: 10 }}>
                      {lastnameErr}
                    </div>
                  )}
                </Col>
              </Row>
              <label
                htmlFor="email"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Email Id*
              </label>
              <input
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                className="form-control"
              />
              {emailErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {emailErr}
                </div>
              )}
              {/* <div className="errorMsg">{this.state.errors.email}</div> */}
              <Row clssName="mobile-row" style={{ marginRight: '5px' }}>
                <div className="col-sm-2">
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Mobile No.*
                  </label>
                  <select
                    className="form-control"
                    style={{ fontFamily: 'Open Sans' }}
                    name="code"
                    value={this.state.name}
                    onChange={this.handleChange}>
                    <option value="" hidden>
                      +91
                    </option>
                    {countryCodeList
                      ? countryCodeList.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {/* {item.label + "(" + item.value + ")"} */}
                              {item.value}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </div>{' '}
                <div className="col-sm-10">
                  <label
                    className="mobile-in-mobile"
                    // style={{
                    //   marginTop: "32px",
                    // }}
                  ></label>
                  <input
                    onBlur={this.onFocusMobile}
                    className="form-control"
                    type="text"
                    name="mobile"
                    value={this.state.mobile}
                    onChange={this.handleChange}
                    style={{ fontFamily: 'Open Sans', marginLeft: '20px' }}
                  />
                </div>
                {mobileErr && (
                  <div
                    style={{
                      color: 'red',
                      paddingBottom: 10,
                    }}>
                    {mobileErr}
                  </div>
                )}
              </Row>
              <label
                htmlFor="linkedin"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                LinkedIn or LinkCxO Profile Link*
              </label>
              <input
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                type="text"
                name="linkedin"
                value={this.state.linkedin}
                onChange={this.handleChange}
                className="form-control"
              />
              {linkedinErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {linkedinErr}
                </div>
              )}
              <label
                htmlFor="university"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Education
              </label>
              <select
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                className="form-control"
                name="university"
                onChange={this.handleChange}
                value={this.state.university}>
                <option value="select">--Select--</option>
                <option>IIT</option>
                <option>IIM</option>
                <option>Both ( IIT & IIM )</option>
                <option>Another Premium University</option>
                <option>Others</option>
              </select>
              {/* {universityErr && (
                <div
                  style={{
                    color: "red",
                    paddingBottom: 10,
                  }}
                >
                  {universityErr}
                </div> */}
              {/* )}; */}
              {this.state.university === 'IIT' && (
                // this.state.university != "Other Premium University" &&
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iit_campus"
                    onChange={this.handleChange}
                    value={this.state.iit_campus}>
                    <option value="select">--Select--</option>
                    <option>IIT Madras </option>
                    <option>IIT Delhi</option>
                    <option>IIT Bombay</option>
                    <option>IIT Kanpur</option>
                    <option>IIT Kharagpur</option>
                    <option>IIT Roorkee </option>
                    <option>IIT Guwahati</option>
                    <option>IIT Hyderabad</option>
                    <option>IIT Dhanbad</option>
                    <option>IIT Indore</option>
                    <option>IIT BHU</option>
                    <option>IIT Roper</option>
                    <option>IIT Patna</option>
                    <option>IIT Gandhinagar</option>
                    <option>IIT Bhubaneswar</option>
                    <option>IIT Mandi</option>
                    <option>IIT Jodhpur</option>
                    <option>IIT Tirupati</option>
                    <option>IIT Bhilai</option>
                    <option>IIT Goa</option>
                    <option>IIT Jammu</option>
                    <option>IIT Dharwad</option>
                    <option>IIT Palakkad</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Course/Specialization*
                  </label>
                  <input
                    name="iit_course"
                    onChange={this.handleChange}
                    value={this.state.iit_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iit_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iit_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              {this.state.university === 'IIM' && (
                // this.state.university != "Other Premium University" &&
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iim_campus"
                    onChange={this.handleChange}
                    value={this.state.iim_campus}>
                    <option value="select">--Select--</option>
                    <option>IIM A </option>
                    <option>IIM C</option>
                    <option>IIM B</option>
                    <option>IIM L</option>
                    <option>IIM K</option>
                    <option>IIM I</option>
                    <option>IIM Shillong</option>
                    <option>IIM Rohtak</option>
                    <option>IIM Ranchi</option>
                    <option>IIM Raipur</option>
                    <option>IIM Trichy</option>
                    <option>IIM Kashipur</option>
                    <option>IIM Udaipur</option>
                    <option>IIM Nagpur</option>
                    <option>IIM Visakhapatnam</option>
                    <option>IIM Bodhgaya</option>
                    <option>IIM Amritsar</option>
                    <option>IIM Sambalpur</option>
                    <option>IIM Sirmaur</option>
                    <option>IIM Jammu</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Course/Specialization*
                  </label>
                  <input
                    name="iim_course"
                    onChange={this.handleChange}
                    value={this.state.iim_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iim_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iim_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              {this.state.university === 'Both ( IIT & IIM )' && (
                // this.state.university != "Other Premium University" &&
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iit_campus"
                    onChange={this.handleChange}
                    value={this.state.iit_campus}>
                    <option value="select">--Select--</option>
                    <option>IIT Madras </option>
                    <option>IIT Delhi</option>
                    <option>IIT Bombay</option>
                    <option>IIT Kanpur</option>
                    <option>IIT Kharagpur</option>
                    <option>IIT Roorkee </option>
                    <option>IIT Guwahati</option>
                    <option>IIT Hyderabad</option>
                    <option>IIT Dhanbad</option>
                    <option>IIT Indore</option>
                    <option>IIT BHU</option>
                    <option>IIT Roper</option>
                    <option>IIT Patna</option>
                    <option>IIT Gandhinagar</option>
                    <option>IIT Bhubaneswar</option>
                    <option>IIT Mandi</option>
                    <option>IIT Jodhpur</option>
                    <option>IIT Tirupati</option>
                    <option>IIT Bhilai</option>
                    <option>IIT Goa</option>
                    <option>IIT Jammu</option>
                    <option>IIT Dharwad</option>
                    <option>IIT Palakkad</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Course/Specialization*
                  </label>
                  <input
                    name="iit_course"
                    onChange={this.handleChange}
                    value={this.state.iit_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iit_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iit_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iim_campus"
                    onChange={this.handleChange}
                    value={this.state.iim_campus}>
                    <option value="select">--Select--</option>
                    <option>IIM A </option>
                    <option>IIM C</option>
                    <option>IIM B</option>
                    <option>IIM L</option>
                    <option>IIM K</option>
                    <option>IIM I</option>
                    <option>IIM Shillong</option>
                    <option>IIM Rohtak</option>
                    <option>IIM Ranchi</option>
                    <option>IIM Raipur</option>
                    <option>IIM Trichy</option>
                    <option>IIM Kashipur</option>
                    <option>IIM Udaipur</option>
                    <option>IIM Nagpur</option>
                    <option>IIM Visakhapatnam</option>
                    <option>IIM Bodhgaya</option>
                    <option>IIM Amritsar</option>
                    <option>IIM Sambalpur</option>
                    <option>IIM Sirmaur</option>
                    <option>IIM Jammu</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Course/Specialization*
                  </label>
                  <input
                    name="iim_course"
                    onChange={this.handleChange}
                    value={this.state.iim_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iim_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iim_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              {this.state.university === 'Other Premium University' && (
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    University Name*
                  </label>
                  <input
                    name="campus"
                    onChange={this.handleChange}
                    value={this.state.campus}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Course/Specialization*
                  </label>
                  <input
                    name="course"
                    onChange={this.handleChange}
                    value={this.state.course}
                    type="text"
                    className="form-control"
                  />

                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="passingyear"
                    onChange={this.handleChange}
                    value={this.state.passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              {this.state.university === 'Others' && (
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    University Name*
                  </label>
                  <input
                    name="campus"
                    onChange={this.handleChange}
                    value={this.state.campus}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Course/Specialization*
                  </label>
                  <input
                    name="course"
                    onChange={this.handleChange}
                    value={this.state.course}
                    type="text"
                    className="form-control"
                  />

                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="passingyear"
                    onChange={this.handleChange}
                    value={this.state.passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Preferred Industry / Vertical to invest in*
              </label>
              <Multiselect
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                className="form-control"
                options={this.industryList}
                onSelectedChanged={this.handleSelected2Changed}
                selected={selected2}
                disableSearch={true}
                overrideStrings={{
                  selectSomeItems: 'you can select multiple industries',
                }}
              />
              {industryErr && (
                <div
                  style={{
                    color: 'red',
                    paddingBottom: 10,
                  }}>
                  {industryErr}
                </div>
              )}
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Preferred Startup Stage to invest in*
              </label>
              <Multiselect
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                className="form-control"
                options={this.startup_stage}
                onSelectedChanged={this.handleSelectedChanged}
                selected={selected}
                disableSearch={true}
                overrideStrings={{
                  selectSomeItems: 'you can select multiple startup stages',
                }}
              />
              {stageErr && (
                <div
                  style={{
                    color: 'red',
                    paddingBottom: 10,
                  }}>
                  {stageErr}
                </div>
              )}
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Preferred Minimum Investment Ticket per Startup *
              </label>
              <Multiselect
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                className="form-control"
                options={this.min_invest}
                onSelectedChanged={this.handleSelected1Changed}
                selected={selected1}
                disableSearch={true}
                overrideStrings={{
                  selectSomeItems: 'you can select multiple investment tickets',
                }}
              />
              {minimuminvestmentErr && (
                <div
                  style={{
                    color: 'red',
                    paddingBottom: 10,
                  }}>
                  {minimuminvestmentErr}
                </div>
              )}
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                How many Startups have you invested in so far?
              </label>
              <input
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                name="howmanyinvest"
                type="text"
                className="form-control"
                style={{ fontFamily: 'Open Sans' }}
                onChange={this.handleChange}
                value={this.state.howmanyinvest}
              />
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                How many successful exits have you had so far?
              </label>
              <input
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                name="successfull_exits_count"
                type="text"
                className="form-control"
                style={{ fontFamily: 'Open Sans' }}
                onChange={this.handleChange}
                value={this.state.successfull_exits_count}
              />
              {/* <label
                style={{
                  marginTop: "15px",
                  color: "#38cd84",
                  fontSize: "16px",
                  float: "left",
                }}
              >
                Tell us one investment you made are proud of and why?*
              </label>
              <input
                name="proudinvest"
                type="text"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.proudinvest}
              /> */}
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Would you be interested in being involved as mentor/advisor for
                startups you'd invest in?*
              </label>
              <select
                type="text"
                className="form-control"
                name="interested"
                onChange={this.handleChange}
                value={this.state.interested}>
                <option value="select">--select--</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {interestedErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {interestedErr}
                </div>
              )}
              <br />
              <Row
                className="form-check"
                style={{
                  float: 'left',
                }}>
                <input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  className="checkbox"
                  style={{
                    color: '#262525',
                    fontSize: '18px',
                    marginRight: '10px',
                    marginTop: '20px',
                  }}
                  type="checkbox"
                  value=""
                  required
                />
                <label
                  style={{
                    color: '#38cd84',
                    fontSize: '16px',
                    marginTop: '15px',
                  }}
                  htmlFor="invalidCheck">
                  I agree to the Terms & Conditions, NDA of LinkCxO Ventures &
                  would like to signup on the app.
                </label>
                <div
                  style={{ color: '#38cd84', fontSize: '16px', float: 'left' }}
                  className="invalid-feedback">
                  You must agree before submitting.
                </div>
              </Row>
              <br />
              <Row style={{ marginTop: '50px' }}>
                <button type="submit" className="venture-btn2">
                  Submit
                </button>
              </Row>
            </form>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} style={{ background: '#151414' }}>
            {' '}
            <Footer />{' '}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ApplyInvestor;
