import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Grid, Box, Typography, Stack } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const PrivacyPolicy = (props) => {
    let location = useLocation();
  return (
    <Fragment>

          <Paper sx={{ backgroundColor: "#232121", backgroundImage: "none", width: "100%" }}>

              <Box
                py={3}
                px={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
        {      location.pathname === "/terms-conditions"
                ?                  <Typography variant="h2s" component="h2s">
                                  Terms and Conditions
                                </Typography>
                : ""}

                {      location.pathname === "/privacy-policy"
                        ?                 <Typography variant="h2s" component="h2s">
                                          Privacy Policy
                                        </Typography>
                        : ""}
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",

                }}
              >
                <Typography variant="h5" component="h5">
                  Effective date: 2020-05-06
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2s" component="h2s">
                  1. Introduction
                </Typography>
              </Box>

              <Box px={3} sx={{  fontSize:"18px"}}>
              <Typography variant="p" component="p">
Welcome to LinkCXO Global Pvt. Ltd..
              </Typography>

               <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
               LinkCXO Global Pvt. Ltd. ("us, "we", or "our") operates linkcxo.com (hereinafter referred to as "Service").
Our Privacy Policy governs your visit to linkcxo.com, and explains how we collect, safeguard and disclose information that results from your use of our Service.
               </Typography>


               <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>

We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
              </Typography>

              <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
              Our Terms and Conditions ("Terms") govern all use of our Service and together with the Privacy Policy constitutes your agreement with us ("agreement").
             </Typography>

             <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
             Welcome to LinkCXO Global Pvt. Ltd..
LinkCXO Global Pvt. Ltd. ("us, "we", or "our") operates linkcxo.com (hereinafter referred to as "Service").
Our Privacy Policy governs your visit to linkcxo.com, and explains how we collect, safeguard and disclose information that results from your use of our Service.
            </Typography>

            <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
            We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
           </Typography>

           <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
           Our Terms and Conditions ("Terms") govern all use of our Service and together with the Privacy Policy constitutes your agreement with us ("agreement").
          </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2s" component="h2s">
                  2 Definitions
                </Typography>
              </Box>
                <Box px={3 } sx={{  fontSize:"18px"}}>
              <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
              SERVICE means the linkcxo.com website operated by LinkCXO Global Pvt. Ltd..
             </Typography>

             <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
             PERSONAL DATA means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
            </Typography>

            <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
            USAGE DATA is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
           </Typography>

           <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
           COOKIES are small files stored on your device (computer or mobile device).

          </Typography>


          <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
          DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
         </Typography>

         <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
         DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.

        </Typography>


        <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
        DATA SUBJECT is any living individual who is the subject of Personal Data.
       </Typography>

       <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
        THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
      </Typography>


      <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
     </Typography>

     <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    </Typography>

              </Box>

              <Box
                py={3}
                px={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2s" component="h2s">
                  3 Information Collection and Use
                </Typography>
              </Box>
              <Box px={3} sx={{  fontSize:"18px"}}>
              <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
              We collect several different types of information for various purposes to provide and improve our Service to you.
             </Typography>

             <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
            </Typography>
          </Box>

          <Box
            py={3}
            px={3}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="h2s" component="h2s">
              4 Types of Data Collected
            </Typography>
          </Box>
          <Box px={3} sx={{  fontSize:"18px"}}>
          <Typography variant="h2" component="h2" sx={{paddingTop:"4px"}}>
           Personal Data
         </Typography>

         <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
         While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
        </Typography>

        <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
          First name and last name
       </Typography>

       <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
        Phone number
      </Typography>

      <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
        Address, Country, State, Province, ZIP/Postal code, City
     </Typography>

     <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      Cookies and Usage Data
    </Typography>

    <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.
   </Typography>
      </Box>


      <Box
        py={3}
        px={3}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h2s" component="h2s">
        Usage Data
        </Typography>
      </Box>
      <Box px={3} sx={{  fontSize:"18px"}}>

      <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device ("Usage Data").

     </Typography>

     <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
    </Typography>

    <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.


    </Typography>

      </Box>

      <Box
        py={3}
        px={3}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h2s" component="h2s">
          Tracking Cookies Data
        </Typography>
      </Box>

      <Box px={3} sx={{  fontSize:"18px"}}>

      <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
        We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
     </Typography>

     <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.
    </Typography>

    <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.

    </Typography>
    <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      Examples of Cookies we use:
   </Typography>

   <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
    Session Cookies: We use Session Cookies to operate our Service.
  </Typography>


  <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Preference Cookies: We use Preference Cookies to remember your preferences and various settings.
 </Typography>



 <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Security Cookies: We use Security Cookies for security purposes.
</Typography>



<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.
</Typography>

      </Box>


      <Box
        py={3}
        px={3}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h2s" component="h2s">
          Other Data
        </Typography>
      </Box>

      <Box px={3} sx={{  fontSize:"18px"}}>

      <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, non-disclosure agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.
     </Typography>

     </Box>


     <Box
       py={3}
       px={3}
       sx={{
         display: "flex",
         justifyContent: "flex-start",
         alignItems: "center",
       }}
     >
       <Typography variant="h2s" component="h2s">
         5. Use of Data
       </Typography>
     </Box>
     <Box px={3} sx={{  fontSize:"18px"}}>

     <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
        LinkCXO Global Pvt. Ltd. uses the collected data for various purposes:
    </Typography>

    </Box>

    <Box px={3} sx={{  fontSize:"18px"}}>

    <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
      to provide and maintain our Service;
   </Typography>

   <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
    to notify you about changes to our Service;
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
    to allow you to participate in interactive features of our Service when you choose to do so;
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  to provide customer support;
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  to gather analysis or valuable information so that we can improve our Service;
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  to monitor the usage of our Service;

</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  to detect, prevent and address technical issues;
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  to fulfil any other purpose for which you provide it;
</Typography>


<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
in any other way we may describe when you provide the information;
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  for any other purpose with your consent.
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Fulfill and manage purchases, orders, payments, and other transactions related to the Site.
</Typography>


<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Process payments and refunds.
</Typography>


<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
Third-Party Service Providers We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
</Typography>


<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Email you regarding your account or order.
</Typography>


<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Enable user-to-user communications.
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
  Notify you of updates to the Site.
</Typography>


   </Box>


   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
       6. Retention of Data
     </Typography>
   </Box>
   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
  </Typography>
   </Box>

   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     7. Transfer of Data

     </Typography>
   </Box>
   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
     Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.

  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
    Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  LinkCXO Global Pvt. Ltd. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
</Typography>
   </Box>

   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     8. Disclosure of Data

     </Typography>
   </Box>
   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    We may disclose personal information that we collect, or you provide:
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    0.1. Disclosure for Law Enforcement.
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    0.2. Business Transaction.
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  0.3. Other cases. We may disclose your information also:

 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  0.3.1. to our subsidiaries and affiliates;
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  0.3.2. to contractors, service providers, and other third parties we use to support our business;
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  0.3.3. to fulfill the purpose for which you provide it;
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  0.3.4. for the purpose of including your company’s logo on our website;
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  0.3.5. for any other purpose disclosed by us when you provide the information;
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  0.3.6. with your consent in any other cases;
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
 0.3.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.


 </Typography>
   </Box>

   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     9. Security of Data

     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
  </Typography>
   </Box>

   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     10. Service Providers

     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
  </Typography>

  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
  </Typography>
   </Box>


   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     11. Analytics

     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
     We may use third-party Service Providers to monitor and analyze the use of our Service.
  </Typography>


   </Box>


   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     12. CI/CD tools
     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
     We may use third-party Service Providers to automate the development process of our Service.
  </Typography>
   </Box>


   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     13. Behavioral Remarketing
     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.
  </Typography>


   </Box>

   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     14. Links to Other Sites
     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
      Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
  </Typography>
  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
    We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
 </Typography>

   </Box>


   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     15. Children’s Privacy
     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
   Our Services are not intended for use by children under the age of 18 ("Child" or "Children").

  </Typography>
  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.
 </Typography>

   </Box>


   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     16. Changes to This Privacy Policy
     </Typography>
   </Box>

   <Box px={3} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

  </Typography>
  <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update "effective date" at the top of this Privacy Policy.
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
  You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
</Typography>
   </Box>


   <Box
     py={3}
     px={3}
     sx={{
       display: "flex",
       justifyContent: "flex-start",
       alignItems: "center",
     }}
   >
     <Typography variant="h2s" component="h2s">
     17. Contact Us
     </Typography>
   </Box>

   <Box px={3} pb={6} sx={{  fontSize:"18px"}}>
   <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
   If you have any questions about this Privacy Policy, please contact us by email1: support@linkcxo.com.
  </Typography>

   </Box>
  <Typography variant="p" component="p" sx={{paddingTop:"4px", paddingLeft:"8px"}}>
 </Typography>

 <Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
</Typography>

<Typography variant="p" component="p" sx={{paddingTop:"4px"}}>
</Typography>
</Paper>

    </Fragment>
  );
};

export default React.memo(PrivacyPolicy);
