import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import Chat from '../../assets/home/Chat_Active.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
import Menuicon from '../../assets/menuicon';
import EventsIcon from '../../assets/eventsicon';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Share from '../../assets/home/Share_White.png';
import credits_gold from '../../assets/header/Credits_Gold.png';
import Membership_Star_White from '../../assets/header/Membership_Star_White.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as jobsActions from '../../store/jobs';
import * as eventsActions from '../../store/events';
import * as activityActions from '../../store/activity';
import * as rewardsActions from '../../store/rewards';
import { useNavigate, useLocation } from 'react-router-dom';
import deleticon from '../../assets/home/Delete_White.png';
import invite from '../../assets/home/Invited_by_user_White.png';

const CorporatePartnerCard = (props) => {
  console.log('CorporatePartnerCard', props);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const navigate = useNavigate();

  const navigateVoucher = async () => {
    debugger;
    const partner = await dispatch(
      rewardsActions.partnerVoucherOpen(props?.partner),
    );
    const path =
      '/corporate/rwards/voucher/' +
      props?.partner?.name +
      '-' +
      'lx' +
      props?.partner?.id;
    navigate(path, { state: { partner: props?.partner } });
  };

  const navigateCategory = () => {
    debugger;
    if (props?.category?.name === 'Real Estate') {
      const path =
        '/corporate/realestate/partners/' +
        props?.category?.name +
        '-' +
        'lx' +
        props?.category?.id;
      navigate(path, { state: { category: props?.category } });
    } else {
      const path =
        '/corporate/partners/category/' +
        props?.category?.name +
        '-' +
        'lx' +
        props?.category?.id;
      navigate(path, { state: { category: props?.category } });
    }
  };
  if (props?.type === 'category') {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '147px',
          width: '100%',
          cursor: 'pointer',
        }}>
        <Stack direction="row">
          <Box
            sx={{
              width: '50%',
              height: '171px',
              display: 'flex',
              backgroundColor: '#333',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={navigateCategory}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '90px',
                maxWidth: 'min-content',
                width: '36px',
              }}>
              <img
                alt="Membership_Star_Passive"
                src={
                  props?.category?.icon
                    ? props?.category?.icon
                    : Membership_Star_White
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '50%',
              height: '171px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontSize: '20px',
                lineHeight: '23px',
                letterSpacing: '0.31px',
                fontWeight: 600,
                padding: '8px',
                width: 'min-content',
              }}>
              {props?.category?.name
                ? props?.category?.name
                : 'Category_' + props?.index}
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  } else if (props?.type === 'partner') {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '147px',
          width: '100%',
          cursor: 'pointer',
        }}>
        <Stack direction="row">
          <Box
            sx={{
              width: '50%',
              height: '171px',
              display: 'flex',
              backgroundColor: '#333',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={navigateVoucher}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '90px',
                maxWidth: 'min-content',
                width: '36px',
              }}>
              <img
                alt="Membership_Star_Passive"
                src={
                  props?.partner?.icon
                    ? props?.partner?.icon
                    : Membership_Star_White
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '50%',
              height: '171px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontSize: '20px',
                lineHeight: '23px',
                letterSpacing: '0.31px',
                fontWeight: 600,
              }}>
              {props?.partner?.name ? props?.partner?.name : 'Partner'}
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  }
};

export default React.memo(CorporatePartnerCard);
