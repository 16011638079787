export const api = {
  POST_SIGN_IN: `user/login/`,
  POST_SEND_EMAIL_OTP: `user/send_email_otp`,
  POST_SEND_OTP: `user/send_otp`,
  POST_VERIFY_OTP: `user/verify_email_otp`,
  POST_SIGNUP_VERIFY: `user/signup/verify_otp`,
  POST_SIGN_UP: `signUp`,
  POST_USER_PROFILE: `user/user_profile/`,
  PROFILE_REQUIRED_FIELD: `user/check_profile_required_field/`,
  GET_DESIGNATION_LIST: 'designation_list',
  GET_INDUSTRY_LIST: 'industry_list',
  GET_SUB_INDUSTRY_LIST: 'sub_industry_list',
  GET_FUNCTION_LIST: 'function_list',
  GET_LOCATION_LIST: 'location_list',
  GET_COUNTRY_CODE_LIST: 'country_list',
  POST_BIO: 'user/manage_about',
  GET_INTEREST_LIST: 'interest_list',
  POST_INTEREST_LIST: 'user/manage_user_interest',
  GET_LINKEDIN_DATA: 'get_access_token',
  GET_POST_LIST: 'post_list',
  GET_ARTICLES_LIST: 'web/post_list',
  USER_REGISTER_FIRST: 'user/register_first',
  USER_REGISTER_FIRST_ADDITIONAL: 'user/register_first_additional',
  GET_COMMENT_POST_LIST: 'comment_list',
  MANAGE_COMMENT: 'manage_comment',
  CONTENT_ACTION: 'content_action',
  DELETE_COMMENT: 'comment_delete',
  GET_SIGNED_URL: 'get_signed_url',
  IS_SUBMIT_FORM_ALLOWED:'forms/is_submit_form_allowed',
  GET_FORM_DETAILS: 'forms/get_form',
  GET_ADS: 'ads/get_ads',
  ADS_CLICK: 'ads/ad_click?campaignId=',
  ADS_IMPRESSIONS: 'ads/ad_impression?campaignId=',
  GET_FORM_REPORT:'forms/get_form_report',
  DELETE_FORM_QUERY_RESPONSE : 'forms/delete_query_response',
  
  SURVEY_DOWNLOAD_REPORT:'forms/download_report',
  

  ADD_FUNCTON: '/user/add_function',
  ADD_INDUSTRY: '/user/add_industry',

  USER_TRACK: 'web/track_user',
  ACCOUNT_DETAILS: 'user/account_details',
  USER_PROFILE: 'user/user_profile',
  USER_SUBSCRIPTIONS: 'user/subscription',
  UPDATE_IMAGE: 'user/update_user_image',
  UPDATE_USER: 'user/update_user',
  CHECK_USER: 'user/check_user',
  CHECK_USER_NAME: 'user/check_new_username',
  CHECK_MOBILE_EMAIL: 'user/check_mobile_email',
  USER_IS_VERIFIED_AND_MEMBERSHIP:'user/isVerified',
  UPDATE_MOBILE: 'user/update_mobile',
  UPDATE_EMAIL: 'user/update_email',
  USER_PROFILE_ABOUT: 'user/user_profile_about',
  USER_MANAGE_SKILL: 'user/manage_user_skill',
  USER_MANAGE_LANGUAGE: 'user/manage_user_language',
  USER_MANAGE_INTEREST: 'user/manage_user_interest',
  USER_MANAGE_PUBLICATION: 'user/manage_user_publication',
  USER_MANAGE_REWARDS: 'user/manage_user_award',
  USER_MANAGE_EDUCATION: 'user/manage_user_education',
  USER_MANAGE_EXPERIENCE: 'user/manage_user_experience',
  USER_MANAGE_AFFILATION: 'user/manage_user_affilation',
  USER_MANAGE_FUNCTION: 'user/manage_user_function/',
  USER_MANAGE_INDUSTRY: 'user/manage_user_industry',
  USER_CHALLENGE_PROFILE_VERIFICATION: 'user/challenge_profile_verification',
  
 

  USER_DELETE_EDUCATION: 'user/delete_user_education',
  USER_DELETE_EXPERIENCE: 'user/delete_user_experience',
  USER_DELETE_INTEREST: 'user/delete_user_interest',
  USER_DELETE_LANGUAGE: 'user/delete_user_laguage',
  USER_DELETE_SKILL: 'user/delete_user_skill',
  USER_DELETE_AFFILIATION: 'user/delete_user_affiliations',
  USER_DELETE_PUBLICATION: 'user/delete_user_publication',
  USER_DELETE_AWARDS: 'user/delete_user_awards',
  USER_DELETE_INDUSTRY: 'user/delete_user_industry',
  USER_DELETE_FUNCTION: 'user/delete_user_function',

  JOB_MANAGE_PREFERENCE: 'job/manage_job_preference',
  USER_CHALLENGE_JOB_MANAGE_PREFERENCE: 'job/manage_job_preference_for_challenge',
  JOB_MESSAGE_LIST: 'job/message_list',
  CANDIDATE_PROFILE_ABOUT: 'user/user_profile_about',
  JOB_UNDO_STATUS: 'job/undo_job_status',
  JOB_MANAGE: 'job/manage_job',
  JOB_SEND_MESSAGE: 'job/send_message',
  JOB_SAVE: 'job/manage_job_status',
  JOB_LIST: 'job/job_list_web',
  JOBS_LIST: 'job/job_list',
  JOB_DETAILS: 'job/job_details',
  JOB_SHARE: 'job/share_job',
  JOB_USER_STATUS: 'job/user_job_status',
  JOB_STATUS_CHANGE: 'job/change_job_status',
  MANAGE_JOB_STATUS: 'job/manage_job_status',
  JOB_CANDIDATE_SHORTLIST: 'job/candidate_shortlist',
  JOB_CANDIDATE_HOLD: 'job/candidate_hold',
  JOB_CANDIDATE_REJECT: 'job/candidate_reject',
  JOB_SEARCH: 'job/search_job',
  JOB_FILTER: 'job/filter_job',
  JOB_LIST_BY_STATUS: 'job/job_list_by_status',
  JOB_LIST_APPLIED: 'job/applied_job_list',
  JOBS_RECOMMENDED_JOB_LIST: 'job/recc_job_list',
  SIMILAR_JOB_LIST: 'job/similar_job_list',
  SHARED_JOB_LIST: 'job/shared_job',
  REPORT_JOB: 'job/report_job',
  DELETE_JOB: 'job/delete_job',
  JOB_COUNT: 'job/job_count',
  RECOMMENDED_JOB_COUNT: 'job/rex_job_count',
  JOB_STATUS_COUNT: 'job/job_status_count',
  JOB_DELETE_MESSAGE: 'job/delete_message',
  JOB_APPLIED_COUNT: 'job/user_job_applied_count',

  CANDIDATE_LIST: 'job/candidate_list',
  CANDIDATE_PROFILE: 'job/candidate_profile',

  MANAGE_CONTENT_SHARE: 'manage_content_share',
  POST_LIKE: 'post_like',
  SHARE_POST: 'share_post',
  DELETE_POST: 'post_delete',
  ADD_NEW_POST: 'post/manage_post',
  ADD_NEW_ARTICLE: 'manage_article',
  SAVE_ARTICLE: 'saved_article',
  ADD_NEW_POLL: 'poll/manage_poll',
  MANAGE_VOTE: 'poll/manage_vote',

  GET_MY_NETWORK_LIST: 'network/discover',
  CONNECTION_NETWORK: 'network/request',
  CONNECTION_DISLIKE: 'network/dislike',
  CONNECTION_NETWORK_SEARCH: 'network/search',

  CONNECTION_REQUEST_FULFILLED: 'network/request',
  DISCOVER_USER: 'network/discover',
  GET_PEOPLE_RECOMMENDATION_LIST: 'network/all_users_list',
  DISLIKE_NETWORK: 'network/dislike',
  REMOVE_PENDING_NETWORK: 'network/connection_request_remove',
  GET_SENT_NETWORK_LIST: 'network/sent_friend_list',
  ACCEPT_REQUEST: 'network/request_accept',
  GET_PENDING_NETWORK_LIST: 'network/pending_friend_list',
  GET_EXISTING_NETWORK_LIST: 'network/existing_friend_list',
  POST_REQUEST_ACCEPT: 'network/request_accept',
  REMOVE_EXISTING_NETWORK: 'network/unfriend',
  USER_NETWORK_COUNT: 'network/user_network_count',
  GET_ALL_USER_LIST: 'network/all_users_list',
  REQUEST_REJECT: 'network/request_reject',
  FRIEND_REQUEST_REMOVE: 'network/friend_request_remove',
  SURVEY_GET_FORM:'forms/get_form',
  SEARCH_ALL: 'search_all',
  NETWORK_FILTER: 'network_filter',

  GET_POST_ACTIVITY: 'user-activity/post_list',
  GET_JOBS_ACTIVITY: 'user-activity/jobs',
  GET_CLUBS_ACTIVITY: 'user-activity/clubs',
  GET_EVENTS_ACTIVITY: 'user-activity/events',
  GET_CONNECTION_REQUEST_ACTIVITY: 'user-activity/connection_request',
  GET_ALL_USER_ACTIVITY: 'user-activity/all_user_activity',

  SAVE_BOOKMARK: 'save_bookmark',
  DELETE_BOOKMARK: 'user/unbookmark',
  NOTIFICATION_LIST: 'notification/notification_list',
  GET_BOOKMARK_LIST: 'user/bookmark_list',

  HOSTED_EVENT_UPCOMING: 'event/hosted_event_upcoming',
  DISCOVER_EVENT_LIST: 'event/event_list',
  INVITED_EVENT_LIST: 'event/invited_event',
  REQUESTED_EVENT_LIST: 'event/requested_event',
  SAVED_EVENT_UPCOMING: 'event/saved_event_upcoming',
  SAVED_EVENT_PASSED: 'event/saved_event_passed',
  EVENT_DETAILS: 'event/event_details',
  EVENT_REQUESTED_MEMBERS: 'event/requested_members',
  EVENT_INVITED_MEMBERS: 'event/invited_members',
  EVENT_REQUEST_ACCEPT: 'event/request_accept',
  EVENT_REQUEST_REJECT: 'event/request_reject',
  EVENT_ATTENDING_MEMBERS: 'event/attending_members',
  EVENT_INVITATION_UNSEND: 'event/unsend',
  EVENT_REQUEST: 'event/request',
  EVENT_REQUEST_WITHDRAW: 'event/requested_withdraw',
  EVENT_STATUS_FOR_USER: 'event/event_status_for_user',
  CANCEL_EVENT: 'event/cancel_event',
  EVENT_INVITE_USER: 'event/invite_user',
  EVENT_CREATE: 'event/create_event',
  EVENT_UPDATE: 'event/update_event',
  MY_CONNECTIONS: 'my_connections',
  HOSTED_EVENT_PASSED: 'event/hosted_event_passed',

  LINKCXO_REWARD_LIST: 'rewards/linkcxo_reward_list',
  REWARDS_CATEGORY_LIST: 'rewards/rewards_category_list',
  REWARDS_PARTNER_LIST: 'rewards/partner_list',
  REWARDS_PARTNER_DETAILS: 'rewards/partner_details',
  REWARDS_VOUCHER_LIST: 'rewards/new_voucher_list',
  REWARDS_VOUCHER_DETAILS: 'rewards/voucher_details',
  REWARDS_REEDEMED_VOUCHER_LIST: 'rewards/new_reedmed_voucher_list',
  REWARDS_EXPIRED_VOUCHER_LIST: 'rewards/expired_voucher_list',
  REWARDS_GET_TOTAL_CREDITS: 'rewards/get_total_credits',
  REWARDS_GET_APPLAUSES: 'rewards/get_applauses',
  REWARDS_GET_CREDITS_DETAILS: 'rewards/get_credit_details',
  REWARDS_GET_CREDITS_LIST: 'rewards/rewards_credit_list',
  REWARDS_REDEEM_VOUCHER: 'rewards/new_redeem_voucher',
  REWARDS_GET_CREDITS_BY_ACTION: 'rewards/get_credit_by_action',
  REWARDS_MEMBERSHIP_TYPE_LIST: 'rewards/membership_type_list',
  REWARDS_NO_AVAILABLE_COUPON: 'rewards/new_available_voucher',
  REWARDS_REALESTATE_LIST: 'rewards/new_vouche_realestate_list',
  REWARDS_REALESTATE_FILTER_LIST: 'rewards/realestate_filter',
  REWARDS_REALESTATE_LOCATION_FILTER: 'rewards/location_for_filter',
  REWARDS_MEMBERSHIP_LIST: 'membership/membership_list',
  REWARDS_MEMBERSHIP_DETAILS: '/membership/membership_detail',
  REWARDS_MEMBERSHIP_BENEFITS: '/membership/membership_benefits',
  REWARDS_USER_MEMBERSHIP: '/membership/user_membership',
  REWARDS_UPGRADE_MEMBERSHIP_PRICE: '/membership/upgrade_membership',
  REWARDS_MEMBERSHIP_APPLY_COUPON: 'membership/apply_coupon',
  REWARDS_REQUEST_NEW_VOUCHER: 'rewards/request_new_voucher',
  REWARDS_INTEREST: 'rewards/reward_interest',

  CLUB_MANAGE: 'club/manage_club',
  CLUB_HOSTED: 'club/hosted_club',
  CLUB_LIST: 'club/club_list',
  CLUB_DETAILS: 'club/club_details',
  CLUB_REQUEST: 'club/request',
  CLUB_DISLIKE: 'club/dislike',
  CLUB_MEMBERS_LIST: 'club/members',
  CLUB_INVITED_MEMBERS: 'club/invited_members',
  CLUB_REQUESTED_MEMBERS: 'club/requested_members',
  CLUB_REQUEST_ACCEPT: 'club/accept_joining_request',
  CLUB_REQUEST_REJECT: 'club/reject_joining_request',
  CLUB_MY_LIST: 'club/my_club_list',
  REQUESTED_CLUB_LIST: 'club/requested_club',
  INVITED_CLUB_LIST: 'club/invited_club',
  CLUB_REQUEST_WITHDRAW: 'club/withdraw_invitation',
  // CLUB_REQUEST_REJECT: 'club/reject_invitation',
  // CLUB_REQUEST_ACCEPT: 'club/accept_invitation',
  CLUB_LEAVE: 'club/club_left',
  CLUB_DELETE: 'club/delete',
  CLUB_UNSEND_REQUEST: 'club/unsend_request',
  SAVED_CLUB: 'club/saved_club',
  CLUB_INVITE_USER: 'club/invite_user',
  CLUB_POST_LIST: 'club/post_list',
  CLUB_EVENT_LIST: 'club/event_list',
  CLUB_MEMBER_FRIEND_LIST: 'club/friend_list',
  UPCOMING_EVENT_LIST: 'web/upcoming_event',
  CLUB_MEMBER_REMOVE: 'club/remove_member',

  CORPORATE_MEMBERSHIP: 'corporate/membership',
  CORPORATE_PACKAGES: 'corporate/packages',
  CORPORATE_MEMBERSHIP_PRODUCT: 'corporate/membership/product',
  CORPORATE_CUSTOMISE_PRODUCT: 'corporate/customise/product',
  CORPORATE_MEMBERSHIP_DETAILS: 'corporate/membership_detail',
  CORPORATE_MEMBERSHIP_BENEFITS: 'corporate/membership_benefits',
  CORPORATE_MEMBERSHIP_PRODUCT_DETAILS: 'corporate/membership/product/detail',
  CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER: 'corporate/purchased_vouchers',

  COMPANY_PAGE_CREATE: 'company_page/create',
  COMPANY_PAGE_ALL: '/company_page/get',
  COMPANY_DETAILS: '/company_page/detail',
  COMPANY_POST_LIST: '/company_page/post_list',
  COMPANY_JOB_LIST: '/company_page/job_list',
  COMPANY_EVENT_LIST: 'company_page/eventList',
  COMPANY_HOSTED_EVENT_PAST_LIST: 'company_page/hosted_company_event_past',
  COMPANY_PAGE_UPDATE: 'company_page/update',
  COMPANY_CHECK_OTP_SEND: '/company_page/send/email_otp',
  COMPANY_VERIFY_OTP: 'company_page/verify/email_otp',
  COMPANY_ACCESS_CREATE: 'company_page/access/create',
  COMPANY_MEMBERSHIP_PURCHASED_VOUCHER: 'company_page/purchased_vouchers',
  COMPANY_MEMBERSHIP_REDEEMED_VOUCHER: 'company_page/redeemed_vouchers',
  COMPANY_MEMBERSHIP_EXPIRED_VOUCHER: 'company_page/expired_vouchers',
  COMPANY_MEMBERSHIP_ACCESS_LIST: '/company_page/access/list',
  COMPANY_MEMBERSHIP_PURCHASED_DETAILS:
    '/company_page/membership/purchased_details',
  COMPANY_MEMBERSHIP_CATEGORY_PURCHASED_VOUCHERS:
    'company_page/category/purchased_vouchers',
  COMPANY_USER_ACCESS: '/company_page/user/access',
  COMPANY_ADDITIONAL_DETAILS: '/company_page/additional_detail',
  COMPANY_PAGE_SHARED: '/company_page/shared',
  COMPANY_AUTHORIZATION_PERSON: '/company_page/email/authorized_person',
  COMPANY_VOUCHER_DETAILS: 'company_page/vouchers_detail',
  COMPANY_CHECK_DOMAIN_NAME: '/company_page/check_domain_name',
  NEWS_LIST: 'news/news_list',
};

// GET_PEOPLE_RECOMMENDATION_LIST: "network/recommendation",
//  COMPANY_MEMBERSHIP_PURCHASED_DETAILS:'/company_page/membership/purchased_details',
