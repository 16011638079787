import {
  ACCOUNT_DETAILS_FULFILLED,
  ACCOUNT_DETAILS_PENDING,
  ACCOUNT_DETAILS_REJECTED,
  USER_PROFILE_DETAILS_FULFILLED,
  USER_PROFILE_DETAILS_PENDING,
  USER_PROFILE_DETAILS_REJECTED,
  USER_PROFILE_ABOUT_FULFILLED,
  USER_MANAGE_SKILL_FULFILLED,
  USER_MANAGE_SKILL_PENDING,
  USER_MANAGE_SKILL_REJECTED,
  OTHER_USER_PROFILE_DETAILS_FULFILLED,
  OTHER_USER_PROFILE_DETAILS_PENDING,
  USER_IS_VERIFIED_AND_MEMBERSHIP_FULFILLED,
  USER_IS_VERIFIED_AND_MEMBERSHIP_PENDING,
  USER_IS_VERIFIED_AND_MEMBERSHIP_REJECTED,
} from './actions';
const defaultState = {
  UserList: [],
  UserDetails: {},
  loading: true,
  errors: {},
  UserAbouts: {},
  AddNew: false,
  AddNewType: '',
  UpdateProfile: false,
  UpdateProfileType: '',
  UpdateSection: {},
  UserOtherDetails: {},
  UserProfileImage: '',
  openCompany: false,
  userStatus: {},
};

export const userreducer = (state = defaultState, action) => {
  switch (action.type) {
    //===================================post-list======================
    case ACCOUNT_DETAILS_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case ACCOUNT_DETAILS_FULFILLED: {
      return {
        ...state,
        UserList: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case ACCOUNT_DETAILS_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    case USER_PROFILE_DETAILS_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case USER_PROFILE_DETAILS_FULFILLED: {
      return {
        ...state,
        UserDetails: action.payload,
        UserProfileImage: action.payload?.image,
        loading: false,
        errors: {},
      };
    }
    case OTHER_USER_PROFILE_DETAILS_PENDING: {
      return {
        ...state,
        UserOtherDetails: {},
        loading: true,
        errors: {},
      };
    }
    case OTHER_USER_PROFILE_DETAILS_FULFILLED: {
      return {
        ...state,
        UserOtherDetails: action.payload,
        loading: false,
        errors: {},
      };
    }

   case USER_IS_VERIFIED_AND_MEMBERSHIP_FULFILLED:{
    return {
      ...state,
      userStatus: action.payload,
      loading: false,
      errors: {},
    };
   }
   case USER_IS_VERIFIED_AND_MEMBERSHIP_PENDING:{
    return {
      ...state, 
      loading: false,
      errors: {},
    };
   }
   case USER_IS_VERIFIED_AND_MEMBERSHIP_REJECTED:{
    return {
      ...state,
      loading: false,
      errors: {},
    };
   }

    case USER_PROFILE_ABOUT_FULFILLED: {
      return {
        ...state,
        UserAbouts: action.payload,
        loading: false,
        errors: {},
      };
    }
    case USER_PROFILE_DETAILS_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    case 'IMAGE_UPDATE_FULFILLED': {
      return {
        ...state,
        UserProfileImage: action.payload.image,
        loading: false,
      };
    }
    case 'OPEN_COMPANY_CHECK': {
      return {
        ...state,
        openCompany: true,
      };
    }
    case 'CLOSE_COMPANY_CHECK': {
      return {
        ...state,
        openCompany: false,
      };
    }

    case 'CLOSE_ADD_NEW_ABOUT': {
      return {
        ...state,
        AddNew: false,
        AddNewType: '',
      };
    }
    case 'OPEN_ADD_NEW_ABOUT': {
      return {
        ...state,
        AddNew: true,
        AddNewType: action.payload,
      };
    }
    case 'CLOSE_UPDATE_ABOUT': {
      return {
        ...state,
        UpdateProfile: false,
        UpdateProfileType: '',
        UpdateSection: {},
      };
    }
    case 'OPEN_UPDATE_ABOUT': {
      return {
        ...state,
        UpdateProfile: true,
        UpdateProfileType: action.payload.type,
        UpdateSection: action.payload.data,
      };
    }
    case USER_MANAGE_SKILL_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }
    case USER_MANAGE_SKILL_FULFILLED: {
      return {
        ...state,
        loading: false,
        errors: {},
      };
    }
    case USER_MANAGE_SKILL_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    default:
      return state;
  }
};
