import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("md")]: {
      height: "50px",
    },
    [theme.breakpoints.up("md")]: {
      height: "72px",
      paddingLeft: "32px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "72px",
      paddingLeft: "72px",
    },
  },
  outergrid: {
    [theme.breakpoints.down("md")]: {
      minHeight: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "100vh",
    },
  },
}));
