import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Grid, Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CardLong from "../../components/CardLong/CardLong";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import CxoButton from "../../components/CxoButton/CxoButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { dateTime } from "../../constants/app";
import { useNavigate } from "react-router-dom";
import * as postActions from "../../store/post";
import * as authActions from "../../store/auth";
import * as jobsActions from "../../store/jobs";
import moment from "moment";

const Message = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(30);
  const [message, setMessage] = useState("");
  const [replyMessage, setReplyMessage] = useState([]);
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  console.log("jobDetails", jobDetails);
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  useEffect(async () => {
    const data = {
      job_id: jobDetails?.id,
      from_user_id: user?.id,
      to_user_id: jobDetails?.userId,
    };
    const message = await dispatch(jobsActions.jobMessageList(data));
    console.log("message", message);
    if (message?.status === 1) {
      setReplyMessage(message?.data);
    }
  }, []);

  const sendMessage = async (e) => {
    const data = {
      job_id: jobDetails?.id,
      from_user_id: user?.id,
      to_user_id: jobDetails?.userId,
      subject: "subject",
      message: message,
      datetime: dateTime(),
      status: "new",
    };
    if (message?.length > 0) {
      const sendmessage = await dispatch(
        jobsActions.jobSendMessageStatus(data)
      );
      setMessage("");
      const dataReply = {
        job_id: jobDetails?.id,
        from_user_id: user?.id,
        to_user_id: jobDetails?.userId,
      };
      const messagerpl = await dispatch(jobsActions.jobMessageList(dataReply));
      if (messagerpl?.status === 1) {
        setReplyMessage(messagerpl?.data);
      }
    }
  };
  const messageDelete = async (msg) => {
    console.log("messageDelete", msg);
    const messageDelete = {
      id: msg?.id,
    };
    if (msg?.from_user_id === user?.id) {
      const deleteMessage = await dispatch(
        jobsActions.jobMessageDelete(messageDelete)
      );

      const data = {
        job_id: jobDetails?.id,
        from_user_id: user?.id,
        to_user_id: jobDetails?.userId,
      };
      const message = await dispatch(jobsActions.jobMessageList(data));

      if (message?.status === 1) {
        setReplyMessage(message?.data);
      } else if (
        message?.status === 1 &&
        message?.message === "No record found"
      ) {
        setReplyMessage([]);
      }
    }
  };

  return (
    <Fragment>
      <Box sx={{ paddingTop: { xs: "8px", md: "8px" } }}>
        <Box px={{ md: 2, xs: 0 }} className="trianglecontainer">
          <Box sx={{ position: "absolute", zIndex: 1, right: "19px" }}>
            <AttachFileOutlinedIcon
              sx={{ color: "#000000", fontSize: "16px" }}
            />
          </Box>
          <TextField
            variant="standard"
            fullWidth
            multiline
            rows={4}
            maxRows={8}
            value={message}
            onChange={handleMessage}
            placeholder="Type here"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              borderRadius: "7px",
              backgroundColor: "#333",
              boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
              padding: "8px",
            }}
          />
        </Box>
        <Box
          px={2}
          pt={1}
          pb={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CxoButton label="Send" onClick={sendMessage} />
        </Box>
      </Box>

      {replyMessage?.map((message, index) => {
        return (
          <Box key={index} pt={2}>
            <Box px={2} className="trianglecontainer">
              <Box
                sx={{ position: "absolute", zIndex: 1, right: "19px" }}
                onClick={() => messageDelete(message)}
              >
                {message?.from_user_id === user?.id && (
                  <DeleteOutlinedIcon
                    sx={{
                      color: "#333",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "80px",
                  borderRadius: "7px",
                  backgroundColor: "#333",
                  boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
                }}
              >
                <Stack direction="column">
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ paddingLeft: "16px", paddingTop: "8px" }}
                  >
                    <Typography variant="h5s" component="h5s">
                      {message?.from_user?.first_name}{" "}
                      {message?.from_user?.last_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ color: "#959498" }}
                    >
                      {moment(message?.datetime).format("LT, LL")}
                    </Typography>
                  </Stack>
                  <Box px={2} py={1}>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        lineHeight: "1.2em",
                        color: "#fff",
                        fontSize: "15px",
                      }}
                    >
                      {message?.message}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Fragment>
  );
};

export default React.memo(Message);
