import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import Chat from '../../assets/home/Chat_Active.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
import Menuicon from '../../assets/menuicon';
import EventsIcon from '../../assets/eventsicon';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Share from '../../assets/home/Share_White.png';
import credits_gold from '../../assets/header/Credits_Gold.png';
import Membership_Star_White from '../../assets/header/Membership_Star_White.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as jobsActions from '../../store/jobs';
import * as eventsActions from '../../store/events';
import * as activityActions from '../../store/activity';
import { useNavigate, useLocation } from 'react-router-dom';
import CxoButton from '../../components/CxoButton/CxoButton';
import deleticon from '../../assets/home/Delete_White.png';
import invite from '../../assets/home/Invited_by_user_White.png';
import * as rewardsActions from '../../store/rewards';
import moment from 'moment';

const RewardPartnerVoucherCard = (props) => {
  console.log('RewardPartnerVoucherCard', props);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const navigate = useNavigate();

  const voucherDetails = async () => {
    const voucher = await dispatch(
      rewardsActions.voucherDetailsOpen(props?.voucher),
    );
    navigate('/voucher/rewards/details', { state: { voucher: props?.voucher } });
  };

  const handleClaim = async (e) =>{
 

    const data = {
      user_id: user?.id,
      product_id: props?.voucher?.id,
      quantity: 1,
      coupons: [{}]
    }
    const voucher = await dispatch(
      rewardsActions.rewardsRequestNewVoucher(data)
    );
    if(voucher?.status === 1) {

    }
  }

  if (
    props?.status === 'redeemed' ||
    props?.status === 'purchased' ||
    props?.status === 'expired'
  ) {
    return (
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '171px',
          width: '100%',
        }}
        onClick={voucherDetails}>
        {props?.voucher?.image ? (
          <CardMedia
            component="img"
            sx={{
              width: 160,
              minWidth: '160px',
              borderRadius: '5px',
              backgroundColor: '#262525',
              margin: '8px 0px 8px 8px',
              cursor: 'pointer',
              objectFit: 'contain',
            }}
            image={
              props?.voucher?.image
                ? props?.voucher?.image
                : props?.voucher?.product_image
            }
          />
        ) : (
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '216px',
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
              borderRadius: '16px',
              padding: '8px',
              objectFit: 'cover',
              backgroundColor: '#222121',
              margin: '8px 0px 8px 8px',
              cursor: 'pointer',
            }}
            alt="">
            <Avatar sx={{ width: 72, height: 72 }}>
              {props?.userlist?.first_name[0] + props?.userlist?.last_name[0]}
            </Avatar>
          </Box>
        )}

        <Stack
          direction="column"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '16px',
          }}>
          <Box
            pt={1}
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              justifyContent: 'flex-start',
            }}>
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontSize: '20px',
                lineHeight: '23px',
                letterSpacing: '0.31px',
                fontWeight: 600,
              }}>
              {props?.voucher?.name
                ? props?.voucher?.name?.substring(0, 60)
                : ''}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 600,
                fontFamily: 'Open Sans',
              }}>
              {props?.voucher?.quantity > 1
                ? props?.voucher?.quantity + ' Vouchers purchased'
                : props?.voucher?.quantity + ' Voucher purchased'}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.22px',
                fontWeight: 600,
              }}>
              {props?.voucher?.offer_details?.length > 120
                ? props?.voucher?.offer_details?.substring(0, 120)
                : props?.voucher?.offer_details}
            </Typography>
          </Box>
          <Box pb={0}>
            <Typography variant="h6" component="h6">
              {'Purchased on ' + moment(props?.voucher?.createdAt).format('LL')}
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6">
              {props?.voucher?.applicalble_on
                ? props?.voucher?.applicalble_on?.substring(0, 120)
                : 'Term and Conditions applied'}
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  } else if (props?.status === 'partnerVoucher') {
    return (
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '171px',
          maxHeight: '172px',
          width: '100%',
        }}
        >
        {props?.voucher?.image ? (
          <CardMedia
            component="img"
            sx={{
              width: 160,
              minWidth: '160px',
              borderRadius: '5px',
              backgroundColor: '#262525',
              margin: '8px 0px 8px 8px',
              cursor: 'pointer',
              objectFit: 'contain',
            }}
            image={props?.voucher?.image ? props?.voucher?.image : ''}
            onClick={voucherDetails}
          />
        ) : (
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '216px',
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
              borderRadius: '16px',
              padding: '8px',
              objectFit: 'contain',
              backgroundColor: '#222121',
              margin: '8px 0px 8px 8px',
              cursor: 'pointer',
            }}
            alt="">
            <Avatar sx={{ width: 72, height: 72 }} onClick={voucherDetails}>
              {props?.userlist?.first_name[0] + props?.userlist?.last_name[0]}
            </Avatar>
          </Box>
        )}

        <Stack
          direction="column"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '16px',
          }}>
          <Box
          onClick={voucherDetails}
            pt={1}
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}>
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontSize: '20px',
                lineHeight: '23px',
                letterSpacing: '0.31px',
                fontWeight: 600,
              }}>
              {props?.voucher?.name ? props?.voucher?.name : ''}
            </Typography>

            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 600,
                fontFamily: 'Open Sans',
              }}>
              {props?.voucher?.location ? props?.voucher?.location : ''}
            </Typography>
          </Box>
          <Box onClick={voucherDetails}>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 600,
                whiteSpace: 'pre-line',
              }}>
              {props?.voucher?.offer_details
                ? props?.voucher?.offer_details?.substring(0, 120)
                : ''}
            </Typography>
          </Box>
          <Stack direction="row" sx={{width:"100%", display:"flex", justifyContent:"space-between"}}>
          <Box pb={1}>
            <Typography variant="h6" component="h6">
            
              {props?.voucher?.applicalble_on
                ? props?.voucher?.applicalble_on?.substring(0, 120)
                : 'Term and Conditions applied'}
            </Typography>
          </Box>

          </Stack>
        </Stack>
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          justifyContent: 'space-between',
          minHeight: '171px',
          width: '100%',
        }}
        onClick={voucherDetails}>
        {props?.voucher?.image ? (
          <CardMedia
            component="img"
            sx={{
              width: 160,
              minWidth: '160px',
              borderRadius: '5px',
              backgroundColor: '#262525',
              margin: '8px 0px 8px 8px',
              cursor: 'pointer',
            }}
            image={props?.voucher?.image ? props?.voucher?.image : ''}
          />
        ) : (
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '216px',

              maxWidth: { xs: 350, md: 250 },
              borderRadius: '16px',
              padding: '8px',
              objectFit: 'cover',
              backgroundColor: '#222121',
              margin: '8px 0px 8px 8px',
              cursor: 'pointer',
            }}
            alt="">
            <Avatar sx={{ width: 72, height: 72 }}>
              {props?.userlist?.first_name[0] + props?.userlist?.last_name[0]}
            </Avatar>
          </Box>
        )}

        <Stack
          direction="column"
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '16px',
          }}>
          <Box
            pt={1}
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              justifyContent: 'flex-start',
            }}>
            <Typography
              variant="h6s"
              component="h6s"
              sx={{
                fontSize: '20px',
                lineHeight: '23px',
                letterSpacing: '0.31px',
                fontWeight: 600,
              }}>
              {props?.voucher?.name ? props?.voucher?.name : ''}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.22px',
                fontWeight: 600,
              }}>
              {props?.voucher?.offer_details
                ? props?.voucher?.offer_details?.substring(0, 120)
                : ''}
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6">
              {'Purchased on ' + moment(props?.voucher?.createdAt).format('LL')}
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="h6" component="h6">
              {props?.voucher?.applicalble_on
                ? props?.voucher?.applicalble_on?.substring(0, 120)
                : 'Term and Conditions applied'}
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  }
};

export default React.memo(RewardPartnerVoucherCard);





// { props?.voucher?.id  === 32 && 
//   <Box sx={{padding:"0px 8px 8px 0px"}}>
//           <CxoButtonWithIcon
//                       label="Claim"
//                       onClick={(e) => handleClaim(e)}
//                     />
//   </Box>
// }
//   { props?.voucher?.id  === 33 && 
//   <Box sx={{padding:"0px 8px 8px 0px"}}>
//           <CxoButtonWithIcon
//                       label="Claim"
//                       onClick={(e) => handleClaim(e)}
//                     />
//   </Box>
// }