import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import JobCard from '../../components/JobCard/JobCard';
import JobCardSkeleton from '../../components/Skeleton/JobCardSkeleton';
import * as jobsActions from '../../store/jobs';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const JobHire = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );
  const openJob = useSelector((state) => state?.jobs?.JobListOpen);
  const inactiveJob = useSelector((state) => state?.jobs?.JobListInactive);
  const jobListLoading = useSelector((state) => state?.jobs?.jobListLoading);

  const closedJob = useSelector((state) => state?.jobs?.JobListClosed);
  const [noofOpenjob, setNoofOpenjob] = React.useState(4);
  const [noofInactivejob, setNoofInactivejob] = React.useState(4);
  const [noofClosejob, setNoofClosejob] = React.useState(4);
  const [pageClosejob, setpageClosejob] = React.useState(0);
  const [pageOpenjob, setpageOpenjob] = React.useState(0);
  const [pageInactivejob, setpageInactivejob] = React.useState(0);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'open',
      page_no: 0,
    };
    // const jobs = await dispatch(jobsActions.getJobsList(data));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'inactive',
      page_no: 0,
    };
    const jobs = await dispatch(jobsActions.getJobsListInactive(data));
    setpageInactivejob(0);
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'closed',
      page_no: 0,
    };
    const jobs = await dispatch(jobsActions.getJobsListClose(data));
    setpageClosejob(0);
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user.id,
    };
    // const jobs = await dispatch(jobsActions.getRecommendedList(data));
  }, []);

  const seeMore = () => {
    if (openJob?.length > noofOpenjob) {
      setNoofOpenjob(openJob?.length);
    }
    if (openJob?.length === noofOpenjob) {
      setNoofOpenjob(4);
    }
  };
  const seeMoreInactive = () => {
    if (inactiveJob?.length > noofInactivejob) {
      setNoofInactivejob(inactiveJob?.length);
    } else if (inactiveJob?.length === noofInactivejob) {
      setNoofInactivejob(4);
    }
  };
  const seeMoreClose = () => {
    if (closedJob?.length > noofClosejob) {
      setNoofClosejob(closedJob?.length);
    } else if (closedJob?.length === noofClosejob) {
      setNoofClosejob(4);
    }
  };

  const seeMoreOpenJob = async () => {
    const data = {
      user_id: user.id,
      status: 'open',
      page_no: pageOpenjob + 1,
    };
    const jobs = await dispatch(jobsActions.getJobsList(data));
    setpageOpenjob(pageOpenjob + 1);
  };

  const seeMoreInactiveJob = async () => {
    const data = {
      user_id: user.id,
      status: 'inactive',
      page_no: pageInactivejob + 1,
    };
    const jobs = await dispatch(jobsActions.getJobsListInactive(data));
    setpageInactivejob(pageInactivejob + 1);
  };

  const seeMoreCloseJob = async () => {
    const data = {
      user_id: user.id,
      status: 'closed',
      page_no: pageClosejob + 1,
    };
    const jobs = await dispatch(jobsActions.getJobsListClose(data));
    setpageClosejob(pageClosejob + 1);
  };

  return (
    <Fragment>
      <Box px={0}>
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Open{' '}
                  {openJob?.length > 0 ? '(' + openJob[0].full_count + ')' : ''}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMore}>
                <Typography variant="h5" component="h5"></Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>

        {jobListLoading ? (
          <Box pt={2}>
            <JobCardSkeleton />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                marginTop: '8px',
              }}>
              <Grid container>
                {openJob?.length > 0 &&
                  openJob?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <JobCard joblist={list} type="hire" />
                      </Grid>
                    );
                  })}

                {openJob?.length === 0 && (
                  <Box pl={3} pt={3}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': { fontSize: '14px' },
                      }}>
                      Currently there are no open jobs.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>

            {openJob?.length > 0 &&
              openJob[0]?.full_count !== openJob?.length && (
                <Box
                  pl={3}
                  pt={3}
                  onClick={seeMoreOpenJob}
                  sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    flex: 1,
                    cursor: 'pointer',
                  }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
                    See more
                  </Typography>
                </Box>
              )}
          </>
        )}
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Inactive{' '}
                  {inactiveJob?.length > 0
                    ? '(' + inactiveJob[0].full_count + ')'
                    : ''}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMoreInactive}></Box>
            </Stack>
          </Paper>
        </Box>
        <Box
          sx={{
            marginTop: '8px',
          }}>
          <Grid container>
            {inactiveJob?.length > 0 &&
              inactiveJob?.map((list, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      paddingRight: index % 2 === 0 ? '8px' : '0px',
                      marginTop: '8px',
                    }}>
                    <JobCard joblist={list} type="inactive_job" />
                  </Grid>
                );
              })}

            {inactiveJob?.length == 0 && (
              <Box pl={3} pt={3}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
                  Currently there are no inactive jobs.
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>

        {inactiveJob?.length > 0 &&
          inactiveJob[0]?.full_count !== inactiveJob?.length && (
            <Box
              pl={3}
              pt={3}
              onClick={seeMoreInactive}
              sx={{
                justifyContent: 'center',
                display: 'flex',
                flex: 1,
                cursor: 'pointer',
              }}>
              <Typography
                variant="h5"
                component="h5"
                sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
                See more
              </Typography>
            </Box>
          )}

        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Closed{' '}
                  {closedJob?.length > 0
                    ? '(' + closedJob[0].full_count + ')'
                    : ''}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMoreClose}>
                {closedJob?.length > 4 ? (
                  <Typography variant="h5" component="h5">
                    {closedJob?.length === noofClosejob
                      ? 'See Less'
                      : 'See All'}
                  </Typography>
                ) : (
                  <Typography variant="h5" component="h5"></Typography>
                )}
              </Box>
            </Stack>
          </Paper>
        </Box>
        <Box
          sx={{
            marginTop: '8px',
          }}>
          <Grid container>
            {closedJob?.length > 0 &&
              closedJob?.map((list, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      paddingRight: index % 2 === 0 ? '8px' : '0px',
                      marginTop: '8px',
                    }}>
                    <JobCard joblist={list} type="closed" />
                  </Grid>
                );
              })}
            {closedJob?.length === 0 && (
              <Box pl={3} pt={3}>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
                  Currently there are no closed jobs.
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>

        {closedJob?.length > 0 &&
          closedJob[0]?.full_count !== closedJob?.length && (
            <Box
              pl={3}
              pt={3}
              onClick={seeMoreClose}
              sx={{
                justifyContent: 'center',
                display: 'flex',
                flex: 1,
                cursor: 'pointer',
              }}>
              <Typography
                variant="h5"
                component="h5"
                sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
                See more
              </Typography>
            </Box>
          )}
      </Box>
    </Fragment>
  );
};

export default React.memo(JobHire);

// {inactiveJob?.length > 4 ? (
//   <Typography variant="h5" component="h5">
//     {inactiveJob?.length === noofInactivejob
//       ? "See Less"
//       : "See All"}
//   </Typography>
// ) : (
//   <Typography variant="h5" component="h5"></Typography>
// )}

// {openJob?.length === noofOpenjob ? "See less" : "See All"}
