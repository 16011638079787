import {
  GET_PEOPLE_RECOMMENDATION_LIST_FULFILLED,
  GET_PEOPLE_RECOMMENDATION_LIST_PENDING,
  GET_PEOPLE_RECOMMENDATION_LIST_REJECTED,
  GET_MY_NETWORK_LIST_REJECTED,
  GET_EXISTING_NETWORK_LIST_FULFILLED,
  GET_EXISTING_NETWORK_LIST_PENDING,
  GET_EXISTING_NETWORK_LIST_REJECTED,
  GET_SENT_NETWORK_LIST_FULFILLED,
  GET_SENT_NETWORK_LIST_PENDING,
  GET_SENT_NETWORK_LIST_REJECTED,
  GET_PENDING_NETWORK_LIST_FULFILLED,
  GET_PENDING_NETWORK_LIST_PENDING,
  GET_PENDING_NETWORK_LIST_REJECTED,
  GET_MY_NETWORK_LIST_NEW_FULFILLED,
  GET_MY_NETWORK_LIST_MORE_FULFILLED,
  GET_NETWORK_FILTER_LIST_FULFILLED,
} from './actions';

const defaultState = {
  MyNetwokList: [],
  ExistingNetworkList: [],
  PendingNetworkList: [],
  SentNetworkList: [],
  User_Count: {},
  existing_User_count: [],
  pending_User_count: [],
  sent_User_count: [],
  FollowersList: [],
  FollowersListCopy: [],
  FollowUserList: [],
  FollowUserListCopy: [],
  AllUserList: [],
  AllUserListCopy: [],
  userRecommendationList: [],
  removependingRequestRes: '',
  connectionRequestRes: '',
  acceptRequestRes: '',
  DislikeNetworkRes: '',
  removeExistingRequestRes: '',
  loading: true,
  errors: {},
  myConnectionList: [],
  userdiscoverList: [],
  networkLoading: false,
  discoverLoading: false,
};
export const usernetworkreducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PEOPLE_RECOMMENDATION_LIST_PENDING:
      return { ...state, ...action.payload, discoverLoading: true };
    case GET_PEOPLE_RECOMMENDATION_LIST_FULFILLED:
      return {
        ...state,
        AllUserList: action.payload.data,
        userRecommendationList: action.payload.data,
        discoverLoading: false,
      };
    case GET_PEOPLE_RECOMMENDATION_LIST_REJECTED:
      return { ...state, ...action.payload, discoverLoading: false };
    case 'GET_MY_NETWORK_LIST_PENDING':
      return{ ...state, ...action.payload}
    case 'GET_MY_NETWORK_LIST_FULFILLED':
      if (action?.payload?.type === 'new') {
        return {
          ...state,
          userdiscoverList: action?.payload?.data?.data,
          loading: false,
          errors: {},
        };
      } else if (action?.payload?.type === 'more') {
        return {
          ...state,
          userdiscoverList: [
            ...state.userdiscoverList,
            ...action?.payload?.data?.data,
          ],
          loading: false,
          errors: {},
        };
      }
      break;
    case GET_MY_NETWORK_LIST_NEW_FULFILLED:
      return {
        ...state,
        userdiscoverList: action?.payload?.data,
        loading: false,
        errors: {},
      };
    case GET_NETWORK_FILTER_LIST_FULFILLED:
      return {
        ...state,
        userdiscoverList: action?.payload?.data,
        loading: false,
        errors: {},
      };
    case GET_MY_NETWORK_LIST_MORE_FULFILLED:
      return {
        ...state,
        userdiscoverList: [...state.userdiscoverList, ...action?.payload?.data],
        loading: false,
        errors: {},
      };
    case GET_MY_NETWORK_LIST_REJECTED:
      return { ...state, ...action.payload };

    // =================================Existing-Network-list========================================================

    case GET_EXISTING_NETWORK_LIST_PENDING:
      return {
        ...state,

        loading: true,
        errors: {},
      };

    case GET_EXISTING_NETWORK_LIST_FULFILLED:
      return {
        ...state,
        ExistingNetworkList: action?.payload?.data,
        loading: false,
        errors: {},
      };

    case GET_EXISTING_NETWORK_LIST_REJECTED:
      return {
        ...state,
        loading: false,
        ExistingNetworkList: [],
        errors: { global: action.payload.message },
      };
    // =================================Pending-Network-list========================================================

    case GET_PENDING_NETWORK_LIST_PENDING:
      return {
        ...state,
        networkLoading: true,
        loading: true,
        errors: {},
      };

    case GET_PENDING_NETWORK_LIST_FULFILLED:
      return {
        ...state,
        PendingNetworkList: action.payload?.data,
        loading: false,
        networkLoading: false,
        errors: {},
      };

    case GET_PENDING_NETWORK_LIST_REJECTED:
      return {
        ...state,
        loading: false,
        networkLoading: false,
        errors: { global: action.payload.message },
      };
    // =================================Sent-Network-list========================================================

    case GET_SENT_NETWORK_LIST_PENDING:
      return {
        ...state,

        loading: true,
        errors: {},
      };
    case 'MY_CONNECTIONS_FULFILLED':
      return {
        ...state,
        loading: false,
        myConnectionList: action?.payload?.data,
      };

    case GET_SENT_NETWORK_LIST_FULFILLED:
      return {
        ...state,
        SentNetworkList: action?.payload?.data,
        loading: false,
        errors: {},
      };

    case GET_SENT_NETWORK_LIST_REJECTED:
      return {
        ...state,
        loading: false,
        SentNetworkList: [],
        errors: { global: action?.payload?.message },
      };

    default:
      return state;
  }
};
