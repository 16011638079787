import { createTheme } from '@mui/material/styles';
import palette from "./palette";
import typography from "./typography";

const theme = createTheme({
  typography,
  palette: {
    mode: 'dark',
    dark: '#1F1E1E',
    main: '#262525',
    color: {
      dark: '#1F1E1E',
      main: '#262525',
    },
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#38CD84',
      dark: '#ba000d',
      contrastText: '#000',
    },
    palette,
  },
});

export default theme;
