import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import { useForm, Controller } from 'react-hook-form';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { dateTime } from '../../constants/app';
import Header from "../../components/Header/Header";
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Footer from '../../components/HomeHeader/footer';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import CxoInput from '../../components/CxoInput/CxoInput';
import CxoButton from '../../components/CxoButton/CxoButton';
import Menuicon from '../../assets/menuicon';
import * as jobsActions from '../../store/jobs';
import * as userActions from '../../store/user';
import * as onboardActions from '../../store/onboard';
import { useNavigate } from 'react-router-dom';
import config  from '../../services/apiConfig';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));

const ReverificationDetails = () => {
  const [formData, setFormData] = useState([]);
  const [name, setName] = useState('');
  const [imgData, setImgData] = useState(null);
  const [type, setType] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Store the selected status for each dropdown

  const [selectedStatuses, setSelectedStatuses] = useState({});
  const user = useSelector((state) => state?.user?.UserDetails);
  const userabouts = useSelector((state) => state?.user?.UserAbouts);

  // Handle status change for each dropdown

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [fileup, setFile] = useState('');

  const handleSubmitForm = async (data) => {

    const datalinkedin = {
      user_id: user?.id,
      about: data?.about,
      linkedin_url: data?.linkedinlink,
    };
    const addbio = await dispatch(
      onboardActions.postBio(datalinkedin),
    );
    if (fileup) {
      const formDatanew = new FormData();
      formDatanew.append('id',  0);
      formDatanew.append('user_id', user?.id);
      formDatanew.append('experience', data?.yearofexp);
      formDatanew.append('salary_drawn', data?.salary);
      formDatanew.append('datetime', dateTime()); 
      formDatanew.append('jd_file', fileup, name);
      const savejob = await dispatch(
        jobsActions.userChallengeManageJobPreference(
          formDatanew,
          async (error, response) => {
            if (response) {
              const datauser ={
                user_id: user.id
              }
              const openapplyjob = await dispatch(
                userActions.userChallengeProfile(datauser),
              );
              window.location.href = config.baseURL + "feed"
              // window.close();
            }
          },
        ),
      );
    }
  };

  const getDocument = async (event) => {
    setFile(event.target.files[0]);
    setType('document');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };


  // userChallengeProfile

  return (
    <>
      <Box
        pt={5}
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <form onSubmit={handleSubmit(handleSubmitForm)} style={{width:"800px"}}>

        <Box px={0} sx={{ width: '100%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                    }}>
                    <Menuicon name="experience" color="#38CD84" />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Stack direction="column" sx={{ width: '100%' }}>
                      <CxoInput
                        label="Total Year of Experience"
                        value={value}
                        onChange={onChange}
                        err={errors?.yearofexp ? errors?.yearofexp : false}
                        type="number"
                        fontFamily="Open Sans"
                      />
                      <Box
                        sx={{
                          fontSize: '9px',
                          height: '10px',
                          paddingLeft: '8px',
                        }}>
                        {errors?.yearofexp?.message}{' '}
                      </Box>
                    </Stack>
                  )}
                  name="yearofexp"
                  rules={{
                    required: {
                      value: true,
                      message: 'Year of experience is required',
                    },
                  }}
                  defaultValue=""
                />
              </Stack>
            </Box>
          <Stack spacing={1} direction="row">
            <Box
              pt={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                    maxWidth: 'min-content',
                    minWidth: '24px',
                  }}>
                  <Menuicon name="salary" color="#38CD84" />
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Stack>
                    <CxoInputCss
                      label="Last Salary Drawn (In lacs)"
                      value={value}
                      err={errors?.salary ? errors?.salary : false}
                      onChange={onChange}
                    />
                    <Box
                      sx={{
                        fontSize: '9px',
                        height: '10px',
                        paddingLeft: '8px',
                      }}>
                      {errors?.salary?.message}
                      {console.log('errors', errors)}
                    </Box>
                  </Stack>
                )}
                name="salary"
                required
                rules={{
                  required: {
                    value: true,
                    message: 'Salary is required',
                  },
                }}
                defaultValue=""
              />
            </Box>
          </Stack>
          <Box px={0} sx={{ width: '100%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                    }}>
                    <Menuicon name="link" color="#38CD84" />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Stack direction="column" sx={{ width: '100%' }}>
                      <CxoInput
                        label="LinkedIn Link"
                        value={value}
                        err={
                          errors?.linkedinlink ? errors?.linkedinlink : false
                        }
                        onChange={onChange}
                      />
                      <Box
                        sx={{
                          fontSize: '9px',
                          height: '10px',
                          paddingLeft: '8px',
                        }}>
                        {errors?.linkedinlink?.message}{' '}
                      </Box>
                    </Stack>
                  )}
                  name="linkedinlink"
                  rules={{
                    required: {
                      value: true,
                      message: 'LinkedIn link is required',
                    },
                  }}
                  defaultValue=""
                />
              </Stack>
            </Box>

          <Stack spacing={1} direction="row">
            <Box
              pt={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                    maxWidth: 'min-content',
                    minWidth: '24px',
                  }}>
                  <AttachFileOutlinedIcon sx={{ color: '#38CD84' }} />
                </Box>
              </Box>
            </Box>
            <Stack spacing={2} direction="row">
              <Box pt={1} pl={1}>
                <label htmlFor="icon-button-doc">
                  <Stack direction="column" spacing={1}>
                    <Typography variant="h5s" component="h5s">
                      Attach Resume
                    </Typography>
                    <Typography variant="h6" component="h6">
                      {name ? name : ''}
                    </Typography>
                  </Stack>
                </label>
                <input
                  id="icon-button-doc"
                  type="file"
                  style={{
                    display: 'none',
                    width: '200px',
                    height: '80px',
                  }}
                  onChange={getDocument}
                  name="docum"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}></Box>
            </Stack>
          </Stack>

          <Box py={1} sx={{ width: '100%' }}>
            <Stack spacing={2} direction="row">
              <Box
                pt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                    maxWidth: 'min-content',
                    minWidth: '24px',
                  }}>
                  <Menuicon name="bio" color="#38CD84" />
                </Box>
              </Box>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CssTextField
                    variant="standard"
                    fullWidth
                    multiline
                    rows={3}
                    maxRows={6}
                    value={value}
                    onChange={onChange}
                    color="secondary"
                    label="Profile Summary"
                    sx={{
                      scrollbarWidth: 'none',
                    }}
                  />
                )}
                name="about"
                defaultValue=""
              />
            </Stack>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              paddingTop: '16px',
              paddingBottom: '32px',
            }}>
            {' '}
            <CxoButton label="Submit" type="submit" />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ReverificationDetails;
