import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Paper, Grid, Box, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import ProfileShort from "../../components/ProfileShort/ProfileShort";
import BottomNavigator from "../../components/BottomNavigator/BottomNavigator";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import HomeArticle from "../../containers/HomeContainer/HomeArticle";
import Bookmarks from "./Bookmarks";
import Helpandfeedback from "../../components/Helpandfeedback/Helpandfeedback";
import LeftNav from "../../components/LeftNav/LeftNav";

const BookmarksContainer = (props) => {
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const feedbackShow = useSelector((state) => state?.post?.FeedbackShow);
  return (
    <Fragment>
      <Helmet>
        <title>Bookmarks || LinkCXO - Exclusive CXO Networking</title>
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
        <Header />
      </Box>
      {openSnackbar && <CustomizedSnackbars />}
      {feedbackShow && <Helpandfeedback />}
      <Box pt={8} sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ maxWidth: "1280px", justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={3}
            sm={8}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box py={1}>
              <LeftNav />
            </Box>
          </Grid>
          <Grid item xs={12} sm={10} md={9}>
            <Bookmarks />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: "0%",
          width: "100%",
          zIndex: 2001,
        }}
      >
        <BottomNavigator />
      </Box>
    </Fragment>
  );
};

export default React.memo(BookmarksContainer);
