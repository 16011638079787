import React, { Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Menuicon from '../../assets/menuicon';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import TextField from '@mui/material/TextField';
import CxoButton from '../CxoButton/CxoButton';
import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CommentCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [editComment, setEditComment] = React.useState(false);
  const [commentText, setCommenttext] = React.useState('');
  const isMenuOpen = Boolean(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(async () => {
    if (props?.comment?.content) {
      setCommenttext(props?.comment?.content);
    }
  }, []);

  const handleMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);

  const handleChange = (event) => {
    setCommenttext(event.target.value);
  };

  const editPost = () => {
    setCommenttext(props?.comment?.content);
    setEditComment(!editComment);
    setAnchorEl(!anchorEl);
  };
  const deletePost = () => {
    const data = {
      user_id: user?.id,
      id: props?.comment?.id,
    };
    props.deleteComment(data);
  };

  const addComment = async () => {
    if (commentText.length > 0) {
      setCommenttext('');
      setEditComment(!editComment);
      return props.updateComment(commentText, props?.comment?.id);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: '#262525',
        marginTop: '2px',
      }}>
      <Fragment>
        <CardHeader
          sx={{ paddingTop: '8px', paddingBottom: '0px' }}
          avatar={
            <Avatar
              src={
                props?.comment?.user_image
                  ? props?.comment?.user_image
                  : PersonIcon
              }
              sx={{ width: 24, height: 24 }}
            />
          }
          action={
            <Stack
              spacing={1}
              direction="row"
              sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton aria-label="addfriend" onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              {anchorEl && user?.id === props?.comment?.user_id && (
                <Box sx={{ marginLeft: '0px!important' }}>
                  <Box
                    anchorEl={anchorEl}
                    id="primary-search-account-menu"
                    keepMounted
                    sx={{
                      marginTop: '12px',
                      scrollbarWidth: 'thin',
                      position: 'absolute',
                      backgroundColor: '#2c2c2d',
                      height: 'auto',
                      width: '129px',
                      marginLeft: '-129px',
                      zIndex: '1000',
                      boxShadow:
                        '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                      borderRadius: '2px',
                    }}
                    open={anchorEl}
                    onClose={handleMenuClose}>
                    <Box>
                      <List>
                        <MenuItem
                          button
                          sx={{ padding: '0px 12px' }}
                          onClick={editPost}>
                          <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          button
                          sx={{ padding: '0px 12px' }}
                          onClick={deletePost}>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
              )}
            </Stack>
          }
          title={
            <Stack spacing={1} direction="row">
              <Typography variant="h5s" component="h5s">
                {props?.comment?.first_name} {props?.comment?.last_name}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>
                {props?.comment?.new_created_datetime}
              </Typography>
            </Stack>
          }
          subheader={
            <Stack spacing={1} direction="row">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '13px',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>
                {props?.comment?.designation ? props?.comment?.designation : ''}{' '}
                ||
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '13px',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>
                {props?.comment?.company_name
                  ? props?.comment?.company_name
                  : ''}
              </Typography>
            </Stack>
          }
        />

        {editComment ? (
          <Fragment>
            <CardContent
              sx={{
                paddingLeft: '8px',
                paddingRight: '8px',
                '@media (max-width: 500px)': {
                  paddingLeft: '0px',
                  paddingRight: '0px',
                },
              }}>
              <Box
                px={{ md: 2, xs: 0 }}
                sx={{ zIndex: '1200' }}
                className="editor">
                <TextField
                  variant="standard"
                  fullWidth
                  multiline
                  rows={2}
                  maxRows={6}
                  value={commentText}
                  onChange={handleChange}
                  placeholder="Start Typing Here"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Box>
            </CardContent>

            <CardActions
              disableSpacing
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}>
                <CxoButton
                  label="Save"
                  onClick={addComment}
                  height="24px"
                  width="90px"
                  minWidth="90px"
                  minHeight="24px"
                  disabled="true"
                />
              </Box>
            </CardActions>
          </Fragment>
        ) : (
          <CardContent
            sx={{
              paddingTop: '4px',
              paddingBottom: '16px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                paddingTop: '3px',
                color: '#ffffff',
              }}
              style={{ wordWrap: 'break-word' }}>
              {props?.comment?.content}
            </Typography>
          </CardContent>
        )}
      </Fragment>
    </Card>
  );
}

// { props?.post?.document &&
//   <Document
// file= {props?.post?.document }
// onLoadSuccess={onDocumentLoadSuccess}
// >
// <Page pageNumber={pageNumber} />
// </Document>
//
// }

// <IconButton aria-label="share">
//   <ReplyIcon fontSize="small" />
// </IconButton>

// <CardActions disableSpacing>
//   <Grid container>
//     <Grid
//       item
//       xs={4}
//       sx={{ display: "flex", justifyContent: "flex-start" }}
//     >
//       <Stack
//         spacing={1}
//         direction="row"
//         sx={{ display: "flex", alignItems: "center" }}
//       >
//         <IconButton
//           aria-label="applause"
//           sx={{
//             width: "32px",
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               height: "16px",
//               width: "fit-content",
//             }}
//           >
//             <Icon
//               name="applause"
//               width={"16px"}
//               color={
//                 props?.post?.liked_by_me == 0 ? "#ffffff" : "#38cd84"
//               }
//             />
//           </Box>
//         </IconButton>
//         <Typography variant="h6" component="h6">
//           {props?.post?.like_count ? props?.post?.like_count : 0}{" "}
//           Applauses
//         </Typography>
//       </Stack>
//     </Grid>
//     <Grid
//       item
//       xs={4}
//       sx={{ display: "flex", justifyContent: "flex-start" }}
//     >
//       <Stack
//         spacing={1}
//         direction="row"
//         sx={{ display: "flex", alignItems: "center" }}
//       >
//         <Typography variant="h6" component="h6">
//           Reply
//         </Typography>
//       </Stack>
//     </Grid>
//   </Grid>
// </CardActions>
