import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import BgImage from '../../assets/auth/BG.png';
import logo from '../../assets/header/Logo.png';
import useStyles from './onboardstyles';
import KnowUMore from './KnowUMore/KnowUMore';
import AddBio from './AddBio/AddBio';
import ChooseUrInterest from './ChooseUrInterest/ChooseUrInterest';
const AddInterestedContainer = (props) => {
  return (
    <Fragment>
      <Helmet>
        <title>Interested</title>
      </Helmet>
      <Paper
        elevation={0}
        sx={{
          backgroundImage: `url(${BgImage})`,

          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        <ChooseUrInterest />
      </Paper>
    </Fragment>
  );
};

export default React.memo(AddInterestedContainer);
