import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import PeopleuMayKnow from '../../components/PeopleuMayKnow/PeopleuMayKnow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { dateTime } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import * as messageActions from '../../store/message';
import moment from 'moment';
const HomeEvent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const user = useSelector((state) => state?.user?.UserDetails);

  const mynetwork = useSelector(
    (state) => state?.usernetwork?.userRecommendationList,
  );
  const discoverlist = useSelector((state) => state?.events?.eventDiscoverList);

  useEffect(() => {
    async function fetchData() {
      const data = {
        user_id: user?.id ? user?.id : 27006,
        categoryId: '',
        page_no: 0,
      };
      const eventList = await dispatch(eventsActions.discoverEventList(data));
    }
    fetchData();

  }, []);

  const redirectToEvent = async (events) => {
    if (user?.isVerified) {
      if (location.pathname.includes('/event/details/')) {
        const data = {
          id: events?.id,
        };
        const eventdetails = await dispatch(eventsActions.eventDetails(data));
        if (events?.event_name) {
          const eventdetails = events?.event_name + '-' + 'lx' + events?.id;
          console.log('eventDetails', events?.type);

          if (props?.type !== 'SavedPassed') {
            navigate('/event/details/' + eventdetails);
          }
        } else {
          const eventdetails = events?.title + '-' + 'lx' + events?.id;
          console.log('eventDetails', props?.event?.type);
          if (props?.type !== 'SavedPassed') {
            navigate('/event/details/' + eventdetails);
          }
        }
      } else {
        if (events?.event_name) {
          const eventdetails = events?.event_name + '-' + 'lx' + events?.id;
          console.log('eventDetails', events?.type);

          if (props?.type !== 'SavedPassed') {
            navigate('/event/details/' + eventdetails);
          }
        } else {
          const eventdetails = events?.title + '-' + 'lx' + events?.id;
          console.log('eventDetails', props?.event?.type);
          if (props?.type !== 'SavedPassed') {
            navigate('/event/details/' + eventdetails);
          }
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <Fragment>
      {discoverlist?.length > 0 && (
        <Box
          px={1}
          py={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Box p={1}>
            <Typography variant="h5s" component="h5s" sx={{ fontSize: '13px' }}>
              Events you maybe interested in
            </Typography>
          </Box>
          <Box
            onClick={() => {
              navigate('/events/discover');
            }}
            sx={{ cursor: 'pointer' }}>
            <Typography variant="h5" component="h5" sx={{ fontSize: '13px' }}>
              See all
            </Typography>
          </Box>
        </Box>
      )}

      {discoverlist &&
        discoverlist?.slice(0, 2)?.map((events, index) => {
          console.log('event', events);
          return (
            <Box key={index} px={1} py={0.2}>
              <Card
                sx={{
                  backgroundColor: '#343232',
                  borderRadius: '5px',
                  backgroundImage: 'none',
                  boxShadow: '0',
                }}
                onClick={() => redirectToEvent(events)}>
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: 70,
                      minWidth: '70px',
                      maxHeight: '50px',
                      borderRadius: '5px',
                      backgroundColor: '#262525',
                      margin: '8px 0px 8px 8px',
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                    image={events?.image_url ? events?.image_url : ''}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: '-webkit-fill-available',
                        paddingBottom: '16px!important',
                      }}>
                      <Typography component="h3s" variant="h3s">
                        {events?.event_name
                          ? events?.event_name.substring(0, 28)
                          : ''}
                      </Typography>
                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{ display: 'flex', paddingTop: '12px' }}>
                        <Typography
                          variant="cardsubtitle"
                          component="cardsubtitle"
                          sx={{
                            color: 'rgba(255, 255,255,0.8)',
                            fontWeight: '400',
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                          }}>
                          By {events?.first_name ? events?.first_name : ''}
                        </Typography>
                        <Typography
                          variant="cardsubtitle"
                          component="cardsubtitle"
                          sx={{
                            color: 'rgba(255, 255,255,0.8)',
                            fontWeight: '400',
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                          }}>
                          {events?.start_date
                            ? moment(events?.start_date).format('ll')
                            : ''}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Box>
                </Stack>
              </Card>
            </Box>
          );
        })}
    </Fragment>
  );
};

export default React.memo(HomeEvent);

// {events?.start_time
//   ? moment(events?.start_time, 'hh:mm:ss A').format(
//       'hh:mm A',
//     )
//   : ''}
