import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import BookmarksCard from '../../components/BookmarksCard/BookmarksCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';
import * as useractivityActions from '../../store/activity';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));

const Activity = (props) => {
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const dispatch = useDispatch();
  const [country, setCountry] = React.useState('91');
  const countryChange = (event) => {
    setCountry(event.target.value);
  };
  const user = useSelector((state) => state?.user?.UserDetails);

  const userpost = useSelector((state) => state?.useractivity);
  const activityPostList = useSelector(
    (state) => state?.useractivity?.PostList,
  );

  useEffect(async () => {
    const postList = await dispatch(
      useractivityActions.getPostActivity({ user_id: user.id }),
    );
  }, []);

  useEffect(async () => {
    const eventList = await dispatch(
      useractivityActions.getEventActivity({ user_id: user.id }),
    );
  }, []);
  useEffect(async () => {
    const clubList = await dispatch(
      useractivityActions.getClubsActivity({ user_id: user.id }),
    );
  }, []);
  const handleChange = (newValue) => {
    setType(newValue);
  };
  return (
    <Fragment>
      <Box p={2} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Paper
            sx={{
              backgroundColor: '#232121',
              backgroundImage: 'none',
              overflowX: 'auto',
            }}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={0}
              sx={{}}>
              <Stack
                px={2}
                py={2}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('All');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '30px',
                  }}>
                  {type === 'All' ? (
                    <DashboardOutlinedIcon sx={{ color: '#38CD84' }} />
                  ) : (
                    <DashboardOutlinedIcon sx={{ color: '#ffffff' }} />
                  )}
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'All' ? 'h2s' : 'h2'}
                    component={type === 'All' ? 'h2s' : 'h2'}>
                    All
                  </Typography>
                </Box>
              </Stack>
              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('network');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  <Icon
                    name="networks"
                    color={type === 'network' ? '#38CD84' : '#ffffff'}
                  />
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'network' ? 'h2s' : 'h2'}
                    component={type === 'network' ? 'h2s' : 'h2'}>
                    Networks
                  </Typography>
                </Box>
              </Stack>

              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('Articles');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  {type === 'Articles' ? (
                    <FormatIndentIncreaseOutlinedIcon
                      sx={{ color: '#38CD84' }}
                    />
                  ) : (
                    <FormatIndentIncreaseOutlinedIcon
                      sx={{ color: '#ffffff' }}
                    />
                  )}
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'Articles' ? 'h2s' : 'h2'}
                    component={type === 'Articles' ? 'h2s' : 'h2'}>
                    Articles
                  </Typography>
                </Box>
              </Stack>

              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('events');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  <Menuicon
                    name="events"
                    color={type === 'events' ? '#38CD84' : '#ffffff'}
                  />
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'events' ? 'h2s' : 'h2'}
                    component={type === 'events' ? 'h2s' : 'h2'}>
                    Events
                  </Typography>
                </Box>
              </Stack>

              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('clubs');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  <Menuicon
                    name="clubs"
                    color={type === 'clubs' ? '#38CD84' : '#ffffff'}
                  />
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'clubs' ? 'h2s' : 'h2'}
                    component={type === 'clubs' ? 'h2s' : 'h2'}>
                    Clubs
                  </Typography>
                </Box>
              </Stack>

              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('Jobs');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  <Icon
                    name="jobs"
                    color={type === 'Jobs' ? '#38CD84' : '#ffffff'}
                  />
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'Jobs' ? 'h2s' : 'h2'}
                    component={type === 'Jobs' ? 'h2s' : 'h2'}>
                    Jobs
                  </Typography>
                </Box>
              </Stack>

              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('industryreport');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  <Menuicon
                    name="industryreport"
                    color={type === 'industryreport' ? '#38CD84' : '#ffffff'}
                  />
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack direction="column">
                    <Typography
                      variant={type === 'industryreport' ? 'h2s' : 'h2'}
                      component={type === 'industryreport' ? 'h2s' : 'h2'}>
                      Industry
                    </Typography>
                  </Stack>
                </Box>
              </Stack>

              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('posts');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  {type === 'posts' ? (
                    <BallotOutlinedIcon sx={{ color: '#38CD84' }} />
                  ) : (
                    <BallotOutlinedIcon sx={{ color: '#ffffff' }} />
                  )}
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'posts' ? 'h2s' : 'h2'}
                    component={type === 'posts' ? 'h2s' : 'h2'}>
                    Posts
                  </Typography>
                </Box>
              </Stack>

              <Stack
                px={2}
                py={1}
                spacing={0}
                sx={{ cursor: 'pointer' }}
                direction="column"
                onClick={() => {
                  handleChange('newsletters');
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '24px',
                  }}>
                  <Menuicon
                    name="newsletters"
                    color={type === 'newsletters' ? '#38CD84' : '#ffffff'}
                  />
                </Box>
                <Box
                  py={0.5}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant={type === 'newsletters' ? 'h2s' : 'h2'}
                    component={type === 'newsletters' ? 'h2s' : 'h2'}>
                    Newsletters
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Paper>

          <Paper
            sx={{
              marginTop: '8px',
              padding: '16px',
              backgroundColor: '#232121',
              backgroundImage: 'none',
            }}>
            {activityPostList?.map((post, index) => {
              return <BookmarksCard key={index} list={post} />;
            })}
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(Activity);

// <Typography
//   variant={type === "industryreport" ? "h2s" : "h2"}
//   component={type === "industryreport" ? "h2s" : "h2"}
// >
//   Report
// </Typography>
