import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import AvgEngRate from '../../resources/new-img/Average Engagement Rate.png';
import AvgRetRate from '../../resources/new-img/Average Retention Rate.png';
import Seo from '../../resources/new-img/seo-new.png';

function MatandEngagement() {
  return (
    <>
      <Container fluid>
        {' '}
        <Row className="nl-about-us" style={{ marginTop: '20px' }}>
          <Col>
            <Row>
              <Col>
                {' '}
                <h2 className="color-green metrics-engagement">
                  Metrics & Engagement
                </h2>
              </Col>
            </Row>
            <Row md={12} className="nl-about-us-count-sec">
              <Col md={4} className="count-col1">
                <p className="color-green">4.1</p>
                <h4>Playstore Rating</h4>
              </Col>
              <Col md={4} className="count-col2">
                <p className="color-green">25000+</p>
                <h4>Users</h4>
              </Col>
              <Col md={4} className="count-col3">
                <p className="color-green">40 mins</p>
                <h4>Average Engagement Time</h4>
              </Col>
            </Row>
            <Row
              className="matrix"
              style={{
                paddingBottom: '80px',
              }}>
              <Col>
                <Row className="seo-ranking">
                  <Col sm={6}>
                    {' '}
                    <img alt="" src={Seo} className="seo" />
                    <h5 className="color-green">
                      Ranking No."1" for CxO Networking
                    </h5>
                  </Col>
                  <Col sm={6}>
                    {' '}
                    <img alt="" src={AvgEngRate} className="avgeng" />
                    <h5 className="color-green">
                      Exponential Growth in Engagement Rate
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {' '}
                    <img alt="" src={AvgRetRate} className="avgret" />
                    <h5 className="color-green">Average Retention Rate</h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MatandEngagement;
