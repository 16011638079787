import React, { useState, Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import HeaderVentures from '../../components/HomeHeader/headerVentures';
import Footer from '../../components/HomeHeader/footer';
import { countryCodeList } from '../../data/date-data';
import axios from 'axios';
import { passing_year_list_iit } from '../../data/date-data';
import { industryList } from '../../data/date-data';
import config from '../../services/apiConfig';

class ApplyStartup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      code: '+91',
      mobile: '',
      linkedin_url: '',
      university: 'select',
      iit_campus: 'select',
      iit_course: '',
      iit_passingyear: 'select',
      iim_campus: 'select',
      iim_course: '',
      iim_passingyear: 'select',
      campus: '',
      course: '',
      passingyear: 'select',
      company: '',
      websitelink: '',
      socialmedialink: '',
      location: '',
      industry: 'select',
      stage: 'select',
      idea: '',
      competitordetails: '',
      existingclients: '',
      fundrequirement: '',
      fundutilization: '',
      formErrors: {},
      newid: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initialState = this.state;
  }
  handleChange = (e) => {
    console.log(this.state.country_code);
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  onFocusMobile = () => {
    console.log('onfocus');
    const data = {
      id: '0',
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      email: this.state.email,
      country_code: '91',
      mobile: this.state.mobile,
    };
    if (this.handleFormValidation()) {
      axios
        .post(
          config?.serverBaseUrl + '/manage_startup',
          'https://lxweb-api.tk/manage_startup',
          //"http://13.233.22.12:4000/manage_startup",
          data,
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 1) {
            this.setState({ newid: res.data });
          }
        });
    }
  };

  handleFormValidation() {
    let formErrors = {};
    let formIsValid = true;
    const { firstname, lastname, email, mobile } = this.state;

    // name
    if (!firstname) {
      formIsValid = false;
      formErrors['firstnameErr'] = 'First Name is .';
    }
    if (!lastname) {
      formIsValid = false;
      formErrors['lastnameErr'] = 'Last Name is .';
    }
    //Email
    if (!email) {
      console.log(email);
      formIsValid = false;
      formErrors['emailErr'] = 'Email id is .';
    } else {
      var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (emailPattern.test(email)) {
        console.log(email);
        return (formIsValid = true);
      } else {
        formIsValid = false;
        formErrors['emailErr'] = 'Invalid email id.';
      }
    }

    //Mobile
    if (mobile) {
      console.mobile('=========MOBILE', mobile);
      var pattern = new RegExp(/^[0-9]{10}$/);
      if (pattern.test(mobile)) {
        return (formIsValid = true);
      } else {
        formIsValid = false;
        formErrors['mobileErr'] = 'Please enter valid mobile number.';
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var m = new Date();
    var dateString =
      m.getFullYear() +
      '-' +
      (m.getMonth() + 1) +
      '-' +
      m.getDate() +
      ' ' +
      m.getHours() +
      ':' +
      m.getMinutes() +
      ':' +
      m.getSeconds();
    if (this.state.university === 'Other Premium University') {
      const data = {
        id: '0',
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: '',
        unversity: this.state.university,
        iit_campus: '',
        iit_course: '',
        iit_graduation_year: '',
        iim_campus: '',
        iim_course: '',
        iim_graduation_year: '',
        campus: this.state.campus,
        course: this.state.course,
        gradution_year: this.state.passingyear,
        startup_name_description: this.state.company,
        app_website_link: this.state.websitelink,
        founder_cofounder_social_media_url: this.state.socialmedialink,
        location: this.state.location,
        industry: this.state.industry,
        startup_stage: this.state.stage,
        about_idea: this.state.idea,
        competitor: this.state.competitordetails,
        existing_client: this.state.existingclients,
        fund_required: this.state.fundrequirement,
        fund_utilization_details: this.state.fundutilization,
        created_at: dateString,
      };
      console.log(data);
      if (this.handleFormValidation()) {
        axios
          .post(
            // `${config.serverBaseUrl} + /manage_startup`,
            //"http://13.233.22.12:4000/manage_startup",
            'https://lxweb-api.tk/manage_startup',
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              return this.props.redirectSuccessPageStartup(data);
            }
          });

        this.setState(this.initialState);
      }
    } else if (this.state.university === 'IIT') {
      const data = {
        id: '0',
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: '',
        unversity: this.state.university,
        iit_campus: this.state.iit_campus,
        iit_course: this.state.iit_course,
        iit_graduation_year: this.state.iit_passingyear,
        iim_campus: '',
        iim_course: '',
        iim_graduation_year: '',
        campus: '',
        course: '',
        gradution_year: '',
        startup_name_description: this.state.company,
        app_website_link: this.state.websitelink,
        founder_cofounder_social_media_url: this.state.socialmedialink,
        location: this.state.location,
        industry: this.state.industry,
        startup_stage: this.state.stage,
        about_idea: this.state.idea,
        competitor: this.state.competitordetails,
        existing_client: this.state.existingclients,
        fund_required: this.state.fundrequirement,
        fund_utilization_details: this.state.fundutilization,
        created_at: dateString,
      };
      console.log(data);
      if (this.handleFormValidation()) {
        axios
          .post(
            // `${config.serverBaseUrl} + /manage_startup`,
            //"http://13.233.22.12:4000/manage_startup",
            'https://lxweb-api.tk/manage_startup',
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              // alert("We will get back to you.");
              return this.props.redirectSuccessPageStartup(data);
            }
          });

        // alert("You have been successfully registered.");
        this.setState(this.initialState);
      }
    } else if (this.state.university === 'IIM') {
      const data = {
        id: '0',
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: '',
        unversity: this.state.university,
        iit_campus: '',
        iit_course: '',
        iit_graduation_year: '',
        iim_campus: this.state.iim_campus,
        iim_course: this.state.iim_course,
        iim_graduation_year: this.state.iim_passingyear,
        campus: '',
        course: '',
        gradution_year: '',
        startup_name_description: this.state.company,
        app_website_link: this.state.websitelink,
        founder_cofounder_social_media_url: this.state.socialmedialink,
        location: this.state.location,
        industry: this.state.industry,
        startup_stage: this.state.stage,
        about_idea: this.state.idea,
        competitor: this.state.competitordetails,
        existing_client: this.state.existingclients,
        fund_required: this.state.fundrequirement,
        fund_utilization_details: this.state.fundutilization,
        created_at: dateString,
      };
      console.log(data);
      if (this.handleFormValidation()) {
        axios
          .post(
            // `${config.serverBaseUrl} + /manage_startup`,
            //"http://13.233.22.12:4000/manage_startup",
            'https://lxweb-api.tk/manage_startup',
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              return this.props.redirectSuccessPageStartup(data);
            }
          });

        this.setState(this.initialState);
      }
    } else {
      const data = {
        id: '0',
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        email: this.state.email,
        country_code: '91',
        mobile: this.state.mobile,
        linkedin_url: '',
        unversity: this.state.university,
        iit_campus: this.state.iit_campus,
        iit_course: this.state.iit_course,
        iit_graduation_year: this.state.iit_passingyear,
        iim_campus: this.state.iim_campus,
        iim_course: this.state.iim_course,
        iim_graduation_year: this.state.iim_passingyear,
        campus: '',
        course: '',
        gradution_year: '',
        startup_name_description: this.state.company,
        app_website_link: this.state.websitelink,
        founder_cofounder_social_media_url: this.state.socialmedialink,
        location: this.state.location,
        industry: this.state.industry,
        startup_stage: this.state.stage,
        about_idea: this.state.idea,
        competitor: this.state.competitordetails,
        existing_client: this.state.existingclients,
        fund_required: this.state.fundrequirement,
        fund_utilization_details: this.state.fundutilization,
        created_at: dateString,
      };
      if (this.handleFormValidation()) {
        axios
          .post(
            'https://lxweb-api.tk/manage_startup',
            //"http://13.233.22.12:4000/manage_startup",
            data,
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 1) {
              return this.props.redirectSuccessPageStartup(data);
            }
          });

        this.setState(this.initialState);
      }
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      firstnameErr,
      lastnameErr,
      emailErr,
      mobileErr,
      linkedinErr,
      universityErr,
      campusErr,
      courseErr,
      passingyearErr,
      companyErr,
      websitelinkErr,
      socialmedialinkErr,
      locationErr,
    } = this.state.formErrors;

    return (
      <Container fluid id="no-padding">
        <Row>
          {' '}
          <Col md={12} xs={12} className="main-header-style-section">
            {' '}
            <HeaderVentures />{' '}
          </Col>
        </Row>
        <Row className="container-ventures3">
          <Col className="form-venture">
            <h2 className="heading-startup">Register to raise funds</h2>

            <form
              autoComplete="off"
              className="form-group"
              onSubmit={this.handleSubmit}>
              <Row sm={12}>
                <Col sm={6} className="first_name_row">
                  {' '}
                  <label
                    htmlFor="firstname"
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    First Name*
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    value={this.state.firstname}
                    onChange={this.handleChange}
                    className="form-control"
                    required
                  />
                  {firstnameErr && (
                    <div style={{ color: 'red', paddingBottom: 10 }}>
                      {firstnameErr}
                    </div>
                  )}
                </Col>
                <Col sm={6} className="last_name_row">
                  {' '}
                  <label
                    htmlFor="lastname"
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Last Name*
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={this.state.lastname}
                    onChange={this.handleChange}
                    className="form-control"
                    required
                  />
                  {lastnameErr && (
                    <div style={{ color: 'red', paddingBottom: 10 }}>
                      {lastnameErr}
                    </div>
                  )}
                </Col>
              </Row>

              <label
                htmlFor="email"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Email Id*
              </label>
              <input
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                className="form-control"
                required
              />
              {emailErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {emailErr}
                </div>
              )}

              <Row clssName="mobile-row" style={{ marginRight: '5px' }}>
                <div className="col-sm-2">
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Mobile No.*
                  </label>
                  <select
                    className="form-control"
                    style={{ fontFamily: 'Open Sans' }}
                    name="code"
                    value={this.state.country_code}
                    onChange={this.handleChange}>
                    <option value="" hidden>
                      +91
                    </option>
                    {countryCodeList
                      ? countryCodeList.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {/* {item.label + "(" + item.value + ")"} */}
                              {item.value}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </div>{' '}
                <div className="col-sm-10">
                  <label className="mobile-in-mobile"></label>
                  <input
                    onBlur={this.onFocusMobile}
                    className="form-control"
                    type="text"
                    name="mobile"
                    value={this.state.mobile}
                    onChange={this.handleChange}
                    style={{ fontFamily: 'Open Sans', marginLeft: '20px' }}
                    required
                  />
                </div>
                {mobileErr && (
                  <div
                    style={{
                      color: 'red',
                      paddingBottom: 10,
                    }}>
                    {mobileErr}
                  </div>
                )}
              </Row>
              <label
                htmlFor="university"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Education*
              </label>
              <select
                className="form-control"
                name="university"
                onChange={this.handleChange}
                value={this.state.university}>
                <option value="select">--Select--</option>
                <option>IIT</option>
                <option>IIM</option>
                <option>Both ( IIT & IIM )</option>
                <option>Another Premium University</option>
                <option>Others</option>
              </select>
              {universityErr && (
                <div
                  style={{
                    color: 'red',
                    paddingBottom: 10,
                  }}>
                  {universityErr}
                </div>
              )}

              {this.state.university === 'IIT' && (
                // this.state.university != "Other Premium University" &&
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iit_campus"
                    onChange={this.handleChange}
                    value={this.state.iit_campus}>
                    <option value="select">--Select--</option>
                    <option>IIT Madras </option>
                    <option>IIT Delhi</option>
                    <option>IIT Bombay</option>
                    <option>IIT Kanpur</option>
                    <option>IIT Kharagpur</option>
                    <option>IIT Roorkee </option>
                    <option>IIT Guwahati</option>
                    <option>IIT Hyderabad</option>
                    <option>IIT Dhanbad</option>
                    <option>IIT Indore</option>
                    <option>IIT BHU</option>
                    <option>IIT Roper</option>
                    <option>IIT Patna</option>
                    <option>IIT Gandhinagar</option>
                    <option>IIT Bhubaneswar</option>
                    <option>IIT Mandi</option>
                    <option>IIT Jodhpur</option>
                    <option>IIT Tirupati</option>
                    <option>IIT Bhilai</option>
                    <option>IIT Goa</option>
                    <option>IIT Jammu</option>
                    <option>IIT Dharwad</option>
                    <option>IIT Palakkad</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Course/Specialization*
                  </label>
                  <input
                    name="iit_course"
                    onChange={this.handleChange}
                    value={this.state.iit_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iit_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iit_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              {this.state.university === 'IIM' && (
                // this.state.university != "Other Premium University" &&
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iim_campus"
                    onChange={this.handleChange}
                    value={this.state.iim_campus}>
                    <option value="select">--Select--</option>
                    <option>IIM A </option>
                    <option>IIM C</option>
                    <option>IIM B</option>
                    <option>IIM L</option>
                    <option>IIM K</option>
                    <option>IIM I</option>
                    <option>IIM Shillong</option>
                    <option>IIM Rohtak</option>
                    <option>IIM Ranchi</option>
                    <option>IIM Raipur</option>
                    <option>IIM Trichy</option>
                    <option>IIM Kashipur</option>
                    <option>IIM Udaipur</option>
                    <option>IIM Nagpur</option>
                    <option>IIM Visakhapatnam</option>
                    <option>IIM Bodhgaya</option>
                    <option>IIM Amritsar</option>
                    <option>IIM Sambalpur</option>
                    <option>IIM Sirmaur</option>
                    <option>IIM Jammu</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Course/Specialization*
                  </label>
                  <input
                    name="iim_course"
                    onChange={this.handleChange}
                    value={this.state.iim_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iim_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iim_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              {this.state.university === 'Both ( IIT & IIM )' && (
                // this.state.university != "Other Premium University" &&
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iit_campus"
                    onChange={this.handleChange}
                    value={this.state.iit_campus}>
                    <option value="select">--Select--</option>
                    <option>IIT Madras </option>
                    <option>IIT Delhi</option>
                    <option>IIT Bombay</option>
                    <option>IIT Kanpur</option>
                    <option>IIT Kharagpur</option>
                    <option>IIT Roorkee </option>
                    <option>IIT Guwahati</option>
                    <option>IIT Hyderabad</option>
                    <option>IIT Dhanbad</option>
                    <option>IIT Indore</option>
                    <option>IIT BHU</option>
                    <option>IIT Roper</option>
                    <option>IIT Patna</option>
                    <option>IIT Gandhinagar</option>
                    <option>IIT Bhubaneswar</option>
                    <option>IIT Mandi</option>
                    <option>IIT Jodhpur</option>
                    <option>IIT Tirupati</option>
                    <option>IIT Bhilai</option>
                    <option>IIT Goa</option>
                    <option>IIT Jammu</option>
                    <option>IIT Dharwad</option>
                    <option>IIT Palakkad</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Course/Specialization*
                  </label>
                  <input
                    name="iit_course"
                    onChange={this.handleChange}
                    value={this.state.iit_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIT Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iit_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iit_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Campus*
                  </label>
                  <select
                    className="form-control"
                    name="iim_campus"
                    onChange={this.handleChange}
                    value={this.state.iim_campus}>
                    <option value="select">--Select--</option>
                    <option>IIM A </option>
                    <option>IIM C</option>
                    <option>IIM B</option>
                    <option>IIM L</option>
                    <option>IIM K</option>
                    <option>IIM I</option>
                    <option>IIM Shillong</option>
                    <option>IIM Rohtak</option>
                    <option>IIM Ranchi</option>
                    <option>IIM Raipur</option>
                    <option>IIM Trichy</option>
                    <option>IIM Kashipur</option>
                    <option>IIM Udaipur</option>
                    <option>IIM Nagpur</option>
                    <option>IIM Visakhapatnam</option>
                    <option>IIM Bodhgaya</option>
                    <option>IIM Amritsar</option>
                    <option>IIM Sambalpur</option>
                    <option>IIM Sirmaur</option>
                    <option>IIM Jammu</option>
                  </select>{' '}
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Course/Specialization*
                  </label>
                  <input
                    name="iim_course"
                    onChange={this.handleChange}
                    value={this.state.iim_course}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    IIM Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="iim_passingyear"
                    onChange={this.handleChange}
                    value={this.state.iim_passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}

              {this.state.university === 'Other Premium University' && (
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    University Name*
                  </label>
                  <input
                    name="campus"
                    onChange={this.handleChange}
                    value={this.state.campus}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Course/Specialization*
                  </label>
                  <input
                    name="course"
                    onChange={this.handleChange}
                    value={this.state.course}
                    type="text"
                    className="form-control"
                  />

                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="passingyear"
                    onChange={this.handleChange}
                    value={this.state.passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              {this.state.university === 'Others' && (
                <>
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    University Name*
                  </label>
                  <input
                    name="campus"
                    onChange={this.handleChange}
                    value={this.state.campus}
                    type="text"
                    className="form-control"
                  />
                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Course/Specialization*
                  </label>
                  <input
                    name="course"
                    onChange={this.handleChange}
                    value={this.state.course}
                    type="text"
                    className="form-control"
                  />

                  <label
                    style={{
                      marginTop: '15px',
                      color: '#38cd84',
                      fontSize: '16px',
                      float: 'left',
                    }}>
                    Graduation Year (If still studying then select present)
                  </label>
                  <select
                    style={{ fontFamily: 'Open Sans' }}
                    className="form-control"
                    name="passingyear"
                    onChange={this.handleChange}
                    value={this.state.passingyear}>
                    <option value="select">--select--</option>
                    {passing_year_list_iit
                      ? passing_year_list_iit.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </>
              )}
              <label
                htmlFor="name"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Startup Name & Description*
              </label>
              <input
                type="text"
                name="company"
                value={this.state.company}
                onChange={this.handleChange}
                className="form-control"
                required
              />
              {companyErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {companyErr}
                </div>
              )}

              <label
                htmlFor="websitelink"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Link to Website/App (If not ready, give us a glimpse of what you
                want to build)*
              </label>
              <input
                type="text"
                name="websitelink"
                value={this.state.websitelink}
                onChange={this.handleChange}
                className="form-control"
              />
              {websitelinkErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {websitelinkErr}
                </div>
              )}
              <label
                htmlFor="socialmedialink"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Startup/Founder or Co-Founders' Social Media Handles
              </label>
              <input
                type="text"
                name="socialmedialink"
                value={this.state.socialmedialink}
                onChange={this.handleChange}
                className="form-control"
                required
              />
              {socialmedialinkErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {socialmedialinkErr}
                </div>
              )}
              <label
                htmlFor="location"
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Location (City, Country)*
              </label>
              <input
                type="text"
                name="location"
                value={this.state.location}
                onChange={this.handleChange}
                className="form-control"
                required
              />
              {locationErr && (
                <div style={{ color: 'red', paddingBottom: 10 }}>
                  {locationErr}
                </div>
              )}
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Industry/Vertical*
              </label>
              <select
                className="form-control"
                name="industry"
                onChange={this.handleChange}
                value={this.state.industry}
                required>
                <option value="select">--select--</option>
                {industryList
                  ? industryList.map((item, key) => {
                      return (
                        <option key={key} value={item.value}>
                          {item.value}
                        </option>
                      );
                    })
                  : ''}
              </select>

              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Startup Stage*
              </label>

              <select
                className="form-control"
                name="stage"
                onChange={this.handleChange}
                value={this.state.stage}>
                <option value="select">--select--</option>
                <option>idea</option>
                <option>Pre-Revenue</option>
                <option>Seed</option>
                <option>Round A</option>
                <option>Round B</option>
              </select>

              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Tell us briefly about your idea (Solution vs Identified Problems
                etc)*
              </label>
              <textarea
                name="idea"
                rows="5"
                type="text"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.idea}
                required
              />

              {/* <label
                style={{
                  marginTop: "15px",
                  color: "#38cd84",
                  fontSize: "16px",
                  float: "left",
                }}
              >
                Competitor Details (if any)
              </label>
              <input
                type="text"
                className="form-control"
                name="competitordetails"
                onChange={this.handleChange}
                value={this.state.competitordetails}
                required
              />
              <label
                style={{
                  marginTop: "15px",
                  color: "#38cd84",
                  fontSize: "16px",
                  float: "left",
                }}
              >
                Existing Clients (if any)
              </label>
              <input
                type="text"
                className="form-control"
                name="existingclients"
                onChange={this.handleChange}
                value={this.state.existingclients}
                required
              /> */}
              <label
                style={{
                  marginTop: '15px',
                  color: '#38cd84',
                  fontSize: '16px',
                  float: 'left',
                }}>
                Fund Required*
              </label>
              <input
                type="text"
                className="form-control"
                name="fundrequirement"
                onChange={this.handleChange}
                value={this.state.fundrequirement}
                required
              />

              {/* <label
                style={{
                  marginTop: "15px",
                  color: "#38cd84",
                  fontSize: "16px",
                  float: "left",
                }}
              >
                Fund Utilization Details*
              </label>
              <input
                type="text"
                className="form-control"
                name="fundutilization"
                onChange={this.handleChange}
                value={this.state.fundutilization}
                required
              /> */}

              <Row
                className="form-check"
                style={{
                  float: 'left',
                }}>
                <input
                  style={{
                    color: '#262525',
                    fontSize: '18px',
                    marginRight: '10px',
                    marginTop: '20px',
                  }}
                  type="checkbox"
                  value=""
                  required
                />
                <label
                  style={{
                    color: '#38cd84',
                    fontSize: '16px',
                    marginTop: '15px',
                  }}
                  forHtml="invalidCheck">
                  I agree to the Terms & Conditions, NDA of LinkCxO Ventures &
                  would like to signup on the app.
                </label>
                <div
                  style={{ color: '#38cd84', fontSize: '16px', float: 'left' }}
                  class="invalid-feedback">
                  You must agree before submitting.
                </div>
              </Row>
              <br />
              <Row style={{ marginTop: '50px' }}>
                <button type="submit" className="venture-btn2">
                  Submit
                </button>
              </Row>
            </form>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} style={{ background: '#151414' }}>
            {' '}
            <Footer />{' '}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ApplyStartup;
