import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';

function CompanyLogoPage1() {
  return (
    <>
      <Container fluid className="company-logo">
        <Row>
          <Col>
            {' '}
            <h2 className="color-green">LinkCxO Users From Top Companies</h2>
          </Col>
        </Row>
        <Row style={{ padding: '0px 100px ' }}>
          <Col>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Adobe.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Accenture.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Asian paints.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Aditya birla finance.png"
                  />
                </div>
              </Col>

              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Apollo Tyres.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Ashok Leyland.png"
                  />
                </div>
              </Col>
              <Col>
                {' '}
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Axis Bank.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Bharti Airtel.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/CEAT LTD.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/aditya birla group.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Amazon internet services logo.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Bajaj auto.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/BMW.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Ericsson.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Ernst & Young LLP.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Bosch Limited.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Barclays Bank.png"
                  />
                </div>
              </Col>

              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Cognizant.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Continental AG.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Capgemini.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Caterpillar India.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img
                    alt=""
                    className="logo_img"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Dell Technologies.png"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/General Motors.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/GlaxoSmithKline Pharmaceuticals Ltd.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Godrej Appliances.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Honda Cars India.png"
                  />
                </div>
                
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/IBM.png"
                  /> 
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Infosys.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/KPMG.png"
                  />
                </div>
              </Col>
              <Col>
                <div className="imgFrame1">
                  <img
                    alt=""
                    className="logo_img1"
                    src="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/circle_logo/Max Life Insurance.png"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CompanyLogoPage1;