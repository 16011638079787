import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import CustomiseGroupPackages from '../../components/CustomiseGroupPackages/CustomiseGroupPackages';
import * as rewardsActions from '../../store/rewards';
import * as messageActions from '../../store/message';
import Slide from '@mui/material/Slide';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const PackageGroup = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [queryParameters] = useSearchParams();
  const [showpopup, setShowpopup] = React.useState(false);
  const [groupPackage, setGroupPackage] = React.useState({});

  const user = useSelector((state) => state?.user?.UserDetails);

  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const membershipList = useSelector((state) => state?.rewards?.membershipList);

  const corporateMemberships = useSelector(
    (state) => state?.rewards?.corporateMemberships,
  );

  const corporateMembershipsProduct = useSelector(
    (state) => state?.rewards?.corporateMembershipsProduct,
  );

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );
  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  console.log(
    'rewardsPartnerVoucher',
    rewardsPartnerVoucher,
    rewardsVoucherList,
  );

  useEffect(() => {
    async function fetchData() {
      const type = params?.type;
      var result = corporateMemberships.filter((obj) => {
        return obj.type === type;
      });
      setGroupPackage(result[0]);
      const data = {
        membership_id: result[0]?.id,
      };

      const rewardscategorylist = await dispatch(
        rewardsActions.corporateMembershipProduct(data),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log('useEffect', queryParameters.get('visit'));
    if (queryParameters.get('visit') == 'new') {
      setShowpopup(true);
    } else {
      setShowpopup(false);
    }
  }, []);

  useEffect(() => {
    async function fetchDetails() {
      const data = {
        membership_id: 2,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchMembershipdetails() {
      const reponse = await dispatch(rewardsActions.corporateMembership());
    }
    fetchMembershipdetails();
  }, []);

  const handleChange = async (event, newValue) => {
    debugger
    if (user?.isVerified) {
      if (newValue === 1) {
        navigate('/corporate/packages/group/membership/Elite');

        var result = corporateMemberships.filter((obj) => {
          return obj.type === 'Elite';
        });
        setGroupPackage(result[0]);
        const data = {
          membership_id: result[0]?.id,
        };
  
        const rewardscategorylist = await dispatch(
          rewardsActions.corporateMembershipProduct(data),
        );
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/corporate/partners');
        setValue(newValue);
      } else if (newValue === 0) {
        var result = corporateMemberships.filter((obj) => {
          return obj.type === 'Premium';
        });
        setGroupPackage(result[0]);
        const data = {
          membership_id: result[0]?.id,
        };
  
        const rewardscategorylist = await dispatch(
          rewardsActions.corporateMembershipProduct(data),
        );
        navigate('/corporate/packages/group/membership/Premium');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const handleClickOpen = () => {
    setShowpopup(true);
  };

  const handleClose = () => {
    setShowpopup(false);
  };

  useEffect(() => {
    const type = params?.type;
    if (type == 'Elite') {
      setValue(1);
    } else if (type == 'Premium') {
      setValue(0);
    }
  }, []);

  const changeTab = async (val) => {
    
    if (user?.isVerified) {
      if (val === 1) {
        navigate('/corporate/packages/group/membership/Elite');
        setValue(val);
      } else if (val === 2) {
        navigate('/corporate/partners');
        setValue(val);
      } else if (val === 0) {
        navigate('/corporate/packages/group/membership/Premium');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Premium
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Elite
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            {!location.pathname.includes('company') && (
              <Box px={0} py={1}>
                <Tabs
                  defaultValue={0}
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  color="white"
                  aria-label="voucher">
                  <Paper
                    sx={{
                      backgroundColor: '#262525',
                      backgroundImage: 'none',
                    }}>
                    <StyledTabsList sx={{ padding: '8px 16px' }}>
                      <StyledTab onClick={() => changeTab(0)}>
                        <Stack direction="row" spacing={0.5}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                            Premium
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabdigit}
                            sx={{
                              color: value === 0 ? '#000000' : '#ffffff',
                            }}></Typography>
                        </Stack>
                      </StyledTab>
                      <StyledTab onClick={() => changeTab(1)}>
                        <Stack direction="row" spacing={0.5}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                            Elite
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabdigit}
                            sx={{
                              color: value === 1 ? '#000000' : '#ffffff',
                            }}></Typography>
                        </Stack>
                      </StyledTab>
                      <StyledTab onClick={() => changeTab(2)}>
                        <Stack direction="row" spacing={0.5}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                            Partners
                          </Typography>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabdigit}
                            sx={{
                              color: value === 2 ? '#000000' : '#ffffff',
                            }}></Typography>
                        </Stack>
                      </StyledTab>
                    </StyledTabsList>
                  </Paper>
                </Tabs>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#262525',
                backgroundImage: 'none',
                justifyContent: 'center',
                minHeight: '71px',
                width: '100%',
                alignItems: 'center',
              }}>
              <Typography variant="h5s" component="h5s">
                Membership Packages
              </Typography>
            </Box>
            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      {corporateMembershipsProduct?.map((membership, index) => {
                        return (
                          <Grid
                            key={0}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: index % 2 === 0 ? '8px' : '0px',
                              marginTop: '8px',
                            }}>
                            <CustomiseGroupPackages membership={membership} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#262525',
                backgroundImage: 'none',
                justifyContent: 'center',
                minHeight: '71px',
                width: '100%',
                alignItems: 'center',
                marginTop: '8px',
              }}>
              <Typography variant="h5s" component="h5s">
                Customise Your Package
              </Typography>
            </Box>

            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      <Grid
                        key={0}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: 0 % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <CustomiseGroupPackages
                          type="customize"
                          groupPackage={groupPackage}
                          from="group"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(PackageGroup);
