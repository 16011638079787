import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';
import 'react-quill/dist/quill.snow.css';
import useStyles from './pollstyle';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import IconButton from '@mui/material/IconButton';
import CxoInput from '../../components/CxoInput/CxoInput';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import * as clubsActions from '../../store/clubs';
import { useForm, Controller } from 'react-hook-form';
import { dateTime } from '../../constants/app';

const ClubPoll = (props) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState('Everyone');
  const [question, setQuesion] = useState('');
  const [options, setOptions] = useState(2);
  const [show, setShow] = useState(true);
  const [pollend, setPollend] = useState(1);
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(40);
  const [toggleshare, setToggleshare] = useState(false);
  const [share, setShare] = useState('Everyone');
  const editPoll = useSelector((state) => state?.post?.PollEdit);
  const user = useSelector((state) => state?.user?.UserDetails);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const openPoll = useSelector((state) => state?.post?.PollEdit);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
  }, []);

  const addPost = async () => {
    var m = new Date();
    var dateString =
      m.getUTCFullYear() +
      '-' +
      (m.getUTCMonth() + 1) +
      '-' +
      m.getUTCDate() +
      ' ' +
      m.getUTCHours() +
      ':' +
      m.getUTCMinutes() +
      ':' +
      m.getUTCSeconds();
    const data = {
      id: 0,
      content: question,
      image: '',
      user_id: user.id,
      datetime: dateTime(),
      document_type: 'post',
      user_type: 'user',
      user_table_pk: user.id,
      document: '',
    };
    if (question.length > 0) {
      const addpost = await dispatch(postActions.sendAddNewPostData(data));
    }
  };

  const handleChange = (event) => {
    if (question.length < 150) {
      setQuesion(event.target.value.substring(0, 150));
    } else if (event.target.value.length < question.length) {
      setQuesion(event.target.value);
    }
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const snackbar = await dispatch(postActions.closePollEdit());
  };

  const addOption = () => {
    if (options < 4) {
      setOptions(options + 1);
    }
  };
  const removeOptions = () => {
    if (options > 2) {
      setOptions(options - 1);
    }
  };
  const handleSubmitpoll = async (data) => {
    console.log(data);
    const aOptions = [];
    var m = new Date();
    var dateString =
      m.getFullYear() +
      '-' +
      (m.getMonth() + 1) +
      '-' +
      m.getDate() +
      ' ' +
      m.getHours() +
      ':' +
      m.getMinutes() +
      ':' +
      m.getSeconds();

    const datatemp = {
      user_id: user?.id,
      question: question,
      aOptions: Object.values(data).slice(0, options),
      share_with: selectedIndex,
      validity: pollend,
      datetime: dateString,
      user_type: 'club',
      share_table_pk: '',
      user_table_pk: clubDetails?.id,
      id: 0,
    };
    console.log(datatemp.aOptions.length, question.length);

    const formData = new FormData();
    formData.append('id', 0);
    formData.append('question', question);
    formData.append(
      'aOptions',
      JSON.stringify(Object.values(data).slice(0, options)),
    );

    formData.append('share_with', JSON.stringify(selectedIndex));
    formData.append('validity', pollend);
    formData.append('user_table_pk', clubDetails?.id);
    formData.append('user_type', 'club');
    formData.append('user_id', user?.id);
    formData.append('share_table_pk', '');
    formData.append('datetime', dateString);

    if (question.length > 0 && datatemp.aOptions.length > 0) {
      if (user?.isVerified) {
        const postpoll = await dispatch(
          postActions.postPoll(formData, async (error, response) => {
            if (response) {
              const formDatas = new FormData();
              formDatas.append('user_id', user?.id);
              formDatas.append('start_item', 0);
              formDatas.append('item_count', 30);
              formDatas.append('datetime', dateTime());

              const datam = {
                user_id: user?.id,
                start_item: 0,
                item_count: 30,
                datetime: dateTime(),
              };
              const datapoll = {
                user_id: user?.id,
                club_id: clubDetails?.id,
                datetime: dateTime(),
              };
              const clubdetails = await dispatch(
                clubsActions.clubPostList(datapoll),
              );
              return dispatch(postActions.getPostList(formDatas, datam));
            }
            if (error) {
              const formDatas = new FormData();
              formDatas.append('user_id', user?.id);
              formDatas.append('start_item', 0);
              formDatas.append('item_count', 30);
              formDatas.append('datetime', dateTime());

              const datam = {
                user_id: user?.id,
                start_item: 0,
                item_count: 30,
                datetime: dateTime(),
              };
              const datapoll = {
                user_id: user?.id,
                club_id: clubDetails?.id,
                datetime: dateTime(),
              };
              const clubdetails = await dispatch(
                clubsActions.clubPostList(datapoll),
              );
              return dispatch(postActions.getPostList(formDatas, datam));
            }
          }),
        );

        const snackbar = await dispatch(postActions.closePollEdit());
      } else {
        await dispatch(messageActions.openUnderVerification());
      }
    }
  };
  const handlePollend = (event) => {
    setPollend(event.target.value);
  };
  const handleShare = (event) => {
    setShare(event.target.value);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const toggleShare = () => {
    setToggleshare(!toggleshare);
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '1001',
      }}>
      <Backdrop sx={{ color: '#fff', zIndex: '2001' }} open={editPoll}>
        <Box
          sx={{
            width: '70%',
            maxWidth: '1080px',
            backgroundColor: '#333',
            borderRadius: '10px',
            zIndex: '3001',
            maxHeight: '95vh',
            marginTop: '40px',
            // overflowY: "scroll",
            '@media (max-width: 500px)': {
              maxWidth: '330px',
              width: '100%',
            },
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Stack spacing={0} direction="row">
              <Box
                px={0.5}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h2s" component="h2s">
                  Compose a Poll
                </Typography>
              </Box>

              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>

            <Box
              px={0.5}
              py={0.5}
              mt={0}
              mb={1.5}
              mx={2}
              sx={{ borderRadius: '5px', border: '1px solid' }}
              className="editor">
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={2}
                maxRows={2}
                value={question}
                onChange={handleChange}
                placeholder="What is the poll about ?"
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <Box>
                <Typography gutterBottom variant="h6" component="h6">
                  {question.length} / 200
                </Typography>
              </Box>
            </Box>

            <Box></Box>
            <form onSubmit={handleSubmit(handleSubmitpoll)}>
              {Array.from(Array(options), (e, i) => {
                let opt = i + 1;
                return (
                  <Box px={4} pr={6} sx={{ textAlign: 'center', width: '90%' }}>
                    <Stack spacing={1} direction="row">
                      <Box
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Box
                          sx={{
                            height: '16px',
                            width: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Icon name="options" />
                        </Box>
                      </Box>
                      <Box sx={{ width: '90%' }}>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <CxoInput
                              label={'Option ' + opt + '*'}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                          name={'options' + opt}
                          defaultValue=""
                          rules={{
                            required: {
                              value: true,
                              maxLength: 5,
                              message: 'This is required',
                            },
                            pattern: {
                              value: true,
                              maxLength: 5,
                              message: 'Maximum 20 Character',
                            },
                            maxLength: {
                              value: 45,
                              message: 'Value must be less than 45 characters',
                            },
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
              <Stack spacing={1} direction="row">
                <Box
                  py={1}
                  pl={{ md: 8, xs: 3 }}
                  onClick={addOption}
                  sx={{ cursor: 'pointer' }}>
                  <Typography gutterBottom variant="h6" component="h6">
                    + Add another option
                  </Typography>
                </Box>
                <Box
                  py={1}
                  pl={{ md: 8, xs: 3 }}
                  onClick={removeOptions}
                  sx={{ cursor: 'pointer' }}>
                  <Typography gutterBottom variant="h6" component="h6">
                    + Remove option
                  </Typography>
                </Box>
              </Stack>

              <Box px={4} pr={6} sx={{ textAlign: 'center', width: '90%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        height: '16px',
                        width: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <AccessAlarmOutlinedIcon color="secondary" />
                    </Box>
                  </Box>
                  <Box
                    pt={1}
                    pl={1}
                    sx={{
                      width: '60%',
                      maxWidth: 175,
                      zIndex: '3001',
                      '@media (max-width: 500px)': {
                        width: '100%',
                      },
                    }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ color: '#38CD84', zIndex: '3001' }}>
                        "When should the poll end?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={pollend}
                        label="When should the poll end?"
                        onChange={handlePollend}
                        sx={{ zIndex: '2001' }}>
                        <MenuItem value={'1'}>1 Day</MenuItem>
                        <MenuItem value={'3'}>3 Days</MenuItem>
                        <MenuItem value={'7'}>1 Week</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Box>

              <Grid
                container
                alignItems="flex-end"
                sx={{ alignItems: 'center' }}>
                <Grid
                  item
                  xs
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '16px',
                  }}></Grid>
                <Grid item>
                  {toggleshare && (
                    <Card
                      sx={{
                        height: '86px',
                        width: '189px',
                        marginLeft: '24px',
                        marginTop: '-72px',
                        borderRadius: '2px',
                        position: 'absolute',
                      }}>
                      <List aria-label="">
                        <ListItemButton
                          size="small"
                          sx={{ height: '36px' }}
                          selected={selectedIndex === 'Everyone'}
                          onClick={(event) =>
                            handleListItemClick(event, 'Everyone')
                          }>
                          <ListItemText primary="Everyone" />
                          {selectedIndex === 'Everyone' ? (
                            <ListItemIcon
                              sx={{ fontSize: 20, minWidth: '16px' }}>
                              <FiberManualRecordIcon
                                size="small"
                                color="secondary"
                                fontSize="10px"
                              />
                            </ListItemIcon>
                          ) : (
                            ''
                          )}
                        </ListItemButton>
                        <ListItemButton
                          sx={{ height: '36px' }}
                          selected={selectedIndex === 'Myconnection'}
                          onClick={(event) =>
                            handleListItemClick(event, 'Myconnection')
                          }>
                          <ListItemText primary="My connection" />
                          {selectedIndex === 'Myconnection' ? (
                            <ListItemIcon
                              sx={{ fontSize: 20, minWidth: '16px' }}>
                              <FiberManualRecordIcon
                                size="small"
                                color="secondary"
                                fontSize="10px"
                              />
                            </ListItemIcon>
                          ) : (
                            ''
                          )}
                        </ListItemButton>
                      </List>
                    </Card>
                  )}

                  <Stack spacing={1} direction="row">
                    <Box
                      py={2}
                      px={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <CxoButton label="Post" color="primary" type="submit" />
                    </Box>
                    <Box
                      py={2}
                      pr={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={toggleShare}>
                      <LanguageOutlinedIcon color="secondary" />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(ClubPoll);

// <FormControl fullWidth>
// <InputLabel id="demo-simple-select-label">Share</InputLabel>
// <Select
// labelId="demo-simple-select-label"
// id="demo-simple-select"
// value={share}
// label="share"
// onChange={handleShare}
// size="small"
// >
// <MenuItem value={'Everyone'}>Everyone</MenuItem>
// <MenuItem value={'Myconnection'}>My Connections</MenuItem>
//
// </Select>
// </FormControl>
