import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Automobile from '../../resources/BenefitsLogos/automobile.png';
import Apparel from '../../resources/BenefitsLogos/apparel.png';
import Sports from '../../resources/BenefitsLogos/sports.png';
import Investment from '../../resources/BenefitsLogos/investment.png';
import Health from '../../resources/BenefitsLogos/health.png';
import Electronics from '../../resources/BenefitsLogos/electronics.png';
import Entertainment from '../../resources/BenefitsLogos/entertainment.png';
import RealState from '../../resources/BenefitsLogos/real-state.png';
import Resume from '../../resources/BenefitsLogos/resume.png';
class Benefits extends Component {
  render() {
    return (
      <Container fluid>
        <Row className="nl-Benefits">
          <Col>
            <Row className="benefits-heading">
              <Col>Benefits for LinkCxO Members</Col>
            </Row>
            <Row
              style={{
                width: '100%',
                margin: '0px',
              }}>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img src={Automobile} alt="" className="benefit-img" />
                      </div>
                    </Col>
                  </Row>
                  <Row className="benefits-subheading">
                    <Col> Experience luxury on wheels</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="benefits-content">
                        Access exclusive privileges, including up to <span style={{fontFamily:'Open Sans'}}>4</span> lakhs in
                        cashback, through partnerships with top luxury
                        automobile brands.
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img alt="" src={Apparel} className="benefit-img" />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="benefits-subheading
              ">
                    <Col> Elevate your lifestyle</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="benefits-content">
                        Indulge in exclusive perks from top-tier fashion brands,
                        thoughtfully curated for your refined preferences.
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img alt="" src={Health} className="benefit-img" />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="benefits-subheading
              ">
                    <Col> Bespoke healthcare solutions</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="benefits-content">
                        {' '}
                        Avail up to <span style={{fontFamily:'Open Sans'}}>15</span>% off on customized packages from India's
                        leading digital healthcare platform
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                width: '100%',
                margin: '0px',
              }}>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img
                          alt=""
                          src={RealState}
                          className="benefit-img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="benefits-subheading">
                    <Col> Epitome of luxury living </Col>
                  </Row>
                  <Row>
                    <Col className="benefits-content">
                      <div className="benefits-content">
                        Experience exclusive discounts of up to <span style={{fontFamily:'Open Sans'}}>10</span> lakhs on
                        premium properties by renowned developers.
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img
                          alt=""
                          src={Entertainment}
                          className="benefit-img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="benefits-subheading
              ">
                    <Col>Unwind in Entertainment and Hospitality</Col>
                  </Row>
                  <Row>
                    <Col className="benefits-content">
                      <div className="benefits-content">
                        Indulge in Curated Hotel Packages, Resorts, and
                        Exclusive Entertainment Discounts.
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img alt="" src={Electronics} className="benefit-img" />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="benefits-subheading
              ">
                    <Col> Elevate your digital experience</Col>
                  </Row>
                  <Row>
                    <Col className="benefits-content">
                      <div className="benefits-content">
                        Unlock Exclusive Discounts and Premium Deals on Leading
                        Electronics Brands.
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img alt="" src={Investment} className="benefit-img" />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="benefits-subheading
              ">
                    <Col> Invest</Col>
                  </Row>
                  <Row>
                    <Col className="benefits-content">
                      <div className="benefits-content">
                        Start your journey in Angel Investing with a curated
                        list of startups with offers Exclusive to LinkCxO
                        Members
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={12}
                md={4}
                style={{
                  padding: '8px',
                }}>
                <div
                  style={{
                    width: '100%',
                    border: '1px dashed #333',
                    borderWidth: '2px',
                    padding: '15px 5px',
                  }}>
                  <Row>
                    <Col className="logo-frame">
                      {' '}
                      <div className="benefit-frame">
                        <img alt="" src={Sports} className="benefit-img" />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="benefits-subheading
              ">
                    <Col> Luxury Sports</Col>
                  </Row>
                  <Row>
                    <Col className="benefits-content">
                      <div className="benefits-content">
                        Curated end to end experiences for the largest sporting
                        events around the world
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
// class Benefits extends Component {
//   render() {
//     return (
//       <Container fluid>
//         <Row className="nl-Benefits">
//           <Col>
//             <Row className="benefits-heading">
//               <Col>Benefits for LinkCxO Members</Col>
//             </Row>
//             <Row
//               style={{
//                 width: '100%',
//                 margin: '0px',
//               }}>
//               <Col
//                 xs={12}
//                 md={4}
//                 style={{
//                   padding: '8px',
//                 }}>
//                 <div
//                   style={{
//                     width: '100%',
//                     border: '1px dashed #333',
//                     borderWidth: '2px',
//                     padding: '15px 5px',
//                   }}>
//                   <Row>
//                     <Col className="logo-frame">
//                       {' '}
//                       <div className="benefit-frame">
//                         <img
//                           src={CorporateBranding}
//                           alt=""
//                           className="benefit-img"
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row
//                     className="benefits-subheading
//               ">
//                     <Col>Premium Automobile Privileges</Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <div className="benefits-content">
//                         Partners with leading Automobile companies which
//                         provides Cash back 1% for LinkCxO members
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//               <Col
//                 xs={12}
//                 md={4}
//                 style={{
//                   padding: '8px',
//                 }}>
//                 <div
//                   style={{
//                     width: '100%',
//                     border: '1px dashed #333',
//                     borderWidth: '2px',
//                     padding: '15px 5px',
//                   }}>
//                   <Row>
//                     <Col className="logo-frame">
//                       {' '}
//                       <div className="benefit-frame">
//                         <img
//                           alt=""
//                           src={CustomRewardPrograms}
//                           className="benefit-img"
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row
//                     className="benefits-subheading
//               ">
//                     <Col>Special Lifestyle Privileges</Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <div className="benefits-content">
//                         {' '}
//                         LinkCxO offers its members with a wide range of leading
//                         fashion and entertainment brands.
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//               <Col
//                 xs={12}
//                 md={4}
//                 style={{
//                   padding: '8px',
//                 }}>
//                 <div
//                   style={{
//                     width: '100%',
//                     border: '1px dashed #333',
//                     borderWidth: '2px',
//                     padding: '15px 5px',
//                   }}>
//                   <Row>
//                     <Col className="logo-frame">
//                       {' '}
//                       <div className="benefit-frame">
//                         <img
//                           alt=""
//                           src={PersonalisedCommunity}
//                           className="benefit-img"
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row
//                     className="benefits-subheading
//               ">
//                     <Col>Customised Healthcare Packages</Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <div className="benefits-content">
//                         {' '}
//                         Get savings up to 15% on health care packages from
//                         India's leading digital Healthcare Platform
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//             </Row>
//             <Row
//               style={{
//                 width: '100%',
//                 margin: '0px',
//               }}>
//               <Col
//                 xs={12}
//                 md={4}
//                 style={{
//                   padding: '8px',
//                 }}>
//                 <div
//                   style={{
//                     width: '100%',
//                     border: '1px dashed #333',
//                     borderWidth: '2px',
//                     padding: '15px 5px',
//                   }}>
//                   <Row>
//                     <Col className="logo-frame">
//                       {' '}
//                       <div className="benefit-frame">
//                         <img
//                           alt=""
//                           src={PremiumBrands}
//                           className="benefit-img"
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row
//                     className="benefits-subheading
//               ">
//                     <Col>Luxurious Properties </Col>
//                   </Row>
//                   <Row>
//                     <Col className="benefits-content">
//                       <div className="benefits-content">
//                         {' '}
//                         Discounts from renowned property partners for LinkCxO
//                         Members
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//               <Col
//                 xs={12}
//                 md={4}
//                 style={{
//                   padding: '8px',
//                 }}>
//                 <div
//                   style={{
//                     width: '100%',
//                     border: '1px dashed #333',
//                     borderWidth: '2px',
//                     padding: '15px 5px',
//                   }}>
//                   <Row>
//                     <Col className="logo-frame">
//                       {' '}
//                       <div className="benefit-frame">
//                         <img
//                           alt=""
//                           src={PremiumUsers}
//                           className="benefit-img"
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row
//                     className="benefits-subheading
//               ">
//                     <Col>Hospitality and Entertainment</Col>
//                   </Row>
//                   <Row>
//                     <Col className="benefits-content">
//                       <div className="benefits-content">
//                         Get best packages on your lavish stay with lots of
//                         entertainment
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//               <Col
//                 xs={12}
//                 md={4}
//                 style={{
//                   padding: '8px',
//                 }}>
//                 <div
//                   style={{
//                     width: '100%',
//                     border: '1px dashed #333',
//                     borderWidth: '2px',
//                     padding: '15px 5px',
//                   }}>
//                   <Row>
//                     <Col className="logo-frame">
//                       {' '}
//                       <div className="benefit-frame">
//                         <img
//                           alt=""
//                           src={SimplePurchase}
//                           className="benefit-img"
//                         />
//                       </div>
//                     </Col>
//                   </Row>
//                   <Row
//                     className="benefits-subheading
//               ">
//                     <Col>Best Electronics discounts</Col>
//                   </Row>
//                   <Row>
//                     <Col className="benefits-content">
//                       <div className="benefits-content">
//                         Get best deals & 3-5% discounts on all major brands of
//                         electronics.
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </Container>
//     );
//   }
// }
export default Benefits;
