import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Paper, Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Icon from '../../assets/icon';
import * as rewardsActions from '../../store/rewards';
import * as messageAction from '../../store/message';
import CxoButton from '../../components/CxoButton/CxoButton';
import credits_gold from '../../assets/header/Credits_Gold.png';
import StarIcon from '@mui/icons-material/Star';
import * as messageActions from '../../store/message';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
export default function RewardSubHeader() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setValue] = React.useState(0);
  const user = useSelector((state) => state?.user?.UserDetails);

  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const membership_type = useSelector(
    (state) => state?.user?.userStatus?.membership_type,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const membershipList = useSelector((state) => state?.rewards?.membershipList);

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );
  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  useEffect(() => {
    async function fetchData() {
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardsCategoryList(),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDetails() {
      const data = {
        membership_id: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
    }
    fetchDetails();
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
    }
    fetchData();
  }, []);

  const handleChange = async (event, newValue) => {
    console.log('handleChange', event, newValue);

    if (user?.isVerified) {
      if (newValue === 1) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/my/vouchers');
        setValue(newValue);
      } else if (newValue === 0) {
        navigate('/rewards/membership');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 1) {
        navigate('/rewards/category/partners');
        setValue(val);
      } else if (val === 2) {
        navigate('/rewards/my/vouchers');
        setValue(val);
      } else if (val === 0) {
        navigate('/rewards/membership');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const subscriptionType = () => {
    var result = membershipList?.filter((obj) => {
      return obj.id === membership_type;
    });

    if (membership_type === 0) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}></Typography>
        </Stack>
      );
    } else if (membership_type === 1) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            {result[0]?.type}
          </Typography>
        </Stack>
      );
    } else if (membership_type === 2) {
      let datev = moment(
        moment
          .utc(
            membershipDetails[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetails[0]?.membership_expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#C0C0C0' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#C0C0C0',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            {result[0]?.type}
          </Typography>
          {datev != 'Invalid date' && (
            <Typography
              variant="h6"
              component="h6"
              sx={{
                color: '#ffffff',
                fontSize: '12px',
                paddingLeft: '12px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
              }}>
              Valid Till - {datev}
            </Typography>
          )}
        </Stack>
      );
    } else if (membership_type === 3) {
      let datev = moment(
        moment
          .utc(
            membershipDetails[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetails[0]?.membership_expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#FFC107' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#FFC107',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            {result[0]?.type}
          </Typography>
          {datev != 'Invalid date' && (
            <Typography
              variant="h6"
              component="h6"
              sx={{
                color: '#ffffff',
                fontSize: '12px',
                paddingLeft: '12px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
              }}>
              Valid Till - {datev}
            </Typography>
          )}
        </Stack>
      );
    } else if (membership_type === 4) {
      let datev = moment(
        moment
          .utc(
            membershipDetails[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetails[0]?.membership_expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#A0B2C6' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#A0B2C6',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            {result[0]?.type}
          </Typography>
          {datev != 'Invalid date' && (
            <Typography
              variant="h6"
              component="h6"
              sx={{
                color: '#ffffff',
                fontSize: '12px',
                paddingLeft: '8px',
                fontWeight: '400',
                fontFamily: 'Open Sans',
              }}>
              Valid Till - {datev}
            </Typography>
          )}
        </Stack>
      );
    }
  };
  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(user?.subscription_id);
      let msg = 'Content copied to clipboard';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'success'),
      );
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
      let msg = 'Failed to copy';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }
  };
  const creditsDetails = () => {
    navigate('/rewards/credits');
  };

  const creditsNvoucherDetails = () => {
    // navigate('/rewards/voucher/details')
    navigate('/rewards/credits');
  };

  const myVouchers = () => {
    navigate('/rewards/my/vouchers');
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}>
            <Box
              py={2}
              px={3}
              sx={{
                display: 'flex',
                justifyItems: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '48px',
                    maxWidth: 'min-content',
                    width: '48px',
                  }}>
                  <img alt="creditgold" src={credits_gold} />
                </Box>
                <Stack
                  direction="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '16px',
                  }}>
                  <Box pt={1} pb={0.5} px={1} sx={{ width: '100%' }}>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{
                        color: '#F5BD02',
                        fontSize: '20px',
                        lineHeight: '27px',
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                      }}>
                      {rewardsTotalCredits?.credits
                        ? parseInt(
                            rewardsTotalCredits?.credits,
                          )?.toLocaleString()
                        : parseInt(
                            rewardsTotalCredits?.credits,
                          )?.toLocaleString()}
                    </Typography>
                  </Box>
                  <Box pt={0.5} pb={1} px={1}>
                    <Typography variant="h2" component="h2" sx={{}}>
                      Credits
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box>

            <Stack direction="column">
              <Box
                pt={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CxoButton
                  label="Details + Voucher"
                  onClick={creditsNvoucherDetails}
                />
              </Box>
              {/* <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CxoButton label="My Vouchers" onClick={myVouchers} />
              </Box> */}
            </Stack>
          </Stack>
        </Paper>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}>
            <Box
              py={2}
              px={3}
              sx={{
                display: 'flex',
                justifyItems: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '48px',
                    maxWidth: 'min-content',
                    width: '48px',
                  }}>
                  <img alt="creditgold" src={credits_gold} />
                </Box>

                <Stack
                  direction="column"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '16px',
                    width: '100%',
                  }}>
                  {rewardsTotalCredits?.credits ? (
                    <Stack direction="row" sx={{ width: '100%' }}>
                      <Box pt={1} pb={0.5} px={1}>
                        <Typography
                          variant="h2"
                          component="h2"
                          sx={{
                            color: '#F5BD02',
                            fontSize: '20px',
                            lineHeight: '27px',
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                          }}>
                          {rewardsTotalCredits?.credits
                            ? parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()
                            : parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()}
                        </Typography>
                      </Box>
                      <Box
                        pt={1}
                        pb={0.5}
                        px={1}
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h2" component="h2" sx={{}}>
                          Credits
                        </Typography>
                      </Box>
                    </Stack>
                  ) : (
                    <Stack direction="row" sx={{ width: '100%' }}>
                      {rewardsTotalCredits?.credits == 0 ? (
                        <Box pt={1} pb={0.5} px={1}>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              color: '#F5BD02',
                              fontSize: '20px',
                              lineHeight: '27px',
                              fontWeight: '600',
                              fontFamily: 'Open Sans',
                            }}>
                            0
                          </Typography>
                        </Box>
                      ) : (
                        <Box pt={1} pb={0.5} px={1}>
                          <Skeleton variant="rectagle" width={80} height={30} />
                        </Box>
                      )}
                      <Box
                        pt={1}
                        pb={0.5}
                        px={1}
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h2" component="h2" sx={{}}>
                          Credits
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  <Stack direction="row" spacing="1">
                    {user?.isVerified ? (
                      <Fragment>
                        <Box sx={{ marginLeft: '8px' }}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{ color: '#ffffff' }}>
                            Membership Id:
                          </Typography>
                        </Box>
                        <Box pl={1}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{
                              color: '#38CD84',
                              fontFamily: 'Open Sans',
                              fontSize: '12px',
                            }}>
                            {user?.subscription_id}
                          </Typography>
                        </Box>
                        <Box
                          onClick={() => copyContent()}
                          pl={1}
                          sx={{
                            height: '24px',
                            width: '24px',
                            cursor: 'pointer',
                            paddingBottom: '8px',
                          }}>
                          <Icon name="copy" sx={{ marginBottom: '8px' }} />
                        </Box>
                      </Fragment>
                    ) : (
                      <Box sx={{ height: '40px' }}></Box>
                    )}
                  </Stack>

                  <Box
                    sx={{ width: 'inherit', paddingTop: '8px' }}
                    direction="row"
                    spacing="1">
                    <Box sx={{ marginLeft: '8px' }}>{subscriptionType()}</Box>
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Stack direction="column">
              <Box
                pt={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CxoButton
                  label="Details + Voucher"
                  onClick={creditsNvoucherDetails}
                />
              </Box>
              {/* <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CxoButton label="My Vouchers" onClick={myVouchers} />
              </Box> */}
            </Stack>
          </Stack>
        </Paper>
      </Fragment>
    );
  }
}
