import React, { Component } from 'react';
import { useState } from 'react';
import LinkCXOLogo from '../../resources/new-img/White-Rectangle.png';
import { Container } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import $ from 'jquery';
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
class Header extends Component {
  constructor(props) {
    super();
    this.state = { open: false };
    this.scroll = this.scroll.bind(this);
  }

  scroll(event) {
    var ele = document.getElementById(event.target.innerText);
    if (ele) {
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > 300) {
          $('.topicon').css('display', 'block');

          $('#1').css({
            'background-color': '#1C4586',
            alignItems: ' center',
            height: '35px',
            width: '100px',
            display: 'flex',
            'justify-content': 'center',
            color: 'white',
            // display: "inline-block",
            'border-radius': '5px',
            padding: '6px 14px',
            'font-size': '16px',
            'font-family': 'Raleway Medium',
          });
        } else {
          $('#1').css({ 'background-color': 'transparent', color: '#fff' });
          $('.topicon').css('display', 'none');
        }
        if (scroll > 2800) {
          $('#2').css({
            'background-color': '#1C4586',
            height: '35px',
            width: '100px',
            alignItems: ' center',
            display: 'flex',
            'justify-content': 'center',
            color: 'white',
            marginTop: '8px',
            // display: "inline-block",
            'border-radius': '5px',
            // padding: "6px 14px",
            'font-size': '16px',
            'font-family': 'Raleway Medium',
          });
          $('#1').css({ 'background-color': 'transparent', color: '#fff' });
        } else {
          $('#2').css({ 'background-color': 'transparent', color: '#fff' });
        }
        if (scroll > 6300) {
          $('#3').css({
            'background-color': '#1C4586',
            height: '35px',
            width: '100px',
            alignItems: ' center',
            marginTop: '8px',
            display: 'flex',
            'justify-content': 'center',
            color: 'white',
            // display: "inline-block",
            'border-radius': '5px',
            // padding: "6px 14px",
            'font-size': '16px',
            'font-family': 'Raleway Medium',
          });
          $('#1').css({ 'background-color': 'transparent', color: '#fff' });
          $('#2').css({ 'background-color': 'transparent', color: '#fff' });
        } else {
          $('#3').css({ 'background-color': 'transparent', color: '#fff' });
        }
        // if (scroll > 5550) {
        //   $("#4").css({
        //     "background-color": "#1C4586",
        //     color: "white",
        //     display: "inline-block",
        //     "border-radius": "3px",
        //     padding: "6px 14px",
        //     "font-size": "16px",
        //     "font-family": "Raleway Medium",
        //   });
        //   $("#1").css({ "background-color": "transparent", color: "#fff" });
        //   $("#2").css({ "background-color": "transparent", color: "#fff" });
        //   $("#3").css({ "background-color": "transparent", color: "#fff" });
        // } else {
        //   $("#4").css({ "background-color": "transparent", color: "#fff" });
        // }
        // if (scroll > 4800) {
        //   $("#5").css({
        //     "background-color": "#1C4586",
        //     color: "white",
        //     display: "inline-block",
        //     "border-radius": "5px",
        //     padding: "6px 14px",
        //     "font-size": "16px",
        //     "font-family": "Raleway Medium",
        //   });
        //   $("#1").css({ "background-color": "transparent", color: "#fff" });
        //   $("#2").css({ "background-color": "transparent", color: "#fff" });
        //   $("#3").css({ "background-color": "transparent", color: "#fff" });
        //   $("#4").css({ "background-color": "transparent", color: "#fff" });
        // } else {
        //   $("#5").css({ "background-color": "transparent", color: "#fff" });
        // }
      });
    });
  }

  handleOnClickToScroll = (id, value) => {
    console.log('id', id, value);
    var ele = document.getElementById(id);
    console.log('windows', window);
    window.scrollTo({ top: value });
  };
  // activeClass = (route) => {
  //   return location?.pathname === route ? "active" : null;
  // };
  render() {
    var isActive =
      this.context.router?.route?.location.pathname === this.props.to;
    var className = isActive ? 'active' : '';

    return (
      <Container fluid id="no-padding">
        <Navbar
          collapseOnSelect
          expand="sm"
          variant="#1F1E1E"
          style={{
            background: '#1F1E1E',
            margin: '0px',
            padding: '0px',
            alignItems: ' center',
            display: 'flex',
            justifyContent: 'center',
          }}
          className="nav-style"
          id="#">
          <Navbar.Brand
            className="logo-image-section"
            onClick={this.handlehome}>
            <Link to="/" className="header-link">
              <img
                alt=""
                src={LinkCXOLogo}
                className="logo-image-section-style"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ paddingTop: '10px' }}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
            style={{
              paddingRight: '60px',
              alignItems: ' center',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Nav className="me-auto">
              <Nav.Link
                eventKey="1"
                className="nave-bar-menu-style"
                // id="1"
                //onClick={this.handleOnClickToScroll.bind(this, "1", 660)}
              >
                <Link to="/aboutus" className={className}>
                  About Us
                </Link>
              </Nav.Link>
              <Nav.Link
                eventKey="2"
                className="nave-bar-menu-style"
                id="2"
                //onClick={this.handleOnClickToScroll.bind(this, "2", 3600)}
              >
                {' '}
                <Link to="/#jobs" className="nave-bar-menu-style-link">
                  {' '}
                  Jobs{' '}
                </Link>
              </Nav.Link>
              <Nav.Link
                eventKey="3"
                id="3"
                className="nave-bar-menu-style"
                //onClick={this.handleOnClickToScroll.bind(this, "3", 4350)}
              >
                {' '}
                <Link to="/#events" className="nave-bar-menu-style-link">
                  {' '}
                  Events
                </Link>
              </Nav.Link>

              {/* <Nav.Link
                className="nave-bar-menu-style"
                id="4"
                onClick={this.handleOnClickToScroll.bind(this, 5650)}
              >
                Business
              </Nav.Link> */}
              {/* <Nav.Link
                eventKey={2}
                onClick={this.handleContent}
                className="nave-bar-menu-style"
              >
                Industry Updates
              </Nav.Link> */}
              {/* <Nav.Link
                eventKey="2"
                id="5"
                className="nave-bar-menu-style"
                onClick={this.handleOnClickToScroll.bind(this, "5", 5195)}
              >
                News & Media
              </Nav.Link> */}
              {/* <Nav.Link eventKey={2} className="nave-bar-menu-style">
                <Link to="/ventures" className="nave-bar-menu-style-link">
                  Ventures
                </Link>
              </Nav.Link> */}
              <Nav.Link
                // eventKey={2}
                style={{
                  color: 'black',
                  fontSize: '14px',
                  marginRight: '45px',
                  height: '24px',
                }}>
                <button
                  className="become-a-member-btn"
                  style={{ height: '36px' }}>
                  <Link to="/login" className="nave-bar-join">
                    Login
                  </Link>
                </button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}
Header.contextTypes = {
  router: PropTypes.object,
};
export default Header;
