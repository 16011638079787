import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import PeopleuMayKnow from '../../components/PeopleuMayKnow/PeopleuMayKnow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import moment from 'moment';
import { dateTime } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import * as messageActions from '../../store/message';
const HomeClub = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const user = useSelector((state) => state?.user?.UserDetails);

  const mynetwork = useSelector(
    (state) => state?.usernetwork?.userRecommendationList,
  );
  const clubDiscoverList = useSelector(
    (state) => state?.clubs?.clubDiscoverList,
  );
  useEffect(() => {

    async function fetchData() {
      const data = {
       page_no: 0,
       user_id: user?.id
      };
      const clubList = await dispatch(clubsActions.discoverClubList(data));
    }
    fetchData();

  }, []);
  const redirectToClub = async (clubs) => {
    if (user?.isVerified) {
      const clubdetails = clubs?.title;
      const clubdetailsToOpen = await dispatch(
        clubsActions.openDetailsClub(clubs),
      );

      if (location.pathname.includes('/club/details/')) {
        const data = {
          user_id: user?.id,
          club_id: clubs?.id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
        if (clubs?.club_name) {
          const clubdetails = clubs?.club_name;
          console.log('clubDetails', clubs?.type);
          if (props?.type !== 'SavedPassed') {
            navigate('/club/details/' + clubdetails);
          }
        } else {
          const clubdetails = clubs?.title;
          console.log('clubDetails', clubs?.type);
          if (props?.type !== 'SavedPassed') {
            navigate('/club/details/' + clubdetails);
          }
        }
      } else {
        if (clubs?.club_name) {
          const clubdetails = clubs?.club_name;
          console.log('clubDetails', clubs?.type);
          if (props?.type !== 'SavedPassed') {
            navigate('/club/details/' + clubdetails);
          }
        } else {
          const clubdetails = clubs?.title;
          console.log('clubDetails', clubs?.type);
          if (props?.type !== 'SavedPassed') {
            navigate('/club/details/' + clubdetails);
          }
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  return (
    <Fragment>
      {clubDiscoverList?.length > 0 && (
        <Box
          px={1}
          py={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Box p={1}>
            <Typography variant="h5s" component="h5s" sx={{ fontSize: '14px' }}>
              Clubs you maybe interested in
            </Typography>
          </Box>
          <Box
            onClick={() => {
              navigate('/clubs');
            }}
            sx={{ cursor: 'pointer' }}>
            <Typography
              variant="h5"
              component="h5"
              sx={{
                fontSize: '12px',
                '@media (max-width: 500px)': {
                  fontSize: '13px',
                },
              }}>
              See all
            </Typography>
          </Box>
        </Box>
      )}

      {clubDiscoverList &&
        clubDiscoverList?.slice(0, 1)?.map((club, index) => {
          console.log('clubs', club);
          return (
            <Box key={index} px={1} py={0.2}>
              <Card
                sx={{
                  backgroundColor: '#343232',
                  borderRadius: '5px',
                  backgroundImage: 'none',
                  boxShadow: '0',
                }}
                onClick={() => redirectToClub(club)}>
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: 70,
                      minWidth: '70px',
                      maxHeight: '50px',
                      borderRadius: '5px',
                      backgroundColor: '#262525',
                      margin: '8px 0px 8px 8px',
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                    image={club?.image ? club?.image : ''}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: '-webkit-fill-available',
                        paddingBottom: '16px!important',
                      }}>
                      <Typography component="h3s" variant="h3s" style={{fontSize:'14px'}}>
                        {club?.club_name && club?.club_name?.length > 31
                          ? club?.club_name?.slice(0, 30) + '...'
                          : club?.club_name}
                        {/* {club?.club_name
                          ? club?.club_name?.substring(0, 100) + '...'
                          : ''} */}
                      </Typography>
                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{ display: 'flex', paddingTop: '8px' }}>
                        <Typography
                          variant="cardsubtitle"
                          component="cardsubtitle"
                          sx={{
                            color: 'rgba(255, 255,255,0.8)',
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '15px',
                            fontFamily: 'Raleway',
                          }}>
                          By{' '}
                          {club?.owner_name
                            ? club?.owner_name?.substring(0, 60)
                            : ''}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Box>
                </Stack>
              </Card>
            </Box>
          );
        })}
    </Fragment>
  );
};

export default React.memo(HomeClub);
