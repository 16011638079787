import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SuccessInvestor = () => {
  const navigate = useNavigate();
  return (
    <Container fluid className="nl-success">
      <Row>
        <Col>
          <Row>
            <Col sm={6} className="nl-success-right">
              <Row>
                <Col>
                  <Row className="success-heading">
                    {" "}
                    <p className="color-green">
                      Thank you for signing up with LinkCxO Ventures as an
                      Investor.
                    </p>
                  </Row>

                  <Row className="success-subheading">
                    <Col>
                      <p className="color-green">What happens next? </p>
                    </Col>
                  </Row>
                  <Row className="success-content">
                    <Col>
                      <Row>
                        <Col sm={1}>
                          {" "}
                          <div className="dot"></div>
                        </Col>
                        <Col sm={11}>
                          <p>
                            Our Verification Team will verify your submission
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={1}>
                          {" "}
                          <div className="dot"></div>
                        </Col>
                        <Col sm={11}>
                          <p>
                            We will find if we have startups that match your
                            interest, expertise and investment size
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={1}>
                          {" "}
                          <div className="dot"></div>
                        </Col>
                        <Col sm={11}>
                          <p>
                            We will get back to you no later than 3 to 4 working
                            days with the next steps of onboarding
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="success-subheading">
                    <Col>
                      <p className="color-green">
                        Join our exclusive C-suite community app{" "}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={6} className="nl-success-left"></Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessInvestor;
