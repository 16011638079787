import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import JobCard from '../../components/JobCard/JobCard';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import { dateTime } from '../../constants/app';
import JobCardSkeleton from '../../components/Skeleton/JobCardSkeleton';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));
const JobSearch = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );
  const recommendedLoading = useSelector(
    (state) => state?.jobs?.recommendedLoading,
  );
  const apliedLoading = useSelector((state) => state?.jobs?.apliedLoading);
  const savedJob = useSelector((state) => state?.jobs?.JobListSaved);
  const savedLoading = useSelector((state) => state?.jobs?.savedLoading);
  const appliedJob = useSelector((state) => state?.jobs?.JobListApplied);
  const [noofRecomended, setNoofRecomended] = React.useState(8);
  const [noofApplied, setNoofApplied] = React.useState(4);
  const [noofSaved, setNoofSaved] = React.useState(4);
  const [pageno, setPageno] = React.useState(0);
  const [appliedpageno, setAppliedpageno] = React.useState(0);

  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'open',
    };
    const jobs = await dispatch(jobsActions.getJobsList(data));
  }, []);

  useEffect(async () => {
    const data = {
      cand_id: user.id,
      page_no: appliedpageno,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
  }, []);

  useEffect(async () => {
    const data = {
      cand_id: user.id,
      status: 'saved',
    };
    const jobs = await dispatch(
      jobsActions.getJobListByStatusSaved(data, data.status),
    );
  }, []);

  useEffect(() => {
    async function fetchDataRec() {
      const data = {
        user_id: user.id,
        page_no: pageno,
      };
      const jobs = await dispatch(jobsActions.getRecommendedList(data));
    }
    fetchDataRec();
  }, []);
  const seeMore = () => {
    if (recommendedJob?.length > noofRecomended) {
      setNoofRecomended(recommendedJob?.length);
    } else if (recommendedJob?.length === noofRecomended) {
      setNoofRecomended(4);
    }
  };

  const seeMoreSaved = () => {
    if (savedJob?.length > noofSaved) {
      setNoofSaved(savedJob?.length);
    } else if (savedJob?.length === noofSaved) {
      setNoofSaved(4);
    }
  };

  const seeMoreRec = async () => {
    // if( recommendedJob?.length - 4 >= noofRecomended){
    //   debugger
    //   setNoofRecomended(noofRecomended + 4);
    // }
    // else{
    setPageno(pageno + 1);
    const data = {
      user_id: user.id,
      page_no: pageno + 1,
    };

    const jobs = await dispatch(jobsActions.getRecommendedList(data, 'new'));
    setNoofRecomended(noofRecomended + 4);
    // }aerch"

    // if (recommendedJob?.length > noofRecomended) {
    //   setNoofRecomended(recommendedJob?.length);
    // } else if (recommendedJob?.length === noofRecomended) {
    //   setNoofRecomended(4);
    // }
  };

  const seeMoreApllied = () => {
    if (appliedJob?.length > noofApplied) {
      setNoofApplied(appliedJob?.length);
    } else if (appliedJob?.length === noofApplied) {
      setNoofApplied(4);
    }
  };

  const seeMoreAppliedJob = async () => {
    const data = {
      cand_id: user.id,
      page_no: appliedpageno,
    };
    const jobs = await dispatch(jobsActions.getJobListByStatusApplied(data));
    setAppliedpageno(appliedpageno + 1);
  };

  const apply = async () => {
    const data = {
      user_id: user.id,
    };
    // const jobstatus = await dispatch(jobsActions.manageJobStatus(data));
  };

  const saveJob = async (jobid) => {
    const data = {
      user_id: user.id,
      type: 'job',
      content_id: jobid,
      datetime: dateTime(),
    };
    // const bookpost = await dispatch(activityActions.saveBookmark(data));
  };

  return (
    <Fragment>
      <Box px={0} id="recommended">
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 600,
                    '@media (max-width: 500px)': {
                      fontSize: '13px',
                      fontWeight: '400',
                    },
                  }}>
                  Recommended Jobs{' '}
                  {recommendedJob?.length > 0
                    ? '(' + recommendedJob[0].full_count + ')'
                    : ''}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMore}>
                {recommendedJob?.length > 4 ? (
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      fontWeight: 600,
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                        fontWeight: '400',
                      },
                    }}>
                    {recommendedJob?.length === noofRecomended
                      ? 'See Less'
                      : 'See All'}
                  </Typography>
                ) : (
                  <Typography variant="h5" component="h5"></Typography>
                )}
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
          }}>
          {recommendedLoading ? (
            <JobCardSkeleton />
          ) : (
            <>
              <Box>
                <InfiniteScroll
                  dataLength={
                    recommendedJob?.length ? recommendedJob?.length : 0
                  }
                  next={() => seeMoreRec()}
                  hasMore={true}
                  loader={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreRec}></Box>
                  }
                  height={
                    recommendedJob?.length > 2 ? 'calc(90vh - 250px)' : 'auto'
                  }
                  endMessage={
                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={seeMoreRec}>
                      <Box>End</Box>
                    </Box>
                  }>
                  <Grid container>
                    {recommendedJob?.length > 0 &&
                      recommendedJob?.map((list, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            md={6}
                            sx={{
                              paddingRight: index % 2 === 0 ? '8px' : '0px',
                              marginTop: '8px',
                            }}>
                            <JobCard
                              joblist={list}
                              type="search"
                              apply={apply}
                              saveJob={saveJob}
                            />
                          </Grid>
                        );
                      })}
                    {recommendedJob?.length == 0 && (
                      <Box pl={3} pt={3}>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            '@media (max-width: 500px)': {
                              fontSize: '12px',
                            },
                          }}>
                          Currently, there are no recommended jobs, please check
                          later.
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </InfiniteScroll>
              </Box>
            </>
          )}
        </Box>
        <Box pt={2} sx={{ width: '100%' }} id="applied">
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 600,
                    '@media (max-width: 500px)': { fontSize: '14px' },
                  }}>
                  Applied{' '}
                  {appliedJob?.length ? '(' + appliedJob?.length + ')' : ''}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMoreApllied}>
                {appliedJob?.length > 4 ? (
                  <Typography variant="h5" component="h5">
                    {appliedJob?.length === noofApplied
                      ? 'See Less'
                      : 'See All'}
                  </Typography>
                ) : (
                  <Typography variant="h5" component="h5"></Typography>
                )}
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
          }}>
          {apliedLoading ? (
            <JobCardSkeleton />
          ) : (
            <Grid container>
              {appliedJob?.length > 0 &&
                appliedJob?.slice(0, noofApplied)?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <JobCard joblist={list} type="search_applied" />
                    </Grid>
                  );
                })}
              {appliedJob?.length == 0 && (
                <Box pl={3} pt={3}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ '@media (max-width: 500px)': { fontSize: '12px' } }}>
                    You've not applied to any job yet
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </Box>
        {appliedJob?.length > 0 &&
          appliedJob[0]?.full_count !== appliedJob?.length && (
            <Box
              pl={3}
              pt={3}
              onClick={seeMoreAppliedJob}
              sx={{
                justifyContent: 'center',
                display: 'flex',
                flex: 1,
                cursor: 'pointer',
              }}>
              <Typography
                variant="h5"
                component="h5"
                sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
                See more
              </Typography>
            </Box>
          )}
        <Box pt={2} sx={{ width: '100%' }} id="saved">
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 600,
                    '@media (max-width: 500px)': { fontSize: '14px' },
                  }}>
                  Saved {savedJob?.length ? '(' + savedJob?.length + ')' : ''}
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMoreSaved}>
                {savedJob?.length > 4 ? (
                  <Typography variant="h5" component="h5">
                    {savedJob?.length === noofSaved ? 'See Less' : 'See All'}
                  </Typography>
                ) : (
                  <Typography variant="h5" component="h5"></Typography>
                )}
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
          }}>
          {savedLoading ? (
            <JobCardSkeleton />
          ) : (
            <Grid container>
              {savedJob?.length > 0 &&
                savedJob?.slice(0, noofSaved)?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        paddingRight: index % 2 === 0 ? '8px' : '0px',
                        marginTop: '8px',
                      }}>
                      <JobCard joblist={list} type="search_saved" />
                    </Grid>
                  );
                })}
              {savedJob?.length == 0 && (
                <Box pl={3} pt={3}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}>
                    Currently, there are no jobs saved by you
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobSearch);

{
  /* <Box
py={3}
px={3}
sx={{
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
  cursor: "pointer",
}}
onClick={seeMore}
>
{recommendedJob?.length > 4 ? (
  <Typography
    variant="h5"
    component="h5"
    sx={{
      fontWeight: 600,
      "@media (max-width: 500px)": {
        fontSize: "12px",
        fontWeight: "400",
      },
    }}
  >
    {recommendedJob?.length === noofRecomended
      ? "See Less"
      : "See All"}
  </Typography>
) : (
  <Typography variant="h5" component="h5"></Typography>
)}
</Box> */
}

{
  /* <Grid container>
{recommendedJob?.length > 0 &&
  recommendedJob?.slice(0, noofRecomended)?.map((list, index) => {
    return (
      <Grid
        key={index}
        item
        xs={12}
        md={6}
        sx={{
          paddingRight: index % 2 === 0 ? "8px" : "0px",
          marginTop: "8px",
        }}
      >
        <JobCard
          joblist={list}
          type="search"
          apply={apply}
          saveJob={saveJob}
        />
      </Grid>
    );
  })}
{recommendedJob?.length == 0 && (
  <Box pl={3} pt={3}>
    <Typography
      variant="h5"
      component="h5"
      sx={{
        "@media (max-width: 500px)": {
          fontSize: "12px",
        },
      }}
    >
      Currently, there are no recommended jobs, please check
      later.
    </Typography>
  </Box>
)}
</Grid>
 <Box
 py={2}
 sx={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   cursor: "pointer",
 }}
 onClick={seeMoreRec}
>
 <Typography variant="h5" component="h5">
  See More
 </Typography>
</Box> */
}
