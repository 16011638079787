import React, { Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Menuicon from '../../assets/menuicon';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import TextField from '@mui/material/TextField';
import CxoButton from '../CxoButton/CxoButton';
import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';
import moment from 'moment';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function QueryAnswerCard(props) {
  console.log('QueryAnswerCard', props);
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [editComment, setEditComment] = React.useState(false);
  const [commentText, setCommenttext] = React.useState('');
  const [trim, setTrim] = React.useState(true);
  const isMenuOpen = Boolean(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(async () => {
    if (props?.comment?.content) {
      setCommenttext(props?.comment?.content);
    }
  }, []);

  const handleMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);

  const handleChange = (event) => {
    setCommenttext(event.target.value);
  };

  const editPost = () => {
    setCommenttext(props?.comment?.content);
    setEditComment(!editComment);
    setAnchorEl(!anchorEl);
  };
  const deleteAnswer = () => {
    const data = {
      user_id: user?.id,
      id: props?.comment?.id,
    };
    props.deleteAnswer(props?.answer?.formResponseId);
  };

  const addComment = async () => {
    if (commentText.length > 0) {
      setCommenttext('');
      setEditComment(!editComment);
      return props.updateComment(commentText, props?.comment?.id);
    }
  };

  const seeMore = () => {
    if (!trim) {
      setTrim(!trim);
    } else {
      setTrim(!trim);
    }
  };

  const timeago = () => {
    if (props?.answer?.createdAt === 'Invalid date') {
      return '';
    } else {
      return moment(props?.answer?.createdAt).fromNow();
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: '#262525',
        marginTop: '4px',
      }}>
      <Fragment>
        <CardHeader
          sx={{ paddingTop: '8px', paddingBottom: '0px' }}
          avatar={
            <Avatar
              src={
                props?.answer?.userImage ? props?.answer?.userImage : PersonIcon
              }
              sx={{ width: 24, height: 24 }}
            />
          }
          action={
            <Stack
              spacing={1}
              direction="row"
              sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton aria-label="addfriend" onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              {anchorEl && user?.id === props?.answer?.userId && (
                <Box sx={{ marginLeft: '0px!important' }}>
                  <Box
                    anchorEl={anchorEl}
                    id="primary-search-account-menu"
                    keepMounted
                    sx={{
                      marginTop: '12px',
                      scrollbarWidth: 'thin',
                      position: 'absolute',
                      backgroundColor: '#2c2c2d',
                      height: 'auto',
                      width: '129px',
                      marginLeft: '-129px',
                      zIndex: '1000',
                      boxShadow:
                        '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                      borderRadius: '2px',
                    }}
                    open={anchorEl}
                    onClose={handleMenuClose}>
                    <Box>
                      <List>
                        {/* <MenuItem
                          button
                          sx={{ padding: '0px 12px' }}
                          onClick={editPost}>
                          <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        <Divider /> */}
                        <MenuItem
                          button
                          sx={{ padding: '0px 12px' }}
                          onClick={deleteAnswer}>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
              )}
            </Stack>
          }
          title={
            <Stack spacing={1} direction="row">
              <Typography variant="h5s" component="h5s" >
                {props?.answer?.user}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>{timeago()}</Typography>
            </Stack>
          }
          subheader={
            <Stack spacing={1} direction="row">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '13px',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>{props?.answer?.userDesignation}</Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '13px',
                  paddingTop: '3px',
                  color: 'rgba(255, 255,255,0.5)',
                }}>{'||'} {' '} {props?.answer?.userCompany}</Typography>
            </Stack>
          }
        />
        <CardContent
          sx={{
            paddingTop: '4px',
            paddingBottom: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              paddingTop: '3px',
              // color: '#ffffff',
              color: 'rgba(255, 255,255,1)',
              whiteSpace: 'pre-line',
              lineHeight: '21px',
              letterSpacing: '1.2',
            }}
            style={{ wordWrap: 'break-word' }}>
            {props?.answer?.response?.length > 260 && trim
              ? props?.answer?.response?.substring(0, 260)
              : props?.answer?.response}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', cursor:"pointer" }}>
            {props?.answer?.response?.length > 260 && trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',
                  // marginTop: "-20px",
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                    marginTop: '-20px',
                  },
                }}
                onClick={seeMore}>
                {' '}
                ...See more
              </Box>
            ) : (
              ' '
            )}
            {props?.answer?.response?.length > 260 && !trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',

                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                    marginTop: '-20px',
                  },
                }}
                onClick={seeMore}>
                {' '}
                ...See less
              </Box>
            ) : (
              ''
            )}
          </Box>
        </CardContent>
      </Fragment>
    </Card>
  );
}

// { props?.post?.document &&
//   <Document
// file= {props?.post?.document }
// onLoadSuccess={onDocumentLoadSuccess}
// >
// <Page pageNumber={pageNumber} />
// </Document>
//
// }

// <IconButton aria-label="share">
//   <ReplyIcon fontSize="small" />
// </IconButton>

// <CardActions disableSpacing>
//   <Grid container>
//     <Grid
//       item
//       xs={4}
//       sx={{ display: "flex", justifyContent: "flex-start" }}
//     >
//       <Stack
//         spacing={1}
//         direction="row"
//         sx={{ display: "flex", alignItems: "center" }}
//       >
//         <IconButton
//           aria-label="applause"
//           sx={{
//             width: "32px",
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               height: "16px",
//               width: "fit-content",
//             }}
//           >
//             <Icon
//               name="applause"
//               width={"16px"}
//               color={
//                 props?.post?.liked_by_me == 0 ? "#ffffff" : "#38cd84"
//               }
//             />
//           </Box>
//         </IconButton>
//         <Typography variant="h6" component="h6">
//           {props?.post?.like_count ? props?.post?.like_count : 0}{" "}
//           Applauses
//         </Typography>
//       </Stack>
//     </Grid>
//     <Grid
//       item
//       xs={4}
//       sx={{ display: "flex", justifyContent: "flex-start" }}
//     >
//       <Stack
//         spacing={1}
//         direction="row"
//         sx={{ display: "flex", alignItems: "center" }}
//       >
//         <Typography variant="h6" component="h6">
//           Reply
//         </Typography>
//       </Stack>
//     </Grid>
//   </Grid>
// </CardActions>
