import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import InputBase from '@mui/material/InputBase';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import * as messageActions from '../../store/message';
import * as surveyActions from '../../store/survey';
import useStyles from './shareurexpstyle';
import Icon from '../../assets/icon';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

export default function ShareUrExperience() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const openpoll = useSelector((state) => state?.post?.PollEdit);

  const openPost = async () => {
    if (user?.isVerified) {
      const mypost = await dispatch(postActions.openPostEdit());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const openSurvey = async () =>{
    if (user?.isVerified) {
      const mySurvey = await dispatch(surveyActions.openSurveyEdit());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  }
  

  const openQuery = async () =>{
    if (user?.isVerified) {
      const mySurvey = await dispatch(surveyActions.openQuery());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  }
  const openArticle = async () => {
    if (user?.isVerified) {
      const myarticle = await dispatch(articleActions.openArticleEdit());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const openPoll = async () => {
    if (user?.isVerified) {
      const mypoll = await dispatch(postActions.openPollEdit());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  if (openpoll) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: '#1f1e1e',
          position: { md: 'absolute', xs: 'relative' },
          width: { md: '624px', xs: '100%' },
          zIndex: { md: '1000', xs: '1000' },
        }}
        py={1}>
        <Box py={1} sx={{ borderRadius: '5px', backgroundColor: '#262525' }}>
          <Stack
            spacing={0}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Box p={1} sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Avatar
                src={user?.image ? user?.image : PersonIcon}
                sx={{ width: 48, height: 48 }}
              />
            </Box>
            <Search>
              <Stack
                spacing={0}
                direction="row"
                sx={{ backgroundColor: '#262525' }}>
                <Box onClick={openPost} sx={{ color: '#fff !important' }}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontSize: '18px',
                      color: '#fff !important',
                      cursor: 'pointer',
                      '@media (max-width: 500px)': { fontSize: '14px' },
                    }}>
                    Share your experience...
                  </Typography>
                </Box>
              </Stack>
            </Search>
            <Stack spacing={0} direction="row">

               <IconButton
                className={classes.iconbtn}
                aria-label="add Image"
                onClick={openPost}>
                <InsertPhotoOutlinedIcon />
              </IconButton>
              <IconButton
                className={classes.iconbtn}
                aria-label="imtent"
                onClick={openArticle}>
                <FormatIndentIncreaseOutlinedIcon />
              </IconButton>
              <IconButton
                className={classes.iconbtn}
                aria-label="gallary"
                onClick={openPoll}>
                <BallotOutlinedIcon />
              </IconButton>
              <IconButton sx={{marginTop:"4px"}} className={classes.iconbtn}
                aria-label="query"
                onClick={openQuery}>
                <Icon
                  name="query"
                  color= "#959498"
                />
              </IconButton>
              <IconButton className={classes.iconbtn}
                aria-label="survey"
                onClick={openSurvey}>
                <Icon
                  name="survey"
                  color= "#959498"
                />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: '#1f1e1e',
          position: { md: 'absolute', xs: 'relative' },
          width: { md: '624px', xs: '100%' },
          zIndex: { md: '2000', xs: '1000' },
        }}
        py={1}>
        <Box py={1} sx={{ borderRadius: '5px', backgroundColor: '#262525' }}>
          <Stack
            spacing={0}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Box p={1} sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Avatar
                src={user?.image ? user?.image : PersonIcon}
                sx={{ width: 48, height: 48 }}
              />
            </Box>
            <Search>
              <Stack
                spacing={0}
                direction="row"
                sx={{ backgroundColor: '#262525' }}>
                <Box onClick={openPost}>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontSize: '18px',
                      color: '#fff',
                      cursor: 'pointer',
                      '@media (max-width: 500px)': { fontSize: '12px' },
                    }}>
                    Share your experience...
                  </Typography>
                </Box>
              </Stack>
            </Search>
            <Stack spacing={0} direction="row">

              <IconButton
                className={classes.iconbtn}
                aria-label="add Image"
                onClick={openPost}>
                <InsertPhotoOutlinedIcon />
              </IconButton>
              <IconButton
                className={classes.iconbtn}
                aria-label="imtent"
                onClick={openArticle}>
                <FormatIndentIncreaseOutlinedIcon />
              </IconButton>
              <IconButton
                className={classes.iconbtn}
                aria-label="gallary"
                onClick={openPoll}>
                <BallotOutlinedIcon />
              </IconButton>
              <IconButton sx={{marginTop:"4px"}} className={classes.iconbtn}
                aria-label="query"
                onClick={openQuery}>
                <Icon
                  name="query"
                  color= "#959498"
                />
              </IconButton> 
              <IconButton className={classes.iconbtn}
                aria-label="survey"
                onClick={openSurvey}>
                <Icon
                  name="survey"
                  color= "#959498"
                />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  }
}

//
// <IconButton
//   className={classes.iconbtn}
//   aria-label="video"
//   onClick={openPost}
// >
//   <VideoCameraBackOutlinedIcon />
// </IconButton>
// <IconButton
//   className={classes.iconbtn}
//   aria-label="file"
//   onClick={openPost}
// >
//   <AttachFileOutlinedIcon />
// </IconButton>
