import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Paper, Grid, Box, Typography } from "@mui/material";
import CxoButton from "../../../components/CxoButton/CxoButton";
import Stack from "@mui/material/Stack";
import CxoInput from "../../../components/CxoInput/CxoInput";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
const AddNew = (props) => {
  const [name, setName] = useState("");
  console.log("name", name);
  return (
    <Fragment>
      <Paper
        elevation={2}
        sx={{
          minWidth: "500px",
          display: "flex",
          flexDirection: "column",
          zIndex: 2000,
        }}
      >
        <Box
          px={4}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <Stack spacing={0} direction="row" sx={{ width: "100%" }}>
            <Box
              pt={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2s"
                component="h2s"
                sx={{ fontWeight: "800" }}
              >
                Add New
              </Typography>
            </Box>
            <Box
              pt={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CloseIcon
                sx={{ color: "#ffffff", cursor: "pointer", fontSize: "24" }}
                onClick={props.handleClose}
              />
            </Box>
          </Stack>

          <Box
            py={2}
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TextField
              fullWidth
              multiline
              rows={2}
              maxRows={4}
              placeholder="Enter Your Interest"
              value={props.name}
              onChange={props.onchange}
              sx={{ backgroundColor: "#2b2a2a" }}
            />
          </Box>

          <Box
            pt={1}
            pb={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CxoButton label="Submit" onClick={(name) => props.onclick(name)} />
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default React.memo(AddNew);
