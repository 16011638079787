import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Medibuddy from '../../../resources/PartnerLogos/MediBuddy-White.png';
import Nura from '../../../resources/PartnerLogos/nura_logo_white.png'

const Healthpkg = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateNextTwoTone = (id, name) => {
    navigate('/rewards/voucher/' + name + '-lx' + id);
  };

  return (
    <>
      <Stack
        direction={'column'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          backgroundColor: '#262525',
        }}>
        <Box
          sx={{
            paddingTop: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            paddingBottom: '36px',
          }}>
          <h2 className="partner-heading">
          Bespoke healthcare solutions{' '}
          </h2>
        </Box>
        <Stack direction={'row'} spacing={8}>  
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              backgroundColor: '#262525',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('64', 'MediBuddy')}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Medibuddy} className="logo_brand_img" />
            </Box>
          </Box>


          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              backgroundColor: '#262525',
            }}>
            <Box
              onClick={() => navigateNextTwoTone('79', 'Nura')}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={Nura} className="logo_brand_img" />
            </Box>
          </Box>
        </Stack>
        <Box pt={8}>
          <h2 className="partner-heading">
            Exclusive Benefits For LinkCxO Members:
          </h2>
        </Box>
        <Box sx={{ width: '100%', padding: '40px 180px' }}>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 10 Lakh on Premium Real Estate
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 4 Lakh on Premium Automobiles
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Exclusive offers on Entertainment and Hospitality
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Offers and Discounts on Premium Apparel and Accessory Brands
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Discount on Purchases of Electronics from India’s Leading Big Box
            Brands
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Health Package Discounts from one of India’s Leading Digital
            Healthcare Platform
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Opportunity to Invest in Hand Picked Startups to start your Angel
            Investing Journey with exclusive Discounts
          </p>
        </Box>
      </Stack>
    </>
  );
};

export default Healthpkg;
