import axios from "axios";
import * as constant from "../utils/constant";
import config from "../services/apiConfig";

import React, { Component } from "react";

export const client = axios.create({
  baseURL: config.serverBaseUrl,
  headers: {
    "Content-Type": "application/json",
    // schema: "exotalentdb",
    token:
      localStorage.getItem("User_Details") !== undefined &&
      JSON.parse(localStorage.getItem("User_Details")) !== null
        ? JSON.parse(localStorage.getItem("User_Details")).token
        : window.location.pathname && window.location.pathname.split("/")[1],
  },
});
/* Send token to backend for authentication */
client.interceptors.request.use((request) => {
  request.headers["x-auth-token"] = localStorage.getItem("User_Details");
  return request;
});
