import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Box, Typography, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import CxoButton from '../../components/CxoButton/CxoButton';
import Backdrop from '@mui/material/Backdrop';
import DirectShare from '../../assets/home/direct_share.png';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const JobShare = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const openJobShare = useSelector((state) => state?.jobs?.JobShare);
  const detailsJobShare = useSelector((state) => state?.jobs?.ShareJobDetails);
  const existingNetwork = useSelector(
    (state) => state?.usernetwork?.ExistingNetworkList,
  );
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [writeBody, setWriteBody] = useState('');
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [imgData, setImgData] = useState(null);
  const [displayNetwork, setDisplayNetwork] = useState(false);
  const [networklst, setNetworklst] = useState([]);
  const handleWrite = (e) => {
    setWriteBody(e.target.value);
  };
  useEffect(() => {
    async function networkApi() {
      const data = {
        user_id: user?.id,
      };
      const existinglist = await dispatch(
        usernetworkActions.getExistingNetwork(data),
      );
    }
    networkApi();
  }, []);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setNetworklst(typeof value === 'string' ? value.split(',') : value);
  };
  const getDocument = async (event) => {
    setFile(event.target.files[0]);
    setType('document');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };
  const handleSubmitform = async (data) => {
    console.log('handleSubmitform', data);
  };
  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const snackbar = await dispatch(jobsActions.closeShareJob());
  };
  const shareJob = async () => {
    const formData = new FormData();
    formData.append('purpose', 'job_share');
    formData.append('from_user_id', user?.id);
    formData.append('content_table_pk', detailsJobShare?.id);
    formData.append('aUsers', JSON.stringify(networklst));
    formData.append('datetime', dateTime());

    const share = await dispatch(
      jobsActions.manageContentShare(formData, async (error, response) => {
        if (response) {
          const snackbar = await dispatch(jobsActions.closeShareJob());
        }
      }),
    );

    // const snackbar = await dispatch(jobsActions.closeShareJob());
    const datashare = {
      user_id: user?.id,
    };
    const jobs = await dispatch(jobsActions.getRecommendedList(datashare));
  };
  const shareInConnection = async () => {
    setDisplayNetwork(!displayNetwork);
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          position: 'fixed',
          zIndex: '1001',
        }}>
        <Backdrop sx={{ color: '#fff', zIndex: '2001' }} open={openJobShare}>
          <Box px={0}>
            <Box
              pt={2}
              sx={{
                width: '100%',
                width: 500,
                '@media (max-width: 500px)': {
                  width: 300,
                },
              }}>
              <Paper
                sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
                {!displayNetwork && (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={0}>
                      <Box
                        px={3}
                        py={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '16px',
                        }}>
                        <Typography variant="h2s" component="h2s">
                          Share Job
                        </Typography>
                      </Box>
                      <Box pb={2} pr={2}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        onClick={shareInConnection}>
                        <Box
                          p={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}>
                          <img alt="logo" src={DirectShare} height= '55'/>
                        </Box>
                        <Box p={2}>
                          <Typography variant="h2" component="h2">
                            In your connection
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </>
                )}

                {displayNetwork && (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={0}>
                      <Box
                        px={3}
                        py={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '16px',
                        }}>
                        <Typography variant="h2s" component="h2s">
                          Share Job
                        </Typography>
                      </Box>
                      <Box pb={2} pr={2}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={shareInConnection}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack direction="column" justifyContent="center">
                        <Box
                          sx={{
                            width: '400px',
                            '@media (max-width: 500px)': {
                              width: '280px',
                            },
                          }}>
                          <Box py={1} pl={1} sx={{ width: '96%' }}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                style={{
                                  color: '#38CD84',
                                  fontSize: '13px',
                                }}
                                id="demo-simple-select-label">
                                Network
                              </InputLabel>
                              <CssSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={networklst}
                                multiple
                                label="Industry List"
                                onChange={handleChange}
                                size="small"
                                className={classes.underline}
                                color="secondary"
                                sx={{ color: '#38CD84' }}
                                renderValue={(selected) => {
                                  console.log('selected', selected);
                                  return selected.length + ' ' + 'Selected';
                                }}>
                                {existingNetwork
                                  ? existingNetwork?.map((item, key) => {
                                      console.log('item', item);
                                      return (
                                        <MenuItem
                                          color="secondary"
                                          key={key}
                                          value={item.id}>
                                          <ListItemText
                                            color="secondary"
                                            primary={
                                              item.first_name +
                                              ' ' +
                                              item.last_name
                                            }
                                          />
                                          <Checkbox
                                            color="secondary"
                                            icon={<RadioButtonUncheckedIcon />}
                                            checkedIcon={<CheckCircleIcon />}
                                            checked={
                                              networklst.indexOf(item.id) > -1
                                            }
                                          />
                                        </MenuItem>
                                      );
                                    })
                                  : ''}
                              </CssSelect>
                            </FormControl>
                          </Box>

                          <Box
                            py={4}
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}>
                            <CxoButton onClick={shareJob} label="Share" />
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </>
                )}
              </Paper>
            </Box>
          </Box>
        </Backdrop>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobShare);
