import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CardLong from '../../components/CardLong/CardLong';
import CardClub from '../../components/CardLong/CardClub';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import { dateTime } from '../../constants/app';
import ClubCard from '../../components/ClubCard/ClubCard';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubFriends = (props) => {
  const dispatch = useDispatch();
  const [userd, setUserd] = useState({
    id: 864,
    first_name: 'Sudha',
    last_name: 'kumari',
    email: 'badal@linkcxo.com',
    image:
      'https://linkcxo-test-new.s3.ap-south-1.amazonaws.com/2022-06-03T06-33-16.940Z2022-03-31T10-21-21.881ZWhatsApp%20Image%202022-02-21%20at%209.49.58%20PM.jpeg',
  });
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const user = useSelector((state) => state?.user?.UserDetails);
  const clubDetailstoOpen = useSelector(
    (state) => state?.clubs?.clubDetailstoOpen,
  );
  const clubPostList = useSelector((state) => state?.clubs?.clubPostList);
  const clubMembersFriend = useSelector(
    (state) => state?.clubs?.clubMembersFriend,
  );
  console.log('clubMembersFriend', clubMembersFriend);
  useEffect(async () => {
    // const data = {
    //   user_id: user?.id,
    //   club_id: clubDetailstoOpen?.id,
    // };
    // const clubdetails = await dispatch(clubsActions.clubDetails(data));
  }, []);

  useEffect(async () => {
    if (clubDetailstoOpen?.club_id) {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.club_id,
      };
      const clubdetails = await dispatch(
        clubsActions.clubMemberFriendList(data),
      );
    } else {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.id,
      };
      const clubdetails = await dispatch(
        clubsActions.clubMemberFriendList(data),
      );
    }
  }, []);

  return (
    <Fragment>
      {clubMembersFriend?.length > 0 && (
        <Fragment>
          <Box px={2}>
            <Stack
              direction="row"
              sx={{ display: 'flex', alignItems: 'center' }}>
              {clubMembersFriend ? (
                clubMembersFriend?.slice(0, 3)?.map((clubfriend, index) => {
                  console.log('clubMembersFriend', clubfriend);
                  return (
                    <Fragment>
                      <Avatar
                        key={clubfriend?.id}
                        src={clubfriend?.image}
                        sx={{
                          height: '30px',
                          border: '1.5px solid  #ffffff',
                          width: '30px',
                        }}
                      />
                    </Fragment>
                  );
                })
              ) : (
                <Box></Box>
              )}
              <Box py={1}>
                <Typography variant="h5" component="h5">
                  {' \0\0\0'}
                  {clubMembersFriend?.length > 3
                    ? '+' +
                      (clubMembersFriend?.length - 3) +
                      '  Connnections have joined'
                    : '  Connnections have joined'}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box py={2}>
            <Divider />
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default React.memo(ClubFriends);

// marginLeft: "-0px",
