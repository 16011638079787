import {  apiUrl } from '../../services';
import { http, http_noauth } from '../../services/client';
import { showMessage } from '../message';
export const GET_POST_ACTIVITY_FULFILLED = 'GET_POST_ACTIVITY_FULFILLED';
export const GET_POST_ACTIVITY_PENDING = 'GET_POST_ACTIVITY_PENDING';
export const GET_POST_ACTIVITY_REJECTED = 'GET_POST_ACTIVITY_REJECTED';

const getPostActivityPending = () => ({
  type: GET_POST_ACTIVITY_PENDING,
});

const getPostActivityFailure = (error) => ({
  type: GET_POST_ACTIVITY_REJECTED,
  payload: { error },
});

const getPostActivitySuccess = (data, post) => ({
  type: GET_POST_ACTIVITY_FULFILLED,
  payload: data,
});

export const getPostActivity = (payload) => {
  return (dispatch) => {
    dispatch(getPostActivityPending());
    return http
      .post(apiUrl.GET_POST_ACTIVITY, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getPostActivitySuccess(response));
          return response;
        } else {
          dispatch(getPostActivityFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getPostActivityFailure(error));
        return error;
      });
  };
};

export const GET_JOBS_LIST_FULFILLED = 'GET_JOBS_LIST_FULFILLED';
export const GET_JOBS_LIST_PENDING = 'GET_JOBS_LIST_PENDING';
export const GET_JOBS_LIST_REJECTED = 'GET_JOBS_LIST_REJECTED';

const getJobsListPending = (payload) => ({
  type: 'GET_JOBS_LIST_PENDING',
  payload: { payld : payload}
});

const getJobsListFailure = (error) => ({
  type: 'GET_JOBS_LIST_REJECTED',
  payload: { error },
});

const getJobsListSuccess = (data, payload) => ({
  type: 'GET_JOB_LIST_FULFILLED',
  payload: { job: data?.data, payld : payload}
});

export const getJobsListr = (payload) => {
  return (dispatch) => {
    dispatch(getJobsListPending(payload));
    return http
      .post(apiUrl.JOB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobsListSuccess(response, payload));
          return response;
        } else {
          dispatch(getJobsListFailure(response, payload));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobsListFailure(error));
        return error;
      });
  };
};

export const GET_UPCOMING_EVENT_LIST_FULFILLED =
  'GET_UPCOMING_EVENT_LIST_FULFILLED';
export const GET_UPCOMING_EVENT_LIST_PENDING =
  'GET_UPCOMING_EVENT_LIST_PENDING';
export const GET_UPCOMING_EVENT_LIST_REJECTED =
  'GET_UPCOMING_EVENT_LIST_REJECTED';

const getEventListPending = () => ({
  type: 'GET_UPCOMING_EVENT_LIST_PENDING',
});

const getEventListFailure = (error) => ({
  type: 'GET_UPCOMING_EVENT_LIST_REJECTED',
  payload: { error },
});

const getEventListSuccess = (data) => ({
  type: 'GET_UPCOMING_EVENT_LIST_FULFILLED',
  payload: data,
});

export const getEventList = () => {
  return (dispatch) => {
    dispatch(getEventListPending());
    return http
      .post(apiUrl.UPCOMING_EVENT_LIST)
      .then((response) => {
        console.log('payload', response);
        if (response?.status === 1) {
          dispatch(getEventListSuccess(response));
          return response;
        } else {
          dispatch(getEventListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getEventListFailure(error));
        return error;
      });
  };
};

export const GET_JOBS_ACTIVITY_FULFILLED = 'GET_JOBS_ACTIVITY_FULFILLED';
export const GET_JOBS_ACTIVITY_PENDING = 'GET_JOBS_ACTIVITY_PENDING';
export const GET_JOBS_ACTIVITY_REJECTED = 'GET_JOBS_ACTIVITY_REJECTED';

const getJobsActivityPending = () => ({
  type: GET_JOBS_ACTIVITY_PENDING,
});

const getJobsActivityFailure = (error) => ({
  type: GET_JOBS_ACTIVITY_REJECTED,
  payload: { error },
});

const getJobsActivitySuccess = (data, post) => ({
  type: GET_JOBS_ACTIVITY_FULFILLED,
  payload: data,
});

export const getJobsActivity = (payload) => {
  return (dispatch) => {
    dispatch(getJobsActivityPending());
    return http_noauth
      .post(apiUrl.GET_JOBS_ACTIVITY, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getJobsActivitySuccess(response));
          return response;
        } else {
          dispatch(getJobsActivityFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobsActivityFailure(error));
        return error;
      });
  };
};

export const GET_CLUBS_ACTIVITY_FULFILLED = 'GET_CLUBS_ACTIVITY_FULFILLED';
export const GET_CLUBS_ACTIVITY_PENDING = 'GET_CLUBS_ACTIVITY_PENDING';
export const GET_CLUBS_ACTIVITY_REJECTED = 'GET_CLUBS_ACTIVITY_REJECTED';

const getClubsActivityPending = () => ({
  type: GET_CLUBS_ACTIVITY_PENDING,
});

const getClubsActivityFailure = (error) => ({
  type: GET_CLUBS_ACTIVITY_REJECTED,
  payload: { error },
});

const getClubsActivitySuccess = (data, post) => ({
  type: GET_CLUBS_ACTIVITY_FULFILLED,
  payload: data,
});

export const getClubsActivity = (payload) => {
  return (dispatch) => {
    dispatch(getClubsActivityPending());
    return http
      .post(apiUrl.GET_CLUBS_ACTIVITY, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getClubsActivitySuccess(response));
          return response;
        } else {
          dispatch(getClubsActivityFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getClubsActivityFailure(error));
        return error;
      });
  };
};
export const GET_EVENTS_ACTIVITY_FULFILLED = 'GET_EVENTS_ACTIVITY_FULFILLED';
export const GET_EVENTS_ACTIVITY_PENDING = 'GET_EVENTS_ACTIVITY_PENDING';
export const GET_EVENTS_ACTIVITY_REJECTED = 'GET_EVENTS_ACTIVITY_REJECTED';

const getEventActivityPending = () => ({
  type: GET_EVENTS_ACTIVITY_PENDING,
});

const getEventActivityFailure = (error) => ({
  type: GET_EVENTS_ACTIVITY_REJECTED,
  payload: { error },
});

const getEventActivitySuccess = (data, post) => ({
  type: GET_EVENTS_ACTIVITY_FULFILLED,
  payload: data,
});

export const getEventActivity = (payload) => {
  return (dispatch) => {
    dispatch(getEventActivityPending());
    return http
      .post(apiUrl.GET_EVENTS_ACTIVITY, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getEventActivitySuccess(response));
          return response;
        } else {
          dispatch(getEventActivityFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getEventActivityFailure(error));
        return error;
      });
  };
};
export const GET_CONNECTION_REQUEST_ACTIVITY_FULFILLED =
  'GET_CONNECTION_REQUEST_ACTIVITY_FULFILLED';
export const GET_CONNECTION_REQUEST_ACTIVITY_PENDING =
  'GET_CONNECTION_REQUEST_ACTIVITY_PENDING';
export const GET_CONNECTION_REQUEST_ACTIVITY_REJECTED =
  'GET_CONNECTION_REQUEST_ACTIVITY_REJECTED';

const getConnectionRequestPending = () => ({
  type: GET_CONNECTION_REQUEST_ACTIVITY_PENDING,
});

const getConnectionRequestFailure = (error) => ({
  type: GET_CONNECTION_REQUEST_ACTIVITY_REJECTED,
  payload: { error },
});

const getConnectionRequestSuccess = (data, post) => ({
  type: GET_CONNECTION_REQUEST_ACTIVITY_FULFILLED,
  payload: data,
});

export const getConnectionRequest = (payload) => {
  return (dispatch) => {
    dispatch(getConnectionRequestPending());
    return http
      .post(apiUrl.GET_CONNECTION_REQUEST_ACTIVITY, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getConnectionRequestSuccess(response));
          return response;
        } else {
          dispatch(getConnectionRequestFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getConnectionRequestFailure(error));
        return error;
      });
  };
};

export const GET_BOOKMARK_POST_LIST_FULFILLED =
  'GET_BOOKMARK_POST_LIST_FULFILLED';
export const GET_BOOKMARK_ARTICLE_FULFILLED = 'GET_BOOKMARK_ARTICLE_FULFILLED';

export const GET_BOOKMARK_NEWS_FULFILLED = 'GET_BOOKMARK_NEWS_FULFILLED';
export const GET_BOOKMARK_LIST_PENDING = 'GET_BOOKMARK_LIST_PENDING';
export const GET_BOOKMARK_LIST_REJECTED = 'GET_BOOKMARK_LIST_REJECTED';

const getBookmarkListPending = () => ({
  type: GET_BOOKMARK_LIST_PENDING,
});

const getBookmarkListFailure = (error) => ({
  type: GET_BOOKMARK_LIST_REJECTED,
  payload: { error },
});

const getBookmarkListPostSuccess = (data, payld) => {
  return {
    type: GET_BOOKMARK_POST_LIST_FULFILLED,
    payload: data,
  };
};

export const getBookmarkListPost = (payload) => {
  return (dispatch) => {
    dispatch(getBookmarkListPending());
    return http
      .post(apiUrl.GET_BOOKMARK_LIST, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getBookmarkListPostSuccess(response, payload));
          return response;
        } else {
          dispatch(getBookmarkListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('error', error);
        if (error?.status === -1 && error?.message === 'No Record Found') {
          dispatch(getBookmarkListPostSuccess([], payload));
          return error;
        } else {
          if (error) {
            dispatch(getBookmarkListFailure(error));
            return error;
          }
        }
      });
  };
};

const getBookmarkListArticleSuccess = (data, payld) => {
  return {
    type: GET_BOOKMARK_ARTICLE_FULFILLED,
    payload: data,
  };
};

export const getBookmarkListArticle = (payload) => {
  return (dispatch) => {
    dispatch(getBookmarkListPending());
    return http
      .post(apiUrl.GET_BOOKMARK_LIST, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getBookmarkListArticleSuccess(response, payload));
          return response;
        } else {
          dispatch(getBookmarkListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('error', error);
        if (error?.status === -1 && error?.message === 'No Record Found') {
          dispatch(getBookmarkListArticleSuccess([], payload));
          return error;
        } else {
          if (error) {
            dispatch(getBookmarkListFailure(error));
            return error;
          }
        }
      });
  };
};

export const GET_CLUB_BOOKMARK_LIST_FULFILLED =
  'GET_CLUB_BOOKMARK_LIST_FULFILLED';
export const GET_CLUB_BOOKMARK_LIST_PENDING = 'GET_CLUB_BOOKMARK_LIST_PENDING';
export const GET_CLUB_BOOKMARK_LIST_REJECTED =
  'GET_CLUB_BOOKMARK_LIST_REJECTED';

const getClubBookmarkListPending = () => ({
  type: GET_CLUB_BOOKMARK_LIST_PENDING,
});

const getClubBookmarkListFailure = (error) => ({
  type: GET_CLUB_BOOKMARK_LIST_REJECTED,
  payload: { error },
});

const getClubBookmarkListSuccess = (data, payld) => {
  return {
    type: GET_CLUB_BOOKMARK_LIST_FULFILLED,
    payload: data,
  };
};

export const getBookmarkListClub = (payload) => {
  return (dispatch) => {
    dispatch(getClubBookmarkListPending());
    return http
      .post(apiUrl.GET_BOOKMARK_LIST, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getClubBookmarkListSuccess(response, payload));
          return response;
        } else {
          dispatch(getClubBookmarkListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('error', error);
        if (error?.status === -1 && error?.message === 'No Record Found') {
          dispatch(getClubBookmarkListSuccess([], payload));
          return error;
        } else {
          if (error) {
            dispatch(getClubBookmarkListFailure(error));
            return error;
          }
        }
      });
  };
};

const getBookmarkListNewsSuccess = (data, payld) => {
  return {
    type: GET_BOOKMARK_NEWS_FULFILLED,
    payload: data,
  };
};

export const getBookmarkListNews = (payload) => {
  return (dispatch) => {
    dispatch(getBookmarkListPending());
    return http
      .post(apiUrl.GET_BOOKMARK_LIST, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(getBookmarkListNewsSuccess(response, payload));
          return response;
        } else {
          dispatch(getBookmarkListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        console.log('error', error);
        if (error?.status === -1 && error?.message === 'No Record Found') {
          dispatch(getBookmarkListNewsSuccess([], payload));
          return error;
        } else {
          dispatch(getBookmarkListFailure(error));
          return error;
        }
      });
  };
};
export const GET_NEWS_LIST_FULFILLED = 'GET_NEWS_LIST_FULFILLED';
export const GET_NEWS_LIST_PENDING = 'GET_NEWS_LIST_PENDING';
export const GET_NEWS_LIST_REJECTED = 'GET_NEWS_LIST_REJECTED';

const getNewsListPending = () => ({
  type: GET_NEWS_LIST_PENDING,
});

const getNewsListFailure = (error) => ({
  type: GET_NEWS_LIST_REJECTED,
  payload: { error },
});

const getNewsListSuccess = (data, payld) => ({
  type: GET_NEWS_LIST_FULFILLED,
  payload: { news: data, payld: payld },
});

export const getNewsList = (payload) => {
  return (dispatch) => {
    dispatch(getNewsListPending());
    return http
      .post(apiUrl.NEWS_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getNewsListSuccess(response, payload));
          return response;
        } else {
          dispatch(getNewsListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getNewsListFailure(error));
        return error;
      });
  };
};

export const SAVE_BOOKMARK_FULFILLED = 'SAVE_BOOKMARK_FULFILLED';
export const SAVE_BOOKMARK_PENDING = 'SAVE_BOOKMARK_PENDING';
export const SAVE_BOOKMARK_REJECTED = 'SAVE_BOOKMARK_REJECTED';

const saveBookmarkPending = () => ({
  type: SAVE_BOOKMARK_PENDING,
});

const saveBookmarkFailure = (error) => ({
  type: SAVE_BOOKMARK_REJECTED,
  payload: { error },
});

const saveBookmarkSuccess = (data) => ({
  type: SAVE_BOOKMARK_FULFILLED,
  payload: data,
});

export const saveBookmark = (payload) => {
  return (dispatch) => {
    dispatch(saveBookmarkPending());
    return http
      .post(apiUrl.SAVE_BOOKMARK, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(saveBookmarkSuccess(response));
          showMessage({
            dispatch,
            message: 'Bookmark saved successfully',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(saveBookmarkFailure(response));
          showMessage({
            dispatch,
            message: 'Bookmark Failed',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(saveBookmarkFailure(error));
        showMessage({
          dispatch,
          message: 'Bookmark Failed',
          variant: 'error',
        });
        return error;
      });
  };
};

export const DELETE_BOOKMARK_FULFILLED = 'DELETE_BOOKMARK_FULFILLED';
export const DELETE_BOOKMARK_PENDING = 'DELETE_BOOKMARK_PENDING';
export const DELETE_BOOKMARK_REJECTED = 'DELETE_BOOKMARK_REJECTED';

const deleteBookmarkPending = () => ({
  type: DELETE_BOOKMARK_PENDING,
});

const deleteBookmarkFailure = (error) => ({
  type: DELETE_BOOKMARK_REJECTED,
  payload: { error },
});

const deleteBookmarkSuccess = (data) => ({
  type: DELETE_BOOKMARK_FULFILLED,
  payload: data,
});

export const deleteBookmark = (payload) => {
  return (dispatch) => {
    dispatch(deleteBookmarkPending());
    return http
      .post(apiUrl.DELETE_BOOKMARK, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(deleteBookmarkSuccess(response));
          showMessage({
            dispatch,
            message: 'Bookmark delete successfully',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(deleteBookmarkFailure(response));
          showMessage({
            dispatch,
            message: 'Bookmark Failed',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(deleteBookmarkFailure(error));
        showMessage({
          dispatch,
          message: 'Bookmark Failed',
          variant: 'error',
        });
        return error;
      });
  };
};

export const USER_TRACK_SUCCESS = 'USER_TRACK_SUCCESS';
export const USER_TRACK_REQUEST = 'USER_TRACK_REQUEST';
export const USER_TRACK_FAILURE = 'USER_TRACK_FAILURE';

const userTrackRequest = () => ({
  type: USER_TRACK_REQUEST,
});

const userTrackFailure = (error) => ({
  type: USER_TRACK_FAILURE,
  payload: { error: error },
});

const userTrackSuccess = (data) => ({
  type: USER_TRACK_SUCCESS,
  payload: data,
});

export const userTrack = (payload) => {
  return (dispatch) => {
    dispatch(userTrackRequest());
    return http
      .post(apiUrl.USER_TRACK, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(userTrackSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(userTrackSuccess({ data: [] }));
            return response;
          } else {
            dispatch(userTrackFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(userTrackFailure());
        return error;
      });
  };
};

export const CLOSE_HELP_FEEDBACK = 'CLOSE_HELP_FEEDBACK';
export const ASSIGN_USER_ID = 'ASSIGN_USER_ID';

export const closeHelpFeedback = () => ({
  type: 'CLOSE_HELP_FEEDBACK',
});

export const assignUserId = (data) => {
  return {
    type: 'ASSIGN_USER_ID',
    data: data,
  };
};
