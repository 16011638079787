import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import KeyboardArrowRightIcon from '../../resources/new-img/Right Arrow - White.png';
import KeyboardArrowLeftIcon from '../../resources/new-img/Left Arrow - White.png';
import MaterialIcon, { colorPallet } from 'material-icons-react';
import greenBlueBG from '../../resources/new-img/Testimonilas - BG.png';
import Quote from '../../resources/new-img/Quotes.png';
import Vijaya from '../../resources/new-img/VijaySaiAndra_Pic.jpg';
import Kunal from '../../resources/img/what our member/Kunal.jpg';
import Kailash from '../../resources/img/what our member/Kailash.jpg';
import Dheeraj from '../../resources/new-img/Dhiraj_Agrawal.jpg';
import Anand from '../../resources/new-img/Anand_Swaroop.jpg';
import Rakesh from '../../resources/new-img/Rakesh_Swami.jpg';
import Ajay from '../../resources/new-img/Dr. Ajay Oberoi.jpeg';

var slideIndex = 1;

class WhatOurMembersNew extends Component {
  constructor(props) {
    super();
    this.state = {
      allcardDataT: [
        {
          image: Kailash,
          mark: Quote,
          Description:
            'Looking to network with CXO and generate sales through the business section of LinkCXO',
          Name: 'Kailash Maisekar	',
          Designation:
            'Cavista	General Management - India Delivery Center Set-up ',
        },

        {
          image: Vijaya,
          mark: Quote,
          Description:
            'The app is good and I am sure as they go on improving it, it’ll become even better. The industry reports being published provide good insights in different industries. The app also gives business opportunities and also platform to interact with industry leaders.',
          Name: 'Vijaya Sai Andra,',
          Designation: 'Sr. Technologist at Reliance',
        },
        {
          image: Anand,
          mark: Quote,
          Description:
            'I have used the app and found it to be informative. The news section has been helpful in keeping me updated with the latest updates, also the other things being posted are good.The app is giving me a filtered group of CXOs to connect and Collaborate. I would encourage people to use the app and make it more engaing.',
          Name: 'Anand Swaroop',
          Designation: 'C.O.O at Olectra Greentech Ltd',
        },
        {
          image: Dheeraj,
          mark: Quote,
          Description:
            'I am relatively happy to see the app and in particular I like the club section which encourages like minded users to meet and share the thoughts. As this app is useful for CXO level which is again making this more unique.  All the best team LincCXO for something exclusive.',
          Name: 'Dhiraj Agrawal',
          Designation: 'CEO at Manappuram',
        },
        {
          image: Ajay,
          mark: Quote,
          Description:
            'LinkCXO, a good idea for connecting CXO community, has expanded its scope by sharing high quality relevant content. The news section and AI Chatbot have added more features for insights & search. Wishing LinkCXO a great Success.',
          Name: 'Dr. Ajay Oberoi',
          Designation: 'CEO & MD HRS@TOP',
        },
        {
          image: Rakesh,
          mark: Quote,
          Description:
            'LinkCXO app is quite informative. The jobs section is also good as all the CXO level jobs are posted there, also the other content being posted are quite useful. The idea is all together a new innovative approach and awesome to connect with CXO fraternity.',
          Name: 'Rakesh Swami',
          Designation:
            'Vice President Government Relations at Honeywell International',
        },
      ],
    };
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const CustomRight = ({ onClick }) => (
      <button onClick={onClick} className="custom-Right-Arrow">
        <img
          alt=""
          src={KeyboardArrowRightIcon}
          style={{ width: '35px', height: '35px' }}
        />
      </button>
    );
    const CustomLeft = ({ onClick }) => (
      <button onClick={onClick} className="custom-Left-Arrow">
        <img
          alt=""
          src={KeyboardArrowLeftIcon}
          style={{ width: '35px', height: '35px' }}
        />
      </button>
    );
    return (
      <Container fluid className="nl-what-our-members">
        <Row>
          <Col>
            <h2 className="color-green metrics-engagement">
              What our members have to say
            </h2>
          </Col>
        </Row>

        <Row>
          <Col md={1} xs={12}></Col>
          <Col md={10} xs={12}>
            <Carousel
              customRightArrow={<CustomRight />}
              customLeftArrow={<CustomLeft />}
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={false}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={this.props.deviceType !== 'mobile' ? true : false}
              autoPlaySpeed={6000}
              keyBoardControl={true}
              containerClass="carousel-container"
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              className="main-what-our-member-style-section">
              {this.state.allcardDataT.map((item) => {
                return (
                  <div>
                    {' '}
                    <div
                      className="imageFrame"
                      style={{ marginBottom: '-40px', zIndex: '1000' }}>
                      <img
                        alt=""
                        className="content-over-bg-pic"
                        src={item.image}
                        style={{ zIndex: '1000' }}
                      />{' '}
                    </div>
                    <div
                      className="whats-our-main-card"
                      style={{ zIndex: '999' }}>
                      <div className="content-over-bg">
                        <div className="what-our-img-right">
                          <img alt="" src={item.mark} />
                        </div>
                        <div
                          className="what-our-left"
                          style={{ textAlign: 'center' }}>
                          <h5>
                            {item.Name.length >= 20
                              ? item.Name.substring(0, 20) + '...'
                              : item.Name}
                          </h5>
                          <p>
                            {item.Designation.length >= 120
                              ? item.Designation.substring(0, 120) + '...'
                              : item.Designation}
                          </p>
                        </div>
                        <div className="what-our-description">
                          <p>
                            {item.Description.length >= 150
                              ? item.Description.substring(0, 150) + '...'
                              : item.Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Col>
          <Col md={1} xs={12}></Col>
        </Row>
      </Container>
    );
  }
}

export default WhatOurMembersNew;
