import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import Select from '@mui/material/Select';
import { useForm, Controller } from 'react-hook-form';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Footer from '../../components/HomeHeader/footer';
import Header from "../../components/Header/Header";
import { useSelector, useDispatch } from 'react-redux';

const NotFound = () => {
  const userdata = useSelector((state) => state.auth);

  return (
    <>
    {userdata.loggedIn ?  <Header /> :
      <Navbar />  }
      <Box
        pt={15}
        sx={{
          width: '100%',
          height: '60vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color:"#fff"
        }}>
          Page Not Found
      </Box>

      {userdata.loggedIn ?  <> </> :
       <Footer />  }
     
    </>
  );
};

export default NotFound;
