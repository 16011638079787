import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { withRouter, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MoreIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ProfileShort from '../ProfileShort/ProfileShort';
import { Navigate } from 'react-router-dom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
// import logo from '../../assets/header/Logo.png';
import logo from '../../assets/header/Link_Logo_White.svg';
import newsmalllogo from '../../assets/header/Linkcxo_logo_gold.png';
import chat from '../../assets/header/Chat_White.png';
import credits_gold from '../../assets/header/Credits_Gold.png';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import useStyles from './headerstyle';
import * as searchActions from '../../store/search';
import * as messageActions from '../../store/message';
import * as usernetworkActions from '../../store/usernetwork';
import * as authActions from '../../store/auth';
import * as postActions from '../../store/post';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: 'rgba(111, 106, 106, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(111, 106, 106, 0.25)',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#fff !important',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#fff !important',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [searchTxt, setSearchTxt] = React.useState('');
  const user = useSelector((state) => state?.user?.UserDetails);
  const notificationList = useSelector(
    (state) => state?.message?.notificationList,
  );

  useEffect(async () => {
    const data = {
      user_id: user.id,
      start_item: 0,
      item_count: 20,
    };
    const notification = await dispatch(messageActions.notificationList(data));
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const redirect = async () => {
    navigate('/login');
    const logout = await dispatch(authActions.logoutSuccess());
  };
  const updateTxt = (event) => {
    setSearchTxt(event.target.value);
  };
  const searchAll = async () => {
    if (searchTxt.length > 0) {
      if (location.pathname.includes('/search/')) {
        const data = {
          search_key: searchTxt,
        };
        const searchresult = await dispatch(searchActions.postSearch(data));
      } else {
        navigate('/search/' + searchTxt);
      }
    }
  };
  const loadStart = async () => {
    const data = {
      user_id: user?.id,
      start_item: 0,
      item_count: 10,
    };

    const postlist = await dispatch(postActions.getPostList(data));
    window.scrollTo(0, 0);
  };

  const openFeedback = async () => {
    const hidepost = await dispatch(postActions.openHelpFeedback());
    setAnchorEl(false);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ marginTop: '48px', scrollbarWidth: 'thin' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <Box px={2} pt={1}>
        <Typography variant="h5s" component="h5s">
          Features
        </Typography>
      </Box>
      <MenuList>
        <MenuItem
          onClick={() => {
            navigate('/clubs/owner');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
                marginLeft: '4px',
              }}>
              <Menuicon
                name="clubs"
                color={
                  location.pathname.includes('/clubs') ? '#38CD84' : '#ffffff'
                }
              />
            </Box>
          </ListItemIcon>
          {location.pathname.includes('/clubs') ? (
            <ListItemText sx={{ color: '#38CD84' }}>Clubs</ListItemText>
          ) : (
            <ListItemText sx={{ color: '#ffffff' }}>Clubs</ListItemText>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/events/hosting');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
                marginLeft: '4px',
              }}>
              <Menuicon
                name="events"
                color={
                  location.pathname.includes('/event') ? '#38CD84' : '#ffffff'
                }
              />
            </Box>
          </ListItemIcon>
          {location.pathname.includes('/event') ? (
            <ListItemText sx={{ color: '#38CD84' }}>Events</ListItemText>
          ) : (
            <ListItemText sx={{ color: '#ffffff' }}>Events</ListItemText>
          )}
        </MenuItem>


        <MenuItem
          onClick={() => {
            navigate('/corporate/packagetab/Premium');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
                marginLeft: '0px',
              }}>
                        <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                    }}>
                    <Icon
                      name="corporate"
                      color={
                        location.pathname.includes('/corporate')
                          ? '#38CD84'
                          : '#ffffff'
                      }
                    />
                  </Box>
            </Box>
          </ListItemIcon>
          {location.pathname.includes('/corporate/packagetab/Premium') ? (
            <ListItemText sx={{ color: '#38CD84' }}>Corporate</ListItemText>
          ) : (
            <ListItemText sx={{ color: '#ffffff' }}>Corporate</ListItemText>
          )}
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            navigate('/news');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
                marginLeft: '-2px',
              }}>
              <Icon
                name="news"
                color={
                  location.pathname.includes('/news') ? '#38CD84' : '#ffffff'
                }
              />
            </Box>
          </ListItemIcon>
          {location.pathname.includes('/news') ? (
            <ListItemText sx={{ color: '#38CD84' }}>News</ListItemText>
          ) : (
            <ListItemText sx={{ color: '#ffffff' }}>News</ListItemText>
          )}
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            navigate('/bookmarks');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
              }}>
              {location?.pathname === '/bookmarks' ? (
                <BookmarkBorderOutlinedIcon sx={{ color: '#38CD84' }} />
              ) : (
                <BookmarkBorderOutlinedIcon sx={{ color: '#ffffff' }} />
              )}
            </Box>
          </ListItemIcon>
          {location?.pathname === '/bookmarks' ? (
            <ListItemText sx={{ color: '#38CD84' }}>Bookmarks</ListItemText>
          ) : (
            <ListItemText sx={{ color: '#ffffff' }}>Bookmarks</ListItemText>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/my-survey');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
              }}>
              {location?.pathname === '/my-survey' ? (
                <BookmarkBorderOutlinedIcon sx={{ color: '#38CD84' }} />
              ) : (
                <BookmarkBorderOutlinedIcon sx={{ color: '#ffffff' }} />
              )}
            </Box>
          </ListItemIcon>
          {location?.pathname === '/my-survey' ? (
            <ListItemText sx={{ color: '#38CD84' }}>
              Survey / Query
            </ListItemText>
          ) : (
            <ListItemText sx={{ color: '#ffffff' }}>
              Survey / Query
            </ListItemText>
          )}
        </MenuItem>
      </MenuList>
      <Box px={2} pt={2}>
        <Typography variant="h5s" component="h5s">
          Account
        </Typography>
      </Box>
      <MenuList>
        <MenuItem
          onClick={() => {
            navigate('/settings');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
              }}>
              <Menuicon
                name="settings"
                color={
                  location.pathname === '/settings' ? '#38CD84' : '#ffffff'
                }
              />
            </Box>
          </ListItemIcon>
          {location?.pathname === '/settings' ? (
            <ListItemText sx={{ color: '#38CD84' }}>Settings</ListItemText>
          ) : (
            <ListItemText sx={{ color: '#ffffff' }}>Settings</ListItemText>
          )}
        </MenuItem>

        <MenuItem onClick={openFeedback}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
              }}>
              <Menuicon
                name="helpnfeedback"
                color={
                  location.pathname === '/feedback' ? '#38CD84' : '#ffffff'
                }
              />
            </Box>
          </ListItemIcon>
          <ListItemText>Help & Feedback</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/terms-conditions');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
              }}>
              <Menuicon
                name="privacypolicy"
                color={
                  location.pathname === '/terms-conditions'
                    ? '#38CD84'
                    : '#ffffff'
                }
              />
            </Box>
          </ListItemIcon>
          <ListItemText>Terms & Conditions</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/privacy-policy');
          }}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '18px',
                maxWidth: 'min-content',
                minWidth: '18px',
              }}>
              <Menuicon
                name="privacypolicy"
                color={
                  location.pathname === '/privacy-policy'
                    ? '#38CD84'
                    : '#ffffff'
                }
              />
            </Box>
          </ListItemIcon>
          <ListItemText>Privacy Policy</ListItemText>
        </MenuItem>
        {location.pathname != '/terms-conditions' && (
          <MenuItem onClick={redirect}>
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        )}
      </MenuList>

      <Divider />
      <Box px={2} pt={2}>
        <Typography variant="h6" component="h6">
          Version 1.0.01
        </Typography>
      </Box>
      <Box px={2} py={2}>
        <Typography variant="h6" component="h6">
          © Copyright 2021
        </Typography>
      </Box>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <img alt="logo" className={classes.menuIcon} src={chat} />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={() => {
            navigate('/notifications');
          }}>
          <Badge
            badgeContent={
              notificationList?.length ? notificationList?.length : ''
            }
            color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, scrollbarWidth: 'thin' }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          alignItems: 'center',
        }}>
        <Toolbar
          sx={{
            maxWidth: { xs: '100%', md: '1304px' },
            width: '100%',
          }}>
          <Grid container>
            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{ display: { xs: 'none', md: 'flex' }, paddingTop: { xs: '6px', md: '6px' }, }}
                onClick={loadStart}>
                <Link to="/feed">
                  <img alt="logo" className={classes.logo} src={logo} />
                </Link>
              </Box>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: { xs: '8px', md: 'none' },
                }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ ml: 1 }}>
                  <MenuIcon />
                </IconButton>
                <Link to="/feed">
                  <img alt="logo" className={classes.logo} src={newsmalllogo} />
                </Link>
              </Box>
            </Grid>
            <Grid item xs={9} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}>
                <Box
                  sx={{
                    width: { xs: '200px', md: '360px' },
                    '@media (max-width: 500px)': {
                      marginLeft: '5px',
                    },
                  }}>
                  <Search>
                    <Stack
                      spacing={0}
                      direction="row"
                      justifyContent="space-between">
                      <StyledInputBase
                        placeholder="Explore more..."
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchTxt}
                        onChange={updateTxt}
                        sx={{
                          width: '-moz-available',
                          width: '100%',
                          width: '-webkit-fill-available',
                          color: '#ffffff',
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          searchAll();
                        }}>
                        <SearchIcon sx={{ color: '#959498' }} />
                      </IconButton>
                    </Stack>
                  </Search>
                </Box>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  maxWidth: 'min-content',
                }}>
                <Stack
                  px={2}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  spacing={0}
                  // pl={4}
                  // pr={2}
                  // pt={1.5}
                  // pb={0.5}
                  // spacing={0}
                  // sx={{ cursor: 'pointer' }}
                  // direction="column"
                  onClick={() => {
                    navigate('/network/pending');
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                    }}>
                    <Icon
                      name="networks"
                      color={
                        location.pathname.includes('/network')
                          ? '#38CD84'
                          : '#ffffff'
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant={
                        location.pathname.includes('/network') ? 'h2s' : 'h2'
                      }
                      component={
                        location.pathname.includes('/network') ? 'h2s' : 'h2'
                      }>
                      Networks
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  px={2}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  spacing={0}
                  direction="column"
                  onClick={() => {
                    navigate('/jobs/search');
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                    }}>
                    <Icon
                      name="jobs"
                      color={
                        location.pathname.includes('/job')
                          ? '#38CD84'
                          : '#ffffff'
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant={
                        location.pathname.includes('/job') ? 'h2s' : 'h2'
                      }
                      component={
                        location.pathname.includes('/job') ? 'h2s' : 'h2'
                      }>
                      Jobs
                    </Typography>
                  </Box>
                </Stack>

                {/* <Stack
                  px={2}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  spacing={0}
                  direction="column"
                  onClick={() => {
                    navigate('/events/hosting');
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                    }}>
                    <Menuicon
                      name="events"
                      color={
                        location.pathname.includes('/event')
                          ? '#38CD84'
                          : '#ffffff'
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant={
                        location.pathname.includes('/event') ? 'h2s' : 'h2'
                      }
                      component={
                        location.pathname.includes('/event') ? 'h2s' : 'h2'
                      }>
                      Events
                    </Typography>
                  </Box>
                </Stack> */}

                <Stack
                  px={2}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  spacing={0}
                  // px={1}
                  // py={1}
                  // sx={{
                  //   cursor: 'pointer',
                  //   alignItems: 'center',
                  //   display: 'flex',
                  //   justifyContent: 'center',
                  // }}
                  // spacing={0}
                  direction="column"
                  onClick={() => {
                    navigate('/rewards/membership/details/Basic');
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                      width: '24px',
                    }}>
                    <Icon
                      name="rewards_white"
                      color={
                        location.pathname.includes('/rewards')
                          ? '#38CD84'
                          : '#ffffff'
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant={
                        location.pathname.includes('/reward') ? 'h2s' : 'h2'
                      }
                      component={
                        location.pathname.includes('/reward') ? 'h2s' : 'h2'
                      }>
                      Rewards
                    </Typography>
                  </Box>
                </Stack>

                {/* <Stack
                  // px={2}
                  // py={1}
                  // spacing={0}
                  // sx={{ cursor: 'pointer' }}
                  px={2}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  spacing={0}
                  direction="column"
                  onClick={() => {
                    navigate('/corporate/packagetab/Premium');
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '26px',
                    }}>
                    <Icon
                      name="corporate"
                      color={
                        location.pathname.includes('/corporate')
                          ? '#38CD84'
                          : '#ffffff'
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant={
                        location.pathname.includes('/corporate') ? 'h2s' : 'h2'
                      }
                      component={
                        location.pathname.includes('/corporate') ? 'h2s' : 'h2'
                      }>
                      Corporate
                    </Typography>
                  </Box>
                </Stack> */}

                <Stack
                  px={2}
                  py={1}
                  spacing={0}
                  sx={{ cursor: 'pointer' }}
                  direction="column"
                  onClick={() => {
                    navigate('/news');
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '26px',
                    }}>
                    <Icon
                      name="news"
                      color={
                        location.pathname.includes('/news')
                          ? '#38CD84'
                          : '#ffffff'
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant={
                        location.pathname.includes('/news') ? 'h2s' : 'h2'
                      }
                      component={
                        location.pathname.includes('/news') ? 'h2s' : 'h2'
                      }>
                      News
                    </Typography>
                  </Box>
                </Stack>

                <Box
                  pl={4}
                  pr={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '64px',
                    width: '48px',
                    alignItems: 'center',
                  }}>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    sx={{ height: '60px', width: '60px' }}
                    onClick={() => {
                      navigate('/notifications');
                    }}>
                    <Badge color="secondary">
                      <NotificationsNoneIcon
                        sx={{
                          color:
                            location.pathname === '/notifications'
                              ? '#38CD84'
                              : '',
                          fontSize: 36,
                        }}
                      />
                    </Badge>
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '64px',
                    width: '48px',
                    alignItems: 'center',
                  }}>
                  <IconButton
                    size="small"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    sx={{ height: '48px' }}>
                    <MenuIcon sx={{ fontSize: '48px' }} />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  height: '64px',
                  width: '64px',
                  alignItems: 'center',
                }}>
                <IconButton
                  size="small"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                  sx={{ height: '32px' }}>
                  <MoreIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: 260,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 260,
            boxSizing: 'border-box',
            backgroundColor: '#262525!important',
          },
          display: { xs: 'flex', md: 'none' },
          scrollbarWidth: 'thin',
          paddingTop: '64px',
        }}
        variant="persistent"
        anchor="left"
        open={open}>
        <Divider />
        <Box pt={8}>
          <ProfileShort />
        </Box>
        <List>
          {['Notification'].map((text, index) => (
            <ListItem
              button
              key={text}
              onClick={() => {
                navigate('/notifications');
              }}>
              <ListItemIcon>
                {index % 2 === 0 ? (
                  <IconButton
                    size="small"
                    aria-label="show 17 new notifications"
                    color="inherit">
                    {' '}
                    <Badge
                      badgeContent={
                        notificationList?.length ? notificationList?.length : ''
                      }
                      color="secondary">
                      <NotificationsNoneIcon
                        sx={{
                          color:
                            location.pathname === '/notifications'
                              ? '#38CD84'
                              : '',
                        }}
                      />
                    </Badge>{' '}
                  </IconButton>
                ) : (
                  <MailIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />

        <Box px={2} pt={2}>
          <Typography variant="h5s" component="h5s">
            Account
          </Typography>
        </Box>
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate('/settings');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                }}>
                <Menuicon
                  name="settings"
                  color={
                    location.pathname === '/settings' ? '#38CD84' : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            {location?.pathname === '/settings' ? (
              <ListItemText sx={{ color: '#38CD84' }}>Settings</ListItemText>
            ) : (
              <ListItemText sx={{ color: '#ffffff' }}>Settings</ListItemText>
            )}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/clubs');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                }}>
                <Menuicon
                  name="clubs"
                  color={
                    location.pathname.includes('/clubs') ? '#38CD84' : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            <ListItemText>Clubs</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate('/rewards/membership/details/Basic');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                }}>
                <img
                  alt="creditgold"
                  src={credits_gold}
                  height="24px"
                  width="24px"
                />
              </Box>
            </ListItemIcon>
            <ListItemText>Rewards</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate('/events/hosting');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                }}>
                <Menuicon
                  name="events"
                  color={
                    location.pathname.includes('/event') ? '#38CD84' : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            {location.pathname.includes('/event') ? (
              <ListItemText sx={{ color: '#38CD84' }}>Events</ListItemText>
            ) : (
              <ListItemText sx={{ color: '#ffffff' }}>Events</ListItemText>
            )}
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate('/news');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                  marginLeft: '-4px',
                }}>
                <Icon
                  name="news"
                  color={
                    location.pathname.includes('/news') ? '#38CD84' : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            {location.pathname.includes('/news') ? (
              <ListItemText sx={{ color: '#38CD84' }}>News</ListItemText>
            ) : (
              <ListItemText sx={{ color: '#ffffff' }}>News</ListItemText>
            )}
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate('/corporate/packages');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                  marginLeft: '-4px',
                }}>
                <Icon
                  name="corporate"
                  color={
                    location.pathname.includes('/corporate')
                      ? '#38CD84'
                      : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            {location.pathname.includes('/corporate') ? (
              <ListItemText sx={{ color: '#38CD84' }}>Corporate</ListItemText>
            ) : (
              <ListItemText sx={{ color: '#ffffff' }}>Corporate</ListItemText>
            )}
          </MenuItem>

          <MenuItem onClick={openFeedback}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                }}>
                <Menuicon
                  name="helpnfeedback"
                  color={
                    location.pathname === '/feedback' ? '#38CD84' : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            <ListItemText>Help & Feedback</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/terms-conditions');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                }}>
                <Menuicon
                  name="privacypolicy"
                  color={
                    location.pathname === '/terms-conditions'
                      ? '#38CD84'
                      : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            <ListItemText>Terms & Conditions</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/privacy-policy');
            }}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '18px',
                  maxWidth: 'min-content',
                  minWidth: '18px',
                }}>
                <Menuicon
                  name="privacypolicy"
                  color={
                    location.pathname === '/privacy-policy'
                      ? '#38CD84'
                      : '#ffffff'
                  }
                />
              </Box>
            </ListItemIcon>
            <ListItemText>Privacy Policy</ListItemText>
          </MenuItem>

          <MenuItem onClick={redirect}>
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </MenuList>

        <Divider />
        <Box px={2} pt={2}>
          <Typography variant="h6" component="h6">
            Version 1.0.01
          </Typography>
        </Box>
        <Box px={2} py={2}>
          <Typography variant="h6" component="h6">
            © Copyright 2021
          </Typography>
        </Box>
      </Drawer>

      {renderMenu}
    </Box>
  );
}
