import { client } from "./";
let token =
  localStorage.getItem("User_Details") &&
  JSON.parse(localStorage.getItem("User_Details"))
    ? JSON.parse(localStorage.getItem("User_Details")).token
    : "";

export function getJobList() {
  return (dispatch) => {
    dispatch({
      type: "GET_JOB_LIST",
      payload: client.post("/job/job_list_web", {
        params: {},
        headers: {},
      }),
    });
  };
}

export function getUpcomingEventList() {
  return (dispatch) => {
    dispatch({
      type: "GET_UPCOMING_EVENT_LIST",
      payload: client.post("/web/upcoming_event", {
        params: {},
        headers: {},
      }),
    });
  };
}


export function getPastEventList() {
  return (dispatch) => {
    dispatch({
      type: "GET_PAST_EVENT_LIST",
      payload: client.post("/web/past_event", {
        params: {},
        headers: {},
      }),
    });
  };
}

export function getViewJobData(item) {
  return function (dispatch) {
    dispatch({
      type: "GET_VIEW_JOB_DETATILS_FULFILLED",
      payload: item,
    });
  };
}

// =========================Manage-Jobs============================
export function ManageJobs(data) {
  console.log("data=>", data);
  return (dispatch) => {
    dispatch({
      type: "MANAGE_JOBS_DATA",
      payload: client.post("/job/manage_job", data, {
        params: {},
        headers: { Authorization: "Bearer " + token },
      }),
    });
  };
}

// =========================JobSearchList============================
export function getJobSearchList(user_id, status) {
  return (dispatch) => {
    dispatch({
      type: "GET_JOB_SEARCH_LIST",
      payload: client.post(
        "/job/job_list_web",
        { user_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

// =========================Recommended jobs ============================
export function getRecommendedJobList(user_id) {
  return (dispatch) => {
    dispatch({
      type: "GET_JOB_RECOMMENDED_LIST",
      payload: client.post(
        "/job/recc_job_list",
        { user_id },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

export function RecommendationjobsDescription(item) {
  console.log("RecommendationjobsDescription", item);
  return function (dispatch) {
    dispatch({
      type: "GET_VIEW_JOB_DESCRIPTION_FULFILLED",
      payload: item,
    });
  };
}

// ===============jobs-Details============================================
export function GetJobsDetails(job_id) {
  return (dispatch) => {
    dispatch({
      type: "GET_JOB_DETAILS_LIST",
      payload: client.post(
        "/job/job_details",
        { job_id },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}
// =========================manage jobs status============================

export function ManageJobStatus(
  user_id,
  cand_id,
  status,
  job_id,
  datetime,
  country_code,
  mobile,
  email,
  message
) {
  return (dispatch) => {
    dispatch({
      type: "MANAGE_JOB_STATUS",
      payload: client.post(
        "/job/manage_job_status",
        {
          user_id,
          cand_id,
          status,
          job_id,
          datetime,
          country_code,
          mobile,
          email,
          message,
        },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}
//===============================================FilterJob=====================================================================//

export function FilterJobList(data) {
  console.log("filterjob", data);
  return (dispatch) => {
    dispatch({
      type: "GET_JOB_RECOMMENDED_LIST",
      payload: client.post("/job/filter_job", data, {
        params: {},
        headers: { Authorization: "Bearer " + token },
      }),
    });
  };
}

// ======================== List of Jobs By status=============================

export function GetSaveJobsList(cand_id, status) {
  return (dispatch) => {
    dispatch({
      type: "GET_JOB_SAVED_LIST",
      payload: client.post(
        "/job/job_list_by_status",
        { cand_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

export function GetAppliedJobsList(cand_id, status) {
  return (dispatch) => {
    dispatch({
      type: "GET_JOB_APPLIED_LIST",
      payload: client.post(
        "/job/job_list_by_status",
        { cand_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

// ====================Hired jobs list ==========================================

export function getHiredJobList(user_id, status, hired_by) {
  return (dispatch) => {
    dispatch({
      type: "GET_HIRED_JOB_LIST",
      payload: client.post(
        "/job/job_list",
        { user_id, status, hired_by },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

export function getInactiveJobList(user_id, status, hired_by) {
  return (dispatch) => {
    dispatch({
      type: "GET_INACTIVE_JOB_LIST",
      payload: client.post(
        "/job/job_list",
        { user_id, status, hired_by },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

export function getClosedJobList(user_id, status, hired_by) {
  return (dispatch) => {
    dispatch({
      type: "GET_CLOSED_JOB_LIST",
      payload: client.post(
        "/job/job_list",
        { user_id, status, hired_by },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

// ==============Change_Job-Status==================================

export function ChangeJobStatus(job_id, status) {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_JOB_STATUS",
      payload: client.post(
        "/job/change_job_status",
        { job_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}
// -Activate-jobs
export function ActivateJobStatus(job_id, status) {
  return (dispatch) => {
    dispatch({
      type: "ACTIVATE_JOB_STATUS",
      payload: client.post(
        "/job/change_job_status",
        { job_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}
//-Close -jobs
export function CloseJobStatus(job_id, status) {
  return (dispatch) => {
    dispatch({
      type: "CLOSE_JOB_STATUS",
      payload: client.post(
        "/job/change_job_status",
        { job_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}
//--------------similar-jobs-list-------------------------------------------
export function GetSimilarJobList(job_id, user_id) {
  return (dispatch) => {
    dispatch({
      type: "SIMILAR_JOBS_LIST",
      payload: client.post(
        "/job/similar_job_list",
        { job_id, user_id },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

//--------------Candidate-list-------------------------------------------
export function GetAppliedCandidatelist(job_id, status) {
  return (dispatch) => {
    dispatch({
      type: "APPLIED_CANDIDATE_LIST",
      payload: client.post(
        "/job/candidate_list",
        { job_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

export function GetRejectedCandidatelist(
  job_id,
  status,
  user_id,
  cand_id,
  datetime
) {
  return (dispatch) => {
    dispatch({
      type: "REJECTED_CANDIDATE_LIST",
      payload: client.post(
        "/job/candidate_list",
        { job_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
    dispatch(RejectCandidate(user_id, job_id, cand_id, datetime));
  };
}

export function GetShortlistCandidatelist(job_id, status) {
  return (dispatch) => {
    dispatch({
      type: "SHORTLIST_CANDIDATE_LIST",
      payload: client.post(
        "/job/candidate_list",
        { job_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

export function GetHoldCandidatelist(job_id, status) {
  return (dispatch) => {
    dispatch({
      type: "HOLD_CANDIDATE_LIST",
      payload: client.post(
        "/job/candidate_list",
        { job_id, status },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

//------------Shortlist condidate==============================
export function ShortlistCandidate(user_id, job_id, cand_id, datetime) {
  return (dispatch) => {
    dispatch({
      type: "SHORTLIST_CANDIDATE",
      payload: client.post(
        "/job/candidate_shortlist",
        { user_id, job_id, cand_id, datetime },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

//------------Reject condidate==============================
export function RejectCandidate(user_id, job_id, cand_id, datetime) {
  return (dispatch) => {
    dispatch({
      type: "REJECTED_CANDIDATE",
      payload: client.post(
        "/job/candidate_reject",
        { user_id, job_id, cand_id, datetime },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

//------------Hold condidate==============================
export function HoldCandidate(user_id, job_id, cand_id, datetime) {
  return (dispatch) => {
    dispatch({
      type: "HOLD_CANDIDATE",
      payload: client.post(
        "/job/candidate_hold",
        { user_id, job_id, cand_id, datetime },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

//------------Undo condidate==============================
export function UndoCandidate(user_id, job_id, cand_id, datetime) {
  return (dispatch) => {
    dispatch({
      type: "UNDO_CANDIDATE",
      payload: client.post(
        "/job/candidate_hold",
        { user_id, job_id, cand_id, datetime },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

// ============Apply-jobs==========================================
export function ApplyJobsSenddata(data) {
  console.log("apply-jobs=>", data);
  return (dispatch) => {
    dispatch({
      type: "APPLY_JOBS",
      payload: client.post(
        "/job/manage_job_status",
        { data },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

// ============Job_count-jobs==========================================
export function JobCount(job_id) {
  return (dispatch) => {
    dispatch({
      type: "JOBS_COUNT",
      payload: client.post(
        "/job/job_count",
        { job_id },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}

// ============ job status count-----open inactive closed==========================================
export function JobStatusCount(user_id) {
  console.log("uerrrrid=>", user_id);
  return (dispatch) => {
    dispatch({
      type: "JOBS_STATUS_COUNT",
      payload: client.post(
        "/job/job_status_count",
        { user_id },
        {
          params: {},
          headers: { Authorization: "Bearer " + token },
        }
      ),
    });
  };
}
//=============Message to recruiter=======================================
export function SendmessagetoRecuirter(data) {
  return (dispatch) => {
    dispatch({
      type: "SEND_MESSAGE_TO_RECRUITER",
      payload: client.post("/job/send_message", data, {
        params: {},
        headers: { Authorization: "Bearer " + token },
      }),
    });
  };
}

//============update-message===============================
export function updateMessage(item) {
  return function (dispatch) {
    dispatch({
      type: "UPDATE_MESSAGE_FULFILLED",
      payload: item,
    });
  };
}

// ===============Update-Jobs-Details==========================

export function GetDynamicJobFormData(field, value) {
  return (dispatch) => {
    dispatch({
      type: "GET_DYNAMIC_JOB_FORMDATA",
      payload: { field, value },
    });
  };
}
