import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchBox from './SearchBox';
import SearchIcon from '@mui/icons-material/Search';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import FormControl from '@mui/material/FormControl';
import { useForm, Controller } from 'react-hook-form';
import Backdrop from '@mui/material/Backdrop';
import CxoButton from '../../components/CxoButton/CxoButton';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as jobsActions from '../../store/jobs';
import * as onboardActions from '../../store/onboard';
import * as messageActions from '../../store/message';

import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import InputLabel from '@mui/material/InputLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';

const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <StyledTab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const SearchHeader = (props) => {
  const classes = useStyles();
  let params = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(4);
  const [type, setType] = React.useState('All');
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [searchbox, setSearchbox] = React.useState(false);
  const [showfilter, setShowfilter] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchTxt, setSearchTxt] = React.useState('');
  const [minArray, setMinArray] = useState([]);
  const [minyear, setMinYear] = useState('');
  const [maxyear, setMaxYear] = useState('');
  const [industrylst, setIndustrylst] = useState('');
  const [functionlst, setFunctionlst] = useState('');
  const [minArrayValue, setMinArrayValue] = useState([
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  ]);
  const [maxArrayValue, setMaxArrayValue] = useState([
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  ]);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const [minSalary, setMinSalary] = useState('');
  const [maxSalary, setMaxSalary] = useState('');
  const [minSalaryValue, setMinSalaryValue] = useState([
    'open',
    'not_disclosed',
    50,
    60,
    70,
    80,
    90,
    100,
    150,
    200,
  ]);
  const [maxSalaryValue, setMaxSalaryValue] = useState([
    'open',
    'not_disclosed',
    50,
    60,
    70,
    80,
    90,
    100,
    150,
    200,
  ]);
  const user = useSelector((state) => state?.user?.UserDetails);
  const searchresult = useSelector((state) => state?.search?.SearchList);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);
  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);

  useEffect(async () => {
    if (location.pathname === '/jobs/search') {
      setValue(0);
    } else if (location.pathname === '/jobs/applied') {
      setValue(0);
    } else if (location.pathname === '/jobs/saved') {
      setValue(0);
    } else if (location.pathname === '/jobs/hire') {
      setValue(1);
    } else if (location.pathname === '/jobs/inactive') {
      setValue(1);
    } else if (location.pathname === '/jobs/closed') {
      setValue(1);
    } else if (location.pathname === '/jobs/hire') {
      setValue(1);
    } else if (location.pathname === '/jobs/hire') {
      setValue(1);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (location.pathname.includes('/job/searchresult')) {
        setSearchbox(true);

        const keywords = searchParams.get('keywords');
        const pageno = searchParams.get('pageno');
        const data = {
          search_key: keywords,
          page_no: pageno,
          user_id: user?.id,
        };
        console.log('searchParams', data);
        const searchresult = await dispatch(jobsActions.getJobSearch(data));
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchFilter() {
      if (location.pathname.includes('/job/filterresult')) {
        let keywords = 'location=' + searchParams.get('location');

        const pageno = searchParams.get('pageno');

        let mxsalary = searchParams.get('max_salary');
        let mxyear = searchParams.get('max_experience');
        if (
          searchParams.get('max_salary') === 'open' ||
          searchParams.get('max_salary') === 'not_disclosed'
        ) {
          mxsalary = '';
        }
        if (
          searchParams.get('max_experience') === 'open' ||
          searchParams.get('max_experience') === 'not_disclosed'
        ) {
          mxyear = '';
        }
        const datapost = {
          location: searchParams.get('location'),
          min_experience: searchParams.get('min_experience'),
          max_experience: mxyear,
          min_salary: searchParams.get('min_salary'),
          max_salary: mxsalary,
          job_type: '',
          industry_id: searchParams.get('industry_id'),
          function_id: searchParams.get('function_id'),
          page_no: 0,
          user_id: user?.id,
        };

        const searchresult = await dispatch(jobsActions.getJobFilter(datapost));
      }
    }
    fetchFilter();
  }, []);

  const createJob = async (e) => {
    console.log('createJob');
    e.stopPropagation();

    if (user?.isVerified) {
      navigate('/job/create/');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const resetFields = () => {
    reset();
    setIndustrylst('');
    setFunctionlst('');
    setMinSalary('');
    setMaxSalary('');
    setMaxYear('');
    setMinYear('');
  };

  const handleChange = async (event, newValue) => {
    console.log('newValue', newValue);

    if (user?.isVerified) {
      if (newValue === 0) {
        navigate('/jobs/search');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/jobs/hire');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const toogleSearch = () => {
    setSearchbox(!searchbox);
  };
  const showFilter = () => {
    console.log('showFilter');
    setShowfilter(!showfilter);
  };

  const handleChangeIdustry = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrylst(value);
  };

  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(value);
  };

  const handleSubmitform = async (data) => {
    console.log(
      'handleSubmitform',
      data,
      industrylst,
      functionlst,
      minyear,
      maxyear,
      minSalary,
      maxSalary,
    );
    let keywords =
      'location=' +
      data?.location +
      '&min_experience=' +
      minyear +
      '&max_experience=' +
      maxyear +
      '&min_salary=' +
      minSalary +
      '&max_salary=' +
      maxSalary +
      '&industry_id=' +
      industrylst +
      '&function_id=' +
      functionlst;

    if (
      data?.location?.length > 0 ||
      minyear > 0 ||
      maxyear > 0 ||
      minSalary > 0 ||
      maxSalary > 0 ||
      industrylst ||
      functionlst
    ) {
      if (location.pathname.includes('/job/filterresult')) {
        let mxsalary = maxSalary;
        let mxyear = maxyear;
        if (maxSalary === 'open' || maxSalary === 'not_disclosed') {
          mxsalary = '';
        }
        if (maxyear === 'open' || maxyear === 'not_disclosed') {
          mxyear = '';
        }
        const datapost = {
          location: data?.location,
          min_experience: minyear,
          max_experience: mxyear,
          min_salary: minSalary,
          max_salary: mxsalary,
          job_type: '',
          industry_id: industrylst,
          function_id: functionlst,
          user_id: user?.id,
          page_no: 0,
        };

        const searchresult = await dispatch(jobsActions.getJobFilter(datapost));
        navigate('/job/filterresult?' + keywords + '&pageno=0');
        setShowfilter(false);
      } else {
        navigate('/job/filterresult?' + keywords + '&pageno=0');
      }
    }
  };

  const minYearChange = (event) => {
    setMinYear(event.target.value);
    let temp = [];
    for (let i = event.target.value + 1; i < 25; i++) {
      temp.push(i);
    }
    setMaxArrayValue(temp);
  };
  const maxYearChange = (event) => {
    setMaxYear(event.target.value);
  };

  const minSalaryChange = (event) => {
    console.log('minSalaryChange', event.target.value);

    setMinSalary(event.target.value);
    let temp = [];
    if (event.target.value < 100) {
      temp.push('open');
      temp.push('not_disclosed');
      for (let i = event.target.value + 10; i < 110; i = i + 10) {
        temp.push(i);
      }
      temp.push(150);
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 100) {
      temp.push('open');
      temp.push('not_disclosed');
      temp.push(150);
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 150) {
      temp.push('open');
      temp.push('not_disclosed');
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 200) {
      temp.push('open');
      temp.push('not_disclosed');
      setMaxSalaryValue(temp);
    } else {
      setMaxSalaryValue(temp);
    }
  };

  const maxSalaryChange = (event) => {
    setMaxSalary(event.target.value);
  };

  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          position: 'fixed',
          zIndex: '1001',
        }}>
        <Backdrop sx={{ color: '#fff', zIndex: '2001' }} open={showfilter}>
          <Box px={0}>
            <Box pt={2} sx={{ width: '100%' }}>
              <Paper
                sx={{
                  backgroundColor: '#232121',
                  backgroundImage: 'none',
                  overflowY: 'auto',
                }}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={0}>
                  <Box pt={2} pr={2}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                      }}
                      onClick={showFilter}
                    />
                  </Box>
                </Stack>

                <form onSubmit={handleSubmit(handleSubmitform)}>
                  <Box px={4} pt={0} sx={{ width: '100%' }}>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={0}>
                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                  marginTop: '-30px',
                                },
                              }}>
                              <Menuicon name="salary" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>
                        <Stack direction="column" sx={{ width: '100%' }}>
                          <Box
                            pl={1}
                            sx={{
                              '@media (max-width: 500px)': {
                                marginTop: '10px',
                              },
                            }}>
                            <Typography variant="h6s" component="h6s">
                              Salary (INR)
                            </Typography>
                          </Box>
                          <Stack direction="row" sx={{ width: '100%' }}>
                            <Box
                              py={{ md: 1, xs: 0, sm: 1 }}
                              pl={1}
                              pr={1}
                              sx={{ width: '96%' }}>
                              <FormControl variant="standard" fullWidth>
                                <InputLabel
                                  style={{ color: '#38CD84' }}
                                  id="demo-simple-select-label">
                                  Min
                                </InputLabel>
                                <CssSelect
                                  MenuProps={{
                                    disablePadding: true,
                                    // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                    PaperProps: {
                                      style: {
                                        maxHeight:
                                          ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                        width: 150,
                                      },
                                    },
                                    variant: 'menu',
                                    getContentAnchorEl: null,
                                  }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Function"
                                  className={classes.underline}
                                  value={minSalary}
                                  onChange={minSalaryChange}
                                  size="small">
                                  {minSalaryValue
                                    ? minSalaryValue.map((item, key) => {
                                        if (item < 100) {
                                          return (
                                            <MenuItem key={key} value={item}>
                                              {item} lacs
                                            </MenuItem>
                                          );
                                        } else if (item >= 100) {
                                          return (
                                            <MenuItem key={key} value={item}>
                                              {item / 100} Cr
                                            </MenuItem>
                                          );
                                        }
                                      })
                                    : ''}
                                </CssSelect>
                              </FormControl>
                            </Box>
                            <Box
                              py={{ md: 1, xs: 0, sm: 1 }}
                              pl={2}
                              sx={{ width: '96%' }}>
                              <FormControl variant="standard" fullWidth>
                                <InputLabel
                                  style={{ color: '#38CD84' }}
                                  id="demo-simple-select-label">
                                  Max
                                </InputLabel>

                                {maxSalaryValue?.length === 0 ? (
                                  <CssSelect
                                    MenuProps={{
                                      disablePadding: true,
                                      // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                      PaperProps: {
                                        style: {
                                          maxHeight:
                                            ITEM_HEIGHT * 4.5 +
                                            ITEM_PADDING_TOP,
                                          width: 150,
                                        },
                                      },
                                      variant: 'menu',
                                      getContentAnchorEl: null,
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Function"
                                    className={classes.underline}
                                    value={maxSalary}
                                    onChange={maxSalaryChange}
                                    size="small">
                                    {' '}
                                    <MenuItem key="1000" value="open">
                                      Open
                                    </MenuItem>
                                    <MenuItem key="1020" value="not_disclosed">
                                      Not Disclosed
                                    </MenuItem>
                                  </CssSelect>
                                ) : (
                                  <CssSelect
                                    MenuProps={{
                                      disablePadding: true,
                                      // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                      PaperProps: {
                                        style: {
                                          maxHeight:
                                            ITEM_HEIGHT * 4.5 +
                                            ITEM_PADDING_TOP,
                                          width: 150,
                                        },
                                      },
                                      variant: 'menu',
                                      getContentAnchorEl: null,
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Function"
                                    className={classes.underline}
                                    value={maxSalary}
                                    onChange={maxSalaryChange}
                                    size="small">
                                    <MenuItem key="1000" value="open">
                                      Open
                                    </MenuItem>
                                    <MenuItem key="1020" value="not_disclosed">
                                      Not Disclosed
                                    </MenuItem>
                                    {maxSalaryValue
                                      ? maxSalaryValue?.map((item, key) => {
                                          if (item < 100) {
                                            return (
                                              <MenuItem key={key} value={item}>
                                                {item} lacs
                                              </MenuItem>
                                            );
                                          } else if (item >= 100) {
                                            return (
                                              <MenuItem key={key} value={item}>
                                                {item / 100} Cr
                                              </MenuItem>
                                            );
                                          }
                                        })
                                      : ''}
                                  </CssSelect>
                                )}
                              </FormControl>
                            </Box>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="location" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={{ width: '100%' }}>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Stack
                                sx={{
                                  '@media (max-width: 500px)': {
                                    paddingRight: '10px',
                                  },
                                }}>
                                <CxoInputCss
                                  label="Location"
                                  value={value}
                                  err={
                                    errors?.location ? errors?.location : false
                                  }
                                  onChange={onChange}
                                />
                                <Box
                                  sx={{
                                    fontSize: '9px',
                                    height: '10px',
                                    paddingLeft: '8px',
                                  }}>
                                  {errors?.location?.message}{' '}
                                </Box>
                              </Stack>
                            )}
                            name="location"
                            defaultValue=""
                          />
                        </Box>
                      </Stack>

                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="experience" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>
                        <Stack direction="column" sx={{ width: '100%' }}>
                          <Box
                            pl={1}
                            sx={{
                              '@media (max-width: 500px)': {
                                marginTop: '10px',
                              },
                            }}>
                            <Typography variant="h6s" component="h6s">
                              Experience
                            </Typography>
                          </Box>
                          <Stack direction="row" sx={{ width: '100%' }}>
                            <Box
                              py={{ md: 1, xs: 0, sm: 1 }}
                              pl={1}
                              pr={1}
                              sx={{ width: '96%' }}>
                              <FormControl variant="standard" fullWidth>
                                <InputLabel
                                  style={{ color: '#38CD84' }}
                                  id="demo-simple-select-label">
                                  Min
                                </InputLabel>
                                <CssSelect
                                  MenuProps={{
                                    fontSize: '12px',
                                    disablePadding: true,
                                    // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                    PaperProps: {
                                      style: {
                                        maxHeight:
                                          ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                        width: 140,
                                      },
                                    },
                                    variant: 'menu',
                                    getContentAnchorEl: null,
                                  }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Function"
                                  className={classes.underline}
                                  value={minyear}
                                  onChange={minYearChange}
                                  size="small"
                                  // MenuProps={MenuProps}
                                >
                                  {minArrayValue
                                    ? minArrayValue.map((item, key) => {
                                        return (
                                          <MenuItem key={key} value={item}>
                                            {item} years
                                          </MenuItem>
                                        );
                                      })
                                    : ''}
                                </CssSelect>
                              </FormControl>
                            </Box>
                            <Box
                              py={{ md: 1, xs: 0, sm: 1 }}
                              pl={2}
                              sx={{ width: '96%' }}>
                              <FormControl variant="standard" fullWidth>
                                <InputLabel
                                  style={{ color: '#38CD84' }}
                                  id="demo-simple-select-label">
                                  Max
                                </InputLabel>
                                <CssSelect
                                  MenuProps={{
                                    fontSize: '12px',
                                    disablePadding: true,
                                    // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                    PaperProps: {
                                      style: {
                                        maxHeight:
                                          ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                        width: 140,
                                      },
                                    },
                                    variant: 'menu',
                                    getContentAnchorEl: null,
                                  }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Function"
                                  className={classes.underline}
                                  value={maxyear}
                                  onChange={maxYearChange}
                                  size="small"
                                  // MenuProps={MenuProps}
                                >
                                  <MenuItem value="open">Open</MenuItem>
                                  <MenuItem value="not_disclosed">
                                    Not disclosed
                                  </MenuItem>
                                  {maxArrayValue
                                    ? maxArrayValue.map((item, key) => {
                                        return (
                                          <MenuItem key={key} value={item}>
                                            {item} years
                                          </MenuItem>
                                        );
                                      })
                                    : ''}
                                </CssSelect>
                              </FormControl>
                            </Box>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="industry" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>
                        <Box py={1} pl={1} sx={{ width: '96%' }}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel
                              style={{ color: '#38CD84' }}
                              id="demo-simple-select-label">
                              Industry
                            </InputLabel>
                            <CssSelect
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={industrylst}
                              label="Industry List"
                              onChange={handleChangeIdustry}
                              size="small"
                              className={classes.underline}
                              color="secondary"
                              sx={{ color: '#38CD84' }}
                              MenuProps={MenuProps}>
                              {industryList?.data
                                ? industryList?.data.map((item, key) => {
                                    return (
                                      <MenuItem
                                        color="secondary"
                                        key={key}
                                        value={item.id}>
                                        <ListItemText
                                          color="secondary"
                                          primary={
                                            item.title.substring(0, 25) + '...'
                                          }
                                        />
                                      </MenuItem>
                                    );
                                  })
                                : ''}
                            </CssSelect>
                          </FormControl>
                        </Box>
                      </Stack>

                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="functions" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>
                        <Box py={1} pl={1} sx={{ width: '96%' }}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel
                              style={{ color: '#38CD84' }}
                              id="demo-simple-select-label">
                              Function
                            </InputLabel>
                            <CssSelect
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={functionlst}
                              label="Function"
                              className={classes.underline}
                              onChange={functionChange}
                              size="small"
                              sx={{ color: '#38CD84' }}
                              MenuProps={MenuProps}>
                              {functionList?.data
                                ? functionList?.data.map((item, key) => {
                                    return (
                                      <MenuItem key={key} value={item.id}>
                                        <ListItemText
                                          color="secondary"
                                          primary={
                                            item.title.substring(0, 25) + '...'
                                          }
                                        />
                                      </MenuItem>
                                    );
                                  })
                                : ''}
                            </CssSelect>
                          </FormControl>
                        </Box>
                      </Stack>

                      <Stack direction="row" spacing="2">
                        <Box py={2} px={2}>
                          <CxoButton label="Reset" onClick={resetFields} />
                        </Box>
                        <Box py={2} px={2}>
                          <CxoButton label="Apply" />
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </form>
              </Paper>
            </Box>
          </Box>
        </Backdrop>
      </Box>

      <Box px={1} pt={1}>
        <Tabs
          defaultValue={0}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          color="white"
          aria-label="ant example">
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            {searchbox ? (
              <>
                <Box
                  sx={{
                    paddingX: '16px',
                    paddingY: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <SearchBox />
                  <Box onClick={toogleSearch} sx={{ cursor: 'pointer' }}>
                    <CloseIcon />
                  </Box>
                </Box>
              </>
            ) : (
              <StyledTabsList sx={{ padding: '8px 8px' }}>
                <StyledTab>
                  <Stack direction="row" spacing={0.5}>
                    <Box>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                        Search
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 0 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Box>
                  </Stack>
                </StyledTab>
                <StyledTab>
                  <Stack direction="row" spacing={0.5}>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabname}
                      sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                      Hire
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.tabdigit}
                      sx={{
                        color: value === 1 ? '#000000' : '#ffffff',
                      }}></Typography>
                  </Stack>
                </StyledTab>

                <StyledTab>
                  <Stack direction="row" spacing={0.5}>
                    <Box onClick={createJob}>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabname}
                        sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                        Create
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.tabdigit}
                        sx={{
                          color: value === 2 ? '#000000' : '#ffffff',
                        }}></Typography>
                    </Box>
                  </Stack>
                </StyledTab>
                <Box
                  sx={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <Stack
                    direction="row"
                    spacing="1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <Box onClick={toogleSearch} sx={{ cursor: 'pointer' }}>
                      <SearchIcon />
                    </Box>
                    <Box pl={2} onClick={showFilter} sx={{ cursor: 'pointer' }}>
                      <FilterAltOutlinedIcon />
                    </Box>
                  </Stack>
                </Box>
              </StyledTabsList>
            )}
          </Paper>
        </Tabs>
      </Box>
    </Fragment>
  );
};

export default React.memo(SearchHeader);
