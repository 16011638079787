import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
import Backdrop from '@mui/material/Backdrop';
import Tab from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import CxoButtonWithIcon from '../../components/CxoButton/CxoButton';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import * as messageAction from '../../store/message';
import RewardSubHeader from '../../components/RewardSubHeader/RewardSubHeader';
import * as rewardsActions from '../../store/rewards';
import * as userActions from '../../store/user';
import CxoButton from '../../components/CxoButton/CxoButton';
import credits_gold from '../../assets/header/Credits_Gold.png';
import { dateTime } from '../../constants/app';
import * as messageActions from '../../store/message';
import RewardVoucherCard from '../../components/RewardVoucherCard/RewardVoucherCard';

import StarIcon from '@mui/icons-material/Star';
import useRazorpay from 'react-razorpay';
import CloseIcon from '@mui/icons-material/Close';
import config from '../../services/apiConfig';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import moment from 'moment';

import * as activityActions from '../../store/activity';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: 'rgba(111, 106, 106, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(111, 106, 106, 0.25)',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
// }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#fff !important',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#fff !important',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '-2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const RewardMembership = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Razorpay = useRazorpay();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('');
  const [details, setDetails] = React.useState('');
  const [detailsBasic, setDetailsBasic] = React.useState('');
  const [detailsPremium, setDetailsPremium] = React.useState('');
  const [detailsElite, setDetailsElite] = React.useState('');
  const [benefits, setBenefits] = React.useState([]);
  const [benefitsBasic, setBenefitsBasic] = React.useState([]);
  const [benefitsPremium, setBenefitsPremium] = React.useState([]);
  const [membershipDetails, setMembershipDetails] = React.useState('');
  const [termdata, setTermdata] = React.useState({});
  const [showconfirmation, setShowConfirmation] = React.useState(false);
  const [showdetails, setShowDetails] = React.useState(false);
  const [addCoupon, setAddCoupon] = React.useState(false);
  const [addcouponShow, setAddCouponShow] = React.useState(false);
  const [currentMembership, setCurrentmembership] = React.useState('');
  const [couponCode, setcouponCode] = React.useState('');
  const [tabNumber, setTabNumber] = useState(3);
  const tempUserId = useSelector((state) => state?.activity?.tempUserId);
  const user = useSelector((state) => state?.user?.UserDetails);
  let param = useParams();
  let location = useLocation();
  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const membershipBenefits = useSelector(
    (state) => state?.rewards?.membershipBenefits,
  );

  const membershipUserDetails = useSelector(
    (state) => state?.rewards?.membershipUserDetails,
  );

  const membershipDetailss = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  const membershipUpgradePrice = useSelector(
    (state) => state?.rewards?.membershipUpgradePrice,
  );

  const membershipList = useSelector((state) => state?.rewards?.membershipList);

  const membership_type = useSelector(
    (state) => state?.user?.userStatus?.membership_type,
  );

  React.useEffect(() => {
    async function postUserTrack() {
      if (location?.search?.includes('campaign')) {
        if (tempUserId == 0 || tempUserId == undefined) {
          const data = {
            tempUserIdo: uuidv4(),
          };
          const assign = await dispatch(activityActions.assignUserId(data));
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: data?.tempUserIdo,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        } else {
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: tempUserId,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        }
        // navigate('/');
      }
    }
    postUserTrack();
  }, [location]);

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardsCategoryList(),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    const type = params?.type;
    setType(type);
    if (type == 'Basic') {
      setValue(0);
    } else if (type == 'Elite') {
      setValue(2);
    } else if (type == 'Premium') {
      setValue(1);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = params?.type;
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });

      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );

      let newArray = responsebenefits?.data?.reduce((acc, cur) => {
        let { category_id, ...rest } = cur;
        let ex = acc.find((x) => x.category_id === category_id);
        if (!ex) {
          ex = { category_id, value: [] };
          acc.push(ex);
        }
        ex.value.push(rest);
        return acc;
      }, []);

      let newresult = responsebenefits?.data?.reduce(function (r, a) {
        r[a.category_id] = r[a.category_id] || [];
        r[a.category_id].push(a);
        return r;
      }, Object.create(null));
      // setBenefits(newArray);
      setDetails(result);
      const data4 = {
        user_id: user?.id,
        membership_key: result[0]?.id,
      };
      const upgradedprice = await dispatch(
        rewardsActions.rewardUpgradeMemberPrice(data4),
      );
      // console.log('JSON', JSON.parse(result[0]?.details));
      setMembershipDetails(JSON.parse(result[0]?.details));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (user?.membership_type < details[0]?.id) {
        // const data4 = {
        //   user_id: user?.id,
        //   membership_key: details[0]?.id,
        // };
        // const upgradedprice = await dispatch(
        //   rewardsActions.rewardUpgradeMemberPrice(data4),
        // );
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = 'Elite';
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });

      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );
      // let newArray = responsebenefits?.data.reduce((acc, cur) => {
      //   let { category_id, ...rest } = cur;
      //   let ex = acc.find((x) => x.category_id === category_id);
      //   if (!ex) {
      //     ex = { category_id, value: [] };
      //     acc.push(ex);
      //   }
      //   ex.value.push(rest);
      //   return acc;
      // }, []);

      // let newresult = responsebenefits?.data?.reduce(function (r, a) {
      //   r[a.category_id] = r[a.category_id] || [];
      //   r[a.category_id].push(a);
      //   return r;
      // }, Object.create(null));
      // console.log('result', newresult, newArray);
      // setBenefits(newArray);
      setDetailsElite(responsedetails?.data[0]);
      setBenefits(JSON.parse(responsedetails?.data[0].details));
      console.log('detailsElite', detailsElite);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = 'Premium';
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });

      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );
      setDetailsPremium(responsedetails?.data[0]);
      setBenefitsPremium(JSON.parse(responsedetails?.data[0].details));
      console.log('benefits', benefits, detailsPremium);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = 'Basic';
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });

      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );
      setDetailsBasic(responsedetails?.data[0]);
      setBenefitsBasic(JSON.parse(responsedetails?.data[0].details));
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );

    var result = membershipList.filter((obj) => {
      return obj.id === user?.membership_type;
    });
    setCurrentmembership(result);
  }, []);

  const showAddCoupons = () => {
    setAddCoupon(!addCoupon);
  };

  const addCouponShow = () => {
    setAddCouponShow(true);
  };
  const razorPaySuccess = async (res) => {
    // alert("Payment Sucessfully", res);
    let msg = 'Purchase Successfully';
    const sendmessage = await dispatch(
      messageAction.messagefromComponent(msg, 'success'),
    );
    navigate('/rewards/membership');

    console.log('Payment Sucessfully', res);
  };

  const razorPayFailed = (error) => {
    console.log('payment failed', error);
  };

  const upgradeMembership = useCallback(() => {
    axios
      .post(config?.serverBaseUrl + '/create_order_membership', {
        membership_key: details[0]?.id,
        currency: 'INR',
        receipt: 'rec_pewep34',
        notes: 'lang',
        user_id: user?.id,
        membership_status: 'upgrade',
        tag: 'membership',
      })
      .then((res) => {
        let options = {
          key: 'rzp_live_SK60amVrcfAb39', 
          amount: res.data.amount_due,
          currency: 'INR',
          name: 'Linkcxo',
          description: 'Membership',
          image: 'https://stagebucket.blob.core.windows.net/linkcxo/new-test/Link_CXO_Monogram_Dark_Gold.png',
          order_id: res.data.id,
          handler: (res) => {
            console.log('Razorpay', res);
            return razorPaySuccess();
          },
          prefill: {
            name: user?.first_name + ' ' + user?.last_name,
            email: user?.email,
            contact: user?.mobile,
          },
          notes: {
            address: '',
          },
          theme: {
            color: '#38CD84',
          },
        };
        console.log('options', options);
        const rzpay = new Razorpay(options);
        rzpay.on('payment.failed', function (response) {
          return razorPayFailed(response);
        });
        rzpay.open();
      })
      .catch((e) => console.log(e));
  }, [Razorpay, razorPaySuccess, razorPayFailed]);

  const addCoupons = () => {};
  // rzp_live_S9XA2i9Ox4zjp5    rzp_test_0uFcavHKXDrPCV

  const buyMembership = useCallback(() => {
    let membership_key = 1;
    let price = 0;
    if (value == 1) {
      membership_key = 2;
      price =
        detailsPremium?.price -
        detailsPremium?.price * (detailsPremium?.offer_per / 100);
    } else if (value == 2) {
      membership_key = 3;
      price =
        detailsElite?.price -
        detailsElite?.price * (detailsElite?.offer_per / 100);
    }
    axios
      .post(config?.serverBaseUrl + '/create_order_membership', {
        amount: price,
        membership_key: membership_key,
        currency: 'INR',
        receipt: 'rec_pewep34',
        notes: 'lang',
        user_id: user?.id,
        membership_status: 'new',
        tag: 'membership',
      })
      .then((res) => {
        let options = {
          key: 'rzp_live_SK60amVrcfAb39',
          amount: res.data.amount_due,
          currency: 'INR',
          name: 'Linkcxo',
          description: 'Membership',
          image: 'https://stagebucket.blob.core.windows.net/linkcxo/new-test/Link_CXO_Monogram_Dark_Gold.png',
          order_id: res.data.id,
          handler: (res) => {
            console.log('Razorpay', res);
            return razorPaySuccess();
          },
          prefill: {
            name: user?.first_name + ' ' + user?.last_name,
            email: user?.email,
            contact: user?.mobile,
          },
          notes: {
            address: '',
          },
          theme: {
            color: '#38CD84',
          },
        };
        console.log('options', options);
        const rzpay = new Razorpay(options);
        rzpay.on('payment.failed', function (response) {
          return razorPayFailed(response);
        });
        rzpay.open();
      })
      .catch((e) => console.log(e));
  }, [Razorpay, razorPaySuccess, razorPayFailed]);

  // const handleChange = async (event, newValue) => {
  //   if (user?.isVerified) {
  //     if (newValue === 0) {
  //       navigate('/rewards/category/partners');
  //       setValue(newValue);
  //     } else if (newValue === 1) {
  //       navigate('/rewards/my/vouchers');
  //       setValue(newValue);
  //     } else if (newValue === 2) {
  //       navigate('/rewards/membership');
  //       setValue(newValue);
  //     }
  //   } else {
  //     await dispatch(messageActions.openUnderVerification());
  //   }
  // };

  const benefitshow = (benefit) => {
    if (benefit['value']) {
      benefit['value']?.map((value, index) => {
        return <RewardVoucherCard benfits={value} />;
      });
    } else {
      return '';
    }
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     if (location.pathname.includes('/partners')) {
  //       setValue(3);
  //     } else if (location.pathname.includes('/Elite')) {
  //       setValue(2);
  //     } else if (location.pathname.includes('/Premium')) {
  //       setValue(1);
  //     } else if (location.pathname.includes('/Basic')) {
  //       setValue(0);
  //     }
  //   }
  //   fetchData();
  // }, []);

  const handleChange = async (event, newValue) => {
    if (user?.isVerified) {
      if (newValue === 0) {
        navigate('/rewards/membership/details/Basic');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/rewards/membership/details/Premium');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/membership/details/Elite');
        setValue(newValue);
      } else if (newValue === 3) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  async function fetchDataNew(membershp) {
    const response = await dispatch(rewardsActions.rewardMemberList());
    const type = params?.type;
    var result = membershipList.filter((obj) => {
      return obj.type === membershp;
    });

    const data = {
      membership_id: result[0]?.id,
    };
    const responsedetails = await dispatch(
      rewardsActions.rewardMemberDetails(data),
    );
    const responsebenefits = await dispatch(
      rewardsActions.rewardMemberBenefits(data),
    );

    setDetails(responsedetails?.data[0]);
    setBenefits(JSON.parse(responsedetails?.data[0].details));
    console.log('benefits', benefits);
  }

  // const changeTab = async (val) => {
  //   setTabNumber(val);
  //   console.log(val, 'val@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
  //   debugger;
  //   console.log(val,'val')
  //   if (user?.isVerified) {
  //     if (val === 0) {
  //       fetchDataNew('Basic');
  //       navigate('/rewards/membership/details/Basic');
  //       setValue(val);
  //     } else if (val === 1) {
  //       fetchDataNew('Premium');
  //       navigate('/rewards/membership/details/Premium');
  //       setValue(val);
  //     } else if (val === 2) {
  //       fetchDataNew('Elite');
  //       navigate('/rewards/membership/details/Elite');
  //       setValue(val);
  //     } else if (val === 3) {
  //       navigate('/rewards/category/partners');
  //       setValue(val);
  //     }
  //   } else {
  //     await dispatch(messageActions.openUnderVerification());
  //   }
  // };

  const creditsDetails = () => {
    navigate('/rewards/credits');
  };

  const categoryName = (id) => {
    var result = rewardsCategoryList.filter((obj) => {
      return obj.id === id;
    });

    return result[0]?.name;
  };

  const showConfirmation = () => {
    setShowConfirmation(!showconfirmation);
  };

  const showDetails = () => {
    setShowDetails(!showdetails);
  };

  const termAndCondition = async (id) => {
    const data = {
      user_id: user?.id,
      product_id: id,
      datetime: dateTime(),
    };
    const rewardVoucherDetails = await dispatch(
      rewardsActions.rewardsVoucherDetails(data),
    );
    if (rewardVoucherDetails?.status === 1) {
      setTermdata(rewardVoucherDetails?.data);
      setShowConfirmation(!showconfirmation);
    }
  };

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(user?.subscription_id);
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const colorStart = () => {
    console.log('colorStart', details);
    var result = membershipList.filter((obj) => {
      return obj.type === type;
    });
    if (result[0]?.id == 1) {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#38CD84' }}
        />
      );
    } else if (result[0]?.id == 2) {
      return (
        <StarIcon
          fontSize="32px"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#C0C0C0' }}
        />
      );
    } else if (result[0]?.id == 3) {
      return (
        <StarIcon
          fontSize="32px"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#FFC107' }}
        />
      );
    } else if (result[0]?.id == 4) {
      return (
        <StarIcon
          fontSize="32px"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#A0B2C6' }}
        />
      );
    } else {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', color: '#38CD84' }}
        />
      );
    }
  };
  const redirectTocategory = (id) => {
    var result = rewardsCategoryList.filter((obj) => {
      return obj.id === id;
    });

    if (result[0]?.name === 'Real Estate') {
      const path =
        '/rewards/realestate/partners/' + result[0]?.name + '-' + 'lx' + id;
      navigate(path, { state: { category: result[0] } });
    } else {
      const path = '/rewards/partners/' + result[0]?.name + '-' + 'lx' + id;
      navigate(path, { state: { category: result[0]?.name } });
    }
  };

  const noOfDays = () => {
    if (user?.membership_type < details[0]?.id) {
      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();
      return '(' + Math.floor(Math.abs(data)) + ' Days' + ' )';
    }
  };

  const membershipUsed = () => {
    if (user?.membership_type < details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();
      var result = membershipList.filter((obj) => {
        return obj.id === user?.membership_type;
      });

      // let rslt = parseInt(
      //   (result[0]?.price / result[0]?.expiry) * Math.floor(Math.abs(data)),
      // );
      let rslt = Math.abs(
        parseInt(details[0]?.price - result[0]?.price - membershipUpgradePrice),
      );
      return parseFloat(rslt).toFixed(2);
    } else {
      return 0;
    }
  };

  const textinBenefits = () => {
    if (details[0]?.id === 1) {
      let detailshk = JSON.parse(details[0]?.details);
      return (
        <Stack direction="column">
          {detailshk?.map((detail, index) => {
            return (
              <Typography
                key={index}
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '16px',
                }}>
                {detail}
              </Typography>
            );
          })}
        </Stack>
      );
    } else if (details[0]?.id === 2) {
      let detailshk = JSON.parse(details[0]?.details);
      return (
        <Stack direction="column">
          {detailshk?.map((detail, index) => {
            return (
              <Typography
                key={index}
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '16px',
                }}>
                {detail}
              </Typography>
            );
          })}
        </Stack>
      );
    } else if (details[0]?.id === 3) {
      let detailshk = JSON.parse(details[0]?.details);
      return (
        <Stack direction="column">
          {detailshk?.map((detail, index) => {
            return (
              <Typography
                key={index}
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '16px',
                }}>
                {detail}
              </Typography>
            );
          })}
        </Stack>
      );
    }
  };

  const membershipHardPrice = () => {
    if (value === 0) {
      return (
        <Stack direction="row">
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              textDecoration: 'line-through',
            }}>
            $ {parseInt(detailsBasic.price_in_doller)} (₹ {parseInt(detailsBasic.price)}){' '}
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}>
            {' '}
            Complimentary
          </Typography>
        </Stack>
      );
    } else if (value === 1) {
      var dollar = parseInt(detailsPremium?.price_in_doller)
        ? parseInt(detailsPremium?.price_in_doller)
        : 0;

      return (
        <Stack direction="row">
          {detailsPremium?.price_in_doller != NaN && (
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '24px',
                lineHeight: '24px',
                color: '#fff',
                paddingBottom: '16px',
                textDecoration: 'line-through',
              }}>
              $ {dollar} (₹ {parseInt(detailsPremium?.price)}){' '}
            </Typography>
          )}
          {detailsPremium?.price_in_doller != NaN && (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  paddingLeft: '8px',
                }}>
                {' / '}${' '}
                {parseInt(
                  parseInt(detailsPremium?.price_in_doller) -
                    parseInt(detailsPremium?.price_in_doller) *
                      (detailsPremium?.offer_per / 100),
                )}{' '}
                {'('}₹{' '}
                {parseInt(detailsPremium?.price -
                  detailsPremium?.price * (detailsPremium?.offer_per / 100))}
                {')'}
              </Typography>
            </>
          )}
        </Stack>
      );
    } else if (value === 2) {
      return (
        <Stack direction="row">
          {detailsElite?.price_in_doller !== NaN && (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  textDecoration: 'line-through',
                }}>
                $ {parseInt(detailsElite?.price_in_doller)} (₹{' '}
                {parseInt(detailsElite?.price)})
              </Typography>
            </>
          )}
          {detailsElite?.price_in_doller != NaN && (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  paddingLeft: '8px',
                }}>
                {' / '}${' '}
                {parseInt(
                  parseInt(detailsElite?.price_in_doller) -
                    parseInt(detailsElite?.price_in_doller) *
                      (detailsElite?.offer_per / 100),
                )}{' '}
                {'('} ₹{' '}
                {parseInt(detailsElite?.price -
                  detailsElite?.price * (detailsElite?.offer_per / 100))}
                {')'}
              </Typography>
            </>
          )}
        </Stack>
      );
    }
  };
  const membershipPrice = () => {
    if (user?.membership_type < details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();

      var result = membershipList.filter((obj) => {
        return obj.id === membership_type;
      });

      let rslt =
        details[0]?.price -
        parseInt(
          (result[0]?.price / result[0]?.expiry) *
            (result[0]?.expiry - Math.floor(Math.abs(data))),
        );

      return (
        <Stack direction="row">
          {rslt ? (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  textDecoration: 'line-through',
                }}>
                {details[0]?.price?.toLocaleString()
                  ? '₹ ' + details[0]?.price?.toLocaleString()
                  : ''}{' '}
                {'  '}
              </Typography>

              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  paddingLeft: '16px',
                }}>
                {membershipUpgradePrice
                  ? '₹ ' + membershipUpgradePrice?.toLocaleString()
                  : ''}
              </Typography>
            </>
          ) : (
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '24px',
                lineHeight: '24px',
                color: '#fff',
                paddingBottom: '16px',
              }}>
              {details[0]?.price?.toLocaleString()
                ? '₹ ' + details[0]?.price?.toLocaleString()
                : ''}
            </Typography>
          )}
        </Stack>
      );
    } else {
      return (
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '24px',
            lineHeight: '24px',
            color: '#fff',
            paddingBottom: '16px',
          }}>
          ₹ {details[0]?.price?.toLocaleString()}
        </Typography>
      );
    }
    // if (type != 'basic') {
    //   return (
    //     <Typography
    //       variant="h6"
    //       component="h6"
    //       sx={{
    //         fontFamily: 'Open Sans',
    //         fontSize: '24px',
    //         lineHeight: '24px',
    //         color: '#fff',
    //         paddingBottom: '16px',
    //       }}>
    //       ₹ {details[0]?.price?.toLocaleString()}
    //     </Typography>
    //   );
    // } else if (type == 'basic') {
    //   return (
    //     <Box sx={{ display: 'flex', width: '200px' }}>
    //       <Typography
    //         variant="h4"
    //         component="h2"
    //         sx={{
    //           color: '#ffffff',
    //           fontSize: '24px',
    //           lineHeight: '27px',
    //           fontWeight: '400',
    //           fontFamily: 'Open Sans',
    //           textDecoration: 'line-through',
    //         }}>
    //         ₹ {5000?.toLocaleString()}
    //       </Typography>
    //       <Typography
    //         variant="h2"
    //         component="h2"
    //         sx={{
    //           color: '#38CD84',
    //           fontSize: '24px',
    //           lineHeight: '27px',
    //           fontWeight: '600',
    //           fontFamily: 'Open Sans',
    //           marginLeft: '8px',
    //         }}>
    //         Free
    //       </Typography>
    //     </Box>
    //   );
    // }
  };

  const updateCode = (event) => {
    setcouponCode(event.target.value);
  };
  const navigatetoUpgrade = () => {
    if (value == 1) {
      navigate('/rewards/membership/details/Elite');
      setValue(2);
    }
  };

  const membershipBalance = () => {
    if (membership_type < details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();

      console.log('details', data, Math.floor(Math.abs(data)));

      var result = membershipList.filter((obj) => {
        return obj.id === membership_type;
      });

      // let rslt = parseInt(
      //   (result[0]?.price / result[0]?.expiry) *
      //     (result[0]?.expiry - Math.floor(Math.abs(data))),
      // );

      let rslt = parseInt(details[0]?.price - membershipUpgradePrice);

      return parseFloat(rslt).toFixed(2);
    } else {
      return 0;
    }
  };

  const topay = () => {
    if (details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();
      var result = membershipList.filter((obj) => {
        return obj.id === membership_type;
      });

      let rslt =
        details[0]?.price -
        parseInt(
          (result[0]?.price / result[0]?.expiry) *
            (result[0]?.expiry - Math.floor(Math.abs(data))),
        );
      return parseFloat(rslt).toFixed(2);
    }
  };

  const activateCoupons = async () => {
    if (couponCode?.length > 0) {
      const data = {
        membership_id: details[0]?.id,
        user_id: user?.id,
        code: couponCode,
      };
      const activatecoupon = await dispatch(
        rewardsActions.rewardsMembershipApplyCoupon(data),
      );
      if (activatecoupon?.status == 1) {
        const data = {
          user_id: user?.id,
          self_id: user?.id,
          datetime: dateTime(),
        };
        const users = await dispatch(userActions.userProfileDetails(data));
        setAddCoupon(!addCoupon);
        navigate('/rewards');
      }
    }
  };

  const userButton = () => {
    let datev = moment(
      moment
        .utc(
          membershipDetailss[0]?.membership_start_date,
          'YYYY-MM-DDTHH:mm:ssZ',
        )
        .add(membershipDetailss[0]?.membership_expiry, 'days'),
    ).format('ll');

    // // if (user?.membership_type < details[0]?.id && user?.membership_type == 0) {
    //   console.log('logged in user', user, details);
    //  if (user?.membership_type == 0) {
    //    return <CxoButtonWithIcon label="Buy Now" onClick={showAddCoupons} />;
    //  } else if (
    //    user?.membership_type < details[0]?.id
    //   //  && user?.membership_type == 1
    //  ) {
    //    console.log("should show")
    //    return <CxoButtonWithIcon label="Buy Now" onClick={showAddCoupons} />;
    //  } else if (user?.membership_type > details[0]?.id) {
    //    return ""
    //   //  return <CxoButtonDisabled label="Upgrade" onClick={showAddCoupons} />;
    //  } else if (user?.membership_type === details[0]?.id) {
    //    // return <CxoButtonWithIcon label="Buy Now" onClick={showAddCoupons} />;
    //    return <CxoButtonWithIcon label="Upgrade" onClick={showAddCoupons} />;
    //    // <Typography
    //    //   variant="h6"
    //    //   component="h6"
    //    //   sx={{
    //    //     fontFamily: 'Open Sans',
    //    //     fontSize: '24px',
    //    //     lineHeight: '24px',
    //    //     color: '#fff',
    //    //   }}>
    //    //   {/* Valid Till - {datev} */}
    //    // </Typography>
    //  } else {
    //   return ""
    //   //  return <CxoButtonWithIcon label="Upgrade" onClick={showAddCoupons} />;
    //  }
    // // return <CxoButtonWithIcon label="Upgrade" onClick={upgradeMembership} />;

    if (membership_type < value + 1) {
      return (
        <CxoButtonWithIcon label="Purchase Now" onClick={showAddCoupons} />
      );
    } else if (membership_type == value + 1 && value != 2 && value != 0) {
      return <CxoButtonWithIcon label="Upgrade" onClick={navigatetoUpgrade} />;
    } else if (membership_type == value) {
      return '';
    } else if (membership_type > value + 1) {
      return '';
    } else {
      return '';
    }
  };

  const typeMemberColor = () => {
    var result = membershipList.filter((obj) => {
      return obj.type === type;
    });

    if (result[0]?.id == 1) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#38CD84',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else if (result[0]?.id == 2) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#C0C0C0',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else if (result[0]?.id == 3) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#FFC107',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else if (result[0]?.id == 4) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#A0B2C6',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#38CD84',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    }
  };

  const redirectToSection = (id, name) => {
    if (id > 0) {
      navigate('/rewards/partners/' + name + '-lx' + id);
    }
  };

  const backgroundColorReturn = (id) => {
    if (id === 1) {
      return 'rgb(56, 205, 132, 0.5)';
    } else if (id === 2) {
      return 'rgb(218,208,216, 0.5)';
    } else if (id === 3) {
      return 'rgb(251, 188, 4, 0.5)';
    }
  };

  const membeshipTYpe = () => {
    if (value === 1) {
      const price =
        detailsPremium?.price -
        detailsPremium?.price * (detailsPremium?.offer_per / 100);
      return 'Premium';
    } else if (value === 2) {
      const eliteprice =
        detailsElite?.price -
        detailsElite?.price * (detailsElite?.offer_per / 100);
      return 'Elite';
    }
  };

  const valuePrice = () => {
    if (value === 1) {
      const price =
        parseInt(detailsPremium?.price -
        detailsPremium?.price * (detailsPremium?.offer_per / 100));
      return price + ' ';
    } else if (value === 2) {
      const eliteprice =parseInt(
        detailsElite?.price -
        detailsElite?.price * (detailsElite?.offer_per / 100));
      return eliteprice + ' ';
    }
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Stack
                    direction="row"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '48px',
                        maxWidth: 'min-content',
                        width: '48px',
                      }}>
                      <img alt="creditgold" src={credits_gold} />
                    </Box>

                    <Stack
                      direction="row"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '16px',
                      }}>
                      <Box pt={1} pb={0.5} px={1} sx={{ width: '100%' }}>
                        <Typography
                          variant="h2"
                          component="h2"
                          sx={{
                            color: '#F5BD02',
                            fontSize: '20px',
                            lineHeight: '27px',
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                          }}>
                          {rewardsTotalCredits?.credits
                            ? parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()
                            : parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()}
                        </Typography>
                      </Box>
                      <Box pt={0.5} pb={1} px={1}>
                        <Typography variant="h2" component="h2" sx={{}}>
                          Credits
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButton label="Details" onClick={creditsDetails} />
                </Box>
              </Stack>
            </Paper>
            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {showdetails && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showdetails}>
              <Box
                sx={{
                  width: '80%',
                  backgroundColor: '#1c1c1c',
                  borderRadius: '10px',
                  maxWidth: '700px',
                }}>
                <Stack spacing={0} sx={{ width: '100%', maxWidth: '700px' }}>
                  <Fragment>
                    <Stack spacing={0} direction="row">
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '24px',
                          color: '#38CD84',
                          fontWeight: '600',
                        }}>
                        Upgrade Membership
                      </Box>
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={showDetails}
                        />
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="column" spacing={1}>
                          <Typography variant="h2" component="h2">
                            Current Membership Price
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                fontFamily: 'Open Sans',
                                textTransform: 'capitalize',
                              }}>
                              ({' '}
                              {currentMembership[0]?.type +
                                ' ' +
                                'Membership -' +
                                currentMembership[0]?.expiry +
                                'Days'}{' '}
                              )
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {currentMembership[0]?.price}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="column" spacing={1}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Membership Used
                            </Typography>
                            <Stack direction="row" spacing={0.5}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: '#F5BD02',
                                  fontFamily: 'Open Sans',
                                }}></Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '14px',
                                  maxWidth: 'min-content',
                                  width: '14px',
                                  paddingTop: '4px',
                                }}></Box>
                            </Stack>
                          </Stack>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              fontFamily: 'Open Sans',
                            }}>
                            {noOfDays()}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {membershipUsed()}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h2" component="h2">
                            Balance
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}></Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {membershipBalance()}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{ textTransform: 'capitalize' }}>
                            {details[0]?.type + ' '} Membership Price
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}></Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {details[0]?.price}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h2" component="h2">
                            To Pay
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}></Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {membershipUpgradePrice}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ paddingTop: '16px' }}>
                      <Box
                        py={2}
                        px={3}
                        pb={5}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CxoButton
                          label={'Pay ' + membershipUpgradePrice}
                          onClick={upgradeMembership}
                        />
                      </Box>
                    </Stack>
                  </Fragment>
                </Stack>
              </Box>
            </Backdrop>
          </Box>
        )}

        {showconfirmation && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{
                color: '#fff',
                backgroundColor: 'rgb(0 0 0 / 28%)',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={showconfirmation}>
              <Stack
                spacing={0}
                sx={{
                  width: '100%',
                  maxWidth: '700px',
                  backgroundColor: '#333333',
                  borderRadius: '5px',
                }}>
                <Stack spacing={0} direction="row">
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '24px',
                    }}>
                    <Typography variant="h5s" component="h5s">
                      Term and Conditions
                    </Typography>
                  </Box>
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                        cursor: 'pointer',
                      }}
                      onClick={showConfirmation}
                    />
                  </Box>
                </Stack>

                <Box
                  p={5}
                  sx={{
                    width: '100%',
                    backgroundColor: '#333333',
                    borderRadius: '10px',
                    maxWidth: '700px',
                    whiteSpace: 'pre-line',
                    borderRadius: '5px',
                  }}>
                  {termdata?.tnc}
                </Box>
              </Stack>
            </Backdrop>
          </Box>
        )}

        {addCoupon && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{
                color: '#fff',
                backgroundColor: 'rgb(0 0 0 / 28%)',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={addCoupon}>
              <Stack
                spacing={0}
                sx={{
                  width: '100%',
                  maxWidth: '700px',
                  backgroundColor: '#333333',
                  borderRadius: '5px',
                }}>
                <Stack spacing={0} direction="row">
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '24px',
                    }}>
                    <Typography variant="h5s" component="h5s">
                      Purchase Membership
                    </Typography>
                  </Box>
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                        cursor: 'pointer',
                      }}
                      onClick={showAddCoupons}
                    />
                  </Box>
                </Stack>

                <Box
                  p={2}
                  sx={{
                    width: '100%',
                    backgroundColor: '#333333',
                    borderRadius: '10px',
                    maxWidth: '700px',
                    whiteSpace: 'pre-line',
                    borderRadius: '5px',
                  }}></Box>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingTop: '16px' }}>
                  <Box pl={3}>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        variant="h2"
                        component="h2"
                        sx={{ textTransform: 'capitalize' }}>
                        {membeshipTYpe()} Membership Price
                      </Typography>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h2"
                          component="h2"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}></Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '14px',
                            maxWidth: 'min-content',
                            width: '14px',
                            paddingTop: '4px',
                          }}></Box>
                      </Stack>
                    </Stack>
                  </Box>

                  <Box pr={3}>
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        color: '#F5BD02',
                        fontFamily: 'Open Sans',
                      }}>
                      Rs {valuePrice()}
                      {/* {details[0]?.price -
                        details[0]?.price * (details[0]?.offer_per / 100)} */}
                    </Typography>
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingTop: '16px' }}>
                  <Box pl={3}>
                    <Stack direction="row" spacing={2}>
                      <Stack direction="row" spacing={0.5}>
                        {/* {addcouponShow ? (
                          <Box p={1}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                fontFamily: 'Open Sans',
                                cursor: 'pointer',
                              }}>
                              {' '}
                              Coupon Code
                            </Typography>
                          </Box>
                        ) : (
                          <Box onClick={addCouponShow} p={1}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                fontFamily: 'Open Sans',
                                cursor: 'pointer',
                              }}>
                              {' '}
                              Add Coupon
                            </Typography>
                          </Box>
                        )} */}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '14px',
                            maxWidth: 'min-content',
                            width: '14px',
                            paddingTop: '4px',
                          }}>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              fontFamily: 'Open Sans',
                            }}>
                            {' '}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>

                  <Box pr={3}></Box>
                </Stack>
                {/* {addcouponShow ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ paddingTop: '8px' }}>
                    <Box pl={3}>
                      <Stack direction="row" spacing={2}>
                        <Search>
                          <Stack
                            spacing={0}
                            direction="row"
                            justifyContent="space-between">
                            <StyledInputBase
                              placeholder="Coupon Code..."
                              inputProps={{ 'aria-label': 'search' }}
                              value={couponCode}
                              onChange={updateCode}
                              sx={{
                                width: '-moz-available',
                                width: '100%',
                                width: '-webkit-fill-available',
                                color: '#ffffff',
                              }}
                            />
                          </Stack>
                        </Search>
                        <Stack direction="row" spacing={0.5}>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              color: '#F5BD02',
                              fontFamily: 'Open Sans',
                            }}></Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '14px',
                              maxWidth: 'min-content',
                              width: '14px',
                              paddingTop: '4px',
                            }}></Box>
                        </Stack>
                      </Stack>
                    </Box>

                    <Box pr={3}>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          color: '#F5BD02',
                          fontFamily: 'Open Sans',
                        }}></Typography>
                    </Box>
                  </Stack>
                ) : (
                  <></>
                )}
                {couponCode?.length > 0 ? (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <CxoButtonWithIcon
                      label="Activate"
                      onClick={activateCoupons}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <CxoButtonWithIcon label="Buy" onClick={buyMembership} />
                  </Box>
                )} */}

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <CxoButtonWithIcon label="Buy" onClick={buyMembership} />
                </Box>
                <Box pb={4}></Box>
              </Stack>
            </Backdrop>
          </Box>
        )}

        <Box p={1}>
          <Box sx={{ width: '100%' }}>
            <RewardSubHeader />
            {/* <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Membership
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Vouchers
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box> */}
            {/* <RewardTabs /> */}

            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Basic
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Premium
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Elite
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>

                    <StyledTab onClick={() => console.log('a')}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 3 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 3 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>
            <Fragment>
              <Box>
                {value === 2 && (
                  <Box sx={{ width: '100%' }}>
                    <Paper
                      sx={{
                        backgroundColor: '#262525',
                        backgroundImage: 'none',
                      }}>
                      <Grid container>
                        {benefits?.b2c?.map((benefit, index) => {
                          return (
                            <>
                              <Box
                                sx={{
                                  width: '100%',
                                  color: '#fff',
                                  padding: '8px 16px',
                                  cursor: 'pointer',
                                  margin: '2px 8px',
                                  backgroundColor: () =>
                                    backgroundColorReturn(
                                      benefit[Object.keys(benefit)[0]].package,
                                    ),
                                }}
                                onClick={() =>
                                  redirectToSection(
                                    benefit[Object.keys(benefit)[0]]
                                      .category_id,
                                    Object.keys(benefit)[0],
                                  )
                                }>
                                <Stack direction="column" spacing="3">
                                  <Typography
                                    variant="h2s"
                                    component="h2s"
                                    className={classes.tabname}
                                    sx={{
                                      color: '#000000',
                                      fontWeight: '600',
                                      paddingBottom: '4px',
                                    }}>
                                    {Object.keys(benefit)[0]}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    component="h5"
                                    className={classes.tabname}>
                                    {benefit[Object.keys(benefit)[0]].benefits}
                                  </Typography>
                                </Stack>
                              </Box>
                              <Divider />
                            </>
                          );
                        })}
                      </Grid>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          direction: 'row',
                          paddingLeft: '8px',
                          marginTop: '8px',
                          backgroundColor: '#3E3D3D',
                          alignItems: 'center',
                          borderBottomLeftRadius: '10px',
                          borderBottomRightRadius: '10px',
                        }}>
                        {' '}
                        <Stack direction="column">
                          <Box
                            px={2}
                            mb={0}
                            mt={2.5}
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}>
                            {membershipHardPrice()}
                          </Box>

                          <Box
                            px={2}
                            mb={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              className={classes.tabname}
                              sx={{
                                color: '#ffffff',
                                fontWeight: '600',
                                paddingBottom: '4px',
                              }}>
                              ( Validity - 365 days from day of purchase )
                            </Typography>
                          </Box>
                        </Stack>
                        <Box pb={1} pt={0.5} pr={2}>
                          {userButton()}
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                )}
              </Box>

              {value === 0 && (
                <Box sx={{ width: '100%' }}>
                  <Paper
                    sx={{
                      backgroundColor: '#262525',
                      backgroundImage: 'none',
                    }}>
                    <Grid container>
                      {benefitsBasic?.b2c?.map((benefit, index) => {
                        return (
                          <>
                            <Box
                              sx={{
                                width: '100%',
                                color: '#fff',
                                padding: '8px 16px',
                                cursor: 'pointer',
                                margin: '2px 8px',
                                backgroundColor: () =>
                                  backgroundColorReturn(
                                    benefit[Object.keys(benefit)[0]].package,
                                  ),
                              }}
                              onClick={() =>
                                redirectToSection(
                                  benefit[Object.keys(benefit)[0]].category_id,
                                  Object.keys(benefit)[0],
                                )
                              }>
                              <Stack direction="column" spacing="3">
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.tabname}
                                  sx={{
                                    color: '#000000',
                                    fontWeight: '600',
                                    paddingBottom: '4px',
                                  }}>
                                  {Object.keys(benefit)[0]}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  className={classes.tabname}>
                                  {benefit[Object.keys(benefit)[0]].benefits}
                                </Typography>
                              </Stack>
                            </Box>
                            <Divider />
                          </>
                        );
                      })}
                    </Grid>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        direction: 'row',
                        paddingLeft: '8px',
                        marginTop: '8px',
                        backgroundColor: '#3E3D3D',
                        alignItems: 'center',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}>
                      {' '}
                      <Box
                        px={2}
                        mb={1}
                        mt={2.5}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}>
                        {membershipHardPrice()}
                      </Box>
                      <Box pb={1} pt={0.5} pr={2}>
                        {userButton()}
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              )}

              {value === 1 && (
                <Box sx={{ width: '100%' }}>
                  <Paper
                    sx={{
                      backgroundColor: '#262525',
                      backgroundImage: 'none',
                    }}>
                    <Grid container>
                      {benefitsPremium?.b2c?.map((benefit, index) => {
                        return (
                          <>
                            <Box
                              sx={{
                                width: '100%',
                                color: '#fff',
                                padding: '8px 16px',
                                cursor: 'pointer',
                                margin: '2px 8px',
                                backgroundColor: () =>
                                  backgroundColorReturn(
                                    benefit[Object.keys(benefit)[0]].package,
                                  ),
                              }}
                              onClick={() =>
                                redirectToSection(
                                  benefit[Object.keys(benefit)[0]].category_id,
                                  Object.keys(benefit)[0],
                                )
                              }>
                              <Stack direction="column" spacing="3">
                                <Typography
                                  variant="h2s"
                                  component="h2s"
                                  className={classes.tabname}
                                  sx={{
                                    color: '#000000',
                                    fontWeight: '600',
                                    paddingBottom: '4px',
                                  }}>
                                  {Object.keys(benefit)[0]}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  className={classes.tabname}>
                                  {benefit[Object.keys(benefit)[0]].benefits}
                                </Typography>
                              </Stack>
                            </Box>
                            <Divider />
                          </>
                        );
                      })}
                    </Grid>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        direction: 'row',
                        paddingLeft: '8px',
                        marginTop: '8px',
                        backgroundColor: '#3E3D3D',
                        alignItems: 'center',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}>
                      {' '}
                      <Stack direction="column">
                        <Box
                          px={2}
                          mb={0}
                          mt={2.5}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                          }}>
                          {membershipHardPrice()}
                        </Box>

                        <Box
                          px={2}
                          mb={1}
                          mt={0.5}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                          }}>
                          <Typography
                            variant="h2s"
                            component="h2s"
                            className={classes.tabname}
                            sx={{
                              color: '#ffffff',
                              fontWeight: '600',
                              paddingBottom: '4px',
                            }}>
                            ( Validity - 365 days from day of purchase )
                          </Typography>
                        </Box>
                      </Stack>
                      <Box pb={1} pt={0.5} pr={2}>
                        {userButton()}
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              )}
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default RewardMembership;

// {userButton()}
// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab> rzp_test_bbIfwGw718hTLk rzp_live_SK60amVrcfAb39
