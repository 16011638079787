import React, { Fragment, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import * as messageActions from "../../store/message";
import * as postActions from "../../store/post";
import * as jobsActions from "../../store/jobs";
import CxoButton from "../CxoButton/CxoButton";
import Icon from "../../assets/icon";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { dateTime } from "../../constants/app";

export default function ReportJob(props) {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("");
  const user = useSelector((state) => state?.user?.UserDetails);
  const reportjob = useSelector((state) => state?.jobs?.ReportJob);
  const job = useSelector((state) => state?.jobs?.reportjob);

  const handleSubmit = async () => {
    const data = {
      job_id: job?.id,
      user_id: user?.id,
      message: message,
      datetime: dateTime(),
    };
    const submitReport = await dispatch(jobsActions.reportJob(data));

    const snackbar = await dispatch(jobsActions.reportJobClose());
  };

  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const snackbar = await dispatch(jobsActions.reportJobClose());
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  return (
    <Box
      sx={{ width: "100%", height: "100vh", position: "fixed", zIndex: "3001" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={reportjob}
      >
        <Box
          sx={{
            width: "700px",
            height: "280px",
            backgroundColor: "#1c1c1c",
            borderRadius: "10px",
          }}
        >
          <Stack spacing={0} sx={{ width: "100%" }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={0.5}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5s" component="h5s">
                  Report Job
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CloseIcon
                  sx={{ color: "#ffffff", cursor: "pointer", fontSize: "24" }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>

            {/* <Grid container sx={{ padding: "16px" }}></Grid> */}

            <Box
              pt={0.5}
              pl={2}
              m={1}
              sx={{
                zIndex: "1200",
                borderStyle: "solid",
                borderWidth: "thin",
                borderColor: "#343232",
              }}
              className="editor"
            >
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={5}
                maxRows={6}
                value={message}
                onChange={handleChange}
                placeholder="Start Typing Here"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box
              p={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CxoButton label="Submit" onClick={handleSubmit} />
            </Box>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
}
