import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';
import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));

const EditProfile = (props) => {
  const classes = useStyles();
  let params = useParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);
  const [country, setCountry] = useState('240');
  const isindustryList = useSelector((state) => !!state.onboard.industryList);
  const isfunctionList = useSelector((state) => !!state.onboard.functionList);
  const iscountryList = useSelector((state) => !!state.onboard.countryList);
  const countryList = useSelector((state) => state.onboard.countryCodeList);
  const designationList = useSelector((state) => state.onboard.designationList);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(moment(newValue).format('YYYY-MM-DD'));
  };
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const userimage = useSelector((state) => state?.user?.UserProfileImage);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  useEffect( () => {  
    async function fetchUserData() {
      console.log('fetchUserData', moment(new Date()).format('YYYY-MM-DD'))
      const data = {
        user_id: user?.id,
        self_id: user?.id,
        datetime: dateTime(),
      };
      const users = await dispatch(userActions.userProfileDetails(data));
    }
    fetchUserData();
  
  }, []);

  useEffect(async () => {
    const designationlist = await dispatch(onboardActions.getDesignationList());
  }, []);
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);
  useEffect(async () => {
    if (!iscountryList) {
      const countryList = await dispatch(onboardActions.getCountryCodeList());
    }
  }, []);

  useEffect(async () => {
    if (userdetails?.dob) {
      setDatevalue(moment(userdetails?.dob).format('YYYY-MM-DD'));
      
    }else{
      setDatevalue(moment(new Date()).format('YYYY-MM-DD'));
      debugger
    }
  }, []);

  useEffect(async () => {
    if (userdetails?.designation) {
      setDesignationlst(userdetails?.designation);
    }
  }, []);

  const handleChange = (newValue) => {
    setType(newValue);
  };
  const countryChange = (event) => {
    setCountry(event.target.value);
  };
  const designatioChange = (event) => {
    setDesignationlst(event.target.value);
  };
  const userProfileDetails = async () => {
    const data = {
      user_id: user?.id,
      self_id: user?.id,
      datetime: dateTime(),
    };
    const users = await dispatch(userActions.userProfileDetails(data, 'own'));
  };
  const closeEdit = () => {};
  const getPhoto = async (event) => {
    // const formData = new FormData();
    // formData.append("id", user.id);
    // formData.append("image", event.target.files[0]);
    // const addImage = await dispatch(userActions.userProfilePic(formData));
    // console.log(event.target.files[0]);
    setFile(event.target.files[0]);
    setFileUrl(URL.createObjectURL(event.target.files[0]));

    return userProfileDetails();
  };
  const updateProfile = async () => {
    // const userRegister = {
    //   id: user.id,
    //   first_name: "Badal",
    //   last_name: "murmu",
    //   company_name: "Linkcxo",
    //   designation: "Frontend Developer",
    //   location: "Bengaluru",
    //   profile_heading: "Good Morning",
    //   about: "Hello",
    //   blog: "https://badalmurmu.medium.com/",
    //   website: "https://badalmurmu.medium.com/",
    //   edited_datetime: dateTime(),
    //   image: file,
    // };
    // const userprofile = await dispatch(
    //   userActions.userProfileUpdate(userRegister)
    // );
  };

  const handleSubmitform = async (data) => {
    // const userRegister = {
    //   id: user?.id,
    //   first_name: data?.first_name,
    //   last_name: data?.last_name,
    //   company_name: data?.company_name,
    //   designation: designationlst,
    //   location: data?.location,
    //   profile_heading: data?.profile_heading,
    //   about: data?.about,
    //   blog: data?.blog,
    //   website: data?.website,
    //   edited_datetime: dateTime(),
    //   dob: moment(datevalue).format("YYYY-MM-DD"),
    //   image: file,
    // };

    const formData = new FormData();
    formData.append('id', user.id);
    formData.append('first_name', data?.first_name);
    formData.append('last_name', data?.last_name);
    formData.append('company_name', data?.company_name);
    formData.append('designation', designationlst);
    formData.append('location', data?.location);
    formData.append('profile_heading', data?.profile_heading);
    formData.append('about', data?.about);
    formData.append('blog', data?.blog);
    formData.append('website', data?.website);
    formData.append('edited_datetime', dateTime());
    formData.append('dob',null);
    if (file) {
      formData.append('image', file);
    } else {
      formData.append('image', userimage);
    }

    const userprofile = await dispatch(userActions.userProfileUpdate(formData));
    const dataprofile = {
      user_id: user?.id,
      self_id: user?.id,
      datetime: dateTime(),
    };
    const users = await dispatch(userActions.userProfileDetails(dataprofile));
    navigate('/profile');
  };
  return (
    <Fragment>
      <Box p={1}>
        <Box sx={{ width: '100%' }}>
          <Paper
            sx={{
              backgroundColor: '#232121',
              backgroundImage: 'none',
              overflowX: 'auto',
            }}>
            <Box
              sx={{
                height:
                  userdetails?.designation?.length +
                    userdetails?.company_name?.length >
                  30
                    ? '168px'
                    : '148px',
                backgroundColor: '#2c2c2d',
              }}></Box>
            <Stack spacing={1} direction="column">
              <Box
                px={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  transform: ' translateY(-50%)',
                }}>
                <Stack direction="row">
                  {file ? (
                    <Avatar
                      src={userimage ? fileUrl : PersonIcon}
                      sx={{
                        width: 148,
                        height: 148,

                        border: '4px solid #38cd84',
                      }}
                    />
                  ) : (
                    <Avatar
                      src={userimage ? userimage : PersonIcon}
                      sx={{
                        width: 148,
                        height: 148,

                        border: '4px solid #38cd84',
                      }}
                    />
                  )}
                  <Box>
                    <Box
                      sx={{
                        position: 'relative',
                        top: '110px',
                        width: '40px',
                        right: '44px',
                      }}>
                      <label htmlFor="icon-button-file">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '36px',
                            maxWidth: 'min-content',
                            minWidth: '36px',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: '#38cd84',
                            borderRadius: '50%',
                            width: '36px',
                          }}>
                          <Box
                            sx={{
                              width: '3px',
                              height: '20px',
                              width: '20px',
                            }}>
                            <Menuicon name="imageadd" color="#000000" />
                          </Box>
                        </Box>
                      </label>
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={getPhoto}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Box
              px={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center  ',
              }}>
              <form
                onSubmit={handleSubmit(handleSubmitform)}
                style={{
                  width: '100%',
                  paddingLeft: '80px',
                  paddingRight: '80px',
                }}>
                <Box py={1} sx={{ width: '100%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          width: '24px',
                        }}>
                        <Menuicon name="individual" color="#38CD84" />
                      </Box>
                    </Box>
                    <Box sx={{ width: '99%' }}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack>
                            <CxoInput
                              label="First Name"
                              value={value}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: '9px',
                                height: '10px',
                                paddingLeft: '8px',
                              }}>
                              {errors?.first_name?.message}{' '}
                            </Box>
                          </Stack>
                        )}
                        name="first_name"
                        rules={{
                          required: {
                            value: true,
                            message: 'First name is required',
                          },
                          pattern: {
                            value: /^[a-zA-Z. ]+$/,
                            message: 'Only Alphabetical Character Allowded',
                          },
                        }}
                        defaultValue={
                          userdetails?.first_name ? userdetails?.first_name : ''
                        }
                      />
                    </Box>
                    <Box sx={{ width: '99%' }} pr={1}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack direction="column">
                            <CxoInput
                              label="Last Name"
                              value={value}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: '9px',
                                height: '10px',
                                paddingLeft: '8px',
                              }}>
                              {errors?.last_name?.message}{' '}
                            </Box>
                          </Stack>
                        )}
                        name="last_name"
                        rules={{
                          required: {
                            value: true,
                            message: 'Last name is required',
                          },
                          pattern: {
                            value: /^[a-zA-Z. ]+$/,
                            message: 'Only Alphabetical Character Allowded',
                          },
                        }}
                        defaultValue={
                          userdetails?.last_name ? userdetails?.last_name : ''
                        }
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box py={1} sx={{ width: '100%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          width: '24px',
                        }}>
                        <Menuicon name="designation" color="#38CD84" />
                      </Box>
                    </Box>
                    <Box py={1} pl={1} sx={{ width: '100%' }}>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ color: '#38CD84' }}>
                          Designation
                        </InputLabel>
                        <Select
                          MenuProps={{
                            fontSize: '12px',
                            disablePadding: true,
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 250,
                              },
                            },
                            variant: 'menu',
                            getContentAnchorEl: null,
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={designationlst}
                          label="Industry List"
                          onChange={designatioChange}
                          size="small">
                          {designationList?.data
                            ? designationList?.data?.map((item, key) => {
                                return (
                                  <MenuItem key={key} value={item.title}>
                                    <ListItemText
                                      color="secondary"
                                      primary={item.title}
                                    />
                                  </MenuItem>
                                );
                              })
                            : ''}
                        </Select>
                      </FormControl>
                    </Box>
                  </Stack>
                </Box>

                <Box py={1} pr={1} sx={{ textAlign: 'center', width: '99%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="entity" color="#38CD84" />
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CxoInput
                          label="Company"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name="company_name"
                      defaultValue={
                        userdetails?.company_name
                          ? userdetails?.company_name
                          : ''
                      }
                    />
                  </Stack>
                </Box>

                <Box py={1} sx={{ width: '99%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="location" color="#38CD84" />
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CxoInput
                          label="Location"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name="location"
                      defaultValue={
                        userdetails?.location ? userdetails?.location : ''
                      }
                    />
                  </Stack>
                </Box>

                <Box py={1} sx={{ width: '100%' }}>
                  <Stack spacing={2} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="info" color="#38CD84" />
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CssTextField
                          variant="standard"
                          fullWidth
                          multiline
                          rows={1}
                          maxRows={4}
                          value={value}
                          onChange={onChange}
                          color="secondary"
                          label="Profile Headline*"
                        />
                      )}
                      name="profile_heading"
                      defaultValue={
                        userdetails?.profile_heading
                          ? userdetails?.profile_heading
                          : ''
                      }
                    />
                  </Stack>
                </Box>

                <Box py={1} sx={{ width: '100%' }}>
                  <Stack spacing={2} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="bio" color="#38CD84" />
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CssTextField
                          variant="standard"
                          fullWidth
                          multiline
                          rows={3}
                          maxRows={6}
                          value={value}
                          onChange={onChange}
                          color="secondary"
                          label="Profile Summary"
                          sx={{
                            scrollbarWidth: 'none',
                          }}
                        />
                      )}
                      name="about"
                      defaultValue={
                        userdetails?.about ? userdetails?.about : ''
                      }
                    />
                  </Stack>
                </Box>

                <Box py={1} sx={{ width: '99%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="website" color="#38CD84" />
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CxoInput
                          label="Website"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name="website"
                      defaultValue={
                        userdetails?.website ? userdetails?.website : ''
                      }
                    />
                  </Stack>
                </Box>

                <Box py={1} sx={{ width: '99%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="blog" color="#38CD84" />
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CxoInput
                          label="Blog"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name="blog"
                      defaultValue={userdetails?.blog ? userdetails?.blog : ''}
                    />
                  </Stack>
                </Box>

                {/* <Box py={1} sx={{ textAlign: 'center', width: '100%' }}>
                  <Stack spacing={2} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="dob" color="#38CD84" />
                      </Box>
                    </Box>

                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        label="Birthday"
                        value={datevalue}
                        onChange={handledateChange}
                        defaultValue={userdetails?.dob}
                        renderInput={(params) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            sx={{ width: '100%', border: '0px' }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Box> */}

                <Box
                  py={4}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-End',
                  }}>
                  <CxoButton label="Save" type="submit" />
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EditProfile);
