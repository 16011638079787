import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import { alpha } from '@mui/material/styles';

import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';

import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));
const EditSkills = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [skill, setSkill] = useState('');
  const [tempskill, setTempSkill] = useState([]);
  const [deleteskill, setDeleteSkill] = useState([]);
  const [oldskill, setOldSkill] = useState([]);
  const [file, setFile] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(new Date());
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };
  const handleSkill = (event) => {
    setSkill(event.target.value);
  };

  const addSkills = async () => {
    console.log('skill', skill, tempskill);

    const data = {
      id: 0,
      user_id: user?.id,
      title: skill,
    };
    for (let i = 0; i < tempskill.length; i++) {
      const addskills = await dispatch(
        userActions.manageSkill({
          id: 0,
          user_id: user?.id,
          title: tempskill[i],
        }),
      );
    }
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
    const snackbar = await dispatch(userActions.closeUpdateProfile());
    const snackbar2 = await dispatch(userActions.closeUpdateSectionProfile());
    // const data = {
    //   id: 0,
    //   user_id: user?.id,
    //   title: skill,
    // };
    // const addskills = await dispatch(userActions.manageSkill(data));
    // const profiledata = {
    //   id: user?.id,
    // };
    // const usersabout = await dispatch(
    //   userActions.userProfileAbout(profiledata)
    // );
    // const snackbar = await dispatch(userActions.closeUpdateProfile());
  };

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);

  // const searchresult =  dispatch(searchActions.postSearch(data));

  const handleChange = (newValue) => {
    setType(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      console.log('Enter key pressed');
      let temp = tempskill;
      temp.push(skill);
      setTempSkill(temp);
      setSkill('');
    }
  };
  const handleDelete = async (e, skill, type) => {
    if (type === 'new') {
      setTempSkill(tempskill.filter((e) => e !== skill));
    }
    if (type === 'old') {
      console.log('skill', skill);
      const userdelteskill = await dispatch(
        userActions.deleteSkill({ id: skill.id }),
      );
      setOldSkill(oldskill.filter((e) => e !== skill));
    }
  };

  useEffect(() => {
    if (props?.section) {
      setOldSkill(props?.section);
    }
  }, []);

  const closeEdit = () => {};
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const handleSubmitform = async (data) => {
    const useredu = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      award_name: data?.award_name,
      awarded_by: data?.awarded_by,
      details: data?.details,
    };
    const usereducation = await dispatch(userActions.manageRewards(useredu));
    const userdata = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(userdata));
    if (updateSection) {
      const snackbar = await dispatch(userActions.closeUpdateSectionProfile());
      
    }
    if (addNew) {
      const snackbaradd = await dispatch(userActions.closeUpdateProfile());
    }

  };
  return (
    <Fragment>
      <Fragment>
        <Box
          py={4}
          px={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}>
          <Box sx={{ width: '100%' }}>
            <TextField
              variant="standard"
              fullWidth
              value={skill}
              sx={{ backgroundColor: '#262525', padding: '8px' }}
              onKeyDown={handleKeyDown}
              onChange={handleSkill}
              InputProps={{
                disableUnderline: true,
              }}
            />
            <Box pt={1}>
              <Typography variant="h6" component="h6">
                *Press Enter to add skills
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box pl={7} pb={2}>
            {oldskill?.map((skill, index) => {
              return (
                <CssChip
                  key={index}
                  className={classes.chipmain}
                  sx={{ margin: '4px' }}
                  label={skill.title}
                  variant="outlined"
                  onDelete={(e) => handleDelete(e, skill, 'old')}
                />
              );
            })}
          </Box>
          <Box pl={7} pb={0}>
            {tempskill?.map((skill, index) => {
              return (
                <CssChip
                  key={index}
                  className={classes.chipmain}
                  sx={{ margin: '4px' }}
                  label={skill}
                  variant="outlined"
                  onDelete={(e) => handleDelete(e, skill, 'new')}
                />
              );
            })}
          </Box>
        </Box>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <Box
              py={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <CxoButton label="Save" onClick={addSkills} />
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    </Fragment>
  );
};

export default React.memo(EditSkills);
