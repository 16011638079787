import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useStyles from './Jobcardcss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CxoButton from '../CxoButton/CxoButton';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import Share from '../../assets/home/Share_White.png';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menuicon from '../../assets/menuicon';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import config from '../../services/apiConfig';
import Backdrop from '@mui/material/Backdrop';
import { dateTime } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import * as jobsActions from '../../store/jobs';
import * as userActions from '../../store/user';
import * as messageActions from '../../store/message';

export default function JobCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [showconfirmation, setShowConfirmation] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobCount = useSelector((state) => state?.jobs?.jobApplyCount);
  const userAbouts = useSelector((state) => state?.user?.UserAbouts);
  const membership_type = useSelector((state) => state?.user?.userStatus?.membership_type);
  const isVerified = useSelector((state) => state?.user?.userStatus?.membership_type);
  const jobapplied = useSelector((state) => state?.jobs?.JobListApplied);
  const clicks = useSelector((state) => state?.message?.noOfClicks);
  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );
  const userfrom = useSelector(
    (state) => state?.user?.UserDetails?.sign_up_through_page,
  );

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };


  const handleMenuClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  const showConfirmation = () => {
    setShowConfirmation(!showconfirmation);
  };
  const checkNoJob = () => {
    if (membership_type === 1 && jobCount <= 2 ) {
      return true;
    } else if (membership_type === 0 && jobCount <= 2) {
      return true;
    } else if (membership_type === 2 && jobCount <= 8) {
      return true;
    } else if (membership_type === 3) {
      return true;
    } else {
      return false;
    }
  };
  const applyJob = async (e) => {
    e.stopPropagation();
    if (user?.isVerified || (userfrom?.includes('job_apply') && jobCount == 0 )) {
      
      if (Object.keys(userAbouts?.jobPreference).length > 0) {
        if (checkNoJob()) {
          const openapplyjob = await dispatch(
            jobsActions.openApplyJob(props?.joblist),
          );
        } else {
          setShowConfirmation(true);
        }
      } else {
        navigate('/job/preference', { state: { job: props?.joblist } });
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const viewPlans = () => {
    navigate('/rewards/membership/details/Premium');
  };
  const shareJob = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const opensharejob = await dispatch(
        jobsActions.openShareJob(props?.joblist),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const saveJob = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const data = {
        job_id: props?.joblist?.id,
        cand_id: user?.id,
        status: 'saved',
        datetime: dateTime(),
        user_id: user?.id,
      };
      const savejob = await dispatch(jobsActions.postSaveJob(data, 'saved'));
      const datasave = {
        cand_id: user?.id,
        status: 'saved',
      };
      const jobs = await dispatch(
        jobsActions.getJobListByStatusSaved(datasave, datasave.status),
      );
      const datarecomendation = {
        user_id: user.id,
      };
      const jobsreco = await dispatch(
        jobsActions.getRecommendedList(datarecomendation),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const unsaveJob = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const data = {
        job_id: props?.joblist?.id,
        cand_id: user?.id,
        status: 'unsave',
        datetime: dateTime(),
        user_id: props?.joblist?.user_table_pk,
      };
      const savejob = await dispatch(jobsActions.postSaveJobNew(data));
      const datasave = {
        cand_id: user?.id,
        status: 'saved',
      };
      const jobs = await dispatch(
        jobsActions.getJobListByStatusSaved(datasave, datasave.status),
      );
      const datarecomendation = {
        user_id: user.id,
      };
      const jobsreco = await dispatch(
        jobsActions.getRecommendedList(datarecomendation),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const editJob = async (e) => {
    e.stopPropagation();

    if (user?.isVerified) {
      const data = {
        job_id: props?.joblist?.id,
      };
      const jobdetails = await dispatch(jobsActions.getJobDetails(data));
      const editjobs = await dispatch(
        jobsActions.editJobOpen(jobdetails?.data),
      );

      navigate('/job/edit');
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const warningText = () => {
    if (membership_type === 1 || membership_type === 0) {
      return 'You have already applied to 3 jobs with your current plan. Please upgrade your plan to apply for more jobs.';
    } else if (membership_type === 2) {
      return 'You have already applied to 9 jobs with your current plan. Please upgrade your plan to apply for more jobs.';
    }
  };
  const jobDetails = async () => {
    const jobcode = props?.joblist?.job_id + '-' + 'lx' + props?.joblist?.id;

    if (props?.type !== 'closedd') {
      if (location.pathname.includes('/job/details/')) {
        props?.changePanel('panel1');
        const data = {
          job_id: props?.joblist?.id,
        };
        const jobdetails = await dispatch(jobsActions.getJobDetails(data));
        const dataapplied = {
          cand_id: user.id,
          status: 'applied',
        };
        const jobsapplied = await dispatch(
          jobsActions.getJobListByStatusApplied(dataapplied),
        );

        const datasaved = {
          cand_id: user.id,
          status: 'saved',
        };
        const jobsaved = await dispatch(
          jobsActions.getJobListByStatus(datasaved),
        );
          console.log('location', location, window.location.hostname);
        // scroll up
        window.open('https://' + window.location.hostname +  '/job/details/' + jobcode, '_blank');
        console.log('location', location, window.location.hostname);
        // navigate("/job/details/" + jobcode);
        return props?.changePanel('panel1');
      } else {
        window.open('https://' + window.location.hostname + '/job/details/' + jobcode, '_blank');
        // navigate("/job/details/" + jobcode);
        console.log('location', location, window.location.hostname);
      }
    }
  };
  const reportPost = async (e) => {
    e.stopPropagation();
    if (user?.isVerified) {
      const reportpost = await dispatch(
        jobsActions.reportJobOpen(props.joblist),
      );
      setAnchorEl(!anchorEl);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const dropDown = () => {
    if (props?.type === 'hire') {
      return <></>;
    } else if (props?.type === 'inactive') {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={editJob}>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={reportPost}>
              <ListItemText>Report</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    }
  };
  return (
    <>
      {' '}
      {showconfirmation && (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            zIndex: '3001',
          }}>
          <Backdrop
            sx={{
              color: '#fff',
              backgroundColor: 'rgb(0 0 0 / 28%)',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={showconfirmation}>
            <Stack
              spacing={0}
              sx={{
                width: '100%',
                maxWidth: '700px',
                backgroundColor: '#333333',
                borderRadius: '5px',
              }}>
              <Stack spacing={0} direction="row">
                <Box
                  px={3}
                  pt={2}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '24px',
                  }}>
                  <Typography variant="h5s" component="h5s">
                    Job Applied Limit Exceeded
                  </Typography>
                </Box>
                <Box
                  px={3}
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CloseIcon
                    sx={{
                      color: '#ffffff',
                      cursor: 'pointer',
                      fontSize: '24',
                      cursor: 'pointer',
                    }}
                    onClick={showConfirmation}
                  />
                </Box>
              </Stack>

              <Box
                p={5}
                sx={{
                  width: '100%',
                  backgroundColor: '#333333',
                  borderRadius: '10px',
                  maxWidth: '700px',
                  whiteSpace: 'pre-line',
                  justifyContent: 'center',
                  display: 'flex',
                }}>
                {warningText()}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '16px',
                }}>
                <CxoButton onClick={viewPlans} label="View Plans" />
              </Box>
            </Stack>
          </Backdrop>
        </Box>
      )}
      <Card
        sx={{
          backgroundColor: '#262525',
          backgroundImage: 'none',
          position: 'relative',
          width: '100%',
        }}>
        <CardHeader
          sx={{ width: '100%', display: 'flex', alignItem: 'flex-start' }}
          action={
            <Stack
              spacing={1}
              direction="row"
              sx={{ display: 'flex', alignItems: 'center' }}>
              {props?.type === 'hire' && (
                <IconButton aria-label="share" onClick={shareJob}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      alt="Share"
                      className={{ height: '20px' }}
                      src={Share}
                    />
                  </Box>
                </IconButton>
              )}

              {props?.type === 'search' && (
                <IconButton aria-label="share" onClick={shareJob}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      alt="Share"
                      className={{ height: '20px' }}
                      src={Share}
                    />
                  </Box>
                </IconButton>
              )}
              <IconButton aria-label="settings" onClick={handleMobileMenuOpen}>
                {props?.type !== 'closed' && props?.type !== 'hire' && (
                  <MoreVertIcon />
                )}
              </IconButton>
              {anchorEl && props?.type !== 'closed' && (
                <Box sx={{ marginLeft: '0px!important' }}>
                  <Box
                    anchorEl={anchorEl}
                    id="primary-search-account-menu"
                    keepMounted
                    sx={{
                      marginTop: '18px',
                      scrollbarWidth: 'thin',
                      position: 'absolute',
                      backgroundColor: '#252525',
                      height: 'auto',
                      width: '129px',
                      marginLeft: '-129px',
                      zIndex: '1000',
                      boxShadow:
                        '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                      borderRadius: '2px',
                    }}
                    open={anchorEl}
                    onClose={handleMenuClose}>
                    {dropDown()}
                  </Box>
                </Box>
              )}
            </Stack>
          }
          title={
            <Stack
              direction="column"
              spacing={1}
              onClick={jobDetails}
              sx={{ cursor: 'pointer' }}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{
                  fontWeight: 600,
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                    fontWeight: '400',
                  },
                }}>
                {props?.joblist?.title && props?.joblist?.title?.length > 30
                  ? props?.joblist?.title?.slice(0, 30) + '...'
                  : props?.joblist?.title}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  paddingTop: '0px',
                  color: 'rgba(255, 255,255,1)',
                  '@media (max-width: 500px)': {
                    fontSize: '11px',
                    fontWeight: '400',
                  },
                }}>
                {props?.joblist?.is_company_confidential
                  ? 'Confidential'
                  : props?.joblist?.company_name?.slice(0, 50)}
              </Typography>
            </Stack>
          }
          subheader=""
        />

        <CardContent
          sx={{
            width: '100%',
            paddingTop: '4px',
            paddingBottom: '14px!important',
          }}>
          <Stack direction="row" spacing={1}>
            <Stack
              direction="column"
              sx={{
                width: '50%',
                '@media (max-width: 500px)': { width: '70%' },
              }}
              spacing={1}>
              <Box onClick={jobDetails} sx={{ cursor: 'pointer' }}>
                <Stack direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '18px', //
                        maxWidth: 'min-content',
                        minWidth: '18px',
                        '@media (max-width: 500px)': {
                          height: '16px',
                          marginTop: '5px',
                        },
                      }}>
                      <Menuicon name="location" color="#ffffff" />
                    </Box>
                  </Box>
                  <Box py={0.5} px={2}>
                    <Typography variant="h6" component="h6">
                      {props?.joblist?.location &&
                      props?.joblist?.location?.length > 20
                        ? props?.joblist?.location?.substring(0, 20) + '...'
                        : props?.joblist?.location}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Stack direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '18px',
                        maxWidth: 'min-content',
                        minWidth: '18px',

                        '@media (max-width: 500px)': {
                          height: '16px',
                          marginTop: '5px',
                        },
                      }}>
                      <Menuicon name="jobtype" color="#ffffff" />
                    </Box>
                  </Box>
                  <Box py={0.5} px={2}>
                    <Typography variant="h6" component="h6">
                      {props?.joblist?.job_type ? props?.joblist?.job_type : ''}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              {props?.type === 'closed' && (
                <>
                  <Box onClick={jobDetails} sx={{ cursor: 'pointer' }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '14px',
                            maxWidth: 'min-content',
                            minWidth: '14px',
                          }}>
                          <Menuicon name="individual" color="#ffffff" />
                        </Box>
                      </Box>
                      <Box py={0.5} px={2}>
                        <Typography variant="h6" component="h6">
                          {props?.joblist?.applied_count +
                            props?.joblist?.shortlisted_count +
                            props?.joblist?.rejected_count +
                            props?.joblist?.hold_count}{' '}
                          {props?.joblist?.applied_count +
                            props?.joblist?.shortlisted_count +
                            props?.joblist?.rejected_count +
                            props?.joblist?.hold_count >
                          0
                            ? 'Applicants'
                            : 'Applicant'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </>
              )}
              {props?.type === 'inactive' && (
                <>
                  <Box onClick={jobDetails} sx={{ cursor: 'pointer' }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '14px',
                            maxWidth: 'min-content',
                            minWidth: '14px',
                          }}>
                          <Menuicon name="individual" color="#ffffff" />
                        </Box>
                      </Box>
                      <Box py={0.5} px={2}>
                        <Typography variant="h6" component="h6">
                          {props?.joblist?.applied_count +
                            props?.joblist?.shortlisted_count +
                            props?.joblist?.rejected_count +
                            props?.joblist?.hold_count}{' '}
                          {props?.joblist?.applied_count +
                            props?.joblist?.shortlisted_count +
                            props?.joblist?.rejected_count +
                            props?.joblist?.hold_count >
                          0
                            ? 'Applicants'
                            : 'Applicant'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </>
              )}
              {props?.type === 'hire' && (
                <>
                  <Box onClick={jobDetails} sx={{ cursor: 'pointer' }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '14px',
                            maxWidth: 'min-content',
                            minWidth: '14px',
                          }}>
                          <Menuicon name="individual" color="#ffffff" />
                        </Box>
                      </Box>
                      <Box py={0.5} px={2}>
                        <Typography variant="h6" component="h6">
                          {props?.joblist?.applied_count +
                            props?.joblist?.shortlisted_count +
                            props?.joblist?.rejected_count +
                            props?.joblist?.hold_count}{' '}
                          {props?.joblist?.applied_count +
                            props?.joblist?.shortlisted_count +
                            props?.joblist?.rejected_count +
                            props?.joblist?.hold_count >
                          0
                            ? 'Applicants'
                            : 'Applicant'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </>
              )}

              <Box>
                <Stack direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '18px',
                        maxWidth: 'min-content',
                        minWidth: '18px',
                        '@media (max-width: 500px)': {
                          height: '16px',
                          marginTop: '5px',
                        },
                      }}>
                      <Menuicon name="calendar" color="#ffffff" />
                    </Box>
                  </Box>
                  <Box py={0.5} px={2}>
                    <Typography variant="h6" component="h6">
                      Posted on:{' '}
                      {props?.joblist?.posted_on
                        ? moment(props?.joblist?.posted_on).format('D') +
                          ' ' +
                          moment(props?.joblist?.posted_on).format('MMM') +
                          ' ' +
                          moment(props?.joblist?.posted_on).format('YYYY')
                        : ''}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Stack
              direction="column"
              sx={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItem: 'center',
                '@media (max-width: 500px)': { width: '30%' },
              }}
              spacing={1}>
              {props?.type === 'hire' && (
                <>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <CxoButtonWithIcon onClick={shareJob} label="Share" />
                  </Box> */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <CxoButtonWithIcon onClick={editJob} label="Edit" />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <CxoButtonWithIcon onClick={editJob} label="Status" />
                  </Box>
                </>
              )}

              {props?.type === 'search' && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <CxoButtonWithIcon onClick={applyJob} label="Apply" />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <CxoButtonWithIcon label="Save" onClick={saveJob} />
                  </Box>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <CxoButtonWithIcon onClick={shareJob} label="Share" />
                  </Box> */}
                </>
              )}

              {props?.type === 'search_applied' && <></>}

              {props?.type === 'result' && <></>}

              {props?.type === 'search_saved' && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <CxoButtonWithIcon onClick={unsaveJob} label="Unsave" />
                  </Box>
                </>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
