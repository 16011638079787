import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Divider from '@mui/material/Divider';
import * as usernetworkActions from '../../store/usernetwork';
import * as rewardsActions from '../../store/rewards';
import credits_gold from '../../assets/header/Credits_Gold.png';
import External_Link_Active from '../../assets/header/External_Link_Active.png';
import CxoButton from '../../components/CxoButton/CxoButton';
import RewardCreditsCard from '../../components/RewardCreditsCard/RewardCreditsCard';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const VoucherActions = (props) => {
  console.log('VoucherActions', props);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const [showDetails, setShowDetails] = React.useState(true);

  const user = useSelector((state) => state?.user?.UserDetails);
  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardVoucherDetail = useSelector(
    (state) => state?.rewards?.rewardVoucherDetail,
  );

  const rewardsVoucherDetails = useSelector(
    (state) => state?.rewards?.rewardsVoucherDetails,
  );

  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsPartnerDetails = useSelector(
    (state) => state?.rewards?.rewardsPartnerDetails,
  );

  const rewardsGetCreditsByApplause = useSelector(
    (state) => state?.rewards?.rewardsGetCreditsByApplause,
  );

  console.log(
    'Voucher',
    rewardsGetCreditsByApplause,
    rewardsPartnerDetails,
    rewardVoucherDetail,
  );

  useEffect(async () => {}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      datetime: dateTime(),
      noOfDays: props?.noOfDays,
      action_code: props?.action_code,
    };
    const linkcxorewardsActions = await dispatch(
      rewardsActions.rewardsGetCreditsByApplause(data),
    );
  }, []);

  const details = () => {};

  return (
    <Fragment>
      {props.showDetails && props?.activeType === 'applause' ? (
        <Box py={8}>
          <Grid container>
            {rewardsGetCreditsByApplause?.length > 0 &&
              rewardsGetCreditsByApplause?.map((list, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      paddingRight: index % 2 === 0 ? '4px' : '4px',
                      marginTop: '8px',
                    }}>
                    <RewardCreditsCard credit={list} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      ) : (
        <Box py={0}>
          <RewardCreditsCard
            credit={{
              type: 'applause',
              message: 'hi',
              credit: '300',
              countMessage: rewardsGetCreditsByApplause?.length,
            }}
            type="applause"
            onClick={props?.activeTypeComponent}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default React.memo(VoucherActions);

// activeType
// activeTypeComponent
