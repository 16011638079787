import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import RewardPartnerVoucherCard from '../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard';
import RewardPartnerCard from '../../components/RewardPartnerCard/RewardPartnerCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as rewardsActions from '../../store/rewards';
import CxoButton from '../../components/CxoButton/CxoButton';
import TextField from '@mui/material/TextField';
import credits_gold from '../../assets/header/Credits_Gold.png';
import { dateTime } from '../../constants/app';
import * as messageActions from '../../store/message';
import Backdrop from '@mui/material/Backdrop';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const Category = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(2);
  const [togglefilter, setTogglefilter] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState('All');
  const [filter, setFilter] = React.useState('All');
  const [locationData, setLocationData] = React.useState([]);
  const [functionlst, setFunctionlst] = React.useState('');

  const [selectedLocation, setSelectedLocation] = React.useState('');

  const user = useSelector((state) => state?.user?.UserDetails);

  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  const rewardsRealEstateList = useSelector(
    (state) => state?.rewards?.rewardsRealEstateList,
  );
  const rewardsPartnerList = useSelector(
    (state) => state?.rewards?.rewardsPartnerList,
  );

  const rewardsRealEstateLocationFilterList = useSelector(
    (state) => state?.rewards?.rewardsRealEstateLocationFilterList,
  );

  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );
  const handleChange = async (event, newValue) => {
    if (user?.isVerified) {
      if (newValue === 2) {
        navigate('/corporate/partners');
        setValue(newValue);
      } else if (newValue === 0) {
        navigate('/corporate/packagetab/Premium');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/corporate/packagetab/Elite');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    console.log('selectedIndex', selectedIndex);
  };
  const toggleFilter = () => {
    setTogglefilter(!togglefilter);
  };

  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(value);
  };

  useEffect(() => {
    async function fetchPartnerData() {
      if (params != 'all') {
        const id = params?.categoryId?.split('-');

        if (id?.length > 0) {
          const data = {
            category_id: id[id?.length - 1].substring(2),
          };
          // const eventdetails = await dispatch(eventsActions.eventDetails(data));
          const rewardspartners = await dispatch(
            rewardsActions.rewardsPartnerList(data),
          );
        }
      } else {
        const data = {
          category_id: 0,
        };
        const rewardspartners = await dispatch(
          rewardsActions.rewardsPartnerList(data),
        );
      }
    }
    fetchPartnerData();
  }, []);

  useEffect(() => {
    async function fetchVoucherData() {
      const id = params?.categoryId?.split('-');
      if (id?.length > 0) {
        const data = {
          category_id: id[id?.length - 1].substring(2),
          datetime: dateTime(),
        };
        const rewardVouchelist = await dispatch(
          rewardsActions.rewardRealestateList(data),
        );
      }
    }
    fetchVoucherData();
  }, []);

  useEffect(() => {
    async function fetchRealestateFilterData() {
      const id = params?.categoryId?.split('-');
      if (id?.length > 0) {
        const data = {
          category_id: id[id?.length - 1].substring(2),
        };
        const rewardVouchelist = await dispatch(
          rewardsActions.rewardRealestateLocationFilterList(data),
        );
        if (rewardVouchelist?.status === 1) {
          let unique = [
            ...new Set(rewardVouchelist?.data?.map((item) => item.location)),
          ];
          setLocationData(unique);
        }
      }
    }
    fetchRealestateFilterData();
  }, []);

  useEffect(() => {
    async function fetchPartnerData() {
      if (params != 'all') {
        const id = params?.categoryId?.split('-');

        if (id?.length > 0) {
          const data = {
            category_id: id[id?.length - 1].substring(2),
          };
          // const eventdetails = await dispatch(eventsActions.eventDetails(data));
          const rewardspartners = await dispatch(
            rewardsActions.rewardsPartnerList(data),
          );
        }
      } else {
        const data = {
          category_id: 0,
        };
        const rewardspartners = await dispatch(
          rewardsActions.rewardsPartnerList(data),
        );
      }
    }
    fetchPartnerData();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const handleSubmitform = async (data) => {
    console.log('data', data, functionlst);
    const id = params?.categoryId?.split('-');
    const datapush = {
      category_id: id[id?.length - 1].substring(2),
      location: selectedLocation,
      partner_id: functionlst,
    };
    const rewardVouchelist = await dispatch(
      rewardsActions.rewardRealestateFilterList(datapush),
    );
    toggleFilter();
  };

  const resetFields = () => {
    reset();
  };
 

 
  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 1) {
        navigate('/corporate/packagetab/Premium');
        setValue(val);
      } else if (val === 2) {
        navigate('/corporate/partners');
        setValue(val);
      }
       else if (val === 0) {
        navigate('/corporate/packagetab/Elite');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const creditsDetails = () => {
    navigate('/rewards/credits');
  };

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  const subscriptionType = () => {
    if (user?.membership_type === 0) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 1) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 2) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#C0C0C0' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#C0C0C0',
              fontSize: '12px',
              fontWeight: '400',

              fontFamily: 'Open Sans',
            }}>
            Silver
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 3) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#FFC107' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#FFC107',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Gold
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 4) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#A0B2C6' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#A0B2C6',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Platinum
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    }
  };
  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Premium
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Elite
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box
          sx={{
            width: '100%',
            position: 'fixed',
            zIndex: '1001',
          }}>
          <Backdrop sx={{ color: '#fff', zIndex: '2001' }} open={togglefilter}>
            <Box px={0}>
              <Box pt={2} sx={{ width: '100%' }}>
                <Paper
                  sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={0}>
                    <Box pt={2} pr={2}>
                      <CloseIcon
                        sx={{
                          color: '#ffffff',
                          cursor: 'pointer',
                          fontSize: '24',
                        }}
                        onClick={toggleFilter}
                      />
                    </Box>
                  </Stack>
                  <form
                    onSubmit={handleSubmit(handleSubmitform)}
                    style={{
                      height: 'auto',
                      paddingBottom: '24px',
                    }}>
                    <Box px={4} pt={0} sx={{ width: '100%' }}>
                      <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                        <Box
                          pt={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                                '@media (max-width: 500px)': {
                                  height: '18px',
                                  minWidth: '18px',
                                },
                              }}>
                              <Menuicon name="location" color="#38CD84" />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            width: '100%',
                            paddingTop: '12px',
                            paddingLeft: '8px',
                          }}>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Stack
                                sx={{
                                  '@media (max-width: 500px)': {
                                    paddingRight: '10px',
                                  },
                                }}>
                                <Autocomplete
                                  id="location-select"
                                  sx={{ width: '100%', paddingRight: '5px' }}
                                  value={selectedLocation}
                                  options={locationData}
                                  label="Location"
                                  autoHighlight
                                  disableClearable
                                  onChange={(_event, country) => {
                                    setSelectedLocation(country);
                                  }}
                                  getOptionLabel={(option) => {
                                    return option;
                                  }}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        '& > img': { mr: 2, flexShrink: 0 },
                                        width: 'auto',
                                        paddingLeft: '8px',
                                      }}
                                      {...props}>
                                      {option}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: true, // disable autocomplete and autofill
                                      }}
                                      variant="standard"
                                      label="Location"
                                    />
                                  )}
                                />

                                {/* <CxoInputCss
                                  label="Location"
                                  value={value}
                                  err={
                                    errors?.location ? errors?.location : false
                                  }
                                  onChange={onChange}
                                /> */}
                                <Box
                                  sx={{
                                    fontSize: '9px',
                                    height: '10px',
                                    paddingLeft: '8px',
                                  }}>
                                  {errors?.location?.message}{' '}
                                </Box>
                              </Stack>
                            )}
                            name="location"
                            defaultValue=""
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}>
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{ width: '100%' }}>
                          <Box
                            pt={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '24px',
                                  maxWidth: 'min-content',
                                  minWidth: '24px',
                                  '@media (max-width: 500px)': {
                                    height: '18px',
                                    minWidth: '18px',
                                  },
                                }}>
                                <Menuicon name="functions" color="#38CD84" />
                              </Box>
                            </Box>
                          </Box>
                          <Box py={1} pl={1} sx={{ width: '96%' }}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                style={{ color: '#38CD84' }}
                                id="demo-simple-select-label">
                                Partners
                              </InputLabel>
                              <CssSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={functionlst}
                                label="Partners"
                                className={classes.underline}
                                onChange={functionChange}
                                size="small"
                                sx={{ color: '#38CD84' }}
                                MenuProps={MenuProps}>
                                {rewardsPartnerList
                                  ? rewardsPartnerList?.map((item, key) => {
                                      return (
                                        <MenuItem key={key} value={item.id}>
                                          <ListItemText
                                            color="secondary"
                                            primary={
                                              item.name.substring(0, 25) + '...'
                                            }
                                          />
                                        </MenuItem>
                                      );
                                    })
                                  : ''}
                              </CssSelect>
                            </FormControl>
                          </Box>
                        </Stack>

                        <Stack direction="row" spacing="2">
                          <Box py={2} px={2}>
                            <CxoButton label="Reset" onClick={resetFields} />
                          </Box>
                          <Box py={2} px={2}>
                            <CxoButton label="Apply" />
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </form>
                </Paper>
              </Box>
            </Box>
          </Backdrop>
        </Box>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} pt={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Premium
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Elite
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5} onClick={() => changeTab(2)}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box
                      pb={2}
                      px={{ md: 3, xs: 0 }}
                      sx={{
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        px={2}
                        onClick={toggleFilter}
                        sx={{ cursor: 'pointer' }}>
                        <FilterAltOutlinedIcon color="secondary" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Grid container>
                      {rewardsRealEstateList?.length > 0 &&
                        rewardsRealEstateList?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <RewardPartnerVoucherCard
                                voucher={list}
                                type="voucher"
                                status="partnerVoucher"
                              />
                            </Grid>
                          );
                        })}

                      {rewardsRealEstateList?.length === 0 && (
                        <Box pl={2}>
                          <Typography variant="h5" component="h5">
                            There is no Voucher.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(Category);

// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab>

// {togglefilter && (
//   <Card
//     sx={{
//       height: '200px',
//       width: '120x',
//       marginLeft: '-80px',
//       marginTop: '185px',
//       borderRadius: '2px',
//       position: 'absolute',
//     }}>
//     <List aria-label="">
//       <ListItemButton
//         size="small"
//         sx={{ height: '36px' }}
//         selected={selectedIndex === 'All'}
//         onClick={(event) =>
//           handleListItemClick(event, 'All')
//         }>
//         <ListItemText primary="All" />
//         {selectedIndex === 'All' ? (
//           <ListItemIcon
//             sx={{ fontSize: 20, minWidth: '16px' }}>
//             <FiberManualRecordIcon
//               size="small"
//               color="secondary"
//               fontSize="8px"
//             />
//           </ListItemIcon>
//         ) : (
//           ''
//         )}
//       </ListItemButton>
//       <Divider />
//       <ListItemButton
//         sx={{ height: '36px' }}
//         selected={selectedIndex === 'Partners'}
//         onClick={(event) =>
//           handleListItemClick(event, 'Partners')
//         }>
//         <ListItemText primary="Partners" />
//         {selectedIndex === 'Partners' ? (
//           <ListItemIcon
//             sx={{ fontSize: 20, minWidth: '16px' }}>
//             <FiberManualRecordIcon
//               size="small"
//               color="secondary"
//               fontSize="8px"
//             />
//           </ListItemIcon>
//         ) : (
//           ''
//         )}
//       </ListItemButton>
//       <Divider />
//       <ListItemButton
//         sx={{ height: '36px' }}
//         selected={selectedIndex === 'Location'}
//         onClick={(event) =>
//           handleListItemClick(event, 'Location')
//         }>
//         <ListItemText primary="Location" />
//         {selectedIndex === 'Location' ? (
//           <ListItemIcon
//             sx={{ fontSize: 20, minWidth: '16px' }}>
//             <FiberManualRecordIcon
//               size="small"
//               color="secondary"
//               fontSize="8px"
//             />
//           </ListItemIcon>
//         ) : (
//           ''
//         )}
//       </ListItemButton>
//     </List>
//   </Card>
// )}
