import React, { Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Menuicon from '../../assets/menuicon';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as activityActions from '../../store/activity';
import * as messageActions from '../../store/message';
import { dateTime } from '../../constants/app';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SearchArticleCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const bookmarkPost = async (post) => {
    const data = {
      user_id: user.id,
      type: post.type,
      content_id: post.id,
      datetime: dateTime(),
    };
    const bookpost = await dispatch(activityActions.saveBookmark(data));
  };
  console.log('props', props);
  return (
    <Card
      sx={{
        backgroundColor: '#252525',
        backgroundImage: 'none',
        position: 'relative',
      }}>
      {props?.aContent?.map((content, index) => {
        return (
          <Fragment>
            <CardHeader
              key={index}
              avatar={
                <Avatar
                  src={content?.image ? content?.image : PersonIcon}
                  sx={{ width: 48, height: 48 }}
                />
              }
              action={
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    aria-label="addfriend"
                    onClick={() => {
                      bookmarkPost(content);
                    }}>
                    <BookmarkBorderOutlinedIcon />
                  </IconButton>
                </Stack>
              }
              title={
                <Typography variant="h5s" component="h5s">
                  {content?.first_name} {content?.last_name}
                </Typography>
              }
              subheader={
                <Box>
                  <Typography variant="h6" component="h6">
                    {content?.designation ? content?.designation : ''}
                  </Typography>
                  <Typography variant="h6" component="h6">
                    {content?.company_name ? content?.company_name : ''}
                  </Typography>
                </Box>
              }
            />

            {content?.content && (
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {content?.content}
                </Typography>
              </CardContent>
            )}

            {content?.image && (
              <CardMedia component="img" image={content?.image} alt=" " />
            )}
          </Fragment>
        );
      })}
    </Card>
  );
}

// { props?.post?.document &&
//   <Document
// file= {props?.post?.document }
// onLoadSuccess={onDocumentLoadSuccess}
// >
// <Page pageNumber={pageNumber} />
// </Document>
//
// }
