import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import moment from 'moment';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import CxoInput from '../../components/CxoInput/CxoInput';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CxoButton from '../../components/CxoButton/CxoButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, Controller } from 'react-hook-form';

import * as onboardActions from '../../store/onboard';
import * as usernetworkActions from '../../store/usernetwork';
import * as searchActions from '../../store/search';
import * as userActions from '../../store/user';
import { dateTime } from '../../constants/app';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
  iconcolor: {
    color: theme.palette.palette.secondary,
  },
  bioIcon: {
    height: '20px',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input': {
    scrollbarWidth: 'none',
  },
  '& ::-webkit-scrollbar': {
    width: '0px' /* width of the entire scrollbar */,
  },
}));

const EditPublication = (props) => {
  const classes = useStyles();
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('All');
  const [tab, setTab] = React.useState(0);
  const [file, setFile] = React.useState('');
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setDatevalue] = React.useState(dayjs(new Date()));
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [functionlst, setFunctionlst] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handledateChange = (newValue) => {
    setDatevalue(newValue);
  };
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state?.user?.UserDetails);
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const addNew = useSelector((state) => state?.user?.AddNew);
  const updateSection = useSelector((state) => state?.user?.UpdateProfile);
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));

  const handleChange = (newValue) => {
    setType(newValue);
  };

  useEffect(() => {
    if (props?.section?.date) {
      var dateMomentObject = dayjs(props?.section?.date);
 
      setDatevalue(dateMomentObject);
    }
  }, []);
  const closeEdit = () => {};

  const handleSubmitform = async (data) => {
    console.log('data', data, datevalue);
    const useredu = {
      id: props?.section?.id ? props?.section?.id : 0,
      user_id: user?.id,
      publication_title: data?.publication_name,
      link: data?.publication_link,
      date: dayjs(datevalue).format('YYYY-MM-DD'),
      details: data?.description,
    };
    if (
      data?.publication_name?.length > 0 &&
      data?.publication_link?.length > 0
    ) {
      const usereducation = await dispatch(
        userActions.managePublication(useredu),
      );
      const userdata = {
        id: user?.id,
      };
      const usersabout = await dispatch(userActions.userProfileAbout(userdata));
      if (updateSection) {
        const snackbar = await dispatch(
          userActions.closeUpdateSectionProfile(),
        );
      }
      if (addNew) {
        const snackbaradd = await dispatch(userActions.closeUpdateProfile());
      }
    }
  };
  return (
    <Fragment>
      <Box px={3} sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center  ',
              width: '100%',
            }}>
            <form
              onSubmit={handleSubmit(handleSubmitform)}
              style={{
                width: '100%',
              }}>
              <Box py={1} pr={1} sx={{ textAlign: 'center', width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Name"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="publication_name"
                    defaultValue={
                      props?.section?.publication_title
                        ? props?.section?.publication_title
                        : ''
                    }
                  />
                </Stack>
              </Box>

              <Box py={1} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>

<LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="Date" 
                            value={datevalue}
                            onChange={handledateChange}               
                          />
                        </DemoContainer>
                    </LocalizationProvider>
                </Stack>
              </Box>

              <Box py={1} sx={{ width: '99%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CxoInput
                        label="Link"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="publication_link"
                    defaultValue={
                      props?.section?.link ? props?.section?.link : ''
                    }
                  />
                </Stack>
              </Box>

              <Box py={1} sx={{ width: '100%' }}>
                <Stack spacing={2} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CssTextField
                        variant="standard"
                        fullWidth
                        multiline
                        rows={3}
                        maxRows={4}
                        value={value}
                        onChange={onChange}
                        color="secondary"
                        label="Description"
                        sx={{
                          scrollbarWidth: 'none',
                        }}
                      />
                    )}
                    name="description"
                    defaultValue={
                      props?.section?.details ? props?.section?.details : ''
                    }
                  />
                </Stack>
              </Box>

              <Box
                py={4}
                px={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CxoButton label="Add" type="submit" />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(EditPublication);
