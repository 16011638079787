import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Menuicon from '../../assets/menuicon';
import { dateTime } from '../../constants/app';
import * as jobsActions from '../../store/jobs';
import * as messageActions from '../../store/message';
import config from '../../services/apiConfig';
import { useNavigate } from 'react-router-dom';

export default function JobProfileCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const navigate = useNavigate();

  useEffect(async () => {
    const data = {
      user_id: props?.userlist?.id,
    };
  }, []);
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const profileOpen = async () => {
    if (user?.isVerified) {
      const profile =
        props?.candidatelist?.first_name +
        '-' +
        props?.candidatelist?.last_name +
        '-' +
        jobDetails?.id +
        '-' +
        'lx' +
        props?.candidatelist?.id;

      window.open(config?.baseURL + 'cadidate/profile/' + profile, '_blank');
      // navigate("/cadidate/profile/" + profile, {
      //   state: { profile: props?.candidatelist, job: jobDetails },
      // });
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const shortList = async () => {
    if (user?.isVerified) {
      const data = {
        job_id: jobDetails?.id,
        cand_id: props?.candidatelist?.id,
        status: 'shortlisted',
        user_id: user?.id,
        datetime: dateTime(),
      };

      const shortlist = await dispatch(
        jobsActions.postJobCandidateShortList(data),
      );

      const datadetal = {
        job_id: jobDetails?.id,
        status: 'applied',
        user_id: user?.id,
      };
      const jobsapply = await dispatch(
        jobsActions.getCandidateListApplied(datadetal, datadetal.type),
      );

      const datashort = {
        job_id: jobDetails?.id,
        status: 'shortlisted',
        user_id: user?.id,
      };
      const jobshort = await dispatch(
        jobsActions.getCandidateListShortlist(datashort, datashort.type),
      );

      const datahold = {
        job_id: jobDetails?.id,
        status: 'hold',
        user_id: user?.id,
      };
      const jobshold = await dispatch(
        jobsActions.getCandidateListHold(datahold, datahold.type),
      );

      const datarejected = {
        job_id: jobDetails?.id,
        status: 'rejected',
        user_id: user?.id,
      };
      const jobsrejected = await dispatch(
        jobsActions.getCandidateListRejected(datarejected, datarejected.status),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const hold = async () => {
    if (user?.isVerified) {
      const data = {
        job_id: jobDetails?.id,
        cand_id: props?.candidatelist?.id,
        status: 'hold',
        user_id: user?.id,
        datetime: dateTime(),
      };
      const holdlist = await dispatch(jobsActions.postJobCandidateHold(data));

      const datadetal = {
        job_id: jobDetails?.id,
        status: 'applied',
        user_id: user?.id,
      };
      const jobsapply = await dispatch(
        jobsActions.getCandidateListApplied(datadetal, datadetal.type),
      );
      const datahold = {
        job_id: jobDetails?.id,
        status: 'hold',
        user_id: user?.id,
      };
      console.log('datahold', datahold);
      const jobshold = await dispatch(
        jobsActions.getCandidateListHold(datahold, datahold.type),
      );

      const datashort = {
        job_id: jobDetails?.id,
        status: 'shortlisted',
        user_id: user?.id,
      };
      const jobshort = await dispatch(
        jobsActions.getCandidateListShortlist(datashort, datashort.type),
      );

      const datarejected = {
        job_id: jobDetails?.id,
        status: 'rejected',
        user_id: user?.id,
      };
      const jobsrejected = await dispatch(
        jobsActions.getCandidateListRejected(datarejected, datarejected.status),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const reject = async () => {
    if (user?.isVerified) {
      const data = {
        job_id: jobDetails?.id,
        cand_id: props?.candidatelist?.id,
        status: 'rejected',
        user_id: user?.id,
        datetime: dateTime(),
      };

      const shortlist = await dispatch(
        jobsActions.postJobCandidateReject(data),
      );

      const datadetal = {
        job_id: jobDetails?.id,
        status: 'applied',
        user_id: user?.id,
      };
      const jobsapply = await dispatch(
        jobsActions.getCandidateListApplied(datadetal, datadetal.type),
      );

      const datashort = {
        job_id: jobDetails?.id,
        status: 'shortlisted',
        user_id: user?.id,
      };
      const jobshort = await dispatch(
        jobsActions.getCandidateListShortlist(datashort, datashort.type),
      );

      const datahold = {
        job_id: jobDetails?.id,
        status: 'hold',
        user_id: user?.id,
      };
      const jobshold = await dispatch(
        jobsActions.getCandidateListHold(datahold, datahold.type),
      );

      const datarejected = {
        job_id: jobDetails?.id,
        status: 'rejected',
        user_id: user?.id,
      };
      const jobsrejected = await dispatch(
        jobsActions.getCandidateListRejected(datarejected, datarejected.status),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const undo = async () => {
    if (user?.isVerified) {
      const data = {
        job_id: jobDetails?.id,
        datetime: dateTime(),
        user_id: props?.candidatelist?.id,
      };

      const shortlist = await dispatch(jobsActions.postJobCandidateUndo(data));

      const datadetal = {
        job_id: jobDetails?.id,
        status: 'applied',
        user_id: user?.id,
      };
      const jobsapply = await dispatch(
        jobsActions.getCandidateList(datadetal, datadetal.type),
      );

      const datashort = {
        job_id: jobDetails?.id,
        status: 'shortlisted',
        user_id: user?.id,
      };
      const jobshort = await dispatch(
        jobsActions.getCandidateListShortlist(datashort, datashort.type),
      );

      const datahold = {
        job_id: jobDetails?.id,
        status: 'hold',
        user_id: user?.id,
      };
      const jobshold = await dispatch(
        jobsActions.getCandidateListHold(datahold, datahold.type),
      );

      const datarejected = {
        job_id: jobDetails?.id,
        status: 'rejected',
        user_id: user?.id,
      };
      const jobsrejected = await dispatch(
        jobsActions.getCandidateListRejected(datarejected, datarejected.status),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  return (
    <Card
      sx={{
        display: 'flex',
        backgroundColor: '#262525',
        backgroundImage: 'none',
        maxHeight: '160px',
        minHeight: '160px',
        width: '100%',
      }}>
      {props?.candidatelist?.image ? (
        <CardMedia
          component="img"
          onClick={() => profileOpen()}
          sx={{
            width: 160,
            minWidth: '160px',
            borderRadius: '5px',
            backgroundColor: '#262525',
            margin: '8px 0px 8px 8px',
            cursor: 'pointer',
          }}
          image={props?.candidatelist?.image ? props?.candidatelist?.image : ''}
        />
      ) : (
        <Box
          onClick={() => profileOpen()}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '216px',
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
            borderRadius: '16px',
            padding: '8px',
            objectFit: 'cover',
            backgroundColor: '#222121',
            margin: '8px 0px 8px 8px',
            cursor: 'pointer',
          }}
          alt="">
          <Avatar sx={{ width: 72, height: 72 }}>
            {props?.candidatelist?.first_name[0] +
              props?.candidatelist?.last_name[0]}
          </Avatar>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <CardHeader
          sx={{ padding: '0px 8px' }}
          title={
            <Box pt={1.5}>
              <Typography
                component="h2s"
                variant="h2s"
                sx={{ fontSize: '18px' }}>
                {props?.candidatelist?.first_name
                  ? props?.candidatelist?.first_name
                  : ''}{' '}
                {props?.candidatelist?.last_name
                  ? props?.candidatelist?.last_name
                  : ''}
              </Typography>
            </Box>
          }
          subheader={
            <Box>
              <Box pt={1.4}>
                <Stack spacing={0.4}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      fontSize: '13px',
                      color: '#ffffff',
                      fontWeight: 'revert',
                      lineHeight: '16px',
                    }}>
                    {props?.candidatelist?.designation &&
                    props?.candidatelist?.designation?.length > 30
                      ? props?.candidatelist?.designation?.slice(0, 30) + '...'
                      : props?.candidatelist?.designation}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      fontSize: '12px',
                      color: '#ffffff',
                      fontWeight: 'revert',
                    }}>
                    {props?.candidatelist?.company_name &&
                    props?.candidatelist?.company_name?.length > 30
                      ? props?.candidatelist?.company_name?.slice(0, 30) + '...'
                      : props?.candidatelist?.company_name}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          }
          action={<Box mt={1} pr={1}></Box>}
        />
        <Stack direction="row" justifyContent="flex-start">
          <Box
            pl={1}
            pt={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              justifyContent: 'flex-start',
              width: '50%',
            }}>
            <Box
              pt={0.5}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItem: 'center',
                justifyContent: 'flex-start',
              }}>
              <Stack direction="row">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItem: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '18px',
                      maxWidth: 'min-content',
                      minWidth: '18px',
                    }}>
                    <Menuicon name="location" color="#ffffff" />
                  </Box>
                </Box>
                <Box py={0.5} px={1}>
                  <Typography variant="h6" component="h6">
                    {' '}
                    {props?.candidatelist?.location &&
                    props?.candidatelist?.location?.length > 16
                      ? props?.candidatelist?.location?.substring(0, 16) + '...'
                      : props?.candidatelist?.location}
                    {/* {props?.candidatelist?.location
                      ? props?.candidatelist?.location
                      : ""} */}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box
            pl={0}
            pt={0.5}
            sx={{
              display: 'flex',
              width: '50%',
              justifyContent: 'flex-start',
            }}>
            <Stack direction="row">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItem: 'center',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '18px',
                    maxWidth: 'min-content',
                    minWidth: '18px',
                  }}>
                  <Menuicon name="calendar" color="#ffffff" />
                </Box>
              </Box>
              <Box py={0.5} px={1}>
                <Typography variant="h6" component="h6">
                  {props?.candidatelist?.datetime
                    ? props?.candidatelist?.datetime
                    : ''}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>

        <Box>
          {props?.type === 'applied' && (
            <CardActions>
              <CxoButtonWithIcon
                width="90px"
                minWidth="90px"
                label="Hold"
                onClick={hold}
              />

              <CxoButtonWithIcon
                minWidth="90px"
                label="Shortlist"
                width="90px"
                onClick={shortList}
              />
              <CxoButtonWithIcon
                minWidth="90px"
                label="Reject"
                width="90px"
                onClick={reject}
              />
            </CardActions>
          )}
          {props?.type === 'hold' && (
            <CardActions>
              <CxoButtonWithIcon
                minWidth="90px"
                label="Shortlist"
                width="90px"
                onClick={shortList}
              />
              <CxoButtonWithIcon
                minWidth="90px"
                label="Reject"
                onClick={reject}
                width="90px"
              />
            </CardActions>
          )}
          {props?.type === 'shortlist' && (
            <CardActions>
              <CxoButtonWithIcon
                width="90px"
                minWidth="90px"
                onClick={hold}
                label="Hold"
              />

              <CxoButtonWithIcon
                minWidth="90px"
                onClick={reject}
                label="Reject"
                width="90px"
              />
            </CardActions>
          )}

          {props?.type === 'rejected' && (
            <CardActions>
              <CxoButtonWithIcon
                width="90px"
                minWidth="90px"
                onClick={undo}
                label="Undo"
              />
            </CardActions>
          )}
        </Box>
      </Box>
    </Card>
  );
}
