import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import EventCard from '../../components/EventCard/EventCard';

import EventMembers from '../../components/EventMembers/EventMembers';
import ClubMembers from '../../components/ClubMembers/ClubMembers';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const ClubRequested = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofRequested, setNoofRequested] = React.useState(4);

  const user = useSelector((state) => state?.user?.UserDetails);
  const Requestedlist = useSelector(
    (state) => state?.events?.eventRequestedList,
  );
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  const eventDetails = useSelector((state) => state?.events?.eventDetails);
  const clubRequestMembers = useSelector(
    (state) => state?.clubs?.clubRequestMembers,
  );
  const eventInvitedMembers = useSelector(
    (state) => state?.events?.eventInvitedMembers,
  );

  const eventAttendingMembers = useSelector(
    (state) => state?.events?.eventAttendingMembers,
  );

  const eventRequestMembers = useSelector(
    (state) => state?.events?.eventRequestMembers,
  );

  useEffect(async () => {
    const data = {
      event_id: eventDetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventRequestedMembers(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const seeMore = () => {
    if (clubRequestMembers?.length > noofRequested) {
      setNoofRequested(clubRequestMembers?.length);
    } else if (clubRequestMembers?.length === noofRequested) {
      setNoofRequested(2);
    }
  };
  return (
    <Fragment>
      <Box px={1}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
            }}>
            <Grid container>
              {clubRequestMembers?.length > 0 &&
                clubRequestMembers?.map((list, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={6}
                      sx={{
                        marginTop: '8px',
                        paddingLeft: '8px',
                      }}>
                      <ClubMembers members={list} type="Requested" />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
        <Box
          py={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          {clubRequestMembers?.length === 0 && (
            <Box pl={2}>
              <Typography variant="h5" component="h5">
                There is no club joining requests.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(ClubRequested);
