import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import CommentCard from '../CommentCard/CommentCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import VideoLong from './VideoLong';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as postActions from '../../store/post';
import * as activityActions from '../../store/activity';
import * as clubsActions from '../../store/clubs';
import * as messageActions from '../../store/message';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BorderLinearProgresssecond = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#38cd84' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 400],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#656363' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 50 }}>
        {props.ischecked ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              color: '#38cd84',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        )}
      </Box>
      <Box sx={{ minWidth: 60, width: '100%', mr: 1 }}>
        {props.ischecked ? (
          <BorderLinearProgresssecond
            variant="determinate"
            color="secondary"
            {...props}
          />
        ) : (
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            {...props}
          />
        )}
      </Box>
    </Box>
  );
}

export default function CardLongShare(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [trim, setTrim] = React.useState(true);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const [comments, setComment] = React.useState([]);
  const [postcomment, setPostComment] = React.useState('');
  const [pollvalue, setPollValue] = React.useState('');
  const [voted, setVoted] = React.useState(false);
  const navigate = useNavigate();
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const seeMore = () => {
    setTrim(!trim);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const dispatch = useDispatch();
  const sharepost = useSelector((state) => state?.post?.SharePost);
  const user = useSelector((state) => state?.user?.UserDetails);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handlePoll = async (event) => {
    const data = {
      user_id: user.id,
      poll_id: props?.post?.id,
      option_id: parseInt(event.target.value),
      undo: false,
      datetime: dateTime(),
    };

    if (!voted) {
      setPollValue(event.target.value);
      const votepoll = await dispatch(postActions.postVotePoll(data));
      props.updatePost();
      setVoted(true);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    console.log('handleMobileMenuOpen', anchorEl);
    // setMobileMoreAnchorEl(event.currentTarget);
    setAnchorEl(!anchorEl);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let subheader = props?.post?.designation + ' || ' + props?.post?.company_name;
  let docment = props?.post?.document + '.pdf';
  let docmentshow =
    'https://docs.google.com/viewer?url=' +
    props?.post?.document +
    '&embedded=true';
  useEffect(() => {
    if (props?.post?.type === 'poll') {
      props?.post?.pollOption?.some(function (el) {
        if (el.isChecked) {
          setPollValue(el.id);
          setVoted(true);
        }
      });
    }
  }, []);

  const profileOpen = async () => {
    if (user?.isVerified) {
      const profile =
        props?.post?.first_name +
        '-' +
        props?.post?.last_name +
        '-' +
        'lx' +
        props?.post?.user_id;
      console.log('profile', profile);
      navigate('/user/' + profile);
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const likePost = async () => {
    if (user?.isVerified) {
      const postlike = await dispatch(
        postActions.postPostLike({
          content_id: props?.post?.id,
          type: props?.post?.type,
          user_id: user?.id,
          id: props?.post?.user_id,
        }),
      );
      props.updatePost();
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const sendComment = (event) => {
    setPostComment(event.target.value);
  };

  const commentPost = async () => {
    const data = {
      user_id: user.id,
      type: props?.post.type,
      content_id: props?.post.id,
      datetime: dateTime(),
    };
    setExpanded(!expanded);
    if (!expanded) {
      const commentpost = await dispatch(postActions.getPostComments(data));
      setComment(commentpost?.data);
    }
  };
  const bookmarkPost = async () => {
    const data = {
      user_id: user.id,
      type: props?.post.type,
      content_id: props?.post.id,
      datetime: dateTime(),
    };
    const bookpost = await dispatch(activityActions.saveBookmark(data));
  };

  const vadityleft = () => {
    if (props?.post?.validity === 1) {
      return props?.post?.validity + ' day left';
    } else if (props?.post?.validity > 1) {
      return props?.post?.validity + ' days left';
    } else {
      return '';
    }
  };

  const reportPost = async () => {
    // const data = {
    //   user_id : user.id,
    //   type : props?.post.type,
    //   content_id: props?.post.id,
    //   datetime: dateTime(),
    // }
    const reportpost = await dispatch(
      postActions.openReportPost({ post: props.post, type: 'report' }),
    );
    props.updatePost();
  };
  const hidePost = async () => {
    const hidepost = await dispatch(
      postActions.openReportPost({ post: props.post, type: 'hide' }),
    );
    props.updatePost();
  };

  const sharePost = async () => {
    const data = {
      user_id: user.id,
      type: props?.post.type,
      content_id: props?.post.id,
      datetime: dateTime(),
      content: props?.post?.content,
      user_type: props?.post?.user_type,
      user_table_pk: props?.post?.user_table_pk,
      child_id: props?.post?.child_id,
    };
    const sharepost = await dispatch(postActions.sharePost(data));
  };
  const postComment = async () => {
    const data = {
      user_id: user?.id,
      type: props?.post?.type,
      content_id: props?.post?.id,
      content: postcomment,
      to_user_id: props?.post?.user_id,
      created_datetime: dateTime(),
      id: 0,
    };
    const commentpost = await dispatch(postActions.manageComment(data));
    props.updatePost();
  };
  const timeago = () => {
    if (props?.post?.created_datetime === 'Invalid date') {
      return '';
    } else {
      console.log(
        'local',
        moment(
          moment(props?.post?.created_datetime).subtract(5, 'hours'),
        ).fromNow(),
      );
      // moment(props?.post?.created_datetime).utcOffset('-0530').fromNow();
      return moment(
        moment(props?.post?.created_datetime).subtract(0, 'minutes'),
      ).fromNow();
    }
  };
  const companyOpen = () => {};
  const clubOpen = async () => {
    if (user?.isVerified) {
      const clubdetails = props?.post?.clubDetails?.title;
      const clubdetailsToOpen = await dispatch(
        clubsActions.openDetailsClub(props?.post?.clubDetails),
      );

      if (props?.post?.clubDetails?.title) {
        const clubdetails = props?.post?.clubDetails?.title;
        navigate('/club/details/' + clubdetails);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const avatarDetails = () => {
    if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.clubDetails)?.length > 0
    ) {
      return (
        <Avatar
          onClick={() => clubOpen()}
          src={
            props?.clubDetails?.image ? props?.clubDetails?.image : PersonIcon
          }
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    } else if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.clubDetails)?.length === 0
    ) {
      return (
        <Avatar
          onClick={() => profileOpen()}
          src={props?.post?.user_image ? props?.post?.user_image : PersonIcon}
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    } else if (props?.post?.user_type === 'user') {
      return (
        <Avatar
          onClick={() => profileOpen()}
          src={props?.post?.user_image ? props?.post?.user_image : PersonIcon}
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    } else if (props?.post?.user_type === 'company_page') {
      return (
        <Avatar
          onClick={() => companyOpen()}
          src={
            props?.oPost?.company_page_image
              ? props?.oPost?.company_page_image
              : PersonIcon
          }
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    }
  };

  const postName = () => {
    if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.clubDetails)?.length > 0
    ) {
      return props?.clubDetails?.title;
    } else if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.clubDetails)?.length === 0
    ) {
      return props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (props?.post?.user_type === 'user') {
      return props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (props?.post?.user_type === 'company_page') {
      if (props?.post?.company_page_name) return props?.post?.company_page_name;
      else {
        return props?.post?.company_name;
      }
    }
  };

  const subheaderShare = () => {
    if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.clubDetails)?.length > 0
    ) {
      return 'By ' + props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.clubDetails)?.length === 0
    ) {
      return props?.post?.designation + ' || ' + props?.post?.company_name;
    } else if (props?.post?.user_type === 'user') {
      return props?.post?.designation + ' || ' + props?.post?.company_name;
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: '#252525',
        backgroundImage: 'none',
        position: 'relative',
        borderTop: '1px solid #808080',
        padding: '0px 8px',
        maxHeight: 'auto',
        height: sharepost ? '28vh' : 'auto',
        overflowY: sharepost ? 'scroll' : 'none',
        '@media (max-width: 500px)': {
          padding: '0px ',
        },
      }}>
      <CardHeader
        avatar={avatarDetails()}
        title={
          <Stack direction="row" spacing={1}>
            <Typography variant="h5s" component="h5s">
              {postName()}
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontFamily: 'Open Sans',
                paddingTop: '3px',
                color: 'rgba(255, 255,255,0.5)',
              }}>
              {timeago()}
            </Typography>
          </Stack>
        }
        subheader={subheaderShare()}
      />

      {props?.post?.content ? (
        <CardContent sx={{ paddingTop: '8px' }}>
          <Typography
            variant="h2"
            color="text.secondary"
            sx={{
              color: 'rgba(255, 255,255,1)',
              whiteSpace: 'pre-line',
              lineHeight: '21px',
              letterSpacing: '1.2',
              '@media (max-width: 500px)': {
                fontSize: '12px',
              },
            }}>
            {props?.post?.content?.length > 276 && trim
              ? props?.post?.content?.substring(0, 200)
              : props?.post?.content}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props?.post?.content?.length > 276 && trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',
                }}
                onClick={seeMore}>
                {' '}
                ...See more
              </Box>
            ) : (
              ' '
            )}
            {props?.post?.content?.length > 276 && !trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',
                }}
                onClick={seeMore}>
                {' '}
                ...See less
              </Box>
            ) : (
              ''
            )}
          </Box>
        </CardContent>
      ) : (
        ''
      )}

      {props?.post?.type === 'poll' ? (
        <CardContent sx={{ paddingTop: '8px', paddingBottom: '4px' }}>
          <Box px={2}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: 'rgba(255, 255,255,1)',
                    paddingBottom: '8px',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}>
                  {props?.post?.heading}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
                value={pollvalue}
                onChange={handlePoll}
                color="secondary">
                {props?.post?.pollOption?.map((poll, index) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{ width: '100%' }}>
                      <FormControlLabel
                        key={index}
                        value={poll?.id}
                        control={<Radio size="small" color="secondary" />}
                        label={poll?.options}
                      />
                      {voted && (
                        <Box sx={{ maxWidth: 200, width: '100%' }}>
                          <LinearProgressWithLabel
                            value={poll?.vote}
                            ischecked={poll?.isChecked}
                            color="secondary"
                          />
                        </Box>
                      )}
                    </Stack>
                  );
                })}
              </RadioGroup>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    maxWidth: 200,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      paddingBottom: '8px',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    {vadityleft()}
                  </Typography>
                </Box>
              </Box>
            </FormControl>
          </Box>
        </CardContent>
      ) : (
        ''
      )}

      {props?.post?.image && props?.post?.document_name != 'video' && (
        <CardMedia
          component="img"
          image={props?.post?.image}
          sx={{
            width: 'auto',
            margin: 'auto',
            maxHeight: '60vh',
            maxWidth: '100%',
          }}
          alt=" "
        />
      )}

      {!props?.post?.image &&
        props?.post?.document_name != 'video' &&
        props?.post?.document?.length > 0 && (
          <Box p={1} sx={{ height: 'auto' }}>
            <iframe
              width="100%"
              height="200px"
              src={docmentshow}
              frameBorder="0"></iframe>
          </Box>
        )}

      {props?.post?.image && props?.post?.document_name === 'video' && (
        <VideoLong
          post={props?.post}
          sx={{
            '@media (max-width: 500px)': {
              height: '100px',
              width: 'auto',
            },
          }}
        />
      )}
    </Card>
  );
}

// { props?.post?.document &&
//   <Document
// file= {props?.post?.document }
// onLoadSuccess={onDocumentLoadSuccess}
// >
// <Page pageNumber={pageNumber} />
// </Document>
//
// }
// const commentpost = await dispatch(messageActions.openUnderVerification());
