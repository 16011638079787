import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Gyanesh from '../../resources/about-us/gyanesh.png';
import Hema from '../../resources/about-us/hema.png';
import Sumit from '../../resources/about-us/sumit.png';
import Rajesh from '../../resources/about-us/rp.png';
import saba from '../../resources/about-us/sabah.png';
import twinkle from '../../resources/about-us/twinkle.png';
import saurabh from '../../resources/about-us/saurabh.png';
import amitabh from '../../resources/about-us/amitabh.png';
import Footer from '../../components/HomeHeader/footer';
import Vedic from '../../resources/about-us/vedic.png';
const AboutUs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Container fluid id="no-padding">
      <Row>
        <Col md={12} xs={12} className="main-header-style-section">
          <Navbar />{' '}
        </Col>
      </Row>
      <Row className="top-section">
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <h1 className="top-section-title">
            LinkCxO is an exclusive Online platform for
            <span className="color-green"> senior professionals </span> to
            create right connects based on their
            <span className="color-green"> Interests </span>
            by collaborating through AI enabled search for{' '}
            <span className="color-green">
              People, Business, Content, Events & Jobs
            </span>{' '}
            in most rewarding way
          </h1>
        </Col>
      </Row>

      <Row className="company-story">
        <Col>
          {' '}
          <Row className="company-story-team">
            <Col>
              {' '}
              <h4 className="color-green">Company Story</h4>
            </Col>
          </Row>
          <Row>
            {' '}
            <div className="container-company-story ">
              <div className="row  ">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <section className="main-timeline-section">
                    <div className="conference-center-line"></div>
                    <div className="conference-timeline-content">
                      <div className="timeline-article timeline-article-top">
                        <div className="content-date">
                          <div className="content-date-text">
                            <p className="contentmonth">Jun</p>
                            <p className="opensansfont">2024</p>
                          </div>
                        </div>
                        <div className="date-dotted-line-top"></div>
                        <div className="meta-date"></div>
                        <div className="content-box">
                          <ul
                            style={{
                              listStyleType: 'none',
                              textAlign: 'left',
                              margin: 0,
                              padding: 0,
                              fontSize: '14px',
                            }}>
                            <li>
                              {' '}
                              LinkCxO v2.0 Launch with Additional Features{' '}
                            </li>{' '}
                            <li>
                              {' '}
                              <span style={{ fontFamily: 'sans-serif' }}>
                                50
                              </span>
                              K Users{' '}
                            </li>{' '}
                            <li> IOS App Launch </li>{' '}
                            {/* <li> Pre-series A, <span style={{ fontFamily: 'sans-serif' }}>3</span>M $ </li>
                            <li>Entry into USA/APAC/EMEA</li> */}
                          </ul>
                        </div>
                      </div>

                      <div className="timeline-article timeline-article-bottom">
                        <div className="content-date-bottom">
                          <div className="content-date-text">
                            <p className="contentmonth">Sep</p>
                            <p className="opensansfont">2022</p>
                          </div>
                        </div>
                        <div className="date-dotted-line-bottom"></div>
                        <div className="meta-date"></div>
                        <div className="content-box">
                          {' '}
                          <ul
                            style={{
                              listStyleType: 'none',
                              textAlign: 'left',
                              margin: 0,
                              padding: 0,
                              fontSize: '14px',
                            }}>
                            <li>
                              {' '}
                              <span style={{ fontFamily: 'sans-serif' }}>
                                10K
                              </span>{' '}
                              users
                            </li>{' '}
                            <li>LinkCxO v1.1 Launch with UX/UI Changes</li>{' '}
                            <li>LinkCxO Webapp Launch</li> <li></li>
                          </ul>
                        </div>
                      </div>

                      <div className="timeline-article timeline-article-top">
                        <div className="content-date">
                          <div className="content-date-text">
                            <p className="contentmonth">Aug</p>
                            <p className="opensansfont">2021</p>
                          </div>
                        </div>
                        <div className="date-dotted-line-top"></div>
                        <div className="meta-date"></div>
                        <div className="content-box">
                          {' '}
                          <ul
                            style={{
                              listStyleType: 'none',
                              textAlign: 'left',
                              margin: 0,
                              padding: 0,
                              fontSize: '14px',
                            }}>
                            <li> Beta Ready </li>{' '}
                            <li>
                              {' '}
                              First
                              <span style={{ fontFamily: 'sans-serif' }}>
                                &nbsp;100{' '}
                              </span>
                              Beta Users
                            </li>{' '}
                          </ul>
                        </div>
                      </div>

                      <div className="timeline-article timeline-article-bottom">
                        <div className="content-date-bottom">
                          <div className="content-date-text">
                            <p className="contentmonth">Mar</p>
                            <p className="opensansfont">2019</p>
                          </div>
                        </div>
                        <div className="date-dotted-line-bottom"></div>
                        <div className="meta-date"></div>
                        <div className="content-box">
                          {' '}
                          <ul
                            style={{
                              listStyleType: 'none',
                              textAlign: 'left',
                              margin: 0,
                              padding: 0,
                              fontSize: '14px',
                            }}>
                            <li>Idea Inception</li> <li>Team Building</li>{' '}
                            <li> Product Development</li>{' '}
                            <li>
                              <span style={{ fontFamily: 'sans-serif' }}>
                                300+
                              </span>{' '}
                              CXOs surveyed
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="timeline-article timeline-article-top">
                        <div className="content-date">
                          <div className="content-date-text">
                            <p className="contentmonth">Oct</p>
                            <p className="opensansfont">2015</p>
                          </div>
                        </div>
                        <div className="date-dotted-line-top"></div>
                        <div className="meta-date"></div>
                        <div className="content-box">
                          {' '}
                          <ul
                            style={{
                              listStyleType: 'none',
                              textAlign: 'left',
                              margin: 0,
                              padding: 0,
                              fontSize: '14px',
                            }}>
                            <li>
                              {' '}
                              LinkCxO was founded as CxO Search and Consulting
                              Services Company
                            </li>{' '}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </Row>
          <Row className="nl-about-us-vision">
            {' '}
            <Col sm={6} className="vision-div">
              <h4 className="color-green">Vision</h4>
              <p className="align ">
                “Build an exclusive community of senior leaders which can grow,
                lead and Influence the professional & social world around them”
              </p>
            </Col>
            <Col sm={6} className="mission-div">
              <h4 className="color-green">Mission</h4>
              <p className="align mission-div-align">
                “Create an exclusive global community of leaders, by providing a
                value-based AI-enabled platform, for their professional &
                personal growth”
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="team-section">
        <Col>
          <Row className="team">
            <Col>
              {' '}
              <h4 className="color-green">Advisory Team</h4>
            </Col>
          </Row>

          <Row className="people4">
            <Col sm={12} md={4}>
              <img src={Rajesh} alt="" className="people-img" />
              <p className="color-green1">Rajesh Padmanabhan</p>
              <p className="color-white">Mentor</p>
            </Col>
            <Col sm={12} md={4}>
              <img src={amitabh} alt="" className="people-img" />
              <p className="color-green1">Amitabh Kumar</p>
              <p className="color-white">Strategic Advisor</p>
            </Col>
            <Col sm={12} md={4}>
              <img src={Sumit} alt="" className="people-img" />
              <p className="color-green1">Sumit Kumar </p>
              <p className="color-white">AI Advisor</p>
            </Col>
          </Row>

          <Row className="team">
            <Col>
              {' '}
              <h4 className="color-green">Founders</h4>
            </Col>
          </Row>

          <Row className="people4">
            <Col sm={12} md={6}>
              <img src={Gyanesh} alt="" className="people-img" />
              <p className="color-green1">Gyanesh Kumar</p>
              <p className="color-white">Founder, CEO</p>
            </Col>
            <Col sm={12} md={6}>
              <img src={Hema} alt="" className="people-img" />
              <p className="color-green1">Hema Gupta</p>
              <p className="color-white">Co-Founder & Head-CxO Jobs</p>
            </Col>
          </Row>
          {/* <Row className="team">
            <Col>
              {' '}
              <h4 className="color-green">LinkCxO Team</h4>
            </Col>
          </Row> */}
          {/* <Row className="people4">
            <Col sm={12} md={3}>
              {' '}
              <img className="people-img" src={Vedic} alt="" />
              <p className="color-green1">Vedic Choubey</p>
              <p className="color-white">Technology Head</p>
            </Col>
            <Col sm={12} md={3}>
              {' '}
              <img className="people-img" src={saba} alt="" />
              <p className="color-green1">Sabah Khan</p>
              <p className="color-white">Manager - Partnership and Alliance</p>
            </Col>
            <Col sm={12} md={3}>
              {' '}
              <img className="people-img" src={saurabh} alt="" />
              <p className="color-green1">Saurabh Puthalat</p>
              <p className="color-white">Manager - Marketing</p>
            </Col>
            <Col sm={12} md={3}>
              {' '}
              <img className="people-img" src={twinkle} alt="" />
              <p className="color-green1">Twinkle Thakker</p>
              <p className="color-white">Manager - Corporate Sales</p>
            </Col>
          </Row> */}
        </Col>
      </Row>
      <Row className="join-team">
        <Col>
          <h3 className="color-green join-our-team">Join Our Team</h3>
          <h5
            style={{
              color: '#fff',
              marginTop: '20px',
              marginBottom: '30px',
              lineHeight: '30px',
            }}>
            We believe that passion triggers passion, join our team and show
            your creative side
          </h5>
          <a href="mailto:careers@linkcxo.com">
            <button className="become-a-member-btn">
              Apply at careers@linkcxo.com
            </button>
          </a>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12} style={{ background: '#151414' }}>
          {' '}
          <Footer />{' '}
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
