import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoButton from '../../components/CxoButton/CxoButton';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useParams } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import * as articleActions from '../../store/article';
import * as clubsActions from '../../store/clubs';
import * as postActions from '../../store/post';
import { dateTime } from '../../constants/app';
import EventsIcon from '../../assets/eventsicon';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useNavigate, useLocation } from 'react-router-dom';
import * as messageActions from '../../store/message';
import ClubPosts from './ClubPosts';
import ClubEvent from './ClubEvent';
import ClubFriends from './ClubFriends';
import PostWhite from '../../assets/Posts_White.png';
import VoucherDetailsSkeleton from '../../components/Skeleton/VoucherDetailsSkeleton';

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const AntTab = styled((props) => <StyledTab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const ClubDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.UserDetails);
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [showClubPost, setShowClub] = React.useState(false);
  const [clubConfirm, setClubConfirm] = React.useState(false);
  const [membershipId, setMembershipId] = React.useState(0);
  const upcomingEventList = useSelector((state) => state?.events?.eventDetails);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const clubEventList = useSelector((state) => state?.clubs?.clubEventList);
  const clubDetailsloading = useSelector(
    (state) => state?.clubs?.clubDetailsloading,
  );
  const clubMembersFriend = useSelector(
    (state) => state?.clubs?.clubMembersFriend,
  );

  const clubDetailstoOpen = useSelector(
    (state) => state?.clubs?.clubDetailstoOpen,
  );

  const eventStatususer = useSelector(
    (state) => state?.events?.eventStatusUser,
  );

  const clubPostList = useSelector((state) => state?.clubs?.clubPostList);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    // setMobileMoreAnchorEl(event.currentTarget);
    // if ( user?.id === props?.post?.user_id){
    //   setAnchorEl(!anchorEl);
    // }
    setAnchorEl(!anchorEl);
  };
  useEffect(async () => {
    // if (clubDetailstoOpen?.club_id) {
    //   const data = {
    //     user_id: user?.id,
    //     club_id: clubDetailstoOpen?.club_id,
    //   };
    //   const clubdetails = await dispatch(clubsActions.clubDetails(data));
    // } else {
    //   const data = {
    //     user_id: user?.id,
    //     club_id: clubDetailstoOpen?.id,
    //   };
    //   const clubdetails = await dispatch(clubsActions.clubDetails(data));
    // }
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.clubID?.split('_');
      const data = {
        user_id: user?.id,
        club_id: id[id?.length - 1].substring(3),
      };

      const data2 = {
        user_id: user?.id,
        club_id: parseInt(id[id?.length - 1].substring(3)),
        datetime: dateTime(),
      };

      const clubdetails = await dispatch(clubsActions.clubDetails(data));

      const clubdposts = await dispatch(clubsActions.clubPostList(data2));

      if (clubdetails?.data?.is_already_requested === 1) {
        const data = {
          user_id: user?.id,
        };
        const requestedclubList = await dispatch(
          clubsActions.requestedClubList(data),
        );

        const matchingObject = requestedclubList?.data?.find(
          (obj) => obj.club_id == id[id?.length - 1].substring(3),
        );
        setMembershipId(0)
        if (matchingObject) {
          const id = matchingObject.member_id;
          setMembershipId(id)
        } else {
          console.log('No object found with the given ID.');
        }
      } else if (clubdetails?.data?.is_already_invited === 1) {
        const data = {
          user_id: user?.id,
        };
        const invitedClubList = await dispatch(
          clubsActions.invitedClubList(data),
        );

        const matchingObject = invitedClubList?.data?.find(
          (obj) => obj.club_id == id[id?.length - 1].substring(3),
        );

        if (matchingObject) {
          const id = matchingObject.member_id;
          setMembershipId(id)
        } else {
          console.log('No object found with the given ID.');
        }
      }
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const hostedEvent = await dispatch(
      eventsActions.hostedEventUpcomming(data),
    );
  }, []);

  useEffect(async () => {
    if (clubDetailstoOpen?.id) {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.id,
        categoryId: '',
      };
      const clubdetails = await dispatch(clubsActions.clubEventList(data));
    }
  }, []);

  useEffect(async () => {
    if (clubDetailstoOpen?.id) {
      const data = {
        user_id: user?.id,
        club_id: clubDetailstoOpen?.club_id,
      };
      const clubdetails = await dispatch(
        clubsActions.clubMemberFriendList(data),
      );
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const eventstartDate = () => {
    let month = moment(clubDetails?.start_date).format('MMM');
    let date = moment(clubDetails?.start_date).format('DD');
    let year = moment(clubDetails?.start_date).format('YYYY');

    return date + ' ' + month + ' ' + year;
  };

  const eventStartTime = () => {
    // let time = moment(clubDetails?.start_time).format("LT");
    let time = moment(clubDetails?.start_time, 'hh:mm:ss A').format('hh:mm A');
    return '( ' + time + ' )';
  };

  const handleCloseConfirm = (type) => {
    setClubConfirm(!clubConfirm);
  };

  const eventEndDate = () => {
    let month = moment(clubDetails?.end_date).format('MMM');
    let date = moment(clubDetails?.end_date).format('DD');
    let year = moment(clubDetails?.end_date).format('YYYY');

    return date + ' ' + month + ' ' + year;
  };

  const eventEndTime = () => {
    // let time = moment(clubDetails?.start_time).format("LT");
    let time = moment(clubDetails?.end_time, 'hh:mm:ss A').format('hh:mm A');
    return '( ' + time + ' )';
  };

  const reportEvent = async () => {
    // const data = {
    //   user_id : user.id,
    //   type : props?.post.type,
    //   content_id: props?.post.id,
    //   datetime: dateTime(),
    // }

    if (user?.isVerified) {
      const reportpost = await dispatch(
        postActions.openReportPost({ post: clubDetails, type: 'report' }),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const withdraw = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: clubDetails?.id,
        user_id: user?.id,
        status: 'accept',
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestWithdraw(data),
      );
      const datainvited = {
        user_id: user?.id,
      };
      const eventListupcoming = await dispatch(
        eventsActions.savedEventUpcoming(datainvited),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
      const dataEvent = {
        user_id: user?.id,
        event_id: clubDetails?.id,
      };
      const hostedEventUser = await dispatch(
        eventsActions.eventStatusForUser(dataEvent),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const fetchData = async () => {
    const id = params?.clubID?.split('_');
    const data = {
      user_id: user?.id,
      club_id: id[id?.length - 1].substring(3),
    };

    const data2 = {
      user_id: user?.id,
      club_id: parseInt(id[id?.length - 1].substring(3)),
      datetime: dateTime(),
    };

    const clubdetails = await dispatch(clubsActions.clubDetails(data));
    const clubdposts = await dispatch(clubsActions.clubPostList(data2));
  };

  const attend = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: clubDetails?.id,
        from_user_id: user?.id,
        to_user_id: clubDetails?.user_id,
        datetime: dateTime(),
      };
      const attendrequest = await dispatch(eventsActions.eventRequest(data));
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const requestToAttend = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: clubDetails?.id,
        from_user_id: clubDetails?.user_id,
        to_user_id: user?.id,
        datetime: dateTime(),
      };
      const acceptinvitation = await dispatch(
        eventsActions.eventRequestAccept(data),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
      const datainvited = {
        user_id: user?.id,
      };
      const eventListhi = await dispatch(
        eventsActions.invitedEventList(datainvited),
      );
      const eventListddd = await dispatch(
        eventsActions.savedEventUpcoming(datalist),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const requestToIgnore = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: clubDetails?.id,
        from_user_id: clubDetails?.user_id,
        to_user_id: user?.id,
        datetime: dateTime(),
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestReject(data),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
      const datainvited = {
        user_id: user?.id,
      };
      const eventListhi = await dispatch(
        eventsActions.invitedEventList(datainvited),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const manageMembers = async () => {
    if (user?.isVerified) {
      navigate('/club/members');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const withdrawRequest = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: clubDetails?.id,
        user_id: user?.id,
        status: 'request',
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestWithdraw(data),
      );
      const datahost = {
        user_id: user?.id,
        event_id: clubDetails?.id,
      };
      const hostedEventUser = await dispatch(
        eventsActions.eventStatusForUser(datahost),
      );
      // const jobdetails = await dispatch(eventsActions.clubDetails(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const eventcancel = async () => {
    if (user?.isVerified) {
      const data = {
        id: clubDetails?.id,
      };
      const cancelevent = await dispatch(eventsActions.eventCancel(data));
      const datahost = {
        user_id: user?.id,
        event_id: clubDetails?.id,
      };
      const hostedEventUser = await dispatch(
        eventsActions.eventStatusForUser(datahost),
      );
      // const jobdetails = await dispatch(eventsActions.clubDetails(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const deleteClub = async () => {
    if (user?.isVerified) {
      const data = {
        club_id: clubDetails?.id,
      };
      const cancelevent = await dispatch(clubsActions.clubDelete(data));

      const datahost2 = {
        user_id: user?.id,
      };
      const clubHostedList = await dispatch(
        clubsActions.hostedClubs(datahost2),
      );

      navigate('/clubs/owner');

      // const datahost = {
      //   user_id: user?.id,
      //   event_id: clubDetails?.id,
      // };

      // const jobdetails = await dispatch(eventsActions.clubDetails(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const copyLink = async () => {
    if (user?.isVerified) {
      navigator.clipboard.writeText(clubDetails?.event_link);
      document.execCommand('copy');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const joinEvent = async (e) => {
    e.preventDefault();
    if (user?.isVerified) {
      window.open(clubDetails?.event_link, '_blank');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const bookmarkClub = async () => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: 'club',
        content_id: clubDetails?.id,
        datetime: dateTime(),
      };
      const bookpost = await dispatch(activityActions.saveBookmark(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const cancelRequest = async (e) => {
    if (user?.isVerified) {
      const data = {
        member_id: membershipId,
        club_id: clubDetails?.id,
      };
      const clubRequestwithdraw = await dispatch(
        clubsActions.clubUnsendRequest(data),
      );

      const datainvited = {
        user_id: user?.id,
      };
      const clubListupcoming = await dispatch(
        clubsActions.myClubList(datainvited),
      );

      if (clubDetailstoOpen?.club_id) {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.club_id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
      } else {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
      }
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const joinClub = async (e) => {
    e.preventDefault();
    if (user?.isVerified) {
      const data = {
        to_user_id: clubDetails?.user_id,
        from_user_id: user?.id,
        club_id: clubDetails?.id,
        datetime: dateTime(),
      };
      const clubrequest = await dispatch(clubsActions.clubRequest(data));
      if (clubDetailstoOpen?.club_id) {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.club_id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
        const data2 = {
          user_id: user?.id,
        };
        const requestedclubList = await dispatch(
          clubsActions.requestedClubList(data2),
        );

        const matchingObject = requestedclubList?.data?.find(
          (obj) => obj.club_id == clubDetails?.id,
        );
        setMembershipId(0)
        if (matchingObject) {
          const id = matchingObject.member_id;
          setMembershipId(id)
        } else {
          console.log('No object found with the given ID.');
        }
      } else {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
        const data2 = {
          user_id: user?.id,
        };
        const requestedclubList = await dispatch(
          clubsActions.requestedClubList(data2),
        );

        const matchingObject = requestedclubList?.data?.find(
          (obj) => obj.club_id == clubDetails?.id,
        );
        setMembershipId(0)
        if (matchingObject) {
          const id = matchingObject.member_id;
          setMembershipId(id)
        } else {
          console.log('No object found with the given ID.');
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const clubAccept = async (e) => {
    if (user?.isVerified) {
      const data = {
        club_id: clubDetails?.id,
        member_id: clubDetails?.member_id,
        from_user_id: clubDetailstoOpen?.user_id,
        to_user_id: user?.id,
        datetime: dateTime(),
      };

      // const data = {
      //   club_id : props?.clubs?.id,
      //   from_user_id: user?.id,
      //   to_user_id: props?.clubs?.user_id,
      //   datetime: dateTime()
      // }
      // const attendrequest = await dispatch(clubsActions.clubRequest(data));

      const acceptinvitation = await dispatch(
        clubsActions.clubRequestAccept(data),
      );

      if (clubDetailstoOpen?.club_id) {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.club_id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
      } else {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
      }
      const datalist = {
        user_id: user?.id,
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
      const datainvited = {
        user_id: user?.id,
      };
      const clubListhi = await dispatch(
        clubsActions.invitedClubList(datainvited),
      );

      const clubListddd = await dispatch(clubsActions.savedClub(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const clubDislike = async (e) => {
    e.preventDefault();
    if (user?.isVerified) {
      const data = {
        to_user_id: clubDetails?.user_id,
        from_user_id: user?.id,
        club_id: clubDetails?.id,
        datetime: dateTime(),
      };
      const clubrequest = await dispatch(clubsActions.clubRequest(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const editClub = async () => {
    if (user?.isVerified) {
      const eventEdit = await dispatch(
        eventsActions.editEventOpen(clubDetails),
      );
      navigate('/club/edit');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const openPost = async () => {
    setShowClub(true);
    // if (user?.isVerified) {
    //   const mypost = await dispatch(clubsActions.openPostEdit('club'));
    // } else {
    //   await dispatch(messageActions.openUnderVerification());
    // }
  };

  const openPostCreate = async () => {
    setShowClub(false);
    if (user?.isVerified) {
      const mypost = await dispatch(clubsActions.openPostEdit('club'));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const openPoll = async () => {
    setShowClub(false);
    if (user?.isVerified) {
      const mypoll = await dispatch(postActions.openPollEdit());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const openArticle = async () => {
    setShowClub(false);
    if (user?.isVerified) {
      const myarticle = await dispatch(articleActions.openArticleEdit());
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const createClubEvent = async () => {
    if (user?.isVerified) {
      navigate('/club/event/create');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const handleClose = () => {
    setShowClub(false);
  };
  const leaveClub = async () => {
    if (user?.isVerified) {
      const data = {
        club_id: clubDetailstoOpen?.club_id,
        user_id: user?.id,
        member_id: clubDetailstoOpen?.member_id,
      };
      const clubRequestwithdraw = await dispatch(clubsActions.clubLeave(data));
      if (clubDetailstoOpen?.club_id) {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.club_id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
      } else {
        const data = {
          user_id: user?.id,
          club_id: clubDetailstoOpen?.id,
        };
        const clubdetails = await dispatch(clubsActions.clubDetails(data));
      }
      const datainvited = {
        user_id: user?.id,
      };
      const clubListupcoming = await dispatch(
        clubsActions.myClubList(datainvited),
      );
      const clubLista = await dispatch(clubsActions.savedClub(datainvited));
      const datalist = {
        user_id: user?.id,
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const dropDown = () => {
    if (user?.id === clubDetails?.user_id) {
      return (
        <Box>
          <List>
            {clubDetails.status !== 'Cancelled' && (
              <Fragment>
                <MenuItem
                  button
                  sx={{ padding: '2px 12px' }}
                  onClick={editClub}>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              </Fragment>
            )}

            {clubDetails.status !== 'Cancelled' && (
              <Fragment>
                <Divider />
                <MenuItem
                  button
                  sx={{ padding: '2px 12px' }}
                  onClick={handleCloseConfirm}>
                  <ListItemText>Delete this club</ListItemText>
                </MenuItem>
              </Fragment>
            )}
          </List>
        </Box>
      );
    } else {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={reportEvent}>
              <ListItemText>Report</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    }
  };

  if (clubDetailsloading) {
    return <VoucherDetailsSkeleton />;
  } else {
    return (
      <Fragment>
        {clubConfirm && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={clubConfirm}>
              <Box
                sx={{
                  width: '80%',
                  backgroundColor: '#1c1c1c',
                  borderRadius: '10px',
                  maxWidth: '600px',
                }}>
                <Stack spacing={0} sx={{ width: '100%', maxWidth: '600px' }}>
                  <Stack spacing={0} direction="row">
                    <Box
                      px={3}
                      py={2}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '24px',
                      }}>
                      <Typography variant="h2s" component="h2s">
                        Confirm
                      </Typography>
                    </Box>
                    <Box
                      px={3}
                      py={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <CloseIcon
                        sx={{
                          color: '#ffffff',
                          cursor: 'pointer',
                          fontSize: '24',
                        }}
                        onClick={handleClose}
                      />
                    </Box>
                  </Stack>
                  <Divider />

                  <Stack>
                    <Box
                      px={3}
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}>
                      <Typography gutterBottom variant="h5" component="h5">
                        Are you sure you want to delete this Club?
                      </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="center">
                      <Box
                        py={2}
                        px={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CxoButton label="No" onClick={handleCloseConfirm} />
                      </Box>
                      <Box
                        py={2}
                        px={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CxoButton label="Yes" onClick={deleteClub} />
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Backdrop>
          </Box>
        )}
        {showClubPost && (
          <Fragment>
            <Box
              sx={{
                width: '100%',
                maxWidth: '400',
                height: '100vh',
                position: 'fixed',
                zIndex: '3001',
              }}>
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showClubPost}>
                <Box
                  sx={{
                    width: '400px',
                    backgroundColor: '#333',
                    borderRadius: '10px',
                    height: '300px',
                  }}>
                  <Stack spacing={0} sx={{ width: '100%' }}>
                    <Stack spacing={0} direction="row">
                      <Box
                        px={3}
                        py={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            '@media (max-width: 500px)': {
                              fontSize: '14px',
                            },
                          }}>
                          Share Your Experience
                        </Typography>
                      </Box>
                      <Box
                        pl={3}
                        pr={1.5}
                        py={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                            cursor: 'pointer',
                          }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Stack>
                    <Stack direction="column" sx={{ padding: '16px' }}>
                      <Stack direction="row" sx={{ padding: '8px 16px' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openPostCreate}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                              width: '18px',
                            }}>
                            <img src={PostWhite} alt="" />
                          </Box>
                        </Box>

                        <Box py={0.5} px={2} onClick={openPostCreate}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{
                              fontSize: '15px',
                              cursor: 'pointer',
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                              },
                            }}>
                            Create a post
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider />

                      <Stack
                        direction="row"
                        sx={{ padding: ' 10px', cursor: 'pointer' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openArticle}>
                          <Box mt={0.4}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '15px',
                              maxWidth: 'min-content',
                              minWidth: '15px',
                              cursor: 'pointer',
                            }}>
                            <Menuicon name="article" color="#ffffff" />
                          </Box>
                        </Box>

                        <Box
                          py={0.5}
                          px={1}
                          onClick={openArticle}
                          sx={{ cursor: 'pointer' }}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontSize: '15px' }}>
                            Compose an article
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider />

                      <Stack
                        direction="row"
                        sx={{ padding: '8px 16px', cursor: 'pointer' }}
                        onClick={openPoll}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}
                          onClick={openPoll}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <Menuicon name="poll" color="#ffffff" />
                          </Box>
                        </Box>

                        <Box py={0.5} px={2} onClick={openPoll}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontSize: '15px' }}>
                            Create a poll
                          </Typography>
                        </Box>
                      </Stack>
                      <Divider />
                      <Box></Box>
                    </Stack>
                  </Stack>
                </Box>
              </Backdrop>
            </Box>
          </Fragment>
        )}
        <Box px={1} py={1}>
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            <Card sx={{ width: '100%', position: 'relative' }}>
              <CardMedia
                component="img"
                alt="event Details"
                height="300"
                sx={{
                  objectFit: 'contain',
                  height: 300,
                  '@media (max-width: 500px)': {
                    margin: '0px auto',
                    height: 160,
                  },
                }}
                image={clubDetails?.image ? clubDetails?.image : ''}
              />

              <CardHeader
                sx={{
                  padding: '16px 24px 8px 32px',
                  '@media (max-width: 500px)': {
                    padding: '16px 0px 8px 16px',
                  },
                }}
                title={
                  <Box pt={{ md: 1.5, xs: 1 }}>
                    <Typography
                      component="h2s"
                      variant="h2s"
                      sx={{ fontSize: '18px' }}>
                      {clubDetails?.title ? clubDetails?.title : ''}
                    </Typography>
                  </Box>
                }
                subheader={
                  <Box>
                    <Box pt={1.4}>
                      {clubDetails?.is_admin === 0 && (
                        <Stack spacing={0.4} direction="row">
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '18px',
                                maxWidth: 'min-content',
                                minWidth: '18px',
                              }}>
                              <Menuicon name="club_owner" />
                            </Box>
                          </Box>
                          <Box>
                            {' '}
                            <Typography
                              variant="h5"
                              component="h5"
                              sx={{
                                fontSize: '15px',
                                color: '#ffffff',
                                fontWeight: 'revert',
                                lineHeight: '16px',
                                paddingLeft: '8px',
                              }}>
                              Owner :{' '}
                              {clubDetails?.owner_name
                                ? clubDetails?.owner_name
                                : ''}
                            </Typography>
                          </Box>
                          {/* <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              fontSize: "15px",
                              color: "#ffffff",
                              fontWeight: "revert",
                              lineHeight: "16px",
                              paddingLeft: "8px",
                            }}
                          >
                            Owner :{" "}
                            {clubDetails?.owner_name
                              ? clubDetails?.owner_name
                              : ""}
                          </Typography> */}
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              fontSize: '12px',
                              color: '#ffffff',
                              fontWeight: 'revert',
                            }}></Typography>
                        </Stack>
                      )}
                      <Stack
                        spacing={0.4}
                        sx={{ paddingTop: '16px' }}
                        direction="row">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            {clubDetails?.type === 'Public' && (
                              <Menuicon name="club_public" />
                            )}

                            {clubDetails?.type === 'Private' && (
                              <Menuicon name="club_private" />
                            )}
                          </Box>
                        </Box>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            fontSize: '15px',
                            color: '#ffffff',
                            fontWeight: 'revert',
                            lineHeight: '16px',
                            paddingLeft: '8px',
                          }}>
                          {clubDetails?.type ? clubDetails?.type : ''}
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            fontSize: '12px',
                            color: '#ffffff',
                            fontWeight: 'revert',
                          }}></Typography>
                      </Stack>
                    </Box>
                  </Box>
                }
                action={
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '8px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}
                      pr={0.5}>
                      {clubDetails?.is_admin === 0 && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '30px',
                            maxWidth: 'min-content',
                            minWidth: '30px',
                            cursor: 'pointer',
                          }}
                          onClick={bookmarkClub}>
                          <Menuicon name="club_bookmark" color="#ffffff" />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                      }}
                      pl={1}>
                      {/* <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "30px",
                          maxWidth: "min-content",
                          minWidth: "30px",
                        }}
                      >
                        <Menuicon name="clubshare" color="#ffffff" />
                      </Box> */}
                    </Box>
                    <IconButton
                      aria-label="settings"
                      onClick={handleMobileMenuOpen}>
                      <MoreVertIcon />
                    </IconButton>
                    {anchorEl && (
                      <Box sx={{ marginLeft: '0px!important' }}>
                        <Box
                          anchorEl={anchorEl}
                          id="primary-search-account-menu"
                          keepMounted
                          sx={{
                            marginTop: '23px',
                            scrollbarWidth: 'thin',
                            position: 'absolute',
                            backgroundColor: '#252525',
                            height: 'auto',
                            width: '164px',
                            marginLeft: '-158px',
                            zIndex: '1000',
                            boxShadow:
                              '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                            borderRadius: '2px',
                          }}
                          open={anchorEl}
                          onClose={handleMenuClose}>
                          {dropDown()}
                        </Box>
                      </Box>
                    )}
                  </Stack>
                }
              />
              <CardContent sx={{ paddingTop: '8px', paddingBottom: '0px' }}>
                <Box>
                  {clubDetails?.is_member === 1 &&
                    clubDetails?.is_admin === 1 && (
                      <Stack
                        direction="row"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <Box
                          pl={{ md: 2, xs: 0 }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}>
                          <Box
                            pl={{ md: 2, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}>
                            {clubDetails?.is_member === 1 &&
                              clubDetails?.is_admin === 0 && (
                                <CxoButton
                                  label={
                                    clubDetails?.member_count
                                      ? clubDetails?.member_count + ' Members'
                                      : 'O Member'
                                  }
                                  sx={{
                                    fontSize: '15px',
                                    width: '120px',
                                    '@media (max-width: 500px)': {
                                      width: '120px',
                                      height: '30px',
                                    },
                                  }}
                                  onClick={manageMembers}
                                />
                              )}
                            {}
                          </Box>
                        </Box>

                        <Stack
                          direction="column"
                          sx={{ margin: '-32px 0px 0px 0px' }}>
                          <Box
                            pl={{ md: 2, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}>
                            <Box
                              pl={{ md: 2, xs: 0 }}
                              py={1}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}>
                              <CxoButton
                                label="Create Event"
                                width="160px"
                                minWidth="160px"
                                onClick={createClubEvent}
                              />
                            </Box>
                          </Box>

                          {clubDetails?.is_member === 1 &&
                            clubDetails?.is_admin === 0 && (
                              <Box
                                pl={{ md: 2, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}>
                                <Box
                                  pl={{ md: 2, xs: 0 }}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                  }}>
                                  <CxoButton
                                    label="Leave"
                                    width="132px"
                                    onClick={leaveClub}
                                  />
                                </Box>
                              </Box>
                            )}
                        </Stack>
                      </Stack>
                    )}

                  {clubDetails?.is_member === 1 &&
                    clubDetails?.is_admin === 0 && (
                      <Stack
                        direction="row"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <Box
                          pl={{ md: 2, xs: 0 }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}>
                          <Box
                            pl={{ md: 2, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}>
                            {clubDetails?.is_member === 1 &&
                              clubDetails?.is_admin === 0 && (
                                <CxoButton
                                  label={
                                    clubDetails?.member_count
                                      ? clubDetails?.member_count + ' Members'
                                      : 'O Member'
                                  }
                                  sx={{
                                    fontSize: '15px',
                                    width: '120px',
                                    '@media (max-width: 500px)': {
                                      width: '120px',
                                      height: '30px',
                                    },
                                  }}
                                  onClick={manageMembers}
                                />
                              )}
                            {}
                          </Box>
                        </Box>

                        <Stack
                          direction="column"
                          sx={{ margin: '-32px 0px 0px 0px' }}>
                          <Box
                            pl={{ md: 2, xs: 0 }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}>
                            <Box
                              pl={{ md: 2, xs: 0 }}
                              py={1}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}>
                              <CxoButton
                                label="Create Event"
                                width="132px"
                                onClick={createClubEvent}
                              />
                            </Box>
                          </Box>

                          {clubDetails?.is_member === 1 &&
                            clubDetails?.is_admin === 0 && (
                              <Box
                                pl={{ md: 2, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}>
                                <Box
                                  pl={{ md: 2, xs: 0 }}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                  }}>
                                  <CxoButton
                                    label="Leave"
                                    width="132px"
                                    onClick={leaveClub}
                                  />
                                </Box>
                              </Box>
                            )}
                        </Stack>
                      </Stack>
                    )}
                  <Stack
                    direction="row"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Box
                      pl={{ md: 2, xs: 0 }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}>
                      {clubDetails?.is_member === 0 &&
                        clubDetails?.type === 'Private' && (
                          <Typography
                            component="h2s"
                            variant="h2s"
                            sx={{ fontSize: '15px' }}>
                            {clubDetails?.member_count
                              ? clubDetails?.member_count + ' Members '
                              : 'O Member'}
                          </Typography>
                        )}

                      {clubDetails?.is_member === 0 &&
                        clubDetails?.type === 'Public' && (
                          <CxoButton
                            label={
                              clubDetails?.member_count
                                ? clubDetails?.member_count + ' Members'
                                : 'O Member'
                            }
                            sx={{
                              width: '112px',
                              '@media (max-width: 500px)': {
                                width: '112px',
                                height: '30px',
                              },
                            }}
                            onClick={manageMembers}
                          />
                        )}
                    </Box>
                    <Stack direction="row" spacing={1}>
                      {clubDetails?.is_member === 0 &&
                        clubDetails?.is_already_invited === 1 && (
                          <Fragment>
                            <Box
                              pl={{ md: 2, xs: 0 }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}>
                              <CxoButton
                                label="Ignore"
                                width="112px"
                                onClick={clubDislike}
                              />
                            </Box>

                            <Box
                              pl={{ md: 2, xs: 0 }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}>
                              <CxoButton
                                label="Accept"
                                onClick={clubAccept}
                                width="112px"
                              />
                            </Box>
                          </Fragment>
                        )}

                      {clubDetails?.is_member === 0 &&
                        clubDetails?.is_already_invited === 0 &&
                        clubDetails?.is_already_requested === 1 && (
                          <Fragment>
                            <Box
                              pl={{ md: 2, xs: 0 }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}>
                              <CxoButton
                                label="Cancel Request"
                                width="112px"
                                onClick={cancelRequest}
                              />
                            </Box>
                          </Fragment>
                        )}

                      {clubDetails?.is_member === 0 &&
                        clubDetails?.is_already_invited === 0 &&
                        clubDetails?.is_already_requested === 0 && (
                          <Fragment>
                            <Box
                              pl={{ md: 2, xs: 0 }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}>
                              <CxoButton
                                label="Join"
                                width="112px"
                                onClick={joinClub}
                              />
                            </Box>
                          </Fragment>
                        )}
                    </Stack>
                  </Stack>
                  <Grid container>
                    {user?.id === clubDetails?.user_id && (
                      <Grid
                        item
                        xs={6}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Box
                          pl={{ md: 2, xs: 0 }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}>
                          {clubDetails?.is_member === 0 &&
                            clubDetails?.type === 'Private' && (
                              <Typography
                                component="h2s"
                                variant="h2s"
                                sx={{
                                  fontSize: '15px',
                                  width: '120px',
                                  '@media (max-width: 500px)': {
                                    width: '120px',
                                    height: '30px',
                                  },
                                }}>
                                {clubDetails?.member_count
                                  ? clubDetails?.member_count + ' Members'
                                  : 'O Member'}
                              </Typography>
                            )}
                          {clubDetails?.is_member === 1 &&
                            clubDetails?.is_admin === 1 && (
                              <CxoButton
                                label={
                                  clubDetails?.member_count
                                    ? clubDetails?.member_count + ' Members'
                                    : 'O Member'
                                }
                                // width="120px"
                                onClick={manageMembers}
                                sx={{
                                  width: '120px',
                                  '@media (max-width: 500px)': {
                                    width: '120px',
                                    height: '30px',
                                  },
                                }}
                              />
                            )}

                          {clubDetails?.is_member === 1 &&
                            clubDetails?.is_admin === 0 && (
                              <CxoButton
                                label={
                                  clubDetails?.member_count
                                    ? clubDetails?.member_count + ' Members'
                                    : 'O Member'
                                }
                                // width="120px"
                                onClick={manageMembers}
                                sx={{
                                  width: '120px',
                                  '@media (max-width: 500px)': {
                                    width: '120px',
                                    height: '30px',
                                  },
                                }}
                              />
                            )}

                          {clubDetails?.is_member === 0 &&
                            clubDetails?.type === 'Public' && (
                              <CxoButton
                                label={
                                  clubDetails?.member_count
                                    ? clubDetails?.member_count + ' Members'
                                    : 'O Member'
                                }
                                // width="120px"
                                onClick={manageMembers}
                                sx={{
                                  width: '120px',
                                  '@media (max-width: 500px)': {
                                    width: '120px',
                                    height: '30px',
                                  },
                                }}
                              />
                            )}
                        </Box>
                      </Grid>
                    )}
                    {clubDetails?.is_admin === 1 && (
                      <Grid
                        item
                        xs={6}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box
                          pl={0}
                          pt={0.5}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}>
                          <Stack
                            direction="row"
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              paddingTop: '8px',
                            }}>
                            <Box
                              pl={{ md: 2, xs: 0 }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}>
                              <Box
                                pl={{ md: 2, xs: 0 }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}>
                                <CxoButton
                                  label="Manage Members"
                                  onClick={manageMembers}
                                  width="112px"
                                />
                              </Box>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <Box py={2}>
                  <Divider />
                </Box>

                <ClubFriends />

                <Box pl={{ md: 2, xs: 0 }}>
                  <Grid container>
                    <Grid xs={3.5} md={2} item>
                      <Box pt={{ md: 1.8, xs: 1 }}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Category:{' '}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8.5} md={10} item>
                      <Box pt={{ md: 2, xs: 1.1 }}>
                        {clubDetails?.categories &&
                          clubDetails?.categories?.map((categories, index) => {
                            if (index > 0) {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {',  ' + categories?.title}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {categories?.title ? categories?.title : ''}
                                </Typography>
                              );
                            }
                          })}
                      </Box>
                    </Grid>

                    <Grid xs={3.5} md={2} item>
                      <Box pt={{ md: 1.8, xs: 1 }}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Industry:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8.5} md={10} item>
                      <Box pt={{ md: 2, xs: 1.5 }}>
                        {clubDetails?.industries &&
                          clubDetails?.industries?.map((industry, index) => {
                            if (index > 0) {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {',  ' + industry?.title}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {industry?.title ? industry?.title : ''}
                                </Typography>
                              );
                            }
                          })}
                      </Box>
                    </Grid>

                    <Grid xs={3.5} md={2} item>
                      <Box pt={{ md: 1.8, xs: 1 }}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Function:{' '}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8.5} md={10} item>
                      <Box pt={{ md: 2, xs: 1.5 }}>
                        {clubDetails?.functions &&
                          clubDetails?.functions?.map((industry, index) => {
                            if (index > 0) {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {',  ' + industry?.title}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {industry?.title ? industry?.title : ''}
                                </Typography>
                              );
                            }
                          })}
                      </Box>
                    </Grid>

                    <Grid xs={3.5} md={2} item>
                      <Box pt={{ md: 1.3, xs: 1.5 }}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Fees:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8.5} md={10} item>
                      <Box pt={{ md: 2, xs: 2 }}>
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          {clubDetails?.fee
                            ? clubDetails?.fee
                            : clubDetails?.fee}{' '}
                          {clubDetails?.currency ? clubDetails?.currency : ''}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid xs={3.5} md={2} item>
                      <Box pt={{ md: 1.5, xs: 1.5 }}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Email Id:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8.5} md={10} item>
                      <Box pt={2}>
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          {clubDetails?.email
                            ? clubDetails?.email
                            : clubDetails?.email}{' '}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid xs={3.5} md={2} item>
                      <Box pt={1.5}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Details:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8.5} md={10} item>
                      <Box pt={2}>
                        <Typography
                          variant="h2"
                          color="text.secondary"
                          sx={{
                            color: 'rgba(255, 255,255,1)',
                            whiteSpace: 'pre-line',
                            lineHeight: '21px',
                            letterSpacing: '1.2',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          {clubDetails?.detail ? clubDetails?.detail : ''}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box py={2}>
                  <Divider />
                </Box>
                <Box>
                  <ClubEvent />
                </Box>
              </CardContent>

              <CardActions>
                <Stack
                  direction="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                  <Box pl={{ md: 2, xs: 0 }} py={2}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h5"
                      sx={{ fontWeight: '600' }}>
                      {clubPostList?.length > 0 ? 'Posts' : ''}
                    </Typography>
                  </Box>
                  <Box
                    pl={{ md: 2, xs: 0 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}>
                    <Box
                      pl={{ md: 2, xs: 0 }}
                      pr={{ md: 1, xs: 0 }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}>
                      {clubDetails?.is_admin === 1 &&
                        clubDetails?.is_member === 1 && (
                          <CxoButton
                            label="Create Post"
                            onClick={openPost}
                            width="112px"
                          />
                        )}

                      {clubDetails?.is_admin === 0 &&
                        clubDetails?.is_member === 1 && (
                          <CxoButton
                            label="Create Post"
                            onClick={openPost}
                            width="112px"
                          />
                        )}
                    </Box>
                  </Box>
                </Stack>
              </CardActions>
            </Card>
          </Paper>

          {clubDetails?.is_admin === 1 && clubDetails?.is_member === 1 && (
            <ClubPosts fetchData={fetchData} />
          )}
          {clubDetails?.is_admin === 0 && clubDetails?.is_member === 1 && (
            <ClubPosts fetchData={fetchData} />
          )}

          {clubDetails?.is_admin === 0 &&
            clubDetails?.is_member === 0 &&
            clubDetails?.type === 'Public' && (
              <ClubPosts fetchData={fetchData} />
            )}
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(ClubDetails);

// {clubDetails?.is_admin === 1 &&
//   clubDetails?.is_member === 1 && <ClubEvent />}

// {clubDetails?.is_admin === 0 &&
//   clubDetails?.is_member === 1 && <ClubEvent />}
