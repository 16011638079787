import {
  GET_DESIGNATION_LIST_FULFILLED,
  GET_DESIGNATION_LIST_PENDING,
  GET_DESIGNATION_LIST_REJECTED,
  GET_INDUSTRY_LIST_FULFILLED,
  GET_INDUSTRY_LIST_PENDING,
  GET_INDUSTRY_LIST_REJECTED,
  GET_FUNCTION_LIST_FULFILLED,
  GET_FUNCTION_LIST_PENDING,
  GET_FUNCTION_LIST_REJECTED,
  GET_LOCATION_LIST_FULFILLED,
  GET_LOCATION_LIST_PENDING,
  GET_LOCATION_LIST_REJECTED,
  GET_SUB_INDUSTRY_LIST_FULFILLED,
  GET_SUB_INDUSTRY_LIST_PENDING,
  GET_SUB_INDUSTRY_LIST_REJECTED,
  GET_COUNTRY_CODE_LIST_FULFILLED,
  GET_COUNTRY_CODE_LIST_PENDING,
  GET_COUNTRY_CODE_LIST_REJECTED,
  POST_BIO_FULFILLED,
  POST_BIO_PENDING,
  POST_BIO_REJECTED,
  GET_INTEREST_LIST_FULFILLED,
  GET_INTEREST_LIST_PENDING,
  GET_INTEREST_LIST_REJECTED,
  SET_REDIRECT_URL,
} from './actions';

const defaultState = {
  loading: false,
  errors: {},

  isComment: '',
  designationList: [],
  bio: [],
  industryList: [],
  functionList: [],
  LocationList: [],
  subindustryList: [],
  countryCodeList: [],
  interestList: [],
  linkedinData: {},
  linkedinLoginSuccess: false,
  redirectUrlweb: '',
};
export const onboardreducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_DESIGNATION_LIST_PENDING:
      return { ...state, ...action.payload };
    case GET_DESIGNATION_LIST_FULFILLED:
      return { ...state, ...action.payload };
    case GET_DESIGNATION_LIST_REJECTED:
      return { ...state, ...action.payload };
    case GET_INDUSTRY_LIST_PENDING:
      return { ...state, ...action.payload };
    case GET_INDUSTRY_LIST_FULFILLED:
      return { ...state, ...action.payload };
    case GET_INDUSTRY_LIST_REJECTED:
      return { ...state, ...action.payload };
    case GET_FUNCTION_LIST_PENDING:
      return { ...state, ...action.payload };
    case GET_FUNCTION_LIST_FULFILLED:
      return { ...state, ...action.payload };
    case GET_FUNCTION_LIST_REJECTED:
      return { ...state, ...action.payload };
    case GET_LOCATION_LIST_PENDING:
      return { ...state, ...action.payload };
    case GET_LOCATION_LIST_FULFILLED:
      return { ...state, ...action.payload };
    case GET_LOCATION_LIST_REJECTED:
      return { ...state, ...action.payload };
    case GET_SUB_INDUSTRY_LIST_PENDING:
      return { ...state, ...action.payload };
    case GET_SUB_INDUSTRY_LIST_FULFILLED:
      return { ...state, ...action.payload };
    case GET_SUB_INDUSTRY_LIST_REJECTED:
      return { ...state, ...action.payload };
    case GET_COUNTRY_CODE_LIST_PENDING:
      return { ...state, ...action.payload };
    case GET_COUNTRY_CODE_LIST_FULFILLED:
      return { ...state, ...action.payload };
    case GET_COUNTRY_CODE_LIST_REJECTED:
      return { ...state, ...action.payload };
    case POST_BIO_PENDING:
      return { ...state, ...action.payload };
    case POST_BIO_FULFILLED:
      return { ...state, ...action.payload };
    case POST_BIO_REJECTED:
      return { ...state, ...action.payload };
    case GET_INTEREST_LIST_PENDING:
      return { ...state, ...action.payload };
    case GET_INTEREST_LIST_FULFILLED:
      return { ...state, ...action.payload };
    case GET_INTEREST_LIST_REJECTED:
      return { ...state, ...action.payload };

    // ======================================Get-User-profile-data-response=========================================================
    case 'USER_REGISTER_FIRST_PENDING': {
      return {
        ...state,
        UserProfileData: {},
        loading: true,
        errors: {},
      };
    }

    case 'USER_REGISTER_FIRST_FULFILLED': {
      return {
        ...state,
        UserProfileData: action.payload.data.data,
        loading: false,
        linkedinLoginSuccess: false,
        errors: {},
      };
    }

    case 'USER_REGISTER_FIRST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    case 'GET_LINKEDIN_DATA_FULFILLED': {
      return {
        ...state,
        linkedinData: action.payload.linkedIn,
        linkedinLoginSuccess: true,
        loading: false,
        errors: {},
      };
    }
    case SET_REDIRECT_URL: {
      return {
        ...state,
        redirectUrlweb: action.payload,
      };
    }
    default:
      return state;
  }
};
