import React, { Fragment, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import company from '../../assets/home/Bitmap_company.png';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ReactPlayer from 'react-player';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import pdf from '../../assets/testpdf.pdf';

import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as articleActions from '../../store/article';
import * as clubsActions from '../../store/clubs';
import * as activityActions from '../../store/activity';
import * as messageActions from '../../store/message';
import * as surveyActions from '../../store/survey';
import { dateTime } from '../../constants/app';

export default function AdsCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const [comments, setComment] = React.useState([]);
  const [postcomment, setPostComment] = React.useState('');
  const [postdetails, setPostdetails] = React.useState('');
  const [pollvalue, setPollValue] = React.useState('');
  const [voted, setVoted] = React.useState(false);
  const [trim, setTrim] = React.useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [play, setPlay] = React.useState(false);
  const playIn = () => setPlay(true);
  const pause = () => setPlay(false);
  const onChangeVisible = (isVisible) => {
    if (!isVisible) {
      setPlay(false);
    }
  };

  // pdf
  const seeMore = () => {
    if (!trim) {
      setPostdetails(convertLinks(props?.post?.content?.substring(0, 200)));
      setTrim(!trim);
    } else {
      setPostdetails(convertLinks(props?.post?.content));
      setTrim(!trim);
    }
  };
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const ads = useSelector((state) => state?.post?.ads);
  const clicks = useSelector((state) => state?.message?.noOfClicks);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const playerRef = useRef(null);

  const togglePlay = () => {
    setPlay(!play);
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    // setMobileMoreAnchorEl(event.currentTarget);
    // if ( user?.id === props?.post?.user_id){
    //   setAnchorEl(!anchorEl);
    // }
    setAnchorEl(!anchorEl);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let docment =
    'https://docs.google.com/viewer?url=' +
    props?.post?.document +
    '&embedded=true';

  useEffect(() => {
    if (props?.post?.type === 'poll') {
      props?.post?.pollOption?.some(function (el) {
        if (el.isChecked) {
          setPollValue(el.id);
          setVoted(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (props?.post?.content) {
      if (props?.post?.content?.length > 276 && trim) {
        setPostdetails(convertLinks(props?.post?.content?.substring(0, 200)));
      } else {
        setPostdetails(convertLinks(props?.post?.content));
      }
    }
  }, []);

  useEffect(() => {
    async function fetchAdsData() {
      const clubdet = await dispatch(postActions.getsAds());
    }
    fetchAdsData();
  }, []);

  useEffect(() => {
    async function fetchAdsImpression() {
      if (ads) {
        const adsclick = await dispatch(
          surveyActions.adsImpressions(ads[0]?.campaignId),
        );
      }
    }
    fetchAdsImpression();
  }, []);

  let subheadertitle =
    props?.post?.designation + ' || ' + props?.post?.company_name;
  let subheadertitleclub =
    'By ' + props?.post?.first_name + ' ' + props?.post?.last_name;
  const subheaderTitle = () => {
    let subhead = '';
    if (props?.post?.user_type === 'club') {
      subhead = 'By ' + props?.post?.first_name + ' ' + props?.post?.last_name;
      return subhead;
    } else if (props?.post?.user_type === 'user') {
      subhead = props?.post?.designation + ' || ' + props?.post?.company_name;
      return subhead;
    } else if (props?.post?.user_type === 'company_page') {
      return subhead;
    }
    return subhead;
  };

  const commentPost = async (type) => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: 'post',
        content_id: props?.post.id,
        datetime: dateTime(),
      };
      if (type === 'postcomment') {
        setExpanded(true);
        if (expanded) {
          const commentpost = await dispatch(postActions.getPostComments(data));
          console.log('commentpost', commentpost?.data);
          setComment(commentpost?.data);
        }
      }
      if (type === 'toggle') {
        setExpanded(!expanded);
        if (!expanded) {
          const commentpost = await dispatch(postActions.getPostComments(data));
          setComment(commentpost?.data);
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const updateComment = async (savecomment, id) => {
    if (user?.isVerified) {
      const data = {
        user_id: user?.id,
        type: 'post',
        content_id: props?.post?.id,
        content: savecomment,
        to_user_id: props?.post?.user_id,
        created_datetime: dateTime(),
        id: id,
      };
      const commentpost = await dispatch(postActions.manageComment(data));
      commentPost('postcomment');
      const userdata = {
        user_id: user.id,
        type: 'post',
        content_id: props?.post.id,
        datetime: dateTime(),
      };
      props.updatePost();
      const commentposts = await dispatch(
        postActions.getPostComments(userdata),
      );
      setComment(commentposts?.data);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const postComment = async (postcomment) => {
    if (user?.isVerified) {
      const data = {
        user_id: user?.id,
        type: 'post',
        content_id: props?.post?.id,
        content: postcomment,
        to_user_id: props?.post?.user_id,
        created_datetime: dateTime(),
        id: 0,
      };
      const commentpost = await dispatch(postActions.manageComment(data));
      commentPost('postcomment');
      props.updatePost();
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const timeago = () => {
    if (props?.post?.created_datetime === 'Invalid date') {
      return '';
    } else {
      return moment(props?.post?.new_created_datetime1).fromNow();
    }
  };

  const vadityleft = () => {
    // return props?.post?.pollRemainingTime;
    if (!props?.post?.is_poll_expired) {
      let deadline = moment(props?.post?.created_datetime)
        .add(props?.post?.validity, 'd')
        .format('YYYY-MM-DD HH:mm:ss');
      var b = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); //now
      var a = moment(deadline);
      let days = a.diff(b, 'days');
      let hr = a.diff(b, 'hours');
      let minutes = a.diff(b, 'minutes');
      if (days > 0) return days + ' days left';
      else if (days == 0 && hr > 0) {
        return hr + ' hours left';
      } else if (days == 0 && hr == 0) {
        return minutes + ' minutes left';
      }
    } else {
      return '';
    }

    //new

    //     if (props?.post?.validity === 1 &&  !props?.post?.is_poll_expired) {
    //       let deadline = moment(props?.post?.created_datetime).add(props?.post?.validity, 'd').format('YYYY-MM-DD HH:mm:ss');
    //       let ms = moment(deadline,"DD/MM/YYYY HH:mm:ss").diff(moment(new Date(),"DD/MM/YYYY HH:mm:ss"));
    //       // let d = moment.duration(ms).asDays();
    //       // // let s = d.format("hh:mm:ss");
    //       // let given = moment("26/03/2022 ", "DD/MM/YYYY HH:mm:ss");
    //       // let current =moment(new Date() ).format('DD/MM/YYYY HH:mm:ss');
    //       //
    //       // var now = moment(new Date()); //todays date
    //       // var end = moment("2022-03-26"); // another date
    //       // var duration = moment.duration(end.diff(now));
    //       // var days = duration.asDays();
    //       // console.log('days',days)
    //       // console.log('validity', deadline, ms,   d, current);
    //       console.log('deadline',deadline);
    //       var b = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');//now
    // var a = moment(deadline);
    //
    // console.log(a.diff(b, 'minutes')) // 44700
    // console.log(a.diff(b, 'hours')) // 745
    // console.log(a.diff(b, 'days')) // 31
    // console.log(a.diff(b, 'weeks')) // 4
    //
    //       console.log('validity left', props?.post?.id, moment().diff( props?.post?.created_datetime, 'days') );
    //       return props?.post?.validity - + " day left";
    //     } else if (props?.post?.validity > 1) {
    //       return props?.post?.validity + " days left";
    //     } else {
    //       return "";
    //     }
  };
  // const renderPdf = (url) =>{
  //   console.log('url', url);
  //   axios.get(url).then(response =>
  //     {
  //       const persons = response.data;
  //
  //       console.log('response', response);
  //     })
  // }

  const avatarReturn = (ad) => {
 
      return (
        <Avatar
          src={ad && ad?.companyImage ? ad?.companyImage : company}
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
  };

  const adClicked = async (ad) => {
    if (ad) {
      const adsclick = await dispatch(
        surveyActions.adsClick(ad.campaignId),
      );
      if(ad.clickUrl?.includes('https')){
        window.open(ad.clickUrl, '_blank');
      }else{
        window.open('https://'+ad.clickUrl, '_blank');
      }
    }
  };

  const userOrClubName = () => {
    if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length > 0
    ) {
      return props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length === 0
    ) {
      return props?.post?.clubDetails?.title;
    } else if (props?.post?.user_type === 'user') {
      return props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (props?.post?.user_type === 'company_page') {
      return props?.post?.company_page_name;
    }
  };

  const onError = (event) => {
    console.log('event', event);
  };
  const file =
    'https://emailmarketingmohanta.s3.ap-south-1.amazonaws.com/2022-03-31T05-17-40(2).pdf';
  const type = 'pdf';

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const profileOpen = async () => {
    if (user?.isVerified) {
      if (props?.post?.user_type != 'company_page') {
        const profile =
          props?.post?.first_name +
          '-' +
          props?.post?.last_name +
          '-' +
          'lx' +
          props?.post?.user_id;
        navigate('/user/' + profile);
      } else {
        const companyPage =
          props?.post?.company_page_name?.split(' ').join('-') +
          '-' +
          'lx' +
          props?.post?.company_page_id;
        navigate('/company/page/' + companyPage);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const clubOpen = async () => {
    console.log('clubOpen', props?.post);
    if (user?.isVerified) {
      const clubdetails = props?.post?.clubDetails?.title;
      const clubdetailsToOpen = await dispatch(
        clubsActions.openDetailsClub(props?.post?.clubDetails),
      );

      if (props?.post?.clubDetails?.title) {
        const clubdetails = props?.post?.clubDetails?.title;
        console.log('clubDetails', props?.club);
        if (props?.type !== 'SavedPassed') {
          navigate('/club/details/' + clubdetails);
        }
      } else {
        const clubdetails = props?.clubs?.title;
        console.log('clubDetails', props?.club?.type);
        if (props?.type !== 'SavedPassed') {
          navigate('/club/details/' + clubdetails);
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const dropDown = () => {
    if (
      user?.id === props?.post?.user_id &&
      props?.post?.type != 'poll' &&
      props?.post?.document_name != 'video'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }}>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (
      user?.id === props?.post?.user_id &&
      props?.post?.type === 'poll'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (user?.id != props?.post?.user_id) {
      // && props?.post?.type != "poll"
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }}>
              <ListItemText>Bookmarks</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }}>
              <ListItemText>Hide</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }}>
              <ListItemText>Report</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (
      user?.id === props?.post?.user_id &&
      props?.post?.document_name === 'video'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    }
  };

  const convertLinks = (input) => {
    let text = input;

    const linksFound = text.match(/(?:www|https?)[^\s]+/g);
    const aLink = [];

    if (linksFound != null) {
      for (let i = 0; i < linksFound.length; i++) {
        let replace = linksFound[i];
        if (!linksFound[i].match(/(http(s?)):\/\//)) {
          replace = 'http://' + linksFound[i];
        }
        let linkText = replace.split('/')[2];
        if (linkText.substring(0, 3) == 'www') {
          linkText = linkText.replace('www.', '');
        }
        if (linkText.match(/youtu/)) {
          let youtubeID = replace.split('/').slice(-1)[0];
          aLink.push(
            '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' +
              youtubeID +
              '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
          );
        } else if (linkText.match(/vimeo/)) {
          let vimeoID = replace.split('/').slice(-1)[0];
          aLink.push(
            '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
              vimeoID +
              '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
          );
        } else {
          aLink.push(
            '<a href="' + replace + '" target="_blank">' + linkText + '</a>',
          );
        }
        text = text
          .split(linksFound[i])
          .map((item) => {
            return aLink[i].includes('iframe') ? item.trim() : item;
          })
          .join(aLink[i]);
      }

      return text;
    } else {
      return input;
    }
  };
  if (ads && ads[props?.position]?.length > 0) {
    return (
      <>
      <Box sx={{width:"100%", maxWidth:"321px"}}>
        <Carousel
          showIndicators={false}
          showThumbs={false}
          infiniteLoop={true}
          showArrows={false}
          swipeable={true}
          fade={true}
          interval={props.interval}
          draggable={true}
          showDots={false}
          autoPlay={true}
          showStatus={false}
          ssr={true}
          infinite={true}
          autoPlaySpeed={200000}
          keyBoardControl={true}
          direction="left"
          customTransition="all .5"
          transitionDuration={20000}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
          sx={{ paddingBottom: '-50px' }}>
          {ads &&
            ads[props?.position]?.map((ad, index) => (
              <Box>
                <Card
                  sx={{
                    backgroundColor: '#252525',
                    backgroundImage: 'none',
                    position: 'relative',
                    marginTop: '8px',
                  }}>
                  <CardHeader
                    onClick={() => {
                      adClicked(ad);
                    }}
                    avatar={avatarReturn(ad)}
                    title={
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="h5s"
                          component="h5s"
                          sx={{
                            textAlign:"left",
                            '@media (max-width: 500px)': {
                              fontSize: '14px',
                              fontWeight: '700',
                            },
                          }}>
                          {ad && ad?.campaignName}
                        </Typography>
                      </Stack>
                    }
                    subheader={
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                          fontSize: '13px',
                          paddingTop: '3px',
                          textAlign: 'left',
                          color: 'rgba(255, 255,255,0.5)',
                          '@media (max-width: 500px)': { fontSize: '11px' },
                        }}>
                        Sponsored
                      </Typography>
                    }
                  />
                  <CardContent sx={{ paddingTop: '8px', paddingBottom: '4px' }}>
                    <Box
                      onClick={() => {
                        adClicked(ad);
                      }}
                      sx={{ cursor: 'pointer' }}>
                      <Typography
                        variant="h5s"
                        color="secondary"
                        sx={{
                          paddingBottom: '8px',
                          fontSize: '16px',
                          fontWeight: '600',
                          textAlign:'left',
                          '@media (max-width: 500px)': {
                            fontSize: '14px',
                            fontWeight: '500',
                          },
                        }}>
                        {props?.post?.heading}
                      </Typography>
                    </Box>
                    {ad &&
                      ad?.collateral?.map((link, index) => (
                        <Box>
                          {link.type.includes('video') && (
                            <>
                              <ReactPlayer
                                ref={playerRef}
                                height="auto"
                                width=""
                                className="react-player"
                                url={link.url}
                                onReady={() => console.log('onReady')}
                                onStart={() => {
                                  setPlay(true);
                                }}
                                onPlay={() => {
                                  setPlay(true);
                                }}
                                playing={play}
                                loop={true}
                                lite={true}
                                controls={false}
                                muted={muted}
                              />
                              <Box sx={{ position: 'relative' }}>
                                <Stack
                                  direction="row"
                                  sx={{
                                    justifyContent: 'space-between',
                                    padding: '0px 8px',
                                    marginTop: '-46px',
                                    zIndex: '1000',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.35)',
                                    },
                                  }}>
                                  <Box>
                                    <IconButton onClick={togglePlay}>
                                      {play ? <PauseIcon /> : <PlayArrowIcon />}
                                    </IconButton>
                                  </Box>
                                  <IconButton onClick={toggleMute}>
                                    {muted ? (
                                      <VolumeOffIcon />
                                    ) : (
                                      <VolumeUpIcon />
                                    )}
                                  </IconButton>
                                </Stack>
                              </Box>
                            </>
                          )}
                        </Box>
                      ))}
                    <Carousel
                      showIndicators={false}
                      showThumbs={false}
                      infiniteLoop={true}
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      autoPlay={true}
                      showStatus={false}
                      ssr={true}
                      infinite={true}
                      autoPlaySpeed={20000}
                      keyBoardControl={true}
                      direction="left"
                      customTransition="all .5"
                      transitionDuration={20}
                      containerClass="carousel-container"
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                      sx={{ paddingBottom: '-50px' }}>
                      {ad &&
                        ad?.collateral?.map((link, index) => (
                          <Box>
                            {link.type.includes('image') && (
                              <>
                                <img src={link.url} />
                              </>
                            )}
                          </Box>
                        ))}
                    </Carousel>
                    <Box  
                      p={1}
                      onClick={() => {
                        adClicked(ad);
                      }}
                      sx={{ cursor: 'pointer', justifyContent:'flex-start', display: 'flex' }}>
                      <span
                        style={{
                          fontSize: '18px',
                          lineHeight: '20px',
                          fontWeight: '600',
                          textAlign:"left"

                        }}>
                        {ad && ad?.title}
                      </span>
                    </Box>
                    <Box px={1}>
                      <p
                        style={{
                          color: '#A8A8A8',
                          fontSize: '15px',
                          lineHeight: '22px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          textAlign:'left'
                        }}>
                        {ad && ad?.description}
                      </p>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions disableSpacing></CardActions>
                </Card>
              </Box>
            ))}
        </Carousel>
        </Box>
      </>
    );
  } else {
    return '';
  }
}
