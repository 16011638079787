import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Paper, Grid, Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import CloseIcon from "@mui/icons-material/Close";
import ManIcon from "@mui/icons-material/Man";
import CxoButtonWithIcon from "../CxoButtonWithIcon/CxoButtonWithIcon";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import moment from "moment";

export default function NotificationCard(props) {
  console.log("props", props);
  const timeago = () => {
    if (props?.list?.datetime === "Invalid date") {
      return "";
    } else {
      return moment(props?.list?.datetime).fromNow();
    }
  };

  return (
    <Stack pt={2} spacing={0} direction="row">
      <Box
        sx={{
          minWidth: { xs: 100, md: 176 },
          maxHeight: { xs: 200, md: 167 },
          maxWidth: { xs: 300, md: 250 },
          backgroundColor: "#272727",
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        <Box pt={{ xs: 4, md: 2 }}>
          <Avatar
            src={props?.list?.image ? props?.list?.image : PersonIcon}
            sx={{
              width: 68,
              height: 68,
              "@media (max-width: 500px)": { width: 60, height: 60 },
            }}
          />
        </Box>
      </Box>
      <Card
        sx={{
          minWidth: 272,
          width: "100%",
          backgroundColor: "#272727",
          "@media (max-width: 500px)": { minWidth: 100 },
        }}
      >
        <CardHeader
          title={
            <Stack direction="row" spacing={1}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{
                  "@media (max-width: 500px)": { fontSize: "14px" },
                }}
              >
                {props?.list?.first_name} {props?.list?.last_name}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "Open Sans",
                  paddingTop: "3px",
                  color: "rgba(255, 255,255,0.5)",

                  "@media (max-width: 500px)": {
                    fontSize: "12px",
                    paddingTop: "0px",
                  },
                }}
              >
                {timeago()}
              </Typography>
            </Stack>
          }
          subheader={<Box></Box>}
        />

        <CardContent>
          <Typography variant="h6" component="h6">
            {props?.list?.message ? props?.list?.message : ""}
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
}

// <CardActions>
//   <CxoButtonWithIcon
//     icon={<CloseIcon />}
//     color="primary"
//     label="Remove"
//   />
//
//   <CxoButtonWithIcon icon={<ManIcon />} color="primary" label="Hide" />
// </CardActions>

//
// <IconButton aria-label="settings">
//   <MoreVertIcon />
// </IconButton>
