import React, { Fragment, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as messageActions from '../../store/message';
import * as postActions from '../../store/post';
import * as jobsActions from '../../store/jobs';
import * as rewardActions from '../../store/rewards';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { dateTime } from '../../constants/app';

export default function CustomizePackages(props) {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState('');
  const user = useSelector((state) => state?.user?.UserDetails);
  const customCorporatePackageOpen = useSelector(
    (state) => state?.rewards?.customCorporatePackage,
  );
  const customPackage = useSelector((state) => state?.rewards?.customPackage);
  const job = useSelector((state) => state?.jobs?.reportjob);
  const openPackage = useSelector((state) => state?.rewards?.openPackage);

  const handleSubmit = async () => {
    let sub_section_id = '';
    if (customPackage?.id) {
      sub_section_id = customPackage?.id;
    }
    const data = {
      user_id: user?.id,
      content: message,
      section_id: openPackage?.id,
      sub_section_id: sub_section_id,
    };
    const submitReport = await dispatch(
      rewardActions.corporateCutomiseProduct(data),
    );

    const snackbar = await dispatch(
      rewardActions.customCorporatePackageClose(),
    );
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(
      rewardActions.customCorporatePackageClose(),
    );
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={customCorporatePackageOpen}>
        <Box
          sx={{
            width: '700px',
            height: '280px',
            backgroundColor: '#1c1c1c',
            borderRadius: '10px',
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={0.5}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  Write to us for custom packages
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>

            {/* <Grid container sx={{ padding: "16px" }}></Grid> */}

            <Box
              pt={0.5}
              pl={2}
              m={1}
              sx={{
                zIndex: '1200',
                borderStyle: 'solid',
                borderWidth: 'thin',
                borderColor: '#343232',
              }}
              className="editor">
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={5}
                maxRows={6}
                value={message}
                onChange={handleChange}
                placeholder="Start Typing Here"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box
              p={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CxoButton label="Send" onClick={handleSubmit} />
            </Box>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
}
