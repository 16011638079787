import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CxoButton from '../../components/CxoButton/CxoButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import { dateTime } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import * as messageAction from '../../store/message';
import * as companyActions from '../../store/company';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
  root: {
    color: '#38CD84',
    '&$focused': {
      color: '#38CD84',
    },
  },
  selected: {
    width: 'auto',
    fontSize: '12px',
    lineHeight: '15px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontWeight: 200,
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: '12px',
    },
    '& li.Mui-selected': {
      color: 'white',
      background: '#6EC177',
    },
    '& li.Mui-selected:hover': {
      background: '#6EC177',
    },
  },
}));

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'green',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },

    '&:hover .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.palette.text.secondary,
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CssAutocomplete = styled(Autocomplete)(({ theme }) => {
  return {
    '& .MuiAutocomplete-root': {
      padding: '0px',
    },
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.palette.text.secondary,
      color: theme.palette.palette.text.black,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
    },
    '& .MuiList-root': {
      maxHeight: '60vh',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      padding: '1px 4px',
      marginRight: '5px',
      backgroundColor: theme.palette.palette.text.secondary,
      color: theme.palette.palette.text.black,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

const JobsCreate = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const [skill, setSkill] = useState('');
  const user = useSelector((state) => state?.user?.UserDetails);
  const companyDetails = useSelector((state) => state?.company?.companyDetails);
  const [industrylst, setIndustrylst] = useState([]);
  const [functionlst, setFunctionlst] = useState([]);
  const [jobtype, setJobType] = useState('Full Time');
  const [companyConfidential, setCompanyConfindential] = useState(0);
  const [salaryConfidential, setSalaryConfindential] = useState(0);
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [statuschange, setStatuschange] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [minArray, setMinArray] = useState([]);
  const [minyear, setMinYear] = useState();
  const [maxyear, setMaxYear] = useState();
  const [minArrayValue, setMinArrayValue] = useState([
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  ]);
  const [maxArrayValue, setMaxArrayValue] = useState([
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  ]);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const companyList = useSelector((state) => state?.company?.companyList);
  const [tempskill, setTempSkill] = useState([]);
  const [checked, setChecked] = useState(true);
  const [jobdescription, setJobdescription] = useState('');
  const [hiringFor, setHiringFor] = React.useState('Company');
  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [minSalaryValue, setMinSalaryValue] = useState([
    'open',
    'not_disclosed',
    50,
    60,
    70,
    80,
    90,
    100,
    150,
    200,
  ]);
  const [maxSalaryValue, setMaxSalaryValue] = useState([
    'open',
    'not_disclosed',
    50,
    60,
    70,
    80,
    90,
    100,
    150,
    200,
  ]);
  const handleHiringFor = (event) => {
    setHiringFor(event.target.value);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(async () => {
    const designationlist = await dispatch(onboardActions.getDesignationList());
  }, []);
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);
  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const companylist = await dispatch(companyActions.companyPageGet(data));
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrylst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSkill = (event) => {
    setSkill(event.target.value);
  };

  const minYearChange = (event) => {
    setMinYear(event.target.value);
    let temp = [];
    for (let i = event.target.value + 1; i < 25; i++) {
      temp.push(i);
    }
    setMaxArrayValue(temp);
  };
  const maxYearChange = (event) => {
    setMaxYear(event.target.value);
  };

  const minSalaryChange = (event) => {
    setMinSalary(event.target.value);
    let temp = [];
    if (event.target.value < 100) {
      temp.push('open');
      temp.push('not_disclosed');
      for (let i = event.target.value + 10; i < 110; i = i + 10) {
        temp.push(i);
      }
      temp.push(150);
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 100) {
      temp.push('open');
      temp.push('not_disclosed');
      temp.push(150);
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 150) {
      temp.push('open');
      temp.push('not_disclosed');
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 200) {
      temp.push('open');
      temp.push('not_disclosed');
      setMaxSalaryValue(temp);
    } else {
      setMaxSalaryValue(temp);
    }
  };

  const maxSalaryChange = (event) => {
    setMaxSalary(event.target.value);
  };
  const jobtypeChange = (event) => {
    setJobType(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      let temp = tempskill;
      temp.push(skill);
      setTempSkill(temp);
      setSkill('');
    }
  };

  const handleChecked = () => {
    setChecked(!checked);
    if (checked) {
      setFile('');
      setName('');
    }
  };

  const handleJobdescription = (event) => {
    setJobdescription(event.target.value);
  };

  const toggleCompanyConfidential = () => {
    if (companyConfidential === 0) {
      setCompanyConfindential(1);
    } else if (companyConfidential === 1) {
      setCompanyConfindential(0);
    }
  };

  const toggleSalaryConfidential = () => {
    if (salaryConfidential === 0) {
      setSalaryConfindential(1);
    } else if (salaryConfidential === 1) {
      setSalaryConfindential(0);
    }
  };

  const getDocument = async (event) => {
    setFile(event.target.files[0]);
    setType('document');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleDelete = async (e, skill, type) => {
    setTempSkill(tempskill.filter((e) => e !== skill));
  };

  const handleSubmitform = async (data) => {
    console.log('data', data);
    // const formData = {
    //   id: 0,
    //   title: data?.job_title,
    //   company_name : data?.company,
    //   aIndustry : industrylst,
    //   aFunction : functionlst,
    //   job_status : 1,
    //   salary: data?.salary,
    //   location: data?.location,
    //   job_type: jobtype,
    //   experience:"3-5 Years",
    //   hired_by: hiringFor,
    //   education: data?.qualifications,
    //   job_description:jobdescription,
    //   posted_on: dateTime(),
    //   user_id: user?.id,
    //   skills: '',
    //   jd_file: ""
    // }
    let maxsalary = 201;
    if (maxSalary) {
      maxsalary = maxSalary;
    }
    const formData = new FormData();
    formData.append('id', 0);
    formData.append('title', data?.job_title);
    formData.append('company_name', data?.company);
    formData.append('aIndustry', JSON.stringify(industrylst));
    formData.append('aFunction', JSON.stringify(functionlst));
    formData.append('job_status', 1);
    formData.append('min_salary', minSalary);
    formData.append('max_salary', maxsalary);
    formData.append('location', data?.location);
    formData.append('job_type', jobtype);
    formData.append('min_experience', minyear);
    formData.append('max_experience', maxyear);
    formData.append('hired_by', hiringFor);
    formData.append('education', data?.qualifications);
    formData.append('job_description', jobdescription);
    formData.append('user_id', user?.id);
    formData.append('skills', data?.skills);
    formData.append('posted_on', dateTime());
    formData.append('is_company_confidential', companyConfidential);
    formData.append('is_salary_confidential', salaryConfidential);
    formData.append('jd_file', file);
    if (location.pathname.includes('company')) {
      formData.append('user_type', 'company_page');
      formData.append('user_table_page', companyDetails?.id);
    } else {
      formData.append('user_type', 'user');
      formData.append('user_table_page', user?.id);
    }
    if (file || jobdescription) {
      if (
        jobtype &&
        minSalary &&
        maxsalary &&
        industrylst?.length > 0 &&
        functionlst?.length > 0 &&
        hiringFor
      ) {
        const managejobs = await dispatch(
          jobsActions.manageJob(
            formData,
            statuschange,
            0,
            (error, response) => {
              if (response) {
                reset();
                setIndustrylst([]);
                setFunctionlst([]);
                setJobdescription('');
                setMinSalary(50);
                setMaxSalary(60);
                setMaxYear(6);
                setMinYear(5);
                if (location.pathname.includes('company')) {
                  let rt =
                    '/company/manage/jobs/details/' +
                    companyDetails?.name +
                    '-lx' +
                    companyDetails?.id;
                  navigate(rt);
                } else {
                  navigate('/jobs/hire');
                }
              }
            },
          ),
        );
      }
    } else {
      let msg = 'Job Description Or JD file is Mandatory';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }

    // console.log('managejobs', managejobs, formData);
  };
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };
  return (
    <Fragment>
      <Box px={2} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0}>
            <Box
              py={3}
              px={3}
              sx={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
              }}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{
                  fontWeight: 600,
                  '@media (max-width: 500px)': {
                    fontSize: '20px',
                  },
                }}>
                Create Job
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>

      <Box px={2} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack direction="column" justifyContent="center" spacing={1}>
            {' '}
            {/* aparajita */}
            <Box px={{ sm: 14, xs: 0, md: 16 }}>
              {' '}
              <form onSubmit={handleSubmit(handleSubmitform)}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="designation" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: '100%',
                    }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack
                          sx={{
                            '@media (max-width: 500px)': {
                              paddingRight: '10px',
                              fontSize: '13px',
                              fontWeight: '500',
                            },
                          }}>
                          <CxoInputCss
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                                fontWeight: '500',
                              },
                            }}
                            label="Job Title *"
                            value={value}
                            err={errors?.job_title ? errors?.job_title : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.job_title?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="job_title"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Job Title is required',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>
                <Stack direction="column">
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '24px',
                            maxWidth: 'min-content',
                            minWidth: '24px',
                            '@media (max-width: 500px)': {
                              height: '18px',
                              minWidth: '18px',
                            },
                          }}>
                          <Menuicon name="entity" color="#38CD84" />
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          // <Autocomplete
                          //   id="country-select-demo3"
                          //   sx={{
                          //     width: '100%',
                          //     paddingRight: '5px',
                          //     paddingLeft: '12px',
                          //     paddingTop: '14px',
                          //   }}
                          //   value={selectedJob}
                          //   options={companyList}
                          //   autoHighlight
                          //   disableClearable
                          //   onChange={(_event, country) => {
                          //     setSelectedJob(country);
                          //   }}
                          //   getOptionLabel={(option) => {
                          //     return option.name;
                          //   }}
                          //   renderOption={(props, option) => (
                          //     <Box
                          //       component="li"
                          //       sx={{
                          //         '& > img': { mr: 2, flexShrink: 0 },
                          //         width: '100%',
                          //       }}
                          //       {...props}>
                          //       {option.name}
                          //     </Box>
                          //   )}
                          //   renderInput={(params) => (
                          //     <TextField
                          //       {...params}
                          //       inputProps={{
                          //         ...params.inputProps,
                          //         autoComplete: 'new-password', // disable autocomplete and autofill
                          //       }}
                          //       variant="standard"
                          //     />
                          //   )}
                          // />

                          <Stack>
                            <CxoInputCss
                              label="Company *"
                              value={value}
                              err={errors?.company ? errors?.company : false}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: '9px',
                                height: '10px',
                                paddingLeft: '8px',
                              }}>
                              {errors?.company?.message}{' '}
                            </Box>
                          </Stack>
                        )}
                        name="company"
                        required
                        rules={{
                          required: {
                            value: true,
                            message: 'Company Name is required',
                          },
                        }}
                        defaultValue=""
                      />
                    </Box>
                    <Box
                      px={1}
                      onClick={toggleCompanyConfidential}
                      sx={{
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        height: '60px',
                        width: '40px',
                      }}>
                      <Typography variant="h6" component="h6">
                        {!companyConfidential ? 'Hide' : 'Show'}
                      </Typography>
                    </Box>
                  </Stack>
                  <Box pl={5}>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ color: '#959498' }}>
                      Company name will be hidden to the candidates, if you
                      choose to hide it
                    </Typography>
                  </Box>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="industry" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: '#38CD84',
                          fontSize: '13px',
                        }}
                        id="demo-simple-select-label">
                        Industry *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industrylst}
                        multiple
                        label="Industry List"
                        onChange={handleChange}
                        size="small"
                        className={classes.underline}
                        color="secondary"
                        renderValue={(selected) => {
                          console.log('selected', selected);
                          return selected.length + ' ' + 'Selected';
                        }}>
                        {industryList?.data
                          ? industryList?.data.map((item, key) => {
                              return (
                                <MenuItem
                                  color="secondary"
                                  key={key}
                                  value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={item.title}
                                  />
                                  <Checkbox
                                    color="secondary"
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    checked={industrylst.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="functions" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: '#38CD84',

                          fontSize: '13px',
                        }}
                        id="demo-simple-select-label">
                        Function *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={functionlst}
                        label="Function"
                        multiple
                        className={classes.underline}
                        onChange={functionChange}
                        size="small"
                        renderValue={(selected) => {
                          console.log('selected', selected);
                          return selected.length + ' ' + 'Selected';
                        }}>
                        {functionList?.data
                          ? functionList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={item.title}
                                  />
                                  <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="secondary"
                                    checked={functionlst.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                            marginTop: '-30px',
                          },
                        }}>
                        <Menuicon name="salary" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Stack direction="column" sx={{ width: '100%' }}>
                    <Box
                      pl={1}
                      sx={{
                        '@media (max-width: 500px)': {
                          marginTop: '10px',
                        },
                      }}>
                      <Typography variant="h6s" component="h6s">
                        Salary (INR)
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
                      <Box
                        py={{ md: 1, xs: 0, sm: 1 }}
                        pl={1}
                        pr={1}
                        sx={{ width: '96%' }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel
                            style={{
                              color: '#38CD84',
                              fontSize: '13px',
                            }}
                            id="demo-simple-select-label">
                            Min
                          </InputLabel>
                          <CssSelect
                            MenuProps={{
                              fontSize: '12px',
                              disablePadding: true,
                              // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                              PaperProps: {
                                style: {
                                  maxHeight:
                                    ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                  width: 250,
                                },
                              },
                              variant: 'menu',
                              getContentAnchorEl: null,
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Function"
                            className={classes.underline}
                            value={minSalary}
                            onChange={minSalaryChange}
                            size="small">
                            {minSalaryValue
                              ? minSalaryValue.map((item, key) => {
                                  if (item < 100) {
                                    return (
                                      <MenuItem key={key} value={item}>
                                        {item} lacs
                                      </MenuItem>
                                    );
                                  } else if (item >= 100) {
                                    return (
                                      <MenuItem key={key} value={item}>
                                        {item / 100} Cr
                                      </MenuItem>
                                    );
                                  }
                                })
                              : ''}
                          </CssSelect>
                        </FormControl>
                      </Box>
                      <Box
                        py={{ md: 1, xs: 0, sm: 1 }}
                        pl={2}
                        sx={{ width: '96%' }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel
                            style={{
                              color: '#38CD84',

                              fontSize: '13px',
                            }}
                            id="demo-simple-select-label">
                            Max
                          </InputLabel>

                          {maxSalaryValue?.length === 0 ? (
                            <CssSelect
                              MenuProps={{
                                fontSize: '12px',
                                disablePadding: true,
                                // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                PaperProps: {
                                  style: {
                                    maxHeight:
                                      ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                  },
                                },
                                variant: 'menu',
                                getContentAnchorEl: null,
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Function"
                              className={classes.underline}
                              value={maxSalary}
                              onChange={maxSalaryChange}
                              size="small">
                              <MenuItem key="1000" value="open">
                                Open
                              </MenuItem>
                              <MenuItem key="1020" value="not_disclosed">
                                Not Disclosed
                              </MenuItem>
                            </CssSelect>
                          ) : (
                            <CssSelect
                              MenuProps={{
                                fontSize: '12px',
                                disablePadding: true,
                                // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                PaperProps: {
                                  style: {
                                    maxHeight:
                                      ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                  },
                                },
                                variant: 'menu',
                                getContentAnchorEl: null,
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Function"
                              className={classes.underline}
                              value={maxSalary}
                              onChange={maxSalaryChange}
                              size="small">
                              <MenuItem key="1000" value="open">
                                Open
                              </MenuItem>
                              <MenuItem key="1020" value="not_disclosed">
                                Not Disclosed
                              </MenuItem>
                              {maxSalaryValue
                                ? maxSalaryValue?.map((item, key) => {
                                    if (item < 100) {
                                      return (
                                        <MenuItem key={key} value={item}>
                                          {item} lacs
                                        </MenuItem>
                                      );
                                    } else if (item >= 100) {
                                      return (
                                        <MenuItem key={key} value={item}>
                                          {item / 100} Cr
                                        </MenuItem>
                                      );
                                    }
                                  })
                                : ''}
                            </CssSelect>
                          )}
                        </FormControl>
                      </Box>
                      <Box
                        px={1}
                        onClick={toggleSalaryConfidential}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          height: '60px',
                          width: '40px',
                        }}>
                        <Typography variant="h6" component="h6">
                          {!salaryConfidential ? 'Hide' : 'Show'}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box pl={1.5} pt={1}>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{ color: '#959498' }}>
                        Salary will be hidden to the candidates, if you choose
                        to hide it
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="location" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack
                          sx={{
                            '@media (max-width: 500px)': {
                              paddingRight: '10px',
                            },
                          }}>
                          <CxoInputCss
                            label="Location *"
                            value={value}
                            err={errors?.location ? errors?.location : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.location?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="location"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Location is required',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="jobtype" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: '#38CD84',
                          fontSize: '13px',
                        }}
                        id="demo-simple-select-label">
                        Job Type *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Function"
                        className={classes.underline}
                        value={jobtype}
                        onChange={jobtypeChange}
                        size="small">
                        <MenuItem key={0} value="Full Time">
                          Full Time
                        </MenuItem>
                        <MenuItem key={1} value="Part Time">
                          Part Time
                        </MenuItem>
                        <MenuItem key={1} value="Consulting">
                          Consulting
                        </MenuItem>
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="experience" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Stack direction="column" sx={{ width: '100%' }}>
                    <Box
                      pl={1}
                      sx={{
                        '@media (max-width: 500px)': {
                          marginTop: '10px',
                        },
                      }}>
                      <Typography variant="h6s" component="h6s">
                        Experience *
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
                      <Box
                        py={{ md: 1, xs: 0, sm: 1 }}
                        pl={1}
                        pr={1}
                        sx={{ width: '96%' }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel
                            style={{
                              color: '#38CD84',

                              fontSize: '13px',
                            }}
                            id="demo-simple-select-label">
                            Min
                          </InputLabel>
                          <CssSelect
                            MenuProps={{
                              fontSize: '12px',
                              disablePadding: true,
                              // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                              PaperProps: {
                                style: {
                                  maxHeight:
                                    ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                  width: 250,
                                },
                              },
                              variant: 'menu',
                              getContentAnchorEl: null,
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Function"
                            className={classes.underline}
                            value={minyear}
                            onChange={minYearChange}
                            size="small">
                            {minArrayValue
                              ? minArrayValue.map((item, key) => {
                                  return (
                                    <MenuItem key={key} value={item}>
                                      {item} years
                                    </MenuItem>
                                  );
                                })
                              : ''}
                          </CssSelect>
                        </FormControl>
                      </Box>
                      <Box
                        py={{ md: 1, xs: 0, sm: 1 }}
                        pl={2}
                        sx={{ width: '96%' }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel
                            style={{
                              color: '#38CD84',
                              fontSize: '13px',
                            }}
                            id="demo-simple-select-label">
                            Max
                          </InputLabel>
                          <CssSelect
                            MenuProps={{
                              fontSize: '12px',
                              disablePadding: true,
                              // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                              PaperProps: {
                                style: {
                                  maxHeight:
                                    ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                  width: 250,
                                },
                              },
                              variant: 'menu',
                              getContentAnchorEl: null,
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Function"
                            className={classes.underline}
                            value={maxyear}
                            onChange={maxYearChange}
                            size="small">
                            <MenuItem value="open">Open</MenuItem>
                            <MenuItem value="not_disclosed">
                              Not disclosed
                            </MenuItem>
                            {maxArrayValue
                              ? maxArrayValue.map((item, key) => {
                                  return (
                                    <MenuItem key={key} value={item}>
                                      {item} years
                                    </MenuItem>
                                  );
                                })
                              : ''}
                          </CssSelect>
                        </FormControl>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="hired_by" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pt={0.5} px={2} sx={{ width: '100%' }}>
                    <Stack spacing={2} direction="row">
                      <FormControl>
                        <FormLabel
                          color="secondary"
                          id="demo-row-radio-buttons-group-label"
                          sx={{
                            color: '#38CD84',
                            fontSize: '13px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                              marginTop: '10px',
                            },
                          }}>
                          Hiring For *
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          color="secondary"
                          value={hiringFor}
                          onChange={handleHiringFor}>
                          <FormControlLabel
                            value="Company"
                            color="secondary"
                            control={<Radio color="secondary" size="small" />}
                            label="Company"
                          />
                          <FormControlLabel
                            value="Client"
                            color="secondary"
                            control={<Radio color="secondary" size="small" />}
                            label="Client"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Box>
                </Stack>
                <Stack spacing={{ md: 1, xs: 0, sm: 1 }} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '14px',
                            minWidth: '14px',
                          },
                        }}>
                        <Menuicon name="educationtwo" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pl={1} sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack
                          sx={{
                            '@media (max-width: 500px)': {
                              paddingRight: '10px',
                            },
                          }}>
                          <CxoInputCss
                            label="Qualifications *"
                            value={value}
                            err={
                              errors?.qualifications
                                ? errors?.qualifications
                                : false
                            }
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.qualifications?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="qualifications"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Qualifications is required',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>

                <Stack spacing={{ md: 1, xs: 0, sm: 1 }} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="skills" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pl={1} sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack
                          sx={{
                            '@media (max-width: 500px)': {
                              paddingRight: '10px',
                            },
                          }}>
                          <CxoInputCss
                            label="Skills *"
                            value={value}
                            err={errors?.skills ? errors?.skills : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.skills?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="skills"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Skills is required',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>
                <Stack>
                  <Box pl={5} pb={2} pt={1}>
                    {tempskill?.map((skill, index) => {
                      return (
                        <CssChip
                          key={index}
                          className={classes.chipmain}
                          sx={{ margin: '4px' }}
                          label={skill}
                          variant="outlined"
                          onDelete={(e) => handleDelete(e, skill, 'new')}
                        />
                      );
                    })}
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="job_details" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pl={1} pt={2} sx={{ width: '100%' }}>
                    <Stack direction="column">
                      <Typography variant="h6s" component="h6s">
                        Job Details *
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between">
                        <Box>
                          {!checked && (
                            <Stack direction="row">
                              <Box pl={0}>
                                <label htmlFor="icon-button-doc">
                                  <Box
                                    py={2}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                    }}>
                                    <AttachFileOutlinedIcon color="secondary" />
                                  </Box>
                                </label>
                                <input
                                  id="icon-button-doc"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={getDocument}
                                  name="docum"
                                />
                              </Box>
                              <Box direction="column">
                                <Box py={1}>
                                  <Typography variant="h6s" component="h6s">
                                    Attach Resume
                                  </Typography>
                                </Box>
                                <Box>
                                  {name?.length > 0 ? (
                                    <Typography variant="h6" component="h6">
                                      {name}
                                    </Typography>
                                  ) : (
                                    <Typography variant="h6" component="h6">
                                      No file attached
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </Stack>
                          )}
                        </Box>

                        <Stack
                          direction="row"
                          sx={{
                            minHeight: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '16px',
                          }}>
                          <Box>
                            <AttachFileIcon />
                          </Box>
                          <Box>
                            <AntSwitch
                              onChange={handleChecked}
                              checked={checked}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: '18px',
                              '@media (max-width: 500px)': {
                                margin: '0px',
                              },
                            }}>
                            <Menuicon
                              width="43"
                              color="#ffffff"
                              name="editapp"
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>

                <Stack spacing={{ md: 1, sm: 1, xs: 0 }} direction="row">
                  <Box
                    pt={{ md: 2, sm: 2, xs: 0 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center' }}></Box>
                  </Box>

                  <Box pl={{ md: 4, sm: 3, xs: 0 }} sx={{ width: '100%' }}>
                    {checked && (
                      <Box pt>
                        <TextField
                          variant="standard"
                          fullWidth
                          multiline
                          rows={12}
                          maxRows={16}
                          value={jobdescription}
                          onChange={handleJobdescription}
                          sx={{
                            backgroundColor: '#262525',
                            padding: '8px',
                            '@media (max-width: 500px)': {
                              margin: '0px',
                            },
                          }}
                          placeholder="Start Typing Here"
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    '@media (max-width: 500px)': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}>
                  <CxoButton
                    label="Create"
                    sx={{
                      '@media (max-width: 500px)': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  />
                </Stack>
              </form>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobsCreate);
