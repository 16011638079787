import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import Applaud from '../../assets/home/Applaud_Active.png';
import Chat from '../../assets/home/Chat_Active.png';
import Comment from '../../assets/home/Comment_White.png';
import Share from '../../assets/home/Share_White.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import CommentCard from '../CommentCard/CommentCard';
import CommentReply from '../CommentCard/CommentReply';
import CommentSkeleton from '../CommentCard/CommentSkeleton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CardLongShare from './CardLongShare';
import Skeleton from '@mui/material/Skeleton';
import ReactPlayer from 'react-player';
import VideoLong from './VideoLong';
import Poll from './Poll';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import pdf from '../../assets/testpdf.pdf';

import { useSelector, useDispatch } from 'react-redux';
import * as postActions from '../../store/post';
import * as articleActions from '../../store/article';
import * as clubsActions from '../../store/clubs';
import * as activityActions from '../../store/activity';
import * as messageActions from '../../store/message';
import { dateTime } from '../../constants/app';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BorderLinearProgresssecond = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#38cd84' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 400],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#656363' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Box sx={{ minWidth: 50 }}>
        {props.ischecked ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              color: '#38cd84',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        )}
      </Box>
      <Box sx={{ minWidth: 60, width: '100%', mr: 1 }}>
        {props.ischecked ? (
          <BorderLinearProgresssecond
            variant="determinate"
            color="secondary"
            {...props}
          />
        ) : (
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            {...props}
          />
        )}
      </Box>
    </Box>
  );
}

export default function CardLong(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const [comments, setComment] = React.useState([]);
  const [postcomment, setPostComment] = React.useState('');
  const [postdetails, setPostdetails] = React.useState('');
  const [pollvalue, setPollValue] = React.useState('');
  const [voted, setVoted] = React.useState(false);
  const [trim, setTrim] = React.useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  // pdf
  const seeMore = () => {
    if (!trim) {
      setPostdetails(convertLinks(props?.post?.content?.substring(0, 200)));
      setTrim(!trim);
    } else {
      setPostdetails(convertLinks(props?.post?.content));
      setTrim(!trim);
    }
  };
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const commentloading = useSelector((state) => state?.post?.commentloading);
  const commentpostid = useSelector((state) => state?.post?.commentpostid);
  const postEndindex = useSelector((state) => state?.post?.postEndIndex);
  const clicks = useSelector((state) => state?.message?.noOfClicks);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handlePoll = async (event) => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        poll_id: props?.post?.id,
        option_id: parseInt(event.target.value),
        undo: false,
        datetime: dateTime(),
      };

      if (!voted) {
        setPollValue(event.target.value);
        const votepoll = await dispatch(postActions.postVotePoll(data));
        props.updatePost();
        setVoted(true);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    // setMobileMoreAnchorEl(event.currentTarget);
    // if ( user?.id === props?.post?.user_id){
    //   setAnchorEl(!anchorEl);
    // }
    setAnchorEl(!anchorEl);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let docment =
    'https://docs.google.com/viewer?url=' +
    props?.post?.document +
    '&embedded=true';

  useEffect(() => {
    if (props?.post?.type === 'poll') {
      props?.post?.pollOption?.some(function (el) {
        if (el.isChecked) {
          setPollValue(el.id);
          setVoted(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (props?.post?.content) {
      if (props?.post?.content?.length > 276 && trim) {
        setPostdetails(convertLinks(props?.post?.content?.substring(0, 200)));
      } else {
        setPostdetails(convertLinks(props?.post?.content));
      }
    }
  }, []);

  let subheadertitle =
    props?.post?.designation + ' || ' + props?.post?.company_name;
  let subheadertitleclub =
    'By ' + props?.post?.first_name + ' ' + props?.post?.last_name;
  const subheaderTitle = () => {
    let subhead = '';
    if (props?.post?.user_type === 'club') {
      subhead = 'By ' + props?.post?.first_name + ' ' + props?.post?.last_name;
      return subhead;
    } else if (props?.post?.user_type === 'user') {
      subhead = props?.post?.designation + ' || ' + props?.post?.company_name;
      return subhead;
    } else if (props?.post?.user_type === 'company_page') {
      return subhead;
    }
    return subhead;
  };

  const subheaderPost = () => {
    if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length > 0
    ) {
      if (props?.post?.designation && props?.post?.company_name) {
        return props?.post?.designation + ' || ' + props?.post?.company_name;
      } else {
        return '';
      }
    } else if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length === 0
    ) {
      return 'By ' + props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (props?.post?.user_type === 'user') {
      return props?.post?.designation + ' || ' + props?.post?.company_name;
    } else if (props?.post?.user_type === 'company_page') {
      return props?.post?.industry_title;
    }
  };

  const likePost = async () => {
    if (user?.isVerified) {
      // const formData = new FormData();
      // formData.append("content_id", props?.post?.id);
      //       formData.append("from_user_id", user?.id);
      // formData.append("type", props?.post?.type);
      // formData.append("to_user_id", props?.post?.user_id);
      // formData.append("user_type",'user');
      // formData.append("datetime", dateTime());
      //

      const postlike = await dispatch(
        postActions.postPostLike({
          content_id: props?.post?.id,
          type: props?.post?.type,
          from_user_id: user?.id,
          to_user_id: props?.post?.user_id,
          user_type: 'user',
          datetime: dateTime(),
        }),
      );
      props.updatePost();
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const sendComment = (event) => {
    setPostComment(event.target.value);
  };

  const deleteComment = async (id) => {
    const data = {
      user_id: user.id,
      id: id,
    };
    const deletecomment = await dispatch(postActions.getPostComments(data));
  };

  const commentPost = async (type) => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: 'post',
        content_id: props?.post.id,
        datetime: dateTime(),
      };
      if (type === 'postcomment') {
        setExpanded(true);
        if (expanded) {
          const commentpost = await dispatch(postActions.getPostComments(data));
          console.log('commentpost', commentpost?.data);
          setComment(commentpost?.data);
        }
      }
      if (type === 'toggle') {
        setExpanded(!expanded);
        if (!expanded) {
          const commentpost = await dispatch(postActions.getPostComments(data));
          setComment(commentpost?.data);
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const deleteCommentPost = async (data) => {
    const deletecmmnt = await dispatch(postActions.deleteComment(data));
    const userdata = {
      user_id: user.id,
      type: 'post',
      content_id: props?.post.id,
      datetime: dateTime(),
    };
    props.updatePost();
    const commentpost = await dispatch(postActions.getPostComments(userdata));
    setComment(commentpost?.data);
  };
  const bookmarkPost = async () => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: props?.post.type,
        content_id: props?.post.id,
        datetime: dateTime(),
      };
      const bookpost = await dispatch(activityActions.saveBookmark(data));
      setAnchorEl(!anchorEl);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const reportPost = async () => {
    // const data = {
    //   user_id : user.id,
    //   type : props?.post.type,
    //   content_id: props?.post.id,
    //   datetime: dateTime(),
    // }
    if (user?.isVerified) {
      const reportpost = await dispatch(
        postActions.openReportPost({ post: props.post, type: 'report' }),
      );
      setAnchorEl(!anchorEl);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const hidePost = async () => {
    if (user?.isVerified) {
      const data = {
        content_id: props?.post?.id,
        status: 'hide',
        user_id: user?.id,
        message: '',
        datetime: dateTime(),
        aReport_field: '',
      };
      const submitReport = await dispatch(postActions.contentAction(data));
      const data1 = {
        user_id: user?.id,
        start_item: 0,
        item_count: postEndindex,
        datetime: dateTime(),
      };

      const formData1 = new FormData();
      formData1.append('user_id', user?.id);
      formData1.append('start_item', 0);
      formData1.append('item_count', postEndindex);
      formData1.append('datetime', dateTime());

      const postlist = await dispatch(
        postActions.getPostList(formData1, data1),
      );

      setAnchorEl(!anchorEl);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const deletePost = async () => {
    if (user?.isVerified) {
      const hidepost = await dispatch(
        postActions.openUpdatePost({ post: props.post, type: 'delete' }),
      );
      const data = {
        user_id: user?.id,
        start_item: 0,
        item_count: postEndindex,
        datetime: dateTime(),
      };

      const formData1 = new FormData();
      formData1.append('user_id', user?.id);
      formData1.append('start_item', 0);
      formData1.append('item_count', postEndindex);
      formData1.append('datetime', dateTime());
      const postlist = await dispatch(postActions.getPostList(formData1, data));
      setAnchorEl(false);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const editPost = async () => {
    if (user?.isVerified) {
      if (props?.post.type === 'post') {
        const hidepost = await dispatch(
          postActions.openUpdatePost({ post: props.post, type: 'edit' }),
        );
      } else if (props?.post.type === 'article') {
        const hidepost = await dispatch(
          articleActions.openUpdateArticle({ post: props.post, type: 'edit' }),
        );
      }
      setAnchorEl(false);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const sharePost = async () => {
    // const data = {
    //   user_id: user.id,
    //   type: props?.post.type,
    //   content_id: props?.post.id,
    //   datetime: dateTime(),
    //   content: props?.post?.content,
    //   user_type: props?.post?.user_type,
    //   user_table_pk: props?.post?.user_table_pk,
    //   child_id: props?.post?.child_id,
    // };
    // const sharepost = await dispatch(postActions.sharePost(data));

    if (user?.isVerified) {
      const sharepost = await dispatch(
        postActions.openSharePost({ post: props.post }),
      );
      setAnchorEl(false);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const updateComment = async (savecomment, id) => {
    if (user?.isVerified) {
      const data = {
        user_id: user?.id,
        type: 'post',
        content_id: props?.post?.id,
        content: savecomment,
        to_user_id: props?.post?.user_id,
        created_datetime: dateTime(),
        id: id,
      };
      const commentpost = await dispatch(postActions.manageComment(data));
      commentPost('postcomment');
      const userdata = {
        user_id: user.id,
        type: 'post',
        content_id: props?.post.id,
        datetime: dateTime(),
      };
      props.updatePost();
      const commentposts = await dispatch(
        postActions.getPostComments(userdata),
      );
      setComment(commentposts?.data);
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const postComment = async (postcomment) => {
    if (user?.isVerified) {
      const data = {
        user_id: user?.id,
        type: 'post',
        content_id: props?.post?.id,
        content: postcomment,
        to_user_id: props?.post?.user_id,
        created_datetime: dateTime(),
        id: 0,
      };
      const commentpost = await dispatch(postActions.manageComment(data));
      commentPost('postcomment');
      props.updatePost();
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };
  const timeago = () => {
    if (props?.post?.created_datetime === 'Invalid date') {
      return '';
    } else {
      return moment(props?.post?.new_created_datetime1).fromNow();
    }
  };

  const vadityleft = () => {
    // return props?.post?.pollRemainingTime;
    if (!props?.post?.is_poll_expired) {
      let deadline = moment(props?.post?.created_datetime)
        .add(props?.post?.validity, 'd')
        .format('YYYY-MM-DD HH:mm:ss');
      var b = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); //now
      var a = moment(deadline);
      let days = a.diff(b, 'days');
      let hr = a.diff(b, 'hours');
      let minutes = a.diff(b, 'minutes');
      if (days > 0) return days + ' days left';
      else if (days == 0 && hr > 0) {
        return hr + ' hours left';
      } else if (days == 0 && hr == 0) {
        return minutes + ' minutes left';
      }
    } else {
      return '';
    }

    //new

    //     if (props?.post?.validity === 1 &&  !props?.post?.is_poll_expired) {
    //       let deadline = moment(props?.post?.created_datetime).add(props?.post?.validity, 'd').format('YYYY-MM-DD HH:mm:ss');
    //       let ms = moment(deadline,"DD/MM/YYYY HH:mm:ss").diff(moment(new Date(),"DD/MM/YYYY HH:mm:ss"));
    //       // let d = moment.duration(ms).asDays();
    //       // // let s = d.format("hh:mm:ss");
    //       // let given = moment("26/03/2022 ", "DD/MM/YYYY HH:mm:ss");
    //       // let current =moment(new Date() ).format('DD/MM/YYYY HH:mm:ss');
    //       //
    //       // var now = moment(new Date()); //todays date
    //       // var end = moment("2022-03-26"); // another date
    //       // var duration = moment.duration(end.diff(now));
    //       // var days = duration.asDays();
    //       // console.log('days',days)
    //       // console.log('validity', deadline, ms,   d, current);
    //       console.log('deadline',deadline);
    //       var b = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');//now
    // var a = moment(deadline);
    //
    // console.log(a.diff(b, 'minutes')) // 44700
    // console.log(a.diff(b, 'hours')) // 745
    // console.log(a.diff(b, 'days')) // 31
    // console.log(a.diff(b, 'weeks')) // 4
    //
    //       console.log('validity left', props?.post?.id, moment().diff( props?.post?.created_datetime, 'days') );
    //       return props?.post?.validity - + " day left";
    //     } else if (props?.post?.validity > 1) {
    //       return props?.post?.validity + " days left";
    //     } else {
    //       return "";
    //     }
  };
  // const renderPdf = (url) =>{
  //   console.log('url', url);
  //   axios.get(url).then(response =>
  //     {
  //       const persons = response.data;
  //
  //       console.log('response', response);
  //     })
  // }

  const avatarReturn = () => {
    if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length > 0
    ) {
      return (
        <Avatar
          onClick={() => profileOpen()}
          src={props?.post?.user_image ? props?.post?.user_image : PersonIcon}
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    } else if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length === 0
    ) {
      return (
        <Avatar
          onClick={() => clubOpen()}
          src={
            props?.post?.clubDetails?.image
              ? props?.post?.clubDetails?.image
              : PersonIcon
          }
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    } else if (props?.post?.user_type === 'user') {
      return (
        <Avatar
          onClick={() => profileOpen()}
          src={props?.post?.user_image ? props?.post?.user_image : PersonIcon}
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    } else if (props?.post?.user_type === 'company_page') {
      return (
        <Avatar
          onClick={() => profileOpen()}
          src={
            props?.post?.company_page_image
              ? props?.post?.company_page_image
              : PersonIcon
          }
          sx={{ width: 48, height: 48, cursor: 'pointer' }}
        />
      );
    }
  };

  const userOrClubName = () => {
    if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length > 0
    ) {
      return props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (
      props?.post?.user_type === 'club' &&
      Object.keys(props?.post?.aChildPost)?.length === 0
    ) {
      return props?.post?.clubDetails?.title;
    } else if (props?.post?.user_type === 'user') {
      return props?.post?.first_name + ' ' + props?.post?.last_name;
    } else if (props?.post?.user_type === 'company_page') {
      return props?.post?.company_page_name;
    }
  };

  const onError = (event) => {
    console.log('event', event);
  };
  const file =
    'https://emailmarketingmohanta.s3.ap-south-1.amazonaws.com/2022-03-31T05-17-40(2).pdf';
  const type = 'pdf';

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const profileOpen = async () => {
    if (user?.isVerified) {
      if (props?.post?.user_type != 'company_page') {
        const profile =
          props?.post?.first_name +
          '-' +
          props?.post?.last_name +
          '-' +
          'lx' +
          props?.post?.user_id;
        navigate('/user/' + profile);
      } else {
        const companyPage =
          props?.post?.company_page_name?.split(' ').join('-') +
          '-' +
          'lx' +
          props?.post?.company_page_id;
        navigate('/company/page/' + companyPage);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const clubOpen = async () => {
    console.log('clubOpen', props?.post);
    if (user?.isVerified) {
      const clubdetails = props?.post?.clubDetails?.title;
      const clubdetailsToOpen = await dispatch(
        clubsActions.openDetailsClub(props?.post?.clubDetails),
      );

      if (props?.post?.clubDetails?.title) {
        const clubdetails = props?.post?.clubDetails?.title;
        console.log('clubDetails', props?.club);
        if (props?.type !== 'SavedPassed') {
          navigate('/club/details/' + clubdetails);
        }
      } else {
        const clubdetails = props?.clubs?.title;
        console.log('clubDetails', props?.club?.type);
        if (props?.type !== 'SavedPassed') {
          navigate('/club/details/' + clubdetails);
        }
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
      if (parseInt(user?.profile_percent) <= 25 && clicks > 10) {
        navigate('/registerall');
      }
    }
  };

  const dropDown = () => {
    if (
      user?.id === props?.post?.user_id &&
      props?.post?.type != 'poll' &&
      props?.post?.document_name != 'video'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={editPost}>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={deletePost}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (
      user?.id === props?.post?.user_id &&
      props?.post?.type === 'poll'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={deletePost}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (user?.id != props?.post?.user_id) {
      // && props?.post?.type != "poll"
      return (
        <Box>
          <List>
            <MenuItem
              button
              sx={{ padding: '2px 12px' }}
              onClick={bookmarkPost}>
              <ListItemText>Bookmarks</ListItemText>
            </MenuItem>

            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={hidePost}>
              <ListItemText>Hide</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={reportPost}>
              <ListItemText>Report</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    } else if (
      user?.id === props?.post?.user_id &&
      props?.post?.document_name === 'video'
    ) {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={deletePost}>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    }
  };

  const convertLinks = (input) => {
    let text = input;

    const linksFound = text.match(/(?:www|https?)[^\s]+/g);
    const aLink = [];

    if (linksFound != null) {
      for (let i = 0; i < linksFound.length; i++) {
        let replace = linksFound[i];
        if (!linksFound[i].match(/(http(s?)):\/\//)) {
          replace = 'http://' + linksFound[i];
        }
        let linkText = replace.split('/')[2];
        if (linkText.substring(0, 3) == 'www') {
          linkText = linkText.replace('www.', '');
        }
        if (linkText.match(/youtu/)) {
          let youtubeID = replace.split('/').slice(-1)[0];
          aLink.push(
            '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' +
              youtubeID +
              '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
          );
        } else if (linkText.match(/vimeo/)) {
          let vimeoID = replace.split('/').slice(-1)[0];
          aLink.push(
            '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
              vimeoID +
              '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
          );
        } else {
          aLink.push(
            '<a href="' + replace + '" target="_blank">' + linkText + '</a>',
          );
        }
        text = text
          .split(linksFound[i])
          .map((item) => {
            return aLink[i].includes('iframe') ? item.trim() : item;
          })
          .join(aLink[i]);
      }

      return text;
    } else {
      return input;
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: '#252525',
        backgroundImage: 'none',
        position: 'relative',
      }}>
      <CardHeader
        avatar={avatarReturn()}
        action={
          <Stack
            spacing={1}
            direction="row"
            sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label="settings" onClick={handleMobileMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            {anchorEl && (
              <Box sx={{ marginLeft: '0px!important' }}>
                <Box
                  anchorEl={anchorEl}
                  id="primary-search-account-menu"
                  keepMounted
                  sx={{
                    marginTop: '18px',
                    scrollbarWidth: 'thin',
                    position: 'absolute',
                    backgroundColor: '#252525',
                    height: 'auto',
                    width: '129px',
                    marginLeft: '-129px',
                    zIndex: '1000',
                    boxShadow:
                      '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                    borderRadius: '2px',
                  }}
                  open={anchorEl}
                  onClose={handleMenuClose}>
                  {dropDown()}
                </Box>
              </Box>
            )}
          </Stack>
        }
        title={
          <Stack direction="row" spacing={1}>
            <Typography
              variant="h5s"
              component="h5s"
              sx={{ '@media (max-width: 500px)': { fontSize: '14px' } }}>
              {userOrClubName()}
            </Typography>

            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontFamily: 'Open Sans',
                paddingTop: '3px',
                color: 'rgba(255, 255,255,0.5)',
                '@media (max-width: 500px)': { fontSize: '10px' },
              }}>
              {timeago()}
            </Typography>
          </Stack>
        }
        subheader={
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontSize: '13px',
              paddingTop: '3px',
              color: 'rgba(255, 255,255,0.5)',
              '@media (max-width: 500px)': { fontSize: '11px' },
            }}>
            {subheaderPost()}
          </Typography>
        }
      />

      {props?.post?.type === 'article' && (
        <CardContent sx={{ paddingTop: '8px', paddingBottom: '4px' }}>
          <Box>
            <Typography
              variant="h5s"
              color="secondary"
              sx={{
                paddingBottom: '8px',
                fontSize: '16px',
                fontWeight: '600',
                '@media (max-width: 500px)': {
                  fontSize: '14px',
                  fontWeight: '500',
                },
              }}>
              {props?.post?.heading}
            </Typography>
          </Box>
        </CardContent>
      )}

      {props?.post?.content ? (
        <CardContent sx={{ paddingTop: '0px' }}>
          <Typography
            variant="h2"
            color="text.secondary"
            sx={{
              color: 'rgba(255, 255,255,1)',
              whiteSpace: 'pre-line',
              lineHeight: '21px',
              letterSpacing: '1.2',
              '@media (max-width: 500px)': {
                fontSize: '13px',
                fontWeight: '500',
              },
            }}>
            {props?.post?.content?.length > 260 && trim
              ? props?.post?.content?.substring(0, 260)
              : props?.post?.content}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props?.post?.content?.length > 260 && trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',
                  // marginTop: "-20px",
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                    marginTop: '-20px',
                  },
                }}
                onClick={seeMore}>
                {' '}
                ...See more
              </Box>
            ) : (
              ' '
            )}
            {props?.post?.content?.length > 260 && !trim ? (
              <Box
                component="span"
                sx={{
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  color: '#38cd84',
                  '@media (max-width: 500px)': {
                    fontSize: '14px',
                    marginTop: '-20px',
                  },
                }}
                onClick={seeMore}>
                {' '}
                ...See less
              </Box>
            ) : (
              ''
            )}
          </Box>
        </CardContent>
      ) : (
        ''
      )}
      {/* {props?.post?.type === 'poll' ? (
        <Poll
          post={props?.post}
          handlePoll={handlePoll}
          pollvalue={pollvalue}
        />
      ) : (
        ' '
      )} */}
      {props?.post?.type === 'poll' ? (
        <CardContent sx={{ paddingTop: '8px', paddingBottom: '4px' }}>
          <Box px={{ md: 2, xs: 0 }}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: 'rgba(255, 255,255,1)',
                    paddingBottom: '8px',
                    fontSize: '16px',
                    fontWeight: '600',
                    '@media (max-width: 500px)': {
                      fontSize: '13px',
                      fontWeight: '500',
                    },
                  }}>
                  {props?.post?.heading}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
                value={pollvalue}
                onChange={handlePoll}
                color="secondary">
                {props?.post?.pollOption?.map((poll, index) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{ width: '100%' }}>
                      {props?.post?.is_poll_expired ? (
                        <FormControlLabel
                          key={index}
                          value={poll?.id}
                          control={<Box pl={1} />}
                          label={
                            poll?.isChecked ? (
                              <Typography
                                variant="h5s"
                                component="h5s"
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                    fontWeight: '500',
                                  },
                                }}>
                                {poll?.options}
                              </Typography>
                            ) : (
                              <Typography
                                variant="h5"
                                component="h5"
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                    fontWeight: '500',
                                  },
                                }}>
                                {poll?.options}
                              </Typography>
                            )
                          }
                        />
                      ) : (
                        <FormControlLabel
                          key={index}
                          value={poll?.id}
                          control={
                            voted ? (
                              <Box pl={2} />
                            ) : (
                              <Radio size="small" color="secondary" />
                            )
                          }
                          label={
                            poll?.isChecked ? (
                              <Typography
                                variant="h5s"
                                component="h5s"
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                    fontWeight: '500',
                                  },
                                }}>
                                {poll?.options}
                              </Typography>
                            ) : (
                              <Typography
                                variant="h5"
                                component="h5"
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '13px',
                                    fontWeight: '500',
                                  },
                                }}>
                                {poll?.options}
                              </Typography>
                            )
                          }
                        />
                      )}

                      {voted ? (
                        <Box
                          sx={{
                            maxWidth: 200,
                            width: '100%',
                            marginBottom: '12px!important',
                          }}>
                          <LinearProgressWithLabel
                            value={poll?.vote}
                            ischecked={poll?.isChecked}
                            color="secondary"
                          />
                        </Box>
                      ) : (
                        ''
                      )}
                    </Stack>
                  );
                })}
              </RadioGroup>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    maxWidth: 200,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      paddingBottom: '8px',
                      fontSize: '14px',
                      fontWeight: '600',
                      '@media (max-width: 500px)': {
                        fontSize: '13px',
                        fontWeight: '500',
                      },
                    }}>
                    {vadityleft()}
                  </Typography>
                </Box>
              </Box>
            </FormControl>
          </Box>
        </CardContent>
      ) : (
        ''
      )}

      {props?.post?.document_name && props?.post?.document_name != 'video' && (
        <Box px={2} pb={2}>
          <Typography
            variant="h6s"
            component="h6s"
            sx={{
              fontWeight: 600,
              fontSize: 18,
              '@media (max-width: 500px)': {
                fontSize: '13px',
                fontWeight: '500',
              },
            }}>
            {props?.post?.document_name ? props?.post?.document_name : ''}
          </Typography>
        </Box>
      )}

      {props?.post?.document && props?.post?.document_name != 'video' && (
        <>
          {/*  // <Pdf file={pdf} page={page} /> */}
          <iframe
            width="100%"
            height="500px"
            src={docment}
            frameBorder="0"></iframe>
          {/*
<PDFViewer url={pdf} />

        <Pdf file={pdf}  page={page}>
      {({ pdfDocument, pdfPage, canvas }) => (
        <>
          {!pdfDocument && <span>Loading...</span>}
          {canvas}
          {Boolean(pdfDocument && pdfDocument.numPages) && (
            <nav>
              <ul className="pager">
                <li className="previous">
                  <button
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous
                  </button>
                </li>
                <li className="next">
                  <button
                    disabled={page === pdfDocument.numPages}
                    onClick={() => setPage(page + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </>
      )}
    </Pdf>
*/}

          {/*    <div>
         <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
           <Page pageNumber={pageNumber} />
         </Document>
         <p>
           Page {pageNumber} of {numPages}
         </p>
       </div>
*/}
        </>
      )}

      {props?.post?.image && props?.post?.document_name != 'video' && (
        <CardMedia
          component="img"
          image={props?.post?.image}
          sx={{
            width: 'auto',
            margin: 'auto',
            maxHeight: '60vh',
            maxWidth: '100%',
          }}
          alt=" "
        />
      )}

      {props?.post?.image && props?.post?.document_name === 'video' && (
        <VideoLong
          post={props?.post}
          sx={{
            '@media (max-width: 500px)': {
              height: '100px',
              maxHeight: '70vh',
              width: 'auto',
            },
          }}
        />
      )}

      {props?.post?.aChildPost &&
        Object.keys(props?.post?.aChildPost).length != 0 && (
          <CardLongShare
            post={props.post.aChildPost}
            clubDetails={props?.post?.clubDetails}
            oPost={props?.post}
          />
        )}
      <Divider />
      <CardActions disableSpacing>
        {props?.post?.user_type === 'club' && (
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={likePost}>
                <IconButton
                  aria-label="applause"
                  sx={{
                    width: '40px',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="applause"
                      color={
                        props?.post?.liked_by_me == 0 ? '#ffffff' : '#38cd84'
                      }
                    />
                  </Box>
                </IconButton>
                <Typography
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '13px',
                      fontWeight: '500',
                    },
                  }}
                  variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                  component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                  {props?.post?.like_count ? props?.post?.like_count : 0}{' '}
                  Applauses
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => commentPost('toggle')}>
                <IconButton aria-label="share">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="comment"
                      color={
                        props?.post?.comment_count == 0 ? '#ffffff' : '#38cd84'
                      }
                    />
                  </Box>
                </IconButton>
                <Typography
                  variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                  component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                  sx={{
                    color: '#38cd84',
                    '@media (max-width: 500px)': {
                      fontSize: '13px',
                      fontWeight: '500',
                    },
                  }}>
                  {props?.post?.comment_count ? props?.post?.comment_count : 0}{' '}
                  Comments
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}

        {props?.post?.type != 'poll' &&
          props?.post?.user_type === 'company_page' && (
            <Grid container>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack
                  spacing={{ xs: 0, sm: 1, md: 1 }}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={likePost}>
                  <IconButton
                    aria-label="applause"
                    sx={{
                      width: '40px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '20px',
                        width: 'fit-content',
                      }}>
                      <Icon
                        name="applause"
                        color={
                          props?.post?.liked_by_me == 0 ? '#ffffff' : '#38cd84'
                        }
                      />
                    </Box>
                  </IconButton>
                  <Stack direction="row" spacing={0.5}>
                    <Typography
                      variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                      component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                      {props?.post?.like_count ? props?.post?.like_count : 0}{' '}
                    </Typography>
                    <Typography
                      variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                      component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                      Applauses
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <Stack
                  spacing={{ xs: 0, sm: 1, md: 1 }}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '@media (max-width: 500px)': { marginLeft: '15px' },
                  }}
                  onClick={() => commentPost('toggle')}>
                  <IconButton aria-label="share">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '16px',
                        width: 'fit-content',
                      }}>
                      <Icon
                        name="comment"
                        color={
                          props?.post?.comment_count > 0 ? '#38cd84' : '#ffffff'
                        }
                      />
                    </Box>
                  </IconButton>
                  <Stack direction="row" spacing={0.5}>
                    {' '}
                    <Typography
                      variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                      component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      {props?.post?.comment_count
                        ? props?.post?.comment_count
                        : 0}{' '}
                    </Typography>{' '}
                    <Typography
                      variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                      component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                      sx={{ display: 'flex', justifyContent: 'center' }}>
                      Comments
                    </Typography>
                  </Stack>
                  {/* <Typography
                  variant="h6"
                  component="h6"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {props?.post?.comment_count ? props?.post?.comment_count : 0}{" "}
                  Comments
                </Typography> */}
                </Stack>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={sharePost}>
                  <IconButton aria-label="share">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <img
                        alt="Share"
                        className={{ height: '20px' }}
                        src={Share}
                      />
                    </Box>
                  </IconButton>
                  <Typography variant="h6" component="h6">
                    Share
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          )}

        {props?.post?.type === 'poll' &&
          props?.post?.user_type === 'company_page' && (
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={likePost}>
                  <IconButton
                    aria-label="applause"
                    sx={{
                      width: '40px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '20px',
                        width: 'fit-content',
                      }}>
                      <Icon
                        name="applause"
                        color={
                          props?.post?.liked_by_me > 0 ? '#38cd84' : '#ffffff'
                        }
                      />
                    </Box>
                  </IconButton>
                  <Typography
                    variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                    {props?.post?.like_count ? props?.post?.like_count : 0}{' '}
                    Applauses
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => commentPost('toggle')}>
                  <IconButton aria-label="share">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '20px',
                        width: 'fit-content',
                      }}>
                      <Icon
                        name="comment"
                        color={
                          props?.post?.comment_count == 0
                            ? '#ffffff'
                            : '#38cd84'
                        }
                      />
                    </Box>
                  </IconButton>
                  <Typography
                    variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}>
                    {props?.post?.comment_count
                      ? props?.post?.comment_count
                      : 0}{' '}
                    Comments
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          )}
        {props?.post?.type != 'poll' && props?.post?.user_type === 'user' && (
          <Grid container>
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={{ xs: 0, sm: 1, md: 1 }}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={likePost}>
                <IconButton
                  aria-label="applause"
                  sx={{
                    width: '40px',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="applause"
                      color={
                        props?.post?.liked_by_me == 0 ? '#ffffff' : '#38cd84'
                      }
                    />
                  </Box>
                </IconButton>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                    {props?.post?.like_count ? props?.post?.like_count : 0}{' '}
                  </Typography>
                  <Typography
                    variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                    Applauses
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Stack
                spacing={{ xs: 0, sm: 1, md: 1 }}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '@media (max-width: 500px)': { marginLeft: '15px' },
                }}
                onClick={() => commentPost('toggle')}>
                <IconButton aria-label="share">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '16px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="comment"
                      color={
                        props?.post?.comment_count > 0 ? '#38cd84' : '#ffffff'
                      }
                    />
                  </Box>
                </IconButton>
                <Stack direction="row" spacing={0.5}>
                  {' '}
                  <Typography
                    variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    {props?.post?.comment_count
                      ? props?.post?.comment_count
                      : 0}{' '}
                  </Typography>{' '}
                  <Typography
                    variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    Comments
                  </Typography>
                </Stack>
                {/* <Typography
                  variant="h6"
                  component="h6"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {props?.post?.comment_count ? props?.post?.comment_count : 0}{" "}
                  Comments
                </Typography> */}
              </Stack>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={sharePost}>
                <IconButton aria-label="share">
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      alt="Share"
                      className={{ height: '20px' }}
                      src={Share}
                    />
                  </Box>
                </IconButton>
                <Typography variant="h6" component="h6">
                  Share
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}

        {props?.post?.type === 'poll' && props?.post?.user_type === 'user' && (
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={likePost}>
                <IconButton
                  aria-label="applause"
                  sx={{
                    width: '40px',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="applause"
                      color={
                        props?.post?.liked_by_me == 0 ? '#ffffff' : '#38cd84'
                      }
                    />
                  </Box>
                </IconButton>
                <Typography
                  variant={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}
                  component={props?.post?.liked_by_me == 0 ? 'h6' : 'h6s'}>
                  {props?.post?.like_count ? props?.post?.like_count : 0}{' '}
                  Applauses
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => commentPost('toggle')}>
                <IconButton aria-label="share">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '20px',
                      width: 'fit-content',
                    }}>
                    <Icon
                      name="comment"
                      color={
                        props?.post?.comment_count == 0 ? '#ffffff' : '#38cd84'
                      }
                    />
                  </Box>
                </IconButton>
                <Typography
                  variant={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}
                  component={props?.post?.comment_count == 0 ? 'h6' : 'h6s'}>
                  {props?.post?.comment_count ? props?.post?.comment_count : 0}{' '}
                  Comments
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box>
          <CommentReply postComment={postComment} />
        </Box>
        <CardContent>
          {commentloading && (
            <Box>
              <CommentSkeleton
                commentpostid={commentpostid}
                postid={props?.post?.id}
              />
            </Box>
          )}
          {comments &&
            comments
              ?.slice(0)
              .reverse()
              .map((comment, index) => {
                return (
                  <CommentCard
                    deleteComment={deleteCommentPost}
                    updateComment={updateComment}
                    key={index}
                    comment={comment}
                  />
                );
              })}
        </CardContent>
      </Collapse>
    </Card>
  );
}
