import {
  GET_POST_LIST_FULFILLED,
  GET_POST_LIST_PENDING,
  GET_POST_LIST_REJECTED,
  POST_POST_LIKE_FULFILLED,
  POST_POLL_FULFILLED,
  POST_POLL_PENDING,
  POST_POLL_REJECTED,
  MANAGE_COMMENT_PENDING,
  MANAGE_COMMENT_FULFILLED,
  MANAGE_COMMENT_REJECTED,
  CLOSE_SURVEY_EDIT,
  OPEN_SURVEY_EDIT,
} from './actions';
const defaultState = {
  PostList: [],
  loading: true,
  errors: {},
  likepost: [],
  sharePostRes: {},
  deletePostRes: '',
  deleteCommentPostRes: '',
  addPostRes: '',
  addArticalsRes: '',
  addCommentPostRes: '',
  CommentPostList: [],
  UserReactionList: [],
  isComment: '',
  SurveyEdit: false,
  openQuery: false,
  PollEdit: false,
  ReportPost: false,
  SharePost: false,
  reportPost: {},
  sharePost: {},
  UpdateEditPost: false,
  updateEditPost: {},
  postStartIndex: 0,
  postEndIndex: 10,
  commentloading: false,
  commentpostid: 0,
  FeedbackShow: false,
  queryAnswerloading: false,
  queryAnswer: [],
};

export const surveyreducer = (state = defaultState, action) => {
  switch (action.type) {
    //===================================post-list======================
    case GET_POST_LIST_PENDING: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }
    case MANAGE_COMMENT_PENDING: {
      return {
        ...state,
        commentloading: true,
        loading: true,
        commentpostid: action.payload.content_id,
      };
    }
    case MANAGE_COMMENT_FULFILLED: {
      return {
        ...state,
        commentloading: false,
        loading: false,
      };
    }
    case MANAGE_COMMENT_REJECTED: {
      return {
        ...state,
        commentloading: false,
        loading: false,
      };
    }
    case GET_POST_LIST_FULFILLED: {
      return {
        ...state,
        PostList: action.payload.data.data,
        postEndIndex: action.payload.payload.item_count,
        loading: false,
        errors: {},
      };
    }

    case GET_POST_LIST_REJECTED: {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    // =======================like-post-response=============================================

    case 'LIKE_POST_LIST_PENDING': {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case POST_POST_LIKE_FULFILLED: {
      const i = state.likepost.findIndex(
        (_element) => _element.content_id === action.payload.post.content_id,
      );
      if (i > -1)
        state.likepost[i] = {
          content_id: action.payload.post.content_id,
          data: action.payload.data.data,
        };
      // (2)
      else
        state.likepost.push({
          content_id: action.payload.post.content_id,
          data: action.payload.data.data,
        });
      console.log('newState', state.likepost);
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case 'LIKE_POST_LIST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    // =================Share-Post-Response============================

    case 'SHARE_POST_PENDING': {
      return {
        ...state,
        sharePostRes: [],
        loading: true,
        errors: {},
      };
    }

    case 'SHARE_POST_FULFILLED': {
      return {
        ...state,
        sharePostRes: action.payload,

        loading: false,
        errors: {},
      };
    }

    case 'SHARE_POST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    // =====================Delete-post -response ====================================================

    case 'GET_FORM_REPORT_PENDING': {
      return {
        ...state,
        queryAnswerloading: true,
        errors: {},
      };
    }

    case 'GET_FORM_REPORT_FULFILLED': {
      return {
        ...state,
        queryAnswer: action?.payload?.data,
        queryAnswerloading: false,
        errors: {},
      };
    }

    case 'GET_FORM_REPORT_REJECTED': {
      return {
        ...state,
        queryAnswerloading: false,
        errors: { global: action.payload.message },
      };
    }

    case 'DELETE_POST_PENDING': {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case 'DELETE_POST_FULFILLED': {
      return {
        ...state,
        deletePostRes: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case 'DELETE_POST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    // ==============================Comment-post-list-response===================================================
    case 'GET_COMMENT_POST_LIST_PENDING': {
      return {
        ...state,
        CommentPostList: [],
        loading: true,
        errors: {},
      };
    }

    case 'GET_COMMENT_POST_LIST_FULFILLED': {
      return {
        ...state,
        CommentPostList: action.payload.data.data,

        loading: false,
        errors: {},
      };
    }

    case 'GET_COMMENT_POST_LIST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    // ========================Add-new-post-respose================================

    case 'SEND_ADD_NEW_POST_DATA_PENDING': {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case 'SEND_ADD_NEW_POST_DATA_FULFILLED': {
      return {
        ...state,
        addPostRes: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case 'SEND_ADD_NEW_POST_DATA_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    //============================User-reaction-response=================================

    case 'GET_USER_REACTION_LIST_PENDING': {
      return {
        ...state,
        UserReactionList: [],
        loading: true,
        errors: {},
      };
    }

    case 'GET_USER_REACTION_LIST_FULFILLED': {
      return {
        ...state,
        UserReactionList: action.payload.data.data,
        loading: false,
        errors: {},
      };
    }

    case 'GET_USER_REACTION_LIST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    // ======================Update/Post comments of particular post-response================================

    case 'SEND_NEW_COMMENT_POST_PENDING': {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case 'SEND_NEW_COMMENT_POST_FULFILLED': {
      return {
        ...state,
        addCommentPostRes: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case 'SEND_NEW_COMMENT_POST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    // ====================================Delete-comment-post-Response=============================

    case 'DELETE_COMMENT_POST_PENDING': {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case 'DELETE_COMMENT_POST_FULFILLED': {
      return {
        ...state,
        deleteCommentPostRes: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case 'DELETE_COMMENT_POST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    case POST_POLL_FULFILLED:
      return {
        ...state,
        PollEdit: false,
      };
    case POST_POLL_PENDING:
      return {
        ...state,
      };
    case POST_POLL_REJECTED:
      return {
        ...state,
      };

    case 'CLOSE_SURVEY_EDIT': {
      return {
        ...state,
        SurveyEdit: false,
      };
    }
    case 'OPEN_SURVEY_EDIT': {
      return {
        ...state,
        SurveyEdit: true,
      };
    }

    case 'CLOSE_QUERY_EDIT': {
      return {
        ...state,
        openQuery: false,
      };
    }
    case 'OPEN_QUERY_EDIT': {
      return {
        ...state,
        openQuery: true,
      };
    }
    case 'CLOSE_POLL_EDIT': {
      return {
        ...state,
        PollEdit: false,
      };
    }
    case 'OPEN_POLL_EDIT': {
      return {
        ...state,
        PollEdit: true,
      };
    }
    case 'CLOSE_REPORT_POST': {
      return {
        ...state,
        ReportPost: false,
        reportPost: {},
      };
    }
    case 'OPEN_REPORT_POST': {
      return {
        ...state,
        ReportPost: true,
        reportPost: action.payload,
      };
    }
    case 'CLOSE_SHARE_POST': {
      return {
        ...state,
        SharePost: false,
        sharePost: {},
      };
    }
    case 'OPEN_SHARE_POST': {
      return {
        ...state,
        SharePost: true,
        sharePost: action.payload,
      };
    }
    case 'CLOSE_UPDATE_POST': {
      return {
        ...state,
        UpdateEditPost: false,
        updateEditPost: {},
      };
    }
    case 'OPEN_UPDATE_POST': {
      return {
        ...state,
        UpdateEditPost: true,
        updateEditPost: action.payload,
      };
    }
    case 'CLOSE_HELP_FEEDBACK': {
      return {
        ...state,
        FeedbackShow: false,
      };
    }
    case 'OPEN_HELP_FEEDBACK': {
      return {
        ...state,
        FeedbackShow: true,
      };
    }
    default:
      return state;
  }
};
