import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Button, { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import Divider from '@mui/material/Divider';
import * as usernetworkActions from '../../store/usernetwork';
import * as rewardsActions from '../../store/rewards';
import credits_gold from '../../assets/header/Credits_Gold.png';
import External_Link_Active from '../../assets/header/External_Link_Active.png';
import CxoButton from '../../components/CxoButton/CxoButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const VoucherPurchased = (props) => {
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(1);
  const [showconfirmation, setShowConfirmation] = React.useState(false);

  useEffect(async () => {}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {}, []);

  const showConfirmation = () => {
    setShowConfirmation(!showconfirmation);
  };
  const purchaseVoucher = async () => {};

  const handleClose = async () => {};

  const visitWebsite = () => {};

  const copyVoucher = () => {};

  const redirectVoucher = () => {};

  return (
    <Fragment>
      {showconfirmation && (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            zIndex: '3001',
          }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showconfirmation}>
            <Box
              sx={{
                width: '80%',
                backgroundColor: '#1c1c1c',
                borderRadius: '10px',
                maxWidth: '600px',
              }}>
              <Stack spacing={0} sx={{ width: '100%', maxWidth: '600px' }}>
                <Box
                  px={3}
                  py={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CloseIcon
                    sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                    onClick={handleClose}
                  />
                </Box>

                <Stack direction="column">
                  <Stack direction="row">
                    <Box>
                      <Typography variant="h2s" component="h2s">
                        You don't have sufficient points to purchase this
                        voucher
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row">
                    <Box>
                      <Typography variant="h2s" component="h2s">
                        You have
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="h2s" component="h2s">
                        Rs 68.92
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row">
                    <Box>
                      <Typography variant="h2s" component="h2s">
                        You have
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="h2s" component="h2s">
                        Rs 68.92
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row">
                    <Box>
                      <Typography variant="h2s" component="h2s">
                        You have
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="h2s" component="h2s">
                        Rs 68.92
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                <CxoButton label="Purchase" />
              </Stack>
            </Box>
          </Backdrop>
        </Box>
      )}
    </Fragment>
  );
};

export default React.memo(VoucherPurchased);
