import { API, apiUrl } from '../../services';
import { http,} from '../../services/client';
import { showMessage } from '../message';
import { getAccessToken } from '../../services/authData';

export const GET_LINKEDIN_DATA_FULFILLED = 'GET_LINKEDIN_DATA_FULFILLED';
export const GET_LINKEDIN_DATA_PENDING = 'GET_LINKEDIN_DATA_PENDING';
export const GET_LINKEDIN_DATA_REJECTED = 'GET_LINKEDIN_DATA_REJECTED';

const getLinkedInDataRequest = () => ({
  type: GET_LINKEDIN_DATA_PENDING,
});

const getLinkedInDataFailure = (error) => ({
  type: GET_LINKEDIN_DATA_REJECTED,
  payload: { error: error },
});

const getLinkedInDataSuccess = (data) => ({
  type: GET_LINKEDIN_DATA_FULFILLED,
  payload: { linkedIn: data },
});

export const getLinkedInData = (payload) => {
  return (dispatch) => {
    dispatch(getLinkedInDataRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      console.log('interest token', token);
      API.http.post(
        {
          endPoint: apiUrl.GET_LINKEDIN_DATA,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getLinkedInDataFailure(error));
            resolve(error);
          } else {
            console.log(response);
            if (response?.status === 1) {
              dispatch(getLinkedInDataSuccess(response));
              resolve(response);
            } else {
              dispatch(getLinkedInDataFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

export const HOSTED_EVENT_PASSED_SUCCESS = 'HOSTED_EVENT_PASSED_SUCCESS';
export const HOSTED_EVENT_PASSED_REQUEST = 'HOSTED_EVENT_PASSED_REQUEST';
export const HOSTED_EVENT_PASSED_FAILURE = 'HOSTED_EVENT_PASSED_FAILURE';

const hostedEventPassedRequest = () => ({
  type: HOSTED_EVENT_PASSED_REQUEST,
});

const hostedEventPassedFailure = (error) => ({
  type: HOSTED_EVENT_PASSED_FAILURE,
  payload: { error: error },
});

const hostedEventPassedSuccess = (data) => ({
  type: HOSTED_EVENT_PASSED_SUCCESS,
  payload: data,
});

export const hostedEventPassed = (payload) => {
  return (dispatch) => {
    dispatch(hostedEventPassedRequest());
    return http
      .post(apiUrl.HOSTED_EVENT_PASSED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(hostedEventPassedSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(hostedEventPassedSuccess({ data: [] }));
            return response;
          } else {
            dispatch(hostedEventPassedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(hostedEventPassedFailure());
        return error;
      });
  };
};

export const HOSTED_EVENT_UPCOMING_SUCCESS = 'HOSTED_EVENT_UPCOMING_SUCCESS';
export const HOSTED_EVENT_UPCOMING_REQUEST = 'HOSTED_EVENT_UPCOMING_REQUEST';
export const HOSTED_EVENT_UPCOMING_FAILURE = 'HOSTED_EVENT_UPCOMING_FAILURE';

const hostedEventUpcommingRequest = (payload) => ({
  type: HOSTED_EVENT_UPCOMING_REQUEST,
  payload: { payld: payload },
});

const hostedEventUpcommingFailure = (error) => ({
  type: HOSTED_EVENT_UPCOMING_FAILURE,
  payload: { error: error },
});

const hostedEventUpcommingSuccess = (data, payload) => ({
  type: HOSTED_EVENT_UPCOMING_SUCCESS,
  payload: { event: data, payld: payload },
});

export const hostedEventUpcomming = (payload) => {
  return (dispatch) => {
    dispatch(hostedEventUpcommingRequest(payload));

    return http
      .post(apiUrl.HOSTED_EVENT_UPCOMING, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(hostedEventUpcommingSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(hostedEventUpcommingSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(hostedEventUpcommingFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(hostedEventUpcommingFailure());
        return error;
      });
  };
};

export const DISCOVER_EVENT_LIST_SUCCESS = 'DISCOVER_EVENT_LIST_SUCCESS';
export const DISCOVER_EVENT_LIST_REQUEST = 'DISCOVER_EVENT_LIST_REQUEST';
export const DISCOVER_EVENT_LIST_FAILURE = 'DISCOVER_EVENT_LIST_FAILURE';

const discoverEventListRequest = (payload) => ({
  type: DISCOVER_EVENT_LIST_REQUEST,
  payload: { payld: payload },
});

const discoverEventListFailure = (error) => ({
  type: DISCOVER_EVENT_LIST_FAILURE,
  payload: { error: error },
});

const discoverEventListSuccess = (data, payload) => ({
  type: DISCOVER_EVENT_LIST_SUCCESS,
  payload: { event: data, payld: payload },
});

export const discoverEventList = (payload) => {
  return (dispatch) => {
    dispatch(discoverEventListRequest(payload));
    return http
      .post(apiUrl.DISCOVER_EVENT_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(discoverEventListSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(discoverEventListSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(discoverEventListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(discoverEventListFailure());
        return error;
      });
  };
};

export const INVITED_EVENT_LIST_SUCCESS = 'INVITED_EVENT_LIST_SUCCESS';
export const INVITED_EVENT_LIST_REQUEST = 'INVITED_EVENT_LIST_REQUEST';
export const INVITED_EVENT_LIST_FAILURE = 'INVITED_EVENT_LIST_FAILURE';

const invitedEventListRequest = () => ({
  type: INVITED_EVENT_LIST_REQUEST,
});

const invitedEventListFailure = (error) => ({
  type: INVITED_EVENT_LIST_FAILURE,
  payload: { error: error },
});

const invitedEventListSuccess = (data, payload) => ({
  type: INVITED_EVENT_LIST_SUCCESS,
  payload: { event: data, payld: payload },
});

export const invitedEventList = (payload) => {
  return (dispatch) => {
    dispatch(invitedEventListRequest());
    return http
      .post(apiUrl.INVITED_EVENT_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(invitedEventListSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(invitedEventListSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(invitedEventListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(invitedEventListFailure());
        return error;
      });
  };
};

export const REQUESTED_EVENT_LIST_SUCCESS = 'REQUESTED_EVENT_LIST_SUCCESS';
export const REQUESTED_EVENT_LIST_REQUEST = 'REQUESTED_EVENT_LIST_REQUEST';
export const REQUESTED_EVENT_LIST_FAILURE = 'REQUESTED_EVENT_LIST_FAILURE';

const requestedEventListRequest = () => ({
  type: REQUESTED_EVENT_LIST_REQUEST,
});

const requestedEventListFailure = (error) => ({
  type: REQUESTED_EVENT_LIST_FAILURE,
  payload: { error: error },
});

const requestedEventListSuccess = (data) => ({
  type: REQUESTED_EVENT_LIST_SUCCESS,
  payload: data,
});

export const requestedEventList = (payload) => {
  return (dispatch) => {
    dispatch(requestedEventListRequest());
    return http
      .post(apiUrl.REQUESTED_EVENT_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(requestedEventListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(requestedEventListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(requestedEventListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(requestedEventListFailure());
        return error;
      });
  };
};

export const SAVED_EVENT_UPCOMING_SUCCESS = 'SAVED_EVENT_UPCOMING_SUCCESS';
export const SAVED_EVENT_UPCOMING_REQUEST = 'SAVED_EVENT_UPCOMING_REQUEST';
export const SAVED_EVENT_UPCOMING_FAILURE = 'SAVED_EVENT_UPCOMING_FAILURE';

const savedEventUpcomingRequest = () => ({
  type: SAVED_EVENT_UPCOMING_REQUEST,
});

const savedEventUpcomingFailure = (error) => ({
  type: SAVED_EVENT_UPCOMING_FAILURE,
  payload: { error: error },
});

const savedEventUpcomingSuccess = (data, payload) => ({
  type: SAVED_EVENT_UPCOMING_SUCCESS,
  payload: { event: data, payld: payload },
});

export const savedEventUpcoming = (payload) => {
  return (dispatch) => {
    dispatch(savedEventUpcomingRequest());
    return http
      .post(apiUrl.SAVED_EVENT_UPCOMING, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(savedEventUpcomingSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(savedEventUpcomingSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(savedEventUpcomingFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(savedEventUpcomingFailure());
        return error;
      });
  };
};

export const SAVED_EVENT_PASSED_SUCCESS = 'SAVED_EVENT_PASSED_SUCCESS';
export const SAVED_EVENT_PASSED_REQUEST = 'SAVED_EVENT_PASSED_REQUEST';
export const SAVED_EVENT_PASSED_FAILURE = 'SAVED_EVENT_PASSED_FAILURE';

const savedEventPassedRequest = () => ({
  type: SAVED_EVENT_PASSED_REQUEST,
});

const savedEventPassedFailure = (error) => ({
  type: SAVED_EVENT_PASSED_FAILURE,
  payload: { error: error },
});

const savedEventPassedSuccess = (data, payload) => ({
  type: SAVED_EVENT_PASSED_SUCCESS,
  payload: { event: data, payld: payload },
});

export const savedEventPassed = (payload) => {
  return (dispatch) => {
    dispatch(savedEventPassedRequest());
    return http
      .post(apiUrl.SAVED_EVENT_PASSED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(savedEventPassedSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(savedEventPassedSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(savedEventPassedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(savedEventPassedFailure());
        return error;
      });
  };
};

export const EVENT_DETAILS_SUCCESS = 'EVENT_DETAILS_SUCCESS';
export const EVENT_DETAILS_REQUEST = 'EVENT_DETAILS_REQUEST';
export const EVENT_DETAILS_FAILURE = 'EVENT_DETAILS_FAILURE';

const eventDetailsRequest = () => ({
  type: EVENT_DETAILS_REQUEST,
});

const eventDetailsFailure = (error) => ({
  type: EVENT_DETAILS_FAILURE,
  payload: { error: error },
});

const eventDetailsSuccess = (data) => ({
  type: EVENT_DETAILS_SUCCESS,
  payload: data,
});

export const eventDetails = (payload) => {
  return (dispatch) => {
    dispatch(eventDetailsRequest());
    return http
      .post(apiUrl.EVENT_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventDetailsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventDetailsFailure());
        return error;
      });
  };
};

export const EVENT_DETAILS_HOME_SUCCESS = 'EVENT_DETAILS_HOME_SUCCESS';
export const EVENT_DETAILS_HOME_REQUEST = 'EVENT_DETAILS_HOME_REQUEST';
export const EVENT_DETAILS_HOME_FAILURE = 'EVENT_DETAILS_HOME_FAILURE';

const eventDetailsHomeRequest = () => ({
  type: EVENT_DETAILS_HOME_REQUEST,
});

const eventDetailsHomeFailure = (error) => ({
  type: EVENT_DETAILS_HOME_FAILURE,
  payload: { error: error },
});

const eventDetailsHomeSuccess = (data) => ({
  type: EVENT_DETAILS_HOME_SUCCESS,
  payload: data,
});

export const eventDetailsHome = (payload) => {
  return (dispatch) => {
    dispatch(eventDetailsHomeRequest());
    return http
      .post(apiUrl.EVENT_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventDetailsHomeSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventDetailsHomeSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventDetailsHomeFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventDetailsHomeFailure());
        return error;
      });
  };
};

export const EVENT_REQUESTED_MEMBERS_SUCCESS =
  'EVENT_REQUESTED_MEMBERS_SUCCESS';
export const EVENT_REQUESTED_MEMBERS_REQUEST =
  'EVENT_REQUESTED_MEMBERS_REQUEST';
export const EVENT_REQUESTED_MEMBERS_FAILURE =
  'EVENT_REQUESTED_MEMBERS_FAILURE';

const eventRequestedMembersRequest = () => ({
  type: EVENT_REQUESTED_MEMBERS_REQUEST,
});

const eventRequestedMembersFailure = (error) => ({
  type: EVENT_REQUESTED_MEMBERS_FAILURE,
  payload: { error: error },
});

const eventRequestedMembersSuccess = (data) => ({
  type: EVENT_REQUESTED_MEMBERS_SUCCESS,
  payload: data,
});

export const eventRequestedMembers = (payload) => {
  return (dispatch) => {
    dispatch(eventRequestedMembersRequest());
    return http
      .post(apiUrl.EVENT_REQUESTED_MEMBERS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventRequestedMembersSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventRequestedMembersSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventRequestedMembersFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventRequestedMembersFailure());
        return error;
      });
  };
};

export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS';
export const CANCEL_EVENT_REQUEST = 'CANCEL_EVENT_REQUEST';
export const CANCEL_EVENT_FAILURE = 'CANCEL_EVENT_FAILURE';

const eventCancelRequest = () => ({
  type: CANCEL_EVENT_REQUEST,
});

const eventCancelFailure = (error) => ({
  type: CANCEL_EVENT_FAILURE,
  payload: { error: error },
});

const eventCancelSuccess = (data) => ({
  type: CANCEL_EVENT_SUCCESS,
  payload: data,
});

export const eventCancel = (payload) => {
  return (dispatch) => {
    dispatch(eventCancelRequest());
    return http
      .post(apiUrl.CANCEL_EVENT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventCancelSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventCancelSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventCancelFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventCancelFailure());
        return error;
      });
  };
};

export const EVENT_ATTENDING_MEMBERS_SUCCESS =
  'EVENT_ATTENDING_MEMBERS_SUCCESS';
export const EVENT_ATTENDING_MEMBERS_REQUEST =
  'EVENT_ATTENDING_MEMBERS_REQUEST';
export const EVENT_ATTENDING_MEMBERS_FAILURE =
  'EVENT_ATTENDING_MEMBERS_FAILURE';

const eventAttendingMembersRequest = () => ({
  type: EVENT_ATTENDING_MEMBERS_REQUEST,
});

const eventAttendingMembersFailure = (error) => ({
  type: EVENT_ATTENDING_MEMBERS_FAILURE,
  payload: { error: error },
});

const eventAttendingMembersSuccess = (data) => ({
  type: EVENT_ATTENDING_MEMBERS_SUCCESS,
  payload: data,
});

export const eventAttendingMembers = (payload) => {
  return (dispatch) => {
    dispatch(eventAttendingMembersRequest());
    return http
      .post(apiUrl.EVENT_ATTENDING_MEMBERS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventAttendingMembersSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventAttendingMembersSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventAttendingMembersFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventAttendingMembersFailure());
        return error;
      });
  };
};

export const EVENT_INVITED_MEMBERS_SUCCESS = 'EVENT_INVITED_MEMBERS_SUCCESS';
export const EVENT_INVITED_MEMBERS_REQUEST = 'EVENT_INVITED_MEMBERS_REQUEST';
export const EVENT_INVITED_MEMBERS_FAILURE = 'EVENT_INVITED_MEMBERS_FAILURE';

const eventInvitedMembersRequest = () => ({
  type: EVENT_INVITED_MEMBERS_REQUEST,
});

const eventInvitedMembersFailure = (error) => ({
  type: EVENT_INVITED_MEMBERS_FAILURE,
  payload: { error: error },
});

const eventInvitedMembersSuccess = (data) => ({
  type: EVENT_INVITED_MEMBERS_SUCCESS,
  payload: data,
});

export const eventInvitedMembers = (payload) => {
  return (dispatch) => {
    dispatch(eventInvitedMembersRequest());
    return http
      .post(apiUrl.EVENT_INVITED_MEMBERS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventInvitedMembersSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventInvitedMembersSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventInvitedMembersFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventInvitedMembersFailure());
        return error;
      });
  };
};

export const EVENT_REQUEST_ACCEPT_SUCCESS = 'EVENT_REQUEST_ACCEPT_SUCCESS';
export const EVENT_REQUEST_ACCEPT_REQUEST = 'EVENT_REQUEST_ACCEPT_REQUEST';
export const EVENT_REQUEST_ACCEPT_FAILURE = 'EVENT_REQUEST_ACCEPT_FAILURE';

const eventRequestAcceptRequest = () => ({
  type: EVENT_REQUEST_ACCEPT_REQUEST,
});

const eventRequestAcceptFailure = (error) => ({
  type: EVENT_REQUEST_ACCEPT_FAILURE,
  payload: { error: error },
});

const eventRequestAcceptSuccess = (data) => ({
  type: EVENT_REQUEST_ACCEPT_SUCCESS,
  payload: data,
});

export const eventRequestAccept = (payload) => {
  return (dispatch) => {
    dispatch(eventRequestAcceptRequest());
    return http
      .post(apiUrl.EVENT_REQUEST_ACCEPT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventRequestAcceptSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventRequestAcceptSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventRequestAcceptFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventRequestAcceptFailure());
        return error;
      });
  };
};

export const EVENT_REQUEST_REJECT_SUCCESS = 'EVENT_REQUEST_REJECT_SUCCESS';
export const EVENT_REQUEST_REJECT_REQUEST = 'EVENT_REQUEST_REJECT_REQUEST';
export const EVENT_REQUEST_REJECT_FAILURE = 'EVENT_REQUEST_REJECT_FAILURE';

const eventRequestRejectRequest = () => ({
  type: EVENT_REQUEST_REJECT_REQUEST,
});

const eventRequestRejectFailure = (error) => ({
  type: EVENT_REQUEST_REJECT_FAILURE,
  payload: { error: error },
});

const eventRequestRejectSuccess = (data) => ({
  type: EVENT_REQUEST_REJECT_SUCCESS,
  payload: data,
});

export const eventRequestReject = (payload) => {
  return (dispatch) => {
    dispatch(eventRequestRejectRequest());
    return http
      .post(apiUrl.EVENT_REQUEST_REJECT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventRequestRejectSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventRequestRejectSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventRequestRejectFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventRequestRejectFailure());
        return error;
      });
  };
};

export const EVENT_INVITATION_UNSEND_SUCCESS =
  'EVENT_INVITATION_UNSEND_SUCCESS';
export const EVENT_INVITATION_UNSEND_REQUEST =
  'EVENT_INVITATION_UNSEND_REQUEST';
export const EVENT_INVITATION_UNSEND_FAILURE =
  'EVENT_INVITATION_UNSEND_FAILURE';

const eventInvitationUnsendRequest = () => ({
  type: EVENT_INVITATION_UNSEND_REQUEST,
});

const eventInvitationUnsendFailure = (error) => ({
  type: EVENT_INVITATION_UNSEND_FAILURE,
  payload: { error: error },
});

const eventInvitationUnsendSuccess = (data) => ({
  type: EVENT_INVITATION_UNSEND_SUCCESS,
  payload: data,
});

export const eventInvitationUnsend = (payload) => {
  return (dispatch) => {
    dispatch(eventInvitationUnsendRequest());
    return http
      .post(apiUrl.EVENT_INVITATION_UNSEND, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventInvitationUnsendSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventInvitationUnsendSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventInvitationUnsendFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventInvitationUnsendFailure());
        return error;
      });
  };
};

export const EVENT_REQUEST_SUCCESS = 'EVENT_REQUEST_SUCCESS';
export const EVENT_REQUEST_REQUEST = 'EVENT_REQUEST_REQUEST';
export const EVENT_REQUEST_FAILURE = 'EVENT_REQUEST_FAILURE';

const eventRequestRequest = () => ({
  type: EVENT_REQUEST_REQUEST,
});

const eventRequestFailure = (error) => ({
  type: EVENT_REQUEST_FAILURE,
  payload: { error: error },
});

const eventRequestSuccess = (data) => ({
  type: EVENT_REQUEST_SUCCESS,
  payload: data,
});

export const eventRequest = (payload) => {
  return (dispatch) => {
    dispatch(eventRequestRequest());
    return http
      .post(apiUrl.EVENT_REQUEST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventRequestSuccess(response));
          showMessage({
            dispatch,
            message: ' Event Request Sent Successfully',
            variant: 'success',
          });
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventRequestSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventRequestFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventRequestFailure());
        return error;
      });
  };
};

export const EVENT_REQUEST_WITHDRAW_SUCCESS = 'EVENT_REQUEST_WITHDRAW_SUCCESS';
export const EVENT_REQUEST_WITHDRAW_REQUEST = 'EVENT_REQUEST_WITHDRAW_REQUEST';
export const EVENT_REQUEST_WITHDRAW_FAILURE = 'EVENT_REQUEST_WITHDRAW_FAILURE';

const eventRequestWithdrawRequest = () => ({
  type: EVENT_REQUEST_WITHDRAW_REQUEST,
});

const eventRequestWithdrawFailure = (error) => ({
  type: EVENT_REQUEST_WITHDRAW_FAILURE,
  payload: { error: error },
});

const eventRequestWithdrawSuccess = (data) => ({
  type: EVENT_REQUEST_WITHDRAW_SUCCESS,
  payload: data,
});

export const eventRequestWithdraw = (payload) => {
  return (dispatch) => {
    dispatch(eventRequestWithdrawRequest());
    return http
      .post(apiUrl.EVENT_REQUEST_WITHDRAW, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventRequestWithdrawSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventRequestWithdrawSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventRequestWithdrawFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventRequestWithdrawFailure());
        return error;
      });
  };
};

export const EVENT_STATUS_FOR_USER_SUCCESS = 'EVENT_STATUS_FOR_USER_SUCCESS';
export const EVENT_STATUS_FOR_USER_REQUEST = 'EVENT_STATUS_FOR_USER_REQUEST';
export const EVENT_STATUS_FOR_USER_FAILURE = 'EVENT_STATUS_FOR_USER_FAILURE';

const eventStatusForUserRequest = () => ({
  type: EVENT_STATUS_FOR_USER_REQUEST,
});

const eventStatusForUserFailure = (error) => ({
  type: EVENT_STATUS_FOR_USER_FAILURE,
  payload: { error: error },
});

const eventStatusForUserSuccess = (data) => ({
  type: EVENT_STATUS_FOR_USER_SUCCESS,
  payload: data,
});

export const eventStatusForUser = (payload) => {
  return (dispatch) => {
    dispatch(eventStatusForUserRequest());
    return http
      .post(apiUrl.EVENT_STATUS_FOR_USER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(eventStatusForUserSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(eventStatusForUserSuccess({ data: [] }));
            return response;
          } else {
            dispatch(eventStatusForUserFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(eventStatusForUserFailure());
        return error;
      });
  };
};

export const EVENT_INVITE_USER_SUCCESS = 'EVENT_INVITE_USER_SUCCESS';
export const EVENT_INVITE_USER_REQUEST = 'EVENT_INVITE_USER_REQUEST';
export const EVENT_INVITE_USER_FAILURE = 'EVENT_INVITE_USER_FAILURE';

const eventInviteUserRequest = () => ({
  type: EVENT_INVITE_USER_REQUEST,
});

const eventInviteUserFailure = (error) => ({
  type: EVENT_INVITE_USER_FAILURE,
  payload: { error: error },
});

const eventInviteUserSuccess = (data) => ({
  type: EVENT_INVITE_USER_SUCCESS,
  payload: data,
});

// export const eventInviteUser = (payload) => {
//   return (dispatch) => {
//     dispatch(eventInviteUserRequest());
//     return http
//       .post(apiUrl.EVENT_INVITE_USER, payload)
//       .then((response) => {
//         if (response?.status === 1) {
//           dispatch(eventInviteUserSuccess(response));
//           return response;
//         } else {
//           if (
//             response?.status === -1 &&
//             response?.message === "No record found"
//           ) {
//             dispatch(eventInviteUserSuccess({ data: [] }));
//             return response;
//           } else {
//             dispatch(eventInviteUserFailure(response));
//             return response;
//           }
//         }
//       })
//       .catch((error) => {
//         dispatch(eventInviteUserFailure());
//         return error;
//       });
//   };
// };

export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE_FAILURE = 'EVENT_UPDATE_FAILURE';

const eventUpdateRequest = () => ({
  type: EVENT_UPDATE_REQUEST,
});

const eventUpdateFailure = (error) => ({
  type: EVENT_UPDATE_FAILURE,
  payload: { error: error },
});

const eventUpdateSuccess = (data) => ({
  type: EVENT_UPDATE_SUCCESS,
  payload: data,
});

export const eventUpdate = (payload, statuschange, id, callback) => {
  return (dispatch) => {
    dispatch(eventUpdateRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.EVENT_UPDATE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(eventUpdateFailure(error));
          showMessage({
            dispatch,
            message: 'Event Update failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(eventUpdateSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          if (id === 0) {
            showMessage({
              dispatch,
              message: 'Event successfully Updated',
              variant: 'success',
            });
          } else if (id !== 0 && !statuschange) {
            showMessage({
              dispatch,
              message: 'Event successfully Updated',
              variant: 'success',
            });
          } else if (id !== 0 && statuschange) {
            showMessage({
              dispatch,
              message: 'Event successfully Updated',
              variant: 'success',
            });
          }
          callback(null, response);
          return response;
        }
      },
    );
    // .then((response) =>{
    //   return response
    // })
    // .catch((error) =>{
    //   return error
    // })
  };
};
// export const eventUpdate = (payload) => {
//   return (dispatch) => {
//     dispatch(eventUpdateRequest());
//     return http
//       .post(apiUrl.EVENT_UPDATE, payload)
//       .then((response) => {
//         if (response?.status === 1) {
//           dispatch(eventUpdateSuccess(response));
//           return response;
//         } else {
//           if (
//             response?.status === -1 &&
//             response?.message === "No record found"
//           ) {
//             dispatch(eventUpdateSuccess({ data: [] }));
//             return response;
//           } else {
//             dispatch(eventStatusForUserFailure(response));
//             return response;
//           }
//         }
//       })
//       .catch((error) => {
//         dispatch(eventUpdateFailure());
//         return error;
//       });
//   };
// };

export const eventInviteUser = (payload, callback) => {
  return (dispatch) => {
    dispatch(eventInviteUserRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.EVENT_INVITE_USER,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(eventInviteUserFailure(error));
          showMessage({
            dispatch,
            message: ' Event invitation sent failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(eventInviteUserSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          showMessage({
            dispatch,
            message: 'Event invitation sent successfully',
            variant: 'success',
          });
          callback(null, response);
          return response;
        }
      },
    );
  };
};

export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS';
export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST';
export const EVENT_CREATE_FAILURE = 'EVENT_CREATE_FAILURE';

const createEventRequest = () => ({
  type: EVENT_CREATE_REQUEST,
});

const createEventFailure = (error) => ({
  type: EVENT_CREATE_FAILURE,
  payload: { error: error },
});

const createEventSuccess = (data) => ({
  type: EVENT_CREATE_SUCCESS,
  payload: { jobs: data },
});

export const createEvent = (payload, statuschange, id, callback) => {
  return (dispatch) => {
    dispatch(createEventRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.EVENT_CREATE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(createEventFailure(error));
          showMessage({
            dispatch,
            message: 'Event Create failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          dispatch(createEventSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          if (id === 0) {
            showMessage({
              dispatch,
              message: 'Event successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && !statuschange) {
            showMessage({
              dispatch,
              message: 'Event successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && statuschange) {
            showMessage({
              dispatch,
              message: 'Event successfully created',
              variant: 'success',
            });
          }
          callback(null, response);
          return response;
        }
      },
    );
    // .then((response) =>{
    //   return response
    // })
    // .catch((error) =>{
    //   return error
    // })
  };
};

export const CLOSE_SHARE_EVENT = 'CLOSE_SHARE_EVENT';
export const OPEN_SHARE_EVENT = 'OPEN_SHARE_EVENT';

export const closeShareEvent = () => ({
  type: 'CLOSE_SHARE_EVENT',
});

export const openShareEvent = (eventdetails) => {
  return {
    type: 'OPEN_SHARE_EVENT',
    payload: eventdetails,
  };
};

export const CLOSE_SEND_INVITEES_EVENT = 'CLOSE_SEND_INVITEES_EVENT';
export const OPEN_SEND_INVITEES_EVENT = 'OPEN_SEND_INVITEES_EVENT';

export const closeSendInviteesEvent = () => ({
  type: 'CLOSE_SEND_INVITEES_EVENT',
});

export const openSendInviteesEvent = (eventdetails) => {
  return {
    type: 'OPEN_SEND_INVITEES_EVENT',
    payload: eventdetails,
  };
};

export const reportEventOpen = (eventd) => {
  return {
    type: 'REPORT_EVENT_OPEN',
    payload: eventd,
  };
};

export const reportEventClose = (eventd) => {
  return {
    type: 'REPORT_EVENT_CLOSE',
  };
};

export const editEventOpen = (eventd) => {
  return {
    type: 'EDIT_EVENT_OPEN',
    payload: eventd,
  };
};

export const editEventClose = () => {
  return {
    type: 'EDIT_EVENT_CLOSE',
  };
};
