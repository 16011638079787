import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ProfService from '../../resources/Industries - Icons/Professional Service.png';
import IT from '../../resources/Industries - Icons/IT.png';
import Tour from '../../resources/Industries - Icons/Tourism.png';
import Edu from '../../resources/Industries - Icons/Education.png';
import IndManu from '../../resources/Industries - Icons/Manufacture.png';
import Fmcg from '../../resources/Industries - Icons/FMCG.png';
import media from '../../resources/Industries - Icons/Media.png';
import Ecom from '../../resources/Industries - Icons/E-Commerce.png';
import Startup from '../../resources/Industries - Icons/Startup.png';
import RealState from '../../resources/Industries - Icons/Real Estate.png';
import Energy from '../../resources/Industries - Icons/Oil & Gas.png';
import BFSI from '../../resources/Industries - Icons/BFSI.png';
import Agriculture from '../../resources/Industries - Icons/Agriculture.png';
import Logistic from '../../resources/Industries - Icons/Transportation.png';
import Healthcare from '../../resources/Industries - Icons/Healthcare.png';
import Ngo from '../../resources/Industries - Icons/NGO.png';
import ETC from '../../resources/Industries - Icons/Others.png';

function Industries() {
  return (
    <>
      <Container fluid>
        <Row className="industry-section">
          <Col>
            <Row>
              <Col>
                <h2 className="interest-title">
                  Choose from a wide range of industrial sectors{' '}
                </h2>
              </Col>
            </Row>
            <Row className="interest-section-mobile1">
              <Col>
                <Row>
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame1">
                          <img
                            alt=""
                            src={ProfService}
                            className="interest-img1"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Professional Services
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={IT} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          IT & ITES
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={Tour} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Tourism & Hospitality
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={Edu} className="interest-img1" />
                        </div>{' '}
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Education & Training
                        </p>{' '}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame1">
                          <img alt="" src={IndManu} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Industrial & Manufacturing
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame1">
                          <img alt="" src={Fmcg} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          FMCG & Retail
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={media} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Media & Entertainment
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={Ecom} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          E-Commerce
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={Startup} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                        Startup Venture Capital (VC) & Private Equity (PE)


                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame1">
                          <img
                            alt=""
                            src={RealState}
                            className="interest-img1"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Real Estate & Construction
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={Energy} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Energy & Oil & Gas
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={BFSI} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          BFSI
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img
                            alt=""
                            src={Agriculture}
                            className="interest-img1"
                          />
                        </div>{' '}
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Agriculture & Allied Services
                        </p>{' '}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame1">
                          <img
                            alt=""
                            src={Logistic}
                            className="interest-img1"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                         Logistics & Transportation
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        {' '}
                        <div className="interest-frame1">
                          <img
                            alt=""
                            src={Healthcare}
                            className="interest-img1"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Healthcare & Pharma
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col>
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={Ngo} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Not For Profit & NGO
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {' '}
                    <Row>
                      {' '}
                      <Col className="logo-frame">
                        <div className="interest-frame1">
                          <img alt="" src={ETC} className="interest-img1" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {' '}
                      <Col className="logo-text">
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                          }}>
                          Others
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col></Col>
                </Row>
              </Col>
            </Row>
            <Row className="industries-row12">
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame1">
                      <img alt="" src={ProfService} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Professional Services
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={IT} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      IT & ITES
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={Tour} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Tourism & Hospitality
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={Edu} className="interest-img1" />
                    </div>{' '}
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Education & Training
                    </p>{' '}
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame1">
                      <img alt="" src={IndManu} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Industrial & Manufacturing
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame1">
                      <img alt="" src={Fmcg} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      FMCG & Retail
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={media} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Media & Entertainment
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={Ecom} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      E-Commerce
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={Startup} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                     Startup Venture Capital (VC) & Private Equity (PE)
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="industries-row22">
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame1">
                      <img alt="" src={RealState} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Real Estate & Construction
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={Energy} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Energy, Oil & Gas
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={BFSI} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      BFSI
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={Agriculture} className="interest-img1" />
                    </div>{' '}
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Agriculture & Allied Services
                    </p>{' '}
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame1">
                      <img alt="" src={Logistic} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                   Logistics & Transportation
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    {' '}
                    <div className="interest-frame1">
                      <img alt="" src={Healthcare} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Healthcare & Pharma
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={Ngo} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Not For Profit & NGO
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                {' '}
                <Row>
                  {' '}
                  <Col className="logo-frame">
                    <div className="interest-frame1">
                      <img alt="" src={ETC} className="interest-img1" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col className="logo-text">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                      }}>
                      Others
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Industries;
