import { white } from './colors';

export default {
  mode: "dark",
  secondary: "#38CD84",
  dark: "#1F1E1E",
  main: "#262525",
  text: {
    primary: "#FFFFFF",
    secondary: "#38CD84",
    light: "#959498",
    grey: "#959498",
    black: "#262525",
    disabled: "#A6B1BB",
  },
  background: {
    default: "#262525",
    dark: "#1F1E1E",
    grey: "#343232",
    secondary: "#38CD84",
    paper: white,
  },
};