import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import * as userActions from '../../store/user';
import * as messageActions from '../../store/message';
import 'react-quill/dist/quill.snow.css';
import useStyles from './addnewstyle';
import CxoButton from '../../components/CxoButton/CxoButton';
import CxoOtpTimer from '../../components/CxoOtpTimer/CxoOtpTimer';
import Icon from '../../assets/icon';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import IconButton from '@mui/material/IconButton';
import CxoInput from '../../components/CxoInput/CxoInput';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import Select from '@mui/material/Select';
import { Input } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditEducation from './editEducation';
import EditLanguage from './editLanguage';
import EditAffilation from './editAffilation';
import EditAwards from './editAwards';
import EditPublication from './editPublication';
import EditExperience from './editExperience';
import EditSkills from './editSkills';
import EditFunctions from './editFunctions';
import EditIndustry from './editIndustry';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { dateTime } from '../../constants/app';
import * as messageAction from '../../store/message';
import * as companyAction from '../../store/company';
import OtpInput from 'react-otp-input';

const CompanyCheck = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState('Everyone');
  const [skill, setSkill] = useState('');
  const [interest, setInterest] = useState('');
  const [board, setBoard] = useState('');
  const [degree, setDegree] = useState('');
  const [passingyear, setPassingyear] = useState('');
  const [educationdetails, setEducationdetails] = useState('');
  const [otp, setOtp] = React.useState('');
  const [timer, setTimer] = useState(true);
  const [options, setOptions] = useState(2);
  const [pollend, setPollend] = useState(1);
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(40);
  const [toggleshare, setToggleshare] = useState(false);
  const [share, setShare] = useState('Everyone');
  const [domainCheck, setDomainCheck] = useState(false);
  const [sendEmail, setSendMail] = useState(false);
  const [sendEmailId, setSendMailId] = useState(false);
  const [domain, setSendDomain] = useState('');
  const addNew = useSelector((state) => state?.user?.AddNew);
  const addNewtype = useSelector((state) => state?.user?.AddNewType);
  const openCompany = useSelector((state) => state?.user?.openCompany);
  const user = useSelector((state) => state?.user?.UserDetails);
  const userabouts = useSelector((state) => state?.user?.UserAbouts);
  const otpSendSuccess = useSelector((state) => state?.company?.otpSendSuccess);
  const companyEmail = useSelector((state) => state?.company?.companyEmail);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
  }, []);

  const addPost = async () => {};
  const handleChange = (event) => {
    setOtp(event);
  };
  const handleSkill = (event) => {
    setSkill(event.target.value);
  };

  const handleInterest = (event) => {
    setInterest(event.target.value);
  };

  const handleBoard = (event) => {
    setBoard(event.target.value);
  };
  const addSkills = async () => {
    const data = {
      id: 0,
      user_id: user?.id,
      title: skill,
    };
    const addskills = await dispatch(userActions.manageSkill(data));
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
    const snackbar = await dispatch(userActions.closeUpdateProfile());
  };

  const addInterest = async () => {
    const intert = [];
    intert.push(interest);
    const data = {
      id: 0,
      user_id: user?.id,
      aTitle: interest,
      platform: 'web',
    };
    console.log('interest', data);
    const addsInterest = await dispatch(userActions.manageInterest(data));
    const profiledata = {
      id: user?.id,
    };
    const usersabout = await dispatch(
      userActions.userProfileAbout(profiledata),
    );
    const snackbar = await dispatch(userActions.closeUpdateProfile());
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const otpsendclick = await dispatch(companyAction.sendCompanyReset());
    const snackbar = await dispatch(userActions.closeCompanyCheck());
  };

  const addOption = () => {
    setOptions(options + 1);
  };
  const removeOptions = () => {
    if (options > 2) {
      setOptions(options - 1);
    }
  };
  const handleSubmitpoll = async (data) => {};

  const timerZero = () => {
    setTimer(false);
  };

  const handlePollend = (event) => {
    setPollend(event.target.value);
  };
  const handleShare = (event) => {
    setShare(event.target.value);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const toggleShare = () => {
    setToggleshare(!toggleshare);
  };

  const handleKeyPress = () => {
    console.log('You pressed a key.');
  };

  const handleEducation = (event, type) => {
    if (type === 'Board') {
      setBoard(event.target.value);
    } else if (type === 'Degree') {
      setDegree(event.target.value);
    } else if (type === 'yearofpassing') {
      setPassingyear(event.target.value);
    } else if (type === 'Details') {
      setEducationdetails(event.target.value);
    }
  };

  const notAuthorize = () => {
    setSendMail(true);
  };

  const yesAuthorize = () => {
    setDomainCheck(true);
  };
  const handleSubmitform = async (data) => {
    let domain = data?.email.split('@');
    if (data?.domain_name?.includes(domain[1])) {
      const datadomain = {
        domain_name: data?.domain_name,
      };
      const domainexist = await dispatch(
        companyAction.getCompanyCheckDomainName(datadomain),
      );
      debugger;
      if (domainexist?.data?.exist != true) {
        setSendMailId(data?.email);
        setSendDomain(data?.domain_name);
        const datafo = {
          email: data?.email,
          user_id: user?.id,
          username: data?.email,
          domain_name: data?.domain_name,
        };
        const otpsend = await dispatch(
          companyAction.sendCompanyCheckOtp(datafo),
        );
      } else {
        let msg = 'Domain Name exist. Please try with another Domain Name';
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(msg, 'error'),
        );
      }
    } else {
      let msg =
        'Domain Name & Email ID doesn’t match. Please enter the Email ID with the same Domain Name';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }
  };

  const handleSubmitEmailform = async (data) => {
    const datafo = {
      email: data?.email,
      user_id: user?.id,
      name: data?.name,
    };
    const company = await dispatch(
      companyAction.postCompanyAuthorizationPerson(datafo),
    );

    if (company?.status == 1) {
      let msg = 'Email send Successfully';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'success'),
      );
      setSendMail(true);
      const snackbar = await dispatch(userActions.closeCompanyCheck());
    }
  };

  const resendOtp = async () => {
    const datafo = {
      email: sendEmailId,
      user_id: user?.id,
      username: sendEmailId,
      domain_name: domain,
    };
    const otpsend = await dispatch(companyAction.sendCompanyCheckOtp(datafo));
  };

  const handlecheckOtp = async (data) => {
    // e.stopPropagation();
    const dataOtp = {
      otp: otp,
      email: companyEmail,
    };
    const verifyotp = await dispatch(companyAction.verifyCompanyOtp(dataOtp));

    if (verifyotp?.status == 1) {
      navigate('/company/create');
      const snackbar = await dispatch(userActions.closeCompanyCheck());
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '1200',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCompany}>
        <Box
          sx={{
            width: '60%',
            marginTop: '80px',
            maxWidth: '600px',
            backgroundColor: '#262525',
            borderRadius: '10px',
            '@media (max-width: 500px)': { width: '90%' },
          }}>
          <Box sx={{ width: '100%' }}>
            <Stack spacing={0} sx={{ width: '100%' }}>
              <Stack spacing={0} direction="row">
                <Box
                  px={3}
                  pt={2}
                  sx={{
                    paddingLeft: '72px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}></Box>
                <Box
                  px={3}
                  py={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CloseIcon
                    sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                    onClick={handleClose}
                  />
                </Box>
              </Stack>

              {otpSendSuccess && (
                <Box
                  // mt={2}
                  px={2}
                  pt={1}
                  pb={1}
                  sx={{ zIndex: '1200', borderRadius: '5px' }}
                  className="editor">
                  <Stack
                    direction="column"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '24px 32px 32px 48px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                      }}>
                      <Typography variant="h2s" component="h2s">
                        Please verify OTP sent to your Email ID
                      </Typography>
                    </Box>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      spacing={1}>
                      <Box px={{ sm: 4, xs: 0 }} pt={2}>
                        <form onSubmit={handleSubmit(handlecheckOtp)}>
                          <Box
                            py={3}
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <OtpInput
                              value={otp}
                              onChange={handleChange}
                              onKeyPress={(e) => handleKeyPress(e)}
                              numInputs={6}
                              inputStyle={{
                                height: '40px',
                                width: '39.92px',
                                borderColor: '#38CD84',
                                margin: '4px',
                                color: '#000000',
                                fontSize: '22px',
                                borderRadius: '5px',
                              }}
                              focusStyle={{ borderColor: '#38CD84' }}
                              isInputNum={true}
                            />
                          </Box>

                          <Box py={0} sx={{ textAlign: 'center' }}>
                            <CxoOtpTimer
                              resendOtp={resendOtp}
                              timerzero={timerZero}
                            />
                          </Box>

                          <Box
                            py={2}
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CxoButton label="Verify" />
                          </Box>
                        </form>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              )}

              {!domainCheck && !sendEmail && (
                <Box
                  // mt={2}
                  px={2}
                  pt={1}
                  pb={1}
                  sx={{ zIndex: '1200', borderRadius: '5px' }}
                  className="editor">
                  <Stack
                    direction="column"
                    sx={{ display: 'flex', padding: '24px 32px 32px 32px' }}>
                    <Typography variant="h2" component="h2">
                      Are you an authorised member from your company to manage
                      the page ?
                    </Typography>
                    <Stack direction="row" sx={{ padding: '16px 0px' }}>
                      <Box
                        py={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                        <CxoButton label="No" onClick={notAuthorize} />
                      </Box>
                      <Box
                        py={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                        <CxoButton label="Yes" onClick={() => yesAuthorize()} />
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              )}

              {domainCheck && !otpSendSuccess && (
                <Box
                  // mt={2}
                  px={2}
                  pt={1}
                  pb={1}
                  sx={{ zIndex: '1200', borderRadius: '5px' }}
                  className="editor">
                  <Stack
                    direction="column"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '24px 32px 32px 48px',
                    }}>
                    <Typography variant="h2s" component="h2s">
                      Please enter the below details for Authentication
                    </Typography>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      spacing={1}>
                      <Box px={{ sm: 4, xs: 0 }} pt={2}>
                        <form onSubmit={handleSubmit(handleSubmitform)}>
                          <Stack spacing={1} direction="row">
                            <Box
                              pt={2}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '24px',
                                    maxWidth: 'min-content',
                                    minWidth: '24px',
                                    marginLeft: '-5px',
                                    marginTop: '-12px',
                                    '@media (max-width: 500px)': {
                                      minWidth: '18px',
                                      height: '18px',
                                      marginLeft: '-8px',
                                      marginTop: '0px',
                                    },
                                  }}>
                                  <Menuicon name="website" color="#38CD84" />
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ width: '100%' }}>
                              <Controller
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <Stack>
                                    <CxoInputCss
                                      label="Domain Name *"
                                      value={value}
                                      err={
                                        errors?.domain_name
                                          ? errors?.domain_name
                                          : false
                                      }
                                      onChange={onChange}
                                    />
                                    <Box
                                      sx={{
                                        fontSize: '9px',
                                        height: '10px',
                                        paddingLeft: '8px',
                                      }}>
                                      {errors?.domain_name?.message}{' '}
                                    </Box>
                                  </Stack>
                                )}
                                name="domain_name"
                                required
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Domain Name is required',
                                  },

                                  pattern: {
                                    value: /^[a-zA-Z.0-9 ]+$/,
                                    message:
                                      'Only Alphabetical Character Number Allowded',
                                  },
                                  maxLength: {
                                    value: 60,
                                    message: 'Max 60 character allow',
                                  },
                                }}
                                defaultValue=""
                              />
                            </Box>
                          </Stack>

                          <Stack spacing={1} direction="row">
                            <Box
                              pt={2}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '24px',
                                    width: '24px',
                                    maxWidth: 'min-content',
                                    minWidth: '24px',
                                    marginLeft: '-5px',
                                    marginTop: '-12px',
                                    '@media (max-width: 500px)': {
                                      minWidth: '18px',
                                      height: '18px',
                                      marginLeft: '-8px',
                                      marginTop: '0px',
                                    },
                                  }}>
                                  <Menuicon name="email" color="#38CD84" />
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ width: '100%' }}>
                              <Controller
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <Stack>
                                    <CxoInputCss
                                      label="Email *"
                                      value={value}
                                      err={
                                        errors?.email ? errors?.email : false
                                      }
                                      onChange={onChange}
                                    />
                                    <Box
                                      sx={{
                                        fontSize: '9px',
                                        height: '10px',
                                        paddingLeft: '8px',
                                      }}>
                                      {errors?.email?.message}{' '}
                                    </Box>
                                  </Stack>
                                )}
                                name="email"
                                required
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Email is required',
                                  },
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'invalid email address',
                                  },
                                  maxLength: {
                                    value: 60,
                                    message: 'Max 60 character allow',
                                  },
                                }}
                                defaultValue=""
                              />
                            </Box>
                          </Stack>

                          <Stack
                            direction="row"
                            justifyContent="center"
                            sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                            <CxoButton label="Verify" />
                          </Stack>
                        </form>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              )}

              {sendEmail && (
                <Box
                  // mt={2}
                  px={2}
                  pt={1}
                  pb={1}
                  sx={{ zIndex: '1200', borderRadius: '5px' }}
                  className="editor">
                  <Stack
                    direction="column"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '24px 32px 32px 48px',
                    }}>
                    <Typography variant="h2s" component="h2s">
                      Please add the Email ID of the authorised person
                    </Typography>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      spacing={1}>
                      <Box px={{ sm: 4, xs: 0 }} pt={2}>
                        <form onSubmit={handleSubmit(handleSubmitEmailform)}>
                          <Stack spacing={1} direction="row">
                            <Box
                              pt={2}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '24px',
                                    maxWidth: 'min-content',
                                    minWidth: '24px',
                                    marginLeft: '-5px',
                                    marginTop: '-12px',
                                    '@media (max-width: 500px)': {
                                      minWidth: '18px',
                                      height: '18px',
                                      marginLeft: '-8px',
                                      marginTop: '0px',
                                    },
                                  }}>
                                  <Menuicon name="individual" color="#38CD84" />
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ width: '100%' }}>
                              <Controller
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <Stack>
                                    <CxoInputCss
                                      label="Name *"
                                      value={value}
                                      err={errors?.name ? errors?.name : false}
                                      onChange={onChange}
                                    />
                                    <Box
                                      sx={{
                                        fontSize: '9px',
                                        height: '10px',
                                        paddingLeft: '8px',
                                      }}>
                                      {errors?.name?.message}{' '}
                                    </Box>
                                  </Stack>
                                )}
                                name="name"
                                required
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Name is required',
                                  },
                                  maxLength: {
                                    value: 60,
                                    message: 'Max 60 character allow',
                                  },
                                }}
                                defaultValue=""
                              />
                            </Box>
                          </Stack>

                          <Stack spacing={1} direction="row">
                            <Box
                              pt={2}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '24px',
                                    width: '24px',
                                    maxWidth: 'min-content',
                                    minWidth: '24px',
                                    marginLeft: '-5px',
                                    marginTop: '-12px',
                                    '@media (max-width: 500px)': {
                                      minWidth: '18px',
                                      height: '18px',
                                      marginLeft: '-8px',
                                      marginTop: '0px',
                                    },
                                  }}>
                                  <Menuicon name="email" color="#38CD84" />
                                </Box>
                              </Box>
                            </Box>

                            <Box sx={{ width: '100%' }}>
                              <Controller
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <Stack>
                                    <CxoInputCss
                                      label="Email *"
                                      value={value}
                                      err={
                                        errors?.email ? errors?.email : false
                                      }
                                      onChange={onChange}
                                    />
                                    <Box
                                      sx={{
                                        fontSize: '9px',
                                        height: '10px',
                                        paddingLeft: '8px',
                                      }}>
                                      {errors?.email?.message}{' '}
                                    </Box>
                                  </Stack>
                                )}
                                name="email"
                                required
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Email is required',
                                  },
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'invalid email address',
                                  },
                                  maxLength: {
                                    value: 60,
                                    message: 'Max 60 character allow',
                                  },
                                }}
                                defaultValue=""
                              />
                            </Box>
                          </Stack>

                          <Stack
                            direction="row"
                            justifyContent="center"
                            sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                            <CxoButton label="Share" />
                          </Stack>
                        </form>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(CompanyCheck);
