import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Paper, Grid, Box, Typography, TextField } from '@mui/material';
import './knowumore.css';
import CxoButton from '../../../components/CxoButton/CxoButton';
import Stack from '@mui/material/Stack';
import CxoInput from '../../../components/CxoInput/CxoInput';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DatePicker from '@mui/lab/DatePicker';
import Menuicon from '../../../assets/menuicon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import nameActive from '../../../assets/bio/Name_Active.png';
import industryActive from '../../../assets/bio/Industry_Active.png';
import experienceActive from '../../../assets/bio/Experience_Active.png';
import functionActive from '../../../assets/bio/Function_Active.png';
import phoneActive from '../../../assets/bio/Phone_Active.png';
import locationActive from '../../../assets/bio/locationImg.png';
import useStyles from './knowmorestyles';
import { dateTime } from '../../../constants/app';
import * as onboardActions from '../../../store/onboard';
import * as authActions from '../../../store/auth';
import * as userActions from '../../../store/user';
import * as messageAction from '../../../store/message';
import * as eventsActions from '../../../store/events';
import * as clubsActions from '../../../store/clubs';
import * as usernetworkActions from '../../../store/usernetwork';
import * as postActions from '../../../store/post';
import { useParams, useLocation } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import * as activityActions from '../../../store/activity';
import { v4 as uuidv4 } from 'uuid';

const countryListnew = [
  {
    id: 99,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  },
  {
    id: 1,
    iso2: 'AF',
    country_name: 'Afghanistan',
    iso3: 'AFG',
    phone_code: 93,
  },
  {
    id: 2,
    iso2: 'AL',
    country_name: 'Albania',
    iso3: 'ALB',
    phone_code: 355,
  },
  {
    id: 3,
    iso2: 'DZ',
    country_name: 'Algeria',
    iso3: 'DZA',
    phone_code: 213,
  },
  {
    id: 4,
    iso2: 'AS',
    country_name: 'American Samoa',
    iso3: 'ASM',
    phone_code: 1684,
  },
  {
    id: 5,
    iso2: 'AD',
    country_name: 'Andorra',
    iso3: 'AND',
    phone_code: 376,
  },
  {
    id: 6,
    iso2: 'AO',
    country_name: 'Angola',
    iso3: 'AGO',
    phone_code: 244,
  },
  {
    id: 7,
    iso2: 'AI',
    country_name: 'Anguilla',
    iso3: 'AIA',
    phone_code: 1264,
  },
  {
    id: 8,
    iso2: 'AQ',
    country_name: 'Antarctica',
    iso3: 'ATA',
    phone_code: 672,
  },
  {
    id: 9,
    iso2: 'AG',
    country_name: 'Antigua and Barbuda',
    iso3: 'ATG',
    phone_code: 1268,
  },
  {
    id: 10,
    iso2: 'AR',
    country_name: 'Argentina',
    iso3: 'ARG',
    phone_code: 54,
  },
  {
    id: 11,
    iso2: 'AM',
    country_name: 'Armenia',
    iso3: 'ARM',
    phone_code: 374,
  },
  {
    id: 12,
    iso2: 'AW',
    country_name: 'Aruba',
    iso3: 'ABW',
    phone_code: 297,
  },
  {
    id: 13,
    iso2: 'AU',
    country_name: 'Australia',
    iso3: 'AUS',
    phone_code: 61,
  },
  {
    id: 14,
    iso2: 'AT',
    country_name: 'Austria',
    iso3: 'AUT',
    phone_code: 43,
  },
  {
    id: 15,
    iso2: 'AZ',
    country_name: 'Azerbaijan',
    iso3: 'AZE',
    phone_code: 994,
  },
  {
    id: 16,
    iso2: 'BS',
    country_name: 'Bahamas',
    iso3: 'BHS',
    phone_code: 1242,
  },
  {
    id: 17,
    iso2: 'BH',
    country_name: 'Bahrain',
    iso3: 'BHR',
    phone_code: 973,
  },
  {
    id: 18,
    iso2: 'BD',
    country_name: 'Bangladesh',
    iso3: 'BGD',
    phone_code: 880,
  },
  {
    id: 19,
    iso2: 'BB',
    country_name: 'Barbados',
    iso3: 'BRB',
    phone_code: 1246,
  },
  {
    id: 20,
    iso2: 'BY',
    country_name: 'Belarus',
    iso3: 'BLR',
    phone_code: 375,
  },
  {
    id: 21,
    iso2: 'BE',
    country_name: 'Belgium',
    iso3: 'BEL',
    phone_code: 32,
  },
  {
    id: 22,
    iso2: 'BZ',
    country_name: 'Belize',
    iso3: 'BLZ',
    phone_code: 501,
  },
  {
    id: 23,
    iso2: 'BJ',
    country_name: 'Benin',
    iso3: 'BEN',
    phone_code: 229,
  },
  {
    id: 24,
    iso2: 'BM',
    country_name: 'Bermuda',
    iso3: 'BMU',
    phone_code: 1441,
  },
  {
    id: 25,
    iso2: 'BT',
    country_name: 'Bhutan',
    iso3: 'BTN',
    phone_code: 975,
  },
  {
    id: 26,
    iso2: 'BO',
    country_name: 'Bolivia',
    iso3: 'BOL',
    phone_code: 591,
  },
  {
    id: 27,
    iso2: 'BA',
    country_name: 'Bosnia and Herzegovina',
    iso3: 'BIH',
    phone_code: 387,
  },
  {
    id: 28,
    iso2: 'BW',
    country_name: 'Botswana',
    iso3: 'BWA',
    phone_code: 267,
  },
  {
    id: 29,
    iso2: 'BV',
    country_name: 'Bouvet Island',
    iso3: null,
    phone_code: 0,
  },
  {
    id: 30,
    iso2: 'BR',
    country_name: 'Brazil',
    iso3: 'BRA',
    phone_code: 55,
  },
  {
    id: 31,
    iso2: 'IO',
    country_name: 'British Indian Ocean Territory',
    iso3: null,
    phone_code: 246,
  },
  {
    id: 32,
    iso2: 'BN',
    country_name: 'Brunei Darussalam',
    iso3: 'BRN',
    phone_code: 673,
  },
  {
    id: 33,
    iso2: 'BG',
    country_name: 'Bulgaria',
    iso3: 'BGR',
    phone_code: 359,
  },
  {
    id: 34,
    iso2: 'BF',
    country_name: 'Burkina Faso',
    iso3: 'BFA',
    phone_code: 226,
  },
  {
    id: 35,
    iso2: 'BI',
    country_name: 'Burundi',
    iso3: 'BDI',
    phone_code: 257,
  },
  {
    id: 36,
    iso2: 'KH',
    country_name: 'Cambodia',
    iso3: 'KHM',
    phone_code: 855,
  },
  {
    id: 37,
    iso2: 'CM',
    country_name: 'Cameroon',
    iso3: 'CMR',
    phone_code: 237,
  },
  {
    id: 38,
    iso2: 'CA',
    country_name: 'Canada',
    iso3: 'CAN',
    phone_code: 1,
  },
  {
    id: 39,
    iso2: 'CV',
    country_name: 'Cape Verde',
    iso3: 'CPV',
    phone_code: 238,
  },
  {
    id: 40,
    iso2: 'KY',
    country_name: 'Cayman Islands',
    iso3: 'CYM',
    phone_code: 1345,
  },
  {
    id: 41,
    iso2: 'CF',
    country_name: 'Central African Republic',
    iso3: 'CAF',
    phone_code: 236,
  },
  {
    id: 42,
    iso2: 'TD',
    country_name: 'Chad',
    iso3: 'TCD',
    phone_code: 235,
  },
  {
    id: 43,
    iso2: 'CL',
    country_name: 'Chile',
    iso3: 'CHL',
    phone_code: 56,
  },
  {
    id: 44,
    iso2: 'CN',
    country_name: 'China',
    iso3: 'CHN',
    phone_code: 86,
  },
  {
    id: 45,
    iso2: 'CX',
    country_name: 'Christmas Island',
    iso3: null,
    phone_code: 61,
  },
  {
    id: 46,
    iso2: 'CC',
    country_name: 'Cocos (Keeling) Islands',
    iso3: null,
    phone_code: 672,
  },
  {
    id: 47,
    iso2: 'CO',
    country_name: 'Colombia',
    iso3: 'COL',
    phone_code: 57,
  },
  {
    id: 48,
    iso2: 'KM',
    country_name: 'Comoros',
    iso3: 'COM',
    phone_code: 269,
  },
  {
    id: 49,
    iso2: 'CG',
    country_name: 'Congo',
    iso3: 'COG',
    phone_code: 242,
  },
  {
    id: 50,
    iso2: 'CD',
    country_name: 'Congo, the Democratic Republic of the',
    iso3: 'COD',
    phone_code: 242,
  },
  {
    id: 51,
    iso2: 'CK',
    country_name: 'Cook Islands',
    iso3: 'COK',
    phone_code: 682,
  },
  {
    id: 52,
    iso2: 'CR',
    country_name: 'Costa Rica',
    iso3: 'CRI',
    phone_code: 506,
  },
  {
    id: 53,
    iso2: 'CI',
    country_name: "Cote D'Ivoire",
    iso3: 'CIV',
    phone_code: 225,
  },
  {
    id: 54,
    iso2: 'HR',
    country_name: 'Croatia',
    iso3: 'HRV',
    phone_code: 385,
  },
  {
    id: 55,
    iso2: 'CU',
    country_name: 'Cuba',
    iso3: 'CUB',
    phone_code: 53,
  },
  {
    id: 56,
    iso2: 'CY',
    country_name: 'Cyprus',
    iso3: 'CYP',
    phone_code: 357,
  },
  {
    id: 57,
    iso2: 'CZ',
    country_name: 'Czech Republic',
    iso3: 'CZE',
    phone_code: 420,
  },
  {
    id: 58,
    iso2: 'DK',
    country_name: 'Denmark',
    iso3: 'DNK',
    phone_code: 45,
  },
  {
    id: 59,
    iso2: 'DJ',
    country_name: 'Djibouti',
    iso3: 'DJI',
    phone_code: 253,
  },
  {
    id: 60,
    iso2: 'DM',
    country_name: 'Dominica',
    iso3: 'DMA',
    phone_code: 1767,
  },
  {
    id: 61,
    iso2: 'DO',
    country_name: 'Dominican Republic',
    iso3: 'DOM',
    phone_code: 1809,
  },
  {
    id: 62,
    iso2: 'EC',
    country_name: 'Ecuador',
    iso3: 'ECU',
    phone_code: 593,
  },
  {
    id: 63,
    iso2: 'EG',
    country_name: 'Egypt',
    iso3: 'EGY',
    phone_code: 20,
  },
  {
    id: 64,
    iso2: 'SV',
    country_name: 'El Salvador',
    iso3: 'SLV',
    phone_code: 503,
  },
  {
    id: 65,
    iso2: 'GQ',
    country_name: 'Equatorial Guinea',
    iso3: 'GNQ',
    phone_code: 240,
  },
  {
    id: 66,
    iso2: 'ER',
    country_name: 'Eritrea',
    iso3: 'ERI',
    phone_code: 291,
  },
  {
    id: 67,
    iso2: 'EE',
    country_name: 'Estonia',
    iso3: 'EST',
    phone_code: 372,
  },
  {
    id: 68,
    iso2: 'ET',
    country_name: 'Ethiopia',
    iso3: 'ETH',
    phone_code: 251,
  },
  {
    id: 69,
    iso2: 'FK',
    country_name: 'Falkland Islands (Malvinas)',
    iso3: 'FLK',
    phone_code: 500,
  },
  {
    id: 70,
    iso2: 'FO',
    country_name: 'Faroe Islands',
    iso3: 'FRO',
    phone_code: 298,
  },
  {
    id: 71,
    iso2: 'FJ',
    country_name: 'Fiji',
    iso3: 'FJI',
    phone_code: 679,
  },
  {
    id: 72,
    iso2: 'FI',
    country_name: 'Finland',
    iso3: 'FIN',
    phone_code: 358,
  },
  {
    id: 73,
    iso2: 'FR',
    country_name: 'France',
    iso3: 'FRA',
    phone_code: 33,
  },
  {
    id: 74,
    iso2: 'GF',
    country_name: 'French Guiana',
    iso3: 'GUF',
    phone_code: 594,
  },
  {
    id: 75,
    iso2: 'PF',
    country_name: 'French Polynesia',
    iso3: 'PYF',
    phone_code: 689,
  },
  {
    id: 76,
    iso2: 'TF',
    country_name: 'French Southern Territories',
    iso3: null,
    phone_code: 0,
  },
  {
    id: 77,
    iso2: 'GA',
    country_name: 'Gabon',
    iso3: 'GAB',
    phone_code: 241,
  },
  {
    id: 78,
    iso2: 'GM',
    country_name: 'Gambia',
    iso3: 'GMB',
    phone_code: 220,
  },
  {
    id: 79,
    iso2: 'GE',
    country_name: 'Georgia',
    iso3: 'GEO',
    phone_code: 995,
  },
  {
    id: 80,
    iso2: 'DE',
    country_name: 'Germany',
    iso3: 'DEU',
    phone_code: 49,
  },
  {
    id: 81,
    iso2: 'GH',
    country_name: 'Ghana',
    iso3: 'GHA',
    phone_code: 233,
  },
  {
    id: 82,
    iso2: 'GI',
    country_name: 'Gibraltar',
    iso3: 'GIB',
    phone_code: 350,
  },
  {
    id: 83,
    iso2: 'GR',
    country_name: 'Greece',
    iso3: 'GRC',
    phone_code: 30,
  },
  {
    id: 84,
    iso2: 'GL',
    country_name: 'Greenland',
    iso3: 'GRL',
    phone_code: 299,
  },
  {
    id: 85,
    iso2: 'GD',
    country_name: 'Grenada',
    iso3: 'GRD',
    phone_code: 1473,
  },
  {
    id: 86,
    iso2: 'GP',
    country_name: 'Guadeloupe',
    iso3: 'GLP',
    phone_code: 590,
  },
  {
    id: 87,
    iso2: 'GU',
    country_name: 'Guam',
    iso3: 'GUM',
    phone_code: 1671,
  },
  {
    id: 88,
    iso2: 'GT',
    country_name: 'Guatemala',
    iso3: 'GTM',
    phone_code: 502,
  },
  {
    id: 89,
    iso2: 'GN',
    country_name: 'Guinea',
    iso3: 'GIN',
    phone_code: 224,
  },
  {
    id: 90,
    iso2: 'GW',
    country_name: 'Guinea-Bissau',
    iso3: 'GNB',
    phone_code: 245,
  },
  {
    id: 91,
    iso2: 'GY',
    country_name: 'Guyana',
    iso3: 'GUY',
    phone_code: 592,
  },
  {
    id: 92,
    iso2: 'HT',
    country_name: 'Haiti',
    iso3: 'HTI',
    phone_code: 509,
  },
  {
    id: 93,
    iso2: 'HM',
    country_name: 'Heard Island and Mcdonald Islands',
    iso3: null,
    phone_code: 672,
  },
  {
    id: 94,
    iso2: 'VA',
    country_name: 'Holy See (Vatican City State)',
    iso3: 'VAT',
    phone_code: 39,
  },
  {
    id: 95,
    iso2: 'HN',
    country_name: 'Honduras',
    iso3: 'HND',
    phone_code: 504,
  },
  {
    id: 96,
    iso2: 'HK',
    country_name: 'Hong Kong',
    iso3: 'HKG',
    phone_code: 852,
  },
  {
    id: 97,
    iso2: 'HU',
    country_name: 'Hungary',
    iso3: 'HUN',
    phone_code: 36,
  },
  {
    id: 98,
    iso2: 'IS',
    country_name: 'Iceland',
    iso3: 'ISL',
    phone_code: 354,
  },
  {
    id: 100,
    iso2: 'ID',
    country_name: 'Indonesia',
    iso3: 'IDN',
    phone_code: 62,
  },
  {
    id: 101,
    iso2: 'IR',
    country_name: 'Iran, Islamic Republic of',
    iso3: 'IRN',
    phone_code: 98,
  },
  {
    id: 102,
    iso2: 'IQ',
    country_name: 'Iraq',
    iso3: 'IRQ',
    phone_code: 964,
  },
  {
    id: 103,
    iso2: 'IE',
    country_name: 'Ireland',
    iso3: 'IRL',
    phone_code: 353,
  },
  {
    id: 104,
    iso2: 'IL',
    country_name: 'Israel',
    iso3: 'ISR',
    phone_code: 972,
  },
  {
    id: 105,
    iso2: 'IT',
    country_name: 'Italy',
    iso3: 'ITA',
    phone_code: 39,
  },
  {
    id: 106,
    iso2: 'JM',
    country_name: 'Jamaica',
    iso3: 'JAM',
    phone_code: 1876,
  },
  {
    id: 107,
    iso2: 'JP',
    country_name: 'Japan',
    iso3: 'JPN',
    phone_code: 81,
  },
  {
    id: 108,
    iso2: 'JO',
    country_name: 'Jordan',
    iso3: 'JOR',
    phone_code: 962,
  },
  {
    id: 109,
    iso2: 'KZ',
    country_name: 'Kazakhstan',
    iso3: 'KAZ',
    phone_code: 7,
  },
  {
    id: 110,
    iso2: 'KE',
    country_name: 'Kenya',
    iso3: 'KEN',
    phone_code: 254,
  },
  {
    id: 111,
    iso2: 'KI',
    country_name: 'Kiribati',
    iso3: 'KIR',
    phone_code: 686,
  },
  {
    id: 112,
    iso2: 'KP',
    country_name: "Korea, Democratic People's Republic of",
    iso3: 'PRK',
    phone_code: 850,
  },
  {
    id: 113,
    iso2: 'KR',
    country_name: 'Korea, Republic of',
    iso3: 'KOR',
    phone_code: 82,
  },
  {
    id: 114,
    iso2: 'KW',
    country_name: 'Kuwait',
    iso3: 'KWT',
    phone_code: 965,
  },
  {
    id: 115,
    iso2: 'KG',
    country_name: 'Kyrgyzstan',
    iso3: 'KGZ',
    phone_code: 996,
  },
  {
    id: 116,
    iso2: 'LA',
    country_name: "Lao People's Democratic Republic",
    iso3: 'LAO',
    phone_code: 856,
  },
  {
    id: 117,
    iso2: 'LV',
    country_name: 'Latvia',
    iso3: 'LVA',
    phone_code: 371,
  },
  {
    id: 118,
    iso2: 'LB',
    country_name: 'Lebanon',
    iso3: 'LBN',
    phone_code: 961,
  },
  {
    id: 119,
    iso2: 'LS',
    country_name: 'Lesotho',
    iso3: 'LSO',
    phone_code: 266,
  },
  {
    id: 120,
    iso2: 'LR',
    country_name: 'Liberia',
    iso3: 'LBR',
    phone_code: 231,
  },
  {
    id: 121,
    iso2: 'LY',
    country_name: 'Libyan Arab Jamahiriya',
    iso3: 'LBY',
    phone_code: 218,
  },
  {
    id: 122,
    iso2: 'LI',
    country_name: 'Liechtenstein',
    iso3: 'LIE',
    phone_code: 423,
  },
  {
    id: 123,
    iso2: 'LT',
    country_name: 'Lithuania',
    iso3: 'LTU',
    phone_code: 370,
  },
  {
    id: 124,
    iso2: 'LU',
    country_name: 'Luxembourg',
    iso3: 'LUX',
    phone_code: 352,
  },
  {
    id: 125,
    iso2: 'MO',
    country_name: 'Macao',
    iso3: 'MAC',
    phone_code: 853,
  },
  {
    id: 126,
    iso2: 'MK',
    country_name: 'Macedonia, the Former Yugoslav Republic of',
    iso3: 'MKD',
    phone_code: 389,
  },
  {
    id: 127,
    iso2: 'MG',
    country_name: 'Madagascar',
    iso3: 'MDG',
    phone_code: 261,
  },
  {
    id: 128,
    iso2: 'MW',
    country_name: 'Malawi',
    iso3: 'MWI',
    phone_code: 265,
  },
  {
    id: 129,
    iso2: 'MY',
    country_name: 'Malaysia',
    iso3: 'MYS',
    phone_code: 60,
  },
  {
    id: 130,
    iso2: 'MV',
    country_name: 'Maldives',
    iso3: 'MDV',
    phone_code: 960,
  },
  {
    id: 131,
    iso2: 'ML',
    country_name: 'Mali',
    iso3: 'MLI',
    phone_code: 223,
  },
  {
    id: 132,
    iso2: 'MT',
    country_name: 'Malta',
    iso3: 'MLT',
    phone_code: 356,
  },
  {
    id: 133,
    iso2: 'MH',
    country_name: 'Marshall Islands',
    iso3: 'MHL',
    phone_code: 692,
  },
  {
    id: 134,
    iso2: 'MQ',
    country_name: 'Martinique',
    iso3: 'MTQ',
    phone_code: 596,
  },
  {
    id: 135,
    iso2: 'MR',
    country_name: 'Mauritania',
    iso3: 'MRT',
    phone_code: 222,
  },
  {
    id: 136,
    iso2: 'MU',
    country_name: 'Mauritius',
    iso3: 'MUS',
    phone_code: 230,
  },
  {
    id: 137,
    iso2: 'YT',
    country_name: 'Mayotte',
    iso3: null,
    phone_code: 269,
  },
  {
    id: 138,
    iso2: 'MX',
    country_name: 'Mexico',
    iso3: 'MEX',
    phone_code: 52,
  },
  {
    id: 139,
    iso2: 'FM',
    country_name: 'Micronesia, Federated States of',
    iso3: 'FSM',
    phone_code: 691,
  },
  {
    id: 140,
    iso2: 'MD',
    country_name: 'Moldova, Republic of',
    iso3: 'MDA',
    phone_code: 373,
  },
  {
    id: 141,
    iso2: 'MC',
    country_name: 'Monaco',
    iso3: 'MCO',
    phone_code: 377,
  },
  {
    id: 142,
    iso2: 'MN',
    country_name: 'Mongolia',
    iso3: 'MNG',
    phone_code: 976,
  },
  {
    id: 143,
    iso2: 'MS',
    country_name: 'Montserrat',
    iso3: 'MSR',
    phone_code: 1664,
  },
  {
    id: 144,
    iso2: 'MA',
    country_name: 'Morocco',
    iso3: 'MAR',
    phone_code: 212,
  },
  {
    id: 145,
    iso2: 'MZ',
    country_name: 'Mozambique',
    iso3: 'MOZ',
    phone_code: 258,
  },
  {
    id: 146,
    iso2: 'MM',
    country_name: 'Myanmar',
    iso3: 'MMR',
    phone_code: 95,
  },
  {
    id: 147,
    iso2: 'NA',
    country_name: 'Namibia',
    iso3: 'NAM',
    phone_code: 264,
  },
  {
    id: 148,
    iso2: 'NR',
    country_name: 'Nauru',
    iso3: 'NRU',
    phone_code: 674,
  },
  {
    id: 149,
    iso2: 'NP',
    country_name: 'Nepal',
    iso3: 'NPL',
    phone_code: 977,
  },
  {
    id: 150,
    iso2: 'NL',
    country_name: 'Netherlands',
    iso3: 'NLD',
    phone_code: 31,
  },
  {
    id: 151,
    iso2: 'AN',
    country_name: 'Netherlands Antilles',
    iso3: 'ANT',
    phone_code: 599,
  },
  {
    id: 152,
    iso2: 'NC',
    country_name: 'New Caledonia',
    iso3: 'NCL',
    phone_code: 687,
  },
  {
    id: 153,
    iso2: 'NZ',
    country_name: 'New Zealand',
    iso3: 'NZL',
    phone_code: 64,
  },
  {
    id: 154,
    iso2: 'NI',
    country_name: 'Nicaragua',
    iso3: 'NIC',
    phone_code: 505,
  },
  {
    id: 155,
    iso2: 'NE',
    country_name: 'Niger',
    iso3: 'NER',
    phone_code: 227,
  },
  {
    id: 156,
    iso2: 'NG',
    country_name: 'Nigeria',
    iso3: 'NGA',
    phone_code: 234,
  },
  {
    id: 157,
    iso2: 'NU',
    country_name: 'Niue',
    iso3: 'NIU',
    phone_code: 683,
  },
  {
    id: 158,
    iso2: 'NF',
    country_name: 'Norfolk Island',
    iso3: 'NFK',
    phone_code: 672,
  },
  {
    id: 159,
    iso2: 'MP',
    country_name: 'Northern Mariana Islands',
    iso3: 'MNP',
    phone_code: 1670,
  },
  {
    id: 160,
    iso2: 'NO',
    country_name: 'Norway',
    iso3: 'NOR',
    phone_code: 47,
  },
  {
    id: 161,
    iso2: 'OM',
    country_name: 'Oman',
    iso3: 'OMN',
    phone_code: 968,
  },
  {
    id: 162,
    iso2: 'PK',
    country_name: 'Pakistan',
    iso3: 'PAK',
    phone_code: 92,
  },
  {
    id: 163,
    iso2: 'PW',
    country_name: 'Palau',
    iso3: 'PLW',
    phone_code: 680,
  },
  {
    id: 164,
    iso2: 'PS',
    country_name: 'Palestinian Territory, Occupied',
    iso3: null,
    phone_code: 970,
  },
  {
    id: 165,
    iso2: 'PA',
    country_name: 'Panama',
    iso3: 'PAN',
    phone_code: 507,
  },
  {
    id: 166,
    iso2: 'PG',
    country_name: 'Papua New Guinea',
    iso3: 'PNG',
    phone_code: 675,
  },
  {
    id: 167,
    iso2: 'PY',
    country_name: 'Paraguay',
    iso3: 'PRY',
    phone_code: 595,
  },
  {
    id: 168,
    iso2: 'PE',
    country_name: 'Peru',
    iso3: 'PER',
    phone_code: 51,
  },
  {
    id: 169,
    iso2: 'PH',
    country_name: 'Philippines',
    iso3: 'PHL',
    phone_code: 63,
  },
  {
    id: 170,
    iso2: 'PN',
    country_name: 'Pitcairn',
    iso3: 'PCN',
    phone_code: 64,
  },
  {
    id: 171,
    iso2: 'PL',
    country_name: 'Poland',
    iso3: 'POL',
    phone_code: 48,
  },
  {
    id: 172,
    iso2: 'PT',
    country_name: 'Portugal',
    iso3: 'PRT',
    phone_code: 351,
  },
  {
    id: 173,
    iso2: 'PR',
    country_name: 'Puerto Rico',
    iso3: 'PRI',
    phone_code: 1787,
  },
  {
    id: 174,
    iso2: 'QA',
    country_name: 'Qatar',
    iso3: 'QAT',
    phone_code: 974,
  },
  {
    id: 175,
    iso2: 'RE',
    country_name: 'Reunion',
    iso3: 'REU',
    phone_code: 262,
  },
  {
    id: 176,
    iso2: 'RO',
    country_name: 'Romania',
    iso3: 'ROM',
    phone_code: 40,
  },
  {
    id: 177,
    iso2: 'RU',
    country_name: 'Russian Federation',
    iso3: 'RUS',
    phone_code: 70,
  },
  {
    id: 178,
    iso2: 'RW',
    country_name: 'Rwanda',
    iso3: 'RWA',
    phone_code: 250,
  },
  {
    id: 179,
    iso2: 'SH',
    country_name: 'Saint Helena',
    iso3: 'SHN',
    phone_code: 290,
  },
  {
    id: 180,
    iso2: 'KN',
    country_name: 'Saint Kitts and Nevis',
    iso3: 'KNA',
    phone_code: 1869,
  },
  {
    id: 181,
    iso2: 'LC',
    country_name: 'Saint Lucia',
    iso3: 'LCA',
    phone_code: 1758,
  },
  {
    id: 182,
    iso2: 'PM',
    country_name: 'Saint Pierre and Miquelon',
    iso3: 'SPM',
    phone_code: 508,
  },
  {
    id: 183,
    iso2: 'VC',
    country_name: 'Saint Vincent and the Grenadines',
    iso3: 'VCT',
    phone_code: 1784,
  },
  {
    id: 184,
    iso2: 'WS',
    country_name: 'Samoa',
    iso3: 'WSM',
    phone_code: 684,
  },
  {
    id: 185,
    iso2: 'SM',
    country_name: 'San Marino',
    iso3: 'SMR',
    phone_code: 378,
  },
  {
    id: 186,
    iso2: 'ST',
    country_name: 'Sao Tome and Principe',
    iso3: 'STP',
    phone_code: 239,
  },
  {
    id: 187,
    iso2: 'SA',
    country_name: 'Saudi Arabia',
    iso3: 'SAU',
    phone_code: 966,
  },
  {
    id: 188,
    iso2: 'SN',
    country_name: 'Senegal',
    iso3: 'SEN',
    phone_code: 221,
  },
  {
    id: 189,
    iso2: 'CS',
    country_name: 'Serbia and Montenegro',
    iso3: null,
    phone_code: 381,
  },
  {
    id: 190,
    iso2: 'SC',
    country_name: 'Seychelles',
    iso3: 'SYC',
    phone_code: 248,
  },
  {
    id: 191,
    iso2: 'SL',
    country_name: 'Sierra Leone',
    iso3: 'SLE',
    phone_code: 232,
  },
  {
    id: 192,
    iso2: 'SG',
    country_name: 'Singapore',
    iso3: 'SGP',
    phone_code: 65,
  },
  {
    id: 193,
    iso2: 'SK',
    country_name: 'Slovakia',
    iso3: 'SVK',
    phone_code: 421,
  },
  {
    id: 194,
    iso2: 'SI',
    country_name: 'Slovenia',
    iso3: 'SVN',
    phone_code: 386,
  },
  {
    id: 195,
    iso2: 'SB',
    country_name: 'Solomon Islands',
    iso3: 'SLB',
    phone_code: 677,
  },
  {
    id: 196,
    iso2: 'SO',
    country_name: 'Somalia',
    iso3: 'SOM',
    phone_code: 252,
  },
  {
    id: 197,
    iso2: 'ZA',
    country_name: 'South Africa',
    iso3: 'ZAF',
    phone_code: 27,
  },
  {
    id: 198,
    iso2: 'GS',
    country_name: 'South Georgia and the South Sandwich Islands',
    iso3: null,
    phone_code: 500,
  },
  {
    id: 199,
    iso2: 'ES',
    country_name: 'Spain',
    iso3: 'ESP',
    phone_code: 34,
  },
  {
    id: 200,
    iso2: 'LK',
    country_name: 'Sri Lanka',
    iso3: 'LKA',
    phone_code: 94,
  },
  {
    id: 201,
    iso2: 'SD',
    country_name: 'Sudan',
    iso3: 'SDN',
    phone_code: 249,
  },
  {
    id: 202,
    iso2: 'SR',
    country_name: 'Suriname',
    iso3: 'SUR',
    phone_code: 597,
  },
  {
    id: 203,
    iso2: 'SJ',
    country_name: 'Svalbard and Jan Mayen',
    iso3: 'SJM',
    phone_code: 47,
  },
  {
    id: 204,
    iso2: 'SZ',
    country_name: 'Swaziland',
    iso3: 'SWZ',
    phone_code: 268,
  },
  {
    id: 205,
    iso2: 'SE',
    country_name: 'Sweden',
    iso3: 'SWE',
    phone_code: 46,
  },
  {
    id: 206,
    iso2: 'CH',
    country_name: 'Switzerland',
    iso3: 'CHE',
    phone_code: 41,
  },
  {
    id: 207,
    iso2: 'SY',
    country_name: 'Syrian Arab Republic',
    iso3: 'SYR',
    phone_code: 963,
  },
  {
    id: 208,
    iso2: 'TW',
    country_name: 'Taiwan, Province of China',
    iso3: 'TWN',
    phone_code: 886,
  },
  {
    id: 209,
    iso2: 'TJ',
    country_name: 'Tajikistan',
    iso3: 'TJK',
    phone_code: 992,
  },
  {
    id: 210,
    iso2: 'TZ',
    country_name: 'Tanzania, United Republic of',
    iso3: 'TZA',
    phone_code: 255,
  },
  {
    id: 211,
    iso2: 'TH',
    country_name: 'Thailand',
    iso3: 'THA',
    phone_code: 66,
  },
  {
    id: 212,
    iso2: 'TL',
    country_name: 'Timor-Leste',
    iso3: null,
    phone_code: 670,
  },
  {
    id: 213,
    iso2: 'TG',
    country_name: 'Togo',
    iso3: 'TGO',
    phone_code: 228,
  },
  {
    id: 214,
    iso2: 'TK',
    country_name: 'Tokelau',
    iso3: 'TKL',
    phone_code: 690,
  },
  {
    id: 215,
    iso2: 'TO',
    country_name: 'Tonga',
    iso3: 'TON',
    phone_code: 676,
  },
  {
    id: 216,
    iso2: 'TT',
    country_name: 'Trinidad and Tobago',
    iso3: 'TTO',
    phone_code: 1868,
  },
  {
    id: 217,
    iso2: 'TN',
    country_name: 'Tunisia',
    iso3: 'TUN',
    phone_code: 216,
  },
  {
    id: 218,
    iso2: 'TR',
    country_name: 'Turkey',
    iso3: 'TUR',
    phone_code: 90,
  },
  {
    id: 219,
    iso2: 'TM',
    country_name: 'Turkmenistan',
    iso3: 'TKM',
    phone_code: 7370,
  },
  {
    id: 220,
    iso2: 'TC',
    country_name: 'Turks and Caicos Islands',
    iso3: 'TCA',
    phone_code: 1649,
  },
  {
    id: 221,
    iso2: 'TV',
    country_name: 'Tuvalu',
    iso3: 'TUV',
    phone_code: 688,
  },
  {
    id: 222,
    iso2: 'UG',
    country_name: 'Uganda',
    iso3: 'UGA',
    phone_code: 256,
  },
  {
    id: 223,
    iso2: 'UA',
    country_name: 'Ukraine',
    iso3: 'UKR',
    phone_code: 380,
  },
  {
    id: 224,
    iso2: 'AE',
    country_name: 'United Arab Emirates',
    iso3: 'ARE',
    phone_code: 971,
  },
  {
    id: 225,
    iso2: 'GB',
    country_name: 'United Kingdom',
    iso3: 'GBR',
    phone_code: 44,
  },
  {
    id: 226,
    iso2: 'US',
    country_name: 'United States',
    iso3: 'USA',
    phone_code: 1,
  },
  {
    id: 227,
    iso2: 'UM',
    country_name: 'United States Minor Outlying Islands',
    iso3: null,
    phone_code: 1,
  },
  {
    id: 228,
    iso2: 'UY',
    country_name: 'Uruguay',
    iso3: 'URY',
    phone_code: 598,
  },
  {
    id: 229,
    iso2: 'UZ',
    country_name: 'Uzbekistan',
    iso3: 'UZB',
    phone_code: 998,
  },
  {
    id: 230,
    iso2: 'VU',
    country_name: 'Vanuatu',
    iso3: 'VUT',
    phone_code: 678,
  },
  {
    id: 231,
    iso2: 'VE',
    country_name: 'Venezuela',
    iso3: 'VEN',
    phone_code: 58,
  },
  {
    id: 232,
    iso2: 'VN',
    country_name: 'Viet Nam',
    iso3: 'VNM',
    phone_code: 84,
  },
  {
    id: 233,
    iso2: 'VG',
    country_name: 'Virgin Islands, British',
    iso3: 'VGB',
    phone_code: 1284,
  },
  {
    id: 234,
    iso2: 'VI',
    country_name: 'Virgin Islands, U.s.',
    iso3: 'VIR',
    phone_code: 1340,
  },
  {
    id: 235,
    iso2: 'WF',
    country_name: 'Wallis and Futuna',
    iso3: 'WLF',
    phone_code: 681,
  },
  {
    id: 236,
    iso2: 'EH',
    country_name: 'Western Sahara',
    iso3: 'ESH',
    phone_code: 212,
  },
  {
    id: 237,
    iso2: 'YE',
    country_name: 'Yemen',
    iso3: 'YEM',
    phone_code: 967,
  },
  {
    id: 238,
    iso2: 'ZM',
    country_name: 'Zambia',
    iso3: 'ZMB',
    phone_code: 260,
  },
  {
    id: 239,
    iso2: 'ZW',
    country_name: 'Zimbabwe',
    iso3: 'ZWE',
    phone_code: 263,
  },
  {
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CssAutocomplete = styled(Autocomplete)(({ theme }) => {
  return {
    '& .MuiAutocomplete-root': {
      padding: '0px',
    },
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.palette.text.secondary,
      color: theme.palette.palette.text.black,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
    },
    '& .MuiList-root': {
      maxHeight: '60vh',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      padding: '1px 4px',
      marginRight: '5px',
      backgroundColor: theme.palette.palette.text.secondary,
      color: theme.palette.palette.text.black,
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const KnowUMore = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const isdesignationList = useSelector(
    (state) => !!state.onboard.designationList,
  );
  const [datevalue, setValue] = React.useState(new Date());
  const [industrylst, setIndustrylst] = useState([]);
  const [designationlst, setDesignationlst] = useState('');
  const [yearofexp, setYearofexp] = useState('');
  const [email, setEmail] = useState('');
  const [functionlst, setFunctionlst] = useState([]);
  const [country, setCountry] = useState(240);
  const [account_type, setAccountType] = useState('linkcxo');
  const [selectedCountry, setSelectedCountry] = useState({
    id: 99,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  });
  const isindustryList = useSelector((state) => !!state.onboard.industryList);
  const isfunctionList = useSelector((state) => !!state.onboard.functionList);
  const iscountryList = useSelector((state) => !!state.onboard.countryList);
  const countryList = useSelector((state) => state.onboard.countryCodeList);
  const designationList = useSelector((state) => state.onboard.designationList);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const user = useSelector((state) => state?.auth?.user?.user_details);
  const type = useSelector((state) => state?.auth?.type);
  const linkedinData = useSelector((state) => state?.onboard?.linkedinData);
  const tempUserId = useSelector((state) => state?.activity?.tempUserId);

  const linkedinLoginSuccess = useSelector(
    (state) => state?.onboard?.linkedinLoginSuccess,
  );
  let location = useLocation();

  useEffect(() => {
    if (queryParams.get('account_type')) {
      setAccountType(queryParams.get('account_type'));
    }
  });

  React.useEffect(() => {
    async function postUserTrack() {
      if (location?.search?.includes('campaign')) {
        debugger;
        if (tempUserId == 0 || tempUserId == undefined) {
          const data = {
            tempUserId: uuidv4(),
          };
          const assign = await dispatch(activityActions.assignUserId(data));
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname?.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: data?.tempUserIdo,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        } else {
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: tempUserId,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        }

        // navigate('/');
      }
    }
    postUserTrack();
  }, [location]);

  useEffect(() => {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18,
    );
    setValue(eighteenYearsAgo);
  }, []);

  let username = '';
  console.log(
    'user',
    user,
    linkedinData?.userProfile?.userProfile,
    account_type,
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handledateChange = (newValue) => {
    console.log(
      'newValue',
      newValue,
      moment().diff(moment(newValue, 'DD-MM-YYYY'), 'years'),
    );
    if (moment().diff(moment(newValue, 'DD-MM-YYYY'), 'years') > 18) {
      setValue(newValue);
    }
  };
  useEffect(async () => {
    const interestlist = await dispatch(onboardActions.getUserInterestList());
  }, []);

  useEffect(async () => {
    const designationlist = await dispatch(onboardActions.getDesignationList());
  }, []);
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);
  useEffect(async () => {
    if (!iscountryList) {
      const countryList = await dispatch(onboardActions.getCountryCodeList());
    }
  }, []);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrylst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const designatioChange = (event) => {
    setDesignationlst(event.target.value);
  };
  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const countryChange = (event) => {
    setCountry(event.target.value);
  };
  const onChangeyear = (event) => {
    setYearofexp(event.target.value);
  };
  const handleClick = () => {
    //desired function to be performed on clicking resend button
  };
  const onChangeemail = (event) => {
    setEmail(event.target.value);
  };
  var redirect_uri = window.location.href.split('redirect=')[1];
  const urlParams = new URLSearchParams(window.location.search);

  var sign_up_through_page = 'signup';
  if (location?.search) {
    sign_up_through_page = location?.search;
  }
  if (location?.search?.includes('job_apply')) {
    let path = location?.search?.split('/job/details/');
    let path_new = path[1];

    sign_up_through_page = 'job_apply' + '_' + path_new;
  }
  if (location?.search?.includes('event')) {
    let path = location?.search?.split('/event/details/');
    let path_new = path[1];

    sign_up_through_page = 'event' + '_' + path_new;
  }

  var link = urlParams.get('redirect');
  var path = sign_up_through_page;

  const handleSubmitform = async (data) => {
    let accnttype = 'linkcxo';

    if (account_type === 'linkedin') {
      accnttype = 'linkedin';
      // const userRegister = {
      //   sign_up_through_page: sign_up_through_page,
      //   username: linkedinData?.userProfile?.email?.emailAddress,
      //   first_name: data?.first_name,
      //   last_name: data?.last_name,
      //   company_name: data?.company_name,
      //   designation: designationlst,
      //   location: data?.location,
      //   country_id: selectedCountry?.id,
      //   mobile: data?.mobile,
      //   email: linkedinData?.userProfile?.email?.emailAddress,
      //   datetime: dateTime(),
      //   account_type: "linkedin",
      //   platfrom: "web",
      //   industry_id: industrylst[0],
      //   sub_industry_id: "",
      //   function_id: functionlst[0],
      //   aFunctionId: functionlst,
      //   aIndustryId: industrylst,
      //   date_of_birth: moment(datevalue, "YYYY-MM-DD"),
      //   total_experience: data?.yearofexp,
      //   image: linkedinData?.userProfile?.userProfile?.profileImageURL
      // };
      const checkmobile = {
        mobile: data?.mobile,
        email: '',
      };
      const userexist = await dispatch(
        authActions.checkMobileEmail(checkmobile),
      );
      if (userexist?.status === 1) {
        if (
          data?.linkedinlink.includes('linkedin.com') ||
          data?.linkedinlink.length === 0
        ) {
          const formDataLinked = new FormData();
          formDataLinked.append('sign_up_through_page', sign_up_through_page);
          formDataLinked.append('username', linkedinData?.email);
          formDataLinked.append('first_name', data?.first_name);
          formDataLinked.append('last_name', data?.last_name);
          formDataLinked.append('company_name', data?.company_name);
          formDataLinked.append('designation', designationlst);
          // formDataLinked.append('location', data?.location);
          formDataLinked.append('country_id', selectedCountry?.id);
          formDataLinked.append('mobile', data?.mobile);
          formDataLinked.append('email', linkedinData?.email);
          formDataLinked.append('datetime', dateTime());
          formDataLinked.append('account_type', 'linkedin');
          formDataLinked.append('platform', 'web');
          // formDataLinked.append('industry_id', industrylst[0]);
          // formDataLinked.append('function_id', functionlst[0]);
          // formDataLinked.append('aFunctionId', JSON.stringify(functionlst));
          // formDataLinked.append('aIndustryId', JSON.stringify(industrylst));
          // formDataLinked.append('total_experience', data?.yearofexp);
          formDataLinked.append('image', linkedinData?.image);
          // formDataLinked.append(
          //   'date_of_birth',
          //   moment(datevalue).format('YYYY-MM-DD'),
          // );

          // linkedinData?.userProfile?.userProfile?.profileImageURL
          if (designationlst.length > 0) {
            const register = await dispatch(
              onboardActions.userRegisterFirst(formDataLinked),
            );

            if (register?.status === 1) {
              const login = await dispatch(
                authActions.attemptLogin({
                  username: linkedinData?.email,
                  type: 'linkedin',
                }),
              );

              const datalinkedin = {
                user_id: register?.data?.user_id,
                about: '',
                linkedin_url: data?.linkedinlink,
              };
              const addbio = await dispatch(
                onboardActions.postBio(datalinkedin),
              );
              let firstname = data?.first_name.replace(/[^\w]/g, '');
              let lastname = data?.last_name.replace(/[^\w]/g, '');

              if (firstname?.length > 1 && lastname?.length > 1) {
                const subscription = {
                  user_id: register?.data?.user_id,
                  first_name: firstname,
                  last_name: lastname,
                };
                const addsubscription = await dispatch(
                  onboardActions.userSubscription(subscription),
                );
              } else {
                const subscription = {
                  user_id: register?.data?.user_id,
                  first_name: 'cxo',
                  last_name: 'lx',
                };
                const addsubscription = await dispatch(
                  onboardActions.userSubscription(subscription),
                );
              }

              const data22 = {
                user_id: register?.data?.user_id,
                self_id: register?.data?.user_id,
                datetime: dateTime(),
              };

              const usersre = await dispatch(
                userActions.userProfileDetails(data22),
              );

              const data1 = {
                user_id: register?.data?.user_id,
                start_item: 0,
                item_count: 10,
                datetime: dateTime(),
              };

              const formData = new FormData();
              formData.append('user_id', register?.data?.user_id);
              formData.append('start_item', 0);
              formData.append('item_count', 10);
              formData.append('datetime', dateTime());

              const postlist = await dispatch(
                postActions.getPostList(formData, data1),
              );

              const data3 = {
                user_id: register?.data?.user_id,
              };
              const clubList = await dispatch(
                clubsActions.discoverClubList(data3),
              );
              const data4 = {
                user_id: register?.data?.user_id,
                categoryId: '',
              };
              const eventList = await dispatch(
                eventsActions.discoverEventList(data4),
              );

              if (redirect_uri != undefined) {
                navigate(redirect_uri);
              } else {
                navigate('/feed');
              }
            }
          }
        } else {
          const msg = 'Please provide valid linkedIn profile link.';

          const sendmessage = await dispatch(
            messageAction.messagefromComponent(msg, 'error'),
          );
        }
      } else {
        const msg = 'Mobile no already exist.';

        const sendmessage = await dispatch(
          messageAction.messagefromComponent(msg, 'error'),
        );
      }
    } else {
      if (user?.email) {
        // const userRegister = {
        //   sign_up_through_page: sign_up_through_page,
        //   username: user?.email,
        //   first_name: data?.first_name,
        //   last_name: data?.last_name,
        //   company_name: data?.company_name,
        //   designation: designationlst,
        //   location: data?.location,
        //   country_id: selectedCountry?.id,
        //   mobile: data?.mobile,
        //   email: user?.email,
        //   datetime: dateTime(),
        //   account_type: "linkcxo",
        //   platfrom: "web",
        //   industry_id: industrylst[0],
        //   sub_industry_id: "",
        //   function_id: functionlst[0],
        //   aFunctionId: functionlst,
        //   aIndustryId: industrylst,
        //   date_of_birth: moment(datevalue, "YYYY-MM-DD"),
        //   total_experience: data?.yearofexp,
        //   image:""
        // };
        const checkmobile = {
          mobile: data?.mobile,
          email: '',
        };
        const userexist = await dispatch(
          authActions.checkMobileEmail(checkmobile),
        );
        if (userexist?.status === 1) {
          const formDataemail = new FormData();
          formDataemail.append('sign_up_through_page', sign_up_through_page);
          formDataemail.append('username', user?.email);
          formDataemail.append('first_name', data?.first_name);
          formDataemail.append('last_name', data?.last_name);
          formDataemail.append('company_name', data?.company_name);
          formDataemail.append('designation', designationlst);
          // formDataemail.append('location', data?.location);
          formDataemail.append('country_id', selectedCountry?.id);
          formDataemail.append('mobile', data?.mobile);
          formDataemail.append('email', user?.email);
          formDataemail.append('datetime', dateTime());
          formDataemail.append('account_type', 'linkcxo');
          formDataemail.append('platform', 'web');
          // formDataemail.append('industry_id', industrylst[0]);
          // formDataemail.append('function_id', functionlst[0]);
          // formDataemail.append('aFunctionId', JSON.stringify(functionlst));
          // formDataemail.append('aIndustryId', JSON.stringify(industrylst));
          // formDataemail.append('total_experience', data?.yearofexp);
          formDataemail.append('image', '');
          // formDataemail.append(
          //   'date_of_birth',
          //   moment(datevalue).format('YYYY-MM-DD'),
          // );
          if (designationlst.length > 0) {
            if (
              data?.linkedinlink.includes('linkedin.com') ||
              data?.linkedinlink.length === 0
            ) {
              const register = await dispatch(
                onboardActions.userRegisterFirst(formDataemail),
              );
              if (register?.status === 1) {
                const datalinkedin = {
                  user_id: register?.data?.user_id,
                  about: '',
                  linkedin_url: data?.linkedinlink,
                };
                const addbio = await dispatch(
                  onboardActions.postBio(datalinkedin),
                );

                let firstname = data?.first_name.replace(/[^\w]/g, '');
                let lastname = data?.last_name.replace(/[^\w]/g, '');

                if (firstname?.length > 1 && lastname?.length > 1) {
                  const subscription = {
                    user_id: register?.data?.user_id,
                    first_name: firstname,
                    last_name: lastname,
                  };
                  const addsubscription = await dispatch(
                    onboardActions.userSubscription(subscription),
                  );
                } else {
                  const subscription = {
                    user_id: register?.data?.user_id,
                    first_name: firstname + 'cxo',
                    last_name: lastname + 'lxi',
                  };
                  const addsubscription = await dispatch(
                    onboardActions.userSubscription(subscription),
                  );
                }
                const data22 = {
                  user_id: register?.data?.user_id,
                  self_id: register?.data?.user_id,
                  datetime: dateTime(),
                };

                const usersre = await dispatch(
                  userActions.userProfileDetails(data22),
                );

                if (redirect_uri != undefined) {
                  let url = '/addbio?sign_up_through_page=job_apply&redirect=';
                  navigate(redirect_uri);
                } else {
                  navigate('/feed');
                }
              }
            }
          } else {
            const msg = 'Please provide valid linkedIn profile link.';

            const sendmessage = await dispatch(
              messageAction.messagefromComponent(msg, 'error'),
            );
          }
        } else {
          const msg = 'Mobile no already exist.';

          const sendmessage = await dispatch(
            messageAction.messagefromComponent(msg, 'error'),
          );
        }
      } else if (user?.mobile) {
        // const userRegister = {
        //   sign_up_through_page: sign_up_through_page,
        //   username: user?.mobile,
        //   first_name: data?.first_name,
        //   last_name: data?.last_name,
        //   company_name: data?.company_name,
        //   designation: designationlst,
        //   location: data?.location,
        //   country_id: selectedCountry?.id,
        //   mobile: user?.mobile,
        //   email: data?.email,
        //   datetime: dateTime(),
        //   account_type: "linkcxo",
        //   platfrom: "web",
        //   industry_id: industrylst[0],
        //   sub_industry_id: "",
        //   function_id: functionlst[0],
        //   aFunctionId: functionlst,
        //   aIndustryId: industrylst,
        //   date_of_birth: moment(datevalue, "YYYY-MM-DD"),
        //   total_experience: data?.yearofexp,
        //   image:""
        // };
        const checkmobile = {
          mobile: '',
          email: data?.email,
        };
        const userexist = await dispatch(
          authActions.checkMobileEmail(checkmobile),
        );
        if (userexist?.status === 1) {
          const formDataReg = new FormData();
          formDataReg.append('sign_up_through_page', sign_up_through_page);
          formDataReg.append('username', user?.mobile);
          formDataReg.append('first_name', data?.first_name);
          formDataReg.append('last_name', data?.last_name);
          formDataReg.append('company_name', data?.company_name);
          formDataReg.append('designation', designationlst);
          // formDataReg.append('location', data?.location);
          formDataReg.append('country_id', selectedCountry?.id);
          formDataReg.append('mobile', user?.mobile);
          formDataReg.append('email', data?.email);
          formDataReg.append('datetime', dateTime());
          formDataReg.append('account_type', 'linkcxo');
          formDataReg.append('platform', 'web');
          // formDataReg.append('industry_id', industrylst[0]);
          // formDataReg.append('function_id', functionlst[0]);
          // formDataReg.append('aFunctionId', JSON.stringify(functionlst));
          // formDataReg.append('aIndustryId', JSON.stringify(industrylst));
          // formDataReg.append('total_experience', data?.yearofexp);
          formDataReg.append('image', '');
          formDataReg.append(
            'date_of_birth',
            moment(datevalue).format('YYYY-MM-DD'),
          );
          if (designationlst.length > 0) {
            if (
              data?.linkedinlink.includes('linkedin.com') ||
              data?.linkedinlink.length === 0
            ) {
              const register = await dispatch(
                onboardActions.userRegisterFirst(formDataReg),
              );
              if (register?.status === 1) {
                const datalinkedin = {
                  user_id: register?.data?.user_id,
                  about: '',
                  linkedin_url: data?.linkedinlink,
                };
                const addbio = await dispatch(
                  onboardActions.postBio(datalinkedin),
                );
                let firstname = data?.first_name.replace(/[^\w]/g, '');
                let lastname = data?.last_name.replace(/[^\w]/g, '');

                if (firstname?.length > 1 && lastname?.length > 1) {
                  const subscription = {
                    user_id: register?.data?.user_id,
                    first_name: firstname,
                    last_name: lastname,
                  };
                  const addsubscription = await dispatch(
                    onboardActions.userSubscription(subscription),
                  );
                } else {
                  const subscription = {
                    user_id: register?.data?.user_id,
                    first_name: firstname + 'cxo',
                    last_name: lastname + 'lxi',
                  };
                  const addsubscription = await dispatch(
                    onboardActions.userSubscription(subscription),
                  );
                }
                const data22 = {
                  user_id: register?.data?.user_id,
                  self_id: register?.data?.user_id,
                  datetime: dateTime(),
                };

                const usersre = await dispatch(
                  userActions.userProfileDetails(data22),
                );

                if (redirect_uri != undefined) {
                  navigate(redirect_uri);
                } else {
                  navigate('/feed');
                }
              }
            } else {
              const msg = 'Please provide valid linkedIn profile link.';

              const sendmessage = await dispatch(
                messageAction.messagefromComponent(msg, 'error'),
              );
            }
          }
        } else {
          const msg = 'Email Id already exist.';
          const sendmessage = await dispatch(
            messageAction.messagefromComponent(msg, 'error'),
          );
        }
      }
    }
  };
  return (
    <Fragment>
      <Paper
        elevation={2}
        sx={{
          width: '520px',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '25px',
        }}>
        <Box
          px={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            flexDirection: 'column',
          }}>
          <Box
            p={2}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Typography
              variant="h2s"
              component="h2s"
              sx={{ fontWeight: '800' }}>
              Help us to know you more
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(handleSubmitform)}>
            <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                      }}>
                      <Menuicon name="individual" color="#38CD84" />
                    </Box>
                  </Box>
                </Box>

                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Stack>
                      <CxoInput
                        label="First Name"
                        value={value}
                        err={errors?.first_name ? errors?.first_name : false}
                        onChange={onChange}
                      />
                      <Box
                        sx={{
                          fontSize: '9px',
                          height: '10px',
                          paddingLeft: '8px',
                        }}>
                        {errors?.first_name?.message}{' '}
                      </Box>
                    </Stack>
                  )}
                  name="first_name"
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'First name is required',
                    },
                    pattern: {
                      value: /^[a-zA-Z. ]+$/,
                      message: 'Only Alphabetical Character Allowded',
                    },
                  }}
                  defaultValue={linkedinData?.firstName}
                />

                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <Stack direction="column">
                        <CxoInput
                          label="Last Name"
                          value={value}
                          err={errors?.last_name ? errors?.last_name : false}
                          onChange={onChange}
                        />
                        <Box
                          sx={{
                            fontSize: '9px',
                            height: '10px',
                            paddingLeft: '8px',
                          }}>
                          {errors?.last_name?.message}{' '}
                        </Box>
                      </Stack>
                    </>
                  )}
                  name="last_name"
                  rules={{
                    required: {
                      value: true,
                      message: 'Last name is required',
                    },
                    pattern: {
                      value: /^[a-zA-Z. ]+$/,
                      message: 'Only Alphabetical Character Allowded',
                    },
                  }}
                  defaultValue={linkedinData?.lastName}
                />
              </Stack>
            </Box>
            <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                    }}>
                    <Menuicon name="entity" color="#38CD84" />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Stack direction="column" sx={{ width: '100%' }}>
                      <CxoInput
                        label="Current Company"
                        value={value}
                        err={
                          errors?.company_name ? errors?.company_name : false
                        }
                        onChange={onChange}
                      />
                      <Box
                        sx={{
                          fontSize: '9px',
                          height: '10px',
                          paddingLeft: '8px',
                        }}>
                        {errors?.company_name?.message}{' '}
                      </Box>
                    </Stack>
                  )}
                  name="company_name"
                  rules={{
                    required: {
                      value: true,
                      message: 'Company name is required',
                    },
                  }}
                  defaultValue=""
                />
              </Stack>
            </Box>
            <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                      }}>
                      <Menuicon name="designation" color="#38CD84" />
                    </Box>
                  </Box>
                </Box>
                <Box py={1} pl={1} sx={{ width: '96%' }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Current Designation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={designationlst}
                      label="Industry List"
                      onChange={designatioChange}
                      size="small"
                      MenuProps={MenuProps}>
                      {designationList?.data
                        ? designationList?.data.map((item, key) => {
                            return (
                              <MenuItem key={key} value={item.title}>
                                {item.title}
                              </MenuItem>
                            );
                          })
                        : ''}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            {/* <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                    }}>
                    <Menuicon name="experience" color="#38CD84" />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Stack direction="column" sx={{ width: '100%' }}>
                      <CxoInput
                        label="Total Year of Experience"
                        value={value}
                        onChange={onChange}
                        err={errors?.yearofexp ? errors?.yearofexp : false}
                        type="number"
                        fontFamily="Open Sans"
                      />
                      <Box
                        sx={{
                          fontSize: '9px',
                          height: '10px',
                          paddingLeft: '8px',
                        }}>
                        {errors?.yearofexp?.message}{' '}
                      </Box>
                    </Stack>
                  )}
                  name="yearofexp"
                  rules={{
                    required: {
                      value: true,
                      message: 'Year of experience is required',
                    },
                  }}
                  defaultValue=""
                />
              </Stack>
            </Box> */}

            {/* <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                      }}>
                      <Menuicon name="industry" color="#38CD84" />
                    </Box>
                  </Box>
                </Box>
                <Box py={1} pl={1} sx={{ width: '96%' }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Industry
                    </InputLabel>
                    <Select
                      MenuProps={{
                        fontSize: '12px',
                        disablePadding: true,
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                        variant: 'menu',
                        getContentAnchorEl: null,
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={industrylst}
                      multiple
                      label="Industry List"
                      onChange={handleChange}
                      size="small"
                      color="secondary"
                      renderValue={(selected) => {
                        return (
                          <Stack direction="row">
                            <Typography
                              sx={{
                                fontFamily: 'Open Sans',
                              }}>
                              {selected.length}{' '}
                            </Typography>
                            <Typography sx={{ paddingLeft: '4px' }}>
                              Selected
                            </Typography>
                          </Stack>
                        );
                      }}

                      // MenuProps={MenuProps}
                    >
                      {industryList?.data
                        ? industryList?.data.map((item, key) => {
                            return (
                              <MenuItem
                                color="secondary"
                                key={key}
                                value={item.id}>
                                <ListItemText
                                  color="secondary"
                                  primary={item.title.substring(0, 25) + '...'}
                                />
                                <Checkbox
                                  color="secondary"
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                  checked={industrylst.indexOf(item.id) > -1}
                                />
                              </MenuItem>
                            );
                          })
                        : ''}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                      }}>
                      <Menuicon name="functions" color="#38CD84" />
                    </Box>
                  </Box>
                </Box>
                <Box py={1} pl={1} sx={{ width: '96%', maxHeight: '50vh' }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Function
                    </InputLabel>
                    <Select
                      MenuProps={{
                        fontSize: '12px',
                        fontFamily: 'Open Sans',
                        disablePadding: true,
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                        variant: 'menu',
                        getContentAnchorEl: null,
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={functionlst}
                      label="Industry List"
                      multiple
                      onChange={functionChange}
                      size="small"
                      renderValue={(selected) => {
                        return (
                          <Stack direction="row">
                            <Typography
                              sx={{
                                fontFamily: 'Open Sans',
                              }}>
                              {selected.length}{' '}
                            </Typography>
                            <Typography sx={{ paddingLeft: '4px' }}>
                              Selected
                            </Typography>
                          </Stack>
                        );
                      }}

                      // MenuProps={MenuProps}
                    >
                      {functionList?.data
                        ? functionList?.data.map((item, key) => {
                            return (
                              <MenuItem key={key} value={item.id}>
                                <ListItemText
                                  color="secondary"
                                  primary={item.title.substring(0, 25) + '...'}
                                />
                                <Checkbox
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                  color="secondary"
                                  checked={functionlst.indexOf(item.id) > -1}
                                />
                              </MenuItem>
                            );
                          })
                        : ''}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </Box> */}

            {account_type === 'linkedin' && (
              <Box px={3} sx={{ width: '95%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                      }}>
                      <Menuicon name="phone" color="#38CD84" />
                    </Box>
                  </Box>

                  <Box
                    pt={2.5}
                    pl={1}
                    sx={{ width: '120px', maxHeight: '60vh' }}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <Autocomplete
                              id="country-select-demo"
                              sx={{ width: 124, paddingRight: '5px' }}
                              value={selectedCountry}
                              options={countryListnew}
                              autoHighlight
                              disableClearable
                              onChange={(_event, country) => {
                                setSelectedCountry(country);
                              }}
                              getOptionLabel={(option) => {
                                return option.iso3 + ' +' + option.phone_code;
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    '& > img': { mr: 2, flexShrink: 0 },
                                    width: '120px',
                                  }}
                                  {...props}>
                                  {option.iso3} ({option.phone_code})
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                  variant="standard"
                                />
                              )}
                            />
                          </>
                        )}
                        name="country_code"
                        defaultValue="91"
                      />
                    </FormControl>
                  </Box>
                  {/*        // <Box py={1} pl={1} sx={{ width: "120px" }}>
    //   <FormControl variant="standard" fullWidth>
    //     <InputLabel id="demo-simple-select-label">
    //       Country Code
    //     </InputLabel>
    //     <Controller
    //       control={control}
    //       render={({ field: { onChange, onBlur, value } }) => (
    //         <Select
    //           labelId="demo-simple-select-label"
    //           id="demo-simple-select"
    //           value={country}
    //           label="Country Code"
    //           onChange={countryChange}
    //           size="small"
    //         >
    //           {countryList?.data
    //             ? countryList?.data.map((item, key) => {
    //                 return (
    //                   <MenuItem key={key} value={item.id}>
    //                     {" "}
    //                     {"+ " + item.phone_code}
    //                   </MenuItem>
    //                 );
    //               })
    //             : ""}
    //         </Select>
    //       )}
    //       name="country_id"
    //       defaultValue="240"
    //     />
    //   </FormControl>
    // </Box>
    */}
                  <Box pr={1}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack direction="column">
                          <CxoInput
                            label="Mobile No"
                            value={value}
                            onChange={onChange}
                            err={errors?.mobile ? errors?.mobile : false}
                            fontFamily="sans-serif"
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.mobile?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="mobile"
                      rules={{
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        pattern: {
                          value: /[0-9]{10}$/,
                          message: 'Invalid Mobile',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>
              </Box>
            )}

            {type === 'email' && account_type != 'linkedin' && (
              <Box px={3} sx={{ width: '95%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                      }}>
                      <Menuicon name="phone" color="#38CD84" />
                    </Box>
                  </Box>

                  <Box
                    pt={2.5}
                    pl={1}
                    sx={{ width: '120px', maxHeight: '60vh' }}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <Autocomplete
                              id="country-select-demo"
                              sx={{ width: 124, paddingRight: '5px' }}
                              value={selectedCountry}
                              options={countryListnew}
                              autoHighlight
                              disableClearable
                              onChange={(_event, country) => {
                                setSelectedCountry(country);
                              }}
                              getOptionLabel={(option) => {
                                return option.iso3 + ' +' + option.phone_code;
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    '& > img': { mr: 2, flexShrink: 0 },
                                    width: '120px',
                                  }}
                                  {...props}>
                                  {option.iso3} ({option.phone_code})
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                  variant="standard"
                                />
                              )}
                            />
                          </>
                        )}
                        name="country_code"
                        defaultValue="91"
                      />
                    </FormControl>
                  </Box>
                  {/*        // <Box py={1} pl={1} sx={{ width: "120px" }}>
                  //   <FormControl variant="standard" fullWidth>
                  //     <InputLabel id="demo-simple-select-label">
                  //       Country Code
                  //     </InputLabel>
                  //     <Controller
                  //       control={control}
                  //       render={({ field: { onChange, onBlur, value } }) => (
                  //         <Select
                  //           labelId="demo-simple-select-label"
                  //           id="demo-simple-select"
                  //           value={country}
                  //           label="Country Code"
                  //           onChange={countryChange}
                  //           size="small"
                  //         >
                  //           {countryList?.data
                  //             ? countryList?.data.map((item, key) => {
                  //                 return (
                  //                   <MenuItem key={key} value={item.id}>
                  //                     {" "}
                  //                     {"+ " + item.phone_code}
                  //                   </MenuItem>
                  //                 );
                  //               })
                  //             : ""}
                  //         </Select>
                  //       )}
                  //       name="country_id"
                  //       defaultValue="240"
                  //     />
                  //   </FormControl>
                  // </Box>
                  */}
                  <Box pr={1}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack direction="column">
                          <CxoInput
                            label="Mobile No"
                            value={value}
                            onChange={onChange}
                            err={errors?.mobile ? errors?.mobile : false}
                            fontFamily="sans-serif"
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.mobile?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="mobile"
                      rules={{
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        pattern: {
                          value: /[0-9]{10}$/,
                          message: 'Invalid Mobile',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>
              </Box>
            )}

            {type === 'mobile' && (
              <Box px={3} sx={{ width: '95%' }}>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '24px',
                        maxWidth: 'min-content',
                        minWidth: '24px',
                        width: '24px',
                      }}>
                      <Menuicon name="email" color="#38CD84" />
                    </Box>
                  </Box>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Stack direction="column" sx={{ width: '100%' }}>
                        <CxoInput
                          label="Email"
                          value={value}
                          err={errors?.email ? errors?.email : false}
                          onChange={onChange}
                        />
                        <Box
                          sx={{
                            fontSize: '9px',
                            height: '10px',
                            paddingLeft: '8px',
                          }}>
                          {errors?.email?.message}{' '}
                        </Box>
                      </Stack>
                    )}
                    name="email"
                    rules={{
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Invalid Email',
                      },
                    }}
                    defaultValue=""
                  />
                </Stack>
              </Box>
            )}
            {/* <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                    }}>
                    <Menuicon name="location" color="#38CD84" />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Stack direction="column" sx={{ width: '100%' }}>
                      <CxoInput
                        label="Location"
                        value={value}
                        err={errors?.location ? errors?.location : false}
                        onChange={onChange}
                      />
                      <Box
                        sx={{
                          fontSize: '9px',
                          height: '10px',
                          paddingLeft: '8px',
                        }}>
                        {errors?.location?.message}{' '}
                      </Box>
                    </Stack>
                  )}
                  name="location"
                  rules={{
                    required: {
                      value: true,
                      message: 'Location is required',
                    },
                  }}
                  defaultValue=""
                />
              </Stack>
            </Box> */}

            {/* <Box px={3} pt={1} sx={{ textAlign: 'center', width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '30px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                      width: '30px',
                    }}>
                    <Menuicon name="dob" color="#38CD84" />
                  </Box>
                </Box>
                <Box pl={1} sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Date of Birth"
                      value={datevalue}
                      onChange={handledateChange}
                      components={{
                        OpenPickerIcon: AccountCircle,
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          sx={{
                            width: '100%',
                            border: '0px',
                            svg: '#38CD84',
                            fontFamily: 'Open Sans',
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Stack>
            </Box> */}

            <Box px={3} sx={{ width: '95%' }}>
              <Stack spacing={1} direction="row">
                <Box
                  pt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                    }}>
                    <Menuicon name="link" color="#38CD84" />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Stack direction="column" sx={{ width: '100%' }}>
                      <CxoInput
                        label="LinkedIn Link"
                        value={value}
                        err={
                          errors?.linkedinlink ? errors?.linkedinlink : false
                        }
                        onChange={onChange}
                      />
                      <Box
                        sx={{
                          fontSize: '9px',
                          height: '10px',
                          paddingLeft: '8px',
                        }}>
                        {errors?.linkedinlink?.message}{' '}
                      </Box>
                    </Stack>
                  )}
                  name="linkedinlink"
                  rules={{
                    required: {
                      value: true,
                      message: 'LinkedIn link is required',
                    },
                  }}
                  defaultValue=""
                />
              </Stack>
            </Box>

            <Box
              py={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}>
              <CxoButton label="Submit" onClick={props.onclick} />
            </Box>
          </form>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default React.memo(KnowUMore);

//
//
//
//
//
// if(account_type && account_type==="linkcxohome"){
//
//   return (
//     <Fragment>
//       <Paper
//         elevation={2}
//         sx={{
//           width: "520px",
//           height: "720px",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           borderRadius: "25px",
//         }}
//       >
//         <Box
//           px={2}
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "start",
//             flexDirection: "column",
//           }}
//         >
//           <Box
//             p={2}
//             sx={{ display: "flex", justifyContent: "center", width: "100%" }}
//           >
//             <Typography
//               variant="h2s"
//               component="h2s"
//               sx={{ fontWeight: "800" }}
//             >
//               Help us to know you more
//             </Typography>
//           </Box>
//           <form onSubmit={handleSubmit(handleSubmitform)}>
//             <Box px={3} sx={{ width: "95%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", justifyContent: "center" }}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         height: "24px",
//                         maxWidth: "min-content",
//                         minWidth: "24px",
//                       }}
//                     >
//                       <Menuicon name="individual" color="#38CD84" />
//                     </Box>
//                   </Box>
//                 </Box>
//
//                 <Controller
//                   control={control}
//                   render={({ field: { onChange, onBlur, value } }) => (
//                     <CxoInput
//                       label="First Name"
//                       value={value}
//
//                       onChange={onChange}
//                     />
//                   )}
//                   name="first_name"
//                   required
//                   defaultValue={
//                      first_name
//                   }
//                 />
//                 <Controller
//                   control={control}
//                   render={({ field: { onChange, onBlur, value } }) => (
//                     <CxoInput
//                       label="Last Name"
//                       value={value}
//                       onChange={onChange}
//                     />
//                   )}
//                   name="last_name"
//                   rules={{
//                     required: {
//                       value: true,
//                       message: "This is required",
//                     },
//                   }}
//                   defaultValue={
//                    last_name
//                   }
//                 />
//               </Stack>
//             </Box>
//             <Box px={3} sx={{ width: "95%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       height: "24px",
//                       maxWidth: "min-content",
//                       minWidth: "24px",
//                     }}
//                   >
//                     <Menuicon name="entity" color="#38CD84" />
//                   </Box>
//                 </Box>
//                 <Controller
//                   control={control}
//                   render={({ field: { onChange, onBlur, value } }) => (
//                     <CxoInput
//                       label="Company"
//                       value={value}
//                       onChange={onChange}
//                     />
//                   )}
//                   name="company_name"
//                   rules={{
//                     required: {
//                       value: true,
//                       message: "This is required",
//                     },
//                   }}
//                   defaultValue={company ? company :""}
//                 />
//               </Stack>
//             </Box>
//             <Box px={3} sx={{ width: "95%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", justifyContent: "center" }}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         height: "24px",
//                         maxWidth: "min-content",
//                         minWidth: "24px",
//                       }}
//                     >
//                       <Menuicon name="designation" color="#38CD84" />
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box py={1} pl={1} sx={{ width: "96%" }}>
//                   <FormControl variant="standard" fullWidth>
//                     <InputLabel id="demo-simple-select-label">
//                       Designation
//                     </InputLabel>
//                     <Select
//                       labelId="demo-simple-select-label"
//                       id="demo-simple-select"
//                       value={designationlst}
//                       label="Industry List"
//                       onChange={designatioChange}
//                       size="small"
//                     >
//                       {designationList?.data
//                         ? designationList?.data.map((item, key) => {
//                             return (
//                               <MenuItem key={key} value={item.title}>
//                                 {item.title}
//                               </MenuItem>
//                             );
//                           })
//                         : ""}
//                     </Select>
//                   </FormControl>
//                 </Box>
//               </Stack>
//             </Box>
//
//             <Box px={3} sx={{ width: "84%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       height: "24px",
//                       maxWidth: "min-content",
//                       minWidth: "24px",
//                     }}
//                   >
//                     <Menuicon name="experience" color="#38CD84" />
//                   </Box>
//                 </Box>
//                 <Controller
//                   control={control}
//                   render={({ field: { onChange, onBlur, value } }) => (
//                     <CxoInput
//                       label="Total Year of Experience"
//                       value={yearofexp}
//                       onChange={onChangeyear}
//                       type="number"
//                     />
//                   )}
//                   name="yearofexp"
//                   defaultValue=""
//                   rules={{
//                     required: {
//                       value: true,
//                       message: "This is required",
//                     },
//                   }}
//                 />
//               </Stack>
//             </Box>
//
//             <Box px={3} sx={{ width: "95%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", justifyContent: "center" }}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         height: "24px",
//                         maxWidth: "min-content",
//                         minWidth: "24px",
//                       }}
//                     >
//                       <Menuicon name="industry" color="#38CD84" />
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box py={1} pl={1} sx={{ width: "96%" }}>
//                   <FormControl variant="standard" fullWidth>
//                     <InputLabel id="demo-simple-select-label">
//                       Industry
//                     </InputLabel>
//                     <Select
//                       labelId="demo-simple-select-label"
//                       id="demo-simple-select"
//                       value={industrylst}
//                       multiple
//                       label="Industry List"
//                       onChange={handleChange}
//                       size="small"
//                       color="secondary"
//                       renderValue={(selected) => {
//                         console.log("selected", selected);
//                         return selected.length + " " + "Selected";
//                       }}
//                     >
//                       {industryList?.data
//                         ? industryList?.data.map((item, key) => {
//                             return (
//                               <MenuItem
//                                 color="secondary"
//                                 key={key}
//                                 value={item.id}
//                               >
//                                 <ListItemText
//                                   color="secondary"
//                                   primary={item.title}
//                                 />
//                                 <Checkbox
//                                   color="secondary"
//                                   icon={<RadioButtonUncheckedIcon />}
//                                   checkedIcon={<CheckCircleIcon />}
//                                   checked={industrylst.indexOf(item.id) > -1}
//                                 />
//                               </MenuItem>
//                             );
//                           })
//                         : ""}
//                     </Select>
//                   </FormControl>
//                 </Box>
//               </Stack>
//             </Box>
//
//             <Box px={3} sx={{ width: "95%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", justifyContent: "center" }}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         height: "24px",
//                         maxWidth: "min-content",
//                         minWidth: "24px",
//                       }}
//                     >
//                       <Menuicon name="functions" color="#38CD84" />
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box py={1} pl={1} sx={{ width: "96%" }}>
//                   <FormControl variant="standard" fullWidth>
//                     <InputLabel id="demo-simple-select-label">
//                       Function
//                     </InputLabel>
//                     <Select
//                       labelId="demo-simple-select-label"
//                       id="demo-simple-select"
//                       value={functionlst}
//                       label="Industry List"
//                       multiple
//                       onChange={functionChange}
//                       size="small"
//                       renderValue={(selected) => {
//                         console.log("selected", selected);
//                         return selected.length + " " + "Selected";
//                       }}
//                     >
//                       {functionList?.data
//                         ? functionList?.data.map((item, key) => {
//                             return (
//                               <MenuItem key={key} value={item.id}>
//                                 <ListItemText
//                                   color="secondary"
//                                   primary={item.title}
//                                 />
//                                 <Checkbox
//                                   icon={<RadioButtonUncheckedIcon />}
//                                   checkedIcon={<CheckCircleIcon />}
//                                   color="secondary"
//                                   checked={functionlst.indexOf(item.id) > -1}
//                                 />
//                               </MenuItem>
//                             );
//                           })
//                         : ""}
//                     </Select>
//                   </FormControl>
//                 </Box>
//               </Stack>
//             </Box>
//             {type === "email" && (
//               <Box px={3} sx={{ width: "95%" }}>
//                 <Stack spacing={1} direction="row">
//                   <Box
//                     pt={2}
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         height: "24px",
//                         maxWidth: "min-content",
//                         minWidth: "24px",
//                       }}
//                     >
//                       <Menuicon name="phone" color="#38CD84" />
//                     </Box>
//                   </Box>
//
//                   <Box py={1} pl={1} sx={{ width: "120px" }}>
//                     <FormControl variant="standard" fullWidth>
//                       <InputLabel id="demo-simple-select-label">
//                         Country Code
//                       </InputLabel>
//                       <Controller
//                         control={control}
//                         render={({ field: { onChange, onBlur, value } }) => (
//                           <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={country}
//                             label="Country Code"
//                             onChange={countryChange}
//                             size="small"
//                           >
//                             {countryList?.data
//                               ? countryList?.data.map((item, key) => {
//                                   return (
//                                     <MenuItem key={key} value={item.id}>
//                                       {" "}
//                                       {"+ " + item.phone_code}
//                                     </MenuItem>
//                                   );
//                                 })
//                               : ""}
//                           </Select>
//                         )}
//                         name="country_id"
//                         defaultValue=""
//                       />
//                     </FormControl>
//                   </Box>
//                   <Box pr={1}>
//                     <Controller
//                       control={control}
//                       render={({ field: { onChange, onBlur, value } }) => (
//                         <CxoInput
//                           label="Mobile No"
//                           value={value}
//                           onChange={onChange}
//                           fontFamily="sans-serif"
//                         />
//                       )}
//                       name="mobile"
//                       rules={{
//                         required: {
//                           value: true,
//                           message: "This is required",
//                         },
//                       }}
//                       defaultValue={mobile}
//                     />
//                   </Box>
//                 </Stack>
//               </Box>
//             )}
//
//             {type === "mobile" && (
//               <Box px={3} sx={{ width: "84%" }}>
//                 <Stack spacing={1} direction="row">
//                   <Box
//                     pt={2}
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         height: "24px",
//                         maxWidth: "min-content",
//                         minWidth: "24px",
//                         width: "24px",
//                       }}
//                     >
//
//                       <Menuicon name="email" color="#38CD84" />
//                     </Box>
//                   </Box>
//                   <Controller
//                     control={control}
//                     render={({ field: { onChange, onBlur, value } }) => (
//                       <CxoInput
//                         label="Email"
//                         value={email}
//                         onChange={onChangeemail}
//                       />
//                     )}
//                     name="email"
//                     rules={{
//                       required: {
//                         value: true,
//                         message: "This is required",
//                       },
//                     }}
//                     defaultValue={email_home }
//                   />
//                 </Stack>
//               </Box>
//             )}
//             <Box px={3} sx={{ width: "84%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       height: "24px",
//                       maxWidth: "min-content",
//                       minWidth: "24px",
//                     }}
//                   >
//                     <Menuicon name="location" color="#38CD84" />
//                   </Box>
//                 </Box>
//                 <Controller
//                   control={control}
//                   render={({ field: { onChange, onBlur, value } }) => (
//                     <CxoInput
//                       label="Location"
//                       value={value}
//                       onChange={onChange}
//                     />
//                   )}
//                   name="location"
//                   defaultValue=""
//                 />
//               </Stack>
//             </Box>
//             <Box px={3} pt={1} sx={{ textAlign: "center", width: "95%" }}>
//               <Stack spacing={1} direction="row">
//                 <Box
//                   pt={2}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       height: "30px",
//                       maxWidth: "min-content",
//                       minWidth: "24px",
//                       width: "30px",
//                     }}
//                   >
//                     <Menuicon name="dob" color="#38CD84" />
//                   </Box>
//                 </Box>
//                 <Box pl={1} sx={{ width: "100%" }}>
//                   <LocalizationProvider dateAdapter={DateAdapter}>
//                     <DatePicker
//                       label="Date of Birth"
//                       value={datevalue}
//                       onChange={handledateChange}
//                       components={{
//                         OpenPickerIcon: AccountCircle,
//                       }}
//                       renderInput={(params) => (
//                         <TextField
//                           id="standard-basic"
//                           variant="standard"
//                           sx={{ width: "100%", border: "0px", svg: "#38CD84" }}
//                           {...params}
//                         />
//                       )}
//                     />
//                   </LocalizationProvider>
//                 </Box>
//               </Stack>
//             </Box>
//             <Box
//               py={2}
//               sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 width: "100%",
//               }}
//             >
//               <CxoButton label="Submit" onClick={props.onclick} />
//             </Box>
//           </form>
//         </Box>
//       </Paper>
//     </Fragment>
//   )
//
// }
// else{
// const userRegister = {
//   username: user?.email,
//   first_name: ,
//   last_name: 'murmu',
//   company_name: 'Linkcxo',
//   designation: 'Android Developer',
//   location: 'Bengaluru',
//   country_id: 91,
//   mobile: '7978618794',
//   email: 'iitcloud3@gmaail.com',
//   datetime: dateTime(),
//   account_type:"",
// }

// <Box  sx={{ textAlign: "center", width: "90%" }}>
//   <Stack spacing={1} direction="row">
//     <Box
//       pt={2}
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <Box sx={{ display: "flex", justifyContent: "center" }}>
//         <img
//           alt="logo"
//           className={classes.bioIcon}
//           src={industryActive}
//         />
//       </Box>
//     </Box>
//     <Box py={1} px={2} sx={{ width: "90%" }}>
//       <FormControl variant="standard" fullWidth sx={{ width: 450 }}>
//         <InputLabel id="demo-simple-select-label">
//           Industry List
//         </InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={industrylst}
//           multiple
//           label="Industry List"
//           onChange={handleChange}
//           size="small"
//         >
//           {industryList?.data
//             ? industryList?.data.map((item, key) => {
//                 return (
//                   <MenuItem key={key} value={item.id}>
//                     {item.title}
//                   </MenuItem>
//                 );
//               })
//             : ""}
//         </Select>
//       </FormControl>
//     </Box>
//   </Stack>
// </Box>

// {JSON.stringify(errors)}

// if (redirect_uri != undefined) {
//   let url =
//   navigate(
//     "/addbio?sign_up_through_page=job_apply&redirect=" +
//       redirect_uri
//   );
// } else {
//   navigate("/addbio");
// }
