import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getUpcomingEventList,
  getPastEventList,
} from '../../actions/jobs-list-action';
import { getEventList } from '../../store/activity';
import { Grid, Box, Stack } from '@mui/material';
import moment from 'moment';
import Host from '../../resources/new-img/Host.png';
import Participate from '../../resources/new-img/Participate.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import KeyboardArrowRightIcon from '../../resources/new-img/Right Arrow - White.png';
import KeyboardArrowLeftIcon from '../../resources/new-img/Left Arrow - White.png';
import Promote from '../../resources/new-img/Promote.png';
import { makeStyles } from '@mui/styles';
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Event(props) {
  useEffect(() => {
    props.getEventList();
    props.getPastEventList();
    props.getUpcomingEventList();

    console.log('Upcoming Events:', props.upcomingeventlist);
  }, []);

  const handleDetails = (item) => {
    let title = item?.title + '-' + 'lx' + item?.id;
    window.location.href = '/eventDetails/' + title;
  };

  const handleDetailsAttend = (item) => {
    let title = item?.title + '-' + 'lx' + item?.id;
    window.location.href =
      '/login?sign_up_through_page=event&redirect=/event/details/' + title;
  };

  const firstUpcomingEvent = props.upcomingeventlist[0] || {};

  const CustomRight = ({ onClick }) => (
    <button onClick={onClick} className="custom-Right-Arrow2">
      <img
        alt=""
        src={KeyboardArrowRightIcon}
        style={{ width: '35px', height: '35px', marginTop:'30px'}}
      />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button onClick={onClick} className="custom-Left-Arrow2">
      <img
        alt=""
        src={KeyboardArrowLeftIcon}
        style={{ width: '35px', height: '35px', marginTop:'30px' }}
      />
    </button>
  );

  return (
    <>
      <Box className="nl-main-events" id="events" sx={{ textAlign: 'center' }}>
        <h1 className="eventheading">EVENTS</h1>
        <Box
          className="event-banner"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          mx={2}>
          <div
            className="banner-details"
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '100%',
              backgroundColor: '#333',
            }}>
            <img
              alt=""
              src={firstUpcomingEvent.image_url}
              style={{ width: '100%', height: 'auto', maxWidth: '100%' }}
            />
          </div>
          <div
            style={{
              height: 'auto',
              textAlign: 'center',
              background: '#AA933E',
              padding: '10px 0px',
              width: '100%',
            }}>
            <h2
              style={{
                color: '#14213D',
                fontSize: '20px',
                fontFamily: 'Open Sans',
              }}>
              {firstUpcomingEvent.title}
            </h2>

            <p
              style={{
                fontSize: '18px',
                color: '#14213D',
                fontFamily: 'Open Sans',
                marginTop: '5px',
              }}>
              {firstUpcomingEvent.start_date &&
                moment(firstUpcomingEvent.start_date).format('LL')}
              {' - '}
              {firstUpcomingEvent.start_time &&
                moment(firstUpcomingEvent.start_time, 'hh:mm:ss A').format(
                  'hh:mm A',
                )}
            </p>
            <button
              className="btn-event-details"
              style={{
                cursor: 'pointer',
                fontFamily: 'Open Sans',
                color: '#fff',
                backgroundColor: '#14213D',
                marginRight: '2%',
              }}
              onClick={() => handleDetails(firstUpcomingEvent)}>
              Details
            </button>
            <button
              className="btn-event-rsvp"
              style={{
                cursor: 'pointer',
                fontFamily: 'Open Sans',
                color: '#fff',
                backgroundColor: '#14213D',
                marginLeft: '2%',
              }}
              onClick={() => handleDetailsAttend(firstUpcomingEvent)}>
              Attend
            </button>

          </div>
        </Box>

        <Box px={12} sx={{ position: 'relative', textAlign: 'center' }}>
          <Carousel
            responsive={responsive}
            autoPlay={false}
            swipeable={false}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={false}
            dotListClass="custom-dot-list-style"
            customRightArrow={<CustomRight />}
            customLeftArrow={<CustomLeft />}>
            {props.upcomingeventlist.slice(1) &&
              props.upcomingeventlist.slice(1).map((item, index) => (
                <Grid
                  mt={8}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 0, sm: 0, md: 0 }}
                >
                  <Grid Item md={0.5} xs={12} ></Grid>
                  <Grid
                    Item
                    md={5}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    // pl={3}
                  >
                    <div
                      style={{
                        backgroundColor: 'black',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        borderRadius: '15px 0px 0px 15px',
                      }}>
                      {' '}
                      <img
                        style={{
                          borderRadius: '15px 0px 0px 15px',
                          objectFit: 'contain',
                          maxWidth: '100%',
                          width: 'auto',
                          height: 210,
                          '@media (max-width: 1200px)': {
                            height: 160, maxWidth: '98%',
                          },
                          border: '1px solid black',

                        }}
                        src={item.image_url}
                        alt=""
                      />
                    </div>
                  </Grid>
                  <Grid Item md={6} xs={12} sx={{ textAlign: 'left',backgroundColor:'#333',borderRadius: '0px 15px 15px 0px', }}
                     px={2} pt={2}
                  >
                    <div style={{ height: '100%',width:'100%' }}>
                      <div style={{ height: '40%', width: '100%' }}>
                        {' '}
                        <p
                          style={{
                            color: '#38cd84',
                            fontSize: '19px',
                            lineHeight: '24px',
                            fontWeight: 700,
                          }}>
                          {item?.title?.length > 100
                            ? item?.title?.substring(0, 91)+'...'
                            : item?.title}
                        </p>
                      </div>
                      <div style={{ height: '60%' }}>
                        <div style={{ display: 'flex',
                            justifyContent: "center"}} >  <p
                          style={{
                            fontFamily: 'Open Sans',
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: 500,
                          }}>
                          {item?.start_date
                            ? moment(item?.start_date).format('LL')
                            : ''}
                          {' - '}{' '}
                          {item?.start_time
                            ? moment(item?.start_time, 'hh:mm:ss A').format(
                                'hh:mm A',
                              )
                            : ''}
                        </p></div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: "center",
                          }}>
                          <div>
                            {' '}
                            <button
                              className="btn-event-details"
                              style={{
                                cursor: 'pointer',
                                fontFamily: 'Open Sans',
                              }}
                              onClick={() => handleDetails(item)}>
                              Details
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn-event-rsvp"
                              style={{
                                cursor: 'pointer',
                                fontFamily: 'Open Sans',
                              }}
                              onClick={() => handleDetailsAttend(item)}>
                              Attend
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid Item md={0.5} xs={12}></Grid>
                </Grid>
              ))}
          </Carousel>
        </Box>

        <div className="home-see-more" >
          <a
            className="home-see-more-a"
            href={
              '/login?sign_up_through_page=event_view&redirect=/events/discover'
            }>
            ...See More
          </a>
        </div>

        <Stack direction="row" mx={5}>
          <Box>
            {' '}
            <img
              src={Host}
              alt=""
              style={{ width: '52px', height: '52px', marginBottom: '15px' }}
            />
            <h3>Host</h3>
            <p style={{ color: '#fff', fontSize: '16px', padding: '5px 35px' }}>
              Host our Elite Events of C-Suite Community & Be the compere of
              your organization.
            </p>
          </Box>
          <Box>
            <img
              src={Participate}
              alt=""
              style={{ width: '52px', height: '52px', marginBottom: '15px' }}
            />
            <h3>Participate</h3>{' '}
            <p style={{ color: '#fff', fontSize: '16px', padding: '5px 35px' }}>
              Attend one of our expertly curated events, via quality symposiums.
            </p>
          </Box>
          <Box>
            <img
              src={Promote}
              alt=""
              style={{ width: '52px', height: '52px', marginBottom: '15px' }}
            />
            <h3>Promote</h3>{' '}
            <p style={{ color: '#fff', fontSize: '16px', padding: '5px 35px' }}>
              Be the presenter of your value propositions in front of an
              Exclusive C-Suite community.
            </p>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

function mapStateToProps(store) {
  return {
    upcomingeventlist: store.activity.upcomingeventList,
  };
}

export default connect(mapStateToProps, {
  getUpcomingEventList,
  getPastEventList,
  getEventList,
})(Event);
