import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import './new-landing.css';
import Banner from './Banner';
import WhyLinkCXO from './Why-linkcxo';
import Features from './Features';
import FeatureNew from './Feature_slide';
import WhatOurMembersNew from './what-our-new';
import CompanyLogoMain from './Company_logo_main_page';
import CompanyLogo from './userCompanyLogo';
import Industries from './Industries';
import Designation from './Designation';
import InterestPage from './Interest_Page';
import Event from './Event';
import NewJobs from './newjobs';
import MeetOurSpeakers from './Meet-our-speakers';
import MatandEngagement from './Matrix&Eng';
import RegiasterAsPartner from './PartnerRegister';
import Benefits from './benefitSection';

class AllSectionInsideNewLandingPage extends Component {
  render() {
    return (
      <Row className="new-landing-page">
        <Banner />
        <WhyLinkCXO />
        <Benefits />
        <Features />
        <FeatureNew />
        <InterestPage />
        <MatandEngagement />
        <Designation />
        <WhatOurMembersNew />
        <CompanyLogo />
        <CompanyLogoMain />
        <Industries />
        <NewJobs />
        <Event />
        <RegiasterAsPartner />
        <MeetOurSpeakers />
      </Row>
    );
  }
}

export default AllSectionInsideNewLandingPage;
