import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import gPlay from '../../resources/new-img/GooglePlaystore.png';
import Mobile1 from '../../resources/new-img/bannerMobile.png';

class Banner extends Component {
  render() {
    return (
      <Container fluid id="banner">
        <Row className="nl-banner">
          <Col>
            <Row>
              <Col sm={7} className="nl-banner-left">
                <Row>
                  <Col className="nl-banner-left-title">
                  An exclusive CXO marketplace with Exclusive Rewards, Insights, and Connections to Influence Professionals and Transform Community.
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.linkcxo"
                      target="blank">
                      {' '}
                      <img className="nl-banner-gplay" src={gPlay} alt="" />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col sm={5} className="nl-banner-right">
                <div>
                  <img className="nl-banner-img-left" src={Mobile1} alt="" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Banner;
