import axios from "axios";
import config from "./apiConfig";
import {
  handleResponse,
  handleError,
  setAuthorizationHeader,
} from "./interceptors";

const http_noauth = axios.create({
  baseURL: config.serverBaseUrl,
});

const http = axios.create({
  baseURL: config.serverBaseUrl,
});

const http_local = axios.create({
  baseURL: config.localServeBaseUrl,
});

http_noauth.interceptors.response.use(handleResponse, handleError);
http.interceptors.request.use(setAuthorizationHeader);
http.interceptors.response.use(handleResponse, handleError);

http_local.interceptors.request.use(setAuthorizationHeader);

export { http_noauth, http, http_local };

//
// export  const client = axios.create({
//     baseURL: config.serverBaseUrl,
//     headers: {
//         "Content-Type": "application/json",
//         // schema: "exotalentdb",
//         token: localStorage.getItem('User_Details') !==undefined&& JSON.parse(localStorage.getItem('User_Details')) !== null ? JSON.parse(localStorage.getItem('User_Details')).token : window.location.pathname&&window.location.pathname.split("/")[1]
//     },
// })
// /* Send token to backend for authentication */
// client.interceptors.request.use((request) => {
//     request.headers['x-auth-token'] = localStorage.getItem("User_Details");
//     return request;
// });
//
//
// client.interceptors.response.use(response => {
//    return response;
// }, error => {
//   if (error.response.status === 401) {
//    //place your reentry code
//    console.log('authorisation failed');
//   }
//   return error;
// });
//
// export  const client_noauth = axios.create({
//     baseURL: config.serverBaseUrl,
//     headers: {
//         "Content-Type": "application/json",
//         // schema: "exotalentdb",
//     },
// })
//
// client_noauth.interceptors.request.use((request) => {
//     request.headers['x-auth-token'] = '';
//     return request;
// });
