import React, { Component } from 'react';
import { useState } from 'react';
import LinkCXOLogo from '../../resources/new-img/White-Rectangle.png';
import { Container } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TopIcon from '../../resources/new-img/ChatBot.png';

class HeaderRaiseFunds extends Component {
  constructor(props) {
    super();
    // this.state = {};
    // this.scroll = this.scroll.bind(this);
  }
  // handleVentures = () => {
  //   // this.props.history.push("/ventures");
  // };
  // handleJoin = () => {
  //   // window.location = "https://www.web.linkcxo.com";
  // };

  // handleContent = () => {
  //   // this.props.history.push("/content");
  // };

  // handlehome = () => {
  //   // this.props.history.push("/");

  //   window.scrollTo({ top: 0 });
  // };

  // handleAbout = () => {
  //   // this.props.history.push("/about-us");
  // };

  // handleJob = () => {
  //   // this.props.history.push("/jobs");
  // };

  // handleEvent = () => {
  //   // this.props.history.push("/event");
  // };

  // handleBusiness = () => {
  //   // this.props.history.push("/business");
  // };
  // handleNewsAndMedia = () => {
  //   // this.props.history.push("/News & Media");
  // };
  // scroll(event) {
  //   var ele = document.getElementById(event.target.innerText);
  //   if (ele) {
  //     window.scrollTo(ele.offsetLeft, ele.offsetTop);
  //   }
  // }

  // componentDidMount() {
  //   $(document).ready(function () {
  //     $(window).scroll(function () {
  //       var scroll = $(window).scrollTop();
  //       if (scroll > 500) {
  //         $(".topicon").css("display", "block");

  //         $("#1").css({
  //           "background-color": "#1C4586",
  //           color: "white",
  //           display: "inline-block",
  //           "border-radius": "5px",
  //           padding: "6px 14px",
  //           "font-size": "16px",
  //           "font-family": "Raleway Medium",
  //         });
  //       } else {
  //         $("#1").css({ "background-color": "#1C4586", color: "#fff" });
  //         $(".topicon").css("display", "none");
  //       }
  //       if (scroll > 2880) {
  //         $("#2").css({
  //           "background-color": "#1C4586",
  //           color: "white",
  //           display: "inline-block",
  //           "border-radius": "5px",
  //           padding: "6px 14px ",
  //           "font-size": "16px",
  //           "font-family": "Raleway Medium",
  //         });
  //         $("#1").css({ "background-color": "transparent", color: "#fff" });
  //       } else {
  //         $("#2").css({ "background-color": "transparent", color: "#fff" });
  //       }
  //       if (scroll > 3800) {
  //         $("#3").css({
  //           "background-color": "#1C4586",
  //           color: "white",
  //           display: "inline-block",
  //           "border-radius": "5px",
  //           padding: "6px 14px",
  //           "font-size": "16px",
  //           "font-family": "Raleway Medium",
  //         });
  //         $("#1").css({ "background-color": "transparent", color: "#fff" });
  //         $("#2").css({ "background-color": "transparent", color: "#fff" });
  //       } else {
  //         $("#3").css({ "background-color": "transparent", color: "#fff" });
  //       }
  //       // if (scroll > 5550) {
  //       //   $("#4").css({
  //       //     "background-color": "#1C4586",
  //       //     color: "white",
  //       //     display: "inline-block",
  //       //     "border-radius": "3px",
  //       //     padding: "6px 14px",
  //       //     "font-size": "16px",
  //       //     "font-family": "Raleway Medium",
  //       //   });
  //       //   $("#1").css({ "background-color": "transparent", color: "#fff" });
  //       //   $("#2").css({ "background-color": "transparent", color: "#fff" });
  //       //   $("#3").css({ "background-color": "transparent", color: "#fff" });
  //       // } else {
  //       //   $("#4").css({ "background-color": "transparent", color: "#fff" });
  //       // }
  //       if (scroll > 4800) {
  //         $("#5").css({
  //           "background-color": "#1C4586",
  //           color: "white",
  //           display: "inline-block",
  //           "border-radius": "5px",
  //           padding: "6px 14px",
  //           "font-size": "16px",
  //           "font-family": "Raleway Medium",
  //         });
  //         $("#1").css({ "background-color": "transparent", color: "#fff" });
  //         $("#2").css({ "background-color": "transparent", color: "#fff" });
  //         $("#3").css({ "background-color": "transparent", color: "#fff" });
  //         $("#4").css({ "background-color": "transparent", color: "#fff" });
  //       } else {
  //         $("#5").css({ "background-color": "transparent", color: "#fff" });
  //       }
  //     });
  //   });
  // }

  // handleOnClickToScroll = (id, value) => {
  //   console.log("id", id, value);
  //   var ele = document.getElementById(id);
  //   console.log("windows", window);
  //   window.scrollTo({ top: value });
  // };

  render() {
    return (
      <Container fluid id="no-padding">
        <Navbar
          // collapseOnSelect
          expand="sm"
          variant="#1F1E1E"
          style={{
            background: '#1F1E1E',
            marginBottom: '-10px',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
          // className="nav-style"
        >
          <Navbar.Brand
            style={{
              cursor: 'pointer',
              paddingBottom: '15px',
            }}>
            <Link to="/AdvRaiseFunds" className="header-link">
              <img
                src={LinkCXOLogo}
                alt=""
                className="logo-image-section-style"
              />
            </Link>
          </Navbar.Brand>
          {/* <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ paddingTop: "66px" }}
          /> */}
          {/* <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
            style={{ marginRight: "60px" }}
          > */}
          <Nav>
            <Nav.Link eventKey={2} className="nave-bar-menu-style-new">
              <Link
                to="/AdvRaiseFunds"
                className="nave-bar-menu-style-link"
                style={{ color: '#38cd84' }}>
                Ventures
              </Link>
            </Nav.Link>
          </Nav>
          {/* </Navbar.Collapse> */}
        </Navbar>
      </Container>
    );
  }
}

export default HeaderRaiseFunds;
