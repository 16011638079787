import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Box, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import { useForm, Controller } from "react-hook-form";
import * as jobsActions from "../../store/jobs";
import CxoInputCss from "../../components/CxoInputCss/CxoInputCss";
import CxoButton from "../../components/CxoButton/CxoButton";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CopyUrl from "../../assets/home/copy_url.png";
import DirectShare from "../../assets/home/direct_share.png";
import WatsappShare from "../../assets/home/watsapp_share.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { alpha, styled } from "@mui/material/styles";
import Menuicon from "../../assets/menuicon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { dateTime } from "../../constants/app";
import * as eventsActions from "../../store/events";
import * as usernetworkActions from "../../store/usernetwork";

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: "#fff",
  },
}));

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    "& .MuiOutlinedInput-input": {
      color: "green",
    },
    "& .MuiSelect-select": {
      color: "#ffffff",
    },
    "& .MuiSelect-outlined": {
      color: "green",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      color: "green",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "purple",
    },
    "& MuiInputLabel-root": {
      color: theme.palette.palette.text.secondary,
    },
    "& label.Mui-focused": {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : "Raleway",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : "Raleway",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const EventInvite = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alreadyinvite, setAlready] = useState([]);
  const user = useSelector((state) => state?.user?.UserDetails);
  const openJobShare = useSelector((state) => state?.jobs?.JobShare);
  const detailsJobShare = useSelector((state) => state?.jobs?.ShareJobDetails);
  const invitedlist = useSelector((state) => state?.events?.eventInvitedList);
  const myConnectionList = useSelector(
    (state) => state?.usernetwork?.myConnectionList
  );
  const eventSendInvitees = useSelector(
    (state) => state?.events?.EventSendInvitees
  );
  const eventInvitedMembers = useSelector(
    (state) => state?.events?.eventInvitedMembers
  );
  const eventdetails = useSelector(
    (state) => state?.events?.SendInviteesEventDetails
  );
  const existingNetwork = useSelector(
    (state) => state?.usernetwork?.ExistingNetworkList
  );

  const eventAttendingMembers = useSelector(
    (state) => state?.events?.eventAttendingMembers
  );

  const eventRequestMembers = useSelector(
    (state) => state?.events?.eventRequestMembers
  );

  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [writeBody, setWriteBody] = useState("");
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [imgData, setImgData] = useState(null);
  const [displayNetwork, setDisplayNetwork] = useState(false);
  const [networklst, setNetworklst] = useState([]);

  useEffect(async () => {
    let invitees = invitedlist?.map((invited, index) => {
      return invited?.id;
    });
    setAlready(invitees);
  }, []);

  useEffect(async () => {
    const data = {
      event_id: eventdetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventInvitedMembers(data));
  }, []);

  useEffect(async () => {
    const data = {
      event_id: eventdetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventAttendingMembers(data));
    if (eventList?.status !== -1) {
      let attending = eventAttendingMembers?.map((invited, index) => {
        return invited?.id;
      });
      setAlready((oldArray) => [...oldArray, ...attending]);
    }
  }, []);

  useEffect(async () => {
    const data = {
      event_id: eventdetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventRequestedMembers(data));
    if (eventList?.status !== -1) {
      let request = eventRequestMembers?.map((invited, index) => {
        return invited?.id;
      });
      setAlready((oldArray) => [...oldArray, ...request]);
    }
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const existinglist = await dispatch(
      usernetworkActions.getExistingNetwork(data)
    );
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      content_id: eventdetails?.id,
      purpose: "event_invite",
    };
    const existinglist = await dispatch(usernetworkActions.myConnections(data));
  }, []);

  useEffect(async () => {
    const data = {
      event_id: eventdetails?.id,
    };
    const eventList = await dispatch(eventsActions.eventInvitedMembers(data));
    console.log("eventList", eventList);
    if (eventList?.status !== -1) {
      let invitees = eventList?.data?.map((invited, index) => {
        return invited?.id;
      });
      setAlready((oldArray) => [...oldArray, ...invitees]);
    }
  }, []);

  const handleWrite = (e) => {
    setWriteBody(e.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setNetworklst(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getDocument = async (event) => {
    setFile(event.target.files[0]);
    setType("document");
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmitform = async (data) => {
    console.log("handleSubmitform", data);
  };

  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const sendInvitees = await dispatch(eventsActions.closeSendInviteesEvent());
  };
  console.log(
    "EventInvite",
    invitedlist,
    existingNetwork,
    eventRequestMembers,
    eventAttendingMembers,
    eventInvitedMembers
  );
  const sendInvitation = async () => {
    const formData = new FormData();
    formData.append("event_id", eventdetails?.id);
    formData.append("user_id", user?.id);
    formData.append("aUsers", JSON.stringify(networklst));
    formData.append("datetime", dateTime());

    const share = await dispatch(
      eventsActions.eventInviteUser(formData, async (error, response) => {
        if (response) {
          const snackbar = await dispatch(
            eventsActions.closeSendInviteesEvent()
          );
        }
      })
    );

    // const data = {
    //   event_id: eventdetails?.id,
    //   user_id: user?.id,
    //   aUsers: networklst,
    //   datetime: dateTime(),
    // };
    //   if(networklst?.length > 0){
    //   const submitReport = await dispatch(eventsActions.eventInviteUser(data));
    // }

    const snackbar = await dispatch(eventsActions.closeSendInviteesEvent());

    // const snackbar = await dispatch(jobsActions.closeShareJob());
    const datashare = {
      user_id: user?.id,
    };
    const jobs = await dispatch(jobsActions.getRecommendedList(datashare));
  };

  const shareInConnection = async () => {
    setDisplayNetwork(!displayNetwork);

    // const data = {
    //   job_id: detailsJobShare.id,
    // };
    // const share = await dispatch(jobsActions.shareJob(data));
    // const snackbar = await dispatch(jobsActions.closeShareJob());
    // const datashare = {
    //   user_id: user.id,
    // };
    // const jobs = await dispatch(jobsActions.getRecommendedList(datashare));
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          zIndex: "1001",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: "2001" }}
          open={eventSendInvitees}
        >
          <Box px={0}>
            <Box
              pt={2}
              sx={{
                width: "100%",
                width: 500,
                "@media (max-width: 500px)": {
                  width: 300,
                },
              }}
            >
              <Paper
                sx={{ backgroundColor: "#232121", backgroundImage: "none" }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={0}
                >
                  <Box
                    px={3}
                    py={2}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <Typography variant="h2s" component="h2s">
                      Send Invite
                    </Typography>
                  </Box>
                  <Box pb={2} pr={2}>
                    <CloseIcon
                      sx={{
                        color: "#ffffff",
                        cursor: "pointer",
                        fontSize: "24",
                      }}
                      onClick={handleClose}
                    />
                  </Box>
                </Stack>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack direction="column" justifyContent="center">
                    <Box
                      sx={{
                        width: "400px",
                        "@media (max-width: 500px)": {
                          width: "280px",
                        },
                      }}
                    >
                      <Box py={1} pl={1} sx={{ width: "96%" }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel
                            style={{
                              color: "#38CD84",
                              fontSize: "13px",
                            }}
                            id="demo-simple-select-label"
                          >
                            Network
                          </InputLabel>
                          <CssSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={networklst}
                            multiple
                            label="Industry List"
                            onChange={handleChange}
                            size="small"
                            className={classes.underline}
                            color="secondary"
                            sx={{ color: "#38CD84" }}
                            renderValue={(selected) => {
                              console.log("selected", selected);
                              return selected.length + " " + "Selected";
                            }}
                          >
                            {existingNetwork
                              ? existingNetwork?.map((item, key) => {
                                  console.log(
                                    "alreadyinvite",
                                    alreadyinvite,
                                    item?.id
                                  );
                                  if (alreadyinvite?.indexOf(item?.id) === -1) {
                                    return (
                                      <MenuItem
                                        color="secondary"
                                        key={key}
                                        value={item.id}
                                      >
                                        <ListItemText
                                          color="secondary"
                                          primary={
                                            item.first_name +
                                            " " +
                                            item.last_name
                                          }
                                        />
                                        <Checkbox
                                          color="secondary"
                                          icon={<RadioButtonUncheckedIcon />}
                                          checkedIcon={<CheckCircleIcon />}
                                          checked={
                                            networklst.indexOf(item.id) > -1
                                          }
                                        />
                                      </MenuItem>
                                    );
                                  }
                                })
                              : ""}
                          </CssSelect>
                        </FormControl>
                      </Box>

                      <Box
                        py={4}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CxoButton onClick={sendInvitation} label="Send" />
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Backdrop>
      </Box>
    </Fragment>
  );
};

export default React.memo(EventInvite);
