import React, { Fragment, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import * as messageActions from '../../store/message';
import * as postActions from '../../store/post';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { dateTime } from '../../constants/app';

export default function ReportPost(props) {
  const dispatch = useDispatch();
  const [reportoption, setReportOption] = React.useState({
    Inappropriate: false,
    Harassment: false,
    Hate_Speech: false,
    Explicit_Content: false,
    False_Information: false,
    Intellectual_Property: false,
    Defamation: false,
    Others: false,
  });
  const [message, setMessage] = React.useState('');
  const user = useSelector((state) => state?.user?.UserDetails);
  const reportPost = useSelector((state) => state?.post?.ReportPost);
  const post = useSelector((state) => state?.post?.reportPost);
  const postEndindex = useSelector((state) => state?.post?.postEndIndex);
  const handleSubmit = async () => {
    let arr = [];
    for (const [key, value] of Object.entries(reportoption)) {
      if (value) {
        arr.push(key.split('_').join(' '));
      }
    }

    const data = {
      content_id: post?.post?.id,
      status: 'report',
      user_id: user?.id,
      message: message,
      datetime: dateTime(),
      aReport_field: JSON.stringify(arr),
    };
    const submitReport = await dispatch(postActions.contentAction(data));
    const datapost = {
      user_id: user?.id,
      start_item: 0,
      item_count: postEndindex,
      datetime: dateTime(),
    };
    const postlist = await dispatch(postActions.getPostList(datapost));
    const snackbar = await dispatch(postActions.closeReportPost());
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const snackbar = await dispatch(postActions.closeReportPost());
  };
  const handleDisplayOption = (event) => {
    setReportOption((reportoption) => ({
      ...reportoption,
      ...{ [event.target.name]: !reportoption[event.target.name] },
    }));
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={reportPost}>
        <Box
          sx={{
            width: '700px',
            height: '440px',
            backgroundColor: '#1c1c1c',
            borderRadius: '10px',
          }}>
          <Stack spacing={0} sx={{ width: '100%' }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h5s" component="h5s">
                  Why would you like to report this?
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>

            <Grid container sx={{ padding: '16px' }}>
              {Object.keys(reportoption).map((option, index) => {
                return (
                  <Grid item xs={4}>
                    <Stack spacing={0} direction="row">
                      <Checkbox
                        checked={reportoption[option]}
                        onChange={handleDisplayOption}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                        name={option}
                        sx={{
                          color: '#38CD84',
                          '&.Mui-checked': {
                            color: '#38CD84',
                          },
                        }}
                      />
                      <Box pt={1.5}>
                        <Typography variant="h6" component="h6">
                          {option.split('_').join(' ')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>

            <Box
              pt={0.5}
              pl={2}
              m={1}
              sx={{
                zIndex: '1200',
                borderStyle: 'solid',
                borderWidth: 'thin',
                borderColor: '#343232',
              }}
              className="editor">
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={5}
                maxRows={6}
                value={message}
                onChange={handleChange}
                placeholder="Start Typing Here"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box
              p={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CxoButton label="Submit" onClick={handleSubmit} />
            </Box>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
}
