import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BgImage from '../../assets/auth/BG.png';
import logo from '../../assets/header/Logo.png';
import useStyles from './authstyles';
import Signin from '../../containers/AuthenticationContainer/Signin/Signin';
import Signup from '../../containers/AuthenticationContainer/Signup/Signup';
import Otp from '../../containers/AuthenticationContainer/Signin/Otp';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import * as authActions from '../../store/auth';
import * as messageAction from '../../store/message';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
const VentureSignupContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [swtch, setSwitch] = React.useState(true);
  const [otpchecked, setOtpchecked] = React.useState(false);
  const [policyagree, setPolicyagree] = React.useState(true);
  const [otpUser, setOtpUser] = React.useState('');
  const [type, setType] = React.useState('mobile');
  const [open, setOpen] = React.useState(false);
  const [minutes, setMinutes] = React.useState(1);
  const [seconds, setSeconds] = React.useState(4);
  const queryParams = new URLSearchParams(window.location.search);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const account_type = queryParams.get('account_type');
  const email_home = queryParams.get('email');
  const mobile = queryParams.get('mobile');
  const first_name = queryParams.get('first_name');
  const last_name = queryParams.get('last_name');
  const company = queryParams.get('company');
  console.log('mobile', mobile);
  console.log('email', email_home);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (event) => {
    setChecked(!checked);
  };
  const handleswitchChange = (event) => {
    setSwitch(!swtch);
  };

  const handleOtp = async (data) => {
    let sendotpdata = {};
    let checkmobile = {};
    if (swtch) {
      sendotpdata = {
        username: data?.mobile,
        country_code: '+' + data.country_code,
      };
      checkmobile = {
        mobile: data?.mobile,
        email: '',
      };
      setType('mobile');
    } else {
      sendotpdata = {
        username: data?.email,
        country_code: '+91',
      };
      checkmobile = {
        mobile: '',
        email: data?.email,
      };
      setType('email');
    }

    const userexist = await dispatch(authActions.checkMobileEmail(checkmobile));
    if (userexist?.status === -1) {
      const checkauth = await dispatch(
        authActions.sendEmailOtp(sendotpdata, 'signedin'),
      );
      // const checkauth = await dispatch(authActions.sendEmailOtp(data));
      // console.log("checkauth", checkauth);
      if (checkauth?.status === 1) {
        setOtpUser(sendotpdata);
        setChecked(!checked);
        setOtpchecked(!otpchecked);
      }
    } else {
      let msg = 'hi';
      if (data?.mobile) {
        msg =
          'you have not signed up using this mobile number. Please use correct mobile number or email Id or sign up if not registered yet.';
      } else if (data?.email) {
        msg =
          'you have not signed up using this email ID. Please use correct mobile number or email Id or sign up if not registered yet.';
      }
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
      setChecked(false);
    }
  };

  const onclickOtpSignup = async (data) => {
    console.log('signuo', data);
    let sendotpdata = {};
    let checkmobile = {};
    if (swtch) {
      sendotpdata = {
        username: data?.mobile,
        country_code: '+' + data.country_code,
      };
      checkmobile = {
        mobile: data?.mobile,
        email: '',
      };
      setType('mobile');
    } else {
      sendotpdata = {
        username: data?.email,
        country_code: '+91',
      };
      checkmobile = {
        mobile: '',
        email: data?.email,
      };
      setType('email');
    }

    const userexist = await dispatch(authActions.checkMobileEmail(checkmobile));
    if (userexist?.status === 1) {
      const checkauth = await dispatch(
        authActions.sendEmailOtp(sendotpdata, 'signedup'),
      );
      // const checkauth = await dispatch(authActions.sendEmailOtp(data));
      console.log('checkauth', checkauth);
      if (checkauth?.status === 1) {
        setOtpUser(sendotpdata);
        setChecked(false);
        setOtpchecked(true);
      }
    } else {
      let msg = '';
      if (data?.mobile) {
        msg =
          'This mobile is already Signed Up,  Please Login or try with different Mobile No';
      } else if (data?.email) {
        msg =
          'This Email Id is already Signed Up,  Please Login or try with different Email Id';
      }
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
      setChecked(true);
    }
  };

  const handleChangeText = (swt) => {
    if (swt === 'checked') {
      setSwitch(false);
    } else if (swt === 'unchecked') {
      setSwitch(true);
    }
  };

  const handleChangepolicy = () => {
    setPolicyagree(!policyagree);
  };
  const attemptLogin = async (username, user_id) => {
    const login = await dispatch(
      authActions.attemptLogin({
        username: username,
        temp_user_id: 0,
        id: user_id,
        type: type,
      }),
    );

    const profilefield = await dispatch(
      authActions.profileRequiredField({
        user_id: login?.data?.user_details?.id,
      }),
    );
    if (profilefield?.status == 1) {
      navigate('/feed');
    } else if (profilefield?.status == -1) {
      navigate('/user/details');
    } else if (profilefield?.status == -2) {
      navigate('/login');
    }
  };

  const checkOtp = async (username, otp) => {
    const data = { username: username.username, otp: otp, temp_user_id: 0 };
    const checkotp = await dispatch(authActions.verifyOtp(data));

    if (checkotp.status === 1) {
      return attemptLogin(username.username, checkotp.data.user_id);
    }
  };
  const resendOtp = async (user) => {
    setMinutes(2);
    setSeconds(59);
    const data = { username: user, country_code: '+91' };
    const resendotp = await dispatch(authActions.sendEmailOtp(otpUser));
  };
  const classes = useStyles();
  return (
    <Fragment>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {openSnackbar && <CustomizedSnackbars />}
      <Paper
        elevation={0}
        sx={{
          backgroundImage: `url(${BgImage})`,
          height: 'inherit',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <Grid container spacing={0} className={classes.outergrid}>
          <Grid item xs={12} md={6} sm={12}>
            <Box pl={5} pt={2}>
              <img alt="logo" className={classes.logo} src={logo} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {checked && !otpchecked && (
              <Signin
                onClick={handleChange}
                onclickOtp={handleOtp}
                handleChange={handleswitchChange}
                handleChangeText={handleChangeText}
                checked={swtch}
              />
            )}

            {!checked && !otpchecked && (
              <Signup
                onClick={handleChange}
                onclickOtpSignup={onclickOtpSignup}
                handleChange={handleswitchChange}
                handleChangeText={handleChangeText}
                policyagree={policyagree}
                handleChangepolicy={handleChangepolicy}
                checked={swtch}
                mobile={mobile}
                email={email_home}
              />
            )}
            {!checked && otpchecked && (
              <Otp
                user={otpUser}
                minutes={minutes}
                seconds={seconds}
                resendOtp={resendOtp}
                onCheckOtp={checkOtp}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default React.memo(VentureSignupContainer);

// navigate("/user/details");
