import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@mui/styles';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';

const CarouselContainer = styled.div`
  .carousel .control-dots {
    display: none; /* Hide the dots */
  }
  .carousel .thumbs-wrapper {
    display: none; /* Hide the numbering */
  }
  .carousel .carousel-status {
    font-size: 0px;
  }
`;
const LeftDiv = styled.div`
  // background-color: lightblue;
  height: 100%;
`;


const useStyles = makeStyles((theme) => ({
  container: {

    color: '#fff',
    height: 'auto',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  heading: {
    fontSize: '22px',
    color: '#38cd84',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  heading1: {
    fontSize: '30px',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  list: {
    fontSize: '16px',
    color: '#fff',
    paddingLeft: '20px',
    listStyleType: 'disc',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

const RealState = () => {
  const classes = useStyles(); const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const images = [
    'https://linkcxoprod.blob.core.windows.net/linkcxo/linkcxp-app/linkcxp-app/2023-03-21T12-16-03.918ZWadhwa%20Aquaria%20Grande22.jpg',
    'https://linkcxoprod.blob.core.windows.net/linkcxo/linkcxp-app/linkcxp-app/2022-11-16T10-07-11.973ZRUSTOMJEE%20CROWN.jpg',
    'https://linkcxoprod.blob.core.windows.net/linkcxo/linkcxp-app/linkcxp-app/2022-11-11T10-36-06.218ZWhatsApp%20Image%202022-11-10%20at%205.43.53%20PM%20%281%29.jpeg',
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ px: { xs: 3, sm: 10 } }}>
        {isMobile ? (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <LeftDiv>
                <CarouselContainer>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    stopOnHover={true}>
                    {images.map((imageUrl, index) => (
                      <div key={index}>
                        <Box
                          component="img"
                          src={imageUrl}
                          alt={`Image ${index + 1}`}
                          sx={{ height: { xs: '200px', sm: '350px' } }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </CarouselContainer>
              </LeftDiv>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              position="relative"
              justifyContent="center"
              alignItems="center"
              display="flex">
              <div className={classes.container}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '20px',
                  }}>
                  {' '}
                  <a
                    href="/login"
                    style={{ textDecoration: 'none', color: '#38cd84' }}>
                    <p
                      style={{
                        color: '#38cd84',
                        fontSize: '20px',
                        fontWeight: 500,
                      }}>
                      See More
                    </p>
                  </a>
                </div>
                <h2 className={classes.heading}>
                  "LinkCxO collaborates with esteemed luxury real estate firms
                  to provide our members with: "
                </h2>
                <ul className={classes.list}>
                  <li>
                    Cashback upto ₹10 lakhs on Premium Properties
                    {/* Expert guidance to find for your ideal luxury property */}
                  </li>
                  <li>Negotiation support for best deals</li>
                  <li>Special cashback and discounts</li>
                  <li>Waived off clubhouse fees while purchase</li>
                </ul>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#38cd84',
                    fontWeight: 600,
                  }}>
                  Partners:
                </span>
                <span style={{ fontSize: '18px', color: '#fff' }}>
                  &nbsp;&nbsp;L&T, Godrej, Rustomjee, Lodha, The Wadhwa Group,
                  Raheja
                </span>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              position="relative"
              justifyContent="center"
              alignItems="center"
              display="flex">
              <div className={classes.container}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '20px',
                  }}>
                  {' '}
                  <a
                    href="/login"
                    style={{ textDecoration: 'none', color: '#38cd84' }}>
                    <p
                      style={{
                        color: '#38cd84',
                        fontSize: '20px',
                        fontWeight: 500,
                      }}>
                      See More
                    </p>
                  </a>
                </div>
                <h2 className={classes.heading1}> Real State</h2>
                <h2 className={classes.heading}>
                  LinkCxO collaborates with esteemed luxury real estate firms to
                  provide our members with:
                </h2>
                <ul className={classes.list}>
                  <li>
                    Cashback upto ₹10 lakhs on Premium Properties
                    {/* Expert guidance to find for your ideal luxury property */}
                  </li>
                  <li>Negotiation support for best deals</li>
                  <li>Special cashback and discounts</li>
                  <li>Waived off clubhouse fees while purchase</li>
                </ul>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#38cd84',
                    fontWeight: 600,
                  }}>
                  Partners:
                </span>
                <span style={{ fontSize: '18px', color: '#fff' }}>
                  &nbsp;&nbsp;L&T, Godrej, Rustomjee, Lodha, The Wadhwa Group,
                  Raheja
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <LeftDiv>
                <CarouselContainer>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    stopOnHover={true}>
                    {images.map((imageUrl, index) => (
                      <div key={index}>
                        <Box
                          component="img"
                          src={imageUrl}
                          alt={`Image ${index + 1}`}
                          sx={{
                            width: 'auto',
                            height: { xs: '200px', sm: '350px' },
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </CarouselContainer>
              </LeftDiv>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default RealState;
