import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import Jaguar from '../../../resources/PartnerLogos/Jaguar-01.png';
import LandRover from '../../../resources/PartnerLogos/LandRover-White.png';
import bmw from '../../../resources/PartnerLogos/bmw.jpg';
import GodrejWhite from '../../../resources/PartnerLogos/GodrejWhite.png';
import LTWhite from '../../../resources/PartnerLogos/L&T-White.png';
import LodhaWhite from '../../../resources/PartnerLogos/Lodha-White.png';
import wadhwa from '../../../resources/PartnerLogos/Wadhwa-White.png';

const RealEstate = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateNextToreward = (id, name) => {
    navigate('/rewards/realestate/partners/Real%20Estate-lx2');
  };
  return (
    <>
      <Stack
        direction={'column'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          backgroundColor: '#262525',
        }}>
        <Box
          sx={{
            paddingTop: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            paddingBottom: '36px',
          }}>
          <h2 className="partner-heading">
            Experience The Epitome Of Luxury Living{' '}
          </h2>
        </Box>
        <Stack direction={'row'} spacing={8}>
          <Box>
            <Box
              onClick={navigateNextToreward}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={GodrejWhite} className="logo_brand_img" />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              onClick={navigateNextToreward}
              sx={{
                backgroundColor: '#262525',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 16px',
              }}>
              <img
                alt=""
                src="https://s3.ap-south-1.amazonaws.com/www.linkcxo.com/Company_Logo/RectLogo/Larsen+%26+Toubro.png"
                height="80"
                width="auto"
              />
            </Box>
          </Box>

          <Box>
            <Box
              onClick={navigateNextToreward}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={LodhaWhite} className="logo_brand_img" />
            </Box>
          </Box>

          <Box>
            <Box
              onClick={navigateNextToreward}
              sx={{
                backgroundColor: '#262525',
                width: '200px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}>
              <img alt="" src={wadhwa} className="logo_brand_img" />
            </Box>
          </Box>
        </Stack>
        <Box pt={8}>
          <h2 className="partner-heading">
            Exclusive Benefits For LinkCxO Members:
          </h2>
        </Box>
        <Box sx={{ width: '100%', padding: '40px 180px' }}>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 10 Lakh on Premium Real Estate
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Cashback Upto 4 Lakh on Premium Automobiles
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Exclusive offers on Entertainment and Hospitality
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Offers and Discounts on Premium Apparel and Accessory Brands
          </p>

          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Discount on Purchases of Electronics from India’s Leading Big Box
            Brands
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Health Package Discounts from one of India’s Leading Digital
            Healthcare Platform
          </p>
          <p style={{ color: '#fff', fontSize: '18px' }}>
            * Opportunity to Invest in Hand Picked Startups to start your Angel
            Investing Journey with exclusive Discounts
          </p>
        </Box>
      </Stack>
    </>
  );
};

export default RealEstate;
