import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Grid, Box, Typography, Stack } from '@mui/material';
import RewardPartnerCard from '../../components/RewardPartnerCard/RewardPartnerCard';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import { dateTime } from '../../constants/app';
import * as rewardsActions from '../../store/rewards';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const PartnerRewards = (props) => {
  const dispatch = useDispatch();
  let params = useParams();
  const [noofPartner, setNoofPartner] = React.useState(4);
  const [noofrewardpage, setNoofRewardPage] = React.useState(0);
  const [hideMore, setHidemo] = React.useState(false);
  const user = useSelector((state) => state?.user?.UserDetails);

  // const rewardsPartnerDetails = useSelector(
  //   (state) => state?.rewards?.rewardsPartnerDetails,
  // );
  const rewardsPartnerList = useSelector(
    (state) => state?.rewards?.rewardsPartnerList,
  );
  // const rewardsCategoryList = useSelector(
  //   (state) => state?.rewards?.rewardsCategoryList,
  // );
  // const rewardsVoucherList = useSelector(
  //   (state) => state?.rewards?.rewardsVoucherList,
  // );
  const rewardsPartnerloading = useSelector(
    (state) => state?.rewards?.rewardsPartnerloading,
  );
  useEffect(async () => {
    const rewardsCategory = await dispatch(
      rewardsActions.rewardsCategoryList(),
    );
  }, []);

  useEffect(() => {
    async function fetchPartnerData() {
      if (params != 'all') {
        const id = params?.categoryId?.split('-');

        if (id?.length > 0) {
          const data = {
            category_id: id[id?.length - 1].substring(2),
            page_no: noofrewardpage,
          };
          // const eventdetails = await dispatch(eventsActions.eventDetails(data));
          const rewardspartners = await dispatch(
            rewardsActions.rewardsPartnerList(data),
          );
          // debugger;
          // const datanext = {
          //   category_id: id[id?.length - 1].substring(2),
          //   page_no: noofrewardpage + 1,
          // };
          // // const eventdetails = await dispatch(eventsActions.eventDetails(data));
          // const rewardspartnersnext = await dispatch(
          //   rewardsActions.rewardsPartnerList(datanext),
          // );

          // if (rewardspartnersnext?.data?.length === 0) {
          //   setHidemo(true);
          // }
        }
      } else {
        const data = {
          category_id: 0,
          page_no: noofrewardpage,
        };
        const rewardspartners = await dispatch(
          rewardsActions.rewardsPartnerList(data),
        );
      }
    }
    fetchPartnerData();
  }, []);

  // useEffect(async () => {
  //   const data = {
  //     category_id: 0,
  //   };
  //   const rewardspartners = await dispatch(
  //     rewardsActions.rewardsPartnerList(data)
  //   );
  // }, []);

  useEffect(async () => {
    const data = {
      partner_id: 25,
      datetime: dateTime(),
    };
    const rewardVouchelist = await dispatch(
      rewardsActions.rewardsVoucherList(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      partner_id: 25,
      datetime: dateTime(),
    };
    const rewardVouchelist = await dispatch(
      rewardsActions.rewardsPartnerDetails(data),
    );
  }, []);

  const seemore = async () => {
    setNoofRewardPage(noofrewardpage + 1);
    if (params != 'all') {
      const id = params?.categoryId?.split('-');

      if (id?.length > 0) {
        const data = {
          category_id: id[id?.length - 1].substring(2),
          page_no: noofrewardpage + 1,
        };
        // const eventdetails = await dispatch(eventsActions.eventDetails(data));
        const rewardspartners = await dispatch(
          rewardsActions.rewardsPartnerList(data),
        );
        const datanext = {
          category_id: id[id?.length - 1].substring(2),
          page_no: noofrewardpage + 2,
        };
        // const eventdetails = await dispatch(eventsActions.eventDetails(data));
        const rewardspartnersnext = await dispatch(
          rewardsActions.rewardsPartnerList(datanext),
        );

        if (rewardspartnersnext?.data?.length === 0) {
          setHidemo(true);
        }
      }
    } else {
      const data = {
        category_id: 0,
        page_no: noofrewardpage + 1,
      };
      const rewardspartners = await dispatch(
        rewardsActions.rewardsPartnerList(data),
      );

      const datanext = {
        category_id: 0,
        page_no: noofrewardpage + 2,
      };

      const rewardspartnersnext = await dispatch(
        rewardsActions.rewardsPartnerList(datanext),
      );

      if (rewardspartnersnext?.data?.length === 0) {
        setHidemo(true);
      }
    }
  };

  if (rewardsPartnerloading) {
    return (
      <Stack direction="column" spacing={1}>
        <PartnerCardSkeleton />
        <PartnerCardSkeleton />
      </Stack>
    );
  } else {
    return (
      <Fragment>
        <Box>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Grid container>
                {rewardsPartnerList?.length > 0 &&
                  rewardsPartnerList?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <RewardPartnerCard partner={list} type="partner" />
                      </Grid>
                    );
                  })}

                {rewardsPartnerList?.length === 0 && (
                  <Box pl={2}>
                    <Typography variant="h5" component="h5">
                      There is no Partner.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
            {hideMore ? (
              <></>
            ) : (
              <Box
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => seemore()}>
                  { rewardsPartnerList?.length >= 8 &&
                <Typography variant="h5" component="h5">
                  See More
                </Typography>
  }
              </Box>
            )}
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(PartnerRewards);


