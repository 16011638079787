import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  socialIcon: {
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px 0px 0px 5px",
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {},
  },
  switchlogin: {
    minWidth: "300px",
  },
  logintxt: {
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "1px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: "28px",
      letterSpacing: "1px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
      lineHeight: "28px",
      letterSpacing: "1px",
    },
  },
  socialtwitter: {
    backgroundColor: "#42B3EB",
  },
  sociallabel: {
    height: "40px",
    width: "96px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0px 5px 5px 0px",
  },
  sociallinkedin: {
    backgroundColor: "#118CAE",
  },
  socialInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "40px",
    marginLeft: "1px",
    backgroundColor: "#38CD84",
    borderRadius: "0px 5px 5px 0px",
  },
  mobileInput: {},
}));
