import React, { Component, Fragment, useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

const NetworkCardSkeleton = (props) => {
  return (
    <Stack direction="row" spacing={1.5}>
      <Card
        sx={{
          display: "flex",
          backgroundColor: "#262525",
          backgroundImage: "none",
          // minHeight: "250px",
          width: "50%",
        }}
      >
        <Stack direction="row">
          <Box>
            <Stack direction="row">
              <Skeleton variant="square" width={170} height={150} />
            </Stack>
          </Box>
          <Box >
            <Stack direction="column">
            <Box px={2} pt={2}>
              <Skeleton variant="rectangular"  width={150} height={15} />
              </Box>

              <Box px={2} pt={2}>
                <Skeleton variant="rectangular"  width={100} height={10} />
              </Box>

              <Box px={2} pt={2}>
                <Skeleton variant="rectangular"  width={150} height={12} />
              </Box>
              <Stack direction="row" spacing={2}>
              <Box px={2} pt={3}>
                <Skeleton variant="rectangular"  width={90} height={20} />
              </Box>

              <Box   pt={3}>
                <Skeleton variant="rectangular"  width={90} height={20} />
              </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>

      <Card
        sx={{
          display: "flex",
          backgroundColor: "#262525",
          backgroundImage: "none",
          // minHeight: "250px",
          width: "50%",
        }}
      >
        <Stack direction="row"  >
          <Box sx={{ width: "100%" }}>
            <Stack direction="row">
              <Skeleton variant="square" width={170} height={150} />
            </Stack>
          </Box>
          <Box >
            <Stack direction="column">
            <Box px={2} pt={2}>
              <Skeleton variant="rectangular"  width={150} height={15} />
              </Box>

              <Box px={2} pt={2}>
                <Skeleton variant="rectangular"  width={100} height={10} />
              </Box>

              <Box px={2} pt={2}>
                <Skeleton variant="rectangular"  width={150} height={12} />
              </Box>
              <Stack direction="row" spacing={2}>
              <Box px={2} pt={3}>
                <Skeleton variant="rectangular"  width={90} height={20} />
              </Box>

              <Box   pt={3}>
                <Skeleton variant="rectangular"  width={90} height={20} />
              </Box>
              </Stack>
            </Stack>
          </Box>

        </Stack>
      </Card>
    </Stack>
  );
};

export default React.memo(NetworkCardSkeleton);
