import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import 'react-quill/dist/quill.snow.css';
import './editorStyle.css';
import useStyles from './editorstyle';
import * as messageActions from '../../store/message';
import CxoButton from '../CxoButton/CxoButton';
import CxoButtonDisabled from '../CxoButton/CxoButtonDisabled';
import Icon from '../../assets/icon';
import ReactQuill from 'react-quill';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import { dateTime } from '../../constants/app';
import s3config from '../../services/apiConfig';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import AWS from 'aws-sdk';
import { makeStyles } from '@mui/styles';
import { base64StringToBlob } from 'blob-util';

const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

const S3_BUCKET = 'lxvideo-live';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: 'AKIA2WFQCNEU6XSYHWHX',
  secretAccessKey: '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',
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const EditorComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [quilltext, setQuilltext] = useState('');
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [videoSrc, seVideoSrc] = useState('');
  const [pdfSrc, sePdfSrc] = useState('');
  const [showPreview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentTitle, setDocumentTitle] = useState('');
  const [doctitleShow, setDoctitleShow] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(10);
  const editPost = useSelector((state) => state?.post?.PostEdit);
  const user = useSelector((state) => state?.user?.UserDetails);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
  }, []);

  const userPostDetails = async () => {
    const data = {
      user_id: user?.id,
      start_item: startItem,
      item_count: itemCount,
      datetime: dateTime(),
    };

    const formData = new FormData();
    formData.append('user_id', user?.id);
    formData.append('start_item', startItem);
    formData.append('item_count', itemCount);
    formData.append('datetime', dateTime());

    const postlist = await dispatch(postActions.getPostList(formData, data));
  };

  const addPost = async () => {
    const data = {
      filename: name,
    };

    // const getuser = await dispatch(postActions.getSignedUrl(data));
    // let uploadfilePath = await UploadFile(getuser?.data?.signedUrl, file);
    // console.log('uploadfile', uploadfile);

    var m = new Date();
    var dateString =
      m.getUTCFullYear() +
      '-' +
      (m.getUTCMonth() + 1) +
      '-' +
      m.getUTCDate() +
      ' ' +
      m.getUTCHours() +
      ':' +
      m.getUTCMinutes() +
      ':' +
      m.getUTCSeconds();

    if (type != 'video') {
      const formData = new FormData();
      formData.append('id', 0);
      formData.append('content', quilltext);
      formData.append('file', file);
      formData.append('user_id', user.id);
      formData.append('datetime', dateTime());
      formData.append('document_type', type);
      formData.append('user_type', 'user');
      formData.append('user_table_pk', user.id);
      if (doctitleShow) {
        formData.append('document_name', documentTitle);
      } else {
        formData.append('document_name', '');
      }
      const headers = { 'Content-Type': 'multipart/form-data' };
      // const data = {
      //   id: 0,
      //   content: quilltext,
      //   image: "",
      //   user_id: user.id,
      //   datetime: dateString,
      //   document_type: "post",
      //   user_type: "user",
      //   user_table_pk: user.id,
      //   document: "",
      // };
      if (quilltext.length > 0 || file) {
        if (user?.isVerified) {
          const addpost = await dispatch(
            postActions.sendAddNewPostData(
              formData,
              async (error, response) => {
                if (response) {
                  const snackbar = await dispatch(postActions.closePostEdit());
                  return userPostDetails();
                }
              },
            ),
          );
        } else {
          await dispatch(messageActions.openUnderVerification());
        }
      }
    } else {
      // return uploadFile();
      setLoading(true);
      const getuser = await dispatch(postActions.getSignedUrl(data));
      let uploadfilePath = await UploadFile(getuser?.data?.signedUrl, file);
      setLoading(false);
      return videoPostToServer(uploadfilePath);
    }
  };

  const handleChange = (event) => {
    if (quilltext.length < 3500) {
      setQuilltext(event.target.value.substring(0, 3500));
    } else if (event.target.value.length < quilltext.length) {
      setQuilltext(event.target.value);
    }
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(postActions.closePostEdit());
  };
  const getPhoto = async (event) => {
    console.log('image');
    setDoctitleShow(false);
    setPreview(true);
    setFile(event.target.files[0]);
    setType('image');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };
  const getDocument = async (event) => {
    setDoctitleShow(true);
    setFile(event.target.files[0]);
    setType('document');
    setName(event.target.files[0].name);
    var url = URL.createObjectURL(event.target.files[0]);
    sePdfSrc(url);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const getVideoAzure = async (event) => {
    // Math.round(event.target.files[0].size / 1024) < 5000000
    if (Math.round(event.target.files[0].size / (1024 * 1024)) < 100) {
      setDoctitleShow(false);
      setPreview(true);
      setFile(event.target.files[0]);
      setType('video');
      setName(event.target.files[0].name);
      var url = URL.createObjectURL(event.target.files[0]);
      seVideoSrc(url);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);

      // const data = {
      //   "filename": event.target.files[0].name
      // }

      // const getuser =  await dispatch(postActions.getSignedUrl(data))
      // console.log('getuser', getuser);

      // const formData = new FormData();
      // formData.append('file', event.target.files[0]);

      // const contentType = event.target.files[0].type;
      // const b64Data = event.target.files.base64?.split(",")[1];

      // const blob = base64StringToBlob(b64Data, contentType);
      // try {
      //   const config = {
      //     method: "put",
      //     maxBodyLength: Infinity,
      //     url: getuser?.data?.signedUrl,
      //     headers: {
      //       "x-ms-blob-type": "BlockBlob",
      //       "x-ms-date": new Date().toUTCString(),
      //       ContentEncoding: "base64",
      //       "Content-Type": contentType,
      //     },
      //     data: blob,
      //   };
      //   const response = await axios.request(config);
      //   console.log(response);
      //   return getuser?.data?.signedUrl?.split("?")[0];
      // } catch (error) {
      //   console.error(error);
      // }

      // try {
      //   const response = await axios.put(getuser?.data?.signedUrl, formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   });
      //  } catch (error) {
      //   console.error('Error uploading file:', error);
      // }
    } else {
      const msg = 'Video file size should be less than 100mb';
      const sendmessage = await dispatch(
        messageActions.messagefromComponent(msg, 'error'),
      );
    }
  };
  const getVideo = async (event) => {
    if (Math.round(event.target.files[0].size / 1024) < 5000000) {
      setDoctitleShow(false);
      setPreview(true);
      setFile(event.target.files[0]);
      setType('video');
      setName(event.target.files[0].name);
      console.log(
        'video',
        event.target.files[0].name,
        event.target.files[0].size,
      );
      var url = URL.createObjectURL(event.target.files[0]);
      seVideoSrc(url);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    } else {
      console.log(
        'File size should be less than 15mb',
        Math.round(event.target.files[0].size / 1024),
      );
      const msg = 'Video file size should be less than 15mb';

      const sendmessage = await dispatch(
        messageActions.messagefromComponent(msg, 'error'),
      );
    }
  };

  const videoPostToServer = async (filepath) => {
    const formData = new FormData();
    formData.append('id', 0);
    formData.append('content', quilltext);
    formData.append('image', filepath);
    formData.append('user_id', user.id);
    formData.append('datetime', dateTime());
    formData.append('document_type', 'image');
    formData.append('user_type', 'user');
    formData.append('user_table_pk', user.id);
    formData.append('document_name', 'video');
    formData.append('image_square', '');
    const headers = { 'Content-Type': 'multipart/form-data' };
    const addpost = await dispatch(
      postActions.sendAddNewPostData(formData, async (error, response) => {
        if (response) {
          const snackbar = await dispatch(postActions.closePostEdit());
          return userPostDetails();
        }
        setLoading(false);
      }),
    );

    setLoading(false);
    const snackbar = await dispatch(postActions.closePostEdit());

    const data = {
      user_id: user?.id,
      start_item: startItem,
      item_count: itemCount,
      datetime: dateTime(),
    };

    const formData1 = new FormData();
    formData1.append('user_id', user?.id);
    formData1.append('start_item', startItem);
    formData1.append('item_count', itemCount);
    formData1.append('datetime', dateTime());

    const postlist = await dispatch(postActions.getPostList(formData1, data));

    //  if (quilltext.length > 0 || file) {
    //   if (user?.isVerified) {
    //     const addpost = await dispatch(
    //       postActions.sendAddNewPostData(formData)
    //     );
    //     const snackbar = await dispatch(postActions.closePostEdit());
    //     return userPostDetails();
    //   } else {
    //     await dispatch(messageActions.openUnderVerification());
    //   }
    // }
  };

  const UploadFile = async (signedUrl, files) => {
    const contentType = files.type;
    const blob = new Blob([files], { type: contentType });
    // const b64Data = files.base64.split(",")[1];
    // const blob = base64StringToBlob(b64Data, contentType);
    try {
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: signedUrl,
        headers: {
          'x-ms-blob-type': 'blockblob',
          'x-ms-date': new Date().toUTCString(),
          ContentEncoding: 'base64',
          'Content-Type': contentType,
        },
        data: blob,
      };
      const response = await axios.request(config);
      return signedUrl.split('?')[0];
    } catch (error) {
      console.error(error);
    }
  };

  // const UploadFile = async (signedUrl, files) => {
  //   const contentType = files.fileList[0].type;
  //   const b64Data = files.base64.split(",")[1];
  //   const blob = base64StringToBlob(b64Data, contentType);
  //   try {
  //     const config = {
  //       method: "put",
  //       maxBodyLength: Infinity,
  //       url: signedUrl,
  //       headers: {
  //         "x-ms-blob-type": "BlockBlob",
  //         "x-ms-date": new Date().toUTCString(),
  //         ContentEncoding: "base64",
  //         "Content-Type": contentType,
  //       },
  //       data: blob,
  //     };
  //     const response = await axios.request(config);
  //     console.log(response);
  //     return signedUrl.split("?")[0];
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const uploadFile = async () => {
    setLoading(true);
    let namekey = uuidv4() + file.name.replace(/ /g, '_');
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();
    // .on('httpUploadProgress', (evt) => {
    //     setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .send((err,data) => {
    //     if (err) console.log('aws err',err)
    //     if (data)console.log('aws data',data)
    //
    // })
    console.log('aws ', objct);
    objct
      .then(function (data) {
        console.log(
          'aws Successfully uploaded data to ' + S3_BUCKET + '/' + namekey,
        );
        console.log('aws Success', data);
        let path =
          'https://' +
          S3_BUCKET +
          '.s3.' +
          REGION +
          '.amazonaws.com/' +
          namekey;
        videoPostToServer(path);
      })
      .catch(function (err) {
        console.log('aws err', err);
      });
  };
  const removePreview = () => {
    setPreview(false);
    setFile('');
    setType('');
    setName('');
    setImgData(null);
  };
  const handleChangeTitle = (event) => {
    setDocumentTitle(event.target.value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '80vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={editPost}>
        <Box
          sx={{
            minHeight: '400px',
            backgroundColor: '#333',
            borderRadius: '10px',
            maxHeight: '80vh',
            maxWidth: '1100px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid #fff',
            },
            minWidth: { xs: '90%', md: '1080px' },
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}>
          <Stack
            spacing={0}
            direction="column"
            justifyContent="space-between"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h2s"
                  component="h2s"
                  sx={{
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '18px',
                  }}>
                  Share Your Experience
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{
                    color: '#ffffff',
                    cursor: 'pointer',
                    fontSize: '24',
                    right: '10px',
                    top: '10px',
                    zIndix: '999999999',
                  }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>
            <Divider />
            <Box p={2} sx={{ zIndex: '1200' }} className="editor">
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={doctitleShow ? 6 : 12}
                maxRows={doctitleShow ? 6 : 16}
                value={quilltext}
                onChange={handleChange}
                placeholder="Start Typing Here"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            {doctitleShow && (
              <>
                <Divider />
                <Box p={2} sx={{ zIndex: '1200' }} className="editor">
                  <TextField
                    variant="standard"
                    fullWidth
                    rows={1}
                    maxRows={2}
                    value={documentTitle}
                    onChange={handleChangeTitle}
                    placeholder="Document Title"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </>
            )}
            {showPreview && type === 'image' && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '12',
                        position: 'absolute',
                        right: '-5px',
                        top: '-5px',
                        zIndix: '999999999',
                      }}
                      onClick={removePreview}
                    />
                    <Box p={1}>
                      <img src={imgData} alt="" height="100px" />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {doctitleShow && type === 'document' && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <CloseOutlinedIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '12',
                        position: 'absolute',
                        right: '-5px',
                        top: '-5px',
                        zIndix: '999999999',
                      }}
                      onClick={removePreview}
                    />
                    <Box p={1} sx={{ height: 'auto' }}>
                      <iframe
                        width="auto"
                        height="200px"
                        src={pdfSrc}
                        frameBorder="0"></iframe>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {showPreview && type === 'video' && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <CloseOutlinedIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '12',
                        position: 'absolute',
                        right: '-5px',
                        top: '-5px',
                        zIndix: '999999999',
                      }}
                      onClick={removePreview}
                    />
                    <Box p={1}>
                      <video height="200" controls>
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <Divider />
            <Box
              pl={3}
              sx={{
                display: 'flex',
                width: '100%',
                height: '48px',
                alignItems: 'center',
              }}>
              <Stack spacing={3} direction="row">
                <Stack spacing={2} direction="row">
                  <Box>
                    <label htmlFor="icon-button-image">
                      <Box
                        p={1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}>
                        <InsertPhotoOutlinedIcon />
                      </Box>
                    </label>
                    <input
                      id="icon-button-image"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={getPhoto}
                      name="image"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h2" component="h2">
                      Images
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={2} direction="row">
                  <Box>
                    <label htmlFor="icon-button-doc">
                      <Box
                        p={1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}>
                        <AttachFileOutlinedIcon />
                      </Box>
                    </label>
                    <input
                      id="icon-button-doc"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={getDocument}
                      name="docum"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h2" component="h2">
                      Documents
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={2} direction="row">
                  <Box>
                    <label htmlFor="icon-button-video">
                      <Box
                        p={1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}>
                        <AttachFileOutlinedIcon />
                      </Box>
                    </label>

                    <input
                      id="icon-button-video"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={getVideoAzure}
                      name="video"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h2" component="h2">
                      Video
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Divider />
            <Grid container alignItems="flex-end" sx={{ alignItems: 'center' }}>
              <Grid
                item
                xs
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '16px',
                }}>
                <Box px={3}>
                  <Typography gutterBottom variant="h6" component="h6">
                    {quilltext.length} / 3500
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  {!loading && <CxoButton label="Post" onClick={addPost} />}
                  {loading && <CxoButtonDisabled label="Post" disabled />}
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(EditorComponent);

//
// <ReactQuill
//   value={quilltext}
//   modules={modules}
//   formats={formats}
//   onChange={handleChange}
// />

//
// <input
//   id="icon-button-doc"
//   type="file"
//   style={{ display: "none" }}
//   onChange={getDocument}
//   name="docum"
// />

//
// <Stack spacing={2} direction="row">
//   <Box>
//     <label htmlFor="icon-button-file">
//       <Box
//         p={1}
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           cursor: "pointer",
//         }}
//       >
//         <VideoCameraBackOutlinedIcon />
//       </Box>
//     </label>
//   </Box>
//
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     }}
//   >
//     <Typography variant="h2" component="h2">
//       Videos
//     </Typography>
//   </Box>
// </Stack>
//
// <Stack spacing={2} direction="row">
//   <Box>
//     <label htmlFor="icon-button-doc">
//       <Box
//         p={1}
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           cursor: "pointer",
//         }}
//       >
//         <AttachFileOutlinedIcon />
//       </Box>
//     </label>
//   </Box>
//
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     }}
//   >
//     <Typography variant="h2" component="h2">
//       Documents
//     </Typography>
//   </Box>
// </Stack>
