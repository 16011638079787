import React, { useEffect, Fragment } from 'react';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import * as companyActions from '../../store/company';
import * as rewardsActions from '../../store/rewards';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { dateTime } from '../../constants/app';
import CardClub from '../../components/CardLong/CardClub';
import CxoButton from '../../components/CxoButton/CxoButton';
import EventCard from '../../components/EventCard/EventCard';
import CorporatePackageCard from '../../components/CorporatePackageCard/CorporatePackageCard';
import MembershipPackageCard from '../../components/MembershipPackage/MembershipPackageCard';
import MembershipDetailsPackageCard from '../../components/MembershipDetailsPackage/MembershipDetailsPackageCard';
import Tab, { tabClasses } from '@mui/base/Tab';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const CompanyCorporateMembership = (props) => {
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.UserDetails);
  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  const companyEventList = useSelector(
    (state) => state?.company?.companyEventList,
  );
  const corporateMemberships = useSelector(
    (state) => state?.rewards?.corporateMemberships,
  );

  const companyMembershipPurchasedDetails = useSelector(
    (state) => state?.company?.companyMembershipPurchasedDetails,
  );
  const companyMembershipTotalPurchased = useSelector(
    (state) => state?.company?.companyMembershipTotalPurchased,
  );

  const companyMembershipTotalExpired = useSelector(
    (state) => state?.company?.companyMembershipTotalExpired,
  );

  const companyMembershipTotalUsed = useSelector(
    (state) => state?.company?.companyMembershipTotalUsed,
  );

  useEffect(() => {
    async function fetchCorporateMembership() {
      const events = await dispatch(rewardsActions.corporateMembership());
    }
    fetchCorporateMembership();
  }, []);

  useEffect(() => {
    async function getCompanyMembershipPurchasedDetails() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
      };
      const membership = await dispatch(
        companyActions.getCompanyMembershipPurchasedDetails(data),
      );
    }
    getCompanyMembershipPurchasedDetails();
  }, []);

  useEffect(() => {
    async function fetchCorporateMembershipPurchased() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
      };
      const company = await dispatch(
        companyActions.getCompanyMembershipPurchased(data),
      );
    }
    fetchCorporateMembershipPurchased();
  }, []);

  useEffect(() => {
    async function fetchCorporateMembershipRedeemed() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
      };
      const company = await dispatch(
        companyActions.getCompanyMembershipRedeemed(data),
      );
    }
    fetchCorporateMembershipRedeemed();
  }, []);

  useEffect(() => {
    async function fetchCorporateMembershipExpired() {
      const id = params?.id?.split('-');
      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
      };
      const company = await dispatch(
        companyActions.getCompanyMembershipExpired(data),
      );
    }
    fetchCorporateMembershipExpired();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const formData = new FormData();
      formData.append('user_id', user?.id);
      formData.append(
        'user_table_pk',
        parseInt(id[id?.length - 1]?.substring(2)),
      );
      formData.append('categoryId', 0);
      formData.append('page_no', 0);
      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
        categoryId: 0,
        page_no: 0,
      };
      const events = await dispatch(companyActions.getCompanyEventList(data));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.id?.split('-');
      const formData = new FormData();
      formData.append('user_id', user?.id);
      formData.append(
        'user_table_pk',
        parseInt(id[id?.length - 1]?.substring(2)),
      );
      formData.append('categoryId', 0);
      formData.append('page_no', 0);
      const data = {
        user_id: user?.id,
        user_table_pk: parseInt(id[id?.length - 1]?.substring(2)),
        categoryId: 0,
        page_no: 0,
      };
      const events = await dispatch(companyActions.getCompanyEventList(data));
    }
    fetchData();
  }, []);

  const exploreMembership = () => {
    navigate('/company/corporate/packages/membership');
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={1}></Box>

            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box>
          <Box sx={{ width: '100%' }}>
            <Box px={0} py={0}></Box>
            {companyMembershipTotalPurchased?.length == 0 && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Stack
                    direction="column"
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        color: '#ffffff',
                      }}>
                      There are no memberships purchased yet. Start exploring.
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '8px',
                      }}>
                      <CxoButton
                        label="Explore Memberships"
                        onClick={exploreMembership}
                      />
                    </Box>
                  </Stack>
                </Box>
              </>
            )}
            {companyMembershipTotalPurchased?.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  backgroundColor: '#262525',
                  backgroundImage: 'none',
                  justifyContent: 'flex-start',
                  minHeight: '71px',
                  width: '100%',
                  alignItems: 'center',
                  paddingLeft: '16px',
                }}>
                <Typography variant="h5s" component="h5s">
                  Packages
                </Typography>
              </Box>
            )}
            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      {companyMembershipTotalPurchased?.map(
                        (membership, index) => {
                          return (
                            <Grid
                              key={0}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <MembershipDetailsPackageCard
                                membership={membership}
                                expired={companyMembershipTotalExpired}
                                active={companyMembershipTotalUsed}
                                index={index}
                              />
                            </Grid>
                          );
                        },
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment>
            {/* <Box
              sx={{
                display: 'flex',
                backgroundColor: '#262525',
                backgroundImage: 'none',
                justifyContent: 'center',
                minHeight: '71px',
                width: '100%',
                alignItems: 'center',
                marginTop: '8px',
              }}>
              <Typography variant="h5s" component="h5s">
                Customise Your Package
              </Typography>
            </Box>

            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      <Grid
                        key={0}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: 0 % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <MembershipPackageCard
                          type="customize"
                          from="membership"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment> */}
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(CompanyCorporateMembership);
