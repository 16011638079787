import React, { Fragment,  useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Box, Typography } from "@mui/material";
import PeopleuMayKnow from "../../components/PeopleuMayKnow/PeopleuMayKnow";
import { dateTime } from "../../constants/app";
import { useNavigate } from "react-router-dom";
import * as usernetworkActions from "../../store/usernetwork";
const HomeNetwork = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.UserDetails);

  const mynetwork = useSelector(
    (state) => state?.usernetwork?.userRecommendationList
  );

  useEffect(() => {
    async function fetchData() {
      const data = {
        user_id: user?.id ? user?.id : 27006,
        page_no: 0,
      };
      const userList = await dispatch(
        usernetworkActions.getPeopleRecommendationList(data)
      );
    }
    fetchData();
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: 0,
    };
    const myNetwork = await dispatch(usernetworkActions.getMyNetworkList(data));
  }, []);

  const connectNetwork = async (connection) => {
    const data = {
      from_user_id: user?.id,
      to_user_id: connection,
      datetime: dateTime(),
    };
    const connectNetwork = await dispatch(
      usernetworkActions.sendConnectionNetwork(data)
    );
    const datauser = {
      user_id: user?.id,
    };
    const myNetwork = await dispatch(
      usernetworkActions.getMyNetworkList(datauser)
    );
    const userList = await dispatch(
      usernetworkActions.getPeopleRecommendationList(datauser)
    );
  };
  const ignoreNetwork = async (connection) => {
    const data = {
      from_user_id: user?.id,
      to_user_id: connection,
      datetime: dateTime(),
    };
    const ignorenetwork = await dispatch(
      usernetworkActions.sendConnectionDislike(data)
    );
    const datauser = {
      user_id: user?.id,
    };
    const myNetwork = await dispatch(
      usernetworkActions.getMyNetworkList(datauser)
    );
    const userList = await dispatch(
      usernetworkActions.getPeopleRecommendationList(datauser)
    );
  };
  return (
    <Fragment>
      <Box
        px={2}
        py={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box p={1}>
          <Typography variant="h5s" component="h5s">
            People You may know
          </Typography>
        </Box>
        <Box
          onClick={() => {
            navigate("/network");
          }}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h5" component="h5">
            See all
          </Typography>
        </Box>
      </Box>

      {mynetwork &&
        mynetwork?.slice(0, 2)?.map((network, index) => {
          return (
            <Box key={index} px={1} py={0.2}>
              <PeopleuMayKnow
                network={network}
                connectNetwork={connectNetwork}
                ignoreNetwork={ignoreNetwork}
              />
            </Box>
          );
        })}
    </Fragment>
  );
};

export default React.memo(HomeNetwork);
