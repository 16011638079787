import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Paper, Grid, Box, Typography } from '@mui/material';
import BgImage from '../../assets/auth/BG.png';
// import logo from "../../assets/auth/Logo.png";
import useStyles from './onboardstyles';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import Snackbar from '@mui/material/Snackbar';
import AddBio from './AddBio/AddBio';
const OnboardContainer = (props) => {
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  return (
    <Fragment>
      <Helmet>
        <title>Bio</title>
      </Helmet>
      {openSnackbar && <CustomizedSnackbars />}
      <Paper
        elevation={0}
        sx={{
          backgroundImage: `url(${BgImage})`,
          height: '100vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        <AddBio />
      </Paper>
    </Fragment>
  );
};

export default React.memo(OnboardContainer);
