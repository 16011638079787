import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tabname: {
    fontWeight: "600",
    letterSpacing: "0.48px",
    fontSize: "15px",
  },
  tabdigit: {
    letterSpacing: "0.18px",
    fontSize: "15px",
    fontFamily: "Open Sans",
  },
}));
