import {
  GET_JOB_SEARCH_SUCCESS,
  GET_JOB_SEARCH_REQUEST,
  GET_JOB_SEARCH_FAILURE,
  GET_JOB_FILTER_SUCCESS,
  GET_JOB_FILTER_REQUEST,
  GET_JOB_FILTER_FAILURE,
  GET_JOB_APPLY_COUNT_SUCCESS,
  GET_JOB_APPLY_COUNT_REQUEST,
  GET_JOB_APPLY_COUNT_FAILURE,
  GET_JOB_USER_STATUS_SUCCESS,
  GET_JOB_USER_STATUS_REQUEST,
  GET_JOB_USER_STATUS_FAILURE,
} from './actions';

const defaultState = {
  JobListOpen: [],
  JobListApplied: [],
  JobListSaved: [],
  JobListInactive: [],
  JobListClosed: [],
  JobListCopy: [],
  Jobdetails: {},
  JobDetails: [],
  SimilarJobList: [],
  SimilarJobCopy: [],
  RecommendatJobList: [],
  CandidateList: [],
  CandidateAppliedList: [],
  CandidateShortList: [],
  CandidateRejectedList: [],
  CandidateHoldList: [],
  CandidateProfile: {},
  CandidateProfileAbout: {},
  CandidateUserProfileDetails: {},
  loading: false,
  JobEdit: false,
  JobProfileEdit: false,
  errors: {},
  JobApply: false,
  ApplyJobDetails: {},
  JobShare: false,
  ShareJobDetails: {},
  JobEdits: [],
  jobeditnav: false,
  jobsave: false,
  ReportJob: false,
  reportjob: {},
  candidateLoading: false,
  recommendedLoading: false,
  apliedLoading: false,
  savedLoading: true,
  jobdetailsLoading: false,
  jobsearchLoading: false,
  jobSearchResult: [],
  jobfilterLoading: false,
  jobfilterResult: [],
  jobListLoading: false,
  jobApplyCount: 0,
  jobUserStatus: '',
};
export const jobsreducer = (state = defaultState, action) => {
  switch (action.type) {
    //===================================job-list======================
    case 'GET_SIMILAR_JOB_LIST_SUCCESS': {
      return {
        ...state,
        SimilarJobList: action.payload.jobs.data,
        loading: false,
        errors: {},
      };
    }
    case 'GET_RECOMMENDED_JOB_LIST_REQUEST': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          RecommendatJobList: [],
          recommendedLoading: true,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }

    case 'GET_RECOMMENDED_JOB_LIST_FAILURE': {
      return {
        ...state,
        recommendedLoading: false,
        loading: false,
        errors: {},
      };
    }
    case 'GET_RECOMMENDED_JOB_LIST_SUCCESS': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          RecommendatJobList: action.payload.jobs.data,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          RecommendatJobList: [
            ...state.RecommendatJobList,
            ...action.payload.jobs.data,
          ],
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_JOBS_LIST_REQUEST': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListOpen: [],
          jobListLoading: true,
          recommendedLoading: true,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_OPEN_JOBS_LIST_SUCCESS': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListOpen: action.payload.jobs.data,
          jobListLoading: false,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          JobListOpen: [...state.JobListOpen, ...action.payload.jobs.data],
          recommendedLoading: false,
          jobListLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_JOBS_LIST_INACTIVE_FAILURE': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListInactive: [],
          jobListInactiveLoading: true,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          jobListInactiveLoading: true,
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_JOBS_LIST_INACTIVE_REQUEST': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListInactive: [],
          jobListInactiveLoading: true,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }

    case 'GET_JOBS_LIST_CLOSED_REQUEST': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListClosed: [],
          jobListClosedLoading: true,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_JOB_LIST_BY_STATUS_SAVED_REQUEST': {
      return {
        ...state,
        savedLoading: true,
        loading: false,
        errors: {},
      };
    }
    case 'GET_JOB_LIST_BY_STATUS_SAVED_FAILURE': {
      return {
        ...state,
        savedLoading: true,
        loading: false,
        errors: {},
      };
    }
    case 'GET_JOB_LIST_BY_STATUS_SAVED_SUCCESS': {
      return {
        ...state,
        savedLoading: false,
        JobListSaved: action.payload.jobs.data,
        loading: false,
        errors: {},
      };
    }
    case 'GET_JOB_LIST_BY_STATUS_APPLIED_FAILURE': {
      return {
        ...state,
        apliedLoading: false,
        loading: false,
        errors: {},
      };
    }
    case 'GET_JOB_LIST_BY_STATUS_APPLIED_REQUEST': {
      return {
        ...state,
        apliedLoading: true,
        loading: false,
        errors: {},
      };
    }
    case 'GET_JOB_LIST_BY_STATUS_APPLIED_SUCCESS': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListApplied: action.payload.jobs.data,
          apliedLoading: false,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          JobListApplied: [
            ...state.JobListApplied,
            ...action.payload.jobs.data,
          ],
          apliedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_INACTIVE_JOBS_LIST_SUCCESS': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListInactive: action.payload.jobs.data,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          JobListInactive: [
            ...state.JobListInactive,
            ...action.payload.jobs.data,
          ],
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case 'JOB_MANAGE_REQUEST': {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }
    case 'JOB_MANAGE_SUCCESS': {
      return {
        ...state,
        loading: false,
        errors: {},
      };
    }
    case 'GET_CLOSED_JOBS_LIST_SUCCESS': {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          JobListClosed: action.payload.jobs.data,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          JobListClosed: [...state.JobListClosed, ...action.payload.jobs.data],
          loading: false,
          errors: {},
        };
      }
    }
    case 'GET_DETAILS_JOB_SUCCESS': {
      return {
        ...state,
        Jobdetails: action.payload.job.data,
        jobdetailsLoading: false,
        loading: false,
        errors: {},
      };
    }
    case 'GET_DETAILS_JOB_REQUEST': {
      return {
        ...state,
        jobdetailsLoading: true,
        loading: false,
        errors: {},
      };
    }
    case 'GET_DETAILS_JOB_FAILURE': {
      return {
        ...state,
        jobdetailsLoading: false,
        loading: false,
        errors: {},
      };
    }
    case 'GET_CANDIDATE_LIST_SUCCESS': {
      return {
        ...state,
        CandidateList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case 'GET_CANDIDATE_APPLIED_LIST_SUCCESS': {
      return {
        ...state,
        CandidateAppliedList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case 'GET_CANDIDATE_SHORTLIST_LIST_SUCCESS': {
      return {
        ...state,
        CandidateShortList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case 'CANDIDATE_PROFILE_ABOUT_FULFILLED': {
      return {
        ...state,
        CandidateProfileAbout: action.payload,
        loading: false,
        errors: {},
      };
    }
    case 'CANDIDATE_PROFILE_USER_DETAILS_FULFILLED': {
      return {
        ...state,
        CandidateUserProfileDetails: action.payload,
        candidateLoading: false,
        loading: false,
        errors: {},
      };
    }
    case 'CANDIDATE_PROFILE_USER_DETAILS_PENDING': {
      return {
        ...state,
        CandidateUserProfileDetails: {},
        candidateLoading: true,
      };
    }
    case 'CANDIDATE_PROFILE_USER_DETAILS_REJECTED': {
      return {
        CandidateUserProfileDetails: {},
        candidateLoading: false,
      };
    }

    case 'GET_CANDIDATE_REJECTED_LIST_SUCCESS': {
      return {
        ...state,
        CandidateRejectedList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case 'GET_CANDIDATE_HOLD_LIST_SUCCESS': {
      return {
        ...state,
        CandidateHoldList: action.payload.data,
        loading: false,
        errors: {},
      };
    }
    case 'CANDIDATE_PROFILE_DETAILS_PENDING': {
      return {
        ...state,
        CandidateProfile: {},
        candidateLoading: true,
        errors: {},
      };
    }
    case 'CANDIDATE_PROFILE_DETAILS_REJECTED': {
      return {
        ...state,
        CandidateProfile: {},
        candidateLoading: false,
        errors: {},
      };
    }
    case 'CANDIDATE_PROFILE_DETAILS_FULFILLED': {
      return {
        ...state,
        CandidateProfile: action.payload,
        candidateLoading: false,
        errors: {},
      };
    }

    case GET_JOB_SEARCH_SUCCESS: {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          jobSearchResult: action.payload.jobs.data,
          jobsearchLoading: false,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          jobSearchResult: [
            ...state.jobSearchResult,
            ...action.payload.jobs.data,
          ],
          jobsearchLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case GET_JOB_SEARCH_REQUEST: {
      return {
        ...state,
        jobsearchLoading: false,
        jobSearchResult: [],
      };
    }
    case GET_JOB_SEARCH_FAILURE: {
      return {
        ...state,
        jobsearchLoading: false,
        jobSearchResult: [],
      };
    }

    case GET_JOB_FILTER_SUCCESS: {
      return {
        ...state,
        jobfilterLoading: true,
        jobfilteresult: action.payload?.data,
      };
    }

    case GET_JOB_USER_STATUS_REQUEST: {
      return {
        ...state,

        jobUserStatus: '',
      };
    }
    case GET_JOB_USER_STATUS_FAILURE: {
      return {
        ...state,

        jobUserStatus: '',
      };
    }

    case GET_JOB_USER_STATUS_SUCCESS: {
      return {
        ...state,

        jobUserStatus: action.payload?.data[0],
      };
    }

    case GET_JOB_FILTER_REQUEST: {
      return {
        ...state,
        jobfilterLoading: false,
        jobfilteresult: [],
      };
    }
    case GET_JOB_FILTER_FAILURE: {
      return {
        ...state,
        jobfilterLoading: false,
        jobfilteresult: [],
      };
    }

    case GET_JOB_APPLY_COUNT_SUCCESS: {
      return {
        ...state,
        jobfilterLoading: true,
        jobApplyCount: action.payload?.job,
      };
    }
    case GET_JOB_APPLY_COUNT_REQUEST: {
      return {
        ...state,
        jobfilterLoading: false,
        jobApplyCount: [],
      };
    }
    case GET_JOB_APPLY_COUNT_FAILURE: {
      return {
        ...state,
        jobfilterLoading: false,
        jobApplyCount: [],
      };
    }
    case 'JOB_SAVE_SUCCESS': {
      return {
        ...state,
        jobsave: true,
      };
    }
    case 'CLOSE_APPLY_JOB': {
      return {
        ...state,
        JobApply: false,
        jobsave: false,
        ApplyJobDetails: {},
      };
    }
    case 'OPEN_APPLY_JOB': {
      return {
        ...state,
        JobApply: true,
        ApplyJobDetails: action.payload,
      };
    }
    case 'CLOSE_SHARE_JOB': {
      return {
        ...state,
        JobShare: false,
        ShareJobDetails: {},
      };
    }
    case 'OPEN_SHARE_JOB': {
      return {
        ...state,
        JobShare: true,
        ShareJobDetails: action.payload,
      };
    }
    case 'EDIT_JOB_OPEN': {
      return {
        ...state,
        jobeditnav: true,
        JobEdits: action.payload,
      };
    }
    case 'EDIT_JOB_CLOSE': {
      return {
        ...state,
        jobeditnav: false,
        JobEdits: {},
      };
    }
    case 'REPORT_JOB_OPEN': {
      return {
        ...state,
        ReportJob: true,
        reportjob: action.payload,
      };
    }
    case 'REPORT_JOB_CLOSE': {
      return {
        ...state,
        ReportJob: false,
        reportjob: {},
      };
    }
    default:
      return state;
  }
};
