import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useStyles from './profilestyle';
import Divider from '@mui/material/Divider';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import InterestIcon from '../../assets/chooseurinterest';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import Message from './Message';
import Skeleton from '@mui/material/Skeleton';
import * as userActions from '../../store/user';
import * as jobsActions from '../../store/jobs';
import { dateTime } from '../../constants/app';
import moment from 'moment';

const CssTab = styled(Tab)(({ theme }) => {
  return {
    '& .MuiTabs-indicator': {
      height: '10px',
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const UserProfile = (props) => {
  const classes = useStyles();
  let params = useParams();
  const location = useLocation();
  const [value, setValue] = React.useState(2);
  const [type, setType] = React.useState('All');
  const [section, setSection] = React.useState('');
  const [tab, setTab] = React.useState(0);
  const [edit, setEdit] = React.useState(true);
  const [editsection, setEditsection] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sectiondata, setSectiondata] = React.useState({});

  const handleaccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.UserDetails);
  const userimage = useSelector((state) => state?.user?.UserProfileImage);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const userotherdetails = useSelector(
    (state) => state?.user?.UserOtherDetails,
  );

  const candidateProfile = useSelector(
    (state) => state?.jobs?.CandidateProfile,
  );
  const candidateAbout = useSelector(
    (state) => state?.jobs?.CandidateProfileAbout,
  );

  const userabouts = useSelector((state) => state?.user?.UserAbouts);

  const candidateUserDetails = useSelector(
    (state) => state?.jobs?.CandidateUserProfileDetails,
  );

  const candidateLoading = useSelector(
    (state) => state?.jobs?.candidateLoading,
  );

  console.log(
    'candidate profile',
    candidateProfile,
    candidateAbout,
    userabouts,
    location?.state,
    candidateUserDetails,
  );
  const data = {
    search_key: params.keywords,
  };
  // const searchresult =  dispatch(searchActions.postSearch(data));
  useEffect(async () => {
    async function fetchDataProfile() {
      const id = params?.code?.split('-');
      const dataCadidate = {
        job_id: id[id?.length - 2],
        cand_id: id[id?.length - 1].substring(2),
      };
      const candidate = await dispatch(
        jobsActions.candidateProfileDetails(dataCadidate),
      );
    }
    fetchDataProfile();
  }, []);

  useEffect(async () => {
    const id = params?.code?.split('-');
    if (id?.length > 0) {
      const data = {
        id: id[id?.length - 1].substring(2),
      };
      const usersabout = await dispatch(userActions.userProfileAbout(data));
    }

    const candiAbout = {
      id: id[id?.length - 1].substring(2),
    };
    const candiabout = await dispatch(
      jobsActions.candidateProfileAbout(candiAbout),
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const id = params?.code?.split('-');
      //debugger;
      const data = {
        user_id: id[id?.length - 1]?.substring(2),
        self_id: id[id?.length - 1]?.substring(2),
        datetime: dateTime(),
      };
      console.log('userProfileCandidateDetails', data);
      const users = await dispatch(
        jobsActions.userProfileCandidateDetails(data, 'other'),
      );
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const id = params?.code?.split('-');
    const data = {
      job_id: id[id?.length - 2],
      from_user_id: user?.id,
      to_user_id: id[id?.length - 1]?.substring(2),
    };
    const message = await dispatch(jobsActions.jobMessageList(data));
    console.log('message', message);
  }, []);
  const handleChange = (newValue) => {
    setType(newValue);
  };
  const editProfile = () => {
    setEdit(!edit);
  };
  const editpub = (e, section) => {};

  const editSection = async (e, type, data) => {};
  const addNew = async (e, type) => {};
  const handleDelete = async () => {};
  const deleteSection = (e, type, data) => {};
  const handleClose = () => {};
  const saveFile = () => {
    if (candidateProfile?.resume) {
      saveAs(candidateProfile?.resume, 'profile.pdf');
    }
  };

  const saveResume = (resume) => {
    if (resume) {
      saveAs(resume, 'profile.pdf');
    }
  };
  if (candidateLoading) {
    return (
      <Fragment>
        <Box p={1}>
          <Box sx={{ width: '100%' }}>
            <Paper
              sx={{
                backgroundColor: '#232121',
                backgroundImage: 'none',
                overflowX: 'auto',
              }}>
              <Box
                sx={{
                  height:
                    userotherdetails?.designation?.length +
                      userotherdetails?.company_name?.length >
                    30
                      ? '168px'
                      : '148px',
                  backgroundColor: '#2c2c2d',
                }}></Box>
              <Stack spacing={1} direction="column">
                <Box
                  px={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                    marginBottom: '-60px',
                  }}>
                  <Stack direction="row">
                    <Skeleton variant="circular" width={148} height={148} />
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                  }}>
                  <Skeleton variant="text" width={100} />
                </Box>

                <Box
                  pb={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-8px',
                    transform: ' translateY(-20%)',
                  }}>
                  <Stack spacing={0.5} direction="column" alignItems="center">
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                  </Stack>
                </Box>
              </Stack>
              <Box px={{ md: 4, xs: 2 }} py={2}>
                <Stack
                  spacing={2}
                  py={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Stack direction="column" spacing={1}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                  </Stack>
                </Stack>

                <Box py={2}>
                  <Skeleton variant="text" width={'100%'} />
                </Box>
                <Box py={2}>
                  <Skeleton variant="rectangular" width={'100%'} height={118} />
                </Box>
                <Box py={2}>
                  <Stack direction="row" spacing={5}></Stack>
                </Box>
                <Stack
                  py={3}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '42px',
                      maxWidth: 'min-content',
                      minWidth: '42px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon
                      name="phone"
                      width="36"
                      color={
                        candidateUserDetails?.mobile ? '#38CD84' : '#ffffff'
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '36px',
                      maxWidth: 'min-content',
                      minWidth: '36px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon
                      name="email"
                      //color="#38CD84"
                      color={
                        candidateUserDetails?.email ? '#38CD84' : '#ffffff'
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '36px',
                      maxWidth: 'min-content',
                      minWidth: '36px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon name="website" color="#38CD84" />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                      cursor: 'pointer',
                      '@media (max-width: 500px)': {
                        minWidth: '18px',
                        height: '18px',
                      },
                    }}>
                    <Menuicon
                      name="blog"
                      color={candidateUserDetails?.blog ? '#38CD84' : '#ffffff'}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '36px',
                      maxWidth: 'min-content',
                      minWidth: '36px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon
                      name="dob"
                      //color="#38CD84"
                      color={candidateUserDetails?.dob ? '#38CD84' : '#ffffff'}
                    />
                  </Box>
                </Stack>
                <Divider />
              </Box>

              <Box py={1}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="fullWidth"
                  aria-label="disabled tabs example"
                  sx={{
                    paddingLeft: '48px',
                    paddingRight: '48px',
                    paddingTop: '16px',
                    '@media (max-width: 500px)': {
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      paddingTop: '14px',
                    },
                  }}>
                  {value === 0 && (
                    <CssTab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <InterestIcon
                            width={20}
                            name="other"
                            color="#38CD84"
                          />
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            About
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  )}
                  {value != 0 && (
                    <Tab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <InterestIcon
                            width={20}
                            name="other"
                            color="#ffffff"
                          />
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            About
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  )}
                  <Tab
                    label={
                      <Stack
                        direction="row"
                        spacing={{ md: 2, xs: 0.8 }}
                        alignItems="center">
                        {' '}
                        <Icon width={20} name="sponsers" color="#808080" />
                        <Typography
                          variant="h2"
                          component="h2"
                          sx={{
                            color: '#808080',
                            '@media (max-width: 500px)': {
                              fontSize: '14px',
                            },
                          }}>
                          Business
                        </Typography>
                      </Stack>
                    }
                    disabled
                    sx={{
                      textTransform: 'none',
                      fontSize: '20px',
                      fontWeight: '600',
                      '@media (max-width: 500px)': {
                        fontSize: '14px',
                      },
                    }}
                  />
                  {value === 2 && (
                    <CssTab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <Menuicon width={20} name="chatbot" color="#38CD84" />

                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            Message
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}
                    />
                  )}
                  {value != 2 && (
                    <CssTab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <Menuicon width={20} name="chatbot" color="#ffffff" />

                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            Message
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}
                    />
                  )}
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'skills'}
                      onChange={handleaccordionChange('skills')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="skills" color="#38CD84" />
                            </Box>
                            <Box
                              pl={2.5}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Skills
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aSkill?.map((skill, index) => {
                            if (editsection && section === 'skills') {
                              return (
                                <CssChip
                                  key={index}
                                  className={classes.chipmain}
                                  label={skill.title}
                                  variant="outlined"
                                />
                              );
                            } else {
                              return (
                                <CssChip
                                  key={index}
                                  className={classes.chipmain}
                                  label={skill.title}
                                  variant="outlined"
                                />
                              );
                            }
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'interest'}
                      onChange={handleaccordionChange('interest')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '36px',
                                maxWidth: 'min-content',
                                minWidth: '36px',
                                width: '36px',
                              }}>
                              <Menuicon name="interests" color="#38CD84" />
                            </Box>
                            <Box
                              pl={1}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Interests
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={(e) => addNew(e, 'interest')}></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aInterest?.map((interest, index) => {
                            return interest?.title
                              ?.split(',')
                              .map((interst, index) => {
                                if (editsection && section === 'interest') {
                                  return (
                                    <CssChip
                                      key={index}
                                      className={classes.chipmain}
                                      label={interst}
                                      onDelete={handleDelete}
                                      variant="outlined"
                                    />
                                  );
                                } else {
                                  return (
                                    <CssChip
                                      key={index}
                                      className={classes.chipmain}
                                      label={interst}
                                      variant="outlined"
                                    />
                                  );
                                }
                              });
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'industry'}
                      onChange={handleaccordionChange('industry')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="industry" color="#38CD84" />
                            </Box>
                            <Box
                              pl={2.5}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Industry
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aExperience?.map(
                            (industry, index) => {
                              if (editsection && section === 'industry') {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{ marginLeft: '4px', marginTop: '4px' }}
                                    label={industry?.industry}
                                    onDelete={handleDelete}
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{
                                      marginLeft: '4px',
                                      marginRight: '4px',
                                      marginTop: '4px',
                                    }}
                                    label={industry?.industry}
                                    variant="outlined"
                                  />
                                );
                              }
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'function'}
                      onChange={handleaccordionChange('function')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="functions" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Function
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={(e) => addNew(e, 'function')}></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aExperience?.map(
                            (industry, index) => {
                              if (editsection && section === 'function') {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{ marginLeft: '4px', marginTop: '4px' }}
                                    label={industry?.functions}
                                    onDelete={handleDelete}
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{ marginLeft: '4px', marginTop: '4px' }}
                                    label={industry?.functions}
                                    variant="outlined"
                                  />
                                );
                              }
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'experience'}
                      onChange={handleaccordionChange('experience')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="experience" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Experience
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={(e) => addNew(e, 'experience')}></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          <Grid container className={classes.outergridyoe}>
                            <Grid item xs={12} className={classes.leftgrid}>
                              <Typography variant="h2s" component="h2s">
                                {candidateAbout?.total_experience}{' '}
                                {candidateAbout?.total_experience
                                  ? ' Years of Experience'
                                  : ''}
                              </Typography>
                            </Grid>
                          </Grid>

                          {candidateAbout?.aExperience?.map(
                            (industry, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={{ md: 2, xs: 0 }}></Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Company:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.company}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Industry:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-5px',
                                            },
                                          }}>
                                          {industry?.industry}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Function:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.functions}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Location:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.location}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Designation:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.designation}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Start Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-5px',
                                            },
                                          }}>
                                          {moment(industry?.start_date).format(
                                            'LL',
                                          )}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          End Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        {industry?.still_working === 1 ? (
                                          <Typography
                                            variant="h2"
                                            component="h2"
                                            sx={{
                                              '@media (max-width: 500px)': {
                                                marginTop: '-4px',
                                              },
                                            }}>
                                            Currently Working
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="h2"
                                            component="h2"
                                            sx={{
                                              '@media (max-width: 500px)': {
                                                marginTop: '-4px',
                                              },
                                            }}>
                                            {moment(industry?.end_date).format(
                                              'LL',
                                            )}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        md={2}
                                        xs={6}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Description:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={6}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-4px',
                                            },
                                          }}>
                                          {industry?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'education'}
                      onChange={handleaccordionChange('education')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="educationtwo" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Education
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="row">
                          <Box>
                            <Box pb={2}>
                              {candidateAbout?.aEducation?.map(
                                (education, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <Stack
                                        direction="row"
                                        sx={{ paddingBottom: '16px' }}>
                                        <Box pl={{ md: 2, xs: 0 }}></Box>
                                        <Grid
                                          key={index}
                                          container
                                          className={classes.outergrid}>
                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              University Name:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.board}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              Degree:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.degree}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              Passing year:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.passing_year}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              Details:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.details}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Stack>
                                    </Fragment>
                                  );
                                },
                              )}
                            </Box>
                          </Box>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'affiliation'}
                      onChange={handleaccordionChange('affiliation')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="affiliation" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Affiliations
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aAffilation?.map(
                            (affilation, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={2}></Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Name:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {affilation?.entity_name}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Role:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {affilation?.role}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Start Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {moment(
                                            affilation?.start_date,
                                          ).format('LL')}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          End Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {moment(affilation?.end_date).format(
                                            'LL',
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Details:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {affilation?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'award'}
                      onChange={handleaccordionChange('award')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="award" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Awards & Certifications
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aAward?.map((award, index) => {
                            return (
                              <Fragment key={index}>
                                <Stack
                                  direction="row"
                                  sx={{ paddingBottom: '16px' }}>
                                  <Box pl={2}></Box>
                                  <Grid
                                    key={index}
                                    container
                                    className={classes.outergrid}>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Name:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {award?.award_name}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        By:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {award?.awarded_by}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Details:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {award?.details}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Fragment>
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'publications'}
                      onChange={handleaccordionChange('publications')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="publications" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Publications
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aPublication?.map(
                            (publication, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={2}></Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Name:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {publication?.publication_title}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {moment(publication?.date).format(
                                            'LL',
                                          )}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Link:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {publication?.link}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Description:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {publication?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'languages'}
                      onChange={handleaccordionChange('languages')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="languages" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Languages
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aLanguage?.map((language, index) => {
                            return (
                              <Fragment key={index}>
                                <Stack
                                  direction="row"
                                  sx={{ paddingBottom: '16px' }}>
                                  <Box pl={2}></Box>
                                  <Grid
                                    key={index}
                                    container
                                    className={classes.outergrid}>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Language:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {language?.title}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Proficiency:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {language?.proficiency}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}></Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {language?.reading === 'Yes'
                                          ? 'Read,'
                                          : ''}{' '}
                                        {language?.writing === 'Yes'
                                          ? ' Write,'
                                          : ''}{' '}
                                        {language?.speaking === 'Yes'
                                          ? 'Speak'
                                          : ''}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Fragment>
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={2}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'aditional'}
                      onChange={handleaccordionChange('aditional')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <InterestIcon name="others" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Additional Information
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          <Fragment key={1}>
                            <Stack
                              direction="row"
                              sx={{ paddingBottom: '16px' }}>
                              <Box pl={2}></Box>
                              <Grid
                                key={1}
                                container
                                className={classes.outergrid}>
                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Email Id:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {candidateProfile?.jobPreference?.email}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Mobile No:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    +{' '}
                                    {
                                      candidateProfile?.jobPreference
                                        ?.country_code
                                    }{' '}
                                    - {candidateProfile?.jobPreference?.mobile}
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Experience:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {
                                      candidateProfile?.jobPreference
                                        ?.experience
                                    }
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Last Salary Drawn:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {
                                      candidateProfile?.jobPreference
                                        ?.salary_drawn
                                    }
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Preffered Industry:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  {Array.isArray(
                                    candidateProfile?.jobPreference
                                      ?.preferred_industry,
                                  ) &&
                                    candidateProfile?.jobPreference?.preferred_industry?.map(
                                      (industry, index) => {
                                        return (
                                          <Typography
                                            variant="span"
                                            component="span"
                                            key={index}>
                                            {' '}
                                            {industry} {','}
                                          </Typography>
                                        );
                                      },
                                    )}

                                  <Typography
                                    variant="h2"
                                    component="h2"></Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Preffered Functions:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  {Array.isArray(
                                    candidateProfile?.jobPreference
                                      ?.preferred_function,
                                  ) &&
                                    candidateProfile?.jobPreference?.preferred_function?.map(
                                      (industry, index) => {
                                        return (
                                          <Typography
                                            variant="span"
                                            component="span"
                                            key={index}>
                                            {' '}
                                            {industry} {','}
                                          </Typography>
                                        );
                                      },
                                    )}

                                  <Typography
                                    variant="h2"
                                    component="h2"></Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Preferred Location:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {
                                      candidateProfile?.jobPreference
                                        ?.preferred_location
                                    }
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Open For Relocate:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {candidateProfile?.jobPreference
                                      ?.open_to_relocate
                                      ? 'Yes'
                                      : 'No'}
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Resume:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Stack direction="row" spacing={5}>
                                    <Box
                                      onClick={() =>
                                        saveResume(
                                          candidateProfile?.jobPreference
                                            ?.resume,
                                        )
                                      }
                                      sx={{ cursor: 'pointer' }}>
                                      <Stack direction="row" spacing={2}>
                                        <FileDownloadOutlinedIcon />
                                        <Typography variant="h2" component="h2">
                                          Download Resume
                                        </Typography>
                                      </Stack>
                                    </Box>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Fragment>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Message
                    candidateProfile={candidateProfile}
                    jobProfile={location?.state?.job}
                  />
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box p={{ md: 1, xs: 0 }}>
          <Box sx={{ width: '100%' }}>
            <Paper
              sx={{
                backgroundColor: '#232121',
                backgroundImage: 'none',
                overflowX: 'auto',
              }}>
              <Box
                sx={{
                  height:
                    userotherdetails?.designation?.length +
                      userotherdetails?.company_name?.length >
                    30
                      ? '168px'
                      : '148px',
                  backgroundColor: '#2c2c2d',
                  '@media (max-width: 500px)': {
                    height:
                      userotherdetails?.designation?.length +
                        userotherdetails?.company_name?.length >
                      30
                        ? '100px'
                        : '100px',
                  },
                }}></Box>
              <Stack spacing={1} direction="column">
                <Box
                  px={{ md: 2, xs: 0.5 }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                    marginBottom: '-60px',
                    '@media (max-width: 500px)': { marginBottom: '-30px' },
                  }}>
                  <Stack direction="row">
                    <Avatar
                      src={
                        candidateProfile?.image
                          ? candidateProfile?.image
                          : PersonIcon
                      }
                      sx={{
                        width: 148,
                        height: 148,
                        border: '4px solid #38cd84',
                        '@media (max-width: 500px)': {
                          width: 110,
                          height: 110,
                        },
                      }}
                    />
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    transform: ' translateY(-50%)',
                  }}>
                  <Typography
                    variant="h2s"
                    component="h2s"
                    sx={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      '@media (max-width: 500px)': {
                        fontSize: '22px',
                      },
                    }}>
                    {candidateUserDetails?.first_name
                      ? candidateUserDetails?.first_name
                          ?.charAt(0)
                          .toUpperCase() +
                        candidateUserDetails?.first_name?.slice(1)
                      : 'NA'}{' '}
                    {candidateUserDetails?.last_name
                      ? candidateUserDetails?.last_name
                          ?.charAt(0)
                          .toUpperCase() +
                        candidateUserDetails?.last_name?.slice(1)
                      : ''}
                  </Typography>
                </Box>

                <Box
                  pb={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-8px',
                    transform: ' translateY(-20%)',
                  }}>
                  <Stack
                    spacing={{ md: 0.5, xs: 0.2 }}
                    direction="column"
                    alignItems="center">
                    <Typography variant="h2" component="h2">
                      {candidateUserDetails?.designation
                        ? candidateUserDetails?.designation
                            ?.charAt(0)
                            .toUpperCase() +
                          candidateUserDetails?.designation?.slice(1)
                        : ''}
                    </Typography>
                    <Typography variant="h2" component="h2">
                      {candidateUserDetails?.designation
                        ? candidateUserDetails?.company_name
                            ?.charAt(0)
                            .toUpperCase() +
                          candidateUserDetails?.company_name?.slice(1)
                        : ''}
                    </Typography>
                    <Typography variant="h5" component="h5">
                      {candidateUserDetails?.location
                        ? candidateUserDetails?.location
                        : ''}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Box px={4} py={1}>
                <Stack
                  spacing={2}
                  py={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="h2s"
                      component="h2s"
                      sx={{
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                        fontSize: '22px',
                      }}>
                      {candidateUserDetails?.connection_count
                        ? candidateUserDetails?.connection_count
                        : 0}
                    </Typography>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{
                        paddingTop: '7px',
                      }}>
                      Connections
                    </Typography>
                  </Stack>
                </Stack>

                <Box py={1}>
                  <Typography variant="h2s" component="h2s">
                    {candidateUserDetails?.profile_heading
                      ? candidateUserDetails?.profile_heading
                      : ''}
                  </Typography>
                </Box>
                <Box py={1}>
                  <Typography variant="h2" component="h2">
                    {candidateUserDetails?.about
                      ? candidateUserDetails?.about
                      : ''}
                  </Typography>
                </Box>
                <Box py={2}>
                  <Stack direction="row" spacing={5}>
                    <Box onClick={saveFile} sx={{ cursor: 'pointer' }}>
                      <Stack direction="row" spacing={2}>
                        <FileDownloadOutlinedIcon />
                        <Typography variant="h2" component="h2">
                          Download Resume
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
                <Stack
                  py={3}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '42px',
                      maxWidth: 'min-content',
                      minWidth: '42px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon
                      name="phone"
                      width="36"
                      color={
                        candidateUserDetails?.mobile ? '#38CD84' : '#ffffff'
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '36px',
                      maxWidth: 'min-content',
                      minWidth: '36px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon
                      name="email"
                      //color="#38CD84"
                      color={
                        candidateUserDetails?.email ? '#38CD84' : '#ffffff'
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '36px',
                      maxWidth: 'min-content',
                      minWidth: '36px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon name="website" color="#38CD84" />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '24px',
                      maxWidth: 'min-content',
                      minWidth: '24px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon
                      name="blog"
                      // color="#38CD84"
                      color={candidateUserDetails?.blog ? '#38CD84' : '#ffffff'}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '36px',
                      maxWidth: 'min-content',
                      minWidth: '36px',
                      cursor: 'pointer',
                    }}>
                    <Menuicon
                      name="dob"
                      //color="#38CD84"
                      color={candidateUserDetails?.dob ? '#38CD84' : '#ffffff'}
                    />
                  </Box>
                </Stack>
                <Divider />
              </Box>

              <Box py={1}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="fullWidth"
                  aria-label="disabled tabs example"
                  sx={{
                    paddingLeft: '48px',
                    paddingRight: '48px',
                    paddingTop: '16px',
                    '@media (max-width: 500px)': {
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      paddingTop: '14px',
                    },
                  }}>
                  {value === 0 && (
                    <CssTab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <InterestIcon
                            width={20}
                            name="other"
                            color="#38CD84"
                          />
                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            About
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  )}
                  {value != 0 && (
                    <Tab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <InterestIcon
                            width={20}
                            name="other"
                            color="#ffffff"
                          />
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            About
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  )}
                  <Tab
                    label={
                      <Stack
                        direction="row"
                        spacing={{ md: 2, xs: 0.8 }}
                        alignItems="center">
                        {' '}
                        <Icon width={20} name="sponsers" color="#808080" />
                        <Typography
                          variant="h2"
                          component="h2"
                          sx={{
                            color: '#808080',
                            '@media (max-width: 500px)': {
                              fontSize: '14px',
                            },
                          }}>
                          Business
                        </Typography>
                      </Stack>
                    }
                    disabled
                    sx={{
                      textTransform: 'none',
                      fontSize: '20px',
                      fontWeight: '600',
                    }}
                  />
                  {value === 2 && (
                    <CssTab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <Menuicon width={20} name="chatbot" color="#38CD84" />

                          <Typography
                            variant="h2s"
                            component="h2s"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            Message
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}
                    />
                  )}
                  {value != 2 && (
                    <CssTab
                      label={
                        <Stack
                          direction="row"
                          spacing={{ md: 2, xs: 0.8 }}
                          alignItems="center">
                          <Menuicon width={20} name="chatbot" color="#ffffff" />

                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}>
                            Message
                          </Typography>
                        </Stack>
                      }
                      sx={{
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}
                    />
                  )}
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'skills'}
                      onChange={handleaccordionChange('skills')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="skills" color="#38CD84" />
                            </Box>
                            <Box
                              pl={2.5}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Skills
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aSkill?.map((skill, index) => {
                            if (editsection && section === 'skills') {
                              return (
                                <CssChip
                                  key={index}
                                  className={classes.chipmain}
                                  label={skill.title}
                                  variant="outlined"
                                />
                              );
                            } else {
                              return (
                                <CssChip
                                  key={index}
                                  className={classes.chipmain}
                                  label={skill.title}
                                  variant="outlined"
                                />
                              );
                            }
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'interest'}
                      onChange={handleaccordionChange('interest')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '36px',
                                maxWidth: 'min-content',
                                minWidth: '36px',
                                width: '36px',
                              }}>
                              <Menuicon name="interests" color="#38CD84" />
                            </Box>
                            <Box
                              pl={1}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Interests
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={(e) => addNew(e, 'interest')}></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aInterest?.map((interest, index) => {
                            return interest?.title
                              ?.split(',')
                              .map((interst, index) => {
                                if (editsection && section === 'interest') {
                                  return (
                                    <CssChip
                                      key={index}
                                      className={classes.chipmain}
                                      label={interst}
                                      onDelete={handleDelete}
                                      variant="outlined"
                                    />
                                  );
                                } else {
                                  return (
                                    <CssChip
                                      key={index}
                                      className={classes.chipmain}
                                      label={interst}
                                      variant="outlined"
                                    />
                                  );
                                }
                              });
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'industry'}
                      onChange={handleaccordionChange('industry')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="industry" color="#38CD84" />
                            </Box>
                            <Box
                              pl={2.5}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Industry
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aExperience?.map(
                            (industry, index) => {
                              if (editsection && section === 'industry') {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{ marginLeft: '4px', marginTop: '4px' }}
                                    label={industry?.industry}
                                    onDelete={handleDelete}
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{
                                      marginLeft: '4px',
                                      marginRight: '4px',
                                      marginTop: '4px',
                                    }}
                                    label={industry?.industry}
                                    variant="outlined"
                                  />
                                );
                              }
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'function'}
                      onChange={handleaccordionChange('function')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="functions" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Function
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={(e) => addNew(e, 'function')}></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pl={7} pb={2}>
                          {candidateAbout?.aExperience?.map(
                            (industry, index) => {
                              if (editsection && section === 'function') {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{ marginLeft: '4px', marginTop: '4px' }}
                                    label={industry?.functions}
                                    onDelete={handleDelete}
                                    variant="outlined"
                                  />
                                );
                              } else {
                                return (
                                  <CssChip
                                    key={index}
                                    sx={{ marginLeft: '4px', marginTop: '4px' }}
                                    label={industry?.functions}
                                    variant="outlined"
                                  />
                                );
                              }
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'experience'}
                      onChange={handleaccordionChange('experience')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="experience" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Experience
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={(e) => addNew(e, 'experience')}></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          <Grid container className={classes.outergridyoe}>
                            <Grid item xs={12} className={classes.leftgrid}>
                              <Typography variant="h2s" component="h2s">
                                {candidateAbout?.total_experience}{' '}
                                {candidateAbout?.total_experience
                                  ? ' Years of Experience'
                                  : ''}
                              </Typography>
                            </Grid>
                          </Grid>

                          {candidateAbout?.aExperience?.map(
                            (industry, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={{ md: 2, xs: 0 }}></Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        md={2}
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Company:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-4px',
                                            },
                                          }}>
                                          {industry?.company}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={2}
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Industry:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-5px',
                                            },
                                          }}>
                                          {industry?.industry}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={2}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Function:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.functions}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={2}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Location:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.location}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={2}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Designation:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-3px',
                                            },
                                          }}>
                                          {industry?.designation}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={2}
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Start Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-5px',
                                            },
                                          }}>
                                          {moment(industry?.start_date).format(
                                            'LL',
                                          )}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={2}
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          End Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        {industry?.still_working === 1 ? (
                                          <Typography
                                            variant="h2"
                                            component="h2"
                                            sx={{
                                              '@media (max-width: 500px)': {
                                                marginTop: '-4px',
                                              },
                                            }}>
                                            Currently Working
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="h2"
                                            component="h2"
                                            sx={{
                                              '@media (max-width: 500px)': {
                                                marginTop: '-5px',
                                              },
                                            }}>
                                            {moment(industry?.end_date).format(
                                              'LL',
                                            )}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        md={2}
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Description:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography
                                          variant="h2"
                                          component="h2"
                                          sx={{
                                            '@media (max-width: 500px)': {
                                              marginTop: '-4px',
                                            },
                                          }}>
                                          {industry?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'education'}
                      onChange={handleaccordionChange('education')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="educationtwo" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Education
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="row">
                          <Box>
                            <Box pb={2}>
                              {candidateAbout?.aEducation?.map(
                                (education, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <Stack
                                        direction="row"
                                        sx={{ paddingBottom: '16px' }}>
                                        <Box pl={2}></Box>
                                        <Grid
                                          key={index}
                                          container
                                          className={classes.outergrid}>
                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              University Name:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.board}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              Degree:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.degree}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              Passing year:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.passing_year}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={5}
                                            className={classes.leftgrid}>
                                            <Typography
                                              variant="h2s"
                                              component="h2s">
                                              Details:
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={7}
                                            className={classes.rightgrid}>
                                            <Typography
                                              variant="h2"
                                              component="h2">
                                              {education?.details}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Stack>
                                    </Fragment>
                                  );
                                },
                              )}
                            </Box>
                          </Box>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'affiliation'}
                      onChange={handleaccordionChange('affiliation')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="affiliation" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Affiliations
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aAffilation?.map(
                            (affilation, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={2}></Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Name:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {affilation?.entity_name}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Role:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {affilation?.role}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Start Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {moment(
                                            affilation?.start_date,
                                          ).format('LL')}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          End Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {moment(affilation?.end_date).format(
                                            'LL',
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Details:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {affilation?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'award'}
                      onChange={handleaccordionChange('award')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="award" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Awards & Certifications
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aAward?.map((award, index) => {
                            return (
                              <Fragment key={index}>
                                <Stack
                                  direction="row"
                                  sx={{ paddingBottom: '16px' }}>
                                  <Box pl={2}></Box>
                                  <Grid
                                    key={index}
                                    container
                                    className={classes.outergrid}>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Name:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {award?.award_name}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        By:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {award?.awarded_by}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Details:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {award?.details}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Fragment>
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'publications'}
                      onChange={handleaccordionChange('publications')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="publications" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Publications
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aPublication?.map(
                            (publication, index) => {
                              return (
                                <Fragment key={index}>
                                  <Stack
                                    direction="row"
                                    sx={{ paddingBottom: '16px' }}>
                                    <Box pl={2}></Box>
                                    <Grid
                                      key={index}
                                      container
                                      className={classes.outergrid}>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Name:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {publication?.publication_title}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Date:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {moment(publication?.date).format(
                                            'LL',
                                          )}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Link:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {publication?.link}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        className={classes.leftgrid}>
                                        <Typography
                                          variant="h2s"
                                          component="h2s">
                                          Description:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        className={classes.rightgrid}>
                                        <Typography variant="h2" component="h2">
                                          {publication?.details}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Fragment>
                              );
                            },
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'languages'}
                      onChange={handleaccordionChange('languages')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <Menuicon name="languages" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Languages
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          {candidateAbout?.aLanguage?.map((language, index) => {
                            return (
                              <Fragment key={index}>
                                <Stack
                                  direction="row"
                                  sx={{ paddingBottom: '16px' }}>
                                  <Box pl={2}></Box>
                                  <Grid
                                    key={index}
                                    container
                                    className={classes.outergrid}>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Language:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {language?.title}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}>
                                      <Typography variant="h2s" component="h2s">
                                        Proficiency:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {language?.proficiency}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      className={classes.leftgrid}></Grid>
                                    <Grid
                                      item
                                      xs={7}
                                      className={classes.rightgrid}>
                                      <Typography variant="h2" component="h2">
                                        {language?.reading === 'Yes'
                                          ? 'Read,'
                                          : ''}{' '}
                                        {language?.writing === 'Yes'
                                          ? ' Write,'
                                          : ''}{' '}
                                        {language?.speaking === 'Yes'
                                          ? 'Speak'
                                          : ''}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Fragment>
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>

                  <Box px={{ md: 2, xs: 0 }}>
                    <Accordion
                      className={classes.accordionmain}
                      expanded={expanded === 'aditional'}
                      onChange={handleaccordionChange('aditional')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ width: '100%', paddingRight: '10px' }}
                          alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '24px',
                                maxWidth: 'min-content',
                                minWidth: '24px',
                              }}>
                              <InterestIcon name="others" color="#38CD84" />
                            </Box>
                            <Box
                              pl={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <Typography
                                variant="h2s"
                                component="h2s"
                                className={classes.headingtab}>
                                Additional Information
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"></Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box pb={2}>
                          <Fragment key={1}>
                            <Stack
                              direction="row"
                              sx={{ paddingBottom: '16px' }}>
                              <Box pl={2}></Box>
                              <Grid
                                key={1}
                                container
                                className={classes.outergrid}>
                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Email Id:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {candidateProfile?.jobPreference?.email}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Mobile No:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    +{' '}
                                    {
                                      candidateProfile?.jobPreference
                                        ?.country_code
                                    }{' '}
                                    - {candidateProfile?.jobPreference?.mobile}
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Experience:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {
                                      candidateProfile?.jobPreference
                                        ?.experience
                                    }
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Last Salary Drawn:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {
                                      candidateProfile?.jobPreference
                                        ?.salary_drawn
                                    }
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Preffered Industry:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  {Array.isArray(
                                    candidateProfile?.jobPreference
                                      ?.preferred_industry,
                                  ) &&
                                    candidateProfile?.jobPreference?.preferred_industry?.map(
                                      (industry, index) => {
                                        return (
                                          <Typography
                                            variant="span"
                                            component="span"
                                            key={index}>
                                            {' '}
                                            {industry} {','}
                                          </Typography>
                                        );
                                      },
                                    )}

                                  <Typography
                                    variant="h2"
                                    component="h2"></Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Preffered Functions:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  {Array.isArray(
                                    candidateProfile?.jobPreference
                                      ?.preferred_function,
                                  ) &&
                                    candidateProfile?.jobPreference?.preferred_function?.map(
                                      (industry, index) => {
                                        return (
                                          <Typography
                                            variant="span"
                                            component="span"
                                            key={index}>
                                            {' '}
                                            {industry} {','}
                                          </Typography>
                                        );
                                      },
                                    )}

                                  <Typography
                                    variant="h2"
                                    component="h2"></Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Preferred Location:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {
                                      candidateProfile?.jobPreference
                                        ?.preferred_location
                                    }
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Open For Relocate:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Typography variant="h2" component="h2">
                                    {candidateProfile?.jobPreference
                                      ?.open_to_relocate
                                      ? 'Yes'
                                      : 'No'}
                                  </Typography>
                                </Grid>

                                <Grid item xs={5} className={classes.leftgrid}>
                                  <Typography variant="h2s" component="h2s">
                                    Resume:
                                  </Typography>
                                </Grid>
                                <Grid item xs={7} className={classes.rightgrid}>
                                  <Stack direction="row" spacing={5}>
                                    <Box
                                      onClick={() =>
                                        saveResume(
                                          candidateProfile?.jobPreference
                                            ?.resume,
                                        )
                                      }
                                      sx={{ cursor: 'pointer' }}>
                                      <Stack direction="row" spacing={2}>
                                        <FileDownloadOutlinedIcon />
                                        <Typography variant="h2" component="h2">
                                          Download Resume
                                        </Typography>
                                      </Stack>
                                    </Box>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Fragment>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Message
                    candidateProfile={candidateProfile}
                    jobProfile={location?.state?.job}
                  />
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(UserProfile);
