import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import ManIcon from '@mui/icons-material/Man';
import CxoButtonWithIcon from '../CxoButtonWithIcon/CxoButtonWithIcon';
import CxoButton from '../../components/CxoButton/CxoButton';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import mutualfriends from '../../assets/Mutual_connections_Grey.png';
import Chat from '../../assets/home/Chat_Active.png';
import CheckIcon from '@mui/icons-material/Check';
import person from '../../assets/rsz_4personimage.png';
import Menuicon from '../../assets/menuicon';
import EventsIcon from '../../assets/eventsicon';
import ClubsIcon from '../../assets/eventsicon';
import Icon from '../../assets/icon';
import Divider from '@mui/material/Divider';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Backdrop from '@mui/material/Backdrop';
import Share from '../../assets/home/Share_White.png';
import { dateTime } from '../../constants/app';
import * as usernetworkActions from '../../store/usernetwork';
import * as jobsActions from '../../store/jobs';
import * as clubsActions from '../../store/clubs';
import * as activityActions from '../../store/activity';
import { useNavigate, useLocation } from 'react-router-dom';
import deleticon from '../../assets/home/Delete_White.png';
import invite from '../../assets/home/Invited_by_user_White.png';
import * as messageActions from '../../store/message';
import moment from 'moment';

export default function ClubCard(props) {
  console.log('ClubCard', props);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const [clubConfirm, setClubConfirm] = React.useState(false);
  const [type, setType] = React.useState('');

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);
  const navigate = useNavigate();

  useEffect(async () => {
    const data = {
      user_id: props?.userlist?.id,
    };

    // const mutualfriend = await dispatch(usernetworkActions.discoverUserNetwork(data));
    // if(mutualfriend.Status === 1){
    //   setMutualFriend(mutualfriend?.data)
    // }
    // console.log('mutualfriend',user.id,props?.userlist?.id, mutualfriend);
  }, []);
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    // setMobileMoreAnchorEl(club.currentTarget);
    setAnchorEl(!anchorEl);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const getclubDetails = async () => {
    const clubdetails = props?.clubs?.title;
    const clubdetailsToOpen = await dispatch(
      clubsActions.openDetailsClub(props?.clubs),
    );

    if (props?.clubs?.club_name) {
      const clubdetails = props?.clubs?.club_name;
      console.log('clubDetails', props?.club?.type);
      if (props?.clubs?.id) {
        if (props?.type !== 'SavedPassed') {
          navigate('/club/details/' + clubdetails + '_clx' + props?.clubs?.id);
        }
      } else if (props?.clubs?.club_id) {
        if (props?.type !== 'SavedPassed') {
          navigate(
            '/club/details/' + clubdetails + '_clx' + props?.clubs?.club_id,
          );
        }
      }
    } else {
      const clubdetails = props?.clubs?.title;
      console.log('clubDetails', props?.club?.type);

      if (props?.clubs?.id) {
        if (props?.type !== 'SavedPassed') {
          navigate('/club/details/' + clubdetails + '_clx' + props?.clubs?.id);
        }
      } else if (props?.clubs?.club_id) {
        if (props?.type !== 'SavedPassed') {
          navigate(
            '/club/details/' + clubdetails + '_clx' + props?.clubs?.club_id,
          );
        }
      }
    }
  };

  const withdraw = async () => {
    if (user?.isVerified) {
      // members_id missing
      const data = {
        club_id: props?.clubs?.club_id,
        from_user_id: user?.id,
        to_user_id: props?.clubs?.user_id,
        member_id: props?.clubs?.member_id,
      };
      const clubRequestwithdraw = await dispatch(clubsActions.clubLeave(data));

      setClubConfirm(!clubConfirm);

      const datainvited = {
        user_id: user?.id,
      };
      const clubListupcoming = await dispatch(
        clubsActions.myClubList(datainvited),
      );
      const clubLista = await dispatch(clubsActions.savedClub(datainvited));
      const datalist = {
        user_id: user?.id,
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const withdrawRequest = async () => {
    if (user?.isVerified) {
      const data = {
        member_id: props?.clubs?.member_id,
      };
      const clubRequestwithdraw = await dispatch(
        clubsActions.clubUnsendRequest(data),
      );

      const datainvited = {
        user_id: user?.id,
      };
      const clubListupcoming = await dispatch(
        clubsActions.myClubList(datainvited),
      );

      const clubLista = await dispatch(clubsActions.savedClub(datainvited));
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const attendRequest = async () => {
    if (user?.isVerified) {
      const data = {
        club_id: props?.clubs?.id,
        from_user_id: user?.id,
        to_user_id: props?.clubs?.user_id,
        datetime: dateTime(),
      };
      const attendrequest = await dispatch(clubsActions.clubRequest(data));
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );

      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const rejectInvitation = async () => {
    if (user?.isVerified) {
      const data = {
        club_id: props?.clubs?.id,
        member_id: props?.clubs?.member_id,
        from_user_id: props?.clubs?.user_id,
        to_user_id: user?.id,
        datetime: dateTime(),
      };
      const clubRequestwithdraw = await dispatch(
        clubsActions.clubRequestReject(data),
      );
      const datalist = {
        user_id: user?.id,
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
      const datainvited = {
        user_id: user?.id,
      };
      const clubListhi = await dispatch(
        clubsActions.invitedClubList(datainvited),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const acceptInvitation = async () => {
    if (user?.isVerified) {
      const data = {
        club_id: props?.clubs?.club_id,
        member_id: props?.clubs?.member_id,
        from_user_id: props?.clubs?.user_id,
        to_user_id: user?.id,
        datetime: dateTime(),
      };

      // const data = {
      //   club_id : props?.clubs?.id,
      //   from_user_id: user?.id,
      //   to_user_id: props?.clubs?.user_id,
      //   datetime: dateTime()
      // }
      // const attendrequest = await dispatch(clubsActions.clubRequest(data));

      const acceptinvitation = await dispatch(
        clubsActions.clubRequestAccept(data),
      );
      const datalist = {
        user_id: user?.id,
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
      const datainvited = {
        user_id: user?.id,
      };
      const dataclub = {
        club_id: props?.clubs?.club_id,
      };

      const clubmemberslist = await dispatch(
        clubsActions.clubRequestedMembers(dataclub),
      );
      const clubListhi = await dispatch(
        clubsActions.invitedClubList(datainvited),
      );

      const clubListddd = await dispatch(clubsActions.savedClub(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const inviteUser = async () => {
    if (user?.isVerified) {
      const sendInvitees = await dispatch(
        clubsActions.openSendInviteesClub(props?.clubs),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const editClub = async () => {
    if (user?.isVerified) {
      const data = {
        user_id: user?.id,
        club_id: props?.clubs?.id,
      };
      const clubdetails = await dispatch(clubsActions.clubDetails(data));

      const clubEdit = await dispatch(clubsActions.editClubOpen(clubDetails));
      navigate('/club/edit');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const deleteClub = async () => {
    if (user?.isVerified) {
      const data = {
        club_id: props?.clubs?.id,
      };
      const clubs = await dispatch(clubsActions.clubDelete(data));
      setClubConfirm(!clubConfirm);
      const datahost = {
        user_id: user?.id,
      };
      const clubHostedList = await dispatch(clubsActions.hostedClubs(datahost));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const bookmarkClub = async () => {
    if (user?.isVerified) {
      const data = {
        user_id: user.id,
        type: 'club',
        content_id: props?.clubs?.id,
        datetime: dateTime(),
      };
      const bookpost = await dispatch(activityActions.saveBookmark(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const joinClub = async (e) => {
    e.preventDefault();
    if (user?.isVerified) {
      const data = {
        to_user_id: props?.clubs?.user_id,
        from_user_id: user?.id,
        club_id: props?.clubs?.id,
        datetime: dateTime(),
      };
      const clubrequest = await dispatch(clubsActions.clubRequest(data));
      const datalist = {
        user_id: user?.id,
      };
      const clubListclub = await dispatch(
        clubsActions.discoverClubList(datalist),
      );
      const clubList = await dispatch(clubsActions.requestedClubList(datalist));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const shareClub = async (e) => {
    e.stopPropagation();

    if (user?.isVerified) {
      const opensharejob = await dispatch(
        clubsActions.openShareClub(props?.clubs),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const handleClose = (type) => {
    setType(type);
    setClubConfirm(!clubConfirm);
  };

  return (
    <Fragment>
      {clubConfirm && (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            zIndex: '3001',
          }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={clubConfirm}>
            <Box
              sx={{
                width: '80%',
                backgroundColor: '#1c1c1c',
                borderRadius: '10px',
                maxWidth: '600px',
              }}>
              <Stack spacing={0} sx={{ width: '100%', maxWidth: '600px' }}>
                <Stack spacing={0} direction="row">
                  <Box
                    px={3}
                    py={2}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '24px',
                    }}>
                    <Typography variant="h2s" component="h2s">
                      Confirm
                    </Typography>
                  </Box>
                  <Box
                    px={3}
                    py={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                      }}
                      onClick={handleClose}
                    />
                  </Box>
                </Stack>
                <Divider />

                <Stack>
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    {type === 'delete' && (
                      <Typography gutterBottom variant="h5" component="h5">
                        Are you sure you want to delete this Club?
                      </Typography>
                    )}

                    {type === 'leave' && (
                      <Typography gutterBottom variant="h5" component="h5">
                        Are you sure you want to leave this Club?
                      </Typography>
                    )}
                  </Box>
                  <Stack direction="row" justifyContent="center">
                    <Box
                      py={2}
                      px={3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <CxoButton label="No" onClick={handleClose} />
                    </Box>
                    <Box
                      py={2}
                      px={3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      {type === 'delete' && (
                        <CxoButton label="Yes" onClick={deleteClub} />
                      )}
                      {type === 'leave' && (
                        <CxoButton label="Yes" onClick={withdraw} />
                      )}
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Backdrop>
        </Box>
      )}

      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#262525',
          backgroundImage: 'none',
          minHeight: '250px',
          width: '100%',
        }}>
        <Stack direction="column" sx={{ width: '100%' }}>
          <Box sx={{ width: '100%', height: 'auto' }}>
            <Stack direction="row">
              {props?.clubs?.image ? (
                <CardMedia
                  component="img"
                  sx={{
                    // width: 260,
                    // minWidth: '250px',
                    // maxHeight: '250px',
                    width: 360,
                    minWidth: '350px',
                    maxHeight: '200px',
                    borderRadius: '5px',
                    backgroundColor: '#262525',
                    margin: '8px 0px 8px 8px',
                    cursor: 'pointer',
                    '@media (max-width: 500px)': {
                      width: 120,
                      minWidth: '110px',
                      maxHeight: '120px',
                    },
                  }}
                  image={props?.clubs?.image ? props?.clubs?.image : ''}
                  onClick={getclubDetails}
                />
              ) : (
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    width: '216px',
                    maxHeight: { xs: 120, md: 167 },
                    maxWidth: { xs: 120, md: 250 },
                    borderRadius: '16px',
                    padding: '8px',
                    objectFit: 'contain',
                    backgroundColor: '#222121',
                    margin: '8px 0px 8px 8px',
                    cursor: 'pointer',
                    '@media (max-width: 500px)': {
                      width: '120px',
                    },
                  }}
                  onClick={getclubDetails}
                  alt="">
                  <Avatar
                    sx={{
                      width: 72,
                      height: 72,
                      '@media (max-width: 500px)': {
                        width: 50,
                        height: 50,
                      },
                    }}>
                    {props?.candidatelist?.first_name[0] +
                      props?.candidatelist?.last_name[0]}
                  </Avatar>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  padding: '8px 16px',
                  '@media (max-width: 500px)': {
                    padding: '4px 8px',
                    width: '100%',
                  },
                }}>
                <CardHeader
                  sx={{
                    padding: '0px 8px',
                    width: '100%',
                  }}
                  title={
                    <Box pt={1}>
                      <Typography
                        component="h2s"
                        variant="h2s"
                        sx={{
                          fontSize: '18px',
                          '@media (max-width: 500px)': {
                            fontSize: '15px',
                            marginRight: '10px',
                          },
                        }}>
                        {props?.clubs?.club_name
                          ? props?.clubs?.club_name?.substring(0, 50)
                          : ''}{' '}
                        {props?.clubs?.title
                          ? props?.clubs?.title?.substring(0, 50)
                          : ''}{' '}
                      </Typography>
                    </Box>
                  }
                  subheader={
                    <Box>
                      <Box pt={0.5}>
                        <Stack spacing={0.4}></Stack>
                      </Box>
                    </Box>
                  }
                  action={<Box mt={{ xs: 0, md: 1 }} pr={1}></Box>}
                />

                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent="flex-start"
                  sx={{
                    paddingTop: '16px',
                    '@media (max-width: 500px)': { paddingTop: '6px' },
                  }}>
                  <Box
                    pl={1}
                    pt={0}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      justifyContent: 'flex-start',
                      width: { xs: '100%', md: '50%' },
                    }}>
                    <Box
                      pt={0}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItem: 'center',
                        justifyContent: 'flex-start',
                      }}>
                      <Stack direction="row">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                              '@media (max-width: 500px)': {
                                height: '14px',
                                minWidth: '14px',
                              },
                            }}>
                            <Menuicon name="calendar" color="#ffffff" />
                          </Box>
                        </Box>
                        <Box py={{ xs: 0, md: 0.5 }} px={2}>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{
                              fontSize: '15px',
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                              },
                            }}>
                            Since{' '}
                            {props?.clubs?.created_datetime &&
                              moment(props?.clubs?.created_datetime).format(
                                'YYYY',
                              )}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>

                  <Box
                    pl={1}
                    pt={0.5}
                    sx={{
                      display: 'flex',
                      width: { xs: '100%', md: '50%' },
                      justifyContent: 'flex-start',
                    }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                            '@media (max-width: 500px)': {
                              height: '14px',
                              minWidth: '14px',
                              marginTop: '4px',
                            },
                          }}>
                          <Menuicon name="clubs" color="#ffffff" />
                        </Box>
                      </Box>
                      <Box py={0.5} px={2}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontSize: '14px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          {props?.clubs?.type ? props?.clubs?.type : ''}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>

                  {/* <Box
                    pl={1}
                    pt={{ md: 0.5, xs: 0 }}
                    sx={{
                      display: 'flex',
                      width: '20%',
                      justifyContent: 'flex-start',
                    }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                            fontFamily: 'open Sans',
                            fontSize: '15px',
                          }}>
                          {props?.clubs?.member_count}
                        </Box>
                      </Box>
                      <Box py={0.5} px={1.7}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Members
                        </Typography>
                      </Box>
                    </Stack>
                  </Box> */}
                </Stack>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent="flex-start"
                  sx={{
                    paddingTop: '16px',
                    '@media (max-width: 500px)': { paddingTop: '6px' },
                  }}>
                  <Box
                    pl={1}
                    pt={0}
                    sx={{
                      display: 'flex',
                      width: { xs: '100%', md: '50%' },
                      justifyContent: 'flex-start',
                    }}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box mt={-0.5}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                            '@media (max-width: 500px)': {
                              height: '12px',
                              minWidth: '12px',
                              marginLeft: '-3px',
                            },
                          }}>
                          <EventsIcon name="event_fee" color="#ffffff" />
                        </Box>
                      </Box>
                      <Box mt={-0.3} px={{ md: 1.5, xs: 1.4 }}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                              marginTop: '-5px',
                            },
                          }}>
                          {props?.clubs?.fee == 0 ? 'Free' : 'Paid'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box
                    pl={1}
                    pt={{ md: 0, xs: 0 }}
                    sx={{
                      display: 'flex',
                      width: '50%',
                      justifyContent: 'flex-start',
                    }}>
                    <Stack direction="row">
                      <Box
                        mt={-1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItem: 'center',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '16px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                            fontFamily: 'open Sans',
                            fontSize: '16px',
                          }}>
                          {props?.clubs?.member_count}
                        </Box>
                      </Box>
                      <Box py={0} px={1.7} mt={-0.2}>
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          {props?.clubs?.member_count === 1
                            ? 'Member'
                            : 'Members'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
                {/* <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent="flex-start"
                  sx={{ paddingTop: '0px' }}>
                  <Box
                    pl={0}
                    pt={0.5}
                    sx={{
                      display: 'flex',
                      width: { xs: '100%', md: '30%' },

                      justifyContent: 'flex-start',
                    }}>
                    {props?.type === 'discover' && (
                      <Stack spacing={0.4} direction="row">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingLeft: '8px',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <Menuicon name="club_owner" />
                          </Box>
                        </Box>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            fontSize: '15px',
                            color: '#ffffff',
                            fontWeight: 'revert',
                            lineHeight: '16px',
                            paddingLeft: '8px',
                            alignItems: 'center',
                            paddingTop: '6px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                              paddingTop: '4px',
                            },
                          }}>
                          {props?.clubs?.owner_name
                            ? props?.clubs?.owner_name
                            : ''}
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            fontSize: '12px',
                            color: '#ffffff',
                            fontWeight: 'revert',
                          }}></Typography>
                      </Stack>
                    )}

                    {props?.type !== 'discover' && (
                      <Stack direction="row">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItem: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                              '@media (max-width: 500px)': {
                                height: '0px',
                                minWidth: '0px',
                              },
                            }}></Box>
                        </Box>

                        <Box
                          py={0.5}
                          px={1}
                          sx={{
                            display: { xs: 'none', md: 'block' },
                          }}>
                          <Typography variant="h6" component="h6"></Typography>
                        </Box>
                      </Stack>
                    )}
                  </Box>
                </Stack> */}

                {props?.clubs?.club_type === 'External' &&
                props?.type !== 'own' ? (
                  <Stack direction="row" sx={{ paddingTop: '4px' }}>
                    <Box
                      pl={1}
                      pt={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '18px',
                          maxWidth: 'min-content',
                          minWidth: '18px',
                        }}>
                        <ClubsIcon name="clubs_partnered" color="#ffffff" />
                      </Box>
                    </Box>

                    <Box
                      py={0.5}
                      pt={1.8}
                      px={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Typography variant="h6" component="h6">
                        Partnered Club
                      </Typography>
                    </Box>
                  </Stack>
                ) : (
                  ''
                )}
                {props?.clubs?.club_type === 'Indivisual' &&
                props?.type !== 'own' ? (
                  <Stack direction="row" sx={{ paddingTop: '4px' }}>
                    <Box
                      pl={1}
                      pt={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '18px',
                          maxWidth: 'min-content',
                          minWidth: '18px',
                        }}>
                        <ClubsIcon name="clubs_suggestedby" color="#ffffff" />
                      </Box>
                    </Box>

                    <Box
                      py={0.5}
                      pt={1.8}
                      px={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{ fontSize: '15px' }}>
                        {props?.clubs?.first_name} {props?.clubs?.last_name}
                      </Typography>
                    </Box>
                  </Stack>
                ) : (
                  ''
                )}

                <Stack direction="row" sx={{ paddingTop: '8px' }}>
                  <Box
                    pl={1}
                    pt={{ xs: 1, md: 2 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '18px',
                        maxWidth: 'min-content',
                        minWidth: '18px',
                        '@media (max-width: 500px)': {
                          height: '12px',
                          minWidth: '12px',
                          marginLeft: '-3px',
                        },
                      }}>
                      <ClubsIcon name="category" color="#38CD84" />
                    </Box>
                  </Box>

                  <Box
                    py={0.5}
                    pt={{ xs: 1, md: 2.2 }}
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      variant="h6s"
                      component="h6s"
                      sx={{ fontSize: '15px' }}>
                      {props?.clubs?.categories[0]}
                      {props?.clubs?.categories?.length > 1
                        ? ' + ' + (props?.clubs?.categories?.length - 1)
                        : ''}
                    </Typography>
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  sx={{
                    paddingTop: '8px',
                    display: { xs: 'none', md: 'block' },
                  }}>
                  <Box py={0.4} px={1}>
                    <Typography
                      variant="h2"
                      color="text.secondary"
                      sx={{
                        fontSize:'15px',
                        color: 'rgba(255, 255,255,1)',
                        whiteSpace: 'pre-line',
                        lineHeight: '21px',
                        letterSpacing: '1.2',
                      }}>
                      {props?.clubs?.detail?.length > 110
                        ? props?.clubs?.detail?.substring(0, 110) + ' ...'
                        : props?.clubs?.detail}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Divider />
          <Box p={1}>
            {props?.type === 'own' && (
              <Grid container>
                <Fragment>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      <IconButton
                        aria-label="share"
                        onClick={() => handleClose('delete')}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                              alignItems: 'center',
                            }}>
                            <Menuicon
                              width="43"
                              color="#ffffff"
                              name="delete"
                            />
                          </Box>
                        </Box>
                      </IconButton>
                      <Box onClick={() => handleClose('delete')}>
                        <Typography variant="h6" component="h6">
                          Delete
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      <IconButton aria-label="share" onClick={editClub}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <Menuicon
                              width="43"
                              color="#ffffff"
                              name="editapp"
                            />
                          </Box>
                        </Box>
                      </IconButton>
                      <Box onClick={editClub}>
                        <Typography variant="h6" component="h6">
                          Edit
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      <IconButton
                        aria-label="applause"
                        sx={{
                          width: '40px',
                        }}
                        onClick={inviteUser}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '20px',
                            width: 'fit-content',
                          }}>
                          <img
                            alt="Comment"
                            className={{ height: '20px' }}
                            src={invite}
                          />
                        </Box>
                      </IconButton>
                      <Box onClick={inviteUser}>
                        <Typography variant="h6" component="h6">
                          Invite
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Fragment>

                {props?.clubs?.status === 'Cancelled' && (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box py={1}>
                      <Typography
                        component="h2s"
                        variant="h2s"
                        sx={{ fontSize: '18px', fontWeight: '600' }}>
                        This club is cancelled
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}

            {props?.type === 'discover' && (
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}
                      onClick={joinClub}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <Menuicon name="attend" color="#ffffff" />
                      </Box>
                    </IconButton>
                    <Box onClick={joinClub}>
                      <Typography variant="h6" component="h6">
                        Join
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton aria-label="Bookmark" onClick={bookmarkClub}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                          }}>
                          <BookmarkBorderOutlinedIcon />
                        </Box>
                      </Box>
                    </IconButton>
                    <Box onClick={bookmarkClub}>
                      <Typography variant="h6" component="h6">
                        Bookmark
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}
                      onClick={shareClub}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <img
                          alt="Share"
                          className={{ height: '20px' }}
                          src={Share}
                        />
                      </Box>
                    </IconButton>
                    <Box onClick={shareClub}>
                      <Typography variant="h6" component="h6">
                        Share
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            )}

            {props?.type === 'Invited' && (
              <Grid container>
                <Fragment>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      <IconButton
                        aria-label="applause"
                        sx={{
                          width: '40px',
                        }}
                        onClick={acceptInvitation}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '20px',
                            width: 'fit-content',
                          }}>
                          <DoneOutlinedIcon />
                        </Box>
                      </IconButton>
                      <Box onClick={acceptInvitation}>
                        <Typography variant="h6" component="h6">
                          Accept
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      <IconButton aria-label="share" onClick={rejectInvitation}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '18px',
                              maxWidth: 'min-content',
                              minWidth: '18px',
                            }}>
                            <CloseOutlinedIcon />
                          </Box>
                        </Box>
                      </IconButton>
                      <Box onClick={rejectInvitation}>
                        <Typography variant="h6" component="h6">
                          Ignore
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Fragment>

                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}
                      onClick={shareClub}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <img
                          alt="Share"
                          className={{ height: '20px' }}
                          src={Share}
                        />
                      </Box>
                    </IconButton>
                    <Box onClick={shareClub}>
                      {' '}
                      <Typography variant="h6" component="h6">
                        Share
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            )}

            {props?.type === 'Requested' && (
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton aria-label="share" onClick={withdrawRequest}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                          }}>
                          <CloseOutlinedIcon />
                        </Box>
                      </Box>
                    </IconButton>
                    <Box onClick={withdrawRequest}>
                      {' '}
                      <Typography variant="h6" component="h6">
                        Cancel Request
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}
                      onClick={shareClub}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <img
                          alt="Share"
                          className={{ height: '20px' }}
                          src={Share}
                        />
                      </Box>
                    </IconButton>
                    <Box onClick={shareClub}>
                      <Typography variant="h6" component="h6">
                        Share
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            )}

            {props?.type === 'myclubs' && (
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton
                      aria-label="share"
                      onClick={() => handleClose('leave')}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '18px',
                            maxWidth: 'min-content',
                            minWidth: '18px',
                          }}>
                          <CloseOutlinedIcon />
                        </Box>
                      </Box>
                    </IconButton>
                    <Typography variant="h6" component="h6">
                      Leave
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton
                      aria-label="applause"
                      sx={{
                        width: '40px',
                      }}
                      onClick={shareClub}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '20px',
                          width: 'fit-content',
                        }}>
                        <img
                          alt="Share"
                          className={{ height: '20px' }}
                          src={Share}
                        />
                      </Box>
                    </IconButton>
                    <Box onClick={shareClub}>
                      <Typography variant="h6" component="h6">
                        Share
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Box>
        </Stack>
      </Card>
    </Fragment>
  );
}
