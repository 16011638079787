import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.palette.text.black,
  borderRadius: '50px',
  fontFamily: 'Raleway',
  fontSize: '9px',
  lineHeight: '12px',
  letterSpacing: '1px',
  fontWeight: '600',
  textTransform: 'none',
  backgroundColor: theme.palette.palette.text.secondary,
  '&:hover': {
    backgroundColor: theme.palette.palette.text.secondary,
  },
}));

export default function CxoButtonWithIcon(props) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton
        variant="contained"
        onClick={props?.onClick}
        startIcon={props.icon}
        type={props?.type ? props?.type : ''}
        sx={{
          height: props?.height ? props?.height : '26px',
          width: props?.width ? props?.width : '110px',
          minWidth: props?.minWidth ? props?.minWidth : '110px',
          minHeight: props?.minHeight ? props?.minHeight : '18px',
          fontSize: props?.fontSize ? props?.fontSize : '13px',
          lineHeight: props?.lineHeight ? props?.lineHeight : '16px',
          padding: '4px',
          '@media (max-width: 500px)': {
            height: props?.height ? props?.height : '24px',
            width: props?.width ? props?.width : '90px',
            minWidth: props?.minWidth ? props?.minWidth : '80px',
            minHeight: props?.minHeight ? props?.minHeight : '18px',
            fontSize: props?.fontSize ? props?.fontSize : '13px',
            lineHeight: props?.lineHeight ? props?.lineHeight : '16px',
          },
        }}>
        {props?.label ? props.label : 'Submit'}
      </ColorButton>
    </Stack>
  );
}
