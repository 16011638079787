import { combineReducers } from 'redux';
import { authreducer as auth } from './auth';
import { activityreducer as activity } from './activity';
import { onboardreducer as onboard } from './onboard';
import { postreducer as post } from './post';
import { usernetworkreducer as usernetwork } from './usernetwork';
import { articlereducer as article } from './article';
import { jobsreducer as jobs } from './jobs';
import { messagereducer as message } from './message';
import { searchreducer as search } from './search';
import { userreducer as user } from './user';
import { eventreducer as events } from './events';
import { activityreducer as useractivity } from './activity';
import { rewardsreducer as rewards } from './rewards';
import { clubsreducer as clubs } from './clubs';
import { companyreducer as company } from './company';
import { surveyreducer as survey } from './survey';
// import storage from 'redux-persist/lib/storage';

const reducers = {
  auth,
  article,
  activity,
  onboard,
  post,
  usernetwork,
  message,
  search,
  useractivity,
  user,
  jobs,
  events,
  rewards,
  clubs,
  company,
  survey
};
const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_FULFILLED') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
