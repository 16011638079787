import React, { Component } from "react";
// import ReactPlayer from "react-player";
import { Row, Col, Container } from "react-bootstrap";

class WhyLinkCXO extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {
    return (
      <Container fluid className="nl-why-linkcxo" id="whyLinkcxo">
      <Row>
        <Col>
          <h1 className="color-green" style={{fontFamily:'Raleway'}}>Why LinkCxO?</h1>

          <iframe
            className="nl-why-linkcxo-video"
            src="https://www.youtube.com/embed/SDyaSzyJOrY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="allowfullscreen"
            scrolling="no"
            seamless="seamless"
          ></iframe>

        </Col>
      </Row>
    </Container>
    );
  }
}

export default WhyLinkCXO;
