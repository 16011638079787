import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@mui/styles';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery'; // Import useMediaQuery
import Appr1 from '../../../resources/partner-image/Apparel1.webp';
import Appr2 from '../../../resources/partner-image/Apparel2.jpeg';

const CarouselContainer = styled.div`
  .carousel .control-dots {
    display: none; /* Hide the dots */
  }
  .carousel .thumbs-wrapper {
    display: none; /* Hide the numbering */
  }
  .carousel .carousel-status {
    font-size: 0px;
  }
`;
const LeftDiv = styled.div`
  // background-color: lightblue;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#fff',
    height: 'auto',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  heading: {
    fontSize: '22px',
    color: '#38cd84',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  heading1: {
    fontSize: '30px',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  list: {
    fontSize: '16px',
    color: '#fff',
    paddingLeft: '20px',
    listStyleType: 'disc',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

const Apparel = () => {
  const classes = useStyles();
  const images = [Appr1, Appr2];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container spacing={2} sx={{ px: { xs: 3, sm: 10 } }}>
        {isMobile ? (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <LeftDiv>
                <CarouselContainer>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    stopOnHover={true}>
                    {images.map((imageUrl, index) => (
                      <div key={index}>
                        <Box
                          component="img"
                          src={imageUrl}
                          alt={`Image ${index + 1}`}
                          sx={{
                            height: { xs: '200px', sm: '350px' },
                            width: 'auto',
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </CarouselContainer>
              </LeftDiv>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <div className={classes.container}>
                <h2 className={classes.heading1}> Apparel</h2>
                <h2 className={classes.heading}>
                  LinkCxO in collaboration with a vast network of top fashion,
                  accessories, and apparel brands offer:
                </h2>
                <ul className={classes.list}>
                  <li>
                    {' '}
                    <span style={{ fontFamily: 'open Sans' }}>5</span>% Discount
                    on a curated list of Luxury Apparel
                  </li>
                  <li> Special privileges on Brands</li>
                  <li>Discover diverse high-end fashion brands</li>
                  <li>Make luxury fashion more enjoyable</li>
                </ul>{' '}
                <span
                  style={{
                    fontSize: '20px',
                    color: '#38cd84',
                    fontWeight: 600,
                  }}>
                  Partners:
                </span>
                <span style={{ fontSize: '18px', color: '#fff' }}>
                  &nbsp;&nbsp;Van Huesen, Allen Solly, Armani Exchange and many
                  more
                </span>{' '}
                <div style={{ display: 'flex' }}>
                  {' '}
                  <p style={{ fontSize: '30px', color: '#fff' }}>*</p>
                  <p
                    style={{
                      fontSize: '12px',
                      lineHeight: '30px',
                      color: '#fff',
                    }}>
                    Through our Channel Partner GyFTR, SriMart
                  </p>
                </div>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              position="relative"
              justifyContent="center"
              alignItems="center"
              display="flex">
              <div className={classes.container}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '20px',
                  }}>
                  {' '}
                  <a
                    href="/login"
                    style={{ textDecoration: 'none', color: '#38cd84' }}>
                    <p
                      style={{
                        color: '#38cd84',
                        fontSize: '20px',
                        fontWeight: 500,
                      }}>
                      See More
                    </p>
                  </a>
                </div>
                <h2 className={classes.heading1}> Apparel</h2>
                <h2 className={classes.heading}>
                  LinkCxO in collaboration with a vast network of top fashion,
                  accessories, and apparel brands offer:
                </h2>
                <ul className={classes.list}>
                  <li>5% Discount on a curated list of Luxury Apparel</li>
                  {/* <li>Unique discounts</li> */}
                  <li> Special privileges on Brands</li>
                  <li>Discover diverse high-end fashion brands</li>
                  <li>Make luxury fashion more enjoyable</li>
                </ul>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#38cd84',
                    fontWeight: 600,
                  }}>
                  Partners:
                </span>
                <span style={{ fontSize: '18px', color: '#fff' }}>
                  &nbsp;&nbsp;Van Huesen, Allen Solly, Armani Exchange and many
                  more
                </span>{' '}
                <div style={{ display: 'flex' }}>
                  {' '}
                  <p style={{ fontSize: '30px', color: '#fff' }}>*</p>
                  <p
                    style={{
                      fontSize: '12px',
                      lineHeight: '30px',
                      color: '#fff',
                    }}>
                    Through our Channel Partner GyFTR, SriMart
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <LeftDiv>
                <CarouselContainer>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    stopOnHover={true}>
                    {images.map((imageUrl, index) => (
                      <div key={index}>
                        <Box
                          component="img"
                          src={imageUrl}
                          alt={`Image ${index + 1}`}
                          sx={{ height: { xs: '200px', sm: '350px' } }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </CarouselContainer>
              </LeftDiv>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Apparel;
