import React, { Component, Fragment, useEffect } from 'react';
import '../../resources/css/app.css';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { getJobList, getViewJobData } from '../../actions/jobs-list-action';
import Designation from '../../resources/new-img/Designation_Passive.png';
import gPlay from '../../resources/new-img/GooglePlaystore.png';
import BackArrow from '../../resources/new-img/Back_Button_Passive.png';
import Education from '../../resources/img/Education.png';
import Entity from '../../resources/img/Entity.png';
import Experience from '../../resources/img/ExperienceA.png';
import Function from '../../resources/new-img/FunctionA.png';
import Industry from '../../resources/new-img/Industry.png';
import Location from '../../resources/new-img/Location.png';
import Calendar from '../../resources/new-img/GCalendar.png';
import Job from '../../resources/img/JobDetails.png';
import JobType from '../../resources/img/JobType.png';
import Skills from '../../resources/img/Skills.png';
import HeaderCopy2 from '../../components/HomeHeader/headercopy2';
import { Paper, Box, Typography, TextField, Stack, Grid } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import Menuicon from '../../assets/menuicon';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import * as usernetworkActions from '../../store/usernetwork';
import * as onboardActions from '../../store/onboard';
import * as jobsActions from '../../store/jobs';
import * as messageActions from '../../store/message';
import * as activityActions from '../../store/activity';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles((theme) => ({
  rowroot: {
    paddingTop: '16px',
    paddingLeft: '16px',
  },
}));

const JobDetailsnew = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const location = useLocation();
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const tempUserId = useSelector((state) => state?.activity?.tempUserId);

  React.useEffect(() => {
    async function postUserTrack() {
      if (location?.search?.includes('campaign')) {
        debugger;
        if (tempUserId == 0 || tempUserId == undefined) {
          const data = {
            tempUserId: uuidv4(),
          };
          const assign = await dispatch(activityActions.assignUserId(data));
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname?.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: data?.tempUserIdo,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        } else {
          let pagename = 'home';
          let campaignid = location?.search?.split('campaign=');
          if (location.pathname != '/') {
            pagename = location.pathname.replace('/', '');
          }
          const data2 = {
            campaign_id: campaignid[1],
            platform: 'web',
            page_name: pagename,
            temp_user_id: tempUserId,
          };
          const reponse = await dispatch(activityActions.userTrack(data2));
        }

        // navigate('/');
      }
    }
    postUserTrack();
  }, [location]);

  useEffect(async () => {
    const id = params?.job?.split('-');
    if (id?.length > 0) {
      window.scrollTo({
        top: 10,
        left: 10,
        behavior: 'smooth',
      });
      const data = {
        job_id: id[id?.length - 1].substring(2),
      };
      const jobdetails = await dispatch(jobsActions.getJobDetails(data));
    }
  }, []);

  const login_redirect_job = () => {
    const jobcode = jobDetails?.job_id + '-' + 'lx' + jobDetails?.id;
    return (
      '/login?sign_up_through_page=job_apply&redirect=/job/details/' + jobcode
    );
  };

  const maxexperience = () => {
    if (jobDetails?.max_experience === 'open') {
      return 'Open';
    } else if (jobDetails?.max_experience === 'not_disclosed') {
      return 'Not disclosed';
    } else {
      return jobDetails?.max_experience + ' years';
    }
  };

  return (
    <Container fluid id="no-padding">
      <Row>
        <Col md={12} xs={12} className="main-header-style-section">
          {' '}
          <Navbar />
        </Col>
      </Row>
      <Row
        className="jobs-apply-header-section"
        style={{
          zIndex: '9',
        }}>
        {' '}
        <Col>
          <Grid container xs={12} md={12}>
            <Grid Item xs={12} md={0.5}>
              <Box py={{ md: 3, xs: 1 }} px={0.5}>
                <WestIcon
                  sx={{ color: '#ffffff', fontSize: '32px', cursor: 'pointer' }}
                  onClick={props.handleOnCLose.bind()}
                />
              </Box>
            </Grid>
            <Grid Item xs={1.5} md={0.5}>
              {' '}
              <Box
                pt={{ md: 3.5, xs: 1 }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '25px',
                    maxWidth: 'min-content',
                    minWidth: '25px',
                    '@media (max-width: 500px)': {
                      height: '20px',
                      minWidth: '20px',
                    },
                  }}>
                  <Menuicon name="designation" color="#38CD84" />
                </Box>
              </Box>
            </Grid>
            <Grid Item xs={8} md={9}>
              <h4 className="job-detail-title">
                {' '}
                {jobDetails?.title?.length >= 60
                  ? jobDetails?.title?.substring(0, 60) + '...'
                  : jobDetails?.title}{' '}
              </h4>
            </Grid>
            <Grid Item xs={2.5} md={2}>
              <Box>
                {' '}
                <a href={login_redirect_job()}>
                  {' '}
                  <button class="job-apply-details-btn">Apply</button>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Col>
      </Row>

      <Row md={12} xs={12} className="job-details-container">
        <Col md={12} xs={12} className="job-details-row">
          <Row md={12} xs={12}>
            <Col md={4} className="job-details-col">
              <span className="company-name-style-section">
                <img alt="" src={Entity} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Company Name</span>
              <br />
              <div className="confidential-style-section">
                {jobDetails?.is_company_confidential
                  ? 'Confidential'
                  : jobDetails?.company_name}
              </div>
            </Col>

            <Col md={4} className="job-details-col">
              <span className="company-name-style-section">
                <img alt="" src={Location} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Location</span>
              <br />
              <div className="confidential-style-section">
                {' '}
                {jobDetails?.location}
              </div>
            </Col>

            <Col md={4} className="job-details-col">
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={JobType} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Job Type</span>
              <br />
              <div className="confidential-style-section">
                {' '}
                &nbsp;{jobDetails?.job_type}
              </div>
            </Col>
          </Row>
          <br />
          <Row className="job-detail-row">
            <Col md={4} className="job-details-col">
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={Experience} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Experience</span>
              <br />
              <div className="confidential-style-section">
                {' '}
                <Stack direction="column" spacing={1}>
                  <Stack direction="row">
                    <Typography
                      variant="h5"
                      component="h5"
                      // sx={{
                      //   "@media (max-width: 500px)": {
                      //     fontSize: "12px",
                      //   },
                      // }}
                    >
                      Min-
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        fontFamily: 'Open Sans',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      {jobDetails?.min_experience
                        ? jobDetails?.min_experience
                        : ''}
                      {'  '} years
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        whiteSpace: 'pre-line',
                        paddingLeft: '8px',
                        '@media (max-width: 500px)': {
                          paddingLeft: '5px',
                          fontSize: '12px',
                        },
                      }}>
                      {' \0\0\0'}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      // sx={{
                      //   "@media (max-width: 500px)": {
                      //     fontSize: "12px",
                      //   },
                      // }}
                    >
                      {} Max-
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        fontFamily: 'Open Sans',
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}>
                      {maxexperience()}
                    </Typography>
                  </Stack>
                </Stack>
              </div>
            </Col>

            <Col md={4} className="job-details-col">
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={Calendar} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Posted on</span>
              <br />
              <div className="confidential-style-section">
                {jobDetails?.posted_on}
              </div>
            </Col>

            <Col md={4} className="job-details-col">
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={Function} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Function</span>
              <br />
              <div className="confidential-style-section">
                {' '}
                {jobDetails?.functions &&
                  jobDetails?.functions?.map((functions, index) => {
                    if (index > 0) {
                      return <span>{',  ' + functions?.title}</span>;
                    } else {
                      return <span>{functions?.title}</span>;
                    }
                  })}
              </div>
            </Col>
          </Row>
          <br />
          <Row className="job-detail-row">
            <Col md={12} xs={12}>
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={Industry} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Industry</span>
              <br />
              <div className="confidential-style-section">
                {' '}
                {jobDetails?.industries &&
                  jobDetails?.industries?.map((industry, index) => {
                    if (index > 0) {
                      return <span>{',  ' + industry?.title}</span>;
                    } else {
                      return <span>{industry?.title}</span>;
                    }
                  })}
                &nbsp;&nbsp;
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} xs={12}>
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={Education} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Education</span>
              <br />
              <div className="confidential-style-section">
                {' '}
                {jobDetails?.education}
              </div>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={12} xs={12}>
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={Skills} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">Skills</span>
              <br />
              <div className="confidential-style-section">
                {' '}
                {jobDetails?.skills}
              </div>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={12} xs={12}>
              <span className="company-name-style-section">
                {' '}
                <img alt="" src={Job} width="20px" height="20px" />
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="company-name-style-section">
                Job Description:
              </span>
              <br />
              <div className="confidential-style-section">
                {' '}
                {jobDetails?.job_description}
                {/* // ? this.props.item.description // : "NA"}
              {/* {this.props.item.description} */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}></Col>
          </Row>
          <Row>
            <Col md={12} xs={12}></Col>
          </Row>
          <Row>
            <Col md={12} xs={12}></Col>
          </Row>
          <Row></Row>
        </Col>
      </Row>
    </Container>
  );
};

export default JobDetailsnew;
