import React, { Component, Fragment, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useSelector, useDispatch } from 'react-redux';
import * as articleActions from '../../store/article';
import * as postActions from '../../store/post';
import * as clubsActions from '../../store/clubs';
import 'react-quill/dist/quill.snow.css';
import './editorStyle.css';
import useStyles from './editorstyle';
import * as messageActions from '../../store/message';
import CxoButton from '../CxoButton/CxoButton';
import Icon from '../../assets/icon';
import ReactQuill from 'react-quill';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import { dateTime } from '../../constants/app';
const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

const EditorClub = (props) => {
  const dispatch = useDispatch();
  const [quilltext, setQuilltext] = useState('');
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [showPreview, setPreview] = useState(false);
  const [documentTitle, setDocumentTitle] = useState('');
  const [doctitleShow, setDoctitleShow] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [startItem, setStartitem] = useState(0);
  const [itemCount, setItemcount] = useState(10);
  const editPost = useSelector((state) => state?.post?.PostEdit);
  const user = useSelector((state) => state?.user?.UserDetails);
  const clubDetails = useSelector((state) => state?.clubs?.clubDetails);

  useEffect(async () => {
    // const myNetwork = await dispatch(articleActions.openArticleEdit());
  }, []);

  const userPostDetails = async () => {
    if (clubDetails?.club_id) {
      const data = {
        user_id: user?.id,
        club_id: clubDetails?.club_id,
      };
      const clubdetails = await dispatch(clubsActions.clubPostList(data));
    } else {
      const data = {
        user_id: user?.id,
        club_id: clubDetails?.id,
      };
      const clubdetails = await dispatch(clubsActions.clubPostList(data));
    }
  };

  const addPost = async () => {
    var m = new Date();
    var dateString =
      m.getUTCFullYear() +
      '-' +
      (m.getUTCMonth() + 1) +
      '-' +
      m.getUTCDate() +
      ' ' +
      m.getUTCHours() +
      ':' +
      m.getUTCMinutes() +
      ':' +
      m.getUTCSeconds();
    const formData = new FormData();
    formData.append('id', 0);
    formData.append('content', quilltext);
    formData.append('file', file);
    formData.append('user_id', user.id);
    formData.append('datetime', dateTime());
    formData.append('document_type', type);
    formData.append('user_type', 'club');
    formData.append('user_table_pk', clubDetails?.id);
    if (doctitleShow) {
      formData.append('document_name', documentTitle);
    } else {
      formData.append('document_name', '');
    }
    const headers = { 'Content-Type': 'multipart/form-data' };
    // const data = {
    //   id: 0,
    //   content: quilltext,
    //   image: "",
    //   user_id: user.id,
    //   datetime: dateString,
    //   document_type: "post",
    //   user_type: "user",
    //   user_table_pk: user.id,
    //   document: "",
    // };
    if (quilltext.length > 0 || file) {
      if (user?.isVerified) {
        const addpost = await dispatch(
          postActions.sendAddNewPostData(formData, async (error, response) => {
            if (response) {
              const snackbar = await dispatch(postActions.closePostEdit());
              return userPostDetails();
            }
          }),
        );
      } else {
        await dispatch(messageActions.openUnderVerification());
      }
    }
  };

  const handleChange = (event) => {
    if (quilltext.length < 2000) {
      setQuilltext(event.target.value.substring(0, 2000));
    } else if (event.target.value.length < quilltext.length) {
      setQuilltext(event.target.value);
    }
  };

  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snackbar = await dispatch(postActions.closePostEdit());
  };
  const getPhoto = async (event) => {
    setDoctitleShow(false);
    setPreview(true);
    setFile(event.target.files[0]);
    setType('image');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };
  const getDocument = async (event) => {
    setDoctitleShow(true);
    setFile(event.target.files[0]);
    setType('document');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };
  const removePreview = () => {
    setPreview(false);
    setFile('');
    setType('');
    setName('');
    setImgData(null);
  };
  const handleChangeTitle = (event) => {
    setDocumentTitle(event.target.value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: '3001',
      }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={editPost}>
        <Box
          sx={{
            minHeight: '400px',
            backgroundColor: '#333',
            borderRadius: '10px',
            minWidth: { xs: '90%', md: '1080px' },
          }}>
          <Stack
            spacing={0}
            direction="column"
            justifyContent="space-between"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Stack spacing={0} direction="row">
              <Box
                px={3}
                py={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h2s" component="h2s">
                  Share Your Experience
                </Typography>
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CloseIcon
                  sx={{ color: '#ffffff', cursor: 'pointer', fontSize: '24' }}
                  onClick={handleClose}
                />
              </Box>
            </Stack>
            <Divider />
            <Box p={2} sx={{ zIndex: '1200' }} className="editor">
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={12}
                maxRows={16}
                value={quilltext}
                onChange={handleChange}
                placeholder="Start Typing Here"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            {doctitleShow && (
              <>
                <Divider />
                <Box p={2} sx={{ zIndex: '1200' }} className="editor">
                  <TextField
                    variant="standard"
                    fullWidth
                    rows={1}
                    maxRows={2}
                    value={documentTitle}
                    onChange={handleChangeTitle}
                    placeholder="Document Title"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </>
            )}
            {showPreview && type === 'image' && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '12',
                        position: 'absolute',
                        right: '0px',
                      }}
                      onClick={removePreview}
                    />
                    <Box p={1}>
                      <img src={imgData} alt="" height="100px" />
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {showPreview && type === 'document' && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <CloseOutlinedIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '12',
                        position: 'absolute',
                        right: '0px',
                      }}
                      onClick={removePreview}
                    />
                    <Box p={1}></Box>
                  </Box>
                </Box>
              </>
            )}
            <Divider />
            <Box
              pl={3}
              sx={{
                display: 'flex',
                width: '100%',
                height: '48px',
                alignItems: 'center',
              }}>
              <Stack spacing={3} direction="row">
                <Stack spacing={2} direction="row">
                  <Box>
                    <label htmlFor="icon-button-image">
                      <Box
                        p={1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}>
                        <InsertPhotoOutlinedIcon />
                      </Box>
                    </label>
                    <input
                      accept="image/*"
                      id="icon-button-image"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={getPhoto}
                      name="image"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h2" component="h2">
                      Images
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={2} direction="row">
                  <Box>
                    <label htmlFor="icon-button-doc">
                      <Box
                        p={1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}>
                        <AttachFileOutlinedIcon />
                      </Box>
                    </label>
                    <input
                      id="icon-button-doc"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={getDocument}
                      name="docum"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography variant="h2" component="h2">
                      Documents
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Divider />

            <Grid container alignItems="flex-end" sx={{ alignItems: 'center' }}>
              <Grid
                item
                xs
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '16px',
                }}>
                <Box px={3}>
                  <Typography gutterBottom variant="h6" component="h6">
                    {quilltext.length} / 2000
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <CxoButton label="Post" onClick={addPost} />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default React.memo(EditorClub);

//
// <ReactQuill
//   value={quilltext}
//   modules={modules}
//   formats={formats}
//   onChange={handleChange}
// />

//
// <input
//   id="icon-button-doc"
//   type="file"
//   style={{ display: "none" }}
//   onChange={getDocument}
//   name="docum"
// />

//
// <Stack spacing={2} direction="row">
//   <Box>
//     <label htmlFor="icon-button-file">
//       <Box
//         p={1}
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           cursor: "pointer",
//         }}
//       >
//         <VideoCameraBackOutlinedIcon />
//       </Box>
//     </label>
//   </Box>
//
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     }}
//   >
//     <Typography variant="h2" component="h2">
//       Videos
//     </Typography>
//   </Box>
// </Stack>
//
// <Stack spacing={2} direction="row">
//   <Box>
//     <label htmlFor="icon-button-doc">
//       <Box
//         p={1}
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           cursor: "pointer",
//         }}
//       >
//         <AttachFileOutlinedIcon />
//       </Box>
//     </label>
//   </Box>
//
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     }}
//   >
//     <Typography variant="h2" component="h2">
//       Documents
//     </Typography>
//   </Box>
// </Stack>
