import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoButton from '../../components/CxoButton/CxoButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useParams } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import * as postActions from '../../store/post';
import { dateTime } from '../../constants/app';
import EventsIcon from '../../assets/eventsicon';
import VoucherDetailsSkeleton from '../../components/Skeleton/VoucherDetailsSkeleton';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useNavigate, useLocation } from 'react-router-dom';
import * as messageActions from '../../store/message';
import * as clubsActions from '../../store/clubs';

const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const EventsDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.UserDetails);
  let params = useParams();
  const [value, setValue] = React.useState(0);
  const [clubdtl, setClubdtl] = React.useState('');
  const upcomingEventList = useSelector((state) => state?.events?.eventDetails);
  const eventDetails = useSelector((state) => state?.events?.eventDetails);
  const eventsDetailsloading = useSelector(
    (state) => state?.events?.eventsDetailsloading,
  );
  const eventStatususer = useSelector(
    (state) => state?.events?.eventStatusUser,
  );
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => {
    // setMobileMoreAnchorEl(event.currentTarget);
    // if ( user?.id === props?.post?.user_id){
    //   setAnchorEl(!anchorEl);
    // }
    setAnchorEl(!anchorEl);
  };
  useEffect(() => {
    async function fetchClubData() {
      const id = params?.eventID?.split('-');
      if (id?.length > 0) {
        const data = {
          id: id[id?.length - 1].substring(2),
        };
        const eventdetails = await dispatch(eventsActions.eventDetails(data));

        if (eventdetails?.status === 1) {
          if (eventdetails?.data?.user_type === 'club') {
            const dataclub = {
              user_id: user?.id,
              club_id: eventdetails?.data?.user_table_pk,
            };

            const clubdet = await dispatch(clubsActions.clubDetails(dataclub));
            if (clubdet?.status === 1) {
              setClubdtl(clubdet?.data);
            }
          }
        }
      }
    }
    fetchClubData();
  }, []);

  useEffect(async () => {
    const id = params?.eventID?.split('-');
    console.log('EventsDetails', id);
    const data = {
      user_id: user?.id,
      event_id: id[id?.length - 1].substring(2),
    };
    const hostedEventUser = await dispatch(
      eventsActions.eventStatusForUser(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const hostedEvent = await dispatch(
      eventsActions.hostedEventUpcomming(data),
    );
  }, []);

  useEffect(() => {
    async function fetchClubData() {}
    fetchClubData();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const eventstartDate = () => {
    let month = moment(eventDetails?.start_date).format('MMM');
    let date = moment(eventDetails?.start_date).format('DD');
    let year = moment(eventDetails?.start_date).format('YYYY');

    return date + ' ' + month + ' ' + year;
  };

  const eventStartTime = () => {
    // let time = moment(eventDetails?.start_time).format("LT");
    let time = moment(eventDetails?.start_time, 'hh:mm:ss A').format('hh:mm A');
    return '( ' + time + ' )';
  };

  const eventEndDate = () => {
    let month = moment(eventDetails?.end_date).format('MMM');
    let date = moment(eventDetails?.end_date).format('DD');
    let year = moment(eventDetails?.end_date).format('YYYY');

    return date + ' ' + month + ' ' + year;
  };

  const eventEndTime = () => {
    // let time = moment(eventDetails?.start_time).format("LT");
    let time = moment(eventDetails?.end_time, 'hh:mm:ss A').format('hh:mm A');
    return '( ' + time + ' )';
  };

  const reportEvent = async () => {
    // const data = {
    //   user_id : user.id,
    //   type : props?.post.type,
    //   content_id: props?.post.id,
    //   datetime: dateTime(),
    // }

    if (user?.isVerified) {
      const reportpost = await dispatch(
        postActions.openReportPost({ post: eventDetails, type: 'report' }),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const withdraw = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: eventDetails?.id,
        user_id: user?.id,
        status: 'accept',
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestWithdraw(data),
      );
      const datainvited = {
        user_id: user?.id,
      };
      const eventListupcoming = await dispatch(
        eventsActions.savedEventUpcoming(datainvited),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
      const dataEvent = {
        user_id: user?.id,
        event_id: eventDetails?.id,
      };
      const hostedEventUser = await dispatch(
        eventsActions.eventStatusForUser(dataEvent),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const attend = async () => {
    if (user?.isVerified) {
      console.log('external_event_link', eventDetails?.external_event_link);
      if (eventDetails?.event_type === 'External') {
        window.open(eventDetails?.external_event_link, '_blank');
      } else {
        const data = {
          event_id: eventDetails?.id,
          from_user_id: user?.id,
          to_user_id: eventDetails?.user_id,
          datetime: dateTime(),
        };
        const attendrequest = await dispatch(eventsActions.eventRequest(data));
        const datalist = {
          user_id: user?.id,
          categoryId: [],
        };
        const eventListevent = await dispatch(
          eventsActions.discoverEventList(datalist),
        );

        const eventList = await dispatch(
          eventsActions.requestedEventList(datalist),
        );
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const requestToAttend = async () => {
    if (user?.isVerified) {
      if (eventDetails?.event_type === 'External') {
        window.open(eventDetails?.external_event_link, '_blank');
      } else {
        const data = {
          event_id: eventDetails?.id,
          from_user_id: eventDetails?.user_id,
          to_user_id: user?.id,
          datetime: dateTime(),
        };
        const acceptinvitation = await dispatch(
          eventsActions.eventRequestAccept(data),
        );
        const datalist = {
          user_id: user?.id,
          categoryId: [],
        };
        const eventListevent = await dispatch(
          eventsActions.discoverEventList(datalist),
        );
        const eventList = await dispatch(
          eventsActions.requestedEventList(datalist),
        );
        const datainvited = {
          user_id: user?.id,
        };
        const eventListhi = await dispatch(
          eventsActions.invitedEventList(datainvited),
        );
        const eventListddd = await dispatch(
          eventsActions.savedEventUpcoming(datalist),
        );
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const requestToIgnore = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: eventDetails?.id,
        from_user_id: eventDetails?.user_id,
        to_user_id: user?.id,
        datetime: dateTime(),
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestReject(data),
      );
      const datalist = {
        user_id: user?.id,
        categoryId: [],
      };
      const eventListevent = await dispatch(
        eventsActions.discoverEventList(datalist),
      );
      const eventList = await dispatch(
        eventsActions.requestedEventList(datalist),
      );
      const datainvited = {
        user_id: user?.id,
      };
      const eventListhi = await dispatch(
        eventsActions.invitedEventList(datainvited),
      );
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const manageInvitation = async () => {
    if (user?.isVerified) {
      navigate('/event/manage/invitation');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const withdrawRequest = async () => {
    if (user?.isVerified) {
      const data = {
        event_id: eventDetails?.id,
        user_id: user?.id,
        status: 'request',
      };
      const eventRequestwithdraw = await dispatch(
        eventsActions.eventRequestWithdraw(data),
      );
      const datahost = {
        user_id: user?.id,
        event_id: eventDetails?.id,
      };
      const hostedEventUser = await dispatch(
        eventsActions.eventStatusForUser(datahost),
      );
      const jobdetails = await dispatch(eventsActions.eventDetails(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const eventcancel = async () => {
    if (user?.isVerified) {
      const data = {
        id: eventDetails?.id,
      };
      const cancelevent = await dispatch(eventsActions.eventCancel(data));
      const datahost = {
        user_id: user?.id,
        event_id: eventDetails?.id,
      };
      const hostedEventUser = await dispatch(
        eventsActions.eventStatusForUser(datahost),
      );
      const jobdetails = await dispatch(eventsActions.eventDetails(data));
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const copyLink = async () => {
    if (user?.isVerified) {
      navigator.clipboard.writeText(eventDetails?.event_link);
      document.execCommand('copy');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };
  const joinEvent = async (e) => {
    e.preventDefault();
    if (user?.isVerified) {
      window.open(eventDetails?.event_link, '_blank');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const registered = async (e) => {
    e.preventDefault();
    if (user?.isVerified) {
      window.open(eventDetails?.external_event_link, '_blank');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const editEvent = async () => {
    if (user?.isVerified) {
      const eventEdit = await dispatch(
        eventsActions.editEventOpen(eventDetails),
      );
      navigate('/event/edit');
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const convertLinks = (input) => {
    console.log('convertLinks', input);
    if (input) {
      let text = input;
      const linksFound = text.match(/(?:www|https?)[^\s]+/g);
      const aLink = [];

      if (linksFound != null) {
        for (let i = 0; i < linksFound.length; i++) {
          let replace = linksFound[i];
          if (!linksFound[i].match(/(http(s?)):\/\//)) {
            replace = 'http://' + linksFound[i];
          }
          let linkText = replace.split('/')[2];
          let originalText = linksFound[i];
          if (linkText.substring(0, 3) == 'www') {
            linkText = linkText.replace('www.', '');
          }
          if (linkText.match(/youtu/)) {
            let youtubeID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' +
                youtubeID +
                '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            );
          } else if (linkText.match(/vimeo/)) {
            let vimeoID = replace.split('/').slice(-1)[0];
            aLink.push(
              '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
                vimeoID +
                '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
            );
          } else {
            aLink.push(
              '<a href="' +
                replace +
                '" target="_blank">' +
                originalText +
                '</a>',
            );
          }
          text = text
            .split(linksFound[i])
            .map((item) => {
              return aLink[i].includes('iframe') ? item.trim() : item;
            })
            .join(aLink[i]);
        }
        return text;
      } else {
        return input;
      }
    }
  };

  const dropDown = () => {
    if (user?.id === eventDetails?.user_id) {
      return (
        <Box>
          <List>
            {eventDetails.status !== 'Cancelled' && (
              <Fragment>
                <MenuItem
                  button
                  sx={{ padding: '2px 12px' }}
                  onClick={editEvent}>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
                <Divider />
              </Fragment>
            )}
            <MenuItem
              button
              sx={{ padding: '2px 12px' }}
              onClick={manageInvitation}>
              <ListItemText>Manage Invitations</ListItemText>
            </MenuItem>
            {eventDetails.status !== 'Cancelled' && (
              <Fragment>
                <Divider />
                <MenuItem
                  button
                  sx={{ padding: '2px 12px' }}
                  onClick={eventcancel}>
                  <ListItemText>Cancel this event</ListItemText>
                </MenuItem>
              </Fragment>
            )}
          </List>
        </Box>
      );
    } else {
      return (
        <Box>
          <List>
            <MenuItem button sx={{ padding: '2px 12px' }} onClick={reportEvent}>
              <ListItemText>Report</ListItemText>
            </MenuItem>
          </List>
        </Box>
      );
    }
  };

  if (eventsDetailsloading) {
    return <VoucherDetailsSkeleton />;
  } else {
    return (
      <Fragment>
        <Box px={1} py={1}>
          <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
            <Card
              sx={{
                width: '100%',
                position: 'relative',
              }}>
              <CardMedia
                component="img"
                alt="event Details"
                // height="300"
                sx={{
                  objectFit: 'contain',
                  height: 300,
                  '@media (max-width: 500px)': {
                    margin: '0px auto',
                    height: 160,
                  },
                }}
                image={eventDetails?.image_url ? eventDetails?.image_url : ''}
              />

              <CardHeader
                sx={{
                  padding: '8px 32px',
                  '@media (max-width: 500px)': { padding: '8px 16px' },
                }}
                title={
                  <Box pt={1.5}>
                    <Typography
                      component="h2s"
                      variant="h2s"
                      sx={{
                        fontSize: '18px',
                        '@media (max-width: 500px)': {
                          fontSize: '16px',
                        },
                      }}>
                      {eventDetails?.title ? eventDetails?.title : ''}
                    </Typography>
                  </Box>
                }
                subheader={
                  <Box>
                    <Box pt={1.4}>
                      <Stack spacing={0.4}>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            fontSize: '13px',
                            color: '#ffffff',
                            fontWeight: 'revert',
                            lineHeight: '16px',
                          }}>
                          {eventDetails?.venue ? eventDetails?.venue : ''}
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{
                            fontSize: '12px',
                            color: '#ffffff',
                            fontWeight: 'revert',
                          }}></Typography>
                      </Stack>
                    </Box>
                  </Box>
                }
                action={
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      aria-label="settings"
                      onClick={handleMobileMenuOpen}>
                      <MoreVertIcon />
                    </IconButton>
                    {anchorEl && (
                      <Box sx={{ marginLeft: '0px!important' }}>
                        <Box
                          anchorEl={anchorEl}
                          id="primary-search-account-menu"
                          keepMounted
                          sx={{
                            marginTop: '23px',
                            scrollbarWidth: 'thin',
                            position: 'absolute',
                            backgroundColor: '#252525',
                            height: 'auto',
                            width: '164px',
                            marginLeft: '-158px',
                            zIndex: '1000',
                            boxShadow:
                              '0px 3px 4px -1px rgb(0 0 0 / 30%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 4px 1px rgb(0 0 0 / 22%);  ',
                            borderRadius: '2px',
                          }}
                          open={anchorEl}
                          onClose={handleMenuClose}>
                          {dropDown()}
                        </Box>
                      </Box>
                    )}
                  </Stack>
                }
              />
              <CardContent>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={user?.id === eventDetails?.user_id ? 4 : 6}
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Box
                        pl={{ md: 2, xs: 0 }}
                        pt={0.5}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '18px',
                                maxWidth: 'min-content',
                                minWidth: '18px',
                                '@media (max-width: 500px)': {
                                  height: '14px',
                                  minWidth: '14px',
                                },
                              }}>
                              {eventDetails?.mode === 'Offline' && (
                                <EventsIcon
                                  name="events_offline"
                                  color="#38CD84"
                                />
                              )}

                              {eventDetails?.mode === 'Online' && (
                                <EventsIcon
                                  name="events_online"
                                  color="#38CD84"
                                />
                              )}
                            </Box>
                          </Box>
                          <Box py={0.5} px={1}>
                            <Typography
                              variant="h5"
                              component="h5"
                              sx={{
                                '@media (max-width: 500px)': {
                                  fontSize: '14px',
                                },
                              }}>
                              {eventDetails?.mode ? eventDetails?.mode : ''}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box py={1}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Box
                        pl={{ md: 2, xs: 0 }}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}>
                        <Stack direction="row">
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '18px',
                                maxWidth: 'min-content',
                                minWidth: '18px',
                              }}>
                              <EventsIcon name="events_time" color="#38CD84" />
                            </Box>
                          </Box>
                          <Box py={0.5} px={1}>
                            <Typography
                              variant="h5"
                              component="h5"
                              sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                '@media (max-width: 500px)': {
                                  fontSize: '12px',
                                },
                              }}>
                              {eventDetails?.start_date ? eventstartDate() : ''}{' '}
                              {eventDetails?.start_time ? eventStartTime() : ''}
                            </Typography>
                          </Box>
                          <Box
                            py={0.5}
                            px={{ md: 1, xs: 0 }}
                            sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="h5"
                              component="h5"
                              sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                '@media (max-width: 500px)': {
                                  fontSize: '12px',
                                },
                              }}>
                              {' -  '}{' '}
                              {eventDetails?.end_date ? eventEndDate() : ''}{' '}
                              {eventDetails?.end_time ? eventEndTime() : ''}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {eventDetails?.status === 'Cancelled' && (
                  <Box pt={2}>
                    <Divider />
                    <Box
                      py={3}
                      sx={{
                        display: 'flex',
                        justifyItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Typography
                        component="h2s"
                        variant="h2s"
                        sx={{ fontSize: '18px', fontWeight: '600' }}>
                        This event is cancelled
                      </Typography>
                    </Box>
                  </Box>
                )}

                {eventStatususer?.status !== 'Invited' &&
                  eventStatususer?.status !== 'accept' &&
                  eventDetails?.user_id !== user?.id &&
                  eventDetails?.status === 'Active' &&
                  eventStatususer?.status !== 'invited' &&
                  eventStatususer?.status !== 'request' &&
                  eventDetails?.external_event_link != '' && (
                    <Box pt={2}>
                      <Divider />
                      <Box
                        py={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <CxoButton
                          label="Register"
                          width="112px"
                          onClick={registered}
                        />
                      </Box>
                    </Box>
                  )}

                {eventStatususer?.status !== 'Invited' &&
                  eventStatususer?.status !== 'accept' &&
                  eventDetails?.user_id !== user?.id &&
                  eventDetails?.status === 'Active' &&
                  eventStatususer?.status !== 'invited' &&
                  eventStatususer?.status !== 'request' &&
                  eventDetails?.external_event_link === '' && (
                    <Box pt={2}>
                      <Divider />
                      <Box
                        py={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <CxoButton
                          label="Request to Attend"
                          width="112px"
                          onClick={attend}
                        />
                      </Box>
                    </Box>
                  )}

                {eventStatususer?.status !== 'Invited' &&
                  eventStatususer?.status !== 'accept' &&
                  eventDetails?.user_id !== user?.id &&
                  eventDetails?.status === 'Active' &&
                  eventStatususer?.status !== 'invited' &&
                  eventStatususer?.status !== 'request' &&
                  eventDetails?.external_event_link === null && (
                    <Box pt={2}>
                      <Divider />
                      <Box
                        py={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <CxoButton
                          label="Register"
                          width="112px"
                          onClick={registered}
                        />
                      </Box>
                    </Box>
                  )}
                {eventStatususer?.status === 'Invited' &&
                  eventDetails?.status === 'Active' && (
                    <Box pt={2}>
                      <Divider />
                      <Box
                        py={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <CxoButton
                          label="request to Attend"
                          width="112px"
                          onClick={attend}
                        />
                      </Box>
                    </Box>
                  )}

                {eventStatususer?.status === 'invited' &&
                  eventDetails?.status === 'Active' && (
                    <Box pt={2}>
                      <Divider />
                      <Box
                        py={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <Stack direction="row" spacing={6}>
                          <CxoButton
                            label="Ignore"
                            width="112px"
                            onClick={requestToIgnore}
                          />
                          <CxoButton
                            label="Attend"
                            width="112px"
                            onClick={requestToAttend}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  )}

                {eventStatususer?.status === 'request' &&
                  eventDetails?.status === 'Active' && (
                    <Box pt={2}>
                      <Divider />
                      <Box
                        py={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <Stack direction="row" spacing={6}>
                          <CxoButton
                            label="Cancel Request"
                            width="112px"
                            onClick={withdrawRequest}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  )}

                {eventStatususer?.status === 'accept' &&
                  eventDetails?.status === 'Active' && (
                    <Box pt={2}>
                      <Divider />
                      <Box
                        py={3}
                        sx={{
                          display: 'flex',
                          justifyItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <Stack direction="row" spacing={1}>
                          <CxoButton
                            label="Withdraw"
                            width="112px"
                            onClick={withdraw}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  )}

                <Box>
                  <Divider />
                </Box>
                {eventStatususer?.status === 'accept' &&
                  eventDetails?.mode === 'Online' &&
                  eventDetails?.status === 'Active' && (
                    <Fragment>
                      <Box pl={2} py={3} sx={{ width: '100%' }}>
                        <Stack direction="row" justifyContent="space between">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5s" component="span">
                              Link:
                            </Typography>

                            <Typography variant="h5" component="span">
                              {eventDetails?.event_link
                                ? eventDetails?.event_link?.substring(
                                    0,
                                    eventDetails?.event_link?.length / 2,
                                  ) + '...'
                                : ''}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                              justifyItems: 'center',
                              alignItems: 'center',
                            }}>
                            <Stack direction="row" spacing={8}>
                              <CxoButton
                                label="Copy Link"
                                width="160px"
                                onClick={copyLink}
                              />

                              <CxoButton
                                label="Join Event"
                                width="160px"
                                onClick={joinEvent}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                      <Divider />
                    </Fragment>
                  )}

                {user?.id === eventDetails?.user_id &&
                  eventDetails?.mode === 'Online' && (
                    <Fragment>
                      <Box pl={2} py={3} sx={{ width: '100%' }}>
                        <Stack direction="row" justifyContent="space between">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5s" component="span">
                              Link:
                            </Typography>

                            <Typography variant="h5" component="span">
                              {eventDetails?.event_link
                                ? eventDetails?.event_link?.substring(
                                    0,
                                    eventDetails?.event_link?.length / 2,
                                  ) + '...'
                                : ''}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                              justifyItems: 'center',
                              alignItems: 'center',
                            }}>
                            <Stack direction="row" spacing={8}>
                              <CxoButton
                                label="Copy Link"
                                width="160px"
                                onClick={copyLink}
                              />

                              <CxoButton
                                label="Join Event"
                                width="160px"
                                onClick={joinEvent}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                      <Divider />
                    </Fragment>
                  )}

                <Box pl={{ xs: 0, md: 2 }}>
                  <Grid container>
                    <Grid xs={4} item>
                      <Box pt={1}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Category:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8} item>
                      <Box pt={{ md: 2, xs: 1 }}>
                        {eventDetails?.categories &&
                          eventDetails?.categories?.map((categories, index) => {
                            if (index > 0) {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {',  ' + categories?.title}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {categories?.title ? categories?.title : ''}
                                </Typography>
                              );
                            }
                          })}
                      </Box>
                    </Grid>

                    <Grid xs={4} item>
                      <Box pt={1}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Industry:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8} item>
                      <Box pt={{ md: 2, xs: 1 }}>
                        {eventDetails?.industries &&
                          eventDetails?.industries?.map((industry, index) => {
                            if (index > 0) {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {',  ' + industry?.title}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {industry?.title ? industry?.title : ''}
                                </Typography>
                              );
                            }
                          })}
                      </Box>
                    </Grid>

                    <Grid xs={4} item>
                      <Box pt={1}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Function:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8} item>
                      <Box pt={{ md: 2, xs: 1 }}>
                        {eventDetails?.functions &&
                          eventDetails?.functions?.map((industry, index) => {
                            if (index > 0) {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {',  ' + industry?.title}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  key={index}
                                  variant="h5"
                                  component="span"
                                  sx={{
                                    fontSize: '15px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '13px',
                                    },
                                  }}>
                                  {industry?.title ? industry?.title : ''}
                                </Typography>
                              );
                            }
                          })}
                      </Box>
                    </Grid>

                    <Grid xs={4} item>
                      <Box pt={1}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Fees:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8} item>
                      <Box pt={{ md: 2, xs: 1 }}>
                        <Typography
                          component="span"
                          variant="h6"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          {eventDetails?.fee
                            ? eventDetails?.fee
                            : eventDetails?.fee}{' '}
                          {eventDetails?.currency ? eventDetails?.currency : ''}
                        </Typography>
                      </Box>
                    </Grid>

                    {user?.id !== eventDetails?.user_id && (
                      <Fragment>
                        <Grid xs={4} item>
                          <Box pt={1.5}>
                            <Typography
                              component="h2s"
                              variant="h2s"
                              sx={{
                                fontSize: '15px',
                                '@media (max-width: 500px)': {
                                  fontSize: '13px',
                                },
                              }}>
                              Host:
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={8} item>
                          <Box pt={{ md: 2, xs: 1 }}>
                            <Typography
                              // component="h6"
                              variant="h6"
                              component="span"
                              sx={{
                                fontSize: '15px',
                                marginLeft: '0',
                                '@media (max-width: 500px)': {
                                  fontSize: '13px',
                                  marginLeft: '25px',
                                },
                              }}>
                              {eventDetails?.first_name
                                ? eventDetails?.first_name +
                                  ' ' +
                                  eventDetails?.last_name
                                : ''}
                            </Typography>
                          </Box>
                        </Grid>
                      </Fragment>
                    )}
                    <Grid xs={4} item>
                      <Box pt={1}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Email:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8} item>
                      <Box pt={{ md: 2, xs: 1 }}>
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          {eventDetails?.email ? eventDetails?.email : ''}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid xs={4} item>
                      <Box pt={1}>
                        <Typography
                          component="h2s"
                          variant="h2s"
                          sx={{
                            fontSize: '15px',
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}>
                          Details:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={8} item>
                      <Box pt={{ md: 2, xs: 1 }}>
                        <Box
                          sx={{
                            fontWeight: '500',
                            fontSize: '15px',
                            color: 'rgba(255, 255,255,1)',
                            whiteSpace: 'pre-line',
                            lineHeight: '21px',
                            letterSpacing: '1px',

                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: convertLinks(eventDetails?.details),
                          }}></Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          </Paper>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(EventsDetails);

// <Stack direction="row">
//   <Box
//     sx={{
//       display: "flex",
//       justifyContent: "flex-start",
//       alignItems: "center",
//     }}
//   >
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "18px",
//         maxWidth: "min-content",
//         minWidth: "18px",
//       }}
//     >
//       <EventsIcon
//         name="event_attending"
//         color="#38CD84"
//       />
//     </Box>
//   </Box>
//   <Box
//     px={1}
//     sx={{ display: "flex", alignItems: "center" }}
//   >
//     <Typography variant="h5s" component="h5s">
//       Attending:
//     </Typography>
//   </Box>
//   <Box
//     py={0.5}
//     px={1}
//     sx={{ display: "flex", alignItems: "center" }}
//   >
//     <Typography variant="h5" component="h5">
//       {eventDetails?.attending_count} Members
//     </Typography>
//   </Box>
// </Stack>

// <Grid
//   item
//   xs={user?.id === eventDetails?.user_id ? 4 : 6}
//   sx={{ display: "flex", justifyContent: "flex-start" }}
// >
//   <Box
//     pl={2}
//     pt={0.5}
//     sx={{
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "flex-start",
//     }}
//   >
//
//   </Box>
// </Grid>

//
// {user?.id === eventDetails?.user_id && (
//   <Grid
//     item
//     xs={user?.id === eventDetails?.user_id ? 4 : 6}
//     sx={{ display: "flex", justifyContent: "flex-start" }}
//   >
//     <Box
//       pl={2}
//       sx={{
//         display: "flex",
//         justifyContent: "flex-start",
//       }}
//     >
//       <Stack direction="row">
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "flex-start",
//             alignItems: "center",
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "18px",
//               maxWidth: "min-content",
//               minWidth: "18px",
//             }}
//           >
//             <EventsIcon
//               name="events_invitation"
//               color="#38CD84"
//             />
//           </Box>
//         </Box>
//         <Box py={0.5} px={1}>
//           <Typography variant="h5s" component="h5s">
//             Invited:
//           </Typography>
//         </Box>
//         <Box
//           py={0.5}
//           px={1}
//           sx={{ display: "flex", alignItems: "center" }}
//         >
//           <Typography variant="h5" component="h5">
//             {eventDetails?.pending_count +
//               eventDetails?.requested_count}{" "}
//             Members
//           </Typography>
//         </Box>
//       </Stack>
//     </Box>
//   </Grid>
// )}
