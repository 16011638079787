import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CxoButton from '../../components/CxoButton/CxoButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import EventsIcon from '../../assets/eventsicon';
import InterestIcon from '../../assets/chooseurinterest';
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import * as eventsActions from '../../store/events';
import * as usernetworkActions from '../../store/usernetwork';
import * as companyActions from '../../store/company';
import { dateTime } from '../../constants/app';
import { useNavigate, useLocation } from 'react-router-dom';
import * as messageAction from '../../store/message';
import MuiCheckbox from '@mui/material/Checkbox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

function CheckboxCss({ label, icon, checkedIcon, value, onChange }) {
  console.log('CheckboxCss', value);
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          icon={icon}
          color="secondary"
          value={value}
          onChange={onChange}
          checkedIcon={checkedIcon}
        />
      }
    />
  );
}

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CssChip = styled(Chip)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  lineHeight: '21px',
  letterSpacing: '0.19px',
  fontSize: '16px',
  padding: '8px',
  marginTop: '16px',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  '& .MuiChip-deleteIcon': {
    color: '#38CD84',
  },
}));

const CompanyPageCreate = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const interestList = useSelector((state) => state.onboard.interestList);
  const loading = useSelector((state) => state?.events?.loading);
  const existingNetwork = useSelector(
    (state) => state?.usernetwork?.ExistingNetworkList,
  );
  const [industrylst, setIndustrylst] = useState([]);
  const [functionlst, setFunctionlst] = useState([]);
  const [interestlst, setInterestlst] = useState([]);
  const [inviteesId, setInviteesId] = useState([]);
  const [invitees, setInvitees] = useState('all');
  const [hostType, setHostType] = useState('Indivisual');
  const [eventType, setEventType] = React.useState('Public');
  const [eventMode, setEventMode] = React.useState('Online');
  const [currency, setCurrency] = React.useState('₹ (INR)');
  const [eventfee, setEventFee] = React.useState(false);
  const [datevalue, setValue] = React.useState(new Date());
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [imgData, setImgData] = useState(null);
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [regType, setRegType] = useState('');
  const [jobtype, setJobType] = useState('Full Time');
  const [showPreview, setPreview] = useState(false);
  const [doctitleShow, setDoctitleShow] = useState(false);
  const [networklst, setNetworklst] = useState([]);
  const [alreadyinvite, setAlready] = useState([]);
  const [showManual, setManual] = useState(false);
  const [policyagree, setPolicyagree] = useState(false);
  // const [hostType, setHosttype] = useState("");
  const companyDomain = useSelector((state) => state?.company?.companyDomain);
  const companyEmail = useSelector((state) => state?.company?.companyEmail);
  const companyPageId = useSelector(
    (state) => state?.company?.companyData?.company_page_id,
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);
  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getUserInterestList());
  }, []);

  useEffect(async () => {
    if (user?.email?.indexOf('linkcxo') > -1) {
    }
  }, []);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const existinglist = await dispatch(
      usernetworkActions.getExistingNetwork(data),
    );
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrylst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleClose = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // const sendInvitees = await dispatch(eventsActions.closeSendInviteesEvent());
    setManual(false);
  };
  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const interestChange = (event) => {
    const {
      target: { value },
    } = event;
    setInterestlst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleNetworkChange = (event) => {
    const {
      target: { value },
    } = event;
    setNetworklst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const hosttypeChange = (event) => {
    console.log('setHostType', event.target.value);
    setHostType(event.target.value);
  };

  const handleEventType = (event) => {
    setEventType('public');
  };
  const handleEventMode = (event) => {
    setEventMode(event.target.value);
  };
  const currencyChange = (event) => {
    setCurrency(event.target.value);
  };
  const changeFee = () => {
    setEventFee(!eventfee);
  };
  const handledateChange = (newValue) => {
    setValue(newValue);
  };
  const handleStartdateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEnddateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleChangepolicy = () => {
    setPolicyagree(!policyagree);
  };

  const handleStartTimeChange = (newvalue) => {
    setStartTime(newvalue);
  };
  const handleEndTimeChange = (newvalue) => {
    setEndTime(newvalue);
  };

  const regTypeChange = (e) => {
    setRegType(e.target.value);
  };
  const handleInvitees = (e) => {
    console.log(e.target.value);
    if (e.target.value === 'manual') {
      setManual(true);
      setInvitees(e.target.value);
    } else {
      setInvitees(e.target.value);
    }
  };

  const jobtypeChange = (event) => {
    setJobType(event.target.value);
  };

  const getPhoto = async (event) => {
    setDoctitleShow(false);
    setPreview(true);
    setFile(event.target.files[0]);
    setType('image');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmitform = async (data) => {
    console.log(
      'data',
      data,
      eventType,
      eventfee,
      eventMode,
      hostType,
      moment(startDate).format('YYYY-MM-DD'),
      moment(startTime).format('HH:mm:ss'),
      moment(endDate).format('YYYY-MM-DD'),
      moment(endTime).format('HH:mm:ss'),
    );

    const formData = new FormData();
    formData.append('id', companyPageId);
    formData.append('name', data?.name);
    formData.append('email', companyEmail);
    formData.append('tagline', data?.tagline);
    formData.append('domain_name', data?.domain_name);
    formData.append('page_name', data?.page_name);
    formData.append('industry_id', JSON.stringify(industrylst));
    formData.append('min_employee', jobtype.split('to')[0]);
    formData.append('max_employee', jobtype.split('to')[1]);
    formData.append('registration_type', regType);
    formData.append('description', data?.details);
    formData.append('tnc', 1);
    formData.append('user_id', user?.id);
    formData.append('image', file);

    if (policyagree) {
      if (file && industrylst && !loading) {
        const createevent = await dispatch(
          companyActions.companyPageUpdate(
            formData,
            0,
            0,
            (error, response) => {
              if (response) {
                reset();
                setIndustrylst([]);
                setFunctionlst([]);
                navigate('/company/manage/page');
              }
            },
          ),
        );
      } else {
        let msg = 'All Fields are Mandatory';
        const sendmessage = await dispatch(
          messageAction.messagefromComponent(msg, 'error'),
        );
      }
    } else {
      let msg = 'Please Check Term and Conditions';
      const sendmessage = await dispatch(
        messageAction.messagefromComponent(msg, 'error'),
      );
    }
  };

  return (
    <Fragment>
      {showManual && (
        <Fragment>
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '1001',
            }}>
            <Backdrop sx={{ color: '#fff', zIndex: '2001' }} open={showManual}>
              <Box px={0}>
                <Box
                  pt={2}
                  sx={{
                    width: '100%',
                    width: 500,
                    '@media (max-width: 500px)': {
                      width: 300,
                    },
                  }}>
                  re
                  <Paper
                    sx={{
                      backgroundColor: '#232121',
                      backgroundImage: 'none',
                    }}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={0}>
                      <Box
                        px={3}
                        py={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '16px',
                        }}>
                        <Typography variant="h2s" component="h2s">
                          Send Invite
                        </Typography>
                      </Box>
                      <Box pb={2} pr={2}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Stack direction="column" justifyContent="center">
                        <Box
                          sx={{
                            width: '400px',
                            '@media (max-width: 500px)': {
                              width: '280px',
                            },
                          }}>
                          <Box
                            py={1}
                            pl={1}
                            sx={{
                              width: '96%',
                            }}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                style={{
                                  color: '#38CD84',
                                  fontSize: '13px',
                                }}
                                id="demo-simple-select-label">
                                Network
                              </InputLabel>
                              <CssSelect
                                MenuProps={{
                                  fontSize: '12px',
                                  disablePadding: true,
                                  // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                                  PaperProps: {
                                    style: {
                                      maxHeight:
                                        ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                      width: 250,
                                    },
                                  },
                                  variant: 'menu',
                                  getContentAnchorEl: null,
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={networklst}
                                multiple
                                label="Industry List"
                                onChange={handleNetworkChange}
                                size="small"
                                className={classes.underline}
                                color="secondary"
                                sx={{ color: '#38CD84' }}
                                renderValue={(selected) => {
                                  console.log('selected', selected);
                                  return selected.length + ' ' + 'Selected';
                                }}>
                                {existingNetwork
                                  ? existingNetwork?.map((item, key) => {
                                      return (
                                        <MenuItem
                                          color="secondary"
                                          key={key}
                                          value={item.id}>
                                          <ListItemText
                                            color="secondary"
                                            primary={
                                              item.first_name +
                                              ' ' +
                                              item.last_name
                                            }
                                          />
                                          <Checkbox
                                            color="secondary"
                                            icon={<RadioButtonUncheckedIcon />}
                                            checkedIcon={<CheckCircleIcon />}
                                            checked={
                                              networklst.indexOf(item.id) > -1
                                            }
                                          />
                                        </MenuItem>
                                      );
                                    })
                                  : ''}
                              </CssSelect>
                            </FormControl>
                          </Box>
                          <Box p={4}></Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Backdrop>
          </Box>
        </Fragment>
      )}

      <Box px={2} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0}>
            <Box
              py={3}
              px={3}
              sx={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
              }}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{ fontWeight: 600 }}>
                Create Company Page
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>
      <Box px={2} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Box px={15}>
            <Card sx={{ width: '100%', position: 'relative' }}>
              <CardMedia
                component="img"
                src={imgData}
                height="230"
                image=""
                sx={{ backgroundColor: '#333', objectFit: 'contain' }}
              />
              <Stack
                direction="column"
                sx={{
                  position: 'absolute',
                  top: '50px',
                  left: '40%',
                  margin: 'auto',
                  '@media (max-width: 500px)': {
                    left: '23%',
                  },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <label htmlFor="icon-button-file">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '64px',
                        maxWidth: 'min-content',
                        minWidth: '64px',
                      }}>
                      <EventsIcon name="events_add_banner" color="#38CD84" />
                    </Box>
                  </label>
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={getPhoto}
                  />
                </Box>
                <Box
                  py={2}
                  px={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant="h6s" component="h6s">
                    Add Company Logo*
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Box>

          <Stack direction="column" justifyContent="center" spacing={1}>
            <Box px={{ sm: 16, xs: 0 }} pt={2}>
              <form onSubmit={handleSubmit(handleSubmitform)}>
                <Stack spacing={1} direction="row" sx={{ marginLeft: '-6px' }}>
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',

                          '@media (max-width: 500px)': {
                            minWidth: '16px',
                            height: '16px',
                          },
                        }}>
                        <Menuicon name="entity" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <CxoInputCss
                            label="Name *"
                            value={value}
                            err={errors?.name ? errors?.name : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.name?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="name"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Company Name is required',
                        },

                        pattern: {
                          value: /^[a-zA-Z.0-9 ]+$/,
                          message:
                            'Only Alphabetical Character Number Allowded',
                        },
                        maxLength: {
                          value: 60,
                          message: 'Max 60 character allow',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row" sx={{ marginLeft: '-6px' }}>
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          marginLeft: '-5px',
                          marginTop: '-12px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                            marginLeft: '-8px',
                            marginTop: '0px',
                          },
                        }}>
                        <InterestIcon name="Others" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <CxoInputCss
                            label="Tagline *"
                            value={value}
                            err={errors?.tagline ? errors?.tagline : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.tagline?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="tagline"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Tagline is required',
                        },

                        pattern: {
                          value: /^[a-zA-Z.0-9 ]+$/,
                          message:
                            'Only Alphabetical Character Number Allowded',
                        },
                        maxLength: {
                          value: 60,
                          message: 'Max 60 character allow',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          marginLeft: '-5px',
                          marginTop: '-12px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                            marginLeft: '-8px',
                            marginTop: '0px',
                          },
                        }}>
                        <Menuicon name="website" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <CxoInputCss
                            label="Domain Name *"
                            value={value}
                            err={
                              errors?.domain_name ? errors?.domain_name : false
                            }
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.domain_name?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="domain_name"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Domain Name is required',
                          readOnly: true,
                        },
                      }}
                      defaultValue={companyDomain}
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          marginLeft: '-5px',
                          marginTop: '-12px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                            marginLeft: '-8px',
                            marginTop: '0px',
                          },
                        }}>
                        <Menuicon name="link_company" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <CxoInputCss
                            label="LinkCxO Page Name* (Your company page name to be displayed on LinkCxO Platform)"
                            value={value}
                            err={errors?.page_name ? errors?.page_name : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.page_name?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="page_name"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'LinkCxO Page Name is required',
                        },

                        pattern: {
                          value: /^[a-zA-Z.0-9 ]+$/,
                          message:
                            'Only Alphabetical Character Number Allowded',
                        },
                        maxLength: {
                          value: 60,
                          message: 'Max 60 character allow',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="industry" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: '#38CD84',
                          fontSize: '13px',
                        }}
                        id="demo-simple-select-label">
                        Industry *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industrylst}
                        label="Industry List"
                        onChange={handleChange}
                        size="small"
                        className={classes.underline}
                        color="secondary"
                        sx={{ color: '#38CD84' }}
                        // renderValue={(selected) => {
                        //   console.log('selected', selected);
                        //   return selected;
                        // }}>
                      >
                        {industryList?.data
                          ? industryList?.data.map((item, key) => {
                              return (
                                <MenuItem
                                  color="secondary"
                                  key={key}
                                  value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.length > 30
                                        ? item.title.substring(0, 30) + '...'
                                        : item.title
                                    }
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            height: '18px',
                            minWidth: '18px',
                          },
                        }}>
                        <Menuicon name="individual" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: '#38CD84',
                          fontSize: '13px',
                        }}
                        id="demo-simple-select-label">
                        Size *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Function"
                        className={classes.underline}
                        value={jobtype}
                        onChange={jobtypeChange}
                        size="small">
                        <MenuItem key={0} value="1to10">
                          1 - 10
                        </MenuItem>
                        <MenuItem key={1} value="11to50">
                          11 - 50
                        </MenuItem>
                        <MenuItem key={2} value="51to200">
                          51 - 200
                        </MenuItem>
                        <MenuItem key={3} value="201to500">
                          201 - 500
                        </MenuItem>
                        <MenuItem key={4} value="501to1000">
                          501 - 1,000
                        </MenuItem>
                        <MenuItem key={5} value="1001to5000">
                          1,001 - 5,000
                        </MenuItem>

                        <MenuItem key={6} value="10000to999999">
                          10,000+ employees
                        </MenuItem>
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '16px',
                            height: '16px',
                          },
                        }}>
                        <EventsIcon name="category" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{
                          color: '#38CD84',
                          fontSize: '13px',
                        }}
                        id="demo-simple-select-label">
                        Registration Type *
                      </InputLabel>
                      <CssSelect
                        MenuProps={{
                          fontSize: '12px',
                          disablePadding: true,
                          // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          variant: 'menu',
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={regType}
                        label="Registration Type*"
                        className={classes.underline}
                        onChange={regTypeChange}
                        size="small">
                        <MenuItem key={0} value="Public company">
                          Public company
                        </MenuItem>
                        <MenuItem key={1} value="Self-employed">
                          Self-employed
                        </MenuItem>
                        <MenuItem key={2} value="Government agency">
                          Government agency
                        </MenuItem>
                        <MenuItem key={3} value="Nonprofit">
                          Nonprofit
                        </MenuItem>
                        <MenuItem key={4} value="Sole proprietorship">
                          Sole proprietorship
                        </MenuItem>
                        <MenuItem key={5} value="Privately held">
                          Privately held
                        </MenuItem>
                        <MenuItem key={6} value="Partnership">
                          Partnership
                        </MenuItem>
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack
                  spacing={{ md: 1, xs: 0, sm: 1 }}
                  direction="row"
                  sx={{ paddingTop: '8px' }}>
                  <Box
                    pt={{ md: 2, xs: 0, sm: 2 }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          marginLeft: '-5px',

                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                            marginLeft: '-8px',
                            marginTop: '0px',
                          },
                        }}>
                        <InterestIcon name="Others" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Typography variant="h6s" component="h6s">
                      Description *
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          '@media (max-width: 500px)': {
                            minWidth: '18px',
                            height: '18px',
                          },
                        }}></Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <TextField
                            variant="standard"
                            fullWidth
                            multiline
                            rows={6}
                            maxRows={8}
                            value={value}
                            onChange={onChange}
                            sx={{
                              backgroundColor: '#262525',
                              marginLeft: '8px',
                              padding: '8px',
                              borderRadius: '5px',
                              border: '2px solid #959498',
                            }}
                            placeholder="Start Typing Here"
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.details?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="details"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Event Details is required',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>

                <Box py={1} sx={{ textAlign: 'center' }}>
                  <Stack spacing={0} direction="row">
                    <Checkbox
                      checked={policyagree}
                      onChange={handleChangepolicy}
                      inputProps={{ 'aria-label': 'controlled' }}
                      size="small"
                      sx={{
                        color: '#38CD84',
                        '&.Mui-checked': {
                          color: '#38CD84',
                        },
                      }}
                    />
                    <Stack
                      spacing={0.5}
                      pt={1.5}
                      direction="column"
                      alignItems="start">
                      <Typography variant="h6" component="h6">
                        By checking this, you verify to be an authorized
                        representative of this organization and have the right
                        to act on behalf in the creation and management of this
                        page. The organization and you agree to the{' '}
                      </Typography>
                      <Typography
                        variant="h6s"
                        component="h6s"
                        sx={{ cursor: 'pointer' }}>
                        Terms & Conditions.
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                  <CxoButton label="Create" />
                </Stack>
              </form>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default React.memo(CompanyPageCreate);

//
// <Stack direction="column">
//   <Box
//     sx={{
//       backgroundColor: "#333",
//       height: "150px",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//                         }}
//   >
//
//     <Stack direction="column">
//
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <label htmlFor="icon-button-file">
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "64px",
//               maxWidth: "min-content",
//               minWidth: "64px",
//             }}
//           >
//             <EventsIcon name="events_add_banner" color="#38CD84" />
//           </Box>
//         </label>
//         <input
//           accept="image/*"
//           id="icon-button-file"
//           type="file"
//           style={{ display: "none" }}
//           onChange={getPhoto}
//         />
//       </Box>
//       <Box
//         py={2}
//         px={2}
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Typography variant="h6s" component="h6s">
//           Add Banner Image/Video*
//         </Typography>
//       </Box>
//     </Stack>
//   </Box>
// </Stack>

// {hostType === "External" && (
//   <Stack spacing={1} direction="row">
//     <Box
//       pt={2}
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <Box sx={{ display: "flex", justifyContent: "center" }}>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             height: "24px",
//             maxWidth: "min-content",
//             minWidth: "24px",
//           }}
//         >
//           <EventsIcon name="event_link" color="#38CD84" />
//         </Box>
//       </Box>
//     </Box>
//
//     <Box pl={1} sx={{ width: "96%" }}>
//       <Controller
//         control={control}
//         render={({ field: { onChange, onBlur, value } }) => (
//           <Stack sx={{ paddingRight: "16px" }}>
//             <CxoInputCss
//               label="External Registartion Link*"
//               value={value}
//               err={errors?.external_link ? errors?.external_link : false}
//               onChange={onChange}
//             />
//             <Box
//               sx={{
//                 fontSize: "9px",
//                 height: "10px",
//                 paddingLeft: "8px",
//               }}
//             >
//               {errors?.link?.message}{" "}
//             </Box>
//           </Stack>
//         )}
//         name="external_link"
//         required
//         rules={{
//           required: {
//             value: true,
//             message: "Event Link is required",
//           },
//         }}
//         defaultValue=""
//       />
//     </Box>
//   </Stack>
// )}
