const defaultState = {
  ArticalList: [],
  ViewArticalsDetails: [],
  loading: true,
  ArticleEdit: false,
  PostEdit: false,
  errors: {},
  article: {},
};
export const articlereducer = (state = defaultState, action) => {
  switch (action.type) {
    //===================================job-list======================
    case 'GET_ARTICALS_LIST_PENDING': {
      return {
        ...state,
        ArticalList: [],
        loading: true,
        errors: {},
      };
    }

    case 'GET_ARTICALS_LIST_FULFILLED': {
      return {
        ...state,
        ArticalList: action.payload.data.data,

        loading: false,
        errors: {},
      };
    }

    case 'GET_ARTICALS_LIST_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }

    //===================================Articals-view-Data======================

    case 'GET_VIEW_ARTICALS_DETATILS_PENDING': {
      return {
        ...state,
        ViewArticalsDetails: [],
        loading: true,
        errors: {},
      };
    }

    case 'GET_VIEW_ARTICALS_DETATILS_FULFILLED': {
      return {
        ...state,
        ViewArticalsDetails: action.payload,
        loading: false,
        errors: {},
      };
    }

    case 'GET_VIEW_ARTICALS_DETATILS_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    // =====================Add-Articals-Api-response==============================================
    case 'SEND_ADD_NEW_ARTICLE_DATA_PENDING': {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case 'SEND_ADD_NEW_ARTICLE_DATA_FULFILLED': {
      return {
        ...state,
        addArticalsRes: action.payload.data,
        loading: false,
        errors: {},
      };
    }

    case 'SEND_ADD_NEW_ARTICLE_DATA_REJECTED': {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    case 'CLOSE_ARTICLE_EDIT': {
      return {
        ...state,
        ArticleEdit: false,
      };
    }
    case 'OPEN_ARTICLE_EDIT': {
      return {
        ...state,
        ArticleEdit: true,
      };
    }
    case 'OPEN_ARTICLE_COMPANY_EDIT': {
      return {
        ...state,
        ArticleEdit: true,
        article: {},
      };
    }

    case 'CLOSE_UPDATE_ARTICLE': {
      return {
        ...state,
        ArticleEdit: false,
        article: {},
      };
    }
    case 'OPEN_UPDATE_ARTICLE': {
      return {
        ...state,
        ArticleEdit: true,
        article: action.payload,
      };
    }

    default:
      return state;
  }
};
