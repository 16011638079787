import React, { Component } from "react";
import { Row, Col, Container, Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderPolicy from "../../components/HomeHeader/headerPolicy";
import Footer from "../../components/HomeHeader/footer";

const PrivacyPolicy1 = () => {
  const navigate = useNavigate();

  return (
    <Container fluid id="no-padding">
      <Row>
        <Col md={12} xs={12} className="main-header-style-section">
          {" "}
          <HeaderPolicy />
        </Col>
      </Row>

      <Row
        style={{
          background: "#262525",
          color: "#fff",
          fontFamily: "open sans",
          letterSpacing: "1.5px",
        }}
      >
        <Col md={12} xs={12}>
          <div className="privacy-policy">
            <div className="wrapper">
              <h2 className="color-green">PRIVACY POLICY </h2>
              <br />
              <br />
              <h4>Introduction</h4>

              <p>
                Welcome to <b>LinkCXO Global Pvt. Ltd. </b> ("us, "we", or
                "our") operates linkcxo.com (hereinafter referred to as
                "Service").
                <br />
                Our Privacy Policy governs your visit to linkcxo.com, and
                explains how we collect, safeguard and disclose information that
                results from your use of our Service.
                <br />
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information
                under this policy. Unless otherwise defined in this Privacy
                Policy, the terms used in this Privacy Policy have the same
                meanings as in our Terms and Conditions.
                <br />
                Our Terms and Conditions ("Terms") govern all use of our Service
                and together with the Privacy Policy constitute your agreement
                with us ("Agreement").
              </p>

              <h4>Definitions</h4>

              <p>
                <b>SERVICE</b> means the linkcxo.com website operated by LinkCXO
                Global Pvt. Ltd.
                <br />
                <b>PERSONAL DATA</b>means data about a living individual who can
                be identified from those data (or from those and other
                information either in our possession or likely to come into our
                possession).
                <br />
                <b>USAGE DATA</b> is data collected automatically either
                generated by the use of Service or from Service infrastructure
                itself (for example, the duration of a page visit).
                <br />
                <b>COOKIES</b>are small files stored on your device (computer or
                mobile device).
                <br />
                <b>DATA CONTROLLER</b>means a natural or legal person who
                (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any
                personal data are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your data.
                <br />
                <b>DATA PROCESSORS</b> (OR SERVICE PROVIDERS)means any natural
                or legal person who processes the data on behalf of the Data
                Controller. We may use the services of various Service Providers
                to process your data more effectively.
                <br />
                <b>DATA SUBJECT</b> is any living individual who is the subject
                of Personal Data.
                <br />
                <b>THE USER</b> is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
              </p>
              <br />

              <h4>Information Collection and Use </h4>

              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>
              <br />

              <h4>Types of Data Collected </h4>

              <b>Personal Data </b>
              <br />

              <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you ("Personal Data"). Personally
                identifiable information may include, but is not limited to:
                {/* <ul>
                  <li>Email address</li>
                  <li>First name and last name </li>
                  <li>Phone number </li>
                  <li>
                    Address, Country, State, Province, ZIP/Postal code, City
                  </li>
                  <li>Cookies and Usage Data </li>
                </ul> */}
                <br />
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link.
              </p>

              <b>Financial Data</b>
              <br />

              <p>
                Financial Data Financial information, such as data related to
                your payment method (e.g. valid credit card number, card brand,
                expiration date) that we may collect when you purchase, order,
                return, exchange, or request information about our services from
                the Site. We store only very limited, if any, financial
                information that we collect. Otherwise, all financial
                information is stored by our payment processor, and you are
                encouraged to review their privacy policy and contact them
                directly for responses to your questions.
              </p>
              <br />

              <b>Social Networks</b>
              <br />

              <p>
                Data from Social Networks User information from social
                networking sites, including your name, your social network
                username, location, gender, birth date, email address, profile
                picture, and public data for contacts, if you connect your
                account to such social networks.
              </p>
              <br />

              <b>Third-Party Data</b>
              <br />

              <p>
                Third-Party Data Information from third parties, such as
                personal information or network friends, if you connect your
                account to the third party and grant the Site permission to
                access this information. Data from Contests, Giveaways, and
                Surveys Personal and other information you may provide when
                entering contests or giveaways and/or responding to surveys.
              </p>
              <br />
              <h4>Usage Data</h4>
              <p>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through
                any device ("Usage Data").
                <br />
                This Usage Data may include information such as your computer’s
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
                <br />
                When you access Service with a device, this Usage Data may
                include information such as the type of device you use, your
                device unique ID, the IP address of your device, your device
                operating system, the type of Internet browser you use, unique
                device identifiers and other diagnostic data.
              </p>
              <br />
              <h4>Tracking Cookies Data</h4>
              <p>
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
                <br />
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
                <br />
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
                <br />
                {/* Examples of Cookies we use: */}
                {/* <ul>
                  <li>
                    <b>Session Cookies:</b> We use Session Cookies to operate
                    our Service.{" "}
                  </li>
                  <li>
                    {" "}
                    <b>Preference Cookies:</b> We use Preference Cookies to
                    remember your preferences and various settings.
                  </li>
                  <li>
                    <b>Security Cookies:</b> We use Security Cookies for
                    security purposes.{" "}
                  </li>
                  <li>
                    <b>Advertising Cookies:</b> Advertising Cookies are used to
                    serve you with advertisements that may be relevant to you
                    and your interests.{" "}
                  </li>
                </ul> */}
              </p>
              <br />
              <h4>Other Data </h4>
              <p>
                While using our Service, we may also collect the following
                information: sex, age, date of birth, place of birth, passport
                details, citizenship, registration at place of residence and
                actual address, telephone number (work, mobile), details of
                documents on education, qualification, professional training,
                employment agreements, non-disclosure agreements, information on
                bonuses and compensation, information on marital status, family
                members, social security (or other taxpayer identification)
                number, office location and other data.
              </p>
              <br />
              <h4>Use of Data</h4>
              <p>
                LinkCXO Global Pvt. Ltd. uses the collected data for various
                purposes:
              </p>

              <ul>
                <li> to provide and maintain our Service;</li>
                <li>to notify you about changes to our Service; </li>
                <li>
                  to allow you to participate in interactive features of our
                  Service when you choose to do so;{" "}
                </li>
                <li>to provide customer support; </li>
                <li>
                  {" "}
                  to gather analysis or valuable information so that we can
                  improve our Service;{" "}
                </li>
                <li>to monitor the usage of our Service; </li>
                <li> to detect, prevent and address technical issues; </li>
                <li>to fulfil any other purpose for which you provide it; </li>
                <li>
                  to carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection;{" "}
                </li>
                <li>
                  to provide you with notices about your account and/or
                  subscription, including expiration and renewal notices,
                  email-instructions, etc.;{" "}
                </li>
                <li>
                  to provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information;
                </li>
                <li>
                  {" "}
                  in any other way we may describe when you provide the
                  information;{" "}
                </li>
                <li>for any other purpose with your consent. </li>

                <li>
                  Fulfill and manage purchases, orders, payments, and other
                  transactions related to the Site.
                </li>

                <li>Process payments and refunds.</li>

                <li>
                  Third-Party Service Providers We may share your information
                  with third parties that perform services for us or on our
                  behalf, including payment processing, data analysis, email
                  delivery, hosting services, customer service, and marketing
                  assistance.
                </li>

                <li>Email you regarding your account or order.</li>

                <li>Enable user-to-user communications.</li>

                <li>Notify you of updates to the Site.</li>
                <li>
                  Offer new products, services, and/or recommendations to you
                </li>
                <li>
                  Request feedback and contact you about your use of the Site.
                </li>

                <li>Resolve disputes and troubleshoot problems.</li>
                <li>Respond to product and customer service requests.</li>
                <li>
                  Return, Exchange, shipping, and Refunds are not applicable on
                  the Vouchers as these are one-time shared code and you use
                  these codes particularly on respective website.
                </li>
                <li>
                  Discounts varies time to time as these are given from third
                  party.
                </li>
              </ul>
              <br />
              <br />
              <br />
              <h4>Retention of Data </h4>

              <p>
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                <br />
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>
              <br />

              <h4>Transfer of Data </h4>

              <p>
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
                <br />
                If you are located outside India and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to India and process it there.
                <br />
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
                <br />
                <b> LinkCXO Global Pvt. Ltd.</b> will take all the steps
                reasonably necessary to ensure that your data is treated
                securely and in accordance with this Privacy Policy and no
                transfer of your Personal Data will take place to an
                organisation or a country unless there are adequate controls in
                place including the security of your data and other personal
                information.
              </p>
              <br />

              <h4>Disclosure of Data </h4>

              <p>
                We may disclose personal information that we collect, or you
                provide:
                <br />
                <b>0.1. Disclosure for Law Enforcement.</b>
                <br />
                Under certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities.
                <br />
                <b>0.2. Business Transaction.</b> <br />
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.
                <br />
                <b>
                  0.3. Other cases. We may disclose your information also:
                </b>{" "}
                <br />
              </p>
              <p style={{ paddinglLeft: "15px;" }}>
                0.3.1. to our subsidiaries and affiliates;
                <br />
                0.3.2. to contractors, service providers, and other third
                parties we use to support our business;
                <br />
                0.3.3. to fulfill the purpose for which you provide it;
                <br />
                0.3.4. for the purpose of including your company’s logo on our
                website;
                <br />
                0.3.5. for any other purpose disclosed by us when you provide
                the information;
                <br />
                0.3.6. with your consent in any other cases;
                <br />
                0.3.7. if we believe disclosure is necessary or appropriate to
                protect the rights, property, or safety of the Company, our
                customers, or others.
              </p>
              <br />

              <h4>Security of Data</h4>

              <p>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.{" "}
              </p>
              <br />

              <h4>Service Providers </h4>

              <p>
                We may employ third party companies and individuals to
                facilitate our Service ("Service Providers"), provide Service on
                our behalf, perform Service-related services or assist us in
                analysing how our Service is used.
                <br />
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>
              <br />

              <h4>Analytics</h4>
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <br />

              <h4>CI/CD tools</h4>
              <p>
                We may use third-party Service Providers to automate the
                development process of our Service.
              </p>
              <br />

              <h4>Behavioral Remarketing</h4>

              <p>
                We may use remarketing services to advertise on third party
                websites to you after you visited our Service. We and our
                third-party vendors use cookies to inform, optimise and serve
                ads based on your past visits to our Service.
              </p>
              <br />

              <h4>Links to Other Sites </h4>

              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy of every site you visit.
                <br />
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>

              <br />
              <h4>Children’s Privacy</h4>

              <p>
                Our Services are not intended for use by children under the age
                of 18 ("Child" or "Children").
                <br />
                We do not knowingly collect personally identifiable information
                from Children under 18. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
              <br />

              <h4>Changes to This Privacy Policy </h4>

              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
                <br />
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                "effective date" at the top of this Privacy Policy.
                <br />
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <br />

              <h4>Contact Us</h4>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us by email1:{" "}
                <a href="mailto:support@linkcxo.com">support@linkcxo.com.</a>
              </p>
            </div>
          </div>
        </Col>
      </Row>

        <Footer />

    </Container>
  );
};

export default PrivacyPolicy1;
