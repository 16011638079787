import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import JobCard from '../../components/JobCard/JobCard';
import * as jobsActions from '../../store/jobs';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
  seismicdata: {
    backgroundColor: '#262626',
    padding: '32px',
  },
  wellheader: {
    backgroundColor: '#000113',
  },
  welldata: {
    maxWidth: '1260px',
  },
}));
const JobSearch = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const recommendedJob = useSelector(
    (state) => state?.jobs?.RecommendatJobList,
  );
  const [noofRecomended, setNoofRecomended] = React.useState(4);
  console.log('recommendedJob', recommendedJob);
  useEffect(async () => {
    const data = {
      user_id: user.id,
      status: 'open',
    };
    const jobs = await dispatch(jobsActions.getJobsList(data, data.status));
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user.id,
    };
    const jobs = await dispatch(jobsActions.getRecommendedList(data));
  }, []);
  const seeMore = () => {
    if (recommendedJob?.length > noofRecomended) {
      setNoofRecomended(noofRecomended + 4);
    }
  };

  return (
    <Fragment>
      <Box px={0}>
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{
                    fontWeight: 600,
                    '@media (max-width: 500px)': {
                      fontSize: '12px',
                      fontWeight: '400',
                    },
                  }}>
                  Recommended Jobs
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={seeMore}>
                <Typography variant="h5" component="h5">
                  See All
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
          }}>
          <Grid container>
            {recommendedJob?.length > 0 &&
              recommendedJob?.slice(0, noofRecomended)?.map((list, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      paddingRight: index % 2 === 0 ? '8px' : '0px',
                      marginTop: '8px',
                    }}>
                    <JobCard joblist={list} type="search" />
                  </Grid>
                );
              })}
            {recommendedJob?.length == 0 && (
              <Typography variant="h5" component="h5">
                No Record Found
              </Typography>
            )}
          </Grid>
        </Box>
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Applied
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}>
                <Typography variant="h5" component="h5">
                  See All
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>
        <Box pt={2} sx={{ width: '100%' }}>
          <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={0}>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h5s"
                  component="h5s"
                  sx={{ fontWeight: 600 }}>
                  Saved
                </Typography>
              </Box>
              <Box
                py={3}
                px={3}
                sx={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}>
                <Typography variant="h5" component="h5">
                  See All
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobSearch);
