import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ApplyStartup from "./ApplyStartup";
import { useNavigate, useLocation } from "react-router-dom";

const ApplyStartupWrapper = (props) => {
  const navigate = useNavigate();
  const redirectSuccessPageStartup = (data) => {
    // navigate("/login?account_type=linkcxohome&email="+ data?.email+"&mobile="+data?.mobile+"&first_name="+data?.first_name+"&last_name="+data?.last_name+"&company_name="+data?.startup_name_description);
    navigate("/SuccessStartup");
  };
  return (
    <ApplyStartup redirectSuccessPageStartup={redirectSuccessPageStartup} />
  );
};
export default ApplyStartupWrapper;
