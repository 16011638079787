import {  apiUrl } from '../../services';
import { http } from '../../services/client';
import { showMessage } from '../message';

export const HOSTED_EVENT_PASSED_SUCCESS = 'HOSTED_EVENT_PASSED_SUCCESS';
export const HOSTED_EVENT_PASSED_REQUEST = 'HOSTED_EVENT_PASSED_REQUEST';
export const HOSTED_EVENT_PASSED_FAILURE = 'HOSTED_EVENT_PASSED_FAILURE';

export const LINKCXO_REWARD_LIST_SUCCESS = 'LINKCXO_REWARD_LIST_SUCCESS';
export const LINKCXO_REWARD_LIST_REQUEST = 'LINKCXO_REWARD_LIST_REQUEST';
export const LINKCXO_REWARD_LIST_FAILURE = 'LINKCXO_REWARD_LIST_FAILURE';

const linkcxoRewardListRequest = () => ({
  type: LINKCXO_REWARD_LIST_REQUEST,
});

const linkcxoRewardListFailure = (error) => ({
  type: LINKCXO_REWARD_LIST_FAILURE,
  payload: { error: error },
});

const linkcxoRewardListSuccess = (data) => ({
  type: LINKCXO_REWARD_LIST_SUCCESS,
  payload: data,
});

export const linkcxoRewardList = (payload) => {
  return (dispatch) => {
    dispatch(linkcxoRewardListRequest());
    return http
      .post(apiUrl.LINKCXO_REWARD_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(linkcxoRewardListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(linkcxoRewardListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(linkcxoRewardListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(linkcxoRewardListFailure());
        return error;
      });
  };
};

export const REWARDS_CATEGORY_LIST_SUCCESS = 'REWARDS_CATEGORY_LIST_SUCCESS';
export const REWARDS_CATEGORY_LIST_REQUEST = 'REWARDS_CATEGORY_LIST_REQUEST';
export const REWARDS_CATEGORY_LIST_FAILURE = 'REWARDS_CATEGORY_LIST_FAILURE';

const rewardsCategoryListRequest = () => ({
  type: REWARDS_CATEGORY_LIST_REQUEST,
});

const rewardsCategoryListFailure = (error) => ({
  type: REWARDS_CATEGORY_LIST_FAILURE,
  payload: { error: error },
});

const rewardsCategoryListSuccess = (data) => ({
  type: REWARDS_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const rewardsCategoryList = (payload) => {
  return (dispatch) => {
    dispatch(rewardsCategoryListRequest());
    return http
      .post(apiUrl.REWARDS_CATEGORY_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsCategoryListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsCategoryListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsCategoryListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsCategoryListFailure());
        return error;
      });
  };
};

export const REWARDS_PARTNER_LIST_SUCCESS = 'REWARDS_PARTNER_LIST_SUCCESS';
export const REWARDS_PARTNER_LIST_REQUEST = 'REWARDS_PARTNER_LIST_REQUEST';
export const REWARDS_PARTNER_LIST_FAILURE = 'REWARDS_PARTNER_LIST_FAILURE';

const rewardsPartnerListRequest = () => ({
  type: REWARDS_PARTNER_LIST_REQUEST,
});

const rewardsPartnerListFailure = (error) => ({
  type: REWARDS_PARTNER_LIST_FAILURE,
  payload: { error: error },
});

const rewardsPartnerListSuccess = (data, payload) => ({
  type: REWARDS_PARTNER_LIST_SUCCESS,
  payload: { reward: data, payld: payload },
});

export const rewardsPartnerList = (payload) => {
  return (dispatch) => {
    dispatch(rewardsPartnerListRequest());
    return http
      .post(apiUrl.REWARDS_PARTNER_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsPartnerListSuccess(response, payload));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsPartnerListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsPartnerListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsPartnerListFailure());
        return error;
      });
  };
};

export const REWARDS_PARTNER_DETAILS_SUCCESS =
  'REWARDS_PARTNER_DETAILS_SUCCESS';
export const REWARDS_PARTNER_DETAILS_REQUEST =
  'REWARDS_PARTNER_DETAILS_REQUEST';
export const REWARDS_PARTNER_DETAILS_FAILURE =
  'REWARDS_PARTNER_DETAILS_FAILURE';

const rewardsPartnerDetailsRequest = () => ({
  type: REWARDS_PARTNER_DETAILS_REQUEST,
});

const rewardsPartnerDetailsFailure = (error) => ({
  type: REWARDS_PARTNER_DETAILS_FAILURE,
  payload: { error: error },
});

const rewardsPartnerDetailsSuccess = (data) => ({
  type: REWARDS_PARTNER_DETAILS_SUCCESS,
  payload: data,
});

export const rewardsPartnerDetails = (payload) => {
  return (dispatch) => {
    dispatch(rewardsPartnerDetailsRequest());
    return http
      .post(apiUrl.REWARDS_PARTNER_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsPartnerDetailsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsPartnerDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsPartnerDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsPartnerDetailsFailure());
        return error;
      });
  };
};

export const REWARDS_VOUCHER_LIST_SUCCESS = 'REWARDS_VOUCHER_LIST_SUCCESS';
export const REWARDS_VOUCHER_LIST_REQUEST = 'REWARDS_VOUCHER_LIST_REQUEST';
export const REWARDS_VOUCHER_LIST_FAILURE = 'REWARDS_VOUCHER_LIST_FAILURE';

const rewardsVoucherListRequest = () => ({
  type: REWARDS_VOUCHER_LIST_REQUEST,
});

const rewardsVoucherListFailure = (error) => ({
  type: REWARDS_VOUCHER_LIST_FAILURE,
  payload: { error: error },
});

const rewardsVoucherListSuccess = (data) => ({
  type: REWARDS_VOUCHER_LIST_SUCCESS,
  payload: data,
});

export const rewardsVoucherList = (payload) => {
  return (dispatch) => {
    dispatch(rewardsVoucherListRequest());
    return http
      .post(apiUrl.REWARDS_VOUCHER_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsVoucherListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsVoucherListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsVoucherListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsVoucherListFailure());
        return error;
      });
  };
};

export const REWARDS_VOUCHER_DETAILS_SUCCESS =
  'REWARDS_VOUCHER_DETAILS_SUCCESS';
export const REWARDS_VOUCHER_DETAILS_REQUEST =
  'REWARDS_VOUCHER_DETAILS_REQUEST';
export const REWARDS_VOUCHER_DETAILS_FAILURE =
  'REWARDS_VOUCHER_DETAILS_FAILURE';

const rewardsVoucherDetailsRequest = () => ({
  type: REWARDS_VOUCHER_DETAILS_REQUEST,
});

const rewardsVoucherDetailsFailure = (error) => ({
  type: REWARDS_VOUCHER_DETAILS_FAILURE,
  payload: { error: error },
});

const rewardsVoucherDetailsSuccess = (data) => ({
  type: REWARDS_VOUCHER_DETAILS_SUCCESS,
  payload: data,
});

export const rewardsVoucherDetails = (payload) => {
  return (dispatch) => {
    dispatch(rewardsVoucherDetailsRequest());
    return http
      .post(apiUrl.REWARDS_VOUCHER_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsVoucherDetailsSuccess(response));

          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsVoucherDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsVoucherDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsVoucherDetailsFailure());
        return error;
      });
  };
};


export const REWARDS_DISCOUNT_COUPONS_SUCCESS =
  'REWARDS_DISCOUNT_COUPONS_SUCCESS';
export const REWARDS_DISCOUNT_COUPONS_REQUEST =
  'REWARDS_DISCOUNT_COUPONS_REQUEST';
export const REWARDS_DISCOUNT_COUPONS_FAILURE =
  'REWARDS_DISCOUNT_COUPONS_FAILURE';

const rewardsValidityCheckRequest = () => ({
  type: REWARDS_DISCOUNT_COUPONS_REQUEST,
});

const rewardsValidityCheckFailure = (error) => ({
  type: REWARDS_DISCOUNT_COUPONS_FAILURE,
  payload: { error: error },
});

const rewardsValidityCheckSuccess = (data) => ({
  type: REWARDS_DISCOUNT_COUPONS_SUCCESS,
  payload: data,
});

export const rewardsValidityCheck = (payload) => {
  return (dispatch) => {
    dispatch(rewardsValidityCheckRequest());
    return http
      .post(apiUrl.REWARDS_VOUCHER_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsValidityCheckSuccess(response));

          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsValidityCheckSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsValidityCheckFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsValidityCheckFailure());
        return error;
      });
  };
};


export const REWARDS_REEDEMED_VOUCHER_LIST_SUCCESS =
  'REWARDS_REEDEMED_VOUCHER_LIST_SUCCESS';
export const REWARDS_REEDEMED_VOUCHER_LIST_REQUEST =
  'REWARDS_REEDEMED_VOUCHER_LIST_REQUEST';
export const REWARDS_REEDEMED_VOUCHER_LIST_FAILURE =
  'REWARDS_REEDEMED_VOUCHER_LIST_FAILURE';

const rewardsReedemedVoucherListRequest = () => ({
  type: REWARDS_REEDEMED_VOUCHER_LIST_REQUEST,
});

const rewardsReedemedVoucherListFailure = (error) => ({
  type: REWARDS_REEDEMED_VOUCHER_LIST_FAILURE,
  payload: { error: error },
});

const rewardsReedemedVoucherListSuccess = (data) => ({
  type: REWARDS_REEDEMED_VOUCHER_LIST_SUCCESS,
  payload: data,
});

export const rewardsReedemedVoucherList = (payload) => {
  return (dispatch) => {
    dispatch(rewardsReedemedVoucherListRequest());
    return http
      .post(apiUrl.REWARDS_REEDEMED_VOUCHER_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsReedemedVoucherListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsReedemedVoucherListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsReedemedVoucherListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsVoucherDetailsFailure());
        return error;
      });
  };
};

export const REWARDS_EXPIRED_VOUCHER_LIST_SUCCESS =
  'REWARDS_EXPIRED_VOUCHER_LIST_SUCCESS';
export const REWARDS_EXPIRED_VOUCHER_LIST_REQUEST =
  'REWARDS_EXPIRED_VOUCHER_LIST_REQUEST';
export const REWARDS_EXPIRED_VOUCHER_LIST_FAILURE =
  'REWARDS_EXPIRED_VOUCHER_LIST_FAILURE';

const rewardsExpiredVoucherListRequest = () => ({
  type: REWARDS_EXPIRED_VOUCHER_LIST_REQUEST,
});

const rewardsExpiredVoucherListFailure = (error) => ({
  type: REWARDS_EXPIRED_VOUCHER_LIST_FAILURE,
  payload: { error: error },
});

const rewardsExpiredVoucherListSuccess = (data) => ({
  type: REWARDS_EXPIRED_VOUCHER_LIST_SUCCESS,
  payload: data,
});

export const rewardsExpiredVoucherList = (payload) => {
  return (dispatch) => {
    dispatch(rewardsExpiredVoucherListRequest());
    return http
      .post(apiUrl.REWARDS_EXPIRED_VOUCHER_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsExpiredVoucherListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsExpiredVoucherListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsExpiredVoucherListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsExpiredVoucherListFailure());
        return error;
      });
  };
};

export const REWARDS_GET_TOTAL_CREDITS_SUCCESS =
  'REWARDS_GET_TOTAL_CREDITS_SUCCESS';
export const REWARDS_GET_TOTAL_CREDITS_REQUEST =
  'REWARDS_GET_TOTAL_CREDITS_REQUEST';
export const REWARDS_GET_TOTAL_CREDITS_FAILURE =
  'REWARDS_GET_TOTAL_CREDITS_FAILURE';

const rewardsGetTotalCreditsRequest = () => ({
  type: REWARDS_GET_TOTAL_CREDITS_REQUEST,
});

const rewardsGetTotalCreditsSuccess = (data) => ({
  type: REWARDS_GET_TOTAL_CREDITS_SUCCESS,
  payload: data,
});

export const rewardsGetTotalCredits = (payload) => {
  return (dispatch) => {
    dispatch(rewardsGetTotalCreditsRequest());
    return http
      .post(apiUrl.REWARDS_GET_TOTAL_CREDITS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsGetTotalCreditsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsGetTotalCreditsSuccess({ data: [] }));
            return response;
          } else {
            // dispatch(rewardsGetTotalCreditsFailure(response));\
            dispatch(rewardsGetTotalCreditsSuccess({ data: [] }));
            return response;
          }
        }
      })
      .catch((error) => {
        // dispatch(rewardsGetTotalCreditsFailure());
        dispatch(rewardsGetTotalCreditsSuccess({ data: [] }));
        return error;
      });
  };
};





export const REWARDS_INTEREST_SUCCESS = 'REWARDS_INTEREST_SUCCESS';
export const REWARDS_INTEREST_REQUEST = 'REWARDS_INTEREST_REQUEST';
export const REWARDS_INTEREST_FAILURE = 'REWARDS_INTEREST_FAILURE';

const rewardsInterestRequest = () => ({
  type: REWARDS_INTEREST_REQUEST,
});

const rewardsInterestFailure = (error) => ({
  type: REWARDS_GET_APPLAUSES_FAILURE,
  payload: { error: error },
});

const rewardsInterestSuccess = (data) => ({
  type: REWARDS_INTEREST_SUCCESS,
  payload: data,
});

export const rewardsInterest = (payload) => {
  return (dispatch) => {
    dispatch(rewardsInterestRequest());
    return http
      .post(apiUrl.REWARDS_INTEREST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsInterestSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsInterestSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsInterestFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsInterestFailure());
        return error;
      });
  };
};


export const REWARDS_GET_APPLAUSES_SUCCESS = 'REWARDS_GET_APPLAUSES_SUCCESS';
export const REWARDS_GET_APPLAUSES_REQUEST = 'REWARDS_GET_APPLAUSES_REQUEST';
export const REWARDS_GET_APPLAUSES_FAILURE = 'REWARDS_GET_APPLAUSES_FAILURE';

const rewardsGetApplausesRequest = () => ({
  type: REWARDS_GET_APPLAUSES_REQUEST,
});

const rewardsGetApplausesFailure = (error) => ({
  type: REWARDS_GET_APPLAUSES_FAILURE,
  payload: { error: error },
});

const rewardsGetApplausesSuccess = (data) => ({
  type: REWARDS_GET_APPLAUSES_SUCCESS,
  payload: data,
});

export const rewardsGetApplauses = (payload) => {
  return (dispatch) => {
    dispatch(rewardsGetApplausesRequest());
    return http
      .post(apiUrl.REWARDS_GET_APPLAUSES, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsGetApplausesSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsGetApplausesSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsGetApplausesFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsGetApplausesFailure());
        return error;
      });
  };
};

export const REWARDS_MEMBERSHIP_TYPE_LIST_SUCCESS =
  'REWARDS_MEMBERSHIP_TYPE_LIST_SUCCESS';
export const REWARDS_MEMBERSHIP_TYPE_LIST_REQUEST =
  'REWARDS_MEMBERSHIP_TYPE_LIST_REQUEST';
export const REWARDS_MEMBERSHIP_TYPE_LIST_FAILURE =
  'REWARDS_MEMBERSHIP_TYPE_LIST_FAILURE';

const rewardsMembershipTypeListRequest = () => ({
  type: REWARDS_MEMBERSHIP_TYPE_LIST_REQUEST,
});

const rewardsMembershipTypeListFailure = (error) => ({
  type: REWARDS_MEMBERSHIP_TYPE_LIST_FAILURE,
  payload: { error: error },
});

const rewardsMembershipTypeListSuccess = (data) => ({
  type: REWARDS_MEMBERSHIP_TYPE_LIST_SUCCESS,
  payload: data,
});

export const rewardsMembershipTypeList = (payload) => {
  return (dispatch) => {
    dispatch(rewardsMembershipTypeListRequest());
    return http
      .post(apiUrl.REWARDS_MEMBERSHIP_TYPE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsMembershipTypeListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsMembershipTypeListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsMembershipTypeListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsGetCreditsDetailsFailure());
        return error;
      });
  };
};

export const REWARDS_GET_CREDITS_DETAILS_SUCCESS =
  'REWARDS_GET_CREDITS_DETAILS_SUCCESS';
export const REWARDS_GET_CREDITS_DETAILS_REQUEST =
  'REWARDS_GET_CREDITS_DETAILS_REQUEST';
export const REWARDS_GET_CREDITS_DETAILS_FAILURE =
  'REWARDS_GET_CREDITS_DETAILS_FAILURE';

const rewardsGetCreditsDetailsRequest = () => ({
  type: REWARDS_GET_CREDITS_DETAILS_REQUEST,
});

const rewardsGetCreditsDetailsFailure = (error) => ({
  type: REWARDS_GET_CREDITS_DETAILS_FAILURE,
  payload: { error: error },
});

const rewardsGetCreditsDetailsSuccess = (data) => ({
  type: REWARDS_GET_CREDITS_DETAILS_SUCCESS,
  payload: data,
});

export const rewardsGetCreditsDetails = (payload) => {
  return (dispatch) => {
    dispatch(rewardsGetCreditsDetailsRequest());
    return http
      .post(apiUrl.REWARDS_GET_CREDITS_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsGetCreditsDetailsSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsGetCreditsDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsGetCreditsDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsGetCreditsDetailsFailure());
        return error;
      });
  };
};

export const REWARDS_GET_CREDITS_LIST_SUCCESS =
  'REWARDS_GET_CREDITS_LIST_SUCCESS';
export const REWARDS_GET_CREDITS_LIST_REQUEST =
  'REWARDS_GET_CREDITS_LIST_REQUEST';
export const REWARDS_GET_CREDITS_LIST_FAILURE =
  'REWARDS_GET_CREDITS_LIST_FAILURE';

const rewardsGetCreditsListRequest = () => ({
  type: REWARDS_GET_CREDITS_LIST_REQUEST,
});

const rewardsGetCreditsListFailure = (error) => ({
  type: REWARDS_GET_CREDITS_LIST_FAILURE,
  payload: { error: error },
});

const rewardsGetCreditsListSuccess = (data) => ({
  type: REWARDS_GET_CREDITS_LIST_SUCCESS,
  payload: data,
});

export const rewardsGetCreditsList = (payload) => {
  return (dispatch) => {
    dispatch(rewardsGetCreditsListRequest());
    return http
      .post(apiUrl.REWARDS_GET_CREDITS_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsGetCreditsListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsGetCreditsListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsGetCreditsListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsGetCreditsListFailure());
        return error;
      });
  };
};

export const REWARDS_REDEEM_VOUCHER_SUCCESS = 'REWARDS_REDEEM_VOUCHER_SUCCESS';
export const REWARDS_REDEEM_VOUCHER_REQUEST = 'REWARDS_REDEEM_VOUCHER_REQUEST';
export const REWARDS_REDEEM_VOUCHER_FAILURE = 'REWARDS_REDEEM_VOUCHER_FAILURE';

const rewardsRedeemVoucherRequest = () => ({
  type: REWARDS_REDEEM_VOUCHER_REQUEST,
});

const rewardsRedeemVoucherFailure = (error) => ({
  type: REWARDS_REDEEM_VOUCHER_FAILURE,
  payload: { error: error },
});

const rewardsRedeemVoucherSuccess = (data) => ({
  type: REWARDS_REDEEM_VOUCHER_SUCCESS,
  payload: data,
});

// export const rewardsRedeemVoucher = (payload ) => {
//   return (dispatch) => {
//     dispatch(rewardsRedeemVoucherRequest());
//     const token = getAccessToken();
//     return API.http_form.post(
//       {
//         endPoint: apiUrl.REWARDS_REDEEM_VOUCHER,
//         body: payload,
//         token: token,
//       },
//
//       (error, response) => {
//         if (error) {
//           console.log("error", error);
//           const message = `${error.error || error.status || error.name}: ${
//             error.message
//           }`;
//           dispatch(rewardsRedeemVoucherFailure(error));
//           showMessage({
//             dispatch,
//             message: "Shared failed",
//             variant: "error",
//           });
//
//           return error;
//         } else {
//           console.log("response", response);
//           dispatch(rewardsRedeemVoucherSuccess(response));
//           // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
//           //     status: "applied"}))
//           // dispatch(getRecommendedList({user_id: type?.user_id}))
//           // dispatch(getJobDetails( {job_id: type?.job_id}))
//           // dispatch(closeApplyJob())
//           showMessage({
//             dispatch,
//             message: "Shared Successfully",
//             variant: "success",
//           });
//
//           return response;
//         }
//       }

export const rewardsRedeemVoucher = (payload) => {
  return (dispatch) => {
    dispatch(rewardsRedeemVoucherRequest());
    return http
      .post(apiUrl.REWARDS_REDEEM_VOUCHER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsRedeemVoucherSuccess(response));
          showMessage({
            dispatch,
            message: 'Purchased Successfully',
            variant: 'success',
          });
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsRedeemVoucherSuccess({ data: [] }));
            showMessage({
              dispatch,
              message: 'Purchased Successfully',
              variant: 'success',
            });
            return response;
          } else {
            dispatch(rewardsRedeemVoucherFailure(response));
            if (response?.message) {
              showMessage({
                dispatch,
                message: 'failed',
                variant: 'error',
              });
            } else {
              showMessage({
                dispatch,
                message: 'Purchased Successfully',
                variant: 'success',
              });
            }
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsRedeemVoucherFailure());
        return error;
      });
  };
};

export const REWARDS_GET_CREDITS_BY_ACTION_SUCCESS =
  'REWARDS_GET_CREDITS_BY_ACTION_SUCCESS';
export const REWARDS_GET_CREDITS_BY_ACTION_REQUEST =
  'REWARDS_GET_CREDITS_BY_ACTION_REQUEST';
export const REWARDS_GET_CREDITS_BY_ACTION_FAILURE =
  'REWARDS_GET_CREDITS_BY_ACTION_FAILURE';

const rewardsGetCreditsByActionsRequest = () => ({
  type: REWARDS_GET_CREDITS_BY_ACTION_REQUEST,
});

const rewardsGetCreditsByActionsFailure = (error) => ({
  type: REWARDS_GET_CREDITS_BY_ACTION_FAILURE,
  payload: { error: error },
});

const rewardsGetCreditsByActionsSuccess = (data) => ({
  type: REWARDS_GET_CREDITS_BY_ACTION_SUCCESS,
  payload: data,
});

export const rewardsGetCreditsByActions = (payload) => {
  return (dispatch) => {
    dispatch(rewardsGetCreditsByActionsRequest());
    return http
      .post(apiUrl.REWARDS_GET_CREDITS_BY_ACTION, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsGetCreditsByActionsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsGetCreditsByActionsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsGetCreditsByActionsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsGetCreditsByActionsFailure());
        return error;
      });
  };
};

export const REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_SUCCESS =
  'REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_SUCCESS';
export const REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_REQUEST =
  'REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_REQUEST';
export const REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_FAILURE =
  'REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_FAILURE';

const rewardsGetCreditsByApplauseRequest = () => ({
  type: REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_REQUEST,
});

const rewardsGetCreditsByApplauseFailure = (error) => ({
  type: REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_FAILURE,
  payload: { error: error },
});

const rewardsGetCreditsByApplauseSuccess = (data) => ({
  type: REWARDS_GET_CREDITS_BY_ACTION_APPLAUSE_SUCCESS,
  payload: data,
});

export const rewardsGetCreditsByApplause = (payload) => {
  return (dispatch) => {
    dispatch(rewardsGetCreditsByApplauseRequest());
    return http
      .post(apiUrl.REWARDS_GET_CREDITS_BY_ACTION, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsGetCreditsByApplauseSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsGetCreditsByApplauseSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsGetCreditsByApplauseFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsGetCreditsByApplauseFailure());
        return error;
      });
  };
};

export const REWARDS_NO_OF_AVAILABLE_SUCCESS =
  'REWARDS_NO_OF_AVAILABLE_SUCCESS';
export const REWARDS_NO_OF_AVAILABLE_REQUEST =
  'REWARDS_NO_OF_AVAILABLE_REQUEST';
export const REWARDS_NO_OF_AVAILABLE_FAILURE =
  'REWARDS_NO_OF_AVAILABLE_FAILURE';

const rewardsNoOfVoucherRequest = () => ({
  type: REWARDS_NO_OF_AVAILABLE_REQUEST,
});

const rewardsNoOfVoucherFailure = (error) => ({
  type: REWARDS_NO_OF_AVAILABLE_FAILURE,
  payload: { error: error },
});

const rewardsNoOfVoucherSuccess = (data) => ({
  type: REWARDS_NO_OF_AVAILABLE_SUCCESS,
  payload: data,
});

export const rewardsNoOfVoucher = (payload) => {
  return (dispatch) => {
    dispatch(rewardsNoOfVoucherRequest());
    return http
      .post(apiUrl.REWARDS_NO_AVAILABLE_COUPON, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.status === 1) {
          dispatch(rewardsNoOfVoucherSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsNoOfVoucherSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsNoOfVoucherFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsNoOfVoucherFailure());
        return error;
      });
  };
};

export const REWARDS_REALESTATE_LIST_SUCCESS =
  'REWARDS_REALESTATE_LIST_SUCCESS';
export const REWARDS_REALESTATE_LIST_REQUEST =
  'REWARDS_REALESTATE_LIST_REQUEST';
export const REWARDS_REALESTATE_LIST_FAILURE =
  'REWARDS_REALESTATE_LIST_FAILURE';

const rewardRealestateListRequest = () => ({
  type: REWARDS_REALESTATE_LIST_REQUEST,
});

const rewardRealestateListFailure = (error) => ({
  type: REWARDS_REALESTATE_LIST_FAILURE,
  payload: { error: error },
});

const rewardRealestateListSuccess = (data) => ({
  type: REWARDS_REALESTATE_LIST_SUCCESS,
  payload: data,
});

export const rewardRealestateList = (payload) => {
  return (dispatch) => {
    dispatch(rewardRealestateListRequest());
    return http
      .post(apiUrl.REWARDS_REALESTATE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardRealestateListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsVoucherListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardRealestateListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardRealestateListFailure());
        return error;
      });
  };
};

export const REWARDS_REALESTATE_FILTER_LIST_SUCCESS =
  'REWARDS_REALESTATE_FILTER_LIST_SUCCESS';
export const REWARDS_REALESTATE_FILTER_LIST_REQUEST =
  'REWARDS_REALESTATE_FILTER_LIST_REQUEST';
export const REWARDS_REALESTATE_FILTER_LIST_FAILURE =
  'REWARDS_REALESTATE_FILTER_LIST_FAILURE';

const rewardRealestateFilterListRequest = () => ({
  type: REWARDS_REALESTATE_FILTER_LIST_REQUEST,
});

const rewardRealestateFilterListFailure = (error) => ({
  type: REWARDS_REALESTATE_FILTER_LIST_FAILURE,
  payload: { error: error },
});

const rewardRealestateFilterListSuccess = (data) => ({
  type: REWARDS_REALESTATE_FILTER_LIST_SUCCESS,
  payload: data,
});

export const rewardRealestateFilterList = (payload) => {
  return (dispatch) => {
    dispatch(rewardRealestateFilterListRequest());
    return http
      .post(apiUrl.REWARDS_REALESTATE_FILTER_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardRealestateFilterListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardRealestateFilterListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardRealestateFilterListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardRealestateFilterListFailure());
        return error;
      });
  };
};

export const REWARDS_REALESTATE_LOCATION_FILTER_LIST_SUCCESS =
  'REWARDS_REALESTATE_LOCATION_FILTER_LIST_SUCCESS';
export const REWARDS_REALESTATE_LOCATION_FILTER_LIST_REQUEST =
  'REWARDS_REALESTATE_LOCATION_FILTER_LIST_REQUEST';
export const REWARDS_REALESTATE_LOCATION_FILTER_LIST_FAILURE =
  'REWARDS_REALESTATE_LOCATION_FILTER_LIST_FAILURE';

const rewardRealestateLocationFilterListRequest = () => ({
  type: REWARDS_REALESTATE_LOCATION_FILTER_LIST_REQUEST,
});

const rewardRealestateLocationFilterListFailure = (error) => ({
  type: REWARDS_REALESTATE_LOCATION_FILTER_LIST_FAILURE,
  payload: { error: error },
});

const rewardRealestateLocationFilterListSuccess = (data) => ({
  type: REWARDS_REALESTATE_LOCATION_FILTER_LIST_SUCCESS,
  payload: data,
});

export const rewardRealestateLocationFilterList = (payload) => {
  return (dispatch) => {
    dispatch(rewardRealestateLocationFilterListRequest());
    return http
      .post(apiUrl.REWARDS_REALESTATE_LOCATION_FILTER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardRealestateLocationFilterListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardRealestateLocationFilterListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardRealestateLocationFilterListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardRealestateLocationFilterListFailure());
        return error;
      });
  };
};

export const REWARDS_MEMBERSHIP_LIST_SUCCESS =
  'REWARDS_MEMBERSHIP_LIST_SUCCESS';
export const REWARDS_MEMBERSHIP_LIST_REQUEST =
  'REWARDS_MEMBERSHIP_LIST_REQUEST';
export const REWARDS_MEMBERSHIP_LIST_FAILURE =
  'REWARDS_MEMBERSHIP_LIST_FAILURE';

const rewardMemberListRequest = () => ({
  type: REWARDS_MEMBERSHIP_LIST_REQUEST,
});

const rewardMemberListFailure = (error) => ({
  type: REWARDS_MEMBERSHIP_LIST_FAILURE,
  payload: { error: error },
});

const rewardMemberListSuccess = (data) => ({
  type: REWARDS_MEMBERSHIP_LIST_SUCCESS,
  payload: data,
});

export const rewardMemberList = (payload) => {
  return (dispatch) => {
    dispatch(rewardMemberListRequest());
    return http
      .post(apiUrl.REWARDS_MEMBERSHIP_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardMemberListSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardMemberListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardMemberListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardMemberListFailure());
        return error;
      });
  };
};

export const REWARDS_MEMBERSHIP_DETAILS_SUCCESS =
  'REWARDS_MEMBERSHIP_DETAILS_SUCCESS';
export const REWARDS_MEMBERSHIP_DETAILS_REQUEST =
  'REWARDS_MEMBERSHIP_DETAILS_REQUEST';
export const REWARDS_MEMBERSHIP_DETAILS_FAILURE =
  'REWARDS_MEMBERSHIP_DETAILS_FAILURE';

const rewardMemberDetailsRequest = () => ({
  type: REWARDS_MEMBERSHIP_DETAILS_REQUEST,
});

const rewardMemberDetailsFailure = (error) => ({
  type: REWARDS_MEMBERSHIP_DETAILS_FAILURE,
  payload: { error: error },
});

const rewardMemberDetailsSuccess = (data) => ({
  type: REWARDS_MEMBERSHIP_DETAILS_SUCCESS,
  payload: data,
});

export const rewardMemberDetails = (payload) => {
  return (dispatch) => {
    dispatch(rewardMemberDetailsRequest());
    return http
      .post(apiUrl.REWARDS_MEMBERSHIP_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardMemberDetailsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardMemberDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardMemberDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardMemberDetailsFailure());
        return error;
      });
  };
};

export const CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_SUCCESS =
  'CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_SUCCESS';
export const CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_REQUEST =
  'CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_REQUEST';
export const CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_FAILURE =
  'CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_FAILURE';

const corporateMembershipDetailsRequest = () => ({
  type: CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_REQUEST,
});

const corporateMembershipDetailsFailure = (error) => ({
  type: CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_FAILURE,
  payload: { error: error },
});

const corporateMembershipDetailsSuccess = (data) => ({
  type: CORPORATE_MEMBERSHIP_PACKAGE_DETAILS_SUCCESS,
  payload: data,
});

export const corporateMembershipDetails = (payload) => {
  return (dispatch) => {
    dispatch(corporateMembershipDetailsRequest());
    return http
      .post(apiUrl.CORPORATE_MEMBERSHIP_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(corporateMembershipDetailsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporateMembershipDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(corporateMembershipDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporateMembershipDetailsFailure());
        return error;
      });
  };
};

export const REWARDS_MEMBERSHIP_BENEFITS_SUCCESS =
  'REWARDS_MEMBERSHIP_BENEFITS_SUCCESS';
export const REWARDS_MEMBERSHIP_BENEFITS_REQUEST =
  'REWARDS_MEMBERSHIP_BENEFITS_REQUEST';
export const REWARDS_MEMBERSHIP_BENEFITS_FAILURE =
  'REWARDS_MEMBERSHIP_BENEFITS_FAILURE';

const rewardMemberBenefitsRequest = () => ({
  type: REWARDS_MEMBERSHIP_BENEFITS_REQUEST,
});

const rewardMemberBenefitsFailure = (error) => ({
  type: REWARDS_MEMBERSHIP_BENEFITS_FAILURE,
  payload: { error: error },
});

const rewardMemberBenefitsSuccess = (data) => ({
  type: REWARDS_MEMBERSHIP_BENEFITS_SUCCESS,
  payload: data,
});

export const rewardMemberBenefits = (payload) => {
  return (dispatch) => {
    dispatch(rewardMemberBenefitsRequest());
    return http
      .post(apiUrl.REWARDS_MEMBERSHIP_BENEFITS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardMemberBenefitsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardMemberBenefitsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardMemberBenefitsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardMemberBenefitsFailure());
        return error;
      });
  };
};

export const CORPORATE_PACKAGES_SUCCESS = 'CORPORATE_PACKAGES_SUCCESS';
export const CORPORATE_PACKAGES_REQUEST = 'CORPORATE_PACKAGES_REQUEST';
export const CORPORATE_PACKAGES_FAILURE = 'CORPORATE_PACKAGES_FAILURE';

const corporatePackagesRequest = () => ({
  type: CORPORATE_PACKAGES_REQUEST,
});

const corporatePackagesFailure = (error) => ({
  type: CORPORATE_PACKAGES_FAILURE,
  payload: { error: error },
});

const corporatePackagesSuccess = (data) => ({
  type: CORPORATE_PACKAGES_SUCCESS,
  payload: data,
});

export const corporatePackages = (payload) => {
  return (dispatch) => {
    dispatch(corporatePackagesRequest());
    return http
      .post(apiUrl.CORPORATE_PACKAGES, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(corporatePackagesSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporatePackagesSuccess({ data: [] }));
            return response;
          } else {
            dispatch(corporatePackagesFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporatePackagesFailure());
        return error;
      });
  };
};

export const CORPORATE_MEMBERSHIP_SUCCESS = 'CORPORATE_MEMBERSHIP_SUCCESS';
export const CORPORATE_MEMBERSHIP_REQUEST = 'CORPORATE_MEMBERSHIP_REQUEST';
export const CORPORATE_MEMBERSHIP_FAILURE = 'CORPORATE_MEMBERSHIP_FAILURE';

const corporateMembershipRequest = () => ({
  type: CORPORATE_MEMBERSHIP_REQUEST,
});

const corporateMembershipFailure = (error) => ({
  type: CORPORATE_MEMBERSHIP_FAILURE,
  payload: { error: error },
});

const corporateMembershipSuccess = (data) => ({
  type: CORPORATE_MEMBERSHIP_SUCCESS,
  payload: data,
});

export const corporateMembership = (payload) => {
  return (dispatch) => {
    dispatch(corporateMembershipRequest());
    return http
      .post(apiUrl.CORPORATE_MEMBERSHIP, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(corporateMembershipSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporateMembershipSuccess({ data: [] }));
            return response;
          } else {
            dispatch(corporateMembershipFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporateMembershipFailure());
        return error;
      });
  };
};

export const CORPORATE_MEMBERSHIP_PRODUCT_SUCCESS =
  'CORPORATE_MEMBERSHIP_PRODUCT_SUCCESS';
export const CORPORATE_MEMBERSHIP_PRODUCT_REQUEST =
  'CORPORATE_MEMBERSHIP_PRODUCT_REQUEST';
export const CORPORATE_MEMBERSHIP_PRODUCT_FAILURE =
  'CORPORATE_MEMBERSHIP_PRODUCT_FAILURE';

const corporateMembershipProductRequest = () => ({
  type: CORPORATE_MEMBERSHIP_PRODUCT_REQUEST,
});

const corporateMembershipProductFailure = (error) => ({
  type: CORPORATE_MEMBERSHIP_PRODUCT_FAILURE,
  payload: { error: error },
});

const corporateMembershipProductSuccess = (data) => ({
  type: CORPORATE_MEMBERSHIP_PRODUCT_SUCCESS,
  payload: data,
});

export const corporateMembershipProduct = (payload) => {
  return (dispatch) => {
    dispatch(corporateMembershipProductRequest());
    return http
      .post(apiUrl.CORPORATE_MEMBERSHIP_PRODUCT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(corporateMembershipProductSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporateMembershipProductSuccess({ data: [] }));
            return response;
          } else {
            dispatch(corporateMembershipProductFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporateMembershipProductFailure());
        return error;
      });
  };
};

export const REWARDS_UPGRADE_MEMBERSHIP_PRICE_SUCCESS =
  'REWARDS_UPGRADE_MEMBERSHIP_PRICE_SUCCESS';
export const REWARDS_UPGRADE_MEMBERSHIP_PRICE_REQUEST =
  'REWARDS_UPGRADE_MEMBERSHIP_PRICE_REQUEST';
export const REWARDS_UPGRADE_MEMBERSHIP_PRICE_FAILURE =
  'REWARDS_UPGRADE_MEMBERSHIP_PRICE_FAILURE';

const rewardUpgradeMemberPriceRequest = () => ({
  type: REWARDS_UPGRADE_MEMBERSHIP_PRICE_REQUEST,
});

const rewardUpgradeMemberPriceFailure = (error) => ({
  type: REWARDS_UPGRADE_MEMBERSHIP_PRICE_FAILURE,
  payload: { error: error },
});

const rewardUpgradeMemberPriceSuccess = (data) => ({
  type: REWARDS_UPGRADE_MEMBERSHIP_PRICE_SUCCESS,
  payload: data,
});

export const rewardUpgradeMemberPrice = (payload) => {
  return (dispatch) => {
    dispatch(rewardUpgradeMemberPriceRequest());
    return http
      .post(apiUrl.REWARDS_UPGRADE_MEMBERSHIP_PRICE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardUpgradeMemberPriceSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardUpgradeMemberPriceSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardUpgradeMemberPriceFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardUpgradeMemberPriceFailure());
        return error;
      });
  };
};

export const REWARDS_USER_MEMBERSHIP_SUCCESS =
  'REWARDS_USER_MEMBERSHIP_SUCCESS';
export const REWARDS_USER_MEMBERSHIP_REQUEST =
  'REWARDS_USER_MEMBERSHIP_REQUEST';
export const REWARDS_USER_MEMBERSHIP_FAILURE =
  'REWARDS_USER_MEMBERSHIP_FAILURE';

const rewardUserMemberRequest = () => ({
  type: REWARDS_USER_MEMBERSHIP_REQUEST,
});

const rewardUserMemberFailure = (error) => ({
  type: REWARDS_USER_MEMBERSHIP_FAILURE,
  payload: { error: error },
});

const rewardUserMemberSuccess = (data) => ({
  type: REWARDS_USER_MEMBERSHIP_SUCCESS,
  payload: data,
});

export const rewardUserMember = (payload) => {
  return (dispatch) => {
    dispatch(rewardUserMemberRequest());
    return http
      .post(apiUrl.REWARDS_USER_MEMBERSHIP, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardUserMemberSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardUserMemberSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardUserMemberFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardUserMemberFailure());
        return error;
      });
  };
};

export const voucherDetailsOpen = (voucher) => {
  return {
    type: 'REWARDS_VOUCHER_DETAILS',
    payload: voucher,
  };
};

export const partnerVoucherOpen = (partner) => {
  return {
    type: 'REWARDS_PARTNER_VOUCHER',
    payload: partner,
  };
};

export const rewardsGetCreditsSetActions = (type) => {
  return {
    type: 'REWARDS_GET_CREDITS_SET_ACTION',
    payload: type,
  };
};

export const customCorporatePackageOpen = (pckg, from) => {
  return {
    type: 'CUSTOM_CORPORATE_PACKAGE_OPEN',
    payload: { package: pckg, from: from },
  };
};

export const customCorporatePackageClose = (reward) => {
  return {
    type: 'CUSTOM_CORPORATE_PACKAGE_CLOSE',
  };
};

export const openPackage = (pckg) => {
  return {
    type: 'OPENED_PACKAGE',
    payload: pckg,
  };
};

export const CORPORATE_CUSTOMISE_PRODUCT_SUCCESS =
  'CORPORATE_CUSTOMISE_PRODUCT_SUCCESS';
export const CORPORATE_CUSTOMISE_PRODUCT_REQUEST =
  'CORPORATE_CUSTOMISE_PRODUCT_REQUEST';
export const CORPORATE_CUSTOMISE_PRODUCT_FAILURE =
  'CORPORATE_CUSTOMISE_PRODUCT_FAILURE';

const corporateCutomiseProductRequest = () => ({
  type: CORPORATE_CUSTOMISE_PRODUCT_REQUEST,
});

const corporateCutomiseProductFailure = (error) => ({
  type: CORPORATE_CUSTOMISE_PRODUCT_FAILURE,
  payload: { error: error },
});

const corporateCutomiseProductSuccess = (data) => ({
  type: CORPORATE_CUSTOMISE_PRODUCT_SUCCESS,
  payload: data,
});

export const corporateCutomiseProduct = (payload) => {
  return (dispatch) => {
    dispatch(corporateCutomiseProductRequest());
    return http
      .post(apiUrl.CORPORATE_CUSTOMISE_PRODUCT, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(corporateCutomiseProductSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporateCutomiseProductSuccess({ data: [] }));
            return response;
          } else {
            dispatch(corporateCutomiseProductFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporateCutomiseProductFailure());
        return error;
      });
  };
};

export const CORPORATE_MEMBERSHIP_BENEFITS_SUCCESS =
  'CORPORATE_MEMBERSHIP_BENEFITS_SUCCESS';
export const CORPORATE_MEMBERSHIP_BENEFITS_REQUEST =
  'CORPORATE_MEMBERSHIP_BENEFITS_REQUEST';
export const CORPORATE_MEMBERSHIP_BENEFITS_FAILURE =
  'CORPORATE_MEMBERSHIP_BENEFITS_FAILURE';

const corporateMemberBenefitsRequest = () => ({
  type: CORPORATE_MEMBERSHIP_BENEFITS_REQUEST,
});

const corporateMemberBenefitsFailure = (error) => ({
  type: CORPORATE_MEMBERSHIP_BENEFITS_FAILURE,
  payload: { error: error },
});

const corporateMemberBenefitsSuccess = (data) => ({
  type: CORPORATE_MEMBERSHIP_BENEFITS_SUCCESS,
  payload: data,
});

export const corporateMemberBenefits = (payload) => {
  return (dispatch) => {
    dispatch(corporateMemberBenefitsRequest());
    return http
      .post(apiUrl.CORPORATE_MEMBERSHIP_BENEFITS, payload)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(corporateMemberBenefitsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporateMemberBenefitsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(corporateMemberBenefitsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporateMemberBenefitsFailure());
        return error;
      });
  };
};

export const CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_SUCCESS =
  'CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_SUCCESS';
export const CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_REQUEST =
  'CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_REQUEST';
export const CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_FAILURE =
  'CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_FAILURE';

const corporateMembershipProductDetailsRequest = () => ({
  type: CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_REQUEST,
});

const corporateMembershipProductDetailsFailure = (error) => ({
  type: CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_FAILURE,
  payload: { error: error },
});

const corporateMembershipProductDetailsSuccess = (data) => ({
  type: CORPORATE_MEMBERSHIP_PRODUCT_DETAILS_SUCCESS,
  payload: data,
});

export const corporateMembershipProductDetails = (payload) => {
  return (dispatch) => {
    dispatch(corporateMembershipProductDetailsRequest());
    return http
      .post(apiUrl.CORPORATE_MEMBERSHIP_PRODUCT_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(corporateMembershipProductDetailsSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporateMembershipProductDetailsSuccess({ data: [] }));
            return response;
          } else {
            dispatch(corporateMembershipProductDetailsFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporateMembershipProductDetailsFailure());
        return error;
      });
  };
};

export const CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS =
  'CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS';
export const CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST =
  'CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST';
export const CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE =
  'CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE';

const corporateMembershipPurchasedVoucherRequest = () => ({
  type: CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_REQUEST,
});

const corporateMembershipPurchasedVoucherFailure = (error) => ({
  type: CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_FAILURE,
  payload: { error: error },
});

const corporateMembershipPurchasedVoucherSuccess = (data) => ({
  type: CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER_SUCCESS,
  payload: data,
});

export const corporateMembershipPurchasedVoucher = (payload) => {
  return (dispatch) => {
    dispatch(corporateMembershipPurchasedVoucherRequest());
    return http
      .post(apiUrl.CORPORATE_MEMBERSHIP_PURCHASED_VOUCHER, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === 1) {
          dispatch(corporateMembershipPurchasedVoucherSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(corporateMembershipPurchasedVoucherSuccess({ data: [] }));

            return response;
          } else {
            dispatch(corporateMembershipPurchasedVoucherFailure(response));

            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(corporateMembershipPurchasedVoucherFailure());
        return error;
      });
  };
};

export const REWARDS_MEMBERSHIP_APPLY_COUPON_SUCCESS =
  'REWARDS_MEMBERSHIP_APPLY_COUPON_SUCCESS';
export const REWARDS_MEMBERSHIP_APPLY_COUPON_REQUEST =
  'REWARDS_MEMBERSHIP_APPLY_COUPON_REQUEST';
export const REWARDS_MEMBERSHIP_APPLY_COUPON_FAILURE =
  'REWARDS_MEMBERSHIP_APPLY_COUPON_FAILURE';

const rewardsMembershipApplyCouponRequest = () => ({
  type: REWARDS_MEMBERSHIP_APPLY_COUPON_REQUEST,
});

const rewardsMembershipApplyCouponFailure = (error) => ({
  type: REWARDS_MEMBERSHIP_APPLY_COUPON_FAILURE,
  payload: { error: error },
});

const rewardsMembershipApplyCouponSuccess = (data) => ({
  type: REWARDS_MEMBERSHIP_APPLY_COUPON_SUCCESS,
  payload: data,
});

export const rewardsMembershipApplyCoupon = (payload) => {
  return (dispatch) => {
    dispatch(rewardsMembershipApplyCouponRequest());
    return http
      .post(apiUrl.REWARDS_MEMBERSHIP_APPLY_COUPON, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === 1) {
          dispatch(rewardsMembershipApplyCouponSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsMembershipApplyCouponSuccess({ data: [] }));
            return response;
          } else {
            showMessage({
              dispatch,
              message: response?.data?.message,
              variant: 'error',
            });
            dispatch(rewardsMembershipApplyCouponFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        showMessage({
          dispatch,
          message: error?.data?.message,
          variant: 'error',
        });
        dispatch(rewardsMembershipApplyCouponFailure());
        return error;
      });
  };
};



export const REWARDS_REQUEST_NEW_VOUCHER_SUCCESS =
  'REWARDS_REQUEST_NEW_VOUCHER_SUCCESS';
export const REWARDS_REQUEST_NEW_VOUCHER_REQUEST =
  'REWARDS_REQUEST_NEW_VOUCHER_REQUEST';
export const REWARDS_REQUEST_NEW_VOUCHER_FAILURE =
  'REWARDS_REQUEST_NEW_VOUCHER_FAILURE';

const rewardsRequestNewVoucherRequest = () => ({
  type: REWARDS_REQUEST_NEW_VOUCHER_REQUEST,
});

const rewardsRequestNewVoucherFailure = (error) => ({
  type: REWARDS_REQUEST_NEW_VOUCHER_FAILURE,
  payload: { error: error },
});

const rewardsRequestNewVoucherSuccess = (data) => ({
  type: REWARDS_REQUEST_NEW_VOUCHER_SUCCESS,
  payload: data,
});

export const rewardsRequestNewVoucher = (payload) => {
  return (dispatch) => {
    dispatch(rewardsRequestNewVoucherRequest());
    return http
      .post(apiUrl.REWARDS_REQUEST_NEW_VOUCHER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(rewardsRequestNewVoucherSuccess(response));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(rewardsRequestNewVoucherSuccess({ data: [] }));
            return response;
          } else {
            dispatch(rewardsRequestNewVoucherFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(rewardsRequestNewVoucherFailure());
        return error;
      });
  };
};

export const CLOSE_REWARDS_MESSAGE = 'CLOSE_REWARDS_MESSAGE';
export const OPEN_REWARDS_MESSAGE = 'OPEN_REWARDS_MESSAGE';

export const closeRewardMessage = () => ({
  type: 'CLOSE_REWARDS_MESSAGE',
});

export const openRewardMessage = (type, data) => {
  return {
    type: 'OPEN_REWARDS_MESSAGE',
    payload: { type: type, data: data },
  };
};