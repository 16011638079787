import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import RewardSubHeader from '../../components/RewardSubHeader/RewardSubHeader';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import RewardPartnerVoucherCard from '../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as rewardsActions from '../../store/rewards';
import CxoButton from '../../components/CxoButton/CxoButton';
import credits_gold from '../../assets/header/Credits_Gold.png';
import { dateTime } from '../../constants/app';
import * as messageActions from '../../store/message';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  // marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const Voucher = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(3);

  const user = useSelector((state) => state?.user?.UserDetails);
  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );
  const membershipDetails = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );

  useEffect(async () => {
   const id = params?.company?.split('-');
    const data = {
      partner_id: id[id?.length - 1].substring(2),
      datetime: dateTime(),
    };
    const rewardVouchelist = await dispatch(
      rewardsActions.rewardsVoucherList(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      partner_id: rewardsPartnerVoucher?.id,
      datetime: dateTime(),
    };
    const rewardVouchelist = await dispatch(
      rewardsActions.rewardsVoucherList(data),
    );
  }, []);
  useEffect(async () => {
    const rewardscreditslist = await dispatch(
      rewardsActions.rewardsGetCreditsList(),
    );
  }, []);
  const handleChange = async (event, newValue) => {
    if (user?.isVerified) {
      if (newValue === 0) {
        navigate('/rewards/membership/details/Basic');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/rewards/membership/details/Premium');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/membership/details/Elite');
        setValue(newValue);
      } else if (newValue === 3) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 0) {
        navigate('/rewards/membership/details/Basic');
        setValue(val);
      } else if (val === 1) {
        navigate('/rewards/membership/details/Premium');
        setValue(val);
      } else if (val === 2) {
        navigate('/rewards/membership/details/Elite');
        setValue(val);
      } else if (val === 3) {
        navigate('/rewards/category/partners');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const creditsDetails = () => {
    navigate('/rewards/credits');
  };

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  const subscriptionType = () => {
    if (user?.membership_type === 0) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 1) {
      return (
        <Stack direction="row" spacing="2">
          <StarIcon sx={{ fontSize: '12px', color: '#38CD84' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#38CD84',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Basic
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 2) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#C0C0C0' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#C0C0C0',
              fontSize: '12px',
              fontWeight: '400',

              fontFamily: 'Open Sans',
            }}>
            Silver
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 3) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');

      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#FFC107' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#FFC107',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Gold
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    } else if (user?.membership_type === 4) {
      let datev = moment(
        moment
          .utc(membershipDetails[0]?.buyDate, 'YYYY-MM-DDTHH:mm:ssZ')
          .add(membershipDetails[0]?.expiry, 'days'),
      ).format('ll');
      return (
        <Stack direction="row" spacing="1">
          <StarIcon sx={{ fontSize: '12px', color: '#A0B2C6' }} />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#A0B2C6',
              fontSize: '12px',

              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Platinum
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#ffffff',
              fontSize: '12px',
              paddingLeft: '8px',
              fontWeight: '400',
              fontFamily: 'Open Sans',
            }}>
            Valid Till - {datev}
          </Typography>
        </Stack>
      );
    }
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Stack
                    direction="row"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '48px',
                        maxWidth: 'min-content',
                        width: '48px',
                      }}>
                      <img alt="creditgold" src={credits_gold} />
                    </Box>

                    <Stack
                      direction="column"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        width: '100%',
                      }}>
                      <Stack direction="row" sx={{ width: '100%' }}>
                        <Box pt={1} pb={0.5} px={1}>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              color: '#F5BD02',
                              fontSize: '20px',
                              lineHeight: '27px',
                              fontWeight: '600',
                              fontFamily: 'Open Sans',
                            }}>
                            {rewardsTotalCredits?.credits
                              ? parseInt(
                                  rewardsTotalCredits?.credits,
                                )?.toLocaleString()
                              : parseInt(
                                  rewardsTotalCredits?.credits,
                                )?.toLocaleString()}
                          </Typography>
                        </Box>
                        <Box
                          pt={1}
                          pb={0.5}
                          px={1}
                          sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="h2" component="h2" sx={{}}>
                            Credits
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing="1">
                        <Box sx={{ marginLeft: '8px' }}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{ color: '#ffffff' }}>
                            Membership Id:
                          </Typography>
                        </Box>
                        <Box pl={1}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{
                              color: '#38CD84',
                              fontFamily: 'Open Sans',
                              fontSize: '12px',
                            }}>
                            {user?.subscription_id}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButton label="Details" onClick={creditsDetails} />
                </Box>
              </Stack>
            </Paper>
            <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Membership
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5} >
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Vouchers
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box>

            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <RewardSubHeader />
            {/* <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Stack
                    direction="row"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '48px',
                        maxWidth: 'min-content',
                        width: '48px',
                      }}>
                      <img alt="creditgold" src={credits_gold} />
                    </Box>

                    <Stack
                      direction="column"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        width: '100%',
                      }}>
                      <Stack direction="row" sx={{ width: '100%' }}>
                        <Box pt={1} pb={0.5} px={1}>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              color: '#F5BD02',
                              fontSize: '20px',
                              lineHeight: '27px',
                              fontWeight: '600',
                              fontFamily: 'Open Sans',
                            }}>
                            {rewardsTotalCredits?.credits
                              ? parseInt(
                                  rewardsTotalCredits?.credits,
                                )?.toLocaleString()
                              : parseInt(
                                  rewardsTotalCredits?.credits,
                                )?.toLocaleString()}
                          </Typography>
                        </Box>
                        <Box
                          pt={1}
                          pb={0.5}
                          px={1}
                          sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="h2" component="h2" sx={{}}>
                            Credits
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing="1">
                        <Box sx={{ marginLeft: '8px' }}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{ color: '#ffffff' }}>
                            Membership Id:
                          </Typography>
                        </Box>
                        <Box pl={1}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.tabname}
                            sx={{
                              color: '#38CD84',
                              fontFamily: 'Open Sans',
                              fontSize: '12px',
                            }}>
                            {user?.subscription_id}
                          </Typography>
                        </Box>
                      </Stack>
                      <Box
                        sx={{ width: 'inherit', paddingTop: '8px' }}
                        direction="row"
                        spacing="1">
                        <Box sx={{ marginLeft: '8px' }}>
                          {subscriptionType()}
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>

                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButton label="Details" onClick={creditsDetails} />
                </Box>
              </Stack>
            </Paper> */}
            {/* <Box px={0} py={1}>
              <Tabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                color="white"
                aria-label="voucher">
                <Paper
                  sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
                  <StyledTabsList sx={{ padding: '8px 16px' }}>
                    <StyledTab onClick={() => changeTab(0)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
                          Membership
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 0 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(1)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
                          Partners
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 1 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                    <StyledTab onClick={() => changeTab(2)}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
                          Vouchers
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabdigit}
                          sx={{
                            color: value === 2 ? '#000000' : '#ffffff',
                          }}></Typography>
                      </Stack>
                    </StyledTab>
                  </StyledTabsList>
                </Paper>
              </Tabs>
            </Box> */}

            
  <Box px={0} py={1}>
  <Tabs
    defaultValue={0}
    value={value}
    onChange={handleChange}
    variant="fullWidth"
    color="white"
    aria-label="voucher">
    <Paper
      sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
      <StyledTabsList sx={{ padding: '8px 16px' }}>
        <StyledTab onClick={() => changeTab(0)}>
          <Stack direction="row" spacing={0.5}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabname}
              sx={{ color: value === 0 ? '#000000' : '#ffffff' }}>
              Basic
            </Typography>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabdigit}
              sx={{
                color: value === 0 ? '#000000' : '#ffffff',
              }}></Typography>
          </Stack>
        </StyledTab>

        <StyledTab onClick={() => changeTab(1)}>
          <Stack direction="row" spacing={0.5}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabname}
              sx={{ color: value === 1 ? '#000000' : '#ffffff' }}>
              Premium
            </Typography>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabdigit}
              sx={{
                color: value === 1 ? '#000000' : '#ffffff',
              }}></Typography>
          </Stack>
        </StyledTab>

        <StyledTab onClick={() => changeTab(2)}>
          <Stack direction="row" spacing={0.5}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabname}
              sx={{ color: value === 2 ? '#000000' : '#ffffff' }}>
              Elite
            </Typography>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabdigit}
              sx={{
                color: value === 2 ? '#000000' : '#ffffff',
              }}></Typography>
          </Stack>
        </StyledTab>

        <StyledTab onClick={() => changeTab(3)}>
          <Stack direction="row" spacing={0.5} onClick={() => changeTab(3)}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabname}
              sx={{ color: value === 3 ? '#000000' : '#ffffff' }}>
              Partners
            </Typography>
            <Typography
              variant="h5"
              component="h5"
              className={classes.tabdigit}
              sx={{
                color: value === 3 ? '#000000' : '#ffffff',
              }}></Typography>
          </Stack>
        </StyledTab>
      </StyledTabsList>
    </Paper>
  </Tabs>
</Box>

            <Fragment>
              <Box>
                <Box sx={{ width: '100%' }}>
                  <Box>
                    <Grid container>
                      {rewardsVoucherList?.length > 0 &&
                        rewardsVoucherList?.map((list, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={6}
                              sx={{
                                paddingRight: index % 2 === 0 ? '8px' : '0px',
                                marginTop: '8px',
                              }}>
                              <RewardPartnerVoucherCard
                                voucher={list}
                                partner={rewardsPartnerVoucher}
                                type="voucher"
                                status="partnerVoucher"
                              />
                            </Grid>
                          );
                        })}
                      {rewardsVoucherList?.length === 0 && (
                        <Box pl={2}>
                          <Typography variant="h5" component="h5">
                            There is no Voucher.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(Voucher);

// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab>
