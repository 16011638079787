import { API, apiUrl } from '../../services';
import { http } from '../../services/client';
import { showMessage } from '../message';
import {
  getAccessToken,
} from '../../services/authData';

export const GET_JOBS_LIST_SUCCESS = 'GET_JOBS_LIST_SUCCESS';
export const GET_JOBS_LIST_REQUEST = 'GET_JOBS_LIST_REQUEST';
export const GET_JOBS_LIST_FAILURE = 'GET_JOBS_LIST_FAILURE';
export const GET_OPEN_JOBS_LIST_SUCCESS = 'GET_OPEN_JOBS_LIST_SUCCESS';
export const GET_INACTIVE_JOBS_LIST_SUCCESS = 'GET_INACTIVE_JOBS_LIST_SUCCESS';
export const GET_CLOSED_JOBS_LIST_SUCCESS = 'GET_CLOSED_JOBS_LIST_SUCCESS';
export const GET_JOBS_LIST_INACTIVE_REQUEST = 'GET_JOBS_LIST_INACTIVE_REQUEST';
export const GET_JOBS_LIST_INACTIVE_FAILURE = 'GET_JOBS_LIST_INACTIVE_FAILURE';
export const GET_JOBS_LIST_CLOSED_REQUEST = 'GET_JOBS_LIST_CLOSED_REQUEST';
export const GET_JOBS_LIST_ClOSED_FAILURE = 'GET_JOBS_LIST_ClOSED_FAILURE';

const getJobsListRequest = (payload) => ({
  type: GET_JOBS_LIST_REQUEST,
  payload: { payld: payload },
});

const getJobsListFailure = (error) => ({
  type: GET_JOBS_LIST_FAILURE,
  payload: { error: error },
});

const getJobsListSuccess = (data, payload) => {
  return {
    type: 'GET_OPEN_JOBS_LIST_SUCCESS',
    payload: { jobs: data, payld: payload },
  };
  // if (payload?.type === "open") {
  //   return {
  //     type: "GET_OPEN_JOBS_LIST_SUCCESS",
  //     payload: { jobs: data },
  //   };
  // } else if (payload?.type === "inactive") {
  //   return {
  //     type: "GET_INACTIVE_JOBS_LIST_SUCCESS",
  //     payload: { jobs: data },
  //   };
  // } else if (payload?.type === "closed") {
  //   return {
  //     type: "GET_CLOSED_JOBS_LIST_SUCCESS",
  //     payload: { jobs: data },
  //   };
  // }
};

const getJobsListInactiveRequest = (payload) => ({
  type: GET_JOBS_LIST_INACTIVE_REQUEST,
  payload: { payld: payload },
});

const getJobsListInactiveFailure = (error) => ({
  type: GET_JOBS_LIST_INACTIVE_FAILURE,
  payload: { error: error },
});

const getJobsListInactiveSuccess = (data, payload) => {
  return {
    type: 'GET_INACTIVE_JOBS_LIST_SUCCESS',
    payload: { jobs: data, payld: payload },
  };
};

const getJobsListCloseSuccess = (data, payload) => {
  console.log('getJobsListSuccess', data, payload);
  return {
    type: 'GET_CLOSED_JOBS_LIST_SUCCESS',
    payload: { jobs: data, payld: payload },
  };
};

const getJobsListClosedRequest = (payload) => ({
  type: GET_JOBS_LIST_CLOSED_REQUEST,
  payload: { payld: payload },
});

const getJobsListClosedFailure = (error) => ({
  type: GET_JOBS_LIST_ClOSED_FAILURE,
  payload: { error: error },
});

export const getJobsListClose = (payload) => {
  return (dispatch) => {
    dispatch(getJobsListClosedRequest(payload));
    return http
      .post(apiUrl.JOBS_LIST, payload)
      .then((response) => {
        console.log('response', response);
        return response;
      })
      .then((response) => {
        console.log('response', response);
        if (response?.status === 1) {
          dispatch(getJobsListCloseSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getJobsListCloseSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getJobsListClosedFailure(response, payload));
            return response;
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        // dispatch(getJobsListFailure(error));
        // return error;
      });
  };
};
export const getJobsListInactive = (payload) => {
  return (dispatch) => {
    dispatch(getJobsListInactiveRequest(payload));
    return http
      .post(apiUrl.JOBS_LIST, payload)
      .then((response) => {
        console.log('response', response);
        return response;
      })
      .then((response) => {
        console.log('response', response);
        if (response?.status === 1) {
          dispatch(getJobsListInactiveSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getJobsListInactiveSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getJobsListInactiveFailure(response, payload));
            return response;
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        // dispatch(getJobsListFailure(error));
        // return error;
      });
  };
};

export const getJobsList = (payload) => {
  return (dispatch) => {
    dispatch(getJobsListRequest(payload));
    return http
      .post(apiUrl.JOBS_LIST, payload)
      .then((response) => {
        console.log('response', response);
        return response;
      })
      .then((response) => {
        console.log('response', response);
        if (response?.status === 1) {
          dispatch(getJobsListSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getJobsListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getJobsListFailure(response, payload));
            return response;
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        // dispatch(getJobsListFailure(error));
        // return error;
      });
  };
};

export const GET_RECOMMENDED_JOB_LIST_SUCCESS =
  'GET_RECOMMENDED_JOB_LIST_SUCCESS';
export const GET_RECOMMENDED_JOB_LIST_REQUEST =
  'GET_RECOMMENDED_JOB_LIST_REQUEST';
export const GET_RECOMMENDED_JOB_LIST_FAILURE =
  'GET_RECOMMENDED_JOB_LIST_FAILURE';

const getRecommendedListRequest = (payld) => ({
  type: GET_RECOMMENDED_JOB_LIST_REQUEST,
  payload: { payld: payld },
});

const getRecommendedListFailure = (error) => ({
  type: GET_RECOMMENDED_JOB_LIST_FAILURE,
  payload: { error: error },
});

const getRecommendedListSuccess = (data, payld) => ({
  type: GET_RECOMMENDED_JOB_LIST_SUCCESS,
  payload: { jobs: data, payld: payld },
});

export const getRecommendedList = (payload) => {
  return (dispatch) => {
    dispatch(getRecommendedListRequest(payload));
    return http
      .post(apiUrl.JOBS_RECOMMENDED_JOB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getRecommendedListSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getRecommendedListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(getRecommendedListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getRecommendedListFailure());
        return error;
      });
  };
};

export const GET_JOB_LIST_BY_STATUS_APPLIED_SUCCESS =
  'GET_JOB_LIST_BY_STATUS_APPLIED_SUCCESS';
export const GET_JOB_LIST_BY_STATUS_APPLIED_REQUEST =
  'GET_JOB_LIST_BY_STATUS_APPLIED_FAILURE';
export const GET_JOB_LIST_BY_STATUS_APPLIED_FAILURE =
  'GET_JOB_LIST_BY_STATUS_FAILURE';
export const GET_JOB_LIST_BY_STATUS_SAVED_SUCCESS =
  'GET_JOB_LIST_BY_STATUS_SAVED_SUCCESS';
export const GET_JOB_LIST_BY_STATUS_SAVED_REQUEST =
  'GET_JOB_LIST_BY_STATUS_SAVED_REQUEST';
export const GET_JOB_LIST_BY_STATUS_SAVED_FAILURE =
  'GET_JOB_LIST_BY_STATUS_SAVED_FAILURE';
export const GET_JOB_LIST_BY_STATUS_REQUEST = 'GET_JOB_LIST_BY_STATUS_REQUEST';
export const GET_JOB_LIST_BY_STATUS_FAILURE = 'GET_JOB_LIST_BY_STATUS_FAILURE';

const getJobListByStatusRequest = () => ({
  type: GET_JOB_LIST_BY_STATUS_APPLIED_REQUEST,
});

const getJobListByStatusFailure = (error) => ({
  type: GET_JOB_LIST_BY_STATUS_APPLIED_FAILURE,
  payload: { error: error },
});

const getJobListByStatusAppliedRequest = (payld) => ({
  type: GET_JOB_LIST_BY_STATUS_APPLIED_REQUEST,
  payload: { payld: payld },
});

export const getJobListByStatusApplied = (payload, status) => {
  return (dispatch) => {
    dispatch(getJobListByStatusAppliedRequest(payload));
    return http
      .post(apiUrl.JOB_LIST_APPLIED, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobListByStatusSuccessApplied(response, payload, status));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getJobListByStatusSuccessApplied({ data: [] }, payload));
            return response;
          } else {
            dispatch(getJobListByStatusFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getJobListByStatusFailure());
        return error;
      });
  };
};

const getJobListByStatusSavedRequest = () => ({
  type: GET_JOB_LIST_BY_STATUS_SAVED_REQUEST,
});

const getJobListByStatusSavedFailure = (error) => ({
  type: GET_JOB_LIST_BY_STATUS_SAVED_FAILURE,
  payload: { error: error },
});

const getJobListByStatusSuccessApplied = (data, payload, status) => {
  return {
    type: GET_JOB_LIST_BY_STATUS_APPLIED_SUCCESS,
    payload: { jobs: data, payld: payload },
  };
};

const getJobListByStatusSuccessSaved = (data, payload, status) => {
  return {
    type: GET_JOB_LIST_BY_STATUS_SAVED_SUCCESS,
    payload: { jobs: data, payld: payload },
  };
};

export const getJobListByStatusSaved = (payload, status) => {
  return (dispatch) => {
    dispatch(getJobListByStatusSavedRequest());
    return http
      .post(apiUrl.JOB_LIST_BY_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobListByStatusSuccessSaved(response, payload, status));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getJobListByStatusSuccessSaved({ data: [] }, payload));
            return response;
          } else {
            dispatch(getJobListByStatusSavedFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getJobListByStatusFailure());
        return error;
      });
  };
};

export const getJobListByStatus = (payload, status) => {
  return (dispatch) => {
    dispatch(getJobListByStatusRequest());
    return http
      .post(apiUrl.JOB_LIST_BY_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobListByStatusSuccessSaved(response, status));
          return response;
        } else {
          dispatch(getJobListByStatusFailure(response, status));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobListByStatusFailure());
        return error;
      });
  };
};

export const JOB_DELETE_MESSAGE_SUCCESS = 'JOB_DELETE_MESSAGE_SUCCESS';
export const JOB_DELETE_MESSAGE_REQUEST = 'JOB_DELETE_MESSAGE_REQUEST';
export const JOB_DELETE_MESSAGE_FAILURE = 'JOB_DELETE_MESSAGE_FAILURE';

const jobMessageDeleteRequest = () => ({
  type: JOB_DELETE_MESSAGE_REQUEST,
});

const jobMessageDeleteFailure = (error) => ({
  type: JOB_DELETE_MESSAGE_FAILURE,
  payload: { error: error },
});

const jobMessageDeleteSuccess = (data) => ({
  type: JOB_DELETE_MESSAGE_SUCCESS,
  payload: { job: data },
});

export const jobMessageDelete = (payload) => {
  return (dispatch) => {
    dispatch(jobMessageDeleteRequest());
    return http
      .post(apiUrl.JOB_DELETE_MESSAGE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(jobMessageDeleteSuccess(response));
          return response;
        } else {
          dispatch(jobMessageDeleteFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(jobMessageDeleteFailure(error));
        return error;
      });
  };
};

export const MANAGE_JOB_STATUS_SUCCESS = 'MANAGE_JOB_STATUS_SUCCESS';
export const MANAGE_JOB_STATUS_REQUEST = 'MANAGE_JOB_STATUS_REQUEST';
export const MANAGE_JOB_STATUS_FAILURE = 'MANAGE_JOB_STATUS_FAILURE';

const manageJobStatusRequest = () => ({
  type: MANAGE_JOB_STATUS_REQUEST,
});

const manageJobStatusFailure = (error) => ({
  type: MANAGE_JOB_STATUS_FAILURE,
  payload: { error: error },
});

const manageJobStatusSuccess = (data) => ({
  type: MANAGE_JOB_STATUS_SUCCESS,
  payload: { job: data },
});

export const manageJobStatus = (payload) => {
  return (dispatch) => {
    dispatch(manageJobStatusRequest());
    return http
      .post(apiUrl.MANAGE_JOB_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(manageJobStatusSuccess(response));
          return response;
        } else {
          dispatch(manageJobStatusFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(manageJobStatusFailure(error));
        return error;
      });
  };
};

export const REPORT_JOB_STATUS_SUCCESS = 'REPORT_JOB_STATUS_SUCCESS';
export const REPORT_JOB_STATUS_REQUEST = 'REPORT_JOB_STATUS_REQUEST';
export const REPORT_JOB_STATUS_FAILURE = 'REPORT_JOB_STATUS_FAILURE';

const reportJobRequest = () => ({
  type: REPORT_JOB_STATUS_REQUEST,
});

const reportJobFailure = (error) => ({
  type: REPORT_JOB_STATUS_FAILURE,
  payload: { error: error },
});

const reportJobSuccess = (data) => ({
  type: REPORT_JOB_STATUS_SUCCESS,
  payload: { job: data },
});

export const reportJob = (payload) => {
  return (dispatch) => {
    dispatch(reportJobRequest());
    return http
      .post(apiUrl.REPORT_JOB, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(reportJobSuccess(response));
          return response;
        } else {
          dispatch(reportJobFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(reportJobFailure(error));
        return error;
      });
  };
};

export const MANAGE_CONTENT_SHARE_STATUS_SUCCESS =
  'MANAGE_CONTENT_SHARE_STATUS_SUCCESS';
export const MANAGE_CONTENT_SHARE_STATUS_REQUEST =
  'MANAGE_CONTENT_SHARE_STATUS_REQUEST';
export const MANAGE_CONTENT_SHARE_STATUS_FAILURE =
  'MANAGE_CONTENT_SHARE_STATUS_FAILURE';

const manageContentShareRequest = () => ({
  type: MANAGE_CONTENT_SHARE_STATUS_REQUEST,
});

const manageContentShareFailure = (error) => ({
  type: MANAGE_CONTENT_SHARE_STATUS_FAILURE,
  payload: { error: error },
});

const manageContentShareSuccess = (data) => ({
  type: MANAGE_CONTENT_SHARE_STATUS_SUCCESS,
  payload: { job: data },
});

export const manageContentShare = (payload, callback) => {
  return (dispatch) => {
    dispatch(manageContentShareRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.MANAGE_CONTENT_SHARE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(manageContentShareFailure(error));
          showMessage({
            dispatch,
            message: 'Shared failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(manageContentShareSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          showMessage({
            dispatch,
            message: 'Shared Successfully',
            variant: 'success',
          });
          callback(null, response);
          return response;
        }
      },
    );
  };
};

export const JOB_MANAGE_PREFERENCE_SUCCESS = 'JOB_MANAGE_PREFERENCE_SUCCESS';
export const JOB_MANAGE_PREFERENCE_REQUEST = 'JOB_MANAGE_PREFERENCE_REQUEST';
export const JOB_MANAGE_PREFERENCE_FAILURE = 'JOB_MANAGE_PREFERENCE_FAILURE';

const manageJobPreferenceRequest = () => ({
  type: JOB_MANAGE_PREFERENCE_REQUEST,
});

const manageJobPreferenceFailure = (error) => ({
  type: JOB_MANAGE_PREFERENCE_FAILURE,
  payload: { error: error },
});

const manageJobPreferenceSuccess = (data) => ({
  type: JOB_MANAGE_PREFERENCE_SUCCESS,
  payload: { job: data },
});

export const manageJobPreference = (payload, callback) => {
  return (dispatch) => {
    dispatch(manageJobPreferenceRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.JOB_MANAGE_PREFERENCE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          console.log('error', error);
          dispatch(manageJobPreferenceFailure(error));
          showMessage({
            dispatch,
            message: 'Job Preference Update failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(manageJobPreferenceSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          showMessage({
            dispatch,
            message: 'Job Preference Updated',
            variant: 'success',
          });
          callback(null, response);
          return response;
        }
      },
    );
  };
};



export const USER_CHALLENGE_JOB_MANAGE_PREFERENCE_SUCCESS = 'USER_CHALLENGE_JOB_MANAGE_PREFERENCE_SUCCESS';
export const USER_CHALLENGE_JOB_MANAGE_PREFERENCE_REQUEST = 'USER_CHALLENGE_JOB_MANAGE_PREFERENCE_REQUEST';
export const USER_CHALLENGE_JOB_MANAGE_PREFERENCE_FAILURE = 'USER_CHALLENGE_JOB_MANAGE_PREFERENCE_FAILURE';

const userChallengeManageJobPreferenceRequest = () => ({
  type: USER_CHALLENGE_JOB_MANAGE_PREFERENCE_REQUEST,
});

const userChallengeManageJobPreferenceFailure = (error) => ({
  type: USER_CHALLENGE_JOB_MANAGE_PREFERENCE_FAILURE,
  payload: { error: error },
});

const userChallengeManageJobPreferenceSuccess = (data) => ({
  type: USER_CHALLENGE_JOB_MANAGE_PREFERENCE_SUCCESS,
  payload: { job: data },
});

export const userChallengeManageJobPreference = (payload, callback) => {
  return (dispatch) => {
    dispatch(userChallengeManageJobPreferenceRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.USER_CHALLENGE_JOB_MANAGE_PREFERENCE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          console.log('error', error);
          dispatch(userChallengeManageJobPreferenceFailure(error));
          showMessage({
            dispatch,
            message: 'Update failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          dispatch(userChallengeManageJobPreferenceSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          showMessage({
            dispatch,
            message: 'Updated',
            variant: 'success',
          });
          callback(null, response);
          return response;
        }
      },
    );
  };
};


export const JOB_SEND_MESSAGE_SUCCESS = 'JOB_SEND_MESSAGE_SUCCESS';
export const JOB_SEND_MESSAGE_REQUEST = 'JOB_SEND_MESSAGE_REQUEST';
export const JOB_SEND_MESSAGE_FAILURE = 'JOB_SEND_MESSAGE_FAILURE';

const jobSendMessageStatusRequest = () => ({
  type: JOB_SEND_MESSAGE_REQUEST,
});

const jobSendMessageStatusFailure = (error) => ({
  type: JOB_SEND_MESSAGE_FAILURE,
  payload: { error: error },
});

const jobSendMessageStatusSuccess = (data) => ({
  type: JOB_SEND_MESSAGE_SUCCESS,
  payload: { job: data },
});

export const jobSendMessageStatus = (payload) => {
  return (dispatch) => {
    dispatch(jobSendMessageStatusRequest());
    return http
      .post(apiUrl.JOB_SEND_MESSAGE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(jobSendMessageStatusSuccess(response));
          return response;
        } else {
          dispatch(jobSendMessageStatusFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(jobSendMessageStatusFailure(error));
        return error;
      });
  };
};

export const GET_SIMILAR_JOB_LIST_SUCCESS = 'GET_SIMILAR_JOB_LIST_SUCCESS';
export const GET_SIMILAR_JOB_LIST_REQUEST = 'GET_SIMILAR_JOB_LIST_REQUEST';
export const GET_SIMILAR_JOB_LIST_FAILURE = 'GET_SIMILAR_JOB_LIST_FAILURE';

const getSimilarListRequest = () => ({
  type: GET_SIMILAR_JOB_LIST_REQUEST,
});

const getSimilarListFailure = (error) => ({
  type: GET_SIMILAR_JOB_LIST_FAILURE,
  payload: { error: error },
});

const getSimilarListSuccess = (data) => ({
  type: GET_SIMILAR_JOB_LIST_SUCCESS,
  payload: { jobs: data },
});

export const getSimilarList = (payload) => {
  return (dispatch) => {
    dispatch(getSimilarListRequest());
    return http
      .post(apiUrl.SIMILAR_JOB_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getSimilarListSuccess(response));
          return response;
        } else {
          dispatch(getSimilarListFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getRecommendedListFailure(error));
        return error;
      });
  };
};

export const JOB_MANAGE_SUCCESS = 'JOB_MANAGE_SUCCESS';
export const JOB_MANAGE_REQUEST = 'JOB_MANAGE_REQUEST';
export const JOB_MANAGE_FAILURE = 'JOB_MANAGE_FAILURE';

const manageJobRequest = () => ({
  type: JOB_MANAGE_REQUEST,
});

const manageJobFailure = (error) => ({
  type: JOB_MANAGE_FAILURE,
  payload: { error: error },
});

const manageJobSuccess = (data) => ({
  type: JOB_MANAGE_SUCCESS,
  payload: { jobs: data },
});

export const manageJob = (payload, statuschange, id, callback) => {
  return (dispatch) => {
    dispatch(manageJobRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.JOB_MANAGE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(manageJobFailure(error));
          showMessage({
            dispatch,
            message: 'Job Update failed',
            variant: 'error',
          });
          callback(error);
          return error;
        } else {
          console.log('response', response);
          dispatch(manageJobSuccess(response));
          // dispatch(getJobListByStatusApplied({    cand_id: type?.user_id,
          //     status: "applied"}))
          // dispatch(getRecommendedList({user_id: type?.user_id}))
          // dispatch(getJobDetails( {job_id: type?.job_id}))
          // dispatch(closeApplyJob())
          if (id === 0) {
            showMessage({
              dispatch,
              message: 'Job successfully created',
              variant: 'success',
            });
          } else if (id !== 0 && !statuschange) {
            showMessage({
              dispatch,
              message: 'Job Update Successfully',
              variant: 'success',
            });
          } else if (id !== 0 && statuschange) {
            showMessage({
              dispatch,
              message: 'Job status updated successfully',
              variant: 'success',
            });
          }
          callback(null, response);
          return response;
        }
      },
    );
    // .then((response) =>{
    //   return response
    // })
    // .catch((error) =>{
    //   return error
    // })
  };
};
// export const manageJob = (payload) => {
//   return function (dispatch) {
//     dispatch(manageJobRequest());
//     return http
//       .post(apiUrl.JOB_MANAGE, payload)
//       .then((response) => {
//         if (response?.status === 1) {
//           dispatch(manageJobSuccess(response));
//           showMessage({
//             dispatch,
//             message: "Job successfully Created" ,
//             variant: "success",
//           });
//           return response;
//         } else {
//           dispatch(manageJobFailure(response));
//           showMessage({
//             dispatch,
//             message: "Job Created failed" ,
//             variant: "success",
//           });
//           return response;
//         }
//       })
//       .catch((error) => {
//         dispatch(manageJobFailure(error));
//         showMessage({
//           dispatch,
//           message: "Job Created failed" ,
//           variant: "success",
//         });
//         return error;
//       });
//   };
// };

export const JOB_SHARE_SUCCESS = 'JOB_SHARE_SUCCESS';
export const JOB_SHARE_REQUEST = 'JOB_SHARE_REQUEST';
export const JOB_SHARE_FAILURE = 'JOB_SHARE_FAILURE';

const shareJobRequest = () => ({
  type: JOB_SHARE_REQUEST,
});

const shareJobFailure = (error) => ({
  type: JOB_SHARE_FAILURE,
  payload: { error: error },
});

const shareJobSuccess = (data) => ({
  type: JOB_SHARE_SUCCESS,
  payload: { jobs: data },
});

export const shareJob = (payload) => {
  return function (dispatch) {
    dispatch(shareJobRequest());
    return http
      .post(apiUrl.JOB_SHARE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(shareJobSuccess(response));
          showMessage({
            dispatch,
            message: 'Job successfully Shared',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(shareJobFailure(response));
          showMessage({
            dispatch,
            message: 'Job Shared failed',
            variant: 'success',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(shareJobFailure(error));
        showMessage({
          dispatch,
          message: 'Job Shared failed',
          variant: 'success',
        });
        return error;
      });
  };
};

export const JOB_SAVE_SUCCESS = 'JOB_SAVE_SUCCESS';
export const JOB_SAVE_REQUEST = 'JOB_SAVE_REQUEST';
export const JOB_SAVE_FAILURE = 'JOB_SAVE_FAILURE';

const postSaveJobRequest = () => ({
  type: JOB_SAVE_REQUEST,
});

const postSaveJobFailure = (error) => ({
  type: JOB_SAVE_FAILURE,
  payload: { error: error },
});

const postSaveJobSuccess = (data) => ({
  type: JOB_SAVE_SUCCESS,
  payload: { jobs: data },
});

export const postSaveJob = (payload, type) => {
  return (dispatch) => {
    dispatch(postSaveJobRequest());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.JOB_SAVE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          dispatch(postSaveJobFailure(response));
          console.log('response', response);
          showMessage({
            dispatch,
            message: 'Job Apply Failed',
            variant: 'error',
          });
          return error;
        } else {
          dispatch(postSaveJobSuccess(response));
          dispatch(
            getJobListByStatusApplied({
              cand_id: type?.user_id,
              status: 'applied',
            }),
          );
          dispatch(getRecommendedList({ user_id: type?.user_id }));
          dispatch(getJobDetails({ job_id: type?.job_id }));
          dispatch(closeApplyJob());
          if (type === 'saved') {
            showMessage({
              dispatch,
              message: 'Job saved successfully ',
              variant: 'success',
            });
          } else {
            showMessage({
              dispatch,
              message: 'Job Applied Successfully ',
              variant: 'success',
            });
          }
          return response;
        }
      },
    );
  };
};

export const postSaveJobNew = (payload) => {
  return function (dispatch) {
    dispatch(postSaveJobRequest());
    return http
      .post(apiUrl.JOB_SAVE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postSaveJobSuccess(response));
          showMessage({
            dispatch,
            message: 'Job ' + payload?.status + ' successfully',
            variant: 'success',
          });
          return response;
        } else {
          dispatch(postSaveJobFailure(response));
          showMessage({
            dispatch,
            message: 'Job' + payload?.status + 'failed',
            variant: 'error',
          });
          return response;
        }
      })
      .catch((error) => {
        dispatch(postSaveJobFailure(error));
        showMessage({
          dispatch,
          message: 'Job' + payload?.status + 'failed',
          variant: 'error',
        });
        return error;
      });
  };
};

export const GET_JOB_UNDO_STATUS_SUCCESS = 'GET_JOB_UNDO_STATUS_SUCCESS';
export const GET_JOB_UNDO_STATUS_REQUEST = 'GET_JOB_UNDO_STATUS_REQUEST';
export const GET_JOB_UNDO_STATUS_FAILURE = 'GET_JOB_UNDO_STATUS_FAILURE';

const postJobCandidateUndoRequest = () => ({
  type: GET_JOB_UNDO_STATUS_REQUEST,
});

const postJobCandidateUndoFailure = (error) => ({
  type: GET_JOB_UNDO_STATUS_FAILURE,
  payload: { error: error },
});

const postJobCandidateUndoSuccess = (data) => ({
  type: GET_JOB_UNDO_STATUS_SUCCESS,
  payload: { jobs: data },
});

export const postJobCandidateUndo = (payload) => {
  return function (dispatch) {
    dispatch(postJobCandidateUndoRequest());
    return http
      .post(apiUrl.JOB_UNDO_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postJobCandidateUndoSuccess(response));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(postJobCandidateUndoSuccess({ data: [] }));
            return response;
          } else {
            dispatch(postJobCandidateUndoFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(postJobCandidateShortListFailure(error));
        return error;
      });
  };
};

export const GET_JOB_CANDIDATE_SHORTLIST_SUCCESS =
  'GET_JOB_CANDIDATE_SHORTLIST_SUCCESS';
export const GET_JOB_CANDIDATE_SHORTLIST_REQUEST =
  'GET_JOB_CANDIDATE_SHORTLIST_REQUEST';
export const GET_JOB_CANDIDATE_SHORTLIST_FAILURE =
  'GET_JOB_CANDIDATE_SHORTLIST_FAILURE';

const postJobCandidateShortListRequest = () => ({
  type: GET_JOB_CANDIDATE_SHORTLIST_REQUEST,
});

const postJobCandidateShortListFailure = (error) => ({
  type: GET_JOB_CANDIDATE_SHORTLIST_FAILURE,
  payload: { error: error },
});

const postJobCandidateShortListSuccess = (data) => ({
  type: GET_JOB_CANDIDATE_SHORTLIST_SUCCESS,
  payload: { jobs: data },
});

export const postJobCandidateShortList = (payload) => {
  return function (dispatch) {
    dispatch(postJobCandidateShortListRequest());
    return http
      .post(apiUrl.MANAGE_JOB_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postJobCandidateShortListSuccess(response));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(postJobCandidateShortListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(postJobCandidateShortListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(postJobCandidateShortListFailure(error));
        return error;
      });
  };
};

export const CANDIDATE_PROFILE_DETAILS_FULFILLED =
  'CANDIDATE_PROFILE_DETAILS_FULFILLED';
export const CANDIDATE_PROFILE_DETAILS_PENDING =
  'CANDIDATE_PROFILE_DETAILS_PENDING';
export const CANDIDATE_PROFILE_DETAILS_REJECTED =
  'CANDIDATE_PROFILE_DETAILS_REJECTED';
export const CANDIDATE_PROFILE_OTHER_DETAILS_FULFILLED =
  'CANDIDATE_PROFILE_OTHER_DETAILS_FULFILLED';

const candidateProfileDetailsPending = () => ({
  type: CANDIDATE_PROFILE_DETAILS_PENDING,
});

const candidateProfileDetailsFailure = (error) => ({
  type: CANDIDATE_PROFILE_DETAILS_REJECTED,
  payload: { error },
});

const candidateProfileDetailsSuccess = (data, type) => {
  return {
    type: CANDIDATE_PROFILE_DETAILS_FULFILLED,
    payload: data,
  };
};

export const candidateProfileDetails = (payload) => {
  console.log('payload candidateProfileDetails', payload);
  return (dispatch) => {
    dispatch(candidateProfileDetailsPending());
    return http
      .post(apiUrl.CANDIDATE_PROFILE, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(candidateProfileDetailsSuccess(response?.data));

          return response;
        } else {
          dispatch(candidateProfileDetailsFailure(response));

          return response;
        }
      })
      .catch((error) => {
        dispatch(candidateProfileDetailsFailure(error));

        return error;
      });
  };
};

export const CANDIDATE_PROFILE_ABOUT_FULFILLED =
  'CANDIDATE_PROFILE_ABOUT_FULFILLED';
export const CANDIDATE_PROFILE_ABOUT_PENDING =
  'CANDIDATE_PROFILE_ABOUT_PENDING';
export const CANDIDATE_PROFILE_ABOUT_REJECTED =
  'CANDIDATE_PROFILE_ABOUT_REJECTED';
export const CANDIDCANDIDATE_PROFILE_ABOUT_FULFILLED =
  'CANDIDATE_PROFILE_ABOUT_FULFILLED';

const candidateProfileAboutPending = () => ({
  type: CANDIDATE_PROFILE_ABOUT_PENDING,
});

const candidateProfileAboutFailure = (error) => ({
  type: CANDIDATE_PROFILE_ABOUT_REJECTED,
  payload: { error },
});

const candidateProfileAboutSuccess = (data, type) => {
  return {
    type: CANDIDATE_PROFILE_ABOUT_FULFILLED,
    payload: data,
  };
};

export const candidateProfileAbout = (payload) => {
  console.log('candidateProfileAbout', payload);
  return (dispatch) => {
    dispatch(candidateProfileAboutPending());
    return http
      .post(apiUrl.CANDIDATE_PROFILE_ABOUT, payload)
      .then((response) => {
        console.log('candidateProfileAbout', response, payload);
        if (response?.status === 1) {
          dispatch(candidateProfileAboutSuccess(response?.data));

          return response;
        } else {
          dispatch(candidateProfileAboutFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(candidateProfileAboutFailure(error));
        return error;
      });
  };
};

export const CANDIDATE_PROFILE_USER_DETAILS_FULFILLED =
  'CANDIDATE_PROFILE_USER_DETAILS_FULFILLED';
export const CANDIDATE_PROFILE_USER_DETAILS_PENDING =
  'CANDIDATE_PROFILE_USER_DETAILS_PENDING';
export const CANDIDATE_PROFILE_USER_DETAILS_REJECTED =
  'CANDIDATE_PROFILE_USER_DETAILS_REJECTED';
export const CANDIDCANDIDATE_PROFILE_USER_DETAILS_FULFILLED =
  'CANDIDATE_PROFILE_USER_DETAILS_FULFILLED';

const userProfileCandidateDetailsPending = () => ({
  type: CANDIDATE_PROFILE_USER_DETAILS_PENDING,
});

const userProfileCandidateDetailsFailure = (error) => ({
  type: CANDIDATE_PROFILE_USER_DETAILS_REJECTED,
  payload: { error },
});

const userProfileCandidateDetailsSuccess = (data, type) => {
  return {
    type: CANDIDATE_PROFILE_USER_DETAILS_FULFILLED,
    payload: data,
  };
};

export const userProfileCandidateDetails = (payload) => {
  console.log('userProfileCandidateDetails', payload);
  return (dispatch) => {
    dispatch(userProfileCandidateDetailsPending());
    return http
      .post(apiUrl.USER_PROFILE, payload)
      .then((response) => {
        console.log('candidateProfileAbout', response, payload);
        if (response?.status === 1) {
          dispatch(userProfileCandidateDetailsSuccess(response?.data));

          return response;
        } else {
          dispatch(userProfileCandidateDetailsFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(userProfileCandidateDetailsFailure(error));
        return error;
      });
  };
};

export const JOB_MESSAGE_LIST_FULFILLED = 'JOB_MESSAGE_LIST_FULFILLED';
export const JOB_MESSAGE_LIST_PENDING = 'JOB_MESSAGE_LIST_PENDING';
export const JOB_MESSAGE_LIST_REJECTED = 'JOB_MESSAGE_LIST_REJECTED';
export const CANDIDJOB_MESSAGE_LIST_FULFILLED = 'JOB_MESSAGE_LIST_FULFILLED';

const jobMessageListPending = () => ({
  type: JOB_MESSAGE_LIST_PENDING,
});

const jobMessageListFailure = (error) => ({
  type: JOB_MESSAGE_LIST_REJECTED,
  payload: { error },
});

const jobMessageListSuccess = (data, type) => {
  return {
    type: JOB_MESSAGE_LIST_FULFILLED,
    payload: data,
  };
};

export const jobMessageList = (payload) => {
  console.log('payload candidateProfileDetails', payload);
  return (dispatch) => {
    dispatch(jobMessageListPending());
    return http
      .post(apiUrl.JOB_MESSAGE_LIST, payload)
      .then((response) => {
        console.log('payload', response, payload);
        if (response?.status === 1) {
          dispatch(jobMessageListSuccess(response?.data));
          return response;
        } else {
          if (
            response?.status === -1 &&
            response?.message === 'No record found'
          ) {
            dispatch(jobMessageListSuccess({ data: [] }));
            return response;
          } else {
            dispatch(jobMessageListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(jobMessageListFailure(error));
        return error;
      });
  };
};

export const GET_JOB_CANDIDATE_HOLD_SUCCESS = 'GET_JOB_CANDIDATE_HOLD_SUCCESS';
export const GET_JOB_CANDIDATE_HOLD_REQUEST = 'GET_JOB_CANDIDATE_HOLD_REQUEST';
export const GET_JOB_CANDIDATE_HOLD_FAILURE = 'GET_JOB_CANDIDATE_HOLD_FAILURE';

const postJobCandidateHoldRequest = () => ({
  type: GET_JOB_CANDIDATE_HOLD_REQUEST,
});

const postJobCandidateHoldFailure = (error) => ({
  type: GET_JOB_CANDIDATE_HOLD_FAILURE,
  payload: { error: error },
});

const postJobCandidateHoldSuccess = (data) => ({
  type: GET_JOB_CANDIDATE_HOLD_SUCCESS,
  payload: { jobs: data },
});

export const postJobCandidateHold = (payload) => {
  return function (dispatch) {
    dispatch(postJobCandidateHoldRequest());
    return http
      .post(apiUrl.MANAGE_JOB_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postJobCandidateHoldSuccess(response));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(postJobCandidateHoldSuccess({ data: [] }));
            return response;
          } else {
            dispatch(postJobCandidateHoldFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(postJobCandidateHoldFailure(error));
        return error;
      });
  };
};

export const GET_JOB_CANDIDATE_REJECT_SUCCESS =
  'GET_JOB_CANDIDATE_REJECT_SUCCESS';
export const GET_JOB_CANDIDATE_REJECT_REQUEST =
  'GET_JOB_CANDIDATE_REJECT_REQUEST';
export const GET_JOB_CANDIDATE_REJECT_FAILURE =
  'GET_JOB_CANDIDATE_REJECT_FAILURE';

const postJobCandidateRejectRequest = () => ({
  type: GET_JOB_CANDIDATE_REJECT_REQUEST,
});

const postJobCandidateRejectFailure = (error) => ({
  type: GET_JOB_CANDIDATE_REJECT_FAILURE,
  payload: { error: error },
});

const postJobCandidateRejectSuccess = (data) => ({
  type: GET_JOB_CANDIDATE_REJECT_SUCCESS,
  payload: { jobs: data },
});

export const postJobCandidateReject = (payload) => {
  return (dispatch) => {
    dispatch(postJobCandidateRejectRequest());
    return http
      .post(apiUrl.MANAGE_JOB_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(postJobCandidateRejectSuccess(response));
          return response;
        } else {
          dispatch(postJobCandidateRejectFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(postJobCandidateRejectFailure(error));
        return error;
      });
  };
};

export const GET_JOB_APPLY_COUNT_SUCCESS = 'GET_JOB_APPLY_COUNT_SUCCESS';
export const GET_JOB_APPLY_COUNT_REQUEST = 'GET_JOB_APPLY_COUNT_REQUEST';
export const GET_JOB_APPLY_COUNT_FAILURE = 'GET_JOB_APPLY_COUNT_FAILURE';

const getJobApplyCountRequest = () => ({
  type: GET_JOB_APPLY_COUNT_REQUEST,
});

const getJobApplyCountFailure = (error) => ({
  type: GET_DETAILS_JOB_FAILURE,
  payload: { error: error },
});

const getJobApplyCountSuccess = (data) => ({
  type: GET_JOB_APPLY_COUNT_SUCCESS,
  payload: { job: data[0]?.applied_count },
});

export const getJobApplyCount = (payload) => {
  return (dispatch) => {
    dispatch(getJobApplyCountRequest());
    return http
      .post(apiUrl.JOB_APPLIED_COUNT, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.status === 1) {
          dispatch(getJobApplyCountSuccess(response?.data));
          return response;
        } else {
          dispatch(getJobApplyCountFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobApplyCountFailure(error));
        return error;
      });
  };
};
export const GET_JOB_USER_STATUS_SUCCESS = 'GET_JOB_USER_STATUS_SUCCESS';
export const GET_JOB_USER_STATUS_REQUEST = 'GET_JOB_USER_STATUS_REQUEST';
export const GET_JOB_USER_STATUS_FAILURE = 'GET_JOB_USER_STATUS_FAILURE';

const getJobUserStatusRequest = () => ({
  type: GET_JOB_USER_STATUS_REQUEST,
});

const getJobUserStatusFailure = (error) => ({
  type: GET_JOB_USER_STATUS_FAILURE,
  payload: { error: error },
});

const getJobUserStatusSuccess = (data) => ({
  type: GET_JOB_USER_STATUS_SUCCESS,
  payload: data,
});

export const getJobUserStatusDetails = (payload) => {
  return (dispatch) => {
    dispatch(getJobUserStatusRequest());
    return http
      .post(apiUrl.JOB_USER_STATUS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobUserStatusSuccess(response));
          return response;
        } else {
          dispatch(getJobUserStatusFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobUserStatusFailure(error));
        return error;
      });
  };
};

export const GET_DETAILS_JOB_SUCCESS = 'GET_DETAILS_JOB_SUCCESS';
export const GET_DETAILS_JOB_REQUEST = 'GET_DETAILS_JOB_REQUEST';
export const GET_DETAILS_JOB_FAILURE = 'GET_DETAILS_JOB_FAILURE';

const getJobDetailsRequest = () => ({
  type: GET_DETAILS_JOB_REQUEST,
});

const getJobDetailsFailure = (error) => ({
  type: GET_DETAILS_JOB_FAILURE,
  payload: { error: error },
});

const getJobDetailsSuccess = (data) => ({
  type: GET_DETAILS_JOB_SUCCESS,
  payload: { job: data },
});

export const getJobDetails = (payload) => {
  return (dispatch) => {
    dispatch(getJobDetailsRequest());
    return http
      .post(apiUrl.JOB_DETAILS, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobDetailsSuccess(response));
          return response;
        } else {
          dispatch(getJobDetailsFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobDetailsFailure(error));
        return error;
      });
  };
};

export const GET_CANDIDATE_LIST_SUCCESS = 'GET_CANDIDATE_LIST_SUCCESS';
export const GET_CANDIDATE_LIST_REQUEST = 'GET_CANDIDATE_LIST_REQUEST';
export const GET_CANDIDATE_LIST_FAILURE = 'GET_CANDIDATE_LIST_FAILURE';

const getCandidateListRequest = () => ({
  type: GET_CANDIDATE_LIST_REQUEST,
});

const getCandidateListFailure = (error) => ({
  type: GET_CANDIDATE_LIST_FAILURE,
  payload: { error: error },
});

// const getCandidateListSuccess = (data, payload) => {
//   return {
//     type: 'GET_CANDIDATE_APPLIED_LIST_SUCCESS',
//     payload: data,
//   };
  // } else if (payload?.status === "shortlisted") {
  //   return {
  //     type: "GET_CANDIDATE_SHORTLIST_LIST_SUCCESS",
  //     payload: data,
  //   };
  // } else if (payload?.status === "hold") {
  //   return {
  //     type: "GET_CANDIDATE_HOLD_LIST_SUCCESS",
  //     payload: data,
  //   };
  // }
  // else if (payload?.status === "rejected") {
  //   return {
  //     type: "GET_CANDIDATE_REJECTED_LIST_SUCCESS",
  //     payload: data,
  //   };
  // }
// };

const getCandidateListAppliedSuccess = (data, payload) => {
  return {
    type: 'GET_CANDIDATE_APPLIED_LIST_SUCCESS',
    payload: data,
  };
};

const getCandidateListHoldSuccess = (data, payload) => {
  return {
    type: 'GET_CANDIDATE_HOLD_LIST_SUCCESS',
    payload: data,
  };
};

const getCandidateListShortlistSuccess = (data, payload) => {
  return {
    type: 'GET_CANDIDATE_SHORTLIST_LIST_SUCCESS',
    payload: data,
  };
};

const getCandidateListRejectedSuccess = (data, payload) => {
  return {
    type: 'GET_CANDIDATE_REJECTED_LIST_SUCCESS',
    payload: data,
  };
};

export const getCandidateListApplied = (payload) => {
  return (dispatch) => {
    dispatch(getCandidateListRequest());
    return http
      .post(apiUrl.CANDIDATE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCandidateListAppliedSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getCandidateListAppliedSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(getCandidateListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCandidateListFailure(error));
        return error;
      });
  };
};

export const getCandidateListShortlist = (payload) => {
  return (dispatch) => {
    dispatch(getCandidateListRequest());
    return http
      .post(apiUrl.CANDIDATE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCandidateListShortlistSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getCandidateListShortlistSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(getCandidateListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCandidateListFailure(error));
        return error;
      });
  };
};

export const getCandidateListHold = (payload) => {
  return (dispatch) => {
    dispatch(getCandidateListRequest());
    return http
      .post(apiUrl.CANDIDATE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCandidateListHoldSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getCandidateListHoldSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(getCandidateListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCandidateListFailure(error));
        return error;
      });
  };
};

export const getCandidateListRejected = (payload) => {
  return (dispatch) => {
    dispatch(getCandidateListRequest());
    return http
      .post(apiUrl.CANDIDATE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCandidateListRejectedSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getCandidateListRejectedSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(getCandidateListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCandidateListFailure(error));
        return error;
      });
  };
};

export const getCandidateList = (payload) => {
  return (dispatch) => {
    dispatch(getCandidateListRequest());
    return http
      .post(apiUrl.CANDIDATE_LIST, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getCandidateListRejectedSuccess(response, payload));
          return response;
        } else {
          if (response?.status === -1 && response?.message === 'Not Found') {
            dispatch(getCandidateListRejectedSuccess({ data: [] }, payload));
            return response;
          } else {
            dispatch(getCandidateListFailure(response));
            return response;
          }
        }
      })
      .catch((error) => {
        dispatch(getCandidateListFailure(error));
        return error;
      });
  };
};

export const GET_ARTICLES_LIST_SUCCESS = 'GET_ARTICLES_LIST_SUCCESS';
export const GET_ARTICLES_LIST_REQUEST = 'GET_ARTICLES_LIST_REQUEST';
export const GET_ARTICLES_LIST_FAILURE = 'GET_ARTICLES_LIST_FAILURE';

const getArticlesListRequest = () => ({
  type: GET_ARTICLES_LIST_REQUEST,
});

const getArticlesListFailure = (error) => ({
  type: GET_ARTICLES_LIST_FAILURE,
  payload: { error: error },
});

const getArticlesListSuccess = (data) => ({
  type: GET_ARTICLES_LIST_SUCCESS,
  payload: { articles: data },
});

export const getArticlesList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'GET_ARTICLES_LIST_REQUEST',
    });
    return http
      .get(apiUrl.GET_POST_LIST, payload)
      .then(({ data }) => {
        console.log('getArticlesList', data);
      })
      .catch((error) => {
        console.log('getArticlesList error', error);
      });
  };
};

export const getArticlesListList = (payload) => {
  return (dispatch) => {
    dispatch(getArticlesListRequest());
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      API.http.post(
        {
          endPoint: apiUrl.GET_ARTICLES_LIST,
          body: payload,
          token: token,
        },
        (error, response) => {
          if (error) {
            console.log(error);
            dispatch(getArticlesListFailure(error));
            resolve(error);
          } else {
            if (response?.status === 1) {
              dispatch(getArticlesListSuccess(response));
              resolve(response);
            } else {
              dispatch(getArticlesListFailure(response));
              resolve(response);
            }
          }
        },
      );
    });
  };
};

const addNewArticlePending = () => ({
  type: 'SEND_ADD_NEW_ARTICLE_DATA_PENDING',
});

const addNewArticleFailure = (error) => ({
  type: 'SEND_ADD_NEW_ARTICLE_DATA_REJECTED',
  payload: { error },
});

const addNewArticleSuccess = (data) => ({
  type: 'SEND_ADD_NEW_ARTICLE_DATA_FULFILLED',
  payload: data,
});

// =====================Add-Post-Api==============================================
// require -- id,content,image ,user_id ,datetime, document_type ,user_type , user_table_pk
export const sendAddNewArticleDataold = (payload) => {
  return (dispatch) => {
    dispatch(addNewArticlePending());
    return http
      .post(apiUrl.ADD_NEW_ARTICLE, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(addNewArticleSuccess(response));
          dispatch(closeArticleEdit());
          return response;
        } else {
          dispatch(addNewArticleFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(addNewArticleFailure(error));
        return error;
      });
  };
};

export const sendAddNewArticleData = (payload) => {
  return (dispatch) => {
    dispatch(addNewArticlePending());
    const token = getAccessToken();
    return API.http_form.post(
      {
        endPoint: apiUrl.ADD_NEW_ARTICLE,
        body: payload,
        token: token,
      },

      (error, response) => {
        if (error) {
          } else {
          console.log('response', response);
        }
      },
    );
    // .then((response) => {
    //   if (response?.status === 1) {
    //     dispatch(addNewPostSuccess(response));
    //     dispatch(closePostEdit());
    //     return response;
    //   } else {
    //     dispatch(addNewPostFailure(response));
    //     return response;
    //   }
    // })
    // .catch((error) => {
    //   dispatch(addNewPostFailure(error));
    //   return error;
    // });
  };
};

export const GET_JOB_SEARCH_SUCCESS = 'GET_JOB_SEARCH_SUCCESS';
export const GET_JOB_SEARCH_REQUEST = 'GET_JOB_SEARCH_REQUEST';
export const GET_JOB_SEARCH_FAILURE = 'GET_JOB_SEARCH_FAILURE';

const getJobSearchRequest = () => ({
  type: GET_JOB_SEARCH_REQUEST,
});

const getJobSearchFailure = (error) => ({
  type: GET_JOB_SEARCH_FAILURE,
  payload: { error: error },
});

const getJobSearchSuccess = (data, payld) => ({
  type: GET_JOB_SEARCH_SUCCESS,
  payload: { jobs: data, payld: payld },
});

export const getJobSearch = (payload) => {
  return (dispatch) => {
    dispatch(getJobSearchRequest());
    return http
      .post(apiUrl.JOB_SEARCH, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobSearchSuccess(response, payload));
          return response;
        } else {
          dispatch(getJobSearchFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobSearchFailure(error));
        return error;
      });
  };
};

export const GET_JOB_FILTER_SUCCESS = 'GET_JOB_FILTER_SUCCESS';
export const GET_JOB_FILTER_REQUEST = 'GET_JOB_FILTER_REQUEST';
export const GET_JOB_FILTER_FAILURE = 'GET_JOB_FILTER_FAILURE';

const getJobFilterRequest = () => ({
  type: GET_JOB_FILTER_REQUEST,
});

const getJobFilterFailure = (error) => ({
  type: GET_JOB_FILTER_FAILURE,
  payload: { error: error },
});

const getJobFilterSuccess = (data) => ({
  type: GET_JOB_FILTER_SUCCESS,
  payload: data,
});

export const getJobFilter = (payload) => {
  return (dispatch) => {
    dispatch(getJobFilterRequest());
    return http
      .post(apiUrl.JOB_FILTER, payload)
      .then((response) => {
        if (response?.status === 1) {
          dispatch(getJobFilterSuccess(response));
          return response;
        } else {
          dispatch(getJobFilterFailure(response));
          return response;
        }
      })
      .catch((error) => {
        dispatch(getJobFilterFailure(error));
        return error;
      });
  };
};

export const CLOSE_ARTICLE_EDIT = 'CLOSE_ARTICLE_EDIT';
export const OPEN_ARTICLE_EDIT = 'OPEN_ARTICLE_EDIT';

export const closeArticleEdit = () => ({
  type: CLOSE_ARTICLE_EDIT,
});

export const openArticleEdit = () => ({
  type: OPEN_ARTICLE_EDIT,
});

export const CLOSE_UPDATE_ARTICLE = 'CLOSE_UPDATE_ARTICLE';
export const OPEN_UPDATE_ARTICLE = 'OPEN_UPDATE_ARTICLE';

export const closeUpdateArticle = () => ({
  type: 'CLOSE_UPDATE_ARTICLE',
});

export const openUpdateArticle = (article) => {
  return {
    type: 'OPEN_UPDATE_ARTICLE',
    payload: article,
  };
};

export const CLOSE_APPLY_JOB = 'CLOSE_APPLY_JOB';
export const OPEN_APPLY_JOB = 'OPEN_APPLY_JOB';

export const closeApplyJob = () => ({
  type: 'CLOSE_APPLY_JOB',
});

export const openApplyJob = (job) => {
  return {
    type: 'OPEN_APPLY_JOB',
    payload: job,
  };
};

export const CLOSE_SHARE_JOB = 'CLOSE_SHARE_JOB';
export const OPEN_SHARE_JOB = 'OPEN_SHARE_JOB';

export const closeShareJob = () => ({
  type: 'CLOSE_SHARE_JOB',
});

export const openShareJob = (job) => {
  return {
    type: 'OPEN_SHARE_JOB',
    payload: job,
  };
};

export const editJobOpen = (job) => {
  return {
    type: 'EDIT_JOB_OPEN',
    payload: job,
  };
};

export const editJobClose = (job) => {
  return {
    type: 'EDIT_JOB_CLOSE',
  };
};

export const reportJobOpen = (job) => {
  return {
    type: 'REPORT_JOB_OPEN',
    payload: job,
  };
};

export const reportJobClose = (job) => {
  return {
    type: 'REPORT_JOB_CLOSE',
  };
};
