import axios from 'axios';
import React, { Component, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { Row, Col, Container, Select } from 'react-bootstrap';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Automotive from './brand/Automotive.jsx';
import RealEstate from './brand/RealEstate.jsx';
import Brand from './brand/Brand';
import { countryCodeList } from '../../data/date-data';
import Header from '../../components/HomeHeader/header';
import Footer from '../../components/HomeHeader/footer';
import AllenSoly from '../../resources/PartnerLogos/Allen Solly-01.png';
import Amazon from '../../resources/PartnerLogos/Amazon-01.png';
import Armani from '../../resources/PartnerLogos/Armani Exchange-01.png';
import Croma from '../../resources/PartnerLogos/Croma-01.png';
import Helios from '../../resources/PartnerLogos/Helios-01.png';
import Jaguar from '../../resources/PartnerLogos/Jaguar-01.png';
import Pvr from '../../resources/PartnerLogos/PVR-01.png';
import Taj from '../../resources/PartnerLogos/Taj-01.png';
import Vero from '../../resources/PartnerLogos/Vero Moda-01.png';
import Godrej from '../../resources/PartnerLogos/GodrejWhite.png';
import LanT from '../../resources/PartnerLogos/L&T-White.png';
import LandRover from '../../resources/PartnerLogos/LandRover-White.png';
import Lodha from '../../resources/PartnerLogos/Lodha-White.png';
import MediBuddy from '../../resources/PartnerLogos/MediBuddy-White.png';
import Oracle from '../../resources/PartnerLogos/Oracle-White.png';
import Puma from '../../resources/PartnerLogos/Puma-White.png';
import Raheja from '../../resources/PartnerLogos/Raheja-White.png';
import Vogue from '../../resources/PartnerLogos/Vogue.png';
import Wadhwa from '../../resources/PartnerLogos/Wadhwa-White.png';
import Gift from '../../resources/PartnerLogos/Gift_Active.png';
import config from '../../services/apiConfig';
class PartnerBrand extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm =
      this.submituserRegistrationForm.bind(this);
    this.initialState = this.state;
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    console.log(this.state);
    this.setState({
      fields,
    });
  }
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['firstname']) {
      formIsValid = false;
      errors['firstname'] = '*Please enter your first name.';
    }

    if (typeof fields['firstname'] !== 'undefined') {
      if (!fields['firstname'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['firstname'] = '*Please enter alphabet characters only.';
      }
    }
    if (!fields['lastname']) {
      formIsValid = false;
      errors['lastname'] = '*Please enter your last name.';
    }

    if (typeof fields['lastname'] !== 'undefined') {
      if (!fields['lastname'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['lastname'] = '*Please enter alphabet characters only.';
      }
    }
    if (!fields['emailid']) {
      formIsValid = false;
      errors['emailid'] = '*Please enter your email-ID.';
    }

    if (typeof fields['emailid'] !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      );
      if (!pattern.test(fields['emailid'])) {
        formIsValid = false;
        errors['emailid'] = '*Please enter valid email-ID.';
      }
    }

    if (!fields['mobileno']) {
      formIsValid = false;
      errors['mobileno'] = '*Please enter your mobile no.';
    }

    if (typeof fields['mobileno'] !== 'undefined') {
      if (!fields['mobileno'].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors['mobileno'] = '*Please enter valid mobile no.';
      }
    }
    // if (!fields["reachingoutfor"]) {
    //   formIsValid = false;
    //   errors["reachingoutfor"] = "*Please enter the purpose to reach.";
    // }
    if (!fields['company']) {
      formIsValid = false;
      errors['company'] = '*Please enter the purpose to reach.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  submituserRegistrationForm(e) {
    e.preventDefault();
    var m = new Date();
    var dateString =
      m.getFullYear() +
      '-' +
      (m.getMonth() + 1) +
      '-' +
      m.getDate() +
      ' ' +
      m.getHours() +
      ':' +
      m.getMinutes() +
      ':' +
      m.getSeconds();

    const data = {
      first_name: this.state.fields.firstname,
      last_name: this.state.fields.lastname,
      email: this.state.fields.emailid,
      country_code: '+91',
      mobile: this.state.fields.mobileno,
      reaching_out_for: 'Brand',
      company: this.state.fields.company,
      description: this.state.fields.writehere,
      created_at: dateString,
    };

    console.log(data);
    if (this.validateForm()) {
      axios
        .post(
          //"http://13.233.22.12:4000/contact_us",
          config?.serverBaseUrl + '/contact_us',
          data,
        )
        .then((response) => {
          console.log(response);
          this.initialState = this.state;
          let fields = {};
          fields['firstname'] = '';
          fields['lastname'] = '';
          fields['mobileno'] = '';
          fields['emailid'] = '';
          fields['reachingoutfor'] = '';
          fields['company'] = '';
          fields['writehere'] = '';
          this.setState({ fields: fields });
          alert('We will get back to you');
        });
    }
  }

  render() {
    return (
      <Container fluid id="no-padding">
        <Row className="partner-top">
          <Col md={12} xs={12} className="main-header-style-section">
            {' '}
            <Navbar />{' '}
          </Col>
        </Row>
        {/* <Row style={{}}>
              <Col>
                <h2 className="partner-heading">Partners With Us</h2>
              </Col>
            </Row> */}
        <Row className="automotive">
          <Brand />
        </Row>
        {/* <Row className="partner-top2">
          <Col>
            <h2 className="partner-heading">Brand Partnership With Us</h2>
          </Col>
        </Row>
        <Row className="partner-top-section1">
          <Col>
            <Row className="partner-benefit-section">
              <Col>
                <Row>
                  <Col sm={12} className="partner-rows">
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Sales</p>
                      <p className="partner-benefit">
                        Generate inbound sales leads through the LinkCxO
                        Platform
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} className="partner-rows">
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Wide Networking</p>
                      <p className="partner-benefit">
                        Network with 15000+ entrepreneurs and investors from
                        IIT/IIM/Premium backgrounds
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="partner-rows">
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Brand showcasing</p>
                      <p className="partner-benefit">
                        Complimentary Brand Showcasing on our platform and
                        Social Media Channels
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} className="partner-rows">
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Free Partnership</p>
                      <p className="partner-benefit">
                        No Cost for early partners
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="partner-rows">
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Exclusive Access </p>
                      <p className="partner-benefit">
                        An exclusive access to the rapidly growing network of
                        5000+ CxOs
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} className="partner-rows">
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Organize or promote </p>
                      <p className="partner-benefit">
                        Organize Or promote an Event on our application free of
                        cost
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="partner-top-section">
          <Col>
            <Row className="partner-benefit-section">
              <Col>
                <Row>
                  <Col
                    className="partner-rows"
                    style={{ marginRight: '120px' }}>
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Sales</p>
                      <p className="partner-benefit">
                        Generate inbound sales leads through the LinkCxO
                        Platform
                      </p>
                    </div>
                  </Col>
                  <Col
                    className="partner-rows1"
                    style={{ marginLeft: '120px' }}>
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Wide Networking</p>
                      <p className="partner-benefit">
                        Network with 15000+ entrepreneurs and investors from
                        IIT/IIM/Premium backgrounds
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                  <Col
                    className="partner-rows"
                    style={{ marginRight: '170px' }}>
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Brand showcasing</p>
                      <p className="partner-benefit">
                        Complimentary Brand Showcasing on our platform and
                        Social Media Channels
                      </p>
                    </div>
                  </Col>
                  <Col
                    className="partner-rows1"
                    style={{ marginLeft: '170px' }}>
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Free Partnership</p>
                      <p className="partner-benefit">
                        No Cost for early partners
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="partner-rows"
                    style={{ marginRight: '120px' }}>
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Exclusive Access </p>
                      <p className="partner-benefit">
                        An exclusive access to the rapidly growing network of
                        15000+ CxOs
                      </p>
                    </div>
                  </Col>
                  <Col
                    className="partner-rows1"
                    style={{ marginLeft: '120px' }}>
                    {' '}
                    <div className="partner-box">
                      <p className="benefit-partner">Organize or promote </p>
                      <p className="partner-benefit">
                        Organize Or promote an Event on our application free of
                        cost
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row className="partner-logo-section-mob">
          {' '}
          <Col>
            <Row>
              <Col>
                {' '}
                <h2 className="interest-title">Partners</h2>
              </Col>
            </Row>{' '}
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={AllenSoly} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Amazon} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Pvr} className="logo_img1" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vero} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Jaguar} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Croma} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Armani} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Taj} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Helios} className="logo_img" />
                </div>
              </Col>{' '}
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Godrej} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LanT} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LandRover} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Lodha} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={MediBuddy} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Oracle} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Puma} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vogue} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Raheja} className="logo_img1" />
                </div>
              </Col>{' '}
            </Row>
          </Col>
        </Row>{' '}
        <Row className="partner-logo-section">
          {' '}
          <Col>
            <Row>
              <Col>
                {' '}
                <h2 className="interest-title">Partners</h2>
              </Col>
            </Row>{' '}
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={AllenSoly} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Amazon} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Pvr} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vero} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Jaguar} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LanT} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Lodha} className="logo_img" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Oracle} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={MediBuddy} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Croma} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Armani} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Taj} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Helios} className="logo_img" />
                </div>
              </Col>{' '}
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Godrej} className="logo_img1" />
                </div>
              </Col>
            </Row>
            <Row style={{ padding: '0px 50px 0px 100px' }}>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={LandRover} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Puma} className="logo_img1" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Wadhwa} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Raheja} className="logo_img" />
                </div>
              </Col>
              <Col>
                <div className="imgFrame">
                  <img alt="" src={Vogue} className="logo_img" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="main-get-in-touch">
          <Col
            className="text-get-in-touch"
            style={{
              textAlign: 'left',
              '@media (max-width: 500px)': {
                marginTop: '50px ',
              },
            }}>
            <h2 className="color-green">Register to partner with us</h2>
            <p style={{ color: '#fff' }}>
              {/* If you need to get in touch with us, please do so. Fill out the
              form and we'll get back to you as soon as possible. */}
              Together we can do so much, Success is when it is shared. Partner
              with us now to achieve more!
            </p>
          </Col>
          <Col className="form-get-in-touch">
            <form
              method="post"
              name="userRegistrationForm"
              onSubmit={this.submituserRegistrationForm}>
              <Row>
                <Col className="firstname" xs={12} md={6}>
                  <input
                    placeholder="First Name*"
                    type="text"
                    name="firstname"
                    value={this.state.fields.firstname}
                    onChange={this.handleChange}
                    required
                  />
                </Col>
                <Col className="lastname" xs={12} md={6}>
                  <input
                    placeholder="Last Name*"
                    type="text"
                    name="lastname"
                    value={this.state.fields.lastname}
                    onChange={this.handleChange}
                    required
                  />
                </Col>
              </Row>

              <Row className="errorMsg">{this.state.errors.name}</Row>
              <Row>
                <input
                  placeholder="Email ID*"
                  type="text"
                  name="emailid"
                  value={this.state.fields.emailid}
                  onChange={this.handleChange}
                  required
                />
              </Row>

              <Row className="errorMsg">{this.state.errors.emailid}</Row>
              <Row
                sm={12}
                className="code-mobile-row"
                style={{ paddingRight: '5px' }}>
                <div className="col-sm-4" style={{ paddingRight: '0px' }}>
                  <select
                    className="code-get-in-touch"
                    onChange={this.handleChange}
                    style={{ backgroundColor: '#fff' }}>
                    <option value="" hidden>
                      India (+91)
                    </option>
                    {countryCodeList
                      ? countryCodeList.map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.label + '(' + item.value + ')'}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </div>

                <div className="col-sm-8">
                  <input
                    className="mobile-get-in-touch"
                    placeholder="Mobile No*"
                    type="text"
                    name="mobileno"
                    value={this.state.fields.mobileno}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </Row>

              <Row className="errorMsg">{this.state.errors.mobileno}</Row>
              {/* <Row>
                <select
                  className="reaching-out"
                  placeholder="Reaching out for*"
                  onChange={this.handleChange}
                  name="reachingoutfor"
                  style={{ backgroundColor: "#fff", color: "#6d6868" }}
                  required
                >
                  {" "}
                  <option>Reaching out for*</option>
                  <option value="Brand Partnership">Brand Partnership</option>
                  <option value="Content Partnership">
                    Content Partnership
                  </option>
                  <option value="Event Partnership">Event Partnership</option>
                  <option value="Startup - Registration">
                    Startup - Registration
                  </option>
                  <option value="Investors - Registration">
                    Investors - Registration
                  </option>
                  <option value="Member on the Application">
                    Member on the Application
                  </option>
                </select>
              </Row> */}
              {/* <Row className="errorMsg">{this.state.errors.reachingoutfor}</Row> */}
              <Row>
                <input
                  className="company"
                  placeholder="Company*"
                  type="text"
                  name="company"
                  value={this.state.fields.company}
                  onChange={this.handleChange}
                  required
                />
              </Row>
              <Row className="errorMsg">{this.state.errors.company}</Row>
              <Row>
                <textarea
                  type="text"
                  name="writehere"
                  rows="5"
                  placeholder="Write here..."
                  value={this.state.fields.writehere}
                  onChange={this.handleChange}
                />
              </Row>
              <Row>
                <button
                  className="btn-form"
                  onClick={this.submitTouch}
                  type="submit">
                  Submit
                </button>
              </Row>
            </form>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            {' '}
            <Footer />{' '}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PartnerBrand;
