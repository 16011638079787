import React from "react";

let InterestIcon = (props) => {
  switch (props.name) {
    case "AdvisoryOpportunities":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          viewBox="0 0 23.19 18.99"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M8.68,8.38v0s0-.1.06-.12a1.9,1.9,0,0,0,.5-1.11,1.84,1.84,0,0,0-.06-.47,1.42,1.42,0,0,1,0-.2.24.24,0,0,1,0-.08.36.36,0,0,1,0-.14,2.48,2.48,0,0,0,.06-.94,3,3,0,0,0-.76-1.8A2.84,2.84,0,0,0,6.2,2.66h0A2.76,2.76,0,0,0,4,3.51a3,3,0,0,0-.71,2.67c0,.05,0,.16,0,.2a1.76,1.76,0,0,0,.29,1.79l0,0a.58.58,0,0,1,.11.18A5.28,5.28,0,0,0,4.62,10a2,2,0,0,0,1.57.72h0A2,2,0,0,0,7.77,10,5.26,5.26,0,0,0,8.68,8.38ZM4.06,6.89h0a.27.27,0,0,0,.19-.13c.07-.1,0-.22,0-.34A2.67,2.67,0,0,1,4.2,5,1.77,1.77,0,0,1,5.87,3.6a3.93,3.93,0,0,1,.93,0,1.73,1.73,0,0,1,1.41,1.5A2.86,2.86,0,0,1,8.1,6.49a.26.26,0,0,0,.16.36c.06,0,.09.06.07.11a2.46,2.46,0,0,1-.17.46H8l0,0-.09.3a4.19,4.19,0,0,1-.24.62,6.47,6.47,0,0,1-.61,1,1,1,0,0,1-.81.41,1.16,1.16,0,0,1-.91-.4,4.28,4.28,0,0,1-.87-1.76l0-.19H4.34c-.08,0-.17-.1-.24-.23A.43.43,0,0,1,4.06,6.89Z"
              />
              <path
                class="cls-1"
                d="M4.17,10.59a.82.82,0,0,0-.4.13L1,12.38a1.59,1.59,0,0,0-.81,1.36c0,.55,0,1.09-.06,1.63,0,.23,0,.47,0,.7l0,.68L0,18.47A.46.46,0,0,0,.45,19h0a.46.46,0,0,0,.46-.48c0-.23,0-.47,0-.7L1,16.45l.09-2.67a.67.67,0,0,1,.35-.6L4,11.64a.19.19,0,0,1,.1,0,.59.59,0,0,0,.44-.12.41.41,0,0,0,.13-.31.6.6,0,0,0-.16-.44A.53.53,0,0,0,4.17,10.59Z"
              />
              <path
                class="cls-1"
                d="M12.33,16.79l0-.6h0l0-.81c0-.55,0-1.09,0-1.63a1.6,1.6,0,0,0-.82-1.37l-1.1-.66-.75-.44c-.33-.21-.67-.41-1-.61A.66.66,0,0,0,8,10.62.57.57,0,0,0,7.7,11a.48.48,0,0,0,.08.45.46.46,0,0,0,.4.18.56.56,0,0,1,.23.05l1.51.9,1,.58a.77.77,0,0,1,.41.68l.15,4.36v.3a.45.45,0,0,0,.46.5h0a.42.42,0,0,0,.32-.14.5.5,0,0,0,.13-.39C12.37,17.9,12.35,17.35,12.33,16.79Z"
              />
              <path
                class="cls-1"
                d="M7.59,18.45c0-.14,0-.28,0-.42l-.19-1.37-.42-3.17,0-.22a1.15,1.15,0,0,1,.17-1.09.27.27,0,0,0,0-.11v0A.89.89,0,0,0,7,11.23.77.77,0,0,0,6.29,11H6a.82.82,0,0,0-.67.3.79.79,0,0,0-.14.69,1.61,1.61,0,0,0,.16.38l.1.2a.41.41,0,0,1,0,.19c-.12.88-.23,1.76-.35,2.64L5,16.68c-.08.59-.15,1.17-.23,1.75a.42.42,0,0,0,.35.51h.06a.42.42,0,0,0,.41-.41L6.16,14v0l0,.29c.11.79.21,1.58.32,2.37.08.63.17,1.28.26,1.94a.46.46,0,0,0,.17.29.42.42,0,0,0,.31.06A.41.41,0,0,0,7.59,18.45ZM6.17,12.24l-.27-.51h.53Z"
              />
              <path
                class="cls-1"
                d="M20.07,9.9v0a.37.37,0,0,1,.05-.11,1.51,1.51,0,0,0,.41-.93,1,1,0,0,0,0-.39,1,1,0,0,1,0-.17s0,0,0-.07a.16.16,0,0,1,0-.11,2.16,2.16,0,0,0,.05-.8,2.47,2.47,0,0,0-.64-1.51A2.41,2.41,0,0,0,18,5.08h0a2.36,2.36,0,0,0-1.87.71,2.54,2.54,0,0,0-.6,2.26.29.29,0,0,1,0,.16,1.49,1.49,0,0,0,.24,1.51l0,0a1,1,0,0,1,.1.16,4.66,4.66,0,0,0,.77,1.36,1.72,1.72,0,0,0,1.32.61h0a1.69,1.69,0,0,0,1.32-.61A4.35,4.35,0,0,0,20.07,9.9ZM16.18,8.64h0a.22.22,0,0,0,.16-.11.34.34,0,0,0,0-.28,2.31,2.31,0,0,1-.05-1.18,1.47,1.47,0,0,1,1.4-1.18,2.86,2.86,0,0,1,.79,0,1.46,1.46,0,0,1,1.19,1.26,2.47,2.47,0,0,1-.1,1.12.22.22,0,0,0,.14.31.07.07,0,0,1,.06.09,2.89,2.89,0,0,1-.15.39h-.12l0,.05c0,.08,0,.16-.08.24a3.52,3.52,0,0,1-.2.52,5.92,5.92,0,0,1-.52.83.89.89,0,0,1-.68.34,1,1,0,0,1-.76-.34,3.45,3.45,0,0,1-.73-1.47l0-.17h-.06a.3.3,0,0,1-.2-.19A.35.35,0,0,1,16.18,8.64Z"
              />
              <path
                class="cls-1"
                d="M16.27,11.75a.75.75,0,0,0-.33.11l-2.36,1.4a1.34,1.34,0,0,0-.68,1.15c0,.46,0,.91-.05,1.37,0,.2,0,.39,0,.58l0,.58c0,.48,0,1-.05,1.45a.39.39,0,0,0,.38.43h0a.38.38,0,0,0,.38-.4l0-.59,0-1.15c0-.74.05-1.49.08-2.24a.56.56,0,0,1,.3-.5l2.17-1.3.09,0a.48.48,0,0,0,.37-.1.33.33,0,0,0,.11-.26.5.5,0,0,0-.13-.37A.48.48,0,0,0,16.27,11.75Z"
              />
              <path
                class="cls-1"
                d="M23.14,17l0-.5h0l0-.69c0-.45,0-.91-.05-1.36a1.33,1.33,0,0,0-.69-1.16l-.92-.56-.63-.37-.85-.5a.5.5,0,0,0-.44-.05.47.47,0,0,0-.27.31.39.39,0,0,0,.06.38.4.4,0,0,0,.33.15.39.39,0,0,1,.2.05l1.28.75.81.49a.64.64,0,0,1,.35.57c0,1.22.08,2.44.12,3.67v.25a.37.37,0,0,0,.38.42h0a.35.35,0,0,0,.27-.11.46.46,0,0,0,.12-.33C23.17,17.91,23.16,17.44,23.14,17Z"
              />
              <path
                class="cls-1"
                d="M19.15,18.37c0-.12,0-.23,0-.35L19,16.86c-.12-.88-.24-1.77-.35-2.66l0-.19a1,1,0,0,1,.14-.92.12.12,0,0,0,0-.09v0a.75.75,0,0,0-.12-.68.64.64,0,0,0-.59-.23h-.23a.68.68,0,0,0-.57.25.65.65,0,0,0-.12.58,1.3,1.3,0,0,0,.14.32,1.3,1.3,0,0,1,.08.17.28.28,0,0,1,0,.15c-.1.75-.19,1.49-.29,2.23L17,16.88c-.07.49-.13,1-.2,1.47a.36.36,0,0,0,.3.43h.05a.36.36,0,0,0,.34-.34l.51-3.8v0l0,.24.27,2c.07.53.14,1.08.22,1.63a.35.35,0,0,0,.14.24.4.4,0,0,0,.26.06A.35.35,0,0,0,19.15,18.37ZM18,13.14l-.22-.43h.45Z"
              />
              <path
                class="cls-1"
                d="M15.69,3.33V.67A.67.67,0,0,0,15,0H10.51a.67.67,0,0,0-.67.67V3.33a.67.67,0,0,0,.67.67h.19v.81a.27.27,0,0,0,.27.27.26.26,0,0,0,.2-.1L12,4h3a.67.67,0,0,0,.67-.67Zm-3.76.31a.16.16,0,0,0-.13.06l-.74.85V3.82a.18.18,0,0,0-.18-.18h-.37a.31.31,0,0,1-.31-.31V.67a.31.31,0,0,1,.31-.31H15a.31.31,0,0,1,.31.31V3.33a.31.31,0,0,1-.31.31Z"
              />
              <path
                class="cls-1"
                d="M13,2.07a.21.21,0,0,1-.22.21.21.21,0,0,1-.21-.21.21.21,0,0,1,.21-.22.22.22,0,0,1,.22.22Z"
              />
              <path
                class="cls-1"
                d="M13.7,2.07a.21.21,0,0,1-.21.21.21.21,0,0,1-.22-.21.22.22,0,0,1,.22-.22.21.21,0,0,1,.21.22Z"
              />
              <path
                class="cls-1"
                d="M12.25,2.07a.21.21,0,0,1-.21.21.21.21,0,0,1-.22-.21A.22.22,0,0,1,12,1.85a.21.21,0,0,1,.21.22Z"
              />
            </g>
          </g>
        </svg>
      );
    case "BusinessOpportunities":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          viewBox="0 0 23.34 23.33"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M23.33.21V23.12c0,.24-.44.21-.71.21H.75c-.56,0-.75-.18-.75-.75Q0,11.65,0,.71C0,.44,0,0,.22,0H23.13C23.38,0,23.33,0,23.33.21ZM.77,11.66q0,5.13,0,10.26c0,.39.08.47.45.3,2.09-.93,3-1.51,5.16-2.5a.52.52,0,0,0,.4-.6q0-7.46,0-14.91a.55.55,0,0,0-.45-.61c-2.15-1-2.95-1.52-5.07-2.48C.86.94.76,1,.76,1.45.78,4.86.77,8.26.77,11.66Zm21.8,0q0-5.13,0-10.26c0-.34,0-.49-.43-.33C20,2,19,2.56,16.94,3.62c-.29.15-.37.27-.37.56q0,7.49,0,15a.49.49,0,0,0,.38.55c2.11,1.12,3.05,1.5,5.17,2.5.39.19.47.09.46-.32Q22.57,16.77,22.57,11.68ZM20.82.79H2.52C4.56,1.72,5,2.08,6.89,3c.44.21.63.38.63.89,0,4.8,0,9.6,0,14.41,0,1.81-.07,1.65-1.71,2.45s-1.71,1-3.25,1.79H20.8c-1.95-1.06-2.51-1.33-4.37-2.2a.78.78,0,0,1-.61-.86q0-7.8,0-15.6c0-.51.21-.64.64-.88A43.08,43.08,0,0,1,20.82.79Z"
              />
              <path
                class="cls-1"
                d="M5.21,11.46c0-.69,0-1.38,0-2.07,0-.29,0-.47.4-.47S6,9.1,6,9.37c0,1.39,0,2.79,0,4.18,0,.3,0,.47-.4.46s-.36-.17-.36-.44C5.21,12.87,5.21,12.16,5.21,11.46Z"
              />
              <path
                class="cls-1"
                d="M16.91,11.78c0-.71,0-1.41,0-2.12,0-.35.17-.33.41-.34s.36,0,.36.33c0,1.47,0,2.93,0,4.4,0,.33-.15.34-.41.34s-.36-.05-.35-.34C16.93,13.3,16.91,12.54,16.91,11.78Z"
              />
              <path
                class="cls-2"
                d="M8.91,10.22V9.89a.14.14,0,0,1,.16-.16h5.67a.14.14,0,0,1,.15.16V13a.14.14,0,0,1-.16.16h-.3v.31c0,.12,0,.17-.18.17H8.61c-.12,0-.17,0-.17-.17v-3c0-.13.05-.17.18-.17Zm4.42.27H9.54a1,1,0,0,1-.82.82v1.2a1,1,0,0,1,.82.81h3.79a1,1,0,0,1,.82-.81v-1.2A1,1,0,0,1,13.33,10.49Zm.92-.27c.13,0,.18,0,.18.17v2.45h.19V10H9.19v.21Zm-5,3.1a.67.67,0,0,0-.53-.53v.53ZM8.72,11a.66.66,0,0,0,.53-.53H8.72Zm4.9-.53a.66.66,0,0,0,.53.53v-.53Zm.53,2.3a.67.67,0,0,0-.53.53h.53Z"
              />
              <path
                class="cls-2"
                d="M11.42,10.66a1.25,1.25,0,1,1-1.24,1.25A1.25,1.25,0,0,1,11.42,10.66Zm1,1.25a1,1,0,0,0-1.93,0,1,1,0,1,0,1.93,0Z"
              />
            </g>
          </g>
        </svg>
      );
    case "CareerOpportunities":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          viewBox="0 0 23.64 22.35"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M16.75,12.84a0,0,0,0,0,0,.06c.31.5.7,1.15,1,1.63.08.13.09.19-.06.28-2.07,1.33-4.14,2.65-6.2,4-.13.07-.2.08-.31,0L7.54,16c-.15-.11-.29-.3-.45-.31s-.3.17-.46.28L.47,20.07c-.12.09-.12.14,0,.25.35.51.71,1,1.05,1.55.08.13.14.12.25,0l5.11-3.46a.22.22,0,0,1,.31,0l3.9,3.05a.21.21,0,0,0,.29,0c2.47-1.6,4.95-3.18,7.43-4.77.28-.18.28-.18.46.1l1,1.52a0,0,0,0,0,.07,0l3-5.77s0-.07,0-.07Z"
              />
              <path
                class="cls-2"
                d="M11.84,5.27v0a.28.28,0,0,1,.05-.11,1.66,1.66,0,0,0,.46-1,1.54,1.54,0,0,0,0-.43,1.06,1.06,0,0,1,0-.19s0,0,0-.07a.83.83,0,0,1,0-.13,2.12,2.12,0,0,0,.06-.87,2.75,2.75,0,0,0-.7-1.65,2.69,2.69,0,0,0-2-.78h0a2.56,2.56,0,0,0-2,.78,2.77,2.77,0,0,0-.66,2.47.37.37,0,0,1,0,.18,1.6,1.6,0,0,0,.27,1.65l0,0a1.06,1.06,0,0,1,.1.17A4.86,4.86,0,0,0,8.1,6.76a1.84,1.84,0,0,0,1.45.67h0A1.85,1.85,0,0,0,11,6.76,5,5,0,0,0,11.84,5.27ZM7.59,3.9h0a.24.24,0,0,0,.18-.12.37.37,0,0,0,0-.31,2.51,2.51,0,0,1,0-1.29A1.62,1.62,0,0,1,9.25.87a3.35,3.35,0,0,1,.86.05,1.58,1.58,0,0,1,1.3,1.38,2.67,2.67,0,0,1-.11,1.23.24.24,0,0,0,.15.33c.06,0,.08.05.07.1a3,3,0,0,1-.16.43h-.09l0,0v0l-.09.27a3.76,3.76,0,0,1-.22.57,6.31,6.31,0,0,1-.56.9.92.92,0,0,1-.75.37,1,1,0,0,1-.84-.36A3.92,3.92,0,0,1,8,4.59l0-.18H7.85a.36.36,0,0,1-.23-.2A.37.37,0,0,1,7.59,3.9Z"
              />
              <path
                class="cls-2"
                d="M7.69,7.3a.89.89,0,0,0-.37.12L4.74,9A1.46,1.46,0,0,0,4,10.21c0,.5,0,1-.05,1.5,0,.21,0,.43,0,.64l0,.63c0,.53,0,1.06,0,1.58a.43.43,0,0,0,.41.48h0a.42.42,0,0,0,.42-.44c0-.22,0-.43,0-.65l0-1.25c0-.82.06-1.64.08-2.45a.65.65,0,0,1,.34-.56L7.56,8.27l.09,0a.55.55,0,0,0,.4-.1.41.41,0,0,0,.13-.29A.57.57,0,0,0,8,7.45.46.46,0,0,0,7.69,7.3Z"
              />
              <path
                class="cls-2"
                d="M15.2,13l0-.54h0l0-.76c0-.5,0-1-.06-1.49A1.47,1.47,0,0,0,14.35,9l-1-.6-.68-.41-.93-.56a.57.57,0,0,0-.49,0,.48.48,0,0,0-.29.33.44.44,0,0,0,.43.59.51.51,0,0,1,.22,0L13,9.13l.9.53a.72.72,0,0,1,.37.63c.05,1.33.09,2.67.14,4v.28a.42.42,0,0,0,.42.46h0a.42.42,0,0,0,.3-.13.5.5,0,0,0,.12-.35C15.23,14,15.22,13.53,15.2,13Z"
              />
              <path
                class="cls-2"
                d="M10.84,14.54l-.06-.38-.16-1.26L10.23,10l0-.21a1.08,1.08,0,0,1,.15-1,.24.24,0,0,0,.05-.1v0a.84.84,0,0,0-.14-.75.69.69,0,0,0-.64-.25H9.37a.78.78,0,0,0-.62.27.75.75,0,0,0-.13.64,1.54,1.54,0,0,0,.15.35.76.76,0,0,1,.09.18.37.37,0,0,1,.05.17c-.11.82-.22,1.63-.32,2.44l-.17,1.22-.21,1.61a.38.38,0,0,0,.33.46h.05A.41.41,0,0,0,9,14.62l.55-4.16v0l0,.27c.09.72.19,1.45.29,2.18.07.58.15,1.18.24,1.78a.4.4,0,0,0,.16.27.35.35,0,0,0,.28.06A.39.39,0,0,0,10.84,14.54ZM9.52,8.82l-.24-.47h.49Z"
              />
            </g>
          </g>
        </svg>
      );
    case "CXONetworking":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          viewBox="0 0 23.62 22.27"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M6.29,15.86h0l0-.06a.76.76,0,0,0,.21-.47.62.62,0,0,0,0-.19s0-.06,0-.09v0s0,0,0-.06a1.07,1.07,0,0,0,0-.4,1.27,1.27,0,0,0-.32-.76,1.26,1.26,0,0,0-.94-.36h0a1.23,1.23,0,0,0-.94.36A1.29,1.29,0,0,0,4,14.93.11.11,0,0,1,4,15a.74.74,0,0,0,.12.76h0a.35.35,0,0,1,.05.08,2.38,2.38,0,0,0,.38.68.85.85,0,0,0,.67.31h0a.85.85,0,0,0,.67-.31A2.16,2.16,0,0,0,6.29,15.86Zm-1.95-.63h0a.19.19,0,0,0,.09-.05.26.26,0,0,0,0-.15,1.05,1.05,0,0,1,0-.59.74.74,0,0,1,.7-.6,1.7,1.7,0,0,1,.4,0,.76.76,0,0,1,.6.64,1.29,1.29,0,0,1,0,.56.12.12,0,0,0,.07.16s0,0,0,0a1.19,1.19,0,0,1-.08.2H6v0a.65.65,0,0,1,0,.13,1.93,1.93,0,0,1-.36.67.4.4,0,0,1-.34.17.45.45,0,0,1-.38-.16,1.82,1.82,0,0,1-.37-.75l0-.08h0a.16.16,0,0,1-.1-.1S4.32,15.25,4.34,15.23Z"
              />
              <path
                class="cls-1"
                fill={props.color || "#ffffff"}
                d="M4.39,16.79a.34.34,0,0,0-.17.05L3,17.55a.65.65,0,0,0-.34.57l0,.69c0,.1,0,.2,0,.3v.29c0,.24,0,.48,0,.73a.19.19,0,0,0,.19.21h0a.19.19,0,0,0,.19-.2c0-.1,0-.2,0-.3l0-.57c0-.38,0-.75,0-1.13a.27.27,0,0,1,.15-.25l1.1-.66h0a.21.21,0,0,0,.18,0A.15.15,0,0,0,4.61,17a.26.26,0,0,0-.07-.19A.22.22,0,0,0,4.39,16.79Z"
              />
              <path
                class="cls-1"
                d="M7.84,19.41v-.25h0v-.34c0-.23,0-.46,0-.69a.7.7,0,0,0-.35-.58L7,17.27l-.31-.19-.43-.25a.23.23,0,0,0-.36.13.21.21,0,0,0,0,.19.23.23,0,0,0,.17.08.16.16,0,0,1,.1,0l.64.38.41.24a.32.32,0,0,1,.17.29L7.46,20v.13a.19.19,0,0,0,.19.21h0a.24.24,0,0,0,.14-.05.29.29,0,0,0,.05-.17C7.85,19.89,7.85,19.65,7.84,19.41Z"
              />
              <path
                class="cls-1"
                fill={props.color || "#ffffff"}
                d="M5.83,20.12a1.1,1.1,0,0,0,0-.18l-.08-.58L5.55,18s0-.06,0-.1a.51.51,0,0,1,.07-.46,0,0,0,0,0,0,0h0a.4.4,0,0,0-.07-.35A.33.33,0,0,0,5.28,17H5.16a.35.35,0,0,0-.28.12.32.32,0,0,0-.06.3.56.56,0,0,0,.07.16s0,0,0,.08a.24.24,0,0,1,0,.08L4.8,18.81l-.08.56-.09.74a.17.17,0,0,0,.15.21h0A.18.18,0,0,0,5,20.15l.26-1.91v0l0,.13c0,.33.08.67.13,1s.07.54.11.82a.16.16,0,0,0,.07.12.16.16,0,0,0,.13,0A.16.16,0,0,0,5.83,20.12Zm-.6-2.63-.11-.22h.22Z"
              />
              <circle
                class="cls-2"
                fill="none"
                stroke={props.color || "#ffffff"}
                cx="5.24"
                cy="17.03"
                r="4.99"
              />
              <path
                class="cls-1"
                fill={props.color || "#ffffff"}
                d="M19.43,15.86h0l0-.06a.76.76,0,0,0,.21-.47.63.63,0,0,0,0-.19s0-.06,0-.09v0a.15.15,0,0,1,0-.06,1.07,1.07,0,0,0,0-.4,1.21,1.21,0,0,0-.32-.76,1.23,1.23,0,0,0-.93-.36h0a1.21,1.21,0,0,0-.94.36,1.24,1.24,0,0,0-.3,1.14.21.21,0,0,1,0,.08.74.74,0,0,0,.12.76h0a.35.35,0,0,1,.05.08,2.19,2.19,0,0,0,.39.68.83.83,0,0,0,.66.31h0a.84.84,0,0,0,.67-.31A2.19,2.19,0,0,0,19.43,15.86Zm-2-.63h0l.08-.05a.26.26,0,0,0,0-.15,1.15,1.15,0,0,1,0-.59.76.76,0,0,1,.71-.6,1.62,1.62,0,0,1,.39,0,.76.76,0,0,1,.6.64,1.16,1.16,0,0,1-.05.56.12.12,0,0,0,.07.16s0,0,0,0a1.19,1.19,0,0,1-.08.2h-.06v0s0,.09-.05.13a1.17,1.17,0,0,1-.1.26,2,2,0,0,1-.25.41.43.43,0,0,1-.35.17.45.45,0,0,1-.38-.16,1.82,1.82,0,0,1-.37-.75l0-.08h0a.24.24,0,0,1-.1-.1A.17.17,0,0,1,17.47,15.23Z"
              />
              <path
                class="cls-1"
                fill={props.color || "#ffffff"}
                d="M17.52,16.79a.34.34,0,0,0-.17.05l-1.19.71a.67.67,0,0,0-.34.57c0,.23,0,.46,0,.69s0,.2,0,.3v.29l0,.73a.2.2,0,0,0,.19.21h0a.2.2,0,0,0,.2-.2c0-.1,0-.2,0-.3l0-.57,0-1.13a.32.32,0,0,1,.15-.25l1.1-.66h0a.24.24,0,0,0,.19,0,.17.17,0,0,0,0-.13.26.26,0,0,0-.06-.19A.27.27,0,0,0,17.52,16.79Z"
              />
              <path
                class="cls-1"
                d="M21,19.41v-.25h0v-.34c0-.23,0-.46,0-.69a.68.68,0,0,0-.35-.58l-.47-.28-.31-.19-.43-.25A.23.23,0,0,0,19,17a.19.19,0,0,0,0,.19.19.19,0,0,0,.16.08.15.15,0,0,1,.1,0c.22.12.43.25.64.38l.41.24a.33.33,0,0,1,.18.29L20.6,20v.13c0,.13.08.21.2.21h0a.22.22,0,0,0,.14-.05.2.2,0,0,0,0-.17C21,19.89,21,19.65,21,19.41Z"
              />
              <path
                class="cls-1"
                fill={props.color || "#ffffff"}
                d="M19,20.12l0-.18-.08-.58c-.06-.45-.12-.89-.17-1.34,0,0,0-.06,0-.1a.51.51,0,0,1,.07-.46s0,0,0,0h0a.4.4,0,0,0-.06-.35.35.35,0,0,0-.3-.11h-.12a.35.35,0,0,0-.28.12.32.32,0,0,0-.06.3.56.56,0,0,0,.07.16s0,0,0,.08a.24.24,0,0,1,0,.08l-.15,1.12-.07.56c0,.25-.07.49-.1.74a.18.18,0,0,0,.15.21h0a.19.19,0,0,0,.18-.17l.25-1.91v0l0,.13c0,.33.09.67.13,1s.07.54.11.82a.17.17,0,0,0,.08.12.13.13,0,0,0,.12,0A.18.18,0,0,0,19,20.12Zm-.61-2.63-.11-.22h.23Z"
              />
              <circle
                class="cls-2"
                fill="none"
                stroke={props.color || "#ffffff"}
                cx="18.37"
                cy="17.03"
                r="4.99"
              />
              <path
                class="cls-1"
                fill={props.color || "#ffffff"}
                d="M12.86,4.07h0l0,0a.76.76,0,0,0,.21-.47.68.68,0,0,0,0-.2.24.24,0,0,1,0-.08v0a.11.11,0,0,1,0-.05,1.1,1.1,0,0,0,0-.4A1.27,1.27,0,0,0,12.75,2a1.19,1.19,0,0,0-.94-.36h0a1.16,1.16,0,0,0-.94.36,1.27,1.27,0,0,0-.3,1.13s0,.07,0,.08A.75.75,0,0,0,10.7,4h0a.3.3,0,0,1,0,.08,2.46,2.46,0,0,0,.39.69.86.86,0,0,0,.67.3h0a.86.86,0,0,0,.66-.3A2.24,2.24,0,0,0,12.86,4.07Zm-1.95-.63h0A.1.1,0,0,0,11,3.38s0-.1,0-.14a1.16,1.16,0,0,1,0-.59.72.72,0,0,1,.7-.6,1.7,1.7,0,0,1,.4,0,.72.72,0,0,1,.59.63,1.21,1.21,0,0,1-.05.57s0,.12.07.15,0,0,0,.05a2,2,0,0,1-.07.19h-.06v0s0,.08,0,.12a1.57,1.57,0,0,1-.1.26,3.19,3.19,0,0,1-.26.42.47.47,0,0,1-.34.17.51.51,0,0,1-.39-.17,1.85,1.85,0,0,1-.37-.74l0-.08h0s-.08,0-.11-.09S10.89,3.47,10.91,3.44Z"
              />
              <path
                class="cls-1"
                d="M11,5a.56.56,0,0,0-.17.06l-1.18.7a.68.68,0,0,0-.35.58c0,.23,0,.46,0,.69s0,.19,0,.29v.29c0,.25,0,.49,0,.73a.2.2,0,0,0,.19.22h0a.19.19,0,0,0,.2-.2c0-.1,0-.2,0-.3l0-.58c0-.37,0-.75,0-1.12A.29.29,0,0,1,9.8,6.1l1.09-.65h0a.3.3,0,0,0,.18-.05.22.22,0,0,0,.06-.13.26.26,0,0,0-.07-.19A.24.24,0,0,0,11,5Z"
              />
              <path
                class="cls-1"
                d="M14.4,7.63V7.38h0V7l0-.69A.68.68,0,0,0,14,5.76l-.46-.28-.32-.19L12.8,5a.25.25,0,0,0-.22,0,.22.22,0,0,0-.13.15.24.24,0,0,0,0,.2.21.21,0,0,0,.17.07l.09,0,.65.38c.13.09.27.17.41.25a.32.32,0,0,1,.17.28L14,8.22v.13a.18.18,0,0,0,.19.21h0a.16.16,0,0,0,.13-.06.23.23,0,0,0,.06-.16Z"
              />
              <path
                class="cls-1"
                fill={props.color || "#ffffff"}
                d="M12.4,8.33a1.1,1.1,0,0,1,0-.18l-.08-.58-.18-1.34s0-.06,0-.09a.47.47,0,0,1,.07-.46s0,0,0,0h0a.38.38,0,0,0-.06-.34.32.32,0,0,0-.29-.12h-.12a.38.38,0,0,0-.29.13.36.36,0,0,0-.06.29.77.77,0,0,0,.07.16.59.59,0,0,1,0,.08s0,.06,0,.08c0,.38-.09.75-.14,1.12l-.08.56c0,.25-.07.5-.1.74a.19.19,0,0,0,.15.22h0a.18.18,0,0,0,.17-.18l.25-1.91h0v.12c0,.34.09.67.13,1l.12.83a.15.15,0,0,0,.07.12.28.28,0,0,0,.13,0A.19.19,0,0,0,12.4,8.33ZM11.8,5.7l-.12-.21h.23Z"
              />
              <circle
                class="cls-2"
                fill="none"
                stroke={props.color || "#ffffff"}
                cx="11.81"
                cy="5.24"
                r="4.99"
              />
              <line
                class="cls-2"
                fill="none"
                stroke={props.color || "#ffffff"}
                x1="10.23"
                y1="17.25"
                x2="13.39"
                y2="17.27"
              />
              <line
                class="cls-2"
                fill="none"
                stroke={props.color || "#ffffff"}
                x1="7.85"
                y1="12.77"
                x2="9.61"
                y2="9.61"
              />
              <line
                class="cls-2"
                fill="none"
                stroke={props.color || "#ffffff"}
                x1="14.06"
                y1="9.61"
                x2="15.78"
                y2="12.77"
              />
            </g>
          </g>
        </svg>
      );
    case "FundRaise":
      return (
        <svg
          fill={props.color || "#ffffff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23.78 22.33"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M12.89,18.21a14.18,14.18,0,0,1-4.58-.5,1.8,1.8,0,0,1-.22-.06.47.47,0,0,1-.31-.58.43.43,0,0,1,.51-.4c.51.08,1,.17,1.51.28a17.78,17.78,0,0,0,4,.19c.27,0,.54,0,.81,0a1.18,1.18,0,0,0,.53-.12.64.64,0,0,0,.36-.63.58.58,0,0,0-.4-.6,4,4,0,0,0-1.29-.2,9.27,9.27,0,0,1-3-.45A3.92,3.92,0,0,1,9.13,14a2.61,2.61,0,0,0-1.73-.89,5.58,5.58,0,0,0-3.27.45C3.06,14.05,2,14.49.9,15c-.23.1-.45.2-.68,0s-.27-.74.21-1c1.1-.52,2.2-1.06,3.35-1.49A8.67,8.67,0,0,1,6.06,12a4.76,4.76,0,0,1,4.11,1.51,2,2,0,0,0,1.76.75,2.23,2.23,0,0,0,1-.38c.82-.49,1.59-1,2.44-1.48a2.61,2.61,0,0,1,3,.11.67.67,0,0,0,.56.08,2.31,2.31,0,0,1,2.12.53.49.49,0,0,0,.47.06,2.48,2.48,0,0,1,1.21,0,1.49,1.49,0,0,1,1,2.11,3.31,3.31,0,0,1-1.23,1.26Q18.72,18.93,15,21.29c-.32.2-.64.4-1,.58a3.66,3.66,0,0,1-3.49,0c-1.41-.69-2.82-1.4-4.21-2.13a1.67,1.67,0,0,0-2,.1,7.3,7.3,0,0,1-.95.61.51.51,0,0,1-.76-.11.5.5,0,0,1,.16-.75,17.66,17.66,0,0,1,1.66-1,2.16,2.16,0,0,1,2.05.08c1.35.69,2.71,1.35,4,2.07a3.23,3.23,0,0,0,3.55-.11c2.6-1.67,5.2-3.34,7.77-5.05a3.39,3.39,0,0,0,.75-.66.41.41,0,0,0,.13-.49.5.5,0,0,0-.49-.33,2.57,2.57,0,0,0-1.21.35c-.79.42-1.51.95-2.26,1.44S17.08,17,16.28,17.52a3.46,3.46,0,0,1-2.25.7C13.65,18.2,13.27,18.21,12.89,18.21Zm7.18-4.44a1.13,1.13,0,0,0-1.28,0c-.67.39-1.32.78-2,1.19s-.61.38-.32,1a1.06,1.06,0,0,0,.09.13Zm-6.23.75a1.91,1.91,0,0,0,1.29.11,9.78,9.78,0,0,0,2.34-1.41C16,12.8,15,13.88,13.84,14.52Z"
              />
              <path
                class="cls-2"
                d="M5.31,1.15V.38A.33.33,0,0,1,5.68,0H19.22a.33.33,0,0,1,.37.37V7.7a.34.34,0,0,1-.39.38h-.73v.74c0,.29-.12.4-.41.4H4.59c-.28,0-.4-.11-.4-.4V1.56c0-.3.12-.41.41-.41Zm10.55.66H6.8A2.36,2.36,0,0,1,4.85,3.76V6.62a2.32,2.32,0,0,1,2,1.94h9.06A2.33,2.33,0,0,1,17.8,6.62V3.76A2.36,2.36,0,0,1,15.86,1.81Zm2.19-.66c.31,0,.42.11.42.42V7.41h.46V.66H6v.49ZM6.12,8.57A1.61,1.61,0,0,0,4.86,7.3V8.57ZM4.84,3.08A1.61,1.61,0,0,0,6.11,1.8H4.84ZM16.55,1.81a1.58,1.58,0,0,0,1.26,1.26V1.81ZM17.81,7.3a1.59,1.59,0,0,0-1.26,1.26h1.26Z"
              />
              <path
                class="cls-2"
                d="M11.31,2.22a3,3,0,1,1-3,3A3,3,0,0,1,11.31,2.22Zm2.3,3a2.31,2.31,0,1,0-2.3,2.31A2.32,2.32,0,0,0,13.61,5.19Z"
              />
            </g>
          </g>
        </svg>
      );
    case "HiringCXOs":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          viewBox="0 0 23.38 23.38"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M15.38,15.23a7.59,7.59,0,1,0-.15.15l.07-.08Zm2.36.86L23,21.38A1.17,1.17,0,1,1,21.38,23l-5.29-5.3a9.92,9.92,0,1,1,1.65-1.65Z"
              />
              <path
                class="cls-1"
                d="M11.68,7.83h0a.73.73,0,0,1,.05-.09A1.3,1.3,0,0,0,12.07,7a.69.69,0,0,0,0-.32l0-.14a.13.13,0,0,0,0-.06s0-.07,0-.09a1.88,1.88,0,0,0,0-.67,2,2,0,0,0-.53-1.25,2,2,0,0,0-1.55-.6h0a2,2,0,0,0-1.56.6,2.09,2.09,0,0,0-.49,1.87s0,.11,0,.14a1.22,1.22,0,0,0,.2,1.25l0,0a.83.83,0,0,1,.08.12A4.1,4.1,0,0,0,8.83,9a1.43,1.43,0,0,0,1.11.5h0A1.43,1.43,0,0,0,11,9,3.65,3.65,0,0,0,11.68,7.83Zm-3.24-1h0a.16.16,0,0,0,.13-.09.29.29,0,0,0,0-.24,1.84,1.84,0,0,1-.05-1,1.23,1.23,0,0,1,1.17-1,2.27,2.27,0,0,1,.65,0,1.21,1.21,0,0,1,1,1.05,2,2,0,0,1-.08.94.19.19,0,0,0,.12.25.06.06,0,0,1,0,.07c0,.12-.09.24-.13.33h-.1v0a1.59,1.59,0,0,0-.07.2,2.87,2.87,0,0,1-.17.44,4,4,0,0,1-.43.68.69.69,0,0,1-.56.28.79.79,0,0,1-.64-.27,2.91,2.91,0,0,1-.61-1.23l0-.14h0c-.06,0-.12-.07-.17-.16A.3.3,0,0,1,8.44,6.79Z"
              />
              <path
                class="cls-1"
                d="M8.52,9.38a.57.57,0,0,0-.28.09c-.71.41-1.35.8-2,1.17a1.09,1.09,0,0,0-.57.95c0,.38,0,.76,0,1.14,0,.16,0,.32,0,.49v.48c0,.4,0,.8,0,1.2a.31.31,0,0,0,.31.36h0a.32.32,0,0,0,.33-.33c0-.16,0-.33,0-.49l0-1,.06-1.86a.47.47,0,0,1,.25-.42l1.81-1.08a.11.11,0,0,1,.07,0A.42.42,0,0,0,8.8,10a.27.27,0,0,0,.09-.22.38.38,0,0,0-.11-.3A.38.38,0,0,0,8.52,9.38Z"
              />
              <path
                class="cls-1"
                d="M14.23,13.72v-.41h0l0-.58c0-.38,0-.76,0-1.13a1.12,1.12,0,0,0-.57-1l-.77-.46-.52-.31-.71-.42a.45.45,0,0,0-.37,0,.42.42,0,0,0-.23.26.37.37,0,0,0,.06.32.31.31,0,0,0,.27.12.39.39,0,0,1,.17,0l1.06.63.68.4a.56.56,0,0,1,.29.48c0,1,.06,2,.1,3v.21a.32.32,0,0,0,.33.35h0a.32.32,0,0,0,.23-.09.36.36,0,0,0,.09-.27C14.26,14.5,14.25,14.11,14.23,13.72Z"
              />
              <path
                class="cls-1"
                d="M10.91,14.89l0-.3-.13-1-.3-2.22a.77.77,0,0,0,0-.15.79.79,0,0,1,.12-.76.16.16,0,0,0,0-.08v0a.62.62,0,0,0-.11-.57A.52.52,0,0,0,10,9.64H9.8a.58.58,0,0,0-.47.21.54.54,0,0,0-.1.48,1.11,1.11,0,0,0,.12.27c0,.05.05.09.07.14a.61.61,0,0,1,0,.13c-.08.62-.16,1.24-.25,1.85l-.12.93c-.05.41-.11.82-.16,1.22a.29.29,0,0,0,.25.36h0a.3.3,0,0,0,.29-.29l.42-3.16v0l0,.21c.08.55.15,1.11.22,1.66s.12.9.19,1.36a.28.28,0,0,0,.12.2.3.3,0,0,0,.21.05A.28.28,0,0,0,10.91,14.89Zm-1-4.36-.19-.35h.37Z"
              />
            </g>
          </g>
        </svg>
      );
    case "IndustryEvents":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          viewBox="0 0 23.34 22.88"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M11.64,20.07H3.27a.49.49,0,0,1-.49-.21.55.55,0,0,1-.07-.33v-10c0-.43.16-.6.58-.6H20a.59.59,0,0,1,.49.17.47.47,0,0,1,.11.34V11.6c0,2.63,0,5.26,0,7.89a.52.52,0,0,1-.59.58c-2.79,0-5.59,0-8.39,0Zm8-10.23H3.76c-.08,0-.12.05-.13.13V18.8c0,.41,0,.37.37.37H19.45c.23,0,.24,0,.24-.28V10.31A2,2,0,0,0,19.66,9.84Z"
              />
              <path
                class="cls-1"
                d="M15,14a.35.35,0,0,1-.13.27l-.48.47-.72.7a.26.26,0,0,0-.09.25c.07.39.13.78.2,1.17l.09.51a.36.36,0,0,1-.49.42,4.16,4.16,0,0,1-.52-.25L11.79,17a.24.24,0,0,0-.26,0l-1.44.76a.55.55,0,0,1-.25.07.37.37,0,0,1-.35-.46c.08-.5.17-1,.26-1.5a.48.48,0,0,0-.15-.51c-.38-.34-.74-.71-1.11-1.07a.38.38,0,0,1,0-.61.44.44,0,0,1,.16-.06l1.65-.23a.33.33,0,0,0,.27-.2l.67-1.38a.68.68,0,0,1,.18-.26.38.38,0,0,1,.56.15c.25.5.5,1,.73,1.49a.35.35,0,0,0,.28.2l1.66.23A.4.4,0,0,1,15,14Zm-5.41.25,0,.08c.28.24.51.51.78.75a.46.46,0,0,1,.15.48l-.15.92c0,.07-.05.14,0,.21s.1,0,.15,0l.89-.46a.43.43,0,0,1,.44,0l.87.46c.05,0,.11.08.18.05s0-.15,0-.22c0-.31-.11-.62-.16-.93a.45.45,0,0,1,.15-.45c.26-.25.5-.51.77-.75,0,0,0-.05,0-.11l-1.11-.16a.45.45,0,0,1-.4-.27c-.14-.3-.3-.6-.44-.9,0-.06,0-.11-.12-.13a.34.34,0,0,0-.09.14l-.45.89a.46.46,0,0,1-.4.28c-.25,0-.51.06-.76.1A3.1,3.1,0,0,0,9.58,14.23Z"
              />
              <path
                class="cls-1"
                d="M21.82,2H18.34V.61A.61.61,0,0,0,17.72,0h0a.62.62,0,0,0-.62.61,2.44,2.44,0,0,0,0,.27V2H6.24V.72C6.24.28,6,0,5.62,0a.6.6,0,0,0-.43.17A.72.72,0,0,0,5,.71V2H1.55A1.41,1.41,0,0,0,0,3.52V21.34a1.39,1.39,0,0,0,1.52,1.54H21.81a1.39,1.39,0,0,0,1.53-1.53V3.5A1.39,1.39,0,0,0,21.82,2ZM5.43,5.14A.57.57,0,0,0,6,5.07a.66.66,0,0,0,.27-.55c0-.33,0-.67,0-1V3.24H17.09v.24c0,.34,0,.68,0,1a.61.61,0,0,0,.44.65.6.6,0,0,0,.55-.07.68.68,0,0,0,.26-.55c0-.36,0-.73,0-1.1V3.21H21.8c.28,0,.29,0,.29.3V6.33H1.25v-3c0-.09,0-.13.13-.13H5V4.49A.61.61,0,0,0,5.43,5.14ZM22.09,7.62V21.31c0,.32,0,.32-.31.32H1.56c-.31,0-.31,0-.31-.27V7.62Z"
              />
            </g>
          </g>
        </svg>
      );
    case "InvestmentOpportunities":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          viewBox="0 0 11.58 23.69"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M6.16,5.58V9.93c.06,0,.08-.08.11-.13A3.8,3.8,0,0,1,9.55,8.39c.21,0,.33.15.33.39a3.75,3.75,0,0,1-3.66,3.68s0,0-.07,0v1.32H11.1c.34,0,.49.15.48.48,0,.6,0,1.2,0,1.8,0,.32-.12.44-.43.44a1.1,1.1,0,0,0-.31,0l-.24,1.22c-.31,1.67-.63,3.34-.95,5,0,.2-.07.39-.11.59a.34.34,0,0,1-.36.29H4.8c0-.22,0-.44,0-.67H8.89c.08-.17,1.21-6.18,1.25-6.45H3.78v-.68h7.1V14.51H3.1v-.68H5.45V12.48a6,6,0,0,1-.93-.14A3.75,3.75,0,0,1,1.76,9.21c0-.15,0-.3,0-.46a.35.35,0,0,1,.36-.36A3.81,3.81,0,0,1,5.45,10V5.59m0,6.18a3,3,0,0,0-3-2.68A3,3,0,0,0,5.43,11.77Zm.75,0a3,3,0,0,0,3-2.69A3,3,0,0,0,6.18,11.77Z"
              />
              <path
                class="cls-1"
                d="M6.14,5.61V10A3.79,3.79,0,0,1,9.49,8.41a.34.34,0,0,1,.36.36,2.37,2.37,0,0,1,0,.46,3.76,3.76,0,0,1-2.76,3.13,5,5,0,0,1-.93.13v1.36H8.48v.67H.7v1.37H7.8v.67H1.45c0,.27,1.17,6.28,1.24,6.46H6.8c0,.23,0,.45,0,.67H2.4a.35.35,0,0,1-.36-.3c0-.19-.07-.39-.11-.58-.32-1.67-.63-3.34-.95-5L.74,16.57a1,1,0,0,0-.3,0c-.32,0-.43-.12-.43-.44,0-.6,0-1.2,0-1.8,0-.33.14-.49.48-.49,1.57,0,3.14,0,4.71,0h.24V12.52s0,0-.07,0a3.68,3.68,0,0,1-3-1.59,3.66,3.66,0,0,1-.69-2.1A.34.34,0,0,1,2,8.41,3.79,3.79,0,0,1,5.31,9.82s.05.11.12.12V5.6M9.14,9.11a3,3,0,0,0-3,2.68A3,3,0,0,0,9.14,9.11Zm-6.71,0a3,3,0,0,0,3,2.69A3,3,0,0,0,2.43,9.1Z"
              />
              <path
                class="cls-1"
                d="M1.92.7V.23C1.93.08,2,0,2.15,0h8.22a.2.2,0,0,1,.22.23V4.67a.21.21,0,0,1-.23.23H9.91v.45c0,.18-.07.25-.24.25H1.49c-.17,0-.24-.07-.24-.25V.94c0-.17.07-.24.25-.24Zm6.41.4H2.83A1.42,1.42,0,0,1,1.65,2.28V4A1.43,1.43,0,0,1,2.83,5.2h5.5A1.41,1.41,0,0,1,9.51,4V2.28A1.42,1.42,0,0,1,8.33,1.1ZM9.66.7c.19,0,.25.06.25.25V4.5h.28V.4H2.33V.7ZM2.42,5.2a1,1,0,0,0-.77-.77V5.2ZM1.64,1.87a1,1,0,0,0,.77-.78H1.64ZM8.75,1.1a1,1,0,0,0,.76.77V1.1Zm.76,3.33a1,1,0,0,0-.76.77h.76Z"
              />
              <path
                class="cls-1"
                d="M5.57,1.35a1.8,1.8,0,1,1-1.8,1.8A1.81,1.81,0,0,1,5.57,1.35ZM7,3.15a1.41,1.41,0,1,0-1.4,1.4A1.41,1.41,0,0,0,7,3.15Z"
              />
            </g>
          </g>
        </svg>
      );
    case "NewsContent":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          width={props.width || "36"}
          viewBox="0 0 22.54 22.53"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M22.54,2.64V22s-.05.07,0,.12a.56.56,0,0,1-.25.35.84.84,0,0,1-.49.1H5.31a.63.63,0,0,1-.47-.2.73.73,0,0,1-.2-.6V13.35a.42.42,0,0,0,0-.22.83.83,0,0,0-.22,0H.68A.62.62,0,0,1,0,12.45C0,9.26,0,6.07,0,2.88A2.92,2.92,0,0,1,2.53,0a.77.77,0,0,0,.25,0H19.9c0,.07.06,0,.1.05a3.09,3.09,0,0,1,2.49,2.49S22.47,2.63,22.54,2.64ZM21.21,21.2V8.37H6V21.2ZM21.22,7V3.14a1.79,1.79,0,0,0-1.83-1.82H5.51s0,0,0,.06A3.43,3.43,0,0,1,6,3.27C6,4.44,6,5.61,6,6.79,6,6.86,6,7,6,7ZM1.33,11.78H4.64v-.39c0-1.23,0-2.46,0-3.7s0-2.42,0-3.63c0-.4,0-.8,0-1.21A1.63,1.63,0,0,0,3.07,1.32,1.65,1.65,0,0,0,1.33,3c0,2.33,0,4.66,0,7Z"
              />
              <path
                class="cls-1"
                d="M17.2,16.24H15.11a.64.64,0,0,1-.72-.72V11.65a.63.63,0,0,1,.71-.72h4.2a.65.65,0,0,1,.73.74v3.81a.69.69,0,0,1-.76.76Zm1.49-1.34V12.28h-3V14.9Z"
              />
              <path
                class="cls-1"
                d="M13.85,18h5.3a.66.66,0,0,1,.07,1.31l-.24,0H8.76a1.08,1.08,0,0,1-.35,0A.61.61,0,0,1,8,18.7a.62.62,0,0,1,.43-.64,1.23,1.23,0,0,1,.33,0Z"
              />
              <path
                class="cls-1"
                d="M10.32,12.59H8.63A.65.65,0,0,1,8,11.7a.62.62,0,0,1,.64-.43H12a.64.64,0,0,1,.67.85.57.57,0,0,1-.6.47Z"
              />
              <path
                class="cls-1"
                d="M10.34,16H8.62A.62.62,0,0,1,8,15.29a.6.6,0,0,1,.64-.64H12a.6.6,0,0,1,.63.58.65.65,0,0,1-.44.72A.75.75,0,0,1,12,16Z"
              />
            </g>
          </g>
        </svg>
      );
    case "Others":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          width={props.width || "36"}
          viewBox="0 0 123.6 123.59"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M123.57,63c1,28.19-26.2,61.43-61.44,60.57C22.4,122.6-.22,93.38,0,61.27.26,24.16,28,.23,62.07,0,92.8-.2,123,22.27,123.57,63ZM61.49,115.63c29.58.07,54.05-24.21,54.12-53.71C115.68,32.2,91,7.5,61.37,7.68,32,7.86,7.56,32.63,7.66,62,7.76,91.21,32.21,115.56,61.49,115.63Z"
              />
              <path
                class="cls-1"
                d="M57.08,73.72c0-5,0-9.91,0-14.86,0-2.84.88-5.09,4.07-5,2.9.07,3.75,2.29,3.76,4.86q0,15.12,0,30.23c0,2.59-.9,4.76-3.82,4.75-3.19,0-4-2.27-4-5.09C57.11,83.63,57.08,78.68,57.08,73.72Z"
              />
              <path
                class="cls-1"
                d="M69,37.08a6,6,0,0,1-6.59,6.28c-3.95-.16-6-2.39-6.14-6.23a6.39,6.39,0,0,1,6.62-6.44A6.3,6.3,0,0,1,69,37.08Z"
              />
            </g>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={props.color || "#ffffff"}
          width={props.width || "36"}
          viewBox="0 0 123.6 123.59"
        >
          {" "}
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="M123.57,63c1,28.19-26.2,61.43-61.44,60.57C22.4,122.6-.22,93.38,0,61.27.26,24.16,28,.23,62.07,0,92.8-.2,123,22.27,123.57,63ZM61.49,115.63c29.58.07,54.05-24.21,54.12-53.71C115.68,32.2,91,7.5,61.37,7.68,32,7.86,7.56,32.63,7.66,62,7.76,91.21,32.21,115.56,61.49,115.63Z"
              />
              <path
                class="cls-1"
                d="M57.08,73.72c0-5,0-9.91,0-14.86,0-2.84.88-5.09,4.07-5,2.9.07,3.75,2.29,3.76,4.86q0,15.12,0,30.23c0,2.59-.9,4.76-3.82,4.75-3.19,0-4-2.27-4-5.09C57.11,83.63,57.08,78.68,57.08,73.72Z"
              />
              <path
                class="cls-1"
                d="M69,37.08a6,6,0,0,1-6.59,6.28c-3.95-.16-6-2.39-6.14-6.23a6.39,6.39,0,0,1,6.62-6.44A6.3,6.3,0,0,1,69,37.08Z"
              />
            </g>
          </g>
        </svg>
      );
  }
};

export default InterestIcon;
