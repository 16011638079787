import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Paper, Grid, Box, Typography, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Navigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import BottomNavigator from "../../components/BottomNavigator/BottomNavigator";
import ProfileShort from "../../components/ProfileShort/ProfileShort";
import CardShort from "../../components/CardShort/CardShort";
import CardLong from "../../components/CardLong/CardLong";
import PeopleuMayKnow from "../../components/PeopleuMayKnow/PeopleuMayKnow";
import ShareUrExperience from "../../components/ShareUrExperience/ShareUrExperience";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import UnderVerification from "../../components/UnderVerification/UnderVerification";
import EditorComponent from "../../components/Editor/Editor";
import UpdatePostComponent from "../../components/UpdatePost/UpdatePost";
import PollComponent from "../../components/Poll/Poll";
import ArticleEditor from "../../components/ArticleEditor/ArticleEditor";
import ReportPostComponent from "../../components/ReportPost/ReportPost";
import SharePostComponent from "../../components/SharePost/SharePost";
import Helpandfeedback from "../../components/Helpandfeedback/Helpandfeedback";
import QAForm from "../../components/Q&AForm/CreateForm"; 
import QueryForm from "../../components/Q&AForm/CreateQueryForm";
import ChatModule from "../ChatModule/ChatModule";
import OneToOneChat from "../ChatModule/OneToOneChat";
import HomePost from "./HomePost";
import HomeNetwork from "./HomeNetwork";
import HomeArticle from "./HomeArticle";
import HomeClub from "./HomeClub";
import HomeEvent from "./HomeEvent";
import AdsCard from "../../components/AdsCard/AdsCard";
import { useNavigate } from "react-router-dom";

const HomePageContainer = (theme) => {
  const navigate = useNavigate();
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const openPost = useSelector((state) => state?.post?.PostEdit);
  const openSurvey = useSelector((state) => state?.survey?.SurveyEdit); 
  const openQuery = useSelector((state) => state?.survey?.openQuery);
  const openPoll = useSelector((state) => state?.post?.PollEdit);
  const reportPost = useSelector((state) => state?.post?.ReportPost);
  const openArticle = useSelector((state) => state?.article?.ArticleEdit);
  const sharePost = useSelector((state) => state?.post?.SharePost);
  const updPost = useSelector((state) => state?.post?.UpdateEditPost);
  const feedbackShow = useSelector((state) => state?.post?.FeedbackShow);
  const underVerification = useSelector(
    (state) => state?.message?.underVerification
  );

  const redirect = () => {
    navigate("/login");
  };
  return (
    <Fragment>
      <Helmet>
        <title>LinkCxO - Exclusive CxO Networking</title>
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
        <Header />
      </Box>

      {openSnackbar && <CustomizedSnackbars />}
      {openPost && <EditorComponent />}
      {openPoll && <PollComponent />}
      {openArticle && <ArticleEditor />}
      {underVerification && <UnderVerification />}
      {reportPost && <ReportPostComponent />}
      {sharePost && <SharePostComponent />}
      {updPost && <UpdatePostComponent />}
      {feedbackShow && <Helpandfeedback />}
      {openSurvey && <QAForm />}  
      {openQuery && <QueryForm />} 
      <Box
        pt={8}
        sx={{
          display: { md: "flex", xs: "none" },
          justifyContent: "center",
          height: "calc(100vh - 65px )",
          width: "1280px",
          margin: "auto",
          // filter: "blur(20px)",
        }}
      >
        <div class="wrapper_home">
          <aside>
            <Box
              py={1}
              sx={{ display: { md: "block", xs: "none" }, width: "100% "}}
            >
              <ProfileShort />
              <HomeEvent />
              <HomeClub />
              <AdsCard interval={20000} position="left"/> 
            </Box>
          </aside>
          <main>
            <div class="wrapper_inner">
              <Box px={1}>
                <ShareUrExperience />
              </Box>
              <HomePost />
            </div>
          </main>
          <aside >
            <Box>
            <Paper
              sx={{
                marginTop: "8px",
                marginBottom:"8px",
                backgroundColor: "#232121",
                backgroundImage: "none",
                height: "calc(100vh - 65px )",
              }}
            >
              <HomeNetwork />
              <AdsCard interval={30000}  position="right"/>
            </Paper>

            </Box>
          </aside>
        </div>
      </Box>

      <Box
        sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center" }}
      >
        <Grid container sx={{ maxWidth: "1228px", justifyContent: "center" }}>
          <Grid item xs={12} sm={10} md={12}>
            <Box px={1}>
              <ShareUrExperience />
            </Box>
            <HomePost />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: "0%",
          width: "100%",
          zIndex: 2001,
        }}
      >
        <BottomNavigator />
      </Box>
    </Fragment>
  );
};

export default React.memo(HomePageContainer);

// <Box
//   sx={{
//     display: { xs: "block", md: "none" },
//     position: "fixed",
//     bottom: "0%",
//     width: "100%",
//     zIndex: 2001,
//   }}
// >
//   <BottomNavigator />
// </Box>

// <Grid
//   container
//   sx={{
//     maxWidth: { xs: "100%", md: "1280px" },
//     justifyContent: "center",
//   }}
// >
//   <Grid
//     item
//     xs={12}
//     md={3}
//     sm={8}
//     sx={{ display: { xs: "none", md: "block" },  flex:"1 0 25%"  }}
//   >
//     <Box py={1}>
//       <ProfileShort />
//     </Box>
//   </Grid>
//   <Grid item xs={12} sm={10} md={6}>
//     <Box pt={1} px={1}>
//       <ShareUrExperience />
//     </Box>
//     <HomePost />
//   </Grid>
//   <Grid item xs={12} sm={8} md={3}>
//     <Paper
//       sx={{
//         marginTop: "8px",
//         backgroundColor: "#232121",
//         backgroundImage: "none",
//       }}
//     >
//       <HomeNetwork />
//     </Paper>
//   </Grid>
// </Grid>

// <Box sx={{ display: "flex", justifyContent: "center" }}>
//   <Grid container sx={{ maxWidth: "1228px", justifyContent: "center" }}>
//     <Grid item xs={12} md={3} sm={8}>
//       <Box py={1}>
//         <ProfileShort />
//       </Box>
//       <Paper sx={{ backgroundColor: "#232121" }}>
//         <HomeArticle />
//       </Paper>
//     </Grid>
//     <Grid item xs={12} sm={10} md={6}>
//       <Box pt={1} px={1}>
//         <ShareUrExperience />
//       </Box>
//       <HomePost />
//     </Grid>
//     <Grid item xs={12} sm={8} md={3}>
//       <Paper sx={{ marginTop: "8px", backgroundColor: "#232121" }}>
//         <HomeNetwork />
//       </Paper>
//     </Grid>
//   </Grid>
// </Box>
// <Box
//   sx={{
//     display: { xs: "block", md: "none" },
//     position: "fixed",
//     bottom: "0%",
//     width: "100%",
//   }}
// >
//   <BottomNavigator />
// </Box>

//
// <Paper sx={{ backgroundColor: "#232121", backgroundImage: "none" }}>
//   <HomeArticle />
// </Paper>

//
// <Box sx={{ position: "fixed", bottom: "0", zIndex: 2001, right: "0" }}>
// <Stack direction="row">
//   <OneToOneChat />
//   <ChatModule />
//   </Stack>
// </Box>
