import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import * as usernetworkActions from '../../store/usernetwork';
import * as eventsActions from '../../store/events';
import * as clubsActions from '../../store/clubs';
import EventCard from '../../components/EventCard/EventCard';
import ClubCard from '../../components/ClubCard/ClubCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const MyClubPassed = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [noofSavedPassed, setNoofSavedPassed] = React.useState(2);
  const [passedEvent, setPassedEvent] = React.useState([]);

  const user = useSelector((state) => state?.user?.UserDetails);
  const SavedPassedlist = useSelector(
    (state) => state?.events?.eventSavedPassedList,
  );
  const myClubList = useSelector((state) => state?.clubs?.myClubList);
  const savedClubList = useSelector((state) => state?.clubs?.savedClubList);
  const eventPassedHosted = useSelector(
    (state) => state?.events?.eventHostedPassedList,
  );
  const allUserList = useSelector((state) => state?.usernetwork?.AllUserList);
  useEffect(async () => {
    const data = {
      user_id: user?.id,
      page_no: 0,
    };
    const eventList = await dispatch(eventsActions.savedEventPassed(data));
    // if (eventList?.status !== -1) {
    //   setPassedEvent([...passedEvent, ...eventList?.data]);
    // }
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const eventList = await dispatch(eventsActions.hostedEventPassed(data));
    if (eventList?.status !== -1) {
      setPassedEvent([...passedEvent, ...eventList?.data]);
    }
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const clubList = await dispatch(clubsActions.savedClub(data));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const seeMoreClubsave = async () => {};
  const seeMore = () => {
    if (savedClubList?.length > noofSavedPassed) {
      setNoofSavedPassed(savedClubList?.length);
    } else if (savedClubList?.length === noofSavedPassed) {
      setNoofSavedPassed(2);
    }
  };
  return (
    <Fragment>
      <Box px={1}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <InfiniteScroll
              dataLength={savedClubList?.length ? savedClubList?.length : 0}
              next={() => seeMoreClubsave()}
              hasMore={true}
              loader={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreClubsave}></Box>
              }
              height={savedClubList?.length > 2 ? 'calc(90vh - 70px)' : 'auto'}
              endMessage={
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreClubsave}>
                  <Box>End</Box>
                </Box>
              }>
              <Grid container>
                {savedClubList?.length > 0 &&
                  savedClubList?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={12}
                        sx={{
                          marginTop: '8px',
                        }}>
                        <ClubCard clubs={list} type="myclubs" />
                      </Grid>
                    );
                  })}
                {savedClubList?.length == 0 && (
                  <Box pl={3} pt={3}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '12px',
                        },
                      }}>
                      There is no club.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default React.memo(MyClubPassed);
