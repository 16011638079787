import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import RewardPartnerCard from '../../components/RewardPartnerCard/RewardPartnerCard';
import RewardLinkcxoCategory from '../../components/RewardLinkcxoCategory/RewardLinkcxoCategory';
import RewardPartnerVoucherCard from '../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import * as rewardsActions from '../../store/rewards';
import { dateTime } from '../../constants/app';
const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));

const VoucherRewards = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);

  const rewardsPartnerList = useSelector(
    (state) => state?.rewards?.rewardsPartnerList,
  );
  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );
  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const rewardsReedemedVoucherList = useSelector(
    (state) => state?.rewards?.rewardsReedemedVoucherList,
  );
  const rewardsExpiredVoucherList = useSelector(
    (state) => state?.rewards?.rewardsExpiredVoucherList,
  );

  const rewardsPurchasedVoucherList = useSelector(
    (state) => state?.rewards?.rewardsPurchasedVoucherList,
  );

  const rewardRedeemVoucherloading = useSelector(
    (state) => state?.rewards?.rewardRedeemVoucherloading,
  );
  const [noofPurchased, setNoofPurchased] = React.useState(4);
  const [noofRedeemed, setNoofRedeemed] = React.useState(4);
  const [noofExpired, setNoofExpired] = React.useState(4);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      datetime: dateTime(),
    };
    const rewardredemedvoucherlist = await dispatch(
      rewardsActions.rewardsExpiredVoucherList(data),
    );
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      datetime: dateTime(),
    };
    const rewardredemedvoucherlist = await dispatch(
      rewardsActions.rewardsReedemedVoucherList(data),
    );
  }, []);

  const seeMore = () => {
    if (rewardsPurchasedVoucherList?.length > noofPurchased) {
      setNoofPurchased(rewardsPurchasedVoucherList?.length);
    }
    if (rewardsPurchasedVoucherList?.length === noofPurchased) {
      setNoofPurchased(4);
    }
  };
  const seeMoreRedeemed = () => {
    if (rewardsReedemedVoucherList?.length > noofRedeemed) {
      setNoofRedeemed(rewardsReedemedVoucherList?.length);
    } else if (rewardsReedemedVoucherList?.length === noofRedeemed) {
      setNoofRedeemed(4);
    }
  };
  const seeMoreClose = () => {
    if (rewardsExpiredVoucherList?.length > noofExpired) {
      setNoofExpired(rewardsExpiredVoucherList?.length);
    } else if (rewardsExpiredVoucherList?.length === noofExpired) {
      setNoofExpired(4);
    }
  };
  if (rewardRedeemVoucherloading) {
    return (
      <Fragment>
        <Box px={0}>
          <Box pt={2} sx={{ width: '100%' }}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={0}>
                <Box
                  py={3}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant="h5s"
                    component="h5s"
                    sx={{ fontWeight: 600 }}>
                    Purchased
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Box>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            <Stack direction="column" spacing={1}>
              <PartnerCardSkeleton />
              <PartnerCardSkeleton />
            </Stack>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box px={0}>
          <Box pt={2} sx={{ width: '100%' }}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={0}>
                <Box
                  py={3}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant="h5s"
                    component="h5s"
                    sx={{ fontWeight: 600 }}>
                    Purchased {'(' + rewardsReedemedVoucherList?.length + ')'}
                  </Typography>
                </Box>
                <Box
                  py={3}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={seeMoreRedeemed}>
                  {rewardsReedemedVoucherList?.length > 4 ? (
                    <Typography variant="h5" component="h5">
                      {rewardsReedemedVoucherList?.length === noofRedeemed
                        ? 'See Less'
                        : 'See All'}
                    </Typography>
                  ) : (
                    <Typography variant="h5" component="h5"></Typography>
                  )}
                </Box>
              </Stack>
            </Paper>
          </Box>
          <Box
            sx={{
              marginTop: '8px',
            }}>
            <Grid container>
              {rewardsReedemedVoucherList?.length > 0 &&
                rewardsReedemedVoucherList
                  ?.slice(0, noofRedeemed)
                  ?.map((list, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        sx={{
                          paddingRight: index % 2 === 0 ? '8px' : '0px',
                          marginTop: '8px',
                        }}>
                        <RewardPartnerVoucherCard
                          voucher={list}
                          type="voucher"
                          status="purchased"
                        />
                      </Grid>
                    );
                  })}
              {rewardsReedemedVoucherList?.length == 0 && (
                <Box pl={3} py={2}>
                  <Typography variant="h5" component="h5">
                    Currently there are no Voucher.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
          <Box
            py={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={seeMoreRedeemed}>
            {rewardsReedemedVoucherList?.length > 4 ? (
              <Typography variant="h5" component="h5">
                {rewardsReedemedVoucherList?.length === noofRedeemed
                  ? 'See Less'
                  : 'See All'}
              </Typography>
            ) : (
              <Typography variant="h5" component="h5"></Typography>
            )}
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(VoucherRewards);

//
// <Box pt={2} sx={{ width: "100%" }}>
//   <Paper sx={{ backgroundColor: "#232121", backgroundImage: "none" }}>
//     <Stack
//       direction="row"
//       justifyContent="space-between"
//       alignItems="flex-end"
//       spacing={0}
//     >
//       <Box
//         py={3}
//         px={3}
//         sx={{
//           display: "flex",
//           justifyItems: "center",
//           alignItems: "center",
//         }}
//       >
//         <Typography
//           variant="h5s"
//           component="h5s"
//           sx={{ fontWeight: 600 }}
//         >
//           Redeemed {"(" + rewardsPurchasedVoucherList?.length + ")"}
//         </Typography>
//       </Box>
//       <Box
//         py={3}
//         px={3}
//         sx={{
//           display: "flex",
//           justifyItems: "center",
//           alignItems: "center",
//           cursor: "pointer",
//         }}
//         onClick={seeMore}
//       >
//         {rewardsPurchasedVoucherList?.length > 4 ? (
//           <Typography variant="h5" component="h5">
//             {rewardsPurchasedVoucherList?.length === noofPurchased
//               ? "See Less"
//               : "See All"}
//           </Typography>
//         ) : (
//           <Typography variant="h5" component="h5"></Typography>
//         )}
//       </Box>
//     </Stack>
//   </Paper>
// </Box>
// <Box
//   sx={{
//     marginTop: "8px",
//   }}
// >
//   <Grid container>
//     {rewardsPurchasedVoucherList?.length > 0 &&
//       rewardsPurchasedVoucherList
//         ?.slice(0, noofRedeemed)
//         ?.map((list, index) => {
//           return (
//             <Grid
//               key={index}
//               item
//               xs={12}
//               md={6}
//               sx={{
//                 paddingRight: index % 2 === 0 ? "8px" : "0px",
//                 marginTop: "8px",
//               }}
//             >
//               <RewardPartnerVoucherCard
//                 voucher={list}
//                 type="voucher"
//                 status="redeemed"
//               />
//             </Grid>
//           );
//         })}
//     {rewardsPurchasedVoucherList?.length == 0 && (
//       <Box pl={3} py={2}>
//         <Typography variant="h5" component="h5">
//           Currently there are no Redeemed Voucher.
//         </Typography>
//       </Box>
//     )}
//   </Grid>
// </Box>
// <Box pt={2} sx={{ width: "100%" }}>
//   <Paper sx={{ backgroundColor: "#232121", backgroundImage: "none" }}>
//     <Stack
//       direction="row"
//       justifyContent="space-between"
//       alignItems="flex-end"
//       spacing={0}
//     >
//       <Box
//         py={3}
//         px={3}
//         sx={{
//           display: "flex",
//           justifyItems: "center",
//           alignItems: "center",
//         }}
//       >
//         <Typography
//           variant="h5s"
//           component="h5s"
//           sx={{ fontWeight: 600 }}
//         >
//           Expired {"(" + rewardsExpiredVoucherList?.length + ")"}
//         </Typography>
//       </Box>
//       <Box
//         py={3}
//         px={3}
//         sx={{
//           display: "flex",
//           justifyItems: "center",
//           alignItems: "center",
//           cursor: "pointer",
//         }}
//         onClick={seeMoreClose}
//       >
//         {rewardsExpiredVoucherList?.length > 4 ? (
//           <Typography variant="h5" component="h5">
//             {rewardsExpiredVoucherList?.length === noofExpired
//               ? "See Less"
//               : "See All"}
//           </Typography>
//         ) : (
//           <Typography variant="h5" component="h5"></Typography>
//         )}
//       </Box>
//     </Stack>
//   </Paper>
// </Box>
