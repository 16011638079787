import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.palette.text.black,
  borderRadius: "50px",
  fontFamily: "Raleway",
  lineHeight: "18px",
  letterSpacing: "1px",
  fontWeight: "800",
  fontSize: "18px",
  textTransform: "none",
  backgroundColor: theme.palette.palette.text.secondary,
  "&:hover": {
    backgroundColor: theme.palette.palette.text.secondary,
  },
}));

export default function CustomizedButtons(props) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton
        variant="contained"
        onClick={props?.onClick}
        type={props?.type ? props?.type : ""}
        sx={{
          height: props?.height ? props?.height : "35",
          minWidth: props?.minWidth ? props?.minWidth : "150px",
          minHeight: props?.minHeight ? props?.minHeight : "35px",
          fontSize: props?.fontSize ? props?.fontSize : "14px",
          lineHeight: props?.lineHeight ? props?.lineHeight : "18px",

          "@media (max-width: 500px)": {
            height: props?.height ? props?.height : "25",
            minWidth: props?.minWidth ? props?.minWidth : "80px",
            minHeight: props?.minHeight ? props?.minHeight : "25px",
            fontSize: props?.fontSize ? props?.fontSize : "12px",
            lineHeight: props?.lineHeight ? props?.lineHeight : "15px",
          },
        }}
      >
        {props?.label ? props.label : "Submit"}
      </ColorButton>
    </Stack>
  );
}
