import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CardContent from '@mui/material/CardContent';
import CardLongShare from './CardLongShare';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgresssecond = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#38cd84' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#ffffff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 400],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#656363' : '#ffffff',
  },
  '& .MuiLinearProgress-root': {
    backgroundColor: '#ffffff',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 50 }}>
        {props.ischecked ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              color: '#38cd84',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontWeight: '600',
              fontFamily: 'Open Sans',
              fontSize: '13px',
            }}>
            {`${Math.round(props.value)}` + ' %'}
          </Typography>
        )}
      </Box>
      <Box sx={{ minWidth: 60, width: '100%', mr: 1 }}>
        {props.ischecked ? (
          <BorderLinearProgresssecond
            variant="determinate"
            color="secondary"
            {...props}
          />
        ) : (
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            {...props}
          />
        )}
      </Box>
    </Box>
  );
}

export default function Poll(props) {
  const [voted, setVoted] = React.useState(false);

  useEffect(() => {
    if (props?.post?.type === 'poll') {
      let vote = false;
      props?.post?.pollOption?.some(function (el) {
        if (el.isChecked) {
          vote = true;
        }
      });
      setVoted(vote);
    }
  }, []);

  return (
    <CardContent sx={{ paddingTop: '8px', paddingBottom: '4px' }}>
      <Box px={{ md: 2, xs: 0 }}>
        <FormControl sx={{ width: '100%' }}>
          <FormLabel id="demo-radio-buttons-group-label">
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                color: 'rgba(255, 255,255,1)',
                paddingBottom: '8px',
                fontSize: '16px',
                fontWeight: '600',
                '@media (max-width: 500px)': {
                  fontSize: '13px',
                  fontWeight: '500',
                },
              }}>
              {props?.post?.heading}
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="radio-buttons-group"
            value={props?.pollvalue}
            onChange={props?.handlePoll}
            color="secondary">
            {props?.post?.pollOption?.map((poll, index) => {
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  sx={{ width: '100%' }}>
                  {props?.post?.is_poll_expired ? (
                    <FormControlLabel
                      key={index}
                      value={poll?.id}
                      control={<Box pl={1} />}
                      label={
                        poll?.isChecked ? (
                          <Typography
                            variant="h5s"
                            component="h5s"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                                fontWeight: '500',
                              },
                            }}>
                            {poll?.options}
                          </Typography>
                        ) : (
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                                fontWeight: '500',
                              },
                            }}>
                            {poll?.options}
                          </Typography>
                        )
                      }
                    />
                  ) : (
                    <FormControlLabel
                      key={index}
                      value={poll?.id}
                      control={
                        voted ? (
                          <Box pl={1} />
                        ) : (
                          <Radio size="small" color="secondary" />
                        )
                      }
                      label={
                        poll?.isChecked ? (
                          <Typography
                            variant="h5s"
                            component="h5s"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                                fontWeight: '500',
                              },
                            }}>
                            {poll?.options}
                          </Typography>
                        ) : (
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '13px',
                                fontWeight: '500',
                              },
                            }}>
                            {poll?.options}
                          </Typography>
                        )
                      }
                    />
                  )}

                  {
                    <Box sx={{ maxWidth: 200, width: '100%' }}>
                      <LinearProgressWithLabel
                        value={poll?.vote}
                        ischecked={poll?.isChecked}
                        color="secondary"
                      />
                    </Box>
                  }
                </Stack>
              );
            })}
          </RadioGroup>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box
              sx={{
                maxWidth: 200,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  paddingBottom: '8px',
                  fontSize: '14px',
                  fontWeight: '600',
                  '@media (max-width: 500px)': {
                    fontSize: '13px',
                    fontWeight: '500',
                  },
                }}>
                {/* {vadityleft()} */}
              </Typography>
            </Box>
          </Box>
        </FormControl>
      </Box>
    </CardContent>
  );
}
