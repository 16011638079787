import {
  HOSTED_EVENT_UPCOMING_SUCCESS,
  HOSTED_EVENT_UPCOMING_FAILURE,
  HOSTED_EVENT_UPCOMING_REQUEST,
} from './actions';

export const eventreducer = (
  state = {
    loading: false,
    eventUpcomingList: [],
    eventDiscoverList: [],
    eventInvitedList: [],
    eventRequestedList: [],
    eventSavedUpcomingList: [],
    eventSavedPassedList: [],
    eventHostedPassedList: [],
    eventDetails: {},
    eventDetailsHome: {},
    eventInvitedMembers: [],
    eventAttendingMembers: [],
    eventRequestMembers: [],
    eventStatusUser: {},
    EventShare: false,
    ShareEventDetails: {},
    EventSendInvitees: false,
    SendInviteesEventDetails: {},
    eventEdit: {},
    hostedEventLoading: false,
    discoverEventLoading: false,
    eventsDetailsloading: false,
  },
  action,
) => {
  switch (action.type) {
    case HOSTED_EVENT_UPCOMING_REQUEST: {
      if (action?.payload?.payld?.page_no === 0) {
        return {
          ...state,
          eventUpcomingList: [],
          recommendedLoading: true,
          loading: false,
          errors: {},
        };
      } else {
        return {
          ...state,
          recommendedLoading: false,
          loading: false,
          errors: {},
        };
      }
    }
    case HOSTED_EVENT_UPCOMING_SUCCESS: {
      if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventUpcomingList: [...action.payload.event.data],
          hostedEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no > 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventUpcomingList: [
            ...state?.eventUpcomingList,
            ...action.payload.event.data,
          ],
          hostedEventLoading: false,
        };
      }
    }
    case HOSTED_EVENT_UPCOMING_FAILURE:
      return { ...state, ...action.payload, hostedEventLoading: false };

    case 'DISCOVER_EVENT_LIST_REQUEST': {
      if (action?.payload?.payld?.page_no === 0) {
        return { ...state, ...action.payload, discoverEventLoading: true };
      } else {
        return { ...state, ...action.payload, discoverEventLoading: false };
      }
    }

    case 'DISCOVER_EVENT_LIST_FAILURE':
      return { ...state, ...action.payload, discoverEventLoading: false };
    case 'DISCOVER_EVENT_LIST_SUCCESS': {
      if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventDiscoverList: [...action.payload.event.data],
          discoverEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no > 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventDiscoverList: [
            ...state.eventDiscoverList,
            ...action.payload.event.data,
          ],
          discoverEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length === 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventDiscoverList: [],
          discoverEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no > 0 &&
        action?.payload?.event?.data?.length === 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          discoverEventLoading: false,
        };
      }
    }
    case 'EVENT_CREATE_REQUEST':
      return {
        ...state,
        loading: true,
        errors: {},
      };

    case 'EVENT_CREATE_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
      };
      case 'EVENT_CREATE_FAILURE':
        return {
          ...state,
          loading: false,
          errors: {},
        };
    case 'HOSTED_EVENT_PASSED_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventHostedPassedList: action.payload.data,
      };
    case 'INVITED_EVENT_LIST_SUCCESS':
      if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventInvitedList: [...action.payload.event.data],
          hostedEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no > 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventInvitedList: [
            ...state?.eventInvitedList,
            ...action.payload.event.data,
          ],
          hostedEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length === 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventInvitedList: [],
          hostedEventLoading: false,
        };
      }
    case 'REQUESTED_EVENT_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventRequestedList: action.payload.data,
      };
    case 'EVENT_STATUS_FOR_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventStatusUser: action.payload.data,
      };
    case 'EVENT_STATUS_FOR_USER_REQUEST':
      return {
        ...state,
        loading: false,
        errors: {},
        eventStatusUser: {},
      };
    case 'SAVED_EVENT_PASSED_SUCCESS':{
      if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventSavedPassedList: [...action.payload.event.data],
          hostedEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no > 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventSavedPassedList: [
            ...state?.eventSavedPassedList,
            ...action.payload.event.data,
          ],
          hostedEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length === 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventSavedPassedList: [],
          hostedEventLoading: false,
        };
      }}
    case 'SAVED_EVENT_UPCOMING_SUCCESS':{
      if (
        action?.payload?.payld?.page_no === 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventSavedUpcomingList: [...action.payload.event.data],
          hostedEventLoading: false,
        };
      } else if (
        action?.payload?.payld?.page_no > 0 &&
        action?.payload?.event?.data?.length > 0
      ) {
        return {
          ...state,
          loading: false,
          errors: {},
          eventSavedUpcomingList: [
            ...state?.eventSavedUpcomingList,
            ...action.payload.event.data,
          ],
          hostedEventLoading: false,
        };
      }
    }
    case 'EVENT_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventsDetailsloading: false,
        eventDetails: action.payload.data,
      };
    case 'EVENT_DETAILS_REQUEST':
      return {
        ...state,
        loading: false,
        errors: {},
        eventsDetailsloading: true,
      };
    case 'EVENT_DETAILS_FAILURE':
      return {
        ...state,
        loading: false,
        errors: {},
        eventsDetailsloading: false,
      };
    case 'EVENT_DETAILS_HOME_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventDetailsHome: action.payload.data,
      };
    case 'EVENT_INVITED_MEMBERS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventInvitedMembers: action.payload.data,
      };
    case 'EVENT_REQUESTED_MEMBERS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventRequestMembers: action.payload.data,
      };
    case 'EVENT_ATTENDING_MEMBERS_SUCCESS':
      return {
        ...state,
        loading: false,
        errors: {},
        eventAttendingMembers: action.payload.data,
      };
    case 'CLOSE_SHARE_EVENT': {
      return {
        ...state,
        EventShare: false,
        ShareEventDetails: {},
      };
    }
    case 'OPEN_SHARE_EVENT': {
      return {
        ...state,
        EventShare: true,
        ShareEventDetails: action.payload,
      };
    }
    case 'OPEN_SEND_INVITEES_EVENT': {
      return {
        ...state,
        EventSendInvitees: true,
        SendInviteesEventDetails: action.payload,
      };
    }
    case 'CLOSE_SEND_INVITEES_EVENT': {
      return {
        ...state,
        EventSendInvitees: false,
        SendInviteesEventDetails: {},
      };
    }
    case 'EDIT_EVENT_OPEN': {
      return {
        ...state,
        eventEdit: action.payload,
      };
    }
    default:
      return state;
  }
};
