import React, { Fragment, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import CxoButtonWithIcon from '../../components/CxoButton/CxoButton';
import CxoButtonDisabled from '../../components/CxoButton/CxoButtonDisabled';
import RewardMembershipCard from '../../components/RewardMembershipCard/RewardMembershipCard';
import NetworkCard from '../../components/NetworkCard/NetworkCard';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CxoInput from '../../components/CxoInput/CxoInput';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import PartnerCardSkeleton from '../../components/Skeleton/PartnerCardSkeleton';
import * as messageAction from '../../store/message';
import MenuItem from '@mui/material/MenuItem';
import Icon from '../../assets/icon';
import Menuicon from '../../assets/menuicon';
import Select from '@mui/material/Select';
import SearchNetworkCard from '../../components/SearchNetworkCard/SearchNetworkCard';
import SearchArticleCard from '../../components/SearchArticleCard/SearchArticleCard';
import RewardPartnerVoucherCard from '../../components/RewardPartnerVoucherCard/RewardPartnerVoucherCard';
import RewardPartnerCard from '../../components/RewardPartnerCard/RewardPartnerCard';
import * as usernetworkActions from '../../store/usernetwork';
import * as rewardsActions from '../../store/rewards';
import CxoButton from '../../components/CxoButton/CxoButton';
import credits_gold from '../../assets/header/Credits_Gold.png';
import { dateTime } from '../../constants/app';
import * as messageActions from '../../store/message';
import RewardVoucherCard from '../../components/RewardVoucherCard/RewardVoucherCard';
import Skeleton from '@mui/material/Skeleton';
import StarIcon from '@mui/icons-material/Star';
import useRazorpay from 'react-razorpay';
import CloseIcon from '@mui/icons-material/Close';
import config from '../../services/apiConfig';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  homeroot: {
    backgroundColor: '#fff',
  },
}));
const StyledTab = styled(Tab)((theme) => ({
  color: 'white',
  cursor: 'pointer',
  width: '140px',
  padding: '8px 12px',
  margin: '6px 6px',
  border: 'none',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#262525',
  fontFamily: 'Raleway',
  fontWeight: '600',
  letterSpacing: '0.68px',

  '& .MuiTabs-indicator': {
    color: '#38CD84',
    backgroundColor: '#000014',
  },
  '&.MuiTab-root': {
    borderBottom: 0,
  },
  '& .Mui-disabled': {
    color: 'rgb(245,245,245, 0.6)!important',
  },
  '&:hover': {
    backgroundColor: '#38CD84',
  },
  '&.Mui-selected': {
    backgroundColor: '#38CD84',
    color: '#1F1E1E',
  },
}));

const StyledTabsList = styled(TabsList)((theme) => ({
  minWidth: '320px',
  backgroundColor: '#262525',
  borderRadius: '8px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  alignContent: 'space-between',
  padding: '16px 16px',
}));

const StyledTabPanel = styled(TabPanel)((theme) => ({
  width: '100%',
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: '800',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: '#38CD84',
      opacity: 1,
    },
    '&.MuiTab-root': {},
    '&.Mui-selected': {
      color: '#38CD84',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const Membership = (props) => {
  const classes = useStyles();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Razorpay = useRazorpay();
  const [value, setValue] = React.useState(2);
  const [type, setType] = React.useState('');
  const [details, setDetails] = React.useState('');
  const [benefits, setBenefits] = React.useState([]);
  const [membershipDetails, setMembershipDetails] = React.useState('');
  const [termdata, setTermdata] = React.useState({});
  const [showconfirmation, setShowConfirmation] = React.useState(false);
  const [showdetails, setShowDetails] = React.useState(false);
  const [currentMembership, setCurrentmembership] = React.useState('');
  const user = useSelector((state) => state?.user?.UserDetails);

  // const searchresult =  dispatch(searchActions.postSearch(data));
  const rewardsTotalCredits = useSelector(
    (state) => state?.rewards?.rewardsTotalCredits,
  );

  const rewardsVoucherList = useSelector(
    (state) => state?.rewards?.rewardsVoucherList,
  );

  const membershipBenefits = useSelector(
    (state) => state?.rewards?.membershipBenefits,
  );
  const corporateMembershipsBenefits = useSelector(
    (state) => state?.rewards?.corporateMembershipsBenefits,
  );
  const membershipUserDetails = useSelector(
    (state) => state?.rewards?.membershipUserDetails,
  );

  const membershipDetailss = useSelector(
    (state) => state?.rewards?.membershipDetails,
  );

  const rewardsPartnerVoucher = useSelector(
    (state) => state?.rewards?.rewardsPartnerVoucher,
  );

  const rewardVouchelistloading = useSelector(
    (state) => state?.rewards?.rewardVouchelistloading,
  );

  const rewardsCategoryList = useSelector(
    (state) => state?.rewards?.rewardsCategoryList,
  );

  const membershipUpgradePrice = useSelector(
    (state) => state?.rewards?.membershipUpgradePrice,
  );

  const membershipList = useSelector((state) => state?.rewards?.membershipList);

  const corporateMemberships = useSelector(
    (state) => state?.rewards?.corporateMemberships,
  );

  console.log(
    'rewardsPartnerVoucher',
    rewardsPartnerVoucher,
    rewardsVoucherList,
  );

  useEffect(() => {
    async function fetchUserDetails() {
      const data = {
        user_id: user?.id,
        membership_type: user?.membership_type,
      };
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardUserMember(data),
      );
    }
    fetchUserDetails();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const rewardscategorylist = await dispatch(
        rewardsActions.rewardsCategoryList(),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    const type = params?.type;
    console.log('type', type);
    setType(type);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(rewardsActions.rewardMemberList());
      const type = params?.type;
      var result = membershipList.filter((obj) => {
        return obj.type === type;
      });

      const data = {
        membership_id: result[0]?.id,
      };
      const responsedetails = await dispatch(
        rewardsActions.rewardMemberDetails(data),
      );
      const responsebenefits = await dispatch(
        rewardsActions.rewardMemberBenefits(data),
      );
      let newArray = responsebenefits?.data?.reduce((acc, cur) => {
        let { category_id, ...rest } = cur;
        let ex = acc.find((x) => x.category_id === category_id);
        if (!ex) {
          ex = { category_id, value: [] };
          acc.push(ex);
        }
        ex.value.push(rest);
        return acc;
      }, []);
      debugger;
      let newresult = responsebenefits?.data?.data?.reduce(function (r, a) {
        r[a.category_id] = r[a.category_id] || [];
        r[a.category_id].push(a);
        return r;
      }, Object.create(null));
      console.log('result', newresult, newArray);
      setBenefits(newArray);
      setDetails(result);
      const data4 = {
        user_id: user?.id,
        membership_key: result[0]?.id,
      };
      const upgradedprice = await dispatch(
        rewardsActions.rewardUpgradeMemberPrice(data4),
      );
      // console.log('JSON', JSON.parse(result[0]?.details));
      setMembershipDetails(JSON.parse(result[0]?.details));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (user?.membership_type < details[0]?.id) {
        // const data4 = {
        //   user_id: user?.id,
        //   membership_key: details[0]?.id,
        // };
        // const upgradedprice = await dispatch(
        //   rewardsActions.rewardUpgradeMemberPrice(data4),
        // );
      }
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const data = {
      user_id: user?.id,
    };
    const linkcxorewards = await dispatch(
      rewardsActions.rewardsGetTotalCredits(data),
    );

    var result = membershipList.filter((obj) => {
      return obj.id === user?.membership_type;
    });
    setCurrentmembership(result);
  }, []);

  const razorPaySuccess = async (res) => {
    // alert("Payment Sucessfully", res);
    let msg = 'Purchase Successfully';
    const sendmessage = await dispatch(
      messageAction.messagefromComponent(msg, 'success'),
    );
    navigate('/rewards/membership');

    console.log('Payment Sucessfully', res);
  };

  const razorPayFailed = (error) => {
    console.log('payment failed', error);
  };

  const upgradeMembership = useCallback(() => {
    axios
      .post(config?.serverBaseUrl + '/create_order_membership', {
        membership_key: details[0]?.id,
        currency: 'INR',
        receipt: 'rec_pewep34',
        notes: 'lang',
        user_id: user?.id,
        membership_status: 'upgrade',
        tag: 'membership',
      })
      .then((res) => {
        let options = {
          key: 'rzp_live_SK60amVrcfAb39',
          amount: res.data.amount_due,
          currency: 'INR',
          name: 'Linkcxo',
          description: 'Membership',
          image: 'https://linkcxo-logo.s3.ap-south-1.amazonaws.com/logo.png',
          order_id: res.data.id,
          handler: (res) => {
            console.log('Razorpay', res);
            return razorPaySuccess();
          },
          prefill: {
            name: user?.first_name + ' ' + user?.last_name,
            email: user?.email,
            contact: user?.mobile,
          },
          notes: {
            address: '',
          },
          theme: {
            color: '#38CD84',
          },
        };
        console.log('options', options);
        const rzpay = new Razorpay(options);
        rzpay.on('payment.failed', function (response) {
          return razorPayFailed(response);
        });
        rzpay.open();
      })
      .catch((e) => console.log(e));
  }, [Razorpay, razorPaySuccess, razorPayFailed]);
  // rzp_live_S9XA2i9Ox4zjp5   rzp_test_0uFcavHKXDrPCV
  const buyMembership = useCallback(() => {
    axios
      .post(config?.serverBaseUrl + '/create_order/corporate_membership', {
        membership_key: details[0]?.id,
        currency: 'INR',
        receipt: 'rec_pewep34',
        notes: 'lang',
        user_id: user?.id,
        membership_status: 'new',
        tag: 'membership',
      })
      .then((res) => {
        let options = {
          key: 'rzp_live_S9XA2i9Ox4zjp5',
          amount: res.data.amount_due,
          currency: 'INR',
          name: 'Linkcxo',
          description: 'Membership',
          image: 'https://linkcxo-logo.s3.ap-south-1.amazonaws.com/logo.png',
          order_id: res.data.id,
          handler: (res) => {
            console.log('Razorpay', res);
            return razorPaySuccess();
          },
          prefill: {
            name: user?.first_name + ' ' + user?.last_name,
            email: user?.email,
            contact: user?.mobile,
          },
          notes: {
            address: '',
          },
          theme: {
            color: '#38CD84',
          },
        };
        console.log('options', options);
        const rzpay = new Razorpay(options);
        rzpay.on('payment.failed', function (response) {
          return razorPayFailed(response);
        });
        rzpay.open();
      })
      .catch((e) => console.log(e));
  }, [Razorpay, razorPaySuccess, razorPayFailed]);

  const handleChange = async (event, newValue) => {
    console.log('handleChange', event, newValue);

    if (user?.isVerified) {
      if (newValue === 0) {
        navigate('/rewards/category/partners');
        setValue(newValue);
      } else if (newValue === 1) {
        navigate('/rewards/my/vouchers');
        setValue(newValue);
      } else if (newValue === 2) {
        navigate('/rewards/membership');
        setValue(newValue);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const benefitshow = (benefit) => {
    if (benefit['value']) {
      benefit['value']?.map((value, index) => {
        console.log('benefitshow', value);
        return <RewardVoucherCard benfits={value} />;
      });
    } else {
      return '';
    }
  };

  const changeTab = async (val) => {
    if (user?.isVerified) {
      if (val === 0) {
        navigate('/rewards/category/partners');
        setValue(val);
      } else if (val === 1) {
        navigate('/rewards/my/vouchers');
        setValue(val);
      } else if (val === 2) {
        navigate('/rewards/membership');
        setValue(val);
      }
    } else {
      await dispatch(messageActions.openUnderVerification());
    }
  };

  const creditsDetails = () => {
    navigate('/rewards/credits');
  };

  const categoryName = (id) => {
    var result = rewardsCategoryList.filter((obj) => {
      return obj.id === id;
    });

    return result[0]?.name;
  };

  const showConfirmation = () => {
    setShowConfirmation(!showconfirmation);
  };

  const showDetails = () => {
    setShowDetails(!showdetails);
  };

  const purchaseNow = () => {
    navigate('/corporate/packages/group/membership/' + params?.type);
  };

  const termAndCondition = async (id) => {
    const data = {
      user_id: user?.id,
      product_id: id,
      datetime: dateTime(),
    };
    const rewardVoucherDetails = await dispatch(
      rewardsActions.rewardsVoucherDetails(data),
    );
    if (rewardVoucherDetails?.status === 1) {
      setTermdata(rewardVoucherDetails?.data);
      setShowConfirmation(!showconfirmation);
    }
  };

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(user?.subscription_id);
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const colorStart = () => {
    console.log('colorStart', details);
    var result = membershipList.filter((obj) => {
      return obj.type === type;
    });
    if (result[0]?.id == 1) {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#38CD84' }}
        />
      );
    } else if (result[0]?.id == 2) {
      return (
        <StarIcon
          fontSize="32px"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#C0C0C0' }}
        />
      );
    } else if (result[0]?.id == 3) {
      return (
        <StarIcon
          fontSize="32px"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#FFC107' }}
        />
      );
    } else if (result[0]?.id == 4) {
      return (
        <StarIcon
          fontSize="32px"
          sx={{ marginTop: '10px', fontSize: '48px', color: '#A0B2C6' }}
        />
      );
    } else {
      return (
        <StarIcon
          fontSize="large"
          sx={{ marginTop: '10px', color: '#38CD84' }}
        />
      );
    }
  };
  const redirectTocategory = (id) => {
    var result = rewardsCategoryList.filter((obj) => {
      return obj.id === id;
    });

    if (result[0]?.name === 'Real Estate') {
      const path =
        '/rewards/realestate/partners/' + result[0]?.name + '-' + 'lx' + id;
      navigate(path, { state: { category: result[0] } });
    } else {
      const path = '/rewards/partners/' + result[0]?.name + '-' + 'lx' + id;
      navigate(path, { state: { category: result[0]?.name } });
    }
  };

  const noOfDays = () => {
    if (user?.membership_type < details[0]?.id) {
      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();
      return '(' + Math.floor(Math.abs(data)) + ' Days' + ' )';
    }
  };

  const membershipUsed = () => {
    if (user?.membership_type < details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();

      console.log('details', data, Math.floor(Math.abs(data)));

      var result = membershipList.filter((obj) => {
        return obj.id === user?.membership_type;
      });

      // let rslt = parseInt(
      //   (result[0]?.price / result[0]?.expiry) * Math.floor(Math.abs(data)),
      // );
      let rslt = Math.abs(
        parseInt(details[0]?.price - result[0]?.price - membershipUpgradePrice),
      );
      return parseFloat(rslt).toFixed(2);
    } else {
      return 0;
    }
  };

  const textinBenefits = () => {
    if (details[0]?.id === 1) {
      let detailshk = JSON.parse(details[0]?.details);
      return (
        <Stack direction="column">
          {detailshk?.map((detail, index) => {
            return (
              <Typography
                key={index}
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '16px',
                }}>
                {detail}
              </Typography>
            );
          })}
        </Stack>
      );
    } else if (details[0]?.id === 2) {
      let detailshk = JSON.parse(details[0]?.details);
      console.log('details', detailshk);
      return (
        <Stack direction="column">
          {detailshk?.map((detail, index) => {
            return (
              <Typography
                key={index}
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '16px',
                }}>
                {detail}
              </Typography>
            );
          })}
        </Stack>
      );
    } else if (details[0]?.id === 3) {
      let detailshk = JSON.parse(details[0]?.details);
      return (
        <Stack direction="column">
          {detailshk?.map((detail, index) => {
            return (
              <Typography
                key={index}
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '16px',
                }}>
                {detail}
              </Typography>
            );
          })}
        </Stack>
      );
    }
  };

  const membershipHardPrice = () => {
    if (details[0]?.id === 1) {
      return (
        <Stack direction="row">
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              textDecoration: 'line-through',
            }}>
            $ {parseInt(details[0]?.price_in_doller)} (₹ {details[0]?.price}){' '}
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}></Typography>
        </Stack>
      );
    } else if (details[0]?.id === 2) {
      return (
        <Stack direction="row">
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              textDecoration: 'line-through',
            }}>
            $ {parseInt(details[0]?.price_in_doller)} (₹ {details[0]?.price}){' '}
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}>
            {' / '}${' '}
            {parseInt(
              details[0]?.price_in_doller -
                details[0]?.price_in_doller * (details[0]?.offer_per / 100),
            )}{' '}
            (₹{' '}
            {details[0]?.price -
              details[0]?.price * (details[0]?.offer_per / 100)}
            {')'}
          </Typography>
        </Stack>
      );
    } else if (details[0]?.id === 3) {
      return (
        <Stack direction="row">
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              textDecoration: 'line-through',
            }}>
            $ {parseInt(details[0]?.price_in_doller)} (₹ {details[0]?.price}){' '}
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '24px',
              lineHeight: '24px',
              color: '#fff',
              paddingBottom: '16px',
              paddingLeft: '8px',
            }}>
            {' / '}${' '}
            {parseInt(
              details[0]?.price_in_doller -
                details[0]?.price_in_doller * (details[0]?.offer_per / 100),
            )}{' '}
            (₹{' '}
            {details[0]?.price -
              details[0]?.price * (details[0]?.offer_per / 100)}
            {')'}
          </Typography>
        </Stack>
      );
    }
  };
  const membershipPrice = () => {
    if (user?.membership_type < details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();

      console.log('details', data, Math.floor(Math.abs(data)));

      var result = membershipList.filter((obj) => {
        return obj.id === user?.membership_type;
      });

      let rslt =
        details[0]?.price -
        parseInt(
          (result[0]?.price / result[0]?.expiry) *
            (result[0]?.expiry - Math.floor(Math.abs(data))),
        );
      console.log('result', result, details, rslt);
      return (
        <Stack direction="row">
          {rslt ? (
            <>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  textDecoration: 'line-through',
                }}>
                {details[0]?.price?.toLocaleString()
                  ? '₹ ' + details[0]?.price?.toLocaleString()
                  : ''}{' '}
                {'  '}
              </Typography>

              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '24px',
                  lineHeight: '24px',
                  color: '#fff',
                  paddingBottom: '16px',
                  paddingLeft: '16px',
                }}>
                {membershipUpgradePrice
                  ? '₹ ' + membershipUpgradePrice?.toLocaleString()
                  : ''}
              </Typography>
            </>
          ) : (
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '24px',
                lineHeight: '24px',
                color: '#fff',
                paddingBottom: '16px',
              }}>
              {details[0]?.price?.toLocaleString()
                ? '₹ ' + details[0]?.price?.toLocaleString()
                : ''}
            </Typography>
          )}
        </Stack>
      );
    } else {
      return (
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '24px',
            lineHeight: '24px',
            color: '#fff',
            paddingBottom: '16px',
          }}>
          ₹ {details[0]?.price?.toLocaleString()}
        </Typography>
      );
    }
    // if (type != 'basic') {
    //   return (
    //     <Typography
    //       variant="h6"
    //       component="h6"
    //       sx={{
    //         fontFamily: 'Open Sans',
    //         fontSize: '24px',
    //         lineHeight: '24px',
    //         color: '#fff',
    //         paddingBottom: '16px',
    //       }}>
    //       ₹ {details[0]?.price?.toLocaleString()}
    //     </Typography>
    //   );
    // } else if (type == 'basic') {
    //   return (
    //     <Box sx={{ display: 'flex', width: '200px' }}>
    //       <Typography
    //         variant="h4"
    //         component="h2"
    //         sx={{
    //           color: '#ffffff',
    //           fontSize: '24px',
    //           lineHeight: '27px',
    //           fontWeight: '400',
    //           fontFamily: 'Open Sans',
    //           textDecoration: 'line-through',
    //         }}>
    //         ₹ {5000?.toLocaleString()}
    //       </Typography>
    //       <Typography
    //         variant="h2"
    //         component="h2"
    //         sx={{
    //           color: '#38CD84',
    //           fontSize: '24px',
    //           lineHeight: '27px',
    //           fontWeight: '600',
    //           fontFamily: 'Open Sans',
    //           marginLeft: '8px',
    //         }}>
    //         Free
    //       </Typography>
    //     </Box>
    //   );
    // }
  };

  const membershipBalance = () => {
    if (user?.membership_type < details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();

      console.log('details', data, Math.floor(Math.abs(data)));

      var result = membershipList.filter((obj) => {
        return obj.id === user?.membership_type;
      });

      // let rslt = parseInt(
      //   (result[0]?.price / result[0]?.expiry) *
      //     (result[0]?.expiry - Math.floor(Math.abs(data))),
      // );

      let rslt = parseInt(details[0]?.price - membershipUpgradePrice);

      return parseFloat(rslt).toFixed(2);
    } else {
      return 0;
    }
  };

  const topay = () => {
    if (details[0]?.id) {
      let datev = moment(
        moment
          .utc(
            membershipDetailss[0]?.membership_start_date,
            'YYYY-MM-DDTHH:mm:ssZ',
          )
          .add(membershipDetailss[0]?.membership_expiry, 'days'),
      ).format('ll');

      let data = moment
        .duration(
          moment
            .utc(
              membershipDetailss[0]?.membership_start_date,
              'YYYY-MM-DDTHH:mm:ssZ',
            )
            .diff(new Date()),
        )
        .asDays();

      console.log('details', data, Math.floor(Math.abs(data)));

      var result = membershipList.filter((obj) => {
        return obj.id === user?.membership_type;
      });

      let rslt =
        details[0]?.price -
        parseInt(
          (result[0]?.price / result[0]?.expiry) *
            (result[0]?.expiry - Math.floor(Math.abs(data))),
        );
      return parseFloat(rslt).toFixed(2);
    }
  };

  const userButton = () => {
    let datev = moment(
      moment
        .utc(
          membershipDetailss[0]?.membership_start_date,
          'YYYY-MM-DDTHH:mm:ssZ',
        )
        .add(membershipDetailss[0]?.membership_expiry, 'days'),
    ).format('ll');

    if (user?.membership_type < details[0]?.id && user?.membership_type == 0) {
      return <CxoButtonWithIcon label="Buy Now" onClick={buyMembership} />;
    } else if (
      user?.membership_type < details[0]?.id &&
      user?.membership_type == 1
    ) {
      return <CxoButtonWithIcon label="Buy Now" onClick={buyMembership} />;
    } else if (user?.membership_type > details[0]?.id) {
      return <CxoButtonDisabled label="Buy Now" onClick={buyMembership} />;
    } else if (user?.membership_type === details[0]?.id) {
      return (
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '24px',
            lineHeight: '24px',
            color: '#fff',
          }}>
          Valid Till - {datev}
        </Typography>
      );
    } else {
      return <CxoButtonWithIcon label="Upgrade" onClick={showDetails} />;
    }
    // return <CxoButtonWithIcon label="Upgrade" onClick={upgradeMembership} />;
  };

  const typeMemberColor = () => {
    var result = membershipList.filter((obj) => {
      return obj.type === type;
    });

    if (result[0]?.id == 1) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#38CD84',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else if (result[0]?.id == 2) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#C0C0C0',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else if (result[0]?.id == 3) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#FFC107',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else if (result[0]?.id == 4) {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#A0B2C6',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: '#38CD84',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '600',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
          }}>
          {result[0]?.type} Membership
        </Typography>
      );
    }
  };

  if (rewardVouchelistloading) {
    return (
      <Fragment>
        <Box p={2}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Stack
                    direction="row"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '48px',
                        maxWidth: 'min-content',
                        width: '48px',
                      }}>
                      <img alt="creditgold" src={credits_gold} />
                    </Box>

                    <Stack
                      direction="row"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '16px',
                      }}>
                      <Box pt={1} pb={0.5} px={1} sx={{ width: '100%' }}>
                        <Typography
                          variant="h2"
                          component="h2"
                          sx={{
                            color: '#F5BD02',
                            fontSize: '20px',
                            lineHeight: '27px',
                            fontWeight: '600',
                            fontFamily: 'Open Sans',
                          }}>
                          {rewardsTotalCredits?.credits
                            ? parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()
                            : parseInt(
                                rewardsTotalCredits?.credits,
                              )?.toLocaleString()}
                        </Typography>
                      </Box>
                      <Box pt={0.5} pb={1} px={1}>
                        <Typography variant="h2" component="h2" sx={{}}>
                          Credits
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>

                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CxoButton label="Details" onClick={creditsDetails} />
                </Box>
              </Stack>
            </Paper>
            <Fragment>
              <Stack direction="column" spacing={1}>
                <PartnerCardSkeleton />
                <PartnerCardSkeleton />
              </Stack>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {showdetails && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showdetails}>
              <Box
                sx={{
                  width: '80%',
                  backgroundColor: '#1c1c1c',
                  borderRadius: '10px',
                  maxWidth: '700px',
                }}>
                <Stack spacing={0} sx={{ width: '100%', maxWidth: '700px' }}>
                  <Fragment>
                    <Stack spacing={0} direction="row">
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '24px',
                          color: '#38CD84',
                          fontWeight: '600',
                        }}>
                        Upgrade Membership
                      </Box>
                      <Box
                        px={3}
                        pt={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}>
                        <CloseIcon
                          sx={{
                            color: '#ffffff',
                            cursor: 'pointer',
                            fontSize: '24',
                          }}
                          onClick={showDetails}
                        />
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="column" spacing={1}>
                          <Typography variant="h2" component="h2">
                            Current Membership Price
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                fontFamily: 'Open Sans',
                                textTransform: 'capitalize',
                              }}>
                              ({' '}
                              {currentMembership[0]?.type +
                                ' ' +
                                'Membership -' +
                                currentMembership[0]?.expiry +
                                'Days'}{' '}
                              )
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {currentMembership[0]?.price}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="column" spacing={1}>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h2" component="h2">
                              Membership Used
                            </Typography>
                            <Stack direction="row" spacing={0.5}>
                              <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                  color: '#F5BD02',
                                  fontFamily: 'Open Sans',
                                }}></Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '14px',
                                  maxWidth: 'min-content',
                                  width: '14px',
                                  paddingTop: '4px',
                                }}></Box>
                            </Stack>
                          </Stack>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                              fontFamily: 'Open Sans',
                            }}>
                            {noOfDays()}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {membershipUsed()}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h2" component="h2">
                            Balance
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}></Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {membershipBalance()}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography
                            variant="h2"
                            component="h2"
                            sx={{ textTransform: 'capitalize' }}>
                            {details[0]?.type + ' '} Membership Price
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}></Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {details[0]?.price}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingTop: '16px' }}>
                      <Box pl={3}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h2" component="h2">
                            To Pay
                          </Typography>
                          <Stack direction="row" spacing={0.5}>
                            <Typography
                              variant="h2"
                              component="h2"
                              sx={{
                                color: '#F5BD02',
                                fontFamily: 'Open Sans',
                              }}></Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '14px',
                                maxWidth: 'min-content',
                                width: '14px',
                                paddingTop: '4px',
                              }}></Box>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box pr={3}>
                        <Typography
                          variant="h2s"
                          component="h2s"
                          sx={{
                            color: '#F5BD02',
                            fontFamily: 'Open Sans',
                          }}>
                          Rs {membershipUpgradePrice}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ paddingTop: '16px' }}>
                      <Box
                        py={2}
                        px={3}
                        pb={5}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CxoButton
                          label={'Pay ' + membershipUpgradePrice}
                          onClick={upgradeMembership}
                        />
                      </Box>
                    </Stack>
                  </Fragment>
                </Stack>
              </Box>
            </Backdrop>
          </Box>
        )}

        {showconfirmation && (
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              zIndex: '3001',
            }}>
            <Backdrop
              sx={{
                color: '#fff',
                backgroundColor: 'rgb(0 0 0 / 28%)',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={showconfirmation}>
              <Stack
                spacing={0}
                sx={{
                  width: '100%',
                  maxWidth: '700px',
                  backgroundColor: '#333333',
                  borderRadius: '5px',
                }}>
                <Stack spacing={0} direction="row">
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '24px',
                    }}>
                    <Typography variant="h5s" component="h5s">
                      Term and Conditions
                    </Typography>
                  </Box>
                  <Box
                    px={3}
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CloseIcon
                      sx={{
                        color: '#ffffff',
                        cursor: 'pointer',
                        fontSize: '24',
                        cursor: 'pointer',
                      }}
                      onClick={showConfirmation}
                    />
                  </Box>
                </Stack>

                <Box
                  p={5}
                  sx={{
                    width: '100%',
                    backgroundColor: '#333333',
                    borderRadius: '10px',
                    maxWidth: '700px',
                    whiteSpace: 'pre-line',
                    borderRadius: '5px',
                  }}>
                  {termdata?.tnc}
                </Box>
              </Stack>
            </Backdrop>
          </Box>
        )}
        <Box m={2}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}>
                <Box
                  py={2}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant="h5s" component="h5s">
                    Benefits
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '250px',
                    backgroundColor: '#333',
                  }}>
                  <Stack
                    direction="column"
                    sx={{ width: '100%', alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: '110px',
                        height: '90px',
                        backgroundColor: '#262525',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      {colorStart()}
                    </Box>
                    <Box
                      sx={{
                        width: '0px',
                        height: '0px',
                        border: '55px solid',
                        borderBottomColor: 'transparent',
                        borderTopColor: '#262525',
                        borderLeftColor: 'transparent',
                        borderRightColor: 'transparent',
                      }}></Box>
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      marginTop: '-30px',
                    }}>
                    {typeMemberColor()}
                  </Stack>
                  <Stack
                    sx={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      marginTop: '6px',
                    }}>
                    <Stack direction="row" spacing="1">
                      {user?.isVerified ? (
                        <>
                          {user?.membership_type === details[0]?.id && (
                            <Fragment>
                              <Box sx={{ marginLeft: '8px' }}>
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  className={classes.tabname}
                                  sx={{ color: '#ffffff', fontSize: '12px' }}>
                                  Membership Id:
                                </Typography>
                              </Box>
                              <Box pl={1}>
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  className={classes.tabname}
                                  sx={{
                                    color: '#38CD84',
                                    fontFamily: 'Open Sans',
                                    fontSize: '12px',
                                  }}>
                                  {user?.subscription_id}
                                </Typography>
                              </Box>
                              <Box
                                onClick={() => copyContent()}
                                pl={1}
                                sx={{
                                  height: '24px',
                                  width: '24px',
                                  cursor: 'pointer',
                                  paddingBottom: '8px',
                                }}>
                                <Icon
                                  name="copy"
                                  sx={{ marginBottom: '8px' }}
                                />
                              </Box>
                            </Fragment>
                          )}
                        </>
                      ) : (
                        <Box sx={{ height: '40px' }}></Box>
                      )}
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: '16px',
                    width: 'calc(100% - 16px)',
                  }}>
                  {/* <ul>
                    {membershipDetails?.length > 0 &&
                      membershipDetails?.map((item, index) => {
                        return <li className="dotp">{item}</li>;
                      })}
                  </ul> */}

                  {/* {membershipBenefits?.map((benefit, index) => {
                    return <RewardVoucherCard benfits={benefit} />;
                  })} */}

                  {benefits?.length == 0 && (
                    <Stack
                      direction="column"
                      sx={{ width: '100%' }}
                      spacing={2}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={185}
                      />

                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={185}
                      />
                    </Stack>
                  )}

                  <Stack
                    direction="column"
                    sx={{
                      width: '100%',
                      backgroundColor: '#333',
                      marginTop: '8px',
                      borderRadius: '8px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        textDecorationColor: '#38CD84',
                      }}
                      p={2}>
                      <Typography
                        variant="h2s"
                        component="h2s"
                        sx={{
                          color: '#38CD84',
                          fontSize: '24px',
                          lineHeight: '27px',
                          fontWeight: '600',
                          fontFamily: 'Open Sans',
                          textTransform: 'capitalize',
                        }}>
                        {'Benefits'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: '0px',
                        backgroundColor: '#333',
                        borderRadius: '8px',
                      }}>
                      <Box pl={1}>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.tabname}
                          sx={{
                            color: '#ffffff',
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                          }}>
                          {textinBenefits()}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>

                  {benefits?.map((benefit, index) => {
                    return (
                      <>
                        <Stack
                          direction="column"
                          sx={{
                            width: '100%',
                            backgroundColor: '#333',
                            marginTop: '8px',
                            borderRadius: '8px',
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignContent: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              textDecorationColor: '#38CD84',
                            }}
                            onClick={() =>
                              redirectTocategory(benefit['category_id'])
                            }
                            p={2}>
                            <Typography
                              variant="h2s"
                              component="h2s"
                              sx={{
                                color: '#38CD84',
                                fontSize: '24px',
                                lineHeight: '27px',
                                fontWeight: '600',
                                fontFamily: 'Open Sans',
                                textTransform: 'capitalize',
                              }}>
                              {categoryName(benefit['category_id'])}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              margin: '0px',
                              backgroundColor: '#333',
                              borderRadius: '8px',
                            }}>
                            {benefit['value']
                              ? benefit['value']?.map((value, index) => {
                                  return (
                                    <RewardVoucherCard
                                      benfits={value}
                                      termAndCondition={termAndCondition}
                                    />
                                  );
                                })
                              : ''}
                          </Box>
                        </Stack>
                      </>
                    );
                  })}
                </Box>
              </Stack>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  direction: 'row',
                  paddingLeft: '8px',
                  marginTop: '8px',
                  backgroundColor: '#3E3D3D',
                  alignItems: 'center',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                }}>
                {' '}
                <Box
                  px={2}
                  mb={1}
                  mt={2.5}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}></Box>
                <Box pb={1} pt={0.5} pr={2}>
                  <CxoButton onClick={purchaseNow} label="Purchase Now" />
                  {/* {details[0]?.id > 1 ? (
                    <CxoButton onClick={buyMembership} label="BUY" />
                  ) : (
                    <CxoButtonDisabled label="BUY" />
                  )} */}
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Fragment>
    );
  }
};

export default React.memo(Membership);

// {userButton()}
// <Tab>
//   <Stack direction="row" spacing={0.5}>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabname}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     >
//       LinkCxO
//     </Typography>
//     <Typography
//       variant="h5"
//       component="h5"
//       className={classes.tabdigit}
//       sx={{ color: value === 0 ? "#000000" : "#ffffff" }}
//     ></Typography>
//   </Stack>
// </Tab> rzp_test_bbIfwGw718hTLk
