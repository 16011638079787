import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  LinearProgress,
  useMediaQuery,
  Stack,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Navbar from '../../components/HomeHeader/navBar/navBar';
import Footer from '../../components/HomeHeader/footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import config from '../../services/apiConfig';
import * as surveyActions from '../../store/survey';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '10px',
  borderRadius: '30px',
  '& .MuiLinearProgress-root': {
    backgroundColor: 'lightgray',
  },
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#38CD84',
    height: '50px',
  },
}));

const CenteredContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // minHeight: '100vh',
  margin: '8px',
});
const SurveyResponse = () => {
  const [responseData, setResponseData] = useState(null);
  const theme = useTheme();
  let params = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state) => state?.user?.UserDetails);
  

  // /forms/get_form_report

  useEffect(() => {
    const fetchData = async () => {
      const id = params?.id;
      try {
        const response = await axios.post(
          config?.serverBaseUrl + '/forms/get_form_report',
          {
            formId: id,
          },
        );
        if (response?.data?.status == 1) {
          const data = response.data;
          setResponseData(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const sendReport = async () => {
    const id = params?.id;
    const data = {
      formId: id,
      userId: user?.id
    };
    const sendreport = await dispatch(surveyActions.surveyDownloadReport(data));
  };

  return (
    <>
      {' '}
      <CenteredContainer>
        {responseData ? (
          <Paper
            elevation={3}
            sx={{
              padding: isMobile ? 1 : 3,
              color: 'white',
              width: '100%',
            }}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  paddingBottom: '8px',
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#38CD84',
                }}>
                {responseData.data.formTitle}
              </Typography>
              <Box sx={{ width: '150px', paddingRight: '20px' }}>
                {!responseData?.data?.surveyRemainingTime?.includes('left') && (
                  <Button
                    sx={{
                      borderRadius: '30px',
                      fontFamily: 'Raleway',
                      lineHeight: '20px',
                      fontWeight: '600',
                      fontSize: '15px',
                      textTransform: 'none',
                      padding: '8px 8px',
                      width:"140px",
                      color: '#000',
                      marginRight: '10px',
                      backgroundColor: (theme) =>
                        theme.palette.palette.text.secondary,
                      '&:hover': {
                        backgroundColor: (theme) =>
                          theme.palette.palette.text.secondary,
                      },
                    }}
                    variant="contained"
                    color="primary"
                    onClick={sendReport}>
                    Send Report
                  </Button>
                )}
              </Box>
            </Stack>

            <Typography
              variant="h2"
              gutterBottom
              sx={{
                color: 'rgba(255, 255,255,1)',
                whiteSpace: 'pre-line',
                lineHeight: '21px',
                letterSpacing: '1.2',
                '@media (max-width: 500px)': {
                  fontSize: '13px',
                  fontWeight: '500',
                },
              }}>
              {responseData.data.formDescription}
            </Typography>
            {responseData.data.formResponses.map((question, index) => (
              <Box
                key={index}
                mt={isMobile ? 2 : 2}
                sx={{
                  width: '100%',
                  border: '1px solid #38CD84',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                  transition: '0.3s',
                  padding: isMobile ? 1 : 2,
                }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontSize: '16px',
                    lineHeight: '18px',
                    color: '#fff',
                  }}>
                  {' '}
                  <span
                    style={{
                      color: '#38CD84',
                      fontFamily: 'Open Sans',
                    }}>{`Q${index + 1}.`}</span>
                  <span
                    style={{
                       color: '#38CD84',
                    }}>
                    {' ' + question.formQuestion}
                  </span>
                </Typography>{' '}
                {question?.formQuestionType === 'text' ? (
                  <Box
                    mt={isMobile ? 0.5 : 2}
                    sx={{
                      display: 'flex',
                      overflowX: 'auto',

                      '&::-webkit-scrollbar': {
                        width: '2px',
                        height: '8px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        marginTop: '100px',
                        backgroundColor: 'gray',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#333',
                      },
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      {question.formQuestionResponses.map((response, idx) => (
                        <div
                          key={idx}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#5a5a5a',
                            boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
                            transition: '0.5s',
                            flex: 'none',
                            marginRight: '10px',
                            padding: '10px 20px',
                            width: '100%',
                            borderRadius: '3px',
                            marginTop: '8px',
                          }}>
                          <div
                            style={{
                              color: '#fff',
                              fontSize: '13px',
                              fontWeight: '500',
                            }}>
                            {`${response.user}`}
                          </div>
                          <div
                            style={{
                              marginLeft: '5px',
                              fontSize: '13px',
                              fontWeight: '500',
                            }}>
                            {':' + ' ' + response.response}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Box>
                ) : (
                  question?.formQuestionResponses?.map((response, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={isMobile ? 0.5 : 2}>
                      <Box px={2}>
                        <Typography color="white" sx={{ fontSize: '14px' }}>
                          <span
                            style={{
                              color: '#38CD84',
                              marginRight: '5px',
                            }}>
                            {String.fromCharCode(97 + index)}.{' '}
                          </span>
                          {' ' + response?.questionOption}
                        </Typography>
                      </Box>
                      <Box sx={{ marginRight: '32px' }}>
                        <Stack direction="row" spacing="2">
                          <Box sx={{ width: '30px' }}>
                            <Typography
                              color="white"
                              sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                marginLeft: '10px',
                                color: '#fff',
                              }}>
                              {response?.responseCount}
                            </Typography>
                          </Box>
                          <Box sx={{ width: '200px', paddingTop: '4px' }}>
                            <CustomLinearProgress
                              variant="determinate"
                              value={parseFloat(response?.percentage)}
                              color="secondary"
                            />
                          </Box>
                          <Box sx={{ width: '30px' }}>
                            <Typography
                              color="white"
                              sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                marginLeft: '20px',
                                color: '#fff',
                              }}>
                              {response?.percentage}
                            </Typography>
                          </Box>{' '}
                        </Stack>
                      </Box>
                    </Stack>
                  ))
                )}
              </Box>
            ))}
          </Paper>
        ) : (
          <Box>
            <Typography gutterBottom variant="h5" component="h5">
              No Responses Till now
            </Typography>
          </Box>
        )}{' '}
      </CenteredContainer>
    </>
  );
};

export default SurveyResponse;
