import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Paper, Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import * as authActions from '../../store/auth';
import * as userActions from '../../store/user';
import * as jobsActions from '../../store/jobs';
import { dateTime } from '../../constants/app';

export default function ProfileShort(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user?.user_details);
  const profileCompleted = useSelector(
    (state) => state?.auth?.profileCompleted,
  );
  const userdetails = useSelector((state) => state?.user?.UserDetails);
  const userimage = useSelector((state) => state?.user?.UserProfileImage);
  const navigate = useNavigate();

  useEffect(async () => {
    const data = {
      user_id: user?.id,
      self_id: user?.id,
      datetime: dateTime(),
    };
    const users = await dispatch(userActions.userProfileDetails(data));
  }, []);
  useEffect(() => {
    async function fetchData() {
      const data = {
        cand_id: user?.id,
      };
      const rewardscategorylist = await dispatch(
        jobsActions.getJobApplyCount(data),
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data ={
        user_id : user?.id
      }
      const membership =  await dispatch(userActions?.userIsVerifiedNMembership(data))
    }
    fetchData();
  }, []);

  useEffect(async () => {
    const data = {
      id: user?.id,
    };
    const usersabout = await dispatch(userActions.userProfileAbout(data));
  }, []);
  
  useEffect(() => {
    async function fetchData() {
      const profilefield = await dispatch(
        authActions.profileRequiredField({ user_id: user?.id }),
      );
    }
    fetchData();
  }, []);
  const redirectProfile = async () => {
    if (profileCompleted) {
      navigate('/profile');
    } else {
      navigate('/addbio');
    }
  };
  return (
    <Paper sx={{ backgroundColor: '#262525', backgroundImage: 'none' }}>
      <Stack spacing={1} direction="column" mx={2}>
        <Box
          px={2}
          pt={2}
          sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
          onClick={() => redirectProfile()}>
          <Avatar
            src={userimage ? userimage : PersonIcon}
            sx={{ width: 64, height: 64 }}
          />
        </Box>
        <Box
          onClick={() => redirectProfile()}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}>
          <Typography
            variant="h5s"
            component="h5s"
            sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            {userdetails?.first_name + userdetails?.last_name &&
            (userdetails?.first_name + userdetails?.last_name)?.length > 32
              ? (userdetails?.first_name + ' ' + userdetails?.last_name)?.slice(
                  0,
                  32,
                ) + '...'
              : userdetails?.first_name +' ' + userdetails?.last_name}
            {/* {userdetails?.last_name && userdetails?.last_name?.length > 22
              ? userdetails?.last_name?.slice(0, 18) + '...'
              : userdetails?.last_name} */}
            {/* {userdetails?.first_name
              ? userdetails?.first_name
              : userdetails?.first_name}{' '}
            {userdetails?.last_name
              ? userdetails?.last_name
              : userdetails?.last_name} */}
          </Typography>
        </Box>
        <Box
          onClick={() => redirectProfile()}
          pb={2}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack
            spacing={0.5}
            direction={
              userdetails?.designation?.length +
                userdetails?.company_name?.length >
              30
                ? 'column'
                : 'row'
            }
            alignItems="center"
            justifyContent="center">
            <Box justifyContent="center" px={1}>
              <Typography
                variant="h5"
                component="h5"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  opacity: '0.7',
                  color: '#959498',
                }}>
                {userdetails?.designation &&
                userdetails?.designation?.length > 31
                  ? userdetails?.designation?.slice(0, 30) + '...'
                  : userdetails?.designation}
              </Typography>
            </Box>

            <Box justifyContent="center">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  opacity: '0.7',
                  color: '#959498',
                }}>
                {userdetails?.designation &&
                userdetails?.company_name?.length > 31
                  ? userdetails?.company_name?.slice(0, 30).concat('...')
                  : userdetails?.company_name}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
}
