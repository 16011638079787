import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Paper, Grid, Box, Typography, Divider } from '@mui/material';
import Card from '@mui/material/Card';

import Membership_Star_White from '../../assets/header/Membership_Star_White.png';
import { dateTime } from '../../constants/app';
import * as rewardsActions from '../../store/rewards';
import { useNavigate, useLocation } from 'react-router-dom';

const RewardVoucherCard = (props) => {
  console.log('props', props);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.UserDetails);
  const jobDetails = useSelector((state) => state?.jobs?.Jobdetails);
  const navigate = useNavigate();
  const [termdata, setTermdata] = React.useState({});

  const navigateVoucher = async () => {
    const voucher = await dispatch(
      rewardsActions.voucherDetailsOpen(props?.benfits),
    );
    navigate('/voucher/rewards/details', { state: { voucher: props?.benfits } });
  };

  const termAndCondition = async () => {
    const data = {
      user_id: user?.id,
      product_id: props?.benfits?.id,
      datetime: dateTime(),
    };
    const rewardVoucherDetails = await dispatch(
      rewardsActions.rewardsVoucherDetails(data),
    );
    if (rewardVoucherDetails?.status === 1) {
      setTermdata(rewardVoucherDetails?.data);
    }
  };

  const navigateCategory = () => {
    // if (props?.category?.name === 'Real Estate') {
    //   const path =
    //     '/rewards/realestate/partners/' +
    //     props?.category?.name +
    //     '-' +
    //     'lx' +
    //     props?.category?.id;
    //   navigate(path, { state: { category: props?.category } });
    // } else {
    //   const path =
    //     '/rewards/partners/' +
    //     props?.category?.name +
    //     '-' +
    //     'lx' +
    //     props?.category?.id;
    //   navigate(path, { state: { category: props?.category } });
    // }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#262525',
        backgroundImage: 'none',
        justifyContent: 'space-between',
        minHeight: '147px',
        maxWidth: '200px',
        width: '100%',
        cursor: 'pointer',
        margin: '16px 12px',
      }}>
      <Stack direction="column">
        <Box
          sx={{
            width: '100%',
            height: '171px',
            display: 'flex',
            backgroundColor: '#262525',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={navigateVoucher}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '90px',
              maxWidth: 'min-content',
              width: '36px',
            }}>
            <img
              alt="Membership_Star_Passive"
              src={
                props?.benfits?.image
                  ? props?.benfits?.image
                  : Membership_Star_White
              }
            />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            width: '100%',
            height: '71px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Box px={1} mt={0.5}>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                color: '#38CD84',
                fontSize: '12px',
                letterSpacing: '0.31px',
                fontWeight: 600,
                padding: '8px',
                width: 'max-content',
              }}>
              {props?.benfits?.name?.length > 26
                ? props?.benfits?.name.substring(0, 26) + '...'
                : props?.benfits?.name}
            </Typography>
          </Box>
          <Box px={1} mt={-1}>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '12px',
                letterSpacing: '0.31px',
                fontWeight: 600,
                padding: '8px',
                width: 'max-content',
              }}>
              {props?.benfits?.offer_details?.length > 26
                ? props?.benfits?.offer_details.substring(0, 26) + '...'
                : props?.benfits?.offer_details}
            </Typography>
          </Box>
          <Box
            onClick={() => props.termAndCondition(props?.benfits?.id)}
            mt={-1.5}>
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: '12px',
                lineHeight: '24px',
                letterSpacing: '0.31px',
                fontWeight: 600,
                padding: '8px',
                width: 'min-content',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}>
              T&C
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Card>
  );
};

export default React.memo(RewardVoucherCard);
