import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CxoInputCss from '../../components/CxoInputCss/CxoInputCss';
import Menuicon from '../../assets/menuicon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemText from '@mui/material/ListItemText';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AntSwitch from '../../components/AntSwitch/AntSwitch';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import CxoButton from '../../components/CxoButton/CxoButton';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileInput from './FileInput';

import FormLabel from '@mui/material/FormLabel';

import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import * as jobsActions from '../../store/jobs';
import * as activityActions from '../../store/activity';
import * as onboardActions from '../../store/onboard';
import * as authActions from '../../store/auth';
import { dateTime } from '../../constants/app';
import { countryListnew } from '../../constants/app';
import { salaryDrawn } from '../../constants/app';
import { experienceRange } from '../../constants/app';
const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    color: '#38CD84',
  },
}));

const CssSelect = styled(Select)(({ theme }, props) => {
  return {
    '& .MuiOutlinedInput-input': {
      color: 'green',
    },
    '& .MuiSelect-select': {
      color: '#ffffff',
    },
    '& .MuiSelect-outlined': {
      color: 'green',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'purple',
    },
    '& MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
      fontFamily: props?.fontFamily ? props?.fontFamily : 'Raleway',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});

const CssAutocomplete = styled(Autocomplete)(({ theme }) => {
  return {
    '& .MuiAutocomplete-root': {
      padding: '0px',
    },
    '& .MuiSelect-select': {
      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
    },
    '& .MuiList-root': {
      maxHeight: '60vh',
    },
    '& label.Mui-focused': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      padding: '1px 4px',
      marginRight: '5px',

      borderRadius: '5px 0px 0px 5px',
      marginRight: '1px',
      fontFamily: 'Ariel',
      '& fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.palette.text.secondary,
      },
    },
  };
});
const JobsPreferences = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [skill, setSkill] = useState('');
  const [country, setCountry] = useState('91');
  const user = useSelector((state) => state?.user?.UserDetails);
  const [industrylst, setIndustrylst] = useState([]);
  const [functionlst, setFunctionlst] = useState([]);
  const industryList = useSelector((state) => state.onboard.industryList);
  const functionList = useSelector((state) => state.onboard.functionList);
  const [tempskill, setTempSkill] = useState([]);
  const [checked, setChecked] = useState(true);
  const [file, setFile] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [imgData, setImgData] = useState(null);
  const [experience, setExperience] = useState('');
  const [salary, setSalary] = useState('');
  const [jobdescription, setJobdescription] = useState('');
  const [relocation, setRelocation] = React.useState('');
  const handleRelocation = (event) => {
    setRelocation(event.target.value);
  };

  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(async () => {
    const designationlist = await dispatch(onboardActions.getDesignationList());
  }, []);
  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndustrylst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(async () => {
    const industrylist = await dispatch(onboardActions.getIndustryList());
  }, []);

  useEffect(async () => {
    const functionlist = await dispatch(onboardActions.getFunctionList());
  }, []);
  const getDocument = async (event) => {
    setFile(event.target.files[0]);
    setType('document');
    setName(event.target.files[0].name);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };
  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setFunctionlst(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSkill = (event) => {
    setSkill(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      let temp = tempskill;
      temp.push(skill);
      setTempSkill(temp);
      setSkill('');
    }
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleJobdescription = () => {};
  const countryChange = (event) => {
    setCountry(event.target.value);
  };

  const experienceChange = (event) => {
    setExperience(event.target.value);
  };
  const salaryChange = (event) => {
    setSalary(event.target.value);
  };
  const handleSubmitform = async (data) => {
    console.log('data', data);
  };

  return (
    <Fragment>
      <Box px={2} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0}>
            <Box
              py={3}
              px={3}
              sx={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
              }}>
              <Typography
                variant="h5s"
                component="h5s"
                sx={{ fontWeight: 600 }}>
                Job Preferences
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>

      <Box px={2} pt={2} sx={{ width: '100%' }}>
        <Paper sx={{ backgroundColor: '#232121', backgroundImage: 'none' }}>
          <Stack direction="column" justifyContent="center" spacing={1}>
            <Box px={{ md: 16, xs: 0 }}>
              <form onSubmit={handleSubmit(handleSubmitform)}>
                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                          width: '24px',
                        }}>
                        <Menuicon name="email" color="#38CD84" />
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack direction="column" sx={{ width: '100%' }}>
                          <CxoInputCss
                            label="Email Id"
                            value={value}
                            err={errors?.email ? errors?.email : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.email?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="email"
                      rules={{
                        required: {
                          value: true,
                          message: 'Email is required',
                        },
                      }}
                      defaultValue=""
                    />
                  </Stack>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="phone" color="#38CD84" />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: '120px',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <>
                              <CssAutocomplete
                                id="country-select-demo"
                                sx={{ width: 124, paddingRight: '5px' }}
                                value={selectedCountry}
                                options={countryListnew}
                                autoHighlight
                                disableClearable
                                variant="standard"
                                onChange={(_event, country) => {
                                  console.log('country', country);
                                  setSelectedCountry(country);
                                }}
                                getOptionLabel={(option) => {
                                  return option.iso3 + ' +' + option.phone_code;
                                }}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{
                                      '& > img': { mr: 2, flexShrink: 0 },
                                      width: '120px',
                                    }}
                                    {...props}>
                                    {option.iso3} ({option.phone_code})
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country Code"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </>
                          )}
                          name="country_code"
                          defaultValue="91"
                        />
                      </FormControl>
                    </Box>
                    <Box px={2} pb={2} sx={{ width: '100%' }}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Stack>
                            <CxoInputCss
                              label="Mobile No *"
                              value={value}
                              err={errors?.mobile ? errors?.mobile : false}
                              onChange={onChange}
                            />
                            <Box
                              sx={{
                                fontSize: '9px',
                                height: '10px',
                                paddingLeft: '8px',
                              }}>
                              {errors?.mobile?.message}{' '}
                            </Box>
                          </Stack>
                        )}
                        name="mobile"
                        required
                        rules={{
                          required: {
                            value: true,
                            message: 'Mobile is required',
                          },
                        }}
                        defaultValue=""
                      />
                    </Box>
                  </Stack>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1} direction="row">
                    <Box
                      pt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '24px',
                            maxWidth: 'min-content',
                            minWidth: '24px',
                          }}>
                          <Menuicon name="experience" color="#38CD84" />
                        </Box>
                      </Box>
                    </Box>
                    <Box py={1} pl={1} sx={{ width: '96%' }}>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{ color: '#38CD84' }}>
                          Experience
                        </InputLabel>
                        <CssSelect
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={experience}
                          label="Experience"
                          onChange={experienceChange}
                          size="small">
                          {experienceRange
                            ? experienceRange.map((item, key) => {
                                return (
                                  <MenuItem key={key} value={item.value}>
                                    {item.value}
                                  </MenuItem>
                                );
                              })
                            : ''}
                        </CssSelect>
                      </FormControl>
                    </Box>
                  </Stack>
                </Box>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="salary" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{ color: '#38CD84' }}
                        id="demo-simple-select-label">
                        Last salary drawn
                      </InputLabel>

                      <CssSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={salary}
                        label="Salary"
                        onChange={salaryChange}
                        size="small">
                        {salaryDrawn
                          ? salaryDrawn.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.value}>
                                  {item.value}
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="industry" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{ color: '#38CD84' }}
                        id="demo-simple-select-label">
                        Preferred Industry
                      </InputLabel>
                      <CssSelect
                        // MenuProps={{
                        //   fontSize: "12px",
                        //   disablePadding: true,
                        //   // PaperProps: { sx: { maxHeight: 220, padding: 0 } },
                        //   PaperProps: {
                        //     style: {
                        //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        //       width: 250,
                        //     },
                        //   },
                        //   variant: "menu",
                        //   getContentAnchorEl: null,
                        // }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industrylst}
                        multiple
                        label="Industry List"
                        onChange={handleChange}
                        size="small"
                        className={classes.underline}
                        color="secondary"
                        sx={{ color: '#38CD84' }}
                        renderValue={(selected) => {
                          console.log('selected', selected);
                          return selected.length + ' ' + 'Selected';
                        }}>
                        {industryList?.data
                          ? industryList?.data.map((item, key) => {
                              return (
                                <MenuItem
                                  color="secondary"
                                  key={key}
                                  value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.substring(0, 25) + '...'
                                    }
                                  />
                                  <Checkbox
                                    color="secondary"
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    checked={industrylst.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="functions" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>
                  <Box py={1} pl={1} sx={{ width: '96%' }}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel
                        style={{ color: '#38CD84' }}
                        id="demo-simple-select-label">
                        Preferred Function
                      </InputLabel>
                      <CssSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={functionlst}
                        label="Function"
                        multiple
                        className={classes.underline}
                        onChange={functionChange}
                        size="small"
                        renderValue={(selected) => {
                          console.log('selected', selected);
                          return selected.length + ' ' + 'Selected';
                        }}>
                        {functionList?.data
                          ? functionList?.data.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  <ListItemText
                                    color="secondary"
                                    primary={
                                      item.title.substring(0, 25) + '...'
                                    }
                                  />
                                  <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="secondary"
                                    checked={functionlst.indexOf(item.id) > -1}
                                  />
                                </MenuItem>
                              );
                            })
                          : ''}
                      </CssSelect>
                    </FormControl>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="location" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Stack>
                          <CxoInputCss
                            label="Preferred Location"
                            value={value}
                            err={errors?.location ? errors?.location : false}
                            onChange={onChange}
                          />
                          <Box
                            sx={{
                              fontSize: '9px',
                              height: '10px',
                              paddingLeft: '8px',
                            }}>
                            {errors?.location?.message}{' '}
                          </Box>
                        </Stack>
                      )}
                      name="location"
                      required
                      rules={{
                        required: {
                          value: true,
                          message: 'Location is required',
                        },
                      }}
                      defaultValue=""
                    />
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <Menuicon name="location" color="#38CD84" />
                      </Box>
                    </Box>
                  </Box>

                  <Box pt={0.5} px={2} sx={{ width: '100%' }}>
                    <Stack spacing={2} direction="row">
                      <FormControl>
                        <FormLabel
                          color="secondary"
                          id="demo-row-radio-buttons-group-label"
                          sx={{ color: '#38CD84' }}>
                          Open to Relocation
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          color="secondary"
                          value={relocation}
                          onChange={handleRelocation}>
                          <FormControlLabel
                            value="Yes"
                            color="secondary"
                            control={<Radio color="secondary" size="small" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            color="secondary"
                            control={<Radio color="secondary" size="small" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Box>
                </Stack>

                <Stack spacing={1} direction="row">
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '24px',
                          maxWidth: 'min-content',
                          minWidth: '24px',
                        }}>
                        <AttachFileOutlinedIcon sx={{ color: '#38CD84' }} />
                      </Box>
                    </Box>
                  </Box>
                  <Stack spacing={2} direction="row">
                    <Box pt={1} pl={1}>
                      <label htmlFor="icon-button-doc">
                        <Stack direction="column" spacing={1}>
                          <Typography variant="h5s" component="h5s">
                            Attach Resume
                          </Typography>
                          <Typography variant="h6" component="h6">
                            {name ? name : ''}
                          </Typography>
                        </Stack>
                      </label>
                      <input
                        id="icon-button-doc"
                        type="file"
                        style={{
                          display: 'none',
                          width: '200px',
                          height: '80px',
                        }}
                        onChange={getDocument}
                        name="docum"
                      />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}></Box>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{ paddingTop: '16px' }}>
                  <CxoButton label="Submit" />
                </Stack>
              </form>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default React.memo(JobsPreferences);

// {designationList?.data
//   ? designationList?.data.map((item, key) => {
//       return (
//         <MenuItem key={key} value={item.title}>
//           {item.title}
//         </MenuItem>
//       );
//     })
//   : ""}
